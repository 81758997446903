import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import GetData from '../services/GetData';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import PostDataWithParam from '../services/PostDataWithParam';
import ConfirmBox from '../externaljs/ConfirmBox';
import Breadcumb from '../externaljs/Breadcrumb';
import Storage from '../externaljs/Storage';
import DateFormat from '../externaljs/DateFormat';

class ManageCustomer extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Customer', link: '', class: 'active' }
        ],
        CustomerErr: '',
        lists: [],
        addCustomerStatus: false,
        loading: false,
        staff_user_id: '',
        custom_menu: []
    }

    componentDidMount() {
        this.AllCustomers();
        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        let custom_menu = [];
        if (staff_user_id != "") {
            custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
        }
        this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
            // console.log(this.state.custom_menu);
            // console.log(this.state.staff_user_id);
        });
    }

    AllCustomers = () => {
        this.setState({ loading: true }, () => {
            PostDataWithParam('GetAllCustomer')
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ loading: false }, () => {
                            this.setState({ lists: response.data }, () => {
                                DataTableBasic();
                                var captionParent = window.$('.datatable-header');
                                captionParent.append(`
                                <div class="legend-wrap">
                                    <span></span>
                                    Military Customer
                                </div>`);
                            });
                        })
                    } else {
                        this.setState({ lists: [], loading: false })
                    }
                })
                .catch(() => {
                    this.setState({ lists: [], loading: false })
                })
        })
    }

    removeDataTable = () => {
        if (window.$.fn.DataTable.isDataTable('#list-table')) {
            window.$('#list-table').DataTable().destroy();
        }

        window.$('#list-table tbody').empty();
    }

    ActiveDeactiveCustomer = (customer_id, status) => {

        if (status == 1) {
            ConfirmBox("You want to deactivate this Customer!")
                .then((result) => {
                    if (result) {
                        PostDataWithParam('ActiveDeactiveCustomer', { customer_id: customer_id, status: 0 })
                            .then((response) => {
                                let result = response;
                                if (result.success === 1) {
                                    this.removeDataTable();
                                    this.AllCustomers();
                                    window.swal('Deactivated!', result.msg, 'success')
                                } else {
                                    window.swal('Deactivated!', result.msg, 'success')
                                }
                            });
                    }
                });
        }
        else {
            ConfirmBox("You want to activate this Customer!")
                .then((result) => {
                    if (result) {
                        PostDataWithParam('ActiveDeactiveCustomer', { customer_id: customer_id, status: 1 })
                            .then((response) => {
                                let result = response;
                                if (result.success === 1) {
                                    this.removeDataTable();
                                    this.AllCustomers();
                                    window.swal('Activated!', result.msg, 'success')
                                } else {
                                    window.swal('Activated!', result.msg, 'success')
                                }
                            });
                    }
                });
        }
    }

    DeleteCustomer = (customer_id) => {
        ConfirmBox("You want to delete this Customer!")
            .then((result) => {
                if (result) {
                    PostDataWithParam('DeleteCustomer', { customer_id: customer_id })
                        .then((response) => {
                            let result = response;
                            if (result.success === 1) {
                                this.removeDataTable();
                                this.AllCustomers();
                                window.swal('Deleted!', result.msg, 'success')
                            } else {
                                window.swal('Deleted!', result.msg, 'success')
                            }
                        });
                }
            });
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="clearfix">
                        <h4 className="pull-left">Customer List</h4>

                        {(this.state.staff_user_id == "") &&
                            <div className="pull-right">
                                <Link to="/independent-mover/dashboard/add-customer" className="btn btn-primary btn-xs m-r-15"><i className="icon-plus22"></i> Add Customer</Link>

                            </div>}

                        {
                            (this.state.staff_user_id != "") && this.state.custom_menu.map((menu, i) => {
                                return (
                                    <div>
                                        {
                                            (menu.title == "Manage Customer" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                            <div className="pull-right">
                                                <Link to="/independent-mover/dashboard/add-customer" className="btn btn-primary btn-xs m-r-15"><i className="icon-plus22"></i> Add Customer</Link>

                                            </div>
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className="panel panel-flat">

                        {
                            this.state.loading
                            &&
                            <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                        }
                        {
                            !this.state.loading
                            &&
                            this.state.lists.length !== 0
                            &&
                            <table className="table datatable-basic table-striped" id="list-table">
                                <thead>
                                    <tr >
                                        {/* <th>Relo#</th> */}
                                        <th style={{ whiteSpace: 'nowrap' }}>Full Name</th>
                                        <th>Status</th>
                                        <th className="text-center">Associated Orders</th>
                                        <th>Last Active On</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.lists.map(list =>
                                        <tr key={list.id} className={list.profile_id == '11' ? 'military-row' : ''}>
                                            <td className="text-nowrap">
                                                <Link to={"/independent-mover/dashboard/view-customer/" + list.id}>{list.first_name} {list.last_name}</Link>
                                            </td>
                                            <td>{(list.status > 0) ? 'Active' : 'Inactive'}</td>
                                            <td className="text-center">
                                                {/* <Link to={"/independent-mover/dashboard/view-customer-orders/" + list.user_id}>View</Link> */}
                                                <Link to={{pathname:"/independent-mover/dashboard/view-customer-orders",
                                                            state:{user_id:list.user_id, profile_id:list.profile_id}}}>View</Link>
                                            </td>
                                            <td>{list.last_login_time!=null?DateFormat.getFormattedDateTime(list.last_login_time, 'M d, Y'):'N/A'}</td>

                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }
                        {
                            !this.state.loading
                            &&
                            this.state.lists.length === 0
                            &&
                            <div className="alert alert-primary border-none text-center">No Customer Found.</div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ManageCustomer;