import React, { Component } from 'react';
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataWithParam from '../services/PostDataWithParam';
import DateFormat from '../externaljs/DateFormat';
import { FileType, FileSvg, FileIcon } from '../externaljs/FileType';
import ConfirmBox from '../externaljs/ConfirmBox';

class AppplicationDetails extends Component {
    state = {
        application_id: '',
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Application List', link: '/independent-mover/dashboard/movers-applications', class: '' },
            { title: 'Application Details', link: '', class: 'active' }
        ],
        applicationInfo: [],
        driverInfo: [],
        customerInfo: [],
        company_files: []
    }

    componentDidMount() {
        this.setState({ application_id: this.props.match.params.application_id }, () => {
            this.GetApplicationDetails()
        });
    }

    GetApplicationDetails = () => {
        PostDataWithParam('GetApplicationDetailsForMMC', { application_id: this.state.application_id })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({
                        applicationInfo: response.data.application_info[0],
                        driverInfo: response.data.driver_info[0],
                        customerInfo: response.data.customer_info[0]
                    }, () => {
                        this.GetAllCompanyFile()
                    });
                }
            })
            .catch(err => {
                this.setState({ application: [] });
            })
    }

    GetAllCompanyFile = () => {
        PostDataWithParam('GetApplicationDocument', { application_id: this.state.application_id })
            .then((response) => {
                if (response.success == 1) {
                    this.setState({ company_files: response.data });
                }
            })
            .catch(err => {
                this.setState({ company_files: [] });
            })
    }

    deleteFile = (id) => {
        ConfirmBox("You want to delete this file!")
            .then((result) => {
                if (result) {
                    PostDataWithParam('DeleteApplicationDocument', { document_id: id,application_id: this.state.application_id })
                        .then((response) => {
                            if (response.success == 1) {
                                window.swal('Deleted!', response.msg, 'success')
                                this.GetAllCompanyFile()
                            }
                        })
                }
            })
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="panel panel-flat profile-page">
                        <div className="panel-heading p-b-0">
                            <h6 className="m-b-0 text-semibold">Driver Info</h6>
                            <div className="heading-elements dropdown">
                            </div>
                        </div>
                        <div className="panel-body">
                            <div className="customer-details">
                                <h6 className="m-b-30 m-t-10 text-semibold">{this.state.driverInfo.gender} {this.state.driverInfo.first_name} {this.state.driverInfo.last_name} &nbsp; <small>(Driver)</small></h6>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="text-bold m-b-0">Driver's Address:</label>
                                    <p className="m-b-20">{this.state.driverInfo.address}</p>
                                </div>
                                <div className="col-md-4">
                                    <label className="text-bold m-b-0">Driver's Email Address:</label>
                                    <p className="m-b-20">{this.state.driverInfo.email}</p>
                                </div>
                                <div className="col-md-4">
                                    <label className="text-bold m-b-0">Driver's Mobile Phone:</label>
                                    <p className="m-b-20">{this.state.driverInfo.contact_no}</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <label className="text-bold m-b-0">Driver's Alternate Phone No:</label>
                                    <p className="m-b-20">{this.state.driverInfo.home_contact_no || '---'}</p>
                                </div>
                                <div className="col-md-4">
                                    <label className="text-bold m-b-0">Driver's License # :</label>
                                    <p className="m-b-20">{this.state.driverInfo.licence_number}</p>
                                </div>
                                <div className="col-md-4">
                                    <label className="text-bold m-b-0">Driver's Vehicle No:</label>
                                    <p className="m-b-20">{this.state.driverInfo.vehicle_no}</p>
                                </div>
                            </div>
                            <hr />
                            <h6 className="text-semibold">Customer Info</h6>
                            <div className="customer-details">
                                <h6 className="m-b-20 m-t-0 text-semibold">{this.state.customerInfo.first_name} {this.state.customerInfo.last_name} &nbsp; <small>(Customer)</small></h6>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="text-bold m-b-0">Customer Address :</label>
                                    <p className="m-b-20">{this.state.customerInfo.address}</p>
                                </div>
                                <div className="col-md-4">
                                    <label className="text-bold m-b-0">Date Requested to be on site :</label>
                                    <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.applicationInfo.request_date, 'M d, Y')}</p>
                                </div>
                                <div className="col-md-4">
                                    <label className="text-bold m-b-0">Application Status :</label>
                                    {
                                        this.state.applicationInfo.status == 0
                                        &&
                                        <p className="m-b-20 text-warning">Pending</p>
                                    }
                                    {
                                        this.state.applicationInfo.status == 1
                                        &&
                                        <p className="m-b-20 text-success">Accepted</p>
                                    }
                                    {
                                        this.state.applicationInfo.status == 2
                                        &&
                                        <p className="m-b-20 text-danger">Rejected</p>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <label className="text-bold m-b-0">Customer Phone Number :</label>
                                    <p className="m-b-20">{this.state.customerInfo.contact_no}</p>
                                </div>
                                <div className="col-md-4">
                                    <label className="text-bold m-b-0">Customer Email Address :</label>
                                    <p className="m-b-20">{this.state.customerInfo.email}</p>
                                </div>
                            </div>
                            <div className="well p-15 border-radius-3px">
                                {
                                    this.state.applicationInfo.status == 0
                                    &&
                                    (
                                        <h6 className="m-t-0 text-warning">
                                            <i className="icon-blocked"></i>
                                            {'Application Pending'}
                                        </h6>
                                    )

                                }
                                {
                                    this.state.applicationInfo.status == 1
                                    &&
                                    <h6 className="m-t-0 text-success">
                                        <i className="icon-check"></i>
                                        {'Application Accepted'}
                                    </h6>

                                }
                                {
                                    this.state.applicationInfo.status == 2
                                    &&
                                    <h6 className="m-t-0 text-danger">
                                        <i className="icon-blocked"></i>
                                        {'Application Rejected'}
                                    </h6>
                                }
                                {
                                    this.state.applicationInfo.notes !== null
                                    &&
                                    <div>
                                        <p><b> Security Office Notes :</b></p>
                                        <p>{this.state.applicationInfo.notes}</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="panel-footer text-center">
                            <div className="heading-elements">
                                {
                                    this.state.applicationInfo.status == 0 && this.state.applicationInfo.accept_reject_date != null
                                    &&
                                    (
                                        <span className="heading-text">
                                            <span className="status-mark border-danger position-left"></span>
                                            {'Pending on ' + DateFormat.getFormattedDateTime(this.state.applicationInfo.accept_reject_date, 'M d, Y')}
                                        </span>
                                    )
                                }
                                {
                                    this.state.applicationInfo.status == 1 && this.state.applicationInfo.accept_reject_date != null
                                    &&
                                    (
                                        <span className="heading-text">
                                            <span className="status-mark border-danger position-left"></span>
                                            {'Accepted on ' + DateFormat.getFormattedDateTime(this.state.applicationInfo.accept_reject_date, 'M d, Y')}
                                        </span>
                                    )

                                }
                                {
                                    this.state.applicationInfo.status == 2 && this.state.applicationInfo.accept_reject_date != null
                                    &&
                                    (
                                        <span className="heading-text">
                                            <span className="status-mark border-danger position-left"></span>
                                            {'Rejected on ' + DateFormat.getFormattedDateTime(this.state.applicationInfo.accept_reject_date, 'M d, Y')}
                                        </span>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-flat profile-page">
                        <div className="panel-body">
                            <div className="file-manager hori-scroll">
                                <ul>
                                    {
                                        this.state.company_files.length != 0
                                        &&
                                        this.state.company_files.map((file) => {
                                            return (
                                                <li key={file.id}>
                                                    <div className={"file-wrapper " + FileType(file.document_extension)} >
                                                        <div className="options">
                                                            <a href="javascript:void(0)" className="text-danger-600" onClick={() => this.deleteFile(file.id)}><i className="icon-cancel-circle2"></i></a>
                                                        </div>
                                                        {FileSvg(file.document_extension, file.document_path)}
                                                        <div className="file-title"><a href={file.document_path}><i className={FileIcon(file.document_extension) + " position-left text-primary"}></i> {file.document_name}</a></div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                    {
                                        this.state.company_files.length == 0
                                        &&
                                        <p>No Files Found.</p>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AppplicationDetails;