import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import PostDataWithParam from '../../services/PostDataWithParam';

class Content extends Component {
    state = {
        subscriptionData: []
    }

    componentDidMount() {
        this.getSubscriptionList();
    }

    getSubscriptionList = () => {
        PostDataWithParam('GetSubscriptionListForHomePage', { profile_id: 2 })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ subscriptionData: response.data });
                }
            })
            .catch(err => {
                this.setState({ subscriptionData: [] });
            })
    }

    goToSection = (id) => {
        window.$('html, body').animate({
            scrollTop: window.$("#" + id).offset().top
        }, 500);
    }

    render() {
        const [package1 = {}, package2 = {}] = this.state.subscriptionData;
        return (
            <div className="homepage">
                <section className="banner">
                    <div className="container">
                        <div className="banner-content-wrap">
                            <div className="banner-content">
                                <h1>Join Independent Movers Network &amp; GROW Your Business Worldwide</h1>
                                <p>Finally, Independent Movers, not van line affiliated, like YOU, can harness technology to connect - book jobs &amp; more with other movers, casual labor, 3rd party &amp; claim repair companies in real time. No need to join a van line, everything you will need to succeed &amp; GROW is here at your fingertips.</p>
                                <Link to="#" className="btn btn-join-free" role="button" onClick={()=>this.goToSection("section-1")}>
                                    <span className="">JOIN FOR FREE</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="homepage-services">
                    <div className="container">
                        <div className="service-wrap clearfix">
                            <div className="service-box">
                                <div className="service-box-red">
                                    <h2>Moving Company</h2>
                                    <img src="front-assets/images/house-sm.png" alt="house-sm" width="72" title="house-sm" />
                                    <h2>Become a DOD Service Providery</h2>
                                    <p>We specialize in getting movers, like YOU, approved with the government &amp; <strong>GROW</strong> your business.</p>
                                </div>
                            </div>
                            <div className="service-box">
                                <div className="service-box-blue">
                                    <h2>Boots on the Groundy</h2>
                                    <img src="front-assets/images/truckpn.png" alt="truckpn" width="72" title="house-sm" />
                                    <h2>Become a Certified ReloPro</h2>
                                    <p>Ensuring drivers, packers, helpers, third party &amp; claim pros exceed military regulations, receive standardized training, and make more $$$</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className="service-details">
                    <div className="container-fluid">
                        <div className="service-details-wrap">
                            <div className="service-details-left">
                                <h1>For Movers</h1>
                                <p>EXPAND your network to provide end-to-end relocation services worldwide that include packing, hauling, storage &amp; delivery of household goods shipments for your bookings &amp; other mover network partners.</p>
                                <div className="media">
                                    <div className="media-left">
                                        <img src="front-assets/images/stopwatch.png" alt="stopwatch" width="90" />
                                    </div>
                                    <div className="media-body">
                                        <p><strong>Quickest &amp; Easiest approval process</strong></p>
                                        <p>Put your unused warehouse space to work &amp; increase the diversification of your services by expanding your network.
                                        </p>
                                    </div>
                                </div>

                                <div className="media">
                                    <div className="media-left">
                                        <img src="front-assets/images/review.png" alt="review" width="90" />
                                    </div>
                                    <div className="media-body">
                                        <p><strong>We've Got Training Covered</strong></p>
                                        <p>Our centralized training hub makes sure you are trained for industry best practices. We standardized relocation training by providing resources, updated requirements, and ongoing training.
                                        </p>
                                    </div>
                                </div>

                                <div className="media">
                                    <div className="media-left">
                                        <img src="front-assets/images/increased-income.png" alt="increased-income" width="90" />
                                    </div>
                                    <div className="media-body">
                                        <p><strong>Approval = More Jobs</strong></p>
                                        <p>Once approved, you will be receiving offers - orders for origin &amp; destination services &amp; so much more from other approved Independent Movers Network service providers.
                                        </p>
                                    </div>
                                </div>

                                <div className="media">
                                    <div className="media-left">
                                        <img src="front-assets/images/money-bag.png" alt="money-bag" width="90" />
                                    </div>
                                    <div className="media-body">
                                        <p><strong>Large Opportunity Revenue $$</strong></p>
                                        <p>Our average approved member increases their revenue by $300,000 to $1,000,000 annually.
                                        </p>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <a href="#" className="btn" role="button" target="_self" onClick={()=>this.goToSection("section-1")}>
                                        <span className="">GET APPROVED NOW</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="relocation-revolution">
                    <img src="front-assets/images/relocation-bg.jpg" alt="" width="100%" className="img-responsive" />
                </section>

                <section className="boots-ground-details">
                    <div className="container-fluid">
                        <div className="service-details-wrap clearfix">
                            <div className="service-details-left"></div>
                            <div className="service-details-right">
                                <h1>Continue to GROW...</h1>
                                <p>Get direct access to relocation jobs from movers &amp; other businesses &amp; residential customers that include packing, loading, unloading, 3rd party &amp; even claim services.</p>
                                <div className="media">
                                    <div className="media-left">
                                        <img src="front-assets/images/statistics.png" alt="statistics" width="90" />
                                    </div>
                                    <div className="media-body">
                                        <p><strong>Make More $$$</strong></p>
                                        <p>Book more jobs with military, COD &amp; National Account moves. Automatically connect with over 400,000 relocations per year.
                                        </p>
                                    </div>
                                </div>

                                <div className="media">
                                    <div className="media-left">
                                        <img src="front-assets/images/settings-2.png" alt="settings-2" width="90" />
                                    </div>
                                    <div className="media-body">
                                        <p><strong>We Standardize Relocation Training</strong></p>
                                        <p>We train your team, plus get training help from other relocation professionals. Our centralized training platform ensures your staff is up to date on best industry practices.
                                        </p>
                                    </div>
                                </div>

                                <div className="media">
                                    <div className="media-left">
                                        <img src="front-assets/images/revenue.png" alt="revenue" width="90" />
                                    </div>
                                    <div className="media-body">
                                        <p><strong>Become a Short Term Storage Provider (SIT)</strong></p>
                                        <p>30,60,90 180 day storage services, including delivery to residence for Government, COD &amp; National Account clients.
                                        </p>
                                    </div>
                                </div>

                                <div className="media">
                                    <div className="media-left">
                                        <img src="front-assets/images/statistics.png" alt="statistics" width="90" />
                                    </div>
                                    <div className="media-body">
                                        <p><strong>Become a Long Term Storage Provider (NTS)</strong></p>
                                        <p>Non-Temporary Storage Providers (NTS) Pack, Pickup &amp; Store household goods for 1 to 10 years for Department of Defence.
                                        </p>
                                    </div>
                                </div>

                                <div className="media">
                                    <div className="media-left">
                                        <img src="front-assets/images/star.png" alt="star" width="90" />
                                    </div>
                                    <div className="media-body">
                                        <p><strong>Approval Stress-Free &amp; Easy</strong></p>
                                        <p>Once approved, you can start connecting with other approved movers worldwide to expand your business to new height
                                        </p>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <h1>Streamlined Process For Approval</h1>
                                    <div className="approval-process">
                                        <div>
                                            <img src="front-assets/images/file.png" alt="file" width="70" />
                                        </div>
                                        <div>
                                            <img src="front-assets/images/tick.png" alt="tick" width="70" />
                                        </div>
                                        <div>
                                            <img src="front-assets/images/result.png" alt="result" width="70" />
                                        </div>
                                    </div>
                                    <div className="approval-process">
                                        <p><strong>Apply</strong></p>
                                        <p><strong>Get Approved</strong></p>
                                        <p><strong>Start Training</strong></p>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <a href="#" className="btn" role="button" target="_self" onClick={()=>this.goToSection("section-1")}>
                                        <span className="">GET STARTED NOW</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="moverappz">
                    <img src="front-assets/images/moverappz-bg.png" alt="" width="100%" classNameimg-responsive />
                </section>

                <section className="simple-process text-center">
                    <div className="container">
                        <div className="simple-process-wrap">
                            <h1>Our Simple Process Leads Your Success</h1>
                            <p>Independent Movers Network’s unique technology and in-depth knowledge of the approval process ensures our client’s experience is STRESS-FREE & EASY. We are here to guide your company to success and build a LONG term profitable relationship. We will show your team how to become a successful service provider for the entire relocation industry.</p>
                            <div className="simple-process-list">
                                <div className="simple-process-list-wrap">
                                    <div className="list-content">
                                        <img src="front-assets/images/favorites.png" alt="favorites" width="100" />
                                        <h2>Subscribe &amp; Complete your Profile</h2>
                                        <p>Our advanced profile questionnaire ensures an expedited approval process by our team. Our easy to use communication tools keep our teams connected from day one.</p>
                                    </div>
                                </div>
                                <div className="simple-process-list-wrap">
                                    <div className="list-content">
                                        <img src="front-assets/images/result.png" alt="result" width="80" />
                                        <h2>Research &amp; Planning</h2>
                                        <p>Your team uploads and provides documents, pictures, and other needed items for your approval package submission.</p>
                                        <p>(Don’t worry,<br />
                                            we will guide you!)</p>
                                    </div>
                                </div>
                                <div className="simple-process-list-wrap">
                                    <div className="list-content">
                                        <img src="front-assets/images/file.png" alt="file" width="89" />
                                        <h2>Execution</h2>
                                        <p>Our team reviews your profile, documents, and all other important information submitted. We provide a custom plan of action to obtain approval ASAP for one or more programs. We direct your team on how to address any deficiencies to ensure expedited approval.</p>
                                    </div>
                                </div>
                                <div className="simple-process-list-wrap">
                                    <div className="list-content">
                                        <img src="front-assets/images/review-1.png" alt="review-1" width="100" />
                                        <h2>Final Review, Submission and Ongoing Assistance</h2>
                                        <p>We review and help complete your application package.</p>
                                        <p>Submit it for approval</p>
                                        <p>Assist with any questions from our team to ensure your APPROVAL.</p>
                                    </div>
                                </div>
                                <div className="simple-process-list-wrap">
                                    <div className="list-content">
                                        <img src="front-assets/images/tick.png" alt="tick" width="100" style={{ 'margin-bottom': '20px' }} />
                                        <h2>Approval, Ongoing Training and Marketing</h2>
                                        <p>We will continue to assist your team in ‘How to do business’ with the IMN Partners.<br />
                                            <strong>Includes,</strong> ongoing training for your team.<br />
                                            <strong>Plus,</strong> marketing to other approved service providers.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="become-member text-center">
                    <div className="container">
                        <a href="#" className="btn" role="button" target="_self" onClick={()=>this.goToSection("section-1")}>
                            <span className="">BECOME A MEMBER</span>
                        </a>
                        <p><strong>GROW</strong> your moving business with Independent Movers Network and <strong>EXPAND</strong> your world.</p>
                    </div>
                </section>

                <div className="subscription-wrap text-center" id="section-1">
                    <div className="subscription-overlay p-t-40 p-b-40">
                        <div className="container p-l-30 p-r-30">
                            <div className="row">
                                <div className="col-md-12 p-b-40 p-t-20">
                                    <h2 className="m-0 text-center common-title p-b-20 p-t-20 f-s-35 text-semi-bold">Subscription Plans</h2>
                                </div>

                                <div className="col-md-4 col-sm-6 col-md-offset-2">
                                    <div className="pricing text-center">
                                        <div className="pricing-header bg-gradient-green">
                                            <p>popular</p>
                                            <h5 className="pricing-title m-0">{package1.independent_mover_title}<span className="show f-s-15 text-capitalize">( {package1.trial_days_independent_mover} Days Free Trial )</span></h5>
                                            <div className="offer">
                                                <span>50%</span><br />
                                                <b>OFF</b>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <div className="pricing-price text-green p-t-20">
                                                ${package1.independent_mover_value} <span> / Monthly</span>
                                                {/* <p>All benefits of free membership</p>
                                                <p><span style={{ 'font-size': '48px', 'color': '#68b364' }}>+</span></p> 
                                                <hr className="m-t-20"/>   */}
                                            </div>
                                            <ul className="pricing-list">
                                                <li>Get free app for your Boots on the Grounds, Customers </li>
                                                <li>Easy As 1-2-3<br />
                                                    Signup &amp; Subscribe<br />
                                                    Answer Few Basic Questions &amp; Upload Logo (Don't worry if you don't have one, we can create one for you! <br />
                                                    Start Sharing your app in your sales process and with your customers &amp; crew.
                                                </li>
                                                <li>Search our database of : <br />
                                                    2,000+ Pro ReloHelpers
                                                    <br />
                                                    2,500+ Moving Companies.
                                                </li>
                                                <li>$$$ Money Saving Partner Discounts on Truck Rental, Packing Material, Hotels, Fuel, Moving Equipment, Bookkeeping, and many other products and services.</li>
                                                <li>Networking with fellow IMN members, movers, Helpers, claim repairs &amp; MORE</li>
                                                <li>Preview our New Tech for Movers,
                                                    Drivers, Customers, and Helpers</li>
                                                <li>Newsletter</li>
                                                <li>Revolutionize Relocation for your Customers &amp; 'Boots on the Ground' with your own mobile app. Your Customers &amp; Crews will it &amp; GROW your business!</li>
                                                <li>We assist you in getting APPROVED to work with other IMN partners</li>
                                                <li>On-Going training in all areas of "How to work with IMN partners", reduce claims plus so many other topics!</li>
                                                <li>Customized solutions to reduce your claims by 75% and increase your customer satisfaction scores and profits by 50% **</li>

                                            </ul>
                                        </div>
                                        <div className="pricing-footer p-t-20 p-b-20">
                                            <Link
                                                to={{
                                                    pathname: '/registration-imn',
                                                    state: {
                                                        seleted_subscription_id: package1.id
                                                    }
                                                }}
                                                className="pricing-footer-bg-green"
                                            >Select
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-6">
                                    <div className="pricing text-center">
                                        <div className="pricing-header bg-gradient-pink">
                                            <p>popular</p>
                                            <h5 className="pricing-title m-0">{package2.independent_mover_title}</h5>
                                            <div class="ribbon-wrapper">
                                                <div class="ribbon">Save 20% by Pre-paying</div>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <div className="pricing-price text-pink p-t-20">
                                                ${package2.independent_mover_value} <span> / Yearly </span>
                                                {/* <p>All benefits of free &amp; monthly membership</p>
                                                <p><span style={{ 'font-size': '48px', 'color': '#dd002f' }}>+</span></p>
                                                <hr className="m-t-20"/> */}
                                            </div>
                                            <ul className="pricing-list">
                                                <li>Get free app for your Boots on the Grounds, Customers </li>
                                                <li>Easy As 1-2-3<br />
                                                    Signup &amp; Subscribe<br />
                                                    Answer Few Basic Questions &amp; Upload Logo (Don't worry if you don't have one, we can create one for you! <br />
                                                    Start Sharing your app in your sales process and with your customers &amp; crew.
                                                </li>
                                                <li>Search our database of : <br />
                                                    2,000+ Pro ReloHelpers
                                                    <br />
                                                    2,500+ Moving Companies.
                                                </li>
                                                <li>$$$ Money Saving Partner Discounts on Truck Rental, Packing Material, Hotels, Fuel, Moving Equipment, Bookkeeping, and many other products and services.</li>
                                                <li>Networking with fellow IMN members, movers, Helpers, claim repairs &amp; MORE</li>
                                                <li>Preview our New Tech for Movers,
                                                    Drivers, Customers, and Helpers</li>
                                                <li>Revolutionize Relocation for your Customers &amp; 'Boots on the Ground' with your own mobile app. Your Customers &amp; Crews will it &amp; GROW your business!</li>

                                                <li>We assist you in getting APPROVED to work with other IMN partners</li>
                                                <li>On-Going training in all areas of "How to work with IMN partners", reduce claims plus so many other topics!</li>
                                                <li>Customized solutions to reduce your claims by 75% and increase your customer satisfaction scores and profits by 50% **</li>

                                                <li>You save $$ by prepaying. </li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer p-t-20 p-b-20">
                                            <Link
                                                to={{
                                                    pathname: '/registration-imn',
                                                    state: {
                                                        seleted_subscription_id: package2.id
                                                    }
                                                }}
                                                className="pricing-footer-bg-pink"
                                            >Select
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <section className="newsletter">
                    <div className="container">
                        <div className="newsletter-wrap clearfix">
                            <div className="newsletter-left text-center">
                                <h2>Sign Up For Our Newsletter</h2>
                                <p>Get great deals sent directly to your inbox!</p>
                            </div>
                            <div className="newsletter-right">
                                <ul className="list-unstyled">
                                    <li>
                                        <input type="text" placeholder="Name" />
                                    </li>
                                    <li>
                                        <input type="email" placeholder="Email" />
                                    </li>
                                    <li>
                                        <button type="submit" className="btn">Subscribe</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Content;