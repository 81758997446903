import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Breadcumb from '../externaljs/Breadcrumb';
import Storage from '../externaljs/Storage';
import PostDataWithParam from '../services/PostDataWithParam';
import { getQueryStringToObject } from '../externaljs/UrlHelpers';
export default class MoverAppzSubscription extends Component {

    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard', class: '' },
            { title: 'My MoverAppz Subscription', link: '', class: 'active' }
        ],
        appzSubscribtionDetails: {},
        initialPackages: [],
        subscriptionPackages: [],
        selectPackage: null,
        selectInitialMoverSizePackage: null,
        currentlyRunningPackage: null,
        isLoadedAllPackages: false,
        isSchduleProcess: false
    }
    componentDidMount() {
        this.getMoverAppzSubDetails();

    }
    isUserWantToUpgrade = () => {
        if (this.props.location && this.props.history.location.search) {
            let queryParams = getQueryStringToObject(this.props.history.location.search);
            if (queryParams.type && queryParams.type === "upgrade") return true;
            return false;
        } else {
            return false;
        }
    }
    getLoginUserData = () => {
        try {
            return JSON.parse(Storage.get('imnLoginData'))
        } catch (err) {
            return null
        }
    }

    getMoverAppzSubDetails = () => {
        PostDataWithParam('MoverAppzSubscriptionDetails', { user_id: this.getLoginUserData().id })
            .then((response) => {
                if (response.success === 1) {
                    let currentlyRunningPackage = null;
                    if (response.data.mover_appz_expiry_status === 0) {
                        currentlyRunningPackage = response.data.selected_package_info[0]
                    }
                    this.setState({
                        appzSubscribtionDetails: response.data,
                        currentlyRunningPackage,
                    }, () => {

                        this.getSubscriptionPackages();
                        window.$('[data-toggle="tooltip"]').tooltip({
                            // viewport: '.weekview-body',
                            placement: 'top',
                            html: true
                        });
                    })
                } else {
                }
            })
            .catch(err => {
            })

    }
    getSubscriptionPackages = () => {
        PostDataWithParam('MoverAppzSubscriptionlist')
            .then((response) => {
                if (response.success === 1) {
                    const { initialPackages, subscriptionPackages } = response.data.reduce((obj, packageInfo) => {
                        if (packageInfo.type === "1") {
                            obj.subscriptionPackages.push(packageInfo);
                        }
                        else if (packageInfo.type === "0") {
                            obj.initialPackages.push(packageInfo);
                        }
                        return obj
                    }, { initialPackages: [], subscriptionPackages: [] });
                    this.setState({
                        initialPackages,
                        subscriptionPackages,
                        isLoadedAllPackages: true
                    })
                } else {
                }
            })
            .catch(err => {
            })
    }
    isFirstMonth = () => {
        return this.state.appzSubscribtionDetails.is_moverappz_payment_count === 0
            ? true
            : false
    }

    goToCheckoutPage = () => {
        const { selectPackage, selectInitialMoverSizePackage } = this.state;
        if (!selectPackage) {
            window.swal({
                title: "Warning!",
                text: this.isFirstMonth() ? 'Please Select Activation & Customization Fee' : 'Please select any subscription package',
                className: "subscrip-info-swal"
            })
            return
        };
        let initialMoverSizeData = {}
        if (this.isFirstMonth()) {
            if (!selectInitialMoverSizePackage) {
                window.swal({
                    title: "Warning!",
                    text: 'Please Select Your Company Size',
                    className: "subscrip-info-swal"
                })
                return;
            }
            initialMoverSizeData.moverappz_id = selectInitialMoverSizePackage.id;
            initialMoverSizeData.customer_limit = selectInitialMoverSizePackage.customer_limit;
            initialMoverSizeData.pro_limit = selectInitialMoverSizePackage.pro_limit;

        }
        const { id, tittle: title, moverappz_amount, type } = selectPackage;
        this.props.history.push({
            pathname: "/independent-mover/dashboard/checkout",
            state: {
                id,
                title,
                value: moverappz_amount,
                type: 'moverAppz',
                initialMoverSizeData
            }
        })


    }
    selectPackageHandle = (packageInfo) => {
        if (this.isFirstMonth()) {
            if (packageInfo.type === "0") {
                this.setState({ selectPackage: packageInfo });
            } else {
                this.setState({ selectInitialMoverSizePackage: packageInfo });
            }
        } else {
            this.setState({ selectPackage: packageInfo });
        }
    }
    isActive = (id, isInitPackage = false) => {
        const { selectPackage, selectInitialMoverSizePackage } = this.state;
        if (this.isFirstMonth()) {
            if (isInitPackage) return !!selectPackage;
            return selectInitialMoverSizePackage && selectInitialMoverSizePackage.id === id;
        } else {
            return selectPackage && selectPackage.id === id;
        }


    }
    scheduleMoverAppzPayment = () => {
        const { selectPackage } = this.state;
        if (!selectPackage) return;
        this.setState({ isSchduleProcess: true })
        PostDataWithParam('ScheduleMoverAppzPayment', { moverappz_id: selectPackage.id })
            .then((response) => {
                if (response.success === 1) {
                    window.swal('Success!', response.msg, 'success');
                    setTimeout(() => {
                        this.props.history.push("/independent-mover/dashboard/moverAppz");
                    }, 2000)
                } else {
                }
            })
            .catch(err => {
            })
            .finally(() => {
                this.setState({ isSchduleProcess: false })
            })

    }
    isRunningPackage = (packageId) => {
        return this.state.currentlyRunningPackage && this.state.currentlyRunningPackage.id === packageId;
    }
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        {Breadcumb(this.state.breadcrumb)}
                        <div className="panel panel-flat">
                            <div className="panel-heading">
                                <h3 className="panel-title m-b-20">My MoverAppz Subscription</h3>
                                {this.isFirstMonth() && (
                                    <>
                                        <div className="row">
                                            <div className="col-md-7">
                                                <div className="alert alert-info m-b-30 money-gurantee-msg p-20">
                                                    <div className="media">
                                                        <div className="media-body">
                                                            <h4 className="text-grey-800"><strong>Easy as 1-2-3</strong></h4>
                                                            <h6 className="text-grey-800"><span className="circle-warning">1</span>&nbsp; Sign Up</h6>
                                                            <h6 className="text-grey-800"><span className="circle-warning">2</span>&nbsp; Upload Logo & Answer a few ?'s <a href="javascript:void(0);" data-toggle="tooltip" data-original-title="'Don't have a logo? no worries. We can design one for you (included in your Activation & Customization)'"><i className="icon-info22 text-info m-l-5"></i></a></h6>
                                                            <h6 className="text-grey-800"><span className="circle-warning">3</span>&nbsp; Start Sharing your App</h6>
                                                        </div>
                                                        <div className="media-right">
                                                            <img src="assets/images/money-back-guarantee.png" width="100" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <h2 className="text-primary">Benefits & Features- Communication, Accountability, Transparency, & Security delivered!</h2>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <ul className="moverappzFeaturesListing moverappzSubscriptionListing">
                                                    <li>Branded App for iOS & Google Play</li>
                                                    <li>Streamline Sales Process- Book More Orders</li>
                                                    <li>Unlimited Virtual & Video Pre-Move Surveys</li>
                                                    <li>Recieve Unlimited ReloOffer leads</li>
                                                    <li>24x7 Enhanced communication via message & video chat</li>
                                                    <li>Easy management of claims- File a Claim</li>
                                                    <li>'Who's My Crew?' ensures safety and security for Customer
                                        <a href="javascript:void(0);" data-toggle="tooltip" data-original-title="'Free for 30 days, requires MyReloPro® subscription for your boots on ground. Start adding your crew-team members after checkout for complete 24/7 visibility'"><i className="icon-info22 text-info m-l-5"></i></a></li>
                                                    <li>Reduce Claims by 90% with Inventory My Stuff & CYA feature
                                        <a href="javascript:void(0);" data-toggle="tooltip" data-original-title="'Free for 30 days, requires MyReloPro® subscription for your boots on ground. Start adding your crew-team members after checkout for complete 24/7 visibility'"><i className="icon-info22 text-info m-l-5"></i></a></li>
                                                    <li>'Where's My Stuff? Real time shipment tracking
                                        <a href="javascript:void(0);" data-toggle="tooltip" data-original-title="'Free for 30 days, requires MyReloPro® subscription for your boots on ground. Start adding your crew-team members after checkout for complete 24/7 visibility'"><i className="icon-info22 text-info m-l-5"></i></a></li>
                                                    <li>Search for Pro's (Helpers, 3rd Party, and Claims)</li>
                                                    <li>Customer Satisfaction Survey Automation</li>
                                                    <li>Build and manage your crew</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6">
                                                <ul className="moverappzFeaturesListing moverappzSubscriptionListing">
                                                    <li>Save $$ with MyReloMarketPlace for everyone. Discounts, buy moving equipment, supplies, truck rentals, and so much more!</li>
                                                    <li>Create & manage profile</li>
                                                    <li>Post an offer</li>
                                                    <li>Make more money</li>
                                                    <li>Import ClearStar Background check</li>
                                                    <li>GetMilitaryApproved Training</li>
                                                    <li>ReloPro<sup>&#174;</sup> Training Badge</li>
                                                    <li>Manage Schedule & calendar</li>
                                                    <li>DoD PreCheck<sup>&#174;</sup></li>
                                                    <li>View & Accept Orders</li>
                                                    <li>Get paid instantly & get a tip</li>
                                                    <li>Marketing to movers, insurance companies & others</li>
                                                </ul>
                                            </div>
                                        </div>

                                        {this.state.initialPackages.length > 0
                                            ? (<>
                                                <h4 className="text-primary">Activation & Customization (One-Time Fee)</h4>

                                                {this.state.initialPackages.map((packageInfo, index) => (
                                                    <div className="row" key={packageInfo.id}>

                                                        <div className={`columns base-package ${this.isActive(packageInfo.id, true) && 'active'}`}>
                                                            <div className="icon-div">
                                                                <i className="icon-check"></i>
                                                            </div>
                                                            <ul className="price">
                                                                <li className="header text-capitalize f-s-18">{packageInfo.tittle}</li>
                                                                <li className="grey p-b-0"><p className="m-0"><b className="f-s-24 text-danger"><strike className="f-s-14 m-r-5">${packageInfo.original_moverappz_amount}</strike>${packageInfo.moverappz_amount}</b> <br /><span className="text-blue-800 f-s-14">(Special Discounted Price)</span></p></li>
                                                                <li className="grey f-s-12 p-b-0">- Premium Live Chat Support</li>
                                                                <li className="grey f-s-12 p-b-0">- Logo Design (as needed)</li>
                                                                <li className="grey f-s-12 p-b-0">- Unlimited Training</li>
                                                                <li className="grey f-s-12">- Easy to use , Copy & Paste Links for Marketing & Sales</li>
                                                                <li><button className="btn btn-primary btn-lg" onClick={() => this.selectPackageHandle(packageInfo)}>Add To Cart</button></li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                ))
                                                }
                                            </>
                                            ) : null
                                        }
                                        <div className="subscrip-msg">
                                            <span className="ribbon ribbon-custom">1st Month Free!</span>
                                            <p>Please Select Your Company Size</p>
                                        </div>
                                    </>
                                )}
                                <div className="price-wrapper m-t-30">
                                    <h4 className="text-primary">Subscription Packages</h4>
                                    <div className="row">
                                        {this.state.subscriptionPackages.map((packageInfo, index) => (
                                            <div className="col-md-4">
                                                <div className={`m-b-20 columns ${this.isActive(packageInfo.id) && 'active'}`}>
                                                    <div className="icon-div">
                                                        <i className="icon-check"></i>
                                                    </div>
                                                    <ul className="price">
                                                        <li className="header text-capitalize f-s-15">{packageInfo.tittle}</li>
                                                        <li><p className="m-t-5">
                                                            <b className="f-s-18 text-danger">
                                                                <strike className="f-s-14 m-r-5">${packageInfo.original_moverappz_amount}</strike>
                                                                ${packageInfo.moverappz_amount}</b> <br />
                                                            <span className="f-s-14">{`(${packageInfo.pro_limit} MyReloPro® App Boots On the Ground Users)`}</span><br />
                                                            <span>{packageInfo.customer_limit ? `(${packageInfo.customer_limit} Customers/ Month)` : `(Unlimited Customers)`}</span>
                                                        </p>
                                                        </li>
                                                        <li>
                                                            <button
                                                                className="btn btn-primary btn-lg"
                                                                onClick={() => this.selectPackageHandle(packageInfo)}
                                                                disabled={this.isRunningPackage(packageInfo.id)}
                                                            >
                                                                {this.isRunningPackage(packageInfo.id) ? `Selected` : `Select`}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                        )}
                                    </div>
                                </div>
                                {this.state.isLoadedAllPackages && <div className="text-right">
                                    {this.isUserWantToUpgrade()
                                        && this.state.appzSubscribtionDetails.mover_appz_expiry_status === 0
                                        ? <button
                                            className="btn btn-primary btn-lg m-t-10"
                                            disabled={this.state.isSchduleProcess}
                                            onClick={this.scheduleMoverAppzPayment}>
                                            {this.state.isSchduleProcess ? `Processing...` : `Submit`}
                                        </button>
                                        : <button className="btn btn-primary btn-lg m-t-10" onClick={this.goToCheckoutPage}>NEXT <i className="icon-arrow-right6"></i></button>
                                    }
                                </div>}

                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}