import React, { Component, Fragment } from 'react'
import { Link, Redirect } from 'react-router-dom';
import PostDataWithParam from '../../services/PostDataWithParam';
import GetData from '../../services/GetData';

class SignUp extends Component {
    state = {
        fields: {
            contact_no: '',
            email: '',
            organization_name: '',
            password: '',
            profile_id: 2,
            card_holder_name: '',
            card_no: '',
            expiry_date: "",
            expiry_month: '',
            expiry_year: '',
            cvv_no: '',
            amount: '',
            company_contact_no: '',
            first_name: '',
            last_name: '',
            subscription_id: 1,
            seleted_subscription_id: '',
            website_link: '',
            position: '',
            support_email: 'help@myrelo.us',
            url: 'myrelo.us/mover',
            is_mover_appz: 0,
            is_independent_mover: 1
        },
        error: {
            confPasswordErr: '',
            dobErr: '',
            urlErr: '',
            card_holder_name_err: '',
            card_no_err: '',
            expiry_err: '',
            cvv_err: ''
        },
        processing: false,
        regStatus: false,
        regErr: '',
        moverappz_amount: ''
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (!!this.props.location.search) {
            let queryParams = new URLSearchParams(this.props.location.search);
            if (queryParams !== '') {
                let urlToken = queryParams.get("is_mover_appz");
                if (!!urlToken) {
                    let fields = Object.assign({}, this.state.fields);
                    fields.is_mover_appz = 1;
                    this.setState({ fields: fields }, () => {
                        this.getMoverappzAmount();
                        
                    });
                }

            }
        }
        if (this.props.location.state && this.props.location.state.seleted_subscription_id) {
            let fields = Object.assign({}, this.state.fields);
            fields.seleted_subscription_id = this.props.location.state.seleted_subscription_id;
            this.setState({ fields: fields })

        }

    }

    getMoverappzAmount = () => {
        // this.setState({ loading: true }, () => {
        GetData('GetMoverAppz')
            .then(response => {
                if (response.success == 1) {
                    this.setState({ moverappz_amount: response.data[0].moverappz_amount })
                }
                else {
                    this.setState({ moverappz_amount: '' })
                }
            })
            .catch(err => {
                window.swal('Failed!', 'Oops! Something went wrong.', 'error')
                this.setState({ moverappz_amount: '' })
            })
        // })
    }


    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        if (e.target.name == 'company_contact_no' || e.target.name == 'contact_no') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                fields[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }

            else {
                fields[e.target.name] = cleaned;
            }
        }

        this.setState({ fields: fields });
    }

    initAutocomplete = (e) => {
        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: ['geocode'] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let fields = this.state.fields;
            fields.latitude = place.geometry.location.lat();
            fields.longitude = place.geometry.location.lng();
            fields.address = place.formatted_address;
            this.setState({ fields: fields });

        })
    }
    isValidCardInfo = () => {
        let fields = this.state.fields;
        let error = this.state.error;
        let numberReg = /^(?=.*\d)[\d ]+$/;
        let cardExp = /^\s*(0?[1-9]|1[0-2])\/(\d\d|\d{4})\s*$/;
        let date = new Date();

        if (fields.card_holder_name == "") {
            error.card_holder_name_err = "Enter card holder name";


        } else if (!numberReg.test(fields.card_no)) {
            error.card_no_err = "Enter valid card no";
        } else if (fields.card_no.length != 19) {
            error.card_no_err = "Enter valid card no";
            return false;
        }
        else if (!cardExp.test(fields.expiry_date)) {
            error.expiry_err = "Enter valid format of month and year (MM/YYYY)";
        }
        else if (fields.cvv_no.length != 3) {
            error.cvv_err = "Enter valid cvv";
        } else {
            let expiry = fields.expiry_date.split("/");
            fields.expiry_month = expiry[0];
            fields.expiry_year = expiry[1];
            this.setState({ fields });
            return true;
        }
        this.setState({ error })
        return false;

    }
    handleAddMmc = (e) => {
        e.preventDefault();
        let flag = 1;

        let error = this.state.error;
        if (this.state.fields.website_link != '') {
            var inputurl = this.state.fields.website_link;

            var res = inputurl.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm);
            if (res == null) {
                error.urlErr = 'Please Enter a Valid Url.';
                flag = 0;
                this.setState({ error: error });
            }
        }
        else if (this.state.fields.password !== this.state.fields.confirm_password) {
            error.urlErr = '';
            error.confPasswordErr = 'Password & Confirm password does not match.';
            flag = 0;
            this.setState({ error: error });
        }
        else if (!this.isValidCardInfo()) {
            flag = 0;
        }
        else {
            error.urlErr = '';
            error.confPasswordErr = '';
            error.card_holder_name_err = '';
            error.card_no_err = '';
            error.expiry_err = '';
            error.cvv_err = ''
            this.setState({ error: error });
            this.setState({ processing: true }, () => {
                PostDataWithParam('UserRegistration', this.state.fields)
                    .then((response) => {
                        if (response.success == 1) {
                            this.setState({ regErr: response.msg, regStatus: true });
                        } else {
                            this.setState({ regErr: response.msg, processing: false, regStatus: false });
                        }
                    })
                    .catch(err => {
                        this.setState({ regErr: 'Oops! Something went wrong.', processing: false, regStatus: false });
                    })
            })

            // else {
            //     this.setState({ processing: true }, () => {
            //         PostDataWithParam('DuplicateEmailCheck', { email_id: this.state.fields.email })
            //             .then((response) => {
            //                 if (response.success == 1) {
            //                     this.setState({ processing: false }, () => {
            //                         this.props.history.push({
            //                             pathname: '/registration-imn-step-two',
            //                             state: this.state.fields
            //                         })
            //                     });
            //                 } else {
            //                     this.setState({ regErr: response.msg, processing: false, regStatus: false });
            //                 }
            //             })
            //             .catch(err => {
            //                 this.setState({ regErr: 'Oops! Something went wrong.', processing: false, regStatus: false });
            //             })
            //     });
            // }
        }
    }

    renderSubmitBtn = () => {
        if (this.state.processing) {
            return <input type="button" className="btn btn-primary" value="Processing..." disabled={true} />
        } else {
            if (this.state.fields.subscription_id == 1)
                return <button type="submit" className="btn btn-primary">REGISTER</button>
            else
                return <button type="submit" className="btn btn-primary">CONTINUE</button>
        }
    }

    showAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-danger"><i className="icon-cross position-left"></i>
                    {this.state.regErr}
                </div>
            )
        }
    }
    changeFormat = (e) => {

        let fields = this.state.fields;
        let value = e.target.value;
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
        var matches = v.match(/\d{4,16}/g);
        var match = matches && matches[0] || '';
        var parts = [];

        for (let i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4))
        }

        if (parts.length) {
            fields.card_no = parts.join(' ')
        } else {
            fields.card_no = value;
        }

        this.setState({ fields: fields });
    }
    render() {
        if (this.state.regStatus) {
            return <Redirect to={{
                pathname: '/activation-message/signup',
                state: { regMsg: this.state.regErr },
                search: this.props.location.search
            }} />
        }

        return (
            <div className={this.state.fields.is_mover_appz === 1 ? "login-page-register moverappz-login register-page" : "login-page-register register-page"}>
                <div className="login-register-box">
                    <div className="text-center login-box-header">
                        <div className="m-b-30">
                            <img src="front-assets/images/logo.png" alt="site-logo" width="400" />

                        </div>
                        <div className="btn-group">
                            <Link to={"/login" + this.props.location.search} className="btn border-slate text-slate-800 btn-flat text-uppercase ">Sign in</Link>
                            <Link to={"/registration-imn" + this.props.location.search} className="btn border-slate text-slate-800 btn-flat text-uppercase active">CREATE ACCOUNT
                                <p className="m-0 f-s-13">(FREE TRIAL)</p>
                            </Link>
                        </div>
                        <br />
                        {this.showAlert(this.state.regErr)}
                    </div>
                    {/* { (this.state.fields.is_mover_appz && this.state.fields.is_mover_appz === 1)
                    ?
                    <div className="row">
                        <div className="col-md-12">
                            <div className="alert alert-info m-b-0 text-center">
                                <p>Hey! You're signing up with MyRelo with a special add-on!</p>
                                <p>Try out <strong><i>MoverAppz.com</i></strong> for free for <strong><i>next 3 days</i></strong>, after that pay <strong><i>${this.state.moverappz_amount}/per month</i></strong> if you wish to continue 😊</p>
                            </div>
                        </div>
                    </div>
                    :
                    ''
                    } */}
                    <form action="#" method="POST" onSubmit={this.handleAddMmc} >
                        <div className="login-box-body m-t-30">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">First Name *</label>
                                        <input type="text" name="first_name" value={this.state.fields.first_name} className="form-control" placeholder="First Name *" onChange={this.changeValue} required />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">Last Name</label>
                                        <input type="text" name="last_name" value={this.state.fields.last_name} className="form-control" placeholder="Last Name *" onChange={this.changeValue} required />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">Position</label>
                                        <input type="text" name="position" value={this.state.fields.position} className="form-control" placeholder="Position *" onChange={this.changeValue} required />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">Cell Phone Number</label>
                                        <input type="text" name="company_contact_no" value={this.state.fields.company_contact_no} className="form-control" placeholder="Cell Phone Number *" onChange={this.changeValue} required maxLength="10" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">Company Name</label>
                                        <input type="text" name="organization_name" value={this.state.fields.organization_name} className="form-control" placeholder="Company Name *" onChange={this.changeValue} required />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">Main Phone Number</label>
                                        <input type="text" name="contact_no" value={this.state.fields.contact_no} className="form-control" placeholder="Main Phone Number *" onChange={this.changeValue} required maxLength="10" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">Email Address</label>
                                        <input type="email" name="email" value={this.state.fields.email} className="form-control" placeholder="Email *" onChange={this.changeValue} required />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">Password</label>
                                        <input type="password" name="password" value={this.state.fields.password} className="form-control" placeholder="Password *" onChange={this.changeValue} required />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group form-group form-group-material">
                                        <label className="control-label">Password Confirmation</label>
                                        <input type="password" name="confirm_password" value={this.state.fields.confirm_password} className="form-control" placeholder="Confirm Password *" onChange={this.changeValue} required />
                                        <p className="text-danger help-block">{this.state.error.confPasswordErr}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12  alert alert-info  m-b-0">
                                    Please enter your card info to register. Don't worry, your card won't be charged until your trial ends 😊
                                </div>
                            </div>
                            {<Fragment>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group form-group-material">
                                            <label className="control-label">Card Holder Name</label>
                                            <input className="form-control" type="text" placeholder="Enter Card Holder Name" name="card_holder_name" onChange={this.changeValue} value={this.state.fields.card_holder_name} />
                                            <p className="text-danger">{this.state.error.card_holder_name_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-group-material">
                                            <label className="control-label">Card No</label>
                                            <input className="form-control" type="text" placeholder="Enter 16 digit card no" name="card_no" onChange={this.changeFormat} value={this.state.fields.card_no} />
                                            <p className="text-danger">{this.state.error.card_no_err}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group form-group-material">
                                            <label className="control-label">Expiration Date (MM/YYYY)</label>
                                            <input className="form-control" type="text" placeholder="MM/YYYY" name="expiry_date" onChange={this.changeValue} value={this.state.fields.expiry_date} />
                                            <p className="text-danger">{this.state.error.expiry_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-group-material">
                                            <label className="control-label">CVV</label>
                                            <input className="form-control" type="password" placeholder="Enter CVV" name="cvv_no" onChange={this.changeValue} maxlength="3" />
                                            <p className="text-danger">{this.state.error.cvv_err}</p>
                                            <p className="help-block"><i className="icon-info22"></i> CVV : A 3 digit code on the card, you can see it on back side of your card.</p>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                            }
                            <div className="form-group m-t-40 m-b-10 clearfix text-center">
                                {this.renderSubmitBtn()}
                            </div>
                            <p className="text-center text-grey-400 f-s-12">By clicking <strong>REGISTER</strong> you agree to our <a href="#" className="text-underline f-s-15">terms and conditions</a></p>
                        </div>
                        <hr className="m-t-40 m-b-40" />
                        <div className="login-box-footer text-center">
                            <span className="text-grey-400"> Already have an account?</span>
                            <Link to="/login" className="link-btn">Sign In</Link>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default SignUp;