import { authMiddleware } from '../externaljs/authHelpers';
import Storage from '../externaljs/Storage';
import Urls from '../externaljs/Urls';

export default function PostDataWithParamOptional(url,params){
    //for live server
    // const API = "https://myrelo.us/relotechApi/public/Api/";
    // const API = "http://localhost:121/Relotech/relotechApi/public/Api/";

    let headers = new Headers();
    headers.append('Content-type','application/json');      
    headers.append('token',Storage.get('imnLoginToken'));
  

    return  fetch(Urls.api+url,{
            method:'GET',
            headers:headers,
            // body:JSON.stringify(params)
        })
        .then(authMiddleware)
}