export const getQueryStringToObject = (search) => {
    let queryParams = new URLSearchParams(search);
    let obj = {};
    for (let [key, value] of queryParams.entries()) {
        obj[key] = value;
    }
    return obj;
}
export const copyHTML = (html) => {
    window.$("body").append('<div id="copyHTML" ></div>');
    window.$("#copyHTML").html(html);
    window.$("#copyHTML").css({ backgroundColor: "white" });
    let elm = document.getElementById("copyHTML");
    if (document.body.createTextRange) {
        var range = document.body.createTextRange();
        range.moveToElementText(elm);
        range.select();
        document.execCommand("Copy");
    }
    else if (window.getSelection) {
        // other browsers
        var selection = window.getSelection();
        var range = document.createRange();
        range.selectNodeContents(elm);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("Copy");
    }
    window.$("#copyHTML").remove();
}