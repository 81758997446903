import { NotificationManager } from 'react-notifications';
import { AgoraAppID } from "./config";
import AgoraRTC from "./AgoraEnhancer";
import { addView, removeView } from "./helpers";

export default class RTCClient {
    constructor() {
        this._client = null;
        this._joined = false;
        this._published = false;
        this._localStream = null;
        this._remoteStreams = [];
        this._params = {};
        this.channel = null;
        this.user = {
            userID: null,
            token: null
        };
    }
    getCameras = () => {
        return this._client.getCameras()
    }

    getMicrophones = () => {
        return this._client.getRecordingDevices()
    }
    handleNotAllowedError = (error) => {
        if (error.msg === 'NotAllowedError') {
            this._client.leave();
            NotificationManager.warning("Please grant access to your camera or audio resource", "Permission denied");
        }
    }
    join({ channel = null, userID = null, token = null, listenerCb = (para) => { } }) {
        return new Promise(async (resolve, reject) => {
            try {
                this.channel = channel;
                this.user.userID = userID;
                this.user.token = token;
                // if (!this._client) throw new Error("Please Join First!");
                this._client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" })
                this._remoteStreamsListener(listenerCb);

                let microphones = await this.getMicrophones();
                if (!microphones || microphones.length === 0) {
                    NotificationManager.error("Microphones is not available", "Microphones");
                    throw new Error("Microphones is not available")
                }

                let cameras = await this.getCameras();
                if (!cameras || cameras.length === 0) {
                    NotificationManager.error("Webcam is not available", "Webcam");
                    throw new Error("Webcam is not available")
                }
                // let cameraPermission = await navigator.permissions.query({ name: "camera" });
                // cameraPermission.state === "denied"
                console.log('%c cameras', 'color:yellow;font-size:20px', cameras, "microphones", microphones, "cameraPermission")
                if (channel) {
                    await this._client.init(AgoraAppID);
                    let uid = await this._client.join(
                        token ? token : null,
                        channel,
                        userID ? +userID : null
                    );
                    this._params.uid = uid;
                    this._joined = true;
                    this._localStream = AgoraRTC.createStream({
                        streamID: uid,
                        video: true,
                        audio: true,
                        screen: false
                    });

                    // stream.setVideoProfile('480p_4')

                    // Initalize the stream
                    await this._localStream.init();

                    // Publish the stream to the channel.
                    await this._client.publish(this._localStream);
                    // this._localStream.play("local_stream", { fit: "cover" });
                    this._published = true;
                    resolve({ cameras, microphones });
                }
            } catch (error) {
                this.handleNotAllowedError(error)
                console.log(error);
                reject();
            }
        });
    }

    playLocalStream = () => {
        this._localStream.play("local_stream", { fit: "cover" });
    }



    leave() {
        if (!this._client) {
            console.log("Please Join First!");
            return;
        }
        if (!this._joined) {
            console.log("You are not in channel");
            return;
        }
        if (this._localStream) {
            // Closes the local stream. This de-allocates the resources and turns off the camera light
            this._localStream.close();
            // unpublish the stream from the client
            this._client.unpublish(this._localStream);
            this._localStream.stop();
        }
        // leave channel
        this._client.leave(
            () => {

                while (this._remoteStreams.length > 0) {
                    const stream = this._remoteStreams.shift();
                    const id = stream.getId();
                    stream.stop();
                    // removeView(id);
                }
                this._localStream = null;
                this._remoteStreams = [];
                // this._client = null;
                // console.log("client leaves channel success");
                this._published = false;
                this._joined = false;
                console.log("leave success");
            },
            (err) => {
                console.log("channel leave failed");
                // Toast.error("leave success");
                // console.error(err);
            }
        );
    }

    _remoteStreamsListener = (listenerCb) => {
        this._client.on("stream-added", (evt) => {

            const remoteStream = evt.stream;
            const id = remoteStream.getId();
            // NotificationManager.success("stream-added uid: " + id);
            if (id !== this._params.uid) {
                listenerCb({ type: "stream-added", data: null })
                this._client.subscribe(remoteStream, (err) => {
                    // console.log("stream subscribe failed", err);
                });
            }
            // console.log('stream-added remote-uid: ', id);
        });

        // Occurs when a user subscribes to a remote stream.
        this._client.on("stream-subscribed", (evt) => {
            const remoteStream = evt.stream;
            const id = remoteStream.getId();
            this._remoteStreams.push(remoteStream);
            addView(id, "this._showProfile");
            remoteStream.play("remote_video_" + id, { fit: "cover" });
            listenerCb({ type: "stream-subscribed", data: null })
            // Toast.info("stream-subscribed remote-uid: " + id);
            // console.log('stream-subscribed remote-uid: ', id);
        });

        // Occurs when the remote stream is removed; for example, a peer user calls Client.unpublish.
        const removeRemote = (evt) => {

            const remoteStream = evt.stream;
            if (remoteStream) {

                const id = remoteStream.getId();
                // NotificationManager.info("stream-removed uid: " + id);
                remoteStream.stop();
                this._remoteStreams = this._remoteStreams.filter((stream) => {
                    return stream.getId() !== id;
                });
                removeView(id);
                listenerCb({ type: "stream-removed", data: null })
                console.log("stream-removed remote-uid: ", id);
            }
        }
        this._client.on("stream-removed", removeRemote);
        this._client.on("peer-leave", removeRemote);

    };



    muteAudio = () => {
        if (this._localStream) this._localStream.muteAudio();
    };
    unmuteAudio = () => {
        if (this._localStream) this._localStream.unmuteAudio();
    };
}
