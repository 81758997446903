import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import DateFormat from '../externaljs/DateFormat';
import Breadcumb from '../externaljs/Breadcrumb';
import Storage from '../externaljs/Storage';
import PostDataWithParam from '../services/PostDataWithParam';

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

class MyProfile extends Component {
    state = {
        user_detail: [],
        UpdateSuc: '',
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'My User Profile', link: '', class: 'active' }
        ],
        proccesing:false,
        src: null,
        crop: { 
            aspect: 1 / 1,
            height: 200,
            width: 200,
            x: 0,
            y: 0
        },
        croppedImageUrl:'',
        loading:false
    }

    componentDidMount() {
        this.getUserInfo();
        if (typeof this.props.location.state !== 'undefined') {
            this.setState({ UpdateSuc: this.props.location.state.UpdateErr });
        }
    }

    componentWillUnmount() {
        this.setState({ UpdateSuc: '' });
    }

    formatSSN = (ssn) => {
        if (typeof ssn !== 'undefined' && ssn != null) {
            let ssn_new = ssn.replace(/^.{6}/g, '***-**')
            return ssn_new
        } else {
            return '';
        }
    }

    getUserInfo = () => {
        this.setState({ loading:true },()=>{
            PostDataWithParam('GetUserInfo', { staff_user_id: (JSON.parse(Storage.get('imnLoginData'))).staff_user_id })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ user_detail: response.data,loading:false });
                } else {
                    this.setState({ user_detail: [],loading:false });
                }
            })
            .catch(err => {
                this.setState({ user_detail: [],loading:false });
            })
        });
        
    }

    phoneformat = (phval) => {
        var cleaned = ('' + phval).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

        if (match) {
            return match[1] + ' ' + match[2] + ' ' + match[3];
        }
    }

    handleshowStatus = (status) => {
        if (status === 1) {
            return (
                <div>
                    <label className="text-bold m-b-0">Social Media</label>
                    <p className="m-b-20">Yes</p>
                </div>
            )
        } else {
            return (
                ''
            )
        }
    }

    showSuccessAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-success"><i className="icon-check2 position-left"></i>
                    {this.state.UpdateSuc}
                </div>
            )
        }
    }


    handleFileCheck = (status) => {
        if (status !== '') {
            return (
                <div className="thumb custom-logo" style={{ background: "url(" + this.state.user_detail.profile_image + ") no-repeat" }}>
                    <label className="file-label profile-img-uplaod">
                        <input className="file-input" name="profile_image" type="file" onChange={this.onSelectFile} />
                        <span className="text-white">Change Photo</span>
                    </label>
                </div>
            )
        } else {
            return (
                <div className="thumb custom-logo" style={{ background: "url('assets/images/default_avatar.jpg') no-repeat" }}>
                    <label className="file-label profile-img-uplaod">
                        <input className="file-input" name="profile_image" type="file" onChange={this.onSelectFile} />
                        <span className="text-white">Change Photo</span>
                    </label>
                </div>
            )
        }
    }


    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
          window.$("#imageModal").modal('show')
          const reader = new FileReader();
          reader.addEventListener("load", () =>
            this.setState({ src: reader.result })
          );
          reader.readAsDataURL(e.target.files[0]);
        }
      };
    
      onImageLoaded = (image, crop) => {
        this.imageRef = image;
      };
    
      onCropComplete = crop => {
        this.makeClientCrop(crop);
      };
    
      onCropChange = crop => {
        this.setState({ crop });
      };
    
      async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            "newFile.jpeg"
          );
          this.setState({ croppedImageUrl });
        }
      }
    
      getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
    
        return new Promise((resolve, reject) => {
            let dataURL = canvas.toDataURL("image/jpeg");
            resolve(dataURL);
        });
      }

      handleUpdateProfilePicture = () => {
        if(this.state.croppedImageUrl!=''){
            this.setState({proccesing:true},()=>{
                PostDataWithParam('UpdateCroppedProfilePictureForMovers',{profile_image:this.state.croppedImageUrl,staff_user_id:(JSON.parse(Storage.get('imnLoginData'))).staff_user_id})
                .then((response) => {
                    if (response.success === 1) {
                        window.swal('Success!', "Profile picture updated successfully", 'success')
                        window.$("#imageModal").modal('hide')                        
                        this.setState({ proccesing:false}); 
                        this.getUserInfo();
                    } else {
                        window.swal('Error!', "Profile picture not updated", 'error')
                        this.setState({proccesing:false}); 
                    }
                })
                .catch((err)=>{
                    window.swal('Error!', 'Something went wrong.', 'Error')
                    this.setState({ proccesing:false});
                })
            })            
        } 
      }

    render() {
        return (
            <div className="content-container">
                <div className="row">
                    <div className="col-md-12">
                        {Breadcumb(this.state.breadcrumb)}
                        {
                            this.state.loading
                            &&
                            <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                        }
                        {
                            !this.state.loading
                            &&
                        <React.Fragment>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="thumbnail only-myprofile">
                                    {this.handleFileCheck(this.state.user_detail.profile_image)}
                                    
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="panel panel-flat profile-page">

                                    <div className="panel-heading clearfix">
                                        <div className="heading-elements">
                                            <Link to='/independent-mover/dashboard/edit-my-profile' className="btn btn-success btn-xs"><i className="icon-pencil"></i> Edit Profile</Link>

                                        </div>
                                        <h2 className="pull-left text-success m-0 text-medium">{this.state.user_detail.salutation} {this.state.user_detail.first_name} {this.state.user_detail.last_name}</h2>
                                    </div>
                                    <div className="panel-body">

                                        {this.showSuccessAlert(this.state.UpdateSuc)}
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="text-bold m-b-0">Primary Work Email Address:</label>
                                                            <p className="m-b-20">{this.state.user_detail.email || "---"}</p>
                                                        </div>
                                                        
                                                            <div className="form-group">
                                                                <label className="text-bold m-b-0">Work Phone Number:</label>
                                                                <p className="m-b-20">{this.phoneformat(this.state.user_detail.work_contact_no) || "---"}</p>
                                                            </div>
                                                        
                                                            <div>
                                                                <label className="text-bold m-b-0">Home Address:</label>
                                                                <p className="m-b-20">{this.state.user_detail.address || "---"}</p>
                                                            </div>
                                                        

                                                    </div>

                                                    <div className="col-md-4">
                                                       
                                                            <div className="form-group">
                                                                <label className="text-bold m-b-0">Recovery Email Address</label>
                                                                <p className="m-b-20">{this.state.user_detail.alternate_email || "---"}</p>
                                                            </div>
                                                        
                                                            <div>
                                                                <label className="text-bold m-b-0">Home Phone Number:</label>
                                                                <p className="m-b-20">{this.phoneformat(this.state.user_detail.home_contact_no) || "---"}</p>
                                                            </div>
                                                        
                                                            <div className="form-group">
                                                                <label className="text-bold m-b-0">Date of Birth :</label>
                                                                <p className="m-b-20">
                                                                    {(this.state.user_detail.dob != null) ? DateFormat.getFormattedDateTime(this.state.user_detail.dob, 'm/d/Y') : "---"}
                                                                </p>
                                                            </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className="text-bold m-b-0">Mobile Phone Number:</label>
                                                                <p className="m-b-20">{this.phoneformat(this.state.user_detail.contact_no) || "---"}</p>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="text-bold m-b-0">Title:</label>
                                                                <p className="m-b-20">{this.state.user_detail.title || "---"}</p>
                                                            </div>
                                                            {/* <div className="form-group">
                                                                <label className="text-bold m-b-0">Social Security Number:</label>
                                                                <p className="m-b-20"> {this.formatSSN(this.state.user_detail.social_security_number) || "---"}</p>
                                                            </div> */}
                                                            </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="modal fade" id="imageModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                    <h4 className="modal-title" id="myModalLabel">Profile Picture</h4>
                                                </div>
                                                <div className="modal-body">
                                                    {this.state.src && (
                                                        <ReactCrop
                                                            src={this.state.src}
                                                            crop={this.state.crop}
                                                            onImageLoaded={this.onImageLoaded}
                                                            onComplete={this.onCropComplete}
                                                            onChange={this.onCropChange}
                                                        />
                                                    )}
                                                    
                                                </div>
                                                <div className="modal-footer">
                                                    {
                                                        this.state.proccesing ?
                                                            <button type="button" className="btn btn-primary" disabled={true}>Proccesing...</button> :
                                                            <button type="button" className="btn btn-primary" onClick={this.handleUpdateProfilePicture}>Uplaod</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        </React.Fragment>    
                        }                       
                    
                    </div>
                </div>
            </div>
        );
    }
}


export default MyProfile;