import React, { Component } from 'react';
import PostDataWithParam from '../services/PostDataWithParam';
import { Redirect,Link } from 'react-router-dom';
import GetData from '../services/GetData';
import {FormatSSN} from '../externaljs/FormatSSN';
import Breadcumb from '../externaljs/Breadcrumb';

class EditStaffNew extends Component {
    state = {
        fields: {
            first_name: '',
            last_name: '',
            email: '',
            contact_no: '',
            alternate_contact_no: '',
            gender: '',
            dob: '',
            ssn: '',
            dept_id:[],
            address:'',
            latitude:'',
            longitude:'',
            staff_id:'',
            phone_no:'',
            work_contact_no:'',
            home_contact_no: '',
            alternate_email:''
        },
        error: {
            first_name_err: '',
            last_name_err: '',
            email_err: '',
            contact_no_err: '',
            alternate_contact_no_err:'',
            gender_err: '',
            dob_err: '',
            ssn_err: '',
            dept_id_err: '',
            address_err:'',
            alternate_email_err:'',
            work_contact_no_err:'',
            home_contact_no_err: '',
            custom_profile_id_err:''
        },
        addStaffStatus: false,
        staffErr:'',
        staff_detail:[],
        departments:[],
        dept:[],
        deptSelected:[],
        ssnClick:false,
        viewSsn:true,
        profileList: [],
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Staff', link: '/independent-mover/dashboard/staff', class: '' },
            { title: 'Edit Staff', link: '', class: 'active' }
        ]
        
    }

    componentDidMount(){
        this.ViewStaffNew();
        window.$('.input-group.date').daterangepicker({
          showDropdowns: true,
            autoclose: true,
            singleDatePicker: true
        }).on('changeDate', (e) => {
            let fields = this.state.fields;
            fields.dob = e.format();
            this.setState({ fields: fields });
        });
        window.$('.select').select2({
            minimumResultsForSearch: Infinity
        });
        window.$(".set-permission-btn").click(function () {
            window.$(".control-access").toggle();
        });
        FormatSSN();
        this.getCustomProfileList();
    }

    Ssnformat = (ssn) => {
        if (typeof ssn !== 'undefined' && ssn!=null) {
            let ssn_new = ssn.replace(/^.{6}/g, '***-**')
            return ssn_new
        } else {
            return '';
        }
    }

    openTextbox = () =>{        
        this.setState({ viewSsn:false,ssnClick:true },()=>{
            FormatSSN()
        });
    }

    getCustomProfileList = () => {
        GetData('GetCustomProfileList')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ profileList: response.data });
                }
            })
    }

    ViewStaffNew = () => {
        PostDataWithParam('ViewStaffNew',{staff_id:this.props.match.params.staff_id})
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ fields: response.data['staff_details'][0] });
                }
            })
    }

    initAutocomplete = (e) => {
        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: ['geocode'] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let fields = this.state.fields;
            fields.latitude = place.geometry.location.lat();
            fields.longitude = place.geometry.location.lng();
            fields.address = window.$('#address')[0].value;
            this.setState({ fields: fields });
        })
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields });
    }

    resetError = (cb) => {
        let error = {
            first_name_err: '',
            last_name_err: '',
            email_err: '',
            contact_no_err: '',
            alternate_contact_no_err:'',
            gender_err: '',
            dob_err: '',
            ssn_err: '',
            dept_id_err: '',
            address_err:'',
            alternate_email_err:'',
            custom_profile_id_err:''
        }
        this.setState({ error: error }, () =>{
            cb();
        });
    }
    handleAddStaff = () => {
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        this.resetError(() => {
            let error = this.state.error;
            if (!this.state.fields.gender) {
                error.gender_err = 'Salutation is required.';
            }
            else if (!this.state.fields.first_name) {

                error.first_name_err = 'First name is required.';
            } else if (!this.state.fields.last_name) {

                error.last_name_err = 'Last name is required.';
            } else if (!this.state.fields.email) {

                error.email_err = 'Email is required.';
            } else if (!pattern.test(this.state.fields.email)) {

                error.email_err = 'Please enter valid email.';
            }
            else if (!pattern.test(this.state.fields.alternate_email) && this.state.fields.alternate_email) {

                error.alternate_email_err = 'Please enter valid email.';
            }
            else if (!this.state.fields.contact_no) {

                error.contact_no_err = 'Please enter phone number.';
            } else if (isNaN(this.state.fields.contact_no)) {

                error.contact_no_err = 'Please enter a valid phone number.';
            } else if (isNaN(this.state.fields.alternate_contact_no)) {
                error.alternate_contact_no_err = 'Please enter a valid alternate phone number.';
            }
            else if (!this.state.fields.address) {

                error.address_err = 'Address is required.';
            }
            else if (document.getElementById('dob').value === '') {

                error.dob_err = 'Date of birth is required.';
            }
            else if(!this.state.fields.custom_profile_id) {
                error.custom_profile_id_err = 'Please select a profile.';
            }
            else
             {
                let fields = this.state.fields;
                let selectedMenu = [{"menu_id":1,"status":0},{"menu_id":2,"status":1}];
                fields.menu = JSON.stringify(selectedMenu);
                fields.staff_id=this.props.match.params.staff_id;
                this.setState({ fields: fields });
                PostDataWithParam('UpdateStaffNew', this.state.fields)
                    .then((response) => {
                        let result = response;
                        if (result.success === 1) {
                            this.setState({ staffErr: result.msg }, () => {
                                this.setState({ addStaffStatus: true });
                            });
                        } else {
                            this.setState({ staffErr: result.msg });
                        }
                    })
            }
            this.setState({ error: error });
        });
    }

    render() {

        if (this.state.addStaffStatus) {
            return <Redirect  to={{
                pathname: '/independent-mover/dashboard/staff',
                state: { staffErr: this.state.staffErr}
                }}/>
        }

        return (
            <div className="content-container">
                            <div className="row">
                                <div className="col-md-12">
                                {Breadcumb(this.state.breadcrumb)}
                                    <div className="panel panel-flat profile-page">
                                        <div className="panel-heading p-b-0">
                                            <h5 className="panel-title">Edit Staff Details</h5>
                                        </div>
                                        <form  action="#">
                                            <div className="panel-body">

                                                <div className="row">
                                                <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Salutation<span className="text-danger">*</span></label>
                                                <select className="form-control" name="gender" value={this.state.fields.gender} onChange={this.changeValue}>
                                                    <option value="">Select Salutation...</option>
                                                    <option value="Mr.">Mr.</option>
                                                    <option value="Ms.">Ms.</option>
                                                    <option value="Mrs.">Mrs.</option>
                                                </select>
                                                <p className="text-danger help-block">{this.state.error.gender_err}</p>
                                            </div>
                                        </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>First Name<span className="text-danger">*</span></label>
                                                            <input className="form-control" type="text" name="first_name" value={this.state.fields.first_name} onChange={this.changeValue}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Last Name<span className="text-danger">*</span></label>
                                                            <input className="form-control" type="text" name="last_name" value={this.state.fields.last_name} onChange={this.changeValue} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Email Id<span className="text-danger">*</span></label>
                                                            <input className="form-control" type="text" name="email" value={this.state.fields.email} onChange={this.changeValue}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Phone No<span className="text-danger">*</span></label>
                                                            <input className="form-control" type="text" name="contact_no" value={this.state.fields.contact_no} onChange={this.changeValue} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Alternate Phone No</label>
                                                            <input className="form-control" type="text" name="alternate_contact_no" value={this.state.fields.alternate_contact_no} onChange={this.changeValue}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Address<span className="text-danger">*</span></label>
                                                            <input className="form-control" type="text"  id="address" onFocus={this.initAutocomplete} name="address" value={this.state.fields.address} onChange={this.changeValue}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Date of Birth<span className="text-danger">*</span> </label>

                                                            <div className="input-group date">
                                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                                <input type="text" className="form-control" name="dob" id="dob" value={this.state.fields.dob} onChange={this.changeValue} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>SSN </label>
                                                            <input type="text" className="form-control ssn" name="ssn" onChange={this.changeValue} maxLength="11" placeholder="Enter your social security number" value={this.state.fields.ssn}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h4 className="m-b-0">Access Control</h4>
                                                <hr  className="m-t-5" />
                                                <div className="row">
                                                        <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Select Profile <span className="text-danger">*</span></label>
                                                        <select className="form-control" name="custom_profile_id" value={this.state.fields.custom_profile_id} onChange={this.changeValue}>
                                                        <option></option>
                                                            {this.state.profileList.map(profile =>
                                                                <option key={profile.id} value={profile.id} >{profile.title}</option>
                                                            )}
                                                        </select>
                                                        <p className="text-danger help-block">{this.state.error.custom_profile_id_err}</p>
                                                    </div>
                                                </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                        <label>&nbsp;</label>
                                                        <a href="javascript:void(0)" className="set-permission-btn m-t-10 show">Custom Permission</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="well p-0 control-access">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Menu</th>
                                                                    <th>Access</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Messages</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value=""  /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="p-l-35">Compose Message</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value=""  /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="p-l-35">Inbox</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value=""  /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="p-l-35">Sent Message</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value=""  /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="p-l-35">Trash</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value=""  /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Staff</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value=""  /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="p-l-35">Manage Staff</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value=""  /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="p-l-35">Assign Role</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value=""  /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Manage Customer</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value="" /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Find & Book Service Providor</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value=""  /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="p-l-35">Movers</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value=""  /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="p-l-35">Helper Network</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value=""  /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="p-l-35">Claim Repairs</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value=""  /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="p-l-35">3rd Party Service</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value=""  /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Track Bookings</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value="" /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Document Management</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value="" /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Claims & Reimbursements</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value="" /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="p-l-35">Manage Claim Request</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value="" /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="p-l-35">Manage Reimbursement Request</td>
                                                                    <td>
                                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                                            <input type="checkbox" value="" /><span></span>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="panel-footer text-right p-r-20">
                                                <button type="button" className="btn btn-primary" onClick={this.handleAddStaff}>Update</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
            );
    }
}


export default EditStaffNew;