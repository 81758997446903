import React, { Component } from 'react';
import { Redirect,Link } from 'react-router-dom';
import GetData from '../services/GetData';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import PostDataWithParam from '../services/PostDataWithParam';
import ConfirmBox from '../externaljs/ConfirmBox';
import Breadcumb from '../externaljs/Breadcrumb';
import DateFormat from '../externaljs/DateFormat';

class StaffDetails extends Component {
    state = {
        breadcrumb:[
            {title:'Dashboard',link:'/independent-mover/dashboard/home',class:''},
            {title:'Manage Staff',link:'/independent-mover/dashboard/other-staff',class:''},
            {title:'Staff Details',link:'',class:'active'}
        ],
        fields:{}
    }

    componentDidMount() {
        this.ViewStaff();        
    }

    ViewStaff = () => {
        PostDataWithParam('ViewStaffDetails',{staff_id:this.props.match.params.staff_id})
        .then((response) => {
            if (response.success === 1) {
                this.setState({ fields: response.data[0] },()=>{
                    DataTableBasic()
                });
            }
        })
    }

    formatSSN = (ssn) => {
        if (typeof ssn !== 'undefined' && ssn != null) {
            let ssn_new = ssn.replace(/^.{6}/g, '***-**')
            return ssn_new
        } else {
            return '';
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="breadcrumb-line breadcrumb-line-component m-b-20">
                    {Breadcumb(this.state.breadcrumb)}
                    </div>
                    <div className="panel panel-flat profile-page">
                        <div className="panel-heading clearfix">
                            <h5 className="panel-title text-success pull-left">{this.state.fields.gender || "----"} {this.state.fields.first_name || "----"} {this.state.fields.last_name || "----"}</h5>
                            <div className="heading-elements">
                                <ul className="icons-list pull-right">
                                    <li className="dropdown">
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                            <i className="icon-menu9"></i>
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-right">
                                            <li><Link to={"/independent-mover/dashboard/edit-other-staff/" + this.state.fields.id}><i className="icon-pencil"></i> Edit Staff</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-4">

                                            <label className="text-bold m-b-0">Staff ID:</label>
                                            <p className="m-b-20">#00{this.state.fields.id || "----"}</p>

                                            <label className="text-bold m-b-0">Alternate Phone No:</label>
                                            <p className="m-b-20">{this.state.fields.alternate_contact_no || "----"}</p>

                                            <label className="text-bold m-b-0">SSN :</label>
                                            <p className="m-b-20">{this.formatSSN(this.state.fields.ssn) || "----"}</p>


                                        </div>

                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Email Address:</label>
                                            <p className="m-b-20">{this.state.fields.email || "----"}</p>

                                            <label className="text-bold m-b-0">Address:</label>
                                            <p className="m-b-20">{this.state.fields.address || "----"}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Mobile Phone No:</label>
                                            <p className="m-b-20">{this.state.fields.contact_no || "----"}</p>
                                            <label className="text-bold m-b-0">Date of Birth :</label>
                                            <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.fields.dob,'m/d/Y') || "----"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StaffDetails;