import React, { Component } from 'react'
import Breadcumb from '../externaljs/Breadcrumb';
import { Link,Redirect } from 'react-router-dom';

import GetData from '../services/GetData';
import PostDataWithParam from '../services/PostDataWithParam';
import PostDataFile from '../services/PostDataFile';
import DateFormat from '../externaljs/DateFormat';
import ConfirmBox from '../externaljs/ConfirmBox';
import {FileType,FileSvg,FileIcon} from '../externaljs/FileType';

class CreateJob extends Component {
     state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'ReloOrders', link: '/independent-mover/dashboard/jobs', class: '' },
            { title: 'Create ReloOrders', link: '', class: 'active' }
        ],
        form_data: {
            id: '',
            request_start_date: '',
            request_end_date: '',
            source_address: '',
            source_latitude: '',
            source_longitude: '',
            total_manpower_needed: '',
            services_needed:[],
            relocation_notes: '',
            customer_email: '',
            customer_name: '',
            contact_no: '',
            home_contact_no: '',
            driver_helper_mover:[],
            driver_email: '',
            driver_name: '',
            driver_mobile_number: '',
            assign_flag:0,
            hire_by_profile_id: 2
        },
        end_date_err: '',
        file_group: 1,
        files: [],
        proccesing:false,
        subscription_id: 0             
    }

    componentDidMount() {
        let form_data = this.state.form_data;

        if (typeof this.props.location.state !== 'undefined' && typeof this.props.location.state.form_data !== 'undefined') {     
            form_data = this.props.location.state.form_data          
        }

        if (typeof this.props.location.state !== 'undefined' && typeof this.props.location.state.selectedProviders !== 'undefined') {     
            form_data.driver_helper_mover = this.props.location.state.selectedProviders            
        }
        this.setState({ form_data: form_data });
        this.initDatePicker('request_start_date');
        this.initDatePicker('request_end_date');
        this.GetUserInfo();
    }

    initDatePicker = (name) => { 
        window.$('#'+name).datepicker({
            startDate:new Date(),
            autoclose: true
        }).on('changeDate', (e) => {
            let form_data = this.state.form_data;
            form_data[name] = e.format();
            this.setState({ form_data: form_data });
        });
    }

    GetUserInfo = () => {
        GetData('GetUserInfo', {})
        .then((response) => {
            if (response.success === 1) {
                this.setState({ subscription_id: response.data.subscription_id });
            }
        })
        .catch(err=>{})
    }

    handleOnChange(e){
        let form_data = this.state.form_data;
        form_data[e.target.name] = e.target.value;
        if(e.target.name=='contact_no' || e.target.name=='home_contact_no' ||  e.target.name=='driver_mobile_number')
        {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                form_data[e.target.name]= match[1] + ' ' + match[2] + ' ' + match[3]
              }
        }
        this.setState({form_data: form_data});
    }

    initAutocomplete(e) {
        let form_data = this.state.form_data;
        form_data.source_address = e.target.value;
        this.setState({ form_data: form_data });
        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: [] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let form_data = this.state.form_data;
            form_data.source_latitude = place.geometry.location.lat();
            form_data.source_longitude = place.geometry.location.lng();
            form_data.source_address = window.$('#address')[0].value;
            this.setState({ form_data: form_data });
        })
    }

    setServices(e){
        let form_data = this.state.form_data;
        if(form_data.services_needed.indexOf(e.target.value) > -1){
            let services_needed = form_data.services_needed.filter((item, i) => {
                if(item == e.target.value)
                    return false;
                else
                    return true;
            });
            form_data.services_needed = services_needed;
        }else{
            form_data.services_needed.push(e.target.value)
        }

        this.setState({form_data: form_data});
    }

    setFileGroup(file_group, e){
        e.preventDefault();
        this.setState({file_group: file_group}, () => {
            this.getFiles();
        });
    }

    getFiles(){
        PostDataWithParam('GetOfferFiles', {order_request_detail_id: this.state.form_data.id, file_group: this.state.file_group})
        .then(response => {
            if(response.success == 1){
                this.setState({files: response.data,proccesing:false});
            }else{
                this.setState({files: [],proccesing:false});
            }
        })
        .catch(err => {
            this.setState({files: [],proccesing:false});
        });
    }

    uploadFiles(e){
        if(e.target.files.length > 0){
            let formData = new FormData();
            for(let i = 0; i< e.target.files.length; i++){
                formData.append('request_files[' + i + ']', e.target.files[i], e.target.files[i].name);
            }
            formData.append('hire_by_profile_id', 2);
            formData.append('file_group', this.state.file_group);
            formData.append('order_request_detail_id', this.state.form_data.id);
            this.setState({proccesing:true}, () =>{
                 PostDataFile('UploadOfferFiles', formData)
                .then(response => {
                    if(response.success == 1){
                        window.swal('Success!', response.msg, 'success')
                        let form_data = this.state.form_data;
                        form_data.id = response.data.order_request_detail_id;
                            this.getFiles();                
                    }else{
                        window.swal('Error!', response.msg, 'error')
                    }
                })
                .catch(err => {
                    window.swal('Error!', 'Something went wrong.', 'error')
                });
         })
        }
    }

    getFileName(file){
        let files = file.request_file.split('/');
        return files[files.length -1];
    }

    deleteFile(id, e){
        e.preventDefault();
        ConfirmBox("You want to delete this file!")
        .then((result) => {
            if (result) {
                PostDataWithParam('DeleteOfferFiles', { id: id })
                .then((response) => {
                    if (response.success == 1) {
                        window.swal('Deleted!', response.msg, 'success')
                        this.getFiles();
                    }
                })
                .catch(err => {

                })
            }
        })
    }

    redirectSubscription = () => {
        window.swal({
            title: "Premium feature!",
            text: 'Please upgrade your membership to access this feature.',
            icon: "warning",
            buttons: ["Cancel", "Upgrade"],
        })
        .then((result) => {
            if (result) {
                this.props.history.push({
                    pathname: '/independent-mover/dashboard/my-subscription'
                })
            }
        });        
    }

    renderFiles(){
        if(this.state.proccesing){
            return  <div className="alert alert-success text-centert">Proccesing...</div>
        } else {
        if(this.state.files.length > 0){
            let files = this.state.files.map((file, i) => {
                return (
                    <li key={'item-' + i}>
                        <div className={"file-wrapper " + FileType(file.file_type)}>                           
                            <div className="options">                                        
                                <a href="" className="text-danger-600" onClick={this.deleteFile.bind(this, file.id)}><i className="icon-cancel-circle2"></i></a>
                            </div>
                            {FileSvg(file.file_type,file.request_file)}
                            <div className="file-title"><a href={file.request_file}><i className={FileIcon(file.file_type) + " position-left text-primary"}></i> {this.getFileName(file)}</a></div>
                        </div>
                    </li>
                )
            });

            return files;
        } else{
            return <h6 className="alert alert-primary no-border">No files were found.</h6>
        }
     }
    }

    postOffer(flag, e){
        e.preventDefault();
        if(flag == 0){
            if (this.state.form_data.request_start_date && this.state.form_data.request_end_date) {
                
                if ((Date.parse(this.state.form_data.request_end_date) <= Date.parse(this.state.form_data.request_start_date))) {
                    this.setState({end_date_err : 'End date should be greater than start date.'});
                }
                else 
                {
                    this.setState({end_date_err : ''});
                    let form_data = this.state.form_data;
                    let services_needed = form_data.services_needed.map((item, i) => {
                        return {name: item};
                    });
                    form_data.services_needed = JSON.stringify(services_needed);
                    form_data.driver_helper_mover = JSON.stringify(form_data.driver_helper_mover);
                    PostDataWithParam('CreateOffer', form_data)
                    .then((response) => {
                        if (response.success == 1) {         
                            window.swal('Success!', response.msg, 'success')           
                            this.props.history.push('/independent-mover/dashboard/unassign-offer');
                        }else{
                            window.swal('Error!', response.msg, 'error');
                        }
                    })
                    .catch(err => {
                        
                    })
                }
            }
        }else{

        }
    }
    render() { 
        return ( 
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <form method="POST" action="#" onSubmit={this.postOffer.bind(this, 0)} autoComplete="off">
                        <div className="panel panel-flat profile-page">
                            <div className="panel-heading show-or">
                                <h5 className="panel-title"><Link to="/independent-mover/dashboard/unassign-offer" className="text-primary">Click here</Link> to select an existing ReloOrders</h5>
                            </div>
                            <hr className="m-0" />
                            <div className="panel-heading">
                                <h5 className="panel-title">Add New ReloOrder</h5>
                            </div>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Requested Start Date <span className="text-danger">*</span></label>
                                            <div className="input-group date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control daterange-single" value={this.state.form_data.request_start_date} onChange={this.handleOnChange.bind(this)} name="request_start_date" id="request_start_date" required={true}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Requested End Date <span className="text-danger">*</span></label>
                                            <div className="input-group date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control daterange-single" value={this.state.form_data.request_end_date} onChange={this.handleOnChange.bind(this)} name="request_end_date" id="request_end_date" required={true}/>
                                            </div>
                                            <p className="text-danger help-block">{this.state.end_date_err}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Service Address <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" value={this.state.form_data.source_address} onChange={this.initAutocomplete.bind(this)} name="source_address" id="source_address" required={true} autoComplete="source_address"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Total Number of Manpower Needed Onsite <span className="text-danger">*</span></label>
                                            <input className="form-control" type="number" value={this.state.form_data.total_manpower_needed} onChange={this.handleOnChange.bind(this)} name="total_manpower_needed" required={true}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Services Needed</label>
                                            <ul className="checkbox-list">
                                                <li ><label className="checkbox-inline "><input type="checkbox" name="services_needed" value="Pre-Move Survey" checked={this.state.form_data.services_needed.indexOf('Pre-Move Survey') > -1} onChange={this.setServices.bind(this)}/><span className=""></span>Pre-Move Survey</label></li>
                                                <li ><label className="checkbox-inline "><input type="checkbox" name="services_needed" value="Packing" checked={this.state.form_data.services_needed.indexOf('Packing') > -1} onChange={this.setServices.bind(this)}/><span className=""></span>Packing</label></li>
                                                <li ><label className="checkbox-inline "><input type="checkbox" name="services_needed" value="Crating" checked={this.state.form_data.services_needed.indexOf('Crating') > -1} onChange={this.setServices.bind(this)}/><span className=""></span>Crating</label></li>
                                                <li ><label className="checkbox-inline "><input type="checkbox" name="services_needed" value="Short Hauling (500 Miles)" checked={this.state.form_data.services_needed.indexOf('Short Hauling (500 Miles)') > -1} onChange={this.setServices.bind(this)}/><span className=""></span>Short Hauling (500 Miles)</label></li>
                                                <li ><label className="checkbox-inline "><input type="checkbox" name="services_needed" value="Long Hauling (500+ MIles)" checked={this.state.form_data.services_needed.indexOf('Long Hauling (500+ MIles)') > -1} onChange={this.setServices.bind(this)}/><span className=""></span>Long Hauling (500+ MIles)</label></li>
                                                <li ><label className="checkbox-inline "><input type="checkbox" name="services_needed" value="Freight Hauling" checked={this.state.form_data.services_needed.indexOf('Freight Hauling') > -1} onChange={this.setServices.bind(this)}/><span className=""></span>Freight Hauling</label></li>
                                                <li ><label className="checkbox-inline "><input type="checkbox" name="services_needed" value="Storage" checked={this.state.form_data.services_needed.indexOf('Storage') > -1} onChange={this.setServices.bind(this)}/><span className=""></span><span className=""></span>Storage</label></li>
                                                <li ><label className="checkbox-inline "><input type="checkbox" name="services_needed" value="Intermodal" checked={this.state.form_data.services_needed.indexOf('Intermodal') > -1} onChange={this.setServices.bind(this)}/><span className=""></span>Intermodal</label></li>
                                                <li ><label className="checkbox-inline "><input type="checkbox" name="services_needed" value="Day Road Labor" checked={this.state.form_data.services_needed.indexOf('Day Road Labor') > -1} onChange={this.setServices.bind(this)}/><span className=""></span>Day Road Labor</label></li>
                                                <li ><label className="checkbox-inline "><input type="checkbox" name="services_needed" value="SIT Approved" checked={this.state.form_data.services_needed.indexOf('SIT Approved') > -1} onChange={this.setServices.bind(this)}/><span className=""></span>SIT Approved</label></li>
                                                <li ><label className="checkbox-inline "><input type="checkbox" name="services_needed" value="International" checked={this.state.form_data.services_needed.indexOf('International') > -1} onChange={this.setServices.bind(this)}/><span className=""></span>International</label></li>
                                                <li ><label className="checkbox-inline "><input type="checkbox" name="services_needed" value="Last Mile Delivery" checked={this.state.form_data.services_needed.indexOf('Last Mile Delivery') > -1} onChange={this.setServices.bind(this)}/><span className=""></span>Last Mile Delivery</label></li>
                                                <li ><label className="checkbox-inline "><input type="checkbox" name="services_needed" value="Freight Warehouse & Handling" checked={this.state.form_data.services_needed.indexOf('Freight Warehouse & Handling') > -1} onChange={this.setServices.bind(this)}/><span className=""></span>Freight Warehouse &amp; Handling</label></li>
                                                <li ><label className="checkbox-inline "><input type="checkbox" name="services_needed" value="Third Party Service" checked={this.state.form_data.services_needed.indexOf('Third Party Service') > -1} onChange={this.setServices.bind(this)}/><span className=""></span>Third Party Service</label></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Relocation Notes <span className="text-danger">*</span></label>
                                            <textarea className="form-control" rows="6" value={this.state.form_data.relocation_notes} onChange={this.handleOnChange.bind(this)} name="relocation_notes" required={true}/>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="alert alert-info text-center"><i className="icon-info22"></i> Customer and Driver info will be Hidden to the ReloHelper until the ReloOrder is Accepted</div>
                                <h6 className="text-success">Customer Info</h6>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Customer Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" value={this.state.form_data.customer_name} onChange={this.handleOnChange.bind(this)} name="customer_name" required={true}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Customer Email Address <span className="text-danger">*</span></label>
                                            <input className="form-control" type="email" value={this.state.form_data.customer_email} onChange={this.handleOnChange.bind(this)} name="customer_email" required={true}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Customer Mobile No</label>
                                            <input className="form-control" type="text" value={this.state.form_data.contact_no} onChange={this.handleOnChange.bind(this)} name="contact_no" maxLength="10"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Customer Mobile No 2</label>
                                            <input className="form-control" type="text" value={this.state.form_data.home_contact_no} onChange={this.handleOnChange.bind(this)} name="home_contact_no" maxLength="10"/>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <h6 className="text-success m-t-20">Driver Info</h6>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Driver Name</label>
                                            <input className="form-control" type="text" value={this.state.form_data.driver_name} onChange={this.handleOnChange.bind(this)} name="driver_name"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Driver Mobile Number</label>
                                            <input className="form-control" type="text" value={this.state.form_data.driver_mobile_number} onChange={this.handleOnChange.bind(this)} name="driver_mobile_number" maxLength="10"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Driver Email address</label>
                                            <input className="form-control" type="email" value={this.state.form_data.driver_email} onChange={this.handleOnChange.bind(this)} name="driver_email"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 className="pull-left">Files</h4> <label className="btn btn-success btn-xs btn-file pull-right"><input type="file" name="files[]" onChange={this.uploadFiles.bind(this)} multiple={true}/><i className="icon-file-plus position-left"></i> Add File</label>
                        <div className="clearfix"></div>
                        <div className="panel panel-flat profile-page">
                            <div className="panel-heading p-0">
                                <ul className="nav nav-tabs nav-tabs-bottom nav-horizantal-scroll">
                                    <li className="active">
                                        <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setFileGroup.bind(this, 1)}>
                                            <p className="text-uppercase m-0 m-l-5 m-r-5">Pre-move survey files</p>
                                        </a>
                                    </li>
                                    <li className="">
                                        <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setFileGroup.bind(this, 2)}>
                                            <p className="text-uppercase m-0 m-l-5 m-r-5">Inventory Pictures</p>
                                        </a>
                                    </li>
                                    <li className="">
                                        <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setFileGroup.bind(this, 3)}>
                                            <p className="text-uppercase m-0 m-l-5 m-r-5">Other Files</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="panel-body">
                                <div className="tabbable hori-scroll">
                                    <div className="file-manager">
                                        <ul>
                                            {this.renderFiles()}
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>                        
                        <div className="text-center">
                            <button type="submit" className="btn btn-default btn-lg m-r-10">Save</button>
                            
                            <button type="button" className="btn btn-primary btn-lg m-r-10" onClick={this.redirectSubscription}>Offer to ReloHelpers</button>
                            
                            <button type="button" className="btn btn-info btn-lg" onClick={this.redirectSubscription}>Offer to Movers</button>
                            
                        </div>
                    </form>
                </div>
            </div>
         );
    }
}
 
export default CreateJob;