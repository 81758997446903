import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PostDataFile from '../services/PostDataFile';
import PostDataWithParam from '../services/PostDataWithParam';
import DateFormat from '../externaljs/DateFormat';
import Breadcumb from '../externaljs/Breadcrumb';

class ViewClient extends Component {
    state = {
        client_details: [],
        client_name: '',
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Client', link: '/independent-mover/dashboard/clients', class: '' },
            { title: 'Client Details', link: '', class: 'active' }
        ],
        contract_details: [],
        fields: {
            contract_start_date: '',
            contract_end_date: '',
            contract_image: ''
        },
        error: {
            contract_start_date_err: '',
            contract_end_date_err: '',
            contract_image_err: ''
        }
    }

    resetError = (cb) => {
        let error = {
            contract_start_date_err: '',
            contract_end_date_err: '',
            contract_image_err: ''
        }
        this.setState({ error: error }, () => {
            cb();
        });
    }

    componentDidMount() {
        window.$('.input-group.date').datepicker({
            autoclose: true
        }).on('changeDate', (e) => {
            let fields = this.state.fields;
            fields.dob = e.format();
            this.setState({ fields: fields });
        });
        this.getClientDetails();
        this.GetClientContractList();
    }

    formatSSN = (ssn) => {
        if (typeof ssn != 'undefined' && ssn != null) {
            let ssn_new = ssn.replace(/^.{6}/g, '***-**')
            return ssn_new
        } else {
            return '';
        }
    }

    getClientDetails = () => {
        PostDataWithParam('GetClientDetails', { client_id: this.props.match.params.client_id })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ client_details: response.data[0] });
                }
            })
    }

    GetClientContractList = () => {
        PostDataWithParam('GetClientContractList', { client_id: this.props.match.params.client_id })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ contract_details: response.data });
                }
            })
    }

    handlecheckDetails = (status) => {

        if (status !== '') {
            return (
                status
            )
        } else {
            return '-----'
        }
    }


    handletContractInfoImage = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.files[0];
        this.setState({ fields: fields });
    }

    handleAddContractInfo = () => {
        this.resetError(() => {
            let error = this.state.error;
            if (document.getElementById('contract_start_date').value === '') {
                error.contract_start_date_err = 'Start Date is required.';
            }
            else if (document.getElementById('contract_end_date').value === '') {
                error.contract_end_date_err = 'End Date is required.';
            }
            else if (this.state.fields.contract_image === '') {
                error.contract_image_err = 'Contract Image is required.';
            }
            else {
                let fields = this.state.fields;
                fields.contract_start_date = document.getElementById('contract_start_date').value;
                fields.contract_end_date = document.getElementById('contract_end_date').value;
                this.setState({ fields: fields });
                let fd = new FormData();
                fd.append('contract_start_date', this.state.fields.contract_start_date);
                fd.append('contract_end_date', this.state.fields.contract_end_date);
                fd.append('document', this.state.fields.contract_image, this.state.fields.contract_image.name);
                fd.append('client_id', this.props.match.params.client_id);
                
                PostDataFile('AddClientContract', fd)
                    .then((response) => {
                        if (response.success === 1) {
                            window.$('#send-contract-modal').modal('hide');
                            window.swal('Successful!', 'Contract Added Successfully', 'success')
                            this.setState({ ClientErr: response.msg }, () => {
                                this.GetClientContractList();
                            });
                        } else {
                            this.setState({ ClientErr: response.msg });
                        }
                    })
                    .catch(err => {
                        this.setState({ ClientErr: 'Oops! Something went wrong.', addClientStatus: false });
                    })
            }
            this.setState({ error: error });
        });
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="panel panel-flat profile-page">
                        <div className="panel-heading clearfix">
                            <h5 className="panel-title text-success pull-left">{this.state.client_details.organization_name}</h5>
                            <div className="heading-elements">
                                <ul className="icons-list pull-right">
                                    <li className="dropdown">
                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                            <i className="icon-menu9"></i>
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-right">
                                            <li><a href="client-edit.php"><i className="icon-pencil"></i> Edit Client</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Office Email Id:</label>
                                            <p className="m-b-20">{this.state.client_details.company_email}</p>
                                            <label className="text-bold m-b-0">State License Number:</label>
                                            <p className="m-b-20">{this.state.client_details.state_license_number}</p>
                                        </div>

                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Office Phone:</label>
                                            <p className="m-b-20">{this.state.client_details.company_contact_no}</p>
                                            <label className="text-bold m-b-0">DOT Number:</label>
                                            <p className="m-b-20">{this.handlecheckDetails(this.state.client_details.DOT_number)}</p>
                                        </div>
                                        <div className="col-md-4">

                                            <label className="text-bold m-b-0">Fax Phone Number:</label>
                                            <p className="m-b-20">{this.handlecheckDetails(this.state.client_details.company_fax_no)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="m-t-0 m-b-0" />
                            <h6>Personal Information</h6>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Full Name:</label>
                                            <p className="m-b-20">{this.state.client_details.first_name} {this.state.client_details.last_name}</p>


                                            <label className="text-bold m-b-0">Work Phone:</label>
                                            <p className="m-b-20">{this.handlecheckDetails(this.state.client_details.work_contact_no)}</p>

                                            <label className="text-bold m-b-0">Date of Birth</label>
                                            <p className="m-b-20"> {DateFormat.getFormattedDateTime(this.state.client_details.dob, 'm/d/Y')}</p>

                                        </div>

                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Email address:</label>
                                            <p className="m-b-20">{this.state.client_details.email}</p>

                                            <label className="text-bold m-b-0">Social Security Number</label>
                                            <p className="m-b-20">{this.formatSSN(this.state.client_details.social_security_number)}</p>

                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Mobile Phone No:</label>
                                            <p className="m-b-20">{this.state.client_details.contact_no}</p>


                                            <label className="text-bold m-b-0">Home address</label>
                                            <p className="m-b-20">{this.state.client_details.address}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel panel-flat">
                        <div className="panel-heading clearfix">
                            <div className="heading-elements">
                                <a href="" className="btn btn-success btn-xs" data-toggle="modal" data-target="#send-contract-modal">Send New Contract</a>
                            </div>
                            <h5 className="panel-title pull-left">Contract History</h5>
                        </div>
                        {
                            this.state.contract_details.length !== 0
                            &&
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th className="text-nowrap">Contract Start Date</th>
                                        <th className="text-nowrap">Contract End Date</th>
                                        <th>Document</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.contract_details.map((contract, index) =>
                                        <tr key={index}>
                                            <td>{contract.contract_start_date} </td>
                                            <td>{contract.contract_end_date} </td>
                                            <td><a href={contract.document_path}>{contract.document}</a></td>
                                            <td>{contract.status}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }
                        {
                            this.state.contract_details.length === 0
                            &&
                            <div className="alert alert-primary border-none text-center">No Contract Found.</div>
                        }
                    </div>
                </div>
                <div id="send-contract-modal" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <form className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Send Contract to <span className="text-success">{this.state.client_details.organization_name}</span></h4>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Contract Start Date</label>
                                            <div className="input-group date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control" id="contract_start_date" name="contract_start_date" />
                                            </div>
                                            <p className="text-danger help-block">{this.state.error.contract_start_date_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Contract End Date</label>
                                            <div className="input-group date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control" id="contract_end_date" name="contract_end_date" />
                                            </div>
                                            <p className="text-danger help-block">{this.state.error.contract_end_date_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Upload Contract Document</label>
                                            <input type="file" name="contract_image" onChange={this.handletContractInfoImage} />
                                            <p className="text-danger help-block">{this.state.error.contract_image_err}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary"  onClick={this.handleAddContractInfo}>Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewClient;
