import React, { Component } from 'react';
import PostDataWithParam from '../services/PostDataWithParam';
import DateFormat from '../externaljs/DateFormat';
import { Link } from 'react-router-dom';
import ConfirmBox from '../externaljs/ConfirmBox';
import { FileType, FileSvg, FileIcon } from '../externaljs/FileType';
import PostDataFile from '../services/PostDataFile';
import Storage from '../externaljs/Storage';
import Urls from '../externaljs/Urls';

class ManageClaimDetail extends Component {

    state = {
        claim_id: '',
        loading: true,
        claim_details: [{
            item_list: [{
                item_images: []
            }]
        }],
        claim_files: [],
        amount: 0,
        conversationPeopleList: [],
        message: '',
        attachment: [],
        note_text: '',
        temp_item_name: '',
        temp_item_id: '',
        claim_notes_list: [],
        staff_user_id: '',
        custom_menu: [],
        permission: false
    }

    database = null;

    componentDidMount() {
        this.setState({ amount: 0, claim_id: this.props.match.params.claim_id }, () => {
            this.GetClaimDetails();
        });
        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        let custom_menu = [];
        if (staff_user_id != "") {
            custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
            for (var i = 0; i < custom_menu.length; i++) {
                if (custom_menu[i].title == "ReloOrders") {
                    if (custom_menu[i].is_add == 1 || custom_menu[i].is_add == "1")
                        this.setState({ permission: true }, () => {
                            // console.log(this.state.permission);
                        })
                }
            }
        }
        this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
            // console.log(this.state.custom_menu);
            // console.log(this.state.staff_user_id);
        });
    }

    GetClaimDetails = () => {
        this.setState({ loading: true }, () => {
            PostDataWithParam('GetClaimDetailsForMovers', { claim_id: this.state.claim_id })
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ claim_details: response.data[0] }, () => {
                            console.log("error 1  is ", this.state.claim_details)
                            this.getConversationPeopleList(0, () => {

                            });
                            this.GetClaimFiles();
                            this.database = Urls.firebase.database();
                            this.registerNotifications();

                            let claim_details = this.state.claim_details;
                            let item_list = this.state.claim_details.item_list;

                            let tempListH = item_list.map((itemH, iH) => {
                                let tempItemH = itemH;
                                tempItemH.msgCount = 0;
                                tempItemH.nodeUrl = '';
                                return tempItemH;
                            });

                            claim_details.item_list = tempListH;

                            this.setState({ claim_details: claim_details })

                        });
                    }
                    else {
                        let claim_details = [{
                            item_list: [{
                                item_images: []
                            }]
                        }]
                        this.setState({ claim_details: claim_details, loading: false });
                    }
                })
                .catch(err => {
                    let claim_details = [{
                        item_list: [{
                            item_images: []
                        }]
                    }]
                    this.setState({ claim_details: claim_details, loading: false });
                })
        })
    }

    registerNotifications() {
        this.database.ref('/notifications/claim_conversations_message/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '_' + this.state.claim_id)
            .on('child_added', (snapshot) => {

                let tempData = snapshot.val();
                let conversationPeopleList = this.state.conversationPeopleList;

                let tempListH = conversationPeopleList.map((itemH, iH) => {
                    if (itemH.conversations_user_id == tempData.sender_user_id) {
                        let tempItemH = itemH;
                        tempItemH.msgCount++;
                        tempItemH.nodeUrl = '/notifications/claim_conversations_message/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '_' + this.state.claim_id;
                        return tempItemH;
                    } else {
                        return itemH;
                    }
                });

                this.setState({ conversationPeopleList: tempListH })
            })

        this.database.ref('/notifications/save_claim_notes/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '_' + this.state.claim_id)
            .on('child_added', (snapshot) => {

                let tempData = snapshot.val();
                let claim_details = this.state.claim_details;
                let item_list = this.state.claim_details.item_list;


                let tempListH = item_list.map((itemH, iH) => {
                    if (itemH.id == tempData.item_id) {
                        let tempItemH = itemH;
                        tempItemH.msgCount++;
                        tempItemH.nodeUrl = '/notifications/save_claim_notes/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '_' + this.state.claim_id;
                        return tempItemH;
                    } else {
                        return itemH;
                    }
                });

                claim_details.item_list = tempListH;

                this.setState({ claim_details: claim_details })
            })
    }

    changeStatus = (claim_id, status) => {
        PostDataWithParam('ChangeClaimStatus', { claim_id: claim_id, status: status })
            .then((response) => {
                if (response.success === 1) {
                    window.swal('Success!', response.msg, 'success')
                    this.GetClaimDetails();
                } else {
                    window.swal('Error!', response.msg, 'error')
                    this.GetClaimDetails();
                }
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error')
                this.GetClaimDetails();
            })
    }

    GetClaimFiles = () => {
        PostDataWithParam('GetClaimFiles', { claim_request_detail_id: this.state.claim_id })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ claim_files: response.data, loading: false }, () => {
                        console.log("this is the error", this.state.claim_files)
                    });
                }
                else {
                    this.setState({ claim_files: [], loading: false });
                }
            })
            .catch(err => {
                this.setState({ claim_files: [], loading: false });
            })
    }

    getFileName(file) {
        let files = file.split('/');
        return files[files.length - 1];
    }

    changeValue = (e) => {
        this.setState({ amount: e.target.value });
    }

    ResolveItem = (id, status) => {
        let amount = this.state.amount
        if (id != "") {
            PostDataWithParam('ResolveItem', { item_id: id, amount: amount, status: status })
                .then((response) => {
                    if (response.success == 1) {
                        window.swal('Success!', response.msg, 'success')
                        this.GetClaimDetails();
                    } else {
                        window.swal('Error!', response.msg, 'error')
                        this.GetClaimDetails();
                    }
                })
                .catch(err => {
                    window.swal('Error!', 'Something went wrong', 'error')
                    this.GetClaimDetails();
                })
        }
    }

    getConversationPeopleList(flag = 0, callback) {
        PostDataWithParam('ClaimRelatedConversationsPeopleList', { order_request_detail_id: this.state.claim_details.id, claim_id: this.state.claim_id })
            .then(response => {
                let conversationPeopleList = this.state.conversationPeopleList;
                if (flag == 1)
                    conversationPeopleList = [];
                if (response.success == 1) {
                    conversationPeopleList = response.data;
                    let tempList = conversationPeopleList.map((item, i) => {
                        return { ...item, conversations: [], msgCount: 0, nodeUrl: '' }
                    });
                    this.setState({ conversationPeopleList: tempList }, () => {
                        callback();
                    })
                } else {
                    this.setState({ conversationPeopleList: [] }, () => {
                        callback();
                    })
                }
            })
            .catch(err => {
                this.setState({ conversationPeopleList: [] }, () => {
                    callback();
                })
            })
    }

    sendMessage(conversations_user_id, e) {
        e.preventDefault();
        let formData = new FormData();
        if (this.state.attachment.length > 0) {
            for (let i = 0; i < this.state.attachment.length; i++) {
                formData.append('file_name[' + i + ']', this.state.attachment[i], this.state.attachment[i].name);
            }
        }
        formData.append('order_request_detail_id', this.state.claim_details.id);
        formData.append('claim_id', this.state.claim_id);
        formData.append('conversations_user_id', conversations_user_id);
        formData.append('message', this.state.message);

        PostDataFile('SentClaimConversationsMsg', formData)
            .then(response => {
                if (response.success == 1) {
                    window.swal('Success!', response.msg, 'success');
                    this.setState({ message: '', attachment: [] }, () => {
                        this.state['activeEditor_' + conversations_user_id].setContent('');
                        this.getConversationPeopleList(1, () => {
                            this.getMessages(conversations_user_id, '', '', '', null);
                        });
                    })
                } else {
                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => {

            })
    }

    deleteFile(id, e) {
        e.preventDefault();
        ConfirmBox("You want to delete this file!")
            .then((result) => {
                if (result) {
                    PostDataWithParam('DeleteClaimFiles', { id: id })
                        .then((response) => {
                            if (response.success == 1) {
                                window.swal('Deleted!', response.msg, 'success')
                                this.GetClaimFiles();
                            }
                        })
                        .catch(err => {

                        })
                }
            })
    }

    handleOnChange(e) {
        this.setState({ message: e.target.getContent() })
    }

    handleFileChange(e) {
        this.setState({ attachment: e.target.files });
    }

    getMessages(user_id, item, index, stateName, e) {

        if (stateName != "") {

            if (item.nodeUrl != "")
                this.database.ref(item.nodeUrl).remove();

            let tempState = this.state[stateName];

            for (let i = 0; i < tempState.length; i++) {
                if (i == index) {
                    tempState[i].msgCount = 0
                }
            }

            this.setState({ [stateName]: tempState });
        }

        PostDataWithParam('GetClaimConversationsMsg', { order_request_detail_id: this.state.claim_details.id, claim_id: this.state.claim_id, conversations_user_id: user_id })
            .then(response => {
                if (response.success == 1) {
                    let conversationPeopleList = this.state.conversationPeopleList;
                    let tempList = conversationPeopleList.map((item, i) => {
                        if (item.conversations_user_id == user_id) {
                            let tempItem = item;
                            tempItem.conversations = response.data;
                            return tempItem;
                        } else {
                            return item;
                        }
                    });

                    this.setState({ conversationPeopleList: tempList })
                } else {
                    let conversationPeopleList = this.state.conversationPeopleList;
                    let tempList = conversationPeopleList.map((item, i) => {
                        if (item.conversations_user_id == user_id) {
                            let tempItem = item;
                            tempItem.conversations = [];
                            return tempItem;
                        } else {
                            return item;
                        }
                    });

                    this.setState({ conversationPeopleList: tempList })
                }
            })
            .catch(err => {

            })
    }

    renderConversations() {
        if (this.state.conversationPeopleList.length > 0) {
            let conversationPeopleList = this.state.conversationPeopleList.map((item, i) => {
                return (
                    <tr key={`conv_${i}`}>
                        <td style={{ width: '35%' }}>
                            <div className="media">
                                <div className="media-left">
                                    <Link to="#">
                                        <img className="media-object" src={item.profile_image} alt="..." width="35" />
                                    </Link>
                                </div>
                                <div className="media-body">
                                    <h6 className="m-0">
                                        <a href="javascript:void(0)" >{item.full_name}</a>
                                    </h6>
                                </div>
                            </div>
                        </td>
                        <td>
                            {(item.conversations_profile_id == 11) && <span className="text-primary">Military  Customer</span>}
                            {(item.conversations_profile_id == 4) && <span className="text-primary">Non-military  Customer</span>}
                        </td>

                        <td className="text-right">
                            <a href=""
                                data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                onClick={this.initChatBox.bind(
                                    this, item.conversations_user_id, item.full_name, this.state.claim_details.id, item.profile_image
                                )}>
                                <i className="icon-bubbles9 f-s-20 text-success"></i>
                            </a>
                        </td>
                    </tr>
                )
            });

            return conversationPeopleList;
        }
    }

    renderMessages(messages) {
        if (messages.length > 0) {
            let msg = messages.map((item, i) => {
                return (
                    <React.Fragment key={'message-' + i}>
                        <div className="message-body clearfix">
                            <div className="content-group-sm">
                                <div className="media">
                                    <span className="media-left">
                                        <span className={((item.conversations_user_id == (JSON.parse(Storage.get('imnLoginData'))).id) ? "btn bg-primary-400" : "btn bg-success-400") + " btn-rounded btn-icon btn-xs"}>
                                            <span className="letter-icon">{item.full_name.substr(0, 1)}</span>
                                        </span>
                                    </span>
                                    <div className="media-body">
                                        <span className="media-heading text-semibold">{item.full_name} </span>
                                        <div className="text-size-mini text-muted">
                                            {DateFormat.getFormattedDateTime(item.msg_time, 'm-d-Y')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: item.message }}></p>
                            <div className="tabbable attachedment-files">
                                <div className="file-manager">
                                    <ul>
                                        {item.quotetion_files.length > 0 && item.quotetion_files.map((file, j) => {
                                            return (
                                                <li key={'file-' + j}>
                                                    <div className="file-wrapper filetype-image">
                                                        <a href={file.file_path || 'javascript:void(0)'} className="file-title"><i className="icon-image5 position-left text-primary"></i> {file.file_name}</a>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {(i < (messages.length - 1)) && <hr />}
                    </React.Fragment>
                )
            });

            return msg;
        }
    }

    submitNotes = (e) => {
        e.preventDefault();

        let receiver_id = -1;

        for (let i in this.state.conversationPeopleList) {
            if (this.state.conversationPeopleList[i].conversations_profile_id == 4) {
                receiver_id = this.state.conversationPeopleList[i].conversations_user_id
                break;
            }
        }

        let postData = {
            claim_id: this.state.claim_id,
            item_id: this.state.temp_item_id,
            receiver_id: receiver_id,
            note: this.state.note_text
        }

        this.setState({ btnloading: true }, () => {
            PostDataWithParam('SaveClaimNotes', postData)
                .then(response => {
                    if (response.success == 1) {
                        this.setState({ btnloading: false })
                        this.GetItemNotes();
                        window.swal('Success!', response.msg, 'success')
                    } else {
                        this.setState({ btnloading: false })
                        window.swal('Error!', response.msg, 'error')
                    }
                })
                .catch(err => {
                    this.setState({ btnloading: false })
                    window.swal('Error!', 'Something went wrong.', 'error')
                })
        })
    }

    onChangeNote = (e) => {
        this.setState({ note_text: e.target.value })
    }

    setTempItem = (name, id, item, index) => {
        let claim_details = this.state.claim_details;
        let item_list = this.state.claim_details.item_list;

        if (item.nodeUrl != "")
            this.database.ref(item.nodeUrl).remove();

        let tempState = item_list;

        for (let i = 0; i < tempState.length; i++) {
            if (i == index) {
                tempState[i].msgCount = 0
            }
        }

        claim_details.item_list = tempState;

        this.setState({ claim_details: claim_details, temp_item_name: name, temp_item_id: id }, () => {
            this.GetItemNotes();
        });
    }

    GetItemNotes = () => {
        let postParam = {
            order_details_id: this.state.claim_details.id,
            claim_id: this.state.claim_id,
            item_id: this.state.temp_item_id
        }

        PostDataWithParam('GetClaimNotesList', postParam)
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ claim_notes_list: response.data });
                }
                else {
                    this.setState({ claim_notes_list: [], loading: false });
                }
            })
            .catch(err => {
                this.setState({ claim_notes_list: [], loading: false });
            })
    }
    initChatBox(user_id, name, order_id, profile_image, e) {
        e.preventDefault();
        this.props.openChatBox({
            chat_box_id: `${order_id}_${user_id}`,
            chat_info: {
                order_id,
                order_no: this.state.claim_details.order_id,
                receiver_info: {
                    user_id,
                    name,
                    profile_image
                }
            }
        })
    }

    render() {
        return (
            <div>
                {
                    this.state.loading
                    &&
                    <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                }
                {
                    !this.state.loading
                    &&
                    <React.Fragment>
                        <div className="row">
                            <div className="col-md-12">

                                <div className="breadcrumb-line breadcrumb-line-component m-b-20">
                                    <ul className="breadcrumb">
                                        <li><Link to="/independent-mover/dashboard/home"><i className="icon-meter-slow position-left"></i> Dashboard</Link></li>
                                        <li><Link to="/independent-mover/dashboard/manage-claims">Manage Claim Request</Link></li>
                                        <li className="active">Claim Details</li>
                                    </ul>
                                </div>
                                <div className="panel panel-flat profile-page">
                                    <div className="panel-heading clearfix">
                                        <h6 className="m-b-0 m-t-0 text-semibold">{this.state.claim_details.order_type == 1 ? 'Shipment' : 'Relo'} #{this.state.claim_details.order_id}</h6>
                                    </div>
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0">Customer Name</label>
                                                <p className="m-b-20">{this.state.claim_details.customer_first_name + ' ' + this.state.claim_details.customer_last_name}</p>
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0">Requested Relocation Date</label>
                                                <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.claim_details.request_start_date, 'm-d-Y')}</p>
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0">Relocation Status</label>
                                                <p className="m-b-20 text-success">Completed on {DateFormat.getFormattedDateTime(this.state.claim_details.request_end_date, 'm-d-Y')}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0">Origin Address</label>
                                                <p className="m-b-20">{this.state.claim_details.source_address}</p>
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0">Destination Address</label>
                                                <p className="m-b-20">{this.state.claim_details.destination_address}</p>
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0">Claim Status</label>
                                                {
                                                    this.state.claim_details.final_claim_status == 0
                                                    &&
                                                    <div className="m-b-20 dropdown">
                                                        <span className="text-primary m-r-5"><div className="reimbursement-status"><span className="active"></span><span></span><span></span></div> Open</span>
                                                        {this.state.staff_user_id == "" && (<a href="" data-toggle="dropdown" className="dropdown-toggle"><u>Change Status</u></a>)}
                                                        {(this.state.staff_user_id != "") && this.state.custom_menu.map((menu, i) => {
                                                            return (
                                                                <div>
                                                                    {
                                                                        (menu.title == "Manage Claims" && (menu.is_add == 1 || menu.is_add == "1")) && (<a href="" data-toggle="dropdown" className="dropdown-toggle"><u>Change Status</u></a>)
                                                                    }
                                                                </div>
                                                            );
                                                        })}
                                                        <ul className="dropdown-menu">
                                                            <li><a href="javascript:void(0)" onClick={() => this.changeStatus(this.state.claim_details.claimid, 1)}><i className="icon-sync"></i> In progress</a></li>
                                                            <li><a href="javascript:void(0)" onClick={() => this.changeStatus(this.state.claim_details.claimid, 2)}><i className="icon-checkmark2"></i> Resolved</a></li>
                                                        </ul>
                                                    </div>
                                                }
                                                {
                                                    this.state.claim_details.final_claim_status == 1
                                                    &&
                                                    <div className="m-b-20 dropdown">
                                                        <span className="text-danger"><div className="reimbursement-status"><span></span><span className="active"></span><span></span></div> In progress</span>
                                                        {this.state.staff_user_id == "" && (<a href="" data-toggle="dropdown" className="dropdown-toggle"><u>Change Status</u></a>)}
                                                        {(this.state.staff_user_id != "") && this.state.custom_menu.map((menu, i) => {
                                                            return (
                                                                <div>
                                                                    {
                                                                        (menu.title == "Manage Claims" && (menu.is_add == 1 || menu.is_add == "1")) && (<a href="" data-toggle="dropdown" className="dropdown-toggle"><u>Change Status</u></a>)
                                                                    }
                                                                </div>
                                                            );
                                                        })}
                                                        <ul className="dropdown-menu">
                                                            <li><a href="javascript:void(0)" onClick={() => this.changeStatus(this.state.claim_details.claimid, 2)}><i className="icon-checkmark2"></i> Resolved</a></li>
                                                        </ul>
                                                    </div>
                                                }
                                                {
                                                    this.state.claim_details.final_claim_status == 2
                                                    &&
                                                    <div className="m-b-20 dropdown">
                                                        <span className="text-success"><div className="reimbursement-status"><span></span><span></span><span className="active"></span></div> Resolved</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {/* <div id="additionalifo" className="collapse" aria-expanded="true" > */}
                                        <div>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6">
                                                    <label className="text-bold m-b-0">Customer Email address</label>
                                                    <p className="m-b-20">{this.state.claim_details.customer_email || "----"}</p>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <label className="text-bold m-b-0">Customer Mobile</label>
                                                    <p className="m-b-20">{this.state.claim_details.customer_mobile_ph || "----"}</p>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <label className="text-bold m-b-0">Customer Alternate Phone Number</label>
                                                    <p className="m-b-20">{this.state.claim_details.customer_work_ph || "----"}</p>
                                                </div>
                                            </div>
                                            <label className="text-bold m-b-0">Description</label>
                                            <p className="m-b-20">{this.state.claim_details.about_move || "----"}</p>
                                        </div>
                                        {/* <div className="text-center m-b-30">
                                            <a href="javascript:void(0);" data-toggle="collapse" data-target="#additionalifo" className="" aria-expanded="true">- Show/Hide Additional Information -</a>
                                        </div> */}
                                    </div>
                                </div>

                                <div className="clearfix">
                                    <h4 className="m-t-0">Claim Files &amp; Documents</h4>
                                </div>
                                <div className="panel panel-flat profile-page">
                                    <div className="panel-body">
                                        <div className="file-manager hori-scroll">
                                            <ul>
                                                {
                                                    this.state.claim_files.length != 0
                                                    &&
                                                    this.state.claim_files.map((item, index) => {
                                                        return (
                                                            <li key={index} className="p-b-0">
                                                                <div className="file-wrapper filetype-image">
                                                                    <div className="options">
                                                                        <a href="" className="text-danger" onClick={this.deleteFile.bind(this, item.id)}><i className="icon-cancel-circle2"></i></a>
                                                                    </div>
                                                                    <div className="file-icon" style={{ backgroundImage: 'url(' + item.request_file + ')' }}></div>
                                                                    <div className="file-title"><a href={item.request_file}><i className="icon-image5 position-left text-primary"></i> {this.getFileName(item.request_file)}</a></div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })
                                                }
                                                {
                                                    this.state.claim_files.length == 0
                                                    &&
                                                    <div className="alert alert-primary border-none text-center m-b-0">No Files Found.</div>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {this.state.claim_details.type == 1
                                    &&
                                    <h4>List of Items</h4>
                                }

                                {
                                    this.state.claim_details.item_list && this.state.claim_details.item_list.length != 0
                                        ? this.state.claim_details.item_list.map((item, index) => {
                                            return (
                                                <div className="panel panel-default" key={index}>
                                                    <div className="panel-heading">
                                                        <div className="pull-right">
                                                            <ul className="icons-list">
                                                                {/*(this.state.staff_user_id == "" || this.state.permission) &&
                                                                    <li><a href="javascript:void(0);" className="" data-toggle="modal" data-target="#notes" 
                                                                    onClick={() => this.setTempItem(item.item_name, item.id, item, index)}>
                                                                    <i className="icon-bubble-lines4"></i></a></li>*/}
                                                                <li><a href="javascript:void(0);" data-action="collapse" className=""></a></li>

                                                            </ul>
                                                        </div>
                                                        <h6 className="panel-title inline-block">Item : {item.item_name} {/*<span className="label bg-danger m-l-15">{item.msgCount} </span>*/}</h6>
                                                    </div>
                                                    <div className="panel-body" style={{ display: 'none' }}>
                                                        <div className="row">
                                                            <div className="col-sm-4">
                                                                <label className="text-bold m-b-0">Purchase Price</label>
                                                                <p className="m-b-20">{'$' + item.purchase_price}</p>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <label className="text-bold m-b-0">Amount Claimed</label>
                                                                <p className="m-b-20">{'$' + item.amount_claimed}</p>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <label className="text-bold m-b-0">Amount Offered </label>
                                                                {
                                                                    <p className="m-b-20 text-green">
                                                                        {item.amount_received ? ('$' + item.amount_received) : "----"}
                                                                    </p>
                                                                }

                                                                {
                                                                    /*{
                                                                    item.claim_status == 2
                                                                    &&
                                                                    <p className="m-b-20 text-green">{'$' + item.amount_received}</p>
                                                                    }
                                                                    {
                                                                        item.claim_status == 3
                                                                        &&
                                                                        <p className="m-b-20 text-warning">Declined</p>
                                                                    }
                                                                    {
                                                                        item.claim_status < 2
                                                                        &&
                                                                        <p className="m-b-20 text-warning">Not Settled Yet</p>
                                                                    }*/
                                                                }

                                                            </div>
                                                        </div>
                                                        {this.state.claim_details.type == 1 &&
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <label className="text-bold m-b-0">Description of Damage</label>
                                                                    <p className="m-b-20">{item.description_of_damage || "----"}</p>
                                                                </div>
                                                            </div>
                                                        }
                                                        {/* <div className="file-manager clearfix">
                                                            <ul>
                                                                {
                                                                    item.item_images.length != 0
                                                                    &&
                                                                    item.item_images.map((image, ind) => {
                                                                        return (
                                                                            <li key={ind}>
                                                                                <div className="file-wrapper filetype-image">
                                                                                    <a href={image.item_image} className="file-title"><i className="icon-image5 position-left text-primary"></i>{this.getFileName(image.item_image)}</a>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    item.item_images.length == 0
                                                                    &&
                                                                    <div className="alert alert-primary border-none text-center">No Item Files Found.</div>
                                                                }

                                                            </ul>
                                                        </div> */}
                                                        <h5 className="text-orange-600 m-b-5">Offers</h5>
                                                        <div className="well p-0">
                                                            <div className="table-responsive">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Claimed Amount</th>
                                                                            {/*<th>Approved Amount</th>*/}
                                                                            <th>Amount Offered</th>
                                                                            {/*(this.state.staff_user_id == "" || this.state.permission) &&
                                                                                <th>Status</th>*/}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="text-nowrap">{'$' + item.amount_claimed}</td>
                                                                            {/*<td>{item.amount_received ? ('$' + item.amount_received) : "----"}</td>*/}
                                                                            {
                                                                                item.claim_status < 2
                                                                                &&
                                                                                this.state.staff_user_id == "" &&
                                                                                <React.Fragment>
                                                                                    <td className="text-success"><input type="text" className="form-control" placeholder="Enter Amount Offered" onChange={this.changeValue} /></td>
                                                                                    <td>
                                                                                        <a href="javascript:void(0)" className="btn btn-xs btn-success m-b-5" onClick={() => this.ResolveItem(item.id, 2)}>Submit</a>
                                                                                        {/*<a href="javascript:void(0)" className="btn btn-xs btn-success m-b-5" onClick={() => this.ResolveItem(item.id, 2)}>Approve</a>
                                                                                                    &nbsp;
                                                                                        <a href="javascript:void(0)" className="btn btn-xs btn-danger m-b-5" onClick={() => this.ResolveItem(item.id, 3)}>Decline</a>*/}
                                                                                    </td>
                                                                                </React.Fragment>}


                                                                            {(this.state.staff_user_id != "") && this.state.custom_menu.map((menu, i) => {
                                                                                return (
                                                                                    <div>
                                                                                        {
                                                                                            (menu.title == "Manage Claims" && (menu.is_add == 1 || menu.is_add == "1"))
                                                                                            && <React.Fragment>
                                                                                                <td className="text-success"><input type="text" className="form-control" placeholder="Enter Amount Offered" onChange={this.changeValue} /></td>
                                                                                                <td>
                                                                                                    <a href="javascript:void(0)" className="btn btn-xs btn-success m-b-5" onClick={() => this.ResolveItem(item.id, 2)}>Submit</a>
                                                                                                    {/*<a href="javascript:void(0)" className="btn btn-xs btn-success m-b-5" onClick={() => this.ResolveItem(item.id, 2)}>Approve</a>
                                                                                                                &nbsp;
                                                                                                    <a href="javascript:void(0)" className="btn btn-xs btn-danger m-b-5" onClick={() => this.ResolveItem(item.id, 3)}>Decline</a>*/}
                                                                                                </td>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    </div>
                                                                                );
                                                                            })
                                                                            }


                                                                            {
                                                                                item.claim_status >= 2
                                                                                &&
                                                                                <React.Fragment>
                                                                                    <td className="text-success">{item.amount_received ? ('$' + item.amount_received) : "----"}</td>
                                                                                    {/*<td><span className="text-success m-b-20"><div className="reimbursement-status"><span></span><span></span><span className="active"></span></div></span> Resolved</td>*/}
                                                                                </React.Fragment>
                                                                            }
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : <div className="alert alert-danger text-center">No Items Found.</div>
                                }



                                <h4 id="messageSection">Claim Conversations</h4>
                                <div className="panel panel-default">
                                    {(this.state.conversationPeopleList.length > 0)
                                        &&
                                        <React.Fragment>
                                            <div className="table-responsive no-border">
                                                <table className="table mover-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-bold">Name</th>
                                                            <th className="text-bold">Profile</th>
                                                            <th className="text-bold text-right">Chat</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.renderConversations()}
                                                    </tbody>

                                                </table>
                                            </div>
                                        </React.Fragment>
                                    }
                                    {
                                        this.state.conversationPeopleList.length == 0
                                        &&
                                        <div className="alert alert-primary border-none text-center m-b-0">No Conversations Found.</div>
                                    }

                                </div>
                            </div>
                        </div>


                        <div id="notes" className="modal fade">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        <h4 className="modal-title">{this.state.temp_item_name}</h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="panel-body  p-0">
                                            {
                                                this.state.claim_notes_list.length != 0
                                                &&
                                                this.state.claim_notes_list.map((item, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <div className="message-body clearfix">
                                                                <div className="content-group-sm">
                                                                    <div className="media">
                                                                        <span className="media-left">
                                                                            <span className={((item.sender_id == (JSON.parse(Storage.get('imnLoginData'))).id) ? "btn bg-primary-400" : "btn bg-success-400") + " btn-rounded btn-icon btn-xs"}>
                                                                                <span className="letter-icon">{item.sender_first_name.substr(0, 1)}</span>
                                                                            </span>
                                                                        </span>
                                                                        <div className="media-body">
                                                                            <span className="media-heading text-semibold">{item.sender_first_name} {item.sender_last_name} </span>
                                                                            <div className="text-size-mini text-muted">
                                                                                {DateFormat.getFormattedDateTime(item.created_at, 'm-d-Y')}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p>
                                                                    {item.note}
                                                                </p>
                                                            </div>
                                                            {
                                                                index < (this.state.claim_notes_list.length - 1)
                                                                &&
                                                                <hr />
                                                            }
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                            {
                                                this.state.claim_notes_list.length == 0
                                                &&
                                                <div className="alert alert-primary border-none text-center">No Notes Found.</div>
                                            }

                                        </div>
                                    </div>
                                    <form className="modal-footer" method="POST" onSubmit={e => this.submitNotes(e)}>
                                        <div className="form-group">
                                            <textarea className="form-control" rows="3" value={this.state.note_text} onChange={this.onChangeNote} name="note_text" required={true}></textarea>
                                        </div>
                                        {
                                            this.state.btnloading
                                            &&
                                            <button type="button" className="btn btn-primary" disabled={true}>Processing...</button>
                                        }
                                        {
                                            !this.state.btnloading
                                            &&
                                            <React.Fragment>
                                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                                <button type="submit" className="btn btn-primary" required={true}>Submit</button>
                                            </React.Fragment>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>

                    </React.Fragment>
                }
            </div>
        )
    }

}

export default ManageClaimDetail