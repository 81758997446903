import React, { Component } from 'react';
import HeaderMain from './HeaderMain';
import FooterMain from './FooterMain';
import { Link } from 'react-router-dom';
const SIGNUP = "signup";
const SIGNIN = "signin";
const RESEND_MAIL = "resend-mail";
export default class ActivationMessage extends Component {
    state = {
        header: "",

    }
    componentDidMount() {
        const { from } = this.props.match.params;
        let header = ""
        if (from === SIGNUP) {
            header = "Registration Successful";
        } else if (from === SIGNIN) {
            header = "Please Verify Your Email Address";
        } else if (from === RESEND_MAIL) {
            header = "Activation Email Has Been Sent Successfully";
        }
        this.setState({ header });

    }
    render() {
        return (
            <section className="activationMassage-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-center col-md-offset-3">
                            <div className="activationMassage-box">
                                <h2 className="text-success m-0 p-20">{this.state.header}</h2>
                                <hr className="m-0" />
                                <p className="p-20 f-s-18 m-0">
                                    Please check your primary email and activate your account. It may take 1-2 minutes to receive the email depending on your email service. Don’t forget to check your junk folder 😊<br/>
                                    Close this window/ Refresh your browser once you have clicked the activation link.
                                </p>
                                <hr className="m-0" />
                                <div className="p-20 f-s-16"><Link to={"/resend-activation" + this.props.location.search} className="btn bg-blue-700">Didn’t Receive Activation Email</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
