import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import GetData from '../services/GetData';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import PostDataWithParam from '../services/PostDataWithParam';
import PostDataFile from '../services/PostDataFile';
import ConfirmBox from '../externaljs/ConfirmBox';
import Breadcumb from '../externaljs/Breadcrumb';


class Helper extends Component {
    state = {
        HelperErr: '',
        helper_list: [],
        addHelperStatus: false,
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Helpers', link: '', class: 'active' }
        ],
        import_file:''

    }

    componentDidMount() {
        if (typeof this.props.location.state !== 'undefined') {
            this.setState({ HelperErr: this.props.location.state.HelperErr });
        }
        this.GetAllHelpers();
    }

    componentWillUnmount() {
        this.setState({ HelperErr: '' });
    }

    GetAllHelpers = () => {
        GetData('GetAllHelpers')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ helper_list: response.data })
                    DataTableBasic();
                }
            })
    }

    showAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-success">
                    {status}
                </div>
            )
        }
    }

    handleStatus = (status) => {
        if (status == 0) {
            return (
                <td>
                    Deactive
                </td>
            )
        } else {
            return (
                <td>
                    Active
                </td>
            )

        }
    }

    handleBtn = (status) => {
        if (status == 0) {
            return (
                'Activate'
            )
        } else {
            return (
                'Deactivate'
            )

        }
    }




    handleaStaffLimit = () => {
        GetData('GetStaffDriverHelperAddLimit')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ addHelperStatus: true });
                } else {
                    window.swal({
                        title: 'Your staff add limit exceeded.',
                        text: "Please renew your subscription.",
                        type: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    ActiveDeactiveHelpers = (helper_id, status) => {

        if (status == 1) {
            ConfirmBox("You want to deactivate this helper!")
                .then((result) => {
                    if (result.value) {
                        PostDataWithParam('ActiveDeactiveHelpers', { helper_id: helper_id, status: 0 })
                            .then((response) => {
                                let result = response;
                                if (result.success === 1) {
                                    this.GetAllHelpers();
                                    window.swal('Deactivated!', 'Helper Deactivated Successfully', 'success')
                                } else {
                                    window.swal('Deactivated!', result.msg, 'success')
                                }
                            });
                    }
                });
        }
        else {
            ConfirmBox("You want to activate this helper!")
                .then((result) => {
                    if (result.value) {
                        PostDataWithParam('ActiveDeactiveHelpers', { helper_id: helper_id, status: 1 })
                            .then((response) => {
                                let result = response;
                                if (result.success === 1) {
                                    this.GetAllHelpers();
                                    window.swal('Activated!', 'Helper Activated Successfully', 'success')
                                } else {
                                    window.swal('Activated!', result.msg, 'success')
                                }
                            });
                    }
                });
        }
    }

    handleCSVUpload = (e) => {
        this.setState({ import_file: e.target.files[0] },() =>{
            let fd = new FormData();
            fd.append('import_file', this.state.import_file, this.state.import_file.name);
            PostDataFile('ImportExcelForHelpers', fd)
            .then((response) => {
                if (response.success === 1) {
                    this.GetAllHelpers();
                    window.swal('Successfull!', 'Helper(s) Added Successfully', 'success')
                } else {
                    window.swal('Failed!', 'The E-mail IDs already exists', 'error')
                }
            })
            .catch(err=>{
                this.setState({ HelperErr: 'Oops! Something went wrong.',addHelperStatus: false });
            })
        });
    }


    render() {
        if (this.state.addHelperStatus) {
            return <Redirect to={{
                pathname: '/independent-mover/dashboard/addhelper'
            }} />
        }
        if(this.state.addHelperStatusCSV){
            return <Redirect to={{
                pathname: '/independent-mover/dashboard/helpers'
            }} />
        }
        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="panel panel-flat">
                        <div className="panel-heading">
                            <h5 className="panel-title">Helper List</h5>
                            <div className="heading-elements">
                                <Link to="/independent-mover/dashboard/addhelper" className="btn btn-primary btn-xs m-r-15"><i className="icon-plus22"></i> Add Helper</Link>

                                <label className="btn btn-success btn-file btn-xs"><input type="file" onChange={this.handleCSVUpload} name="import_file" /><i className="icon-file-excel"></i> Upload CSV</label>
                            </div>
                        </div>
                        {this.showAlert(this.state.HelperErr)}
                        {
                            this.state.helper_list.length !== 0
                            &&
                            <table className="table datatable-basic table-striped">
                                <thead>
                                    <tr>
                                        <th style={style}>Name</th>
                                        <th>Mobile</th>
                                        <th>Email Address</th>
                                        <th>DL Exp. Date</th>
                                        <th>Medical Exam Exp.</th>
                                        <th>Type</th>
                                        <th>Vehicle No</th>
                                        <th>Status</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.helper_list.map(d_list =>
                                        <tr id={"#00" + d_list.id} key={d_list.id}>
                                            <td className="text-nowrap"><Link to={"/independent-mover/dashboard/viewhelper/" + d_list.id} >{d_list.gender} {d_list.first_name} {d_list.last_name}</Link></td>
                                            <td>{d_list.contact_no}</td>
                                            <td>{d_list.email}</td>
                                            <td>{d_list.license_exp_date}</td>
                                            <td>{d_list.medical_exam_exp_date}</td>
                                            <td>Local Contractor</td>
                                            <td>{d_list.vehicle_no}</td>
                                            {this.handleStatus(d_list.status)}
                                            <td className="text-center">
                                                <ul className="icons-list">
                                                    <li className="dropdown">
                                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                            <i className="icon-menu9"></i>
                                                        </a>
                                                        <ul className="dropdown-menu dropdown-menu-right">
                                                            <li><Link to={"/independent-mover/dashboard/edithelper/" + d_list.id} ><i className="icon-pencil"></i> Edit Helper</Link></li>
                                                            
                                                            <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.ActiveDeactiveHelpers(d_list.id, d_list.status)}><i className="icon-user-block"></i> {this.handleBtn(d_list.status)} Helper</a></li>
                                                           
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }
                        {
                            this.state.helper_list.length === 0
                            &&
                            <div className="alert alert-primary border-none text-center">No Helper Found.</div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Helper;

const style = {
    whiteSpace: 'nowrap'
}