import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import GetData from '../services/GetData';
import PostDataWithParam from '../services/PostDataWithParam';
import PostDataFile from '../services/PostDataFile';
import Breadcumb from '../externaljs/Breadcrumb';

class EditMyProfile extends Component {
    state = {
        fields: {
            DOT_number: '',
            about_us: '',
            company_MC: '',
            company_contact_no: '',
            company_email: '',
            company_fax_no: '',
            core_values: '',
            facebook_link: '',
            linkedIn_link:'',
            logo:'',
            organization_name:'',
            our_mission:'',
            state_license_number:'',
            twitter_link:'',
            website_link:'',
            year_founded: ''
        },
        error: {
            DOT_number_err: '',
            about_us_err: '',
            company_MC_err: '',
            company_contact_no_err: '',
            company_email_err: '',
            company_fax_no_err: '',
            core_values_err: '',
            facebook_link_err: '',
            linkedIn_link_err:'',
            logo_err:'',
            organization_name_err:'',
            our_mission_err:'',
            state_license_number_err:'',
            twitter_link_err:'',
            website_link_err:'',
            year_founded_err: ''
        },
        changeUpdateStatus: false,
        UpdateErr:'',
        selectedFile : null,
        selectedFileAll : [],
        breadcrumb:[
            {title:'Dashboard',link:'/independent-mover/dashboard/home',class:''},
            {title:'Company Profile',link:'/independent-mover/dashboard/company-profile',class:''},
            {title:'Edit Company Profile',link:'',class:'active'}
        ]
        
    }

    componentDidMount(){
        this.GetCompanyInfo();
    }


    GetCompanyInfo = () => {
        PostDataWithParam('GetCompanyInfo',{})
        .then((response) => {
            if (response.success === 1) {
                this.setState({ fields: response.data });
            }
        })
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields });
    }

    resetError = (cb) => {
        let error = {
            DOT_number_err: '',
            about_us_err: '',
            company_MC_err: '',
            company_contact_no_err: '',
            company_email_err: '',
            company_fax_no_err: '',
            core_values_err: '',
            facebook_link_err: '',
            linkedIn_link_err:'',
            logo_err:'',
            organization_name_err:'',
            our_mission_err:'',
            state_license_number_err:'',
            twitter_link_err:'',
            website_link_err:'',
            year_founded_err: ''
        }
        this.setState({ error: error }, () =>{
            cb();
        });
    }


    handleFileSelect = (e) => {
        this.setState({ selectedFile: e.target.files[0] });
    }

    handleFileSelectAll = (e) => {
        let userfiles = []
        for (let i = 0; i < e.target.files.length; i++) {
            userfiles.push(e.target.files[i])
        }
        this.setState({ selectedFileAll: userfiles });
    }


      handleUpdateCompanyProfile = () => {
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const phonepattern = /^\d{10}$/;

        this.resetError(() => {
            let error = this.state.error;
             if (!this.state.fields.organization_name) {
                error.organization_name_err = 'Organization Name is required.';
            } 
            else if (!this.state.fields.company_email) {                

                error.company_email_err = 'Email is required.';
            }
            else if (!pattern.test(this.state.fields.company_email)) {
                
                error.company_email_err = 'Please enter valid email.';
            } 
             else if (!this.state.fields.company_contact_no) {           

                error.company_contact_no_err = 'Office Phone Number is required.';

            }  else if (isNaN(this.state.fields.company_contact_no)) {
                
                error.company_contact_no_err = 'Please enter a valid phone number.';
            } 
            else if(!phonepattern.test(this.state.fields.company_contact_no)) {
                
                error.company_contact_no_err = 'Please enter a valid phone number.';
            } 
            else if (isNaN(this.state.fields.company_fax_no)) {
                
                error.company_fax_no_err = 'Please enter a valid number.';
            } 
            else if (isNaN(this.state.fields.year_founded)) {

                error.year_founded_err = 'Please enter a valid number.';
                
            } 
            else {    
                PostDataWithParam('UpdateCompanyProfile',this.state.fields)
                .then( (response) => {
                    let result = response;
                    if(result.success === 1){
                        this.setState({ UpdateErr : result.msg },()=>{
                            this.setState({ changeUpdateStatus: true });   
                        });
                    }else{
                        this.setState({ UpdateErr: result.msg });
                    }
                })

                if(this.state.selectedFile!=null){    
                    let fd = new FormData();
                    fd.append('logo', this.state.selectedFile, this.state.selectedFile.name);
                    PostDataFile('UpdateCompanyLogo', fd)
                    .then((response) => {
                        if (response.success === 1) {
                            this.setState({ UpdateErr : "Company Profile updated successfully" },()=>{
                                this.setState({ changeUpdateStatus: true });   
                            });
                        } else {
                            this.setState({ UpdateErr: response.msg });
                        }
                    })
                }

                if(this.state.selectedFileAll.length!=0){    
                    let fd = new FormData();
                    for (let i = 0; i < this.state.selectedFileAll.length; i++) {
                        fd.append('document['+i+']', this.state.selectedFileAll[i], this.state.selectedFileAll[i].name);
                    }
                    PostDataFile('UpdateCompanyDocument', fd)
                    .then((response) => {
                        if (response.success === 1) {
                            this.setState({ UpdateErr : "Company Profile updated successfully" },()=>{
                                this.setState({ changeUpdateStatus: true });   
                            });
                        } else {
                            this.setState({ UpdateErr: response.msg });
                        }
                    })
                }



            }
            this.setState({ error: error });
        });  

        
    }


    render() {

        if (this.state.changeUpdateStatus) {
            return <Redirect  to={{
                pathname: '/independent-mover/dashboard/company-profile',
                state: { UpdateErr: this.state.UpdateErr}
                }}/>
        }

        return (
            <div className="content-container">
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="panel panel-flat profile-page">
                        <div className="panel-heading p-b-0">
                            <h5 className="panel-title">Edit Company Profile</h5>
                        </div>
                        <form  action="#">
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Company Name</label>
                                            <input className="form-control" type="text" name="organization_name" placeholder="Enter Company Name" onChange={this.changeValue} value={this.state.fields.organization_name}/>
                                            <p className="text-danger help-block">{this.state.error.organization_name_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email Address</label>
                                            <input className="form-control" type="text" name="company_email" placeholder="Enter Email Address" onChange={this.changeValue} value={this.state.fields.company_email}/>
                                            <p className="text-danger help-block">{this.state.error.company_email_err}</p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Office Phone Number</label>
                                            <input className="form-control" type="text" name="company_contact_no" placeholder="Enter Office Phone Number" onChange={this.changeValue} value={this.state.fields.company_contact_no}/>
                                            <p className="text-danger help-block">{this.state.error.company_contact_no_err}</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Fax Phone Number</label>
                                            <input className="form-control" type="text" name="company_fax_no" placeholder="Enter Fax Phone Number" onChange={this.changeValue} value={this.state.fields.company_fax_no}/>
                                            <p className="text-danger help-block">{this.state.error.company_fax_no_err}</p>
                                    </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-6">
                                        <label>Year Founded</label>
                                        <input className="form-control" type="text" name="year_founded" placeholder="Enter Year Founded" onChange={this.changeValue} value={this.state.fields.year_founded || ''}/>
                                        <p className="text-danger help-block">{this.state.error.year_founded_err}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>DOT Number</label>
                                            <input className="form-control" type="text" name="DOT_number" placeholder="Enter DOT Number" onChange={this.changeValue} value={this.state.fields.DOT_number}/>
                                            <p className="text-danger help-block">{this.state.error.DOT_number_err}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>MC# </label>
                                            <input className="form-control" type="text" name="company_MC" placeholder="Enter MC#" onChange={this.changeValue} value={this.state.fields.company_MC || ''}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>State License Number</label>
                                            <input type="text" className="form-control" name="state_license_number" placeholder="Enter State License Number"  onChange={this.changeValue} value={this.state.fields.state_license_number}/>
                                            <p className="text-danger help-block">{this.state.error.state_license_number_err}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>About us</label>
                                            <textarea className="form-control" rows="8" name="about_us" placeholder="Write Sometings.." onChange={this.changeValue} value={this.state.fields.about_us || ''}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Our Mission</label>
                                            <textarea className="form-control" rows="8" name="our_mission" placeholder="Write Sometings.." onChange={this.changeValue} value={this.state.fields.our_mission || ''}/>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Core Values</label>
                                            <textarea className="form-control" rows="8" name="core_values" placeholder="Write Sometings.." onChange={this.changeValue} value = {this.state.fields.core_values || ''}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Upload Company Logo</label>
                                            <input type="file" name="logo" onChange={this.handleFileSelect}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Upload Documents</label>
                                            <input type="file" name="logo" onChange={this.handleFileSelectAll} multiple/>
                                        </div>
                                    </div>
                                </div>
                                <h5 className="panel-title">Social Links</h5>
                                <hr className="m-t-5"/>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Facebook Link</label>
                                            <div className="input-group date">
                                                <span className="input-group-addon"><i className="fa fa-facebook-f"></i></span>
                                                <input className="form-control" name="facebook_link" placeholder="Enret Facebook Link"  type="text" onChange={this.changeValue} value={this.state.fields.facebook_link || ''}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Twitter Link</label>
                                            <div className="input-group date">
                                                <span className="input-group-addon"><i className="fa fa-twitter"></i></span>
                                                <input className="form-control" name="twitter_link" placeholder="Enter Twitter Link" onChange={this.changeValue} value={this.state.fields.twitter_link || ''} type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Website Link</label>
                                            <div className="input-group date">
                                                <span className="input-group-addon"><i className="fa fa-globe"></i></span>
                                                <input className="form-control" name="website_link" placeholder="Enter Website Link" onChange={this.changeValue} value={this.state.fields.website_link || ''} type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>LinkedIn Link</label>
                                            <div className="input-group date">
                                                <span className="input-group-addon"><i className="fa fa-linkedin"></i></span>
                                                <input className="form-control" name="linkedIn_link" placeholder="Enter LinkedIn Link" onChange={this.changeValue} value={this.state.fields.linkedIn_link || ''} type="text"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-footer text-right p-r-20">
                                <button type="button" className="btn btn-primary" onClick={this.handleUpdateCompanyProfile}>Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>      
         );
    }
}


export default EditMyProfile;