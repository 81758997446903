import React, { Component } from 'react';
import {Link} from "react-router-dom";
import PostDataWithParam from '../services/PostDataWithParam';
import DateFormat from '../externaljs/DateFormat';
import ConfirmBox from '../externaljs/ConfirmBox';
import Breadcumb from '../externaljs/Breadcrumb';

class ViewStaff extends Component {
    state = {
        staff_detail:[],
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Staff', link: '/independent-mover/dashboard/staff', class: '' },
            { title: 'View Staff', link: '', class: 'active' }
        ]
    }

    componentDidMount(){
        this.ViewStaffNew();
    }

    formatSSN = (ssn) => {
        if (typeof ssn !== 'undefined' && ssn != null) {
            let ssn_new = ssn.replace(/^.{6}/g, '***-**')
            return ssn_new
        } else {
            return '';
        }
    }


    ViewStaffNew = () => {
        PostDataWithParam('ViewStaffNew',{staff_id:this.props.match.params.staff_id})
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ staff_detail: response.data['staff_details'][0]});
                }
            })
    }

    handleBtn = (status) => {
        if (status == 0) {
            return (
                'Activate'
            )
        }else {
            return(
                'Deactivate'
            )
           
        }
    }


    handleStatusShow = (status) => {
        if (status == 0) {
            return (
               
                <p className="m-b-20">Deactive</p>
            )
        }else {
            return(
               
                <p className="m-b-20">Active</p>
            )
           
        }
    }



    ActiveDeactiveStaff = (staff_id,status) => {
        if(status === 1){
            ConfirmBox("You want to deactivate this staff!")
            .then((result) => {
            if (result.value) {
                PostDataWithParam('ActiveDeactiveStaff',{staff_id:staff_id,status:0})
                .then((response) => {
                    let result = response;
                    if (result.success === 1) {
                        this.ViewStaffNew();
                        window.swal('Deactivated!',result.msg,'success')
                    } else {
                        window.swal('Deactivated!',result.msg,'success')
                    } 
                });              
            }
          });       
    }
 else{
    ConfirmBox("You want to activate this staff!")
    .then((result) => {
        if (result.value) {
            PostDataWithParam('ActiveDeactiveStaff',{staff_id:staff_id,status:1})
            .then((response) => {
                let result = response;
                if (result.success === 1) {
                    this.ViewStaffNew();
                    window.swal('Activated!',result.msg,'success')
                } else {
                    window.swal('Activated!', result.msg,'success')
                } 
            });              
        }
      });   
 }
}


    render() {
        return (
            <div className="content-container">
                <div className="row">
                    <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                        <div className="panel panel-flat profile-page">
                            <div className="panel-heading">
                                <div className="heading-elements">
                                    <ul className="icons-list pull-right">
                                        <li className="dropdown">
                                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                <i className="icon-menu9"></i>
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-right">
                                                <li><Link to={{
                                                pathname: '/independent-mover/dashboard/editstaff/'+this.state.staff_detail.id
                                                }}><i className="icon-pencil"></i>Edit Staff</Link></li>
                                                <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.ActiveDeactiveStaff(this.state.staff_detail.id,this.state.staff_detail.status)}><i className="icon-user-block"></i> {this.handleBtn(this.state.staff_detail.status)} Staff</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <h6 className="m-b-0 m-t-0 text-semibold">  <p className="m-b-5">Staff ID <strong>#{this.state.staff_detail.id}</strong></p></h6>
                                <h5 className="panel-title text-success pull-left">{this.state.staff_detail.gender} {this.state.staff_detail.first_name} {this.state.staff_detail.last_name}</h5>
                            </div>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Primary Email Address:</label>
                                                <p className="m-b-20">{this.state.staff_detail.email}</p>
                                                {
                                                    this.state.staff_detail.alternate_email
                                                    &&
                                                    <div>
                                                    <label className="text-bold m-b-0">Secondary Email Address:</label>
                                                    <p className="m-b-20">{this.state.staff_detail.alternate_email}</p>
                                                    </div>
                                                }        
                                                <label className="text-bold m-b-0">Date of Birth :</label>
                                                <p className="m-b-20">
                                                {DateFormat.getFormattedDateTime(this.state.staff_detail.dob,'m/d/Y')}
                                                </p>
                                               
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Phone No:</label>
                                                <p className="m-b-20">{this.state.staff_detail.contact_no}</p>
                                                <label className="text-bold m-b-0">Address:</label>
                                                <p className="m-b-20">{this.state.staff_detail.address}</p>
                                                {
                                                    this.state.staff_detail.ssn
                                                    &&
                                                    <div>
                                                        <label className="text-bold m-b-0">SSN :</label>
                                                        <p className="m-b-20">
                                                        {this.formatSSN(this.state.staff_detail.ssn)}
                                                        </p>
                                                    </div>
                                                }                                                
                                            </div>
                                            <div className="col-md-4">
                                                {
                                                    this.state.staff_detail.alternate_contact_no
                                                    &&
                                                    <div>
                                                    <label className="text-bold m-b-0">Alternate Phone No:</label>
                                                    <p className="m-b-20">{this.state.staff_detail.alternate_contact_no}</p>
                                                    </div>
                                                }   

                                                <label className="text-bold m-b-0">Status :</label>
                                                {this.handleStatusShow(this.state.staff_detail.status)}

                                                {
                                                    this.state.staff_detail.home_contact_no
                                                    &&
                                                    <div>
                                                    <label className="text-bold m-b-0">Home Phone:</label>
                                                    <p className="m-b-20">{this.state.staff_detail.home_contact_no}</p>
                                                    </div>
                                                }  
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default ViewStaff;