import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataFile from '../services/PostDataFile';
import GetData from '../services/GetData';
import PostDataWithParam from '../services/PostDataWithParam';
import { getQueryStringToObject } from '../externaljs/UrlHelpers';
import Storage from '../externaljs/Storage';

class ComposeMessage extends Component {
    state = {
        profiles: [],
        receivers: [],
        fields: {
            user_id: [],
            subject: '',
            message: '',
            attachment: ''
        },
        error: {
            subject_err: '',
            message_err: '',
            user_id_err: ''
        },
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Message', link: '/independent-mover/dashboard/inbox', class: '' },
            { title: 'Compose Message', link: '', class: 'active' }
        ],
        MessaageErr: '',
        MessageStatus: false,
        activeEditor: '',
        queryParams: null,
        sending_message: false,
        editPermission: true,
    }

    subscriptionCheckInterval = null;

    componentDidMount = () => {
        const { search } = this.props.location;

        // When clicking on the message link in the mail's compose, the profile and user will be automatically set to 'queryParams' state.
        if (search) {
            const params = getQueryStringToObject(search);
            this.setState({
                queryParams: {
                    profile_id: atob(params.profile_id),
                    user_id: atob(params.user_id)
                }
            });
        }
        window.$('.select-size-xs').select2({
            containerCssClass: 'select-xs'
        })
        window.$('.select-size-xs-mul').select2({
            containerCssClass: 'select-xs'
        })
        this.GetProfilesForComposeMessage();

        this.subscriptionCheckInterval = setInterval(() => {
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1)
                this.setState({ editPermission: false })
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ editPermission: true })
        }, 1000);
    }

    componentWillUnmount() {
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
    }

    doSubscribe = (e) => {
        window.swal({
            title: "Warning!",
            text: "Please upgrade your membership to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }

    resetError = (cb) => {
        let error = {
            subject_err: '',
            message_err: '',
            user_id_err: ''
        }
        this.setState({ error: error }, () => {
            cb();
        });
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields });
    }

    handleEditorChange = (e) => {
        let fields = this.state.fields;
        fields.message = e.target.getContent();
        this.setState({ fields: fields });
    }

    handleAttachment = (e) => {
        let fields = this.state.fields;
        fields.attachment = e.target.files[0];
        this.setState({ fields: fields });
    }

    GetProfilesForComposeMessage = () => {
        PostDataWithParam('GetProfilesForMessaging', { search_text: '' })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ profiles: response.data }, () => {
                        this.GetReceiverNames();
                        // if query params data exist then autopoulate profile
                        if (this.state.queryParams) {
                            setTimeout(() => {
                                window.$('.select-size-xs').val(this.state.queryParams.profile_id).trigger('change.select2');
                            }, 0)
                        }

                    });
                }
            })
            .catch(err => {
                this.setState({ profiles: [] });
            })
    }

    GetReceiverNames = () => {
        window.$('.select-size-xs').select2({
            containerCssClass: 'select-xs'
        })
            // .off('change.select2')
            .on('change.select2', e => {
                PostDataWithParam('GetReceiverNameForMessaging', { search_text: '', profile_id: e.target.value })
                    .then((response) => {
                        if (response.success === 1) {
                            this.setState({ receivers: response.data }, () => {
                                let fields = this.state.fields;
                                fields.user_id = []
                                this.setState({ fields: fields }, () => {
                                    this.GetReceiverNamesSelectBox();
                                    // if query params data exist then autopoulate user
                                    if (this.state.queryParams) {
                                        setTimeout(() => {
                                            window.$('.select-size-xs-mul').val(this.state.queryParams.user_id).trigger('change.select2');
                                        }, 0)
                                    }
                                });
                            });
                        }
                    })
                    .catch(err => {
                        this.setState({ receivers: [] });
                    })
            });
    }

    GetReceiverNamesSelectBox = () => {
        window.$('.select-size-xs-mul').select2({
            containerCssClass: 'select-xs'
        })
            // .off('change.select2')
            .on('change.select2', e => {
                let fields = this.state.fields;
                fields.user_id.push({ id: e.target.value })
                this.setState({ fields: fields });
            })
            .off('select2:unselect')
            .on('select2:unselect', e => {
                let fields = this.state.fields;
                let tempUserId = fields.user_id.filter((data) => {
                    if (data.id == e.params.data.id)
                        return false
                    else
                        return true
                })
                fields.user_id = tempUserId
                this.setState({ fields: fields })
            })
    }

    handleMessage = () => {
        this.resetError(() => {
            let error = this.state.error;
            if (this.state.fields.user_id.length == 0) {
                error.user_id_err = 'Please choose atleast a name';
            }
            else if (!this.state.fields.subject) {
                error.subject_err = 'Subject is required.';
            }
            else if (!this.state.fields.message) {
                error.message_err = 'Message is required.';
            }
            else {
                let fd = new FormData();
                if (this.state.fields.attachment.name !== undefined) {
                    fd.append('attachment', this.state.fields.attachment, this.state.fields.attachment.name);
                }
                fd.append('subject', this.state.fields.subject);
                fd.append('message', this.state.fields.message);
                fd.append('user_id', JSON.stringify(this.state.fields.user_id));
                this.setState({ sending_message: true }, () => {
                    PostDataFile('ComposeMessage', fd)
                        .then((response) => {
                            if (response.success === 1) {
                                this.setState({ MessaageErr: response.msg, sending_message: false }, () => {
                                    window.swal('Success!', response.msg, 'success');
                                    this.state.activeEditor.setContent('');
                                    this.setState({ MessageStatus: true });
                                });
                            } else {
                                this.setState({ MessaageErr: response.msg, sending_message: false });
                            }
                        })
                        .catch(err => {
                            this.setState({ MessaageErr: 'Oops! Something went wrong.', MessageStatus: false, sending_message: false });
                        })
                })
            }
            this.setState({ error: error });
        });
    }

    render() {
        if (this.state.MessageStatus) {
            return <Redirect to={{
                pathname: '/independent-mover/dashboard/inbox'
            }} />
        }

        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="panel panel-flat profile-page">
                        <div className="panel-heading">
                            <h5 className="panel-title">Compose Message</h5>
                        </div>
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>User Type</label>
                                        <select data-placeholder="Please specify a user type" className="select-size-xs">
                                            <option value=""></option>
                                            {this.state.profiles.map(profile =>
                                                <option key={profile.id} value={profile.id}>{profile.title}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Specific User</label>
                                        <select data-placeholder="Please specify a specific user" className="select-size-xs-mul" multiple="multiple">
                                            <option value=""></option>
                                            {this.state.receivers.map(receiver =>
                                                <option key={receiver.user_id} value={receiver.user_id}>{receiver.full_name}</option>
                                            )}
                                        </select>
                                        <p className="text-danger help-block">{this.state.error.user_id_err}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Subject</label>
                                <input type="text" className="form-control" placeholder="Enter Subject." name="subject" onChange={this.changeValue} />
                                <p className="text-danger help-block">{this.state.error.subject_err}</p>
                            </div>
                            <div className="form-group">
                                <Editor
                                    placeHolder="Write Something ..."
                                    initialValue=""
                                    init={{
                                        setup: editor => {
                                            this.setState({ activeEditor: editor })
                                        },
                                        menubar: false,
                                        statusbar: false,
                                        toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                    }}
                                    onChange={this.handleEditorChange}
                                    className="form-control textarea-editor"
                                    name="messageBody"
                                    id='messageBody'
                                />
                                <p className="text-danger help-block">{this.state.error.message_err}</p>
                                <label className="file-label">
                                    <input className="file-input" name="attachment" id="attachment" type="file" onChange={this.handleAttachment} />
                                    <span className="text-muted">
                                        <i className="icon icon-attachment"></i> Attach File
                                    </span>
                                </label>
                                <p>
                                {this.state.fields.attachment.name != ""  && this.state.fields.attachment.name}
                                    </p>
                            </div>

                            {this.state.editPermission ?
                                <React.Fragment>
                                    {this.state.sending_message ?
                                        <div className="text-right">
                                            <button type="button" className="btn btn-primary" disabled={true}>Processing</button>
                                        </div>
                                        :
                                        <div className="text-right">
                                            <button type="button" className="btn btn-default m-r-10">Cancel</button>
                                            <button type="button" className="btn btn-primary" onClick={this.handleMessage}>Submit</button>
                                        </div>
                                    }
                                </React.Fragment>
                                :
                                <div className="text-right">
                                    <button type="button" className="btn btn-default m-r-10">Cancel</button>
                                    <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} className="btn btn-primary">Submit</Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ComposeMessage;