import React, { Component, Fragment } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import DateFormat from '../externaljs/DateFormat';
import { FileIcon, FileSvg, FileType } from '../externaljs/FileType';
import Storage from '../externaljs/Storage';
import Urls from '../externaljs/Urls';
import FileUploadWithProgress from '../services/FileUploadWithProgress';
const database = Urls.firebase.database();
export default class ChatBox extends Component {
  constructor(props) {
    super(props);
    const login_user_data = JSON.parse(Storage.get('imnLoginData'));
    this.state = {
      order_id: this.props.order_id,
      order_no: this.props.order_no,
      sender_info: {
        user_id: login_user_data.id,
        profile_image: login_user_data.profile_image || '',
        first_name: login_user_data.first_name || '',
        last_name: login_user_data.last_name || '',
        profile_id: login_user_data.profile_id
      },
      receiver_info: this.props.receiver_info,
      typed_message: "",
      messages: [],
      chat_box_id: `${this.props.order_id}_${this.props.receiver_info.user_id}`,
      is_fetch_from_sender_db: false,
      selectFile: null,
      selectFileType: null,
      selectFileURL: null,
      isProccesingFileUpload: false,
      uploadPercentage: 0,
      isSending: false,
      fileErrorMsg: null,
      fileUploadLimit: 5, //MB
      isActiveReciverUser: null,
      lastActiveReciverUserTime: ""
    }
  }

  componentDidMount() {
    this.onMessageListener();
    this.onUserActiveListener();
  }
  onUserActiveListener = () => {
    database
      .ref(`/active_users/${this.state.receiver_info.user_id}`)
      .on("value", (snapshot) => {
        let reciver_current_chat_info = snapshot.val();
        if (reciver_current_chat_info) {
          console.log("reciver_current_chat_info ", reciver_current_chat_info);
          let lastActiveReciverUserTime = "";
          if (!!reciver_current_chat_info.timestamp && !reciver_current_chat_info.is_active) {
            lastActiveReciverUserTime = window.moment(reciver_current_chat_info.timestamp, "YYYY-MM-DD HH:mm:ss a").format("MM-DD-YYYY, hh:mm:ss a")
          }
          this.setState({
            isActiveReciverUser: reciver_current_chat_info.is_active,
            lastActiveReciverUserTime
          });
        }
      })
  }
  onMessageListener = (user) => {
    const { sender_info, receiver_info, order_id } = this.state;
    // if (!is_fetch_from_sender_db) {
    //     let sender_db = database.ref(`/chat/order_${sender_info.user_id}_${order_id}_${receiver_info.user_id}`);
    //     sender_db.once("value", async () => {
    //         let data = snapshot.val();
    //         console.log("snapshot from sender_db", data)
    //         await this.setState({ messages: Object.values(data), is_fetch_from_sender_db: true });
    //     })
    // }
    // let receiver_db = database.ref(`/chat/order_${receiver_info.user_id}_${order_id}_${sender_info.user_id}`);
    let sender_db = database.ref(
      `/chat/order_${sender_info.user_id}_${order_id}_${receiver_info.user_id}`
    );
    sender_db.on("value", async (snapshot) => {
      let data = snapshot.val();
      console.log(
        "%c MyReloPro Helper snapshot from receiver_db",
        "color:green",
        data
      );
      if (data)
        this.setState({ messages: Object.values(data) }, () => {
          window
            .$(".chat-body")
            .animate(
              { scrollTop: window.$(".chat-body")[0].scrollHeight },
              "slow"
            );
        });
    });
  };
  renderMessages() {
    const { sender_info, messages } = this.state;
    if (messages.length > 0) {
      let date = "";
      let flag = 0;
      let time = "";
      let tempMessages = messages.map((msg_info, i) => {
        if (msg_info.date != date) {
          flag = 1;
          date = msg_info.date;
        } else {
          flag = 0;
        }
        if (msg_info.timestamp) {
          time = window.moment(msg_info.timestamp).format("LT");
        }
        // video related info show
        if (msg_info.channelName && msg_info.channelName !== "") {
          return (
            <React.Fragment key={"chat-messages-" + i}>
              {flag == 1 && (
                <div className="conversation-date">{msg_info.date}</div>
              )}
              {msg_info.status === "1" &&
                sender_info.user_id != msg_info.from_id ? (
                <div
                  className="conversation-date"
                  onClick={() =>
                    this.props.joinVideoCall(msg_info.channelName, true)
                  }
                >
                  <div className="chat-text text-center-callend">
                    <img
                      src="assets/images/icons/video-camera-red.svg"
                      width="20"
                      className="m-r-5"
                    />
                    <span className="link-text-curser-style">
                      <b>Click here</b> to join video call
                        </span>
                  </div>
                </div>
              ) : null}
              {msg_info.status !== "1" ? (
                <div className="conversation-date">
                  <div className="chat-text text-center-callend">
                    <img
                      src="assets/images/icons/video-camera-red.svg"
                      width="20"
                      className="m-r-5"
                    />
                    <b>Call ended!</b>
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          );
        }
        // normal chat show
        return (
          <React.Fragment key={"chat-messages-" + i}>
            {flag == 1 && (
              <div className="conversation-date">{msg_info.date}</div>
            )}
            {/* LEFT SIDE (RECIVER)*/}
            {sender_info.user_id != msg_info.from_id && (
              <div className="chat-item">
                <div className="chat-user-pic">
                  <img
                    src={
                      sender_info.user_id != msg_info.from_id
                        ? msg_info.sender_profile_image
                          ? msg_info.sender_profile_image
                          : "assets/images/default_avatar.jpg"
                        : msg_info.receiver_profile_image
                          ? msg_info.receiver_profile_image
                          : "msg_info.receiver_profile_image"
                    }
                    alt=""
                  />
                </div>
                <div className="chat-text">
                  {msg_info.file_name && msg_info.file_name != "" ? (
                    <Fragment>
                      <span>
                        <div
                          className={
                            "file-wrapper " + FileType(msg_info.file_type)
                          }
                          onClick={() =>
                            this.handleFileView(msg_info.file_name)
                          }
                        >
                          {FileSvg(
                            msg_info.file_type,
                            this.getChatFileURL(msg_info.file_name)
                          )}
                          {/* <div className="file-title"><a href={this.getChatFileURL(msg_info.file_name)} download={`${msg_info.file_name}`}><i className={FileIcon(msg_info.file_type) + " position-left text-primary"}></i> {sender_info.first_name}</a>
                                                    </div> */}
                        </div>
                        {msg_info.message}
                      </span>
                      <p className="text-slate-300">{time}</p>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <span>{msg_info.message}</span>
                      <p className="text-slate-300">{time}</p>
                    </Fragment>
                  )}
                </div>
              </div>
            )}
            {/* RIGHT SIDE (SENDER)*/}
            {sender_info.user_id == msg_info.from_id && (
              <div className="chat-item sent">
                <div className="chat-text">
                  {msg_info.file_name && msg_info.file_name != "" ? (
                    <Fragment>
                      <span>
                        <div
                          className={
                            "file-wrapper " + FileType(msg_info.file_type)
                          }
                          onClick={() =>
                            this.handleFileView(msg_info.file_name)
                          }
                        >
                          {FileSvg(
                            msg_info.file_type,
                            this.getChatFileURL(msg_info.file_name)
                          )}
                          {/* <div className="file-title"><a href={this.getChatFileURL(msg_info.file_name)} download={`${msg_info.file_name}`}><i className={FileIcon(msg_info.file_type) + " position-left text-primary"}></i> {sender_info.first_name}</a>
                                                    </div> */}
                        </div>
                        {msg_info.message}
                      </span>
                      <p className="text-slate-300">{time}</p>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <span>{msg_info.message}</span>
                      <p className="text-slate-300">{time}</p>
                    </Fragment>
                  )}
                </div>
                <div className="chat-user-pic">
                  <img
                    src={
                      sender_info.user_id != msg_info.from_id
                        ? msg_info.receiver_profile_image
                          ? msg_info.receiver_profile_image
                          : "assets/images/default_avatar.jpg"
                        : msg_info.sender_profile_image
                          ? msg_info.sender_profile_image
                          : "assets/images/default_avatar.jpg"
                    }
                    alt=""
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        );
      });

      return tempMessages;
    } else {
      return (
        <div className="text-center p-t-15">
          <p className="f-s-14" style={{ color: "#32465a" }}>
            <b>No messages found</b>
          </p>
        </div>
      );
    }
  }
  getNotificationData = () => {
    const {
      sender_info,
      receiver_info,
      order_id,
      typed_message,
      order_no,
      selectFile
    } = this.state;
    return {
      accepted_user_id: "",
      activeFlag: "",
      approval_time: "",
      field_name: "",
      flag: "",
      hire_by_profile_id: "",
      hire_by_user_id: "",
      id: order_id,
      message: typed_message,
      msg_id: "",
      msg_time: window.moment().format("YYYY-MM-DD HH:mm:ss"),
      notification_type: "orderChat",
      order_id: order_no,
      order_request_detail_id: "",
      receiver_user_id: receiver_info.user_id,
      request_to_profile_id: "",
      request_to_user_id: "",
      sender_full_name: `${sender_info.first_name} ${sender_info.last_name}`,
      sender_profile_name: "Mover",
      sender_user_id: sender_info.user_id,
      status: "",
      subject: "",
      subscription_id: "",
      tab_id: "",
      time: window.moment().format("YYYY-MM-DD HH:mm:ss"),
      type: "",
      read_status:0
    };
  };
  sendMessage = async () => {
    const {
      sender_info,
      receiver_info,
      order_id,
      typed_message,
      order_no,
      selectFile
    } = this.state;
    let send_data = {
      order_id: order_id,
      from_id: sender_info.user_id,
      to_id: receiver_info.user_id,
      message: typed_message,
      receiver_name: receiver_info.name,
      receiver_profile_image: receiver_info.profile_image,
      sender_profile_image: sender_info.profile_image,
      date: DateFormat.getFormattedDateTime(new Date(), "m/d/Y"),
      channelName: "",
      status: "",
      timestamp: window.moment().format("YYYY-MM-DD HH:mm:ss"),
      file_id: "",
      file_name: "",
      file_type: ""
    };
    let notificationData = this.getNotificationData();

    let uploadFileRes = null;
    if (selectFile) {
      this.setState({ isSending: true });
      uploadFileRes = await this.uploadFile();
      if (uploadFileRes) {
        send_data.file_id = uploadFileRes.file_id;
        send_data.file_name = uploadFileRes.file_name;
        send_data.file_type = uploadFileRes.file_type;
      }
    }
    if (typed_message != "" || uploadFileRes) {
      // Get reciver current chat info from firebase
      database
        .ref(`/active_users/${receiver_info.user_id}`)
        .once("value", (snapshot) => {
          let reciver_current_chat_info = snapshot.val();
          console.log("reciver_current_chat_info", reciver_current_chat_info);
          // here check reciver current chat info match to semder info if match then not set unread message
          if (
            reciver_current_chat_info &&
            reciver_current_chat_info.is_active &&
            reciver_current_chat_info.chat_order_id === order_id &&
            reciver_current_chat_info.chat_reciver_id === sender_info.user_id
          ) {
            database
              .ref(
                `/chat/order_${sender_info.user_id}_${order_id}_${receiver_info.user_id}`
              )
              .push(send_data);
            database
              .ref(
                `/chat/order_${receiver_info.user_id}_${order_id}_${sender_info.user_id}`
              )
              .push(send_data);
          } else {
            let msg_count = 1;
            if (receiver_info.user_id == 1) {
              // For Super Admin
              database
                .ref(
                  `/chat/order_${sender_info.user_id}_${order_id}_${receiver_info.user_id}`
                )
                .push(send_data);
              database
                .ref(
                  `/chat/order_${receiver_info.user_id}_${order_id}_${sender_info.user_id}`
                )
                .push(send_data);
              // set notification_list from admin
              let keyName = `/order_chat_${sender_info.user_id}_${order_id}_${receiver_info.user_id}`;
              database
                .ref(
                  `/notifications/notification_list/user_${receiver_info.user_id}/${keyName}`
                )
                .set(notificationData);
            } else {
              database
                .ref(
                  `/unread_messages/${receiver_info.user_id}/${order_id}_${sender_info.user_id}`
                )
                .once("value", (snapshot) => {
                  let data = snapshot.val();
                  console.log("Unread Msg Data", data);
                  if (data) msg_count = data.msg_count + 1;
                  // set unread message count
                  database
                    .ref(
                      `/unread_messages/${receiver_info.user_id}/${order_id}_${sender_info.user_id}`
                    )
                    .set({
                      user_id: sender_info.user_id,
                      profile_id: sender_info.profile_id,
                      name: `${sender_info.first_name} ${sender_info.last_name}`,
                      profile_image: sender_info.profile_image,
                      msg_count,
                      order_id,
                      order_no,
                      timestamp: window.moment().format("YYYY-MM-DD HH:mm:ss")
                    });
                  database
                    .ref(
                      `/chat/order_${sender_info.user_id}_${order_id}_${receiver_info.user_id}`
                    )
                    .push(send_data);
                  database
                    .ref(
                      `/chat/order_${receiver_info.user_id}_${order_id}_${sender_info.user_id}`
                    )
                    .push(send_data);
                });
            }
          }
        });
      this.setState({
        typed_message: "",
        selectFile: null,
        isSending: false,
        fileErrorMsg: null
      });
    }
  };
  handleKeyPress = (e) => {
    if (e.which == 13 && !e.shiftKey) {
      this.sendMessage();
      e.preventDefault();
    }
  };
  handleChange = (e) => {
    this.setState({ typed_message: e.target.value });
  };

  getChatFileURL = (fileName) => {
    return Urls.chatFile + fileName;
  };

  handleFileChnage = (e) => {
    if (e.target.files.length > 0) {
      let fileSize = e.target.files[0].size / 1024 / 1024;
      const { fileUploadLimit } = this.state;
      if (fileSize <= fileUploadLimit) {
        let file = e.target.files[0];
        let ext = file.name.split(".").pop();

        let fileReader = new FileReader();
        fileReader.onload = (e) => {
          this.setState({ selectFileURL: e.target.result });
        };
        fileReader.readAsDataURL(file);
        this.setState({
          selectFile: e.target.files[0],
          selectFileType: ext,
          fileErrorMsg: null
        });
      } else
        this.setState({
          fileErrorMsg: `File size must ${fileUploadLimit}mb or below`,
          selectFile: null
        });
    } else {
      this.setState({ selectFile: null, fileErrorMsg: null });
    }
  };
  uploadFile = () => {
    const {
      sender_info,
      receiver_info,
      order_id,
      typed_message,
      order_no
    } = this.state;
    this.setState({ isProccesingFileUpload: true }, () => {
      window
        .$(".chat-body")
        .animate({ scrollTop: window.$(".chat-body")[0].scrollHeight }, "slow");
    });
    let formData = new FormData();
    formData.append("order_id", order_id);
    formData.append("user_from", sender_info.user_id);
    formData.append("user_to", receiver_info.user_id);
    formData.append("file[]", this.state.selectFile);
    return FileUploadWithProgress("UploadChatFiles", formData, (e) => {
      var percent = Math.round((e.loaded / e.total) * 100);
      this.setState({ uploadPercentage: percent });
    })
      .then((response) => {
        if (response.success == 1) {
          this.setState({
            isProccesingFileUpload: false,
            uploadPercentage: 0,
            selectFile: null
          });
          return response.data;
        } else {
          throw new Error("Not able to upload.");
        }
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({
          isProccesingFileUpload: false,
          selectFile: null,
          uploadPercentage: 0,
          isSending: false
        });
        window.swal("Error!", "Something went wrong.", "error");
      });
  };

  handleFileView = (fileName) => {
    window.open(this.getChatFileURL(fileName), "_blank");
  };

  removeSelectFile = () => {
    this.setState({
      selectFile: null,
      selectFileType: null,
      selectFileURL: null,
      fileErrorMsg: null
    });
  };

  render() {
    const {
      sender_info,
      receiver_info,
      typed_message,
      order_id,
      order_no,
      chat_box_id,
      fileErrorMsg,
      isActiveReciverUser,
      lastActiveReciverUserTime
    } = this.state;
    const { closeChatBox, openVideoChatWindow } = this.props;
    return (
      <div
        className="popup-chat-window"
        data-chatid={order_id + "_" + receiver_info.user_id}
      >
        <div className="chat-header">
          <a href="javascript:void(0)">
            <h6 className="chat-username">{receiver_info.name}</h6>
            <span>#{order_no}</span><br/>{isActiveReciverUser !== null && (isActiveReciverUser ?
              <span className="text-success"><i className="icon-circle-small"></i>Active Now</span>
              : <span className="text-warning" style={{ whiteSpace: 'nowrap' }}><i className="icon-circle-small"></i>Last seen: {lastActiveReciverUserTime}</span>
            )}
          </a>

          <div className="chat-actions">
            {/* video chat */}
            <span
              id="join"
              data-toggle="tooltip"
              data-placement="top"
              title="Video Chat"
              className="m-r-10"
              style={{ cursor: "pointer" }}
              onClick={() =>
                openVideoChatWindow({
                  order_id,
                  order_no,
                  chat_box_id,
                  receiver_info
                })
              }
            >
              {/* <i className="icon-video-camera3 text-primary f-s-20"></i> */}
              <img
                src="assets/images/icons/dark-blue/video-camera.svg"
                width="30"
              />
            </span>
            {/* video chat end */}
            {/* <a href="#"><i className="icon-phone2 text-primary m-l-10 f-s-22"></i></a> */}
            <a
              href="javascript:void(0)"
              className="close-chat"
              onClick={() => this.props.closeChatBox(chat_box_id)}
            >
              <i className="icon-cross"></i>
            </a>
          </div>
        </div>
        <div className="chat-body">
          {this.renderMessages()}
          {/* Upload file processing */}
          {this.state.isProccesingFileUpload && (
            <div className="chat-item sent">
              {/* message */}
              <div className="chat-text">
                <span>
                  {/* Image */}
                  <div className="file-wrapper m-b-10">
                    <div className="progress-file-icon">
                      <img src="assets/images/blur-inventory.jpg" alt="" />
                      <div className="inventory-progess-bar">
                        <CircularProgressbar
                          value={this.state.uploadPercentage}
                          text={`${this.state.uploadPercentage}%`}
                        />
                      </div>
                    </div>
                  </div>
                  {typed_message}
                </span>
                {/* <p className="text-slate-300">{time}</p> */}
              </div>
              <div className="chat-user-pic">
                <img
                  src={
                    sender_info.profile_image
                      ? sender_info.profile_image
                      : "assets/images/default_avatar.jpg"
                  }
                  alt=""
                />
              </div>
            </div>
          )}
          {/* Upload file processing End*/}
        </div>
        {this.state.selectFile && !this.state.isProccesingFileUpload ? (
          <div className="chatfile-upload p-10">
            <div className="media">
              <div className="media-left">
                {["png", "jpg", "jpeg"].indexOf(
                  this.state.selectFileType.toLowerCase()
                ) !== -1 ? (
                  <img src={this.state.selectFileURL} width="40" alt="Image" />
                ) : (
                  <div style={{ width: 40 }}>
                    {FileSvg(
                      this.state.selectFileType,
                      this.state.selectFileURL
                    )}
                  </div>
                )}
              </div>
              <div className="media-body text-left">
                <p className="m-t-10">{this.state.selectFile.name}</p>
              </div>
            </div>
            <div className="file-cross" onClick={this.removeSelectFile}>
              <i className="icon-cross3"></i>
            </div>
          </div>
        ) : null}
        <form action="" method="POST">
          <div className="chat-footer">
            <textarea
              className="form-control"
              id={"emojiId" + order_id + "_" + receiver_info.user_id}
              placeholder="Type your message..."
              value={typed_message}
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChange}
            />

            <div className="footer-bottom">
              <label className="file-attach-label m-r-10">
                <i className="icon-attachment text-slate-700"></i>
                <input
                  id="FileInput"
                  name=""
                  type="file"
                  onChange={this.handleFileChnage}
                />
              </label>
              <div className="emoji-icon-container2"></div>
             
              <button
                type="button"
                className="btn btn-success btn-xs text-uppercase"
                onClick={this.sendMessage}
                disabled={this.state.isSending}
              >
                {this.state.isSending ? `Sending...` : `Send`}{" "}
                <i className="icon-arrow-right8"></i>
              </button>
            </div>
            {fileErrorMsg && (
              <div className="d-block text-left">
                <hr className="m-0" />
                <p className="text-danger m-0 p-l-10 p-r-10 p-t-5 p-b-5">
                  {fileErrorMsg}
                </p>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}
