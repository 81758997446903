import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import DateFormat from '../externaljs/DateFormat';

class EdcOrderDetails extends Component {
    state = {
        details: {},
        loading: false
    }
    database = null;
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        if (this.props.location && this.props.location.state) {
            this.setState({ details: this.props.location.state })
        } else {
            this.goBack();
        }
    }
    goBack(e = null) {
        if (e) e.preventDefault();
        // this.props.history.push({
        //     pathname: '/moving-company/dashboard'
        // })
        this.props.history.goBack();
    }
    getEDCPickupAddress = (order) => {
        let address = ""
        address += order.PickupStreet ? `${order.PickupStreet}, ` : ``;
        address += order.PickupCity ? `${order.PickupCity}, ` : ``;
        address += `${order.PickupState}`
        return address;
    }
    getEDCDeliveryAddress = (order) => {
        let address = ""
        address += order.DeliveryStreet ? `${order.DeliveryStreet},` : ``;
        address += order.DeliveryCity ? `${order.DeliveryCity},` : ``;
        address += `${order.DeliveryState}`
        return address;
    }
    render() {

        return (
            <section className="content-container">
                <div className="container">
                    <div className="clearfix">
                        <h1 className="m-b-0 pull-left">Move Details</h1>
                    </div>
                    <div className="clearfix">
                        <a href="#" className="inline-block text-grey-400 m-b-20" onClick={this.goBack.bind(this)}><i className="icon-arrow-left8 text-grey-400"></i> Back to List</a>
                    </div>
                    <div className="row">
                        {
                            this.state.loading
                            &&
                            <div className="col-md-12">
                                <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                            </div>
                        }
                        {
                            !this.state.loading
                            &&
                            <React.Fragment>
                                <div className="col-md-12">
                                    <div className="panel panel-default Move-details-panel" id="order-summary">
                                        <div className="panel-heading">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <p className="m-0">
                                                        <small className="text-semi-bold text-uppercase">Shipment #</small><br />
                                                        <span className="text-warning f-s-16">{this.state.details.ShipmentId}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="panel-body">
                                            <h6 className="text-success">Customer Basic Info</h6>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">SCAC</label>
                                                    <p className="m-b-20">{this.state.details.SCAC || "---"}
                                                    </p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">First Name</label>

                                                    <p className="m-b-20">{this.state.details.FirstName || "---"}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Last Name</label>

                                                    <p className="m-b-20">{this.state.details.LastName || "---"}</p>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Phone</label>

                                                    <p className="m-b-20">{this.state.details.Phone || "---"}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Email</label>

                                                    <p className="m-b-20">{this.state.details.Email || "---"}</p>
                                                </div>

                                            </div>
                                            <hr />
                                            <h6 className="text-success m-t-0">Service Info</h6>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Status</label>
                                                    <p className="m-b-20">{this.state.details.Status || "---"}
                                                    </p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Type</label>
                                                    <p className="m-b-20">{this.state.details.Type || "---"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Pack Date</label>

                                                    <p className="m-b-20">{this.state.details.PackDate || "---"}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Pickup Date</label>

                                                    <p className="m-b-20">{this.state.details.PickupDate || "---"}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Delivery Date</label>
                                                    <p className="m-b-20">{this.state.details.DeliveryDate || "---"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Service Pickup Address</label>

                                                    <p className="m-b-20">{this.getEDCPickupAddress(this.state.details)}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Service Delivery Address</label>

                                                    <p className="m-b-20">{this.getEDCDeliveryAddress(this.state.details)}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Code Of Service</label>
                                                    <p className="m-b-20">{this.state.details.CodeOfService || "---"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Market</label>

                                                    <p className="m-b-20">{this.state.details.Market || "---"}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Origin GBLOC</label>

                                                    <p className="m-b-20">{this.state.details.OriginGBLOC || "---"}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Destination GBLOC</label>
                                                    <p className="m-b-20">{this.state.details.DestinationGBLOC || "---"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Booked Weight</label>

                                                    <p className="m-b-20">{this.state.details.BookedWeight || "---"}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Actual Net Weight</label>

                                                    <p className="m-b-20">{this.state.details.ActualNetWeight || "---"}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Actual Gross Weight</label>
                                                    <p className="m-b-20">{this.state.details.ActualGrossWeight || "---"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                </div>
            </section>
        );
    }
}

export default EdcOrderDetails;