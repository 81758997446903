import React, { Component } from 'react';
import PostDataWithParam from '../services/PostDataWithParam';
import Breadcumb from '../externaljs/Breadcrumb';
import DateFormat from '../externaljs/DateFormat';
import { FileType, FileSvg, FileIcon } from '../externaljs/FileType';
import Storage from '../externaljs/Storage';
import { Link } from 'react-router-dom';
class ClosedOrderDetails extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'ClosedReloOrders', link: '/independent-mover/dashboard/closed-orders', class: '' },
            { title: 'Close Proposal Details', link: '', class: 'active' }
        ],
        id: '',
        pathname: '',
        type: '',
        details: {
            request_start_date: '',
            request_end_date: '',
            booked: {
                helper: [],
                driver: [],
                mover: []
            },
            invited: {
                helper: [],
                driver: [],
                mover: []
            },
            offer_from: {
                contact_no: "",
                email: "",
                organization_name: ""
            }
        },
        file_group: 1,
        files: [],
        conversationCustomerList: [],
        conversationHelperList: [],
        conversationDriverList: [],
        conversationMoverList: [],
        conversationAdminList: [],
        message: '',
        attachment: [],
        order_type: '',
        subscription_id: 0,
        proccesing: false,
        loading: false,
        helper_details: {
            address_type: '',
            address: ''
        },
        tempItem: {
            conversations: []
        },
        ratings: [{
            review_to_user_id: "", rate: "", public_review: "", private_review: ""
        }],
        totalCloseMsg: {
            helperTotalMsg: 0,
            moverTotalMsg: 0,
            driveTotalMsg: 0,
            adminTotalMsg: 0
        },
        menu: [],
        custom_profile: [],
        staff_user_id: '',
        custom_menu: [],
        tabid: 'order-summary',
        editPermission: true,
        admin_details_msg: [],

    }

    subscriptionCheckInterval = null;

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({ id: this.props.location.state.id, type: this.props.location.state.type }, () => {
                if (this.state.type == 'accepted') {
                    this.setState({ order_type: 1 }, () => {
                        this.getConversationMoverList();
                        this.getConversationHelperList();
                        this.getConversationAdminList();
                        this.getConversationDriverList();
                        this.getConversationCustomerList();

                    });
                } else {
                    this.setState({ order_type: 2 }, () => {
                        this.getConversationMoverList();
                        this.getConversationHelperList();
                        this.getConversationAdminList();
                        this.getConversationDriverList();
                        this.getConversationCustomerList();
                    });
                }
                this.orderDetails();
                this.getFiles();
            }
            )
        } else {
            this.props.history.goBack();
        }
        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        let custom_menu = [];
        if (staff_user_id != "") {
            custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
        }
        this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
            // console.log(this.state.custom_menu);
            // console.log(this.state.staff_user_id);
        });
        this.subscriptionCheckInterval = setInterval(() => {
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1)
                this.setState({ editPermission: false })
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ editPermission: true })
        }, 1000);
    }

    componentWillUnmount() {
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
    }

    orderDetails() {
        this.setState({ loading: true }, () => {
            PostDataWithParam('GetOrderDetailsForMovers', { order_id: this.state.id, type: this.state.type })
                .then(response => {
                    if (response.success == 1) {
                        // let admin_details_msg= JSON.parse(response.data[0].admin_details)
                        this.setState({ details: response.data[0], admin_details_msg: [response.data[0].admin_details], loading: false }, () => {
                            // console.log('admin',this.state.admin_details_msg);
                            // console.log('aaa', response.data[0].admin_details);
                        })
                    } else {
                        this.setState({
                            details: {
                                booked: [],
                                invited: []
                            }, loading: false
                        })
                    }
                })
                .catch(err => {
                    this.setState({
                        details: {
                            booked: [],
                            invited: []
                        }, loading: false
                    })
                })
        })
    }

    goBack(e) {
        e.preventDefault();
        this.props.history.push({
            pathname: this.state.pathname
        })
    }

    /**
    * Files
    */

    setFileGroup(file_group, e) {
        e.preventDefault();
        this.setState({ file_group: file_group }, () => {
            this.getFiles();
        });
    }

    getFiles() {
        PostDataWithParam('GetOfferFiles', { order_request_detail_id: this.state.id, file_group: this.state.file_group })
            .then(response => {
                if (response.success == 1) {
                    this.setState({ files: response.data, proccesing: false });
                } else {
                    this.setState({ files: [], proccesing: false });
                }
            })
            .catch(err => {
                this.setState({ files: [], proccesing: false });
            });
    }
    getFileName(file) {
        let files = file.request_file.split('/');
        return files[files.length - 1];
    }
    renderFiles() {
        if (this.state.proccesing) {
            return <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Uploading. Please wait...</div>
        } else {
            if (this.state.files.length > 0) {
                let files = this.state.files.map((file, i) => {
                    return (
                        <li key={'item-' + i}>
                            <div className={"file-wrapper " + FileType(file.file_type)}>
                                <div className="options">

                                </div>
                                {FileSvg(file.file_type, file.request_file)}
                                <div className="file-title"><a href={file.request_file}><i className={FileIcon(file.file_type) + " position-left text-primary"}></i> {this.getFileName(file)}</a></div>
                            </div>
                        </li>

                    )
                });

                return files;
            } else {
                return <h6 className="alert alert-primary no-border m-0">No files were found.</h6>
            }
        }
    }


    renderDriverConversations() {
        if (this.state.conversationDriverList.length > 0) {
            let conversationDriverList = this.state.conversationDriverList.map((item, i) => {
                return (
                    <div className="panel panel-flat profile-page panel-collapsed" key={'driver-' + i}>
                        <div className="panel-heading">
                            <h5 className="panel-title">{item.full_name} <span className="badge badge-success">{this.state.totalCloseMsg.driveTotalMsg = item.msgCount}</span></h5>
                            <div className="heading-elements">
                                <ul className="icons-list">
                                    <li><a href="javascript:void(0)" data-action="collapse" onClick={this.getMessages.bind(this, item.conversations_user_id, 'driver', item, i, 'conversationDriverList')}></a></li>
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div className="panel-body">
                            {this.renderMessages(item.conversations)}
                        </div>
                    </div>
                )
            })
            return conversationDriverList
        } else {
            return <h6 className="alert alert-primary border-none m-0">No Drivers Found.</h6>
        }
    }

    renderMoverConversations() {
        if (this.state.conversationMoverList.length > 0) {
            let conversationMoverList = this.state.conversationMoverList.map((item, i) => {
                return (
                    <div className="panel panel-flat profile-page panel-collapsed" key={'mover-' + i}>
                        <div className="panel-heading">
                            <h5 className="panel-title">{item.full_name} <span className="badge badge-success">{this.state.totalCloseMsg.moverTotalMsg = item.msgCount}</span></h5>
                            <div className="heading-elements">
                                <ul className="icons-list">
                                    <li><a href="javascript:void(0)" data-action="collapse" onClick={this.getMessages.bind(this, item.conversations_user_id, 'mover', item, i, 'conversationMoverList')}></a></li>
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div className="panel-body">
                            {this.renderMessages(item.conversations)}
                        </div>
                    </div>
                )
            })
            return conversationMoverList
        } else {
            return <h6 className="alert alert-primary no-border">No Movers Found.</h6>
        }
    }
    getMessages(user_id, flag, item, index, stateName, e) {
        let conversationPeopleList;

        if (stateName != "") {

            if (item.nodeUrl != "") {
                this.database.ref(item.nodeUrl).remove();
            }

            let tempState = this.state[stateName];

            for (let i = 0; i < tempState.length; i++) {
                if (i == index) {
                    tempState[i].msgCount = 0
                }
            }

            this.setState({ [stateName]: tempState });
        }

        PostDataWithParam('GetOrderConversationsMsg', { order_request_detail_id: this.state.id, conversations_user_id: user_id })
            .then(response => {
                if (response.success == 1) {
                    if (flag == 'helper')
                        conversationPeopleList = this.state.conversationHelperList;
                    else if (flag == 'driver')
                        conversationPeopleList = this.state.conversationDriverList;
                    else if (flag == 'mover')
                        conversationPeopleList = this.state.conversationMoverList;
                    else if (flag == 'admin')
                        conversationPeopleList = this.state.conversationAdminList;

                    let tempList = conversationPeopleList.map((item, i) => {
                        if (item.conversations_user_id == user_id) {
                            let tempItem = item;
                            tempItem.conversations = response.data;
                            return tempItem;
                        } else {
                            return item;
                        }
                    });

                    if (flag == 'helper') {
                        this.setState({ conversationHelperList: tempList })
                    }
                    else if (flag == 'driver') {
                        this.setState({ conversationDriverList: tempList })
                    }
                    else if (flag == 'mover') {
                        this.setState({ conversationMoverList: tempList })
                    }
                    else if (flag == 'admin') {
                        this.setState({ conversationAdminList: tempList })
                    }

                } else {
                    if (flag == 'helper')
                        conversationPeopleList = this.state.conversationHelperList;
                    else if (flag == 'driver')
                        conversationPeopleList = this.state.conversationDriverList;
                    else if (flag == 'mover')
                        conversationPeopleList = this.state.conversationMoverList;
                    else if (flag == 'admin')
                        conversationPeopleList = this.state.conversationAdminList;

                    let tempList = conversationPeopleList.map((item, i) => {
                        if (item.conversations_user_id == user_id) {
                            let tempItem = item;
                            tempItem.conversations = [];
                            return tempItem;
                        } else {
                            return item;
                        }
                    });

                    if (flag == 'helper') {
                        this.setState({ conversationHelperList: tempList })
                    }
                    else if (flag == 'driver') {
                        this.setState({ conversationDriverList: tempList })
                    }
                    else if (flag == 'mover') {
                        this.setState({ conversationMoverList: tempList })
                    }
                    else if (flag == 'admin') {
                        this.setState({ conversationAdminList: tempList })
                    }
                }
            })
            .catch(err => {

            })
    }
    renderHelperConversations() {
        if (this.state.conversationHelperList.length > 0) {
            let conversationHelperList = this.state.conversationHelperList.map((item, i) => {
                return (
                    <div className="panel panel-flat profile-page panel-collapsed" key={'helper-' + i}>
                        <div className="panel-heading">
                            <h5 className="panel-title">{item.full_name} <span className="badge badge-success">{this.state.totalCloseMsg.helperTotalMsg = item.msgCount}</span></h5>
                            <div className="heading-elements">

                                <ul className="icons-list">
                                    <li><a href="javascript:void(0)" data-action="collapse" onClick={this.getMessages.bind(this, item.conversations_user_id, 'helper', item, i, 'conversationHelperList')}></a></li>
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div className="panel-body">
                            {this.renderMessages(item.conversations)}
                        </div>
                    </div>
                )
            })
            return conversationHelperList
        } else {
            return <h6 className="alert alert-primary no-border m-0">No Helpers Found.</h6>
        }
    }


    renderAdminConversations() {
        if (this.state.conversationAdminList.length > 0) {
            let conversationAdminList = this.state.conversationAdminList.map((item, i) => {
                return (
                    <div className="panel panel-flat profile-page panel-collapsed" key={'admin-' + i}>
                        <div className="panel-heading">
                            <h5 className="panel-title">{item.full_name} <span className="badge badge-success">{this.state.totalCloseMsg.adminTotalMsg = item.msgCount}</span></h5>
                            <div className="heading-elements">
                                <ul className="icons-list">
                                    <li><a href="javascript:void(0)" data-action="collapse" onClick={this.getMessages.bind(this, item.conversations_user_id, 'admin', item, i, 'conversationAdminList')}></a></li>
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div className="panel-body">
                            {this.renderMessages(item.conversations)}
                        </div>
                    </div>
                )
            })
            return conversationAdminList
        } else {
            return <h6 className="alert alert-primary no-border m-0">No Admin Found.</h6>
        }
    }
    renderMessages = (messages) => {
        if (messages.length > 0) {
            let msg = messages.map((item, i) => {
                return (
                    <React.Fragment key={'message-' + i}>
                        <div className="message-body clearfix">
                            <div className="content-group-sm">
                                <div className="media">
                                    <span className="media-left">
                                        <span className={((item.conversations_user_id == (JSON.parse(Storage.get('imnLoginData'))).id) ? "bg-primary-400" : "bg-warning") + " btn btn-rounded btn-icon btn-xs"}>
                                            <span className="letter-icon">{item.full_name.substr(0, 1)}</span>
                                        </span>
                                    </span>
                                    <div className="media-body">
                                        <span className="media-heading text-semibold">{item.full_name} </span>
                                        <div className="text-size-mini text-muted">
                                            {DateFormat.getFormattedDateTime(item.msg_time, 'M d, Y,h:i')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: item.message }}></p>
                            <div className="tabbable attachedment-files">
                                <div className="file-manager">
                                    <ul>
                                        {item.quotetion_files.length > 0 && item.quotetion_files.map((file, j) => {
                                            return (
                                                <li key={'file-' + j}>
                                                    <div className="file-wrapper filetype-image">
                                                        <div className="file-title"><a href={file.file_path}><i className={FileIcon(this.getExtension(file.file_name)) + " position-left text-primary"}></i>{file.file_name}</a></div>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {(i < (messages.length - 1)) && <hr />}
                    </React.Fragment>
                )
            });

            return msg;
        }
    }
    getConversationMoverList() {
        PostDataWithParam('OrderRelatedConversationsPeopleList', { order_request_detail_id: this.state.id, order_type: this.state.order_type, conversations_profile_id: 2 })
            .then(response => {
                if (response.success == 1) {
                    let conversationMoverList = response.data;
                    let tempList = conversationMoverList.map((item, i) => {
                        return { ...item, conversations: [], msgCount: 0, nodeUrl: '' }
                    });
                    this.setState({ conversationMoverList: tempList }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {

                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                } else {
                    this.setState({ conversationMoverList: [] }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {

                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                }

            })
            .catch(err => { })
    }

    getConversationAdminList() {
        PostDataWithParam('OrderRelatedConversationsPeopleList', { order_request_detail_id: this.state.id, order_type: this.state.order_type, conversations_profile_id: 1 })
            .then(response => {
                if (response.success == 1) {
                    let conversationAdminList = response.data;
                    let tempList = conversationAdminList.map((item, i) => {
                        return { ...item, conversations: [], msgCount: 0, nodeUrl: '' }
                    });
                    this.setState({ conversationAdminList: tempList }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {

                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                } else {
                    this.setState({ conversationAdminList: [] }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {

                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                }

            })
            .catch(err => { })
    }
    /* Start Chat */
    initChatBox(user_id, name, order_id, profile_image, e) {
        e.preventDefault();
        this.props.openChatBox({
            chat_box_id: `${order_id}_${user_id}`,
            chat_info: {
                order_id,
                order_no: this.state.details.order_id,
                receiver_info: {
                    user_id,
                    name,
                    profile_image
                }
            }
        })
    }
    getConversationHelperList() {
        PostDataWithParam('OrderRelatedConversationsPeopleList', { order_request_detail_id: this.state.id, order_type: this.state.order_type, conversations_profile_id: 5 })
            .then(response => {
                if (response.success == 1) {
                    let conversationHelperList = response.data;
                    let tempList = conversationHelperList.map((item, i) => {
                        return { ...item, conversations: [], msgCount: 0, nodeUrl: '' }
                    });
                    this.setState({ conversationHelperList: tempList }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {

                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                } else {
                    this.setState({ conversationHelperList: [] }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {

                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                }

            })
            .catch(err => { })
    }

    getConversationDriverList() {
        PostDataWithParam('OrderRelatedConversationsPeopleList', { order_request_detail_id: this.state.id, order_type: this.state.order_type, conversations_profile_id: 6 })
            .then(response => {
                if (response.success == 1) {
                    let conversationDriverList = response.data;
                    let tempList = conversationDriverList.map((item, i) => {
                        return { ...item, conversations: [], msgCount: 0, nodeUrl: '' }
                    });
                    this.setState({ conversationDriverList: tempList }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {
                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                } else {
                    this.setState({ conversationDriverList: [] }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {
                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                }
            })
            .catch(err => { })
    }
    getConversationCustomerList() {
        PostDataWithParam('OrderRelatedConversationsPeopleList', { order_request_detail_id: this.state.id, order_type: this.state.order_type, conversations_profile_id: 4 })
            .then(response => {
                if (response.success == 1) {
                    let conversationCustomerList = response.data;
                    let tempList = conversationCustomerList.map((item, i) => {
                        return { ...item, conversations: [], msgCount: 0, nodeUrl: '' }
                    });
                    this.setState({ conversationCustomerList: tempList });
                } else {
                    this.setState({ conversationCustomerList: [] });
                }
            })
            .catch(err => { })
    }

    //View Rating for working movers,drivers and helper

    renderRatingForMovers = () => {
        return (<React.Fragment>
            {
                this.state.conversationMoverList.length > 0
                &&
                this.state.conversationMoverList.map((item, i) => {
                    if (item.rating == 0) {
                        return (
                            <React.Fragment key={i}>
                                <div className="well p-0 m-b-20">
                                    <div className="p-15">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="media">
                                                    <div className="media-left">
                                                        <img src={item.profile_image || "assets/images/default_avatar.jpg"} className="img-circle" width="40" height="40" />
                                                    </div>

                                                    <div className="media-body">
                                                        <div className="clearfix">
                                                            <h5 className="m-0 pull-left">{item.full_name}</h5>
                                                            {this.state.staff_user_id == "" &&
                                                                <div className="star-ratting-dislay m-t-5 m-b-5 pull-right">
                                                                    <a data-toggle="modal" data-target="#RatingGiveSingle" className="btn btn-primary btn-xs" onClick={() => this.getInfo(item, i)} href="">Rate Now</a>
                                                                </div>
                                                            }
                                                            {this.state.staff_user_id == "" && this.state.custom_menu.map((menu, i) => {
                                                                return (
                                                                    <div>
                                                                        {
                                                                            (menu.title == "Customer Satisfaction Survey" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                                            <div className="star-ratting-dislay m-t-5 m-b-5 pull-right">
                                                                                <a data-toggle="modal" data-target="#RatingGiveSingle" className="btn btn-primary btn-xs" onClick={() => this.getInfo(item, i)} href="">Rate Now</a>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                );
                                                            })
                                                            }

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment key={i}>
                                <div className="well p-0 m-b-20">
                                    <div className="p-15">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="media">
                                                    <div className="media-left">
                                                        <img src={item.profile_image || "assets/images/default_avatar.jpg"} className="img-circle" width="40" height="40" />
                                                    </div>
                                                    <div className="media-body">
                                                        <div className="clearfix">
                                                            <h5 className="m-0 pull-left">{item.full_name}</h5>
                                                            <div className="star-ratting-dislay m-t-5 m-b-5 pull-right">
                                                                <p className="m-0">
                                                                    <span className="text-warning-300 m-r-5"> <span className={(item.rating >= 1) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span className="text-warning-300 m-r-5"> <span className={(item.rating >= 2) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span className="text-warning-300 m-r-5"><span className={(item.rating >= 3) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span className="text-warning-300 m-r-5"><span className={(item.rating >= 4) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span className="text-warning-300 m-r-5"><span className={(item.rating == 5) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span> {item.rating} &nbsp; Star</span>
                                                                </p>

                                                            </div>
                                                        </div>
                                                        <p>{item.public_review}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    }
                })

            }{
                !this.state.conversationMoverList.length > 0
                &&
                <h6 className="alert alert-primary no-border m-0">
                    No movers found
                </h6>
            }
        </React.Fragment>
        )
    }
    renderRatingForDrivers = () => {
        return (
            <React.Fragment>
                {
                    this.state.conversationDriverList.length > 0
                    &&
                    this.state.conversationDriverList.map((item, i) => {
                        if (item.rating == 0) {
                            return (
                                <React.Fragment key={i}>
                                    <div className="well p-0 m-b-20">
                                        <div className="p-15">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="media">
                                                        <div className="media-left">
                                                            <img src={item.profile_image || "assets/images/default_avatar.jpg"} className="img-circle" width="40" height="40" />
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="clearfix">
                                                                <h5 className="m-0 pull-left">{item.full_name}</h5>
                                                                {this.state.staff_user_id == "" &&
                                                                    <div className="star-ratting-dislay m-t-5 m-b-5 pull-right">
                                                                        <a data-toggle="modal" data-target="#RatingGiveSingle" className="btn btn-primary btn-xs" onClick={() => this.getInfo(item, i)} href="">Rate Now</a>
                                                                    </div>
                                                                }
                                                                {this.state.staff_user_id == "" && this.state.custom_menu.map((menu, i) => {
                                                                    return (
                                                                        <div>
                                                                            {
                                                                                (menu.title == "Customer Satisfaction Survey" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                                                <div className="star-ratting-dislay m-t-5 m-b-5 pull-right">
                                                                                    <a data-toggle="modal" data-target="#RatingGiveSingle" className="btn btn-primary btn-xs" onClick={() => this.getInfo(item, i)} href="">Rate Now</a>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    );
                                                                })
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        } else {
                            return (
                                <div className="well p-0 m-b-20" key={i}>
                                    <div className="p-15">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="media">
                                                    <div className="media-left">
                                                        <img src={item.profile_image || "assets/images/default_avatar.jpg"} className="img-circle" width="40" height="40" />
                                                    </div>
                                                    <div className="media-body">
                                                        <div className="clearfix">
                                                            <h5 className="m-0 pull-left">{item.full_name}</h5>
                                                            <div className="star-ratting-dislay m-t-5 m-b-5 pull-right">
                                                                <p className="m-0">
                                                                    <span className="text-warning-300 m-r-5"> <span className={(item.rating >= 1) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span className="text-warning-300 m-r-5"> <span className={(item.rating >= 2) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span className="text-warning-300 m-r-5"><span className={(item.rating >= 3) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span className="text-warning-300 m-r-5"><span className={(item.rating >= 4) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span className="text-warning-300 m-r-5"><span className={(item.rating == 5) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span> {item.rating} &nbsp; Star</span>
                                                                </p>

                                                            </div>
                                                        </div>
                                                        <p>{item.public_review}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })

                }{
                    !this.state.conversationDriverList.length > 0
                    &&
                    <h6 className="alert alert-primary no-border m-0">
                        No movers found
                    </h6>
                }
            </React.Fragment>
        )
    }

    renderRatingForHelpers = () => {
        return (
            <React.Fragment>
                {
                    this.state.conversationHelperList.length > 0
                    &&
                    this.state.conversationHelperList.map((item, i) => {
                        if (item.rating == 0) {
                            return (
                                <React.Fragment key={i}>
                                    <div className="well p-0 m-b-20">
                                        <div className="p-15">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="media">
                                                        <div className="media-left">
                                                            <img src={item.profile_image || "assets/images/default_avatar.jpg"} className="img-circle" width="40" height="40" />
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="clearfix">
                                                                <h5 className="m-0 pull-left">{item.full_name}</h5>
                                                                {this.state.staff_user_id == "" &&
                                                                    <div className="star-ratting-dislay m-t-5 m-b-5 pull-right">
                                                                        <a data-toggle="modal" data-target="#RatingGiveSingle" className="btn btn-primary btn-xs" onClick={() => this.getInfo(item, i)} href="">Rate Now</a>
                                                                    </div>
                                                                }
                                                                {this.state.staff_user_id == "" && this.state.custom_menu.map((menu, i) => {
                                                                    return (
                                                                        <div>
                                                                            {
                                                                                (menu.title == "Customer Satisfaction Survey" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                                                <div className="star-ratting-dislay m-t-5 m-b-5 pull-right">
                                                                                    <a data-toggle="modal" data-target="#RatingGiveSingle" className="btn btn-primary btn-xs" onClick={() => this.getInfo(item, i)} href="">Rate Now</a>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    );
                                                                })
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        } else {
                            return (
                                <div className="well p-0 m-b-20" key={i}>
                                    <div className="p-15">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="media">
                                                    <div className="media-left">
                                                        <img src={item.profile_image || "assets/images/default_avatar.jpg"} className="img-circle" width="40" height="40" />
                                                    </div>
                                                    <div className="media-body">
                                                        <div className="clearfix">
                                                            <h5 className="m-0 pull-left">{item.full_name}</h5>
                                                            <div className="star-ratting-dislay m-t-5 m-b-5 pull-right">
                                                                <p className="m-0">
                                                                    <span className="text-warning-300 m-r-5"> <span className={(item.rating >= 1) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span className="text-warning-300 m-r-5"> <span className={(item.rating >= 2) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span className="text-warning-300 m-r-5"><span className={(item.rating >= 3) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span className="text-warning-300 m-r-5"><span className={(item.rating >= 4) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span className="text-warning-300 m-r-5"><span className={(item.rating == 5) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span> {item.rating} &nbsp; Star</span>
                                                                </p>

                                                            </div>
                                                        </div>
                                                        <p>{item.public_review}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })

                }{
                    !this.state.conversationHelperList.length > 0
                    &&
                    <h6 className="alert alert-primary no-border m-0">
                        No movers found
                    </h6>
                }
            </React.Fragment>
        )
    }

    renderRatingForCustomers = () => {
        return (
            <React.Fragment>
                {
                    this.state.conversationCustomerList.length > 0
                    &&
                    this.state.conversationCustomerList.map((item, i) => {
                        if (item.rating == 0) {
                            return (
                                <React.Fragment key={i}>
                                    <div className="well p-0 m-b-20">
                                        <div className="p-15">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="media">
                                                        <div className="media-left">
                                                            <img src={item.profile_image || "assets/images/default_avatar.jpg"} className="img-circle" width="40" height="40" />
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="clearfix">
                                                                <h5 className="m-0 pull-left">{item.full_name}</h5>
                                                                {this.state.staff_user_id == "" &&
                                                                    <div className="star-ratting-dislay m-t-5 m-b-5 pull-right">
                                                                        <a data-toggle="modal" data-target="#RatingGiveSingle" className="btn btn-primary btn-xs" onClick={() => this.getInfo(item, i)} href="">Rate Now</a>
                                                                    </div>
                                                                }
                                                                {this.state.staff_user_id == "" && this.state.custom_menu.map((menu, i) => {
                                                                    return (
                                                                        <div>
                                                                            {
                                                                                (menu.title == "Customer Satisfaction Survey" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                                                <div className="star-ratting-dislay m-t-5 m-b-5 pull-right">
                                                                                    <a data-toggle="modal" data-target="#RatingGiveSingle" className="btn btn-primary btn-xs" onClick={() => this.getInfo(item, i)} href="">Rate Now</a>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    );
                                                                })
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        } else {
                            return (
                                <div className="well p-0 m-b-20" key={i}>
                                    <div className="p-15">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="media">
                                                    <div className="media-left">
                                                        <img src={item.profile_image || "assets/images/default_avatar.jpg"} className="img-circle" width="40" height="40" />
                                                    </div>
                                                    <div className="media-body">
                                                        <div className="clearfix">
                                                            <h5 className="m-0 pull-left">{item.full_name}</h5>
                                                            <div className="star-ratting-dislay m-t-5 m-b-5 pull-right">
                                                                <p className="m-0">
                                                                    <span className="text-warning-300 m-r-5"> <span className={(item.rating >= 1) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span className="text-warning-300 m-r-5"> <span className={(item.rating >= 2) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span className="text-warning-300 m-r-5"><span className={(item.rating >= 3) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span className="text-warning-300 m-r-5"><span className={(item.rating >= 4) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span className="text-warning-300 m-r-5"><span className={(item.rating == 5) ? 'icon-star-full2' : 'icon-star-empty3'}></span></span>
                                                                    <span> {item.rating} &nbsp; Star</span>
                                                                </p>

                                                            </div>
                                                        </div>
                                                        <p>{item.public_review}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })

                }{
                    !this.state.conversationCustomerList.length > 0
                    &&
                    <h6 className="alert alert-primary no-border m-0">
                        No Customer found
                    </h6>
                }
            </React.Fragment>
        )
    }

    //Give Rating if not present 
    getInfo = (item, i) => {
        let ratings = [{
            review_to_user_id: "", rate: "", public_review: "", private_review: ""
        }]

        this.setState({ ratings: ratings, tempItem: item });
    }

    getRatings = (e, userId) => {
        let ratings = this.state.ratings;

        if (e.target.name == "public_review") {
            ratings[0].public_review = e.target.value;
        }
        else {
            ratings[0].rate = e.target.value;
        }

        ratings[0].review_to_user_id = userId;

        this.setState({ ratings: ratings })
    }

    ratingSubmit = () => {
        PostDataWithParam("GiveRatingReview", {
            order_request_detail_id: this.state.details.id,
            rating_review: JSON.stringify(this.state.ratings),
            is_complete:1
        })
            .then(response => {
                if (response.success == 1) {
                    window.swal("Success!", response.msg, "success");
                    this.getConversationMoverList();
                    this.getConversationHelperList();
                    this.getConversationDriverList();
                    this.getConversationCustomerList();
                    this.orderDetails();
                    this.getFiles();
                } else {
                    window.swal("Error!", response.msg, "error");
                }
            })
            .catch(err => { window.swal("Error!", 'Something went wrong!', "error"); });
    }

    handleClickActiveTab = (status) => {
        this.setState({ tabid: status }, () => {
            window.$('html, body').animate({
                scrollTop: window.$("#" + status).offset().top
            }, 1000);
        });
    }

    reOpenMove = (e) => {
        e.preventDefault();
        PostDataWithParam('ReOpenMove', { order_id: this.state.id })
            .then(response => {
                if (response.success == 1) {
                    window.swal('Success!', response.msg, 'success');
                    // this.props.history.push('/driver/dashboard/offersposted');
                    this.props.history.push({
                        pathname: '/independent-mover/dashboard/manage-orders',
                        state: {
                            activeFlag: 0
                        }
                    })
                } else {
                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => {
                window.swal('Error!', err, 'error');
            })
    }
    renderBookedDriver() {
        if (this.state.details.booked.driver.length > 0) {
            let booked = this.state.details.booked.driver.map((item, i) => {
                return (
                    <React.Fragment key={'booked-driver-' + i}>
                        <tr>
                            <td width="80%">
                                <div className="media">
                                    <div className="media-left">
                                        <Link to="#">
                                            <img className="media-object" src={item.profile_image} alt="..." width="35" />
                                        </Link>
                                    </div>
                                    <div className="media-body">
                                        <h6 className="m-0"><a href="javascript:void(0)" onClick={() => this.setTempHelper(item.user_id)}>{item.full_name}</a><b /></h6>
                                    </div>
                                </div>
                            </td>
                            <td width="20%" className="text-center"><a href="" data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                onClick={this.initChatBox.bind(this, item.user_id, item.full_name, this.state.id, '')}>
                                <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                        </tr>
                    </React.Fragment>
                )
            });

            return booked;
        } else {
            // return <h6 className="alert alert-primary border-none">No Result Found.</h6>
        }
    }

    renderBookedHelper() {
        if (this.state.details.booked.helper.length > 0) {
            let booked = this.state.details.booked.helper.map((item, i) => {
                return (
                    <React.Fragment key={'booked-helper-' + i}>
                        <tr>
                            <td width="80%">
                                <div className="media">
                                    <div className="media-left">
                                        <Link to="#">
                                            <img className="media-object" src={item.profile_image} alt="..." width="35" />
                                        </Link>
                                    </div>
                                    <div className="media-body">
                                        <h6 className="m-0"><a href="javascript:void(0)" onClick={() => this.setTempHelper(item.user_id)}>{item.full_name}</a><b /></h6>
                                    </div>
                                </div>
                            </td>
                            <td width="20%" className="text-center"><a href="" data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                onClick={this.initChatBox.bind(this, item.user_id, item.full_name, this.state.id, '')}>
                                <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                        </tr>
                    </React.Fragment>
                )
            });

            return booked;
        } else {
            // return <h6 className="alert alert-primary border-none">No Result Found.</h6>
        }
    }
    renderBookedMover() {
        if (this.state.details.booked.mover.length > 0) {
            let booked = this.state.details.booked.mover.map((item, i) => {
                return (
                    <React.Fragment key={'booked-mover-' + i}>
                        <tbody>
                            <tr>
                                <td width="80%">
                                    <div className="media">
                                        <div className="media-left">
                                            <Link to="#">
                                                <img className="media-object" src={item.profile_image} alt="..." width="35" />
                                            </Link>
                                        </div>
                                        <div className="media-body">
                                            <h6 className="m-0"><a href="javascript:void(0)" onClick={() => this.setTempHelper(item.user_id)}>{item.full_name}</a><b /></h6>
                                        </div>
                                    </div>
                                </td>
                                <td width="20%">
                                    <a href="" data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                        onClick={this.initChatBox.bind(this, item.user_id, item.full_name, this.state.id, '')}>
                                        <i className="icon-bubbles9 f-s-20 text-success"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </React.Fragment>
                )
            });
            return booked;
        } else {
            return <td colspan="2"><h6 className="alert alert-primary border-none m-15">No Result Found.</h6></td>
        }
    }

    renderAdmin() {
        if (this.state.admin_details_msg.length > 0) {
            let admin = this.state.admin_details_msg.map((item, i) => {
                return (
                    <React.Fragment key={'admin-' + i}>
                        <tr>
                            <td width="80%">
                                <div className="media">
                                    <div className="media-left">
                                        <Link to="#">
                                            <img className="media-object" src={item.admin_profile_image || "https://myrelo.us/relotechApi/public/uploads/profile_image/default_avatar.jpg"} alt="..." width="35" />
                                        </Link>
                                    </div>
                                    <div className="media-body">
                                        <h6 className="m-0"><a href="javascript:void(0)" onClick={() => this.setTempHelper(item.admin_user_id)}>{item.admin_name}</a><b /></h6>
                                    </div>
                                </div>
                            </td>
                            <td width="20%">
                                <a href="" data-chatusername={item.admin_name} data-uid={this.state.id + '_' + item.admin_user_id}
                                    onClick={this.initChatBox.bind(this, item.admin_user_id, item.admin_name, this.state.id, '')}>
                                    <i className="icon-bubbles9 f-s-20 text-success"></i></a>
                            </td>
                        </tr>
                    </React.Fragment>
                )
            });
            return admin;
        } else {
            return <td colspan="2"><h6 className="alert alert-primary border-none m-15">No Result Found.</h6></td>
        }
    }

    renderInvitedHelper() {
        if (this.state.details.invited.helper.length > 0) {
            let invited = this.state.details.invited.helper.map((item, i) => {
                return (
                    <React.Fragment key={'invited-helper-' + i}>
                        <tr>
                            <td style={{ width: '35%' }}>
                                <div className="media">
                                    <div className="media-left">
                                        <Link to="#">
                                            <img className="media-object" src={item.profile_image} alt="..." width="35" />
                                        </Link>
                                    </div>
                                    <div className="media-body">
                                        <h6 className="m-0">
                                            <a href="javascript:void(0)" onClick={() => this.setTempHelper(item.user_id)}>{item.full_name}</a>
                                            <span className="m-l-5 m-b-0 text-orange">
                                                <span className="icon-star-full2"></span>
                                                <span className="icon-star-full2"></span>
                                                <span className="icon-star-full2"></span>
                                                <span className="icon-star-full2"></span>
                                                <span className="icon-star-empty3"></span>
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {item.status == 0 && <span className="text-primary">Pending</span>}
                                {item.status == 1 && <span className="text-info">Communicating</span>}
                                {item.status == 2 && <span className="text-danger">Declined</span>}
                                {item.status == 4 && <span className="text-danger">Cancel</span>}
                            </td>
                            {
                                item.status == 0 &&
                                item.award_status == 0 &&
                                this.state.permission &&
                                <React.Fragment>
                                    {/* <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 1, item.user_id, this.state.details.id)}><i className="icon-checkmark4 f-s-20 text-success"></i></a></td> */}
                                    <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}><i className="icon-blocked f-s-20 text-danger"></i></a></td>
                                    {/* <td className="count text-center">
                                        <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                            <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                        </a>
                                    </td> */}
                                    {
                                        (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                                        &&
                                        <td className="text-center"><a href=""
                                            data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                            onClick={this.initChatBox.bind(
                                                this, item.user_id, item.full_name, this.state.id, item.profile_image
                                            )}>
                                            <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                                    }
                                </React.Fragment>
                            }
                            {
                                item.award_status == 1
                                &&
                                item.status != 2
                                &&
                                this.state.permission &&
                                <React.Fragment>
                                    {/* <td className="text-muted text-center"><span className="text-success"><i className="icon-checkmark-circle"></i></span> Awarded</td> */}
                                    <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}><i className="icon-blocked f-s-20 text-danger"></i></a></td>
                                    {/* <td className="count text-center">
                                        <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                            <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                        </a>
                                    </td> */}
                                    {
                                        (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                                        &&
                                        <td className="text-center"><a href=""
                                            data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                            onClick={this.initChatBox.bind(
                                                this, item.user_id, item.full_name, this.state.id, item.profile_image
                                            )}>
                                            <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                                    }
                                </React.Fragment>
                            }
                            {
                                item.status == 2 &&
                                (item.award_status == 0 || item.award_status == 1)
                                &&
                                this.state.permission &&
                                <React.Fragment>
                                    {/* <td className="text-center"><a href="" onClick={(e) => this.reAwardDeclineOffers(e, 1, item.user_id, this.state.details.id)}><i className="icon-checkmark4 f-s-20 text-success"></i></a></td> */}
                                    <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}><i className="icon-blocked f-s-20 text-danger"></i></a></td>
                                    {/* <td className="count text-center">
                                        <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                            <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                        </a>
                                    </td> */}
                                    {
                                        (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                                        &&
                                        <td className="text-center"><a href=""
                                            data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                            onClick={this.initChatBox.bind(
                                                this, item.user_id, item.full_name, this.state.id, item.profile_image
                                            )}>
                                            <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                                    }
                                </React.Fragment>
                            }
                        </tr>
                    </React.Fragment>
                    // <div className="well p-0 m-b-20" key={'invited-helper-' + i}>
                    //     <div className="p-15">
                    //         <div className="row">
                    //             <div className="col-md-2">
                    //                 <label className="m-b-0 text-bold">Helper's Name</label>
                    //                 <p className="m-b-0"><a data-toggle="modal" data-target="#profileInfoModalHelperDriver" href="#" onClick={() => this.setTempHelper(item.user_id)}>{item.full_name}</a><b /></p>
                    //             </div>

                    //             {
                    //                 (
                    //                     !item.address_type
                    //                     ||
                    //                     item.address_type == ""
                    //                 )
                    //                 &&
                    //                 <React.Fragment>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             this.state.details.source_address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="text-bold m-b-0">Address <b className="text-success">(Origin)</b></label>
                    //                                 <p className="m-b-20">{this.state.details.source_address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             this.state.details.destination_address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="text-bold m-b-0">Address <b className="text-success">(Destination)</b></label>
                    //                                 <p className="m-b-20">{this.state.details.destination_address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                 </React.Fragment>
                    //             }
                    //             {
                    //                 item.address_type
                    //                 &&
                    //                 <React.Fragment>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             item.address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="m-b-0 text-bold">Address <b className="text-success">({item.address_type || "---"})</b></label>
                    //                                 <p className="m-b-0">{item.address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                     <div className="col-md-3">
                    //                     </div>
                    //                 </React.Fragment>
                    //             }
                    //             <div className="col-md-2">
                    //                 <label className="m-b-0 text-bold">Phone</label>
                    //                 <p className="m-b-0">{item.contact_no}</p>
                    //             </div>
                    //             <div className="col-md-2 text-right">
                    //                 {/* <a href="" className="m-r-10" onClick={(e) => this.openMsgSection(e, item.user_id, 'helper')}><i className="icon-envelope"></i></a> */}
                    //                 {item.status == 0 && <span className="text-primary">Pending</span>}
                    //                 {item.status == 1 && <span className="text-info">Communicating</span>}
                    //                 {item.status == 2 && <span className="text-danger">Declined</span>}
                    //             </div>
                    //         </div>
                    //         <div className="row">
                    //             <div className="col-md-10">
                    //                 <label className="m-b-0 text-bold">Services</label>
                    //                 <p className="m-b-0">{item.services_needed || "---"}</p>
                    //             </div>
                    //             {
                    //                 item.status == 0 &&
                    //                 item.award_status == 0
                    //                 &&
                    //                 <div className="col-md-2 text-right">
                    //                     <a href="" className="btn btn-success btn-xs m-r-5" onClick={(e) => this.awardDeclineOffers(e, 1, item.user_id, this.state.details.id)}>Award</a>
                    //                     <a href="" className="btn btn-default btn-xs" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}>Decline</a>
                    //                 </div>
                    //             }
                    //             {
                    //                 item.award_status == 1
                    //                 &&
                    //                 item.status != 2
                    //                 &&
                    //                 <div className="col-md-2 text-right">
                    //                     <p className="text-muted"><span className="text-success"><i className="icon-checkmark-circle"></i></span> Awarded</p>
                    //                 </div>
                    //             }
                    //         </div>
                    //     </div>
                    // </div>
                )
            });

            return invited;
        } else {
            // return <h6 className="text-grey-600">No result found.</h6>
        }
    }
    // Invited function
    renderInvitedMover() {
        if (this.state.details.invited.mover.length > 0) {
            let invited = this.state.details.invited.mover.map((item, i) => {
                return (
                    <React.Fragment key={'invited-mover-' + i}>
                        <tbody>

                            <tr>
                                <td style={{ width: '35%' }}>
                                    <div className="media">
                                        <div className="media-left">
                                            <Link to="#">
                                                <img className="media-object" src={item.profile_image} alt="..." width="35" />
                                            </Link>
                                        </div>
                                        <div className="media-body">
                                            <h6 className="m-0">
                                                <a href="javascript:void(0)" onClick={() => this.setTempHelper(item.user_id)}>{item.full_name}</a>
                                                <span className="m-l-5 m-b-0 text-orange">
                                                    <span className="icon-star-full2"></span>
                                                    <span className="icon-star-full2"></span>
                                                    <span className="icon-star-full2"></span>
                                                    <span className="icon-star-full2"></span>
                                                    <span className="icon-star-empty3"></span>
                                                </span>
                                            </h6>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {item.status == 0 && <span className="text-primary">Pending</span>}
                                    {item.status == 1 && <span className="text-info">Communicating</span>}
                                    {item.status == 2 && <span className="text-danger">Declined</span>}
                                    {item.status == 4 && <span className="text-danger">Cancel</span>}
                                </td>
                                {
                                    item.status == 0 &&
                                    item.award_status == 0
                                    &&
                                    this.state.permission &&
                                    <React.Fragment>
                                        {/* <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 1, item.user_id, this.state.details.id)}><i className="icon-checkmark4 f-s-20 text-success"></i></a></td> */}
                                        <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}><i className="icon-blocked f-s-20 text-danger"></i></a></td>
                                        {/* <td className="count text-center">
                                            <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                                <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                            </a>
                                        </td> */}
                                        {
                                            (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                                            &&
                                            <td className="text-center"><a href=""
                                                data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                                onClick={this.initChatBox.bind(
                                                    this, item.user_id, item.full_name, this.state.id, item.profile_image
                                                )}>
                                                <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                                        }
                                    </React.Fragment>
                                }
                                {
                                    item.award_status == 1
                                    &&
                                    item.status != 2
                                    &&
                                    this.state.permission &&
                                    <React.Fragment>
                                        {/* <td className="text-muted text-center"><span className="text-success"><i className="icon-checkmark-circle"></i></span> Awarded</td> */}
                                        <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}><i className="icon-blocked f-s-20 text-danger"></i></a></td>
                                        {/* <td className="count text-center">
                                            <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                                <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                            </a>
                                        </td> */}
                                        {
                                            (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                                            &&
                                            <td className="text-center"><a href=""
                                                data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                                onClick={this.initChatBox.bind(
                                                    this, item.user_id, item.full_name, this.state.id, item.profile_image
                                                )}>
                                                <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                                        }
                                    </React.Fragment>
                                }
                                {
                                    item.status == 2 &&
                                    (item.award_status == 0 || item.award_status == 1)
                                    &&
                                    this.state.permission &&
                                    <React.Fragment>
                                        {/* <td className="text-center"><a href="" onClick={(e) => this.reAwardDeclineOffers(e, 1, item.user_id, this.state.details.id)}><i className="icon-checkmark4 f-s-20 text-success"></i></a></td> */}
                                        <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}><i className="icon-blocked f-s-20 text-danger"></i></a></td>
                                        {/* <td className="count text-center">
                                        <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                            <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                        </a>
                                    </td> */}
                                        {
                                            (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                                            &&
                                            <td className="text-center"><a href=""
                                                data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                                onClick={this.initChatBox.bind(
                                                    this, item.user_id, item.full_name, this.state.id, item.profile_image
                                                )}>
                                                <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                                        }
                                    </React.Fragment>
                                }
                            </tr>
                        </tbody>
                    </React.Fragment>
                    // <div className="well p-0 m-b-20" key={'invited-mover-' + i}>
                    //     <div className="p-15">
                    //         <div className="row">
                    //             <div className="col-md-2">
                    //                 <label className="m-b-0 text-bold">Mover's Company Name</label>
                    //                 <p className="m-b-0"><a href="javascript:void(0)" data-toggle="modal" data-target="#profileInfoModal" onClick={(e) => this.setTempMover(e, item.user_id)}>{item.full_name}</a><b /></p>
                    //             </div>


                    //             {
                    //                 (
                    //                     !item.address_type
                    //                     ||
                    //                     item.address_type == ""
                    //                 )
                    //                 &&
                    //                 <React.Fragment>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             this.state.details.source_address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="text-bold m-b-0">Address <b className="text-success">(Origin)</b></label>
                    //                                 <p className="m-b-20">{this.state.details.source_address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             this.state.details.destination_address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="text-bold m-b-0">Address <b className="text-success">(Destination)</b></label>
                    //                                 <p className="m-b-20">{this.state.details.destination_address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                 </React.Fragment>
                    //             }
                    //             {
                    //                 item.address_type
                    //                 &&
                    //                 <React.Fragment>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             item.address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="m-b-0 text-bold">Address <b className="text-success">({item.address_type || "---"})</b></label>
                    //                                 <p className="m-b-0">{item.address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                     <div className="col-md-3">
                    //                     </div>
                    //                 </React.Fragment>
                    //             }

                    //             <div className="col-md-2">
                    //                 <label className="m-b-0 text-bold">Phone</label>
                    //                 <p className="m-b-0">{item.contact_no}</p>
                    //             </div>
                    //             <div className="col-md-2 text-right">
                    //                 {/* <a href="" className="m-r-10" onClick={(e) => this.openMsgSection(e, item.user_id, 'mover')}><i className="icon-envelope"></i></a> */}
                    //                 {item.status == 0 && <span className="text-primary">Pending</span>}
                    //                 {item.status == 1 && <span className="text-info">Communicating</span>}
                    //                 {item.status == 2 && <span className="text-danger">Declined</span>}
                    //             </div>
                    //         </div>
                    //         <div className="row">
                    //             <div className="col-md-10">
                    //                 <label className="m-b-0 text-bold">Services</label>
                    //                 <p className="m-b-0">{item.services_needed || "---"}</p>
                    //             </div>
                    //             {
                    //                 item.status == 0 &&
                    //                 item.award_status == 0
                    //                 &&
                    //                 <div className="col-md-2 text-right">
                    //                     <a href="" className="btn btn-success btn-xs m-r-5" onClick={(e) => this.awardDeclineOffers(e, 1, item.user_id, this.state.details.id)}>Award</a>
                    //                     <a href="" className="btn btn-default btn-xs" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}>Decline</a>
                    //                 </div>
                    //             }
                    //             {
                    //                 item.award_status == 1
                    //                 &&
                    //                 item.status != 2
                    //                 &&
                    //                 <div className="col-md-2 text-right">
                    //                     <p className="text-muted"><span className="text-success"><i className="icon-checkmark-circle"></i></span> Awarded</p>
                    //                 </div>
                    //             }
                    //         </div>
                    //     </div>
                    // </div>
                )
            });

            return invited;
        } else {
            return <h6 className="text-grey-600">No result found.</h6>
        }
    }
    doSubscribe = (e) => {
        window.swal({
            title: "Warning!",
            text: "Please upgrade your membership to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }

    render() {
        return (
            <React.Fragment>
                {
                    this.state.loading
                    &&
                    <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                }{!this.state.loading
                    &&
                    <div className="row">
                        <div className="col-md-12">
                            {Breadcumb(this.state.breadcrumb)}
                            {/* <div className="panel panel-default profile-page"> */}
                            <div className="panel-heading well clearfix m-b-20 p-t-10 p-b-10">
                                <div className="row">
                                    <div className="col-md-12">
                                    <h5 className="panel-title pull-left m-t-5 m-b-10">{this.state.details.order_type == 0 ? 'Relo' : 'Shipment'} <b>#{this.state.details.order_id}</b> Posted by <b>{this.state.details.offer_from && this.state.details.offer_from.organization_name}</b></h5>
                                        {
                                            this.state.details.status == 3 &&
                                            this.state.details.hire_by_profile_id == 2 &&
                                            <div className="pull-right">
                                                {this.state.editPermission ?
                                                    <a href="javascript:void(0)" onClick={this.reOpenMove} className="btn btn-primary btn-xs text-uppercase">Re-Open Move</a>
                                                    :
                                                    <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-primary btn-xs text-uppercase" onClick={this.doSubscribe}>Re-Open Move</Link>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                                <ul className="inner-page navigation tab-navigation m-b-10 m-t-10 p-0">
                                    <li className={(this.state.tabid === 'order-summary' ? 'active' : '')}>
                                        <Link to="#" onClick={() => this.handleClickActiveTab('order-summary')} className="f-s-15 text-uppercase">Order Summary</Link>
                                    </li>
                                    <li className={(this.state.tabid === 'movers-pros' ? 'active' : '')}>
                                        <Link to="#" onClick={() => this.handleClickActiveTab('movers-pros')} className="f-s-15 text-uppercase">Movers &amp; Pros</Link>
                                    </li>
                                    <li className={(this.state.tabid === 'survey-file' ? 'active' : '')}>
                                        <Link to="#" onClick={() => this.handleClickActiveTab('survey-file')} className="f-s-15 text-uppercase">Files &amp; Attachments</Link>
                                    </li>
                                    <li className={(this.state.tabid === 'messaging' ? 'active' : '')}>
                                        <Link to="#" onClick={() => this.handleClickActiveTab('messaging')} className="f-s-15 text-uppercase">Conversation</Link>
                                    </li>
                                </ul>

                            </div>

                            <div id="order-summary">
                                <div className="panel panel-default profile-page">
                                    <div className="panel-body">
                                        <h6 className="text-success">Customer Info</h6>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Customer Name</label>
                                                <p className="m-b-20">{(this.state.details.customer_first_name + ' ' + this.state.details.customer_last_name) || '---'}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Customer Mobile #</label>
                                                <p className="m-b-20">{this.state.details.contact_no || '---'}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Customer Mobile #(2)</label>
                                                <p className="m-b-20">{this.state.details.home_contact_no || '---'}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Customer Email Address</label>
                                                <p className="m-b-20">{this.state.details.customer_email}</p>
                                            </div>
                                        </div>
                                        <h6 className="text-success m-t-0">Service Info</h6>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Requested Start Date</label>
                                                <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.details.request_start_date, 'M d, Y')}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Requested End Date</label>
                                                <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.details.request_end_date, 'M d, Y')}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Total Number of Members needed onsite</label>
                                                <p className="m-b-20">{this.state.details.total_manpower_needed}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Origin Address</label>
                                                <p className="m-b-20">{this.state.details.source_address || "---"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Destination Address</label>
                                                <p className="m-b-20">{this.state.details.destination_address || "---"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Services Needed</label>
                                                <p className="m-b-20">{this.state.details.services_needed}</p>
                                            </div>
                                        </div>
                                        <label className="text-bold m-b-0">Relocation Notes</label>
                                        <p className="m-b-20">{this.state.details.relocation_notes}</p>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="clearfix"></div>
                            <div className="panel panel-flat profile-page m-b-40">
                                <div className="panel-heading p-0">
                                    <ul className="nav nav-tabs nav-tabs-bottom nav-horizantal-scroll">
                                        <li className="active">
                                            <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setFileGroup.bind(this, 1)}>
                                                <p className="text-uppercase m-0 m-l-5 m-r-5">Pre-move survey files</p>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setFileGroup.bind(this, 2)}>
                                                <p className="text-uppercase m-0 m-l-5 m-r-5">Inventory Pictures</p>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setFileGroup.bind(this, 3)}>
                                                <p className="text-uppercase m-0 m-l-5 m-r-5">Other Files</p>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="panel-body">
                                    <div className="tabbable hori-scroll">
                                        <div className="file-manager">
                                            <ul>
                                                {this.renderFiles()}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> */}


                            {/* ------------------------ New Design start ------------------------------------ */}
                            <div>
                                <h4 className="pull-left">Files</h4>
                            </div>
                            <div id="survey-file">
                                <div className="clearfix"></div>
                                <div className="panel panel-flat profile-page m-b-40">
                                    <div className="panel-heading p-0">
                                        <ul className="nav nav-tabs nav-tabs-bottom nav-horizantal-scroll">
                                            <li className="active">
                                                <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setFileGroup.bind(this, 1)}>
                                                    <p className="text-uppercase m-0 m-l-5 m-r-5">Pre-move survey files</p>
                                                </a>
                                            </li>
                                            <li className="">
                                                <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setFileGroup.bind(this, 2)}>
                                                    <p className="text-uppercase m-0 m-l-5 m-r-5">Inventory Pictures</p>
                                                </a>
                                            </li>
                                            <li className="">
                                                <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setFileGroup.bind(this, 3)}>
                                                    <p className="text-uppercase m-0 m-l-5 m-r-5">Other Files</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="panel-body">
                                        <div className="tabbable hori-scroll">
                                            <div className="file-manager">
                                                <ul>
                                                    {this.renderFiles()}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* -------------------------- new design end -------------------------------  */}

                            {/* <h4>ReloDrivers, ReloHelpers & Movers Worked for this Relocation</h4> */}
                            {/* <div id="movers-pros">
                                <div className="panel panel-default m-b-40">
                                    <div className="panel-heading p-0 hori-scroll">
                                        <ul className="nav nav-tabs nav-tabs-bottom m-b-0">
                                            <li className="active">
                                                <a href="#moversInvited" data-toggle="tab" aria-expanded="false">
                                                    <h5 className="text-uppercase m-0 m-l-5 m-r-5">Movers</h5>
                                                </a>
                                            </li>
                                            <li className="">
                                                <a href="#driversInvited" data-toggle="tab" aria-expanded="false">
                                                    <h5 className="text-uppercase m-0 m-l-5 m-r-5">ReloDriver</h5>
                                                </a>
                                            </li>
                                            <li className="">
                                                <a href="#helpersInvited" data-toggle="tab" aria-expanded="false">
                                                    <h5 className="text-uppercase m-0 m-l-5 m-r-5">ReloHelper</h5>
                                                </a>
                                            </li>
                                            <li className="">
                                                <a href="#customersInvited" data-toggle="tab" aria-expanded="false">
                                                    <h5 className="text-uppercase m-0 m-l-5 m-r-5">Customer</h5>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="panel-body">
                                        <div className="tabbable">
                                            <div className="tab-content">
                                                <div className="tab-pane active" id="moversInvited">
                                                    {this.renderRatingForMovers()}
                                                </div>
                                                <div className="tab-pane" id="driversInvited">
                                                    {this.renderRatingForDrivers()}
                                                </div>
                                                <div className="tab-pane" id="helpersInvited">
                                                    {this.renderRatingForHelpers()}
                                                </div>
                                                <div className="tab-pane" id="customersInvited">
                                                    {this.renderRatingForCustomers()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div id="movers-pros">
                                {(this.state.details.booked.helper.length > 0 || this.state.details.booked.mover.length > 0 || this.state.details.booked.driver.length > 0)
                                    &&
                                    <React.Fragment>
                                        <div className="clearfix  m-b-10 p-t-20">
                                            <h4 className="m-t-0 pull-left">Booked &amp; Confirmed</h4>
                                        </div>
                                        <div className="panel panel-default">

                                            {
                                                this.state.details.hire_by_profile_id != ''
                                                &&
                                                this.state.details.booked.mover.length > 0
                                                &&
                                                <React.Fragment>
                                                    <div className="panel-body p-b-0">
                                                        <h6 className="m-0 text-success">Your Movers</h6>
                                                    </div>
                                                    <div className="table-responsive no-border">
                                                        <table className="table mover-table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-bold">Name</th>
                                                                    {/* <th className="text-bold">Phone</th>
                                                                    <th className="text-bold">Email</th> */}
                                                                    {/* <th className="text-bold">Message</th> */}
                                                                    <th className="text-bold">Chat</th>
                                                                </tr>
                                                            </thead>
                                                            {/* <tbody>
                                                        <tr>
                                                            <td style={{ width: '30%' }}>
                                                                <div className="media">
                                                                    <div className="media-left">
                                                                        <a href="#">
                                                                            <img className="media-object" src="https://myrelo.us/relotechApi/public/uploads/customer_profile_image/3368_1598023583_customers.jpg" alt="..." width="35" />
                                                                        </a>
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h6 className="m-0">1st USA Moving & Storage</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>988642367</td>
                                                            <td>support@movingapt.com</td>
                                                            <td className="count">
                                                                <a href="#" onClick={() => this.openConversationModal()} data-toggle="modal" data-target="#conversationModal">
                                                                    <i className="icon-envelope f-s-20 text-dark-blue"></i>
                                                                    <span className="info">8</span>
                                                                </a>
                                                            </td>
                                                            <td><i className="icon-bubbles9 f-s-20 text-success"></i></td>
                                                        </tr>
                                                        
                                                    </tbody> */}
                                                            {this.renderBookedMover()}
                                                        </table>
                                                    </div>
                                                </React.Fragment>
                                            }

                                            {(this.state.details.booked.helper.length > 0 || this.state.details.booked.driver.length > 0)
                                                &&
                                                <React.Fragment>
                                                    <div className="panel-body p-b-0">
                                                        <h6 className="m-0 text-success">Your ReloPros</h6>
                                                    </div>
                                                    <div className="table-responsive no-border">
                                                        <table className="table mover-table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-bold">Name</th>
                                                                    {/* <th className="text-bold">Phone</th>
                                                                    <th className="text-bold">Email</th> */}
                                                                    {/* <th className="text-bold text-center">Message</th> */}
                                                                    <th className="text-bold text-center">Chat</th>
                                                                    {/* <th className="text-bold text-center">Share App</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {/* <tr>
                                                                <td style={{ width: '30%' }}>
                                                                    <div className="media">
                                                                        <div className="media-left">
                                                                            <a href="#">
                                                                                <img className="media-object" src="https://myrelo.us/relotechApi/public/uploads/customer_profile_image/3368_1598023583_customers.jpg" alt="..." width="35" />
                                                                            </a>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h6 className="m-0">John Doe</h6>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>988642367</td>
                                                                <td>support@movingapt.com</td>
                                                                <td className="count">
                                                                    <a href="#" onClick={() => this.openConversationModal()} data-toggle="modal" data-target="#conversationModal">
                                                                        <i className="icon-envelope f-s-20 text-dark-blue"></i>
                                                                        <span className="info">8</span>
                                                                    </a>
                                                                </td>
                                                                <td><i className="icon-bubbles9 f-s-20 text-success"></i></td>
                                                                <td><i className="icon-share2 f-s-20 text-success"></i></td>
                                                            </tr> */}
                                                                {this.renderBookedHelper()}
                                                                {this.renderBookedDriver()}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </React.Fragment>
                                            }

                                        </div>

                                    </React.Fragment>
                                }

                                {(this.state.details.invited.mover.length > 0 || this.state.details.invited.helper.length > 0 || this.state.details.invited.driver.length > 0)
                                    &&
                                    <React.Fragment>
                                        <div className="clearfix  m-b-10 p-t-20">
                                            <h4 className="m-t-0 pull-left">Invited (Awaiting response)</h4>
                                        </div>
                                        <div className="panel panel-default">

                                            {
                                                this.state.details.hire_by_profile_id != ''
                                                &&
                                                this.state.details.invited.mover.length > 0
                                                &&
                                                <React.Fragment>
                                                    <div className="panel-body p-b-0">
                                                        <h6 className="m-0 text-success">Requested Moving Companies For This Move</h6>
                                                    </div>
                                                    <div className="table-responsive no-border">
                                                        <table className="table mover-table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-bold">Name</th>
                                                                    <th className="text-bold">Status</th>
                                                                    {/* <th className="text-bold text-center">Award</th> */}
                                                                    <th className="text-bold text-center">Cancel Invitation</th>
                                                                    <th className="text-bold text-center">Chat</th>
                                                                </tr>
                                                            </thead>
                                                            {/* <tbody>

                                                        <tr>
                                                            <td style={{ width: '30%' }}>
                                                                <div className="media">
                                                                    <div className="media-left">
                                                                        <a href="#">
                                                                            <img className="media-object" src="https://myrelo.us/relotechApi/public/uploads/customer_profile_image/3368_1598023583_customers.jpg" alt="..." width="35" />
                                                                        </a>
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h6 className="m-0">1st USA Moving & Storage</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>Accepted</td>
                                                            <td>5</td>
                                                            <td><i className="icon-checkmark4 f-s-20 text-success"></i></td>
                                                            <td><i className="icon-blocked f-s-20 text-danger"></i></td>
                                                            <td className="count">
                                                                <a href="#" onClick={() => this.openConversationModal()} data-toggle="modal" data-target="#conversationModal">
                                                                    <i className="icon-envelope f-s-20 text-dark-blue"></i>
                                                                    <span className="info">8</span>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody> */}
                                                            {this.renderInvitedMover()}
                                                        </table>
                                                    </div>
                                                    {/* <div className="" id="driversAssigned">
                                                    {this.renderInvitedMover()}
                                                </div> */}
                                                </React.Fragment>
                                            }

                                            {(this.state.details.invited.helper.length > 0 || this.state.details.invited.driver.length > 0)
                                                &&
                                                <React.Fragment>
                                                    <div className="panel-body p-b-0">
                                                        <h6 className="m-0 text-success">Requested ReloPros For This Move</h6>
                                                    </div>
                                                    <div className="table-responsive no-border">
                                                        <table className="table mover-table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-bold">Name</th>
                                                                    <th className="text-bold">Status</th>
                                                                    {/* <th className="text-bold text-center">Award</th> */}
                                                                    <th className="text-bold text-center">Cancel Invitation</th>
                                                                    <th className="text-bold text-center">Chat</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {/* <tr>
                                                                <td style={{ width: '30%' }}>
                                                                    <div className="media">
                                                                        <div className="media-left">
                                                                            <a href="#">
                                                                                <img className="media-object" src="https://myrelo.us/relotechApi/public/uploads/customer_profile_image/3368_1598023583_customers.jpg" alt="..." width="35" />
                                                                            </a>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h6 className="m-0">Michael Mishra</h6>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>Accepted</td>
                                                                <td>5</td>
                                                                <td><i className="icon-checkmark4 f-s-20 text-success"></i></td>
                                                                <td><i className="icon-blocked f-s-20 text-danger"></i></td>
                                                                <td className="count">
                                                                    <a href="#" onClick={() => this.openConversationModal()} data-toggle="modal" data-target="#conversationModal">
                                                                        <i className="icon-envelope f-s-20 text-dark-blue"></i>
                                                                        <span className="info">8</span>
                                                                    </a>
                                                                </td>
                                                            </tr> */}
                                                                {this.renderInvitedHelper()}
                                                                {/* {this.renderInvitedDriver()} */}
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </React.Fragment>
                                            }

                                            {/* <div className="" id="driversAssigned">
                                            {this.renderInvitedHelper()}
                                        </div> */}

                                        </div>

                                    </React.Fragment>
                                }
                            </div>

                            <div id="messaging">
                                <h4>Conversation</h4>
                                <div className="panel panel-default">
                                    <div className="panel-body p-b-0">
                                        <h6 className="m-0 text-success">Movers</h6>
                                    </div>
                                    <div className="table-responsive no-border">
                                        <table className="table mover-table">
                                            <thead>
                                                <tr>
                                                    <th className="text-bold" width="80%">Name</th>
                                                    <th className="text-bold" width="20%">Chat</th>
                                                </tr>
                                                {this.renderBookedMover()}
                                                {/* {this.renderMoverConversations()} */}
                                            </thead>
                                        </table>
                                    </div>
                                    <div className="panel-body p-b-0">
                                        <h6 className="m-0 text-success">ReloPros</h6>
                                    </div>
                                    <div className="table-responsive no-border">
                                        <table className="table mover-table">
                                            <thead>
                                                <tr>
                                                    <th className="text-bold" width="80%">Name</th>
                                                    <th className="text-bold" width="20%">Chat</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(this.state.details.booked.driver.length == 0 && this.state.details.booked.helper.length == 0) ?
                                                    <td colspan="2"><h6 className="alert alert-primary border-none m-15">No Result Found.</h6></td>
                                                    :
                                                    <React.Fragment>
                                                        {this.renderBookedHelper()}
                                                        {this.renderBookedDriver()}
                                                    </React.Fragment>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="panel panel-default">
                                    <div className="panel-body p-b-0">
                                        <h6 className="m-0 text-success">Superadmin</h6>
                                    </div>
                                    <div className="table-responsive no-border">
                                        <table className="table mover-table">
                                            <thead>
                                                <tr>
                                                    <th className="text-bold" width="80%">Name</th>
                                                    <th className="text-bold" width="20%">Chat</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderAdmin()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <h4>Messaging</h4>
                                <div className="panel panel-default">
                                    <div className="panel-heading p-0 hori-scroll">
                                        <ul className="nav nav-tabs nav-tabs-bottom m-b-0">
                                            <li className="active">
                                                <a href="#bordered-tab1" data-toggle="tab" aria-expanded="true">
                                                    <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>Movers
                                                <span className="label label-success m-l-5">{this.state.totalCloseMsg.moverTotalMsg}</span></h5>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#bordered-tab2" data-toggle="tab" aria-expanded="true">
                                                    <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>ReloHelper
                                                <span className="label label-success m-l-5">{this.state.totalCloseMsg.helperTotalMsg}</span></h5>
                                                </a>
                                            </li>
                                            <li className="">
                                                <a href="#bordered-tab3" data-toggle="tab" aria-expanded="false">
                                                    <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>ReloDriver
                                                <span className="label label-success m-l-5">{this.state.totalCloseMsg.driveTotalMsg}</span></h5>
                                                </a>
                                            </li>
                                            <li className="">
                                                <a href="#bordered-tab4" data-toggle="tab" aria-expanded="false">
                                                    <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>SuperAdmin
                                                <span className="label label-success m-l-5">{this.state.totalCloseMsg.adminTotalMsg}</span></h5>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="panel-body">
                                        <div className="tabbable">
                                            <div className="tab-content">
                                                <div id="bordered-tab1" className="tab-pane active">
                                                    {this.renderMoverConversations()}
                                                </div>
                                                <div id="bordered-tab2" className="tab-pane">
                                                    {this.renderHelperConversations()}
                                                </div>
                                                <div id="bordered-tab3" className="tab-pane">
                                                    {this.renderDriverConversations()}
                                                </div>
                                                <div id="bordered-tab4" className="tab-pane">
                                                    {this.renderAdminConversations()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/* -- Add ReloHelper - Choose Address Modal -- */}
                        <div id="RatingGiveSingle" className="modal fade" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        <h4 className="modal-title">Post Feedback</h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="p-0">
                                            <div className="row">
                                                <div className="col-md-1">
                                                    <img src={this.state.tempItem.profile_image || "assets/images/default_avatar.jpg"} width="40" height="40" className="img-circle" />
                                                </div>
                                                <div className="col-md-4">
                                                    <h4 className="m-0">{this.state.tempItem.full_name}</h4>
                                                    <p className="m-b-0 text-muted"><a href="">{this.state.tempItem.email}</a><b></b></p>
                                                </div>
                                                <div className="col-md-6 text-right">

                                                    <div className="star-ratting m-0">
                                                        <input type="checkbox" id={"st1_"} onChange={(e) => this.getRatings(e, this.state.tempItem.conversations_user_id)} value="5" checked={(this.state.ratings[0].rate > 4) ? true : false} />
                                                        <label htmlFor={"st1_"} className="m-0" />
                                                        <input type="checkbox" id={"st2_"} onChange={(e) => this.getRatings(e, this.state.tempItem.conversations_user_id)} value="4" checked={(this.state.ratings[0].rate > 3) ? true : false} />
                                                        <label htmlFor={"st2_"} className="m-0" />
                                                        <input type="checkbox" id={"st3_"} onChange={(e) => this.getRatings(e, this.state.tempItem.conversations_user_id)} value="3" checked={(this.state.ratings[0].rate > 2) ? true : false} />
                                                        <label htmlFor={"st3_"} className="m-0" />
                                                        <input type="checkbox" id={"st4_"} onChange={(e) => this.getRatings(e, this.state.tempItem.conversations_user_id)} value="2" checked={(this.state.ratings[0].rate > 1) ? true : false} />
                                                        <label htmlFor={"st4_"} className="m-0" />
                                                        <input type="checkbox" id={"st5_"} onChange={(e) => this.getRatings(e, this.state.tempItem.conversations_user_id)} value="1" checked={(this.state.ratings[0].rate == 1) ? true : false} />
                                                        <label htmlFor={"st5_"} className="m-0" />
                                                    </div>
                                                </div>
                                            </div>
                                            <textarea
                                                className="form-control"
                                                name="public_review"
                                                id={"public_review_"}
                                                rows="1"
                                                value={this.state.ratings[0].public_review}
                                                onChange={(e) => this.getRatings(e, this.state.tempItem.conversations_user_id)}></textarea>

                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.ratingSubmit}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default ClosedOrderDetails;