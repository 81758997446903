import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import PostDataWithParam from '../services/PostDataWithParam';
import Breadcumb from '../externaljs/Breadcrumb';

class FindDrivers extends Component {
    state = {
        helpersCount: 0,
        loadMore: 0,
        helpers: [],
        btnCount: 0,
        search_text: '',
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Find Drivers', link: '', class: 'active' }
        ],
        selectedProviders: [],
        form_data: {
            id: '',
            request_start_date: '',
            request_end_date: '',
            source_address: '',
            source_latitude: '',
            source_longitude: '',
            total_manpower_needed: '',
            services_needed: [],
            relocation_notes: '',
            customer_email: '',
            customer_name: '',
            contact_no: '',
            home_contact_no: '',
            driver_helper_mover: [],
            driver_email: '',
            driver_name: '',
            driver_mobile_number: '',
            assign_flag: 0,
            hire_by_profile_id: 2
        },
        loading: false,
        addJobLoader: false,
        address_type: '',
        address: '',
        services_needed: [],
        editPermission: true
    }

    subscriptionCheckInterval = null;

    componentDidMount = () => {
        if (typeof this.props.location.state !== 'undefined' && typeof this.props.location.state.form_data !== 'undefined') {
            this.setState({ form_data: this.props.location.state.form_data });
        }

        if (typeof this.props.location.state !== 'undefined' && typeof this.props.location.state.order_request_id !== 'undefined') {
            this.setState({
                order_request_id: this.props.location.state.order_request_id,
                type: this.props.location.state.type,
                order_no: this.props.location.state.order_no,
                address_type: this.props.location.state.address_type,
                address: this.props.location.state.address,
                services_needed: this.props.location.state.services_needed
            }, () => {

                this.renderTitle()
            });
        }

        this.findHelpers(0, '')

        this.subscriptionCheckInterval = setInterval(() => {
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1)
                this.setState({ editPermission: false })
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ editPermission: true })
        }, 1000);
    }

    componentWillUnmount() {
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
    }

    doSubscribe = (e) => {
        window.swal({
            title: "Warning!",
            text: "Please upgrade your membership to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }


    changeValue = (e) => {
        let search_text = e.target.value;
        this.setState({ search_text: search_text });
    }
    handleSearchOnEnterKeyPress = (e) => {
        const code = e.keyCode || e.which;
        if (code === 13) {
            this.searchProvider();
        }
    }
    searchProvider = () => {

        this.findHelpers(0, this.state.search_text)

    }

    findHelpers = (offset, search_text) => {
        this.setState({ loading: true }, () => {
            PostDataWithParam('FindDriversForMMC', { offset: offset, search_text: search_text })
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ helpers: response.data.mmc, helpersCount: response.data.count, loadMore: response.data.load_more, loading: false });
                    } else {
                        this.setState({ helpers: [], helpersCount: 0, loadMore: 0, loading: false });
                    }
                })
                .catch(() => {
                    this.setState({ helpers: [], helpersCount: 0, loadMore: 0, loading: false });
                })
        })
    }

    loadMore = () => {
        let count = this.state.btnCount;

        count = count + 1;
        let offset = count * 9;

        this.setState({ btnCount: count }, () => {
            PostDataWithParam('FindDriversForMMC', { offset: offset, search_text: this.state.search_text })
                .then((response) => {
                    if (response.success === 1) {
                        let oldHelpers = this.state.helpers;
                        let newHelpers = response.data.mmc;
                        let allHelpers = oldHelpers.concat(newHelpers);
                        this.setState({ helpers: allHelpers, helpersCount: response.data.count, loadMore: response.data.load_more });
                    }
                })
        });
    }

    checkHelper = (e, profile_id, chk_id) => {
        let selectedProviders = this.state.selectedProviders;

        if (e.target.checked) {
            if (selectedProviders.length >= 10) {
                document.getElementById('helper_' + chk_id).checked = false;

                window.swal('Error!', 'Pick upto 10', 'error');
            }
            else
                selectedProviders.push({ user_id: e.target.value, name: e.target.name, profile_id: profile_id })
        } else {
            for (let i = 0; i < selectedProviders.length; i++) {
                if (selectedProviders[i].user_id === e.target.value) {
                    selectedProviders.splice(i, 1);
                    break;
                }
            }
        }

        this.setState({ selectedProviders: selectedProviders });
    }

    removeProvider = (user_id) => {
        let selectedProviders = this.state.selectedProviders;

        for (let i = 0; i < selectedProviders.length; i++) {
            if (selectedProviders[i].user_id === user_id) {
                selectedProviders.splice(i, 1);
                window.$('#helper_' + user_id).attr('checked', false)
                break;
            }
        }

        this.setState({ selectedProviders: selectedProviders });
    }

    handleLimitInvitation = (selectedProviders, checkedItems) => {
        if (this.state.order_request_id != "") {
            if (checkedItems !== 0) {
                if (checkedItems > 10) {
                    window.swal('Error!', 'Pick upto 10', 'error')
                    return (
                        <button type="button" className="btn btn-primary btn-xs btn-block" disabled={true}>Send Offer</button>
                    )
                } else {
                    if (this.state.addJobLoader) {
                        return <button type="button" className="btn btn-primary btn-xs btn-block" disabled={true}>Processing...</button>
                    } else {

                        if (this.state.editPermission) {
                            return <button type="button" className="btn btn-primary btn-xs btn-block" onClick={this.assignDriver}>Send Offer</button>
                        }
                        else {
                            return <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-primary btn-xs btn-block" onClick={this.doSubscribe}>Send Offer</Link>
                        }
                    }
                }
            } else {
                return <button type="button" className="btn btn-primary btn-xs btn-block" disabled={true}>Send Offer</button>
            }
        } else {
            if (checkedItems !== 0) {
                if (checkedItems > 10) {
                    window.swal('Error!', 'Pick upto 10', 'error')
                    return (
                        <button type="button" className="btn btn-primary btn-xs btn-block" disabled={true}>Send Offer</button>
                    )
                } else {

                    if (this.state.editPermission) {
                        return (
                            <Link to={{
                                pathname: '/independent-mover/dashboard/create-job',
                                state: { selectedProviders: selectedProviders, form_data: this.state.form_data }
                            }} className="btn btn-primary btn-xs btn-block">Send Offer</Link>
                        )
                    }
                    else {
                        return <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-primary btn-xs btn-block" onClick={this.doSubscribe}>Send Offer</Link>
                    }
                }
            } else {
                return (
                    <button type="button" className="btn btn-primary btn-xs btn-block" disabled={true}>Send Offer</button>
                )
            }
        }
    }

    assignDriver = () => {

        this.setState({ addJobLoader: true }, () => {
            PostDataWithParam('AssignReloHelpersToExistingJob', {
                order_request_id: this.state.order_request_id,
                flag: this.state.type,
                driver_helper_mover: JSON.stringify(this.state.selectedProviders),
                address_type: this.state.address_type,
                address: this.state.address,
                services_needed: this.state.services_needed
            })
                .then((response) => {
                    if (response.success == 1) {
                        this.setState({ addJobLoader: false })
                        window.swal('Success!', response.msg, 'success')
                        let flag = (this.state.type == "outsource") ? 'accepted' : '';
                        this.props.history.push({
                            pathname: '/independent-mover/dashboard/order-details',
                            state: { type: flag, id: this.state.order_request_id }
                        });
                    } else {
                        this.setState({ addJobLoader: false })
                        window.swal('Error!', response.msg, 'error');
                    }
                })
                .catch(err => {
                    this.setState({ addJobLoader: false })
                })
        })
    }

    renderTitle = () => {
        if (this.state.order_request_id) {
            return (
                <div className="">
                    <Link to={{
                        pathname: '/independent-mover/dashboard/order-details',
                        state: {
                            id: this.state.order_request_id,
                            type: ''
                        }
                    }} className="btn btn-link p-l-0"><i className="icon-arrow-left8"></i> Back</Link>
                    <h5 className="panel-title">Find Helpers for the {this.state.address_type} Address “{this.state.address}” (Relo #{this.state.order_no})</h5>

                </div>
            )
        } else {
            return (
                <h5 className="panel-title">Find Driver </h5>
            )
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div>
                        <div className="panel panel-flat profile-page">
                            <div className="panel-heading clearfix p-b-5">
                                {this.renderTitle()}
                            </div>
                            <div className="panel-body">
                                <form className="">
                                    <div className="row">
                                        <div className="col-md-10 p-r-5">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    placeholder="Search by name, city, state, zip code, address" className="form-control"
                                                    name="search_text"
                                                    onChange={this.changeValue}
                                                    onKeyPress={this.handleSearchOnEnterKeyPress}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2 p-l-5">
                                            <div className="form-group">
                                                <button type="button" className="btn btn-primary btn-block" onClick={this.searchProvider}>FIND</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9">
                                <p className="f-s-16">{this.state.helpersCount} Driver(s) found <small>( Pick upto 10 )</small></p>
                                <div className="row">
                                    {
                                        this.state.loading
                                        &&
                                        <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                                    }
                                    {
                                        !this.state.loading
                                        &&
                                        this.state.helpers.length != 0
                                        &&
                                        this.state.helpers.map((helper, index) => {
                                            return (
                                                <div className="col-md-4" key={index}>
                                                    <div className="service-box  m-b-20">
                                                        <div className="custom-checkbox-wrap">
                                                            <label className="checkbox-inline custom-checkbox ">

                                                                <input type="checkbox" id={"helper_" + helper.user_id} onClick={(e) => this.checkHelper(e, helper.profile_id, helper.user_id)} value={helper.user_id} name={helper.first_name + ' ' + helper.last_name} />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                        <div className="service-box-list">
                                                            <ul className="list-unstyled m-0">
                                                                {
                                                                    helper.profile_image == ""
                                                                    &&
                                                                    <li className=""><span style={{ background: 'url(assets/images/default_avatar.jpg) no-repeat center center / cover' }}></span></li>
                                                                }
                                                                {
                                                                    helper.profile_image !== ""
                                                                    &&
                                                                    <li className=""><span style={{ background: 'url(' + helper.profile_image + ') no-repeat center center / cover' }}></span></li>
                                                                }
                                                                <li className="f-s-16">
                                                                    <a href="javascript:void(0)">{helper.first_name + ' ' + helper.last_name}</a>

                                                                    {
                                                                        helper.address
                                                                        &&
                                                                        <p>{(helper.address.length < 30) ? helper.address : helper.address.substr(0, 30) + '...'}</p>
                                                                    }
                                                                    {
                                                                        !helper.address
                                                                        &&
                                                                        <p>---</p>
                                                                    }
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        {
                                                            Math.ceil(helper.avarage_rating) > 0
                                                            &&
                                                            <div className="service-box-list text-center text-orange">
                                                                <p className="m-0 f-s-24">{helper.avarage_rating}</p>
                                                                <p className="m-0">
                                                                    <React.Fragment>
                                                                        <span className={(Math.ceil(helper.avarage_rating) >= 1) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                        <span className={(Math.ceil(helper.avarage_rating) >= 2) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                        <span className={(Math.ceil(helper.avarage_rating) >= 3) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                        <span className={(Math.ceil(helper.avarage_rating) >= 4) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                        <span className={(Math.ceil(helper.avarage_rating) == 5) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                    </React.Fragment>
                                                                </p>
                                                            </div>
                                                        }
                                                        {
                                                            Math.ceil(helper.avarage_rating) == 0.0
                                                            &&
                                                            <div className="service-box-list text-center text-orange">
                                                                <p className="m-0 f-s-24">----</p>
                                                                <p className="m-0">
                                                                    <React.Fragment>
                                                                        <span className='icon-star-empty3'></span>
                                                                        <span className='icon-star-empty3'></span>
                                                                        <span className='icon-star-empty3'></span>
                                                                        <span className='icon-star-empty3'></span>
                                                                        <span className='icon-star-empty3'></span>
                                                                    </React.Fragment>
                                                                </p>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        !this.state.loading
                                        &&
                                        this.state.helpers.length == 0
                                        &&
                                        <div className="alert alert-primary border-none text-center">No Drivers Found</div>
                                    }
                                </div>
                                {
                                    this.state.loadMore == 1
                                    &&
                                    <div className="text-center">
                                        <a href="javascript:void(0)" className="btn btn-primary" onClick={this.loadMore}>Load More</a>
                                    </div>
                                }
                            </div>
                            <div className="col-md-3">
                                <div className="sidebar-fixed-rightside">
                                    <p className="f-s-16">Selected Driver</p>
                                    <div className="panel panel-default sidebar-content">
                                        <div className="panel-body selected-comapany">
                                            <form action="proposal-add.php" method="post">
                                                <h6 className="m-t-0"><img src="assets/images/icons/steering-wheel.svg" alt="" />Selected Driver ({this.state.selectedProviders.length}/10)</h6>
                                                <ul className="selected-company-list m-b-25">
                                                    {
                                                        this.state.selectedProviders.length != 0
                                                        &&
                                                        this.state.selectedProviders.map((item, index) => {
                                                            return (
                                                                <li key={index}>{item.name}<a href="javascript:void(0)" className="pull-right" onClick={() => this.removeProvider(item.user_id)}><i className="icon-cross2 text-muted"></i></a></li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                <div className="text-center m-t-15">
                                                    {this.handleLimitInvitation(this.state.selectedProviders, this.state.selectedProviders.length)}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FindDrivers;