import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataFile from '../services/PostDataFile';
import GetData from '../services/GetData';

class ComposeIndividual extends Component {
    state = {
        fields: {
            user_id: '',
            subject: '',
            message: '',
            attachment: ''
        },
        error: {
            subject_err: '',
            message_err: '',
            attachment_err: ''
        },
        clients: [],
        activeEditor: '',
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Message', link: '', class: '' },
            { title: 'Compose Message', link: '', class: 'active' }
        ],
        MessaageErr: '',
        MessageStatus: false,
        name:'',
        user_id:'',
    }

    componentDidMount() {
        if (typeof this.props.location.state !== 'undefined') {       
            this.setState({ name: this.props.location.state.name, user_id: this.props.location.state.user_id });
        }
    }

    resetError = (cb) => {
        let error = {
            subject_err: '',
            message_err: '',
            user_id_err: ''
        }
        this.setState({ error: error }, () => {
            cb();
        });
    }

    ComposeIndividualToList = () => {
        GetData('ComposeIndividualToList')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ clients: response.data });
                }
            })
            .catch(err => {
                this.setState({ clients: [] });
            })
    }

    getSelectValues = (select) => {
        var result = [];
        var options = select && select.options;
        var opt;

        for (var i = 0, iLen = options.length; i < iLen; i++) {
            opt = options[i];

            if (opt.selected) {
                result.push({ id: opt.value });
            }
        }
        return result;
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields });
    }

    handleEditorChange = (e) => {
        let fields = this.state.fields;
        fields.message = e.target.getContent();
        this.setState({ fields: fields });
    }

    handleAttachment = (e) => {
        let fields = this.state.fields;
        fields.attachment = e.target.files[0];
        this.setState({ fields: fields });
    }

    handleMessage = () => {
        this.resetError(() => {
            let error = this.state.error;
            if (!this.state.fields.subject) {
                error.subject_err = 'Subject is required.';
            }
            else if (!this.state.fields.message) {
                error.message_err = 'Message is required.';
            }
             else {
                let fields = this.state.fields;
                fields.user_id = JSON.stringify([{id:this.state.user_id}]);
                this.setState({ fields: fields });
                let fd = new FormData();
                if (this.state.fields.attachment.name !== undefined) {
                    fd.append('attachment', this.state.fields.attachment, this.state.fields.attachment.name);
                }
                fd.append('subject', this.state.fields.subject);
                fd.append('message', this.state.fields.message);
                fd.append('user_id', this.state.fields.user_id);

                    PostDataFile('ComposeMessage', fd)
                        .then((response) => {
                            if (response.success === 1) {
                                this.setState({ MessaageErr: response.msg }, () => {
                                    window.swal('Success!', response.msg, 'success');
                                    this.state.activeEditor.setContent('');
                                    this.setState({MessageStatus: true});
                                });
                            } else {
                                this.setState({ MessaageErr: response.msg });
                            }
                        })
                        .catch(err => {
                            this.setState({ MessaageErr: 'Oops! Something went wrong.',MessageStatus: false });
                        })
            }
            this.setState({ error: error });
        });
    }

    render() {
        if(this.state.MessageStatus){
            return <Redirect to={{
                pathname: '/independent-mover/dashboard/find-movers'
            }}/>
        }

        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="panel panel-flat profile-page">
                        <div className="panel-heading p-b-0">
                            <h5 className="panel-title">Compose Message</h5>
                        </div>
                        <div className="panel-body">
                            <form action="#">
                                <div className="form-group">
                                    <label>To</label>
                                    <input type="text" className="form-control" readOnly={true} defaultValue={this.state.name}/>
                                </div>
                                <div className="form-group">
                                    <label>Subject</label>
                                    <input type="text" className="form-control" placeholder="Enter Subject." name="subject" onChange={this.changeValue} />
                                    <p className="text-danger help-block">{this.state.error.subject_err}</p>
                                </div>
                                <div className="form-group">
                                    <Editor
                                        placeHolder="Write Something ..."
                                        initialValue=""
                                        init={{
                                            setup: editor => {
                                                this.setState({ activeEditor: editor })
                                            },
                                            menubar: false,
                                            statusbar: false,
                                            toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                        }}
                                        onChange={this.handleEditorChange}
                                        className="form-control"
                                        name="qutationMessage"
                                        id='qutationMessage'
                                    />
                                   <p className="text-danger help-block">{this.state.error.message_err}</p>
                                    <label className="file-label">
                                        <input className="file-input" name="attachment" id="attachment" type="file" onChange={this.handleAttachment} />
                                        <span className="text-muted">
                                            <i className="icon icon-attachment"></i> Attach File
                            </span>
                                    </label>
                                </div>

                                <div className="text-right">
                                    <button type="button" className="btn btn-primary" onClick={this.handleMessage}>Send</button>
                                        
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ComposeIndividual;