import React, { Component } from 'react'

class Footer extends Component {
    state = {}
    render() {
        return (
            <div className="footer admin-footer text-muted">
                &copy; Independent Movers Network {new Date().getFullYear()}. All Rights Reserved.
                {/* <div className="footer-help">
                    <a href="" data-toggle="modal" data-target="#helpModal"><i className="icon-help"></i> Help</a>
                </div> */}
            </div>
        );
    }
}

export default Footer;