import React, { Component } from 'react'
import Breadcumb from '../externaljs/Breadcrumb';
import { Link } from 'react-router-dom';
import FullCalendar from 'fullcalendar-reactwrapper';
import PostDataWithParam from '../services/PostDataWithParam';
import Storage from '../externaljs/Storage';

class ViewDriver extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Drivers', link: '/independent-mover/dashboard/drivers', class: '' },
            { title: 'Driver Details', link: '', class: 'active' }
        ],
        fields: {},
        events:[],
        staff_user_id: null,
        custom_menu: []
    }

    componentDidMount() {
        window.$('[data-toggle="image-modal"]').click(function (e) {
            e.preventDefault();
            var modalTitle = window.$(this).attr('data-title');
            var imgSrc = window.$(this).attr('data-src');
            window.$('#imageModal .modal-title').html(modalTitle);
            window.$('#imageModal .modal-body').html('<img src="' + imgSrc + '" class="img-responsive"/>');
            window.$('#imageModal').modal('show');
        });
        this.getUserInfo();

        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        let custom_menu = [];
        if(staff_user_id!=""){
            custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
        }
        this.setState({ staff_user_id: staff_user_id , custom_menu : custom_menu}, () => {
            // console.log(this.state.custom_menu);
            // console.log(this.state.staff_user_id);
        });
       
    }

    getUserInfo = () => {
        PostDataWithParam('GetDriverDetails', { driver_id: this.props.match.params.driver_id })
            .then((response) => {
                if (response.success === 1) {
                    let response_data = response.data[0];
                    for (var key in response_data) {
                        if (response_data.hasOwnProperty(key)) {
                            if (response_data[key] == null) {
                                response_data[key] = ""
                            }
                        }
                    }
                    this.setState({ fields: response_data },()=>{
                        
                        this.getEvents(this.state.fields.user_id);
                    });
                }
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong.', 'error')
            })
    }

    getEvents = (user_id) => {
        PostDataWithParam('GetEvents', {type:'1',user_id: user_id})
        .then(response => {
            if(response.success === 1){
                this.setState({events: response.data})
            } else {
                this.setState({events: []})
            }
        })
        .catch(err => {
            this.setState({events: []})
        })
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        {Breadcumb(this.state.breadcrumb)}
                        <div className="row">
                            <div className="col-md-2">
                                <div className="profile-image">
                                    <img src={this.state.fields.profile_image || "assets/images/profile-pic-sample.jpg"} alt="" className="img-responsive" />
                                </div>
                                <div className="text-center text-orange m-b-20">
                                    <p className="m-t-15 m-b-0">
                                        <span className="icon-star-full2"></span>
                                        <span className="icon-star-full2"></span>
                                        <span className="icon-star-full2"></span>
                                        <span className="icon-star-full2"></span>
                                    </p>
                                    <p className="m-0 f-s-24">4.0 <small className="text-muted f-s-13">(765 ratings)</small></p>
                                </div>
                            </div>
                            <div className="col-md-10">
                                <div className="panel panel-flat profile-panel">
                                    <div className="panel-heading clearfix">
                                        <div className="heading-elements">
                                            {
                                                this.state.staff_user_id=="" &&
                                                <ul className="icons-list">
                                                <li className="dropdown">
                                                    <a href="#" className="dropdown-toggle text-mid-grey" data-toggle="dropdown" aria-expanded="false"><i className="icon-menu7"></i></a>
                                                    <ul className="dropdown-menu dropdown-menu-right">
                                                        <li><Link to={"/independent-mover/dashboard/editdriver/" + this.state.fields.id}><i className="icon-pencil"></i> 
                                                        {this.state.fields.profile_id==6 ? 'Edit Driver' : 'Edit Packer'}</Link></li>
                                                    </ul>
                                                </li>
                                                </ul>
                                            }
                                            {
                                                this.state.staff_user_id!="" && this.state.custom_menu.map((menu,i)=>{
                                                    return(
                                                        <ul className="icons-list">
                                                            {(menu.title == "Manage Team" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                                <li className="dropdown">
                                                                <a href="#" className="dropdown-toggle text-mid-grey" data-toggle="dropdown" aria-expanded="false"><i className="icon-menu7"></i></a>
                                                                <ul className="dropdown-menu dropdown-menu-right">
                                                                    <li><Link to={"/independent-mover/dashboard/editdriver/" + this.state.fields.id}><i className="icon-pencil"></i> Edit Driver</Link></li>
                                                                </ul>
                                                            </li>
                                                            }
                                                        </ul>
                                                    );
                                                })
                                            }
                                        </div>
                                        <h2 className="pull-left text-success m-b-0 m-t-0 text-medium">{this.state.fields.gender} {this.state.fields.first_name} {this.state.fields.last_name}</h2>
                                    </div>
                                    <div className="panel-body p-t-0">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Address</label>
                                                <p className="m-b-20">{this.state.fields.address || "----"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Email Address</label>
                                                <p className="m-b-20">{this.state.fields.email || "----"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Recovery Email Address</label>
                                                <p className="m-b-20">{this.state.fields.alternate_email || "----"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Mobile Phone</label>
                                                <p className="m-b-20">{this.state.fields.contact_no || "----"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Alternate Phone No</label>
                                                <p className="m-b-20">{this.state.fields.home_contact_no || "----"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Date Of Birth</label>
                                                <p className="m-b-20">{this.state.fields.dob || "----"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Social Security number</label>
                                                <p className="m-b-20">{this.state.fields.ssn || "----"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Years of Experience</label>
                                                <p className="m-b-20">{this.state.fields.years_of_experience || "----"}</p>
                                            </div>
                                        </div>
                                        <hr className="m-t-0" />
                                        <h5 className="text-medium m-t-0 text-green m-b-15">Drivers License Info</h5>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Drivers License No</label>
                                                <p className="m-b-20">{this.state.fields.licence_number || "----"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Drivers License State</label>
                                                <p className="m-b-20">{this.state.fields.licence_state || "----"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Drivers License Expiration Date</label>
                                                <p className="m-b-20">{this.state.fields.license_exp_date || "----"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">License Image</label>
                                                {
                                                    this.state.fields.licence_image!=""
                                                    &&
                                                    <p className="m-b-20"><a href="" data-src={this.state.fields.licence_image || "assets/images/drivers-license.png"} data-title="Driver's License" data-toggle="image-modal">View License Image</a></p>
                                                }
                                                {
                                                    this.state.fields.licence_image==""
                                                    &&
                                                    <p className="m-b-20">No License Image Found</p>
                                                }                                                
                                            </div>
                                        </div>
                                        <hr className="m-t-0" />
                                        <h5 className="text-medium m-t-0 text-green m-b-15">Passport Info</h5>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Passport Number</label>
                                                <p className="m-b-20">{this.state.fields.passport_no || "----"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Passport Expiration Date</label>
                                                <p className="m-b-20">{this.state.fields.passport_exp_date || "----"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Place of Birth</label>
                                                <p className="m-b-20">{this.state.fields.place_of_birth || "----"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Passport Image</label>                                                
                                                {
                                                    this.state.fields.passport_image!=""
                                                    &&
                                                    <p className="m-b-20"><a href="" data-src={this.state.fields.passport_image || "assets/images/passport.jpg"} data-title="Passport" data-toggle="image-modal">View Passport</a></p>
                                                }
                                                {
                                                    this.state.fields.passport_image==""
                                                    &&
                                                    <p className="m-b-20">No Passport Found</p>
                                                }  
                                            </div>
                                        </div>
                                        <hr className="m-t-0" />
                                        <h5 className="text-medium m-t-0 text-green m-b-15">Medical Info</h5>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Medical Card Issue Date</label>
                                                <p className="m-b-20">{this.state.fields.medical_card_issue_date || "----"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Medical Card Expiration Date</label>
                                                <p className="m-b-20">{this.state.fields.medical_exam_exp_date || "----"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Medical Card Image</label>
                                                {
                                                    this.state.fields.medical_card_image!=""
                                                    &&
                                                    <p className="m-b-20"><a href="" data-src={this.state.fields.medical_card_image || "assets/images/medical-card.jpg"} data-title="Medical Card" data-toggle="image-modal">View Medical Card Image</a></p>
                                                }
                                                {
                                                    this.state.fields.medical_card_image==""
                                                    &&
                                                    <p className="m-b-20">No Medical Card Found</p>
                                                }                                                
                                            </div>
                                        </div>
                                        <hr className="m-t-0" />
                                        <h5 className="text-medium m-t-0 text-green m-b-15">Background Check Info</h5>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Background check performed Date</label>
                                                <p className="m-b-20">{this.state.fields.background_check_perform_date || "----"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Background Check Completed</label>
                                                <p className="m-b-20">{this.state.fields.background_check_status == 1 ? 'Yes' : 'No'}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Background check will be requested to Mover</label>
                                                <p className="m-b-20">{this.state.fields.background_check_requested_to || "----"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Background Check Image</label>                                                
                                                {
                                                    this.state.fields.background_check_image!=""
                                                    &&
                                                    <p className="m-b-20"><a href="" data-src={this.state.fields.background_check_image || "assets/images/passport.png"} data-title="Background check" data-toggle="image-modal">View Background image</a></p>
                                                }
                                                {
                                                    this.state.fields.background_check_image==""
                                                    &&
                                                    <p className="m-b-20">No Background image Found</p>
                                                } 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="p-t-20 m-b-20 text-regular">About Me</h4>
                                <div className="panel panel-default profile-panel">
                                    <div className="panel-body">
                                        <p>
                                            {this.state.fields.about_me || "----"}
                                        </p>
                                    </div>
                                </div>
                                <h4 className="p-t-20 m-b-20 text-regular">View Schedule</h4>
                                <div className="panel panel-default profile-panel">
                                    <div className="panel-body">
                                        <div>
                                            <FullCalendar
                                                id="cal123"
                                                
                                                header={{
                                                    left: 'prev,next today myCustomButton',
                                                    center: 'title',
                                                    right: 'month,basicWeek,basicDay'
                                                }}
                                                navLinks={true} // can click day/week names to navigate views
                                                editable={true}
                                                eventLimit={true} // allow "more" link when too many events
                                                events={this.state.events}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="imageModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                <h4 className="modal-title" id="myModalLabel">Modal title</h4>
                                            </div>
                                            <div className="modal-body">
                                                ...
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewDriver;