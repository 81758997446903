import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import PostDataWithParam from '../services/PostDataWithParam';
import MilitaryConversations from './MilitaryConversations';
import { Prompt } from 'react-router';

class Responsible extends Component {
    state = {
        fields: {
            organized_orderly: -1,
            is_management_structure: -1,
            utilize_computer_web: -1,
            utilize_computer_web_name: '',
            cell_phone: -1,
            id: '',
            tab_id: 7
        },
        saveStatus: false,
        proccesing:false,
        formDirty:false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        PostDataWithParam('GetResponsibleManagementOperations',{})
        .then((response) => {
            if (response.success == 1) {
                let response_data = {...this.state.fields, ...response.data};
                this.setState({ fields: response_data });
            }
        })
        window.$('[data-popup="popover"]').popover();
        window.$('[data-popup="tooltip"]').tooltip();
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields,formDirty:true});
    }

    updateResponsibleManagementOperations = () => {
        this.setState({proccesing:true})
        PostDataWithParam('SaveResponsibleManagementOperations', this.state.fields)
            .then((response) => {
                if (response.success === 1) {
                    window.swal('Success!', response.msg, 'success')
                    this.setState({ saveStatus: true,proccesing:false,formDirty:false }, () => {
                        this.props.onUpdateApprovalProgress();
                    });
                } else {
                    this.setState({proccesing:false,formDirty:false})
                    window.swal('Error!', response.msg, 'error')
                }
            })
    }

    openModal = (field_name,tab_id) => {
        this.props.onOpenModal(field_name,tab_id)
    }

    renderAlertMsg = (field_name,tab_id) => {
       
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
        

        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Answer Received</p>
                        </React.Fragment>
                    )
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Waiting for reply</p>
                        </React.Fragment>
                    )
                }
            }
        }
    }
    renderClassName = (field_name,tab_id) => {
       
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
        

        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return "btn-success "
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return "btn-warning "
                }
            }
        }


    }
    render() {
        if (this.state.saveStatus) {
            return <Redirect to='/independent-mover/dashboard/military-approved/information-tab/fire' />
        }

        return (
            <div className="tab-pane active" id="top-icon-tab7">
                <Prompt
                when={this.state.formDirty}
                message="Please save your data before you leave the page. Otherwise, you will lose the information you entered !"
                />
                <div className="panel panel-flat">
                    <div className="panel-heading">
                        <h5 className="panel-title">Responsible Management &amp; Operations &nbsp;<a href="#" data-popup="popover" title="" data-trigger="hover" data-content="Inspection records will contain evidence of good management and operational practices.Such evidence may be obtained through visual observation of the facility,equipment,and personnel and from information relating to credit standing and financial stability.Whenever a state or municipality requires a business firm to be licensed to operate a personal property storage warehouse,possession of such license will be verified."><i className="icon-info22"></i></a></h5>
                    </div>
                    <div className="panel-body">
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Are your offices organized and orderly?</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="organized_orderly" onChange={this.changeValue} checked={(this.state.fields.organized_orderly == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="organized_orderly" onChange={this.changeValue} checked={(this.state.fields.organized_orderly == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('organized_orderly',this.state.fields.tab_id)} className={this.renderClassName('organized_orderly',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('organized_orderly',this.state.fields.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Is there a management structure of workers, supervisors, and senior management, to ensure good management of relocation process?</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="is_management_structure" onChange={this.changeValue} checked={(this.state.fields.is_management_structure == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="is_management_structure" onChange={this.changeValue} checked={(this.state.fields.is_management_structure == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('is_management_structure',this.state.fields.tab_id)} className={this.renderClassName('is_management_structure',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('is_management_structure',this.state.fields.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Do you utilize a computer/web based booking and operations management system?</h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="utilize_computer_web" onChange={this.changeValue} checked={(this.state.fields.utilize_computer_web == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="utilize_computer_web" onChange={this.changeValue} checked={(this.state.fields.utilize_computer_web == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('utilize_computer_web',this.state.fields.tab_id)} className={this.renderClassName('utilize_computer_web',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('utilize_computer_web',this.state.fields.tab_id)} 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h5> If so, please give name</h5>
                                    <input type="text" className="form-control" name="utilize_computer_web_name" onChange={this.changeValue} value={this.state.fields.utilize_computer_web_name || ""} />
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Does all your staff have cell phones?</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="cell_phone" onChange={this.changeValue} checked={(this.state.fields.cell_phone == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="cell_phone" onChange={this.changeValue} checked={(this.state.fields.cell_phone == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('cell_phone',this.state.fields.tab_id)} className={this.renderClassName('cell_phone',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('cell_phone',this.state.fields.tab_id)} 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MilitaryConversations/>   
                <div className="text-center">{this.state.proccesing? <button className="btn btn-primary" disabled={true}>Processing...</button>:<button className="btn btn-primary" onClick={this.updateResponsibleManagementOperations}>Save &amp; Next</button>}</div>
            </div>

        );
    }
}

export default Responsible;