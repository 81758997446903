import React, { Component } from 'react'
import { Link, Redirect } from "react-router-dom";
import Breadcumb from '../externaljs/Breadcrumb';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import PostDataWithParam from '../services/PostDataWithParam';
import Storage from '../externaljs/Storage';
import GetData from '../services/GetData';
import DateFormat from '../externaljs/DateFormat';
import Urls from "../externaljs/Urls"
const currentYear = new Date().getFullYear();

class ViewCustomerOrders extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Customer', link: '/independent-mover/dashboard/customer', class: 'active' },
            { title: 'View Order', link: '', class: 'active' }
        ],
        order_list: [],
        tracking_list: [],
        loading: false,
        tempOrder: {},
        tempTrackOrder: [],
        tracking_nxt_btn: 1,
        editPermission: true,
        custom_menu: [],
        manage_orders: true,
        reloOrder: true,
        edcOrder: false,
        edcOrderList: [],

        date_from: window.moment(currentYear + "-01-01").format("MM/DD/YYYY"),
        date_to: window.moment().format("MM/DD/YYYY"),
        lastSessionId: 0
    }

    staffMenuCheckInterval = null;
    subscriptionCheckInterval = null;

    componentDidMount() {
        this.GetOrderList();
        this.staffMenuCheckInterval = setInterval(() => {
            if (Storage.get('mmcStaffMenu')) {
                let custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
                this.setState({ custom_menu }, () => {
                    // console.log(this.state.custom_menu)
                    let find_reloOrders = this.state.custom_menu.find(menu => menu.title == "ReloOrders")
                    if (find_reloOrders) this.setState({ manage_orders: true })
                    else this.setState({ manage_orders: false })
                });
            }
        }, 1000)

        this.subscriptionCheckInterval = setInterval(() => {
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1)
                this.setState({ editPermission: false })
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ editPermission: true })
        }, 1000);
    }

    componentWillUnmount() {
        if (this.staffMenuCheckInterval) {
            clearInterval(this.staffMenuCheckInterval)
        }
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
    }

    GetOrderList = () => {
        this.setState({ loading: true }, () => {
            PostDataWithParam('GetOrdersOfCustomerForMover', { customer_user_id: this.props.location.state.user_id })
                .then((response) => {
                    if (response.success == 1) {

                        this.setState({ order_list: response.data, loading: false });
                        DataTableBasic();
                    } else {
                        this.setState({ order_list: [], loading: false });
                    }
                })
                .catch(err => {
                    this.setState({ order_list: [], loading: false });
                })
        })
    }

    GettrackingList = () => {
        GetData('GetAllOrderTrackingStatus')
            .then((response) => {
                if (response.success == 1) {
                    this.setState({ tracking_list: response.data, loading: false }, () => {

                        let tracking_list = this.state.tracking_list;
                        let count = this.state.tempTrackOrder.length;

                        for (let list_index in tracking_list) {
                            for (let track of this.state.tempTrackOrder) {
                                if (track.order_status_field == tracking_list[list_index].id) {
                                    tracking_list[list_index]['track_class'] = "complete";
                                    tracking_list[list_index]['track_date'] = track.order_status_time;
                                    tracking_list[list_index]['track_time'] = track.order_status_time;
                                    tracking_list[list_index]['track_btn_status'] = 1;
                                    if (tracking_list[list_index].id >= this.state.tracking_nxt_btn) {
                                        this.setState({ tracking_nxt_btn: parseInt(tracking_list[list_index].id) + 1 });
                                    }
                                }
                            }
                        }

                        if (this.state.tracking_list.length > 0 && this.state.tempTrackOrder.length > 0)
                            tracking_list[count - 1]['track_class'] = "current";

                        this.setState({ tracking_list: tracking_list }, () => {
                            window.$("#tracking-Move").modal('show')
                        });
                    });
                } else {
                    this.setState({ tracking_list: [], loading: false });
                }
            })
            .catch(err => {
                this.setState({ tracking_list: [], loading: false });
            })
    }

    renderStatus = (status, order) => {
        if (status == 0) {
            return (
                <div>
                    <span className="text-orange m-r-5" href="" data-toggle="tooltip" data-placement="top" title="Pending"><i className="icon-circle2"></i></span> {order.tracking_status || "--"} (<a href="javascript:void(0)" onClick={() => this.setTempOrder(order)}>Update</a>)
                </div>
            )
        }
        else if (status == 1) {
            return (
                <div>
                    <span className="text-success m-r-5" href="" data-toggle="tooltip" data-placement="top" title="In Progress"><i className="icon-circle2"></i></span> {order.tracking_status || "--"} (<a href="javascript:void(0)" onClick={() => this.setTempOrder(order)}>Update</a>)
                </div>
            )
        } else if (status == 2) {
            return (
                <div>
                    <span className="text-muted m-r-5" href="" data-toggle="tooltip" data-placement="top" title="Closed"><i className="icon-circle2"></i></span> {order.tracking_status || "--"} (<a href="javascript:void(0)" onClick={() => this.setTempOrder(order)}>Update</a>)
                </div>
            )
        }
    }

    renderOrderType = (hire_by_user_id) => {
        if (hire_by_user_id === (JSON.parse(Storage.get('imnLoginData'))).id)
            return ''
        else
            return 'accepted'
    }

    removeDataTable = () => {
        if (window.$.fn.DataTable.isDataTable('#list-table')) {
            window.$('#list-table').DataTable().destroy();
        }

        window.$('#list-table tbody').empty();
    }

    setTempOrder = (order) => {
        this.setState({ tempOrder: order }, () => {
            PostDataWithParam('TrackOrder', { order_request_detail_id: this.state.tempOrder.order_request_detail_id })
                .then((response) => {
                    if (response.success == 1) {
                        this.setState({ tempTrackOrder: response.data, loading: false }, () => {
                            this.GettrackingList()
                        });
                    } else {
                        this.setState({ tempTrackOrder: [], loading: false });
                    }
                })
                .catch(err => {
                    this.setState({ tempTrackOrder: [], loading: false });
                })
        });
    }

    changeDoneStatus = (id, title) => {
        let postData = {
            order_request_detail_id: this.state.tempOrder.order_request_detail_id,
            order_status_id: id
        }

        PostDataWithParam('SaveOrderTrackingStatus', postData)
            .then((response) => {
                if (response.success == 1) {
                    window.swal('Success!', response.msg, 'success')
                    this.removeDataTable();
                    this.GetOrderList();
                    window.$("#tracking-Move").modal('hide')

                } else {
                    window.swal('Error!', response.msg, 'error')
                }
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error')
            })
    }

    doSubscribe = (e) => {
        window.swal({
            title: "Warning!",
            text: "Please upgrade your membership to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }

    initDatePicker = (name) => {
        window.$('#' + name).daterangepicker({
            startDate: this.state[name],
            showDropdowns: true,
            autoclose: true,
            singleDatePicker: true,
            // minDate: this.getMinDate(name),
            // container: '#manageProfile modal-body',
        }).on('apply.daterangepicker', (ev, picker) => {
            let formatDate = picker.startDate.format('MM/DD/YYYY');
            this.setState({ [name]: formatDate });

        });

    }

    getEDCOrderList = () => {
        const { date_from, date_to } = this.state;
        let lastSessionId = this.state.lastSessionId + 1;
        this.setState({ loading: true, lastSessionId })
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');

        let data = {
            ShipmentFromDate: window.moment(date_from, "MM-DD-YYYY").format("YYYY-MM-DD"),
            ShipmentToDate: window.moment(date_to, "MM-DD-YYYY").format("YYYY-MM-DD"),
            PageNumber: 0,
            PageSize: 0
        }

        fetch(Urls.edcBaseURL + 'Shipment', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(res => {
                if (res && res.Results && lastSessionId === this.state.lastSessionId) {
                    this.setState({ edcOrderList: res.Results, loading: false });
                    DataTableBasic();
                    this.initDatePicker('date_from');
                    this.initDatePicker('date_to');
                }

            })
            .catch((err) => {
                if (lastSessionId === this.state.lastSessionId) {
                    this.setState({ edcOrderList: [], loading: false }, () => {
                        console.log(this.state.edcOrderList)
                        this.initDatePicker('date_from');
                        this.initDatePicker('date_to');
                    })
                }
            })

    }

    handleSwitchForm = (e) => {
        if (e.target.innerText == 'Relo Orders') {
            this.setState({ reloOrder: true, edcOrder: false });
            this.GetOrderList();
        } else {
            this.setState({ reloOrder: false, edcOrder: true })
            this.getEDCOrderList();
        }
    }

    render() {
        if (typeof this.props.location.state == 'undefined') {
            return <Redirect to='/independent-mover/dashboard/customer' />
        }
        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="clearfix">

                        <h4 className="pull-left">Customer Orders List</h4>
                    </div>
                    <div className="panel-header bg-white border-radius-3px">
                        <ul className="nav nav-tabs nav-tabs-bottom nav-horizantal-scroll m-b-0">
                            <li className={this.state.reloOrder ? 'active' : ''}>
                                <Link to="#" data-toggle="tab" aria-expanded="true" name="reloOrder" onClick={this.handleSwitchForm}><h6>Relo Orders</h6></Link>
                            </li>
                            {this.props.location.state.profile_id == 11 &&
                                <li className={this.state.edcOrder ? 'active' : ''}>
                                    <Link to="#" data-toggle="tab" aria-expanded="true" name="edcOrder" onClick={this.handleSwitchForm}><h6>EDC Orders</h6></Link>
                                </li>
                            }
                        </ul>
                    </div>

                    {this.state.reloOrder &&
                        <div className="panel panel-flat">
                            {
                                this.state.loading
                                &&
                                <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                            }
                            {
                                !this.state.loading
                                &&
                                this.state.order_list.length !== 0
                                &&
                                <div className="table-responsive">
                                    <table className="table datatable-basic table-striped" id="list-table">
                                        <thead>
                                            <tr>
                                                <th style={{ whiteSpace: 'nowrap' }}>Relo #</th>
                                                <th style={{ whiteSpace: 'nowrap' }}>Requested Start Date</th>
                                                <th>Current Status</th>
                                                <th style={{ whiteSpace: 'nowrap' }}>Requested End Date</th>
                                                <th>Service Address</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.order_list.map((order, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {/* <td>
                                                            <a
                                                                href="javascript:void(0)"
                                                            >#{order.order_id}
                                                            </a>
                                                        </td> */}
                                                            <td>{this.state.editPermission ?
                                                                this.state.manage_orders ?
                                                                    <Link
                                                                        to={{
                                                                            pathname: '/independent-mover/dashboard/order-details',
                                                                            state: {
                                                                                id: order.id,
                                                                                type: this.renderOrderType(order.hire_by_user_id),
                                                                                request_from: order.request_from
                                                                            }
                                                                        }}
                                                                    >#{order.order_id}</Link>
                                                                    :
                                                                    <a href="javascript:void(0)">#{order.order_id}</a>
                                                                :
                                                                <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe}>#{order.order_id}</Link>
                                                            }
                                                            </td>
                                                            <td className="text-nowrap">{DateFormat.getFormattedDateTime(order.request_start_date, 'm-d-Y')}</td>
                                                            {/* <td>{this.renderStatus(order.status,order)}</td> */}
                                                            <td>{this.renderStatus(order.status, order == 1) ? 'Inprogress' : 'Completed'}</td>
                                                            <td className="text-nowrap">{DateFormat.getFormattedDateTime(order.request_end_date, 'm-d-Y')}</td>
                                                            <td ><p className="m-0 text-muted">{((order.source_address.length > 38) ? order.source_address.substr(0, 38) + '...' : order.source_address)}</p></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                            {
                                !this.state.loading
                                &&
                                this.state.order_list.length === 0
                                &&
                                <div className="alert alert-primary border-none text-center m-15">No Orders Found.</div>
                            }
                        </div>
                    }

                    {/* ------------------- for EDC orders (start)---------------- */}

                    {this.state.edcOrder &&
                        <div className="panel panel-flat">
                            {
                                this.state.loading
                                &&
                                <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                            }
                            {
                                !this.state.loading
                                &&
                                this.state.edcOrderList.length !== 0
                                &&
                                <React.Fragment>
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="m-b-15 clearfix">
                                                <div className="col-md-5">
                                                    <label>From</label>
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="icon-calendar22"></i></span>
                                                        <input type="text" className="form-control daterange-single" value={this.state.date_from} placeholder="Enter Date" name="date_from" id="date_from" />
                                                    </div>
                                                </div>
                                                <div className="col-md-5">
                                                    <label>To</label>
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="icon-calendar22"></i></span>
                                                        <input type="text" className="form-control daterange-single" value={this.state.date_to} placeholder="Enter Date" name="date_to" id="date_to" />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <label>&nbsp;</label>
                                                    <a href="javascript:void(0)" className="btn btn-primary btn-block text-uppercase" onClick={this.getEDCOrderList}>Search</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table datatable-basic table-striped" id="list-table">
                                            <thead>
                                                <tr>
                                                    <th style={{ whiteSpace: 'nowrap' }}>Shipment ID</th>
                                                    <th style={{ whiteSpace: 'nowrap' }}>Customer Name</th>
                                                    <th>Booked Date</th>
                                                    <th>Delivery Date</th>
                                                    <th>Current Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.edcOrderList.map((order, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-nowrap">{order.ShipmentId}</td>
                                                                <td className="text-nowrap">{order.FirstName + " " + order.LastName}</td>
                                                                <td>{window.moment(order.BookedDate).format("MM-DD-YYYY h:mm:ss")}</td>
                                                                <td>{DateFormat.getFormattedDateTime(order.DeliveryDate, 'm-d-Y h:i a')}</td>
                                                                <td>{order.status || 'In Progress'}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                {
                                                    this.state.edcOrderList.length === 0
                                                    &&
                                                    <td colspan="5">
                                                        <h6 className="alert alert-primary no-border text-center m-15">No EDC Orders Found.</h6>
                                                    </td>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </React.Fragment>
                            }
                            {
                                !this.state.loading
                                &&
                                this.state.edcOrderList.length === 0
                                &&
                                <div className="alert alert-primary border-none text-center m-15">No EDC Orders Found.</div>
                            }
                        </div>
                    }

                    {/* --------------------- edc order end ------------------ */}
                </div>

                <div id="tracking-Move" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Move Tracking</h4>
                            </div>
                            <hr className="m-0" />
                            <div className="modal-body alpha-grey p-0">
                                <div className="tracking-timeline">
                                    <ul>
                                        {
                                            this.state.tracking_list.map((item, i) => {
                                                return (
                                                    <li className={item.track_class || ""} key={i}>
                                                        <div data-date={(item.track_date) ? DateFormat.getFormattedDateTime(item.track_date, 'M d, Y') : ""} data-time={(item.track_date) ? DateFormat.getFormattedDateTime(item.track_date, 'H:i a') : ""}>{item.title}</div>

                                                        {
                                                            item.id == 6
                                                            &&
                                                            <React.Fragment>
                                                                <a href="javascript:void(0)" className="f-s-14"><i className="icon-location4"></i> Live View Driver Location</a>
                                                                <br />
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            item.id == 14
                                                            &&
                                                            <React.Fragment>
                                                                <a href="javascript:void(0)" className="f-s-14"><i className="icon-pencil"></i> View Survey</a>
                                                                <br />
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            this.state.tracking_nxt_btn == item.id
                                                            &&
                                                            <a href="javascript:void(0)" className="btn btn-success btn-xs btn-labeled" onClick={() => this.changeDoneStatus(item.id, item.title)}><b><i className="icon-check2"></i></b>Done</a>
                                                        }
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                            <hr className="m-0" />
                            <div className="modal-footer p-t-15">
                                <button type="button" className="btn btn-primary btn-xs" data-dismiss="modal">View Customer Satisfaction Survey</button>
                                <button type="button" className="btn btn-default btn-xs" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ViewCustomerOrders;