import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import GetData from '../services/GetData';
import DateFormat from '../externaljs/DateFormat';

class FindService extends Component {

    state = {
        lists: [],
        count:''
    }
    componentDidMount(){
        this.GetAllGlobelRequest();
    }
    GetAllGlobelRequest = () =>{
            GetData('GetAllGlobalRequest')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ lists: response.data },() =>{
                        this.setState({ count: response.count });
                    });
                }
            })
    }

    render() {
        return (
            <div>
                <h4 className="m-t-0">Found {this.state.count} service jobs for you</h4>
                <div className="row">
                {this.state.lists.map(list =>
                    <div className="col-md-6">
                        <div className="panel panel-flat">
                            <div className="panel-body">
                                <ul className="list-inline list-inline-separate text-muted mb-5">
                                    <li><i className="icon-user position-left"></i> {list.first_name} {list.last_name}</li>
                                    <li> {DateFormat.getFormattedDateTime(list.moving_date, 'd M, Y')}</li>
                                    <li className="pull-right"><span className="label label-primary">Military</span></li>
                                </ul>

                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="text-bold m-b-0">Pickup Location:</label> <p className="m-b-0">{list.source_address}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="text-bold m-b-0"> Drop off Location :</label> <p className="m-b-0">{list.destination_address}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Link to="/independent-mover/dashboard/quote-details" className="btn btn-success m-t-15 btn-xs"><i className="icon-eye"></i> View Details</Link>
                                    </div>
                                    <div className="col-md-6">
                                        <p className="m-b-0 text-warning-800 m-t-20"><i className="icon-exclamation"></i> Waiting Pre-Move Survey</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-footer panel-footer-condensed">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="heading-text m-t-10 m-l-20">
                                            <span className="status-mark border-danger position-left"></span> Relocation Request Date: <span className="text-semibold">{DateFormat.getFormattedDateTime(list.req_time, 'd M, Y')}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <ul className="list-inline list-inline-condensed heading-text pull-right m-t-10 m-r-20">
                                            <li><span className="text-default"><i className="icon icon-attachment"></i> {list.attachment_count} attachment(s)</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
              </div>
                <div className="text-center">
                    <ul className="pagination">
                        <li><a href="#"><i className="icon-arrow-left22"></i> Pervious</a></li>
                        <li><a href="#">1</a></li>
                        <li className="active"><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#">5</a></li>
                        <li><a href="#">Next <i className="icon-arrow-right22"></i></a></li>
                    </ul>
                </div>
            </div>

        )
    }
}
export default FindService