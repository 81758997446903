import React, { Component } from 'react'

import GetData from '../services/GetData';
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataWithParam from '../services/PostDataWithParam';
import ConfirmBox from '../externaljs/ConfirmBox';

class CardList extends Component {
    state = {
        cardList: [],
        fields: {
            card_holder_name: '',
            card_no: '',
            expiry_month: '',
            expiry_year: '',
            expiry_date:'',
            cvv_no: '',
            card_id: 0
        },
        fields_error: {
            card_holder_name_err: '',
            card_no_err: '',
            expiry_err: '',
            cvv_err: ''
        },
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'My Saved Cards', link: '', class: 'active' }
        ],
        saveStatus: false,
        DeleteStatus: false
    }

    componentDidMount() {
        window.$('.table-responsive').on('show.bs.dropdown', function () {
            window.$('.table-responsive').css( "overflow", "inherit" );
        });

        window.$('.table-responsive').on('hide.bs.dropdown', function () {
             window.$('.table-responsive').css( "overflow", "auto" );
        })
        
        this.GetCardList()
    }

    changeValue = (e) => {
        if(e.target.name=="cvv_no" && +e.target.value.length >3) return;
        
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields });
    }
    resetCardValue = () => {
        let  fields =  {
            card_holder_name: '',
            card_no: '',
            expiry_month: '',
            expiry_year: '',
            expiry_date:'',
            cvv_no: '',
            card_id: 0
        }

        let fields_error =  {
            card_holder_name_err:'',
            card_no_err:'',
            expiry_err:'',
            cvv_err:''
        }

        this.setState({ fields:fields,fields_error:fields_error });
    }

    GetCardList = () => {
        GetData('GetCardList')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ cardList: response.data });
                }
            })
            .catch(err => {
                this.setState({ cardList: [] });
            })
    }

    resetErr = (cb) => {
        let fields_error = {
            card_holder_name_err: '',
            card_no_err: '',
            expiry_err: '',
            cvv_err: ''
        }

        this.setState({ fields_error: fields_error }, () => {
            cb()
        });
    }

    handleAddCard = (e) => {
        e.preventDefault();
        let url;
        if (this.state.fields.card_id == 0)
            url = 'AddCard'
        else
            url = 'EditCard'

        this.resetErr(() => {
            let fields = this.state.fields;
            let fields_error = this.state.fields_error;
            let numberReg = /^(?=.*\d)[\d ]+$/;
            let cardExp = /^\s*(0?[1-9]|1[0-2])\/(\d\d|\d{4})\s*$/;
            let date = new Date();     

            if (fields.card_holder_name == "") {
                fields_error.card_holder_name_err = "Enter card holder name"

            } else if (!numberReg.test(fields.card_no)) {
                fields_error.card_no_err = "Enter valid card no"

            } else if (fields.card_no.length != 19) {
                fields_error.card_no_err = "Enter valid card no"

            }
            else if (!cardExp.test(fields.expiry_date)) {
                fields_error.expiry_err = "Enter valid format of month and year (MM/YYYY)";

            } 
            else if (fields.cvv_no.length != 3) {
                fields_error.cvv_err = "Enter valid cvv"

            } 
            
            else {                
                let expiry = fields.expiry_date.split("/");
                fields.expiry_month = expiry[0];
                fields.expiry_year = expiry[1];             
                this.setState({ fields: fields },()=>{
                    PostDataWithParam(url, this.state.fields)
                    .then((response) => {
                        if (response.success === 1) {
                            window.$("#addCard").modal('hide')
                            window.swal('Success!', response.msg, 'success')
                            this.GetCardList()
                            this.setState({ saveStatus: true })
                        } else {
                            window.swal('Error!', response.msg, 'error')
                            this.setState({ saveStatus: false })
                        }
                    })
                    .catch(err => {
                        window.swal('Failed!', 'Oops! Something went wrong.', 'error');
                    })
                });
                
            }

            this.setState({ fields_error: fields_error });
        });
    }

    DeleteCard = (id) => {
        ConfirmBox("You want to delete this Card!")
            .then((result) => {
                if (result) {
                    PostDataWithParam('DeleteCard', { card_id: id })
                        .then((response) => {
                            if (response.success === 1) {
                                window.swal('Success!', response.msg, 'success');
                                this.setState({ DeleteStatus: true });
                                this.GetCardList()
                            } else {
                                window.swal('Failed!', response.msg, 'error');
                            }
                        })
                }
            })
            .catch(err => {
                window.swal('Failed!', 'Oops! Something went wrong.', 'error');
            })
    }

    renderPrimary = (status) => {
        if (status == 1)
            return <span className="label bg-green-800">Primary</span>
        else
            return ''
    }

    renderPrimaryBtn = (status, id) => {
        if (status == 0)
            return <li><a href="javascript:void(0)" onClick={() => this.SetCard(id)}><i className="icon-check"></i> Set as Primary</a></li>
        else
            return ''
    }

    SetCard = (id) => {
        PostDataWithParam('SetPrimaryCard', { card_id: id })
            .then((response) => {
                if (response.success === 1) {
                    window.swal('Success!', response.msg, 'success')
                    this.GetCardList()
                } else {
                    window.swal('Error!', response.msg, 'error')
                }
            })
            .catch(err => {
                window.swal('Failed!', 'Oops! Something went wrong.', 'error');
            })
    }

    EditCard = (id, card_name, card_no, expiry_month, expiry_year, cvv_no) => {
        let fields = {
            card_holder_name: card_name,
            card_no: card_no,
            expiry_month: expiry_month,
            expiry_year: expiry_year, 
            expiry_date: expiry_month + '/' + expiry_year,
            cvv_no: cvv_no,
            card_id: id
        }

        let fields_error =  {
            card_holder_name_err:'',
            card_no_err:'',
            expiry_err:'',
            cvv_err:''
        }

        this.setState({ fields: fields,fields_error:fields_error }, () => {
            window.$("#addCard").modal('show')
        });
    }

    changeFormat = (e) => {

        let fields = this.state.fields;
        let value = e.target.value;
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
        var matches = v.match(/\d{4,16}/g);
        var match = matches && matches[0] || '';
        var parts = [];

        for (let i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4))
        }

        if (parts.length) {
            fields.card_no = parts.join(' ')
        } else {
            fields.card_no = value;
        }

        this.setState({ fields: fields });
    }

    cardFormat = (str) => {
        const trailingCharsIntactCount = 4;
        let visblecc = str.slice(-trailingCharsIntactCount);
        return str = 'XXXX XXXX XXXX ' + visblecc;
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        {Breadcumb(this.state.breadcrumb)}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="panel panel-flat">
                                    <div className="panel-heading p-b-15">
                                        <h5 className="panel-title">My Saved Cards</h5>
                                        <div className="heading-elements">
                                            <a href="javascript:void(0)" className="btn btn-xs btn-success" data-toggle="modal" data-target="#addCard" onClick={this.resetCardValue}><i className="icon-plus2"></i> Add New Card</a>
                                        </div>
                                    </div>
                                    <div className="panel-body p-0">
                                        <div className="table-responsive">
                                            <table className="table text-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Card Holder Name</th>
                                                        <th>Card No</th>
                                                        <th className="col-md-2">Expiration Date</th>
                                                        <th></th>
                                                        <th className="col-md-2 text-center">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.cardList.map((card, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td><h6 className="text-semibold">{card.card_name}</h6></td>
                                                                    <td><h6 className="text-semibold"> <i className="icon-credit-card pull-lef"></i> {this.cardFormat(card.card_no)}</h6></td>
                                                                    <td><h6 className="text-semibold">{card.expiry_month}/{card.expiry_year}</h6></td>
                                                                    <td>{this.renderPrimary(card.primary_card)}</td>
                                                                    <td className="text-center">
                                                                        <ul className="icons-list">
                                                                            <li className="dropdown">
                                                                                <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"><i className="icon-menu7"></i></a>
                                                                                <ul className="dropdown-menu dropdown-menu-right">
                                                                                    {this.renderPrimaryBtn(card.primary_card, card.id)}
                                                                                    <li><a href="javascript:void(0)" onClick={() => this.EditCard(card.id, card.card_name, card.card_no, card.expiry_month, card.expiry_year, card.cvv_no)}><i className="icon-pencil"></i> Edit Card</a></li>
                                                                                    <li><a href="javascript:void(0)" onClick={() => this.DeleteCard(card.id)}><i className="icon-trash"></i> Remove Card</a></li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="addCard" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Add Credit/Debit Card</h4>
                            </div>
                            <form action="#" method="POST" onSubmit={this.handleAddCard}>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Card Holder Name</label>
                                                <input className="form-control" type="text" placeholder="Enter Card Holder Name" name="card_holder_name" onChange={this.changeValue} value={this.state.fields.card_holder_name} />
                                                <p className="text-danger">{this.state.fields_error.card_holder_name_err}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Card No</label>
                                                <input className="form-control" type="text" placeholder="Enter 16 digit card no" name="card_no" onChange={this.changeFormat} value={this.state.fields.card_no} />
                                                <p className="text-danger">{this.state.fields_error.card_no_err}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Expiration Date (MM/YYYY)</label>
                                                <input className="form-control" type="text" placeholder="MM/YYYY" name="expiry_date" onChange={this.changeValue} value={this.state.fields.expiry_date} />
                                                <p className="text-danger">{this.state.fields_error.expiry_err}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>CVV</label>
                                                <input className="form-control"  type="number" placeholder="Enter CVV" id="cvv_no" name="cvv_no" onChange={this.changeValue} value={this.state.fields.cvv_no}  maxlength="3"/>
                                                <p className="text-danger">{this.state.fields_error.cvv_err}</p>
                                                <p className="help-block"><i className="icon-info22"></i> CVV : A 3 digit code on the card, you can see it on back side of your card.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CardList;