export function addView(id, show) {
    if (!window.$("#" + id)[0]) {
        window.$("<div/>", {
            id: "remote_video_panel_" + id,
            class: "video-view",
        }).appendTo("#video");

        window.$("<div/>", {
            id: "remote_video_" + id,
            class: "video-placeholder",
        }).appendTo("#remote_video_panel_" + id);

        window.$("<div/>", {
            id: "remote_video_info_" + id,
            class: "video-profile " + (show ? "" : "hide"),
        }).appendTo("#remote_video_panel_" + id);

        window.$("<div/>", {
            id: "video_autoplay_" + id,
            class: "autoplay-fallback hide",
        }).appendTo("#remote_video_panel_" + id);
    }
}

export function removeView(id) {
    if (window.$("#remote_video_panel_" + id)[0]) {
        window.$("#remote_video_panel_" + id).remove();
    }
}