import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import PostDataWithParam from '../services/PostDataWithParam';
import MilitaryConversations from './MilitaryConversations';
import { Prompt } from 'react-router';
class Authority extends Component {
    state = {
        fields: {
            fmcsa:-1,
            mc_dot:'',
            state_license:-1,
            license_number:'',
            scac:-1,
            scac_value:''           
        },
        id:'',
        tab_id:10,
        saveStatus:false,
        proccesing:false,
        formDirty:false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        PostDataWithParam('GetMilitaryApprovalAuthorityLicensing',{})
        .then((response) => {
            if (response.success == 1) {
                this.setState({ fields:response.data, id: response.data.id });
            }
        })
    }

    ChangeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields,formDirty:true});
    }

    updateAuthority = () => {
        this.setState({ proccesing: true })
        let fields = this.state.fields;
        fields['tab_id'] = this.state.tab_id;
        fields['id'] = this.state.id;

        PostDataWithParam('SaveMilitaryApprovalAuthorityLicensing',fields)
        .then((response) => {
            if (response.success === 1) {
                window.swal('Success!', response.msg,'success')
                this.setState({ saveStatus: true,proccesing:false,formDirty:false }, () => {
                    this.props.onUpdateApprovalProgress();
                });   
            } else {
                this.setState({ proccesing: false,formDirty:false })
                window.swal('Error!', response.msg,'error')
            }
        })
    }

    openModal = (field_name,tab_id) => {
        this.props.onOpenModal(field_name,tab_id)
    }

    renderAlertMsg = (field_name,tab_id) => {
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Answer Received</p>
                        </React.Fragment>
                    )
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Waiting for reply</p>
                        </React.Fragment>
                    )
                }
            }
        }
    }
    renderClassName = (field_name,tab_id) => {
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return "btn-success "
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return "btn-warning "
                }
            }
        }


    }
    render() {
        if (this.state.saveStatus) {
            return <Redirect  to='/independent-mover/dashboard/military-approved/information-tab/evaluation'/>
        }
        return (
            <div className="tab-pane active" id="top-icon-tab10">
                <Prompt
                when={this.state.formDirty}
                message="Please save your data before you leave the page. Otherwise, you will lose the information you entered !"
                />
                <div className="panel panel-flat">
                    <div className="panel-heading">
                        <h5 className="panel-title">Operating Authority and Licensing</h5>
                    </div>
                    <div className="panel-body">
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Do you have FMCSA DOT authority for your company?</h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" name="fmcsa"  value="1" onChange={this.ChangeValue} checked={(this.state.fields.fmcsa==1)?true:false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" name="fmcsa" value="0" onChange={this.ChangeValue} checked={(this.state.fields.fmcsa==0)?true:false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('fmcsa',this.state.tab_id)} className={this.renderClassName('fmcsa',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('fmcsa',this.state.tab_id)} 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className="">If so, what is your MC or DOT #</h5>
                                    <textarea name="mc_dot" className="form-control" value={this.state.fields.mc_dot} onChange={this.ChangeValue}></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Do you have a State license to operate in your state?</h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" name="state_license" value="1" onChange={this.ChangeValue} checked={(this.state.fields.state_license==1)?true:false}  />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" name="state_license" value="0" onChange={this.ChangeValue} checked={(this.state.fields.state_license==0)?true:false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('state_license',this.state.tab_id)} className={this.renderClassName('state_license',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('state_license',this.state.tab_id)} 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className="">If so, what is your license number?</h5>
                                    <input type="text" name="license_number" className="form-control" value={this.state.fields.license_number} onChange={this.ChangeValue}/>
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Do you have a SCAC (Standard Classification Alpha Code)</h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" name="scac" value="1" onChange={this.ChangeValue} checked={(this.state.fields.scac==1)?true:false}  />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" name="scac" value="0" onChange={this.ChangeValue} checked={(this.state.fields.scac==0)?true:false}  />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('scac',this.state.tab_id)} className={this.renderClassName('scac',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('scac',this.state.tab_id)} 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className="">If so, what is it?</h5>
                                    <input type="text" name="scac_value" className="form-control" value={this.state.fields.scac_value} onChange={this.ChangeValue}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <MilitaryConversations/>   
                <div className="text-center">{this.state.proccesing? <button className="btn btn-primary" disabled={true}>Processing...</button>:<button className="btn btn-primary" onClick={this.updateAuthority}>Save &amp; Next</button>}</div>
            </div>

        );
    }
}

export default Authority;