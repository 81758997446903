import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import GetData from '../services/GetData';
import PostDataWithParam from '../services/PostDataWithParam';
import MilitaryConversations from './MilitaryConversations';
import { Prompt } from 'react-router';

class Repair extends Component {
    state = {
        fields: {
            warehouse_need_major_repairs:-1,
            major_repairs:''
               
        },
        repair_id:'',
        tab_id:6,
        saveStatus:false,
        proccesing:false,
        formDirty:false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        GetData('GetRepair')
        .then((response) => {
            if (response.success == 1) {
                this.setState({ fields:response.data[0], repair_id: response.data[0].id });
            }
        })
    }

    ChangeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields,formDirty:true  });
    }

    updateRepair = () => {
        this.setState({proccesing:true })
        let fields = this.state.fields;
        fields['tab_id'] = this.state.tab_id;
        fields['repair_id'] = this.state.repair_id;

        PostDataWithParam('SaveRepair',fields)
        .then((response) => {
            if (response.success === 1) {
                window.swal('Success!', response.msg,'success')
                this.setState({ saveStatus: true,proccesing:false,formDirty:false  }, () => {
                    this.props.onUpdateApprovalProgress();
                });   
            } else {
                this.setState({proccesing:false,formDirty:false })
                window.swal('Error!', response.msg,'error')
            }
        })
    }

    openModal = (field_name,tab_id) => {
        this.props.onOpenModal(field_name,tab_id)
    }

    renderAlertMsg = (field_name,tab_id) => {
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Answer Received</p>
                        </React.Fragment>
                    )
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Waiting for reply</p>
                        </React.Fragment>
                    )
                }
            }
        }
    }
    renderClassName = (field_name,tab_id) => {
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return "btn-success "
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return "btn-warning "
                }
            }
        }


    }
    render() {
        if (this.state.saveStatus) {
            return <Redirect  to='/independent-mover/dashboard/military-approved/information-tab/responsible'/>
        }

        return (
            <div className="tab-pane active" id="top-icon-tab6">
                <Prompt
                when={this.state.formDirty}
                message="Please save your data before you leave the page. Otherwise, you will lose the information you entered !"
                />
                <div className="panel panel-flat">
                    <div className="panel-heading">
                        <h5 className="panel-title">Repair</h5>
                    </div>
                    <div className="panel-body">
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Does the warehouse need any major repairs?</h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" name="warehouse_need_major_repairs" value="1" onChange={this.ChangeValue} checked={(this.state.fields.warehouse_need_major_repairs==1)?true:false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" name="warehouse_need_major_repairs" value="0" onChange={this.ChangeValue} checked={(this.state.fields.warehouse_need_major_repairs==0)?true:false}/>
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('warehouse_need_major_repairs',this.state.tab_id)} className={this.renderClassName('warehouse_need_major_repairs',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('warehouse_need_major_repairs',this.state.tab_id)} 
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className="">If so, please list any</h5>
                                    <textarea className="form-control" name="major_repairs" value={this.state.fields.major_repairs} onChange={this.ChangeValue}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MilitaryConversations/>                
        <div className="text-center">{this.state.proccesing? <button className="btn btn-primary" disabled={true}>Processing...</button>:<button className="btn btn-primary" onClick={this.updateRepair}>Save &amp; Next</button>}</div>
            </div>

        );
    }
}

export default Repair;