import React, { Component } from 'react'
import Breadcumb from '../externaljs/Breadcrumb';
import { Link } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import GetData from '../services/GetData';
import PostDataWithParam from '../services/PostDataWithParam';
import PostDataFile from '../services/PostDataFile';
import ConfirmBox from '../externaljs/ConfirmBox';
import { FileType, FileSvg, FileIcon } from '../externaljs/FileType';
import Storage from '../externaljs/Storage';
import { CircularProgressbar } from 'react-circular-progressbar';
import FileUploadWithProgress from "../services/FileUploadWithProgress";
const initalFields = {
    id: '',
    // Basic Info
    scac: '',
    customer_first_name: '',
    customer_last_name: '',
    ssn: '',
    ssn_val: '',
    contact_no: '',
    home_contact_no: '',
    customer_email: '',
    alternate_email: '',

    // Service Info
    customer_estimated_weight: '',
    requested_pack_date: '',
    nm_requested_pack_date: '',
    request_start_date: '',
    nm_request_start_date: '',
    requested_latest_pickup_date: '',
    nm_requested_latest_pickup_date: '',
    request_end_date: '',
    nm_request_end_date: '',
    required_delivery_date: '',
    nm_required_delivery_date: '',
    source_address: '',
    nm_source_address: '',
    city: '',
    state: '',
    zip: '',
    primary_pickup_country: 'USA',
    nm_primary_pickup_country: 'USA',
    primary_pickup_city: '',
    nm_primary_pickup_city: '',
    primary_pickup_state: '',
    nm_primary_pickup_state: '',
    primary_pickup_zip: '',
    nm_primary_pickup_zip: '',
    primary_destination_country: '',
    nm_primary_destination_country: '',
    primary_destination_city: '',
    nm_primary_destination_city: '',
    primary_destination_state: '',
    nm_primary_destination_state: '',
    primary_destination_zip: '',
    nm_primary_destination_zip: '',
    destination_address: '',
    nm_destination_address: '',
    total_manpower_needed: '',
    special_items: '',
    firearms: '',
    firearms_details: 0,
    remarks: '',
    vehicles: '',
    service_err: '',
    end_addr_err: '',

    // Additional Information Section
    service_branch: '',
    shipment_type: '',
    shipment_market: '',
    origin_gbloc: '',
    destination_gbloc: '',
    channel: '',
    releasing_agent: '',
    releasing_agent_phone: '',
    releasing_agent_email: '',
    receiving_agent: '',
    receiving_agent_phone: '',
    receiving_agent_email: '',
    code_of_service: '',
    order_number: '',
    order_date: '',
    services_needed: [],
    nm_services_needed: [],
    storage_needed: 0,
    relocation_notes: '',
    //needs to impleements in BE

    nm_order_date: '',

    // Additional Locations
    authorized_delivery: '',
    authorized_pickup: '',
    in_transit: '',
    port_of_embark: '',
    port_of_debark: '',
    tcn_number: '',

    // Driver Info (hidden until order accepted)
    driver_first_name: '',
    driver_last_name: '',
    driver_mobile_number: '',
    driver_email: '',

    source_latitude: '',
    source_longitude: '',
    destination_latitude: '',
    destination_longitude: '',
    driver_helper_mover: [],
    assign_flag: 0,
    hire_by_profile_id: 2,
    driver_data: [],
    military_flag: 1,
    uploadFileName: '',
    uploadFileDesc: '',
    uploaded_files: [],
    agreed_compensation_price: ''

};
class AssignOfferJob extends Component {

    constructor(props) {
        super(props);
        this.initAutocomplete = this.initAutocomplete.bind(this);
        this.initAutocompleteDestination = this.initAutocompleteDestination.bind(this);
        this.state = {
            breadcrumb: [
                { title: 'Dashboard', link: '/moving-company/dashboard/home', className: '' },
                { title: 'ReloOrders', link: '/moving-company/dashboard/jobs', className: '' },
                { title: 'Assign & Offer ReloOrders', link: '', className: 'active' }
            ],
            military_form_data: { ...initalFields },
            non_military_form_data: { ...initalFields },
            form_data: { ...initalFields },
            end_date_err: '',
            file_group: 1,
            files: [],
            filesModal: [],
            filesView: [],
            showFilesTab: false,
            showOthersTab: false,
            showMoverNote: false,
            viewSaveFile: false,

            buttonpressed: '',
            proccesing: false,
            subscription_id: 0,
            search_driver_fields: {
                name: '',
                email: '',
                contact_no: ''
            },
            search_loading: false,
            search_driver_list: [],
            search_customer_fields: {
                name: '',
                email: '',
                contact_no: '',
                profile_id: 11
            },
            search_customer_list: [],
            loading: false,
            chk_drivers: [],
            chk_customers: [],
            customer_data: [{
                "customer_email": "",
                "customer_first_name": "",
                "customer_last_name": "",
                "customer_mobile_number": ""
            }],
            driver_data: [{
                "driver_email": "",
                "driver_first_name": "",
                "driver_last_name": "",
                "driver_mobile_number": ""
            }],
            getModalId: 0,
            details: {
                request_start_date: '',
                request_end_date: '',
                booked: {
                    helper: [],
                    driver: [],
                    mover: []
                },
                invited: {
                    helper: [],
                    driver: [],
                    mover: []
                },
                offer_from: {
                    contact_no: "",
                    email: "",
                    organization_name: ""
                }
            },
            tmpUploadedFiles: [],
            shareFile: [],
            shareFileDetails: {},
            sharedFileWith: [],
            passWordType: true,
            iconEye: true,
            select_address: true,
            allCountries: [],
            allStates: [],
            primaryCities: [],
            UpdateErr: '',
            google_map: '',
            manual_address: '',
            // address_mode : ''
            primary_pickup_address_check: true,
            primary_destination_address_check: true,

            military_order_form: true,
            non_military_order_form: false,
            baseList: [],
            branchList: [],
            isFileSaving: false,
            // firearms_details:false,
            //set military_flag = 1 for Militray Customer and military_flag = 0 for Non-military customer 
            driver_info: '',
            existing_driver: '',
            uploadPercentage: 0,
            searchProfileId: 11
        }
    }

    componentDidMount() {
        let form_data = this.state.form_data;
        if (typeof this.props.location.state !== 'undefined') {
            form_data.driver_helper_mover = this.props.location.state.selectedProviders
        }
        this.setFormData(form_data)
        this.initDatePicker('requested_pack_date');
        this.initDatePicker('nm_requested_pack_date');
        this.initDatePicker('request_start_date');
        this.initDatePicker('nm_request_start_date');
        this.initDatePicker('requested_latest_pickup_date');
        this.initDatePicker('nm_requested_latest_pickup_date');
        this.initDatePicker('request_end_date');
        this.initDatePicker('nm_request_end_date');
        this.initDatePicker('required_delivery_date');
        this.initDatePicker('nm_required_delivery_date');
        this.initDatePicker('order_date');
        this.initDatePicker('nm_order_date');
        this.getCountries();
        this.getBaseList();
        this.getBranchList();
        this.getStates();
        this.getCities('Alaska');
        this.GetUserInfo();
        window.$('.AddDriver').click(function () {
            window.$('.block:last').before('<div className="row block"><hr/><div className="col-md-6"><div className="form-group"><label>Driver First Name</label><input className="form-control" type="text" name="driver_first_name" value=""></div></div><div className="col-md-6"><div className="form-group"><label>Driver Last Name</label><input className="form-control" type="text" name="driver_last_name" value=""></div></div><div className="col-md-6"><div className="form-group"><label>Driver Mobile Number</label><input placeholder="xxx xxx xxxx" className="form-control" type="text" name="driver_mobile_number" value=""></div></div><div className="col-md-6"><div className="form-group"><label>Driver Email address</label><input className="form-control" type="email" name="driver_email" value=""></div></div><div className="col-md-12 text-center"><a href="javascript:void(0)" className="text-danger m-t-40  show remove"><i className="icon-cross f-s-20"></i> Remove</a></div></div>');
        });
        window.$('.optionBox').on('click', '.remove', function () {
            window.$(this).parent().remove();
        });
    }

    // radioHandler = (e) =>{
    //     if(e.target.value=='manual')
    //         this.setState({address_mode:e.target.value});
    //     else
    //         this.setState({address_mode:''});
    // }

    handleManualAddress = (e) => {
        // this.setState({manual_address_check:e.target.value})

        if (e.target.name == "primary_pickup_address")
            this.setState(prevState => ({ primary_pickup_address_check: !prevState.primary_pickup_address_check }))
        else if (e.target.name == "primary_destination_address")
            this.setState(prevState => ({ primary_destination_address_check: !prevState.primary_destination_address_check }))
        else {
            this.setState(prevState => ({ primary_pickup_address_check: !prevState.primary_pickup_address_check }));
            this.setState(prevState => ({ primary_destination_address_check: !prevState.primary_destination_address_check }));
        }
    }
    getBranchList() {
        GetData('AllBranchList')
            .then((response) => {
                if (response.success == 1) {
                    // console.log('success')
                    this.setState({ branchList: response.data }, () => {
                        // console.log(this.state.custom_profile)
                    });
                }
                else {

                }

            })
            .catch(err => {
                // window.swal('Error!', 'Something went wrong', 'error');
            })
    }
    existingDriverModal = (e, modalId) => {
        e.preventDefault();
        if (modalId === 1) {
            this.searchDriver();
            window.$('#addExistingDriverModal').modal('show');
        } else {
            this.searchCustomer();
            window.$('#addExistingCustomerModal').modal('show');
        }
    }

    btnPress = (id) => {
        // console.log(id);

        if (id == 'save_military') {
            var tmp_form_data = this.state.form_data;
            tmp_form_data["military_flag"] = 1;
            // console.log(tmp_form_data);
            this.setState({ form_data: tmp_form_data });
        }
        else {
            var tmp_form_data = this.state.form_data;
            tmp_form_data["military_flag"] = 0;
            // console.log(tmp_form_data);
            this.setState({ form_data: tmp_form_data });
        }

        // console.log(this.state.form_data.military_flag);

        this.setState({ buttonpressed: id });
    }

    // getMinDate = (name) => {
    //     const { requested_pack_date, nm_requested_pack_date } = this.state.form_data;
    //     let field = {
    //         request_start_date: requested_pack_date,
    //         nm_request_start_date: nm_requested_pack_date
    //     }
    //     return !!field[name] ? field[name] : undefined;
    // }
    // initDatePicker = (name) => {
    //     window.$('#' + name).daterangepicker({
    //         startDate: new Date(),
    //         showDropdowns: true,
    //         autoclose: true,
    //         singleDatePicker: true,
    //         minDate: this.getMinDate(name)
    //     }).on('apply.daterangepicker', (ev, picker) => {
    //         let form_data = { ...this.state.form_data };
    //         let formatDate = picker.startDate.format('MM/DD/YYYY');
    //         let initDateName = null;
    //         form_data[name] = formatDate;
    //         if (name === "requested_pack_date") {
    //             form_data.request_start_date = formatDate;
    //             initDateName = 'request_start_date';
    //         } if (name === "nm_requested_pack_date") {
    //             form_data.nm_request_start_date = formatDate;
    //             initDateName = 'nm_request_start_date';
    //         }
    //         this.setState({ form_data: form_data }, () => {
    //             if (initDateName) this.initDatePicker(initDateName)
    //         });

    //     });
    // }

    getMinDate = (name) => {
        const { requested_pack_date, request_start_date } = this.state.form_data;
        let field = {
            request_start_date: requested_pack_date,
            request_end_date: window.moment(request_start_date, 'MM/DD/YYYY').add(1, 'days').format('MM/DD/YYYY')
        }
        return !!field[name] ? field[name] : undefined;
    }

    initDatePicker = (name) => {
        window.$('#' + name).daterangepicker({
            startDate: new Date(),
            showDropdowns: true,
            autoclose: true,
            singleDatePicker: true,
            minDate: this.getMinDate(name)
        }).on('apply.daterangepicker', (ev, picker) => {
            let form_data = { ...this.state.form_data };
            let formatDate = picker.startDate.format('MM/DD/YYYY');
            let initDateName = null;
            form_data[name] = formatDate;
            if (name === "requested_pack_date") {
                form_data.request_start_date = formatDate;
                form_data.request_end_date = window.moment(formatDate, 'MM/DD/YYYY').add(1, 'days').format('MM/DD/YYYY');
            }
            else if (name === "nm_requested_pack_date") {
                form_data.nm_request_start_date = formatDate;
                form_data.nm_request_end_date = window.moment(formatDate, 'MM/DD/YYYY').add(1, 'days').format('MM/DD/YYYY');
            }
            else if (name === "request_start_date") {
                form_data.request_end_date = formatDate;
            }
            else if (name === "nm_request_start_date") {
                form_data.nm_request_end_date = formatDate;
            }
            this.setState({ form_data: form_data }, () => {
                if (name === "requested_pack_date") {
                    this.initDatePicker('request_start_date');
                    this.initDatePicker('request_end_date')
                }
                else if (name === "nm_requested_pack_date") {
                    this.initDatePicker('nm_request_start_date');
                    this.initDatePicker('nm_request_end_date')
                }
                else if (name === "request_start_date") {
                    this.initDatePicker('request_end_date');
                }
                else {
                    this.initDatePicker('nm_request_end_date');
                }
            });

        });
    }
    getBaseList() {
        GetData('AllBaseList')
            .then((response) => {
                if (response.success == 1) {
                    // console.log('success')
                    this.setState({ baseList: response.data }, () => {
                        // console.log(this.state.custom_profile)
                    });
                }
                else {

                }

            })
            .catch(err => {
                // window.swal('Error!', 'Something went wrong', 'error');
            })
    }
    // selectDatePicker = (name) => {
    //     if (name == 'request_start_date') {
    //         window.$('#' + name).daterangepicker({
    //             startDate: new Date(),
    //             showDropdowns: true,
    //             autoclose: true,
    //             singleDatePicker: true,
    //             minDate: this.state.form_data.requested_pack_date
    //         }).on('apply.daterangepicker', (ev, picker) => {
    //             let form_data = this.state.form_data;
    //             form_data[name] = picker.startDate.format('MM/DD/YYYY');
    //             this.setFormData(form_data)
    //         });
    //     }

    //     if (name == 'nm_request_start_date') {
    //         window.$('#' + name).daterangepicker({
    //             startDate: new Date(),
    //             showDropdowns: true,
    //             autoclose: true,
    //             singleDatePicker: true,
    //             minDate: this.state.form_data.nm_requested_pack_date
    //         }).on('apply.daterangepicker', (ev, picker) => {
    //             let form_data = this.state.form_data;
    //             // name='request_start_date';
    //             form_data[name] = picker.startDate.format('MM/DD/YYYY');
    //             this.setFormData(form_data)
    //         });
    //     }

    //     if (name == 'requested_latest_pickup_date') {
    //         window.$('#' + name).daterangepicker({
    //             startDate: new Date(),
    //             showDropdowns: true,
    //             autoclose: true,
    //             singleDatePicker: true,
    //             minDate: this.state.form_data.request_start_date
    //         }).on('apply.daterangepicker', (ev, picker) => {
    //             let form_data = this.state.form_data;
    //             form_data[name] = picker.startDate.format('MM/DD/YYYY');
    //             this.setFormData(form_data)
    //         });
    //     }

    //     if (name == 'nm_requested_latest_pickup_date') {
    //         window.$('#' + name).daterangepicker({
    //             startDate: new Date(),
    //             showDropdowns: true,
    //             autoclose: true,
    //             singleDatePicker: true,
    //             minDate: this.state.form_data.nm_request_start_date
    //         }).on('apply.daterangepicker', (ev, picker) => {
    //             let form_data = this.state.form_data;
    //             form_data[name] = picker.startDate.format('MM/DD/YYYY');
    //             this.setFormData(form_data)
    //         });
    //     }

    // }

    GetUserInfo = () => {
        GetData('GetUserInfo', {})
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ subscription_id: response.data.subscription_id });
                }
            })
            .catch(err => { })
    }

    getCountries() {
        GetData('CountryList', {})
            .then((response) => {
                let result = response;
                if (result.success === 1) {
                    this.setState({ allCountries: result.data });
                } else {
                    this.setState({ UpdateErr: result.msg });
                }

            })
            .catch(err => {

            })
    }

    getStates() {
        PostDataWithParam('GetStates', {})
            .then((response) => {
                let result = response;
                if (result.success === 1) {
                    this.setState({ allStates: result.data });
                } else {
                    this.setState({ UpdateErr: result.msg });
                }
            })
            .catch(err => {

            })
    }

    getCities(state = 'Alaska', flag = '') {
        var state_code = 'AK';
        for (var st of this.state.allStates) {
            if (st.state.trim() == state.trim()) {
                state_code = st.state_code;
                break;
            }
        }

        PostDataWithParam('GetCities', { state_code: state_code })
            .then((response) => {
                let result = response;
                if (result.success === 1) {

                    this.setState({ primaryCities: result.data }, () => {

                        this.setState({ city: result.data[0].city });
                    });
                } else {
                    this.setState({ UpdateErr: result.msg });
                }
            })
            .catch(err => {

            })
    }

    changeValue = (e) => {
        if (e.target.name == 'state') {
            this.getCities(e.target.value);
        }
        let form_data = this.state.form_data;
        form_data[e.target.name] = e.target.value;
        if (e.target.name == 'home_contact_no' || e.target.name == 'contact_no') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                form_data[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
        }
        this.setFormData(form_data)


    }

    handleOnChange(e) {
        // debugger;
        let form_data = Object.assign({}, this.state.form_data);
        form_data[e.target.name] = e.target.value;
        if (e.target.name == 'contact_no'
            || e.target.name == 'home_contact_no'
            || e.target.name == 'driver_mobile_number'
            || e.target.name == 'releasing_agent_phone'
            || e.target.name == 'receiving_agent_phone'
            || e.target.name == 'code_of_service') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                form_data[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
        }
        if (e.target.name == 'ssn') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/)
            if (match) {
                form_data['ssn_val'] = match[1] + ' ' + match[2] + ' ' + match[3]
                form_data[e.target.name] = 'XXX' + '-' + 'XX' + '-' + match[3]
                // form_data[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
        }
        if (e.target.name == 'scac' || e.target.name == 'tcn_number') {
            let cleaned = ('' + e.target.value).replace(/\d/g, '')
            form_data[e.target.name] = cleaned;
        }
        if (e.target.name == 'tcn_number') {
            let cleaned = ('' + e.target.value).replace(/\D/g, '')
            form_data[e.target.name] = cleaned;
        }
        this.setFormData(form_data)


    }

    handleEditorChange = (e) => {
        let form_data = Object.assign({}, this.state.form_data);
        form_data.remarks = e.target.getContent();
        this.setState({ form_data: form_data });
    }
    
    setFormData = (form_data) => {
        if (this.state.military_order_form) { this.setState({ military_form_data: form_data, form_data }); }
        if (this.state.non_military_order_form) { this.setState({ non_military_form_data: form_data, form_data }); }
    }
    togglePassword() {
        let form_data = Object.assign({}, this.state.form_data);
        if (this.state.passWordType == true) {
            this.setState({ passWordType: false });
            this.setState({ iconEye: false });
            let swap = form_data['ssn'] // masked value
            form_data['ssn'] = form_data['ssn_val']
            form_data['ssn_val'] = swap  // masked value    
        } else {
            this.setState({ passWordType: true });
            this.setState({ iconEye: true });
            let swapssn = form_data['ssn']
            form_data['ssn'] = form_data['ssn_val']  // masked value          
            form_data['ssn_val'] = swapssn
        }
        this.setFormData(form_data)
    }

    handleAddressOptionChange(e) {
        let autocomplete_field;
        let autocomplete_field_destination;
        if (e.target.value == "google") {
            // console.log(this.state.form_data.source_address);
            window.$(this.autocomplete_field).val(this.state.form_data.source_address);
            window.$("#source_address").replaceWith(this.autocomplete_field);
            //for destination
            window.$(this.autocomplete_field_destination).val(this.state.form_data.destination_address);
            window.$("#destination_address").replaceWith(this.autocomplete_field_destination);
            this.setState({
                select_address: true
            });
        } else {
            this.autocomplete_field = window.$("#source_address");
            window.$("#source_address").replaceWith(window.$("#source_address").clone());
            window.$("#source_address").on("change", this.initAutocomplete);
            //for destination
            this.autocomplete_field_destination = window.$("#destination_address");
            window.$("#destination_address").replaceWith(window.$("#destination_address").clone());
            window.$("#destination_address").on("change", this.initAutocompleteDestination);
            this.setState({
                select_address: false
            });
        }
    }
    initAutocomplete(e) {
        //    // debugger;
        // if(this.state.primary_pickup_address_check==true){
        //     return;
        // }
        let form_data = Object.assign({}, this.state.form_data);
        let addressName = e.target.name;
        if (e.target.name == "source_address")
            form_data.source_address = e.target.value;
        else if (e.target.name == "nm_source_address")
            form_data.nm_source_address = e.target.value;
        else if (e.target.name == "nm_destination_address")
            form_data.nm_destination_address = e.target.value;
        else
            form_data.destination_address = e.target.value;
        this.setFormData(form_data)
        //console.log(this.state.form_data.source_address);
        if (this.state.select_address) {

            if (addressName == "source_address") {
                form_data.primary_pickup_city = '';
                form_data.primary_pickup_state = '';
                form_data.primary_pickup_zip = '';
                form_data.primary_pickup_country = '';
            }
            else if (addressName == "nm_source_address") {
                form_data.nm_primary_pickup_city = '';
                form_data.nm_primary_pickup_state = '';
                form_data.nm_primary_pickup_zip = '';
                form_data.nm_primary_pickup_country = '';
            }
            else if (e.target.name == "nm_destination_address") {
                form_data.nm_primary_destination_city = '';
                form_data.nm_primary_destination_state = '';
                form_data.nm_primary_destination_zip = '';
                form_data.nm_primary_destination_country = '';
            }
            else {
                form_data.primary_destination_city = '';
                form_data.primary_destination_state = '';
                form_data.primary_destination_zip = '';
                form_data.primary_destination_country = '';
            }

            let autocomplete = new window.google.maps.places.Autocomplete(
                /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
                { types: [] });

            window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
                let place = autocomplete.getPlace();
                var componentForm = {
                    country: 'long_name',
                    locality: 'long_name',
                    administrative_area_level_1: 'long_name',
                    postal_code: 'short_name'
                };
                let form_data = this.state.form_data;
                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0];
                    if (componentForm[addressType]) {
                        var val = place.address_components[i][componentForm[addressType]];
                        if (addressType == "country") {
                            if (addressName == "source_address")
                                form_data.primary_pickup_country = val
                            else if (addressName == "destination_address")
                                form_data.primary_destination_country = val
                            else if (addressName == "nm_source_address")
                                form_data.nm_primary_pickup_country = val
                            else
                                form_data.nm_primary_destination_country = val

                        }

                        else if (addressType == "administrative_area_level_1") {
                            if (addressName == "source_address")
                                form_data.primary_pickup_state = val
                            else if (addressName == "nm_source_address")
                                form_data.nm_primary_pickup_state = val
                            else if (addressName == "nm_destination_address")
                                form_data.nm_primary_destination_state = val
                            else
                                form_data.primary_destination_state = val
                        }
                        else if (addressType == "locality") {
                            if (addressName == "source_address")
                                form_data.primary_pickup_city = val
                            else if (addressName == "nm_source_address")
                                form_data.nm_primary_pickup_city = val
                            else if (addressName == "nm_destination_address")
                                form_data.nm_primary_destination_city = val
                            else
                                form_data.primary_destination_city = val
                        }
                        // form_data.city = val
                        else if (addressType == "postal_code") {
                            if (addressName == "source_address")
                                form_data.primary_pickup_zip = val
                            else if (addressName == "nm_source_address")
                                form_data.nm_primary_pickup_zip = val
                            else if (addressName == "nm_destination_address")
                                form_data.nm_primary_destination_zip = val
                            else
                                form_data.primary_destination_zip = val
                        }
                        // form_data.zip = val
                    }
                }
                form_data.source_latitude = place.geometry.location.lat();
                form_data.source_longitude = place.geometry.location.lng();
                let address = '';
                let flag = 0;
                if (addressName == "source_address")
                // form_data.source_address = window.$('#source_address')[0].value;
                {
                    for (let i = 0; i < place.address_components.length; i++) {
                        if (place.address_components[i].types[0] == "street_number" || place.address_components[i].types[0] == "route" || place.address_components[i].types[0] == "sublocality_level_1" || place.address_components[i].types[0] == "sublocality_level_2" || place.address_components[i].types[0] == "sublocality_level_3") {
                            address = address + " " + place.address_components[i].long_name;
                            flag = 1;
                        }
                        if (flag != 1) {

                            if (place.address_components[i].types[0] == "locality") {

                                address = address + " " + place.address_components[i].long_name;
                                flag = 0;
                            }
                        }

                    }
                    form_data.source_address = address;
                }
                else if (addressName == "nm_source_address")
                // form_data.nm_source_address = window.$('#nm_source_address')[0].value;
                {
                    for (let i = 0; i < place.address_components.length; i++) {
                        if (place.address_components[i].types[0] == "street_number" || place.address_components[i].types[0] == "route" || place.address_components[i].types[0] == "sublocality_level_1" || place.address_components[i].types[0] == "sublocality_level_2" || place.address_components[i].types[0] == "sublocality_level_3") {
                            address = address + " " + place.address_components[i].long_name;
                            flag = 1;
                        }
                        if (flag != 1) {

                            if (place.address_components[i].types[0] == "locality") {

                                address = address + " " + place.address_components[i].long_name;
                                flag = 0;
                            }
                        }

                    }
                    form_data.nm_source_address = address;
                }
                else if (addressName == "nm_destination_address")
                // form_data.nm_destination_address = window.$('#nm_destination_address')[0].value;
                {
                    for (let i = 0; i < place.address_components.length; i++) {
                        if (place.address_components[i].types[0] == "street_number" || place.address_components[i].types[0] == "route" || place.address_components[i].types[0] == "sublocality_level_1" || place.address_components[i].types[0] == "sublocality_level_2" || place.address_components[i].types[0] == "sublocality_level_3") {
                            address = address + " " + place.address_components[i].long_name;
                            flag = 1;
                        }
                        if (flag != 1) {

                            if (place.address_components[i].types[0] == "locality") {

                                address = address + " " + place.address_components[i].long_name;
                                flag = 0;
                            }
                        }

                    }
                    form_data.nm_destination_address = address;
                }
                else
                // form_data.destination_address = window.$('#destination_address')[0].value;
                {
                    for (let i = 0; i < place.address_components.length; i++) {
                        if (place.address_components[i].types[0] == "street_number" || place.address_components[i].types[0] == "route" || place.address_components[i].types[0] == "sublocality_level_1" || place.address_components[i].types[0] == "sublocality_level_2" || place.address_components[i].types[0] == "sublocality_level_3") {
                            address = address + " " + place.address_components[i].long_name;
                            flag = 1;
                        }
                        if (flag != 1) {

                            if (place.address_components[i].types[0] == "locality") {

                                address = address + " " + place.address_components[i].long_name;
                                flag = 0;
                            }
                        }

                    }
                    form_data.destination_address = address;
                }
                // form_data.source_address = window.$('#source_address')[0].value;   
                this.setState({ form_data: form_data }, () => {
                    // console.log("form_data = ", form_data);
                });
            })
        }
        else {
            form_data.source_latitude = '';
            form_data.source_longitude = '';
            if (addressName == "source_address")
                form_data.source_address = e.target.value;
            else if (addressName == "nm_source_address")
                form_data.nm_source_address = e.target.value;
            else if (addressName == "nm_destination_address")
                form_data.nm_destination_address = e.target.value;
            else
                form_data.destination_address = e.target.value;
            this.setState({ form_data: form_data }, () => {
                // console.log("form_data = ", form_data);
            });

        }

    }

    initAutocompleteDestination(e) {
        // debugger;
        let form_data = this.state.form_data;
        form_data.destination_address = e.target.value;
        this.setFormData(form_data)
        if (this.state.select_address) {
            let autocomplete = new window.google.maps.places.Autocomplete(
                /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
                { types: [] });

            window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
                let place = autocomplete.getPlace();
                let form_data = this.state.form_data;
                form_data.destination_latitude = place.geometry.location.lat();
                form_data.destination_longitude = place.geometry.location.lng();
                form_data.destination_address = window.$('#destination_address')[0].value;
                this.setFormData(form_data)
            })
        } else {
            form_data.source_latitude = '';
            form_data.source_longitude = '';
            form_data.destination_address = e.target.value;
            this.setFormData(form_data)
        }
    }
    authorizedpicinitAutocomplete(e) {
        //    // debugger;
        let form_data = Object.assign({}, this.state.form_data);
        form_data.authorized_pickup = e.target.value;
        this.setFormData(form_data)
        let autocomplete = new window.google.maps.places.Autocomplete(
                        /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: [] });
        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let form_data = this.state.form_data;
            form_data.source_latitude = place.geometry.location.lat();
            form_data.source_longitude = place.geometry.location.lng();
            form_data.authorized_pickup = window.$('#authorized_pickup')[0].value;
            this.setFormData(form_data)
        })
    }
    transitinitAutocomplete(e) {
        //    // debugger;
        let form_data = Object.assign({}, this.state.form_data);
        form_data.in_transit = e.target.value;
        this.setFormData(form_data)
        let autocomplete = new window.google.maps.places.Autocomplete(
                        /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: [] });
        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let form_data = this.state.form_data;
            form_data.source_latitude = place.geometry.location.lat();
            form_data.source_longitude = place.geometry.location.lng();
            form_data.in_transit = window.$('#in_transit')[0].value;
            this.setFormData(form_data)
        })
    }
    authorizeddeliverypicinitAutocomplete(e) {
        //    // debugger;
        let form_data = Object.assign({}, this.state.form_data);
        form_data.authorized_delivery = e.target.value;
        this.setFormData(form_data)
        let autocomplete = new window.google.maps.places.Autocomplete(
                        /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: [] });
        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let form_data = this.state.form_data;
            form_data.source_latitude = place.geometry.location.lat();
            form_data.source_longitude = place.geometry.location.lng();
            form_data.authorized_delivery = window.$('#authorized_delivery')[0].value;
            this.setFormData(form_data)
        })
    }

    setServices(e) {
        let form_data = this.state.form_data;
        if (form_data.services_needed.indexOf(e.target.value) > -1) {
            let services_needed = form_data.services_needed.filter((item, i) => {
                if (item == e.target.value)
                    return false;
                else
                    return true;
            });
            form_data.services_needed = services_needed;
        } else {
            form_data.services_needed.push(e.target.value)
        }

        this.setState({ form_data: form_data }, () => {

        });
    }

    setFileGroup(file_group, e) {
        e.preventDefault();
        this.setState({ file_group: file_group }, () => {
            this.getFiles();
        });
    }

    getFiles() {
        PostDataWithParam("GetOfferFiles", {
            order_request_detail_id: this.state.form_data.id,
            file_group: this.state.file_group,
        })
            .then((response) => {
                if (response.success == 1) {
                    this.setState({ files: response.data });
                } else {
                    this.setState({ files: [] });
                }
            })
            .catch((err) => {
                this.setState({ files: [] });
            });
    }

    getFilesModal() {
        PostDataWithParam("GetOfferFiles", {
            order_request_detail_id: this.state.form_data.id,
            file_group: this.state.file_group,
            is_file_save: 1     //to identify whether the file should be saved or not
        })
            .then((response) => {
                if (response.success == 1) {
                    // console.log(response.data.length)
                    if (response.data.length != 0)
                        this.setState({ filesView: response.data });
                    else
                        this.setState({ filesView: response.data });
                } else {
                    this.setState({ filesView: [] });
                }
            })
            .catch((err) => {
                this.setState({ filesView: [] });
            });
    }


    fileUpload = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ uploaded_files: e.target.files })
        // console.log('uploadFileName', this.state.form_data.uploadFileName)
        if (this.state.tmpUploadedFiles.length > 0) {
            let formData = new FormData();
            for (let i = 0; i < this.state.tmpUploadedFiles.length; i++) {
                formData.append('request_files[' + i + ']', this.state.tmpUploadedFiles[i], this.state.tmpUploadedFiles[i].name);
            }
            formData.append('hire_by_profile_id', 2);
            formData.append('file_group', this.state.file_group);
            formData.append('order_request_detail_id', this.state.form_data.id);
            formData.append('file_description', this.state.form_data.uploadFileDesc);
            formData.append('file_name', this.state.form_data.uploadFileName);

            this.setState({ isFileSaving: true }, () => {
                PostDataFile('UploadOfferFiles', formData)
                    .then(response => {
                        if (response.success == 1) {
                            this.setState({ viewSaveFile: true, showFilesTab: true, isFileSaving: false });
                            window.$('#FileUpload-Modal').modal('hide');
                            window.$('#FileUpload-Modal').trigger('reset');
                            window.$('#FileUploadNonMily-Modal').modal('hide');
                            window.$('#FileUploadNonMily-Modal').trigger('reset');
                            window.swal('Success!', 'Saved Successfully', 'success');
                            let form_data = this.state.form_data;
                            form_data.id = response.data.order_request_detail_id;
                            this.getFiles();
                        } else {
                            window.swal('Error!', 'Something went wrong.', 'error');
                        }
                    })
                    .catch(err => {
                        // console.log('err', err)
                        this.setState({ isFileSaving: false });
                        window.swal('Error!', 'Something went wrong.', 'error')
                    });
            })

        }
    }


    uploadFiles(e) {
        e.persist()
        this.setState({ filesModal: e.target.files })
        if (e.target.files.length > 0) {
            let formData = new FormData();
            for (let i = 0; i < e.target.files.length; i++) {
                formData.append('request_files[' + i + ']', e.target.files[i], e.target.files[i].name);
            }
            formData.append('hire_by_profile_id', 2);
            formData.append('file_group', this.state.file_group);
            formData.append('order_request_detail_id', this.state.form_data.id);
            formData.append('file_description', '');
            formData.append('file_name', '');

            this.setState({ isProccesingFileUpload: true }, () => {
                FileUploadWithProgress('UploadOfferFiles', formData, (e) => {
                    var percent = Math.round((e.loaded / e.total) * 100);
                    this.setState({ uploadPercentage: percent })
                })
                    .then(response => {
                        if (response.success == 1) {
                            this.setState({ showFilesTab: true, isProccesingFileUpload: false, uploadPercentage: 0, tmpUploadedFiles:[...this.state.tmpUploadedFiles,e.target.files[0]] });
                            // window.swal('Success!', 'File Saved Successfully', 'success');
                            let form_data = this.state.form_data;
                            form_data.id = response.data.order_request_detail_id;
                            // this.getFiles();
                            this.getFilesModal();
                        } else {
                            this.setState({ isProccesingFileUpload: false });
                            window.swal('Error!', 'Something went wrong.', 'error');
                        }
                    })
                    .catch(err => {
                        // console.log('err', err)
                        this.setState({ isProccesingFileUpload: false });
                        window.swal('Error!', 'Something went wrong.', 'error')
                    });
            })

        }

    }




    getFileName(file) {
        let files = file.request_file.split("/");
        return files[files.length - 1];
    }

    deleteFile(id, e) {
        e.preventDefault();
        ConfirmBox("You want to delete this file!").then((result) => {
            if (result) {
                PostDataWithParam("DeleteOfferFiles", { id: id })
                    .then((response) => {
                        if (response.success == 1) {
                            window.swal("Deleted!", response.msg, "success");
                            this.getFiles();
                        }
                    })
                    .catch((err) => { });
            }
        });
    }

    deleteFileModal(id, e) {
        e.preventDefault();
        ConfirmBox("You want to delete this file!").then((result) => {
            if (result) {
                PostDataWithParam("DeleteOfferFiles", { id: id })
                    .then((response) => {
                        if (response.success == 1) {
                            window.swal("Deleted!", response.msg, "success");
                            this.getFilesModal();
                        }
                    })
                    .catch((err) => { });
            }
        });
    }

    getFileId = (e, file_id) => {
        e.preventDefault();
        let files = []
        this.state.sharedFileWith.map((item, i) => {
            if (item.document_id == file_id) {
                if (item.mover_flag == 1) {
                    files.push("mover")
                }
                if (item.helper_flag == 1) {
                    files.push("helper")
                }
                if (item.driver_flag == 1) {
                    files.push("driver")
                }
                if (item.customer_flag == 1) {
                    files.push("customer")
                }
            }
        })

        this.setState({ document_id: file_id, shareFile: files }, () => {
        });
    }

    shareWith = (e) => {
        e.preventDefault();
        let shareFile = this.state.shareFile;

        if (shareFile.indexOf(e.target.value) > -1) {
            let temp = shareFile.filter((item, i) => {
                if (item == e.target.value && e.target.checked == false)
                    return false;
                else
                    return true;
            });
            shareFile = temp;
        } else {
            shareFile.push(e.target.value);
        }
        this.setState({ shareFile: shareFile }, () => {
        });
    }
    submitShareWith = (e) => {
        e.preventDefault();
        this.state.mover_flag = 0;
        this.state.helper_flag = 0;
        this.state.driver_flag = 0;
        this.state.shareFile.filter(item => {
            if (item == 'mover') {
                this.state.mover_flag = 1;
            } else if (item == 'helper') {
                this.state.helper_flag = 1;
            }
            else if (item == 'driver') {
                this.state.driver_flag = 1;
            }
        })
        let user_id = (JSON.parse(Storage.get('customerLoginData'))).id;
        PostDataWithParam("ShareFile",
            {
                order_id: this.state.form_data.id,
                user_id: user_id,
                profile_id: 2,
                mover_flag: this.state.mover_flag,
                driver_flag: this.state.driver_flag,
                helper_flag: this.state.helper_flag,
                customer_flag: this.state.customer_flag,
                document_id: this.state.document_id
            }).then(response => {
                if (response.success === 1) {
                    this.getShareFileStatus(this.state.form_data.id);
                    window.swal('Share', response.msg, 'success');
                } else {
                    window.swal("Error", response.msg, 'Error')
                }
            }).catch(err => {
                window.swal("Error", err.msg, 'Error')
            })

    }

    getShareFileDetails = () => {
        PostDataWithParam("ShareFileDetails", { order_id: this.state.form_data.id, profile_id: 2 })
            .then(response => {
                if (response.success === 1) {
                    this.setState({ shareFileDetails: response.data }, () => {
                    })
                } else {
                    this.setState({ shareFileDetails: [] });
                }
            }).catch(err => {
                this.setState({ shareFileDetails: [] });
            })
    }
    getShareFileStatus = (orderId) => {
        PostDataWithParam("ShareFileStatus", { order_id: orderId })
            .then(response => {
                if (response.success === 1) {
                    this.setState({ sharedFileWith: response.data }, () => {
                    })
                } else {
                    this.setState({ sharedFileWith: [] });
                }
            }).catch(err => {
                this.setState({ sharedFileWith: [] });
            })
    }

    renderFiles() {
        if (this.state.files.length > 0) {
            // console.log(this.state.files)
            let files = this.state.files.map((file, i) => {
                // console.log(file)
                return (
                    <li key={"item-" + file.id}>
                        <div className={"file-wrapper " + FileType(file.file_type)}>

                            {FileSvg(file.file_type, file.request_file)}
                            <div className="file-title">
                                <a href={file.request_file}>
                                    <i
                                        className={
                                            FileIcon(file.file_type) + " position-left text-primary"
                                        }
                                    ></i>{" "}
                                    {this.getFileName(file)}
                                </a>
                            </div>
                            <hr className="m-0" />
                            <div className="uploadfile-icons text-center">
                                {/* <a href="" className="text-info" data-toggle="modal" data-target="#sharefile" onClick={(e) => this.getFileId(e, file.id)}><i className="icon-share3 f-s-18"></i> <span className="show">Share</span></a> */}
                                <a href="" className="text-success" data-toggle="modal" data-target={"#showfile" + file.id}><i className="icon-eye4 f-s-18"></i> <span className="show">View</span></a>
                                <a href="" className="text-danger" onClick={this.deleteFile.bind(this, file.id)}><i className="icon-cancel-circle2 f-s-18"></i> <span className="show">Delete</span></a>
                            </div>
                        </div>
                        <div id="sharefile" className="modal fade" role="dialog">
                            <div className="modal-dialog modal-xs">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        <h4 className="modal-title text-grey">Share With</h4>
                                    </div>
                                    <div className="modal-body text-grey">
                                        <div className="form-group m-b-10">
                                            <label className="checkbox-inline custom-checkbox check-success">
                                                <input type="checkbox" name="driver" value="driver" checked={this.state.shareFile.indexOf('driver') > -1} onChange={this.shareWith} /><span></span> Driver</label>
                                        </div>
                                        <div className="form-group m-b-10">
                                            <label className="checkbox-inline custom-checkbox check-success">
                                                <input type="checkbox" name="helper" value="helper" checked={this.state.shareFile.indexOf('helper') > -1} onChange={this.shareWith} /><span></span> Helper</label>
                                        </div>
                                        <div className="form-group m-b-10">
                                            <label className="checkbox-inline custom-checkbox check-success">
                                                <input type="checkbox" name="mover" value="mover" checked={this.state.shareFile.indexOf('mover') > -1} onChange={this.shareWith} /><span></span> Moving Company</label>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.submitShareWith}>Done</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* showfile Modal start */}



                        <div id={"showfile" + file.id} className="modal fade" role="dialog">
                            <div className="modal-dialog modal-sm">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        {FileType(file.file_type) == "filetype-image" &&
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        }
                                        {FileType(file.file_type) == "filetype-video" &&
                                            <button type="button" className="close" data-dismiss="modal" onClick={(e) => this.closeModal("audio-video" + i)}>&times;</button>
                                        }
                                        {/* <button type="button" className="close" data-dismiss="modal" onClick={(e) => this.closeModal("audio-video" + i)}>&times;</button> */}
                                        <h4 className="modal-title" style={{ color: "black" }}>File Details</h4>
                                    </div>
                                    <hr className="m-t-15" />
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="show-file-picture">
                                                    <div className={FileType(file.file_type)}>
                                                        {/* {FileSvg(file.file_type, file.request_file)} */}
                                                        {FileType(file.file_type) == "filetype-image" &&
                                                            <img src={file.request_file} />}

                                                        {FileType(file.file_type) == "filetype-video" &&
                                                            <video width="100%" height="100%" controls id={"audio-video" + i} >
                                                                <source src={file.request_file} type="video/mp4" />
                                                            </video>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="file-title m-t-15">
                                                    <h6 style={{ color: "black" }}><strong>File Name</strong></h6>
                                                    {/* <p style={{ color: "black" }}>{this.getFileName(file.file_name)}</p> */}
                                                    <pre style={{ color: "black" }}>{this.getFileName(file)}</pre>
                                                    <h6 style={{ color: "black" }}><strong>File Description</strong></h6>
                                                    <pre style={{ color: "black" }}>{file.file_description}</pre>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {/* <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e) => this.closeModal("audio-video" + i)}>Close</button> */}
                                        {FileType(file.file_type) == "filetype-image" &&
                                            <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                                        }
                                        {FileType(file.file_type) == "filetype-video" &&
                                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e) => this.closeModal("audio-video" + i)}>Close</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* showfile Modal end */}
                    </li>

                );
            });

            return files;
        } else {
            // return <h6 className="text-grey-600">No files were found.</h6>;
        }
    }

    closeModal(id) {
        window.$('#' + id)[0].pause();
        // window.$('#audio-video0')[0].pause()
    }

    // openFileModal = (e) => {
    //     this.setState({ uploadFileName: '', uploadFileDesc: '', filesView: [] })
    //     window.$('#FileUpload-Modal').modal('show');
    // }
    openFileModal = (e) => {
        let form_data = { ...this.state.form_data, uploadFileName: '', uploadFileDesc: '' }

        this.setState({ form_data, filesView: [], tmpUploadedFiles:[] }, () => {

            window.$('#FileUpload-Modal').modal('show')
        })

    }
    closeFile = (e) => {
        let form_data = { ...this.state.form_data, uploadFileName: '', uploadFileDesc: '' }

        this.setState({ form_data, filesView: [] }, () => {

            window.$('#FileUpload-Modal').modal('hide');
        })

    }

    openNonMilitaryFileModal = (e) => {
        let form_data = { ...this.state.form_data, uploadFileName: '', uploadFileDesc: '' }

        this.setState({ form_data, filesView: [] }, () => {

            window.$('#FileUploadNonMily-Modal').modal('show')
        })
    }

    closeNonMiliFile = (e) => {
        let form_data = { ...this.state.form_data, uploadFileName: '', uploadFileDesc: '' }

        this.setState({ form_data, filesView: [] }, () => {

            window.$('#FileUploadNonMily-Modal').modal('hide');
        })

    }

    renderFilesModal() {
        if (this.state.filesView.length > 0) {
            let files = this.state.filesView.map((file, i) => {
                return (
                    <li key={"item-" + i}>
                        <div className={"file-wrapper " + FileType(file.file_type)}>
                            <div className="overlay-file">

                                <a
                                    href=""
                                    className="text-danger"
                                    onClick={this.deleteFileModal.bind(this, file.id)}
                                >
                                    <i className="icon-cancel-circle2"></i>
                                    <span className="show">Delete</span>
                                </a>

                            </div>
                            {FileSvg(file.file_type, file.request_file)}
                            <div className="file-title">
                                <a href={file.request_file}>
                                    <i
                                        className={
                                            FileIcon(file.file_type) + " position-left text-primary"
                                        }
                                    ></i>{" "}
                                    {this.getFileName(file)}
                                </a>
                            </div>
                        </div>

                    </li>

                );
            });

            return files;
        } else {
            // return <h6 className="text-grey-600">No files were found.</h6>;
        }

    }


    postOffer(flag, e) {
        e.preventDefault();
        // let form_data= {...this.state.form_data}
        // form_data.military_flag=1
        // this.setState({form_data})
        // console.log('form ', this.state.form_data);
        // return;
        if (flag == 0) {

            if ((this.state.form_data.request_start_date && this.state.form_data.request_end_date)) {

                if ((Date.parse(this.state.form_data.request_end_date) <= Date.parse(this.state.form_data.request_start_date))) {
                    this.setState({ end_date_err: 'End date should be greater than start date.' });
                }

                else if ((this.state.form_data.source_address == "" && this.state.form_data.destination_address == "")) {
                    this.setState({ end_addr_err: 'Please enter any of the addresses' });
                }
                else if (this.state.form_data.services_needed.length <= 0) {

                    this.setState({ service_err: 'Please select at least one service.' }, () => {
                        //console.log(this.state.service_err)
                    });

                }

                else {

                    if (this.state.form_data.ssn.charAt(0) == 'X') {
                        let swap = this.state.form_data.ssn_val;
                        this.state.form_data.ssn = swap
                    }
                    this.state.form_data.ssn_val = '';
                    this.setState({ end_date_err: '' });
                    this.setState({ end_addr_err: '' });
                    this.setState({ service_err: '' });
                    let form_data = this.state.form_data;
                    if (form_data.services_needed.length != 0) {
                        let services_needed = form_data.services_needed.map((item, i) => {
                            return { name: item };
                        });
                        form_data.services_needed = JSON.stringify(services_needed);
                        // console.log(form_data.services_needed);
                    }
                    form_data.driver_helper_mover = JSON.stringify(form_data.driver_helper_mover);
                    form_data.driver_data = JSON.stringify(this.state.driver_data)
                    this.setState({ loading: true }, () => {
                        // console.log(form_data);
                        // debugger;
                        PostDataWithParam('CreateOffer', form_data)
                            .then((response) => {
                                if (response.success == 1) {

                                    this.setState({ loading: false });
                                    // window.swal('Success!', 'Order Created Successfully\r\nNow you can assign resources to this order.', 'success');
                                    // this.props.history.push({
                                    //     pathname: '/independent-mover/dashboard/order-details',
                                    //     state: {
                                    //         id: response.data.id,
                                    //         type: ''
                                    //     }
                                    // }
                                    // )

                                    window.swal({
                                        title: "Success!",
                                        text: "Order Created Successfully\r\nNow you can assign resources to this order.",
                                        icon: "success",
                                        timer: 2000
                                    }).then(() => {
                                        this.props.history.push({
                                            pathname: '/independent-mover/dashboard/order-details',
                                            state: {
                                                id: response.data.id,
                                                type: '',
                                                from: "CREATE_OFFER"
                                            }
                                        }
                                        )
                                    });

                                } else {
                                    let f_data = {
                                        services_needed: form_data.services_needed
                                    }
                                    this.setState({ loading: false, form_data: f_data });
                                    window.swal('Error!', response.msg, 'error');
                                }
                            })
                            .catch(err => {
                                let f_data = {
                                    services_needed: []
                                }
                                this.setState({ loading: false, form_data: f_data });
                                window.swal('Error!', 'Something went wrong', 'error');
                            })
                    })
                }
            }

        } else {

        }
    }

    postOfferForNM(flag, e) {
        e.preventDefault();
        // console.log('flag',flag)
        let form_data = { ...this.state.form_data };
        form_data.military_flag = 0
        this.setState({ form_data })
        // console.log('form ' , this.state.form_data);
        // console.log('form ' , this.state.form_data.military_flag);
        form_data.request_start_date = this.state.form_data.nm_request_start_date;
        form_data.request_end_date = this.state.form_data.nm_request_end_date;
        // form_data.request_start_date=this.state.form_data.nm_requested_packed_date;
        form_data.request_end_date = this.state.form_data.nm_request_end_date;
        form_data.source_address = this.state.form_data.nm_source_address;
        form_data.destination_address = this.state.form_data.nm_destination_address;
        form_data.requested_pack_date = this.state.form_data.nm_request_start_date;
        form_data.source_address = form_data.nm_source_address;
        form_data.destination_address = form_data.nm_destination_address;
        form_data.primary_pickup_city = form_data.nm_primary_pickup_city
        form_data.primary_pickup_state = form_data.nm_primary_pickup_state
        form_data.primary_pickup_zip = form_data.nm_primary_pickup_zip
        form_data.primary_pickup_country = form_data.nm_primary_pickup_country
        form_data.primary_destination_city = form_data.nm_primary_destination_city
        form_data.primary_destination_state = form_data.nm_primary_destination_state
        form_data.primary_destination_zip = form_data.nm_primary_destination_zip
        form_data.primary_destination_country = form_data.nm_primary_destination_country

        //this.state.form_data.services_needed=" ";
        if (flag == 0) {

            if ((this.state.form_data.nm_request_start_date && this.state.form_data.nm_request_end_date)) {

                if ((Date.parse(this.state.form_data.nm_request_end_date) <= Date.parse(this.state.form_data.nm_request_start_date))) {
                    this.setState({ end_date_err: 'End date should be greater than start date.' });
                    // console.log('if');
                }

                else if ((this.state.form_data.nm_source_address == "" && this.state.form_data.nm_destination_address == "")) {
                    this.setState({ end_addr_err: 'Please enter any of the addresses' });
                    // console.log('else if');
                }

                else if (this.state.form_data.services_needed.length <= 0) {

                    this.setState({ service_err: 'Please select at least one service.' }, () => {
                        //console.log(this.state.service_err)
                    });
                }

                else {
                    // console.log('else');
                    // if (this.state.form_data.ssn.charAt(0) == 'X') {
                    //     let swap = this.state.form_data.ssn_val;
                    //     this.state.form_data.ssn = swap
                    // }
                    //this.state.form_data.ssn_val = '';
                    this.setState({ end_date_err: '' });
                    this.setState({ end_addr_err: '' });
                    this.setState({ service_err: '' });

                    // if (form_data.nm_services_needed.length != 0) {
                    //     let nm_services_needed = form_data.nm_services_needed.map((item, i) => {
                    //         return { name: item };
                    //     });
                    //     form_data.nm_services_needed = JSON.stringify(nm_services_needed);
                    // }
                    if (form_data.services_needed.length != 0) {
                        let services_needed = form_data.services_needed.map((item, i) => {
                            return { name: item };
                        });
                        form_data.services_needed = JSON.stringify(services_needed);
                        // console.log(form_data.services_needed);
                    }
                    form_data.driver_helper_mover = JSON.stringify(form_data.driver_helper_mover);
                    form_data.driver_data = JSON.stringify(this.state.driver_data)
                    this.setState({ loading: true }, () => {
                        // console.log(form_data);
                        // debugger;
                        // return
                        PostDataWithParam('CreateOffer', form_data)
                            .then((response) => {
                                if (response.success == 1) {

                                    this.setState({ loading: false });
                                    // window.swal('Success!', 'Order Created Successfully\r\nNow you can assign resources to this order.', 'success');
                                    // this.props.history.push({
                                    //     pathname: '/independent-mover/dashboard/order-details',
                                    //     state: {
                                    //         id: response.data.id,
                                    //         type: ''
                                    //     }
                                    // }
                                    // )

                                    window.swal({
                                        title: "Success!",
                                        text: "Order Created Successfully\r\nNow you can assign resources to this order.",
                                        icon: "success",
                                        timer: 2000
                                    }).then(() => {
                                        this.props.history.push({
                                            pathname: '/independent-mover/dashboard/order-details',
                                            state: {
                                                id: response.data.id,
                                                type: '',
                                                from: "CREATE_OFFER"
                                            }
                                        }
                                        )
                                    });
                                } else {
                                    let f_data = {
                                        services_needed: form_data.services_needed
                                        // nm_services_needed: form_data.nm_services_needed
                                    }
                                    this.setState({ loading: false, form_data: f_data });
                                    window.swal('Error!', response.msg, 'error');
                                }
                            })
                            .catch(err => {
                                let f_data = {
                                    // nm_services_needed: ' ',
                                    services_needed: []
                                }
                                this.setState({ loading: false, form_data: f_data });
                                window.swal('Error!', 'Something went wrong', 'error');
                            })
                    })
                }
            }

        } else {

        }
    }




    redirectSubscription = () => {
        window.$('#subscribeToPremiumModal').modal('show');
    }

    handleOnChangeSearchDriver = (e) => {
        let search_driver_fields = this.state.search_driver_fields;
        search_driver_fields[e.target.name] = e.target.value;
        if (e.target.name == 'contact_no') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                search_driver_fields[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
        }
        this.setState({ search_driver_fields: search_driver_fields });
    }
    handleOnChangeSearchCustomer = (e) => {
        let search_customer_fields = this.state.search_customer_fields;
        search_customer_fields[e.target.name] = e.target.value;
        if (e.target.name == 'contact_no') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                search_customer_fields[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
        }
        this.setState({ search_customer_fields: search_customer_fields });
    }

    searchDriver = () => {
        let search_driver_fields = this.state.search_driver_fields;

        this.setState({ search_loading: true }, () => {
            PostDataWithParam('SearchDriverForCreateOrder', search_driver_fields)
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ search_driver_list: response.result, search_loading: false });
                    } else {
                        this.setState({ search_driver_list: [], search_loading: false })
                    }
                })
                .catch(() => {
                    this.setState({ search_driver_list: [], search_loading: false })
                })
        })
    }

    searchCustomer = () => {
        let search_customer_fields = this.state.search_customer_fields;

        this.setState({ search_loading: true }, () => {
            PostDataWithParam('SearchCustomerForCreateOrder', search_customer_fields)
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ search_customer_list: response.result, search_loading: false });
                    } else {
                        this.setState({ search_customer_list: [], search_loading: false })
                    }
                })
                .catch(() => {
                    this.setState({ search_customer_list: [], search_loading: false })
                })
        })
    }

    getDriverInfo = (id) => {
        this.setState({ search_loading: true }, () => {
            PostDataWithParam('GetDriverDetails', { driver_id: id })
                .then((response) => {
                    if (response.success === 1) {
                        let form_data = this.state.form_data;

                        form_data.driver_email = response.data[0].email;
                        form_data.driver_first_name = response.data[0].first_name;
                        form_data.driver_last_name = response.data[0].last_name;
                        form_data.driver_mobile_number = response.data[0].contact_no;

                        this.setState({ form_data: form_data, search_loading: false }, () => {
                            window.$('#addExistingDriverModal').modal('hide');
                        });
                    } else {
                        this.setState({ search_loading: false })
                        window.swal('Error!', 'Something went wrong.', 'error')
                    }
                })
                .catch(err => {
                    this.setState({ search_loading: false })
                    window.swal('Error!', 'Something went wrong.', 'error')
                })
        })
    }

    chkDrivers = (e) => {
        let chk_drivers = this.state.chk_drivers;

        if (chk_drivers.indexOf(e.target.value) > -1) {
            chk_drivers = chk_drivers.filter((item, i) => {
                if (item == e.target.value)
                    return false;
                else
                    return true;
            });
        } else {
            chk_drivers.push(e.target.value)
        }

        this.setState({ chk_drivers: chk_drivers });
    }

    saveChkDrivers = () => {
        let chk_drivers = this.state.chk_drivers;

        if (chk_drivers.length != 0) {
            chk_drivers = chk_drivers.map((item, i) => {
                return { id: item };
            });

            chk_drivers = JSON.stringify(chk_drivers);
        }

        this.setState({ search_loading: true }, () => {
            PostDataWithParam('SearchDriverDetailsForCreateOrder', { user_id: chk_drivers })
                .then((response) => {
                    if (response.success === 1) {

                        this.setState({ driver_data: response.driver_data, search_loading: false }, () => {
                            window.$('#addExistingDriverModal').modal('hide');
                        });
                    } else {
                        this.setState({ driver_data: [], search_loading: false })
                        window.swal('Error!', 'Something went wrong.', 'error')
                    }
                })
                .catch(() => {
                    this.setState({ driver_data: [], search_loading: false })
                    window.swal('Error!', 'Something went wrong.', 'error')
                })
        })
    }
    setStorage(e) {
        let form_data = this.state.form_data;
        if (window.$('input:checkbox[name=storage_needed]').is(':checked')) {
            form_data.storage_needed = 1;
        }
        else {
            form_data.storage_needed = 0;
        }
        this.setFormData(form_data)

    }
    chkCustomers = (e) => {
        let chk_customers = this.state.chk_customers;

        if (chk_customers.indexOf(e.target.value) > -1) {
            chk_customers = chk_customers.filter((item, i) => {
                if (item == e.target.value)
                    return false;
                else
                    return true;
            });
        } else {
            chk_customers = e.target.value
        }

        this.setState({ chk_customers: chk_customers, search_loading: true }, () => {
            chk_customers = JSON.stringify(chk_customers);
            PostDataWithParam('SearchCustomerDetailsForCreateOrder', { user_id: chk_customers, profile_id: this.state.searchProfileId })
                .then((response) => {
                    if (response.success === 1) {
                        let form_data = this.state.form_data;
                        form_data.customer_first_name = response.customer_data[0].customer_first_name;
                        form_data.customer_last_name = response.customer_data[0].customer_last_name;
                        form_data.customer_email = response.customer_data[0].customer_email;
                        form_data.contact_no = response.customer_data[0].customer_mobile_number;


                        this.setState({ form_data: form_data, search_loading: false }, () => {
                            window.$('#addExistingCustomerModal').modal('hide');
                        });
                    } else {
                        window.$('#addExistingCustomerModal').modal('hide');
                        this.setState({ customer_data: [], search_loading: false })
                        window.swal('Error!', 'Something went wrong.', 'error')
                    }
                })
                .catch(() => {
                    window.$('#addExistingCustomerModal').modal('hide');
                    this.setState({ customer_data: [], search_loading: false })
                    window.swal('Error!', 'Something went wrong.', 'error')
                })
        });
    }

    handleOnChangeDriver = (e, index) => {
        let driver_data = this.state.driver_data;
        driver_data[index][e.target.name] = e.target.value;
        if (e.target.name == 'driver_mobile_number') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                driver_data[index][e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
        }
        this.setState({ driver_data: driver_data }, () => {

        });
    }

    renderDriverSection() {
        if (this.state.driver_data.length > 0) {
            let drivers = this.state.driver_data.map((driver, index) => {
                return (
                    <React.Fragment key={index}>
                        <div className="row block">
                            {
                                index > 0
                                &&
                                <hr />
                            }
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Driver First Name</label>
                                    <input className="form-control" type="text"
                                        value={driver.driver_first_name} placeholder="First Name"
                                        onChange={(e) => this.handleOnChangeDriver(e, index)}
                                        name="driver_first_name" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Driver Last Name</label>
                                    <input className="form-control" type="text"
                                        value={driver.driver_last_name} placeholder="Last Name"
                                        onChange={(e) => this.handleOnChangeDriver(e, index)}
                                        name="driver_last_name" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Driver Mobile Number</label>
                                    <input placeholder="xxx xxx xxxx" className="form-control" type="text"
                                        value={driver.driver_mobile_number} placeholder="Mobile Number"
                                        onChange={(e) => this.handleOnChangeDriver(e, index)}
                                        name="driver_mobile_number" maxLength="12" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Driver Email address</label>
                                    <input className="form-control" type="email"
                                        value={driver.driver_email} placeholder="Email address"
                                        onChange={(e) => this.handleOnChangeDriver(e, index)} name="driver_email" />
                                </div>
                            </div>
                            {
                                index > 0
                                &&
                                <div className="col-md-12 text-center">
                                    <a href="javascript:void(0)" className="text-danger m-t-40 " onClick={() => this.removeDriver(index, driver.driver_user_id)}><i className="icon-cross f-s-20"></i> Remove</a>
                                </div>
                            }
                        </div>
                    </React.Fragment>
                )
            })
            return drivers;
        }
    }

    addMoreDriver = () => {
        let driver_data = this.state.driver_data;
        let driver_obj = {
            "driver_email": "",
            "driver_first_name": "",
            "driver_last_name": "",
            "driver_mobile_number": ""
        }
        driver_data.push(driver_obj)
        this.setState({ driver_data: driver_data })
    }

    removeDriver = (index, driver_user_id) => {
        let driver_data = this.state.driver_data;
        let chk_drivers = this.state.chk_drivers;

        driver_data = driver_data.filter((item, i) => {
            if (i == index)
                return false;
            else
                return true;
        });

        chk_drivers = chk_drivers.filter((item, i) => {
            if (item == driver_user_id)
                return false;
            else
                return true;
        });

        this.setState({ chk_drivers: chk_drivers, driver_data: driver_data })
    }

    handleSwitchForm = (e) => {
        // alert(e.target.innerText);
        if (e.target.innerText == 'Military Orders') {
            let search_customer_fields = Object.assign({}, this.state.search_customer_fields);
            search_customer_fields.profile_id = 11;
            this.setState(prevState => ({ military_order_form: true, searchProfileId: 11, search_customer_fields: search_customer_fields, non_military_order_form: false, form_data: { ...prevState.military_form_data } }));
            let form_data= this.state.military_form_data
            form_data.services_needed=[]
            this.setState({form_data})
        } else {
            let search_customer_fields = Object.assign({}, this.state.search_customer_fields);
            search_customer_fields.profile_id = 4;
            this.setState(prevState => ({ military_order_form: false, searchProfileId: 4, search_customer_fields: search_customer_fields, non_military_order_form: true, form_data: { ...prevState.non_military_form_data } }))
            let form_data= this.state.non_military_form_data
            form_data.services_needed=[]
            this.setState({form_data})
            
        }
    }

    handleFirearms = (e) => {
        // alert(e.target.value);
        let form_data = Object.assign({}, this.state.form_data);
        if (e.target.value == 1) {
            form_data[e.target.name] = e.target.value;
            // this.setState({firearms_details:true})
            this.setState({ form_data: form_data })
        }
        else {
            form_data[e.target.name] = e.target.value;
            // this.setState({firearms_details:true})
            this.setState({ form_data: form_data })
        }

    }



    render() {

        const { allCountries, baseList, branchList } = this.state;

        let countriesList = allCountries.length > 0
            && allCountries.map((item, i) => {
                return (
                    <option key={i} value={item.country_name}>{item.country_name}</option>
                )
            }, this);
        let renderBaseList = baseList.length > 0
            && baseList.map((base, i) => {
                return (
                    <option key={i} value={base.name}>{base.name}</option>
                )
            }, this);
        let renderBranchList = branchList.length > 0
            && branchList.map((branch, i) => {
                return (
                    <option key={i} value={branch.name}>{branch.name}</option>
                )
            }, this);

        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="panel panel-flat b-0 bg-color-transparent box-shadow-none">
                        <div className="panel-header bg-white border-radius-3px">
                            <ul className="nav nav-tabs nav-tabs-bottom nav-horizantal-scroll m-b-0">

                                <li className={this.state.military_order_form ? 'active' : ''}>
                                    <Link to="#" data-toggle="tab" aria-expanded="true" name="military_order" onClick={this.handleSwitchForm}><h6>Military Orders</h6></Link>

                                </li>
                                <li className={this.state.non_military_order_form ? 'active' : ''}>

                                    <Link to="#" data-toggle="tab" aria-expanded="true" name="non_military_order" onClick={this.handleSwitchForm}><h6>Non-Military Orders</h6></Link>

                                </li>
                            </ul>
                        </div>
                        <div className="panel-body p-0 bg-color-transparent">
                            <div className="military_form" style={{ display: (this.state.military_order_form ? 'block' : 'none') }}>
                                <form method="POST" action="#" id="military_form" onSubmit={this.postOffer.bind(this, 0)} autoComplete="off">
                                    {/* <input type="hidden" value={this.state.military_flag=1}></input> */}
                                    <div className="panel panel-flat profile-page b-t-0">
                                        <div className="panel-heading show-or">
                                            <h5 className="m-0"><Link to={{
                                                pathname: '/moving-company/dashboard/assign-offer',
                                                state: { backBtn: 1, driver_helper_mover: this.state.form_data.driver_helper_mover }
                                            }} className="text-primary">Click here</Link> to select an existing ReloOrders</h5>
                                        </div>
                                        <hr className="m-0" />
                                        <div className="panel-heading">
                                            <h5 className="m-0">Add New ReloOrder</h5>
                                        </div>
                                        <div className="panel-body">
                                            <div className="well bg-indigo-800">
                                                <h6 className="m-t-0">Customer Basic Info (hidden until order accepted)</h6>
                                                <p><a href="javascript:void(0)" onClick={(e) => this.existingDriverModal(e, 0)}
                                                    className="text-warning">Click here
                                                </a> to choose an existing customer or add a new customer below.</p>
                                                {/* <h5>RELO # BGAC0498401</h5> */}
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>SCAC <span className="text-danger">*</span> </label>
                                                            <input placeholder="SCAC" className="form-control" type="text"
                                                                value={this.state.form_data.scac} name="scac"
                                                                maxLength={4}
                                                                onChange={this.handleOnChange.bind(this)} required={true} />
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Customer SSN <span className="text-danger">*</span></label>
                                                           

                                                            <input placeholder="xxx xx xxxx" type="text" className="form-control"
                                                                value={this.state.form_data.ssn} name="ssn"
                                                                onChange={this.handleOnChange.bind(this)} maxLength="9"
                                                                required={true}
                                                            />


                                                            <span style={{ color: 'black', float: 'right', marginLeft: '-25px', marginTop: '-25px', marginRight: '10px', position: 'relative', zIndex: '2', cursor: 'pointer' }} className={`fa field-icon ${this.state.iconEye ? 'fa-eye-slash' : 'fa-eye'}`} onClick={this.togglePassword.bind(this)}></span>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Customer First Name <span className="text-danger">*</span></label>
                                                            <input placeholder="Customer First Name" className="form-control" type="text"
                                                                value={this.state.form_data.customer_first_name} name="customer_first_name"
                                                                onChange={this.handleOnChange.bind(this)} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Customer Last Name <span className="text-danger">*</span></label>
                                                            <input placeholder="Customer Last Name" className="form-control" type="text"
                                                                value={this.state.form_data.customer_last_name} name="customer_last_name"
                                                                onChange={this.handleOnChange.bind(this)} required={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Primary Phone <span className="text-danger">*</span></label>
                                                            <input placeholder="xxx xxx xxxx" className="form-control" type="text"
                                                                name="contact_no" maxLength="12"
                                                                value={this.state.form_data.contact_no} onChange={this.handleOnChange.bind(this)} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Secondary Phone</label>
                                                            <input placeholder="xxx xxx xxxx" className="form-control" type="text"
                                                                name="home_contact_no" maxLength="12"
                                                                value={this.state.form_data.home_contact_no}
                                                                onChange={this.handleOnChange.bind(this)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Primary Email <span className="text-danger">*</span></label>
                                                            <input placeholder="Primary Email" className="form-control" type="text"
                                                                name="customer_email" value={this.state.form_data.customer_email}
                                                                onChange={this.handleOnChange.bind(this)} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Secondary Email</label>
                                                            <input placeholder="Secondary Email" className="form-control" type="text"
                                                                name="alternate_email" value={this.state.form_data.alternate_email}
                                                                onChange={this.handleOnChange.bind(this)} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <h6 className="text-success">Service Info</h6>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Customer Estimated Weight <span className="text-danger">*</span>
                                                            <a href="https://move.mil/resources/weight-estimator" target="_blank" title="Weight Estimator">
                                                                <i className="icon-info22 m-l-5"></i>
                                                            </a>
                                                        </label>
                                                        <input placeholder="Customer Estimated Weight" className="form-control"
                                                            type="text" name="customer_estimated_weight"
                                                            value={this.state.form_data.customer_estimated_weight}
                                                            onChange={this.handleOnChange.bind(this)} required={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Requested Pack Date <span className="text-danger">*</span></label>
                                                        <div className="input-group date">
                                                            <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                            <input type="text" className="form-control" placeholder="Select date"
                                                                name="requested_pack_date" value={this.state.form_data.requested_pack_date}
                                                                id="requested_pack_date"
                                                                onChange={this.handleOnChange.bind(this)} required={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Requested Pickup Date <span className="text-danger">*</span></label>
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                            <input type="text" className="form-control" placeholder="Select date"
                                                                name="request_start_date" value={this.state.form_data.request_start_date || this.state.form_data.requested_pack_date}
                                                                id="request_start_date"
                                                                onChange={this.handleOnChange.bind(this)} required={true} />
                                                            {/* onClick={this.selectDatePicker('request_start_date')} */}
                                                        </div>
                                                        <p className="text-danger help-block">{this.state.end_date_err}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="row">

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Requested Latest Pickup Date</label>
                                                        <div className="input-group date">
                                                            <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                            <input type="text" className="form-control"
                                                                name="requested_latest_pickup_date" placeholder="Select date"
                                                                value={this.state.form_data.requested_latest_pickup_date || this.state.form_data.request_start_date}
                                                                id="requested_latest_pickup_date"
                                                                onChange={this.handleOnChange.bind(this)} onClick={this.selectDatePicker('requested_latest_pickup_date')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Requested Delivery Date <span className="text-danger">*</span></label>
                                                        <div className="input-group date">
                                                            <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                            <input type="text" className="form-control" placeholder="Select date"
                                                                value={this.state.form_data.request_end_date}
                                                                name="request_end_date" id="request_end_date"
                                                                onChange={this.handleOnChange.bind(this)} required={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Required Delivery Date (System Generated)</label>
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                            <input type="text" className="form-control" placeholder="Select date"
                                                                value={this.state.form_data.required_delivery_date}
                                                                // value={Date().getFullYear}
                                                                name="required_delivery_date" id="required_delivery_date"
                                                                onChange={this.handleOnChange.bind(this)} disabled />
                                                        </div>
                                                        <p className="text-danger help-block">{this.state.end_date_err}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="row">
                                    <div className="col-md-2">
                                        <p>Select Address Mode</p>
                                    </div>
                                    <div className="col-md-2">                                        
                                        <input onChange={this.handleAddressOptionChange.bind(this)} className="form-check-input mt-2" type="radio" name="select_address_google" id="select_address_google" value="google" checked={this.state.select_address} onClick={this.radioHandler}/>&nbsp;
                                        <label className="form-check-label" for="select_address">Using Google Map</label>                                                                                                                            
                                    </div>
                                    <div className="col-md-2">
                                        <input onChange={this.handleAddressOptionChange.bind(this)} className="form-check-input mt-2" type="radio" name="select_address_google" id="select_address_manual" value="manual" checked={this.state.select_address == false} onClick={this.radioHandler} />&nbsp;
                                        <label className="form-check-label" for="select_address">Enter Manually</label>
                                    </div>
                                </div> */}

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Primary Pickup Origin Address <span className="text-danger">*</span></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        {/* <input type="checkbox" name="primary_pickup_address" onClick={this.handleManualAddress} ></input>&nbsp;&nbsp; */}
                                                        {/* <label>Enter Manually</label> */}
                                                        {/* <textarea className="form-control"
                                                rows="2" name="source_address" id="source_address"
                                                placeholder="Enter Address"
                                                value={this.state.form_data.source_address}
                                                onChange={this.initAutocomplete}></textarea> */}
                                                        <input type="text" className="form-control" name="source_address" id="source_address" value={this.state.form_data.source_address || ''} placeholder="Primary Pickup Origin Address" onChange={this.initAutocomplete} required={true}></input>
                                                        {/*  */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row" >

                                                <div className="col-md-3 col-sm-6">
                                                    <div className="form-group">
                                                        <label>City</label><span className="text-danger">*</span>
                                                        <input type="text" className="form-control" id="primary_pickup_city" name="primary_pickup_city" value={this.state.form_data.primary_pickup_city} placeholder='' onChange={this.changeValue} required={!!this.state.form_data.source_address ? true: false}></input>
                                                        {/* readOnly={this.state.primary_pickup_address_check} */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="form-group">
                                                        <label>State</label><span className="text-danger">*</span>
                                                        <input type="text" className="form-control" id="primary_pickup_state" name="primary_pickup_state" value={this.state.form_data.primary_pickup_state || ''} placeholder='' onChange={this.changeValue} required={!!this.state.form_data.source_address ? true: false}></input>
                                                        {/* readOnly={this.state.primary_pickup_address_check} */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="form-group">
                                                        <label>Zip Code</label><span className="text-danger">*</span>
                                                        <input type="text" className="form-control" id="primary_pickup_zip" name="primary_pickup_zip" value={this.state.form_data.primary_pickup_zip || ''} placeholder='' onChange={this.changeValue} required={!!this.state.form_data.source_address ? true: false}></input>
                                                        {/* readOnly={this.state.primary_pickup_address_check} */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="form-group">
                                                        <label>Country </label>
                                                        {/* <input type="text" className="form-control" id="primary_pickup_country" name="primary_pickup_country" value={this.state.form_data.primary_pickup_country}  placeholder='' onChange={this.changeValue}></input> */}
                                                        {/* readOnly={this.state.primary_pickup_address_check} */}

                                                        <select className="form-control" id="primary_pickup_country"
                                                            value={this.state.form_data.primary_pickup_country}
                                                            name="primary_pickup_country" placeholder='' onChange={this.changeValue}>
                                                            {countriesList}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Primary Destination Delivery Address <span className="text-danger">*</span></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        {/* <input type="checkbox" name="primary_destination_address" onClick={this.handleManualAddress} ></input>&nbsp;&nbsp; */}
                                                        {/* <label>Enter Manually</label> */}
                                                        {/* <textarea className="form-control"
                                                    rows="2" name="destination_address" id="destination_address"
                                                    placeholder="Enter Address"
                                                    value={this.state.form_data.destination_address}
                                                    onChange={this.initAutocompleteDestination}></textarea> */}
                                                        <input type="text" className="form-control" name="destination_address" id="destination_address" value={this.state.form_data.destination_address || ''} placeholder="Primary Destination Delivery Address" onChange={this.initAutocomplete} required={true}></input>
                                                        {/*  */}
                                                    </div>
                                                    <p className="text-danger help-block">{this.state.end_addr_err}</p>
                                                </div>
                                            </div>


                                            <div className="row">

                                                <div className="col-md-3 col-sm-6">
                                                    <div className="form-group">
                                                        <label>City</label><span className="text-danger">*</span>
                                                        <input className="form-control" id="primary_destination_city" name="primary_destination_city" placeholder='' value={this.state.form_data.primary_destination_city || ''} onChange={this.changeValue} required={!!this.state.form_data.destination_address ? true: false}></input>
                                                        {/* readOnly={this.state.primary_destination_address_check} */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="form-group">
                                                        <label>State</label><span className="text-danger">*</span>
                                                        <input className="form-control" id="primary_destination_state" name="primary_destination_state" placeholder='' value={this.state.form_data.primary_destination_state || ''} onChange={this.changeValue} required={!!this.state.form_data.destination_address ? true: false}></input>
                                                        {/* readOnly={this.state.primary_destination_address_check} */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="form-group">
                                                        <label>Zip Code</label><span className="text-danger">*</span>
                                                        <input className="form-control" id="primary_destination_zip" name="primary_destination_zip" placeholder='' value={this.state.form_data.primary_destination_zip || ''} onChange={this.changeValue} required={!!this.state.form_data.destination_address ? true: false}></input>
                                                        {/* readOnly={this.state.primary_destination_address_check} */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="form-group">
                                                        <label>Country</label>
                                                        {/* <input type="text" className="form-control" id="primary_destination_country" name="primary_destination_country" value={this.state.form_data.primary_destination_country}  placeholder='' onChange={this.changeValue}></input> */}
                                                        {/* readOnly={this.state.primary_pickup_address_check} */}
                                                        <select className="form-control" id="primary_destination_country"
                                                            value={this.state.form_data.primary_destination_country}
                                                            name="primary_destination_country" placeholder='' onChange={this.changeValue}>
                                                            {countriesList}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">

                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Special Items</label>
                                                        <input className="form-control" type="text"
                                                            placeholder="Special Items"
                                                            value={this.state.form_data.special_items} name="special_items"
                                                            id="special_items" onChange={this.handleOnChange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Vehicles</label>
                                                        <input className="form-control" type="text"
                                                            placeholder="Vehicles" value={this.state.form_data.vehicles}
                                                            name="vehicles" id="vehicles" onChange={this.handleOnChange.bind(this)} />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="m-r-15">Firearms<span className="text-danger">*</span></label>
                                                        <label className="radio-inline m-b-0 m-r-5"><input type="radio" name="firearms_details" value="1" onChange={this.handleFirearms} required={true} />Yes</label>
                                                        <label className="radio-inline m-b-0"><input type="radio" name="firearms_details" value="0" onChange={this.handleFirearms} />No</label>
                                                        <input className="form-control" type={this.state.form_data.firearms_details == 1 ? 'text' : 'hidden'}
                                                            placeholder="Please Enter Detailed Firearm Information"
                                                            value={this.state.form_data.firearms}
                                                            name="firearms" id="firearms" onChange={this.handleOnChange.bind(this)} required={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Agreed Compensation/ Price</label>
                                                        <input className="form-control" type="text"
                                                            placeholder="Agreed Compensation/ Price"
                                                            value={this.state.form_data.agreed_compensation_price} name="agreed_compensation_price"
                                                            id="compensation" onChange={this.handleOnChange.bind(this)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Remarks (Any special notes or concerns of the customer)</label>
                                                        <Editor
                                                            placeHolder="Enter Remarks"
                                                            initialValue=""
                                                            init={{
                                                                menubar: false,
                                                                statusbar: false,
                                                                toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                                            }}
                                                            onChange={this.handleEditorChange}
                                                            className="form-control textarea-editor"
                                                            name="remarks"
                                                            id='remarks'
                                                        />

                                                        {/*<textarea className="form-control" value={this.state.form_data.remarks}
                                                                                                                    rows="2" name="remarks" id="remarks"
                                                                                                                    placeholder="Enter Remarks"
                                                                                                                    onChange={this.handleOnChange.bind(this)}></textarea>*/}
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Relocation Notes (Any special notes or concerns of the customer) <span className="text-danger">*</span></label>
                                                        <textarea className="form-control" rows="6" placeholder="Relocation Notes"
                                                            value={this.state.form_data.relocation_notes}
                                                            onChange={this.handleOnChange.bind(this)} name="relocation_notes" required={true} />
                                                    </div>
                                                </div> */}
                                            </div>
                                            <hr />

                                            <h6 className="text-success m-t-20">Additional Contact Information (Optional)</h6>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Service Branch </label>
                                                        <select className="form-control" value={this.state.form_data.service_branch}
                                                            name="service_branch" id="service_branch" onChange={this.handleOnChange.bind(this)}>
                                                            <option value="">Select Branch</option>
                                                            {renderBranchList}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Shipment Type</label>
                                                        <input className="form-control" type="text"
                                                            placeholder="Shipment Type" value={this.state.form_data.shipment_type}
                                                            name="shipment_type" id="shipment_type" onChange={this.handleOnChange.bind(this)}/>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Shipment Market </label>
                                                        <input className="form-control" type="text"
                                                            placeholder="Shipment Market" value={this.state.form_data.shipment_market}
                                                            name="shipment_market" id="shipment_market" onChange={this.handleOnChange.bind(this)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Origin GBLOC </label>
                                                        <select className="form-control" value={this.state.form_data.origin_gbloc}
                                                            name="origin_gbloc" id="origin_gbloc" onChange={this.handleOnChange.bind(this)}>
                                                            <option value="">Select Base</option>
                                                            {renderBaseList}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Destination GBLOC </label>
                                                        <select className="form-control" value={this.state.form_data.destination_gbloc}
                                                            name="destination_gbloc" id="destination_gbloc" onChange={this.handleOnChange.bind(this)}>
                                                            <option value="">Select Base</option>
                                                            {renderBaseList}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Channel </label>
                                                        <input className="form-control" type="text"
                                                            placeholder="Channel" value={this.state.form_data.channel}
                                                            name="channel" id="channel" onChange={this.handleOnChange.bind(this)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Releasing Agent (Alt. Point of Contact) </label>
                                                        <input className="form-control" type="text"
                                                            placeholder="Releasing Agent" value={this.state.form_data.releasing_agent}
                                                            name="releasing_agent" id="releasing_agent" onChange={this.handleOnChange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Releasing Agent Phone</label>
                                                        <input placeholder="xxx-xxx-xxxx" className="form-control" type="text"
                                                            maxLength="12"
                                                            value={this.state.form_data.releasing_agent_phone}
                                                            name="releasing_agent_phone"
                                                            onChange={this.handleOnChange.bind(this)} />
                                                    </div>

                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Releasing Agent Email Address </label>
                                                        <input className="form-control" type="text"
                                                            placeholder="Email Address"
                                                            value={this.state.form_data.releasing_agent_email}
                                                            name="releasing_agent_email" id="releasing_agent_email"
                                                            onChange={this.handleOnChange.bind(this)} />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Receiving Agent (Alt. Point of Contact)</label>
                                                        <input className="form-control" type="text"
                                                            placeholder="Receiving Agent" value={this.state.form_data.receiving_agent}
                                                            name="receiving_agent" id="receiving_agent" onChange={this.handleOnChange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Receiving Agent Phone</label>
                                                        <input placeholder="xxx-xxx-xxxx" className="form-control" type="text"
                                                            maxLength="12"
                                                            value={this.state.form_data.receiving_agent_phone}
                                                            name="receiving_agent_phone"
                                                            onChange={this.handleOnChange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Receiving Agent Email Address </label>
                                                        <input className="form-control" type="text"
                                                            placeholder="Email Address"
                                                            value={this.state.form_data.receiving_agent_email}
                                                            name="receiving_agent_email" id="receiving_agent_email"
                                                            onChange={this.handleOnChange.bind(this)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Code Of Service </label>
                                                        <input className="form-control" type="text" placeholder="Service Code" name="code_of_service" maxLength="12"
                                                            value={this.state.form_data.code_of_service}
                                                            name="code_of_service" id="code_of_service" onChange={this.handleOnChange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Orders Number</label>
                                                        <input className="form-control" type="text"
                                                            placeholder="Orders Number" value={this.state.form_data.order_number}
                                                            name="order_number" id="order_number" onChange={this.handleOnChange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Orders Date </label>
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                            <input type="text" className="form-control"
                                                                placeholder="Select date" value={this.state.form_data.order_date}
                                                                name="order_date" id="order_date" onChange={this.handleOnChange.bind(this)} />
                                                        </div>
                                                        <p className="text-danger help-block">{this.state.end_date_err}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group m-b-0">
                                                        <label>Services Needed <span className="text-danger">*</span></label>
                                                        <ul className="checkbox-list m-b-0">
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Pre-Move Survey"
                                                                        checked={this.state.form_data.services_needed.indexOf('Pre-Move Survey') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Pre-Move Survey
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Packing"
                                                                        checked={this.state.form_data.services_needed.indexOf('Packing') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Packing
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Loading"
                                                                        checked={this.state.form_data.services_needed.indexOf('Loading') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Loading
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Transport/ Driving"
                                                                        checked={this.state.form_data.services_needed.indexOf('Transport/ Driving') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Transport/ Driving
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Unloading"
                                                                        checked={this.state.form_data.services_needed.indexOf('Unloading') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Unloading
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Unpacking"
                                                                        checked={this.state.form_data.services_needed.indexOf('Unpacking') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Unpacking
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Debris Removal"
                                                                        checked={this.state.form_data.services_needed.indexOf('Debris Removal') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Debris Removal
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Third Party Service (Other)"
                                                                        checked={this.state.form_data.services_needed.indexOf('Third Party Service (Other)') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Third Party Service (Other)
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Crating"
                                                                        checked={this.state.form_data.services_needed.indexOf('Crating') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Crating
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Uncrating"
                                                                        checked={this.state.form_data.services_needed.indexOf('Uncrating') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Uncrating
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Appliance Service"
                                                                        checked={this.state.form_data.services_needed.indexOf('Appliance Service') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Appliance Service
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Disassembly-Setup"
                                                                        checked={this.state.form_data.services_needed.indexOf('Disassembly-Setup') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Disassembly-Setup
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Clock Service"
                                                                        checked={this.state.form_data.services_needed.indexOf('Clock Service') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Clock Service
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Maid Service"
                                                                        checked={this.state.form_data.services_needed.indexOf('Maid Service') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Maid Service
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Hoisting Service"
                                                                        checked={this.state.form_data.services_needed.indexOf('Hoisting Service') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Hoisting Service
                                                                </label>
                                                            </li>
                                                            {/* <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Short Hauling (500 Miles)"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'Short Hauling (500 Miles)') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Short Hauling (500 Miles)
                                                        </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Long Hauling (500+ MIles)"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'Long Hauling (500+ MIles)') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Long Hauling (500+ MIles)
                                                        </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Freight Hauling"
                                                                        checked={this.state.form_data.services_needed.indexOf('Freight Hauling') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Freight Hauling
                                                        </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Storage"
                                                                        checked={this.state.form_data.services_needed.indexOf('Storage') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span><span className=""></span>Storage
                                                        </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Intermodal"
                                                                        checked={this.state.form_data.services_needed.indexOf('Intermodal') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Intermodal
                                                        </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Day Road Labor"
                                                                        checked={this.state.form_data.services_needed.indexOf('Day Road Labor') > -1}
                                                                        onChange={this.setServices.bind(this)} /><span className="">
                                                                    </span>Day Road Labor
                                                            </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="SIT Approved"
                                                                        checked={this.state.form_data.services_needed.indexOf('SIT Approved') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>SIT Approved
                                                        </label>
                                                            </li> */}
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed"
                                                                        value="International Origin Service"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'International Origin Service') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>International Origin Service
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed"
                                                                        value="International Destination Service"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'International Destination Service') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>International Destination Service
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed"
                                                                        value="Claim Damage Inspection"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'Claim Damage Inspection') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Claim Damage Inspection
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed"
                                                                        value="Claim Damage Repair"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'Claim Damage Repair') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Claim Damage Repair
                                                                </label>
                                                            </li>
                                                            {/* <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Last Mile Delivery"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'Last Mile Delivery') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Last Mile Delivery
                                                            </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed"
                                                                        value="Freight Warehouse & Handling"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'Freight Warehouse & Handling') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Freight Warehouse &amp; Handling
                                                            </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Third Party Service"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'Third Party Service') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Third Party Service
                                                            </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Unpack"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'Unpack') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Unpack
                                                            </label>
                                                            </li> */}
                                                        </ul>
                                                    </div>
                                                    <p className="text-danger help-block">{this.state.service_err}</p>
                                                </div>

                                                {/* <div className="col-md-12">
                                                    <div className="form-group">
                                                        <span style={{ "vertical-align": "text-bottom" }}> Will Storage Be Needed? </span>
                                                        <span style={{ "vertical-align": "text-bottom" }} className="m-l-10"> No </span>
                                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                                            <input type="checkbox" name="storage_needed" value="1"
                                                                id="social_media_status" checked={this.state.form_data.storage_needed}
                                                                onChange={this.setStorage.bind(this)} />
                                                            <span className="m-l-25 m-t-5"></span>
                                                        </label>
                                                        <span style={{ "vertical-align": "text-bottom" }}>Yes</span>
                                                    </div>
                                                </div> */}
                                            </div>
                                            <hr />

                                            <h6 className="text-success m-t-20">Additional Locations (Optional)</h6>
                                            <div className="row">
                                                <div className="col-sm-6">

                                                    <div className="form-group">
                                                        <label>Authorized Pickup</label>
                                                        <textarea className="form-control"
                                                            rows="2" name="authorized_pickup" id="authorized_pickup"
                                                            placeholder="Authorized Pickup"
                                                            value={this.state.form_data.authorized_pickup}
                                                            onChange={this.authorizedpicinitAutocomplete.bind(this)}></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>In Transit</label>
                                                        <textarea className="form-control"
                                                            rows="2" name="in_transit" id="in_transit"
                                                            placeholder="In Transit"
                                                            value={this.state.form_data.in_transit}
                                                            onChange={this.transitinitAutocomplete.bind(this)}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Authorized Delivery</label>

                                                        <textarea className="form-control"
                                                            rows="2" name="authorized_delivery" id="authorized_delivery"
                                                            placeholder="Authorized Delivery"
                                                            value={this.state.form_data.authorized_delivery}
                                                            onChange={this.authorizeddeliverypicinitAutocomplete.bind(this)}></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>TCN Number</label>
                                                        <input className="form-control" type="text" placeholder="TCN Number"
                                                            value={this.state.form_data.tcn_number}
                                                            name="tcn_number" id="tcn_number"
                                                            onChange={this.handleOnChange.bind(this)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Point Of Embark</label>
                                                        <input className="form-control" type="text" placeholder="Point Of Embark"
                                                            value={this.state.form_data.port_of_embark}
                                                            name="port_of_embark" id="port_of_embark"
                                                            onChange={this.handleOnChange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Point Of Debark</label>
                                                        <input className="form-control" type="text" placeholder="Point Of Debark"
                                                            value={this.state.form_data.port_of_debark}
                                                            name="port_of_debark" id="port_of_debark"
                                                            onChange={this.handleOnChange.bind(this)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h6 className="text-success m-t-20">Driver Info (hidden until order accepted)</h6>
                                            <p><a href="javascript:void(0)" onClick={(e) => this.existingDriverModal(e, 1)}>Click here</a> to choose an existing driver or add a new driver below.</p>
                                            {this.renderDriverSection()}
                                            <div className="text-right block">
                                                <a href="javascript:void(0)" className="btn btn-primary"
                                                    onClick={this.addMoreDriver}><i className="icon-plus22"></i> Add Driver</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <h4 className="pull-left">Files</h4>
                                     <label className="btn btn-success btn-xs pull-right btn-file">
                                        <input type="file" name="files[]" onChange={this.uploadFiles.bind(this)} multiple={true} />
                                        <i className="icon-file-plus position-left"></i> Add File</label> */}
                                    <button className="btn btn-success btn-xs pull-right m-b-20" type="button" data-toggle="modal" data-target="#FileUpload-Modal" onClick={this.openFileModal}><i className="icon-file-plus"></i> &nbsp; Add File</button>

                                    <div className="clearfix"></div>
                                    <div className="panel panel-flat profile-page">
                                        <div className="panel-heading p-0">
                                            <ul className="nav nav-tabs nav-tabs-bottom nav-horizantal-scroll m-t-0">
                                                <li className="inline-block active">
                                                    <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setFileGroup.bind(this, 1)}>
                                                        <p className="text-uppercase m-0 m-l-5 m-r-5">Video &amp; Image Files</p>
                                                    </a>
                                                </li>
                                                {/* <li className="">
                                                    <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setFileGroup.bind(this, 2)}>
                                                        <p className="text-uppercase m-0 m-l-5 m-r-5">Inventory Pictures</p>
                                                    </a>
                                                </li> */}
                                                <li className="inline-block">
                                                    <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setFileGroup.bind(this, 3)}>
                                                        <p className="text-uppercase m-0 m-l-5 m-r-5">Other Files</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        {/* FileUpload-Modal start */}
                                        {this.state.military_order_form &&
                                            <div className="modal fade" id="FileUpload-Modal" role="dialog">

                                                <div className="modal-dialog">
                                                    <form onSubmit={this.fileUpload.bind(this)}>
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeFile}><span aria-hidden="true">&times;</span></button>
                                                                <h4 className="modal-title" style={{ color: "black" }}>Upload File & Details</h4>
                                                            </div>
                                                            <hr />
                                                            <div className="modal-body p-t-0 p-b-0">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            {this.state.isProccesingFileUpload ?
                                                                                <label className="btn btn-upload-file btn-xs">Processing...</label>
                                                                                :
                                                                                <label className="btn btn-upload-file btn-xs">
                                                                                    <input type="file" name="files[]" onChange={this.uploadFiles.bind(this)} /><i className="icon-file-plus2 f-s-22"></i> &nbsp; Add File
                                                                                    {/* <input type="file" name="files[]" onChange={this.previewFiles.bind(this)} /><i className="icon-file-plus2 f-s-22"></i> &nbsp; Add File */}
                                                                                </label>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="file-manager horizontal-scroll">
                                                                            <ul>
                                                                                {this.renderFilesModal()}
                                                                                {/* {this.state.filePreview &&
                                                                                    <img src={this.state.imgSrc} height="100px" width="100px" />
                                                                                } */}

                                                                                {/* Static circular progress bar */}
                                                                                {this.state.isProccesingFileUpload &&
                                                                                    <li>
                                                                                        <div className="file-wrapper">
                                                                                            <div className="progress-file-icon">
                                                                                                <img src="assets/images/blur-inventory.jpg" alt="" />
                                                                                                <div className="inventory-progess-bar">
                                                                                                    <CircularProgressbar value={this.state.uploadPercentage} text={`${this.state.uploadPercentage}%`} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                }
                                                                                {/* Static circular progress bar */}

                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="control-label">Name your file <span className="text-danger">*</span></label>
                                                                            <input type="text" value={this.state.form_data.uploadFileName} name="uploadFileName" className="form-control" onChange={this.handleOnChange.bind(this)} required={true} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="control-label">Tell us a little more about it </label>
                                                                            <textarea className="form-control" value={this.state.form_data.uploadFileDesc} id="" rows="2" name="uploadFileDesc" onChange={this.handleOnChange.bind(this)} ></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="modal-footer">
                                                                {/* {this.state.isFileSaving ?
                                                                <button className="btn btn-primary btn-xs" disabled={true} >Processing...</button>
                                                                :
                                                                <button className="btn btn-primary btn-xs" >Save</button>
                                                            } */}
                                                                {this.state.isProccesingFileUpload ?
                                                                    <React.Fragment>
                                                                        <button className="btn btn-primary btn-xs" disabled={true}>Save</button>
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        {this.state.isFileSaving ?
                                                                            <button className="btn btn-primary btn-xs" disabled={true} >Processing...</button>
                                                                            :
                                                                            <button className="btn btn-primary btn-xs" >Save</button>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                            </div>

                                                        </div>
                                                    </form>

                                                </div>
                                            </div>}
                                        {/* FileUpload-Modal end */}


                                        {this.state.showFilesTab && this.state.military_order_form &&
                                            <div className="p-20">
                                                <div className="well">
                                                    <div className="file-manager horizontal-scroll">
                                                        <ul>{this.state.viewSaveFile &&
                                                            this.renderFiles()
                                                        }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        }

                                    </div>
                                    <div className="text-center">
                                        {
                                            this.state.loading
                                            &&
                                            <button type="button" className="btn btn-default btn-lg m-r-10" disabled={true}>Processing...</button>
                                        }
                                        {
                                            !this.state.loading
                                            &&
                                            // <button type="submit" className="btn btn-primary btn-lg m-r-10" name="save_military" id="btn-save" onClick={() => this.btnPress('save_military')}>Save &amp; Assign</button>
                                            <button type="submit" className="btn btn-primary btn-lg m-r-10" name="save_military" id="btn-save">Save &amp; Assign</button>
                                        }
                                    </div>
                                </form>
                            </div>  {/* end of military form */}

                            <div className="non_military_form" style={{ display: (this.state.non_military_order_form ? 'block' : 'none') }}>
                                <form method="POST" action="#" onSubmit={this.postOfferForNM.bind(this, 0)} autoComplete="off">
                                    {/* <input type="hidden" value={this.state.military_flag=0}></input> */}
                                    <div className="panel panel-flat profile-page b-t-0">
                                        <div className="panel-heading show-or">
                                            <h5 className="m-0"><Link to={{
                                                pathname: '/moving-company/dashboard/assign-offer',
                                                state: { backBtn: 1, driver_helper_mover: this.state.form_data.driver_helper_mover }
                                            }} className="text-primary">Click here</Link> to select an existing ReloOrders</h5>
                                        </div>
                                        <hr className="m-0" />
                                        <div className="panel-heading">
                                            <h5 className="m-0">Add New ReloOrder</h5>
                                        </div>
                                        <div className="panel-body">
                                            <div className="well bg-indigo-800">
                                                <h6 className="m-t-0">Customer Basic Info (hidden until order accepted)</h6>
                                                <p><a href="javascript:void(0)" onClick={(e) => this.existingDriverModal(e, 0)}
                                                    className="text-warning">Click here
                                                </a> to choose an existing customer or add a new customer below.</p>
                                                {/* <h5>RELO # BGAC0498401</h5> */}
                                                {/*<div className="row">
                                                     <div className="col-md-6">
                                            <div className="form-group">
                                                <label>SCAC </label>
                                                <input placeholder="SCAC" className="form-control" type="text"
                                                    value={this.state.form_data.scac} name="scac"
                                                    onChange={this.handleOnChange.bind(this)} />
                                            </div>
                                        </div> 
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Customer SSN</label> 
                                                            <span className="fa fa-eye" aria-hidden="true"></span>

                                                            <input placeholder="xxx xx xxxx" type="text" className="form-control"
                                                    value={this.state.form_data.ssn} name="ssn"
                                                    onChange={this.handleOnChange.bind(this)} maxLength="9"

                                                />


                                                            <span style={{ color: 'black', float: 'right', marginLeft: '-25px', marginTop: '-25px', marginRight: '10px', position: 'relative', zIndex: '2', cursor: 'pointer' }} className={`fa field-icon ${this.state.iconEye ? 'fa-eye' : 'fa-eye-slash'}`} onClick={this.togglePassword.bind(this)}></span>
                                                        </div>
                                                    </div>
                                                </div>*/}
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Customer First Name <span className="text-danger">*</span></label>
                                                            <input placeholder="Customer First Name" className="form-control" type="text"
                                                                value={this.state.form_data.customer_first_name} name="customer_first_name"
                                                                onChange={this.handleOnChange.bind(this)} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Customer Last Name <span className="text-danger">*</span></label>
                                                            <input placeholder="Customer Last Name" className="form-control" type="text"
                                                                value={this.state.form_data.customer_last_name} name="customer_last_name"
                                                                onChange={this.handleOnChange.bind(this)} required={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Primary Phone <span className="text-danger">*</span></label>
                                                            <input placeholder="xxx xxx xxxx" className="form-control" type="text"
                                                                name="contact_no" maxLength="12"
                                                                value={this.state.form_data.contact_no} onChange={this.handleOnChange.bind(this)} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Secondary Phone</label>
                                                            <input placeholder="xxx xxx xxxx" className="form-control" type="text"
                                                                name="home_contact_no" maxLength="12"
                                                                value={this.state.form_data.home_contact_no}
                                                                onChange={this.handleOnChange.bind(this)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Primary Email <span className="text-danger">*</span></label>
                                                            <input placeholder="Primary Email" className="form-control" type="text"
                                                                name="customer_email" value={this.state.form_data.customer_email}
                                                                onChange={this.handleOnChange.bind(this)} required={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label>Secondary Email</label>
                                                            <input placeholder="Secondary Email" className="form-control" type="text"
                                                                name="alternate_email" value={this.state.form_data.alternate_email}
                                                                onChange={this.handleOnChange.bind(this)} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <h6 className="text-success">Service Info</h6>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Customer Estimated Weight <span className="text-danger">*</span>
                                                            <a href="https://move.mil/resources/weight-estimator" target="_blank" title="Weight Estimator">
                                                                <i className="icon-info22 m-l-5"></i>
                                                            </a>
                                                        </label>
                                                        <input placeholder="Customer Estimated Weight" className="form-control"
                                                            type="text" name="customer_estimated_weight"
                                                            value={this.state.form_data.customer_estimated_weight}
                                                            onChange={this.handleOnChange.bind(this)} required={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Requested Pack Date <span className="text-danger">*</span></label>
                                                        <div className="input-group date">
                                                            <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                            <input type="text" className="form-control" placeholder="Select date"
                                                                name="nm_requested_pack_date" value={this.state.form_data.nm_requested_pack_date}
                                                                id="nm_requested_pack_date"
                                                                onChange={this.handleOnChange.bind(this)} required={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Requested Pickup Date <span className="text-danger">*</span></label>
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                            <input type="text" className="form-control" placeholder="Select date"
                                                                name="nm_request_start_date" value={this.state.form_data.nm_request_start_date || this.state.form_data.nm_requested_pack_date}
                                                                id="nm_request_start_date"
                                                                onChange={this.handleOnChange.bind(this)} required={true} />
                                                            {/* onClick={this.selectDatePicker('nm_request_start_date')} */}
                                                        </div>
                                                        <p className="text-danger help-block">{this.state.end_date_err}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="row">
                                                
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Requested Latest Pickup Date</label>
                                                        <div className="input-group date">
                                                            <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                            <input type="text" className="form-control"
                                                                name="nm_requested_latest_pickup_date" placeholder="Select date"
                                                                value={this.state.form_data.nm_requested_latest_pickup_date || this.state.form_data.nm_request_start_date}
                                                                id="nm_requested_latest_pickup_date"
                                                                onChange={this.handleOnChange.bind(this)} onClick={this.selectDatePicker('nm_requested_latest_pickup_date')} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Requested Delivery Date <span className="text-danger">*</span></label>
                                                        <div className="input-group date">
                                                            <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                            <input type="text" className="form-control" placeholder="Select date"
                                                                value={this.state.form_data.nm_request_end_date}
                                                                name="nm_request_end_date" id="nm_request_end_date"
                                                                onChange={this.handleOnChange.bind(this)} required={true} />
                                                            {/* onClick={this.selectDatePicker('nm_request_end_date')} */}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Required Delivery Date (System Generated)</label>
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                            <input type="text" className="form-control" placeholder="Select date"
                                                                // value={this.state.form_data.required_delivery_date}
                                                                value={Date().getFullYear}
                                                                name="required_delivery_date" id="required_delivery_date"
                                                                onChange={this.handleOnChange.bind(this)} disabled />
                                                        </div>
                                                        <p className="text-danger help-block">{this.state.end_date_err}</p>
                                                    </div>
                                                </div> */}
                                            </div>
                                            {/* <div className="row">
                                    <div className="col-md-2">
                                        <p>Select Address Mode</p>
                                    </div>
                                    <div className="col-md-2">                                        
                                        <input onChange={this.handleAddressOptionChange.bind(this)} className="form-check-input mt-2" type="radio" name="select_address_google" id="select_address_google" value="google" checked={this.state.select_address} onClick={this.radioHandler}/>&nbsp;
                                        <label className="form-check-label" for="select_address">Using Google Map</label>                                                                                                                            
                                    </div>
                                    <div className="col-md-2">
                                        <input onChange={this.handleAddressOptionChange.bind(this)} className="form-check-input mt-2" type="radio" name="select_address_google" id="select_address_manual" value="manual" checked={this.state.select_address == false} onClick={this.radioHandler} />&nbsp;
                                        <label className="form-check-label" for="select_address">Enter Manually</label>
                                    </div>
                                </div> */}

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Primary Pickup Origin Address <span className="text-danger">*</span></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        {/* <input type="checkbox" name="primary_pickup_address" onClick={this.handleManualAddress}></input>&nbsp;&nbsp; */}
                                                        {/* <label>Enter Manually</label> */}
                                                        {/* <textarea className="form-control"
                                                rows="2" name="source_address" id="source_address"
                                                placeholder="Enter Address"
                                                value={this.state.form_data.source_address}
                                                onChange={this.initAutocomplete}></textarea> */}
                                                        <input type="text" className="form-control" name="nm_source_address" id="nm_source_address" value={this.state.form_data.nm_source_address || ''} placeholder="Primary Pickup Origin Address" onChange={this.initAutocomplete} required={true} ></input>
                                                        {/*  */}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row" >

                                                <div className="col-md-3 col-sm-6">
                                                    <div className="form-group">
                                                        <label>City</label><span className="text-danger">*</span>
                                                        <input type="text" className="form-control" id="nm_primary_pickup_city" name="nm_primary_pickup_city" value={this.state.form_data.nm_primary_pickup_city} placeholder='' onChange={this.changeValue} required={!!this.state.form_data.nm_source_address ? true: false}></input>
                                                        {/* readOnly={this.state.primary_pickup_address_check} */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="form-group">
                                                        <label>State</label><span className="text-danger">*</span>
                                                        <input type="text" className="form-control" id="nm_primary_pickup_state" name="nm_primary_pickup_state" value={this.state.form_data.nm_primary_pickup_state || ''} placeholder='' onChange={this.changeValue} required={!!this.state.form_data.nm_source_address ? true: false}></input>
                                                        {/* readOnly={this.state.primary_pickup_address_check} */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="form-group">
                                                        <label>Zip Code</label><span className="text-danger">*</span>
                                                        <input type="text" className="form-control" id="nm_primary_pickup_zip" name="nm_primary_pickup_zip" value={this.state.form_data.nm_primary_pickup_zip || ''} placeholder='' onChange={this.changeValue} required={!!this.state.form_data.nm_source_address ? true: false}></input>
                                                        {/* readOnly={this.state.primary_pickup_address_check} */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="form-group">
                                                        <label>Country</label>
                                                        {/* <input type="text" className="form-control" id="nm_primary_pickup_country" name="nm_primary_pickup_country" value={this.state.form_data.nm_primary_pickup_country}  placeholder='' onChange={this.changeValue}></input> */}
                                                        {/* readOnly={this.state.primary_pickup_address_check} */}
                                                        <select className="form-control" id="nm_primary_pickup_country"
                                                            value={this.state.form_data.nm_primary_pickup_country}
                                                            name="nm_primary_pickup_country" placeholder='' onChange={this.changeValue}>
                                                            {countriesList}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Primary Destination Delivery Address <span className="text-danger">*</span></label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        {/* <input type="checkbox" name="primary_destination_address" onClick={this.handleManualAddress}></input>&nbsp;&nbsp; */}
                                                        {/* <label>Enter Manually</label> */}
                                                        {/* <textarea className="form-control"
                                                    rows="2" name="destination_address" id="destination_address"
                                                    placeholder="Enter Address"
                                                    value={this.state.form_data.destination_address}
                                                    onChange={this.initAutocompleteDestination}></textarea> */}
                                                        <input type="text" className="form-control" name="nm_destination_address" id="nm_destination_address" value={this.state.form_data.nm_destination_address || ''} placeholder="Primary Destination Delivery Address" onChange={this.initAutocomplete}></input>
                                                        {/*  */}
                                                    </div>
                                                    <p className="text-danger help-block">{this.state.end_addr_err}</p>
                                                </div>
                                            </div>


                                            <div className="row">

                                                <div className="col-md-3 col-sm-6">
                                                    <div className="form-group">
                                                        <label>City</label><span className="text-danger">*</span>
                                                        <input className="form-control" id="nm_primary_destination_city" name="nm_primary_destination_city" placeholder='' value={this.state.form_data.nm_primary_destination_city || ''} onChange={this.changeValue}></input>
                                                        {/* readOnly={this.state.primary_destination_address_check} */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="form-group">
                                                        <label>State</label><span className="text-danger">*</span>
                                                        <input className="form-control" id="nm_primary_destination_state" name="nm_primary_destination_state" placeholder='' value={this.state.form_data.nm_primary_destination_state || ''} onChange={this.changeValue}></input>
                                                        {/* readOnly={this.state.primary_destination_address_check} */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="form-group">
                                                        <label>Zip Code</label><span className="text-danger">*</span>
                                                        <input className="form-control" id="nm_primary_destination_zip" name="nm_primary_destination_zip" placeholder='' value={this.state.form_data.nm_primary_destination_zip || ''} onChange={this.changeValue}></input>
                                                        {/* readOnly={this.state.primary_destination_address_check} */}
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <div className="form-group">
                                                        <label>Country</label>
                                                        {/* <input type="text" className="form-control" id="nm_primary_destination_country" name="nm_primary_destination_country" value={this.state.form_data.nm_primary_destination_country}  placeholder='' onChange={this.changeValue}></input> */}
                                                        {/* readOnly={this.state.primary_pickup_address_check} */}
                                                        <select className="form-control" id="nm_primary_destination_country"
                                                            value={this.state.form_data.nm_primary_destination_country}
                                                            name="nm_primary_destination_country" placeholder='' onChange={this.changeValue}>
                                                            {countriesList}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Special Items</label>
                                                        <input className="form-control" type="text"
                                                            placeholder="Special Items"
                                                            value={this.state.form_data.special_items} name="special_items"
                                                            id="special_items" onChange={this.handleOnChange.bind(this)} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label># MyReloPro's Requested On Site</label>
                                                        <input className="form-control" type="number" min="0" value={this.state.form_data.total_manpower_needed} onChange={this.handleOnChange.bind(this)} name="total_manpower_needed" />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="m-r-15">Firearms <span className="text-danger">*</span></label>
                                                        <label className="radio-inline m-b-0 m-r-5"><input type="radio" name="firearms_details" value="1" onChange={this.handleFirearms} required={true} />Yes</label>
                                                        <label className="radio-inline m-b-0"><input type="radio" name="firearms_details" value="0" onChange={this.handleFirearms} />No</label>
                                                        <input className="form-control" type={this.state.form_data.firearms_details == 1 ? 'text' : 'hidden'}
                                                            placeholder="Please Enter Detailed Firearm Information"
                                                            value={this.state.form_data.firearms}
                                                            name="firearms" id="firearms" onChange={this.handleOnChange.bind(this)} required={true} />
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Vehicles</label>
                                            <input className="form-control" type="text"
                                                placeholder="Vehicles" value={this.state.form_data.vehicles}
                                                name="vehicles" id="vehicles" onChange={this.handleOnChange.bind(this)} />
                                        </div>
                                    </div> */}
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Agreed Compensation/ Price</label>
                                                        <input className="form-control" type="text"
                                                            placeholder="Agreed Compensation/ Price"
                                                            value={this.state.form_data.agreed_compensation_price} name="agreed_compensation_price"
                                                            id="compensation" onChange={this.handleOnChange.bind(this)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Remarks (Any special notes or concerns of the customer)</label>
                                                        <Editor
                                                            placeHolder="Enter Remarks"
                                                            initialValue=""
                                                            init={{
                                                                menubar: false,
                                                                statusbar: false,
                                                                toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                                            }}
                                                            onChange={this.handleEditorChange}
                                                            className="form-control textarea-editor"
                                                            name="remarks"
                                                            id='remarks'
                                                        />

                                                        {/*<textarea className="form-control" value={this.state.form_data.remarks}
                                                                                                                    rows="2" name="remarks" id="remarks"
                                                                                                                    placeholder="Enter Remarks"
                                                                                                                    onChange={this.handleOnChange.bind(this)}></textarea>*/}
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>Relocation Notes (Any special notes or concerns of the customer) <span className="text-danger">*</span></label>
                                                        <textarea className="form-control" rows="6" placeholder="Relocation Notes"
                                                            value={this.state.form_data.relocation_notes}
                                                            onChange={this.handleOnChange.bind(this)} name="relocation_notes" required={true} />
                                                    </div>
                                                </div> */}
                                            </div>
                                            <hr />

                                            <h6 className="text-success m-t-20">Additional Contact Information </h6>
                                            <div className="row">
                                                {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Service Branch</label>
                                            <input className="form-control" type="text"
                                                placeholder="Service Branch" value={this.state.form_data.service_branch}
                                                name="service_branch" id="service_branch" onChange={this.handleOnChange.bind(this)} />
                                        </div>
                                    </div> */}
                                                {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Shipment Type</label>
                                            <input className="form-control" type="text"
                                                placeholder="Shipment Type" value={this.state.form_data.shipment_type}
                                                name="shipment_type" id="shipment_type" onChange={this.handleOnChange.bind(this)} />
                                        </div>
                                    </div> */}
                                                {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Shipment Market</label>
                                            <input className="form-control" type="text"
                                                placeholder="Shipment Market" value={this.state.form_data.shipment_market}
                                                name="shipment_market" id="shipment_market" onChange={this.handleOnChange.bind(this)} />
                                        </div>
                                    </div> */}
                                            </div>
                                            <div className="row">
                                                {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Origin GBLOC</label>
                                            <input className="form-control" type="text"
                                                placeholder="Origin GBLOC" value={this.state.form_data.origin_gbloc}
                                                name="origin_gbloc" id="origin_gbloc" onChange={this.handleOnChange.bind(this)} />
                                        </div>
                                    </div> */}
                                                {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Destination GBLOC</label>
                                            <input className="form-control" type="text"
                                                placeholder="Destination GBLOC" value={this.state.form_data.destination_gbloc}
                                                name="destination_gbloc" id="destination_gbloc" onChange={this.handleOnChange.bind(this)} />
                                        </div>
                                    </div> */}
                                                {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Channel</label>
                                            <input className="form-control" type="text"
                                                placeholder="Channel" value={this.state.form_data.channel}
                                                name="channel" id="channel" onChange={this.handleOnChange.bind(this)} />
                                        </div>
                                    </div> */}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Releasing Agent (Alt. Point of Contact) <span className="text-danger">*</span></label>
                                                        <input className="form-control" type="text"
                                                            placeholder="Releasing Agent" value={this.state.form_data.releasing_agent}
                                                            name="releasing_agent" id="releasing_agent" onChange={this.handleOnChange.bind(this)} required={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Releasing Agent Phone <span className="text-danger">*</span></label>
                                                        <input placeholder="xxx-xxx-xxxx" className="form-control" type="text"
                                                            maxLength="12"
                                                            value={this.state.form_data.releasing_agent_phone}
                                                            name="releasing_agent_phone"
                                                            onChange={this.handleOnChange.bind(this)} required={true} />
                                                    </div>

                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Releasing Agent Email Address <span className="text-danger">*</span></label>
                                                        <input className="form-control" type="text"
                                                            placeholder="Email Address"
                                                            value={this.state.form_data.releasing_agent_email}
                                                            name="releasing_agent_email" id="releasing_agent_email"
                                                            onChange={this.handleOnChange.bind(this)} required={true} />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Receiving Agent (Alt. Point of Contact)<span className="text-danger">*</span></label>
                                                        <input className="form-control" type="text"
                                                            placeholder="Receiving Agent" value={this.state.form_data.receiving_agent}
                                                            name="receiving_agent" id="receiving_agent" onChange={this.handleOnChange.bind(this)} required={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Receiving Agent Phone <span className="text-danger">*</span></label>
                                                        <input placeholder="xxx-xxx-xxxx" className="form-control" type="text"
                                                            maxLength="12"
                                                            value={this.state.form_data.receiving_agent_phone}
                                                            name="receiving_agent_phone"
                                                            onChange={this.handleOnChange.bind(this)} required={true} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Receiving Agent Email Address <span className="text-danger">*</span></label>
                                                        <input className="form-control" type="text"
                                                            placeholder="Email Address"
                                                            value={this.state.form_data.receiving_agent_email}
                                                            name="receiving_agent_email" id="receiving_agent_email"
                                                            onChange={this.handleOnChange.bind(this)} required={true} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group m-b-0">
                                                        <label>Services Needed <span className="text-danger">*</span></label>
                                                        <ul className="checkbox-list m-b-0">
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Pre-Move Survey"
                                                                        checked={this.state.form_data.services_needed.indexOf('Pre-Move Survey') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Pre-Move Survey
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Packing"
                                                                        checked={this.state.form_data.services_needed.indexOf('Packing') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Packing
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Loading"
                                                                        checked={this.state.form_data.services_needed.indexOf('Loading') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Loading
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Transport/ Driving"
                                                                        checked={this.state.form_data.services_needed.indexOf('Transport/ Driving') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Transport/ Driving
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Unloading"
                                                                        checked={this.state.form_data.services_needed.indexOf('Unloading') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Unloading
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Unpacking"
                                                                        checked={this.state.form_data.services_needed.indexOf('Unpacking') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Unpacking
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Debris Removal"
                                                                        checked={this.state.form_data.services_needed.indexOf('Debris Removal') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Debris Removal
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Third Party Service (Other)"
                                                                        checked={this.state.form_data.services_needed.indexOf('Third Party Service (Other)') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Third Party Service (Other)
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Crating"
                                                                        checked={this.state.form_data.services_needed.indexOf('Crating') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Crating
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Uncrating"
                                                                        checked={this.state.form_data.services_needed.indexOf('Uncrating') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Uncrating
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Appliance Service"
                                                                        checked={this.state.form_data.services_needed.indexOf('Appliance Service') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Appliance Service
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Disassembly-Setup"
                                                                        checked={this.state.form_data.services_needed.indexOf('Disassembly-Setup') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Disassembly-Setup
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Clock Service"
                                                                        checked={this.state.form_data.services_needed.indexOf('Clock Service') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Clock Service
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Maid Service"
                                                                        checked={this.state.form_data.services_needed.indexOf('Maid Service') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Maid Service
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Hoisting Service"
                                                                        checked={this.state.form_data.services_needed.indexOf('Hoisting Service') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Hoisting Service
                                                                </label>
                                                            </li>
                                                            {/* <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Short Hauling (500 Miles)"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'Short Hauling (500 Miles)') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Short Hauling (500 Miles)
                                                        </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Long Hauling (500+ MIles)"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'Long Hauling (500+ MIles)') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Long Hauling (500+ MIles)
                                                        </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Freight Hauling"
                                                                        checked={this.state.form_data.services_needed.indexOf('Freight Hauling') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Freight Hauling
                                                        </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Storage"
                                                                        checked={this.state.form_data.services_needed.indexOf('Storage') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span><span className=""></span>Storage
                                                        </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Intermodal"
                                                                        checked={this.state.form_data.services_needed.indexOf('Intermodal') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Intermodal
                                                        </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Day Road Labor"
                                                                        checked={this.state.form_data.services_needed.indexOf('Day Road Labor') > -1}
                                                                        onChange={this.setServices.bind(this)} /><span className="">
                                                                    </span>Day Road Labor
                                                            </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="SIT Approved"
                                                                        checked={this.state.form_data.services_needed.indexOf('SIT Approved') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>SIT Approved
                                                        </label>
                                                            </li> */}
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed"
                                                                        value="International Origin Service"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'International Origin Service') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>International Origin Service
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed"
                                                                        value="International Destination Service"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'International Destination Service') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>International Destination Service
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed"
                                                                        value="Claim Damage Inspection"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'Claim Damage Inspection') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Claim Damage Inspection
                                                                </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed"
                                                                        value="Claim Damage Repair"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'Claim Damage Repair') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Claim Damage Repair
                                                                </label>
                                                            </li>
                                                            {/* <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Last Mile Delivery"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'Last Mile Delivery') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Last Mile Delivery
                                                            </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed"
                                                                        value="Freight Warehouse & Handling"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'Freight Warehouse & Handling') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Freight Warehouse &amp; Handling
                                                            </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Third Party Service"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'Third Party Service') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Third Party Service
                                                            </label>
                                                            </li>
                                                            <li >
                                                                <label className="checkbox-inline ">
                                                                    <input type="checkbox" name="services_needed" value="Unpack"
                                                                        checked={this.state.form_data.services_needed.indexOf(
                                                                            'Unpack') > -1}
                                                                        onChange={this.setServices.bind(this)} />
                                                                    <span className=""></span>Unpack
                                                            </label>
                                                            </li> */}
                                                        </ul>
                                                    </div>
                                                    <p className="text-danger help-block">{this.state.service_err}</p>
                                                </div>


                                                {/* <div className="col-md-12">
                                        <div className="form-group">
                                            <span style={{ "vertical-align": "text-bottom" }}> Will Storage Be Needed? </span>
                                            <span style={{ "vertical-align": "text-bottom" }} className="m-l-10"> No </span>
                                            <label className="checkbox-inline checkbox-switch switch-danger">
                                                <input type="checkbox" name="storage_needed" value="1"
                                                    id="social_media_status" checked={this.state.form_data.storage_needed}
                                                    onChange={this.setStorage.bind(this)} />
                                                <span className="m-l-25 m-t-5"></span>
                                            </label>
                                            <span style={{ "vertical-align": "text-bottom" }}>Yes</span>
                                        </div>
                                    </div> */}
                                            </div>
                                            <hr />

                                            {/* <h6 className="text-success m-t-20">Additional Locations</h6> */}
                                            {/* <div className="row">
                                    <div className="col-md-6">

                                        <div className="form-group">
                                            <label>Authorized Pickup</label>
                                            <textarea className="form-control"
                                                rows="2" name="authorized_pickup" id="authorized_pickup"
                                                placeholder="Authorized Pickup"
                                                value={this.state.form_data.authorized_pickup}
                                                onChange={this.authorizedpicinitAutocomplete.bind(this)}></textarea>
                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">  
                                            <label>In Transit</label>
                                                <textarea className="form-control"
                                                rows="2" name="in_transit" id="in_transit"
                                                placeholder="In Transit"
                                                value={this.state.form_data.in_transit}
                                                onChange={this.transitinitAutocomplete.bind(this)}></textarea>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Authorized Delivery</label>
                                            
                                                <textarea className="form-control"
                                                rows="2" name="authorized_delivery" id="authorized_delivery"
                                                placeholder="Authorized Delivery"
                                                value={this.state.form_data.authorized_delivery}
                                                onChange={this.authorizeddeliverypicinitAutocomplete.bind(this)}></textarea>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>TCN Number</label>
                                            <input className="form-control" type="text" placeholder="TCN Number"
                                                value={this.state.form_data.tcn_number}
                                                name="tcn_number" id="tcn_number"
                                                onChange={this.handleOnChange.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Point Of Embark</label>
                                            <input className="form-control" type="text" placeholder="Point Of Embark"
                                                value={this.state.form_data.port_of_embark}
                                                name="port_of_embark" id="port_of_embark"
                                                onChange={this.handleOnChange.bind(this)} />
                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Point Of Debark</label>
                                            <input className="form-control" type="text" placeholder="Point Of Debark"
                                                value={this.state.form_data.port_of_debark}
                                                name="port_of_debark" id="port_of_debark"
                                                onChange={this.handleOnChange.bind(this)} />
                                        </div>
                                    </div>



                                </div>
                                <hr /> */}
                                            <h6 className="text-success m-t-20">Driver Info (hidden until order accepted)</h6>
                                            <p><a href="javascript:void(0)"
                                                onClick={(e) => this.existingDriverModal(e, 1)}>Click here
                                            </a> to choose an existing driver or add a new driver below.</p>
                                            {this.renderDriverSection()}
                                            <div className="text-right block">
                                                <a href="javascript:void(0)" className="btn btn-primary"
                                                    onClick={this.addMoreDriver}><i className="icon-plus22"></i> Add Driver</a>
                                            </div>
                                        </div>

                                    </div>
                                    {/* <h4 className="pull-left">Files</h4> */}


                                    <button className="btn btn-success btn-xs pull-right m-b-20" type="button" data-toggle="modal" data-target="#FileUpload-Modal" onClick={this.openNonMilitaryFileModal}><i className="icon-file-plus"></i> &nbsp; Add File</button>
                                    <div className="clearfix"></div>
                                    <div className="panel panel-flat profile-page">
                                        <div className="panel-heading p-0">
                                            <ul className="nav nav-tabs nav-tabs-bottom nav-horizantal-scroll m-t-0">
                                                <li className="inline-block active">
                                                    <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setFileGroup.bind(this, 1)}>
                                                        <p className="text-uppercase m-0 m-l-5 m-r-5">Video &amp; Image Files</p>
                                                    </a>
                                                </li>

                                                <li className="inline-block">
                                                    <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setFileGroup.bind(this, 3)}>
                                                        <p className="text-uppercase m-0 m-l-5 m-r-5">Other Files</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* FileUpload-Modal start */}
                                        {this.state.non_military_order_form &&
                                            <div className="modal fade" id="FileUploadNonMily-Modal" role="dialog">
                                                {/* <div className="modal fade" id="FileUpload-Modal" role="dialog"> */}

                                                <div className="modal-dialog">
                                                    <form onSubmit={this.fileUpload.bind(this)}>
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeFile}><span aria-hidden="true">&times;</span></button>
                                                                <h4 className="modal-title" style={{ color: "black" }}>Upload File & Details</h4>
                                                            </div>
                                                            <hr />
                                                            <div className="modal-body p-t-0 p-b-0">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            {this.state.isProccesingFileUpload ?
                                                                                <label className="btn btn-upload-file btn-xs">Processing...</label>
                                                                                :
                                                                                <label className="btn btn-upload-file btn-xs">
                                                                                    <input type="file" name="files[]" onChange={this.uploadFiles.bind(this)} /><i className="icon-file-plus2 f-s-22"></i> &nbsp; Add File
                                                                                    {/* <input type="file" name="files[]" onChange={this.previewFiles.bind(this)} /><i className="icon-file-plus2 f-s-22"></i> &nbsp; Add File */}
                                                                                </label>
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="file-manager horizontal-scroll">
                                                                            <ul>
                                                                                {this.renderFilesModal()}
                                                                                {/* {this.state.filePreview &&
<img src={this.state.imgSrc} height="100px" width="100px" />
} */}

                                                                                {/* Static circular progress bar */}
                                                                                {this.state.isProccesingFileUpload &&
                                                                                    <li>
                                                                                        <div className="file-wrapper">
                                                                                            <div className="progress-file-icon">
                                                                                                <img src="assets/images/blur-inventory.jpg" alt="" />
                                                                                                <div className="inventory-progess-bar">
                                                                                                    <CircularProgressbar value={this.state.uploadPercentage} text={`${this.state.uploadPercentage}%`} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                }
                                                                                {/* Static circular progress bar */}

                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="control-label">Name your file <span className="text-danger">*</span></label>
                                                                            <input type="text" name="uploadFileName" value={this.state.form_data.uploadFileName} className="form-control" onChange={this.handleOnChange.bind(this)} required={true} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="control-label">Tell us a little more about it </label>
                                                                            <textarea className="form-control" value={this.state.form_data.uploadFileDesc} id="" rows="2" name="uploadFileDesc" onChange={this.handleOnChange.bind(this)} ></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div className="modal-footer">
                                                                {/* <button className="btn btn-primary btn-xs" >Save</button> */}
                                                                {this.state.isProccesingFileUpload ?
                                                                    <React.Fragment>
                                                                        <button className="btn btn-primary btn-xs" disabled={true}>Save</button>
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        {this.state.isFileSaving ?
                                                                            <button className="btn btn-primary btn-xs" disabled={true} >Processing...</button>
                                                                            :
                                                                            <button className="btn btn-primary btn-xs" >Save</button>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>}
                                        {/* FileUpload-Modal end */}

                                        {this.state.showFilesTab && this.state.non_military_order_form &&
                                            <div className="p-20">
                                                <div className="well">
                                                    <div className="file-manager horizontal-scroll">
                                                        <ul>{this.state.viewSaveFile &&
                                                            this.renderFiles()
                                                        }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        }


                                        {/* <div className="panel-body">
                                            <div className="tabbable hori-scroll">
                                                <div className="file-manager">
                                                    <ul>
                                                        {this.renderFiles()}
                                                    </ul>
                                                </div>

                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="text-center">
                                        {
                                            this.state.loading
                                            &&
                                            <button type="button" className="btn btn-default btn-lg m-r-10" disabled={true}>Processing...</button>
                                        }
                                        {
                                            !this.state.loading
                                            &&
                                            // <button type="submit" className="btn btn-primary btn-lg m-r-10" name="save_non_military" id="btn-save" onClick={() => this.btnPress('save_non_military')}>Save &amp; Assign</button>
                                            <button type="submit" className="btn btn-primary btn-lg m-r-10" name="save_non_military" id="btn-save" >Save &amp; Assign</button>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="addExistingDriverModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title" id="myModalLabel">Search Existing Driver</h4>
                            </div>
                            <div className="modal-body">
                                <div className="well">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group m-b-0">
                                                <label className="control-label">Driver Name</label>
                                                <input type="text" name="name" className="form-control" onChange={this.handleOnChangeSearchDriver} />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3">
                                            <div className="form-group m-b-0">
                                                <label className="control-label">Driver Mobile Number</label>
                                                <input type="text" name="contact_no" className="form-control" onChange={this.handleOnChangeSearchDriver} />
                                            </div>
                                        </div> */}
                                        {/* <div className="col-md-3">
                                            <div className="form-group m-b-0">
                                                <label className="control-label">Driver Email Address</label>
                                                <input type="text" name="email" className="form-control" onChange={this.handleOnChangeSearchDriver} />
                                            </div>
                                        </div> */}
                                        <div className="col-md-2">
                                            <label className="control-label">&nbsp;</label>
                                            <button className="btn btn-primary btn-block" onClick={this.searchDriver}>Search</button>
                                        </div>
                                    </div>
                                </div>

                                <p className="p-t-10 text-primary"><b>Search Result</b></p>

                                <div className="table-responsive well p-0">
                                    {
                                        this.state.search_loading
                                        &&
                                        <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                                    }
                                    {
                                        !this.state.search_loading
                                        &&
                                        this.state.search_driver_list.length !== 0
                                        &&
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Driver Name</th>
                                                    {/* <th>Driver Mobile</th>
                                                    <th>Driver Email</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.search_driver_list.map((d_list, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td><label className="checkbox-inline"><input type="checkbox" onChange={this.chkDrivers} value={d_list.user_id} checked={this.state.chk_drivers.indexOf(d_list.user_id) > -1} /><span className=""></span>{d_list.gender} {d_list.first_name} {d_list.last_name}</label></td>
                                                                {/* <td>{d_list.contact_no}</td>
                                                                <td>{d_list.email}</td> */}
                                                            </tr>
                                                        )
                                                    }
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    }
                                    {
                                        !this.state.search_loading
                                        &&
                                        this.state.search_driver_list.length === 0
                                        &&
                                        <div className="alert alert-primary border-none text-center">No Driver Found.</div>
                                    }
                                </div>
                            </div>
                            {
                                !this.state.search_loading
                                &&
                                this.state.search_driver_list.length !== 0
                                &&
                                <div className="modal-footer">
                                    <button className="btn btn-success" onClick={this.saveChkDrivers}>Save</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="addExistingCustomerModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title" id="myModalLabel">Search Existing Customer</h4>
                            </div>
                            <div className="modal-body">
                                <div className="well">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group m-b-0">
                                                <label className="control-label">Customer Name</label>
                                                <input type="text" name="name" className="form-control" onChange={this.handleOnChangeSearchCustomer} />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3">
                                            <div className="form-group m-b-0">
                                                <label className="control-label">Customer Mobile Number</label>
                                                <input type="text" name="contact_no" className="form-control" onChange={this.handleOnChangeSearchCustomer} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group m-b-0">
                                                <label className="control-label">Customer Email Address</label>
                                                <input type="text" name="email" className="form-control" onChange={this.handleOnChangeSearchCustomer} />
                                            </div>
                                        </div> */}
                                        <div className="col-md-2">
                                            <label className="control-label">&nbsp;</label>
                                            <button className="btn btn-primary btn-block" onClick={this.searchCustomer}>Search</button>
                                        </div>
                                    </div>
                                </div>

                                <p className="p-t-10 text-primary"><b>Search Result</b></p>

                                <div className="table-responsive well p-0">
                                    {
                                        this.state.search_loading
                                        &&
                                        <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                                    }
                                    {
                                        !this.state.search_loading
                                        &&
                                        this.state.search_customer_list.length !== 0
                                        &&
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Customer Name</th>
                                                    {/* <th>Customer Mobile</th>
                                                    <th>Customer Email</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.search_customer_list.map((d_list, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td><label className="checkbox-inline"><input type="radio" name="customer" onChange={this.chkCustomers} value={d_list.user_id} checked={this.state.chk_customers.indexOf(d_list.user_id) > -1} /><span className=""></span>&nbsp;{d_list.gender} {d_list.first_name} {d_list.last_name}</label></td>
                                                                {/* <td>{d_list.contact_no}</td>
                                                                <td>{d_list.email}</td> */}
                                                            </tr>
                                                        )
                                                    }
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    }
                                    {
                                        !this.state.search_loading
                                        &&
                                        this.state.search_customer_list.length === 0
                                        &&
                                        <div className="alert alert-primary border-none text-center">No Customer Found.</div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AssignOfferJob;