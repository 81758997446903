import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataWithParam from '../services/PostDataWithParam';
import GetData from '../services/GetData';
import ConfirmBox from '../externaljs/ConfirmBox';

const initial_form_data =  {
    id:'',
    salutation:'',
    first_name: '',
    last_name: '',
    gender:'',
    email:'',
    alternate_email:'',
    contact_no: '',
    alternate_contact_no: '',
    work_contact_no:'',
    address:'',
    dob:'',
    ssn:'',
    ssn_val:'',
    custom_profile_id:'',
    latitude:'',
    longitude: ''

}

// const initial_field_data=  {
//     id:'',
//     associated_with_profile_id:'',
//     title:'',
//     profile_id:'',
//     status:'',
//     menu:[],
//     created_by:''
// }

class ManageProfile extends Component {

    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Add Team Member', link: '', class: 'active' }
        ],
        order_list: [],
        loading: false,
        selCustomFilter: '',
        order_type: '',
        //new added
        profile_menu: [],
        profile_menu_err: '',
        menu:[],
        form_data : {
            ...initial_form_data
        },

        error: {
            first_name_err: '',
            last_name_err: '',
            email_err: '',
            contact_no_err: '',
            alternate_contact_no_err:'',
            salutation_err: '',
            dob_err: '',
            ssn_err: '',
            dept_id_err: '',
            address_err:'',
            alternate_email_err:'',
            work_contact_no_err:'',
            home_contact_no_err: '',
        },
        passWordType: true,
        iconEye: true,
        custom_profile:[],

        tmp_checked_menu:[],
        changeUpdateStatus: false,
        UpdateErr: '',

    }

    componentDidMount() {
        this.initDatePicker('dob');
        this.getCustomProfile();
        
    }

    getCustomProfile(){
        PostDataWithParam('ViewCustomProfileForGMA',{profile_id:2})
        .then((response)=>{
            if(response.success == 1){
                // console.log('success')
                this.setState({ custom_profile : response.data }, ()=>{
                    // console.log(this.state.custom_profile)
                });
            }
            else{

            }

        })
        .catch(err=>{
            // window.swal('Error!', 'Something went wrong', 'error');
        })
    }

    

    initDatePicker=(name)=>{
        window.$('.input-group.date').daterangepicker({
            showDropdowns: true,
            autoclose: true,
            singleDatePicker: true
        }).on('apply.daterangepicker', (ev, picker) => {
            let form_data = this.state.form_data;
            form_data.dob = picker.startDate.format('MM/DD/YYYY');
            this.setState({ form_data: form_data });
        });
        window.$('.select').select2({
            minimumResultsForSearch: Infinity
        });
    }

    // handleOnChange=(e)=>{
    //     let form_data = Object.assign({},this.state.form_data)
    //     form_data[e.target.name]=e.target.value
    //     this.setState({ form_data: form_data });
    // }

    

    handleOnChange=(e)=> {
        // debugger;
        let form_data = Object.assign({}, this.state.form_data);
        form_data[e.target.name] = e.target.value;
        if (e.target.name == 'contact_no'
            || e.target.name == 'alternate_contact_no'
            ) {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                form_data[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
        }
        if (e.target.name == 'ssn_val') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/)
            if (match) {
                form_data['ssn'] = match[1] + ' ' + match[2] + ' ' + match[3]
                form_data[e.target.name] = 'XXX' + '-' + 'XX' + '-' + match[3]
            }
        }
        if(e.target.name=="salutation"){
            if(e.target.value=="Mr."){
                form_data['gender']="Mr."
            }
            else if(e.target.value=="Ms."){
                form_data['gender']="Ms."
            }
            else{
                form_data['gender']="Mrs."
            }
        }

        this.setState({ form_data: form_data }, ()=>{
            console.log(this.state.form_data)
        });

    }

    createProfile=(e)=>{
        let form_data = {...this.state.form_data};
        // console.log(form_data);
        // return;
        PostDataWithParam('AddStaffsNew', form_data)
        .then((response) => {
            let result = response;

            if (result.success === 1) {
                
                window.swal('Success!', 'Custom Staff Created Successfully', 'success');

            } else {

                window.swal('Error!', response.msg, 'error');
            }
        })
        .catch(err => {
            window.swal('Error!', 'Something went wrong', 'error');
        })
    }

    // updateProfile=(e)=>{
    //     let form_data = {...this.state.form_data};
    //     form_data.menu=JSON.stringify(this.state.menu.map(item=>({id:item.menu_id, is_add:item.is_add, is_edit:item.is_edit, is_delete:item.is_delete})));
    //     // console.log(form_data);
    //     // return;
    //     PostDataWithParam('CreateCustomProfileForGMA', form_data)
    //     .then((response) => {
    //         let result = response;

    //         if (result.success === 1) {
    //             this.removeDataTable();
    //             this.GetProfileData();
    //             window.swal('Success!', 'Profile Updated Successfully', 'success');

    //         } else {
                
    //             window.swal('Error!', response.msg, 'error');
    //         }
    //     })
    //     .catch(err => {
    //         window.swal('Error!', 'Something went wrong', 'error');
    //     })
    // }

    initAutocomplete = (e) => {
        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: ['geocode'] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let form_data = this.state.form_data;
            form_data.latitude = place.geometry.location.lat();
            form_data.longitude = place.geometry.location.lng();
            form_data.address = window.$('#address')[0].value;
            this.setState({ form_data: form_data });
        })
    }

    togglePassword() {
        let form_data = Object.assign({}, this.state.form_data);
        if (this.state.passWordType == true) {
            this.setState({ passWordType: false });
            this.setState({ iconEye: false });
            let swap = form_data['ssn'] // masked value
            form_data['ssn'] = form_data['ssn_val']
            form_data['ssn_val'] = swap  // masked value    
        } else {
            this.setState({ passWordType: true });
            this.setState({ iconEye: true });
            let swapssn = form_data['ssn']
            form_data['ssn'] = form_data['ssn_val']  // masked value          
            form_data['ssn_val'] = swapssn
        }
        this.setState({ form_data: form_data });
    }

    render() {

        const { custom_profile } = this.state;

        let renderCustomProfile = custom_profile.length > 0
            && custom_profile.map((profile, i) => {
                return (
                    <option key={i} value={profile.id}>{profile.title}</option>
                )
            }, this);

        return (
            <React.Fragment>
                <div className="content-container">
                    <div className="row">
                        <div className="col-md-12">
                            {Breadcumb(this.state.breadcrumb)}
                            <div className="panel panel-flat">
                                <div className="panel-heading">
                                    <h5 className="panel-title">Add Member Details</h5>
                                </div>

                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                            <label>Salutation <span className="text-danger">*</span> </label>
                                                <select className="form-control" name="salutation" onChange={this.handleOnChange} value={this.state.form_data.salutation} required={true}>
                                                    <option value="">Select</option>
                                                    <option value="Mr.">Mr.</option>
                                                    <option value="Ms.">Ms.</option>
                                                    <option value="Mrs.">Mrs.</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                            <label>First Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter First Name" name= "first_name" value={this.state.form_data.first_name} onChange={this.handleOnChange} required={true}/>
                                            <p className="text-danger help-block">{this.state.error.first_name_err}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                            <label>Last Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter Last Name" name= "last_name" value={this.state.form_data.last_name} onChange={this.handleOnChange} required={true}/>
                                            <p className="text-danger help-block">{this.state.error.last_name_err}</p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Email ID <span className="text-danger">*</span></label>
                                            <input className="form-control" type="email" placeholder="Enter Email Address" name= "email" value={this.state.form_data.email} onChange={this.handleOnChange} required={true}/>
                                            <p className="text-danger help-block">{this.state.error.email_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Phone Number <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="XXX-XXX-XXXX" name= "contact_no" value={this.state.form_data.contact_no} onChange={this.handleOnChange} maxLength="12" required={true}/>
                                            <p className="text-danger help-block">{this.state.error.contact_no_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Alternate Phone Number <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="XXX-XXX-XXXX" name= "alternate_contact_no" value={this.state.form_data.alternate_contact_no} onChange={this.handleOnChange} maxLength="12" required={true}/>
                                            <p className="text-danger help-block">{this.state.error.alternate_contact_no_err}</p>
                                        </div>
                                    </div>
                                    </div>

                                    <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Address</label>
                                            <input className="form-control" placeholder="Enter Address" type="text" id="address" onFocus={this.initAutocomplete} name="address" value={this.state.form_data.address || ''} onChange={this.handleOnChange}/>
                                            <p className="text-danger help-block">{this.state.error.address_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Date of Birth</label>
                                            <div className="input-group date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control" placeholder="" name= "dob" id="dob" value={this.state.form_data.dob || ''} onChange={this.handleOnChange} />
                                                <p className="text-danger help-block">{this.state.error.dob_err}</p>
                                            </div>
                                        </div>
                                    </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>SSN </label>
                                                {/* <span className="fa fa-eye" aria-hidden="true"></span>  */}

                                                <input placeholder="XXX-XX-XXXX" type="text" className="form-control"
                                                    value={this.state.form_data.ssn_val} name="ssn_val"
                                                    onChange={this.handleOnChange} maxLength="9"
                                                    
                                                />
                                                <span style={{ color: 'black', float: 'right', marginLeft: '-25px', marginTop: '-25px', marginRight: '10px', position: 'relative', zIndex: '2', cursor: 'pointer' }} className={`fa field-icon ${this.state.iconEye ? 'fa-eye' : 'fa-eye-slash'}`} onClick={this.togglePassword.bind(this)}></span>
                                            </div>
                                        </div>
                                    
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                        <div className="form-group">    
                                            <label> <h4>Profile</h4> </label>
                                        </div>
                                        </div>
                                    </div>
                                            <hr></hr>
                                            <div className="row">
                                            <div className="col-md-6">
                                            <div className="form-group">
                                            <label>Select Profile<span className="text-danger">*</span></label>
                                                <select className="form-control" name="custom_profile_id" onChange={this.handleOnChange} value={this.state.form_data.custom_profile_id} required={true}>
                                                    <option value="">Select Profile</option>
                                                    {renderCustomProfile}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pull-right">
                                            <button type="submit" className="btn btn-primary btn-lg m-r-10" name="save_military" id="btn-save" onClick={this.createProfile} >Save</button>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
                
            </React.Fragment>
        );
    }
}

export default ManageProfile;

