import React, { Component } from 'react';
import GetData from '../services/GetData';
import Breadcumb from '../externaljs/Breadcrumb';
class CssDashboard extends Component {
    state = {
      breadcrumb: [
         { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
         { title: 'Customer satisfaction survey', link: '/independent-mover/dashboard/manage-question', class: '' },
         { title: 'Dashboard', link: '', class: 'active' }
     ],
     serveyDetails:[],
     loading:false
    }

    componentDidMount() {
   this.getSurveyGraph();
    }
    getSurveyGraph=()=>{
      this.setState({ loading: true }, () => {
      GetData('GetSurveyGraph')
      .then(response=>{
         if(response.success){
            this.setState({serveyDetails:response.data,loading:false})
         } else {                    
            this.setState({ serveyDetails:[], loading: false });
        }
      })
      .catch(err=>{this.setState({ serveyDetails:[], loading: false });})
   })
    }
    render() {
        return (
            <div>
                     {Breadcumb(this.state.breadcrumb)}
                    {
                        this.state.loading
                        &&
                        <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                     }{
                        !this.state.loading
                        &&
                        this.state.serveyDetails.length !== 0
                        &&
                        this.state.serveyDetails.map((servey,i)=>{
                           let total=servey.Excellent+servey.Good+servey.Poor+servey.Satisfactory+servey.Unsatisfactory;
                           let excellentPercent=servey.Excellent!=0 ?servey.Excellent/total*100:0
                           let goodPercent=servey.Good!=0 ?servey.Good/total*100:0
                           let satisfactoryPercent=servey.Satisfactory!=0 ?servey.Satisfactory/total*100:0
                           let poorPercent=servey.Poor!=0 ?servey.Poor/total*100:0
                           let unsatisfactoryPercent=servey.Unsatisfactory!=0 ?servey.Unsatisfactory/total*100:0
                          
                           return(<React.Fragment key={'servey-' + i}>
                           <div className="panel panel-default">
                           <div className="panel-heading">
                           <h4 className="panel-title">{servey.question}</h4>
                        </div>
                        <div  className="panel-body">
                           <div className="row m-b-15">
                              <div className="col-md-6">
                                 <div className="viewReport-total">
                                    <div className="total-percentage">
                                       <h1 className="f-s-35 m-t-0">{total}</h1>
                                       <span className="text-muted">Total Surveys</span> 
                                    </div>
                                    <div className="total-individual">
                                       <div className="clearfix m-b-10">
                                          <span className="left-label">Excellent</span>
                                          <div className="progress center-label">
                                             <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{width:excellentPercent+'%'}}>
                                                <span className="sr-only">{excellentPercent}% Complete</span>
                                             </div>
                                          </div>
                                          <small className="text-muted right-label">{servey.Excellent}</small>
                                       </div>
                                       <div className="clearfix m-b-10">
                                          <span className="left-label">Good</span>
                                          <div className="progress center-label">
                                             <div className="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:goodPercent+'%'}}>
                                                <span className="sr-only">{goodPercent}% Complete</span>
                                             </div>
                                          </div>
                                          <small className="text-muted right-label">{servey.Good}</small>
                                       </div>
                                       <div className="clearfix m-b-10">
                                          <span className="left-label">Satisfactory</span>
                                          <div className="progress center-label">
                                             <div className="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:satisfactoryPercent+'%'}}>
                                                <span className="sr-only">{satisfactoryPercent}% Complete</span>
                                             </div>
                                          </div>
                                          <small className="text-muted right-label">{servey.Satisfactory}</small>
                                       </div>
                                       <div className="clearfix m-b-10">
                                          <span className="left-label">Poor</span>
                                          <div className="progress center-label">
                                             <div className="progress-bar" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100" style={{width:poorPercent+'%'}}>
                                                <span className="sr-only">{poorPercent}% Complete</span>
                                             </div>
                                          </div>
                                          <small className="text-muted right-label">{servey.Poor}</small>
                                       </div>
                                       <div className="clearfix m-b-10">
                                          <span className="left-label">Un-satisfactory</span>
                                          <div className="progress center-label">
                                             <div className="progress-bar" role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style={{width:unsatisfactoryPercent+'%'}}>
                                                <span className="sr-only">{unsatisfactoryPercent}% Complete</span>
                                             </div>
                                          </div>
                                          <small className="text-muted right-label">{servey.Unsatisfactory}</small>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        </div>
                      </React.Fragment>)
                        })
                     }
                     {
                            !this.state.loading
                            &&
                            this.state.serveyDetails.length === 0
                            &&
                            <div className="alert alert-primary border-none text-center">No Survey Found.</div>
                     }
            </div> 
        );
    }
}

export default CssDashboard;