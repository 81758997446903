import React, { Component, Fragment } from 'react'
import { CircularProgressbar } from 'react-circular-progressbar';
import DateFormat from '../externaljs/DateFormat';
import { FileIcon, FileSvg, FileType } from '../externaljs/FileType';
import Storage from '../externaljs/Storage';
import Urls from '../externaljs/Urls';
import FileUploadWithProgress from '../services/FileUploadWithProgress';
const database = Urls.firebase.database();

export default class ComposeChatBox extends React.Component {
    constructor(props) {
        super(props);
        const login_user_data = JSON.parse(Storage.get('imnLoginData'));
        this.state = {
            order_id: null,
            order_no: null,
            sender_info: {
                user_id: login_user_data.id,
                profile_image: login_user_data.profile_image || '',
                first_name: login_user_data.first_name || '',
                last_name: login_user_data.last_name || '',
                profile_id: login_user_data.profile_id
            },
            receiver_info: null,
            typed_message: "",
            messages: [],
            chat_box_id: null,
            is_fetch_from_sender_db: false,
            selectFile: null,
            selectFileType: null,
            selectFileURL: null,
            isProccesingFileUpload: false,
            uploadPercentage: 0,
            isSending: false,
            fileErrorMsg: null,
            fileUploadLimit: 5, //MB

            searchText: ""
        }
    }
    onMessageListener = (user) => {
        const { sender_info, receiver_info, order_id } = this.state;
        // if (!is_fetch_from_sender_db) {
        //     let sender_db = database.ref(`/chat/order_${sender_info.user_id}_${order_id}_${receiver_info.user_id}`);
        //     sender_db.once("value", async () => {
        //         let data = snapshot.val();
        //         console.log("snapshot from sender_db", data)
        //         await this.setState({ messages: Object.values(data), is_fetch_from_sender_db: true });
        //     })
        // }
        // let receiver_db = database.ref(`/chat/order_${receiver_info.user_id}_${order_id}_${sender_info.user_id}`);
        let sender_db = database.ref(`/chat/order_${sender_info.user_id}_${order_id}_${receiver_info.user_id}`);
        sender_db.on('value', async (snapshot) => {
            let data = snapshot.val();
            if (data)
                this.setState({ messages: Object.values(data) }, () => {
                    window.$('.chat-body').animate({ scrollTop: window.$(".chat-body")[0].scrollHeight }, 'slow');
                })
            else {
                this.setState({ messages: [] })
            }
        })
    }
    renderMessages() {
        const { sender_info, messages } = this.state;
        if (messages.length > 0) {
            let date = '';
            let flag = 0;
            let time = "";
            let tempMessages = messages.map((msg_info, i) => {
                if (msg_info.date != date) {
                    flag = 1;
                    date = msg_info.date;
                } else {
                    flag = 0;
                }
                if (msg_info.timestamp) {
                    time = window.moment(msg_info.timestamp).format('LT');
                }
                // video related info show
                if (msg_info.channelName && msg_info.channelName !== "") {
                    return (
                        <React.Fragment key={'chat-messages-' + i}>
                            {flag == 1 && <div className="conversation-date">{msg_info.date}</div>}
                            {
                                (msg_info.status === "1" && sender_info.user_id != msg_info.from_id)
                                    ?
                                    <div className="conversation-date"
                                        onClick={() => this.props.joinVideoCall(msg_info.channelName, true)}
                                    >
                                        <div className="chat-text text-center-callend">
                                            <img src="assets/images/icons/video-camera-red.svg" width="20" className="m-r-5" />
                                            <span className="link-text-curser-style"><b>Click here</b> to join video call</span>
                                        </div>
                                    </div>
                                    : null

                            }
                            {
                                (msg_info.status !== "1")
                                    ? (
                                        <div className="conversation-date">
                                            <div className="chat-text text-center-callend">
                                                <img src="assets/images/icons/video-camera-red.svg" width="20" className="m-r-5" />
                                                <b>Call ended!</b>
                                            </div>
                                        </div>
                                    ) : null

                            }

                        </React.Fragment>
                    )
                }
                // normal chat show
                return (
                    <React.Fragment key={'chat-messages-' + i}>
                        {flag == 1 && <div className="conversation-date">{msg_info.date}</div>}
                        {/* LEFT SIDE (RECIVER)*/}
                        {sender_info.user_id != msg_info.from_id &&
                            <div className="chat-item">
                                <div className="chat-user-pic"><img src={((sender_info.user_id != msg_info.from_id) ? msg_info.sender_profile_image ? msg_info.sender_profile_image : 'assets/images/default_avatar.jpg' : msg_info.receiver_profile_image ? msg_info.receiver_profile_image : 'msg_info.receiver_profile_image')} alt="" /></div>
                                <div className="chat-text">
                                    {msg_info.file_name && msg_info.file_name != '' ? (
                                        <Fragment>
                                            <span>
                                                <div className={"file-wrapper " + FileType(msg_info.file_type)} onClick={() => this.handleFileView(msg_info.file_name)}>
                                                    {FileSvg(msg_info.file_type, this.getChatFileURL(msg_info.file_name))}
                                                    {/* <div className="file-title"><a href={this.getChatFileURL(msg_info.file_name)} download={`${msg_info.file_name}`}><i className={FileIcon(msg_info.file_type) + " position-left text-primary"}></i> {sender_info.first_name}</a>
                                                </div> */}
                                                </div>
                                                {msg_info.message}
                                            </span>
                                            <p className="text-slate-300">{time}</p>
                                        </Fragment>
                                    ) : (
                                            <Fragment>
                                                <span>{msg_info.message}</span>
                                                <p className="text-slate-300">{time}</p>
                                            </Fragment>
                                        )}
                                </div>
                            </div>
                        }
                        {/* RIGHT SIDE (SENDER)*/}
                        {sender_info.user_id == msg_info.from_id &&
                            <div className="chat-item sent">

                                <div className="chat-text">

                                    {msg_info.file_name && msg_info.file_name != '' ? (
                                        <Fragment>
                                            <span>
                                                <div className={"file-wrapper " + FileType(msg_info.file_type)} onClick={() => this.handleFileView(msg_info.file_name)}>
                                                    {FileSvg(msg_info.file_type, this.getChatFileURL(msg_info.file_name))}
                                                    {/* <div className="file-title"><a href={this.getChatFileURL(msg_info.file_name)} download={`${msg_info.file_name}`}><i className={FileIcon(msg_info.file_type) + " position-left text-primary"}></i> {sender_info.first_name}</a>
                                                </div> */}
                                                </div>
                                                {msg_info.message}
                                            </span>
                                            <p className="text-slate-300">{time}</p>
                                        </Fragment>
                                    ) : (
                                            <Fragment>
                                                <span>{msg_info.message}</span>
                                                <p className="text-slate-300">{time}</p>
                                            </Fragment>
                                        )}

                                </div>
                                <div className="chat-user-pic"><img src={((sender_info.user_id != msg_info.from_id) ? msg_info.receiver_profile_image ? msg_info.receiver_profile_image : 'assets/images/default_avatar.jpg' : msg_info.sender_profile_image ? msg_info.sender_profile_image : 'assets/images/default_avatar.jpg')} alt="" /></div>
                            </div>
                        }
                    </React.Fragment>
                )
            })

            return tempMessages;
        } else {
            return (
                <div className="text-center p-t-15">
                    <p className="f-s-14" style={{ color: '#32465a' }}><b>No messages found</b></p>
                </div>
            )
        }
    }
    sendMessage = async () => {
        const { sender_info, receiver_info, order_id, typed_message, order_no, selectFile } = this.state;
        let send_data = {
            order_id: order_id,
            from_id: sender_info.user_id,
            to_id: receiver_info.user_id,
            message: typed_message,
            receiver_name: receiver_info.name,
            receiver_profile_image: receiver_info.profile_image,
            sender_profile_image: sender_info.profile_image,
            date: DateFormat.getFormattedDateTime(new Date(), 'm/d/Y'),
            channelName: "",
            status: "",
            timestamp: window.moment().format("YYYY-MM-DD HH:mm:ss"),
            file_id: "",
            file_name: "",
            file_type: ""


        }
        let uploadFileRes = null;
        if (selectFile) {
            this.setState({ isSending: true })
            uploadFileRes = await this.uploadFile();
            if (uploadFileRes) {
                send_data.file_id = uploadFileRes.file_id;
                send_data.file_name = uploadFileRes.file_name;
                send_data.file_type = uploadFileRes.file_type;
            }
        }
        if (typed_message != "" || uploadFileRes) {

            // Get reciver current chat info from firebase 
            database.ref(`/active_users/${receiver_info.user_id}`).once('value', (snapshot) => {
                let reciver_current_chat_info = snapshot.val();
                console.log("reciver_current_chat_info", reciver_current_chat_info);
                // here check reciver current chat info match to semder info if match then not set unread message
                if (reciver_current_chat_info && reciver_current_chat_info.is_active && reciver_current_chat_info.chat_order_id === order_id && reciver_current_chat_info.chat_reciver_id === sender_info.user_id) {
                    database.ref(`/chat/order_${sender_info.user_id}_${order_id}_${receiver_info.user_id}`).push(send_data);
                    database.ref(`/chat/order_${receiver_info.user_id}_${order_id}_${sender_info.user_id}`).push(send_data);
                } else {
                    let msg_count = 1;
                    database.ref(`/unread_messages/${receiver_info.user_id}/${order_id}_${sender_info.user_id}`).once('value', (snapshot) => {
                        let data = snapshot.val();
                        console.log("Unread Msg Data", data)
                        if (data) msg_count = data.msg_count + 1
                        // set unread message count
                        database.ref(`/unread_messages/${receiver_info.user_id}/${order_id}_${sender_info.user_id}`).set({
                            user_id: sender_info.user_id,
                            profile_id: sender_info.profile_id,
                            name: `${sender_info.first_name} ${sender_info.last_name}`,
                            profile_image: sender_info.profile_image,
                            msg_count,
                            order_id,
                            order_no,
                            timestamp: window.moment().format("YYYY-MM-DD HH:mm:ss")
                        })
                        database.ref(`/chat/order_${sender_info.user_id}_${order_id}_${receiver_info.user_id}`).push(send_data);
                        database.ref(`/chat/order_${receiver_info.user_id}_${order_id}_${sender_info.user_id}`).push(send_data);
                    });
                }
            })
            this.setState({ typed_message: "", selectFile: null, isSending: false, fileErrorMsg: null })

        }
    }
    handleKeyPress = (e) => {
        if (e.which == 13 && !e.shiftKey) {
            this.sendMessage();
            e.preventDefault();
        }

    }
    handleChange = (e) => {
        this.setState({ typed_message: e.target.value });
    }

    getChatFileURL = (fileName) => {
        return Urls.chatFile + fileName;
    }

    handleFileChnage = (e) => {
        if (e.target.files.length > 0) {
            let fileSize = e.target.files[0].size / 1024 / 1024;
            const { fileUploadLimit } = this.state;
            if (fileSize <= fileUploadLimit) {
                let file = e.target.files[0];
                let ext = file.name.split(".").pop();

                let fileReader = new FileReader();
                fileReader.onload = (e) => {
                    this.setState({ selectFileURL: e.target.result });
                }
                fileReader.readAsDataURL(file);
                this.setState({ selectFile: e.target.files[0], selectFileType: ext, fileErrorMsg: null });
            }
            else
                this.setState({ fileErrorMsg: `File size must ${fileUploadLimit}mb or below`, selectFile: null });
        } else {
            this.setState({ selectFile: null, fileErrorMsg: null });
        }
    }
    uploadFile = () => {
        const { sender_info, receiver_info, order_id, typed_message, order_no } = this.state;
        this.setState({ isProccesingFileUpload: true }, () => {
            window.$('.chat-body').animate({ scrollTop: window.$(".chat-body")[0].scrollHeight }, 'slow');
        });
        let formData = new FormData();
        formData.append('order_id', order_id);
        formData.append('user_from', sender_info.user_id);
        formData.append('user_to', receiver_info.user_id);
        formData.append('file[]', this.state.selectFile);
        return FileUploadWithProgress('UploadChatFiles', formData, (e) => {
            var percent = Math.round((e.loaded / e.total) * 100);
            this.setState({ uploadPercentage: percent })
        })
            .then(response => {
                if (response.success == 1) {
                    this.setState({ isProccesingFileUpload: false, uploadPercentage: 0, selectFile: null });
                    return response.data;

                } else {
                    throw new Error("Not able to upload.")
                }
            })
            .catch(err => {
                console.log('err', err)
                this.setState({ isProccesingFileUpload: false, selectFile: null, uploadPercentage: 0, isSending: false });
                window.swal('Error!', 'Something went wrong.', 'error')
            });

    }

    handleFileView = (fileName) => {
        window.open(this.getChatFileURL(fileName), '_blank');
    }
    removeSelectFile = () => {
        this.setState({ selectFile: null, selectFileType: null, selectFileURL: null, fileErrorMsg: null });
    }
    closesearchResult = () => {
        window.$(".search-result").show();
        window.$('.search').blur(function () {
            setTimeout(() => {
                window.$('.search-result').hide()
            }, 200);
        });
        // window.$('.search').focus(function(){
        //     window.$('.search-result').show();
        // });
    }
    componentDidUpdate(prevProps, prevState) {
        console.log(this.state.chat_box_id, prevState.chat_box_id)
        if (this.state.chat_box_id !== prevState.chat_box_id) {
            this.onMessageListener();
            this.setState({ searchText: this.state.receiver_info.name })
        }
    }
    onSelectUser = (userInfo, selectIndex) => {
        const { chat_box_id, order_id, order_no, user_id, name, profile_image, msg_count } = userInfo;
        this.setState({
            chat_box_id,
            order_id,
            order_no,
            receiver_info: {
                user_id,
                name,
                profile_image
            }
        })
        if (msg_count > 0) {
            // remove from firebase 
            this.props.removeUnreadUserFromFB(chat_box_id);
        }


    }
    handleSearch = (e) => {
        this.setState({ searchText: e.target.value })
    }

    render() {
        const { sender_info, receiver_info, typed_message, order_id, order_no, chat_box_id, fileErrorMsg, searchText } = this.state;
        const { closeChatBox, openVideoChatWindow, closeComposeChatBox, chatUserList } = this.props;
        return (
            <div className="popup-chat-window open-another-chatbox" style={{ 'display': 'none' }}>
                <div className="chat-header">
                    <div className="search p-10 p-r-0">
                        <div className="input-group">
                            <div className="input-group-addon p-10">
                                <i className="icon-search4 f-s-11"></i>
                            </div>
                            <input
                                type="text"
                                placeholder="Search By Name"
                                name="searchText"
                                className="form-control search"
                                onFocus={() => this.closesearchResult()}
                                onChange={this.handleSearch}
                                value={this.state.searchText}
                                autoComplete="off"
                            />

                        </div>
                        {/* <div className="filter p-t-5 text-left">
                            {order_no && `#${order_no}`}
                        </div> */}
                        <div className="search-result" style={{ 'display': 'none' }}>
                            <ul className="list-group">
                                {chatUserList.map((userInfo, index) => {
                                    if (searchText !== ""
                                        && userInfo['name'].toLowerCase().startsWith(searchText.toLowerCase())) {
                                        return (
                                            <li className="list-group-item" key={userInfo.chat_box_id} style={{ cursor: 'pointer' }} onClick={() => this.onSelectUser(userInfo, index)}>
                                                <div className="media">
                                                    <div className="media-left">
                                                        <img src={!!userInfo.profile_image ? userInfo.profile_image : "assets/images/default_avatar.jpg"} alt="avatar" />
                                                    </div>
                                                    <div className="media-body text-left">
                                                        <p className="m-0">{userInfo.name}</p>
                                                        <p className="m-0">{!!userInfo.order_no && `#${userInfo.order_no}`}</p>
                                                    </div>
                                                </div>
                                            </li>)
                                    }
                                    if (searchText == "") {
                                        return (
                                            <li className="list-group-item" key={userInfo.chat_box_id} style={{ cursor: 'pointer' }} onClick={() => this.onSelectUser(userInfo, index)}>
                                                <div className="media">
                                                    <div className="media-left">
                                                        <img src={!!userInfo.profile_image ? userInfo.profile_image : "assets/images/default_avatar.jpg"} alt="avatar" />
                                                    </div>
                                                    <div className="media-body text-left">
                                                        <p className="m-0">{userInfo.name}</p>
                                                        <p className="m-0">{!!userInfo.order_no && `#${userInfo.order_no}`}</p>
                                                    </div>
                                                </div>
                                            </li>)
                                    }
                                })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="chat-actions">
                        <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Video Chat"
                            className="m-r-10"
                            style={{ "cursor": !this.state.order_id ? "not-allowed" : "pointer", "opacity": !this.state.order_id ? "0.65" : 1 }}
                            onClick={(e) => !this.state.order_id ? e.preventDefault() : openVideoChatWindow({ order_id, order_no, chat_box_id, receiver_info })}
                        >
                            <img src="assets/images/icons/dark-blue/video-camera.svg" width="30" />
                        </span>
                        <a href="javascript:void(0)" className="close-chat" onClick={() => closeComposeChatBox()}><i className="icon-cross"></i></a>
                    </div>
                </div>
                <div className="chat-body">
                    {/* <div className="chat-item"></div> */}
                    {order_id && receiver_info ? this.renderMessages() : <div className="chat-item"></div>}
                    {/* Upload file processing */}
                    {this.state.isProccesingFileUpload && (
                        <div className="chat-item sent">

                            {/* message */}
                            <div className="chat-text">
                                <span>
                                    {/* Image */}
                                    <div className="file-wrapper m-b-10">
                                        <div className="progress-file-icon">
                                            <img src="assets/images/blur-inventory.jpg" alt="" />
                                            <div className="inventory-progess-bar">
                                                <CircularProgressbar value={this.state.uploadPercentage} text={`${this.state.uploadPercentage}%`} />
                                            </div>
                                        </div>
                                    </div>
                                    {typed_message}</span>
                                {/* <p className="text-slate-300">{time}</p> */}
                            </div>
                            <div className="chat-user-pic"><img src={sender_info.profile_image ? sender_info.profile_image : 'assets/images/default_avatar.jpg'} alt="" /></div>
                        </div>
                    )
                    }
                    {/* Upload file processing End*/}
                </div>
                {this.state.selectFile && !this.state.isProccesingFileUpload ? (<div className="chatfile-upload p-10">
                    <div className="media">
                        <div className="media-left">
                            {['png', 'jpg', 'jpeg'].indexOf(this.state.selectFileType.toLowerCase()) !== -1 ? (
                                <img src={this.state.selectFileURL} width="40" alt="Image" />
                            )
                                : (<div style={{ width: 40 }} >
                                    {FileSvg(this.state.selectFileType, this.state.selectFileURL)}
                                </div>)}

                        </div>
                        <div className="media-body text-left">
                            <p className="m-t-10">{this.state.selectFile.name}</p>
                        </div>
                    </div>
                    <div className="file-cross" onClick={this.removeSelectFile}>
                        <i className="icon-cross3"></i>
                    </div>
                </div>
                ) : null}
                <form>
                    <div className="chat-footer">
                        <textarea className="form-control"
                            // id={'emojiId' + order_id + '_' + receiver_info.user_id}
                            placeholder="Type your message..." value={typed_message}
                            onKeyPress={this.handleKeyPress}
                            onChange={this.handleChange}
                            style={{ 'height': '51px' }} />

                        <div className="footer-bottom">
                            <label className="file-attach-label m-r-10">
                                <i className="icon-attachment text-slate-700"></i>
                                <input id="FileInput" name="" type="file" onChange={this.handleFileChnage} />
                            </label>
                            <div className="emoji-icon-container2">
                            </div>
                          
                            <button type="button" className="btn btn-success btn-xs text-uppercase" onClick={this.sendMessage} disabled={this.state.isSending || !this.state.order_id}>{this.state.isSending ? `Sending...` : `Send`} <i className="icon-arrow-right8"></i></button>

                        </div>
                        {fileErrorMsg && (<div className="d-block text-left">
                            <hr className="m-0" />
                            <p className="text-danger m-0 p-l-10 p-r-10 p-t-5 p-b-5">
                                {fileErrorMsg}
                            </p>
                        </div>)}
                    </div>
                </form>
            </div>
        )
    }
}