import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataWithParam from '../services/PostDataWithParam';
import DateFormat from '../externaljs/DateFormat';
import Storage from '../externaljs/Storage';

class SentMessage extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Message', link: '/independent-mover/dashboard/sent-message', class: '' },
            { title: 'Sent Messages', link: '', class: 'active' }
        ],
        messages: [],
        updateStatus: false,
        loading: false,
        menu: [],
        custom_profile: [],
        staff_user_id: '',
        custom_menu: [],
        editPermission: true,
        total_msg_count: 0,
        allDepartments: [],
        unreadMessages: [],
        seletedDepartmentIds: [],
        allDepartments: [],
        isSelectAllDept: true
    }

    subscriptionCheckInterval = null;

    componentDidMount() {

        this.subscriptionCheckInterval = setInterval(async () => {
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1)
                this.setState({ editPermission: false })
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ editPermission: true })
        }, 1000);
        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        let custom_menu = [];
        if (staff_user_id != "") {
            custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
        }
        this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
            // console.log(this.state.custom_menu);
            // console.log(this.state.staff_user_id);
        });
        // this.MessageListSentMessage();
        this.getAllDepartments();
    }

    componentWillUnmount() {
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
    }

    doSubscribe = (e) => {
        // window.swal('warning', 'Please upgrade your membership to complete this action', 'warning')
        window.swal({
            title: "Warning!",
            text: "Please upgrade your membership to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }

    MessageListSentMessage = () => {
        let dep = [];
        for (let i = 0; i < this.state.seletedDepartmentIds.length; i++) {
            dep.push({ profile_id: this.state.seletedDepartmentIds[i] });
        }
        this.setState({ loading: true }, () => {
            PostDataWithParam('MessageList', { list_type: 2, departments: JSON.stringify(dep) })
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ messages: response.data, loading: false, total_msg_count: response.total_msg_count });
                    } else {
                        this.setState({ messages: [], loading: false, total_msg_count: 0 });
                    }
                })
                .catch(err => {
                    this.setState({ messages: [], loading: false });
                })
        })
    }

    getAllDepartments = () => {
        this.setState({ loading: true });
        PostDataWithParam('GetProfilesForMessaging', { search_text: '' })
            .then((response) => {
                if (response.success === 1) {
                    let seletedDepartmentIds = response.data.map(({ id }) => id);
                    this.setState({ seletedDepartmentIds, allDepartments: response.data }, () => {
                        this.MessageListSentMessage();
                    });
                } else {
                    this.MessageListSentMessage();
                }
            })
            .catch(err => {
                this.MessageListSentMessage();
            })
    }

    toggleAllDept = (e) => {
        this.setState(prevState => {
            const isChecked = !prevState.isSelectAllDept;
            let seletedDepartmentIds = []
            // is checked all dept checkbox then select all dept ids to `seletedDepartmentIds` state
            if (isChecked) {
                seletedDepartmentIds = prevState.allDepartments.map(({ id }) => id);
            }
            return {
                isSelectAllDept: isChecked,
                seletedDepartmentIds
            }
        }, () => {
            this.MessageListSentMessage();
        });
    }

    setdepartment = (e) => {
        let seletedDepartmentIds = this.state.seletedDepartmentIds;
        if (seletedDepartmentIds.indexOf(e.target.value) > -1) {
            let depart = seletedDepartmentIds.filter((item, i) => {
                if (item == e.target.value)
                    return false;
                else
                    return true;
            });
            seletedDepartmentIds = depart;
        } else {
            seletedDepartmentIds.push(e.target.value)
        }

        //--------------------------------- handle all dept check and uncheck -------------------------------------
        let isSelectAllDept = this.state.isSelectAllDept;
        if (seletedDepartmentIds.length === this.state.allDepartments.length && !isSelectAllDept) {
            isSelectAllDept = true;
        } else {
            isSelectAllDept = false;
        }
        //--------------------------------------------------------------------------------------------------------------
        this.setState({ seletedDepartmentIds: seletedDepartmentIds, isSelectAllDept }, () => {
            this.MessageListSentMessage();
        });
    }

    stripTags = (html) => {
        const regex = /(<([^>]+)>)/ig;
        const result = html.replace(regex, '');
        return (
            result
        )
    }

    spanColor = (first_letter, index) => {
        let color = '';
        if (index % 4 == 1)
            color = 'primary'
        else if (index % 4 == 2)
            color = 'indigo'
        else if (index % 4 == 3)
            color = 'danger'
        else if (index % 4 == 0)
            color = 'success'
        return (
            <span className={"btn bg-" + color + "-400 btn-rounded btn-icon btn-xs"}>
                <span className="letter-icon">{first_letter.toUpperCase()}</span>
            </span>
        )
    }

    renderProfileName = (profile_id, profile_name) => {
        if (profile_id == 2)
            return <small className="label bg-violet f-s-8">{profile_name}</small>
        else if (profile_id == 5)
            return <small className="label bg-success">{profile_name}</small>
        else if (profile_id == 6)
            return <small className="label bg-orange-800 f-s-8">{profile_name}</small>
        else
            return <small className="label bg-teal f-s-8">{profile_name}</small>
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="panel panel-flat profile-page">
                        <div className="panel-heading clearfix">
                            <div className="dropdown pull-left">
                                <button className="dropdown-toggle btn btn-link text-semibold p-l-0" type="button" data-toggle="dropdown"><span className=""><span className="status-mark border-warning position-left text-capitalize"></span>Sent Messages</span>
                                    <span className="caret"></span></button>
                                <ul className="dropdown-menu">
                                    <li><Link to="/independent-mover/dashboard/inbox">Inbox</Link></li>
                                    <li><Link to="/independent-mover/dashboard/sent-message">Sent Messages</Link></li>
                                </ul>
                            </div>
                            <Link to="/independent-mover/dashboard/all-messages" className="pull-left m-t-10 text-muted m-l-15">
                                <i className="icon-spinner11"></i>
                            </Link>
                            <div className="heading-elements">
                                
                                {
                                    (this.state.staff_user_id == "") &&
                                    <div className="pull-right">
                                        {this.state.editPermission ?
                                            <Link to="/independent-mover/dashboard/compose-message" className="btn btn-primary btn-xs pull-right"><i className="icon-plus22"></i> Compose Message</Link>
                                            :
                                            <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-primary btn-xs pull-right" onClick={this.doSubscribe}><i className="icon-plus22"></i> Compose Message</Link>
                                        }
                                    </div>
                                }

                                <form className="dropdown pull-right">
                                    <span className="badge badge-success">{this.state.total_msg_count}</span>
                                    <a className="dropdown-toggle btn btn-link text-semibold" data-toggle="dropdown" aria-expanded="false">

                                        <span className="">
                                            <span className="status-mark border-warning position-left text-capitalize"></span>Filter by User Type</span>
                                        <span className="caret"></span></a>
                                    <ul className="dropdown-menu">
                                        <li className="p-l-10 p-r-10">
                                            <div>
                                                <label class="checkbox-inline text-primary">
                                                    <input type="checkbox" name="menu" id="select_all" checked={this.state.isSelectAllDept} onChange={this.toggleAllDept} />
                                                    <strong>All Departments</strong>
                                                </label>
                                            </div>
                                        </li>
                                        <li className="divider"></li>
                                        {this.state.allDepartments.map((dep) => {
                                            return (
                                                <>
                                                    <li className="p-l-10 p-r-10" key={"dep_" + dep.id}>
                                                        <div className="checkbox">
                                                            <label className="p-l-20">
                                                                <input type="checkbox" name='dept' value={dep.id} checked={this.state.seletedDepartmentIds.indexOf(dep.id) > -1} onChange={this.setdepartment} />
                                                                {dep.title}
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li className="divider"></li>
                                                </>
                                            )
                                        })}
                                    </ul>
                                </form>

                                {
                                    (this.state.staff_user_id != "") && this.state.custom_menu.map((menu, i) => {
                                        return (
                                            <div>
                                                {
                                                    (menu.title == "Messages" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                    <div className="pull-right">
                                                        {this.state.editPermission ?
                                                            <Link to="/independent-mover/dashboard/compose-message" className="btn btn-primary btn-xs pull-right"><i className="icon-plus22"></i> Compose Message</Link>
                                                            :
                                                            <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-primary btn-xs pull-right" onClick={this.doSubscribe}><i className="icon-plus22"></i> Compose Message</Link>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        );
                                    })
                                }


                                {/* <Link to="/independent-mover/dashboard/compose-message" className="btn btn-primary btn-xs"><i className="icon-plus22"></i> Compose Message</Link> */}
                            </div>
                        </div>

                        <div className="panel-body p-0">
                            {
                                this.state.loading
                                &&
                                <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                            }
                            {
                                !this.state.loading
                                &&
                                this.state.messages.length != 0
                                &&
                                <ul className="media-list dashboard">
                                    {this.state.messages.map((message, index) =>
                                        <li key={message.id} className="media">
                                            {this.state.editPermission ?
                                                <Link to={{ pathname: '/independent-mover/dashboard/messages/sent-message-details/' + message.id }} className="link-msg-details">
                                                    {
                                                        message.profile_image == ""
                                                        &&
                                                        <div className="media-left">
                                                            {this.spanColor(message.full_name[0], index)}
                                                        </div>
                                                    }
                                                    {
                                                        message.profile_image != ""
                                                        &&
                                                        <div className="media-left">
                                                            <img src={message.profile_image} alt="" className="img-circle" />
                                                        </div>
                                                    }

                                                    <div className="media-body">
                                                        {message.full_name}
                                                        <span className="media-annotation pull-right text-right">{DateFormat.getFormattedDateTime(message.msg_time, 'm-d-Y')}</span>
                                                        <span className="display-block text-muted">{message.subject}</span>
                                                        {this.renderProfileName(message.profile_id, message.profile_name)}
                                                    </div>
                                                </Link>
                                                :
                                                <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} className="link-msg-details">
                                                    {
                                                        message.profile_image == ""
                                                        &&
                                                        <div className="media-left">
                                                            {this.spanColor(message.full_name[0], index)}
                                                        </div>
                                                    }
                                                    {
                                                        message.profile_image != ""
                                                        &&
                                                        <div className="media-left">
                                                            <img src={message.profile_image} alt="" className="img-circle" />
                                                        </div>
                                                    }

                                                    <div className="media-body">
                                                        {message.full_name}
                                                        <span className="media-annotation pull-right text-right">{DateFormat.getFormattedDateTime(message.msg_time, 'm-d-Y')}</span>
                                                        <span className="display-block text-muted">{message.subject}</span>
                                                        {this.renderProfileName(message.profile_id, message.profile_name)}
                                                    </div>
                                                </Link>
                                            }
                                        </li>
                                    )}
                                </ul>
                            }
                            {
                                !this.state.loading
                                &&
                                this.state.messages.length == 0
                                &&
                                <div className="alert alert-primary border-none text-center">No Messages Found.</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SentMessage;