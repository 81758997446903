import React, { Component } from 'react';
import { Redirect,Link } from 'react-router-dom';
import GetData from '../services/GetData';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import PostDataWithParam from '../services/PostDataWithParam';
import ConfirmBox from '../externaljs/ConfirmBox';
import Breadcumb from '../externaljs/Breadcrumb';

class Staff extends Component {
    state = {
        staffErr: '',
        lists: [],
        addStaffStatus: false,
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Staff', link: '', class: 'active' }
        ]
    }

    componentDidMount() {
        if (typeof this.props.location.state !== 'undefined') {
            this.setState({ staffErr: this.props.location.state.staffErr });
        }
        this.AllStaffs();
    }

    componentWillUnmount() {
        this.setState({ staffErr: '' });
    }

    AllStaffs = () => {
        GetData('AllStaffs')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ lists: response.data }, () =>{
                        DataTableBasic();
                    });
                }
            })
    }
    
    showAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-success">
                    {status}
                </div>
            )
        }
    }

    handleStatus = (status) => {
        if (status == 0) {
            return (
                <td>
                Deactive
                </td>
            )
        }else {
            return(
                <td>
                Active
                </td>
            )
           
        }
    }

    handleBtn = (status) => {
        if (status == 0) {
            return (
                'Activate'
            )
        }else {
            return(
                'Deactivate'
            )
           
        }
    }


    ActiveDeactiveStaff = (staff_id,status) => {

        if(status == 1){
            ConfirmBox("You want to deactivate this staff!")
            .then((result) => {
            if (result.value) {
                PostDataWithParam('ActiveDeactiveStaff',{staff_id:staff_id,status:0})
                .then((response) => {
                    let result = response;
                    if (result.success === 1) {
                        this.AllStaffs();
                        window.swal('Deactivated!', result.msg, 'success')
                    } else {
                        window.swal('Deactivated!',result.msg,'success') 
                    } 
                });              
            }
          });       
    }
 else{
    ConfirmBox("You want to activate this staff!")
    .then((result) => {
        if (result.value) {
            PostDataWithParam('ActiveDeactiveStaff',{staff_id:staff_id,status:1})
            .then((response) => {
                let result = response;
                if (result.success === 1) {
                    this.AllStaffs();
                    window.swal('Activated!',result.msg,'success')
                } else {
                    window.swal('Activated!',result.msg,'success')
                } 
            });              
        }
      });   
 }
}

DeleteStaff = (staff_id) => {
    ConfirmBox("You want to delete this staff!")
    .then((result) => {
    if (result.value) {
        PostDataWithParam('DeleteStaff',{staff_id:staff_id})
        .then((response) => {
            let result = response;
            if (result.success === 1) {
                this.AllStaffs();
                window.swal('Deleted!',result.msg, 'success')
            } else {
                window.swal('Deleted!',result.msg, 'success')
            } 
        });              
    }
  });  
}

    render() {
        if (this.state.addStaffStatus) {
            return <Redirect  to={{
                pathname: '/independent-mover/dashboard/addstaff'
                }}/>
        }
        return (
            <div className="content-container">
                <div className="row">
                    <div className="col-md-12">
                        {Breadcumb(this.state.breadcrumb)}
                        <div className="panel panel-flat">
                        {this.showAlert(this.state.staffErr)}
                            <div className="panel-heading">
                                <h5 className="panel-title">Staff List</h5>
                                <div className="heading-elements">
                                    <Link to="/independent-mover/dashboard/addstaff" className="btn btn-primary btn-xs" ><i className="icon-plus22" ></i>Add Staff</Link>
                                    
                                </div>
                            </div>
                            {
                    this.state.lists.length !== 0
                    &&
                            <table className="table datatable-basic table-striped">
                                <thead>
                                    <tr>
                                        <th style={style}>ID</th>
                                        <th style={style}>Full Name</th>
                                        <th>Custom Profile</th>
                                        {/* <th>Mobile</th>
                                        <th>Email</th> */}
                                        <th>Status</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.lists.map(list =>
                                        <tr id={"#00"+list.id} key={list.id}>
                                            <td>#00{list.id}</td>
                                            <td className="text-nowrap">
                                                <Link to={{
                                                    pathname: '/independent-mover/dashboard/viewstaff/' + list.id
                                                }}>{list.first_name} {list.last_name}</Link>
                                            </td>
                                            <td>{list.custom_profile}</td>
                                            {/* <td>{list.contact_no}</td>
                                            <td>{list.email}</td> */}
                                            {this.handleStatus(list.status)}
                                            <td className="text-center">
                                                <ul className="icons-list">
                                                    <li className="dropdown">
                                                        <a href="" className="dropdown-toggle" data-toggle="dropdown">
                                                            <i className="icon-menu9"></i>
                                                        </a>
                                                        <ul className="dropdown-menu dropdown-menu-right">
                                                            <li><Link to={{
                                                                pathname: '/independent-mover/dashboard/editstaff/' + list.id
                                                            }}><i className="icon-pencil"></i> Edit Staff</Link></li>
                                                             <li><a href="javascript:void(0)" onClick={() => this.DeleteStaff(list.id)}><i className="icon-cross2"></i> Delete Staff</a></li>
                                                            <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.ActiveDeactiveStaff(list.id,list.status)}><i className="icon-user-block"></i> {this.handleBtn(list.status)} Staff</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            }
                            {
                                this.state.lists.length === 0
                                &&
                                <div className="alert alert-primary border-none text-center">No Staff Found.</div>
                            }
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

export default Staff;

const style = {
    whiteSpace: 'nowrap'
}