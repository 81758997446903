import React, { Component } from 'react'
import Breadcumb from '../externaljs/Breadcrumb';
import { Link } from 'react-router-dom';
import Storage from '../externaljs/Storage';
import PostDataWithParam from '../services/PostDataWithParam';
import DateFormat from '../externaljs/DateFormat';

class FindAnOffer extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Offers Received', link: '', class: 'active' }
        ],
        offers: [],
        myOffers: [],
        acceptedOffers: [],
        declinedOffers: [],
        orderType: 0,
        loading: false,
        offset: 0,
        limit: 9,
        search_text: '',
        loadMore: 0,
        total: 0,
        total_count: 0,
        editPermission: true,
        isDeclined: false,
        isAccepted: false,
        edit_rcvOffers: true,
        final_search_text: '',
        staff_user_id: null,
        staffOfferOrder: 0
    }
    staffMenuCheckInterval = null;
    subscriptionCheckInterval = null;
    componentDidMount() {
        this.offersReceived();
        this.subscriptionCheckInterval = setInterval(() => {
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1)
                this.setState({ editPermission: false })
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ editPermission: true })
        }, 1000);
        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        this.setState({ staff_user_id });
        this.staffMenuCheckInterval = setInterval(() => {
            if (Storage.get('mmcStaffMenu')) {
                let custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
                this.setState({ custom_menu }, () => {
                    // console.log(this.state.custom_menu)

                    let find_offer_received = this.state.custom_menu.find(menu => menu.title == "Offers Received")
                    if (find_offer_received) {
                        if (find_offer_received.is_add == 1 || find_offer_received.is_add == '1') this.setState({ edit_rcvOffers: true })
                        else this.setState({ edit_rcvOffers: false })
                    }

                });
            }
        }, 1000)
        if (!!this.props.location.state && !!this.props.location.state.orderType) {
            this.setState({ orderType: this.props.location.state.orderType }, () => {
                if (this.state.orderType == 1)
                    this.setState({ isAccepted: true, isDeclined: false });
                if (this.state.orderType == 2)
                    this.setState({ isAccepted: false, isDeclined: true });

            })
        }
    }

    componentWillUnmount() {
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
        if (this.staffMenuCheckInterval) {
            clearInterval(this.staffMenuCheckInterval)
        }
    }

    doSubscribe = (e) => {
        window.swal({
            title: "Warning!",
            text: "Please upgrade your membership to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }

    offersReceived = () => {
        let offers
        this.setState({ loading: true, loadMore: 0, total_count: 0 }, () => {
            PostDataWithParam('RecivedAndAcceptedOrdersListForMovers', { order_type: this.state.orderType, search_text: this.state.final_search_text, offset: this.state.offset, staffOfferOrder: this.state.staffOfferOrder, staff_user_id: this.state.staffOfferOrder == 1 ? this.state.staff_user_id : '' })
                .then(response => {
                    if (response.success === 1) {
                        // let offers = this.state.offers;
                        if (this.state.orderType == 0) {
                            offers = this.state.myOffers;
                        }
                        if (this.state.orderType == 1) {
                            offers = this.state.acceptedOffers;
                        }
                        if (this.state.orderType == 2) {
                            offers = this.state.declinedOffers;
                        }
                        if (offers) {
                            offers = [...offers, ...response.data]
                        }
                        else {
                            offers = response.data
                        }
                        this.setState({ offers: offers, loading: false, search_text: this.state.search_text, loadMore: response.load_more, total: response.data.count, total_count: response.total_count }, () => {
                            let offset = this.state.offset;
                            if (offset == 0) {
                                offset = this.state.limit;
                            } else {
                                offset += this.state.limit;
                            }
                            this.setState({ offset: offset });
                        })
                    } else {
                        this.setState({ offers: [], offset: 0, total: 0, loading: false })
                    }
                })
                .catch(err => {
                    this.setState({ offers: [], loading: false })
                })
        })
    }

    getOffers = (status) => {
        if (status === 2) {
            this.setState({ isDeclined: true, isAccepted: false });
        }
        else if (status === 1) {
            this.setState({ isAccepted: true, isDeclined: false });
        }
        else {
            this.setState({ isAccepted: false, isDeclined: false });
        }
        this.setState({ orderType: status, offset: 0, offers: [], final_search_text: '' }, () => {
            this.offersReceived()
        });
    }

    handleOnChange(e) {
        this.setState({ search_text: e.target.value });
    }

    search() {
        this.setState({ offset: 0, loadMore: 0, total: 0, offers: [], final_search_text: this.state.search_text }, () => {
            this.offersReceived();
        })
    }
    acceptRejectOffers(status, id, e) {
        e.preventDefault();
        PostDataWithParam('AcceptRejectOffer', { offer_id: id, status: status })
            .then(response => {
                if (response.success === 1) {
                    window.swal('Success!', response.msg, 'success');
                    this.setState({ loading: true, offers: [] }, () => {
                        this.offersReceived();
                        this.setState({ loading: false });
                    })
                } else {
                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => { })
    }
    reconsiderOffer = (id, e) => {
        e.preventDefault();
        PostDataWithParam('reconsiderOffer', { order_id: id })
            .then(response => {
                if (response.success === 1) {
                    window.swal('Success!', response.msg, 'success');
                    this.setState({ orderType: 0, isDeclined: false }, () => {
                        this.offersReceived();
                    })

                } else {
                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => {

            })
    }

    renderOrderType = (hire_by_user_id) => {
        if (hire_by_user_id === (JSON.parse(Storage.get('imnLoginData'))).id)
            return ''
        else
            return 'accepted'
    }

    renderOffers = () => {
        if (this.state.offers.length > 0) {
            let offers = this.state.offers.map((item, i) => {
                return (
                    <div className="order-item" key={'offers-' + i}>
                        <div className="order-item-details">
                            <div className="order-item-header ">
                                {(item.order_type == 1 || item.order_type == '1') ? <h4 className="m-0">Shipment #{item.order_id}</h4> :
                                    <>
                                        {item.military_flag == 1 ? <h4 className="m-0">GBL #{item.order_id}</h4> : <h4 className="m-0">Relo #{item.order_id}</h4>}
                                    </>
                                }
                                <p className="order-item-footer p-b-0"><i><span className="text-bold m-0">{item.posted_by}</span> posted on {DateFormat.getFormattedDateTime(item.posted_date, 'm-d-Y')}</i></p>
                                <p><i>
                                    <label className="text-bold m-0">Weight of shipment :</label> {(item.weight_of_shipment)}</i></p>
                            </div>
                            {/* <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-6">
                                    <div className="form-group">
                                        <label className="text-bold m-0">Requested Start Date</label>
                                        <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22 m-r-10"></i>{DateFormat.getFormattedDateTime(item.request_start_date, 'm-d-Y')}</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-6">
                                    <div className="form-group">
                                        <label className="text-bold m-0">Requested End Date</label>
                                        <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22 m-r-10"></i>{DateFormat.getFormattedDateTime(item.request_end_date, 'm-d-Y')}</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="order-item-footer p-b-0">
                                <div className="form-group">
                                    <label className="text-bold m-0">Service Origin Address</label>
                                    <p className="f-s-16 m-0 text-muted overflow-ellipsis"><i className="icon-location3 m-r-10"></i>{item.source_address ? ((item.source_address && item.source_address.length > 30) ? item.source_address.substr(0, 30) + '' : item.source_address) : ''}</p>
                                </div>
                                <div className="form-group m-b-0">
                                    <label className="text-bold m-0">Service Destination Address</label>
                                    <p className="f-s-16 m-0 text-muted overflow-ellipsis"><i className="icon-location3 m-r-10"></i>{item.destination_address ? ((item.source_address && item.destination_address.length > 30) ? item.destination_address.substr(0, 30) + '' : item.destination_address) : ''}</p>
                                </div>
                            </div><br />
                            <div className="order-item-footer">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label className="text-bold f-s-15 m-0">Pack Date</label>
                                        <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22"></i>&nbsp;{DateFormat.getFormattedDateTime(item.requested_pack_date, 'm-d-Y')}</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <label className="text-bold f-s-15 m-0">Load Date</label>
                                        <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22"></i>&nbsp;
                                            <label>{DateFormat.getFormattedDateTime(item.request_start_date, 'm-d-Y')}</label>
                                        </p>
                                    </div>
                                </div>
                                <div className="mover-tracking-status">
                                    <p className={item.tracking_status != '' ? "success-status f-s-15 " + 'overflow-ellipsis m-b-0' + 'overflow-ellipsis m-b-0' : "alert alert-light f-s-15 " + 'overflow-ellipsis m-b-0'}><span className=""></span>{item.tracking_status}</p>
                                </div>
                            </div>
                            <div className="overlay">
                                {this.state.editPermission ?

                                    !this.state.isDeclined && !this.state.isAccepted
                                        ?
                                        <React.Fragment>
                                            <Link to={{
                                                pathname: '/independent-mover/dashboard/my-offer-details',
                                                state: {
                                                    id: item.id,
                                                    type: 'received',
                                                    post_date: item.request_date_time,
                                                    post_by: item.posted_by,
                                                    orderType: this.state.orderType,
                                                    actionType: 'SEND_PROPOSAL',
                                                    customer_id: item.customer_id
                                                }
                                            }} className="btn btn-success btn-xs btn-block">View Details</Link>
                                            {this.state.edit_rcvOffers &&
                                                <Link to="#" onClick={this.acceptRejectOffers.bind(this, 2, item.id)} className="btn btn-link btn-xs btn-block"><u>Reject Offer</u></Link>}
                                        </React.Fragment>
                                        :
                                        this.state.isAccepted && !this.state.isDeclined
                                            ?
                                            <React.Fragment>
                                                <Link to={{
                                                    // pathname: '/independent-mover/dashboard/my-offer-details',
                                                    pathname: '/independent-mover/dashboard/order-details',
                                                    state: {
                                                        // id: item.id,
                                                        // type: 'received',
                                                        // post_date: item.request_date_time,
                                                        // post_by: item.posted_by,
                                                        // orderType: this.state.orderType,
                                                        // actionType: '',
                                                        // customer_id: item.customer_id
                                                        id: item.id,
                                                        type: this.renderOrderType(item.hire_by_user_id),
                                                        request_from: item.posted_by
                                                    }
                                                }} className="btn btn-success btn-xs btn-block">View Details
                                                    {/* &amp; Send Proposal */}
                                                </Link>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <a href="" className="btn btn-success btn-xs btn-block" onClick={this.reconsiderOffer.bind(this, item.id)}>Reconsider</a>
                                            </React.Fragment>
                                    :
                                    !this.state.isDeclined && !this.state.isAccepted
                                        ?
                                        <React.Fragment>
                                            <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} className="btn btn-success btn-xs btn-block">View Details</Link>
                                            {this.state.edit_rcvOffers &&
                                                <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} className="btn btn-link btn-xs btn-block"><u>Reject Offer</u></Link>}
                                        </React.Fragment>
                                        :
                                        this.state.isAccepted && !this.state.isDeclined
                                            ?
                                            <React.Fragment>
                                                <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} className="btn btn-success btn-xs btn-block">View Details</Link>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} className="btn btn-success btn-xs btn-block">Reconsider</Link>
                                            </React.Fragment>
                                }
                            </div>
                        </div>
                        <hr className="m-0" />
                        <div className="clearfix p-l-15 p-r-15 p-10 order-bottom-footer">
                            {
                                item.hire_by_profile_id == 2
                                &&
                                <div>
                                    <h6 className="text-success pull-left m-0">Mover</h6>
                                    {this.state.editPermission ?
                                        <Link to={{
                                            pathname: '/independent-mover/dashboard/my-offer-details',
                                            state: {
                                                id: item.id,
                                                type: 'received',
                                                post_date: item.request_date_time,
                                                post_by: item.posted_by,
                                                orderType: this.state.orderType
                                            }
                                        }} className="pull-right text-muted p-l-10"><i className="icon-arrow-right8"></i></Link>
                                        :
                                        <Link to="/independent-mover/dashboard/my-subscription" className="pull-right text-muted p-l-10" onClick={this.doSubscribe}><i className="icon-arrow-right8"></i></Link>
                                    }
                                </div>
                            }
                            {
                                item.hire_by_profile_id == 4
                                &&
                                <div>
                                    <h6 className="text-primary pull-left m-0">Non-Military Customer</h6>
                                    {this.state.editPermission ?
                                        <Link to={{
                                            pathname: '/independent-mover/dashboard/my-offer-details',
                                            state: {
                                                id: item.id,
                                                type: 'received',
                                                post_date: item.request_date_time,
                                                post_by: item.posted_by,
                                                orderType: this.state.orderType
                                            }
                                        }} className="pull-right text-muted p-l-10"><i className="icon-arrow-right8"></i></Link>
                                        :
                                        <Link to="/independent-mover/dashboard/my-subscription" className="pull-right text-muted p-l-10" onClick={this.doSubscribe}><i className="icon-arrow-right8"></i></Link>
                                    }
                                </div>
                            }
                            {
                                item.hire_by_profile_id == 11
                                &&
                                <div>
                                    <h6 className="text-violet pull-left m-0">Military Customer</h6>
                                    {this.state.editPermission ?
                                        <Link to={{
                                            pathname: '/independent-mover/dashboard/my-offer-details',
                                            state: {
                                                id: item.id,
                                                type: 'received',
                                                post_date: item.request_date_time,
                                                post_by: item.posted_by,
                                                orderType: this.state.orderType
                                            }
                                        }} className="pull-right text-muted p-l-10"><i className="icon-arrow-right8"></i></Link>
                                        :
                                        <Link to="/independent-mover/dashboard/my-subscription" className="pull-right text-muted p-l-10" onClick={this.doSubscribe}><i className="icon-arrow-right8"></i></Link>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                )
            });
            return offers;
        }
    }

    changeOfferOrderStatus = (e) => {
        if (e.target.checked)
            this.setState({ staffOfferOrder: 1 }, () => { this.offersReceived() })
        else
            this.setState({ staffOfferOrder: 0 }, () => { this.offersReceived() })
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="row">
                        <div className="col-sm-10">
                            <div className="form-group">
                                <input type="text" name="search_text" className="form-control" placeholder="Search by Relo#, Name, City, State, Zip Code, Etc." style={{ 'height': '50px' }} value={this.state.search_text} onChange={this.handleOnChange.bind(this)} />
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <button className="btn btn-primary btn-block" style={{ 'height': '50px' }} onClick={this.search.bind(this)}>Search</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="bg-white border-radius-3px">
                                <ul className="nav nav-tabs nav-tabs-bottom nav-horizantal-scroll">
                                    <li className={(this.state.orderType == 0) ? 'active' : ''}>
                                        <a href="#" style={{ 'pointer-events': this.state.orderType == 0 ? 'none' : '' }} data-toggle="tab" aria-expanded="true" onClick={() => this.getOffers(0)}>
                                            <p className="text-uppercase m-0 m-l-5 m-r-5">My Offers</p>
                                        </a>
                                    </li>
                                    <li className={(this.state.orderType == 1) ? 'active' : ''}>
                                        <a href="#" style={{ 'pointer-events': this.state.orderType == 1 ? 'none' : '' }} data-toggle="tab" aria-expanded="true" onClick={() => this.getOffers(1)}>
                                            <p className="text-uppercase m-0 m-l-5 m-r-5">Accepted Offers</p>
                                        </a>
                                    </li>
                                    <li className={(this.state.orderType == 2) ? 'active' : ''}>
                                        <a href="#" style={{ 'pointer-events': this.state.orderType == 2 ? 'none' : '' }} data-toggle="tab" aria-expanded="true" onClick={() => this.getOffers(2)}>
                                            <p className="text-uppercase m-0 m-l-5 m-r-5">Declined Offers</p>
                                        </a>
                                    </li>
                                    {!!this.state.staff_user_id &&
                                    <li style={{ float: 'right' }}>
                                        <label><b>All Offers</b></label>{' '}
                                        <label className="checkbox-inline checkbox-switch switch-danger">
                                            <small className="m-r-5 text-muted"></small>
                                            <input type="checkbox" onChange={(e) => { this.changeOfferOrderStatus(e) }} /><span></span>
                                            <small className="text-muted"></small>
                                        </label>
                                        {' '}<label><b>My Offers</b></label>
                                    </li>
                                }
                                </ul>
                            </div>
                            {
                                !this.state.loading
                                &&
                                this.state.offers.length == 0
                                &&
                                <div className="panel panel-default">
                                    <div className="panel-body">
                                        <h6 className="alert alert-primary border-none text-center m-0">No Offers Found.</h6>
                                    </div>
                                </div>
                            }
                            {
                                this.state.loading
                                &&
                                <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                            }
                            {
                                !this.state.loading
                                &&
                                this.state.offers.length != 0
                                &&
                                <div>
                                    <p className="f-s-16">{this.state.offers.length} Offers found of {this.state.total_count}</p>
                                    <div className="order-item-box">
                                        {this.renderOffers()}
                                    </div>
                                    {this.state.loadMore == 1 &&
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <a href="javascript:void(0)" className="btn btn-load m-b-20 m-t-20" onClick={this.offersReceived.bind(this)}>Load More</a>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FindAnOffer;