import React, { Component } from 'react'
import GetData from '../services/GetData';
import PostDataWithParam from '../services/PostDataWithParam';
import MmcChart, { progressBar } from '../externaljs/MmcChart';
import DateFormat from '../externaljs/DateFormat';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getInboxCount } from '../redux/action';
import Storage from '../externaljs/Storage';
import { copyHTML } from '../externaljs/UrlHelpers';
import Urls from '../externaljs/Urls';

const initial_form_data = {
    f_name: '',
    l_name: '',
    contact_no: '',
    email_id: '',
    secondary_contact_no: '',
    secondary_email_id: '',
    user_type: ''
}

class Content extends Component {
    state = {
        profile_percentage: '',
        military_percentage: 0,
        total_helpers: 0,
        total_mmc: 0,
        pieData: [{
            name: 'Offers Received',
            y: 200,
            count: 200
        }, {
            name: 'Offers Accepted',
            y: 35,
            count: 35,
            sliced: 1,
            selected: 1
        }, {
            name: 'Offers Pending',
            y: 20,
            count: 20,
            sliced: 1,
            selected: 1
        }],
        form_data: {
            ...initial_form_data
        },
        f_name_err: '',
        l_name_err: '',
        contact_no_err: '',
        email_id_err: '',
        secondary_contact_no_err: '',
        secondary_email_id_err: '',
        user_type_err: '',
        offers: [],
        ongoing_orders: [],
        assign_orders: [],
        orderType: 0,
        messages: [],
        edcOrders: [],
        updateStatus: false,
        departments: [2, 5, 6, 1, 12, 13, 14, 4, 11],
        redirectSubscription: false,
        loading: false,
        loadingAssigningOrders: false,
        loadingOngoing_orders: false,
        loadingOffersReceived: false,
        loadingMessage: false,
        subscription_id: 4,
        custom_menu: [],
        offers_received: true,
        manage_orders: true,
        editPermission: true,
        edit_msg: true,
        edit_rcvOffers: true,
        searchoffset: 0,
        searchloadMore: 0,
        searchtotal: 0,
        searchOffers: [],
        searchloading: false,
        offset: 0,
        limit: 9,
        loadMore: 0,
        total: 0,
        search_total_count: 0,
        global_search_text: '',
        final_search_text: '',
        type: "1",
        isCopied: false,
        scacName: '',
        isScac: 0,
        unreadMessages: []
    }

    staffMenuCheckInterval = null;
    subscriptionCheckInterval = null;
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    database = null;
    componentDidMount() {
        this.database = Urls.firebase.database();
        this.registerMsgNotifications();
        this.GetUserInfo();
        this.getCompanyInfo();
        this.CheckFirstLogin();
        // Dashboard-owl-carousel
        window.$('.dashboard-offer-owl-carousel').owlCarousel({
            loop: true,
            margin: 29,
            nav: true,
            navText: ['<i class="icon-arrow-left8"></i>', '<i class="icon-arrow-right8"></i>'],
            dots: false,
            autoHeight: true,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                576: {
                    items: 2
                }
            }
        });

        this.staffMenuCheckInterval = setInterval(() => {
            if (Storage.get('mmcStaffMenu')) {
                let custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
                this.setState({ custom_menu }, () => {
                    // console.log(this.state.custom_menu)

                    let find_offer_received = this.state.custom_menu.find(menu => menu.title == "Offers Received")
                    if (find_offer_received) {
                        this.setState({ offers_received: true })
                        if (find_offer_received.is_add == 1 || find_offer_received.is_add == '1') this.setState({ edit_rcvOffers: true })
                        else this.setState({ edit_rcvOffers: false })
                    }
                    else this.setState({ offers_received: false, edit_rcvOffers: false })

                    let find_reloOrders = this.state.custom_menu.find(menu => menu.title == "ReloOrders")
                    if (find_reloOrders) this.setState({ manage_orders: true })
                    else this.setState({ manage_orders: false })

                    let edit_msg = this.state.custom_menu.find(menu => menu.title == 'Messages')
                    if (edit_msg) {
                        if (edit_msg.is_add == 1 || edit_msg.is_add == '1') this.setState({ edit_msg: true })
                        else this.setState({ edit_msg: false })
                    }
                    else this.setState({ edit_msg: false })
                });
            }
        }, 1000)

        this.subscriptionCheckInterval = setInterval(() => {
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1)
                this.setState({ editPermission: false })
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ editPermission: true })
        }, 1000);

    }

    componentWillUnmount() {
        if (this.staffMenuCheckInterval) {
            clearInterval(this.staffMenuCheckInterval)
        }
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
    }

    doSubscribe = (e) => {
        window.swal({
            title: "Warning!",
            text: "Please upgrade your membership to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }

    componentDidUpdate() {

        window.$('.dashboard-offer-owl-carousel').owlCarousel({
            loop: true,
            margin: 29,
            nav: true,
            navText: ['<i class="icon-arrow-left8"></i>', '<i class="icon-arrow-right8"></i>'],
            dots: false,
            autoHeight: true,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                576: {
                    items: 2
                },
            }
        });
    }
    CheckFirstLogin = () => {
        if (Storage.get('imnFirstLogin') == 1) {
            this.afterSubscription()
        } else {
            this.getRedirectSubscription()
        }
    }

    registerMsgNotifications() {
        this.database.ref('/notifications/msg_compose_reply/user_' + (JSON.parse(Storage.get('imnLoginData'))).id)
            .on('child_added', (snapshot) => {
                this.setState({ notificationStatus: 'message' });
                let unreadMessages = this.state.unreadMessages;
                let tempData = snapshot.val();
                tempData['nodeUrl'] = '/notifications/msg_compose_reply/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '/' + snapshot.key;
                unreadMessages.push(tempData);
                this.setState({ unreadMessages: unreadMessages }, () => {
                    // console.log(this.state.unreadMessages)
                });
            })
    }

    afterSubscription = () => {
        this.getCompletionStatus()
        this.FindDriversHelpersForMMC();
        this.FindMoversForMMC();
        this.offersReceived();
        this.ongoingOrders();
        this.assigningOrders();
        this.MessageListInbox();
        this.props.getInboxCount();
    }

    GetUserInfo = () => {

        GetData('GetUserInfo', {})
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ subscription_id: response.data.subscription_id });
                }
            })
            .catch(err => { })
    }

    getCompanyInfo = () => {

        PostDataWithParam('GetCompanyInfo', {})
            .then((response) => {
                if (response.success === 1) {
                    const data = response.data[0];
                    this.setState({ isScac: data.is_scac, scacName: data.scac_name })
                } else {
                    this.setState({ isScac: 0 })
                }
            })
            .catch(err => {
                this.setState({ isScac: 0 })
            })
    }

    getRedirectSubscription = () => {
        this.setState({ loading: true }, () => {
            PostDataWithParam('CheckFirstLogin', {})
                .then((response) => {
                    if (response.success == 1) {
                        if (response.data[0].first_login == 2 && response.data[0].subscription_id == 1) {
                            Storage.set('imnFirstLogin', 1);
                            this.setState({ redirectSubscription: true }, () => {
                                this.afterSubscription()
                            });
                        } else {
                            this.setState({ redirectSubscription: false }, () => {
                                this.afterSubscription()
                            });
                        }
                    } else {
                        this.setState({ redirectSubscription: false }, () => {
                            this.afterSubscription()
                        });
                    }
                })
                .catch(err => {
                    this.setState({ redirectSubscription: false }, () => {
                        this.afterSubscription()
                    });
                })
        })
    }

    MessageListInbox = () => {
        let dep = [];
        for (let i = 0; i < this.state.departments.length; i++) {
            dep.push({ profile_id: this.state.departments[i] });
        }
        this.setState({ loadingMessage: true }, () => {
            PostDataWithParam('MessageList', { list_type: 1, departments: JSON.stringify(dep) })
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ messages: response.data, loadingMessage: false });
                    }
                    else {
                        this.setState({ messages: [], loadingMessage: false });
                    }
                })
                .catch(err => {
                    this.setState({ messages: [], loadingMessage: false });
                })
        })
    }

    stripTags = (html) => {
        const regex = /(<([^>]+)>)/ig;
        const result = html.replace(regex, '');
        return (
            result
        )
    }

    spanColor = (first_letter, index) => {
        let color = '';
        if (index % 4 == 1)
            color = 'primary'
        else if (index % 4 == 2)
            color = 'indigo'
        else if (index % 4 == 3)
            color = 'danger'
        else if (index % 4 == 0)
            color = 'success'
        return (
            <span className={"btn bg-" + color + "-400 btn-rounded btn-icon btn-xs"}>
                <span className="letter-icon">{first_letter.toUpperCase()}</span>
            </span>
        )
    }

    mediaClass = (status) => {
        if (status == 1)
            return "media"
        else
            return "media alpha-slate unread"
    }

    updateStatus = (id, status) => {
        if (status == 0) {
            let findMsg = this.state.unreadMessages.find(msg => msg.msg_id === parseInt(id));
            PostDataWithParam('UpdateReadStatus', { message_id: id })
                .then((response) => {
                    if (response.success === 1) {
                        if (findMsg) {
                            this.database.ref(findMsg.nodeUrl).remove();
                        }
                        this.setState({ updateStatus: true });
                    }
                })
        }
    }

    FindDriversHelpersForMMC = () => {
        PostDataWithParam('FindDriversHelpersForMMC', { offset: 0, search_text: '' })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ total_helpers: response.data.count });
                }
            })
    }

    FindMoversForMMC = () => {
        PostDataWithParam('FindMoversForMMC', { offset: 0, search_text: '' })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ total_mmc: response.data.count });
                }
            })
    }

    GetApprovalProgress = () => {
        PostDataWithParam('GetApprovalProgress', {})
            .then((response) => {
                if (response.success == 1) {
                    this.setState({ military_percentage: response.data.percentage }, () => {
                        // this.OrderPieChart()
                    });
                }
            })
            .catch(err => {
                this.setState({ military_percentage: 0 });
            })
    }

    getCompletionStatus = () => {
        GetData('GetProfileCompleteStatus')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ profile_percentage: response.completion_percentage }, () => {
                        this.GetApprovalProgress()
                    });
                }
            })
            .catch(err => {
                this.setState({ profile_percentage: '' });
            })
    }

    acceptRejectOffers(status, id, e) {
        e.preventDefault();
        PostDataWithParam('AcceptRejectOffer', { offer_id: id, status: status })
            .then(response => {
                if (response.success === 1) {
                    window.swal('Success!', response.msg, 'success');
                    this.setState({ loading: true }, () => {
                        this.offersReceived();
                        this.setState({ loading: false });
                    })
                } else {
                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => { })
    }
    // OrderPieChart = () => {
    //     PostDataWithParam('OrderPieChart', {})
    //         .then((response) => {
    //             if (response.success === 1) {
    //                 let data = [{
    //                     name: 'Offers Received',
    //                     y: parseInt(response.data['os']),
    //                     count: parseInt(response.data['os'])
    //                 }, {
    //                     name: 'Offers Accepted',
    //                     y: parseInt(response.data['oa']),
    //                     count: parseInt(response.data['oa']),
    //                     sliced: true,
    //                     selected: true
    //                 }, {
    //                     name: 'Offers Pending',
    //                     y: parseInt(response.data['op']),
    //                     count: parseInt(response.data['op']),
    //                     sliced: true,
    //                     selected: true
    //                 }]
    //                 this.setState({ pieData: data, loading: false }, () => {
    //                     MmcChart(this.state.pieData)
    //                 });
    //             }
    //         })
    //         .catch(err => { })
    // }

    offersReceived = () => {
        this.setState({ loadingOffersReceived: true }, () => {
            PostDataWithParam('RecivedAndAcceptedOrdersListForMovers', { is_dashboard: 1, order_type: this.state.orderType })
                .then(response => {
                    if (response.success === 1) {
                        this.setState({ offers: response.data, loadingOffersReceived: false })
                    } else {
                        this.setState({ offers: [], loadingOffersReceived: false })
                    }
                })
                .catch(err => {
                    this.setState({ offers: [], loadingOffersReceived: false })
                })
        })
    }
    ongoingOrders = () => {
        this.setState({ loadingOngoingOrders: true }, () => {
            PostDataWithParam('GetOrderListForMovers', { is_dashboard: 1 })
                .then(response => {
                    if (response.success === 1) {
                        this.setState({ ongoing_orders: response.data, loadingOngoingOrders: false })
                    } else {
                        this.setState({ ongoing_orders: [], loadingOngoingOrders: false })
                    }
                })
                .catch(err => {
                    this.setState({ ongoing_orders: [], loadingOngoingOrders: false })
                })
        })
    }
    assigningOrders = () => {
        this.setState({ loadingAssigningOrders: true }, () => {
            PostDataWithParam('GetOrderListForResourcesToBeAssigned', { is_dashboard: 1 })
                .then(response => {
                    if (response.success === 1) {
                        this.setState({ assign_orders: response.data, loadingAssigningOrders: false })
                    } else {
                        this.setState({ assign_orders: [], loadingAssigningOrders: false })
                    }
                })
                .catch(err => {
                    this.setState({ assign_orders: [], loadingAssigningOrders: false })
                })
        })
    }
    renderOrderType = (hire_by_user_id) => {
        if (hire_by_user_id === (JSON.parse(Storage.get('imnLoginData'))).id)
            return ''
        else
            return 'accepted'
    }
    getMoverAppzSubDetails = () => {
        PostDataWithParam('MoverAppzSubscriptionDetails', { user_id: this.getLoginUserData().id })
            .then((response) => {
                if (response.success === 1) {
                    if (response.data.is_moverappz_payment_count === 0) {
                        this.props.history.push("/independent-mover/dashboard/moverAppz-subscription");
                    }
                } else {
                }
            })
            .catch(err => {
            })

    }
    openShareAppModal = async (e) => {
        try {
            let response = await PostDataWithParam('MoverAppzSubscriptionDetails', { user_id: JSON.parse(Storage.get('imnLoginData')).id });
            const { success, data } = response;
            if (success === 0) {
                window.swal('Error!', 'Something went wrong', 'error');
                return;
            }
            if (success === 1 && data.mover_appz_expiry_status === 0) {
                this.setState({
                    form_data: { ...initial_form_data }, f_name_err: '',
                    l_name_err: '',
                    contact_no_err: '',
                    email_id_err: '',
                    user_type_err: '',
                    type: '1'
                })
                window.$('#shareAppModal').modal({
                    backdrop: 'static',
                    keyboard: false
                });
                window.$("#shareAppModal").modal('show');
            } else {
                window.swal({
                    title: "Warning!",
                    text: 'Please Upgrade Your MoverAppz Subscription to Complete this Action.',
                    className: "subscrip-info-swal",
                    icon: "",
                    // buttons: ["Cancel", "Upgrade"],
                })
                    .then((result) => {
                        if (result) {
                            this.props.history.push({
                                pathname: "/independent-mover/dashboard/moverAppz-subscription"
                            })
                        }
                    });
            }
        } catch (err) {
            console.log(err)
            window.swal('Error!', 'Something went wrong', 'error');
        }


    }
    shareAppDataSave = (e) => {

        if (this.state.form_data.user_type == '') this.setState({ user_type_err: 'Please select user type' })
        if (this.state.form_data.f_name == '') this.setState({ f_name_err: 'Please enter first name' })
        if (this.state.form_data.l_name == '') this.setState({ l_name_err: 'Please enter last name' })
        if (this.state.form_data.email_id == '') this.setState({ email_id_err: 'Please enter primary email id' })
        if (this.state.form_data.contact_no == '') this.setState({ contact_no_err: 'Please enter primary phone no' })
        // if (this.state.form_data.secondary_email_id == '') this.setState({ email_id_err: 'Please enter secondary email id' })
        // if (this.state.form_data.secondary_contact_no == '') this.setState({ contact_no_err: 'Please enter secondary phone no' })

        else {
            let form_data = { ...this.state.form_data };
            PostDataWithParam('ShareAppWithFormDetails', form_data)
                .then((response) => {
                    let result = response;

                    if (result.success === 1) {
                        window.$('#shareAppModal').modal('hide');
                        window.swal('Success!', response.msg, 'success');

                    } else {
                        this.setState({ loading: false });
                        window.swal('Error!', response.msg, 'error');
                    }
                })
                .catch(err => {
                    this.setState({ loading: false });
                    window.swal('Error!', 'Something went wrong3', 'error');
                })
        }
    }
    handleOnChange = (e) => {
        let form_data = Object.assign({}, this.state.form_data)
        form_data[e.target.name] = e.target.value
        if (e.target.name == 'contact_no' || e.target.name == 'secondary_contact_no') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                form_data[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
        }
        this.setState({ form_data: form_data, global_search_text: e.target.value });
    }
    handleSearchOnEnterKeyPress = (e) => {
        const code = e.keyCode || e.which;
        if (code === 13) {
            this.search();
        }
    }
    handleChange(event) {
        this.setState({ type: event.currentTarget.value });
    }

    search() {
        this.searchAllOrder()
    }

    searchAllOrder = () => {
        if (this.state.global_search_text == "" || this.state.final_search_text == this.state.global_search_text) return;
        this.setState({
            searchoffset: 0,
            searchloadMore: 0,
            searchtotal: 0,
            searchOffers: [],
            searchloading: true,
            loadMore: 0,
            search_total_count: 0,
            final_search_text: this.state.global_search_text
        }, () => {
            PostDataWithParam('GlobalOrderSearch', { order_type: this.state.orderType, search_text: this.state.final_search_text, offset: this.state.offset, order_by: 'desc' })
                .then(response => {
                    if (response.success === 1) {
                        let searchOffers = this.state.searchOffers;
                        if (searchOffers) {
                            searchOffers = [...searchOffers, ...response.data]
                        }
                        else {
                            searchOffers = response.data
                        }
                        this.setState({ searchOffers: searchOffers, loadMore: response.load_more, total: response.data.count, search_total_count: response.total_count }, () => {
                            // let offset = this.state.offset;
                            // if (offset == 0) {
                            //     offset = this.state.limit;
                            // } else {
                            //     offset += this.state.limit;
                            // }
                            // this.setState({ offset: offset });
                        })
                    } else {
                        // window.swal('Sorry!', 'No results found', 'error')
                        this.setState({ searchOffers: [], offset: 0, total: 0 })
                    }
                })
                .catch(err => {
                    window.swal('Error!', 'Something went wrong', 'error')
                    this.setState({ searchOffers: [] })
                })
                .finally(() => {
                    // this.getEDCOrders()
                })
        })
    }

    // getEDCOrders = () => {
    //     this.setState({ loading: true, loadMore: 0, total_count: 0 }, async () => {
    //         await this.getCompanyInfo();
    //         PostDataWithParam('EDCOrder', { search_text: this.state.final_search_text })
    //             .then(res => {
    //                 if (res && res.Results) {
    //                     this.setState({
    //                         edcOrders: res.Results.filter((order) => order.SCAC === this.state.scacName),
    //                         searchloading: false
    //                     });
    //                 }
    //                 else {
    //                     this.setState({ edcOrders: [], offset: 0, total: 0, searchloading: false })
    //                 }
    //             })
    //             .catch(err => {
    //                 this.setState({ edcOrders: [], offset: 0, total: 0, searchloading: false })
    //             })
    //     })
    // }

    reconsiderOffer = (id, e) => {
        e.preventDefault();
        PostDataWithParam('reconsiderOffer', { order_id: id })
            .then(response => {
                if (response.success === 1) {
                    window.swal('Success!', response.msg, 'success');
                    this.setState({ orderType: 0, isDeclined: false }, () => {
                        this.offersReceived();
                    })

                } else {
                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => {

            })
    }

    getEDCPickupAddress = (order) => {
        let address = ""
        address += order.PickupStreet ? `${order.PickupStreet}, ` : ``;
        address += order.PickupCity ? `${order.PickupCity}, ` : ``;
        address += `${order.PickupState}`
        return address;
    }
    getEDCDeliveryAddress = (order) => {
        let address = ""
        address += order.DeliveryStreet ? `${order.DeliveryStreet},` : ``;
        address += order.DeliveryCity ? `${order.DeliveryCity},` : ``;
        address += `${order.DeliveryState}`
        return address;
    }

    renderAllSearch = () => {
        let list = [...this.state.searchOffers, ...this.state.edcOrders]
        if (list.length > 0) {
            // console.log(this.state.searchOffers, 'test');
            // console.log(list);
            let offersSearch = list.map((item, i) => {
                // if (item.ShipmentId) {
                //     return (
                //         <div className="item article-items" key={'offers-' + i}>
                //             <div className="order-item-box">
                //                 <div className="order-item">
                //                     <div className="order-item-details">
                //                         <div className="order-item-header">
                //                             <h4 className="m-0">Shipment # {item.ShipmentId}</h4>
                //                             <p className="text-semibold text-muted overflow-ellipsis"><i> {item.FirstName} {item.LastName}</i></p>

                //                             <div className="form-group p-b-0">
                //                                 <div className="form-group">
                //                                     <label className="text-bold f-s-15 m-0">Service Pickup Address</label>
                //                                     <p className="f-s-16 m-0 text-muted overflow-ellipsis">
                //                                         <i className="icon-location3 m-r-10"></i>{this.getEDCPickupAddress(item)}</p>
                //                                 </div>
                //                                 <div className="form-group m-b-0">
                //                                     <label className="text-bold f-s-15 m-0">Service Delivery Address</label>
                //                                     <p className="f-s-16 m-0 text-muted overflow-ellipsis">
                //                                         <i className="icon-location3 m-r-10"></i>{this.getEDCDeliveryAddress(item)}</p>
                //                                 </div>
                //                             </div>

                //                         </div>
                //                         <div className="order-item-footer">
                //                             <div className="row">
                //                                 <div className="col-sm-6">
                //                                     <label className="text-bold f-s-15 m-0">Pack Date</label>
                //                                     <p className="f-s-16 m-t-0 text-muted"><i className="icon-calendar22"></i>&nbsp;{DateFormat.getFormattedDateTime(item.PackDate, 'm-d-Y')}</p>
                //                                 </div>
                //                                 <div className="col-sm-6">
                //                                     <label className="text-bold f-s-15 m-0">Load Date</label>
                //                                     <p className="f-s-16 m-t-0 text-muted"><i className="icon-calendar22"></i>&nbsp;{DateFormat.getFormattedDateTime(item.PickupDate, 'm-d-Y')}</p>
                //                                 </div>
                //                             </div>

                //                             <span className="bg-green-700 badge m-t-15">{item.Status}</span>
                //                         </div>
                //                         {this.state.editPermission ?
                //                             (this.state.manage_orders || this.state.edit_rcvOffers) &&
                //                             <React.Fragment>
                //                                 <div className="overlay">
                //                                     <Link to={{
                //                                         pathname: '/independent-mover/dashboard/edc-order-details',
                //                                         state: item
                //                                     }} className="btn btn-success btn-xs btn-block">View details</Link>
                //                                 </div>
                //                             </React.Fragment>
                //                             :
                //                             <div className="overlay">
                //                                 <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-success btn-xs btn-block" onClick={this.doSubscribe}>View details</Link>
                //                             </div>
                //                         }
                //                     </div>
                //                 </div>
                //             </div>
                //         </div>
                //     )
                // }
                return (
                    <div className="item article-items" key={'offers-' + i}>

                        <div className="order-item-box">
                            <div className="order-item">
                                <div className="order-item-details">
                                    <div className="order-item-header">
                                        {(item.order_type == 1 || item.order_type == '1') ? <h4 className="m-0">Shipment #{item.order_id}</h4> :
                                            <>
                                                {item.military_flag == 1 ? <h4 className="m-0">GBL #{item.order_id}</h4> : <h4 className="m-0">Relo #{item.order_id}</h4>}
                                            </>
                                        }
                                        <p className="text-semibold text-muted overflow-ellipsis"><i>{item.posted_by} posted on {DateFormat.getFormattedDateTime(item.posted_date, 'm-d-Y')}</i></p>
                                        <p><i><label className="text-bold m-0">Weight of shipment :</label> {(item.customer_estimated_weight)}</i></p>
                                        <div className="form-group p-b-0">
                                            <div className="form-group">
                                                <label className="text-bold f-s-15 m-0">Service Origin Address</label>
                                                <p className="f-s-16 m-0 text-muted overflow-ellipsis"><i className="icon-location3 m-r-10"></i>{item.source_address ? ((item.source_address && item.source_address.length > 30) ? item.source_address.substr(0, 30) + '...' : item.source_address) : '...'}</p>
                                            </div>
                                            <div className="form-group m-b-0">
                                                <label className="text-bold f-s-15 m-0">Service Destination Address</label>
                                                <p className="f-s-16 m-0 text-muted overflow-ellipsis"><i className="icon-location3 m-r-10"></i>{item.destination_address ? ((item.destination_address && item.destination_address.length > 30) ? item.destination_address.substr(0, 30) + '...' : item.destination_address) : '...'}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="order-item-footer m-b-0">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <label className="text-bold f-s-15 m-0"> Pack Date  </label>
                                                <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22"></i>&nbsp;{DateFormat.getFormattedDateTime(item.requested_pack_date, 'm-d-Y')}</p>
                                            </div>
                                            <div className="col-sm-6">
                                                <label className="text-bold f-s-15 m-0">Load Date</label>
                                                <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22"></i>&nbsp;
                                                    {item.request_start_date != null ? <label>{DateFormat.getFormattedDateTime(item.request_start_date, 'm-d-Y')}</label> : ""}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {item.tracking_status != "" ?
                                        <div className="mover-tracking-status p-b-0">
                                            <p className={item.tracking_status === 'Packing Completed' ? "success-status f-s-15 " + 'overflow-ellipsis m-b-0' + 'overflow-ellipsis m-b-0' : "warning-status f-s-15 " + 'overflow-ellipsis m-b-0'}><span className=""></span>{item.tracking_status}</p>
                                        </div> : ""
                                    }

                                    {this.state.editPermission ?
                                        (this.state.manage_orders || this.state.edit_rcvOffers) &&
                                        <React.Fragment>
                                            {(item.type == 'Offers Received-My Offers' || item.type == 'Offers Received-Accepted Offers') &&
                                                <div className="overlay">
                                                    {item.hire_by_profile_id != 2 ?
                                                        <Link to={{
                                                            pathname: '/independent-mover/dashboard/my-offer-details',
                                                            state: {
                                                                id: item.id,
                                                                type: 'received',
                                                                post_date: item.request_date_time,
                                                                post_by: item.posted_by,
                                                                orderType: this.state.orderType,
                                                                actionType: 'SEND_PROPOSAL',
                                                                customer_id: item.customer_id
                                                            }
                                                        }} className="btn btn-success btn-xs btn-block">View details</Link>
                                                        :
                                                        <Link to={{
                                                            pathname: '/independent-mover/dashboard/order-details',
                                                            state: {
                                                                id: item.id,
                                                                type: this.renderOrderType(item.hire_by_user_id),
                                                                request_from: item.posted_by
                                                            }
                                                        }} className="btn btn-success btn-xs btn-block">View details</Link>
                                                    }
                                                </div>
                                            }
                                            {(item.type == 'Offers Received-Declined Offers') &&
                                                <div className="overlay">
                                                    <a href="" className="btn btn-success btn-xs btn-block" onClick={this.reconsiderOffer.bind(this, item.id)}>Reconsider</a>
                                                </div>
                                            }
                                            {(item.type == 'Manage ReloOrders') &&
                                                <div className="overlay">
                                                    <Link to={{
                                                        pathname: '/independent-mover/dashboard/order-details',
                                                        state: {
                                                            id: item.id,
                                                            type: this.renderOrderType(item.hire_by_user_id),
                                                            request_from: item.posted_by
                                                        }
                                                    }} className="btn btn-success btn-xs btn-block">View details</Link>
                                                </div>
                                            }
                                            {(item.type == 'Closed ReloOrders') &&
                                                <div className="overlay">
                                                    <Link to={{
                                                        pathname: '/independent-mover/dashboard/closed-order-details',
                                                        state: {
                                                            id: item.id,
                                                            type: this.renderOrderType(item.hire_by_user_id)
                                                        }
                                                    }} className="btn btn-success btn-xs btn-block">View details</Link>
                                                </div>
                                            }
                                        </React.Fragment>
                                        :
                                        <div className="overlay">
                                            <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-success btn-xs btn-block" onClick={this.doSubscribe}>View details</Link>
                                        </div>
                                    }
                                </div>

                                {/* <hr className="m-0" /> */}

                                {/* <div className="order-bottom-footer clearfix p-l-15 p-r-15 p-10">
                                    {this.state.editPermission ?
                                        (this.state.manage_orders && this.state.edit_rcvOffers) &&
                                        <React.Fragment>
                                            {(item.type == 'Offers Received-My Offers' || item.type == 'Offers Received-Accepted Offers') &&
                                                <div className="overlay">
                                                    {item.hire_by_profile_id != 2 ?
                                                        <Link to={{
                                                            pathname: '/independent-mover/dashboard/my-offer-details',
                                                            state: {
                                                                id: item.id,
                                                                type: 'received',
                                                                post_date: item.request_date_time,
                                                                post_by: item.posted_by,
                                                                orderType: this.state.orderType,
                                                                actionType: 'SEND_PROPOSAL',
                                                                customer_id: item.customer_id
                                                            }
                                                        }} className="pull-right text-muted p-l-10"><i className="icon-arrow-right8"></i></Link>
                                                        :
                                                        <Link to={{
                                                            pathname: '/independent-mover/dashboard/order-details',
                                                            state: {
                                                                id: item.id,
                                                                type: this.renderOrderType(item.hire_by_user_id),
                                                                request_from: item.posted_by
                                                            }
                                                        }} className="pull-right text-muted p-l-10"><i className="icon-arrow-right8"></i></Link>
                                                    }
                                                </div>
                                            }
                                           
                                            {(item.type == 'Manage ReloOrders') &&
                                                <div className="overlay">
                                                    <Link to={{
                                                        pathname: '/independent-mover/dashboard/order-details',
                                                        state: {
                                                            id: item.id,
                                                            type: this.renderOrderType(item.hire_by_user_id),
                                                            request_from: item.posted_by
                                                        }
                                                    }} className="pull-right text-muted p-l-10"><i className="icon-arrow-right8"></i></Link>
                                                </div>
                                            }
                                            {(item.type == 'Closed ReloOrders') &&
                                                <div className="overlay">
                                                    <Link to={{
                                                        pathname: '/independent-mover/dashboard/closed-order-details',
                                                        state: {
                                                            id: item.id,
                                                            type: this.renderOrderType(item.hire_by_user_id)
                                                        }
                                                    }} className="pull-right text-muted p-l-10"><i className="icon-arrow-right8"></i></Link>
                                                </div>
                                            }
                                        </React.Fragment>
                                        :
                                        <Link to="/independent-mover/dashboard/my-subscription" className="pull-right text-muted p-l-10" onClick={this.doSubscribe}><i className="icon-arrow-right8"></i></Link>
                                    }
                                </div> */}
                            </div>
                        </div>
                    </div>
                )
            });
            return offersSearch;
        }
        else {
            return <div className="panel panel-flat">
                <div className="panel-heading clearfix">
                    <h6 class="text-primary m-0">No Order Found.</h6>
                </div>
            </div>
        }
    }


    copyLink = async (e) => {
        e.preventDefault();
        try {
            let response = await PostDataWithParam('MoverAppzSubscriptionDetails', { user_id: JSON.parse(Storage.get('imnLoginData')).id });
            const { success, data } = response;
            if (success === 0) {
                window.swal('Error!', 'Something went wrong', 'error');
                return;
            }
            if (success === 1 && data.mover_appz_expiry_status === 0) {
                // -----------------------copy share link work start ------------------
                let link = "Thank you for joining the Relocation Revolution and use our easy to use app!<br/><br/>";
                for (let key in data) {
                    let obj = data[key];
                    if (['military_customer', 'non_military_customer', 'relo_pro'].includes(key) && !!obj) {
                        link += `<b>${obj.tittle}</b><br/>`;
                        link += `${obj.links.android_link} <br/>`;
                        link += `${obj.links.ios_link} <br/>`;
                        link += "<br/></br>";
                    }

                }
                copyHTML(link);
                this.setState({ isCopied: true });
                setTimeout(() => {
                    this.setState({ isCopied: false });
                }, 2000)
                // ----------------------------------- End --------------------------------------
            } else {
                window.swal({
                    title: "Warning!",
                    text: 'Please Upgrade Your MoverAppz Subscription to Complete this Action.',
                    className: "subscrip-info-swal",
                    icon: "",
                    // buttons: ["Cancel", "Upgrade"],
                })
                    .then((result) => {
                        if (result) {
                            this.props.history.push({
                                pathname: "/independent-mover/dashboard/moverAppz-subscription"
                            })
                        }
                    });
            }
        } catch (err) {
            console.log(err)
            window.swal('Error!', 'Something went wrong', 'error');
        }


    }

    render() {
        if (this.state.redirectSubscription) {
            return <Redirect to={{
                pathname: '/independent-mover/dashboard/my-subscription'
            }} />
        }
        const searchList = [...this.state.searchOffers, ...this.state.edcOrders];
        return (
            <div className="row">
                {this.state.isCopied && (<div className="row">
                    <div className="col-md-12 text-center">
                        <div className="alert alert-success">
                            <i className="icon-checkmark-circle m-r-5"></i> Link Has Been Copied Successfully.
                        </div>
                    </div>
                </div>)}
                {/* {
                    this.state.loading
                    &&
                    <div className="col-md-12">
                        <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please Wait...</div>
                    </div>
                } */}
                {/* {
                    !this.state.loading
                    &&
                    <div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-xs-6">
                                    <div className="panel bg-teal-400">
                                        <div className="panel-body">
                                            <div className="heading-elements">
                                                <i className="icon-briefcase3 fa-3x"></i>
                                            </div>
                                            <h3 className="no-margin">{this.state.total_helpers}</h3>
                                    ReloHelpers
                                </div>
                                    </div>

                                </div>

                                <div className="col-xs-6">

                                    <div className="panel bg-pink-400">
                                        <div className="panel-body">
                                            <div className="heading-elements">
                                                <i className="icon-truck fa-3x"></i>
                                            </div>
                                            <h3 className="no-margin">{this.state.total_mmc}</h3>
                                    Movers
                                </div>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="panel panel-flat">
                                        <div className="panel-heading">
                                            <h6 className="panel-title">Percentage of Profile Completed</h6>
                                        </div>
                                        <div className="panel-body">
                                            <div className="p-t-35">
                                                <div className="row">
                                                    <div className="col-xs-6 p-b-20">
                                                        <div id="radialProgressBar"></div>
                                                        <text id="count" style={{ display: 'none' }}>{this.state.profile_percentage}</text>
                                                    </div>
                                                    <div className="col-xs-6 p-t-40 f-s-16">
                                                        Basic Mover Profile
                                            </div>
                                                </div>
                                            </div>
                                            <div className="p-b-30">
                                                {
                                                    this.state.military_percentage > 0
                                                    &&
                                                    <div className="row">
                                                        <div className="col-xs-6">
                                                            <div id="radialProgressBarTwo"></div>
                                                            <text id="count2" style={{ display: 'none' }}>{this.state.military_percentage}</text>
                                                        </div>
                                                        <div className="col-xs-6 p-t-40 f-s-16">
                                                            Get Military Approved Profile
                                                </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.military_percentage == 0
                                                    &&
                                                    <div className="row">
                                                        <div className="col-xs-6 graph-value-zero">
                                                            <div className="zero-value">
                                                                <span> 0%</span>
                                                            </div>
                                                            <div id="radialProgressBarTwo"></div>
                                                            <text id="count2" style={{ display: 'none' }}>11</text>
                                                        </div>
                                                        <div className="col-xs-6 p-t-40 f-s-16">
                                                            Get Military Approved Profile
                                                </div>
                                                    </div>
                                                }

                                            </div>

                                            <Link to="/independent-mover/dashboard/military-approved/profile-tab" className="btn btn-primary btn-block btn-lg">Complete your profile</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="panel panel-flat for-PremiumFeature-graph">
                                        <div className="panel-heading">
                                            <h6 className="panel-title">Offers to ReloHelpers & Movers</h6>
                                        </div>

                                        <div className="panel-body">
                                            <div id="claim-pie-chart" style={style1}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-lg-4">
                            <div className="panel panel-flat">
                                <div className="panel-heading">
                                    <h6 className="panel-title text-slate">Offers Received</h6>
                                    <div className="heading-elements">
                                        <Link to="/independent-mover/dashboard/find-an-offer" className="btn btn-primary btn-xs">View All</Link>
                                    </div>
                                </div>
                                <div className="panel-body p-0">
                                    <ul className="media-list">
                                        {
                                            this.state.offers.length > 0
                                            &&
                                            this.state.offers.map((item, i) => {
                                                if (i < 3) {
                                                    return (
                                                        <li className="media m-t-0" key={'offers-' + i}>
                                                            <Link
                                                                to={{
                                                                    pathname: '/independent-mover/dashboard/my-offer-details',
                                                                    state: {
                                                                        id: item.id,
                                                                        type: 'received'
                                                                    }
                                                                }} className="link-msg-details">
                                                                <div className="media-body">
                                                                    <ul className="list-inline list-inline-separate text-muted mb-5">
                                                                        <li><i className="icon-user position-left"></i> {item.posted_by}</li>
                                                                        <li>{DateFormat.getFormattedDateTime(item.request_date_time, 'm-d-Y')}</li>
                                                                    </ul>
                                                                    <p className="text-bold m-b-0 text-grey-800">#{item.order_id}</p>
                                                                    {
                                                                        item.relocation_notes != null
                                                                            ?
                                                                            <p className="display-block text-grey-800">{(item.relocation_notes.length < 40) ? item.relocation_notes : item.relocation_notes.substr(0, 40) + '...'}</p>
                                                                            : <p className="display-block text-grey-800"></p>
                                                                    }

                                                                </div>
                                                            </Link>
                                                        </li>
                                                    )
                                                }
                                            })
                                        }
                                        {
                                            this.state.offers.length == 0
                                            &&
                                            <div className="alert alert-danger text-center">No offers found.</div>
                                        }
                                    </ul>
                                </div>
                            </div>

                            <div className="panel panel-flat">
                                <a href="inbox.php">
                                    <div className="panel-heading">
                                        <h6 className="panel-title text-slate"><i className="icon-envelope"></i> Inbox</h6>
                                        <div className="heading-elements">
                                            <span className="label bg-warning heading-text">{this.props.InboxCount} Unread</span>
                                        </div>
                                    </div>
                                </a>

                                {
                                    this.state.messages.length != 0
                                    &&
                                    <ul className="media-list panel-body p-0 inbox-list dashboard">
                                        {this.state.messages.map((message, index) => {
                                            if (index < 3)
                                                return (
                                                    <li key={message.id} className={this.mediaClass(message.msg_read_status)}>
                                                        <Link to={{ pathname: '/independent-mover/dashboard/messages/inbox-details/' + message.id }} className="link-msg-details" onClick={() => this.updateStatus(message.id, message.msg_read_status)}>
                                                            {
                                                                message.profile_image == ""
                                                                &&
                                                                <div className="media-left">
                                                                    {this.spanColor(message.full_name[0], index)}
                                                                </div>
                                                            }
                                                            {
                                                                message.profile_image != ""
                                                                &&
                                                                <div className="media-left">
                                                                    <img src={message.profile_image} alt="" className="img-circle img-xs" />
                                                                </div>
                                                            }
                                                            <div className="media-body">
                                                                {message.full_name}
                                                                <span className="media-annotation pull-right">{DateFormat.getFormattedDateTime(message.msg_time, 'm-d-Y, h:i')}</span>
                                                                <span className="display-block text-muted">{message.subject}</span>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                )
                                            else
                                                return false
                                        }
                                        )}
                                    </ul>
                                }
                                {
                                    this.state.messages.length == 0
                                    &&
                                    <div className="alert alert-danger text-center">No Messages Found.</div>
                                }

                            </div>
                        </div>
                    </div>
                } */}
                <div>
                    <div className="col-lg-8">

                        {/*  Start Search Result */}
                        <div className="row">
                            <div className="col-sm-10">
                                <div className="form-group m-b-15">
                                    <input type="text" name="global_search_text" className="form-control"
                                        placeholder="Search by Relo Id, name, city, state, zip etc"
                                        style={{ 'height': '50px' }}
                                        value={this.state.global_search_text}
                                        onChange={this.handleOnChange.bind(this)}
                                        onKeyPress={this.handleSearchOnEnterKeyPress}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-2">
                                <button className="btn btn-primary btn-block f-s-15" onClick={this.search.bind(this)} style={{ 'height': '50px' }}>Search</button>
                            </div>
                        </div>

                        {this.state.searchloading
                            &&
                            <div className="panel panel-flat">
                                <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please Wait...</div>
                            </div>
                        }

                        {!this.state.searchloading && (searchList.length > 0) &&
                            <div className="panel panel-flat">
                                <div className="panel-heading clearfix p-t-10 p-b-10">
                                    <div className="pull-left">
                                        <p className="text-blue m-0 f-s-16"><i className="icon-stack-check"></i><span className="m-l-5">Offer(s) found</span></p>
                                    </div>
                                    <div className="pull-right">
                                        <p className="text-danger m-0 f-s-14">{searchList.length} Offer(s) found of {searchList.length}
                                        </p>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <div className="panel-body">
                                    <div className="offer-box-wrap">
                                        <div className={searchList.length > 1 ? "dashboard-offer-owl-carousel owl-carousel owl-theme" : ""} >
                                            {this.renderAllSearch()}
                                        </div>
                                    </div>
                                    {this.state.loadMore == 1 &&
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <a href="javascript:void(0)" className="btn btn-primary m-b-20" onClick={this.searchAllOrder.bind(this)}>Load More</a>
                                            </div>
                                        </div>
                                    }
                                </div>


                            </div>
                        }

                        {/* {!this.state.searchloading && (this.state.searchOffers.length == 1) &&
                            <div className="panel panel-flat">
                                <div className="panel-heading clearfix p-t-10 p-b-10">
                                    <div className="pull-left">
                                        <p className="text-blue m-0 f-s-16"><i className="icon-stack-check"></i><span className="m-l-5">Offer(s) found</span></p>
                                    </div>
                                    <div className="pull-right">
                                        <p className="text-danger m-0 f-s-14">{this.state.searchOffers.length} Offer(s) found of {this.state.search_total_count}</p>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <div className="panel-body">
                                    <div className="offer-box-wrap">
                                        <div className="dashboard-offer-owl-carousel owl-carousel owl-theme" >
                                            {this.renderAllSearch()}
                                        </div>
                                    </div> */}
                        {/* {this.state.loadMore == 1 &&
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <a href="javascript:void(0)" className="btn btn-primary m-b-20" onClick={this.searchAllOrder.bind(this)}>Load More</a>
                                            </div>
                                        </div>
                                    } */}
                        {/* </div> */}
                        {/* {this.state.searchOffers
                                    &&
                                    <div className="panel-footer text-right p-15">
                                        <Link to="/independent-mover/dashboard/find-an-offer" className="text-primary f-s-15">View all <i className="icon-arrow-right8"></i></Link>
                                    </div>
                                } */}

                        {/* </div>
                        } */}

                        {/*  End Search Result */}

                        <div className="panel panel-flat">
                            <div className="panel-heading clearfix p-t-10 p-b-10">
                                <div className="pull-left">
                                    <p className="text-violet m-0 f-s-16"><i className="icon-download7"></i><span className="m-l-5">Offers received</span></p>
                                </div>
                                {this.state.offers_received
                                    &&
                                    <div className="pull-right">
                                        <p className="text-danger m-0 f-s-14">{this.state.offers.length} Offers received</p>
                                    </div>
                                }
                            </div>
                            <hr className="m-0" />

                            <div className="panel-body">
                                <div className="offer-box-wrap">
                                    {
                                        this.state.loadingOffersReceived
                                        &&
                                        <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please Wait...</div>
                                    }
                                    {
                                        !this.state.loadingOffersReceived
                                        &&
                                        this.state.offers.length > 0
                                        // (this.state.offers.length > 1? className="dashboard-offer-owl-carousel owl-carousel owl-theme":'')
                                        &&
                                        this.state.offers_received
                                        &&
                                        <div className={((this.state.offers.length > 1 || this.state.offers_received.length > 1) ? 'dashboard-offer-owl-carousel owl-carousel owl-theme' : null)}>

                                            {this.state.offers.map((item, i) => {
                                                return (
                                                    <div className="item article-items" key={'offers-' + i}>

                                                        <div className="order-item-box">
                                                            <div className="order-item">
                                                                <div className="order-item-details">
                                                                    <div className="order-item-header">
                                                                        {(item.order_type == 1 || item.order_type == '1') ? <h4 className="m-0">Shipment #{item.order_id}</h4>
                                                                            :
                                                                            <>
                                                                                {item.military_flag == 1 ? <h4 className="m-0">GBL #{item.order_id}</h4> : <h4 className="m-0">Relo #{item.order_id}</h4>}
                                                                            </>
                                                                        }

                                                                        {/* <p className="text-semibold text-muted overflow-ellipsis"><i>   
                                                                                {(item.posted_by)}</i></p> */}
                                                                        <p><i><label className="text-bold m-0">{item.posted_by}</label> posted on {DateFormat.getFormattedDateTime(item.posted_date, 'm-d-Y')}</i></p>

                                                                        {/* <p className="text-semibold text-muted overflow-ellipsis"><i>   
                                                                                <b>Service Origin Address :</b> {(item.origin_city)},&nbsp;{(item.origin_state)}</i></p>

                                                                                <p className="text-semibold text-muted overflow-ellipsis"><i>   
                                                                                <b>Service Destination Address :</b> {(item.destination_city)},&nbsp;{(item.destination_state)}</i></p> */}

                                                                        <p><i>
                                                                            <label className="text-bold m-0">Weight of shipment :</label> {(item.weight_of_shipment)}</i></p>
                                                                        <div className="form-group p-b-0">
                                                                            <div className="form-group">
                                                                                <label className="text-bold m-0">Service Origin Address</label>
                                                                                <p className="f-s-16 m-0 text-muted overflow-ellipsis"><i className="icon-location3 m-r-10"></i>{item.source_address ? ((item.source_address && item.source_address.length > 30) ? item.source_address.substr(0, 30) + '' : item.source_address) : ''}
                                                                                </p>
                                                                            </div>
                                                                            <div className="form-group m-b-0">
                                                                                <label className="text-bold m-0">Service Destination Address</label>
                                                                                <p className="f-s-16 m-0 text-muted overflow-ellipsis"><i className="icon-location3 m-r-10"></i>{item.destination_address ? ((item.source_address && item.destination_address.length > 30) ? item.destination_address.substr(0, 30) + '' : item.destination_address) : ''}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="order-item-footer m-b-0">
                                                                        <div class="row">
                                                                            <div class="col-sm-6">
                                                                                <label className="text-bold f-s-15 m-0">Pack Date</label>
                                                                                <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22"></i>&nbsp;{DateFormat.getFormattedDateTime(item.pack_date, 'm-d-Y')}</p>
                                                                            </div>
                                                                            <div class="col-sm-6">
                                                                                <label className="text-bold f-s-15 m-0">Load Date</label>
                                                                                <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22"></i>&nbsp;
                                                                                    {item.load_date != null ? <label>{DateFormat.getFormattedDateTime(item.load_date, 'm-d-Y')}</label> : ""}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {this.state.editPermission ?
                                                                        <div className="overlay">
                                                                            <Link to={{
                                                                                pathname: '/independent-mover/dashboard/my-offer-details',
                                                                                state: {
                                                                                    id: item.id,
                                                                                    type: 'received',
                                                                                    post_date: item.request_date_time,
                                                                                    post_by: item.posted_by,
                                                                                    orderType: this.state.orderType,
                                                                                    actionType: 'SEND_PROPOSAL',
                                                                                    customer_id: item.customer_id
                                                                                }
                                                                            }} className="btn btn-success btn-xs btn-block">View details</Link>
                                                                            {this.state.edit_rcvOffers &&
                                                                                <Link to="#" className="btn btn-link btn-xs btn-block" onClick={this.acceptRejectOffers.bind(this, 2, item.id)}><u>Reject</u></Link>}
                                                                        </div>
                                                                        :
                                                                        <div className="overlay">
                                                                            <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-success btn-xs btn-block" onClick={this.doSubscribe}>Send Proposal</Link>
                                                                            <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-link btn-xs btn-block" onClick={this.doSubscribe}><u>Reject</u></Link>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <hr className="m-0" />
                                                                <div className="order-bottom-footer clearfix p-l-15 p-r-15 p-10">
                                                                    <h6 className={item.hire_by_profile_id == 2 ? "text-success pull-left m-0" : item.hire_by_profile_id == 4 ? "text-primary pull-left m-0" : "text-violet pull-left m-0"}>{item.hire_by_profile_id == 2 ? 'Mover' : item.hire_by_profile_id == 4 ? 'Non-Military Customer' : 'Military Customer'}</h6>
                                                                    {this.state.editPermission ?
                                                                        <Link to={{
                                                                            pathname: '/independent-mover/dashboard/my-offer-details',
                                                                            state: {
                                                                                id: item.id,
                                                                                type: 'received',
                                                                                post_date: item.request_date_time,
                                                                                post_by: item.posted_by,
                                                                                orderType: this.state.orderType,
                                                                            }
                                                                        }} className="pull-right text-muted p-l-10"><i className="icon-arrow-right8"></i></Link>
                                                                        :
                                                                        <Link to="/independent-mover/dashboard/my-subscription" className="pull-right text-muted p-l-10" onClick={this.doSubscribe}><i className="icon-arrow-right8"></i></Link>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                    }
                                    {
                                        !this.state.loadingOffersReceived
                                        &&
                                        (this.state.offers.length === 0 || this.state.offers_received == false)
                                        &&

                                        <div className="alert alert-primary border-none text-center">No Offers Found.</div>
                                    }
                                </div>
                            </div>
                            {this.state.offers.length > 2
                                &&
                                <div className="panel-footer text-right p-15">
                                    <Link to="/independent-mover/dashboard/find-an-offer" className="text-primary f-s-15">View all Offers <i className="icon-arrow-right8"></i></Link>
                                </div>
                            }
                        </div>
                        <div className="panel panel-flat">
                            <div className="panel-heading p-t-10 p-b-10">
                                <p className="text-success m-0 f-s-16"><i className="icon-location4"></i><span className="m-l-5">Ongoing Orders</span></p>
                            </div>
                            <hr className="m-0" />
                            <div className="panel-body">
                                <div className="offer-box-wrap">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                this.state.loadingOngoingOrders
                                                &&
                                                <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please Wait...</div>
                                            }
                                            {
                                                !this.state.loadingOngoingOrders
                                                &&
                                                this.state.ongoing_orders.length > 0
                                                &&
                                                this.state.manage_orders
                                                &&
                                                <div className={((this.state.ongoing_orders.length > 1) ? 'dashboard-offer-owl-carousel owl-carousel owl-theme' : null)}>
                                                    {this.state.ongoing_orders.map((item, i) => {
                                                        return (
                                                            <div className="item article-items" key={'offers-' + i}>
                                                                <div className="order-item-box">
                                                                    <div className="order-item">
                                                                        <div className="order-item-details">
                                                                            <div className="order-item-header">
                                                                                {(item.order_type == 1 || item.order_type == '1') ? <h4 className="m-0">Shipment #{item.order_id}</h4>
                                                                                    :
                                                                                    <>
                                                                                        {item.military_flag == 1 ? <h4 className="m-0">GBL #{item.order_id}</h4> : <h4 className="m-0">Relo #{item.order_id}</h4>}
                                                                                    </>
                                                                                }

                                                                                <p><i>
                                                                                    <label className="text-bold m-0">{(item.customer_name)}</label>&nbsp;posted on {DateFormat.getFormattedDateTime(item.posted_date, 'm-d-Y')}</i></p>


                                                                                {/* <p className="text-semibold text-muted overflow-ellipsis"><i>   
                                                                                <b>Service Origin Address :</b> {(item.origin_city)},&nbsp;{(item.origin_state)}</i></p>

                                                                                <p className="text-semibold text-muted overflow-ellipsis"><i>   
                                                                                <b>Service Destination Address :</b> {(item.destination_city)},&nbsp;{(item.destination_state)}</i></p> */}

                                                                                <p><i>
                                                                                    <label className="text-bold m-0">Weight of shipment :</label> {(item.weight_of_shipment)}</i></p>

                                                                                <div className="form-group p-b-0">
                                                                                    <div className="form-group">
                                                                                        <label className="text-bold m-0">Service Origin Address</label>
                                                                                        <p className="f-s-16 m-0 text-muted overflow-ellipsis"><i className="icon-location3 m-r-10"></i>{item.source_address ? ((item.source_address && item.source_address.length > 30) ? item.source_address.substr(0, 30) + '' : item.source_address) : ''}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="form-group m-b-0">
                                                                                        <label className="text-bold m-0"> Destination Address</label>
                                                                                        <p className="f-s-16 m-0 text-muted overflow-ellipsis"><i className="icon-location3 m-r-10"></i>{item.destination_address ? ((item.destination_address && item.destination_address.length > 30) ? item.destination_address.substr(0, 30) + '' : item.destination_address) : ''}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className="order-item-footer">
                                                                                {/* <div className="form-group">
                                                                                    <label className="text-bold f-s-15 m-0">Relocation Requested Date</label>
                                                                                    <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22"></i>&nbsp;{DateFormat.getFormattedDateTime(item.request_start_date, 'm-d-Y')}</p>
                                                                                </div> */}
                                                                                <div class="row">
                                                                                    <div class="col-sm-6">
                                                                                        <label className="text-bold f-s-15 m-0">Pack Date</label>
                                                                                        <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22"></i>&nbsp;{DateFormat.getFormattedDateTime(item.pack_date, 'm-d-Y')}</p>
                                                                                    </div>
                                                                                    <div class="col-sm-6">
                                                                                        <label className="text-bold f-s-15 m-0">Load Date</label>
                                                                                        <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22"></i>&nbsp;
                                                                                            {item.load_date != null ? <label>{DateFormat.getFormattedDateTime(item.load_date, 'm-d-Y')}</label> : ""}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div class="container">
                                                                                <div class="row">
                                                                                <div className="form-group">
                                                                                <div class="col">
                                                                                    <label className="text-bold f-s-15 m-0">Pack Date</label>
                                                                                    <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22"></i>&nbsp;{DateFormat.getFormattedDateTime(item.pack_date, 'm-d-Y')}</p>
                                                                                </div>
                                                                                <div class="col">
                                                                                    <label className="text-bold f-s-15 m-0">Load Date</label>
                                                                                    <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22"></i>&nbsp;{DateFormat.getFormattedDateTime(item.load_date, 'm-d-Y')}</p>
                                                                                   </div> 
                                                                                    
                                                                                </div>
                                                                                </div>
                                                                                </div> */}
                                                                                {/* <div className="form-group">
                                                                                    <label className="text-bold f-s-15 m-0">Load Date</label>
                                                                                    <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22"></i>&nbsp;{DateFormat.getFormattedDateTime(item.load_date, 'm-d-Y')}</p>
                                                                                </div> */}

                                                                                <div className="mover-tracking-status">
                                                                                    <p className={item.tracking_status === 'Packing Completed' ? "success-status f-s-15 " + 'overflow-ellipsis m-b-0' + 'overflow-ellipsis m-b-0' : "warning-status f-s-15 " + 'overflow-ellipsis m-b-0'}><span className=""></span>{item.tracking_status}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="overlay">
                                                                                {this.state.editPermission ?
                                                                                    <Link
                                                                                        to={{
                                                                                            pathname: '/independent-mover/dashboard/order-details',
                                                                                            state: {
                                                                                                id: item.id,
                                                                                                type: this.renderOrderType(item.hire_by_user_id),
                                                                                                request_from: item.request_from
                                                                                            }
                                                                                        }} className="btn btn-success btn-xs btn-block">View details</Link>
                                                                                    :
                                                                                    <Link
                                                                                        to="/independent-mover/dashboard/my-subscription" className="btn btn-success btn-xs btn-block" onClick={this.doSubscribe}>View details</Link>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <hr className="m-0" />
                                                                        <div className="order-bottom-footer clearfix p-l-15 p-r-15 p-10">
                                                                            <h6 className={item.hire_by_profile_id == 2 ? "text-success pull-left m-0" : item.hire_by_profile_id == 4 ? "text-primary pull-left m-0" : "text-violet pull-left m-0"}>{item.hire_by_profile_id == 2 ? 'Mover' : item.hire_by_profile_id == 4 ? 'Non-Military Customer' : 'Military Customer'}</h6>
                                                                            {this.state.editPermission ?
                                                                                <Link
                                                                                    to={{
                                                                                        pathname: '/independent-mover/dashboard/order-details',
                                                                                        state: {
                                                                                            id: item.id,
                                                                                            type: this.renderOrderType(item.hire_by_user_id),
                                                                                            request_from: item.request_from
                                                                                        }
                                                                                    }} className="pull-right text-muted p-l-10"><i className="icon-arrow-right8"></i></Link>
                                                                                :
                                                                                <Link
                                                                                    to="/independent-mover/dashboard/my-subscription" className="pull-right text-muted p-l-10" onClick={this.doSubscribe}><i className="icon-arrow-right8"></i></Link>
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                            }
                                            {
                                                !this.state.loadingOngoingOrders
                                                &&
                                                (this.state.ongoing_orders.length === 0 || this.state.manage_orders == false)
                                                &&
                                                <div className="alert alert-primary text-center border-none">No Orders Found.</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.ongoing_orders.length > 2
                                &&
                                <div className="panel-footer text-right p-15">
                                    <Link
                                        to={{
                                            pathname: '/independent-mover/dashboard/manage-orders',
                                            state: {}
                                        }} className="text-primary f-s-15">View all Orders <i className="icon-arrow-right8"></i></Link>
                                </div>
                            }
                        </div>
                        <div className="panel panel-flat">
                            <div className="panel-heading p-t-10 p-b-10">
                                <p className="text-warning m-0 f-s-16"><i className="icon-user-plus"></i><span className="m-l-5">Orders which need resources to be assigned</span></p>
                            </div>
                            <hr className="m-0" />
                            <div className="panel-body">
                                <div className="offer-box-wrap">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {
                                                this.state.loadingAssigningOrders
                                                &&
                                                <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please Wait...</div>
                                            }
                                            {
                                                !this.state.loadingAssigningOrders
                                                &&
                                                this.state.assign_orders.length > 0
                                                &&
                                                this.state.manage_orders
                                                &&
                                                <div className={((this.state.assign_orders.length > 1) ? 'dashboard-offer-owl-carousel owl-carousel owl-theme' : null)}>
                                                    {this.state.assign_orders.map((item, i) => {
                                                        return (
                                                            <div className="item article-items" key={'offers-' + i}>
                                                                <div className="order-item-box">
                                                                    <div className="order-item">
                                                                        <div className="order-item-details">
                                                                            <div className="order-item-header">
                                                                                {(item.order_type == 1 || item.order_type == '1') ? <h4 className="m-0">Shipment #{item.order_id}</h4>
                                                                                    :
                                                                                    <>
                                                                                        {item.military_flag == 1 ? <h4 className="m-0">GBL #{item.order_id}</h4> : <h4 className="m-0">Relo #{item.order_id}</h4>}
                                                                                    </>
                                                                                }
                                                                                {/* <p className="text-semibold text-muted overflow-ellipsis"><i>   
                                                                                 {(item.customer_name)}</i></p> */}

                                                                                {/* <p className="text-semibold text-muted overflow-ellipsis"><i>   
                                                                                <b>Service Origin Address :</b> {(item.origin_city)},&nbsp;{(item.origin_state)}</i></p>

                                                                                <p className="text-semibold text-muted overflow-ellipsis"><i>   
                                                                                <b>Service Destination Address :</b> {(item.destination_city)},&nbsp;{(item.destination_state)}</i></p> */}


                                                                                <p><i><label className="text-bold f-s-15 m-0">{item.request_from}</label> posted on {DateFormat.getFormattedDateTime(item.posted_date, 'm-d-Y')}</i></p>
                                                                                <p><i>
                                                                                    <label className="text-bold m-0">Weight of shipment :</label> {(item.weight_of_shipment)}</i></p>

                                                                                <div className="form-group p-b-0">

                                                                                    <div className="form-group">
                                                                                        <label className="text-bold m-0">Service Origin Address</label>
                                                                                        <p className="f-s-16 m-0 text-muted overflow-ellipsis"><i className="icon-location3 m-r-10"></i>{item.source_address ? ((item.source_address && item.source_address.length > 30) ? item.source_address.substr(0, 30) + '' : item.source_address) : ''}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="form-group m-b-0">
                                                                                        <label className="text-bold m-0">Service Destination Address</label>
                                                                                        <p className="f-s-16 m-0 text-muted overflow-ellipsis"><i className="icon-location3 m-r-10"></i>{item.destination_address ? ((item.destination_address && item.destination_address.length > 30) ? item.destination_address.substr(0, 30) + '' : item.destination_address) : ''}
                                                                                        </p>
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                            <div className="order-item-footer">
                                                                                <div class="row">
                                                                                    <div class="col-sm-6">
                                                                                        <label className="text-bold f-s-15 m-0">Pack Date</label>
                                                                                        <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22"></i>&nbsp;{DateFormat.getFormattedDateTime(item.pack_date, 'm-d-Y')}</p>
                                                                                    </div>
                                                                                    <div class="col-sm-6">
                                                                                        <label className="text-bold f-s-15 m-0">Load Date</label>
                                                                                        <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22"></i>&nbsp;
                                                                                            {item.load_date != null ? <label>{DateFormat.getFormattedDateTime(item.load_date, 'm-d-Y')}</label> : ""}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="form-group">
                                                                                    <label className="text-bold f-s-15 m-0">Relocation Requested Date</label>
                                                                                    <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22"></i>&nbsp;{DateFormat.getFormattedDateTime(item.request_start_date, 'm-d-Y')}</p>
                                                                                </div> */}
                                                                                <div className="mover-tracking-status">
                                                                                    <p className={item.tracking_status === 'Order Accepted' ? "success-status f-s-15 " + 'overflow-ellipsis m-b-0' : "warning-status f-s-15 " + 'overflow-ellipsis m-b-0'}><span className=""></span>{item.tracking_status}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="overlay">
                                                                                {this.state.editPermission ?
                                                                                    <Link
                                                                                        to={{
                                                                                            pathname: '/independent-mover/dashboard/order-details',
                                                                                            state: {
                                                                                                id: item.id,
                                                                                                type: this.renderOrderType(item.hire_by_user_id),
                                                                                                request_from: item.request_from
                                                                                            }
                                                                                        }} className="btn btn-success btn-xs btn-block">Invite ReloPros</Link>
                                                                                    :
                                                                                    <Link
                                                                                        to="/independent-mover/dashboard/my-subscription" className="btn btn-success btn-xs btn-block" onClick={this.doSubscribe}>Invite ReloPros</Link>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <hr className="m-0" />
                                                                        <div className="order-footer-bottom clearfix p-l-15 p-r-15 p-10">
                                                                            <h6 className={item.hire_by_profile_id == 2 ? "text-success pull-left m-0" : item.hire_by_profile_id == 4 ? "text-primary pull-left m-0" : "text-violet pull-left m-0"}>{item.hire_by_profile_id == 2 ? 'Mover' : item.hire_by_profile_id == 4 ? 'Non-Military Customer' : 'Military Customer'}</h6>
                                                                            {this.state.editPermission ?
                                                                                <Link
                                                                                    to={{
                                                                                        pathname: '/independent-mover/dashboard/order-details',
                                                                                        state: {
                                                                                            id: item.id,
                                                                                            type: this.renderOrderType(item.hire_by_user_id),
                                                                                            request_from: item.request_from
                                                                                        }
                                                                                    }} className="pull-right text-muted p-l-10"><i className="icon-arrow-right8"></i></Link>
                                                                                :
                                                                                <Link
                                                                                    to="/independent-mover/dashboard/my-subscription" className="pull-right text-muted p-l-10" onClick={this.doSubscribe}><i className="icon-arrow-right8"></i></Link>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        )
                                                    })}
                                                </div>

                                            }
                                            {
                                                !this.state.loadingAssigningOrders
                                                &&
                                                (this.state.assign_orders.length === 0 || this.state.manage_orders == false)
                                                &&
                                                <div className="alert alert-primary border-none text-center">No Orders Found.</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.assign_orders.length > 2 &&
                                <div className="panel-footer text-right p-15">
                                    <Link
                                        to={{
                                            pathname: '/independent-mover/dashboard/manage-orders',
                                            state: {}
                                        }} className="text-primary f-s-15">View all Orders <i className="icon-arrow-right8"></i></Link>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-lg-4">
                        {this.state.profile_percentage && this.state.profile_percentage < 80
                            &&
                            <div className="panel panel-flat">
                                <Link
                                    to="/independent-mover/dashboard/military-approved/profile-tab"
                                >
                                    <div className="panel-body p-0">
                                        <div className="profile-completion">
                                            <h5 className="m-0 p-15 p-b-0 text-semibold">Your Profile Completion rate is {this.state.profile_percentage}%</h5>
                                            <p className="m-0 p-15 p-t-0 f-s-14 text-semibold" style={{ color: "black" }}>Please complete your profile to get more offers</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        }
                        <div className="panel panel-flat share-app">
                            <div className="panel-body">
                                <div className="media">
                                    <div className="media-left">
                                        <img src="assets/images/MyRelo-Login.png" alt="" style={{ 'width': '80px', 'height': 'auto' }} />
                                    </div>
                                    <div className="media-body">
                                        <h4 className="m-t-0">MyRelo Mobile App</h4>
                                        <p className="text-muted f-s-15">Share your branded app with your customers & boots on the ground</p>
                                        <Link to="#" onClick={this.openShareAppModal} className="btn btn-danger p-0 no-border m-t-10 m-r-5"><i className="icon-share3 p-10"></i><span className="p-10 f-s-14">Share</span></Link>
                                        <Link to="#" onClick={this.copyLink} className="btn btn-danger p-0 no-border m-t-10"><i className="icon-copy3 p-10"></i><span className="p-10 f-s-14">Copy Link</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {this.state.edit_msg && */}
                        <div className="panel panel-flat">
                            <div className="panel-heading clearfix p-t-10 p-b-10">
                                <div className="pull-left">
                                    <p className="m-0 f-s-16">Messages</p>
                                </div>
                                <div className="pull-right">
                                    <p className="text-danger m-0 f-s-14">{this.state.unreadMessages.length} Unread messages</p>
                                </div>
                            </div>
                            <div className="panel-body p-0">
                                {
                                    this.state.loadingMessage
                                    &&
                                    <div className="well text-center m-15"><i className="icon-spinner4 spinner position-left"></i> Loading. Please Wait...</div>
                                }
                                {
                                    !this.state.loadingMessage
                                    &&
                                    this.state.messages.length != 0
                                    &&
                                    <ul className="media-list panel-body p-0 inbox-list dashboard">
                                        {this.state.messages.map((message, index) => {
                                            {/* if(index<3) */ }
                                            return (

                                                <li key={message.id} className="media">
                                                    {this.state.editPermission ?
                                                        <Link to={{ pathname: '/independent-mover/dashboard/messages/inbox-details/' + message.id }} className={message.msg_read_status === '0' ? "link-msg-details text-muted text-unread" : "link-msg-details text-muted"} onClick={() => this.updateStatus(message.id, message.msg_read_status)}>
                                                            {/* <Link to="#" className="link-msg-details text-muted text-unread"> */}
                                                            {
                                                                message.profile_image == ""
                                                                &&
                                                                <div className="media-left">
                                                                    {this.spanColor(message.full_name[0], index)}
                                                                </div>
                                                            }
                                                            {
                                                                message.profile_image != ""
                                                                &&
                                                                <div className="media-left">
                                                                    <img src={message.profile_image} alt="" className="rounded-avatar img-xs" />
                                                                </div>
                                                            }

                                                            <div className="media-body">
                                                                <h6 className="m-0">{message.full_name}</h6>
                                                                <p className="m-0 overflow-ellipsis">{message.subject}</p>
                                                            </div>
                                                        </Link>
                                                        :
                                                        <Link to='/independent-mover/dashboard/my-subscription' className={message.msg_read_status === '0' ? "link-msg-details text-muted text-unread" : "link-msg-details text-muted"} onClick={this.doSubscribe}>
                                                            {
                                                                message.profile_image == ""
                                                                &&
                                                                <div className="media-left">
                                                                    {this.spanColor(message.full_name[0], index)}
                                                                </div>
                                                            }
                                                            {
                                                                message.profile_image != ""
                                                                &&
                                                                <div className="media-left">
                                                                    <img src={message.profile_image} alt="" className="rounded-avatar img-xs" />
                                                                </div>
                                                            }

                                                            <div className="media-body">
                                                                <h6 className="m-0">{message.full_name}</h6>
                                                                <p className="m-0 overflow-ellipsis">{message.subject}</p>
                                                            </div>
                                                        </Link>
                                                    }
                                                </li>

                                            )
                                            {/* else
                                        return false */}
                                        }
                                        )}
                                    </ul>
                                }
                                {
                                    !this.state.loadingMessage
                                    &&
                                    this.state.messages.length == 0
                                    &&
                                    <div className="alert alert-primary border-none text-center m-15">No Messages Found.</div>
                                }

                            </div>
                            {
                                this.state.messages.length > 4 &&
                                <div className="panel-footer text-right p-15">
                                    <Link to="/independent-mover/dashboard/inbox" className="text-primary f-s-15">View all Messages <i className="icon-arrow-right8"></i></Link>
                                </div>
                            }
                        </div>
                        {/* } */}
                    </div>
                </div>
                {/* Start Share Modal */}
                <React.Fragment>
                    <div id="shareAppModal" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
                                    <h4 className="modal-title">Share App</h4>
                                </div>
                                <hr />
                                <div className="modal-body p-t-0">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="text-semibold">Share With</label>
                                                <div>
                                                    <label className="radio-inline m-b-0 m-r-15">
                                                        <input type="radio" name="share_with" value="1" checked={this.state.type === "1"} onChange={this.handleChange} />Customer
                                                    </label>
                                                    {/* <label className="radio-inline m-b-0 m-r-15">
                                                        <input type="radio" name="share_with" value="2" checked={this.state.type === "2"} onChange={this.handleChange} />Team Member
                                                        </label> */}
                                                    <label className="radio-inline m-b-0 m-r-15">
                                                        <input type="radio" name="share_with" value="3" checked={this.state.type === "3"} onChange={this.handleChange} />Pros
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="text-semibold">User Type <span className="text-danger">*</span></label>
                                                    {this.state.type === "1" ?
                                                        <select className="form-control" name="user_type" value={this.state.form_data.user_type} onChange={this.handleOnChange}>
                                                            <option value="">--Select--</option>
                                                            <option value="Military Customer">Military</option>
                                                            <option value="Non-Military Customer">Non-Military</option>
                                                        </select>

                                                        :
                                                        <select className="form-control" name="user_type" value={this.state.form_data.user_type} onChange={this.handleOnChange}>
                                                            <option value="">--Select--</option>
                                                            <option value="Driver">Driver</option>
                                                            <option value="Packer">Packer</option>
                                                            <option value="Helper">Helper</option>
                                                            <option value="3rd Party">3rd Party</option>
                                                            <option value="Claim Repair">Claim Repair</option>
                                                        </select>
                                                    }
                                                    <p className="text-danger help-block">{this.state.user_type_err}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="text-semibold">First Name <span className="text-danger">*</span></label>
                                                    <input type="text" name="f_name" value={this.state.form_data.f_name} onChange={this.handleOnChange} className="form-control" placeholder="Enter First Name" />
                                                    <p className="text-danger help-block">{this.state.f_name_err}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="text-semibold">Last Name <span className="text-danger">*</span></label>
                                                    <input type="text" name="l_name" value={this.state.form_data.l_name} onChange={this.handleOnChange} className="form-control" placeholder="Enter First Name" />
                                                    <p className="text-danger help-block">{this.state.l_name_err}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="text-semibold">Primary Email Address <span className="text-danger">*</span></label>
                                                    <input type="text" name="email_id" value={this.state.form_data.email_id} onChange={this.handleOnChange} className="form-control" placeholder="Enter Primary Email Address" />
                                                    <p className="text-danger help-block">{this.state.email_id_err}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="text-semibold">Primary Phone Number <span className="text-danger">*</span></label>
                                                    <input type="text" name="contact_no" value={this.state.form_data.contact_no} onChange={this.handleOnChange} className="form-control" placeholder="xxx xxx xxxx" maxLength="12" />
                                                    <p className="text-danger help-block">{this.state.contact_no_err}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="text-semibold">Secondary Email Address</label>
                                                    <input type="text" name="secondary_email_id" value={this.state.form_data.secondary_email_id} onChange={this.handleOnChange} className="form-control" placeholder="Enter Secondary Email Address" />
                                                    {/* <p className="text-danger help-block">{this.state.secondary_email_id_err}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="text-semibold">Secondary Phone Number</label>
                                                    <input type="text" name="secondary_contact_no" value={this.state.form_data.secondary_contact_no} onChange={this.handleOnChange} className="form-control" placeholder="xxx xxx xxxx" maxLength="12" />
                                                    {/* <p className="text-danger help-block">{this.state.secondary_contact_no_err}</p> */}
                                                </div>
                                            </div>
                                        </div>

                                    </React.Fragment>
                                </div>
                                <hr className="m-t-0" />
                                <div className="modal-footer p-t-0">
                                    <button type="button" className="btn btn-default" data-dismiss="modal" >Close</button>
                                    <button type="button" className="btn btn-primary" onClick={this.shareAppDataSave}>Share</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                {/* End Share Modal */}
            </div>

        );
    }
}

// export default Content;
const mapStateToProps = function (state, props) {
    return {

        InboxCount: state.InboxCount
    }
}

const mapActionsToProp = {

    getInboxCount: getInboxCount
}

export default connect(mapStateToProps, mapActionsToProp)(Content);

const style1 = {
    minWidth: '310px', height: '400px', maxWidth: '600px', margin: '0 auto'
}