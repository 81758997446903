import { SET_ACTIVE_MODULES,GET_INBOX_COUNT } from './action';

const activeModules = {
    Claims: false,
    Customer_Satisfaction_Survey: false,
    Customer_Service: false,
    Document_Management: true,
    Internal_Communication_System: true,
    Quality_Control: false,
    Reimbursement: false,
    Helper:false,
    Driver:false,
    claimsMain:true
}

const total_new_msg = 0

export function getInboxCountReducer(state = total_new_msg, action){
    switch(action.type){
        case GET_INBOX_COUNT:
            return action.payload.total_new_msg;
        default:
            return state;
    }
}

export function activeModulesReducer(state = activeModules, action){
    switch(action.type){
        case SET_ACTIVE_MODULES:    
            let actMod = {
                Claims: false,
                Customer_Satisfaction_Survey: false,
                Customer_Service: false,
                Document_Management: true,
                Internal_Communication_System: true,
                Quality_Control: false,
                Reimbursement: false,
                Helper:false,
                Driver:false,
                claimsMain:true
            }        
            action.payload.activeModules.map((module)  => { 
                if(module.title == "Claims Department" && module.status == 1){
                    actMod.Claims=true;
                }
                if(module.title == "Customer Satisfaction Survey" && module.status == 1){
                    actMod.Customer_Satisfaction_Survey=true;
                }
                if(module.title == "Customer Service & Quality Control Staff" && module.status == 1){
                    actMod.Customer_Service=true;
                }
                if(module.title == "Reimbursement" && module.status == 1){
                    actMod.Reimbursement=true;
                }
                if(module.title == "Driver" && module.status == 1){
                    actMod.Driver=true;
                }
                if(module.title == "Helper" && module.status == 1){
                    actMod.Helper=true;
                }
            })

            if(state.Claims==false && state.Reimbursement==false){
                actMod.claimsMain = false;
            }
            return actMod;
        default:
            return state;
    }
}