import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PostDataWithParam from '../services/PostDataWithParam';
import PostDataFile from '../services/PostDataFile';
import DateFormat from '../externaljs/DateFormat';
import Breadcumb from '../externaljs/Breadcrumb';
import Storage from '../externaljs/Storage';

class ViewCustomer extends Component {
    state = {
        menu: [],
        custom_profile: [],
        staff_user_id: '',
        custom_menu: [],
        customer_details: [],
        customer_name: '',
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Customer', link: '/independent-mover/dashboard/customer', class: '' },
            { title: 'Customer Details', link: '', class: 'active' }
        ],
        contract_details: [],
        fields: {
            contract_start_date: '',
            contract_end_date: '',
            contract_image: ''
        },
        error: {
            contract_start_date_err: '',
            contract_end_date_err: '',
            contract_image_err: ''
        },
        editPermission: true,
        isProccesingContract:false
    }

    subscriptionCheckInterval = null;

    componentDidMount() {
        // window.$('.input-group.date').datepicker({
        //     autoclose: true
        // }).on('changeDate', (e) => {
        //     let fields = this.state.fields;
        //     fields.dob = e.format();
        //     this.setState({ fields: fields });
        // });
        this.getCustomerDetails();
        this.GetCustomerContractList();


        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        let custom_menu = [];
        if (staff_user_id != "") {
            custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
        }
        this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
            // console.log(this.state.custom_menu);
            // console.log(this.state.staff_user_id);
        });

        this.subscriptionCheckInterval = setInterval(() => {
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1)
                this.setState({ editPermission: false })
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ editPermission: true })
        }, 1000);
        this.datepic('contract_start_date');
        this.datepic('contract_end_date');

    }
    datepic(name) {
        window.$('#' + name).daterangepicker({
            showDropdowns: true,
            autoclose: true,
            singleDatePicker: true
        }).on('apply.daterangepicker', (ev, picker) => {
            let fields = this.state.fields;
            fields[name] = picker.startDate.format('MM/DD/YYYY');
            this.setState({ fields: fields });
        });
    }
    componentWillUnmount() {
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
    }

    doSubscribe = (e) => {
        window.swal({
            title: "Warning!",
            text: "Please upgrade your membership to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }

    resetError = (cb) => {
        let error = {
            contract_start_date_err: '',
            contract_end_date_err: '',
            contract_image_err: ''
        }
        this.setState({ error: error }, () => {
            cb();
        });
    }

    formatSSN = (ssn) => {
        if (typeof ssn != 'undefined' && ssn != null) {
            let ssn_new = ssn.replace(/^.{6}/g, '***-**')
            return ssn_new
        } else {
            return '';
        }
    }

    getCustomerDetails = () => {
        PostDataWithParam('getCustomerDetails', { customer_id: this.props.match.params.customer_id })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ customer_details: response.data[0] });
                }
            })
    }

    GetCustomerContractList = () => {
        PostDataWithParam('GetCustomerContractList', { customer_id: this.props.match.params.customer_id })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ contract_details: response.data });
                }
            })
    }

    handletContractInfoImage = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.files[0];
        this.setState({ contract_image: e.target.files[0] });
    }

    handleChange = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields });
    }
    handleAddContractInfo = () => {
        this.resetError(() => {
            let error = this.state.error;
            // if (document.getElementById('contract_start_date').value === '') {
            //     error.contract_start_date_err = 'Start Date is required.';
            // }
            // else if (document.getElementById('contract_end_date').value === '') {
            //     error.contract_end_date_err = 'End Date is required.';
            // }
            if (this.state.fields.contract_start_date === '') {
                error.contract_start_date_err = 'Start Date is required.';
            }
            else if (this.state.fields.contract_end_date === '') {
                error.contract_end_date_err = 'End Date is required.';
            }
            else if (this.state.fields.contract_image === '') {
                error.contract_image_err = 'Contract Image is required.';
            }
            else {
                // let fields = this.state.fields;
                // fields.contract_start_date = document.getElementById('contract_start_date').value;
                // fields.contract_end_date = document.getElementById('contract_end_date').value;
                this.setState({isProccesingContract: true }, ()=>{

                
                let fd = new FormData();
                fd.append('contract_start_date', this.state.fields.contract_start_date);
                fd.append('contract_end_date', this.state.fields.contract_end_date);
                fd.append('document', this.state.fields.contract_image, this.state.fields.contract_image.name);
                fd.append('customer_id', this.props.match.params.customer_id);

                PostDataFile('AddCustomerContract', fd)
                    .then((response) => {
                        console.log(response)
                        if (response.success === 1) {
                            let fields = this.state.fields;
                            fields.contract_start_date = '';
                            fields.contract_end_date = '';
                            fields.contract_image='';
                            document.getElementById("contract_image").value = null;
                            this.setState({ CustErr: response.msg,fields:fields }, () => {
                                window.$('#send-contract-modal').modal('hide');
                                window.$('#send-contract-modal').trigger('reset');
                                window.swal('Successful!', 'Contract Added Successfully', 'success');
                                this.setState({ addCustomerStatus: true,isProccesingContract: false });
                                this.GetCustomerContractList();

                            });
                        } else {
                            this.setState({ CustErr: response.msg,isProccesingContract: false });
                        }
                    })
                    .catch(err => {
                        this.setState({ CustErr: 'Oops! Something went wrong.', addCustomerStatus: false,isProccesingContract: false });
                    })
                }
                );
            }
            this.setState({ error: error });
        });
    }


    render() {
        return (
            <div className="content-container">
                <div className="row">
                    <div className="col-md-12">
                        {Breadcumb(this.state.breadcrumb)}
                        <div className="panel panel-flat profile-page">
                            <div className="panel-heading clearfix">
                                <h5 className="panel-title text-success pull-left">{this.state.customer_details.first_name} {this.state.customer_details.last_name}</h5>

                            </div>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4">
                                                {/* <label className="text-bold m-b-0">Email Address</label>
                                                <p className="m-b-20">{this.state.customer_details.email}</p> */}
                                                {
                                                    this.state.customer_details.ssn
                                                    &&
                                                    <div>
                                                        <label className="text-bold m-b-0">SSN</label>
                                                        <p className="m-b-20">
                                                            {this.formatSSN(this.state.customer_details.ssn)}
                                                        </p>
                                                    </div>
                                                }
                                                {/* {
                                                    this.state.customer_details.spouse_contact_no
                                                    &&
                                                    <div>
                                                        <label className="text-bold m-b-0">Spouse Mobile Phone</label>
                                                        <p className="m-b-20">{this.state.customer_details.spouse_contact_no}</p>
                                                    </div>
                                                } */}
                                                {
                                                    this.state.customer_details.number_of_children
                                                    &&
                                                    <div>
                                                        <label className="text-bold m-b-0">No of children</label>
                                                        <p className="m-b-20">{this.state.customer_details.number_of_children}</p>
                                                    </div>
                                                }
                                                <label className="text-bold m-b-0">Address</label>
                                                <p className="m-b-20">{this.state.customer_details.address || "---"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                {/* <label className="text-bold m-b-0">Mobile Phone No</label>
                                                <p className="m-b-20">{this.state.customer_details.contact_no}</p> */}
                                                {
                                                    this.state.customer_details.customer_id
                                                    &&
                                                    <div>
                                                        <label className="text-bold m-b-0">Associated Customer</label>
                                                        <p className="m-b-20">{this.state.customer_name}</p>
                                                    </div>
                                                }
                                                {/* {
                                                    this.state.customer_details.alternate_email
                                                    &&
                                                    <div>
                                                        <label className="text-bold m-b-0">Secondary Email Address</label>
                                                        <p className="m-b-20">{this.state.customer_details.alternate_email}</p>
                                                    </div>
                                                } */}
                                                {
                                                    this.state.customer_details.spouse_first_name
                                                    &&
                                                    <div>
                                                        <label className="text-bold m-b-0">Spouse First name</label>
                                                        <p className="m-b-20">{this.state.customer_details.spouse_first_name}</p>
                                                    </div>
                                                }
                                                {/* {
                                                    this.state.customer_details.spouse_work_contact_no
                                                    &&
                                                    <div>
                                                        <label className="text-bold m-b-0">Spouse Work Phone</label>
                                                        <p className="m-b-20">{this.state.customer_details.spouse_work_contact_no}</p>
                                                    </div>
                                                } */}
                                                {
                                                    this.state.customer_details.number_of_pets
                                                    &&
                                                    <div>
                                                        <label className="text-bold m-b-0">No of pets</label>
                                                        <p className="m-b-20">{this.state.customer_details.number_of_pets}</p>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-md-4">
                                                {/* {
                                                    this.state.customer_details.home_contact_no
                                                    &&
                                                    <div>
                                                        <label className="text-bold m-b-0">Home Phone</label>
                                                        <p className="m-b-20">{this.state.customer_details.home_contact_no}</p>
                                                    </div>
                                                }
                                                {
                                                    this.state.customer_details.work_contact_no
                                                    &&
                                                    <div>
                                                        <label className="text-bold m-b-0">Work Phone</label>
                                                        <p className="m-b-20">{this.state.customer_details.work_contact_no}</p>
                                                    </div>
                                                } */}
                                                <label className="text-bold m-b-0">Date of Birth</label>
                                                {
                                                    this.state.customer_details.dob
                                                    &&
                                                    <p className="m-b-20">
                                                        {DateFormat.getFormattedDateTime(this.state.customer_details.dob, 'm/d/Y')}
                                                    </p>
                                                }
                                                {
                                                    this.state.customer_details.spouse_last_name
                                                    &&
                                                    <div>
                                                        <label className="text-bold m-b-0">Spouse Last name</label>
                                                        <p className="m-b-20">{this.state.customer_details.spouse_last_name}</p>
                                                    </div>
                                                }
                                                {/* {
                                                    this.state.customer_details.spouse_home_contact_no
                                                    &&
                                                    <div>
                                                        <label className="text-bold m-b-0">Spouse Home Phone</label>
                                                        <p className="m-b-20">{this.state.customer_details.spouse_home_contact_no}</p>
                                                    </div>
                                                } */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-flat">
                            <div className="panel-heading clearfix">
                                <div className="heading-elements">
                                    {this.state.staff_user_id == "" ?
                                        <React.Fragment>
                                            {this.state.editPermission ?
                                                <a href="" className="btn btn-success btn-xs" data-toggle="modal" data-target="#send-contract-modal" >Send New Contract</a>
                                                :
                                                <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-success btn-xs" onClick={this.doSubscribe}>Send New Contract</Link>
                                            }
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {this.state.editPermission ?
                                                this.state.custom_menu.map((menu, i) => {
                                                    return (
                                                        <div>
                                                            {
                                                                (menu.title == "Manage Customer" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                                <a href="" className="btn btn-success btn-xs" data-toggle="modal" data-target="#send-contract-modal">Send New Contract</a>
                                                            }
                                                        </div>
                                                    );
                                                })
                                                :
                                                this.state.custom_menu.map((menu, i) => {
                                                    return (
                                                        <div>
                                                            {
                                                                (menu.title == "Manage Customer" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                                <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-success btn-xs" onClick={this.doSubscribe}>Send New Contract</Link>
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </React.Fragment>
                                    }
                                </div>
                                <h5 className="panel-title pull-left">Contract History</h5>
                            </div>
                            {
                                this.state.contract_details.length !== 0
                                &&
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th className="text-nowrap">Contract Start Date</th>
                                            <th className="text-nowrap">Contract End Date</th>
                                            <th>Document</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.contract_details.map((contract, index) =>
                                            <tr key={index}>
                                                <td>{contract.contract_start_date} </td>
                                                <td>{contract.contract_end_date} </td>
                                                <td><a href={contract.document_path}>{contract.document}</a></td>
                                                <td>{contract.status}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            }
                        </div>
                        {/* 
                        <div className="panel panel-flat">
                            <div className="panel-heading clearfix">
                                <h5 className="panel-title pull-left">Relocation Info</h5>
                            </div>
                            <div className="panel-body">
                            <div className="row">
                                          
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Requested Pack Date</label>
                                                <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.customer_details.requested_pack_date, 'm-d-Y')}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Requested Pickup Date</label>
                                                <div>
                                                    <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.customer_details.request_start_date, 'm-d-Y')}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Requested Delivery Date</label>
                                                <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.customer_details.request_end_date, 'm-d-Y')}</p>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Required Delivery Date (System Generated)</label>
                                                <div>
                                                    <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.customer_details.required_delivery_date, 'm-d-Y')}</p>
                                                </div>
                                            </div>
                                        
                                       
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Primary Pickup Origin Address</label>
                                                <p className="m-b-20">{this.state.customer_details.source_address || "---"}<br />
                                                    {this.state.customer_details.primary_pickup_city ? this.state.customer_details.primary_pickup_city + ', ' : ''}
                                                    {this.state.customer_details.primary_pickup_state ? this.state.customer_details.primary_pickup_state + ', ' : ''}<br />
                                                    {this.state.customer_details.primary_pickup_zip ? this.state.customer_details.primary_pickup_zip + ', ' : ''}
                                                    {this.state.customer_details.primary_pickup_country ? this.state.customer_details.primary_pickup_country : ''}
                                                </p>

                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Primary Destination Delivery Address</label>
                                                <p className="m-b-20">{this.state.customer_details.destination_address || "---"}<br />
                                                    {this.state.customer_details.primary_destination_city ? this.state.customer_details.primary_destination_city + ', ' : ''}
                                                    {this.state.customer_details.primary_destination_state ? this.state.customer_details.primary_destination_state + ', ' : ''}<br />
                                                    {this.state.customer_details.primary_destination_zip ? this.state.customer_details.primary_destination_zip + ', ' : ''}
                                                    {this.state.customer_details.primary_destination_country ? this.state.customer_details.primary_destination_country : ''}
                                                </p>

                                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="panel panel-flat">
                            <div className="panel-heading clearfix">
                                <h5 className="panel-title pull-left">Releasing Agent Info</h5>
                            </div>
                            <div className="panel-body">
                            <div className="row">
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Releasing Agent</label>
                                            <p className="m-b-20">{this.state.customer_details.releasing_agent || "---"}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Releasing Agent Phone</label>
                                            <p className="m-b-20">{this.state.customer_details.releasing_agent_phone || "---"}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Releasing Agent Email Address</label>
                                            <p className="m-b-20">{this.state.customer_details.releasing_agent_email || "---"}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Receiving Agent</label>
                                            <p className="m-b-20">{this.state.customer_details.receiving_agent || "---"}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Receiving Agent Phone</label>
                                            <p className="m-b-20">{this.state.customer_details.receiving_agent_phone || "---"}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Receiving Agent Email Address</label>
                                            <p className="m-b-20">{this.state.customer_details.receiving_agent_email || "---"}</p>
                                        </div>
                                    </div>
                        </div>
                        </div> */}
                    </div>
                </div>
                <div id="send-contract-modal" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <form className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Send Contract to <span className="text-success">{this.state.customer_details.gender} {this.state.customer_details.first_name} {this.state.customer_details.last_name}</span></h4>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Contract Start Date<span className="text-danger">*</span></label>
                                            <div className="input-group date" id="contract_start_date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control" id="contract_start_date" name="contract_start_date" onChange={this.handleChange} value={this.state.fields.contract_start_date}/>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Contract End Date<span className="text-danger">*</span></label>
                                            <div className="input-group date" id="contract_end_date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control" id="contract_end_date" name="contract_end_date" onChange={this.handleChange} value={this.state.fields.contract_end_date}/>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div className="row">
                                    <div className="col-md-6">
                                    <p className="text-danger help-block">{this.state.error.contract_start_date_err}</p>
                                    </div>

                                    <div className="col-md-6">
                                    <p className="text-danger help-block">{this.state.error.contract_end_date_err}</p>
                                    </div>

                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Upload Contract Document<span className="text-danger">*</span></label>
                                            <input type="file" name="contract_image" id="contract_image" onChange={this.handletContractInfoImage}/>
                                            <p className="text-danger help-block">{this.state.error.contract_image_err}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Cancel</button>
                                {
                                    this.state.isProccesingContract ?
                                        <label className="btn btn-upload-file btn-xs">Processing...</label>
                                        :
                                        <button type="button" className="btn btn-primary" onClick={this.handleAddContractInfo}>Send</button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewCustomer;