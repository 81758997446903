import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import GetData from '../services/GetData';
import PostDataFile from '../services/PostDataFile';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataWithParam from '../services/PostDataWithParam';
import Storage from '../externaljs/Storage';

class Customer extends Component {
    state = {
        CustErr: '',
        customer_list: [],
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Customer', link: '', class: 'active' }
        ],
        import_file: '',
        CustErr: '',
        menu: [],
        custom_profile: [],
        staff_user_id: '',
        custom_menu: [],
        customer_details: [],
    }

    componentDidMount() {
        if (typeof this.props.location.state !== 'undefined') {
            this.setState({ CustErr: this.props.location.state.CustErr });
        }
        this.GetAllCustomer();
        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        let custom_menu = [];
        if (staff_user_id != "") {
            custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
        }
        this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
            // console.log(this.state.custom_menu);
            // console.log(this.state.staff_user_id);
        });
    }

    componentWillUnmount() {
        this.setState({ CustErr: '' });
    }

    GetAllCustomer = () => {
        PostDataWithParam('GetAllCustomer')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ customer_list: response.data });
                    DataTableBasic();
                }
            })
    }

    showAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-success">
                    {status}
                </div>
            )
        }
    }

    handleChange = (e) => {
        let isChecked = e.target.checked;
        if (isChecked) {
            PostDataWithParam('GetAllCustomer', { status: 'Inactive' })
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ customer_list: response.data });
                        DataTableBasic();
                    }
                })
        } else {
            PostDataWithParam('GetAllCustomer')
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ customer_list: response.data });
                        DataTableBasic();
                    }
                })
        }
    }

    handleCSVUpload = (e) => {
        this.setState({ import_file: e.target.files[0] }, () => {
            let fd = new FormData();
            fd.append('import_file', this.state.import_file, this.state.import_file.name);
            PostDataFile('ImportExcelForCustomers', fd)
                .then((response) => {
                    if (response.success === 1) {
                        this.GetAllCustomer();
                        window.swal('Successfull!', 'Customer(s) Added Successfully', 'success')
                    } else {
                        window.swal('Failed!', 'The E-mail IDs already exists', 'error')
                    }
                })
                .catch(err => {
                    this.setState({ CustErr: 'Oops! Something went wrong.' });
                })
        });
    }

    render() {
        return (
            <div className="content-container">
                <div className="row">
                    <div className="col-md-12">
                        {Breadcumb(this.state.breadcrumb)}
                        <div className="clearfix">
                            <h5 className="pull-left">Customer List</h5>
                            {
                                (this.state.staff_user_id == "") &&
                                <div className="pull-right">
                                    <label className="checkbox-inline p-l-20 m-r-20">
                                        <input type="checkbox" onChange={this.handleChange} />Include inactive
                                    </label>

                                    <Link to="/independent-mover/dashboard/add-customer" className="btn btn-primary"><i className="icon-plus22"></i> Add Customer</Link>

                                    <label className="btn btn-success btn-file btn-xs"><input type="file" onChange={this.handleCSVUpload} name="import_file" /><i className="icon-file-excel"></i> Upload CSV</label>
                                </div>
                            }
                            {
                                (this.state.staff_user_id != "") && this.state.custom_menu.map((menu, i) => {
                                    return (
                                        <div className="pull-right">
                                            { (menu.title == "Manage Customer" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                <div>
                                                    <label className="checkbox-inline p-l-20 m-r-20">
                                                        <input type="checkbox" onChange={this.handleChange} />Include inactive
                                                 </label>

                                                    <Link to="/independent-mover/dashboard/add-customer" className="btn btn-primary"><i className="icon-plus22"></i> Add Customer</Link>

                                                    <label className="btn btn-success btn-file btn-xs"><input type="file" onChange={this.handleCSVUpload} name="import_file" /><i className="icon-file-excel"></i> Upload CSV</label>
                                                </div>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className="panel panel-flat">
                            {this.showAlert(this.state.CustErr)}
                            {
                                this.state.customer_list.length !== 0
                                &&
                                <table className="table datatable-basic table-striped" data-button-title="Message">
                                    <thead>
                                        <tr>
                                            <th className="text-nowrap">Full Name</th>
                                            <th>Mobile</th>
                                            <th>Email</th>
                                            <th>Address</th>
                                            <th>Contract Status</th>
                                            <th>Contract End Date</th>
                                            <th className="text-center">Actions</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.customer_list.map(customer =>
                                            <tr id={"#00" + customer.id} key={customer.id}>
                                                <td><Link to={{
                                                    pathname: '/independent-mover/dashboard/view-customer/' + customer.id
                                                }}>{customer.first_name} {customer.last_name}</Link></td>
                                                <td>{customer.contact_no}</td>
                                                <td>{customer.email}</td>
                                                <td>{customer.address}</td>
                                                <td>{customer.contract_status || 'N/A'}</td>
                                                <td>{customer.contract_end_date || 'N/A'}</td>
                                                <td className="text-center">
                                                    <ul className="icons-list">
                                                        <li className="dropdown">
                                                            <a href="" className="dropdown-toggle" data-toggle="dropdown">
                                                                <i className="icon-menu9"></i>
                                                            </a>
                                                            <ul className="dropdown-menu dropdown-menu-right">
                                                                <li><Link to={{
                                                                    pathname: '/independent-mover/dashboard/edit-customer/' + customer.id
                                                                }}>
                                                                    <i className="icon-pencil"></i>Edit Customer</Link></li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </td>

                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            }
                            {
                                this.state.customer_list.length === 0
                                &&
                                <div className="alert alert-primary border-none text-center">No Customer Found.</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Customer;

const style = {
    whiteSpace: 'nowrap'
}