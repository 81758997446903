import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

class Notification extends Component {
    state = { 
        url:''
     }

    componentDidMount(){
        if (this.props.location.state) {
            this.setState({ url:this.props.location.state.urlPath },()=>{
                return (
                    <Redirect to={{
                        pathname: this.props.location.state.urlPath,
                        state: {
                            id: this.props.location.state.id,
                            type: this.props.location.state.type
                        }
                    }}></Redirect>
                )
            });
        }
    }

    render() { 
        return ( 
            <p></p>
         );
    }
}
 
export default Notification;