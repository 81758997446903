import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class HeaderMain extends Component {
    toggleNavbar = () => {
        window.$('body').toggleClass('show-menu');
    }
    goToSection = (id) => {
        window.$('html, body').animate({
            scrollTop: window.$("#" + id).offset().top
        }, 500);
    }
    state = {}

    render() {

        return (
            <header>
                <div className="container">
                    <div className="header">
                        <div className="header-left">
                            <img src="front-assets/images/logo.png" alt="independent-mover-logo" width="400" />
                        </div>
                        <div className="header-right">
                            <div>
                                <Link to="#" className="btn btn-free-account" role="button" onClick={()=>this.goToSection("section-1")}>
                                    <span className="">Create Free Account</span>
                                    <p className="m-0 f-s-12">(FREE TRIAL)</p>
                                </Link>
                            </div>
                            <div>
                                <Link to="/login" className="btn btn-login" role="button" style={{ lineHeight: "35px" }}>
                                    <span className="">Login</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default HeaderMain;