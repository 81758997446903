import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import Breadcumb from '../externaljs/Breadcrumb';
import GetData from '../services/GetData';
import PostDataWithParam from '../services/PostDataWithParam';
import Storage from '../externaljs/Storage';

class MySubscription extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Subscriptions', link: '', class: 'active' }
        ],
        list: [],
        subscription_id: 0,
        trial_subscription_id: '',
        subscription_id: '',
        title: '',
        appzSubscribtionDetails: {},
        selectedMoverAppzPlan: {},
        loading: true
    }

    subscriptionCheckInterval = null;

    componentDidMount() {
        Promise.allSettled([
            this.GetUserInfo(),
            this.getMoverAppzSubDetails(),
            this.getSubscriptionList()
        ]).then(() => {
            console.log("Done")
            this.setState({ loading: false })
        })

        let trial_subscription_id, subscription_id;
        this.subscriptionCheckInterval = setInterval(async () => {
            trial_subscription_id = JSON.parse(Storage.get('imnLoginData')).trial_subscription_id;
            subscription_id = JSON.parse(Storage.get('imnLoginData')).subscription_id;
            this.setState({ subscription_id, trial_subscription_id })
            if (JSON.parse(Storage.get('imnLoginData')).trial_subscription_id == 1) {
                // this.setState({ title: "(" + JSON.parse(Storage.get('imnLoginData')).trial_days + " days trial)" });
                this.setState({ title: "(Trial)" })
            }
            else
                this.setState({ title: '' })
        }, 1000);
    }

    componentWillUnmount() {
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
    }

    GetUserInfo = () => {
        return GetData('GetUserInfo', {})
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ subscription_id: response.data.subscription_id });
                }
            })
            .catch(err => { })
    }
    getLoginUserData = () => {
        try {
            return JSON.parse(Storage.get('imnLoginData'))
        } catch (err) {
            return null
        }
    }
    getMoverAppzSubDetails = () => {
        return PostDataWithParam('MoverAppzSubscriptionDetails', { user_id: this.getLoginUserData().id })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({
                        appzSubscribtionDetails: response.data,
                        selectedMoverAppzPlan: response.data.selected_package_info[0] || {}
                    })
                } else {
                }
            })
            .catch(err => {
                console.log(err)
            })

    }

    getSubscriptionList = () => {
        return PostDataWithParam('GetSubscriptionList', { profile_id: 2 })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ list: response.data });
                }
            })
            .catch(err => {
                this.setState({ list: [] });
            })
    }

    renderActive = (status) => {
        if (status == 1)
            return 'active'
        else
            return ''
    }

    renderSelectBtn = (status, id, title, value) => {
        // status==active
        // console.log(status, id, title, value)
        if (status == 1 && value != 0) {
            if (this.state.trial_subscription_id == 0 && this.state.subscription_id == 2) {
                return <button className="btn btn-primary btn-lg" disabled={true}>Selected</button>
            }
            else {
                return <Link className="btn btn-primary btn-lg" to={{
                    pathname: "/independent-mover/dashboard/checkout",
                    state: { id: id, title: title, value: value }
                }}>Upgrade to Premium</Link>
            }
        }

        else if (status == 0 && value == 0) {
            return <button className="btn btn-primary btn-lg" disabled={true}>Select</button>
        }
        else if (status == 1 && value == 0) {
            return <button className="btn btn-primary btn-lg" disabled={true}>Selected</button>
        }
        else {
            return <Link className="btn btn-primary btn-lg" to={{
                pathname: "/independent-mover/dashboard/checkout",
                state: { id: id, title: title, value: value }
            }}>Upgrade to Premium</Link>
        }

    }



    renderDescription = (id) => {
        if (id == 2) {
            return (
                <React.Fragment>
                    <li>Get free app for your Boots on the Grounds, Customers </li>
                    <li>Easy As 1-2-3<br />
                        Signup &amp; Subscribe<br />
                        Answer Few Basic Questions &amp; Upload Logo (Don't worry if you don't have one, we can create one for you! <br />
                        Start Sharing your app in your sales process and with your customers &amp; crew.
                    </li>
                    <li>Search our database of : <br />
                        2,000+ Pro ReloHelper
                        <br />
                        2,500+ Moving Companies.
                    </li>
                    <li>$$$ Money Saving Partner Discounts on Truck Rental, Packing Material, Hotels, Fuel, Moving Equipment, Bookkeeping, and many other products and services.</li>
                    <li>Networking with fellow IMN members, movers, Helpers, claim repairs &amp; MORE</li>
                    <li>Preview our New Tech for Movers,
                        Drivers, Customers, and Helpers</li>
                    <li>Newsletter</li>
                    <li>Revolutionize Relocation for your Customers &amp; 'Boots on the Ground' with your own mobile app. Your Customers &amp; Crews will it &amp; GROW your business!</li>
                    <li>We assist you in getting APPROVED to work with other IMN partners</li>
                    <li>On-Going training in all areas of "How to work with IMN partners", reduce claims plus so many other topics!</li>
                    <li>Customized solutions to reduce your claims by 75% and increase your customer satisfaction scores and profits by 50% **</li>



                </React.Fragment>
            )
        } else if (id == 3) {
            return (
                <React.Fragment>
                    <li>Get free app for your Boots on the Grounds, Customers </li>
                    <li>Easy As 1-2-3<br />
                        Signup &amp; Subscribe<br />
                        Answer Few Basic Questions &amp; Upload Logo (Don't worry if you don't have one, we can create one for you! <br />
                        Start Sharing your app in your sales process and with your customers &amp; crew.
                    </li>
                    <li>Search our database of : <br />
                        2,000+ Pro ReloHelpers
                        <br />
                        2,500+ Moving Companies.
                    </li>
                    <li>$$$ Money Saving Partner Discounts on Truck Rental, Packing Material, Hotels, Fuel, Moving Equipment, Bookkeeping, and many other products and services.</li>
                    <li>Networking with fellow IMN members, movers, Helpers, claim repairs &amp; MORE</li>
                    <li>Preview our New Tech for Movers,
                        Drivers, Customers, and Helpers</li>
                    <li>Revolutionize Relocation for your Customers &amp; 'Boots on the Ground' with your own mobile app. Your Customers &amp; Crews will it &amp; GROW your business!</li>

                    <li>We assist you in getting APPROVED to work with other IMN partners</li>
                    <li>On-Going training in all areas of "How to work with IMN partners", reduce claims plus so many other topics!</li>
                    <li>Customized solutions to reduce your claims by 75% and increase your customer satisfaction scores and profits by 50% **</li>

                    <li>You save $$ by prepaying. </li>
                </React.Fragment>
            )
        }
    }
    render() {
        const { selectedMoverAppzPlan, appzSubscribtionDetails } = this.state;
        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="panel panel-flat">
                        {
                            this.state.loading
                                ?
                                <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please Wait...</div>
                                :
                                <>
                                    <div className="panel-heading">
                                        <h3 className="panel-title">My Subscription</h3>
                                    </div>
                                    <div className="panel-body">
                                        <div className="row">
                                            {/* MoverAppz package start */}
                                            <div className="col-lg-4">
                                                {appzSubscribtionDetails.mover_appz_expiry_status === 0 ? (
                                                    <div className="columns m-b-20 active">
                                                        <div className="icon-div">
                                                            <i className="icon-check"></i>
                                                        </div>
                                                        <ul className="price">
                                                            <li className="header text-capitalize"><p className="m-0" style={{ 'line-height': 'normal' }}>MoverAppz Subscription</p><span className="f-s-15">({selectedMoverAppzPlan.tittle})</span></li>
                                                            <li className="grey">${selectedMoverAppzPlan.moverappz_amount} USD</li>
                                                            <li>
                                                                <strong>
                                                                    {/* (6 MyReloPro® App Boots On the Ground Users)
                                                    <br />
                                                    (<span className="text-blue-800 f-s-15">200 Customers/ Month)</span> */}

                                                                    {`(${selectedMoverAppzPlan.pro_limit} MyReloPro® App Boots On the Ground Users)`}
                                                                    <br />
                                                                    <span className="text-blue-800 f-s-15">
                                                                        {selectedMoverAppzPlan.customer_limit
                                                                            ? `(${selectedMoverAppzPlan.customer_limit} Customers/ Month)`
                                                                            : `(Unlimited Customers)`
                                                                        }
                                                                    </span>
                                                                </strong>
                                                            </li>
                                                            <li>
                                                                <button className="btn btn-primary btn-lg disabled">Selected</button>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    className="btn btn-success btn-lg"
                                                                    to={"/independent-mover/dashboard/moverAppz-subscription?type=upgrade"}
                                                                >
                                                                    Upgrade / Downgrade
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )
                                                    :
                                                    (<div className="columns m-b-20">
                                                        <div className="icon-div">
                                                            <i className="icon-check"></i>
                                                        </div>
                                                        <ul className="price">
                                                            <li className="header text-capitalize">MoverAppz - Get My Custom Branded App</li>
                                                            <li>
                                                                <p className="text-uppercase">All benefits of Premium membership</p>
                                                                <i><img src="assets/images/icons/green/add.svg" width="20" /></i>
                                                            </li>
                                                            <li className="f-s-15"><strong>Grow your user base by inviting your customers & pros</strong></li>
                                                            <li className="f-s-15"><strong>Share the app with your customers & pros</strong></li>
                                                            <li>
                                                                <Link className="btn btn-primary btn-lg" to={"/independent-mover/dashboard/moverAppz-subscription"}>{appzSubscribtionDetails.is_moverappz_payment_count > 0 ? "Upgrade Subscription" : "Get my app"}</Link>
                                                            </li>
                                                        </ul>
                                                    </div>)
                                                }
                                            </div>
                                            {/* MoverAppz package end */}
                                            {
                                                this.state.list.map((list_item, index) => {
                                                    return (
                                                        <div className="col-sm-12 col-lg-4" key={index}>
                                                            <div className={"m-b-20 columns " + this.renderActive(list_item.active)}>
                                                                <div className="icon-div">
                                                                    <i className="icon-check"></i>
                                                                </div>
                                                                <ul className="price">
                                                                    <li className="header text-capitalize">{!list_item.trial_days_independent_mover ? list_item.title 
                                                                    : `${list_item.title} (${list_item.trial_days_independent_mover} Days Free Trial )`}</li>
                                                                    <li className="grey">{(list_item.value == 0) ? 'FREE' : '$ ' + list_item.value + ' USD'} </li>
                                                                    {
                                                                        this.renderDescription(list_item.id)
                                                                    }
                                                                    <li>{this.renderSelectBtn(list_item.active, list_item.id, list_item.title, list_item.value)}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default MySubscription;