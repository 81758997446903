import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Breadcumb from '../externaljs/Breadcrumb';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import PostDataWithParam from '../services/PostDataWithParam';
import Storage from '../externaljs/Storage';

class UnassignedOffers extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'ReloOrders', link: '', class: 'active' }
        ],
        offer_list: [],
        loading: false,
        backBtn:0
    }

    componentDidMount() {
        if(this.props.location.state){
            this.setState({ backBtn:this.props.location.state.backBtn });
        }
        this.UnassignedOfferListForMovers();
    }

    UnassignedOfferListForMovers = () => {
        this.setState({ loading: true }, () => {
            PostDataWithParam('UnassignedOfferListForMovers', {})
                .then((response) => {
                    if (response.success == 1) {
                        this.setState({ offer_list: response.data, loading: false }, () => {
                            DataTableBasic();
                        });
                    } else {
                        this.setState({ offer_list: [], loading: false });
                    }
                })
                .catch( err => {
                    this.setState({ offer_list: [], loading: false });
                })
        })
    }

    renderStatus = (status,req_status) => {
        if (status == 0 && req_status=="") {
            return <span className="text-primary">Unassigned</span>;
        }
        else if (status == 1 && req_status==0) {
            return (
                <span>Pending</span>
            )
        } else if (status == 1 && req_status==2) {
            return (
                <span className="text-warning">Declined</span>
            )
        } else if (status == 2 && req_status==3) {
            return (
                <span className="text-danger">Closed</span>
            )
        } else {
            return (
                <span>In Progress</span>
            )

        }
    }

    renderOrderType = (hire_by_user_id) => {
        if (hire_by_user_id === (JSON.parse(Storage.get('imnLoginData'))).id)
            return ''
        else
            return 'accepted'
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="panel panel-flat">
                        <div className="panel-heading clearfix">
                            {
                                this.state.backBtn == 1
                                &&
                                <div className="">
                                    <Link to="/independent-mover/dashboard/assign-offer-job" className="btn btn-link p-l-0"><i className="icon-arrow-left8"></i> Back</Link>
                                </div>   
                            }                      
                            <h5 className="panel-title ">List of ReloOrders</h5>
                            
                        </div>
                        {
                            this.state.loading
                            &&
                            <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                        }
                        {
                            !this.state.loading
                            &&
                            this.state.offer_list.length !== 0
                            &&
                            <table className="table datatable-basic table-striped">
                                <thead>
                                    <tr>
                                        <th style={{ whiteSpace: 'nowrap' }}>Relo #</th>
                                        <th style={{ whiteSpace: 'nowrap' }}>Customer Name</th>
                                        <th>Relocation Address</th>
                                        <th>Requested To</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.offer_list.map((offer, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><Link
                                                        to={{
                                                            pathname: '/independent-mover/dashboard/order-details',
                                                            state: {
                                                                id: offer.id,
                                                                type: this.renderOrderType(offer.hire_by_user_id)
                                                            }
                                                        }}
                                                    >#{offer.order_id}</Link></td>
                                                    <td className="text-nowrap">{offer.customer_name}</td>
                                                    <td>{offer.source_address}</td>
                                                    <td className="text-nowrap ">
                                                        <div className="customer-details">
                                                            <a href="javascript:void(0)">{offer.request_to_name}</a>
                                                        </div>
                                                    </td>
                                                    <td>{this.renderStatus(offer.status,offer.request_status)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        }
                        {
                            !this.state.loading
                            &&
                            this.state.offer_list.length === 0
                            &&
                            <div className="alert alert-primary border-none text-center">No Offers Found.</div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default UnassignedOffers;