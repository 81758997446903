import React, { Component } from 'react'
import { Link, Route, Redirect } from 'react-router-dom';

import Breadcumb from '../externaljs/Breadcrumb';
import ProfileTab from './ProfileTab';
import ScacTab from './ScacTab';
import MilitaryApprovedTab from './MilitaryApprovedTab';
import RatingReview from './RatingReview';
import Storage from '../externaljs/Storage';
import PostDataWithParam from '../services/PostDataWithParam';
import GetData from '../services/GetData';
import DateFormat from '../externaljs/DateFormat'

class GetMilitaryApproved extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Basic Profile', link: '', class: 'active' }
        ],
        conversation: {
            message: '',
            field_name: '',
            tab_id: ''
        },
        saveStatus: false,
        messages: [],
        gma_subscription_id: 0,
        custom_menu: [],
        gma_permission: true,
        is_scac: 0
    }

    staffMenuCheckInterval = null;

    componentDidMount() {
        window.$(document).on('click', '[data-toggle="image-modal-file"]', function (e) {
            e.preventDefault();
            var modalTitle = window.$(this).attr('data-title');
            var imgSrc = window.$(this).attr('data-src');
            window.$('#imageModal .modal-title').html(modalTitle);
            window.$('#imageModal .modal-body').html('<img src="' + imgSrc + '" class="img-responsive"/>');
            window.$('#imageModal').modal('show');
        });

        this.GetApprovalConversation()
        document.body.addEventListener('subscription', (e) => {
            this.GetUserInfo();
        }, false);
        this.GetUserInfo();
        this.GetCompanyInfo();
        this.staffMenuCheckInterval = setInterval(() => {
            if (Storage.get('mmcStaffMenu')) {
                let custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
                this.setState({ custom_menu }, () => {

                    let find_gma_permission = this.state.custom_menu.find(menu => menu.title.trim() == "Get Military Approved")
                    // console.log(find_gma_permission);
                    if (find_gma_permission) this.setState({ gma_permission: true })
                    else this.setState({ gma_permission: false })

                });
            }
        }, 1000)
    }

    componentWillUnmount() {
        if (this.staffMenuCheckInterval) {
            clearInterval(this.staffMenuCheckInterval)
        }
    }

    OpenModal = (field_name, tab_id) => {
        let conversation = this.state.conversation;
        conversation.field_name = field_name;
        conversation.tab_id = tab_id;
        conversation.message = '';
        this.setState({ conversation: conversation }, () => {
            this.GetApprovalConversation()
        });
    }

    ChangeValue = (e) => {
        let conversation = this.state.conversation;
        conversation.message = e.target.value;
        this.setState({ conversation: conversation });
    }

    SendMessage = () => {
        let conversation = this.state.conversation;
        if (conversation.message != '') {
            PostDataWithParam('SaveApprovalConversation', conversation)
                .then((response) => {
                    if (response.success === 1) {
                        conversation.message = "";
                        window.swal('Success!', response.msg, 'success')
                        this.setState({ conversation: conversation, saveStatus: true }, () => {
                            this.GetApprovalConversation()
                        });
                    } else {
                        window.swal('Error!', response.msg, 'error')
                    }
                })
                .catch(err => { })
        }
    }

    GetApprovalConversation = () => {
        PostDataWithParam('GetApprovalConversation', this.state.conversation)
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ messages: response.data });
                }
            })
            .catch(err => { })
    }

    GetUserInfo = () => {
        GetData('GetUserInfo', {})
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ gma_subscription_id: response.data.gma_subscription_id }, () => {
                        // console.log(this.state.gma_subscription_id)
                    });
                }
            })
            .catch(err => { })
    }

    GetCompanyInfo = () => {

        PostDataWithParam('GetCompanyInfo', {})
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ is_scac: response.data[0].is_scac })
                } else {
                    this.setState({ is_scac: 0 })
                }
            })
            .catch(err => {
                this.setState({ is_scac: 0 })
            })
    }

    getNavLinkClass = (path, exact = true) => {
        if (exact)
            return this.props.location.pathname === path ? 'active' : '';
        else {
            return ((this.props.location.pathname).indexOf(path) !== -1) ? 'active' : '';
        }
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        {Breadcumb(this.state.breadcrumb)}
                        <div className="panel panel-default">
                            <div className="panel-heading p-0">
                                <ul className="nav nav-tabs nav-tabs-solid m-b-0">
                                    <li className={this.getNavLinkClass("/independent-mover/dashboard/military-approved/profile-tab")}><Link to="/independent-mover/dashboard/military-approved/profile-tab" className="f-s-16">Basic Profile</Link></li>
                                    {this.state.is_scac == 1 &&
                                        <li className={this.getNavLinkClass("/independent-mover/dashboard/military-approved/scac-tab")}><Link to="/independent-mover/dashboard/military-approved/scac-tab" className="f-s-16">SCAC Information</Link></li>
                                    }
                                    {this.state.gma_subscription_id < 1 &&
                                        <li className={this.getNavLinkClass("/independent-mover/dashboard/military-approved/information-tab", false)}>
                                            {this.state.gma_permission &&
                                                <Link to={{
                                                    pathname: "/independent-mover/dashboard/checkout",
                                                    state: {
                                                        gma_subscription_id: JSON.parse(Storage.get('imnLoginData')).gma_subscription_id,
                                                        title: JSON.parse(Storage.get('imnLoginData')).gma_package_name,
                                                        value: JSON.parse(Storage.get('imnLoginData')).gma_package_price,
                                                        type: 'gmaSubscription'
                                                    }
                                                }} className="f-s-16">Advanced Profile to Get Military Approved</Link>
                                            }
                                        </li>
                                    }
                                    {this.state.gma_subscription_id >= 1 &&
                                        <li className={this.getNavLinkClass("/independent-mover/dashboard/military-approved/information-tab", false)}>
                                            {this.state.gma_permission &&
                                                <Link to="/independent-mover/dashboard/military-approved/information-tab" className="f-s-16">Advanced Profile to Get Military Approved</Link>
                                            }
                                        </li>
                                    }
                                    <li className={this.getNavLinkClass("/independent-mover/dashboard/military-approved/ratings-reviews")}><Link to="/independent-mover/dashboard/military-approved/ratings-reviews" className="f-s-16">Ratings & Reviews</Link></li>
                                </ul>
                            </div>
                            <div className="panel-body">
                                <div className="tabbable">
                                    <div className="tab-content">
                                        {JSON.parse(Storage.get('imnLoginData')).gma_subscription_id >= 1 ?
                                            <Route path="/independent-mover/dashboard/military-approved" exact render={
                                                () => { return <Redirect to="/independent-mover/dashboard/military-approved/information-tab" /> }
                                            } />
                                            :
                                            <Route path="/independent-mover/dashboard/military-approved" exact render={
                                                () => {
                                                    return <Redirect to={{
                                                        pathname: "/independent-mover/dashboard/checkout",
                                                        state: {
                                                            gma_subscription_id: JSON.parse(Storage.get('imnLoginData')).gma_subscription_id,
                                                            title: JSON.parse(Storage.get('imnLoginData')).gma_package_name,
                                                            value: JSON.parse(Storage.get('imnLoginData')).gma_package_price,
                                                            type: 'gmaSubscription'
                                                        }
                                                    }} />
                                                }
                                            } />
                                        }
                                        <Route path="/independent-mover/dashboard/military-approved/profile-tab" component={ProfileTab} />
                                        {this.state.is_scac == 1 &&
                                            <Route path="/independent-mover/dashboard/military-approved/scac-tab" component={ScacTab} />
                                        }
                                        {this.state.gma_subscription_id >= 1 &&
                                            <Route path="/independent-mover/dashboard/military-approved/information-tab" render={(props) => {
                                                return <MilitaryApprovedTab {...props} onOpenModal={this.OpenModal} />
                                            }} />
                                        }
                                        <Route path="/independent-mover/dashboard/military-approved/ratings-reviews" component={RatingReview} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="comment" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Question to MyRelo team</h4>
                            </div>
                            <div className="modal-body">
                                <ul className="media-list">
                                    <li className="media">
                                        <div className="media-left">
                                            <img src="assets/images/default_avatar.jpg" className="img-circle img-xs" alt="" />
                                        </div>

                                        <div className="media-body">
                                            {(JSON.parse(Storage.get('imnLoginData'))).first_name} {(JSON.parse(Storage.get('imnLoginData'))).last_name}
                                        </div>
                                        <div className="m-t-5 form-group">
                                            <textarea className="form-control" name="major_repairs" value={this.state.conversation.message} onChange={this.ChangeValue}></textarea>
                                        </div>
                                        <button className="btn btn-primary" onClick={this.SendMessage}>Send</button>
                                    </li>
                                    <hr />
                                    {
                                        this.state.messages.map((msg, i) => {
                                            return (
                                                <li className="media" key={i}>
                                                    <div className="media-left">
                                                        <img src="assets/images/default_avatar.jpg" className="img-circle img-xs" alt="" />
                                                    </div>

                                                    <div className="media-body">
                                                        {msg.first_name + ' ' + msg.last_name}
                                                        <span className="media-annotation pull-right">{DateFormat.getFormattedDateTime(msg.created_at, 'H:i')}<br /></span>
                                                        <span className="display-block text-muted">{msg.message}</span>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="imageModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title" id="myModalLabel">Modal title</h4>
                            </div>
                            <div className="modal-body">
                                ...
                                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GetMilitaryApproved;
