import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Breadcumb from '../externaljs/Breadcrumb';
import { CircularProgressbar } from 'react-circular-progressbar';
import FileUploadWithProgress from "../services/FileUploadWithProgress";
import PostDataWithParam from '../services/PostDataWithParam';
import { FileType, FileSvg, FileIcon } from "../externaljs/FileType";
import PostDataFile from '../services/PostDataFile';
import GetData from '../services/GetData';
import ConfirmBox from '../externaljs/ConfirmBox';
import Storage from '../externaljs/Storage';

export default class TrainingMaterial extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'My Training', link: '', class: 'active' }
        ],
        uploadPercentage: 0,
        user_type: [],
        all_user: ['4', '5', '6', '11', '12', '13', '14'],
        form_data: {
            id: '',
            user_type: [],
            file_name: '',
            main_category: '',
            sub_category: '',
            description: '',
            request_file: '',
            type: 1,
            youtube_link: ''
        },
        usertype_err: '',
        main_category_err: '',
        file_name_err: '',
        link_err: '',
        file_upload_err: '',
        trainingList: [],
        isProccesingFileUpload: false,
        isFileSaving: false,
        filesModal: [],
        filesView: [],
        main_category: [],
        sub_category: [],
        training_material: [],
        created_training_material_tab: true,
        shared_training_material_tab: false,
        editPermission: true,
        sharePermission: true,
        type: 1
    }

    subscriptionCheckInterval = null;

    componentDidMount() {
        this.getTrainingMaterialDetails();
        this.getAllTrainingMaterial();
        this.subscriptionCheckInterval = setInterval(() => {
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ sharePermission: true })
            else
                this.setState({ sharePermission: false })
        }, 1000);

        this.getMainCategoryList();
        // this.getSubCategoryList();
    }

    openMyTrainingModal = (e) => {
        let form_data = {
            ...this.state.form_data, id: '',
            user_type: [],
            file_name: '',
            main_category: '',
            sub_category: '',
            description: '',
            request_files: '',
            type: 1,
            youtube_link: ''
        }
        this.setState({ form_data, filesView: [], type: 1 }, () => {
            window.$("#addTrainingModal").modal('show');
        })

    }

    getAllTrainingMaterial() {
        this.setState({ loading: true }, () => {
            PostDataWithParam('TrainingMaterialListForAllUsers')
                .then(response => {
                    if (response.success == 1) {
                        this.setState({ training_material: response.data, loading: false }, () => {
                            // console.log(this.state.training_material)
                        })
                    }
                    else {
                        this.setState({ training_material: [], loading: false })
                        // window.swal('Error!', response.msg, 'error')
                    }
                })
                .catch(err => {
                    this.setState({ training_material: [], loading: false })
                    window.swal('Error!', 'Something went wrong', 'error')
                })
        })
    }

    updateMyTrainingModal = (e, id) => {

        PostDataWithParam('GetTrainingMaterialDetails', { id })
            .then((response) => {
                if (response.success === 1) {
                    let form_data = response.data[0]
                    let user_type = form_data.user_type
                    if (user_type !== '') {
                        form_data.user_type = user_type.split(',')
                    }

                    this.setState({ type: parseInt(form_data.type) })
                    this.setState({ form_data, usertype_err: '', main_category_err: '', file_name_err: '', file_upload_err: '' }, () => {
                        window.$("#editTrainingModal").modal('show');
                        if (this.state.type === 1) {
                            this.getFilesModal();
                        }
                        this.getSubCategoryList();
                    });
                }
                else
                    this.setState({ form_data: [] }, () => {
                    });
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error');
            })
    }

    getTrainingMaterialDetails = () => {
        PostDataWithParam('GetTrainingMaterialDetails', { id: this.state.form_data.id })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ trainingList: response.data }, () => {
                    });
                }
                else
                    this.setState({ trainingList: [] }, () => {
                    });
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error');
            })
    }
    getTrainingMaterial = () => {
        PostDataWithParam('GetTrainingMaterialDetails', { id: '' })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ trainingList: response.data }, () => {
                    });
                }
                else
                    this.setState({ trainingList: [] }, () => {
                    });
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error');
            })
    }
    getMainCategoryList() {
        PostDataWithParam('GMAGetTrainingMainCategoryList', {})
            .then((response) => {
                let result = response;
                if (result.success === 1) {
                    this.setState({ main_category: result.data });
                } else {
                    this.setState({ UpdateErr: result.msg });
                }

            })
            .catch(err => {

            })
    }

    getSubCategoryList() {
        PostDataWithParam('GMAGetTrainingSubCategoryList', { main_category: this.state.form_data.main_category })
            .then((response) => {
                let result = response;
                if (result.success === 1) {
                    this.setState({ sub_category: result.data });
                } else {
                    this.setState({ UpdateErr: result.msg });
                }

            })
            .catch(err => {

            })
    }

    changeFormData = (e) => {
        if (e.target.name === 'main_category') {
            let form_data = this.state.form_data;
            form_data[e.target.name] = e.target.value;
            this.setState({ form_data: form_data }, () => {
                this.getSubCategoryList();
            });
        }
        else {
            let form_data = this.state.form_data;
            form_data[e.target.name] = e.target.value;
            this.setState({ form_data: form_data }, () => {
                // console.log(this.state.form_data.youtube_link);
            });
        }

    }

    setUserType = (e) => {
        let index;
        let form_data = { ...this.state.form_data };
        let user = [...this.state.user_type];
        if (e.target.value != "select_all") {
            if (form_data.user_type.indexOf(e.target.value) == -1) {
                user.push(e.target.value)
                form_data.user_type.push(e.target.value);
            } else {
                user = user.filter(user_type => user_type.user_type !== e.target.value)
                index = form_data.user_type.indexOf(e.target.value);
                form_data.user_type.splice(index, 1);
            }

            this.setState({ form_data, user_type: user }, () => {
            });
        } else {

            let users = e.target.name

            if (e.target.checked) {
                let all_user = this.state.all_user.map(item => {
                    return item;
                });
                form_data.user_type = all_user;
                this.setState({ form_data });

            } else {

                form_data.user_type = [];
                this.setState({ form_data, user_type: [] });
            }
        }
    }

    setUserTypeCheck = (e) => {
        let form_data = { ...this.state.form_data };
        if (e.target.value != "select_all") {
            if (form_data.user_type.indexOf(e.target.value) > -1) {
                let user_type = form_data.user_type.filter((item, i) => {
                    if (item == e.target.value)
                        return false;
                    else
                        return true;
                });
                form_data.user_type = user_type;
            } else {
                form_data.user_type.push(e.target.value)
            }

            this.setState({ form_data: form_data });
        }
        else {
            if (e.target.checked) {
                let all_user = this.state.all_user.map(item => {
                    return item;
                });
                form_data.user_type = all_user;
                this.setState({ form_data });

            } else {

                form_data.user_type = [];
                this.setState({ form_data, user_type: [] });
            }
        }

    }

    addTrainingMaterial = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.form_data.user_type.length == 0) {
            this.setState({ usertype_err: 'Please select at least one User Type' })

        }
        else if (this.state.type == 1 && (this.state.form_data.file_name == '' || this.state.form_data.file_name == null)) {
            this.setState({ file_name_err: 'Please enter file name' })
            // console.log('2');
        }
        else if (this.state.type == 0 && (this.state.form_data.youtube_link == '' || this.state.form_data.youtube_link == null)) {
            this.setState({ link_err: 'Please enter the link' })
            // console.log('3');
        }
        else if (this.state.form_data.main_category == '') {
            this.setState({ main_category_err: 'Please select main category' })
            // console.log('4');
        }
        else if (this.state.type == 1 && (this.state.filesModal.length == 0)) {
            this.setState({ file_upload_err: 'Please add file' })
            // console.log('2');
        }

        else {
            // console.log('5');
            this.setState({ usertype_err: '' });
            this.setState({ file_name_err: '' });
            this.setState({ link_err: '' });
            let formData = new FormData();
            // for (let i = 0; i < this.state.filesModal.length; i++) {
            //     formData.append('request_files[' + i + ']', this.state.filesModal[i], this.state.filesModal[i].name);
            // }
            formData.append('id', this.state.form_data.id);
            formData.append('file_name', this.state.form_data.file_name);
            formData.append('user_type', JSON.stringify(this.state.form_data.user_type));
            formData.append('main_category', this.state.form_data.main_category);
            formData.append('sub_category', this.state.form_data.sub_category);
            formData.append('description', this.state.form_data.description);
            formData.append('type', this.state.form_data.type);
            formData.append('youtube_link', this.state.form_data.youtube_link);

            this.setState({ isFileSaving: true }, () => {
                PostDataFile('AddTrainingMaterial', formData)
                    .then(response => {
                        if (response.success == 1) {
                            let form_data = { ...this.state.form_data }
                            form_data.id = '';
                            form_data.user_type = [];
                            form_data.file_name = '';
                            form_data.main_category = '';
                            form_data.sub_category = '';
                            form_data.description = '';
                            form_data.request_files = '';
                            form_data.type = 1;
                            form_data.youtube_link = '';
                            this.setState({ isFileSaving: false, form_data })
                            window.swal('Success!', response.msg, 'success');
                            window.$('#addTrainingModal').modal('hide');
                            window.$('#addTrainingModal').trigger('reset');
                            this.getTrainingMaterialDetails();
                        }
                        else {
                            this.setState({ isFileSaving: false })
                            window.swal('Sorry!', response.msg, 'error');
                        }
                    })
                    .catch(err => {
                        this.setState({ isFileSaving: false })
                        window.swal('Error!', 'Something went wrong', 'error');
                    })
            })

        }
    }

    updateTrainingMaterial = (e) => {

        e.preventDefault();
        e.stopPropagation();
        if (this.state.form_data.user_type.length == 0) {
            this.setState({ usertype_err: 'Please select at least one User Type' })
        }
        else if (this.state.type == 1 && (this.state.form_data.file_name == '' || this.state.form_data.file_name == null)) {
            this.setState({ file_name_err: 'Please enter file name' })
        }
        else if (this.state.type == 0 && (this.state.form_data.youtube_link == '' || this.state.form_data.youtube_link == null)) {
            this.setState({ link_err: 'Please enter the link' })
        }
        else if (this.state.form_data.main_category == '') {
            this.setState({ main_category_err: 'Please select main category' })
        }
        else if (this.state.type == 1 && this.state.filesModal.length == 0 && !this.state.form_data.request_file) {
            this.setState({ file_upload_err: 'Please add file' })
        }
        else {
            console.log('dfdgfd')
            // console.log('5');
            this.setState({ usertype_err: '' });
            this.setState({ file_name_err: '' });
            this.setState({ link_err: '' });
            this.setState({ file_upload_err: '' });
            let formData = new FormData();
            if (this.state.filesModal.length > 0) {
                for (let i = 0; i < this.state.filesModal.length; i++) {
                    formData.append('request_files[' + i + ']', this.state.filesModal[i], this.state.filesModal[i].name);
                }
            }
            formData.append('id', this.state.form_data.id);
            formData.append('file_name', this.state.form_data.file_name);
            formData.append('user_type', JSON.stringify(this.state.form_data.user_type));
            formData.append('main_category', this.state.form_data.main_category);
            formData.append('sub_category', this.state.form_data.sub_category);
            formData.append('description', this.state.form_data.description);
            formData.append('type', this.state.form_data.type);
            formData.append('youtube_link', this.state.type == 0 ? this.state.form_data.youtube_link : '');
            console.log(this.state.form_data);
            // return
            this.setState({ isFileSaving: true }, () => {
                PostDataFile('EditTrainingMaterial', formData)
                    .then(response => {
                        if (response.success == 1) {
                            let form_data = { ...this.state.form_data }
                            form_data.id = '';
                            form_data.user_type = [];
                            form_data.file_name = '';
                            form_data.main_category = '';
                            form_data.sub_category = '';
                            form_data.description = '';
                            form_data.request_files = '';
                            form_data.type = 1;
                            form_data.youtube_link = '';
                            this.setState({ isFileSaving: false, form_data, filesModal: [], filesView: [] })
                            window.swal('Success!', response.msg, 'success');
                            window.$('#editTrainingModal').modal('hide');
                            window.$('#editTrainingModal').trigger('reset');
                            this.getTrainingMaterialDetails();
                        }
                        else {
                            this.setState({ isFileSaving: false })
                            window.swal('Sorry!', response.msg, 'error');
                        }
                    })
                    .catch(err => {
                        this.setState({ isFileSaving: false })
                        window.swal('Error!', 'Something went wrong', 'error');
                    })
            })

        }
    }

    uploadFiles = (e) => {
        this.setState({ filesModal: e.target.files, filesView: [] })
        let form_data = { ...this.state.form_data }
        this.setState({ form_data })
        if (e.target.files.length > 0) {
            let formData = new FormData();
            for (let i = 0; i < e.target.files.length; i++) {
                formData.append('request_files[' + i + ']', e.target.files[i], e.target.files[i].name);
            }
            formData.append('id', this.state.form_data.id);
            formData.append('file_name', this.state.form_data.file_name);
            formData.append('user_type', JSON.stringify(this.state.form_data.user_type));
            formData.append('main_category', this.state.form_data.main_category);
            formData.append('sub_category', this.state.form_data.sub_category);
            formData.append('description', this.state.form_data.description);
            formData.append('type', this.state.form_data.type);
            formData.append('youtube_link', this.state.form_data.youtube_link);

            this.setState({ isProccesingFileUpload: true }, () => {
                FileUploadWithProgress('AddTrainingMaterial', formData, (e) => {
                    var percent = Math.round((e.loaded / e.total) * 100);
                    this.setState({ uploadPercentage: percent })
                })
                    .then(response => {
                        if (response.success == 1) {
                            let form_data = { ...this.state.form_data };
                            form_data.id = response.data.id;

                            this.setState({ form_data, isProccesingFileUpload: false, uploadPercentage: 0 }, () => {
                            });
                            this.getFilesModal();
                        } else {
                            this.setState({ isProccesingFileUpload: false });
                            window.swal('Error!', 'Something went wrong.', 'error');
                        }
                    })
                    .catch(err => {
                        // console.log('err', err)
                        this.setState({ isProccesingFileUpload: false });
                        window.swal('Error!', 'Something went wrong.', 'error')
                    });
            })

        }

    }

    getFilesModal() {
        PostDataWithParam("GetTrainingMaterialDetails", {
            id: this.state.form_data.id,
        })
            .then((response) => {
                if (response.success == 1) {
                    if (response.data.length != 0)
                        this.setState({ filesView: response.data }, () => {
                        });
                    else
                        this.setState({ filesView: response.data });
                } else {
                    this.setState({ filesView: [] });
                }
            })
            .catch((err) => {
                this.setState({ filesView: [] });
            });
    }

    getFileName(file) {
        if (typeof (file.request_file) !== 'undefined') {
            let files = file.request_file.split("/");
            return files[files.length - 1];
        }
        else {
            let files = file.split("/")
            return files[files.length - 1]
        }
    }

    renderFilesModal() {

        if (this.state.filesView.length > 0) {

            let files = this.state.filesView.map((file, i) => {
                return (
                    <li key={"item-" + i}>
                        <div className={"file-wrapper " + FileType(file.file_type)}>
                            <div className="overlay-file">

                            </div>
                            {FileSvg(file.file_type, file.request_file)}
                            <div className="file-title">
                                <a href={file.request_file}>
                                    <i
                                        className={
                                            FileIcon(file.file_type) + " position-left text-primary"
                                        }
                                    ></i>{" "}
                                    {this.getFileName(file)}
                                </a>
                            </div>
                        </div>

                    </li>

                );
            });

            return files;
        } else {
            return <h6 className="alert alert-primary no-border m-r-15">No files were found.</h6>;
        }

    }

    handleSwitchForm = (e) => {
        // alert(e.target.innerText);
        if (e.target.innerText == 'Created By Me') {
            this.setState({ created_training_material_tab: true, shared_training_material_tab: false });
            // window.$('#military_form').reset();
        } else {
            this.setState({ created_training_material_tab: false, shared_training_material_tab: true })
        }
    }

    deleteTrainingMaterial = (e, id) => {
        ConfirmBox("You want to delete this training material!").then((result) => {
            if (result) {
                PostDataWithParam('DeleteTrainingMaterialDetails', { id })
                    .then(response => {
                        if (response.success == 1) {
                            window.swal('Success!', response.msg, 'success');
                            this.getTrainingMaterial();
                        }
                        else
                            window.swal('Sorry!', response.msg, 'error');
                    })
                    .catch(err => {
                        window.swal('Error!', 'Something went wrong', 'error');
                    })
            }
        });
    }

    renderTrainingMaterial = () => {
        if (this.state.trainingList.length > 0) {
            return <React.Fragment>
                {this.state.trainingList.map((trainingMaterial, i) => {
                    return (
                        <div className="panel panel-flat">
                            <div className="panel-heading clearfix">
                                <h5 className="m-0 text-success pull-left">{trainingMaterial.file_name}</h5>
                                <div className="pull-right">
                                    <Link to="#" className="btn btn-danger btn-xs" onClick={(e) => this.deleteTrainingMaterial(e, trainingMaterial.id)}><i className="icon-cross3"></i> Delete</Link>
                                    <Link to="#" className="btn btn-success m-l-5 btn-xs" onClick={(e) => this.updateMyTrainingModal(e, trainingMaterial.id)}><i className="icon-pencil"></i> Edit</Link>

                                </div>
                            </div>
                            <div className="panel-body">
                                <div className="my-training-container">
                                    <div className="item">
                                        {FileType(trainingMaterial.file_type) == "filetype-pdf" &&
                                            <div className="download-pdf-file">
                                                <img src="assets/images/main-pdf.png" alt="" className="img-responsive" />
                                                <a href={trainingMaterial.request_file} target="_blank">Download Pdf File</a>
                                            </div>
                                        }
                                        {FileType(trainingMaterial.file_type) == "filetype-powerpoint" &&
                                            <div className="download-pdf-file">
                                                <img src="assets/images/ppt.png" alt="" className="img-responsive" />
                                                <a href={trainingMaterial.request_file} target="_blank">Download PPT File</a>
                                            </div>
                                        }
                                        {FileType(trainingMaterial.file_type) == "filetype-video" &&
                                            <video width="100%" controls id={"audio-video" + i} className="embed-responsive-item">
                                                <source src={trainingMaterial.request_file} type="video/mp4" className="embed-responsive-item" />
                                            </video>
                                        }
                                        {!!trainingMaterial.youtube_link &&
                                            <div className="download-pdf-file">
                                                <img src="assets/images/http.svg" alt="" className="img-responsive" />
                                                <a href={trainingMaterial.youtube_link} target="_blank">Go To Link</a>
                                            </div>
                                        }

                                    </div>
                                    <div className="item">
                                        <div className="panel panel-flat">
                                            <div className="panel-body">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="form-group m-b-0">
                                                            <label className="text-bold m-b-0">Shared With</label>
                                                            <p>{trainingMaterial.user_type}</p>
                                                        </div>
                                                    </div>
                                                    {trainingMaterial.file_name && !!trainingMaterial.file_name &&
                                                        <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <label className="text-bold m-b-0">File Name</label>
                                                                <p>{trainingMaterial.file_name}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-bold m-b-0">Main Category</label>
                                                            <p>{trainingMaterial.main_category}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label className="text-bold m-b-0">Sub Category</label>
                                                            <p>{trainingMaterial.sub_category}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="text-bold m-b-0">Description</label>
                                                            <p>{trainingMaterial.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </React.Fragment>
        }
        else {
            return <h6 className="alert alert-primary no-border m-b-0">No Training Material Found.</h6>
        }
    }

    setTrainingId = (training_material_id) => {

        this.setState({ loading: true }, () => {
            PostDataWithParam('GetSharedTrainingMaterial', { training_material_id: training_material_id })
                .then(response => {
                    if (response.success == 1) {

                        let form_data = this.state.form_data;
                        var string = response.data[0].shared_with;
                        string = string.split(',');
                        var array = [];
                        array = string;
                        form_data.shared_with = array;
                        form_data.training_material_id = response.data[0].training_material_id
                        this.setState({ form_data: form_data, loading: false }, () => {
                            // console.log(this.state.training_material)
                        })
                    }
                    else {

                        let form_data = {
                            ...this.state.form_data,
                            training_material_id: training_material_id,
                            shared_with: []
                        };

                        this.setState({ form_data: form_data, loading: false })
                        // window.swal('Error!', response.msg, 'error')
                    }
                })
                .catch(err => {


                    let form_data = {
                        training_material_id: training_material_id,
                        shared_with: [],

                    };
                    this.setState({ form_data: form_data, loading: false })
                    window.swal('Error!', 'Something went wrong', 'error')
                })
        })
    }

    shareTrainingMaterial = (e) => {
        e.preventDefault();
        // console.log(this.state.form_data)
        // return
        let formData = new FormData();
        formData.append('training_material_id', this.state.form_data.training_material_id);
        formData.append('user_type', JSON.stringify(this.state.form_data.shared_with));

        PostDataFile('ShareTrainingMaterial', formData)
            .then(response => {
                if (response.success == 1) {
                    this.getAllTrainingMaterial()
                    window.swal('Success!', response.msg, 'success')
                    window.$('#shareTrainingModal').modal('hide');
                    window.$('#shareTrainingModal').trigger('reset');
                }
                else window.swal('Error!', 'Something went wrong', 'error')
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error')
            })
    }
    selectUserType = (e) => {
        let index;
        if (e.target.name == 'shared_with') {
            let form_data = this.state.form_data;
            if (e.target.checked) {
                form_data.shared_with.push(e.target.value);
            }
            else {
                index = form_data.shared_with.indexOf(e.target.value);
                form_data.shared_with.splice(index, 1);
            }
            this.setState({ form_data: form_data })
        }

    }

    handleChangeFileSets(status) {
        if (status == 1) {
            this.setState({ file_name_err: '' })
        }
        else {
            this.setState({ link_err: '' })
        }
        this.setState({ type: status }, () => {
            let form_data = this.state.form_data;
            form_data.type = status;
            this.setState({ form_data: form_data })

        });
    }

    render() {
        const { main_category } = this.state;

        let main_category_list = main_category.length > 0
            && main_category.map((item, i) => {
                return (
                    <option key={i} value={item.main_category}>{item.main_category}</option>
                )
            }, this);

        const { sub_category } = this.state;

        let sub_category_list = sub_category.length > 0
            && sub_category.map((item, i) => {
                return (
                    <option key={i} value={item.sub_category}>{item.sub_category}</option>
                )
            }, this);

        return (



            <React.Fragment>

                <div className="row">
                    <div className="col-md-12">
                        {Breadcumb(this.state.breadcrumb)}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="pull-right m-b-20">
                                    <Link to="#" className="btn btn-primary m-l-5 btn-xs" onClick={this.openMyTrainingModal}><i className="icon-plus2"></i> Add New Training Material</Link>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-flat b-0 box-shadow-none">
                            <div className="panel-header bg-white border-radius-3px">
                                <ul className="nav nav-tabs nav-tabs-bottom nav-horizantal-scroll m-b-0">

                                    <li className={this.state.created_training_material_tab ? 'active' : ''}>
                                        <Link to="#" data-toggle="tab" aria-expanded="true" onClick={this.handleSwitchForm} name="createdByMe"><h6>Created By Me</h6></Link>
                                    </li>
                                    <li className={this.state.shared_training_material_tab ? 'active' : ''}>
                                        <Link to="#" data-toggle="tab" aria-expanded="true" onClick={this.handleSwitchForm} name="sharedWithMe"><h6> Shared With Me</h6></Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="panel-body p-0 bg-color-transparent">
                                <div className="createdByMe" style={{ display: (this.state.created_training_material_tab ? 'block' : 'none') }}>
                                    <div className="panel-body">
                                        {
                                            this.state.loading
                                            &&
                                            <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please Wait...</div>
                                        }
                                        {
                                            !this.state.loading
                                            &&
                                            this.renderTrainingMaterial()
                                        }
                                    </div>
                                </div>
                                <div className="sharedWithMe" style={{ display: (this.state.shared_training_material_tab ? 'block' : 'none') }}>
                                    <div className="panel-body">
                                        {
                                            this.state.loading
                                            &&
                                            <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please Wait...</div>
                                        }
                                        {
                                            !this.state.loading
                                            &&
                                            this.state.training_material.length > 0
                                            &&
                                            this.state.training_material.map((item, i) => {
                                                return <div className="panel panel-flat">
                                                    <div className="panel-heading clearfix">
                                                        <h5 className="m-0 text-success pull-left"></h5>
                                                        <div className="pull-right">
                                                            {this.state.sharePermission ?
                                                                <a href="#" class="btn btn-xs btn-primary" data-toggle="modal" data-target="#shareTrainingModal" onClick={() => this.setTrainingId(item.id)}><i class="icon-share2"></i> Share My Trainings</a>
                                                                :
                                                                <Link to="/independent-mover/dashboard/my-sub" className="btn btn-xs btn-primary" onClick={this.doSubscribe}>Share My Trainings</Link>
                                                            }

                                                        </div>
                                                    </div>
                                                    <div className="panel-body">
                                                        <div className="my-training-container">
                                                            <div className="item">
                                                                {/* {item.file_type == 'mp4' ?
                                                                    <div className="embed-responsive embed-responsive-16by9 m-b-20">
                                                                        <video width="100%" height="240" controls className="embed-responsive-item">
                                                                            <source src={item.request_file} type="video/mp4" className="embed-responsive-item" />
                                                                        </video>
                                                                    </div>
                                                                    :
                                                                    <div className="download-pdf-file">
                                                                        <img src="assets/images/main-pdf.png" alt="" className="img-responsive" />
                                                                        {this.state.editPermission ?
                                                                            <a href={item.request_file} target="_blank">Download Pdf File</a>
                                                                            :
                                                                            <Link to="/helper/dashboard/my-sub" onClick={this.doSubscribe}>Download Pdf File</Link>
                                                                        }
                                                                    </div>
                                                                } */}
                                                                {FileType(item.file_type) == "filetype-pdf" &&
                                                                    <div className="download-pdf-file">
                                                                        <img src="assets/images/main-pdf.png" alt="" className="img-responsive" />
                                                                        {this.state.editPermission ?
                                                                            <a href={item.request_file} target="_blank">Download Pdf File</a>
                                                                            :
                                                                            <Link to="/independent-mover/dashboard/my-sub" onClick={this.doSubscribe}>Download Pdf File</Link>}
                                                                    </div>
                                                                }
                                                                {FileType(item.file_type) == "filetype-powerpoint" &&
                                                                    <div className="download-pdf-file">
                                                                        <img src="assets/images/ppt.png" alt="" className="img-responsive" />
                                                                        {this.state.editPermission ?
                                                                            <a href={item.request_file} target="_blank">Download PPT File</a>
                                                                            :
                                                                            <Link to="/independent-mover/dashboard/my-sub" onClick={this.doSubscribe}>Download PPT File</Link>
                                                                        }
                                                                    </div>
                                                                }
                                                                {FileType(item.file_type) == "filetype-video" &&
                                                                    <video width="100%" controls id={"audio-video" + i} className="embed-responsive-item">
                                                                        <source src={item.request_file} type="video/mp4" className="embed-responsive-item" />
                                                                    </video>
                                                                }
                                                                {!!item.youtube_link &&
                                                                    <div className="download-pdf-file">
                                                                        <img src="assets/images/http.svg" alt="" className="img-responsive" />
                                                                        {this.state.editPermission ?
                                                                            <a href={item.youtube_link} target="_blank">Go To Link</a>
                                                                            :
                                                                            <Link to="/independent-mover/dashboard/my-sub" onClick={this.doSubscribe}>Go To Link</Link>}
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className="item">
                                                                <div className="panel panel-flat">
                                                                    <div className="panel-body">
                                                                        <div className="row">
                                                                            {!!item.user_type &&
                                                                                <div className="col-sm-6">
                                                                                    <label className="text-bold m-b-0">Shared With</label>
                                                                                    <p>{!!item.user_type ? item.user_type : ''}</p>
                                                                                </div>
                                                                            }
                                                                            {!!item.file_name &&
                                                                                <div className="col-sm-6">
                                                                                    <label className="text-bold m-b-0">File Name</label>
                                                                                    <p>{!!item.file_name ? item.file_name : ''}</p>
                                                                                </div>
                                                                            }
                                                                            <div className="col-sm-6">
                                                                                <label className="text-bold m-b-0">Main Category</label>
                                                                                <p>{!!item.main_category ? item.main_category : ''}</p>
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                <label className="text-bold m-b-0">Sub Category</label>
                                                                                <p>{!!item.sub_category ? item.sub_category : ''}</p>
                                                                            </div>
                                                                            <div className="col-md-12">
                                                                                <label className="text-bold m-b-0">Description</label>
                                                                                <p>{!!item.description ? item.description : ''}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                        {
                                            !this.state.loading && this.state.training_material.length === 0
                                            &&
                                            <div className="alert alert-primary no-border text-center">No Training Material Found.</div>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>



                        {/* shareTrainingModal start */}
                        <div className="modal fade" tabindex="-1" role="dialog" id="shareTrainingModal">
                            <div className="modal-dialog modal-sm" role="document">
                                <form method="POST">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                            <h4 className="modal-title">Share With</h4>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group m-b-0">
                                                        <ul className="checkbox-list list-unstyled">
                                                            <li className="display-block">
                                                                <label className="checkbox-inline custom-checkbox check-success"><input type="checkbox" name="shared_with" value="11" onChange={this.selectUserType} checked={this.state.form_data.shared_with && this.state.form_data.shared_with.indexOf('11') !== -1} /><span className=""></span>Military Customer</label>
                                                            </li>
                                                            <li className="display-block">
                                                                <label className="checkbox-inline custom-checkbox check-success"><input type="checkbox" name="shared_with" value="4" onChange={this.selectUserType} checked={this.state.form_data.shared_with && this.state.form_data.shared_with.indexOf('4') !== -1} /><span className=""></span>Non-Military Customer</label>
                                                            </li>
                                                            <li className="display-block">
                                                                <label className="checkbox-inline custom-checkbox check-success"><input type="checkbox" name="shared_with" value="2" onChange={this.selectUserType} checked={this.state.form_data.shared_with && this.state.form_data.shared_with.indexOf('2') !== -1} /><span className=""></span>Moving Company</label>
                                                            </li>
                                                            <li className="display-block">
                                                                <label className="checkbox-inline custom-checkbox check-success"><input type="checkbox" name="shared_with" value="6" onChange={this.selectUserType} checked={this.state.form_data.shared_with && this.state.form_data.shared_with.indexOf('6') !== -1} /><span className=""></span>Driver</label>
                                                            </li>
                                                            {/* <li className="display-block">
                                                    <label className="checkbox-inline custom-checkbox check-success"><input type="checkbox" name="shared_with" value="5" onChange={this.selectUserType} checked={this.state.form_data.shared_with && this.state.form_data.shared_with.indexOf('5') !== -1}/><span className=""></span>Helper</label>
                                                </li> */}
                                                            <li className="display-block">
                                                                <label className="checkbox-inline custom-checkbox check-success"><input type="checkbox" name="shared_with" value="12" onChange={this.selectUserType} checked={this.state.form_data.shared_with && this.state.form_data.shared_with.indexOf('12') !== -1} /><span className=""></span>Packer</label>
                                                            </li>
                                                            <li className="display-block">
                                                                <label className="checkbox-inline custom-checkbox check-success"><input type="checkbox" name="shared_with" value="13" onChange={this.selectUserType} checked={this.state.form_data.shared_with && this.state.form_data.shared_with.indexOf('13') !== -1} /><span className=""></span>3rd Party</label>
                                                            </li>
                                                            <li className="display-block">
                                                                <label className="checkbox-inline custom-checkbox check-success"><input type="checkbox" name="shared_with" value="14" onChange={this.selectUserType} checked={this.state.form_data.shared_with && this.state.form_data.shared_with.indexOf('14') !== -1} /><span className=""></span>Claim Repair</label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer p-t-0">
                                            <button type="button" className="btn btn-default" data-dismiss="modal" >Close</button>
                                            {this.state.form_data.shared_with && this.state.form_data.shared_with.length == 0 ?
                                                <button className="btn btn-primary" disabled>Share</button>
                                                :
                                                <button className="btn btn-primary" onClick={this.shareTrainingMaterial}>Share</button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {/* shareTrainingModal end */}
                        {/* addTrainingModal start */}
                        <div className="modal fade" tabindex="-1" role="dialog" id="addTrainingModal">
                            <div className="modal-dialog" role="document">
                                <form method="POST">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                            <h4 className="modal-title">Add New Training Material</h4>
                                        </div>
                                        <hr className="m-0 m-t-10" />
                                        <div className="modal-body">
                                            {/* <form className=""> */}
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">Share With <span className="text-danger">*</span></label>
                                                        <div>
                                                            {/* <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="2" onChange={this.setUserType} checked={this.state.form_data.user_type.indexOf('2') !== -1} /><span className=""></span>Mover
                                                    </label> */}
                                                            <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="6" onChange={this.setUserType} checked={this.state.form_data.user_type.indexOf('6') !== -1} /><span className=""></span>Driver
                                                            </label>
                                                            <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="5" onChange={this.setUserType} checked={this.state.form_data.user_type.indexOf('5') !== -1} /><span className=""></span>Helper
                                                            </label>
                                                            <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="12" onChange={this.setUserType} checked={this.state.form_data.user_type.indexOf('12') !== -1} /><span className=""></span>Packer
                                                            </label>
                                                            <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="13" onChange={this.setUserType} checked={this.state.form_data.user_type.indexOf('13') !== -1} /><span className=""></span>3rd Party
                                                            </label>
                                                            <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="14" onChange={this.setUserType} checked={this.state.form_data.user_type.indexOf('14') !== -1} /><span className=""></span>Claim Repair
                                                            </label>
                                                            <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="11" onChange={this.setUserType} checked={this.state.form_data.user_type.indexOf('11') !== -1} /><span className=""></span>Military Customer
                                                            </label>
                                                            <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="4" onChange={this.setUserType} checked={this.state.form_data.user_type.indexOf('4') !== -1} /><span className=""></span>Non-Military Customer
                                                            </label>
                                                            <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="select_all" onChange={this.setUserType} /><span className=""></span>Public
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <p className="text-danger">{this.state.usertype_err}</p>

                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="radio-inline m-b-0 m-r-15">
                                                            <input type="radio" name="type" value="1" checked={this.state.type === 1} onChange={() => this.handleChangeFileSets(1)} />Upload File
                                                        </label>
                                                        <label className="radio-inline m-b-0 m-r-15">
                                                            <input type="radio" name="type" value="0" checked={this.state.type === 0} onChange={() => this.handleChangeFileSets(0)} /> Paste Link
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.type != 1 ? 'hide' : '' + 'row'}>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">Name Your File <span className="text-danger">*</span></label>
                                                        <input type="text" name="file_name" value={this.state.form_data.file_name} className="form-control" placeholder="Enter Title" onChange={this.changeFormData} required={true} />
                                                    </div>
                                                </div>
                                                <p className="text-danger">{this.state.file_name_err}</p>

                                            </div>
                                            <div className={this.state.type === 1 ? 'hide' : '' + 'row'}>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">Link <span className="text-danger">*</span></label>
                                                        <input type="text" name="youtube_link" value={this.state.form_data.youtube_link} className="form-control" placeholder="Enter Link" onChange={this.changeFormData} required={true} />
                                                    </div>
                                                </div>
                                                <p className="text-danger">{this.state.link_err}</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">Main Category <span className="text-danger">*</span></label>
                                                        <select className="form-control" id="main_category"
                                                            value={this.state.form_data.main_category}
                                                            name="main_category" placeholder='Enter Main Category' onChange={this.changeFormData}>
                                                            <option value="">--Select--</option>
                                                            {/* {main_category_list} */}
                                                            {this.state.main_category && this.state.main_category.length > 0 ? this.state.main_category.map((item, i) => {
                                                                return <option key={i} value={item.main_category}>{item.main_category}</option>
                                                            }) : null}
                                                        </select>
                                                    </div>
                                                </div>
                                                <p className="text-danger">{this.state.main_category_err}</p>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">Sub Category</label>
                                                        <select className="form-control" id="sub_category"
                                                            value={this.state.form_data.sub_category}
                                                            name="sub_category" placeholder='Enter Sub Category' onChange={this.changeFormData}>
                                                            <option value="">--Select--</option>
                                                            {/* {sub_category_list} */}
                                                            {this.state.sub_category && this.state.sub_category.length > 0 ? this.state.sub_category.map((item, i) => {
                                                                return <option key={i} value={item.sub_category}>{item.sub_category}</option>
                                                            }) : null}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">Description</label>
                                                        <textarea className="form-control" name="description" value={this.state.form_data.description} placeholder="Enter Text" onChange={this.changeFormData}></textarea>
                                                    </div>
                                                </div>
                                                <div className={this.state.type !== 1 ? 'hide' : '' + 'col-md-12'}>
                                                    <div className="file-manager horizontal-scroll admin-file-manager">
                                                        <ul>
                                                            {this.renderFilesModal()}
                                                            {this.state.isProccesingFileUpload &&
                                                                <li>
                                                                    <div className="file-wrapper">
                                                                        <div className="progress-file-icon">
                                                                            <img src="assets/images/blur-inventory.jpg" alt="" />
                                                                            <div className="inventory-progess-bar">
                                                                                <CircularProgressbar value={this.state.uploadPercentage} text={`${this.state.uploadPercentage}%`} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className={this.state.type !== 1 ? 'hide' : '' + 'col-md-12'}>
                                                    <div className="form-group">
                                                        <label className="text-semibold">Upload Video(mp4) / Upload Pdf / Upload PPT<span className="text-danger">*</span></label>
                                                        {this.state.form_data.file_name !== '' &&
                                                            <input type="file" name="files[]" onChange={this.uploadFiles} required={true} />
                                                        }
                                                    </div>
                                                    <p className="text-danger help-block">{this.state.file_upload_err}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="m-0" />
                                        <div className="modal-footer p-t-15">
                                            {this.state.isProccesingFileUpload ?
                                                <React.Fragment>
                                                    <button type="button" className="btn btn-default" data-dismiss="modal" disabled={true}>Close</button>
                                                    <button className="btn btn-primary btn-xs" disabled={true}>Save</button>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    {this.state.isFileSaving ?
                                                        <button className="btn btn-primary btn-xs" disabled={true} >Processing...</button>
                                                        :
                                                        <React.Fragment>
                                                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                                            <button type="submit" className="btn btn-primary" onClick={this.addTrainingMaterial}>Save</button>
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* addTrainingModal end */}
                        {/* editTrainingModal start */}
                        <div className="modal fade" tabindex="-1" role="dialog" id="editTrainingModal">
                            <div className="modal-dialog" role="document">
                                <form method="POST">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                            <h4 className="modal-title">Edit Training Material</h4>
                                        </div>
                                        <hr className="m-0 m-t-10" />
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">Share With <span className="text-danger">*</span></label>
                                                        <div>
                                                            {/* <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="2" onChange={this.setUserTypeCheck} checked={this.state.form_data.user_type.indexOf('2') > -1} /><span className=""></span>Mover
                                                            </label> */}
                                                            <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="6" onChange={this.setUserTypeCheck} checked={this.state.form_data.user_type.indexOf('6') > -1} /><span className=""></span>Driver
                                                            </label>
                                                            <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="5" onChange={this.setUserTypeCheck} checked={this.state.form_data.user_type.indexOf('5') > -1} /><span className=""></span>Helper
                                                            </label>
                                                            <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="12" onChange={this.setUserTypeCheck} checked={this.state.form_data.user_type.indexOf('12') > -1} /><span className=""></span>Packer
                                                            </label>
                                                            <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="13" onChange={this.setUserTypeCheck} checked={this.state.form_data.user_type.indexOf('13') > -1} /><span className=""></span>3rd Party
                                                            </label>
                                                            <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="14" onChange={this.setUserTypeCheck} checked={this.state.form_data.user_type.indexOf('14') > -1} /><span className=""></span>Claim Repair
                                                            </label>
                                                            <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="11" onChange={this.setUserTypeCheck} checked={this.state.form_data.user_type.indexOf('11') > -1} /><span className=""></span>Military Customer
                                                            </label>
                                                            <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="4" onChange={this.setUserTypeCheck} checked={this.state.form_data.user_type.indexOf('4') !== -1} /><span className=""></span>Non-Military Customer
                                                            </label>
                                                            <label className="checkbox-inline m-l-0 m-r-15">
                                                                <input type="checkbox" name="user_type[]" value="select_all" onChange={this.setUserTypeCheck} /><span className=""></span>Public
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <p className="text-danger">{this.state.usertype_err}</p>

                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="radio-inline m-b-0 m-r-15">
                                                            <input type="radio" name="type" value="1" checked={this.state.type === 1} onChange={() => this.handleChangeFileSets(1)} />Upload File
                                                        </label>
                                                        <label className="radio-inline m-b-0 m-r-15">
                                                            <input type="radio" name="type" value="0" checked={this.state.type === 0} onChange={() => this.handleChangeFileSets(0)} /> Paste Link
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={this.state.type != 1 ? 'hide' : '' + 'row'}>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">Name Your File<span className="text-danger">*</span></label>
                                                        <input type="text" name="file_name" className="form-control" value={this.state.form_data.file_name} placeholder="Enter Title" onChange={this.changeFormData} required={true} />
                                                    </div>
                                                </div>
                                                <p className="text-danger">{this.state.file_name_err}</p>

                                            </div>
                                            <div className={this.state.type === 1 ? 'hide' : '' + 'row'}>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">Link <span className="text-danger">*</span></label>
                                                        <input type="text" name="youtube_link" value={this.state.form_data.youtube_link} className="form-control" placeholder="Enter Link" onChange={this.changeFormData} required={true} />
                                                    </div>
                                                </div>
                                                <p className="text-danger">{this.state.link_err}</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">Main Category <span className="text-danger">*</span></label>
                                                        {/* <input type="text" name="main_category" className="form-control" placeholder="Enter Main Category" onChange={this.changeFormData} required={true} /> */}
                                                        <select className="form-control" id="main_category"
                                                            value={this.state.form_data.main_category}
                                                            name="main_category" placeholder='Enter Main Category' onChange={this.changeFormData}>
                                                            <option value="">--Select--</option>
                                                            {main_category_list}
                                                        </select>
                                                    </div>
                                                </div>
                                                <p className="text-danger">{this.state.main_category_err}</p>

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">Sub Category</label>
                                                        <select className="form-control" id="sub_category"
                                                            value={this.state.form_data.sub_category}
                                                            name="sub_category" placeholder='Enter Sub Category' onChange={this.changeFormData}>
                                                            <option value="">--Select--</option>
                                                            {sub_category_list}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">Description</label>
                                                        <textarea className="form-control" name="description" value={this.state.form_data.description} placeholder="Enter Text" onChange={this.changeFormData}></textarea>
                                                    </div>
                                                </div>
                                                <div className={this.state.form_data.type !== 1 ? 'hide' : '' + 'col-md-12'}>
                                                    <div className="file-manager horizontal-scroll admin-file-manager">
                                                        <ul>
                                                            {this.renderFilesModal()}
                                                            {this.state.isProccesingFileUpload &&
                                                                <li>
                                                                    <div className="file-wrapper">
                                                                        <div className="progress-file-icon">
                                                                            <img src="assets/images/blur-inventory.jpg" alt="" />
                                                                            <div className="inventory-progess-bar">
                                                                                <CircularProgressbar value={this.state.uploadPercentage} text={`${this.state.uploadPercentage}%`} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className={this.state.type !== 1 ? 'hide' : '' + 'col-md-12'}>
                                                    <div className="form-group">
                                                        <label className="text-semibold">Upload Video(mp4) / Upload Pdf / Upload PPT<span className="text-danger">*</span></label>
                                                        {this.state.form_data.file_name !== '' &&
                                                            <input type="file" name="files[]" onChange={this.uploadFiles} required={true} />
                                                        }
                                                    </div>
                                                    <span>{this.state.form_data.request_file !== '' && this.getFileName(this.state.form_data.request_file)}</span>
                                                    <p className="text-danger help-block">{this.state.file_upload_err}</p>
                                                </div>
                                            </div>
                                            {/* </form> */}
                                        </div>
                                        <hr className="m-0" />
                                        <div className="modal-footer p-t-15">
                                            {this.state.isProccesingFileUpload ?
                                                <React.Fragment>
                                                    <button type="button" className="btn btn-default" data-dismiss="modal" disabled={true}>Close</button>
                                                    <button className="btn btn-primary btn-xs" disabled={true}>Save</button>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    {this.state.isFileSaving ?
                                                        <button className="btn btn-primary btn-xs" disabled={true} >Processing...</button>
                                                        :
                                                        <React.Fragment>
                                                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                                            <button type="submit" className="btn btn-primary" onClick={this.updateTrainingMaterial}>Update</button>
                                                        </React.Fragment>
                                                    }
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* editTrainingModal end */}
                    </div>
                </div>
            </React.Fragment>

        )
    }
}