import React, { Component } from 'react'

import { Editor } from '@tinymce/tinymce-react';
import PostDataWithParam from '../services/PostDataWithParam';
import DateFormat from '../externaljs/DateFormat';
import PostDataFile from '../services/PostDataFile';
import Storage from '../externaljs/Storage';
import GetData from '../services/GetData';

class MilitaryConversations extends Component {
    state = {
        activeEditor: '',
        messages: [],
        subject: '',
        message_id:'',
        fields: {
            message: '',
            attachment: ''
        },
        error: {
            message_err: ''
        },
        MessaageErr: '',
        MessageStatus: false,
        conversationNotifications:{
            msgCount:0,
            nodeUrl:""
        }
    }

    database = null;

    componentDidMount() {
       
    }

    registerNotifications(){
        this.database.ref('/notifications/conversation/user_' + (JSON.parse(Storage.get('imnLoginData'))).id)
        .on('child_added', (snapshot) => {
            let conversationNotifications = this.state.conversationNotifications;
            let tempData = snapshot.val();
            conversationNotifications.nodeUrl = '/notifications/conversation/user_' + (JSON.parse(Storage.get('imnLoginData'))).id;
            conversationNotifications.msgCount++;
            this.setState({conversationNotifications: conversationNotifications})
        })
    }

    GetConversation = () => {
        GetData('GetConversation')
        .then((response) => {
            if (response.success === 1) {
                this.setState({ messages: response.data },()=>{
                    // Hide if collapsed by default
                    window.$('.panel-conversation').each(function(){
                        
                        window.$(this).children('.panel-heading').nextAll().hide();
                    });


                    // Rotate icon if collapsed by default
                    window.$('.panel-conversation').find('[data-action=collapse]').addClass('rotate-180');
                });
            } else {
                this.setState({ messages: [] },()=>{
                    // Hide if collapsed by default
                    window.$('.panel-conversation').each(function(){
                        
                        window.$(this).children('.panel-heading').nextAll().hide();
                    });


                    // Rotate icon if collapsed by default
                    window.$('.panel-conversation').find('[data-action=collapse]').addClass('rotate-180');
                });
            }
        })
    }

    spanColor = (first_letter,sender_user_id) => {
        let color = '';
        if(sender_user_id === (JSON.parse(Storage.get('imnLoginData'))).id)
             color = 'primary'
        else
             color ='success'
        return (
            <span className={"btn bg-"+color+"-400 btn-rounded btn-icon btn-xs"}>
                <span className="letter-icon">{first_letter.toUpperCase()}</span>
            </span>
        )
    }

    stripTags = (html) => {
        const regex = /(<([^>]+)>)/ig;
        const result = html.replace(regex, '');
        return result
    }

    handleAttachment = (e) => {
        let fields = this.state.fields;
        fields.attachment = e.target.files[0];
        this.setState({ fields: fields });
    }

    handleEditorChange = (e) => {
        let fields = this.state.fields;
        fields.message = e.target.getContent();
        this.setState({ fields: fields });
    }

    handleMessage = () => {
        this.resetError(() => {
            let error = this.state.error;
            if (!this.state.fields.message) {
                error.message_err = 'Message is required.';
            } else {
                let fd = new FormData();
                if (this.state.fields.attachment.name !== undefined) {
                    fd.append('file_name', this.state.fields.attachment, this.state.fields.attachment.name);
                }
                fd.append('message', this.state.fields.message);
                PostDataFile('SendConversation', fd)
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ MessaageErr: response.msg }, () => {
                            window.swal('Success!', response.msg, 'success');
                            this.state.activeEditor.setContent('');
                            this.GetConversation()
                           
                        });
                    } else {
                        this.setState({ MessaageErr: response.msg });
                    }
                })
                .catch(err => {
                    this.setState({ MessaageErr: 'Oops! Something went wrong.' });
                })
            }
            this.setState({ error: error });
        });
    }

    resetError = (cb) => {
        let error = {
            message_err: ''
        }
        this.setState({ error: error }, () => {
            cb();
        });
    }

    clearNotification = () => {
        if(this.state.conversationNotifications.nodeUrl){
            let conversationNotifications = this.state.conversationNotifications;
            this.database.ref(conversationNotifications.nodeUrl).remove();
            conversationNotifications.msgCount = 0;
            this.setState({conversationNotifications: conversationNotifications})
        }        
    }

    render() {
        return (
            <React.Fragment></React.Fragment>
        );
    }
}

export default MilitaryConversations;