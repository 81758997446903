import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Breadcumb from '../externaljs/Breadcrumb';
import { Editor } from '@tinymce/tinymce-react';
import PostDataWithParam from '../services/PostDataWithParam';
import DateFormat from '../externaljs/DateFormat';
import PostDataFile from '../services/PostDataFile';
import ConfirmBox from '../externaljs/ConfirmBox';
import { FileType, FileSvg, FileIcon } from '../externaljs/FileType';
import Storage from '../externaljs/Storage';
import Urls from '../externaljs/Urls';

class MyOfferDetails extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Offers Received', link: '/independent-mover/dashboard/find-an-offer', class: '' },
            { title: 'Offer Details', link: '', class: 'active' }
        ],
        id: '',
        ssn: '',
        type: '',
        details: {
            request_start_date: '',
            request_end_date: '',
            booked: [],
            invited: [],
            offer_from: {
                contact_no: "",
                email: "",
                organization_name: ""
            },
            cya_video: []
        },

        file_group: 1,
        files: [],
        conversationHelperList: [],
        conversationDriverList: [],
        conversationMoverList: [],
        conversationCustomerList: [],
        message: '',
        attachment: [],
        order_type: 0,
        post_date: '',
        post_by: '',
        proccesing: false,
        shareFile: [],
        shareFileDetails: {},
        mover_flag: 0,
        driver_flag: 0,
        helper_flag: 0,
        customer_flag: 0,
        thirdparty_flag: 0,
        packer_flag: 0,
        claimrepair_flag: 0,
        document_id: 0,
        sharedFileWith: [],
        totalMyOfferReceivedMsg: {
            helperTotalMsg: 0,
            moverTotalMsg: 0,
            driveTotalMsg: 0,
            customerTotalMsg: 0
        },
        cya_video_path: '',
        staff_user_id: '',
        custom_menu: [],
        permission: false,
        //for inventory items
        showInventoryTab: false,
        showFilesTab: true,
        showOtherFilesTab: false,
        showOthersTab: false,
        showMoverNote: false,
        showArchiveTab: false,
        inventoryList: null,
        itemList: '',
        btnAddItem: false,
        item_qty: '',
        item_qty1: '',
        offset: 0,
        offsetView: 0,
        limit: 3,
        limitView: 4,
        search_text: '',
        loadMore: 0,
        loadMoreView: 0,
        total: 0,
        totalView: 0,
        selectedItem: [],
        selectedItemList: [],
        allmaininventory: [],
        showInventoryItems: false,
        total_estimated_weight: 0,
        total_cubic_feet: 0,
        requested_mover_note: '',
        get_mover_note: '',
        get_mover_fromDate: '',
        get_mover_toDate: '',
        hire_by_profile_id: '',
        isGetFile: false,
        isGetInventory: false,
        isGetMovernote: true,
        isGetArchiveFile: false,
        arch_files: [],
        tabid: 'order-summary',
        allmessagelist: [],
        conversation_id: '',
        full_name: '',
        is_load_send_proposal: false,
        loading: true,
        edit_msg: true,
        proposalList: [],
        proposalFields: {
            description: '',
            request_files: []
        },
        proposal_err: '',
        sending_proposal: false
    }

    database = null;

    componentDidMount() {

        // if (this.props.location.state) {

        //     this.setState({
        //         id: this.props.location.state.id,
        //         type: this.props.location.state.type,
        //         post_by: this.props.location.state.post_by,
        //         post_date: this.props.location.state.post_date,
        //         order_type: (typeof (this.props.location.state.orderType) == 'undefined' ? '0' : this.props.location.state.orderType)
        //     }, () => {
        //         this.orderDetails();
        //         this.getFiles();
        //         //this.listArchived();
        //         this.getConversationMoverList();
        //         this.getConversationHelperList();
        //         this.getConversationDriverList();
        //         this.getConversationCustomerList();
        //         //this.renderInventoryItems();
        //         //this.GetMoverNote();
        //         this.database = Urls.firebase.database();
        //         this.registerNotifications();
        //         this.getShareFileStatus(this.props.location.state.id);

        //     })
        // } else {
        //     this.props.history.goBack();
        // }

        // let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        // let custom_menu = [];
        // if (staff_user_id != "") {
        //     custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
        //     for (var i = 0; i < custom_menu.length; i++) {
        //         if (custom_menu[i].title == "ReloOrders") {
        //             if (custom_menu[i].is_add == 1 || custom_menu[i].is_add == "1")
        //                 this.setState({ permission: true }, () => {
        //                     // console.log(this.state.permission);
        //                 })
        //         }
        //         let edit_msg = custom_menu.find(menu => menu.title == 'Messages')
        //         if (edit_msg) {
        //             if (edit_msg.is_add == 1 || edit_msg.is_add == '1') this.setState({ edit_msg: true })
        //             else this.setState({ edit_msg: false })
        //         }
        //         else this.setState({ edit_msg: false })
        //     }
        // }
        // this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
        //     // console.log(this.state.custom_menu);
        //     // console.log(this.state.staff_user_id);
        // });

        this.loadOfferDetails();

    }

    loadOfferDetails() {
        if (this.props.location.state) {

            this.setState({
                id: this.props.location.state.id,
                type: this.props.location.state.type,
                post_by: this.props.location.state.post_by,
                post_date: this.props.location.state.post_date,
                order_type: (typeof (this.props.location.state.orderType) == 'undefined' ? '0' : this.props.location.state.orderType)
            }, () => {
                this.orderDetails();
                this.getFiles();
                //this.listArchived();
                this.getConversationMoverList();
                this.getConversationHelperList();
                this.getConversationDriverList();
                this.getConversationCustomerList();
                this.renderInventoryItems();
                this.GetMoverNote();
                this.database = Urls.firebase.database();
                this.registerNotifications();
                this.getShareFileStatus(this.props.location.state.id);
                this.getPrposalDetails();

            })
        } else {
            this.props.history.goBack();
        }

        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        let custom_menu = [];
        if (staff_user_id != "") {
            custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
            for (var i = 0; i < custom_menu.length; i++) {
                if (custom_menu[i].title == "ReloOrders") {
                    if (custom_menu[i].is_add == 1 || custom_menu[i].is_add == "1")
                        this.setState({ permission: true }, () => {
                            // console.log(this.state.permission);
                        })
                }
                let edit_msg = custom_menu.find(menu => menu.title == 'Messages')
                if (edit_msg) {
                    if (edit_msg.is_add == 1 || edit_msg.is_add == '1') this.setState({ edit_msg: true })
                    else this.setState({ edit_msg: false })
                }
                else this.setState({ edit_msg: false })
            }
        }
        this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
            // console.log(this.state.custom_menu);
            // console.log(this.state.staff_user_id);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.state) {

            if (this.props.location.state.actionType && this.props.location.state.actionType === 'SEND_PROPOSAL' && !this.state.loading && !this.state.is_load_send_proposal) {
                this.setState({ is_load_send_proposal: true });
                // window.scrollTo(0, 100000);
            }
            if (this.props.location.state.id !== prevProps.location.state.id) {
                this.loadOfferDetails()
            }
        }

    }

    renderInventoryItems = () => {

        PostDataWithParam('GetInventoryListForCustomer', { order_id: this.state.id, offset: '' })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ allmaininventory: response.data.item_list, total: response.data.total_items }, () => {
                        this.setState({ showInventoryItems: true })
                        //   if(this.state.allmaininventory.length>0){
                        //       this.setState({isGetInventory:true},()=>{this.setInvenotryTab()})
                        //   }
                        //   let offset = this.state.offset;
                        //     if (offset == 0) {
                        //         offset = this.state.limit;
                        //     } else {
                        //         offset += this.state.limit;
                        //     }
                        //     this.setState({ offset: offset });
                        //   console.log(this.state.allmaininventory)
                    });
                } else {
                    this.setState({ allmaininventory: [], loadMore: 0, offset: 0, total: 0, isGetInventory: false });
                    // window.swal("Error!", "Oops! Something went wrong. 1", "error");
                }
            })
            .catch((err) => {
                this.setState({ allmaininventory: [], loadMore: 0, offset: 0, total: 0 });
                //   window.swal("Error!", "Oops! Something went wrong. 2", "error");
            });
    }

    showItemList = () => {

        if (this.state.allmaininventory.length > 0) {
            let inventory_items = this.state.allmaininventory.map((item, i) => {
                return (

                    <React.Fragment>
                        <div className="panel panel-default text-grey-600" key={item.room_id}>
                            <a href="javascript:void();" data-toggle="collapse" data-target={'#inventory-body' + item.room_id} className="">
                                <div className="panel-heading">
                                    <div className="heading-elements">
                                        <div className="icons-list text-grey"> {item.items.length} item(s) selected<i className="icon-arrow-down22"></i></div>
                                    </div>
                                    <h6 className="m-0 text-success">{item.title} ({item.items.length})</h6>

                                </div>
                            </a>
                            <hr className="m-0" />
                            <div className="collapse" id={'inventory-body' + item.room_id}>
                                <div className="panel-body">
                                    <div className="show-inventory-items file-manager horizontal-scroll">

                                        <ul>
                                            {/* <h6 className="text-success">{item.title}</h6> */}
                                            {
                                                item.items.map((sub_item, j) => {
                                                    return (
                                                        <li>
                                                            <div className="well p-15 m-b-0">
                                                                <div className="media">
                                                                    <div className="media-left">
                                                                        <img src={"https://myrelo.us/relotechApi/public/uploads/inventory/item/" + sub_item.image} className="img-rounded media-inventory-preview" alt="" />
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <label className="text-bold m-b-0 text-purple-600">Item Name</label>
                                                                        <p className="m-b-5">{sub_item.title}</p>
                                                                        <label className="text-bold m-b-5 text-purple-600">Qty</label>
                                                                        <p className="m-b-5">{sub_item.quantity}</p>
                                                                    </div>
                                                                    <div className="media-right">

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                })
                                            }

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </React.Fragment>
                );
            })
            return inventory_items;
        }
        else {
            // return <h6 className="text-grey-600">No files were found.</h6>
        }
    }

    setInvenotryTab = (e) => {
        this.setState({ showInventoryTab: true, showOtherFilesTab: false, showOthersTab: false, showFilesTab: false, showArchiveTab: false })
        this.renderInventoryItems();
    }

    setOthersTab = (e) => {
        this.setState({ showInventoryTab: false, showOtherFilesTab: false, showOthersTab: true, showFilesTab: false, showArchiveTab: false })
        this.GetMoverNote();
    }

    setArchiveTab = (e) => {
        this.setState({ showInventoryTab: false, showOtherFilesTab: false, showOthersTab: false, showFilesTab: false, showArchiveTab: true })
        this.listArchived();

    }

    //   listArchived=()=>

    //   {


    //     PostDataWithParam('ArchiveImageList', {order_request_detail_id: this.state.id})
    //     .then(response => {
    //         if(response.success == 1){

    //             this.setState({arch_files: response.data,proccesing:false,showInventoryTab: false, showOthersTab: false, showFilesTab: false},()=>{
    //                 // console.log('files',this.state.arch_files)
    //                 if(this.state.arch_files.length>0){
    //                     this.setState({showArchiveTab:true })
    //                 }
    //             });
    //         }else{
    //             this.setState({ arch_files: [],proccesing:false });
    //         }
    //     })
    //     .catch(err => {

    //         this.setState({ arch_files: [],proccesing:false });
    //     });
    // }




    registerNotifications() {
        this.database.ref('/notifications/order_conversations_message/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '_' + this.state.id)
            .on('child_added', (snapshot) => {

                let tempData = snapshot.val();
                let conversationHelperList = this.state.conversationHelperList;
                let conversationDriverList = this.state.conversationDriverList;
                let conversationMoverList = this.state.conversationMoverList;
                let conversationCustomerList = this.state.conversationCustomerList;

                let tempListH = conversationHelperList.map((itemH, iH) => {
                    if (itemH.conversations_user_id == tempData.sender_user_id) {
                        let tempItemH = itemH;
                        tempItemH.msgCount++;
                        tempItemH.nodeUrl = '/notifications/order_conversations_message/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '_' + this.state.id;
                        return tempItemH;
                    } else {
                        return itemH;
                    }
                });

                let tempListD = conversationDriverList.map((itemD, iD) => {
                    if (itemD.conversations_user_id == tempData.sender_user_id) {
                        let tempItemD = itemD;
                        tempItemD.msgCount++;
                        tempItemD.nodeUrl = '/notifications/order_conversations_message/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '_' + this.state.id;
                        return tempItemD;
                    } else {
                        return itemD;
                    }
                });

                let tempListM = conversationMoverList.map((itemM, iM) => {
                    if (itemM.conversations_user_id == tempData.sender_user_id) {
                        let tempItemM = itemM;
                        tempItemM.msgCount++;
                        tempItemM.nodeUrl = '/notifications/order_conversations_message/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '_' + this.state.id;
                        return tempItemM;
                    } else {
                        return itemM;
                    }
                });
                let tempListC = conversationCustomerList.map((itemM, iM) => {
                    if (itemM.conversations_user_id == tempData.sender_user_id) {
                        let tempItemM = itemM;
                        tempItemM.msgCount++;
                        tempItemM.nodeUrl = '/notifications/order_conversations_message/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '_' + this.state.id;
                        return tempItemM;
                    } else {
                        return itemM;
                    }
                });
                this.setState({ conversationHelperList: tempListH, conversationDriverList: tempListD, conversationMoverList: tempListM, conversationCustomerList: tempListC })
            })
    }

    orderDetails() {
        this.setState({ loading: true }, () => {
            PostDataWithParam('GetOrderDetailsForMovers', { order_id: this.state.id, type: this.state.type })
                .then(response => {
                    if (response.success == 1) {
                        let details = response.data[0];

                        if (details.order_status === "1") {
                            this.props.history.push({
                                pathname: "/independent-mover/dashboard/order-details",
                                state: {
                                    id: this.state.id,
                                    type: "accepted"
                                }
                            });
                        } else if (details.status === "2") {
                            this.props.history.push({
                                pathname: "/independent-mover/dashboard/closed-order-details",
                                state: {
                                    id: this.state.id,
                                    type: ""
                                }
                            });
                        }

                        let total_estimated_weight = details.total_estimated_weight || this.state.total_estimated_weight;
                        let total_cubic_feet = details.total_cubic_feet || this.state.total_cubic_feet;
                        this.setState({ details: details, total_cubic_feet, total_estimated_weight, loading: false }, () => {
                            // console.log('@',this.state.details)
                            this.setState({ hire_by_profile_id: this.state.details.hire_by_profile_id }, () => {
                                this.getConversationCustomerList();
                            })
                            this.getShareFileDetails();
                            this.ssnFormat(this.state.details.ssn);
                        })
                    } else {
                        this.setState({
                            details: {
                                booked: [],
                                invited: [],
                                cya_video: []
                            }, loading: false
                        })
                    }
                })
                .catch(err => {
                    this.setState({
                        details: {
                            booked: [],
                            invited: [],
                            cya_video: []
                        }, loading: false
                    })
                })
        })
    }

    goBack(e) {
        e.preventDefault();
        this.props.history.push({
            pathname: this.state.pathname
        })
    }

    async acceptRejectOffers(status, e) {
        e.preventDefault();
        this.setState({ loading: true });
        // PostDataWithParam('AcceptRejectOffer', {offer_id:this.state.id, status:status})
        // .then(response => {
        //     if(response.success === 1){
        //         window.swal('Success!', response.msg, 'success');
        //         let details= this.state.details
        //         this.setState({loading:true},()=>{
        //             details.order_status=1
        //             this.setState({details:details,loading:false});
        //         })
        //     }else{
        //         window.swal('Error!', response.msg, 'error');
        //     }
        // })
        // .catch(err => {})
        let user_id = (JSON.parse(Storage.get('imnLoginData'))).id;
        const { order_id, request_start_date, request_end_date } = this.state.details;
        let schedule = [
            {
                order_request_detail_id: order_id,
                user_id: user_id,
                date_from: DateFormat.getFormattedDateTime(request_start_date, 'm/d/Y'),
                date_to: DateFormat.getFormattedDateTime(request_end_date, 'm/d/Y'),
                is_full: 1,
                is_morning: 0,
                is_evening: 0,
            }
        ]
        try {
            let response = await PostDataWithParam('AcceptRejectOffer', { offer_id: this.state.id, status: status });
            if (response.success === 1) {
                let scheduleResponse = await PostDataWithParam('CreateScheduleJob', { schedule });

                window.swal('Success!', response.msg, 'success');
                let details = this.state.details;
                details.order_status = 1
                this.setState({ details: details, loading: false });
                this.props.history.push({
                    pathname: '/independent-mover/dashboard/find-an-offer',
                    state: {
                        orderType: status
                    }

                })
            } else {
                window.swal('Error!', response.msg, 'error');
            }
        } catch (err) {
            this.setState({ loading: false });
            console.log(err)
            window.swal('Error!', 'Oops! Something went wrong', 'error');
        }



    }

    /**
     * Files
     */

    setFileGroup(file_group, e) {
        e.preventDefault();
        this.setState({ file_group: file_group, showInventoryTab: false, showOtherFilesTab: false, showFilesTab: true, showOthersTab: false, showArchiveTab: false }, () => {
            this.getFiles();
        });
    }

    setOtherFiles(file_group, e) {
        e.preventDefault();
        this.setState({ file_group: file_group, showOtherFilesTab: true, showInventoryTab: false, showFilesTab: false, showOthersTab: false, showArchiveTab: false }, () => {
            this.getFiles();
        });
    }

    getFiles() {
        PostDataWithParam('GetOfferFiles', { order_request_detail_id: this.state.id, file_group: this.state.file_group })
            .then(response => {
                if (response.success == 1) {

                    this.setState({ files: response.data, proccesing: false }, () => {
                        // console.log('files',this.state.files)
                        if (this.state.files.length > 0) {
                            this.setState({ isGetFile: true })
                        }
                    });
                } else {
                    this.setState({ files: [], proccesing: false, isGetFile: false });
                }
            })
            .catch(err => {

                this.setState({ files: [], proccesing: false });
            });
    }

    // getFiles() {
    //     PostDataWithParam('GetOfferFiles', { order_request_detail_id: this.state.id, file_group: this.state.file_group })
    //         .then(response => {
    //             if (response.success == 1) {
    //                 this.setState({ files: response.data, proccesing: false, showFilesTab: true, showOthersTab: false, showArchiveTab: false });
    //             } else {
    //                 this.setState({ files: [], proccesing: false });
    //             }
    //         })
    //         .catch(err => {
    //             this.setState({ files: [], proccesing: false });
    //         });
    // }

    uploadFiles(e) {
        if (e.target.files.length > 0) {
            let formData = new FormData();
            for (let i = 0; i < e.target.files.length; i++) {
                formData.append('request_files[' + i + ']', e.target.files[i], e.target.files[i].name);
            }
            formData.append('hire_by_profile_id', this.state.details.hire_by_profile_id);
            formData.append('file_group', this.state.file_group);
            formData.append('order_request_detail_id', this.state.id);
            this.setState({ proccesing: true }, () => {
                PostDataFile('UploadOfferFiles', formData)
                    .then(response => {
                        if (response.success == 1) {
                            window.swal('Success!', response.msg, 'success');
                            this.getFiles();
                        } else {
                            window.swal('Error!', response.msg, 'error')
                        }
                    })
                    .catch(err => {

                    });
            });
        }
    }

    getFileName(file) {
        let files = file.request_file.split('/');
        return files[files.length - 1];
    }
    getFileProposalName(file) {
        let files = file.file_path.split('/');
        return files[files.length - 1];
    }

    deleteFile(id, e) {
        e.preventDefault();
        ConfirmBox("You want to delete this file!")
            .then((result) => {
                if (result) {
                    PostDataWithParam('DeleteOfferFiles', { id: id })
                        .then((response) => {
                            if (response.success == 1) {
                                window.swal('Deleted!', response.msg, 'success')
                                this.getFiles();
                            }
                        })
                        .catch(err => {

                        })
                }
            })
    }
    shareWith = (e) => {
        e.preventDefault();
        let shareFile = this.state.shareFile;

        if (shareFile.indexOf(e.target.value) > -1) {
            let temp = shareFile.filter((item, i) => {
                if (item == e.target.value && e.target.checked == false)
                    return false;
                else
                    return true;
            });
            shareFile = temp;
        } else {
            shareFile.push(e.target.value);
        }
        this.setState({ shareFile: shareFile });
    }
    submitShareWith = (e) => {
        e.preventDefault();
        this.state.mover_flag = 0;
        this.state.helper_flag = 0;
        this.state.driver_flag = 0;
        this.state.customer_flag = 0;
        this.state.thirdparty_flag = 0;
        this.state.packer_flag = 0;
        this.state.claimrepair_flag = 0;
        this.state.shareFile.filter(item => {
            if (item == 'mover') {
                this.state.mover_flag = 1;
            } else if (item == 'helper') {
                this.state.helper_flag = 1;
            }
            else if (item == 'driver') {
                this.state.driver_flag = 1;
            } else if (item == 'customer') {
                this.state.customer_flag = 1;
            }
            else if (item == '3rd party') {
                this.state.thirdparty_flag = 1;
            }
            else if (item == 'packer') {
                this.state.packer_flag = 1;
            }
            else if (item == 'claim repair') {
                this.state.claimrepair_flag = 1;
            }
        })
        let user_id = (JSON.parse(Storage.get('imnLoginData'))).id;
        PostDataWithParam("ShareFile",
            {
                order_id: this.state.details.id,
                user_id: user_id,
                profile_id: this.state.details.hire_by_profile_id,
                mover_flag: this.state.mover_flag,
                driver_flag: this.state.driver_flag,
                helper_flag: this.state.helper_flag,
                customer_flag: this.state.customer_flag,
                thirdparty_flag: this.state.thirdparty_flag,
                packer_flag: this.state.packer_flag,
                claimrepair_flag: this.state.claimrepair_flag,
                document_id: this.state.document_id
            }).then(response => {
                if (response.success === 1) {
                    if (this.props.location.state) {
                        this.getShareFileStatus(this.props.location.state.id);
                    }
                    window.swal('Share', response.msg, 'success')
                } else {
                    window.swal("Error", response.msg, 'Error')
                }
            }).catch(err => {
                window.swal("Error", err.msg, 'Error')
            })

    }
    getShareFileStatus = (orderId) => {
        PostDataWithParam("ShareFileStatus", { order_id: orderId })
            .then(response => {
                if (response.success === 1) {
                    this.setState({ sharedFileWith: response.data })
                } else {
                    this.setState({ sharedFileWith: [] });
                }
            }).catch(err => {
                this.setState({ sharedFileWith: [] });
            })
    }
    getShareFileDetails = () => {
        PostDataWithParam("ShareFileDetails", { order_id: this.state.details.id, profile_id: 2 })
            .then(response => {
                if (response.success === 1) {
                    this.setState({ shareFileDetails: response.data })
                } else {
                    this.setState({ shareFileDetails: [] });
                }
            }).catch(err => {
                this.setState({ shareFileDetails: [] });
            })
    }

    // toggleAdditionalInfo = (e) => {
    //     e.preventDefault();
    //     window.$('.order-details-panel').slideToggle();
    // }

    getFileId = (e, file_id) => {
        e.preventDefault();
        let files = []
        this.state.sharedFileWith.map((item, i) => {
            if (item.document_id == file_id) {
                if (item.mover_flag == 1) {
                    files.push("mover")
                }
                if (item.helper_flag == 1) {
                    files.push("helper")
                }
                if (item.driver_flag == 1) {
                    files.push("driver")
                }
                if (item.customer_flag == 1) {
                    files.push("customer")
                }
            }
        })

        this.setState({ document_id: file_id, shareFile: files });
    }

    archivefile(id) {

        console.log(id);
        ConfirmBox("you want to archive this file!")
            .then((result) => {
                if (result) {
                    PostDataWithParam('ArchiveImage', { id: id })
                        .then((response) => {
                            if (response.success == 1) {
                                window.swal('Archived!', response.msg, 'success')
                                this.getFiles();

                            }
                        })
                        .catch(err => {

                        })
                }
            })
    }

    listArchived = () => {


        PostDataWithParam('ArchiveImageList', { order_request_detail_id: this.state.id })
            .then(response => {
                if (response.success == 1) {

                    this.setState({ arch_files: response.data, proccesing: false, showInventoryTab: false, showOthersTab: false, showFilesTab: false, showArchiveTab: true }, () => {
                        // console.log('files', this.state.arch_files)
                    });
                } else {
                    this.setState({ arch_files: [], proccesing: false });

                }
            })
            .catch(err => {

                this.setState({ arch_files: [], proccesing: false });
            });
    }
    // getFileName(file) {
    //     if (typeof (file.request_file) !== 'undefined') {
    //         let files = file.request_file.split("/");
    //         return files[files.length - 1];
    //     }
    //     else {
    //         let files = file.split("/")
    //         return files[files.length - 1]
    //     }
    // }
    renderProposal() {
        if (this.state.proposalList.length > 0) {
            let proposal = this.state.proposalList.map((proposal, index) => {
                return (
                    <div key={index}>
                        <div className="message-body clearfix">
                            <div className="media m-b-15">
                                <span className="media-left p-r-15">
                                    <span className="bg-primary-400 btn btn-rounded btn-icon btn-xs">{JSON.parse(Storage.get('imnLoginData')).first_name.charAt(0)}</span>
                                </span>
                                <div className="media-body">
                                    <span className="media-heading text-bold">{JSON.parse(Storage.get('imnLoginData')).first_name}&nbsp;&nbsp;
                                        {JSON.parse(Storage.get('imnLoginData')).last_name}</span>
                                    <div className="text-mid-grey f-s-13">
                                        {DateFormat.getFormattedDateTime(proposal.created_date, 'm-d-Y h:i a')}
                                    </div>
                                </div>
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: proposal.description }}></p>
                            <div className="attachedment-files">
                                {proposal.request_files && proposal.request_files.length > 0
                                    &&
                                    <ul className="file-list">
                                        {this.renderFilesView(proposal.request_files)}
                                    </ul>
                                }
                            </div>
                        </div>
                        {
                            this.state.proposalList.length !== index + 1
                            &&
                            <hr />
                        }
                    </div>
                )
            });
            return <div className="p-20 well m-20">
                {proposal}
            </div>
        }
        else {
            return (parseInt(this.state.details.award_status) !== 1 && parseInt(this.state.details.order_status) !== 2)
                ?
                null
                :
                <div className="p-20 well m-20"><h6 className="alert alert-primary no-border">No Results Found.</h6></div>
        }

    }
    renderFilesView(request_files) {
        if (request_files.length > 0) {
            let files = request_files.map((file, i) => {
                return (
                    // <li>
                    //     <a href='javascript:void(0);'><i className="icon-image2 position-left text-primary"></i>image-file-1248</a>
                    //     </li>
                    <li>
                        <a href={file.file_path} target="_blank"><i className="icon-file-zip position-left text-primary"></i>{this.getFileProposalName(file)}</a>
                    </li>

                );
            });

            return files;
        }
        // else {
        //     return <h6 className="text-grey-600">No files were found.</h6> ;
        // }

    }
    renderArchiveFile() {
        if (this.state.arch_files.length > 0) {
            let files = this.state.arch_files.map((file, i) => {
                return (
                    <React.Fragment key={'item-' + i}>
                        {
                            (JSON.parse(Storage.get('imnLoginData'))).id != file.uploaded_by_user_id
                            &&
                            // this.state.shareFileDetails[file.id]==1
                            // &&
                            <React.Fragment>
                                <li>
                                    <div className={"file-wrapper " + FileType(file.file_type)}>
                                        {FileSvg(file.file_type, file.request_file)}
                                        <div className="file-title"><a href={file.request_file} ><i className={FileIcon(file.file_type) + " position-left text-primary"}></i> {this.getFileName(file)}</a>
                                        </div>
                                        <div className="overlay-file">
                                            {/* <a href="" className="text-success" data-toggle="modal" data-target={"#showarchivefile" + i}><i className="icon-eye4 f-s-20"></i> <span className="show">View</span></a> */}
                                            {FileType(file.file_type) == "filetype-pdf" ?
                                                <a href={file.request_file} target="_blank"><i className="icon-eye4 f-s-18"></i> <span className="show">View</span></a>
                                                :
                                                <a href="" className="text-success" data-toggle="modal" data-target={"#showarchivefile" + i}><i className="icon-eye4 f-s-18"></i> <span className="show">View</span></a>
                                            }
                                            {/* <a href="" className="text-danger" onClick={this.deleteFile.bind(this, file.id)}><i className="icon-cancel-circle2 f-s-20"></i> <span className="show">Delete</span></a> */}

                                        </div>
                                    </div>
                                </li>
                            </React.Fragment>
                        }
                        {
                            (JSON.parse(Storage.get('imnLoginData'))).id === file.uploaded_by_user_id
                            &&
                            <React.Fragment>
                                <li >
                                    <div className={"file-wrapper " + FileType(file.file_type)}>
                                        {FileSvg(file.file_type, file.request_file)}
                                        <div className="file-title"><a href={file.request_file}><i className={FileIcon(file.file_type) + " position-left text-primary"}></i> {this.getFileName(file)}</a></div>
                                        <div className="overlay-file">
                                            {/* <a href="" className="text-success" data-toggle="modal" data-target={"#showarchivefile" + i}><i className="icon-eye4 f-s-20"></i> <span className="show">View</span></a> */}
                                            {FileType(file.file_type) == "filetype-pdf" ?
                                                <a href={file.request_file} target="_blank"><i className="icon-eye4 f-s-18"></i> <span className="show">View</span></a>
                                                :
                                                <a href="" className="text-success" data-toggle="modal" data-target={"#showarchivefile" + i}><i className="icon-eye4 f-s-18"></i> <span className="show">View</span></a>
                                            }
                                            {/* <a href="" className="text-danger" onClick={this.deleteFile.bind(this, file.id)}><i className="icon-cancel-circle2 f-s-20"></i> <span className="show">Delete</span></a> */}

                                        </div>
                                    </div>
                                </li>
                            </React.Fragment>
                        }
                        {/* <div id={"showarchivefile" + i} className="modal fade" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        <h4 className="modal-title"></h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className={"file-wrapper " + FileType(file.file_type)}>
                                                {FileSvg(file.file_type, file.request_file)}
                                                <div className="file-title m-t-15"><a href={file.request_file}><i className={FileIcon(file.file_type) + " position-left text-primary"}></i> {this.getFileName(file)}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div id={"showarchivefile" + i} className="modal fade" role="dialog">
                            <div className="modal-dialog modal-sm">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        {/* <button type="button" className="close" data-dismiss="modal" onClick={(e)=>this.closeModal("audio-video"+i)}>&times;</button> */}
                                        {FileType(file.file_type) == "filetype-image" &&
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        }
                                        {FileType(file.file_type) == "filetype-video" &&
                                            <button type="button" className="close" data-dismiss="modal" onClick={(e) => this.closeModal("audio-video" + i)}>&times;</button>
                                        }
                                        <h4 className="modal-title" style={{ color: "black" }}>File Details</h4>
                                    </div>
                                    <hr className="m-0" />
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="show-file-picture">
                                                    <div className={FileType(file.file_type)}>
                                                        {/* {FileSvg(file.file_type, file.request_file)} */}
                                                        {FileType(file.file_type) == "filetype-image" &&
                                                            <img src={file.request_file} />}

                                                        {FileType(file.file_type) == "filetype-video" &&
                                                            <video width="100%" height="100%" controls id={"audio-video" + i}>
                                                                <source src={file.request_file} type="video/mp4" />
                                                            </video>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="file-title m-t-15">
                                                    <h6 style={{ color: "black" }}><strong>File Name</strong></h6>
                                                    {/* <p style={{ color: "black" }}>{this.getFileName(file.file_name)}</p> */}
                                                    <p style={{ color: "black" }}><pre>{this.getFileName(file)}</pre></p>
                                                    <h6 style={{ color: "black" }}><strong>File Description</strong></h6>
                                                    <p style={{ color: "black" }}><pre>{file.file_description}</pre></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {/* <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e)=>this.closeModal("audio-video"+i)}>Close</button> */}
                                        {FileType(file.file_type) == "filetype-image" &&
                                            <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                                        }
                                        {FileType(file.file_type) == "filetype-video" &&
                                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e) => this.closeModal("audio-video" + i)}>Close</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </React.Fragment>
                )
            });

            return files;
        } else {
            return <h6 className="alert alert-primary no-border">No files were found.</h6>
        }
    }

    // renderArchiveFile() {
    //     if (this.state.arch_files.length > 0) {
    //         let files = this.state.arch_files.map((file, i) => {
    //             return (
    //                 <React.Fragment key={'item-' + i}>
    //                     {
    //                         (JSON.parse(Storage.get('imnLoginData'))).id != file.uploaded_by_user_id
    //                         &&
    //                         // this.state.shareFileDetails[file.id]==1
    //                         // &&
    //                         <React.Fragment>
    //                             <li>
    //                                 <div className={"file-wrapper " + FileType(file.file_type)}>
    //                                     {FileSvg(file.file_type, file.request_file)}
    //                                     <div className="file-title"><a href={file.request_file}><i className={FileIcon(file.file_type) + " position-left text-primary"}></i> {this.getFileName(file)}</a>
    //                                     </div>
    //                                 </div>
    //                             </li>
    //                         </React.Fragment>
    //                     }
    //                     {
    //                         (JSON.parse(Storage.get('imnLoginData'))).id === file.uploaded_by_user_id
    //                         &&
    //                         <React.Fragment>
    //                             <li >
    //                                 <div className={"file-wrapper " + FileType(file.file_type)}>
    //                                     {FileSvg(file.file_type, file.request_file)}
    //                                     <div className="file-title"><a href={file.request_file}><i className={FileIcon(file.file_type) + " position-left text-primary"}></i> {this.getFileName(file)}</a></div>
    //                                 </div>
    //                             </li>
    //                         </React.Fragment>
    //                     }
    //                 </React.Fragment>
    //             )
    //         });

    //         return files;
    //     } else {
    //         return <h6 className="alert alert-danger m-b-0">No files were found.</h6>
    //     }
    // }                          


    renderFiles() {
        if (this.state.files.length > 0) {
            let files = this.state.files.map((file, i) => {
                console.log(file);
                return (
                    <React.Fragment key={'item-' + file.id}>
                        {
                            (JSON.parse(Storage.get('imnLoginData'))).id === file.uploaded_by_user_id
                            &&
                            // this.state.shareFileDetails[file.id]==1
                            // &&
                            <React.Fragment>
                                <li>
                                    <div className={"file-wrapper " + FileType(file.file_type)}>
                                        {FileSvg(file.file_type, file.request_file)}
                                        <div className="file-title"><a href={file.request_file}><i className={FileIcon(file.file_type) + " position-left text-primary"}></i> {this.getFileName(file)}</a>
                                        </div>
                                        <div className="overlay-file">
                                            {/* <a href="" className="text-success" data-toggle="modal" data-target={"#showfile" + file.id}><i className="icon-eye4 f-s-20"></i> <span className="show">View</span></a> */}
                                            {FileType(file.file_type) == "filetype-pdf" ?
                                                <a href={file.request_file} target="_blank"><i className="icon-eye4 f-s-18"></i> <span className="show">View</span></a>
                                                :
                                                <a href="" className="text-success" data-toggle="modal" data-target={"#showfile" + i}><i className="icon-eye4 f-s-18"></i> <span className="show">View</span></a>
                                            }
                                            <a href="" className="text-danger" onClick={this.deleteFile.bind(this, file.id)}><i className="icon-cancel-circle2 f-s-20"></i> <span className="show">Delete</span></a>

                                        </div>
                                    </div>
                                </li>
                            </React.Fragment>
                        }
                        {
                            (JSON.parse(Storage.get('imnLoginData'))).id != file.uploaded_by_user_id
                            &&
                            <React.Fragment>
                                <li >
                                    <div className={"file-wrapper " + FileType(file.file_type)}>
                                        {FileSvg(file.file_type, file.request_file)}
                                        <div className="file-title"><a href={file.request_file}><i className={FileIcon(file.file_type) + " position-left text-primary"}></i> {this.getFileName(file)}</a></div>
                                        <div className="overlay-file archive-filetype">

                                            {FileType(file.file_type) == "filetype-pdf" ?
                                                <a href={file.request_file} target="_blank"><i className="icon-eye4 f-s-18"></i> <span className="show">View</span></a>
                                                :
                                                <a href="" className="text-success" data-toggle="modal" data-target={"#showfile" + i}><i className="icon-eye4 f-s-18"></i> <span className="show">View</span></a>
                                            }
                                            <a href="" className="text-info" data-toggle="modal" data-target={"#sharefile" + file.id} onClick={(e) => this.getFileId(e, file.id)}><i className="icon-share3 f-s-20"></i> <span className="show">Share</span></a>

                                            <a href="javascript:void(0)" className="text-success" onClick={this.archivefile.bind(this, file.id)}><i className="icon-archive f-s-20"></i> <span className="show">Archive</span></a>
                                        </div>
                                    </div>
                                </li>
                            </React.Fragment>
                        }
                        <div id={"sharefile" + file.id} className="modal fade" role="dialog">
                            <div className="modal-dialog modal-xs">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        <h4 className="modal-title">Share With</h4>
                                    </div>
                                    <div className="modal-body">
                                        <div className="form-group m-b-0">
                                            <label className="checkbox-inline custom-checkbox check-success">
                                                <input type="checkbox" name="driver" value="driver" checked={this.state.shareFile.indexOf('driver') > -1} onChange={this.shareWith} /><span></span> Driver</label>
                                        </div>
                                        <div className="form-group m-b-0">
                                            <label className="checkbox-inline custom-checkbox check-success">
                                                <input type="checkbox" name="helper" value="helper" checked={this.state.shareFile.indexOf('helper') > -1} onChange={this.shareWith} /><span></span> Helper</label>
                                        </div>
                                        <div className="form-group m-b-0">
                                            <label className="checkbox-inline custom-checkbox check-success">
                                                <input type="checkbox" name="mover" value="mover" checked={this.state.shareFile.indexOf('mover') > -1} onChange={this.shareWith} /><span></span> Moving Company</label>
                                        </div>
                                        <div className="form-group m-b-0">
                                            <label className="checkbox-inline custom-checkbox check-success">
                                                <input type="checkbox" name="customer" value="customer" checked={this.state.shareFile.indexOf('customer') > -1} onChange={this.shareWith} /><span></span> Customer</label>
                                        </div>
                                        <div className="form-group m-b-10">
                                            <label className="checkbox-inline custom-checkbox check-success">
                                                <input type="checkbox" name="packer" value="packer" checked={this.state.shareFile.indexOf('packer') > -1} onChange={this.shareWith} /><span></span> Packer</label>
                                        </div>
                                        <div className="form-group m-b-10">
                                            <label className="checkbox-inline custom-checkbox check-success">
                                                <input type="checkbox" name="3rd party" value="3rd party" checked={this.state.shareFile.indexOf('3rd party') > -1} onChange={this.shareWith} /><span></span>3rd party</label>
                                        </div>
                                        <div className="form-group m-b-10">
                                            <label className="checkbox-inline custom-checkbox check-success">
                                                <input type="checkbox" name="claim repair" value="claim repair" checked={this.state.shareFile.indexOf('claim repair') > -1} onChange={this.shareWith} /><span></span>claim repair</label>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.submitShareWith}>Done</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* showfile Modal start */}

                        <div id={"showfile" + i} className="modal fade" role="dialog">
                            <div className="modal-dialog modal-sm">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        {/* <button type="button" className="close" data-dismiss="modal" onClick={(e) => this.closeModal("audio-video" + i)}>&times;</button> */}
                                        {FileType(file.file_type) == "filetype-image" &&
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        }
                                        {FileType(file.file_type) == "filetype-video" &&
                                            <button type="button" className="close" data-dismiss="modal" onClick={(e) => this.closeModal("audio-video" + i)}>&times;</button>
                                        }
                                        <h4 className="modal-title" style={{ color: "black" }}>File Details</h4>
                                    </div>
                                    <hr className="m-0" />
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="show-file-picture">
                                                    <div className={FileType(file.file_type)}>
                                                        {/* {FileSvg(file.file_type, file.request_file)} */}
                                                        {FileType(file.file_type) == "filetype-image" &&
                                                            <img src={file.request_file} />}

                                                        {FileType(file.file_type) == "filetype-video" &&
                                                            <video width="100%" height="100%" controls id={"audio-video" + i}>
                                                                <source src={file.request_file} type="video/mp4" />
                                                            </video>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="file-title m-t-15">
                                                    <h6 style={{ color: "black" }}><strong>File Name</strong></h6>
                                                    {/* <p style={{ color: "black" }}>{this.getFileName(file.file_name)}</p> */}
                                                    <p style={{ color: "black" }}><pre>{this.getFileName(file)}</pre></p>
                                                    <h6 style={{ color: "black" }}><strong>File Description</strong></h6>
                                                    <p style={{ color: "black" }}><pre>{file.file_description}</pre></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {/* <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e) => this.closeModal("audio-video" + i)}>Close</button> */}
                                        {FileType(file.file_type) == "filetype-image" &&
                                            <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                                        }
                                        {FileType(file.file_type) == "filetype-video" &&
                                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e) => this.closeModal("audio-video" + i)}>Close</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* showfile Modal end */}




                    </React.Fragment>
                )
            });

            return files;
        } else {
            return <h6 className="alert alert-primary no-border">No files were found.</h6>
        }
    }

    /**
     * End of files
     */

    /**
     * start of Cya Videos
     */
    getCyaVideo = (e, video_path) => {
        e.preventDefault();
        this.setState({ cya_video_path: video_path });
    }

    renderCyaVideo() {
        if (this.state.proccesing) {
            return <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Please wait...</div>
        } else {
            if (this.state.details.cya_video.length > 0) {
                let cya_video = this.state.details.cya_video.map((cya_video, i) => {
                    return (
                        <React.Fragment key={'item-' + i}>
                            <li>
                                <div className="file-wrapper filetype-pdf">
                                    <div className="options">
                                        <a href="javascript:void(0)" className="text-danger-600"><i className="icon-cancel-circle2"></i></a>
                                    </div>
                                    <a href="#" data-toggle="modal" onClick={(e) => this.getCyaVideo(e, cya_video.video_path)} data-target="#myVideo">
                                        {/* <a  href="javascript:void(0)" data-toggle="modal"  data-target="#myVideo"> */}
                                        <div className="file-icon"><img src="assets/images/icons/video.svg" alt="Audio" /></div>
                                        <div className="file-title"><a href="javascript:void(0)"><i className="icon-file-video position-left text-success"></i>{cya_video.video_name}</a></div>
                                    </a>
                                </div>
                            </li>

                        </React.Fragment>
                    )
                });

                return cya_video;
            } else {
                return <h6 className="alert alert-primary no-border">No files were found.</h6>
            }
        }
    }

    /**
     * End of Cya Videos
     */

    getConversationMoverList() {
        PostDataWithParam('OrderRelatedConversationsPeopleList', { order_request_detail_id: this.state.id, order_type: this.state.order_type, conversations_profile_id: 2 })
            .then(response => {
                if (response.success == 1) {
                    let conversationMoverList = response.data;
                    let tempList = conversationMoverList.map((item, i) => {
                        return { ...item, conversations: [], msgCount: 0, nodeUrl: '' }
                    });
                    this.setState({ conversationMoverList: tempList }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {

                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                } else {
                    this.setState({ conversationMoverList: [] }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {

                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                }

            })
            .catch(err => { })
    }
    getConversationCustomerList() {
        // console.log(this.state.hire_by_profile_id);
        PostDataWithParam('OrderRelatedConversationsPeopleList', { order_request_detail_id: this.state.id, order_type: this.state.order_type, conversations_profile_id: this.state.hire_by_profile_id })
            .then(response => {
                if (response.success == 1) {
                    let conversationCustomerList = response.data;
                    let tempList = conversationCustomerList.map((item, i) => {
                        return { ...item, conversations: [], msgCount: 0, nodeUrl: '' }
                    });
                    this.setState({ conversationCustomerList: tempList }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {

                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                } else {
                    this.setState({ conversationCustomerList: [] }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {

                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                }

            })
            .catch(err => { })
    }

    getConversationHelperList() {
        PostDataWithParam('OrderRelatedConversationsPeopleList', { order_request_detail_id: this.state.id, order_type: this.state.order_type, conversations_profile_id: 5 })
            .then(response => {
                if (response.success == 1) {
                    let conversationHelperList = response.data;
                    let tempList = conversationHelperList.map((item, i) => {
                        return { ...item, conversations: [], msgCount: 0, nodeUrl: '' }
                    });
                    this.setState({ conversationHelperList: tempList }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {

                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                } else {
                    this.setState({ conversationHelperList: [] }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {

                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                }

            })
            .catch(err => { })
    }


    getConversationDriverList() {
        PostDataWithParam('OrderRelatedConversationsPeopleList', { order_request_detail_id: this.state.id, order_type: this.state.order_type, conversations_profile_id: 6 })
            .then(response => {
                if (response.success == 1) {
                    let conversationDriverList = response.data;
                    let tempList = conversationDriverList.map((item, i) => {
                        return { ...item, conversations: [], msgCount: 0, nodeUrl: '' }
                    });
                    this.setState({ conversationDriverList: tempList }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {

                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                } else {
                    this.setState({ conversationDriverList: [] }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {

                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                }
            })
            .catch(err => { })
    }

    getMessages(user_id, flag, item, index, stateName, e) {

        let conversationPeopleList;

        if (stateName != "") {

            if (item.nodeUrl != "") {
                this.database.ref(item.nodeUrl).remove();
            }

            let tempState = this.state[stateName];

            for (let i = 0; i < tempState.length; i++) {
                if (i == index) {
                    tempState[i].msgCount = 0
                }
            }

            this.setState({ [stateName]: tempState });
        }

        PostDataWithParam('GetOrderConversationsMsg', { order_request_detail_id: this.state.id, conversations_user_id: user_id })
            .then(response => {
                if (response.success == 1) {
                    if (flag == 'helper')
                        conversationPeopleList = this.state.conversationHelperList;
                    else if (flag == 'driver')
                        conversationPeopleList = this.state.conversationDriverList;
                    else if (flag == 'mover')
                        conversationPeopleList = this.state.conversationMoverList;
                    else if (flag == 'customer')
                        conversationPeopleList = this.state.conversationCustomerList;

                    let tempList = conversationPeopleList.map((item, i) => {
                        if (item.conversations_user_id == user_id) {
                            let tempItem = item;
                            tempItem.conversations = response.data;
                            return tempItem;
                        } else {
                            return item;
                        }
                    });

                    if (flag == 'helper') {
                        this.setState({ conversationHelperList: tempList })
                    }
                    else if (flag == 'driver') {
                        this.setState({ conversationDriverList: tempList })
                    }
                    else if (flag == 'mover') {
                        this.setState({ conversationMoverList: tempList })
                    }
                    else if (flag == 'customer') {
                        this.setState({ conversationCustomerList: tempList })
                    }

                } else {
                    if (flag == 'helper')
                        conversationPeopleList = this.state.conversationHelperList;
                    else if (flag == 'driver')
                        conversationPeopleList = this.state.conversationDriverList;
                    else if (flag == 'mover')
                        conversationPeopleList = this.state.conversationMoverList;
                    else if (flag == 'customer')
                        conversationPeopleList = this.state.conversationCustomerList;

                    let tempList = conversationPeopleList.map((item, i) => {
                        if (item.conversations_user_id == user_id) {
                            let tempItem = item;
                            tempItem.conversations = [];
                            return tempItem;
                        } else {
                            return item;
                        }
                    });

                    if (flag == 'helper') {
                        this.setState({ conversationHelperList: tempList })
                    }
                    else if (flag == 'driver') {
                        this.setState({ conversationDriverList: tempList })
                    }
                    else if (flag == 'mover') {
                        this.setState({ conversationMoverList: tempList })
                    }
                    else if (flag == 'customer') {
                        this.setState({ conversationCustomerList: tempList })
                    }
                }
            })
            .catch(err => {

            })
    }

    handleOnChange(e) {
        this.setState({ message: e.target.getContent() })
    }
    handleProposalChange(val, e) {
        let proposalFields = this.state.proposalFields;
        if (val === 'description') {
            proposalFields.description = e.target.value;
        }
        else {

            let userfiles = []
            for (let i = 0; i < e.target.files.length; i++) {
                userfiles.push(e.target.files[i])
            }
            proposalFields.request_files = userfiles;
        }
        this.setState({ proposalFields: proposalFields }, () => {
            // console.log(this.state.proposalFields,'sdsadad');

        });
    }
    resetError = (cb) => {

        this.setState({ proposal_err: '' }, () => {
            cb();
        });
    }
    getPrposalDetails = () => {
        PostDataWithParam('ViewProposal', { order_request_detail_id: this.state.id })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ proposalList: response.data }, () => {
                    })
                }
                else {
                    this.setState({ proposalList: [] }, () => {
                    })
                }
            })
    }

    handleProposal = () => {
        this.resetError(() => {
            let proposal_err = this.state.proposal_err;
            if (!this.state.proposalFields.description) {
                proposal_err = 'Message is required.';
            } else {

                let fd = new FormData();
                for (let i = 0; i < this.state.proposalFields.request_files.length; i++) {
                    fd.append('request_files[' + i + ']', this.state.proposalFields.request_files[i], this.state.proposalFields.request_files[i].name);
                }
                fd.append('description', this.state.proposalFields.description);
                fd.append('order_request_detail_id', this.state.id);
                // fd.append('msg_flag', 1);
                this.setState({ sending_proposal: true }, () => {
                    PostDataFile('SendProposal', fd)
                        .then((response) => {
                            if (response.success === 1) {
                                this.setState({}, () => {
                                    window.swal('Success!', response.msg, 'success');
                                    // this.state.activeEditor.setContent('');
                                    let proposalFields = { ...this.state.proposalFields }
                                    proposalFields.request_files = [];
                                    proposalFields.description = '';
                                    this.setState({ proposalFields, sending_proposal: false });
                                    this.getPrposalDetails();
                                });
                            } else {
                                this.setState({ proposal_err: response.msg, sending_proposal: false });
                            }
                        })
                        .catch(err => {
                            this.setState({ proposal_err: 'Oops! Something went wrong.', sending_proposal: false });
                        })
                })
            }
            this.setState({ proposal_err: proposal_err });
        });
    }
    handleFileChange(e) {
        this.setState({ attachment: e.target.files });
    }

    sendMessage(conversations_user_id, flag, j, e) {
        e.preventDefault();
        let formData = new FormData();
        if (this.state.attachment.length > 0) {
            for (let i = 0; i < this.state.attachment.length; i++) {
                formData.append('file_name[' + i + ']', this.state.attachment[i], this.state.attachment[i].name);
            }
        }
        formData.append('order_request_detail_id', this.state.id);
        formData.append('conversations_user_id', conversations_user_id);
        formData.append('message', this.state.message);

        PostDataFile('SentOrderConversationsMsg', formData)
            .then(response => {
                if (response.success == 1) {
                    window.swal('Success!', response.msg, 'success');
                    this.setState({ message: '', attachment: [] }, () => {
                        this.state['activeEditor_' + conversations_user_id].setContent('');

                        this.getMessages(conversations_user_id, flag, '', '', '', null)
                    })
                } else {
                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => {

            })
    }

    renderHelperConversations() {
        if (this.state.conversationHelperList.length > 0) {
            let conversationHelperList = this.state.conversationHelperList.map((item, i) => {
                return (
                    <div className="panel panel-flat profile-page panel-collapsed" key={'helper-' + i}>

                        <a href="javascript:void(0);" id={'conversations_people_' + item.conversations_user_id} data-toggle="collapse" data-target={"#helper-messagebody-" + i} onClick={this.getMessages.bind(this, item.conversations_user_id, 'helper', item, i, 'conversationHelperList')}>
                            <div className="panel-heading">
                                <div className="heading-elements">
                                    <ul className="icons-list">
                                        <li><i className="icon-arrow-down22"></i></li>
                                    </ul>
                                </div>
                                <h6 className="m-0">{item.full_name} <span className="badge bg-green m-l-15">{this.state.totalMyOfferReceivedMsg.helperTotalMsg = item.msgCount}</span></h6>
                            </div>
                        </a>
                        {(this.state.staff_user_id == "" || this.state.permission) &&
                            <div id={"helper-messagebody-" + i} className="collapse">
                                <div className="panel-body p-b-0 editor-box" id={"editor-box-in-helper-" + i} >
                                    <form action="" method="POST" onSubmit={this.sendMessage.bind(this, item.conversations_user_id, 'helper', i)}>
                                        <div className="form-group">
                                            <label className="control-label">Write Something</label>
                                            {/* <Editor
                                                placeHolder="Write Something ..."
                                                initialValue=""
                                                init={{
                                                    setup: editor => {
                                                        this.setState({ ['activeEditor_' + item.conversations_user_id]: editor })
                                                    },
                                                    menubar: false,
                                                    statusbar: false,
                                                    toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                                }}
                                                onChange={this.handleOnChange.bind(this)}
                                                className="form-control textarea-editor"
                                                name="qutationMessage"
                                                id={'qutationHelperMessage-' + i}
                                            /> */}
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="file-label">
                                                    <input className="file-input" name="resume" type="file" onChange={this.handleFileChange.bind(this)} multiple={true} />
                                                    <span className="text-muted">
                                                        <i className="icon icon-attachment"></i> Attach File
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <button className="btn btn-primary">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <hr />
                                <div className="panel-body">
                                    {this.renderMessages(item.conversations)}
                                </div>
                            </div>}
                    </div>
                )
            })
            return conversationHelperList
        } else {
            return <div className="alert alert-danger text-center">No Helpers Found.</div>
        }
    }

    getExtension(file_name) {
        let file = file_name.split('.');
        return file[file.length - 1];
    }

    renderMessages = (messages) => {
        if (messages.length > 0) {
            let msg = messages.map((item, i) => {
                return (
                    <React.Fragment key={'message-' + i}>
                        <div className="message-body clearfix">
                            <div className="content-group-sm">
                                <div className="media">
                                    <span className="media-left">
                                        <span className={((item.conversations_user_id == (JSON.parse(Storage.get('imnLoginData'))).id) ? "bg-primary-400" : "bg-warning") + " btn btn-rounded btn-icon btn-xs"}>
                                            <span className="letter-icon">{item.full_name.substr(0, 1)}</span>
                                        </span>
                                    </span>
                                    <div className="media-body">
                                        <span className="media-heading text-semibold">{item.full_name} </span>
                                        <div className="text-size-mini text-muted">
                                            {DateFormat.getFormattedDateTime(item.msg_time, 'm-d-Y,h:i')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: item.message }}></p>
                            <div className="tabbable attachedment-files">
                                <div className="file-manager">
                                    <ul>
                                        {item.quotetion_files.length > 0 && item.quotetion_files.map((file, j) => {
                                            return (
                                                <li key={'file-' + j}>
                                                    <div className="file-wrapper filetype-image">
                                                        <div className="file-title"><a href={file.file_path}><i className={FileIcon(this.getExtension(file.file_name)) + " position-left text-primary"}></i>{file.file_name}</a></div>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {(i < (messages.length - 1)) && <hr />}
                    </React.Fragment>
                )
            });

            return msg;
        }
    }
    reconsiderOffer = (e) => {
        e.preventDefault();
        PostDataWithParam('reconsiderOffer', { order_id: this.state.id })
            .then(response => {
                if (response.success === 1) {
                    window.swal('Success!', response.msg, 'success');
                    this.props.history.push({
                        pathname: '/independent-mover/dashboard/find-an-offer',

                    })
                } else {
                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => {

            })
    }
    gotoConversation = (e, user_id) => {
        e.preventDefault();
        this.state.conversationCustomerList.map((item, i) => {
            if ((user_id) == item.conversations_user_id) {
                window.$("#conversations_people_" + user_id).trigger("click");
                window.scrollTo(window.$('#relo-conversation'), 1000);
            }
        })
    }
    renderDriverConversations() {
        if (this.state.conversationDriverList.length > 0) {
            let conversationDriverList = this.state.conversationDriverList.map((item, i) => {
                return (
                    <div className="panel panel-flat profile-page panel-collapsed" key={'driver-' + i}>
                        <a href="javascript:void(0);" id={'conversations_people_' + item.conversations_user_id} data-toggle="collapse" data-target={"#driver-messagebody-" + i} onClick={this.getMessages.bind(this, item.conversations_user_id, 'driver', item, i, 'conversationDriverList')}>
                            <div className="panel-heading">
                                <div className="heading-elements">
                                    <ul className="icons-list">
                                        <li><i className="icon-arrow-down22"></i></li>
                                    </ul>
                                </div>
                                <h6 className="m-0">{item.full_name} <span className="badge bg-green m-l-15">{this.state.totalMyOfferReceivedMsg.driveTotalMsg = item.msgCount}</span></h6>
                            </div>
                        </a>
                        {(this.state.staff_user_id == "" || this.state.permission) &&
                            <div id={"driver-messagebody-" + i} className="collapse">
                                <div className="panel-body p-b-0 editor-box" id={"editor-box-in-driver-" + i} >
                                    <form action="" method="POST" onSubmit={this.sendMessage.bind(this, item.conversations_user_id, 'driver', i)}>
                                        <div className="form-group">
                                            <label className="control-label">Write Something</label>
                                            {/* <Editor
                                                placeHolder="Write Something ..."
                                                initialValue=""
                                                init={{
                                                    setup: editor => {
                                                        this.setState({ ['activeEditor_' + item.conversations_user_id]: editor })
                                                    },
                                                    menubar: false,
                                                    statusbar: false,
                                                    toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                                }}
                                                onChange={this.handleOnChange.bind(this)}
                                                className="form-control textarea-editor"
                                                name="qutationMessage"
                                                id={'qutationDriverMessage-' + i}
                                            /> */}
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="file-label">
                                                    <input className="file-input" name="resume" type="file" onChange={this.handleFileChange.bind(this)} multiple={true} />
                                                    <span className="text-muted">
                                                        <i className="icon icon-attachment"></i> Attach File
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <hr />
                                <div className="panel-body">
                                    {this.renderMessages(item.conversations)}
                                </div>
                            </div>}
                    </div>
                )
            })
            return conversationDriverList
        } else {
            return <div className="alert alert-danger text-center">No Drivers Found.</div>
        }
    }
    renderCustomerConversations = (e, id) => {
        if (this.state.conversationCustomerList.length > 0) {

            let conversationCustomerList = this.state.conversationCustomerList.map((item, i) => {
                return (
                    <div className={(this.state.details.hire_by_profile_id == 4 || this.state.details.hire_by_profile_id == 11) ? 'panel panel-flat profile-page' : "panel panel-flat profile-page panel-collapsed"} key={'customer-' + i}>
                        <a href="javascript:void(0);" id={'conversations_people_' + item.conversations_user_id} data-toggle="collapse" data-target={"#customer-messagebody-" + item.conversations_user_id} onClick={this.getMessages.bind(this, item.conversations_user_id, 'customer', item, i, 'conversationCustomerList')}>
                            <div className="panel-heading">
                                <div className="heading-elements">
                                    <ul className="icons-list">
                                        <li><i className="icon-arrow-down22"></i></li>
                                    </ul>
                                </div>
                                <h6 className="m-0">{item.full_name} <span className="badge bg-green m-l-15">{this.state.totalMyOfferReceivedMsg.customerTotalMsg = item.msgCount}</span></h6>
                            </div>
                        </a>
                        {(this.state.staff_user_id == "" || this.state.permission) &&

                            <div id={"customer-messagebody-" + item.conversations_user_id} className="collapse">
                                <div className="panel-body p-b-0 editor-box" id={"editor-box-in-customer-" + i} >
                                    <form action="" method="POST" onSubmit={this.sendMessage.bind(this, item.conversations_user_id, 'customer', item.conversations_user_id)}>
                                        <div className="form-group">
                                            <label className="control-label">Write Something</label>
                                            {/* <Editor
                                                placeHolder="Write Something ..."
                                                initialValue=""
                                                init={{

                                                    setup: editor => {
                                                        this.setState({ ['activeEditor_' + item.conversations_user_id]: editor })
                                                    },
                                                    menubar: false,
                                                    statusbar: false,
                                                    toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                                }}
                                                onChange={this.handleOnChange.bind(this)}
                                                className="form-control textarea-editor"
                                                name="qutationMessage"
                                                id={'qutationCustomerMessage-' + i}
                                            /> */}
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="file-label">
                                                    <input className="file-input" name="resume" type="file" onChange={this.handleFileChange.bind(this)} multiple={true} />
                                                    <span className="text-muted">
                                                        <i className="icon icon-attachment"></i> Attach File
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <hr />
                                <div className="panel-body">
                                    {this.renderMessages(item.conversations)}
                                </div>
                            </div>
                        }
                    </div>
                )
            })
            return conversationCustomerList
        } else {
            return <div className="alert alert-danger text-center">No Customers Found.</div>
        }
    }
    initChatBox(user_id, name, order_id, profile_image, e) {
        e.preventDefault();
        // console.log(user_id, name, order_id, profile_image);return;
        this.props.openChatBox({
            chat_box_id: `${order_id}_${user_id}`,
            chat_info: {
                order_id,
                order_no: this.state.details.order_id,
                receiver_info: {
                    user_id,
                    name,
                    profile_image
                }
            }
        })
    }
    renderConversations(val) {
        // console.log(val);
        if ((val === 1 ? this.state.conversationMoverList : val === 2 ? this.state.conversationHelperList : val === 3 ? this.state.conversationDriverList : this.state.conversationCustomerList).length > 0) {
            let conversationPeopleList = (val === 1 ? this.state.conversationMoverList : val === 2 ? this.state.conversationHelperList : val === 3 ? this.state.conversationDriverList : this.state.conversationCustomerList).map((item, i) => {
                // console.log(this.state.conversationCustomerList);
                return (
                    <React.Fragment key={'people-' + i}>
                        {/* {this.state.type != '' && ((item.conversations_profile_id == this.state.details.offer_from_profile_id && item.conversations_user_id == this.state.details.offer_from_user_id) || item.conversations_profile_id != 2) ? */}
                        <div className="panel panel-default conversation">
                            <div className="panel-heading">
                                <h6 className="m-0">{item.full_name}
                                    {this.state.edit_msg &&
                                        <span>
                                            {
                                                (JSON.parse(Storage.get('imnLoginData'))).id != item.conversations_user_id
                                                &&
                                                <a href=""
                                                    data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.conversations_user_id}
                                                    onClick={this.initChatBox.bind(
                                                        this, item.conversations_user_id, item.full_name, this.state.id, item.profile_image
                                                    )} className="btn btn-success btn-xs m-l-15 text-bold">
                                                    <i className="icon-bubbles9 m-r-5 f-s-20"></i>Chat</a>
                                            }
                                        </span>}
                                </h6>
                            </div>
                        </div>
                        {/* : */}
                        {/* item.conversations_profile_id != 2 && */}
                        {/* <div className="panel panel-default conversation">
                                    <div className="panel-heading">
                                        <h6 className="m-0">{item.full_name} <span>
                                        {
                                        (JSON.parse(Storage.get('imnLoginData'))).id != item.conversations_user_id
                                        &&
                                        <a href=""
                                            data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.conversations_user_id}
                                            onClick={this.initChatBox.bind(
                                                this, item.conversations_user_id, item.full_name, this.state.id, item.profile_image
                                            )}>
                                            <i className={this.state.edit_msg? "icon-bubbles9 f-s-20 text-success":""}></i></a>
                                    }
                                        </span></h6>
                                    </div>
                            </div> */}
                        {/* } */}
                    </React.Fragment>
                )
            });

            return conversationPeopleList;
        } else {
            return (
                // <div className="panel panel-default conversation">
                //     <div className="panel-heading">
                //         <div className="heading-elements">
                //             <h6 className="text-grey-600 m-b-20">No conversations found.</h6>
                //         </div>
                //     </div>
                // </div>
                <h6 className="text-grey-600 m-b-20">No conversations found.</h6>
            );
        }
    }
    renderMoverConversations() {
        if (this.state.conversationMoverList.length > 0) {
            let conversationMoverList = this.state.conversationMoverList.map((item, i) => {
                return (
                    <div className={i == 0 && this.state.details.hire_by_profile_id == 2 && this.state.type != '' ? 'panel panel-flat profile-page' : "panel panel-flat profile-page panel-collapsed"} key={'mover-' + i}>
                        <a href="javascript:void(0);" id={'conversations_people_' + item.conversations_user_id} data-toggle="collapse" data-target={"#mover-messagebody-" + i} onClick={this.getMessages.bind(this, item.conversations_user_id, 'mover', item, i, 'conversationMoverList')}>
                            <div className="panel-heading">
                                <div className="heading-elements">
                                    <ul className="icons-list">
                                        <li><i className="icon-arrow-down22"></i></li>
                                    </ul>
                                </div>
                                <h6 className="m-0">{item.full_name} <span className="badge bg-green m-l-15">{this.state.totalMyOfferReceivedMsg.moverTotalMsg = item.msgCount}</span></h6>
                            </div>
                        </a>
                        {(this.state.staff_user_id == "" || this.state.permission) &&
                            <div id={"mover-messagebody-" + i} className="collapse">
                                <div className="panel-body p-b-0 editor-box" id={"editor-box-in-mover-" + i} >
                                    <form action="" method="POST" onSubmit={this.sendMessage.bind(this, item.conversations_user_id, 'mover', i)}>
                                        <div className="form-group">
                                            <label className="control-label">Write Something</label>
                                            {/* <Editor
                                                placeHolder="Write Something ..."
                                                initialValue=""
                                                init={{
                                                    setup: editor => {
                                                        this.setState({ ['activeEditor_' + item.conversations_user_id]: editor })
                                                    },
                                                    menubar: false,
                                                    statusbar: false,
                                                    toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                                }}
                                                onChange={this.handleOnChange.bind(this)}
                                                className="form-control textarea-editor"
                                                name="qutationMessage"
                                                id={'qutationMoverMessage-' + i}
                                            /> */}
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="file-label">
                                                    <input className="file-input" name="resume" type="file" onChange={this.handleFileChange.bind(this)} multiple={true} />
                                                    <span className="text-muted">
                                                        <i className="icon icon-attachment"></i> Attach File
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <hr />
                                <div className="panel-body">
                                    {this.renderMessages(item.conversations)}
                                </div>
                            </div>}
                    </div>
                )
            })
            return conversationMoverList
        } else {
            return <div className="alert alert-primary border-none text-center">No Movers Found.</div>
        }
    }

    ssnFormat = (str) => {
        if (str != null && str.length === 11) {
            str = 'XXX-XX-' + str.substr(7, 4);
            this.setState({
                ssn: str
            })
        } else {
            this.setState({
                ssn: "---"
            })
        }

    }

    GetMoverNote = () => {

        PostDataWithParam('GetOrderNote', { order_id: this.state.id })
            .then(response => {
                if (response.success == 1) {
                    // console.log(response.data[0].from_date)
                    // console.log(response.data[0].to_date)
                    // console.log(response.data[0].note)

                    let from_date = response.data[0].from_date.split(" ")
                    let to_date = response.data[0].to_date.split(" ")
                    // console.log(from_date[0],to_date[0])
                    this.setState({ get_mover_fromDate: from_date[0], get_mover_toDate: to_date[0], get_mover_note: response.data[0].note, isGetMovernote: true })
                    //     ()=>{
                    //       this.setOthersTab()
                    //   })
                }
                else {
                    //   window.swal('Error!', 'Something went wrong', 'error')
                    this.setState({ isGetMovernote: false })
                }

            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error')
            })
    }

    handleClickActiveTab = (status) => {
        this.setState({ tabid: status }, () => {
            window.$('html, body').animate({
                scrollTop: window.$("#" + status).offset().top - 70
            }, 1000);
        });
    }
    openMessageModal = (e, user_id, full_name) => {
        e.preventDefault();
        this.setState({ conversation_id: user_id, full_name: full_name });
        window.$("#messageModal").modal('show');
        this.getAllMessages(user_id);
    }
    getAllMessages(user_id) {
        this.setState({ allmessagelist: [] }, () => {
            PostDataWithParam('GetOrderConversationsMsg', { order_request_detail_id: this.state.id, conversations_user_id: user_id })
                .then(response => {
                    if (response.success == 1) {

                        this.setState({ allmessagelist: response.data }, () => {
                            // this.messagelistindividual(this.state.allmessagelist);

                        });
                    } else {

                    }
                })
                .catch(err => {

                })
        })
    }
    messagelistindividual(messages) {

        if (messages.length > 0) {
            let msg = messages.map((item, i) => {
                return (
                    <React.Fragment key={'message-' + i}>
                        <div className="message-body clearfix">
                            <div className="media m-b-15">
                                <span className="media-left p-r-15">
                                    <span className={((item.conversations_user_id == (JSON.parse(Storage.get('imnLoginData'))).id) ? "bg-primary-400" : "bg-warning") + " btn-rounded btn-icon text-uppercase"}>{item.full_name.substr(0, 1)}</span>
                                </span>
                                <div className="media-body">
                                    <span className="media-heading text-semi-bold">{item.full_name} </span>
                                    <div className="text-mid-grey f-s-13">
                                        {DateFormat.getFormattedDateTime(item.msg_time, 'm-d-Y,h:i')}
                                    </div>
                                </div>
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: item.message }}></p>
                            <div className="attachedment-files">
                                <ul className="file-list">
                                    {item.quotetion_files.length > 0 && item.quotetion_files.map((file, j) => {
                                        return (
                                            <li key={'file-' + j}>
                                                <a href={file.file_path || 'javascript:void(0)'}><i className={FileIcon(this.getExtension(file.file_name)) + " position-left text-primary"}></i>{file.file_name}</a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        {(i < (messages.length - 1)) && <hr />}
                    </React.Fragment>
                )
            });

            return msg;
        }
    }
    handleMessageChange(e) {
        this.setState({ message: e.target.getContent() })
    }
    sendMessageIndividual = (e, conversations_user_id) => {
        e.preventDefault();
        // if (convType == TEAM_CONVERSATION) return;
        let formData = new FormData();
        if (this.state.attachment.length > 0) {
            for (let i = 0; i < this.state.attachment.length; i++) {
                formData.append('file_name[' + i + ']', this.state.attachment[i], this.state.attachment[i].name);
            }
        }
        formData.append('order_request_detail_id', this.state.id);
        formData.append('conversations_user_id', conversations_user_id);
        formData.append('message', this.state.message);

        PostDataFile('SentOrderConversationsMsg', formData)
            .then(response => {
                if (response.success == 1) {
                    // window.swal('Success!', response.msg, 'success');
                    this.setState({ message: '', attachment: [] }, () => {
                        this.state['activeEditor'].setContent('');
                        // this.getConversationPeopleList(1, convType, () => {
                        this.getAllMessages(conversations_user_id);
                        // });
                    })
                } else {
                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => {

            })
    }
    renderMessageIndividual() {
        return (
            <div className="conversation">
                <div id='conversations_people'>
                    <h3 className="p-l-20 p-r-20 m-0">{this.state.full_name}</h3>
                </div>
                <div id="messagebody" className="">
                    <div className="p-20 editor-box">
                        <form onSubmit={(e) => this.sendMessageIndividual(e, this.state.conversation_id)}>
                            <div className="form-group">
                                {/* <Editor
                                    placeHolder="Write Something ..."
                                    initialValue=""
                                    init={{
                                        setup: editor => {
                                            this.setState({ ['activeEditor']: editor })
                                        },
                                        menubar: false,
                                        statusbar: false,
                                        toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                    }}
                                    onChange={this.handleMessageChange.bind(this)}
                                    className="form-control textarea-editor"
                                    name="qutationMessage"
                                    id='qutationMessage'
                                /> */}
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="file-label">
                                        <input className="file-input" name="resume" type="file" onChange={this.handleFileChange.bind(this)} multiple={true} />                                        <span className="text-muted">
                                            <i className="icon icon-attachment"></i> Attach File
                                        </span>
                                    </label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <hr className="m-t-0 m-b-0" />
                    <div className="p-20">
                        {this.messagelistindividual(this.state.allmessagelist)}

                        {/* <div className="message-body clearfix">
                            <div className="media m-b-15">
                                <span className="media-left p-r-15">
                                    <span className="bg-primary-400 btn-rounded btn-icon text-uppercase">M</span>
                                </span>
                                <div className="media-body">
                                    <span className="media-heading text-semi-bold">Moving APT</span>
                                    <div className="text-mid-grey f-s-13">
                                        sep 19, 2020, 3:40 PM
                                </div>
                                </div>
                            </div>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            <div className="attachedment-files">
                                <ul className="file-list">
                                    <li>
                                        <a href='javascript:void(0);'><i className="position-left text-primary"></i>image-file-1248</a>
                                    </li>
                                    <li>
                                        <a href='javascript:void(0);'><i className="position-left text-primary"></i>zip-file-1248</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        */}
                    </div>
                </div>
            </div>




        )
    }
    closeModal(id) {
        window.$('#' + id)[0].pause();
        // window.$('#audio-video0')[0].pause()
    }


    render() {
        return (
            <div className="row">
                {
                    this.state.loading
                    &&
                    <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                }
                {
                    !this.state.loading
                    &&
                    <div className="col-md-12">
                        {Breadcumb(this.state.breadcrumb)}

                        {

                            this.state.details.order_status != 3
                            &&
                            this.state.details.order_status != 1
                            &&
                            this.state.details.order_status != 2
                            &&
                            <div className="row">
                                <div className="col-md-12 ">
                                    <div className="well m-b-30 text-center bg-blue-gradient ">
                                        <h1 className="no-margin text-light">Congratulations!</h1>

                                        {
                                            (
                                                this.state.details.award_status == "1"
                                                &&
                                                <h6 className="m-t-8 m-b-30 text-semibold">

                                                    {/* You are offered with the (Relo # {this.state.details.order_id}) 
                                                by the */}
                                                    {
                                                        ((
                                                            this.state.details.hire_by_profile_id == 4
                                                            ||
                                                            this.state.details.hire_by_profile_id == 11
                                                        )
                                                            &&
                                                            ' Customer '
                                                        )
                                                    }
                                                    {
                                                        this.state.details.hire_by_profile_id == 2
                                                        &&
                                                        ' Moving Company '
                                                    }

                                                    {this.state.details.offer_from.organization_name}
                                                    {/* Move forward and Accept or Reject the order. */}
                                                    {/* and Accept / Decline the JOB. */}

                                                    &nbsp;has offered you {this.state.details.order_type == 0 ? 'ReloOrder' : 'Shipment'}(# {this.state.details.order_id}) .<br />
                                                    Check the {this.state.details.order_type == 0 ? 'ReloOrder' : 'Shipment'} details below and use our secure message below to discuss this offer & send your proposal.<br />
                                                    Don't forget to Accept or Reject this ASAP to ensure the highest level of customer experience.


                                                </h6>
                                                ||
                                                this.state.details.award_status != 1
                                                &&
                                                <h6 className="m-t-8 m-b-30 text-semibold">
                                                    {/* You are offered the following JOB (Relo # {this.state.details.order_id}) 
                                                by the */}
                                                    {
                                                        ((
                                                            this.state.details.hire_by_profile_id == 4
                                                            ||
                                                            this.state.details.hire_by_profile_id == 11
                                                        )
                                                            &&
                                                            ' Customer '
                                                        )
                                                    }

                                                    {
                                                        this.state.details.hire_by_profile_id == 2
                                                        &&
                                                        ' Moving Company '
                                                    }
                                                    {this.state.details.offer_from.organization_name}&nbsp;
                                                    has offered you {this.state.details.order_type == 0 ? 'ReloOrder' : 'Shipment'}(# {this.state.details.order_id}) .<br />
                                                    Check the {this.state.details.order_type == 0 ? 'ReloOrder' : 'Shipment'} details below and use our secure message below to discuss this offer & send your proposal.<br />
                                                    Don't forget to Accept or Reject this ASAP to ensure the highest level of customer experience.

                                                    {/* { (this.state.staff_user_id=="" || this.state.permission) && 'send your quote through the message box below.'} */}

                                                </h6>
                                            )
                                        }
                                        {
                                            (
                                                this.state.details.hire_by_profile_id == 4
                                                ||
                                                this.state.details.hire_by_profile_id == 11
                                                ||
                                                this.state.details.hire_by_profile_id == 2
                                            )
                                            &&
                                            this.state.details.award_status == 1
                                            &&
                                            (this.state.staff_user_id == "" || this.state.permission)
                                            &&
                                            <React.Fragment>
                                                &nbsp;
                                                <a href="" className="btn btn-success m-r-15" onClick={this.acceptRejectOffers.bind(this, 1)}><i className="icon-check2"></i> Yes, Accept it</a>
                                                <a href="javascript:void(0)" className="btn btn-default" onClick={(e) => this.gotoConversation(e, this.state.details.customer_id)}>Send Message</a>
                                                <a href="" className="btn btn-link btn-xs text-white m-r-15" style={{ textDecoration: "underline" }} onClick={this.acceptRejectOffers.bind(this, 2)}><i className="icon-cross"></i> No, Reject it</a>


                                            </React.Fragment>
                                        }
                                        {
                                            (
                                                (
                                                    this.state.details.hire_by_profile_id == 4
                                                    ||
                                                    this.state.details.hire_by_profile_id == 11
                                                )
                                                &&
                                                this.state.details.hire_by_profile_id != 2
                                            )
                                            &&
                                            this.state.details.award_status != 1
                                            &&
                                            (this.state.staff_user_id == "" || this.state.permission)
                                            &&
                                            <React.Fragment>
                                                &nbsp;
                                                {this.state.details.is_close_proposal == 0 && <a href="javascript:void(0)" className="btn btn-default m-r-10" onClick={(e) => this.gotoConversation(e, this.state.details.customer_id)}>Chat</a>
                                                }
                                                {this.state.details.is_close_proposal == 0 ? <Link to="#" onClick={() => this.handleClickActiveTab('send-proposal')} className="btn btn-success">Send Proposal</Link>
                                                    :
                                                    <a href="javascript:void(0)" className="btn btn-default m-r-15" ><i className="icon-check2"></i> This job is closed for Sending Proposal</a>
                                                }
                                                {this.state.details.is_close_proposal == 0 && <a href="" className="btn btn-link btn-xs text-white" style={{ textDecoration: "underline" }} onClick={this.acceptRejectOffers.bind(this, 2)}><i className="icon-cross"></i> No, Reject it</a>}
                                            </React.Fragment>
                                        }
                                        {/* {(
                                                this.state.details.hire_by_profile_id == 4
                                                    ||
                                                    this.state.details.hire_by_profile_id == 11
                                            ) && (this.state.staff_user_id=="" || this.state.permission) &&
                                                <a href="" className="btn btn-link btn-xs text-white" style={{textDecoration: "underline"}} onClick={this.acceptRejectOffers.bind(this, 2)}><i className="icon-cross"></i> No, Reject it2</a>
                                                } */}

                                        {
                                            (
                                                (
                                                    this.state.details.hire_by_profile_id != 4
                                                    ||
                                                    this.state.details.hire_by_profile_id != 11
                                                )
                                                &&
                                                this.state.details.hire_by_profile_id == 2
                                            )
                                            &&
                                            this.state.details.award_status != 1
                                            &&
                                            (this.state.staff_user_id == "" || this.state.permission)
                                            &&
                                            <React.Fragment>
                                                &nbsp;
                                                <a href="" className="btn btn-success m-r-15" onClick={this.acceptRejectOffers.bind(this, 1)}><i className="icon-check2"></i> Yes, Accept it</a>
                                                <a href="javascript:void(0)" className="btn btn-default" onClick={(e) => this.gotoConversation(e, this.state.details.customer_id)}>Send Message</a>
                                                <a href="" className="btn btn-link btn-xs text-white m-r-15" style={{ textDecoration: "underline" }} onClick={this.acceptRejectOffers.bind(this, 2)}><i className="icon-cross"></i> No, Reject it</a>


                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.details.order_status == 3
                            &&
                            <div className="row">
                                <div className="col-md-12 ">
                                    <div className="well m-b-30 text-center bg-blue-gradient ">
                                        <h1 className="no-margin text-light">Congratulations!</h1>
                                        <h6 className="m-t-8 m-b-30 text-semibold">
                                            {/* 
                                                You are offered the following JOB (Relo # {this.state.details.order_id}) 
                                                by the  */}
                                            {
                                                (
                                                    this.state.details.hire_by_profile_id == 4
                                                    ||
                                                    this.state.details.hire_by_profile_id == 11
                                                )
                                                &&
                                                ' Customer '
                                            }
                                            {
                                                this.state.details.hire_by_profile_id == 2
                                                &&
                                                ' Moving Company '
                                            }
                                            {this.state.details.offer_from.organization_name}&nbsp;
                                            has offered you {this.state.details.order_type == 0 ? 'ReloOrder' : 'Shipment'}(# {this.state.details.order_id}) .<br />
                                            Check the {this.state.details.order_type == 0 ? 'ReloOrder' : 'Shipment'} details below and use our secure message below to discuss this offer & send your proposal.<br />
                                            Don't forget to Accept or Reject this ASAP to ensure the highest level of customer experience.
                                            {/* { (this.state.staff_user_id=="" || this.state.permission) && 'send your quote through the message box below.'} */}
                                            {/* and Accept / Decline the JOB. */}

                                        </h6>
                                        {(this.state.staff_user_id == "" || this.state.permission) &&
                                            <a href="javascript:void(0)" className="btn btn-default" ><i className="icon-cross"></i> This job is closed</a>}
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            ((this.state.staff_user_id == "" || this.state.permission) && this.state.details.order_status == 2)
                            &&
                            <div className="well no-border text-center bg-blue-gradient ">
                                <a href="" className="btn btn-default" onClick={this.reconsiderOffer.bind(this)}>Reconsider</a>
                            </div>
                        }
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="inner-page navigation tab-navigation m-b-20 p-0">
                                    <li className={(this.state.tabid === 'order-summary' ? 'active' : '')}>
                                        <Link to="#" onClick={() => this.handleClickActiveTab('order-summary')} className="f-s-15 text-uppercase">Order Summary</Link>
                                    </li>
                                    <li className={(this.state.tabid === 'survey-file' ? 'active' : '')}>
                                        <Link to="#" onClick={() => this.handleClickActiveTab('survey-file')} className="f-s-15 text-uppercase">Files &amp; Attachments</Link>
                                    </li>
                                    <li className={(this.state.tabid === 'relo-conversation' ? 'active' : '')}>
                                        <Link to="#" onClick={() => this.handleClickActiveTab('relo-conversation')} className="f-s-15 text-uppercase">Conversation</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div id="order-summary">
                            <div className="panel panel-flat profile-page">
                                <div className="panel-body">
                                    <h6 className="text-success">Customer Basic Info</h6>
                                    {this.state.details.military_flag == 1 && (
                                        <div className="row">
                                            {this.state.details.scac && <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0">SCAC</label>
                                                <p className="m-b-20">{this.state.details.scac || "---"}</p>
                                            </div>

                                            }
                                            {/* <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0">Customer SSN</label>
                                                <p className="m-b-20">{this.state.ssn || "---"}</p>
                                            </div> */}
                                            <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0">Customer Name</label>
                                                <p className="m-b-20">{this.state.details.customer_first_name || "---"}&nbsp;&nbsp;
                                                    {this.state.details.customer_last_name.charAt(0)}
                                                </p>
                                            </div>
                                        </div>

                                    )}
                                    {this.state.details.military_flag == 1 && (
                                        <div>
                                            <div className="row">
                                                {/* <div className="col-md-4">
                                                <label className="text-bold m-b-0">Customer Last Name</label>
                                                <p className="m-b-20">{this.state.details.customer_last_name || "---"}</p>
                                            </div> */}
                                                <div className="col-md-4 col-sm-6">
                                                    <label className="text-bold m-b-0">Primary Phone</label>
                                                    <p className="m-b-20">{this.state.details.contact_no || "---"}</p>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <label className="text-bold m-b-0">Secondary Phone</label>
                                                    <p className="m-b-20">{this.state.details.home_contact_no || "---"}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6">
                                                    <label className="text-bold m-b-0">Primary Email</label>
                                                    <p className="m-b-20">{this.state.details.customer_email || "---"}</p>

                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <label className="text-bold m-b-0">Secondary Email</label>
                                                    <p className="m-b-20">{this.state.details.alternate_email || "---"}</p>

                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.details.military_flag == 0 && (
                                        <div className="row">
                                            <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0">Customer Name</label>
                                                <p className="m-b-20">{this.state.details.customer_first_name || "---"}&nbsp;&nbsp;
                                                    {this.state.details.customer_last_name.charAt(0)}
                                                </p>
                                            </div>
                                            {/* <div className="col-md-4">
                                            <label className="text-bold m-b-0">Customer Last Name</label>
                                            <p className="m-b-20">{this.state.details.customer_last_name || "---"}</p>
                                        </div> */}
                                        </div>
                                    )}

                                    {this.state.details.military_flag == 0 && (
                                        <div>
                                            <div className="row">
                                                {/* <div className="col-md-4">
                                                <label className="text-bold m-b-0">Customer Last Name</label>
                                                <p className="m-b-20">{this.state.details.customer_last_name || "---"}</p>
                                            </div> */}
                                                <div className="col-md-4 col-sm-6">
                                                    <label className="text-bold m-b-0">Primary Phone</label>
                                                    <p className="m-b-20">{this.state.details.contact_no || "---"}</p>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <label className="text-bold m-b-0">Secondary Phone</label>
                                                    <p className="m-b-20">{this.state.details.home_contact_no || "---"}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6">
                                                    <label className="text-bold m-b-0">Primary Email</label>
                                                    <p className="m-b-20">{this.state.details.customer_email || "---"}</p>

                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <label className="text-bold m-b-0">Secondary Email</label>
                                                    <p className="m-b-20">{this.state.details.alternate_email || "---"}</p>

                                                </div>
                                            </div>
                                        </div>
                                    )}



                                </div>

                                {/* ----------------------------- LONG INFO VIEW ---------------------------------- */}


                                <div className="panel-body">
                                    <h6 className="text-success m-t-0">Service Info</h6>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-6">
                                            <label className="text-bold m-b-0">Customer Estimated Weight</label>
                                            <p className="m-b-20">{this.state.details.customer_estimated_weight || "---"}</p>
                                        </div>
                                        {this.state.details.order_type == 1 &&
                                            <React.Fragment>
                                                <div className="col-md-4 col-sm-6">
                                                    <label className="text-bold m-b-0">Actual Net Weight</label>
                                                    <p className="m-b-20">{this.state.details.actual_net_weight || "---"}</p>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <label className="text-bold m-b-0">Actual Gross Weight</label>
                                                    <p className="m-b-20">{this.state.details.actual_gross_weight || "---"}</p>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <label className="text-bold m-b-0">Requested Pack Date</label>
                                            <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.details.requested_pack_date, 'm-d-Y')}</p>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <label className="text-bold m-b-0">Requested Pickup Date</label>
                                            <div>
                                                <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.details.request_start_date, 'm-d-Y')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* <div className="col-md-4">
                                            <label className="text-bold m-b-0">Requested Latest Pickup Date</label>
                                            <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.details.requested_latest_pickup_date, 'm-d-Y')}</p>

                                        </div> */}
                                        <div className="col-md-6 col-sm-6">
                                            <label className="text-bold m-b-0">Requested Delivery Date</label>
                                            <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.details.request_end_date, 'm-d-Y')}</p>
                                        </div>

                                        {this.state.details.military_flag == 1 && (
                                            <div className="col-md-6 col-sm-6">
                                                <label className="text-bold m-b-0">Required Delivery Date (System Generated)</label>
                                                <div>

                                                    <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.details.required_delivery_date, 'm-d-Y') != "01-01-1970" ? DateFormat.getFormattedDateTime(this.state.details.required_delivery_date, 'm-d-Y') : '---'}</p>
                                                </div>
                                            </div>
                                        )}


                                    </div>
                                    {/* {this.state.details.military_flag==1 && (
                                        <div className="row">
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Required Delivery Date (System Generated)</label>
                                            <div>
                                                <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.details.required_delivery_date, 'm-d-Y')}</p>
                                            </div>
                                        </div> 
                                        </div>
                                    )} */}

                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <label className="text-bold m-b-0">Primary Pickup Origin Address</label>
                                            <p className="m-b-20">
                                                {/* {this.state.details.source_address || "---"} */}
                                                {this.state.details.primary_pickup_city ? this.state.details.primary_pickup_city + ', ' : ''}
                                                {this.state.details.primary_pickup_state ? this.state.details.primary_pickup_state + ', ' : ''}
                                                {this.state.details.primary_pickup_zip ? this.state.details.primary_pickup_zip + ', ' : ''}
                                                {this.state.details.primary_pickup_country ? this.state.details.primary_pickup_country : ''}
                                            </p>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <label className="text-bold m-b-0">Primary Destination Delivery Address</label>
                                            <p className="m-b-20">
                                                {/* {this.state.details.destination_address || "---"} */}
                                                {this.state.details.primary_destination_city ? this.state.details.primary_destination_city + ', ' : ''}
                                                {this.state.details.primary_destination_state ? this.state.details.primary_destination_state + ', ' : ''}
                                                {this.state.details.primary_destination_zip ? this.state.details.primary_destination_zip + ', ' : ''}
                                                {this.state.details.primary_destination_country ? this.state.details.primary_destination_country : ''}
                                            </p>
                                        </div>
                                    </div>

                                    {this.state.details.military_flag == 0 && (
                                        <div className="row">
                                            <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0"># MyReloPro's Requested On Site</label>
                                                <p className="m-b-20">{this.state.details.total_manpower_needed || "---"}</p>
                                            </div>
                                        </div>
                                    )}

                                    <div className="row">

                                        <div className="col-md-6 col-sm-6">
                                            <label className="text-bold m-b-0">Special Items</label>
                                            <p className="m-b-20">{this.state.details.special_items || "---"}</p>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                            <label className="text-bold m-b-0">Firearms</label>
                                            <p className="m-b-20">{this.state.details.firearms || "---"}</p>
                                        </div>
                                        {/* <div className="col-md-4">
                                                <label className="text-bold m-b-0">Vehicles</label>
                                                <p className="m-b-20">{this.state.details.vehicles || "---"}</p>
                                            </div> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <label className="text-bold m-b-0">Remarks</label>
                                            <p className="m-b-20">{this.state.details.remarks || "---"}</p>
                                        </div>
                                        {/* <div className="col-md-4">
                                            <label className="text-bold m-b-0">Status</label>
                                            <div>
                                                <span className="text-success m-r-5" href="" data-toggle="tooltip" data-placement="top" title="In Progress"><i className="icon-circle2"></i></span> {trackingStatus || "--"} (<a href="javascript:void(0)" onClick={() => this.setTempOrder()}><b>Update</b></a>)
                                            </div>
                                        </div> */}

                                        <div className="col-md-6">
                                            <label className="text-bold m-b-0">Services Needed</label>
                                            <p className="m-b-20">{this.state.details.services_needed}</p>
                                        </div>
                                        {this.state.details.relocation_notes
                                            && <div className="col-md-6">
                                                <label className="text-bold m-b-0">Relocation Notes:</label>
                                                <p className="m-b-20">{this.state.details.relocation_notes || '---'}</p>
                                            </div>
                                        }
                                        <div className="row">
                                            {/* <div className="col-md-12 text-center">
                                                <a href="#" onClick={this.toggleAdditionalInfo}>Show/hide Additional Information</a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="panel panel-flat profile-page order-details-panel" >
                                <div className="panel-body">

                                    <div>
                                        <h6 className="text-success m-t-0">Additional Information Section</h6>

                                        {this.state.details.military_flag == 1 &&
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label className="text-bold m-b-0">Service Branch</label>
                                                        <p className="m-b-20">{this.state.details.service_branch || "---"}</p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="text-bold m-b-0">Shipment Type</label>
                                                        <p className="m-b-20">{this.state.details.shipment_type || "---"}</p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="text-bold m-b-0">Shipment Market</label>
                                                        <p className="m-b-20">{this.state.details.shipment_market || "---"}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label className="text-bold m-b-0">Origin GBLOC</label>
                                                        <p className="m-b-20">{this.state.details.origin_gbloc || "---"}</p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="text-bold m-b-0">Destination GBLOC</label>
                                                        <p className="m-b-20">{this.state.details.destination_gbloc || "---"}</p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="text-bold m-b-0">Channel</label>
                                                        <p className="m-b-20">{this.state.details.channel || "---"}</p>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                        <div className="row">
                                            <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0">Releasing Agent</label>
                                                <p className="m-b-20">{this.state.details.releasing_agent || "---"}</p>
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0">Releasing Agent Phone</label>
                                                <p className="m-b-20">{this.state.details.releasing_agent_phone || "---"}</p>
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0">Releasing Agent Email Address</label>
                                                <p className="m-b-20">{this.state.details.releasing_agent_email || "---"}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0">Receiving Agent</label>
                                                <p className="m-b-20">{this.state.details.receiving_agent || "---"}</p>
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0">Receiving Agent Phone</label>
                                                <p className="m-b-20">{this.state.details.receiving_agent_phone || "---"}</p>
                                            </div>
                                            <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0">Receiving Agent Email Address</label>
                                                <p className="m-b-20">{this.state.details.receiving_agent_email || "---"}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/*<div className="col-md-4">
                                                <label className="text-bold m-b-0">Code Of Service</label>
                                                <p className="m-b-20">{this.state.details.code_of_service || "---"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Orders Number</label>
                                                <p className="m-b-20">{this.state.details.order_number || "---"}</p>
                                            </div>*/}
                                            <div className="col-md-4 col-sm-6">
                                                <label className="text-bold m-b-0">Orders Date</label>
                                                <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.details.order_date, 'm-d-Y') != "01-01-1970" ? DateFormat.getFormattedDateTime(this.state.details.order_date, 'm-d-Y') : "---"}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/*this.state.details.military_flag==1 && (
                                    <div>
                                        <hr />
                                        <h6 className="text-success m-t-0">Additional Locations</h6>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Authorized Delivery:</label>
                                            <p className="m-b-20">{this.state.details.authorized_delivery}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Authorized Pickup:</label>
                                            <p className="m-b-20">{this.state.details.authorized_pickup}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">In Transit:</label>
                                            <p className="m-b-20">{this.state.details.in_transit}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Port Of Embark:</label>
                                            <p className="m-b-20">{this.state.details.port_of_embark}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Port Of Debark:</label>
                                            <p className="m-b-20">{this.state.details.port_of_debark}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">TCN Number:</label>
                                            <p className="m-b-20">{this.state.details.tcn_number}</p>
                                        </div>
                                    </div>
                                    </div>
                                )

                                <hr />*/}
                        {/* <h6 className="text-success m-t-0">Driver Info</h6>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="text-bold m-b-0">Driver Name</label>
                                        <p className="m-b-20">{this.state.details.driver_name}</p>
                                    </div>
                                </div>
                                {this.state.details.military_flag==1 && (
                                    <div className="row">
                                        <div className="col-md-6">
                                        <label className="text-bold m-b-0">Driver Mobile Number</label>
                                        <p className="m-b-20">{this.state.details.driver_mobile_number}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="text-bold m-b-0">Driver Email address</label>
                                        <p className="m-b-20">{this.state.details.driver_email}</p>
                                    </div>
                                    </div> */}




                        {/* <h6 className="text-success">Service Info</h6> */}
                        {/* <div className="row">
                                <div className="col-md-4">
                                    <label className="text-bold m-b-0">Requested Start Date:</label>
                                    <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.details.request_start_date, 'm-d-Y')}</p>
                                </div>
                                <div className="col-md-4">
                                    <label className="text-bold m-b-0">Requested End Date:</label>
                                    <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.details.request_end_date, 'm-d-Y')}</p>
                                </div>
                                <div className="col-md-4">
                                    <label className="text-bold m-b-0">Service Address:</label>
                                    <p className="m-b-20">{this.state.details.source_address}</p>
                                </div>
                            </div> */}
                        {/* <div className="row">
                                {
                                    this.state.details.hire_by_profile_id != 4
                                    &&
                                    <div className="col-md-4">
                                        <label className="text-bold m-b-0">Total Number of Members needed onsite:</label>
                                        <p className="m-b-20">{this.state.details.total_manpower_needed}</p>
                                    </div>
                                }
                                
                                <div className="col-md-4">
                                    <label className="text-bold m-b-0">Services Needed:</label>
                                    <p className="m-b-20">{this.state.details.services_needed || "---"}</p>
                                </div>
                                <div className="col-md-4">
                                    <label className="text-bold m-b-0">Destination Address:</label>
                                    <p className="m-b-20">{this.state.details.destination_address || "---"}</p>
                                </div>
                                {
                                    this.state.post_by
                                    &&
                                    <div className="col-md-4">
                                    <label className="text-bold m-b-0">Posted by:</label>
                                    <p className="m-b-20">
                                        <span className="text-success">{this.state.post_by}</span> on {DateFormat.getFormattedDateTime(this.state.post_date, 'm-d-Y')}
                                    </p>
                                    </div>
                                }                                
                            </div> */}
                        {/* <div className="row">
                            <div className="col-md-4">
                                <label className="text-bold m-b-0">Relocation Notes:</label>
                                <p className="m-b-20">{this.state.details.relocation_notes||'---'}</p>
                            </div>
                            <div className="col-md-4">
                                <label className="text-bold m-b-0">Will Storage Be Needed:</label>
                                {this.state.details.storage_needed == 1
                                    ?
                                    <p className="m-b-20">Yes</p>
                                    :
                                    <p className="m-b-20">No</p>
                                }
                            </div>
                            <div className="col-md-4">
                                <label className="text-bold m-b-0">Are Dates Flexible:</label>
                                {this.state.details.flexibility_date == 1
                                    ?
                                    <p className="m-b-20">Yes</p>
                                    :
                                    <p className="m-b-20">No</p>
                                }
                            </div>
                        </div> */}

                        <div id="survey-file">
                            <h4>Files</h4>
                            {/* {
                             this.state.details.order_status != 0
                             &&
                             (this.state.staff_user_id=="" || this.state.permission)
                             &&
                             <React.Fragment>
                             <label className="btn btn-success btn-xs btn-file pull-right"><input type="file" name="files[]" onChange={this.uploadFiles.bind(this)} multiple={true}/><i className="icon-file-plus position-left"></i> Add File</label>
                            </React.Fragment>
                            } */}
                            <div className="panel panel-flat profile-page m-b-40">
                                <div className="panel-heading p-0">
                                    <ul className="nav nav-tabs nav-tabs-bottom nav-horizantal-scroll m-b-0">
                                        {
                                            //this.state.isGetFile &&
                                            <li className="active">
                                                <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setFileGroup.bind(this, 1)}>
                                                    <p className="text-uppercase m-0 m-l-5 m-r-5">Video &amp; Image Files</p>
                                                </a>
                                            </li>
                                        }
                                        {
                                            //this.state.isGetInventory &&
                                            <li className="">
                                                {/* this.setFileGroup.bind(this, 2) */}
                                                <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setInvenotryTab}>
                                                    <p className="text-uppercase m-0 m-l-5 m-r-5">{(this.state.allmaininventory.length > 0) && <i className="icon-checkmark-circle"></i>}
                                                        &nbsp;&nbsp;Estimate Tool</p>
                                                </a>
                                            </li>
                                        }
                                        {
                                            //this.state.isGetInventory &&
                                            <li className="">
                                                {/* this.setFileGroup.bind(this, 2) */}
                                                <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setOtherFiles.bind(this, 3)}>
                                                    <p className="text-uppercase m-0 m-l-5 m-r-5">Other Files</p>
                                                </a>
                                            </li>
                                        }
                                        {
                                            this.state.isGetMovernote &&
                                            <li className="">
                                                <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setOthersTab}>
                                                    {/* onClick={this.setFileGroup.bind(this, 3)} */}
                                                    <p className="text-uppercase m-0 m-l-5 m-r-5">Request for Pre-move Survey</p>
                                                </a>
                                            </li>
                                        }

                                        {
                                            //this.getFiles &&
                                            <li className="">
                                                <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setArchiveTab}>

                                                    <p className="text-uppercase m-0 m-l-5 m-r-5">Archived</p>
                                                </a>
                                            </li>
                                        }

                                    </ul>
                                </div>

                                <div className="panel-body p-b-0 p-t-0">
                                    <div className="tabbable">
                                        {this.state.showInventoryTab &&
                                            <React.Fragment>
                                                {this.state.showInventoryItems ?
                                                    <div className="panel-body text-right p-b-0">
                                                        <h6 className="text-primary m-b-0"><span className="text-semibold">Total Cubic Feet : </span><span className="text-grey-800">{this.state.total_cubic_feet}</span></h6>
                                                        <h6 className="text-primary m-0 m-b-10"><span className="text-semibold">Total Estimated Weight : </span><span className="text-grey-800">{this.state.total_estimated_weight}</span></h6>
                                                    </div>
                                                    :
                                                    <h6 className="alert alert-primary no-border">No files were found.</h6>
                                                }

                                                {this.state.showInventoryItems &&
                                                    this.showItemList()
                                                }
                                            </React.Fragment>
                                        }

                                        {this.state.showFilesTab &&
                                            <div className="show-inventory-items file-manager horizontal-scroll">
                                                <ul>{this.renderFiles()}</ul>
                                            </div>
                                        }

                                        {this.state.showArchiveTab &&
                                            <div className="show-inventory-items file-manager horizontal-scroll">
                                                <ul>{this.renderArchiveFile()}</ul>
                                            </div>
                                        }

                                        {this.state.showOtherFilesTab &&
                                            <div className="show-inventory-items file-manager horizontal-scroll">
                                                <ul>{this.renderFiles()}</ul>
                                            </div>
                                        }

                                        {
                                            this.state.showOthersTab &&
                                            <div className="request-to-mover-view">
                                                <div className="row">
                                                    <div className="col-md-4 col-sm-6">
                                                        <label className="text-bold m-b-0">From Date</label>
                                                        <p className="m-b-20">{this.state.get_mover_fromDate || '---'}</p>
                                                    </div>
                                                    <div className="col-md-4 col-sm-6">
                                                        <label className="text-bold m-b-0">To Date</label>
                                                        <p className="m-b-20">{this.state.get_mover_toDate || '---'}</p>

                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label className="text-bold m-b-0">Note</label>
                                                        <p className="m-b-20">{this.state.get_mover_note || '---'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* Cya A File Start */}
                        {/* <div><h4 className="pull-left">CYA Video</h4> </div>
                            <div className="clearfix"></div>
                            <div className="panel panel-flat profile-page m-b-40">
                                <div className="panel-body">
                                    <div className="tabbable hori-scroll">
                                        <div className="file-manager">
                                            <ul>
                                                {this.renderCyaVideo()}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        {/* Cya A File End */}
                        <div id="relo-conversation">
                            <h4>Conversation</h4>
                            <div className="panel panel-default">
                                {/* <div className="panel-heading p-0 hori-scroll">
                                <ul className="nav nav-tabs nav-tabs-bottom m-b-0">
                                    <li className={this.state.details.hire_by_profile_id==2?"active":''}>
                                        <a href="#bordered-tab1" data-toggle="tab" aria-expanded="true">
                                            <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>Moving Company
                                            <span className="label label-success m-l-5">{this.state.totalMyOfferReceivedMsg.moverTotalMsg}</span>
                                            <a href="#" onClick={() => this.openConversationModal()} data-toggle="modal" data-target="#conversationModal">
                                                <i className="icon-envelope f-s-20 text-dark-blue"></i>
                                                <span className="info">8</span>
                                            </a>
                                            </h5>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#bordered-tab2" data-toggle="tab" aria-expanded="true">
                                            <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>ReloHelper
                                            <span  className="label label-success m-l-5">{this.state.totalMyOfferReceivedMsg.helperTotalMsg}</span>
                                            <a href="#" data-toggle="modal" data-target="#conversationModal">
                                                <i className="icon-envelope f-s-20 text-dark-blue"></i>
                                                <span className="info">8</span>
                                            </a>
                                            </h5>
                                        </a>
                                    </li>
                                    <li className="">
                                        <a href="#bordered-tab3" data-toggle="tab" aria-expanded="false">
                                            <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>ReloDriver
                                            <span  className="label label-success m-l-5">{this.state.totalMyOfferReceivedMsg.driveTotalMsg}</span>
                                            <a href="#" data-toggle="modal" data-target="#conversationModal">
                                                <i className="icon-envelope f-s-20 text-dark-blue"></i>
                                                <span className="info">8</span>
                                            </a>
                                            </h5>
                                        </a>
                                    </li>
                                    <li className={this.state.details.hire_by_profile_id==4?"active":''}>
                                        <a href="#bordered-tab4" data-toggle="tab" aria-expanded="false">
                                            <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>ReloCustomer
                                            <span  className="label label-success m-l-10">{this.state.totalMyOfferReceivedMsg.customerTotalMsg}</span>
                                            <a href="#" data-toggle="modal" data-target="#conversationModal" className="m-l-10">
                                                <i className="icon-envelope f-s-24 text-dark-blue"></i>
                                                <span className="info">8</span>
                                            </a>
                                            </h5>
                                        </a>
                                    </li>
                                </ul>
                            </div> */}
                                <div className="panel-body">
                                    <div className="tabbable">
                                        <div className="tab-content">
                                            <div id="bordered-tab1" className={this.state.details.hire_by_profile_id == 2 ? "tab-pane active" : 'tab-pane'}>
                                                {this.renderConversations(1)}
                                            </div>
                                            <div id="bordered-tab2" className="tab-pane">
                                                {this.renderConversations(2)}
                                            </div>
                                            <div id="bordered-tab3" className="tab-pane">
                                                {this.renderConversations(3)}
                                            </div>
                                            <div id="bordered-tab4" className={(this.state.details.hire_by_profile_id == 4 || this.state.details.hire_by_profile_id == 11) ? "tab-pane active" : 'tab-pane'}>
                                                {this.renderConversations(4)}
                                            </div>
                                        </div>
                                    </div>
                                    {/* {this.renderMoverConversations()} */}
                                    {/* {this.renderHelperConversations()} */}
                                    {/* {this.renderDriverConversations()} */}
                                    {/* {this.renderCustomerConversations(null,null)}  */}

                                    {/* <div className="">
                                    <h5 className="text-uppercase m-0 m-l-5 m-r-5 relo-msg text-success" style={{ textTransform: 'none' }}>{this.state.details.offer_from.organization_name} 
                                   <a href="#" onClick={(e) => this.openMessageModal(e,this.state.details.hire_by_user_id,this.state.details.offer_from.organization_name)} className="m-l-10" style={{ 'position': 'relative' }}>
                                            <i className="icon-envelope f-s-24 text-indigo-800"></i>
                                        </a> 
                                       
                                     </h5>
                                </div>  */}
                                    {/* <div className="well m-b-20 p-15">
                                    <h5 className="text-uppercase m-0 m-l-5 m-r-5 relo-msg text-success" style={{ textTransform: 'none' }}>ReloHelper
                                        <a href="javascript:void();" data-toggle="modal" data-target="#conversationModal" className="m-l-10" style={{ 'position': 'relative' }}>
                                            <i className="icon-envelope f-s-24 text-indigo-800"></i>
                                            <span className="info">8</span>
                                        </a>
                                    </h5>
                                </div>
                                <div className="well m-b-20 p-15">
                                    <h5 className="text-uppercase m-0 m-l-5 m-r-5 relo-msg text-success" style={{ textTransform: 'none' }}>ReloDriver
                                        <a href="javascript:void();" data-toggle="modal" data-target="#conversationModal" className="m-l-10" style={{ 'position': 'relative' }}>
                                            <i className="icon-envelope f-s-24 text-indigo-800"></i>
                                            <span className="info">8</span>
                                        </a>
                                    </h5>
                                </div>
                                <div className="well m-b-20 p-15">
                                    <h5 className="text-uppercase m-0 m-l-5 m-r-5 relo-msg text-success" style={{ textTransform: 'none' }}>ReloCustomer
                                        <a href="javascript:void();" data-toggle="modal" data-target="#conversationModal" className="m-l-10" style={{ 'position': 'relative' }}>
                                            <i className="icon-envelope f-s-24 text-indigo-800 text-success"></i>
                                            <span className="info">8</span>
                                        </a>
                                    </h5>
                                </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="" id="send-proposal">
                            {this.state.proposalList && this.state.proposalList.length > 0
                                ? <> <h4>View &amp; Send Proposal</h4>
                                    <div className="panel panel-default">
                                        <div className="conversation">
                                            {(parseInt(this.state.details.award_status) !== 1 && parseInt(this.state.details.order_status) !== 2)
                                                &&
                                                <div className="panel-heading clearfix">
                                                    {this.state.proposalList.length > 0
                                                        &&
                                                        this.state.details.is_close_proposal == 0 &&
                                                        <a className="btn btn-success pull-right" role="button" data-toggle="collapse" data-target="#proposalbody" aria-expanded="false">
                                                            <i className="icon-reply"></i> Send Another Proposal
                                                        </a>
                                                    }
                                                </div>
                                            }
                                            {this.state.proposalList.length > 0
                                                &&
                                                <hr className="m-0" />
                                            }
                                            {
                                                (parseInt(this.state.details.award_status) !== 1 && parseInt(this.state.details.order_status) !== 2)
                                                &&
                                                this.state.details.is_close_proposal == 0 &&
                                                <div id="proposalbody" className={this.state.proposalList.length > 0 ? "collapse" : "collapse in"}>
                                                    <div className="p-20 editor-box">
                                                        <form action="" method="POST">
                                                            <div className="form-group">
                                                                {/* <Editor
                                                        placeHolder="Write Something ..."
                                                        initialValue=""
                                                        init={{
                                                            setup: editor => {
                                                                this.setState({ ['activeEditor']: editor })
                                                            },
                                                            menubar: false,
                                                            statusbar: false,
                                                            toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                                        }}
                                                        onChange={this.handleProposalChange.bind(this,'description')}
                                                        className="form-control textarea-editor"
                                                        name="description"
                                                        id='description-'
                                                    /> */}
                                                                <textarea className="form-control" style={{ 'min-height': '100px' }} onChange={this.handleProposalChange.bind(this, 'description')} placeholder="Write Something ..." name="description" value={this.state.proposalFields.description}></textarea>

                                                            </div>
                                                            <p className="text-danger help-block">{this.state.proposal_err}</p>
                                                            <div className="row">
                                                                <div className="col-md-8">
                                                                    <label className="file-label">
                                                                        <input className="file-input" name="request_files" type="file" onChange={this.handleProposalChange.bind(this, 'request_files')} multiple />
                                                                        <span className="text-muted">
                                                                            <i className="icon icon-attachment"></i> Attach File
                                                                        </span>
                                                                        {this.state.proposalFields.request_files.length > 0 &&
                                                                            this.state.proposalFields.request_files.map((file, index) => {
                                                                                return (
                                                                                    <div>
                                                                                        <span className="btn-default btn m-l-10 m-b-5 text-primary border-primary">
                                                                                            <i className="icon-file-text2"></i> {file.name}
                                                                                        </span>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </label>
                                                                </div>
                                                                <div className="col-md-4 text-right">
                                                                    {this.state.sending_proposal ?
                                                                        <button type="button" className="btn btn-primary close-editor" disabled={true}>Processing</button>
                                                                        :
                                                                        <button type="button" className="btn btn-primary close-editor" onClick={this.handleProposal}>Submit</button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            }
                                            <hr className="m-t-0 m-b-0" />
                                            {/* {this.state.proposalList.length>0 
                                        && */}


                                            {this.renderProposal()}

                                            {/* </div> */}
                                            {/* } */}
                                        </div>
                                    </div>
                                </> : null
                            }
                        </div>
                    </div>
                }
                {/* Start of cya files Modal
                */ }
                <div id="myVideo" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header p-b-0">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="m-b-0">Cya Video</h4>
                            </div>
                            <div className="modal-body">
                                {/* <!-- Timeline --> */}

                                <video src={this.state.cya_video_path} type="video/mp4" controls="controls" autoPlay={false} loop >

                                </video>
                            </div>
                        </div>

                    </div>
                </div>
                {/* 
                * End of cya files Modal
                */ }
                <React.Fragment>
                    <div id="messageModal" className="modal fade event" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header p-b-0">
                                    <button type="button" className="close" data-dismiss="modal" style={{ 'opacity': '1' }}><i className="icon-cancel-square2 text-indigo-800"></i></button>
                                    <h6 className="modal-title text-uppercase text-bold text-indigo-800">Conversation With</h6>
                                </div>
                                <div className="modal-body p-0">
                                    {/* {this.renderConversations()} */}
                                    {this.renderMessageIndividual()}
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                {/* <div id="conversationModal" className="modal fade event" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header p-b-0">
                                <button type="button" className="close" data-dismiss="modal" style={{ 'opacity': '1' }}><i className="icon-cancel-square2 text-indigo-800"></i></button>
                                <h6 className="modal-title text-uppercase text-bold text-indigo-800">Conversation With</h6>
                            </div>
                            <div className="modal-body p-0">
                           
                                <div className="conversation">
                                    <div id='conversations_people'>
                                        <h3 className="p-l-20 p-r-20 m-0">Moving APT</h3>
                                    </div>
                                    <div id="messagebody" className="">
                                        <div className="p-20 editor-box">
                                            <form action="" method="POST">
                                                <div className="form-group">
                                                    <Editor
                                                        placeHolder="Write Something ..."
                                                        initialValue=""
                                                        init={{
                                                            setup: editor => {
                                                                // this.setState({ ['activeEditor_' + item.conversations_user_id]: editor })
                                                            },
                                                            menubar: false,
                                                            statusbar: false,
                                                            toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                                        }}
                                                        onChange={this.handleOnChange.bind(this)}
                                                        className="form-control textarea-editor"
                                                        name="qutationMessage"
                                                        id='qutationMessage-'
                                                    />
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label className="file-label">
                                                            <input className="file-input" name="resume" type="file" onChange={this.handleFileChange.bind(this)} multiple={true} />
                                                            <span className="text-muted">
                                                                <i className="icon icon-attachment"></i> Attach File
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="col-md-6 text-right">
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <hr className="m-t-0 m-b-0" />
                                        <div className="p-20">
                                            {this.renderMessages(item.conversations)}
                                            <div className="message-body clearfix">
                                                <div className="media m-b-15">
                                                    <span className="media-left p-r-15">
                                                        <span className="bg-primary-400 btn btn-rounded btn-icon btn-xs">M</span>
                                                    </span>
                                                    <div className="media-body">
                                                        <span className="media-heading text-bold">Moving APT</span>
                                                        <div className="text-mid-grey f-s-13">
                                                            Sep 19, 2020, 3:40 PM
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                <div className="attachedment-files">
                                                    <ul className="file-list">
                                                        <li>
                                                            <a href='javascript:void(0);'><i className="position-left text-primary"></i>image-file-1248</a>
                                                        </li>
                                                        <li>
                                                            <a href='javascript:void(0);'><i className="position-left text-primary"></i>zip-file-1248</a>
                                                        </li>   
                                                    </ul>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="message-body clearfix">
                                                <div className="media m-b-15">
                                                    <span className="media-left p-r-15">
                                                        <span className="bg-primary-400 btn btn-rounded btn-icon btn-xs"><span className="letter-icon">M</span></span>
                                                    </span>
                                                    <div className="media-body">
                                                        <span className="media-heading text-bold">Moving APT</span>
                                                        <div className="text-mid-grey f-s-13">
                                                            Sep 19, 2020, 3:40 PM
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                <div className="attachedment-files">
                                                    <ul className="file-list">
                                                        <li>
                                                            <a href='javascript:void(0);'><i className="position-left text-primary"></i>image-file-1248</a>
                                                        </li>
                                                        <li>
                                                            <a href='javascript:void(0);'><i className="position-left text-primary"></i>zip-file-1248</a>
                                                        </li>   
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
        
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}

export default MyOfferDetails;