import React, { Component, Fragment } from 'react'

export default class UserOrderChatList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            searchType: 'name'   // name or order_no
        }
    }
    handleSearch = (e) => {
        this.setState({ searchText: e.target.value })
    }
    searchTypeToggle = () => {
        this.setState({ searchType: this.state.searchType === 'name' ? 'order_no' : 'name', searchText: "" })
    }
    clearSearch = () => {
        this.setState({ searchText: "" })
    }
    render() {
        const { chatUserList, onSelectChatUser, setChatListTab } = this.props;
        const { searchText, searchType } = this.state;
        let searchPlaceholder = searchType === 'name' ? "Search by Name" : "Search by Relo#";
        let filterTooltipTittle = searchType !== 'name' ? "Search by Name" : "Search by Relo#";
        return (
            <Fragment>
                <div className="search p-10">
                    <div className="input-group">
                        <div className="input-group-addon p-10">
                            <i className="icon-search4  f-s-11"></i>
                        </div>
                        <input type="text" placeholder={searchPlaceholder} name="searchText" value={searchText} className="form-control search" onChange={this.handleSearch} />
                        {searchText !== "" && (<span className="searchClearButton" onClick={this.clearSearch}><i className="icon-cross3"></i></span>)}
                    </div>
                    <div className="filter p-t-5 text-left">
                        <a href="javascript:void(0)" onClick={this.searchTypeToggle} className="f-s-12"><i className="icon-filter4 m-r-5"></i>{filterTooltipTittle}</a>
                    </div>
                </div>
                <div className="text-center chat-tab-wrapper">
                    <ul className="inner-page navigation chat-tab">
                        <li  className={'active'}>
                            <a href="javascript:void(0)" onClick={(e) => setChatListTab(0)}>Orders</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" onClick={(e) => setChatListTab(1)}>Users</a>
                        </li>
                    </ul>
                </div>
                <ul className="chat-contact">
                    {this.props.isLoading &&    <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>}
                    {
                        chatUserList.map((userInfo, index) => {
                            if (searchType !== ""
                                && searchText !== ""
                                && userInfo[searchType].toLowerCase().startsWith(searchText.toLowerCase()))
                                return (
                                    <li className="media" key={userInfo.chat_box_id} onClick={() => onSelectChatUser(userInfo, index)}>
                                        <div className="media-left user-active p-r-5">
                                            <img src={!!userInfo.profile_image ? userInfo.profile_image : "assets/images/default_avatar.jpg"} alt="avatar" width="30" />
                                              {/* <span className="dot inactive"></span> */}
                                        </div>
                                        <div className="media-body">
                                            <div className="about clearfix">
                                                <div className="about-left">
                                                    <p className="m-b-5">
                                                        <a href="javascript:void(0)" className="name unread">{userInfo.name}</a>
                                                        <span>{!!userInfo.profile_name && `(${userInfo.profile_name})`}</span>
                                                    </p>
                                                    <small className="relo-id">
                                                        #{userInfo.order_no}
                                                    </small>
                                                </div>
                                                <div className="about-right">
                                                    {/* <p className="chat-date">15/08</p> */}
                                                    <span className="badge badge-warning">{userInfo.msg_count > 0 && userInfo.msg_count}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                )
                            if (searchText == "") {
                                return (
                                    <li className="media" key={userInfo.chat_box_id} onClick={() => onSelectChatUser(userInfo, index)}>
                                        <div className="media-left user-active p-r-5">
                                            <img src={!!userInfo.profile_image ? userInfo.profile_image : "assets/images/default_avatar.jpg"} alt="avatar" width="30" />
                                            {/* <span className="dot inactive"></span> */}
                                        </div>
                                        <div className="media-body">
                                            <div className="about clearfix">
                                                <div className="about-left">
                                                    <p className="m-b-5">
                                                        <a href="javascript:void(0)" className="name unread">{userInfo.name}</a>
                                                        <span>{!!userInfo.profile_name && `(${userInfo.profile_name})`}</span>
                                                    </p>
                                                    <small className="relo-id">
                                                        #{userInfo.order_no}
                                                    </small>
                                                </div>
                                                <div className="about-right">
                                                    {/* <p className="chat-date">15/08</p> */}
                                                    <span className="badge badge-warning">{userInfo.msg_count > 0 && userInfo.msg_count}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                )
                            }
                        })
                    }
                </ul>

            </Fragment>
        )
    }
}
