import React, { Component, Fragment } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataWithParam from '../services/PostDataWithParam';
import { FileType, FileSvg, FileIcon } from '../externaljs/FileType';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Storage from '../externaljs/Storage';
import PostDataFile from '../services/PostDataFile';
import DateFormat from '../externaljs/DateFormat';
import { copyHTML } from '../externaljs/UrlHelpers';

const initial_form_data = {
    f_name: '',
    l_name: '',
    contact_no: '',
    email_id: '',
    user_type: '',
    secondary_contact_no: '',
    secondary_email_id: '',
}

class MoverAppz extends Component {

    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'My MoverAppz', link: '', class: 'active' }
        ],
        company_detail: {},
        logo: '',
        src: null,
        crop: {
            // aspect: 1 / 1,
            // height: 200,
            // width: 200,
            // x: 0,
            // y: 0
            unit: '%',
            width: 50,
            height: 50,
            x: 0,
            y: 0
        },
        form_data: {
            ...initial_form_data
        },
        f_name_err: '',
        l_name_err: '',
        contact_no_err: '',
        email_id_err: '',
        secondary_contact_no_err: '',
        secondary_email_id_err: '',
        user_type_err: '',
        croppedImageUrl: '',
        loading: false,
        uploadProccesing: false,
        UpdateErr: '',
        selectedFile: null,
        selectedFileAll: [],
        allStates: [],
        primaryCities: [],
        secondaryCities: [],
        company_files: [],
        isSubscribe: false,
        subDaysDetail: {},
        isCopied: false,
        type: "1",
        isUpgradeSub: false,
        nextSchedulePackage: null
    }
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    GetCompanyInfo = () => {

        PostDataWithParam('GetCompanyInfo', {})
            .then((response) => {
                if (response.success === 1) {
                    Storage.set('imnFirstLogin', JSON.stringify({ organization_name: response.data[0].organization_name, logo: response.data[0].logo }))

                    this.setState({ company_detail: response.data[0] });
                } else {
                    this.setState({ company_detail: [] })
                }
            })
            .catch(err => {
                this.setState({ company_detail: [], })
            })
    }
    getSubscriptionDays = () => {
        this.setState({ loading: true }, () => {
            PostDataWithParam('MoverAppzSubscriptionDetails', { user_id: JSON.parse(Storage.get('imnLoginData')).id })
                // PostDataWithParam('MoverAppzSubscriptionDetails', { user_id: JSON.parse(Storage.get('imnLoginData')).user_id })
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ subDaysDetail: response.data, loading: false });
                    } else {
                        this.setState({ subDaysDetail: {}, loading: false })
                    }
                })
                .catch(err => {
                    this.setState({ subDaysDetail: {}, loading: false })
                })
        })
    }
    getNextSchedulePackageInfo = () => {
        PostDataWithParam('GetScheduleMoverAppzPackage', {})
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ nextSchedulePackage: response.data[0] });

                }
            })
            .catch(err => {
            })
    }
    openNextScheduleDetailsModal = (e) => {
        if (e) e.preventDefault()
        window.$('#nextScheduleModal').modal('show');
    }
    componentDidMount() {
        this.GetCompanyInfo();
        this.getSubscriptionDays();
        this.getNextSchedulePackageInfo();

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.loading !== this.state.loading) {
            if (!this.state.loading) {
                window.$('[data-toggle="tooltip"]').tooltip({
                    // viewport: '.weekview-body',
                    placement: 'top',
                    html: true
                });
            }
        }
    }
    copyLink = (e) => {
        e.preventDefault();
        if (this.state.subDaysDetail.mover_appz_expiry_status !== 0) {
            window.swal({
                title: "Warning!",
                text: 'Please Upgrade Your MoverAppz Subscription to Complete this Action.',
                className: "subscrip-info-swal",
                icon: "",
                // buttons: ["Cancel", "Upgrade"],
            })
                .then((result) => {
                    if (result) {
                        this.props.history.push({
                            pathname: "/independent-mover/dashboard/moverAppz-subscription"
                        })
                    }
                });
            return
        }

        let link = "Thank you for joining the Relocation Revolution and use our easy to use app!<br/><br/>";
        for (let key in this.state.subDaysDetail) {
            let obj = this.state.subDaysDetail[key];
            if (['military_customer', 'non_military_customer', 'relo_pro'].includes(key) && !!obj) {
                link += `<b>${obj.tittle}</b><br/>`;
                link += `${obj.links.android_link} <br/>`;
                link += `${obj.links.ios_link} <br/>`;
                link += "<br/></br>";
            }

        }
        copyHTML(link);
        this.setState({ isCopied: true });
        setTimeout(() => {
            this.setState({ isCopied: false });
        }, 2000)

    }


    handleFileCheck = (file) => {
        if (file !== '') {
            return (
                <div className="thumb custom-logo" style={{ backgroundImage: "url(" + this.state.company_detail.logo + ")" }}>
                </div>
            )
        } else {
            return (
                <div className="thumb custom-logo" style={{ backgroundImage: "url('assets/images/icon-no-image.svg')" }}>
                </div>
            )
        }
    }
    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            window.$("#imageModal").modal('show')
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    onImageLoaded = (image, crop) => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = crop => {
        this.setState({ crop });
    };
    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            let dataURL = canvas.toDataURL("image/jpeg");
            resolve(dataURL);
        });
    }

    handleUpdateCompanyLogo = () => {
        if (this.state.croppedImageUrl != '') {
            this.setState({ proccesing: true }, () => {
                PostDataWithParam('UpdateCroppedCompanyLogoForMovers', { 'logo': this.state.croppedImageUrl })
                    .then((response) => {
                        if (response.success === 1) {
                            Storage.set('imnFirstLogin', JSON.stringify({ logo: this.state.logo }))
                            window.$("#imageModal").modal('hide')
                            window.swal('Success!', "Company logo updated successfully", 'success')
                            this.setState({
                                changeUpdateStatus: true, proccesing: false
                            });
                            this.GetCompanyInfo();
                        } else {
                            this.setState({ UpdateErr: response.msg, changeUpdateStatus: false, proccesing: false });
                        }
                    })
                    .catch((err) => {
                        this.setState({ UpdateErr: 'Something went wrong!', changeUpdateStatus: false, proccesing: false });
                    })
            })
        }
    }
    redirectSubPage = () => {
        this.setState({
            isSubscribe: true,
        });

    }
    redirectSubPageForUpgrade = () => {
        this.setState({
            isUpgradeSub: true,
        });

    }

    openShareAppModal = (e) => {
        e.preventDefault();
        if (this.state.subDaysDetail.mover_appz_expiry_status === 0) {
            this.setState({
                form_data: { ...initial_form_data }, f_name_err: '',
                l_name_err: '',
                contact_no_err: '',
                email_id_err: '',
                user_type_err: '',
                type: "1"
            })
            window.$('#shareAppModal').modal({
                backdrop: 'static',
                keyboard: false
            });
            window.$("#shareAppModal").modal('show');
        }
        else {
            window.swal({
                title: "Warning!",
                text: 'Please Upgrade Your MoverAppz Subscription to Complete this Action.',
                className: "subscrip-info-swal",
                icon: "",
                // buttons: ["Cancel", "Upgrade"],
            })
                .then((result) => {
                    if (result) {
                        this.props.history.push({
                            pathname: "/independent-mover/dashboard/moverAppz-subscription"
                        })
                    }
                });
        }


    }
    shareAppDataSave = (e) => {

        if (this.state.form_data.user_type == '') this.setState({ user_type_err: 'Please select user type' })
        if (this.state.form_data.f_name == '') this.setState({ f_name_err: 'Please enter first name' })
        if (this.state.form_data.l_name == '') this.setState({ l_name_err: 'Please enter last name' })
        if (this.state.form_data.email_id == '') this.setState({ email_id_err: 'Please enter email id' })
        if (this.state.form_data.contact_no == '') this.setState({ contact_no_err: 'Please enter phone no' })

        else {
            let form_data = { ...this.state.form_data };
            PostDataWithParam('ShareAppWithFormDetails', form_data)
                .then((response) => {
                    let result = response;

                    if (result.success === 1) {
                        window.$('#shareAppModal').modal('hide');
                        window.swal('Success!', response.msg, 'success');

                    } else {
                        this.setState({ loading: false });
                        window.swal('Error!', response.msg, 'error');
                    }
                })
                .catch(err => {
                    this.setState({ loading: false });
                    window.swal('Error!', 'Something went wrong3', 'error');
                })
        }
    }
    handleOnChange = (e) => {
        let form_data = Object.assign({}, this.state.form_data)
        form_data[e.target.name] = e.target.value
        if (e.target.name == 'contact_no' || e.target.name == 'secondary_contact_no') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                form_data[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
        }
        this.setState({ form_data: form_data });
    }
    handleChange(event) {
        this.setState({ type: event.currentTarget.value });
    }

    render() {
        if (this.state.isSubscribe) {
            return <Redirect to={{
                pathname: '/independent-mover/dashboard/moverAppz-subscription',

            }} />
        }
        if (this.state.isUpgradeSub) {
            return <Redirect to={'/independent-mover/dashboard/my-subscription'} />
        }
        return (
            <div>
                {
                    this.state.loading
                    &&
                    <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please Wait...</div>
                }
                {
                    !this.state.loading
                    &&
                    <React.Fragment>

                        <div className="row">
                            <div className="col-md-12">
                                {Breadcumb(this.state.breadcrumb)}
                            </div>
                        </div>
                        {this.state.isCopied && (<div className="row">
                            <div className="col-md-12 text-center">
                                <div className="alert alert-success">
                                    <i className="icon-checkmark-circle m-r-5"></i> Link Has Been Copied Successfully.
                                </div>
                            </div>
                        </div>)}
                        <div className="panel panel-flat">
                            <div className="panel-heading clearfix">
                                <h4 className="m-0 pull-left">My MoverAppz</h4>
                                <div className="pull-right">
                                    <Link to="#" className="btn btn-primary m-r-5" onClick={this.copyLink}><i className="icon-copy3"></i> Copy Link</Link>
                                    <Link to="#" className="btn btn-primary m-r-5" onClick={this.openShareAppModal}><i className="icon-share2"></i> Share App</Link>
                                    {JSON.parse(Storage.get('imnLoginData')).is_mover_appz === '1'
                                        ?
                                        (this.state.subDaysDetail.mover_appz_expiry_status === 0 && this.state.subDaysDetail.mover_appz_subscription_date !== '')
                                            ?
                                            <button className="btn bg-success" onClick={this.redirectSubPageForUpgrade}><i class="icon-checkmark4 m-r-5"></i> Subscribed</button>
                                            :
                                            <button className="btn bg-success" onClick={this.redirectSubPage}>Subscribe Now</button>
                                        :
                                        (this.state.subDaysDetail.mover_appz_expiry_status === 0)
                                            ?
                                            <button className="btn bg-success" onClick={this.redirectSubPageForUpgrade}><i class="icon-checkmark4 m-r-5"></i> Subscribed</button>
                                            :
                                            <button className="btn bg-success" onClick={this.redirectSubPage}>Subscribe Now</button>
                                    }

                                    {/* <button className="btn bg-success"><a href="moving-company/dashboard/checkout">Subscribe Now</a></button> */}
                                </div>
                            </div>
                            <div className="panel-body">
                                <div className="m-b-15">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <label className="moverappz-profile upload-company-logo thumbnail">
                                                {this.handleFileCheck(this.state.company_detail.logo)}

                                                <input type="file" name="logo" onChange={this.onSelectFile} />
                                            </label>
                                            <Link className="creat-new" to="#" onClick={e => {
                                                e.preventDefault();
                                                this.props.openHelpModal({ support_requirement: 1 });
                                            }}><i className="icon icon-pencil5 m-r-5"></i> Get Help With My Logo/ Create a New One</Link>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="panel panel-flat">
                                                {
                                                    this.state.loading
                                                    &&
                                                    <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please Wait...</div>
                                                }
                                                {/* {
                                                    !this.state.loading
                                                        &&
                                                        JSON.parse(Storage.get('imnLoginData')).is_mover_appz === 1
                                                        ?
                                                        (this.state.subDaysDetail.mover_appz_expiry_status === 0 && this.state.subDaysDetail.mover_appz_subscription_date !== '')
                                                            ?
                                                            ''
                                                            :
                                                            this.state.subDaysDetail.mover_appz_expiry_status === 0
                                                                ?
                                                                <div className="panel-heading">
                                                                    <div className="alert alert-warning m-b-0 text-center">
                                                                        <p className="f-s-14">Your <b>{this.state.subDaysDetail.remaining_days + 1}</b> Days trial will expire on <b>{DateFormat.getFormattedDateTime(this.state.subDaysDetail.mover_appz_expiry_date, 'm-d-Y')}</b>. <Link to={{
                                                                            pathname: "/independent-mover/dashboard/checkout",
                                                                            state: { id: this.state.company_detail.profile_id, title: 'MoverAppz', value: this.state.company_detail.moverappz_amount, type: 'moverAppz', type: 'moverAppz' }
                                                                        }} style={{ 'color': '#aa3510', 'font-style': 'italic' }}><u>Subscribe Now</u></Link> to continue sharing your app with your customers &amp; pros.</p>

                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="panel-heading">
                                                                    <div className="alert alert-warning m-b-0 text-center">
                                                                        <p className="f-s-14">Your MoverAppz subscription has been expired on <b>{DateFormat.getFormattedDateTime(this.state.subDaysDetail.mover_appz_expiry_date, 'm-d-Y')}</b>. <Link to={{
                                                                            pathname: "/independent-mover/dashboard/checkout",
                                                                            state: { id: this.state.company_detail.profile_id, title: 'MoverAppz', value: this.state.company_detail.moverappz_amount, type: 'moverAppz', type: 'moverAppz' }
                                                                        }} style={{ 'color': '#aa3510', 'font-style': 'italic' }}><u>Subscribe Now</u></Link> to continue sharing your app with your customers &amp; pros.</p>

                                                                    </div>
                                                                </div>
                                                        :
                                                        this.state.subDaysDetail.mover_appz_expiry_status === 0
                                                            ?
                                                            ''
                                                            :
                                                            <div className="panel-heading">
                                                                <div className="alert alert-warning m-b-0 text-center">
                                                                    <p className="f-s-14">Your MoverAppz subscription  has been expired on <b>{DateFormat.getFormattedDateTime(this.state.subDaysDetail.mover_appz_expiry_date, 'm-d-Y')}</b>. <Link to={{
                                                                        pathname: "/independent-mover/dashboard/checkout",
                                                                        state: { id: this.state.company_detail.profile_id, title: 'MoverAppz', value: this.state.company_detail.moverappz_amount, type: 'moverAppz', type: 'moverAppz' }
                                                                    }} style={{ 'color': '#aa3510', 'font-style': 'italic' }}><u>Subscribe Now</u></Link> to continue sharing your app with your customers &amp; pros.</p>

                                                                </div>
                                                            </div>

                                                } */}
                                                {
                                                    !this.state.loading
                                                    && (
                                                        <Fragment>
                                                            {
                                                                this.state.subDaysDetail.is_moverappz_payment_count === 0
                                                                &&
                                                                (

                                                                    <div className="panel-heading">
                                                                        <div className="alert alert-primary text-primary no-border m-b-0 text-center">
                                                                            <p className="f-s-14">You are not subscribed to MoverAppz. Please <Link to='/independent-mover/dashboard/moverAppz-subscription' style={{ 'color': '#aa3510', 'font-style': 'italic' }}><u>Subscribe Now</u></Link> to keep sharing your app with your customers.</p>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            }

                                                            {
                                                                this.state.subDaysDetail.mover_appz_expiry_status !== 0 && this.state.subDaysDetail.is_moverappz_payment_count > 0
                                                                &&
                                                                (
                                                                    <div className="panel-heading">
                                                                        <div className="alert alert-primary text-primary no-border m-b-0 text-center">
                                                                            <p className="f-s-14">Your MoverAppz subscription  has been expired on <b>{DateFormat.getFormattedDateTime(this.state.subDaysDetail.mover_appz_expiry_date, 'm-d-Y')}</b>. <Link to='/independent-mover/dashboard/moverAppz-subscription' style={{ 'color': '#aa3510', 'font-style': 'italic' }}><u>Subscribe Now</u></Link> to continue sharing your app with your customers &amp; pros.</p>

                                                                        </div>
                                                                    </div>
                                                                )

                                                            }
                                                            {
                                                                this.state.nextSchedulePackage
                                                                &&
                                                                (
                                                                    <div className="panel-heading">
                                                                        <div className="alert alert-primary text-primary no-border m-b-0 text-center">
                                                                            <p className="f-s-14">
                                                                                Your MoverAppz package has been updated & will be effective from your next payment due date.
                                                                                <a
                                                                                    href="#"
                                                                                    onClick={this.openNextScheduleDetailsModal}
                                                                                    style={{ 'color': '#aa3510', 'font-style': 'italic' }}
                                                                                >Click here
                                                                                </a> to view your new package info
                                                                            </p>

                                                                        </div>
                                                                    </div>
                                                                )

                                                            }
                                                        </Fragment>
                                                    )
                                                }



                                                <div className="panel-body">

                                                    <Link
                                                        to="/independent-mover/dashboard/military-approved/profile-tab"
                                                        className="btn btn-info btn-block m-t-5 f-s-16"
                                                    >
                                                        Review & Edit Profile
                                                    </Link>
                                                    {/* <h6 className="text-primary m-t-0 f-s-16">Basic Info</h6>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-md-4">
                                                            <div className="form-group">
                                                                <label className="text-bold m-b-0">Email Address</label>
                                                                <p>{this.state.company_detail.email || '---'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-4">
                                                            <div className="form-group">
                                                                <label className="text-bold m-b-0">Main office phone no</label>
                                                                <p>{this.state.company_detail.main_office_phone_no || "---"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-4">
                                                            <div className="form-group">
                                                                <label className="text-bold m-b-0">Address</label>
                                                                <p>{this.state.company_detail.address || "---"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-4">
                                                            <div className="form-group">
                                                                <label className="text-bold m-b-0">Year Founded</label>
                                                                <p>{this.state.company_detail.year_founded || "---"}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-4">
                                                            <div className="form-group">
                                                                <label className="text-bold m-b-0">Website</label>
                                                                <p>{this.state.company_detail.website_link || 'http://'}</p>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    {/* <h6 className="text-primary f-s-16">Feature lists</h6> */}
                                                    <h2 className="text-primary m-b-20">Benefits & Features- Communication, Accountability, Transparency, & Security delivered!</h2>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <ul className="moverappzFeaturesListing">
                                                                <li>Branded App for iOS & Google Play</li>
                                                                <li>Streamline Sales Process- Book More Orders</li>
                                                                <li>Unlimited Virtual & Video Pre-Move Surveys</li>
                                                                <li>Recieve Unlimited ReloOffer leads</li>
                                                                <li>24x7 Enhanced communication via message & video chat</li>
                                                                <li>Easy management of claims- File a Claim</li>
                                                                <li>'Who's My Crew?' ensures safety and security for Customer
                                        <a href="javascript:void(0);" data-toggle="tooltip" data-original-title="'Free for 30 days, requires MyReloPro® subscription for your boots on ground. Start adding your crew-team members after checkout for complete 24/7 visibility'"><i className="icon-info22 text-info m-l-5"></i></a></li>
                                                                <li>Reduce Claims by 90% with Inventory My Stuff & CYA feature
                                        <a href="javascript:void(0);" data-toggle="tooltip" data-original-title="'Free for 30 days, requires MyReloPro® subscription for your boots on ground. Start adding your crew-team members after checkout for complete 24/7 visibility'"><i className="icon-info22 text-info m-l-5"></i></a></li>
                                                                <li>'Where's My Stuff? Real time shipment tracking
                                        <a href="javascript:void(0);" data-toggle="tooltip" data-original-title="'Free for 30 days, requires MyReloPro® subscription for your boots on ground. Start adding your crew-team members after checkout for complete 24/7 visibility'"><i className="icon-info22 text-info m-l-5"></i></a></li>
                                                                <li>Search for Pro's (Helpers, 3rd Party, and Claims)</li>
                                                                <li>Customer Satisfaction Survey Automation</li>
                                                                <li>Build and manage your crew</li>
                                                            </ul>

                                                        </div>
                                                        <div className="col-md-6">
                                                            <ul className="moverappzFeaturesListing">
                                                                <li>Save $$ with MyReloMarketPlace for everyone. Discounts, buy moving equipment, supplies, truck rentals, and so much more!</li>
                                                                <li>Create & manage profile</li>
                                                                <li>Post an offer</li>
                                                                <li>Make more money</li>
                                                                <li>Import ClearStar Background check</li>
                                                                <li>GetMilitaryApproved Training</li>
                                                                <li>ReloPro<sup>&#174;</sup> Training Badge</li>
                                                                <li>Manage Schedule & calendar</li>
                                                                <li>DoD PreCheck<sup>&#174;</sup></li>
                                                                <li>View & Accept Orders</li>
                                                                <li>Get paid instantly & get a tip</li>
                                                                <li>Marketing to movers, insurance companies & others</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- subscribeNow Modal --> */}
                        <div class="modal fade" id="subscribeNowModal" tabindex="-1" role="dialog" aria-labelledby="subscribeNowModalLabel">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        <h4 class="modal-title" id="myModalLabel">Add Credit/Debit Card</h4>
                                    </div>
                                    <div class="modal-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="text-semibold">Card Holder Name</label>
                                                    <input type="text" className="form-control" placeholder="Enter Card Holder Name" name="card_holder_name" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="text-semibold">Card No</label>
                                                    <input type="text" className="form-control" placeholder="Enter 16 digit card no" name="card_no" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="text-semibold">Expiration Date (MM/YYYY)</label>
                                                    <input type="text" className="form-control" placeholder="MM/YYYY" name="expiry_date" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="text-semibold">CVV</label>
                                                    <input type="text" className="form-control" placeholder="Enter CVV" name="cvv_no" />
                                                    <p className="help-block"><i className="icon-info22"></i> CVV : A 3 digit code on the card, you can see it on back side of your card.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary">Submit</button>
                                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="imageModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title" id="myModalLabel">Upload Company Logo</h4>
                                    </div>
                                    <div className="modal-body">
                                        {this.state.src && (
                                            <ReactCrop
                                                src={this.state.src}
                                                crop={this.state.crop}
                                                onImageLoaded={this.onImageLoaded}
                                                onComplete={this.onCropComplete}
                                                onChange={this.onCropChange}
                                            />
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        {
                                            this.state.proccesing ?
                                                <button type="button" className="btn btn-primary" disabled={true}>Proccesing...</button> :
                                                <button type="button" className="btn btn-primary" onClick={this.handleUpdateCompanyLogo}>Upload</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Start shareApp Modal --> */}
                        <div id="shareAppModal" className="modal fade" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title">Share App</h4>
                                    </div>
                                    <hr />
                                    <div className="modal-body p-t-0">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="text-semibold">Share With</label>
                                                    <div>
                                                        <label className="radio-inline m-b-0 m-r-15">
                                                            <input type="radio" name="share_with" value="1" checked={this.state.type === "1"} onChange={this.handleChange} />Customer
                                                        </label>
                                                        {/* <label className="radio-inline m-b-0 m-r-15">
                                                            <input type="radio" name="share_with" value="2" checked={this.state.type === "2"} onChange={this.handleChange} />Team Member
                                                        </label> */}
                                                        <label className="radio-inline m-b-0 m-r-15">
                                                            <input type="radio" name="share_with" value="3" checked={this.state.type === "3"} onChange={this.handleChange} />Pros
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">User Type <span className="text-danger">*</span></label>
                                                        {this.state.type === "1" ?
                                                            <select className="form-control" name="user_type" value={this.state.form_data.user_type} onChange={this.handleOnChange}>
                                                                <option value="">--Select--</option>
                                                                <option value="Military Customer">Military</option>
                                                                <option value="Non-Military Customer">Non-Military</option>
                                                            </select>
                                                            :
                                                            <select className="form-control" name="user_type" value={this.state.form_data.user_type} onChange={this.handleOnChange}>
                                                                <option value="">--Select--</option>
                                                                <option value="Driver">Driver</option>
                                                                <option value="Packer">Packer</option>
                                                                <option value="Helper">Helper</option>
                                                                <option value="3rd Party">3rd Party</option>
                                                                <option value="Claim Repair">Claim Repair</option>
                                                            </select>

                                                        }
                                                        <p className="text-danger help-block">{this.state.user_type_err}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">First Name <span className="text-danger">*</span></label>
                                                        <input type="text" name="f_name" value={this.state.form_data.f_name} onChange={this.handleOnChange} className="form-control" placeholder="Enter First Name" />
                                                        <p className="text-danger help-block">{this.state.f_name_err}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">Last Name <span className="text-danger">*</span></label>
                                                        <input type="text" name="l_name" value={this.state.form_data.l_name} onChange={this.handleOnChange} className="form-control" placeholder="Enter First Name" />
                                                        <p className="text-danger help-block">{this.state.l_name_err}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">Primary Email Address <span className="text-danger">*</span></label>
                                                        <input type="text" name="email_id" value={this.state.form_data.email_id} onChange={this.handleOnChange} className="form-control" placeholder="Enter Primary Email Address" />
                                                        <p className="text-danger help-block">{this.state.email_id_err}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">Primary Phone Number <span className="text-danger">*</span></label>
                                                        <input type="text" name="contact_no" value={this.state.form_data.contact_no} onChange={this.handleOnChange} className="form-control" placeholder="xxx xxx xxxx" maxLength="12" />
                                                        <p className="text-danger help-block">{this.state.contact_no_err}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">Secondary Email Address</label>
                                                        <input type="text" name="secondary_email_id" value={this.state.form_data.secondary_email_id} onChange={this.handleOnChange} className="form-control" placeholder="Enter Secondary Email Address" />
                                                        {/* <p className="text-danger help-block">{this.state.secondary_email_id_err}</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="text-semibold">Secondary Phone Number</label>
                                                        <input type="text" name="secondary_contact_no" value={this.state.form_data.secondary_contact_no} onChange={this.handleOnChange} className="form-control" placeholder="xxx xxx xxxx" maxLength="12" />
                                                        {/* <p className="text-danger help-block">{this.state.secondary_contact_no_err}</p> */}
                                                    </div>
                                                </div>
                                            </div>

                                        </React.Fragment>


                                    </div>
                                    <hr className="m-t-0" />
                                    <div className="modal-footer p-t-0">
                                        <button type="button" className="btn btn-default" data-dismiss="modal" >Close</button>
                                        <button type="button" className="btn btn-primary" onClick={this.shareAppDataSave}>Share</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- End shareApp Modal --> */}
                        {/* Nest schedule info modal */}
                        <div className="modal fade" id="nextScheduleModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    {/* <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title" id="myModalLabel">Upload Company Logo</h4>
                                    </div> */}
                                    <div className="modal-body">
                                        {this.state.nextSchedulePackage &&
                                            (<div className="row">
                                                <div className="col-md-12">
                                                    <div className={`m-b-20 columns `}>
                                                        <div className="icon-div">
                                                            <i className="icon-check"></i>
                                                        </div>
                                                        <ul className="price">
                                                            <li className="header text-capitalize f-s-15">{this.state.nextSchedulePackage.tittle}</li>
                                                            <li><p className="m-t-5">
                                                                <b className="f-s-18 text-danger">
                                                                    <strike className="f-s-14 m-r-5">${this.state.nextSchedulePackage.original_moverappz_amount}</strike>
                                                                ${this.state.nextSchedulePackage.moverappz_amount}</b> <br />
                                                                <span className="f-s-14">{`(${this.state.nextSchedulePackage.pro_limit} MyReloPro® App Boots On the Ground Users)`}</span><br />
                                                                <span>{this.state.nextSchedulePackage.customer_limit ? `(${this.state.nextSchedulePackage.customer_limit} Customers/ Month)` : `(Unlimited Customers)`}</span>
                                                            </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>


                                            </div>

                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Nest schedule info modal  End*/}
                    </React.Fragment>
                }
            </div>
        );
    }
}
export default withRouter(MoverAppz);