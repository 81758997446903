import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import GetData from '../services/GetData';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import PostDataWithParam from '../services/PostDataWithParam';
import ConfirmBox from '../externaljs/ConfirmBox';
import Breadcumb from '../externaljs/Breadcrumb';
import DateFormat from '../externaljs/DateFormat';

class CustomerServeys extends Component {
   state = {
      breadcrumb: [
         { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
         { title: 'Customer satisfaction survey', link: '', class: '' },
         { title: 'Customer Surveys', link: '', class: 'active' }
      ],
      lists: [],
      loading: false,
      comment: '',
      answers: [],
      survey_loading: false
   }

   componentDidMount() {
      this.AllStaffs();
   }

   AllStaffs = () => {
      this.setState({ loading: true }, () => {
         PostDataWithParam('GetSurveyListForMover', {})
            .then((response) => {
               if (response.success === 1) {
                  this.setState({ loading: false }, () => {
                     this.setState({ lists: response.data }, () => {
                        DataTableBasic();
                     });
                  })
               } else {
                  this.setState({ lists: [], loading: false })
               }
            })
            .catch(() => {
               this.setState({ lists: [], loading: false })
            })
      })
   }

   viewSurvey = (id) => {
      this.setState({ survey_loading: true }, () => {
         PostDataWithParam('GetSurveyAnswerForMover', { order_request_detail_id: id })
            .then((response) => {
               if (response.success === 1) {
                  this.setState({ answers: response.data, comment: response.comment, loading: false, survey_loading: false });
               }
               else {
                  this.setState({ answers: [], comment: '', loading: false, survey_loading: false });
               }
            })
            .catch(err => {
               window.swal('Error!', 'Something went wrong', 'error')
               this.setState({ answers: [], comment: '', loading: false, survey_loading: false });
            })
      })
   }

   renderAnswer = (ans) => {
      if (ans == "Good" || ans == "Excellent")
         return <h4 className="text-success">{ans}</h4>
      else if (ans == "Un-satisfactory")
         return <h4 className="text-danger">{ans}</h4>
      else if (ans == "Satisfactory")
         return <h4 className="text-info">{ans}</h4>
      else if (ans == "Poor")
         return <h4 className="text-orange">{ans}</h4>
      else
         return <h4 className="text-info">{ans}</h4>
   }

   render() {
      return (

         <div>
            {Breadcumb(this.state.breadcrumb)}

            <div className="panel panel-flat">
               {
                  this.state.loading
                  &&
                  <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
               }
               {
                  !this.state.loading
                  &&
                  this.state.lists.length !== 0
                  &&
                  <table className="table datatable-basic table-striped">
                     <thead>
                        <tr>
                           <th>Relo #</th>
                           <th>Customer Name</th>
                           <th>Posted By</th>
                           <th>Relocation Request Date</th>
                           <th>End Date</th>
                           <th>Action</th>
                        </tr>
                     </thead>
                     <tbody>
                        {this.state.lists.map((list, i) => {
                           return (
                              <tr key={i}>
                                 <td><Link
                                    to={{
                                       pathname: '/independent-mover/dashboard/closed-order-details',
                                       state: {
                                          id: list.id,
                                          type: 'accepted'
                                       }
                                    }}
                                 >{list.order_id}</Link></td>
                                 <td className="text-nowrap">{list.customer_name}</td>
                                 <td>{list.posted_by}</td>
                                 <td>{DateFormat.getFormattedDateTime(list.request_start_date, 'm-d-Y')}</td>
                                 <td>{DateFormat.getFormattedDateTime(list.request_end_date, 'm-d-Y')}</td>
                                 <td ><a href="#" className="btn btn-xs btn-primary" data-toggle="modal" data-target="#ViewServey" onClick={() => this.viewSurvey(list.order_request_detail_id)}>View Survey</a></td>
                              </tr>
                           )
                        }
                        )}
                     </tbody>
                  </table>
               }
               {
                  !this.state.loading
                  &&
                  this.state.lists.length === 0
                  &&
                  <div className="alert alert-primary border-none text-center">No Staff Found.</div>
               }
            </div>
            <div id="ViewServey" className="modal fade" role="dialog">
               <div className="modal-dialog">
                  <div className="modal-content">
                     <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">View Customer Satisfaction Survey</h4>
                     </div>
                     <hr className="m-0 m-t-5" />
                     <div className="modal-body">
                        {this.state.survey_loading
                           &&
                           <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                        }
                        {!this.state.survey_loading
                           &&
                           this.state.answers.length > 0
                           &&
                           <React.Fragment>
                              {
                                 this.state.answers.map((ques, index) => {
                                    return (
                                       <React.Fragment key={index}>
                                          <div className="media">
                                             <div className="media-body">
                                                <div className="clearfix">
                                                   <h5 className="m-0">{ques.question}</h5>
                                                   <div className="m-t-5 m-b-5">
                                                      <h4 className="text-success">{this.renderAnswer(ques.answer)}</h4>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <hr className="m-t-5 m-b-10" />
                                       </React.Fragment>
                                    )
                                 })
                              }
                              <div className="well p-10">
                                 <label className="text-semibold">Comments</label>
                                 <p>{this.state.comment ||
                                    <div className="panel-body">
                                       <div className="alert alert-primary border-none text-center m-0">No Comments Found.</div>
                                    </div>
                                 }
                                 </p>
                              </div>
                           </React.Fragment>
                        }
                        {!this.state.survey_loading
                           &&
                           this.state.answers.length == 0
                           &&
                           <React.Fragment>
                              <div className="well p-10">
                                 <div className="panel-body">
                                    <div className="alert alert-primary border-none text-center m-0">No Survey Reports Found.</div>
                                 </div>
                              </div>
                           </React.Fragment>
                        }
                     </div>
                     <hr className="m-0" />
                     <div className="modal-footer p-t-15">
                        <button type="button" className="btn btn-default btn-xs" data-dismiss="modal">Close</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}


export default CustomerServeys;