import React, { Component } from 'react';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import { Redirect } from 'react-router-dom';
import Breadcumb from '../externaljs/Breadcrumb';
import Storage from '../externaljs/Storage';
import Urls from '../externaljs/Urls';
import GetData from '../services/GetData';
import PostDataWithParam from '../services/PostDataWithParam';


class Checkout extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Checkout', link: '', class: 'active' }
        ],
        cardList: [],
        fields: {
            card_holder_name: '',
            card_no: '',
            expiry_month: '',
            expiry_year: '',
            expiry_date: '',
            cvv_no: '',
            amount: '',
            subscription_id: '',
            subscription_issue_date: '',
            subscription_expiry_date: ''
        },
        fields_error: {
            card_holder_name_err: '',
            card_no_err: '',
            expiry_err: '',
            cvv_err: ''
        },
        checkout: {
            subscription_id: '',
            title: '',
            value: '',
            user_id: '',
            issue: '',
            expire: ''
        },
        saveStatus: false,
        paymentStatus: false,
        GMApaymentStatus: false,
        selectRadio: true,
        addCardChk: false,
        processing: false,
        processingC: false,
        paymentType: false,
        redirectUrlAfterAppzPayment: "/independent-mover/dashboard"
    }

    componentDidMount() {
        if (typeof this.props.location.state !== 'undefined') {

            let today = new Date();
            let dd = today.getDate();
            let mm = today.getMonth() + 1; //January is 0!
            let mm_nxt = today.getMonth() + 2;
            let yyyy = today.getFullYear();
            let yyyy_nxt = today.getFullYear();
            let expire;

            if (dd < 10) {
                dd = '0' + dd;
            }

            if (mm < 10) {
                mm = '0' + mm;
            }

            if (mm_nxt < 10) {
                mm_nxt = '0' + mm_nxt;
            }
            if (this.props.location.state.type === 'moverAppz') {
                // expire = mm_nxt + '/' + dd + '/' + yyyy_nxt;
                expire = window.moment().add(1, 'months').format('MM/DD/YYYY')
            }
            else {
                if (this.props.location.state.gma_subscription_id == 0 || this.props.location.state.gma_subscription_id == 1)
                    // expire = mm_nxt + '/' + dd + '/' + yyyy_nxt;
                    expire = window.moment().add(1, 'months').format('MM/DD/YYYY')
                if (this.props.location.state.id == 2)
                    // expire = mm_nxt + '/' + dd + '/' + yyyy_nxt;
                    expire = window.moment().add(1, 'months').format('MM/DD/YYYY')
                else if (this.props.location.state.id == 3)
                    // expire = mm_nxt + '/' + dd + '/' + yyyy_nxt;
                    expire = window.moment().add(1, 'months').format('MM/DD/YYYY')
                else if (this.props.location.state.id == 4)
                    expire = '';
            }

            let checkout = {
                subscription_id: this.props.location.state.id || 1,    // 1 for gma_subscription_id
                title: this.props.location.state.title,
                value: this.props.location.state.value,
                user_id: (JSON.parse(Storage.get('imnLoginData'))).id,
                issue: mm + '/' + dd + '/' + yyyy,
                expire: expire
            }
            if (this.props.location.state.type === 'moverAppz') {
                checkout = { ...checkout, ...this.props.location.state.initialMoverSizeData }
            }
            this.setState({ checkout: checkout });
            if (this.props.location.state.type === 'moverAppz') {
                this.setState({
                    paymentType: true,
                    redirectUrlAfterAppzPayment: Object.keys(this.props.location.state.initialMoverSizeData).length > 0
                        ? '/independent-mover/dashboard/military-approved/profile-tab'
                        : '/independent-mover/dashboard'
                });

            }
            else {
                this.setState({ paymentType: false });
            }
        }
        this.GetCardList()
    }

    /**
     * Paypal express checkout
     */

    onSuccess = (payment) => {

        let formData = {
            amount: this.state.checkout.value,
            transaction_id: payment.paymentID,
            subscription_id: this.state.checkout.subscription_id,
            subscription_issue_date: this.state.checkout.issue,
            subscription_expiry_date: this.state.checkout.expire,
            gma_subscription_id: this.state.checkout.subscription_id,
            gma_subscription_issue_date: this.state.checkout.issue,
            gma_subscription_expiry_date: this.state.checkout.expire
        };

        if (this.props.location.state.type == 'gmaSubscription') {

            PostDataWithParam('GMAPackagePaymentPaypal', formData)
                .then((response) => {
                    if (response.success === 1) {
                        var event = new Event('subscription');
                        document.body.dispatchEvent(event);
                        window.swal('Success!', response.msg, 'success')
                        this.setState({ GMApaymentStatus: true, processing: false, processingC: false })
                    } else {
                        window.swal('Error!', response.msg, 'error')
                        this.setState({ GMApaymentStatus: false, processing: false, processingC: false })
                    }
                })
                .catch(err => {
                    window.swal('Error!', 'Oops! Something went wrong. Please try again.', 'error')
                    this.setState({ GMApaymentStatus: false, processing: false, processingC: false })
                })
        }

        else {
            PostDataWithParam(this.state.paymentType ? 'MoverAppzPaymentPaypal' : 'SubscriptionPaypalPayment', { ...formData, ...this.state.checkout })
                .then((response) => {
                    if (response.success === 1) {
                        var event = new Event('subscription');
                        document.body.dispatchEvent(event);
                        window.swal('Success!', response.msg, 'success')
                        this.setState({ paymentStatus: true })
                    } else {
                        window.swal('Error!', response.msg, 'error')
                        this.setState({ paymentStatus: false })
                    }
                })
                .catch(err => {
                    window.swal('Error!', 'Oops! Something went wrong. Please try again.', 'error')
                    this.setState({ paymentStatus: false })
                })
        }

    }

    onCancel = (data) => {
        window.swal('Cancelled!', 'Payment could not be processed.', 'warning');
    }

    onError = (err) => {
        window.swal('Error!', 'Payment could not be processed.', 'error');
    }

    /**
     * End of paypal
     */

    // changeValue = (e) => {
    //     let fields = this.state.fields;
    //     fields[e.target.name] = e.target.value;
        
    //     this.setState({ fields: fields });
    // }

    changeValue = (e) => {
        let fields = this.state.fields;
        let value = e.target.value;
        if (e.target.name === "expiry_date") {
            if (/^[0-9\/]*\S$/.test(value)) {
                fields[e.target.name] = this.formatExpDate(value);
            }
        } else {
            fields[e.target.name] = value;
        }
        this.setState({ fields: fields });

    }
    formatExpDate = (value) => {
        return value.substr(0, 5).replace(/([0-9]{2})\/?([0-9]{2})/, "$1/$2");
    }

    changeCardChk = (e) => {
        this.setState({ addCardChk: e.target.checked });
    }

    changeFormat = (e) => {

        let fields = this.state.fields;
        let value = e.target.value;
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
        var matches = v.match(/\d{4,16}/g);
        var match = matches && matches[0] || '';
        var parts = [];

        for (let i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4))
        }

        if (parts.length) {
            fields.card_no = parts.join(' ')
        } else {
            fields.card_no = value;
        }

        this.setState({ fields: fields });
    }

    GetCardList = () => {
        GetData('GetCardList')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ cardList: response.data });
                }
            })
            .catch(err => {
                this.setState({ cardList: [] });
            })
    }

    resetErr = (cb) => {
        let fields_error = {
            card_holder_name_err: '',
            card_no_err: '',
            expiry_err: '',
            cvv_err: ''
        }

        this.setState({ fields_error: fields_error }, () => {
            cb()
        });
    }

    handleAddCard = (e) => {
        e.preventDefault();
        this.resetErr(() => {
            let fields = this.state.fields;
            let fields_error = this.state.fields_error;
            let numberReg = /^(?=.*\d)[\d ]+$/;
            let cardExp = /^\s*(0?[1-9]|1[0-2])\/(\d\d|\d{4})\s*$/;
            let date = new Date();

            if (fields.card_holder_name == "") {
                fields_error.card_holder_name_err = "Enter card holder name"

            } else if (!numberReg.test(fields.card_no)) {
                fields_error.card_no_err = "Enter valid card no"

            } else if (fields.card_no.length != 19) {
                fields_error.card_no_err = "Enter valid card no"

            }
            else if (!cardExp.test(fields.expiry_date)) {
                fields_error.expiry_err = "Enter valid format of month and year (MM/YYYY)";

            }
            else if (fields.cvv_no.length != 3) {
                fields_error.cvv_err = "Enter valid cvv"

            } else {
                let expiry = fields.expiry_date.split("/");
                fields.expiry_month = expiry[0];
                fields.expiry_year = expiry[1];
                this.setState({ fields: fields }, () => {
                    if (this.state.addCardChk) {
                        this.setState({ processing: true }, () => {
                            PostDataWithParam('AddCard', this.state.fields)
                                .then((response) => {
                                    if (response.success === 1) {
                                        let fields = {
                                            card_holder_name: this.state.fields.card_holder_name,
                                            card_no: this.state.fields.card_no,
                                            expiry_month: this.state.fields.expiry_month,
                                            expiry_year: this.state.fields.expiry_year,
                                            expiry_date: this.state.fields.expiry_month + '/' + this.state.fields.expiry_year,
                                            cvv_no: this.state.fields.cvv_no,
                                            amount: this.state.checkout.value,
                                            subscription_id: this.state.checkout.subscription_id,
                                            subscription_issue_date: this.state.checkout.issue,
                                            subscription_expiry_date: this.state.checkout.expire
                                        }

                                        this.setState({ fields: fields }, () => {

                                            this.checkOut()
                                            this.setState({ saveStatus: true })
                                        });

                                    } else {
                                        window.swal('Error!', response.msg, 'error');
                                        this.setState({ saveStatus: false, processing: false });
                                    }
                                })
                                .catch(err => {
                                    window.swal('Failed!', 'Oops! Something went wrong.', 'error');
                                    this.setState({ saveStatus: false, processing: false });
                                })
                        })
                    } else {
                        let fields = {
                            card_holder_name: this.state.fields.card_holder_name,
                            card_no: this.state.fields.card_no,
                            expiry_month: this.state.fields.expiry_month,
                            expiry_year: this.state.fields.expiry_year,
                            expiry_date: this.state.fields.expiry_month + '/' + this.state.fields.expiry_year,
                            cvv_no: this.state.fields.cvv_no,
                            amount: this.state.checkout.value,
                            subscription_id: this.state.checkout.subscription_id,
                            subscription_issue_date: this.state.checkout.issue,
                            subscription_expiry_date: this.state.checkout.expire
                        }

                        this.setState({ fields: fields }, () => {
                            this.setState({ processing: true }, () => {

                                this.checkOut()
                                this.setState({ saveStatus: true })
                            })
                        });
                    }
                })
            }
            this.setState({ fields_error: fields_error });
        })
    }

    renderPrimary = (status) => {
        if (status == 1)
            return <span className="label bg-green-800">Primary</span>
        else
            return ''
    }

    ChangeRadioValue = (id, card_name, card_no, expiry_month, expiry_year, cvv_no) => {

        let fields = {
            card_holder_name: card_name,
            card_no: card_no,
            expiry_month: expiry_month,
            expiry_year: expiry_year,
            cvv_no: cvv_no,
            expiry_date: expiry_month + '/' + expiry_year,
            amount: this.state.checkout.value,
            subscription_id: this.state.checkout.subscription_id,
            subscription_issue_date: this.state.checkout.issue,
            subscription_expiry_date: this.state.checkout.expire

        }

        this.setState({ fields: fields, selectRadio: false }, () => {
            console.log(this.state.fields)
        });
    }

    checkOut = () => {
        this.setState({ processingC: true }, () => {
            if (this.props.location.state.type == 'gmaSubscription') {
                let fields = {
                    ...this.state.fields,
                    gma_subscription_id: this.state.fields.subscription_id,
                    gma_subscription_issue_date: this.state.fields.subscription_issue_date,
                    gma_subscription_expiry_date: this.state.fields.subscription_expiry_date,
                }
                PostDataWithParam('GMAPackagePaymentStripe', fields)
                    .then((response) => {
                        if (response.success === 1) {
                            var event = new Event('subscription');
                            document.body.dispatchEvent(event);
                            window.swal('Success!', response.msg, 'success')
                            this.setState({ GMApaymentStatus: true, processing: false, processingC: false })
                        } else {
                            window.swal('Error!', response.msg, 'error')
                            this.setState({ GMApaymentStatus: false, processing: false, processingC: false })
                        }
                    })
                    .catch(err => {
                        window.swal('Error!', 'Oops! Something went wrong. Please try again.', 'error')
                        this.setState({ GMApaymentStatus: false, processing: false, processingC: false })
                    })
            }
            else {
                PostDataWithParam(this.state.paymentType ? 'MoverAppzPaymentStripe' : 'SubscriptionPayment', { ...this.state.fields, ...this.state.checkout })
                    .then((response) => {
                        if (response.success === 1) {
                            var event = new Event('subscription');
                            document.body.dispatchEvent(event);
                            window.swal('Success!', response.msg, 'success')
                            this.setState({ paymentStatus: true, processing: false, processingC: false })
                        } else {
                            window.swal('Error!', response.msg, 'error')
                            this.setState({ paymentStatus: false, processing: false, processingC: false })
                        }
                    })
                    .catch(err => {
                        window.swal('Error!', 'Oops! Something went wrong. Please try again.', 'error')
                        this.setState({ paymentStatus: false, processing: false, processingC: false })
                    })
            }
        })
    }

    renderSubmitCheckoutBtn = () => {
        if (this.state.processing) {
            return <input type="button" className="btn btn-primary" value="Processing..." disabled={true} />
        } else {
            return <button type="submit" className="btn btn-primary">Submit &amp; Checkout</button>
        }
    }

    renderCheckoutBtn = () => {
        if (this.state.processingC) {
            return <input type="button" className="btn btn-primary" value="Processing..." disabled={true} />
        } else {
            return <button type="button" className="btn btn-primary" onClick={this.checkOut} disabled={this.state.selectRadio}>Checkout</button>
        }
    }

    cardFormat = (str) => {
        const trailingCharsIntactCount = 4;
        let visblecc = str.slice(-trailingCharsIntactCount);
        return str = 'XXXX XXXX XXXX ' + visblecc;
    }

    moveBack = (e) => {
        this.props.history.goBack();
    }

    render() {
        if (typeof this.props.location.state == 'undefined') {
            return <Redirect to='/independent-mover/dashboard/my-subscription' />
        }

        if (this.state.GMApaymentStatus) {
            return <Redirect to='/independent-mover/dashboard/military-approved' />
        }

        if (this.state.paymentStatus && this.state.paymentType === false) {
            return <Redirect to='/independent-mover/dashboard/my-subscription' />
        }
        else if (this.state.paymentStatus && this.state.paymentType) {
            // return <Redirect to='/independent-mover/dashboard/moverAppz' />
            return <Redirect to={this.state.redirectUrlAfterAppzPayment} />
        }
        else {
            // return <Redirect  to='/independent-mover/dashboard/home'/>
        }

        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="row">
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="panel invoice-grid">
                                        <div className="panel-body">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <h6 className="text-semibold no-margin-top text-uppercase">{this.state.checkout.title}</h6>
                                                </div>
                                                <div className="col-sm-6">
                                                    <h6 className="text-semibold text-right no-margin-top">${this.state.checkout.value}</h6>
                                                </div>
                                                <div className="col-sm-12">
                                                    <ul className="list list-unstyled">
                                                        <li>Users Id #: &nbsp;00{this.state.checkout.user_id}</li>
                                                        <li>Issued on: <span className="text-semibold">{this.state.checkout.issue}</span></li>
                                                        <li><span className="label label-danger">Pending Payment</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-footer panel-footer-condensed"><a className="heading-elements-toggle"><i className="icon-more"></i></a>
                                            <div className="heading-elements">
                                                <span className="heading-text">
                                                    <span className="status-mark border-danger position-left"></span> Expire on:<span className="text-semibold">{this.state.checkout.expire || ""}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                
                            </div> */}
                        </div>
                        <div className="col-md-9">
                            <div className="panel panel-flat">
                                <div className="panel-heading p-b-15 clearfix">
                                    <h3 className="panel-title pull-left">Checkout</h3>
                                    <div className="pull-right">
                                        <button className="btn alpha-danger text-danger" onClick={this.moveBack}><i className="icon-arrow-left8 m-r-5"></i>BACK</button>
                                    </div>
                                </div>
                                <div className="panel-body p-0">
                                    <hr className="m-0" />

                                    {this.props.location.state && this.props.location.state.type !== "moverAppz" ?
                                        <div className="alternate-payment-method m-b-20">
                                            <div className="p-15">
                                                <h5 className="m-t-0 m-b-0 m-b-15 text-semibold text-green">Pay Using Paypal</h5>
                                                <PaypalExpressBtn env={Urls.paypal.env} client={Urls.paypal.client} currency={Urls.paypal.currency} style={{ color: 'blue', size: 'medium', label: 'paypal', tagline: false, shape: 'rect', layout: 'horizontal' }} total={parseInt(this.state.checkout.value)} onError={this.onError} onSuccess={this.onSuccess} onCancel={this.onCancel} />
                                            </div>
                                            <hr className="m-t-10 m-b-0" />
                                        </div>
                                        : null}
                                    <h5 className="p-l-15 p-r-15 text-semibold text-green">Pay Using Saved Card(s)</h5>
                                    <hr className="m-0" />
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Select</th>
                                                    <th>Card Holder Name</th>
                                                    <th width="30"></th>
                                                    <th>Card No</th>
                                                    <th className="col-md-2">Expiration Date</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.cardList.length > 0 ?
                                                    this.state.cardList.map((card, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td className="text-center">
                                                                    <div className="form-group">
                                                                        <div className="radio">
                                                                            <label>
                                                                                <input type="radio" name="card_select" value={card.id} onChange={() => this.ChangeRadioValue(card.id, card.card_name, card.card_no, card.expiry_month, card.expiry_year,'')} />
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td><h6 className="text-semibold">{card.card_name}</h6></td>
                                                                <td><i className="fa fa-credit-card fa-2x text-black"></i></td>
                                                                <td><h6 className="text-semibold">{this.cardFormat(card.card_no)}</h6></td>
                                                                <td><h6 className="text-semibold">{card.expiry_month}/{card.expiry_year}</h6></td>
                                                                <td>{this.renderPrimary(card.primary_card)}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <div className="panel-heading">
                                                        <div className="heading-elements">
                                                        </div>
                                                        <h6 className="text-grey-600 m-b-20">No cards found.</h6>
                                                    </div>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <div className="panel-footer text-center">                                    
                                    {this.renderCheckoutBtn()}                
                                </div> */}
                            </div>
                            <div className="panel panel-flat">
                                <div className="panel-heading p-b-15">
                                    <h5 className="panel-title">Add Card</h5>
                                </div>
                                <form action="#" method="POST" onSubmit={this.handleAddCard}>
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Card Holder Name</label>
                                                    <input className="form-control" type="text" placeholder="Enter Card Holder Name" name="card_holder_name" onChange={this.changeValue} value={this.state.fields.card_holder_name} />
                                                    <p className="text-danger">{this.state.fields_error.card_holder_name_err}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Card No</label>
                                                    <input className="form-control" type="text" placeholder="Enter 16 digit card no" name="card_no" onChange={this.changeFormat} value={this.state.fields.card_no} />
                                                    <p className="text-danger">{this.state.fields_error.card_no_err}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Expiration Date (MM/YYYY)</label>
                                                    <input className="form-control" type="text" placeholder="MM/YYYY" name="expiry_date" onChange={this.changeValue} value={this.state.fields.expiry_date} />
                                                    <p className="text-danger">{this.state.fields_error.expiry_err}</p>
                                                    <div className="checkbox p-t-5">
                                                        <label><input type="checkbox" className="styled" name="addCardChk" onChange={this.changeCardChk} value="1" />Add this to my Saved Cards</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>CVV</label>
                                                    <input className="form-control" type="password" placeholder="Enter CVV" name="cvv_no" onChange={this.changeValue} maxlength="3" />
                                                    <p className="text-danger">{this.state.fields_error.cvv_err}</p>
                                                    <p className="help-block"><i className="icon-info22"></i> CVV : A 3 digit code on the card, you can see it on back side of your card.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            {this.renderSubmitCheckoutBtn()}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Checkout;