import React, { Component } from 'react';
import PostDataWithParam from '../services/PostDataWithParam';
import PostDataFile from '../services/PostDataFile';
import { Redirect, Link } from 'react-router-dom';
import { FormatSSN } from '../externaljs/FormatSSN';
import Breadcumb from '../externaljs/Breadcrumb';

class AddClient extends Component {
    state = {
        fields: {
            organization_name:'',
            company_email:'',
            company_contact_no:'',
            company_fax_no:'',
            DOT_number:'',
            state_license_number:'',
            gender:'',
            first_name:'',
            last_name:'',
            email:'',
            contact_no:'',
            work_contact_no:'',
            address:'',
            latitude:'',
            longitude:'',
            dob:'',
            ssn:'',
            contract_start_date: '',
            contract_end_date: '',
            contract_image: ''
        },
        error: {
            organization_name_err:'',
            company_email_err:'',
            company_contact_no_err:'',
            company_fax_no_err:'',
            DOT_number_err:'',
            state_license_number_err:'',
            gender_err:'',
            first_name_err:'',
            last_name_err:'',
            email_err:'',
            contact_no_err:'',
            work_contact_no_err:'',
            address_err:'',
            latitude_err:'',
            longitude_err:'',
            dob_err:'',
            ssn_err:'',
            contract_start_date_err: '',
            contract_end_date_err: '',
            contract_image_err: ''
        },
        addClientStatus: false,
        ClientErr: '',
        change:false,
        client_id:'',
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Clients', link: '/independent-mover/dashboard/clients', class: '' },
            { title: 'Add Client', link: '', class: 'active' }
        ]
    }

    componentDidMount() {
        window.$('#contract_info').hide();
        window.$('.input-group.date').datepicker({
            autoclose: true
        }).on('changeDate', (e) => {
            let fields = this.state.fields;
            fields.dob = e.format();
            this.setState({ fields: fields });
        });
        window.$('.select').select2({
            minimumResultsForSearch: Infinity
        });
        FormatSSN();
    }

    handleChange = (e) => {
        let isChecked = e.target.checked;
        if(isChecked){
            this.setState({change:true});
            window.$('#contract_info').show();
        }else{
            this.setState({change:false});
            window.$('#contract_info').hide();
        }
      }

    initAutocomplete = (e) => {
        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: ['geocode'] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let fields = this.state.fields;
            fields.latitude = place.geometry.location.lat();
            fields.longitude = place.geometry.location.lng();
            fields.address = window.$('#address')[0].value;
            this.setState({ fields: fields });

        })
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields });
    }

    resetError = (cb) => {
        let error = {
            first_name_err: '',
            last_name_err: '',
            email_err: '',
            contact_no_err: '',
            gender_err: '',
            dob_err: '',
            address_err: '',
            alternate_email_err: ''
        }
        this.setState({ error: error }, () => {
            cb();
        });
    }

    handletContractInfoImage = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.files[0];
        this.setState({ fields: fields });
    }

    handleAddClient = () => {
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        this.resetError(() => {
            let error = this.state.error;
            if (!this.state.fields.organization_name) {

                error.organization_name_err = 'Company Name is required.';
            }
            else if (!this.state.fields.state_license_number) {

                error.state_license_number_err = 'State License Number is required.';
            }
            else if (!this.state.fields.company_email) {

                error.company_email_err = 'Company Email is required.';
            }
            else if (!pattern.test(this.state.fields.company_email) && this.state.fields.company_email) {
                error.company_email_err = 'Please enter valid email.';
            }
            else if (!this.state.fields.company_contact_no) {

                error.company_contact_no_err = "Office Phone Number is required.";
            }
            else if (!this.state.fields.gender) {

                error.gender_err = 'Salutation is required.';
            }
            else if (!this.state.fields.first_name) {

                error.first_name_err = 'First name is required.';
            }
             else if (!this.state.fields.last_name) {
                error.last_name_err = 'Last name is required.';
            }
            else if (!this.state.fields.email) {
                error.email_err = 'Email is required.';
            }
            else if (!pattern.test(this.state.fields.email) && this.state.fields.email) {
                error.email_err = 'Please enter valid email.';
            }
            else if (!this.state.fields.contact_no) {
                error.contact_no_err = "Phone Number is required.";
            }
            else if (isNaN(this.state.fields.contact_no)) {
                error.contact_no_err = 'Please enter a valid phone number.';
            }
            else if (!this.state.fields.address) {
                error.address_err = 'Address is required.';
            }
            else if (document.getElementById('dob').value === '') {
                error.dob_err = 'Date of birth is required.';
            }
            else if (document.getElementById('ssn').value === '') {
                error.ssn_err = 'SSN is required.';
            }
            else if(this.state.change){
                if (document.getElementById('contract_start_date').value === '') {
                    error.contract_start_date_err = 'Start Date is required.';
                }
                else if (document.getElementById('contract_end_date').value === '') {
                    error.contract_end_date_err = 'End Date is required.';
                }
                else if (this.state.fields.contract_image === '') {
                    error.contract_image_err = 'Contract Image is required.';
                }else{
                    let fields = this.state.fields;
                    fields.dob = document.getElementById('dob').value;
                    fields.ssn = document.getElementById('ssn').value;
                    this.setState({ fields: fields });
                    PostDataWithParam('AddClient',this.state.fields)
                    .then( (response) => {
                        let result = response;
                        if(result.success === 1){
                               this.setState({client_id:result.data.client_id}, ()=>{
                                this.handleAddContractInfo(); 
                               });
                        } else {
                            this.setState({ ClientErr : result.msg });
                        }
                    })
                    .catch(err=>{
                        this.setState({ ClientErr: 'Oops! Something went wrong.' });
                    })
                }
            }            
            else {
                let fields = this.state.fields;
                fields.dob = document.getElementById('dob').value;
                fields.ssn = document.getElementById('ssn').value;
                this.setState({ fields: fields });
                PostDataWithParam('AddClient',this.state.fields)
                .then( (response) => {
                    let result = response;
                    if(result.success === 1){
                            this.setState({ ClientErr : result.msg },()=>{
                                this.setState({ addClientStatus: true });   
                            });
                    } else {
                        this.setState({ ClientErr : result.msg });
                    }
                })
                .catch(err=>{
                    this.setState({ ClientErr: 'Oops! Something went wrong.' });
                })
            }
            this.setState({ error: error });
        });
    }

    handleAddContractInfo = () => {
        if(this.state.change){
            let fields = this.state.fields;
            fields.contract_start_date = document.getElementById('contract_start_date').value;
            fields.contract_end_date = document.getElementById('contract_end_date').value;
            this.setState({ fields: fields });
            let fd = new FormData();
            fd.append('contract_start_date', this.state.fields.contract_start_date);
            fd.append('contract_end_date', this.state.fields.contract_end_date);
            fd.append('document', this.state.fields.contract_image, this.state.fields.contract_image.name);
            fd.append('client_id', this.state.client_id);                

            PostDataFile('AddClientContract', fd)
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ ClientErr: "Client Added Successfully" }, () => {
                        this.setState({ addClientStatus: true });
                    });
                } else {
                    this.setState({ ClientErr: response.msg });
                }
            })
            .catch(err => {
                this.setState({ ClientErr: 'Oops! Something went wrong.', addClientStatus: false });
            })
        }
    }


    render() {

        if (this.state.addClientStatus) {
            return <Redirect to={{
                pathname: '/independent-mover/dashboard/clients',
                state: { ClientErr: this.state.ClientErr }
            }} />
        }

        return (
            <div className="row">
                <div className="col-md-12">
                {Breadcumb(this.state.breadcrumb)}
                    <div className="panel panel-flat profile-page">
                        <div className="panel-heading p-b-0">
                            <h5 className="panel-title">Company Information</h5>
                            <span className="text-danger">{this.state.ClientErr}</span>
                        </div>
                        <form action="#">
                            <div className="panel-body">
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Company Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter Company Name" name="organization_name" onChange={this.changeValue}/>
                                            <p className="text-danger help-block">{this.state.error.organization_name_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>State License Number <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter State License Number" name="state_license_number" onChange={this.changeValue}/>
                                            <p className="text-danger help-block">{this.state.error.state_license_number_err}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Email Id <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter Email Id" name="company_email" onChange={this.changeValue} />
                                            <p className="text-danger help-block">{this.state.error.company_email_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Office Phone <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter Office Phone" name="company_contact_no" onChange={this.changeValue}/>
                                            <p className="text-danger help-block">{this.state.error.company_contact_no_err}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Fax Phone Number</label>
                                            <input className="form-control" type="text" placeholder="Enter Fax Phone Number" name="company_fax_no" onChange={this.changeValue}/>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>DOT Number</label>
                                            <input className="form-control" type="text" placeholder="Enter DOT Number" name="DOT_number" onChange={this.changeValue}/>
                                        </div>
                                    </div>

                                </div>

                                <h4 className="m-b-0">Personal Information</h4>
                                <hr className="m-t-5" />
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Salutation<span className="text-danger">*</span></label>
                                            <select className="form-control" name="gender" onChange={this.changeValue}>
                                                <option></option>
                                                <option>Mr.</option>
                                                <option>Ms.</option>
                                                <option>Mrs.</option>
                                            </select>
                                            <p className="text-danger help-block">{this.state.error.gender_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label>First Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter First Name" name="first_name" onChange={this.changeValue}/>
                                            <p className="text-danger help-block">{this.state.error.first_name_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label>Last Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter Last Name" name="last_name" onChange={this.changeValue}/>
                                            <p className="text-danger help-block">{this.state.error.last_name_err}</p>
                                    </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Email Id <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" name="email" onChange={this.changeValue} />
                                            <p className="text-danger help-block">{this.state.error.email_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Phone No <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter Phone No" name="contact_no" onChange={this.changeValue}/>
                                            <p className="text-danger help-block">{this.state.error.contact_no_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Work Phone No</label>
                                            <input className="form-control" type="text" placeholder="Enter Work Phone No" name="work_contact_no" onChange={this.changeValue}/>
                                            <p className="text-danger help-block">{this.state.error.work_contact_no_err}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Home Address <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text"  id="address" onFocus={this.initAutocomplete} name="address" placeholder="Enter Home Address" name="address" onChange={this.changeValue}/>
                                            <p className="text-danger help-block">{this.state.error.address_err}</p>
                                        </div>
                                    </div>


                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Date of Birth <span className="text-danger">*</span></label>
                                            <div className="input-group date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control" placeholder="" id="dob" />
                                            </div>
                                            <p className="text-danger help-block">{this.state.error.dob_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>SSN <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name="format-ssn" id="ssn" data-mask="999-99-9999" placeholder="Enter your social security number" />
                                            <p className="text-danger help-block">{this.state.error.ssn_err}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="checkbox-inline p-l-20 m-r-20">
                                                <input type="checkbox" id="contract_chk" onChange={this.handleChange}/>Do you already have any contract with this client?
                                </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" id="contract_info">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Contract Start Date<span className="text-danger">*</span></label>
                                            <div className="input-group date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control" id="contract_start_date" name="contract_start_date" />
                                            </div>
                                            <p className="text-danger help-block">{this.state.error.contract_start_date_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Contract End Date<span className="text-danger">*</span></label>
                                            <div className="input-group date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control" id="contract_end_date" name="contract_end_date" />
                                            </div>
                                            <p className="text-danger help-block">{this.state.error.contract_end_date_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Upload Contract Document<span className="text-danger">*</span></label>
                                            <input type="file" name="contract_image" onChange={this.handletContractInfoImage} />
                                        </div>
                                        <p className="text-danger help-block">{this.state.error.contract_image_err}</p>
                                    </div>
                                </div>
                            </div>


                            <div className="panel-footer text-right p-r-20">
                                <button type="button" className="btn btn-primary" onClick={this.handleAddClient}>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}


export default AddClient;