import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import PostDataWithParam from '../services/PostDataWithParam';
import MilitaryConversations from './MilitaryConversations';
import { Prompt } from 'react-router';

class Considerations extends Component {
    state = {
        fields: {
            business_multi_tenant: '',
            vehicle_maintenance: -1,
            english_speaking: -1,
            normal_business_hours: '',
            business_hours: -1,
            arrive_for_inspection: -1,
            tab_id: 12,
            id: ''
        },
        days: [
            {
                day: 'Monday',
                start: '',
                end: '',
                checked: false
            },
            {
                day: 'Tuesday',
                start: '',
                end: '',
                checked: false
            },
            {
                day: 'Wednesday',
                start: '',
                end: '',
                checked: false
            },
            {
                day: 'Thrusday',
                start: '',
                end: '',
                checked: false
            },
            {
                day: 'Friday',
                start: '',
                end: '',
                checked: false
            },
            {
                day: 'Saturday',
                start: '',
                end: '',
                checked: false
            },
            {
                day: 'Sunday',
                start: '',
                end: '',
                checked: false
            }
        ],
        saveStatus: false,
        proccesing:false,
        formDirty:false
    }

    componentDidMount() {

        this.initTimePicker('start');
        this.initTimePicker('end');

        window.scrollTo(0, 0);
        PostDataWithParam('GetMilitaryApprovalOtherConsideration', {})
        .then((response) => {
            if (response.success == 1) {
                let days = JSON.parse(response.data.days);
                let response_data = { ...this.state.fields, ...response.data };
                this.setState({ fields: response_data,days:days });
            }
        })
    }

    initTimePicker = (name) => {
        let obj = this;

        obj.state.days.map((item,i)=>{
            if (window.$('#'+name+'_'+item.day).length > 0) {
                window.$('#'+name+'_'+item.day).pickatime({
                    formatSubmit: 'HH:i',
                    hiddenName: true,
                    onSet: function (context) {
                        let days = obj.state.days;
                        days[i][name] = window.$('#'+name+'_'+item.day).val();
                        obj.setState({ days: days })
                    }
                });
            }
        })
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields,formDirty:true});
    }

    changeDaysValue = (e, index, name) => {

        let days = this.state.days;        
        days[index][name] = e.target.checked;
        if(!e.target.checked){
            days[index]['start'] = '';
            days[index]['end'] = '';
        }
        this.setState({ days: days,formDirty:true });
    }

    SaveMilitaryApprovalOtherConsideration = () => {
        this.setState({proccesing:true })
        let fields = { ...this.state.fields, days: JSON.stringify(this.state.days)};
        PostDataWithParam('SaveMilitaryApprovalOtherConsideration', fields)
        .then((response) => {
            if (response.success === 1) {
                window.swal('Success!', response.msg, 'success')
                this.setState({ saveStatus: true,proccesing:false ,formDirty:false}, () => {
                    this.props.onUpdateApprovalProgress();
                });
            } else {
                this.setState({proccesing:false,formDirty:false })
                window.swal('Error!', response.msg, 'error')
            }
        })
    }

    openModal = (field_name, tab_id) => {
        this.props.onOpenModal(field_name, tab_id)
    }

    renderAlertMsg = (field_name,tab_id) => {
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-success text-right m-t-5">Answer Received</p>
                        </React.Fragment>
                    )
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-warning text-right m-t-5">Waiting for reply</p>
                        </React.Fragment>
                    )
                }
            }
        }
    }

    renderClassName = (field_name,tab_id) => {
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return "btn-success "
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return "btn-warning "
                }
            }
        }


    }

    render() {
        if (this.state.saveStatus) {
            return <Redirect to='/independent-mover/dashboard/military-approved/information-tab/financial' />
        }

        return (
            <div className="tab-pane" id="top-icon-tab12">
                 <Prompt
                when={this.state.formDirty}
                message="Please save your data before you leave the page. Otherwise, you will lose the information you entered !"
                />
                <div className="panel panel-flat">
                    <div className="panel-heading">
                        <h5 className="panel-title">Other Considerations</h5>
                    </div>
                    <div className="panel-body">
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Is your building solely used by your business or is this a multi tenant building? Please explain.</h5>
                            <div className="row">
                                <div className="col-md-12">
                                    <textarea className="form-control" name="business_multi_tenant" onChange={this.changeValue} value={this.state.fields.business_multi_tenant || ""}></textarea>
                                </div>
                                <div className="col-md-12">
                                    <a href="javascript:void(0)" onClick={() => this.openModal('business_multi_tenant', this.state.fields.tab_id)} className={this.renderClassName('business_multi_tenant',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('business_multi_tenant',this.state.fields.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Do you maintenance your vehicles inside your location?</h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="vehicle_maintenance" onChange={this.changeValue} checked={(this.state.fields.vehicle_maintenance == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="vehicle_maintenance" onChange={this.changeValue} checked={(this.state.fields.vehicle_maintenance == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={() => this.openModal('vehicle_maintenance', this.state.fields.tab_id)} className={this.renderClassName('vehicle_maintenance',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('vehicle_maintenance',this.state.fields.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Is there always an English speaking staff member on duty during regular business hours?</h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="english_speaking" onChange={this.changeValue} checked={(this.state.fields.english_speaking == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="english_speaking" onChange={this.changeValue} checked={(this.state.fields.english_speaking == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={() => this.openModal('english_speaking', this.state.fields.tab_id)} className={this.renderClassName('english_speaking',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('english_speaking',this.state.fields.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">What are your normal business hours?</h5>
                        
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        this.state.days.map((dayObj, index) => {
                                            return (
                                                <div className="row" data-rowid={dayObj.day} key={index}>
                                                    <div className="col-md-2 col-sm-2 col-xs-2 text-left">
                                                        <p className="m-t-5"><label className="m-t-5 checkbox-inline custom-checkbox check-success"> 
                                                        <input type="checkbox" value="1" id={"checkbox_"+dayObj.day} checked={(dayObj.checked===true || dayObj.checked==1)?true:false} onChange={(e) => this.changeDaysValue(e, index, 'checked')}/><span></span> {dayObj.day}</label></p>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                                        <div className="form-group">
                                                            <div className="input-group">
                                                                <span className="input-group-addon">
                                                                    <i className="icon-alarm"></i>
                                                                </span>
                                                                <input type="text" className="form-control pickatime-hidden" placeholder="HH:MM" id={"start_"+dayObj.day} value={dayObj.start} onChange={(e) => this.changeValue(e)} disabled={(dayObj.checked===true || dayObj.checked==1)?false:true}/>
                                                                <span className="input-group-addon">To</span>
                                                                <input type="text" className="form-control pickatime-hidden" placeholder="HH:MM" id={"end_"+dayObj.day} value={dayObj.end} onChange={(e) => this.changeValue(e)} disabled={(dayObj.checked===true || dayObj.checked==1)?false:true}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="col-md-12">
                                    <a href="javascript:void(0)" onClick={() => this.openModal('normal_business_hours', this.state.fields.tab_id)} className={this.renderClassName('normal_business_hours',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('normal_business_hours',this.state.fields.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Is warehouse manned during normal business hours?</h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="business_hours" onChange={this.changeValue} checked={(this.state.fields.business_hours == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="business_hours" onChange={this.changeValue} checked={(this.state.fields.business_hours == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={() => this.openModal('business_hours', this.state.fields.tab_id)} className={this.renderClassName('business_hours',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('business_hours',this.state.fields.tab_id)} 
                                </div>
                            </div>
                            <h5 className="m-t-0">If not, are they able to arrive within 15 minutes for inspection by military personnel.</h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="arrive_for_inspection" onChange={this.changeValue} checked={(this.state.fields.arrive_for_inspection == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="arrive_for_inspection" onChange={this.changeValue} checked={(this.state.fields.arrive_for_inspection == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={() => this.openModal('arrive_for_inspection', this.state.fields.tab_id)} className={this.renderClassName('arrive_for_inspection',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('arrive_for_inspection',this.state.fields.tab_id)} 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <MilitaryConversations />
                <div className="text-center">{this.state.proccesing? <button className="btn btn-primary" disabled={true}>Processing...</button>:<button className="btn btn-primary" onClick={this.SaveMilitaryApprovalOtherConsideration}>Save</button>}</div>
            </div>
        );
    }
}

export default Considerations;