import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import UserOrderChatList from './UserOrderChatList';
import UserChatList from './UserChatList';

export default class ChatNotifications extends Component {
    state = {
    }

    render() {
        const { openChatNotifications, closeChatNotifications, isOpenChatNotifications, unreadMsgUserCount, openComposeChatBox, activeChatListTab, setChatListTab, isLoading } = this.props;
        return (<Fragment>
            {(isOpenChatNotifications === false) &&
                <button className="open-chat-button clearfix" onClick={openChatNotifications}>
                    <p className="m-0"><i className="icon-bubble-dots3"></i></p>
                    <a href="javascript:void();" className="badge badge-warning">{unreadMsgUserCount.total > 0 && unreadMsgUserCount.total}</a>
                </button>
            }
            <div className="chat-contact-list" id="chat-contact-list">
                <Fragment>
                    <div className="chat-contact-list-header">
                        <a href="javascript:void(0)" onClick={closeChatNotifications}><i className="icon-cross"></i></a>
                    </div>

                    {activeChatListTab === 0
                        ? <UserOrderChatList  {...this.props} />
                        : <UserChatList {...this.props} />
                    }
                    {activeChatListTab === 0 && !isLoading ? <Link to="#" className="chat-plus-icon" onClick={openComposeChatBox}><i className="icon-plus2 f-s-24"></i></Link> : null
                    }
                </Fragment>
            </div>

        </Fragment>
        )
    }
}
