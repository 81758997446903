import React, { Component } from 'react';
import { FormGroupMaterial } from '../../externaljs/FormGroupMaterial';
import PostDataWithParam from '../../services/PostDataWithParam';
import { Link, Redirect } from 'react-router-dom';

class ResendActivation extends Component {

    state = {
        resendEmailField: {
            email: '',
            support_email: 'help@myrelo.us',
            url: 'ReloDriver.com',
            profile_id: 2,
            is_independent_mover:1
        },
        regErr: '',
        is_mover_appz: 0
    }
    componentDidMount() {
        FormGroupMaterial();
        let queryParams = new URLSearchParams(this.props.location.search);
        if (!!queryParams) {
            let is_mover_appz = queryParams.get("is_mover_appz") || 0;
            this.setState({ is_mover_appz: +is_mover_appz })
        }

    }
    changeEmailValue = (e) => {
        let resendEmailField = this.state.resendEmailField;
        resendEmailField[e.target.name] = e.target.value;
        this.setState({ resendEmailField: resendEmailField });
    }
    renderSubmitBtn = () => {
        if (this.state.processing) {
            // return <Link to="#" className="btn btn-primary pull-left" onClick={() => this.otpModal()}>Submit</Link>
            return <input type="button" className="btn btn-primary btn-block" value="Sending..." disabled={true} />;
        } else {
            // return <Link to="#" className="btn btn-primary pull-left" onClick={() => this.otpModal()}>Submit</Link>
            return <button type="submit" className="btn btn-primary btn-block">Submit</button>;
        }
    }
    showAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-danger no-border"><button type="button" className="close" data-dismiss="alert"><span>×</span><span className="sr-only">Close</span></button>
                    {this.state.regErr}
                </div>
            )
        }
    }
    handleResendEmail = (e) => {
        e.preventDefault();
        this.setState({ processing: true }, () => {
            PostDataWithParam('ResendActivationEmail', this.state.resendEmailField)
                .then((response) => {
                    if (response.success == 1) {
                        this.setState({ regErr: response.msg, regStatus: true });
                        this.props.history.push({
                            pathname: '/activation-message/resend-mail',
                            state: { regMsg: this.state.regErr },
                            search: this.props.location.search
                        });
                    } else {
                        this.setState({ regErr: response.msg, processing: false, regStatus: false });
                    }
                    this.setState({ processing: false });
                })
                .catch(err => {
                    this.setState({ regErr: 'Oops! Something went wrong.', processing: false, regStatus: false });
                })
        })
    }
    render() {
        return (
            <section className={this.state.is_mover_appz === 1 ? "login-page-register moverappz-login register-page" : "login-page-register register-page"}>
                <div className="login-register-box">
                    <div className="text-center login-box-header common-unit">
                        <div className="m-b-30">
                            <Link to="/home">
                                   <img src="front-assets/images/logo.png" alt="site-logo" width="400"/>
                            </Link>
                        </div>
                        <h3>Resend Activation Email</h3>
                    </div>
                    <form action="#" method="POST" onSubmit={this.handleResendEmail}>
                        <div className="login-box-body m-t-30">
                            {this.showAlert(this.state.regErr)}
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group form-group-material">
                                        <label className="control-label">Email Address</label>
                                        <input type="text" name="email" value={this.state.resendEmailField.email} className="form-control" placeholder="Enter your email address or mobile no." onChange={this.changeEmailValue} required />
                                        <p className="text-danger help-block"></p>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center">
                                    <div className="form-group m-t-40 clearfix text-sm-center text-center">
                                        {this.renderSubmitBtn()}
                                        {/* <button type="submit" className="btn btn-primary btn-block">Submit</button> */}
                                        <Link to="/login" className="btn btn-default btn-block m-t-20">Back</Link>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </section>
        )
    }
}
export default ResendActivation;