import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import GetData from '../services/GetData';
import PostDataWithParam from '../services/PostDataWithParam';
import { FormatSSN } from '../externaljs/FormatSSN';
import PostDataFile from '../services/PostDataFile';
import Breadcumb from '../externaljs/Breadcrumb';
import ReactCrop from "react-image-crop";
import Storage from '../externaljs/Storage';
import "react-image-crop/dist/ReactCrop.css";
import { logout } from '../externaljs/authHelpers';

class EditMyProfile extends Component {
    state = {
        fields: {
            first_name: '',
            last_name: '',
            email: '',
            contact_no: '',
            alternate_contact_no: '',
            salutation: '',
            dob: '',
            social_security_number: '',
            dept_id: [],
            address: '',
            latitude: '',
            longitude: '',
            staff_id: '',
            phone_no: '',
            work_contact_no: '',
            home_contact_no: '',
            alternate_email: ''
        },
        error: {
            first_name_err: '',
            last_name_err: '',
            email_err: '',
            contact_no_err: '',
            alternate_contact_no_err: '',
            salutation_err: '',
            dob_err: '',
            ssn_err: '',
            dept_id_err: '',
            address_err: '',
            alternate_email_err: '',
            work_contact_no_err: '',
            home_contact_no_err: '',
        },
        changeUpdateStatus: false,
        UpdateErr: '',
        staff_detail: [],
        departments: [],
        dept: [],
        ssnClick: false,
        viewSsn: true,
        selectedFile: null,
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'My User Profile', link: '/independent-mover/dashboard/myprofile', class: '' },
            { title: 'Edit My User Profile', link: '', class: 'active' }
        ],
        proccesing: false,
        src: null,
        crop: {
            height: 250,
            width: 250,
            x: 0,
            y: 0
        },
        croppedImageUrl: '',
        otp: "",
        otpProccesing: false

    }

    componentDidMount() {
        window.$('.input-group.date').daterangepicker({
            showDropdowns: true,
            autoclose: true,
            singleDatePicker: true
        }).on('apply.daterangepicker', (ev, picker) => {
            let fields = this.state.fields;
            fields.dob = picker.startDate.format('MM/DD/YYYY');
            this.setState({ fields: fields });
        });
        window.$('.select').select2({
            minimumResultsForSearch: Infinity
        });

        this.GetUserInfo();
    }

    Ssnformat = (social_security_number) => {
        if (typeof social_security_number !== 'undefined' && social_security_number != null) {
            let ssn_new = social_security_number.replace(/^.{6}/g, '***-**')
            return ssn_new
        } else {
            return '';
        }
    }

    openTextbox = () => {
        this.setState({ viewSsn: false, ssnClick: true }, () => {
            FormatSSN()
        });
    }

    GetUserInfo = () => {
        PostDataWithParam('GetUserInfo', { staff_user_id: (JSON.parse(Storage.get('imnLoginData'))).staff_user_id })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ fields: response.data });
                }
            })
    }
    phoneformat = (phval) => {
        var cleaned = ('' + phval).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        var ph = '';
        if (match) {
            return match[1] + ' ' + match[2] + ' ' + match[3];
        }
        else
            return phval;

    }

    openTextboxPh = () => {
        this.setState({ viewSsn: false, ssnClick: true }, () => {
            FormatSSN()
        });
    }

    initAutocomplete = (e) => {
        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: ['geocode'] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let fields = this.state.fields;
            fields.latitude = place.geometry.location.lat();
            fields.longitude = place.geometry.location.lng();
            fields.address = window.$('#address')[0].value;
            this.setState({ fields: fields });
        })
    }

    changeValue = (e) => {
        let fields = this.state.fields;

        if (e.target.name == 'work_contact_no' || e.target.name == 'contact_no' || e.target.name == 'home_contact_no') {
            fields[e.target.name] = this.phoneformat(e.target.value)
        }
        else
            fields[e.target.name] = e.target.value;
        this.setState({ fields: fields });
    }

    resetError = (cb) => {
        let error = {
            first_name_err: '',
            last_name_err: '',
            email_err: '',
            contact_no_err: '',
            alternate_contact_no_err: '',
            salutation_err: '',
            dob_err: '',
            ssn_err: '',
            dept_id_err: '',
            address_err: '',
            alternate_email_err: ''
        }
        this.setState({ error: error }, () => {
            cb();
        });
    }

    getCheckedModule = (status) => {
        if (status == 1)
            return (
                <label className="checkbox-inline checkbox-switch switch-danger">
                    <input type="checkbox" checked name="social_media_status" id="social_media_status" onChange={this.changeValue} /><span></span>
                </label>
            )
        else
            return (
                <label className="checkbox-inline checkbox-switch switch-danger">
                    <input type="checkbox" name="social_media_status" id="social_media_status" onChange={this.changeValue} /><span></span>
                </label>
            )

    }

    handleFileSelect = (e) => {
        this.setState({ selectedFile: e.target.files[0] });
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            window.$("#imageModal").modal('show')
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onImageLoaded = (image, crop) => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = crop => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            let dataURL = canvas.toDataURL("image/jpeg");
            resolve(dataURL);
        });
    }

    handleUpdateProfilePicture = () => {
        if (this.state.croppedImageUrl != '') {
            this.setState({ proccesing: true }, () => {
                PostDataWithParam('UpdateCroppedProfilePictureForMovers', { 'profile_image': this.state.croppedImageUrl })
                    .then((response) => {
                        if (response.success === 1) {
                            window.$("#imageModal").modal('hide')
                            this.setState({
                                UpdateErr: "Your profile has been updated successfully.",
                                changeUpdateStatus: true, proccesing: false
                            });
                        } else {
                            this.setState({ UpdateErr: response.msg, changeUpdateStatus: false, proccesing: false });
                        }
                    })
                    .catch((err) => {
                        this.setState({ UpdateErr: 'Something went wrong!', changeUpdateStatus: false, proccesing: false });
                    })
            })
        }
    }


    handleUpdateProfile = () => {
        this.resetError(() => {
            let error = this.state.error;

            if (!this.state.fields.first_name) {

                error.first_name_err = 'First name is required.';
            } else if (!this.state.fields.last_name) {

                error.last_name_err = 'Last name is required.';
            } else if (!this.state.fields.email) {

                error.email_err = 'Email is required.';
            }

            else if (!this.state.fields.contact_no) {

                error.contact_no_err = 'Please enter phone number.';
            }
            else {
                this.setState({ proccesing: true });
                let fields = this.state.fields;
                fields.dob = document.getElementById('dob').value;
                fields.staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
                this.setState({ fields: fields });
                PostDataWithParam('UpdateDetails', this.state.fields)
                    .then((response) => {
                        let result = response;
                        if (response.success === 1) {
                            if (response.otp_pop_up_status) {
                                window.$("#otpModal").modal("show")
                            } else {
                                this.setState({ UpdateErr: result.msg, changeUpdateStatus: true, proccesing: false });
                            }
                        } else {
                            window.swal('Error!', response.msg, 'error')
                            this.setState({ UpdateErr: result.msg, changeUpdateStatus: false, proccesing: false });
                        }
                    })
                    .catch((err) => {
                        this.setState({ UpdateErr: 'Something went wrong!', changeUpdateStatus: false, proccesing: false });
                    })
            }
            this.setState({ error: error });
        });

    }
    onOTPSubmit = (e) => {
        e.preventDefault();
        const { otp } = this.state;
        if (otp != '') {
            this.setState({ otpProccesing: true }, () => {
                PostDataWithParam('VerifyOtpForUpdateEmail', { otp })
                    .then((response) => {
                        if (response.success === 1) {
                            window.swal('Success!', response.msg, 'success');
                            window.$("#otpModal").modal('hide');
                            setTimeout(logout, 2000);
                            this.setState({ otpProccesing: false });
                        } else {
                            window.swal('Error!', response.msg, 'error');
                            this.setState({ otpProccesing: false });
                        }
                    })
                    .catch((err) => {
                        window.swal('Error!', 'Something went wrong.', 'error')
                        this.setState({ otpProccesing: false });
                    })
            })
        }
    }
    render() {

        if (this.state.changeUpdateStatus) {
            return <Redirect to={{
                pathname: '/independent-mover/dashboard/myprofile',
                state: { UpdateErr: this.state.UpdateErr }
            }} />
        }

        return (
            <div className="content-container">
                <div className="row">
                    <div className="col-md-12">
                        {Breadcumb(this.state.breadcrumb)}
                        <div className="panel panel-flat profile-page">
                            <div className="panel-heading p-b-0">
                                <h5 className="panel-title">Edit My Profile</h5>
                            </div>
                            <form action="#">
                                <div className="panel-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Salutation </label>
                                                <select className="form-control" name="salutation" onChange={this.changeValue} value={this.state.fields.salutation}>
                                                    <option value="">Select Salutation...</option>
                                                    <option value="Mr.">Mr.</option>
                                                    <option value="Ms.">Ms.</option>
                                                    <option value="Mrs.">Mrs.</option>
                                                </select>
                                                <p className="text-danger help-block">{this.state.error.salutation_err}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>First Name</label>
                                                <input className="form-control" type="text" placeholder="Enter First Name" name="first_name" value={this.state.fields.first_name} onChange={this.changeValue} />
                                                <p className="text-danger help-block">{this.state.error.first_name_err}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Last Name</label>
                                                <input className="form-control" type="text" placeholder="Enter Last Name" name="last_name" value={this.state.fields.last_name} onChange={this.changeValue} />
                                                <p className="text-danger help-block">{this.state.error.last_name_err}</p>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Primary Work Email Address</label>
                                                <input className="form-control" type="email" placeholder="Enter Primary Work Email Address"  name="email" value={this.state.fields.email} onChange={this.changeValue} />
                                                <p className="text-danger help-block">{this.state.error.email_err}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Recovery Email Address</label>
                                            <input className="form-control" type="email" placeholder="Enter Recovery Email Address" name="alternate_email" value={this.state.fields.alternate_email || ''} onChange={this.changeValue} />
                                            <p className="text-danger help-block">{this.state.error.alternate_email_err}</p>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Mobile Phone Number</label>
                                                <input className="form-control" type="text" placeholder="XXX XXX XXXX" name="contact_no" value={this.state.fields.contact_no} onChange={this.changeValue} maxLength="12" />
                                                <p className="text-danger help-block">{this.state.error.contact_no_err}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Work Phone Number</label>
                                                <input className="form-control" type="text" placeholder="XXX XXX XXXX" name="work_contact_no" value={this.state.fields.work_contact_no || ''} onChange={this.changeValue} maxLength="12" />
                                                <p className="text-danger help-block">{this.state.error.work_contact_no_err}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Home Phone Number</label>
                                                <input type="text" className="form-control" placeholder="XXX XXX XXXX" name="home_contact_no" value={this.state.fields.home_contact_no || ''} onChange={this.changeValue} maxLength="12" />
                                                <p className="text-danger help-block">{this.state.error.home_contact_no_err}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Title</label>
                                                <input type="text" className="form-control" placeholder="Enter Title" name="title" value={this.state.fields.title || ''} onChange={this.changeValue} />

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Home Address</label>
                                                <input className="form-control" placeholder="Enter Home Address" type="text" id="address" onFocus={this.initAutocomplete} name="address" value={this.state.fields.address || ''} onChange={this.changeValue} />
                                                <p className="text-danger help-block">{this.state.error.address_err}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Date of Birth</label>
                                                <div className="input-group date">
                                                    <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                    <input type="text" className="form-control" placeholder="" name="dob" id="dob" value={this.state.fields.dob || ''} onChange={this.changeValue} />
                                                    <p className="text-danger help-block">{this.state.error.dob_err}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Social Security Number </label>
                                                {
                                                    this.state.viewSsn
                                                    &&
                                                    <input type="text" className="form-control" name="state-ssn" required={true} defaultValue={this.Ssnformat(this.state.fields.social_security_number)} onClick={this.openTextbox} />
                                                }
                                                {
                                                    this.state.ssnClick
                                                    &&
                                                    <input type="text" className="form-control ssn" name="social_security_number" onChange={this.changeValue} maxLength="11" placeholder="Enter your social security number" />
                                                }
                                                <p className="text-danger help-block">{this.state.error.ssn_err}</p>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="row">
                                    </div>
                                </div>
                                <div className="panel-footer text-right p-r-20">
                                    {this.state.proccesing ? <button type="Button" className="btn btn-primary" onClick={this.handleUpdateProfile} disabled={true}>Proccesing...</button> : <button type="Button" className="btn btn-primary" onClick={this.handleUpdateProfile}>Update</button>}

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="imageModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title" id="myModalLabel">Profile Picture</h4>
                            </div>
                            <div className="modal-body">
                                {this.state.src && (
                                    <ReactCrop
                                        src={this.state.src}
                                        crop={this.state.crop}
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}
                                    />
                                )}
                            </div>
                            <div className="modal-footer">
                                {
                                    this.state.proccesing ?
                                        <button type="button" className="btn btn-primary" disabled={true}>Proccesing...</button> :
                                        <button type="button" className="btn btn-primary" onClick={this.handleUpdateProfilePicture}>Uplaod</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Start OTP Modal --> */}
                <div id="otpModal" className="modal fade" role="dialog" aria-labelledby="otpModalLabel" data-backdrop="static" data-keyboard="false">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Enter OTP</h4>
                            </div>
                            <hr className="m-t-0" />
                            <form onSubmit={this.onOTPSubmit}>
                                <div className="modal-body p-t-0">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="text-semibold">OTP <span className="text-danger">*</span></label>
                                                <input type="text" name="f_name" value={this.state.otp} onChange={e => this.setState({ otp: e.target.value })} className="form-control" placeholder="OTP" required maxLength />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <hr className="m-t-0" />
                                <div className="modal-footer p-t-0">
                                    {this.state.otpProcessing ?
                                        <React.Fragment>
                                            <button type="button" className="btn btn-primary" disabled>Processing...</button>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <button className="btn btn-primary">Submit</button>
                                        </React.Fragment>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <!-- End Otp Modal --> */}
            </div>
        );
    }
}


export default EditMyProfile;