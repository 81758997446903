import React, { Component } from 'react';
import { Route, Redirect, Link } from 'react-router-dom';
import { Application } from '../externaljs/Application';

import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
// Agora Repo
import RTCClient from '../agora/RTCClient';
// end

import Urls from '../externaljs/Urls';
import Storage from '../externaljs/Storage';
import DateFormat from '../externaljs/DateFormat';
import PostDataWithParam from '../services/PostDataWithParam';
import GetData from '../services/GetData';

import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import Sidebar from './Sidebar';

import MyProfile from './MyProfile';
import EditMyProfile from './EditMyProfile';
import CompanyProfile from './CompanyProfile';
import EditCompanyProfile from './EditCompanyProfile';
import ChangePassword from './ChangePassword';
import Driver from './Driver';
import AddDriver from './AddDriver';
import EditDriver from './EditDriver';
import ViewDriver from './ViewDriver';
import Helper from './Helper';
import AddHelper from './AddHelper';
import EditHelper from './EditHelper';
import ViewHelper from './ViewHelper';
import Customer from './Customer';
import AddCustomer from './AddCustomer';
import ViewCustomer from './ViewCustomer';
import EditCustomer from './EditCustomer';
import Client from './Client';
import ViewClient from './ViewClient';
import AddClient from './AddClient';
import EditClient from './EditClient';
import SubscriptionPayment from './SubscriptionPayment';
import ComposeMessage from './ComposeMessage';
import Inbox from './Inbox';
import SentMessage from './SentMessage';
import InboxDetails from './InboxDetails';
import SentMessageDetails from './SentMessageDetails';
import AccessControl from './AccessControl';
import Staff from './Staff';
import AddStaffNew from './AddStaffNew';
import EditStaffNew from './EditStaffNew';
import ViewStaff from './ViewStaff';
import FindService from './FindService';
import QuoteDetails from './QuoteDetails';
import FindMovers from './FindMovers';
import FindDrivers from './FindDrivers';
import FindHelpers from './FindHelpers';
import FindThirdParty from './FindThirdParty';
import FindClaimRepair from './FindClaimRepair';
import MoversApplications from './MoversApplications';
import AppplicationDetails from './AppplicationDetails';
import ComposeIndividual from './ComposeIndividual';
import SendApplication from './SendApplication';
import MySubscription from './MySubscription';
import GetMilitaryApproved from './GetMilitaryApproved';
import CardList from './CardList';
import Checkout from './Checkout';
import AssignOfferJob from './AssignOfferJob';
import UnassignedOffers from './UnassignedOffers';
import UnassignedOfferDetails from './UnassignedOfferDetails';
import OutsourceProject from './OutsourceProject';
import ManageOrders from './ManageOrders';
import OrderDetails from './OrderDetails';
import EdcOrderDetails from './EdcOrderDetails';
import FindAnOffer from './FindAnOffer';
import AssignedOrder from './AssignedOrder';
import MyOfferDetails from './MyOfferDetails';
import OtherStaff from './OtherStaff';
import AddStaff from './AddStaff';
import StaffDetails from './StaffDetails';
import EditStaff from './EditStaff';
import CreateJob from './CreateJob';
import Notification from './Notification';
import ViewMoversProfile from './ViewMoversProfile';
import EditOrderDetails from './EditOrderDetails';
import ClosedOrder from './ClosedOrder';
import ClosedOrderDetails from './ClosedOrderDetails';
import ManageClaims from './ManageClaims';
import ManageClaimDetail from './ManageClaimDetail';
import ManageQuestion from './ManageQuestion';
import CustomerServeys from './CustomerServeys';
import CssDashboard from './CssDashboard';
import ManageCustomer from './ManageCustomer';
import ViewCustomerOrders from './ViewCustomerOrders';
import InventoryDetails from './InventoryDetails';
import Inventory from './Inventory';
import ManageProfile from './ManageProfile';
import AddTeamMember from './AddTeamMember';
import TeamMemberDetails from './TeamMemberDetails';
import ViewTeamMember from './ViewTeamMember';
import Packer from './Packer';
// import Brand from './Brand';

import ChatBox from './ChatBox';
import ChatNotifications from './ChatNotifications';
import MySchedule from './MySchedule';
import MoverAppz from './MoverAppz';
import ComposeChatBox from './ComposeChatBox';
import TrainingMaterial from './TrainingMaterial';
import AddTrainingCategory from './AddTrainingCategory';
import MoverAppzSubscription from './MoverAppzSubscription';
import { getQueryStringToObject } from '../externaljs/UrlHelpers';
import UserChatBox from './UserChatBox';

const database = Urls.firebase.database();
const RTC = new RTCClient();
class DashboardLayout extends Component {

    state = {
        users: [],
        userList: [],
        userMessages: [],

        // chat state
        chatBoxes: [],
        chatBoxArray: [],
        totalUsers: 0,
        logoutStatus: false,
        chatBoxObj: null,
        isOpenChatNotifications: false,
        unreadMsgUserCount: {
            total: 0,
            orderTabCount: 0,
            userTabCount: 0
        },
        unreadMsgUsers: [],
        usersOfActiveOrders: [],
        chatUserList: [],
        selectUnreadUserCount: 0,
        // chat state end

        // video chatF
        openVideoChat: false,
        devices: [],
        isLoadingToJoinVideoChat: false,
        // video chat end

        custom_profile_id: '',
        // custom_menu:[],
        isLoadCustomMenu: false,

        //compose chat
        isOpenComposeChat: false,
        activeChatListTab: 0,
        usersOfSingleChat: [],
        unreadMsgUsersOfSingleChat: [],
        currentSearchSessionIdForSingleChat: 0,
        isLoadingUserChatList: false
    }
    // firebase chat ref
    senderChatRef = "";
    reciverChatRef = "";
    // --------------------

    customProfileMenuCheckInterval = null;
    /*
        * when current url has `redirectPath` as a query params
        * then convert query params to object and psuh to the redirect path
        *  this is basically use for mail redirection link
        */
    redirectMiddleware = () => {
        if (this.props.location) {
            const { search, pathname } = this.props.location;
            let queryParams = getQueryStringToObject(search);
            console.log(queryParams)
            if (!!queryParams.redirectPath) {
                if (queryParams.redirectPath.includes("/compose-message")) {
                    this.props.history.push({
                        pathname: queryParams.redirectPath,
                        search
                    })

                } else if (queryParams.redirectPath.includes("/messages/inbox-details")) {
                    setTimeout(() => {
                        this.props.history.push(queryParams.redirectPath)
                    }, 0);
                  

                }
                else {
                    this.props.history.push({
                        pathname: queryParams.redirectPath,
                        state: queryParams
                    })
                }
                return true;

            }
            return false;
        }
    }
    async componentDidMount() {
        let isRedirect = this.redirectMiddleware();
        if (!isRedirect) {
            if (this.getLoginUserData().is_mover_appz === "1") {
                this.getMoverAppzSubDetails();
            } else if (this.getLoginUserData().first_login <= 1) {
                this.props.history.push("/independent-mover/dashboard/military-approved/profile-tab")
            }
        }


        Application();

        /* Start Chat */
        window.$(window).resize(() => {
            this.arrangeChatWindows();
        });
        await this.getUsersOfActiveOrders();
        this.onUnreadMsgListener();
        this.onUnreadMsgListenerOfSingleChat();
        // chat info delete from `active_users` of fb node
        this.handlePresenceOfUser();
        /* End Chat */

        this.customProfileMenuCheckInterval = setInterval(async () => {
            await this.getCustomProfileMenu();
        }, 7000);

        // setInterval(async () => {
        //     await this.GetSubscriptionDetails();
        // }, 10000);

        // this.GetSubscriptionDetails();

        // PostDataWithParam('GetCompanyInfo', {})
        //     .then((response) => {
        //         if (response.success === 1) {
        //             let loginData = JSON.parse(Storage.get('imnLoginData'));
        //             Storage.set('imnLoginData', JSON.stringify({...loginData, organization_name: response.data[0].organization_name, logo: response.data[0].logo }))

        //         } else {

        //         }
        //     })
        //     .catch(err => {

        //     })


    }

    componentWillUnmount() {
        if (this.customProfileMenuCheckInterval) {
            clearInterval(this.customProfileMenuCheckInterval)
        }
    }

    //-------------------------- Mover Appz --------------------------------
    getMoverAppzSubDetails = () => {
        PostDataWithParam('MoverAppzSubscriptionDetails', { user_id: this.getLoginUserData().id })
            .then((response) => {
                if (response.success === 1) {
                    if (response.data.is_moverappz_payment_count === 0) {
                        this.props.history.push("/independent-mover/dashboard/moverAppz-subscription");
                    }
                } else {
                }
            })
            .catch(err => {
            })

    }
    // -------------------------- Mover Appz End --------------------------------

    // ------------------- subscription start ------------------------------

    checkFirstLogin = () => {
        if (this.getLoginUserData().first_login <= 1) {
            this.props.history.push("/independent-mover/dashboard/my-subscription")
            return;
        }
    }

    // GetSubscriptionDetails() {
    //     let loginData
    //     GetData('GetUserDetailsForSubscription')
    //         .then(response => {
    //             if (response.success == 1) {
    //                 loginData = JSON.parse(Storage.get('imnLoginData'));
    //                 // console.log(loginData)
    //                 // console.log('subscription_id dash',response.data[0].subscription_id);

    //                 Storage.set('imnLoginData', JSON.stringify({
    //                     ...loginData,
    //                     // subscription_id: response.data[0].subscription_id,
    //                     subscription_id: 2,
    //                     trial_subscription_id: response.data[0].trial_subscription_id,
    //                     subscription_expiry_date: response.data[0].subscription_expiry_date
    //                 }))

    //                 // -------------- static data checking ---------------
    //                 // Storage.set('myReloProHelperLoginData', JSON.stringify({...loginData ,
    //                 //     subscription_id : 8, 
    //                 //     trial_subscription_id : 0, 
    //                 //     subscription_issue_date: '2020-08-28',
    //                 //     subscription_expiry_date : ''}))
    //             }

    //         })
    //         .catch(err => {

    //         })
    // }

    getLoginUserData = () => {
        try {
            return JSON.parse(Storage.get('imnLoginData'))
        } catch (err) {
            return null
        }
    }

    // ------------------- subscription end ------------------------------

    async getCustomProfileMenu() {

        let custom_profile_id = (JSON.parse(Storage.get('imnLoginData'))).custom_profile_id;
        this.setState({ custom_profile_id: custom_profile_id }, () => {
            // console.log(this.state.custom_profile_id);
        });
        if (custom_profile_id != "") {
            try {
                this.setState({ isLoadCustomMenu: false });
                let response = await PostDataWithParam('GetCustomProfileWiseMenuList', { custom_profile_id: custom_profile_id })

                if (response.success == 1) {
                    // let custom_menu = [];
                    let custom_menu = {
                        Messages: null,
                        "Offers Received": null,
                        ReloOrders: null,
                        "Manage Claims": null,
                        "Find & Book a Pro": null,
                        "Manage Team": null,
                        "Manage Customer": null,
                        "Customer Satisfaction Survey": null,
                        Inventory: null
                    };
                    for (let j = 0; j < response.data.length; j++) {
                        custom_menu[response.data[j].title] = response.data[j];
                    }
                    // Storage.set('mmcStaffMenu', JSON.stringify(Object.values(custom_menu)));
                    Storage.set('mmcStaffMenu', JSON.stringify(Object.values(custom_menu).filter(menu => !!menu)));
                    await this.setState({ isLoadCustomMenu: true });

                } else {
                    // await this.setState({
                    //     custom_menu: [],
                    // })
                }
            } catch (err) {
                // await this.setState({
                //     custom_menu: [],
                // })
            }
        }
        else {
            this.setState({ isLoadCustomMenu: true });
        }
    }

    losePremiumModal = () => {
        window.$('#subscribeToPremiumModal').modal('hide');
    }

    // --------------------------------------------- CHAT HANDLE --------------------------------------------------------
    setChatListTab = async (tab) => {
        this.closeChatBox();
        this.setState({
            activeChatListTab: tab
        });
        if (tab === 1) {
            this.setState({ currentSearchSessionIdForSingleChat: 0 })
            await this.getUsersOfSingleChat();
            this.setChatUserListOfSingleChat()

        } else {
            await this.getUsersOfActiveOrders();
            this.setChatUserList();
        }

    }
    isActiveOrderChatTab = () => this.state.activeChatListTab === 0;
    startLoadingUserChatList = () => {
        this.setState({ isLoadingUserChatList: true })
    }
    stopLoadingUserChatList = () => {
        this.setState(prevState => {
            return { isLoadingUserChatList: prevState.isLoadingUserChatList === true ? false : prevState.isLoadingUserChatList }
        })
    }
    getUsersOfActiveOrders = () => {
        this.setState({ chatUserList: [], usersOfActiveOrders: [] });
        this.startLoadingUserChatList();
        return PostDataWithParam('GetAllOrderIdForChattingNew', {}).then(response => {
            let usersOfActiveOrders = []
            if (response.success == 1 && this.state.activeChatListTab === 0) {
                usersOfActiveOrders = response.data;
            }
            this.setState({ usersOfActiveOrders })
        })
            .catch(err => console.log(err))
            .finally(() => {
                if (this.state.activeChatListTab === 0)
                    this.stopLoadingUserChatList();
            });



    }
    getUsersOfSingleChat = () => {
        this.setState({ chatUserList: [], usersOfSingleChat: [] });
        this.startLoadingUserChatList();
        return PostDataWithParam('GetUsersForChat', {}).then(response => {
            let usersOfSingleChat = []
            if (response.success == 1 && this.state.activeChatListTab === 1) {
                usersOfSingleChat = response.data;
            }
            if (this.state.currentSearchSessionIdForSingleChat === 0)
                this.setState({ usersOfSingleChat })
        })
            .catch(err => console.log(err))
            .finally(() => {
                if (this.state.activeChatListTab === 1)
                    this.stopLoadingUserChatList();
            });
    }
    getsSearchUsersForSingleChat = (search) => {
        let prevSearchSessionIdForSingleChat = this.state.currentSearchSessionIdForSingleChat;
        return PostDataWithParam('SearchUsersForChat', { search_text: search }).then(response => {
            let usersOfSingleChat = []
            if (response.success == 1) {
                usersOfSingleChat = response.data;
            }
            if (prevSearchSessionIdForSingleChat === this.state.currentSearchSessionIdForSingleChat)
                this.setState({ usersOfSingleChat })
        }).catch(err => console.log(err))
    }
    addUserToChatListForSingleChat = (receiver_info) => {
        if (receiver_info) {
            PostDataWithParam('AddUserToChatList ', { receiver_user_id: receiver_info.user_id })
                .then(response => {
                    if (response.success == 1) {
                    }
                }).catch(err => console.log(err))
        }
    }
    handleUserSearchForSingleChat = async (search) => {

        if (search && search.length > 0) {
            this.setState(prevState => ({
                currentSearchSessionIdForSingleChat: prevState.currentSearchSessionIdForSingleChat + 1
            }), async () => {
                await this.getsSearchUsersForSingleChat(search);
                this.setChatUserListOfSingleChat()
            })

        } else {
            this.setState({ currentSearchSessionIdForSingleChat: 0 })
            await this.getUsersOfSingleChat();
            this.setChatUserListOfSingleChat()
        }

    }
    setOpenChatDataInFB = () => {
        let loginData = this.getLoginUserData();
        let chatBoxObj = this.state.chatBoxObj;
        if (loginData && chatBoxObj) {
            let { chat_box_id, chat_info } = chatBoxObj
            let data = {
                id: loginData.id,
                is_active: true,
                chat_order_id: chat_info.order_id,
                chat_reciver_id: chat_info.receiver_info.user_id,
                chat_box_id
            }
            database.ref(`/active_users/${loginData.id}`).set(data)
        }
    }
    onUserActive = () => {
        let loginData = this.getLoginUserData();
        if (loginData) {
            let data = {
                id: loginData.id,
                is_active: true,
            }
            database.ref(`/active_users/${loginData.id}`).set(data);
        }
    }
    onUserDeactive = () => {
        let loginData = this.getLoginUserData();
        database.ref(`active_users`).child(`${loginData.id}`).remove()

    }
    handlePresenceOfUser = () => {
        let loginData = this.getLoginUserData();
        if (loginData) {
            var userStatusDatabaseRef = database.ref(`/active_users/${loginData.id}`);
            var isOfflineForDatabase = {
                id: loginData.id,
                is_active: false,
                chat_order_id: "",
                chat_reciver_id: "",
                chat_box_id: "",
                timestamp: window.moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            // Create a reference to the special '.info/connected' path in 
            // Realtime Database. This path returns `true` when connected
            // and `false` when disconnected.
            database.ref('.info/connected').on('value', (snapshot) => {
                // If we're not currently connected, don't do anything.
                console.log(snapshot.val())
                if (snapshot.val() == false) {
                    return;
                };

                // If we are currently connected, then use the 'onDisconnect()' 
                // method to add a set which will only trigger once this 
                // client has disconnected by closing the app, 
                // losing internet, or any other means.
                userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(() => {
                    if (this.state.chatBoxObj) {
                        this.setOpenChatDataInFB();
                    } else {
                        this.onUserActive();
                    }
                });
            });
        }
    }
    onUnreadMsgListener = () => {

        let loginData = this.getLoginUserData();
        if (loginData) {
            database.ref(`/unread_messages/${loginData.id}`).on("value", (snapshot) => {
                let prevUnreadMsgUserCount = { ...this.state.unreadMsgUserCount };
                let data = snapshot.val();
                let msgCount = 0;
                let unreadMsgUsers = [];

                if (data) {
                    msgCount = Object.keys(data).length;
                    unreadMsgUsers = Object.values(data);
                    // add any unread message then execute this block
                    console.log(prevUnreadMsgUserCount.orderTabCount, msgCount)
                    if (prevUnreadMsgUserCount.orderTabCount <= msgCount && document.getElementById("chat-sound")) {
                        document.getElementById("chat-sound").play();
                    }
                }
                // console.log("%c Unread Message User Count : "+unreadMsgUserCount,"border:5px solid red;font-size:20px;color:blue");
                // console.log(JSON.stringify(unreadMsgUsers,null,2))
                this.setState(prevState => ({
                    unreadMsgUserCount: {
                        total: prevState.unreadMsgUserCount.userTabCount + msgCount,
                        userTabCount: prevState.unreadMsgUserCount.userTabCount,
                        orderTabCount: msgCount
                    },
                    unreadMsgUsers
                }), () => {
                    this.setChatUserList()
                })
            })
        }

    }
    onUnreadMsgListenerOfSingleChat = () => {

        let loginData = this.getLoginUserData();
        if (loginData) {
            database.ref(`/user_unread_messages/${loginData.id}`).on("value", (snapshot) => {
                let prevUnreadMsgUserCount = { ...this.state.unreadMsgUserCount };
                let data = snapshot.val();
                let msgCount = 0;
                let unreadMsgUsersOfSingleChat = [];

                if (data) {
                    msgCount = Object.keys(data).length;
                    unreadMsgUsersOfSingleChat = Object.values(data);
                    // add any unread message then execute this block
                    console.log("USER TAB", prevUnreadMsgUserCount.userTabCount, msgCount)
                    if (prevUnreadMsgUserCount.userTabCount <= msgCount && document.getElementById("chat-sound")) {
                        document.getElementById("chat-sound").play();
                    }
                }
                // console.log("%c Unread Message User Count of Single Chat: "+unreadMsgUserCount,"border:5px solid red;font-size:20px;color:blue");
                // console.log(JSON.stringify(unreadMsgUsersOfSingleChat,null,2))
                this.setState(prevState => ({
                    unreadMsgUserCount: {
                        total: prevState.unreadMsgUserCount.orderTabCount + msgCount,
                        userTabCount: msgCount,
                        orderTabCount: prevState.unreadMsgUserCount.orderTabCount
                    },
                    unreadMsgUsersOfSingleChat
                }), () => {
                    this.setChatUserListOfSingleChat()
                })
            });
        }

    }
    getProfileName = (profile_id) => {
        let profiles = {
            1: "Super Admin",
            2: "Movers",
            3: "U.S Military",
            4: "Customer",
            5: "Helper",
            6: "Driver",
            7: "Staffs",
            8: "Client",
            9: "Storage Management Office",
            10: "MyPCS Admin",
            11: "MyPCS Customer",
            12: "Packers",
            13: "3rd Party",
            14: "Claim Repair"

        }
        return profiles[profile_id] || ""
    }
    setChatUserList = () => {
        const { unreadMsgUsers, usersOfActiveOrders, usersOfSingleChat } = this.state;
        // `usersOfActiveOrders` and `unreadMsgUsers` combine map
        let chatUserList = usersOfActiveOrders.map(user => {
            let findUnreadUser = null;
            let profile_name = this.getProfileName(user.profile_id);
            let chat_box_id = "";
            findUnreadUser = unreadMsgUsers.find(unreadUser => unreadUser.user_id === user.user_id && unreadUser.order_id === user.order_id);
            chat_box_id = `${user.order_id}_${user.user_id}`;
            if (findUnreadUser) {
                console.log(profile_name, user)
                return {
                    ...user,
                    ...findUnreadUser,
                    is_unread_user: true,
                    chat_box_id,
                    profile_name,
                    timestamp: window.moment(findUnreadUser.timestamp).valueOf()
                }
            }
            return { ...user, msg_count: 0, timestamp: user.timestamp ? window.moment(user.timestamp).valueOf() : 0, is_unread_user: false, chat_box_id, profile_name }
        })
        // Sorting by timestamp desc
        if (chatUserList.length > 0) {
            chatUserList.sort((a, b) => b.timestamp - a.timestamp);
            this.setState({ chatUserList })
        }
    }
    setChatUserListOfSingleChat = () => {
        const { unreadMsgUsersOfSingleChat, usersOfActiveOrders, usersOfSingleChat } = this.state;
        console.log("usersOfSingleChat", usersOfSingleChat)
        // `usersOfSingleChat` and `unreadMsgUsers` combine map
        let chatUserList = usersOfSingleChat.map(user => {
            let findUnreadUser = null;
            let profile_name = this.getProfileName(user.profile_id);
            let chat_box_id = "";
            findUnreadUser = unreadMsgUsersOfSingleChat.find(unreadUser => unreadUser.user_id === user.user_id);
            chat_box_id = user.user_id;

            if (findUnreadUser) {
                console.log(profile_name, user)
                return {
                    ...user,
                    ...findUnreadUser,
                    is_unread_user: true,
                    chat_box_id,
                    profile_name,
                    timestamp: window.moment(findUnreadUser.timestamp).valueOf(),
                    order_id: "",
                    order_no: ""
                }
            }
            return {
                ...user, msg_count: 0, timestamp: user.timestamp ? window.moment(user.timestamp).valueOf() : 0, is_unread_user: false, chat_box_id, profile_name, order_id: "",
                order_no: ""
            }
        })
        // Sorting by timestamp desc
        if (chatUserList.length > 0) {
            chatUserList.sort((a, b) => b.timestamp - a.timestamp);
            this.setState({ chatUserList })
        }
    }

    removeUnreadUserFromFB = (chat_box_id) => {
        let loginData = this.getLoginUserData();
        let ref = this.isActiveOrderChatTab() ? 'unread_messages' : 'user_unread_messages';
        database.ref(ref).child(`${loginData.id}`).child(`${chat_box_id}`).remove();

    }
    onSelectChatUser = (userInfo, selectIndex) => {
        const { chat_box_id, order_id, order_no, user_id, name, profile_image, msg_count } = userInfo;
        let select_chat_data = {
            chat_box_id: chat_box_id,
            chat_info: {
                order_id,
                order_no,
                receiver_info: {
                    user_id,
                    name,
                    profile_image
                }
            }
        }
        // if (msg_count === 0)
        //     this.openChatBox(select_chat_data);
        // else {
        // set `timestamp` 
        // let selectUnreadUserCount = this.state.selectUnreadUserCount;
        // let usersOfActiveOrders = [...this.state.usersOfActiveOrders];
        // let findIndex = usersOfActiveOrders.findIndex(user => user.user_id === user_id && user.order_id === order_id);
        // if (findIndex !== -1)
        //     usersOfActiveOrders[findIndex].timestamp = selectUnreadUserCount + 1;
        // this.setState({ usersOfActiveOrders, selectUnreadUserCount })

        // open chat window
        this.openChatBox(select_chat_data);
        // remove from firebase 
        // this.removeUnreadUserFromFB(chat_box_id);

        // }

    }

    initChatJquery(chat_box_id) {

        this.arrangeChatWindows();

        window.$('[data-toggle="tooltip"]').tooltip();

        window.$('[data-chatid="' + chat_box_id + '"]').on('change keydown keyup paste cut', 'textarea', function () {
            window.$(this).height(0).height(this.scrollHeight + 2);
        }).find('#emojiId' + chat_box_id).change();

        window.$('#emojiId' + chat_box_id).pickMoji({
            iconContainer: '[data-chatid="' + chat_box_id + '"] .emoji-icon-container2',
            targetArea: 'emojiId' + chat_box_id,
            positionVertical: 'dropup', /* dropdown, dropup */
            positionHorizontal: 'dropdown-menu',
            pickerIcon: '<img src="assets/images/icons/Icon/smile.svg" width="20" />'
        });

        window.$('[data-chatid="' + chat_box_id + '"] .chat-header > a').click(function (e) {
            e.preventDefault();
            window.$(this).closest('.popup-chat-window').toggleClass('chat-minimized');
        });
        /* Chat Script End */
    }

    arrangeChatWindows() {
        var chatWindowsListWidth = Math.trunc((window.$(window).width() / 10) * 9);
        var maxNumberOfChatWIndows = Math.trunc(chatWindowsListWidth / 315);
        var countChildElements = window.$('.popup-chat-window').length;

        var hiddenElementsCount = countChildElements - maxNumberOfChatWIndows;

        if (countChildElements > 0) {
            window.$('body').addClass('chats-visible');
        } else {
            window.$('body').removeClass('chats-visible');
        }

        var i = 1;
        window.$('.chat-windows-list > .popup-chat-window').each(function () {
            if (i <= hiddenElementsCount) {
                window.$(this).addClass('chat-hidden');
            } else {
                window.$(this).removeClass('chat-hidden');
            }
            i++;
        });

        var countHiddenChatWindows = window.$('.popup-chat-window.chat-hidden').length;
        if (countHiddenChatWindows > 0) {
            var hiddenNames = '';
            window.$('.chat-windows-list > .popup-chat-window.chat-hidden').each(function () {
                var name = window.$(this).find('.chat-username').text();
                var id = window.$(this).data('chatid');
                hiddenNames = hiddenNames + '<li><a href="" className="user-chat" data-chatusername="' + name + '" data-uid="' + id + '">' + name + '</a></li>';
            });
            window.$('.chat-windows-list .hidden-chats').remove();
            window.$('.chat-windows-list').prepend('<div className="hidden-chats dropup"><a href="" className=" dropdown-toggle"  data-toggle="dropdown"><img src="assets/images/icons/speech-bubble.svg" width="30" /> <b>' + countHiddenChatWindows + '</b> <span className="caret"></span></a><ul className="dropdown-menu dropdown-menu-right">' + hiddenNames + '</ul></div>');
        } else {
            window.$('.chat-windows-list .hidden-chats').remove();
        }
    }
    openChatBox = ({ chat_box_id, chat_info }) => {
        try {
            const findUnreadMsg = this.state.unreadMsgUsers
                .find(unreadUser => unreadUser.user_id === chat_info.receiver_info.user_id && unreadUser.order_id === chat_info.order_id);
            if (findUnreadMsg && findUnreadMsg.msg_count) {
                this.removeUnreadUserFromFB(chat_box_id, "unread_messages");
            }
        } catch (err) {

        }
        // here checking this chat box id already exist Or not set any chat box info then set incoming chat info
        if (!this.state.chatBoxObj || this.state.chatBoxObj.chat_box_id !== chat_box_id) {
            // clear prev chat box then replace new chat info
            this.setState({ chatBoxObj: null }, () => {
                let chatBoxObj = { chat_box_id, chat_info };
                this.setState({ chatBoxObj }, () => {
                    this.initChatJquery(chat_box_id);
                    this.setOpenChatDataInFB()
                })
            })

        }
        // when chat to admin then clear all admin chat notification from notification list
        let sender_info = this.getLoginUserData();
        const { receiver_info, order_id } = chat_info;
        if (receiver_info.user_id === 1) {
            let keyName = `/order_chat_${receiver_info.user_id}_${order_id}_${sender_info.user_id}`;
            database.ref(`/notifications/notification_list/user_${sender_info.user_id}/${keyName}`).remove();
        }

    }
    closeChatBox = (chat_box_id) => {
        this.setState({ chatBoxObj: null });
        window.$('body').removeClass('chats-visible');
        // chat info delete from `active_users` of fb node
        this.onUserActive();
    }

    openChatNotifications = () => {
        if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1) {
            window.swal({
                title: "Warning!",
                text: "Please upgrade your membership to complete this action",
                className: "subscrip-info-swal",
                icon: ''
            });
            this.props.history.push("/independent-mover/dashboard/my-subscription")
        }
        else {
            this.setState({ isOpenChatNotifications: true })
            window.$("#chat-contact-list").show();
        }


    }
    closeChatNotifications = () => {
        this.setState({ isOpenChatNotifications: false })
        window.$("#chat-contact-list").hide();
        window.$(".open-another-chatbox").hide();

    }


    openComposeChatBox = () => {
        this.setState({ isOpenComposeChat: true }, () => {
            window.$(".open-another-chatbox").show();
        })

    }
    closeComposeChatBox = () => {
        this.setState({ isOpenComposeChat: false })
        window.$(".open-another-chatbox").hide();
    }
    // --------------------------------------------- CHAT HANDLE END ------------------------------------------------

    // --------------------------------------------- Video CALL HANDLE --------------------------------------------------------
    openVideoChatWindow = ({ order_id, order_no, chat_box_id, receiver_info }) => {//to send link to join video chat
        // return;
        if (this.state.isLoadingToJoinVideoChat) return;
        this.setState({ isLoadingToJoinVideoChat: true });
        let loginUserData = this.getLoginUserData();
        if (!loginUserData) return;
        let sender_info = {
            user_id: loginUserData.id,
            profile_image: loginUserData.profile_image || '',
            first_name: loginUserData.first_name || '',
            last_name: loginUserData.last_name || '',
            profile_id: loginUserData.profile_id
        }
        let temp_channel_name = `channel_${sender_info.user_id}_${order_id}_${receiver_info.user_id}`
        let send_data = {
            to_id: receiver_info.user_id,
            from_id: sender_info.user_id,
            order_id: order_id,
            message: "",
            receiver_name: receiver_info.name,
            sender_profile_image: sender_info.profile_image,
            receiver_profile_image: receiver_info.profile_image || '',
            date: DateFormat.getFormattedDateTime(new Date(), 'm/d/Y'),
            // video chat
            channelName: temp_channel_name,
            status: "1",
            // new add
            timestamp: window.moment().format("YYYY-MM-DD HH:mm:ss")
        }

        this.joinVideoCall(temp_channel_name, false, () => {
            // when local stream publish successfully then send join link by firebase
            database.ref(`/active_users/${receiver_info.user_id}`).once('value', (snapshot) => {
                let reciver_current_chat_info = snapshot.val();
                console.log("reciver_current_chat_info", reciver_current_chat_info);
                // here check reciver current chat info match to semder info if match then not set unread message
                if (reciver_current_chat_info && reciver_current_chat_info.is_active && reciver_current_chat_info.chat_order_id === order_id && reciver_current_chat_info.chat_reciver_id === sender_info.user_id) {
                    this.senderChatRef = database.ref(`/chat/order_${sender_info.user_id}_${order_id}_${receiver_info.user_id}`).push(send_data);
                    this.reciverChatRef = database.ref(`/chat/order_${receiver_info.user_id}_${order_id}_${sender_info.user_id}`).push(send_data);
                } else {
                    let msg_count = 1;
                    database.ref(`/unread_messages/${receiver_info.user_id}/${order_id}_${sender_info.user_id}`).once('value', (snapshot) => {
                        let data = snapshot.val();
                        console.log("Unread Msg Data", data)
                        if (data) msg_count = data.msg_count + 1
                        // set unread message count
                        database.ref(`/unread_messages/${receiver_info.user_id}/${order_id}_${sender_info.user_id}`).set({
                            user_id: sender_info.user_id,
                            profile_id: sender_info.profile_id,
                            name: `${sender_info.first_name} ${sender_info.last_name}`,
                            profile_image: sender_info.profile_image,
                            msg_count,
                            order_id,
                            order_no,
                            timestamp: window.moment().format("YYYY-MM-DD HH:mm:ss")
                        })
                        this.senderChatRef = database.ref(`/chat/order_${sender_info.user_id}_${order_id}_${receiver_info.user_id}`).push(send_data);
                        this.reciverChatRef = database.ref(`/chat/order_${receiver_info.user_id}_${order_id}_${sender_info.user_id}`).push(send_data);
                    });
                }

            })
        });



    }
    openVideoChatWindowForSingleChat = ({ receiver_info }) => {//to send link to join video chat
        if (this.state.isLoadingToJoinVideoChat) return;
        this.setState({ isLoadingToJoinVideoChat: true });
        let loginUserData = this.getLoginUserData();
        if (!loginUserData) return;
        let sender_info = {
            user_id: loginUserData.id,
            profile_image: loginUserData.profile_image || '',
            first_name: loginUserData.first_name || '',
            last_name: loginUserData.last_name || '',
            profile_id: loginUserData.profile_id
        }
        let temp_channel_name = `channel_${sender_info.user_id}_${receiver_info.user_id}`
        let send_data = {
            to_id: receiver_info.user_id,
            from_id: sender_info.user_id,
            order_id: "",
            message: "",
            receiver_name: receiver_info.name,
            sender_profile_image: sender_info.profile_image,
            receiver_profile_image: receiver_info.profile_image || '',
            date: DateFormat.getFormattedDateTime(new Date(), 'm/d/Y'),
            // video chat
            channelName: temp_channel_name,
            status: "1",
            // new add
            timestamp: window.moment().format("YYYY-MM-DD HH:mm:ss")
        }

        this.joinVideoCall(temp_channel_name, false, () => {
            // when local stream publish successfully then send join link by firebase
            database.ref(`/active_users/${receiver_info.user_id}`).once('value', (snapshot) => {
                let reciver_current_chat_info = snapshot.val();
                console.log("reciver_current_chat_info", reciver_current_chat_info);
                // here check reciver current chat info match to semder info if match then not set unread message
                if (reciver_current_chat_info && reciver_current_chat_info.is_active && reciver_current_chat_info.chat_reciver_id === sender_info.user_id) {
                    this.senderChatRef = database.ref(`/user_chat/user_${sender_info.user_id}_${receiver_info.user_id}`).push(send_data);
                    this.reciverChatRef = database.ref(`/user_chat/user_${receiver_info.user_id}_${sender_info.user_id}`).push(send_data);
                } else {
                    let msg_count = 1;
                    database.ref(`/user_unread_messages/${receiver_info.user_id}/${sender_info.user_id}`).once('value', (snapshot) => {
                        let data = snapshot.val();
                        console.log("Unread Msg Data", data)
                        if (data) msg_count = data.msg_count + 1
                        // set unread message count
                        database.ref(`/user_unread_messages/${receiver_info.user_id}/${sender_info.user_id}`).set({
                            user_id: sender_info.user_id,
                            profile_id: sender_info.profile_id,
                            name: `${sender_info.first_name} ${sender_info.last_name}`,
                            profile_image: sender_info.profile_image,
                            msg_count,
                            order_id: "",
                            order_no: "",
                            timestamp: window.moment().format("YYYY-MM-DD HH:mm:ss")
                        })
                        this.senderChatRef = database.ref(`/user_chat/user_${sender_info.user_id}_${receiver_info.user_id}`).push(send_data);
                        this.reciverChatRef = database.ref(`/user_chat/user_${receiver_info.user_id}_${sender_info.user_id}`).push(send_data);
                    });
                }

            })
        });



    }
    joinVideoCall = (channel, isReciverLink = false, cb = null) => {
        // return ;
        this.setState({ openVideoChat: true })
        const listenerCb = ({ type, data = null }) => {
            const chatBoxObj = this.state.chatBoxObj;
            if (this.state.openVideoChat) {
                if (type === "stream-subscribed" && chatBoxObj.chat_info)
                    NotificationManager.success(`${chatBoxObj.chat_info.receiver_info.name} join`, 'Join');
                else if (type === "stream-removed" && chatBoxObj.chat_info) {
                    NotificationManager.success(`${chatBoxObj.chat_info.receiver_info.name} leave`, "Leave");
                }
            }
        }
        RTC.join({ channel, listenerCb })
            .then(() => {
                // this.setState({ openVideoChat: true }, () => {
                if (typeof cb === 'function') cb()
                RTC.playLocalStream();
                window.$("#videoPopUp").modal().show();
                // })

            })
            .catch((err) => { })
            .finally(() => {
                this.setState({ isLoadingToJoinVideoChat: false });
            });
    }


    disconnectVideoCall = () => {
        if (this.senderChatRef != "" && this.reciverChatRef != "") {
            this.senderChatRef.update({ status: "0" })
            this.reciverChatRef.update({ status: "0" })
        }
        this.setState({
            openVideoChat: false
        }, () => {
            RTC.leave();
            window.$("#videoPopUp").modal().hide();
        });
    }
    // --------------------------------------------- Video CALL HANDLE END --------------------------------------------------------


    headerRef = React.createRef();
    openHelpModal = (data) => {
        this.headerRef.current.getWrappedInstance().openHelpModal(data)
    }
    render() {
        if (this.state.logoutStatus) {
            window.location.href = Urls.login
            return <p></p>;
        }
        return (
            <div className="navbar-top">
                <audio id="chat-sound" src="assets/chat-sound.mp3"></audio>
                <Header
                    {...this.props}
                    openChatBox={this.openChatBox}
                    ref={this.headerRef}
                    closeChatNotifications={this.closeChatNotifications}
                    closeChatBox={this.closeChatBox}
                />
                <NotificationContainer />
                <div className="page-container">
                    <div className="page-content">

                        <Sidebar {...this.props} isLoadCustomMenu={this.state.isLoadCustomMenu} />

                        <div className="content-wrapper">
                            <div className="content">
                                <div className="content-container">

                                    {/* <Route path="/independent-mover/dashboard"
                                        exact render={
                                            () => { return <Redirect to="/independent-mover/dashboard/home" /> }
                                        } /> */}

                                    <Route path="/independent-mover/dashboard/home" exact component={Content} />
                                    <Route path="/independent-mover/dashboard" exact component={Content} />
                                    <Route path="/independent-mover/dashboard/myprofile" component={MyProfile} />
                                    <Route path="/independent-mover/dashboard/edit-my-profile" component={EditMyProfile} />
                                    <Route path="/independent-mover/dashboard/company-profile" component={CompanyProfile} />
                                    <Route path="/independent-mover/dashboard/edit-company-profile" component={EditCompanyProfile} />
                                    <Route path="/independent-mover/dashboard/change-password" component={ChangePassword} />
                                    <Route path="/independent-mover/dashboard/drivers" component={Driver} />
                                    <Route path="/independent-mover/dashboard/packers" component={Packer} />
                                    <Route path="/independent-mover/dashboard/adddriver" component={AddDriver} />
                                    <Route path="/independent-mover/dashboard/viewdriver/:driver_id" component={ViewDriver} />
                                    <Route path="/independent-mover/dashboard/editdriver/:driver_id" component={EditDriver} />
                                    <Route path="/independent-mover/dashboard/helpers" component={Helper} />
                                    <Route path="/independent-mover/dashboard/addhelper" component={AddHelper} />
                                    <Route path="/independent-mover/dashboard/viewhelper/:helper_id" component={ViewHelper} />
                                    <Route path="/independent-mover/dashboard/edithelper/:helper_id" component={EditHelper} />
                                    <Route path="/independent-mover/dashboard/customers" component={Customer} />
                                    <Route path="/independent-mover/dashboard/add-customer" component={AddCustomer} />
                                    <Route path="/independent-mover/dashboard/view-customer/:customer_id" component={ViewCustomer} />
                                    <Route path="/independent-mover/dashboard/edit-customer/:customer_id" component={EditCustomer} />
                                    <Route path="/independent-mover/dashboard/clients" component={Client} />
                                    <Route path="/independent-mover/dashboard/viewclient/:client_id" component={ViewClient} />
                                    <Route path="/independent-mover/dashboard/addclient" component={AddClient} />
                                    <Route path="/independent-mover/dashboard/editclient/:client_id" component={EditClient} />
                                    <Route path="/independent-mover/dashboard/subscription-payment" component={SubscriptionPayment} />

                                    <Route path="/independent-mover/dashboard/compose-message" component={ComposeMessage} />

                                    <Route path="/independent-mover/dashboard/all-messages" exact render={
                                        () => { return <Redirect to="/independent-mover/dashboard/inbox" /> }
                                    } />
                                    <Route path="/independent-mover/dashboard/inbox" component={Inbox} />
                                    <Route path="/independent-mover/dashboard/sent-message" component={SentMessage} />
                                    <Route path="/independent-mover/dashboard/messages/inbox-details/:message_id" component={InboxDetails} />
                                    <Route path="/independent-mover/dashboard/messages/sent-message-details/:message_id" component={SentMessageDetails} />
                                    <Route path="/independent-mover/dashboard/access-control" component={AccessControl} />

                                    <Route path="/independent-mover/dashboard/view-movers-profile/:provider_user_id" component={ViewMoversProfile} />

                                    <Route path="/independent-mover/dashboard/staff" component={Staff} />
                                    <Route path="/independent-mover/dashboard/addstaff" component={AddStaffNew} />
                                    <Route path="/independent-mover/dashboard/viewstaff/:staff_id" component={ViewStaff} />
                                    <Route path="/independent-mover/dashboard/editstaff/:staff_id" component={EditStaffNew} />
                                    <Route path="/independent-mover/dashboard/get-quote" component={FindService} />
                                    <Route path="/independent-mover/dashboard/quote-details" component={QuoteDetails} />
                                    <Route path="/independent-mover/dashboard/find-movers" component={FindMovers} />
                                    <Route path="/independent-mover/dashboard/find-drivers" component={FindDrivers} />
                                    <Route path="/independent-mover/dashboard/find-helpers" component={FindHelpers} />
                                    <Route path="/independent-mover/dashboard/find-thirdparty" component={FindThirdParty} />
                                    <Route path="/independent-mover/dashboard/find-claimrepair" component={FindClaimRepair} />
                                    {/* Custom profile */}
                                    <Route path="/independent-mover/dashboard/manage-profile" component={ManageProfile} />
                                    <Route path="/independent-mover/dashboard/add-team-member" component={AddTeamMember} />
                                    {/* <Route path="/independent-mover/dashboard/team-member-details" component={TeamMemberDetails} /> */}
                                    <Route path="/independent-mover/dashboard/team-member-details/:profile_id" component={TeamMemberDetails} />
                                    <Route path="/independent-mover/dashboard/view-team-member/:user_id" component={ViewTeamMember} />

                                    <Route path="/independent-mover/dashboard/movers-applications" component={MoversApplications} />
                                    <Route path="/independent-mover/dashboard/application-details/:application_id" component={AppplicationDetails} />
                                    <Route path="/independent-mover/dashboard/compose-individual" component={ComposeIndividual} />
                                    <Route path="/independent-mover/dashboard/send-application" component={SendApplication} />
                                    <Route path="/independent-mover/dashboard/my-subscription" component={MySubscription} />
                                    <Route path="/independent-mover/dashboard/military-approved" component={GetMilitaryApproved} />
                                    <Route path="/independent-mover/dashboard/my-cardlist" component={CardList} />
                                    <Route path="/independent-mover/dashboard/checkout" component={Checkout} />
                                    <Route path="/independent-mover/dashboard/assign-offer-job" component={AssignOfferJob} />
                                    <Route path="/independent-mover/dashboard/unassign-offer" component={UnassignedOffers} />
                                    <Route path="/independent-mover/dashboard/unassigned-offer-details" component={UnassignedOfferDetails} />
                                    <Route path="/independent-mover/dashboard/outsource-project" component={OutsourceProject} />
                                    <Route path="/independent-mover/dashboard/manage-orders" component={ManageOrders} />
                                    <Route path="/independent-mover/dashboard/closed-orders" component={ClosedOrder} />
                                    {/* Start Chat */}
                                    <Route path="/independent-mover/dashboard/order-details" render={(props) => {
                                        return <OrderDetails {...props} openChatBox={this.openChatBox} />
                                    }} />
                                    {/* End Chat */}
                                    <Route path="/independent-mover/dashboard/edc-order-details" component={EdcOrderDetails} />
                                    <Route path="/independent-mover/dashboard/closed-order-details" component={ClosedOrderDetails} />
                                    <Route path="/independent-mover/dashboard/manage-claims" render={(props) => {
                                        return <ManageClaims {...props} openChatBox={this.openChatBox} unreadMsgUsers={this.state.unreadMsgUsers} />
                                    }} />
                                    <Route path="/independent-mover/dashboard/manage-claim-details/:claim_id" render={(props) => {
                                        return <ManageClaimDetail {...props} openChatBox={this.openChatBox} />
                                    }}
                                    />
                                    <Route path="/independent-mover/dashboard/find-an-offer" component={FindAnOffer} />
                                    <Route path="/independent-mover/dashboard/assign-offer" component={AssignedOrder} />
                                    <Route path="/independent-mover/dashboard/my-offer-details" render={(props) => {
                                        return <MyOfferDetails {...props} openChatBox={this.openChatBox} />
                                    }} />
                                    <Route path="/independent-mover/dashboard/other-staff" component={OtherStaff} />
                                    <Route path="/independent-mover/dashboard/add-other-staff" component={AddStaff} />
                                    <Route path="/independent-mover/dashboard/view-other-staff/:staff_id" component={StaffDetails} />
                                    <Route path="/independent-mover/dashboard/edit-other-staff/:staff_id" component={EditStaff} />
                                    <Route path="/independent-mover/dashboard/create-job" component={CreateJob} />
                                    <Route path="/independent-mover/dashboard/notification" component={Notification} />
                                    <Route path="/independent-mover/dashboard/edit-order-details" component={EditOrderDetails} />
                                    <Route path="/independent-mover/dashboard/manage-question" component={ManageQuestion} />
                                    <Route path="/independent-mover/dashboard/customer-serveys" component={CustomerServeys} />
                                    <Route path="/independent-mover/dashboard/css-dashboard" component={CssDashboard} />
                                    <Route path="/independent-mover/dashboard/customer" component={ManageCustomer} />
                                    <Route path="/independent-mover/dashboard/view-customer-orders" component={ViewCustomerOrders} />
                                    <Route path="/independent-mover/dashboard/inventory" component={Inventory} />
                                    <Route path="/independent-mover/dashboard/view-inventory-details/:inventory_id" component={InventoryDetails} />
                                    {/* <Route path="/independent-mover/dashboard/brand" component={Brand} /> */}
                                    <Route path="/independent-mover/dashboard/myschedule" component={MySchedule} />
                                    <Route path="/independent-mover/dashboard/moverAppz" render={() => <MoverAppz openHelpModal={this.openHelpModal} />} />
                                    <Route path="/independent-mover/dashboard/training-material" component={TrainingMaterial} />
                                    <Route path="/independent-mover/dashboard/add-training-category" component={AddTrainingCategory} />
                                    <Route path="/independent-mover/dashboard/moverAppz-subscription" component={MoverAppzSubscription} />
                                </div>
                                <Footer />
                                {this.state.openVideoChat && <div id="videoPopUp" className="modal" role="dialog" data-backdrop="static" data-keyboard="false">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header p-l-10 p-r-10 p-t-5 p-b-5">
                                                <h4 className="modal-title text-left">
                                                    {this.state.chatBoxObj && this.state.chatBoxObj.chat_info.receiver_info.name}
                                                </h4>
                                            </div>
                                            <div className="modal-body p-0 agora-theme">

                                                <div className="video-grid" id="video">
                                                    <div className="video-view video-view-local">
                                                        <div id="local_stream" className="video-placeholder"></div>
                                                        <div id="local_video_info"
                                                            className="video-profile hide">
                                                        </div>
                                                        <div id="video_autoplay_local"
                                                            className="autoplay-fallback hide">

                                                        </div>
                                                    </div>
                                                </div>

                                                <button id="call-decline" type="button" data-dismiss="modal"
                                                    className="btn bg-warning-600 round-btn"
                                                    onClick={this.disconnectVideoCall.bind(this)}>
                                                    <img src="assets/images/icons/white/phone.svg" alt="" width="25" />
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                }

                                <div className="chat-windows-list">
                                    {
                                        this.state.chatBoxObj && this.isActiveOrderChatTab() && <ChatBox
                                            {...this.state.chatBoxObj.chat_info}
                                            closeChatBox={this.closeChatBox}
                                            openVideoChatWindow={this.openVideoChatWindow}
                                            joinVideoCall={this.joinVideoCall}
                                            isLoadingToJoinVideoChat={this.state.isLoadingToJoinVideoChat}
                                        />
                                    }
                                    {
                                        this.state.chatBoxObj && !this.isActiveOrderChatTab() && <UserChatBox
                                            {...this.state.chatBoxObj.chat_info}
                                            closeChatBox={this.closeChatBox}
                                            openVideoChatWindowForSingleChat={this.openVideoChatWindowForSingleChat}
                                            joinVideoCall={this.joinVideoCall}
                                            isLoadingToJoinVideoChat={this.state.isLoadingToJoinVideoChat}
                                            addUserToChatListForSingleChat={this.addUserToChatListForSingleChat}
                                        />
                                    }
                                    {
                                        this.state.isOpenComposeChat && <ComposeChatBox
                                            removeUnreadUserFromFB={this.removeUnreadUserFromFB}
                                            closeComposeChatBox={this.closeComposeChatBox}
                                            chatUserList={this.state.chatUserList}
                                            openVideoChatWindow={this.openVideoChatWindow}
                                            joinVideoCall={this.joinVideoCall}
                                        />
                                    }
                                    <ChatNotifications
                                        isOpenChatNotifications={this.state.isOpenChatNotifications}
                                        openChatNotifications={this.openChatNotifications}
                                        closeChatNotifications={this.closeChatNotifications}
                                        unreadMsgUserCount={this.state.unreadMsgUserCount}
                                        unreadMsgUsers={this.state.unreadMsgUsers}
                                        chatUserList={this.state.chatUserList}
                                        onSelectChatUser={this.onSelectChatUser}
                                        openComposeChatBox={this.openComposeChatBox}
                                        activeChatListTab={this.state.activeChatListTab}
                                        setChatListTab={this.setChatListTab}
                                        handleUserSearchForSingleChat={this.handleUserSearchForSingleChat}
                                        isLoading={this.state.isLoadingUserChatList}

                                    />
                                </div>

                                <div className="modal fade subscribe-to-premium-modal" id="subscribeToPremiumModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                                    <div className="modal-dialog modal-sm" role="document">
                                        <div className="modal-content">
                                            <div className="modal-body p-0">
                                                <a href="#" className="btn-close" data-dismiss="modal"><i className="icon-cross2"></i></a>
                                                <Link to="/independent-mover/dashboard/my-subscription" onClick={this.closePremiumModal}><img src="assets/images/premium-modal-bg.png" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardLayout;