import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import PostDataFile from '../services/PostDataFile';
import GetData from '../services/GetData';
import MilitaryConversations from './MilitaryConversations';
import { Prompt } from 'react-router';

class FinancialHealth extends Component {
    state = {
        fields: {
            profit_loss_current_year:null,
            profit_loss_previous_year:null,
            balance_sheet_current_year:null,
            balance_sheet_previous_year:null,
            cash_flows_current_year:null,
            cash_flows_previous_year:null            
        },
        files: {
            profit_loss_current_year:'',
            profit_loss_previous_year:'',
            balance_sheet_current_year:'',
            balance_sheet_previous_year:'',
            cash_flows_current_year:'',
            cash_flows_previous_year:''            
        },
        financial_health_id:'',
        tab_id:1,
        saveStatus:false,
        proccesing:false,
        formDirty:false
    }

    componentDidMount() {

       
        window.scrollTo(0, 0);

        GetData('GetFinancialHealth')
        .then((response) => {
            if (response.success == 1) {
                this.setState({ files:response.data[0], financial_health_id: response.data[0].id });
            }
        })        
    }  

    getFileName(file){
        if(file!=null){
            let files = file.split('/');
            return files[files.length -1];
        }        
    }
    
    handleFileSelect = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.files[0];
        this.setState({ fields: fields,formDirty:true});
    }

    updateFinancialHealth = () => {
        this.setState({proccesing:true});
        let fields = this.state.fields;
        let fd = new FormData();

        for (var key in fields) {
            if (fields.hasOwnProperty(key)) {
                if(fields[key]!=null){
                                     
                    fd.append(key, fields[key], fields[key].name);
                }                
            }
        }
        fd.append('tab_id', 1);
        fd.append('financial_health_id', this.state.financial_health_id);
        PostDataFile('SaveFinancialHealth', fd)
        .then((response) => {
            if (response.success === 1) {
                window.swal('Success!', response.msg,'success')
                this.setState({ saveStatus: true,proccesing:false,formDirty:false }, () => {
                    this.props.onUpdateApprovalProgress();
                });   
            } else {
                this.setState({ proccesing:false ,formDirty:false});
                window.swal('Error!', response.msg,'error')
            }
        })
    }
    
    openModal = (field_name,tab_id) => {
        this.props.onOpenModal(field_name,tab_id)
    }

    renderAlertMsg = (field_name,tab_id) => {
       
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
        

        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Answer Received</p>
                        </React.Fragment>
                    )
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Waiting for reply</p>
                        </React.Fragment>
                    )
                }
            }
        }
    }

    renderClassName = (field_name,tab_id) => {
        
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
       
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
         

        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return "btn-success "
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return "btn-warning "
                }
            }
        }


    }

    render() {
        if (this.state.saveStatus) {
            return <Redirect  to='/independent-mover/dashboard/military-approved/information-tab/warehouse'/>
        }

        return (
            <div className="tab-pane active" id="top-icon-tab1">
                <Prompt
                when={this.state.formDirty}
                message="Please save your data before you leave the page. Otherwise, you will lose the information you entered !"
                />
                <div className="panel panel-flat">
                    <div className="panel-heading">
                        <h5 className="panel-title">Financial Health</h5>
                        <small className="text-muted">Here we are evaluating the financial health of your organization to ensure it meets the guidelines for approval. Each type of approval (SIT/NTS/TSP) has different requirements. We have provided sample documents & links, so you may produce from your accounting software, the similar documents. Please contact your bookkeeper/ accountant for assistance. We have attached, Accountant Primer, an important document which describes the formulas used to determine your financial health of your organization, which will allow your bookkeeper/ accountant to ensure the highest level of success. For initial approval, USTRANSCOM will accept generated forms from your accounting software but to obtain final approval, your accountant will need to generate a Reviewed Financial Statement (see Sample Reviewed Financial Statement).</small>
                    </div>
                    <div className="panel-body">
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Profit & Loss Statement Current Year &nbsp; <small><a href="https://quickbooks.intuit.com/features/reporting/profit-loss-statement/" target="_blank">See How Here in QB</a></small></h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <input type="file" className="m-t-10" name="profit_loss_current_year" onChange={this.handleFileSelect}/>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('profit_loss_current_year',this.state.tab_id)} className={this.renderClassName('profit_loss_current_year',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('profit_loss_current_year',this.state.tab_id)} 
                                </div>
                            </div>
                            {
                                this.state.files.profit_loss_current_year
                                &&

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="file-wrapper filetype-image">
                                            <div className="file-title">
                                            
                                            <a href="javascript:void(0)" data-src={this.state.files.profit_loss_current_year || ""} data-title="Preview" data-toggle="image-modal-file" className="image-modal-file" className="image-modal-file"><i className="icon-image5 position-left text-primary"></i> {this.getFileName(this.state.files.profit_loss_current_year)}</a>
                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }                            
                        </div>

                        <div className="well m-b-15">
                            <h5 className="m-t-0">Profit & Loss Previous Fiscal Year</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <input type="file" className="m-t-10" name="profit_loss_previous_year" onChange={this.handleFileSelect}/>
                                </div>

                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('profit_loss_previous_year',this.state.tab_id)} className={this.renderClassName('profit_loss_previous_year',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('profit_loss_previous_year',this.state.tab_id)} 
                                </div>
                            </div>
                            {
                                this.state.files.profit_loss_previous_year
                                
                                &&
                              
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="file-wrapper filetype-image">
                                            <div className="file-title"><a href="javascript:void(0)" data-src={this.state.files.profit_loss_previous_year} data-title="Preview" data-toggle="image-modal-file" className="image-modal-file"><i className="icon-image5 position-left text-primary"></i> {this.getFileName(this.state.files.profit_loss_previous_year)}</a></div>
                                        </div>
                                    </div>
                                </div>
                            }                            
                        </div>

                        <div className="well m-b-15">
                            <h5 className="m-t-0">Balance Sheet Current Year &nbsp; <small><a href="https://quickbooks.intuit.com/features/reporting/balance-sheet/" target="_blank">See How Here in QB</a></small></h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <input type="file" className="m-t-10" name="balance_sheet_current_year" onChange={this.handleFileSelect}/>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('balance_sheet_current_year',this.state.tab_id)} className={this.renderClassName('balance_sheet_current_year',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('balance_sheet_current_year',this.state.tab_id)} 
                                </div>
                            </div>
                            {
                                this.state.files.balance_sheet_current_year
                                &&
                               
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="file-wrapper filetype-image">
                                            <div className="file-title">
                                            <a href="javascript:void(0)" data-src={this.state.files.balance_sheet_current_year} data-title="Preview"  data-toggle="image-modal-file" className="image-modal-file">
                            
                                <i className="icon-image5 position-left text-primary"></i> 
                                {this.getFileName(this.state.files.balance_sheet_current_year)}
                                </a>
                                </div>
                                        </div>
                                    </div>
                                </div>
                            }                             
                        </div>

                        <div className="well m-b-15">
                            <h5 className="m-t-0">Balance Sheet Previous Fiscal Year</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <input type="file" className="m-t-10" name="balance_sheet_previous_year" onChange={this.handleFileSelect}/>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('balance_sheet_previous_year',this.state.tab_id)} className={this.renderClassName('balance_sheet_previous_year',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('balance_sheet_previous_year',this.state.tab_id)} 
                                </div>
                            </div>
                            {
                                this.state.files.balance_sheet_previous_year
                                &&
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="file-wrapper filetype-image">
                                            <div className="file-title"><a href="javascript:void(0)" data-src={this.state.files.balance_sheet_previous_year} data-title="Preview"  data-toggle="image-modal-file" className="image-modal-file">
                                            <i className="icon-image5 position-left text-primary"></i> {this.getFileName(this.state.files.balance_sheet_previous_year)}</a></div>
                                        </div>
                                    </div>
                                </div>
                            }                            
                        </div>

                        <div className="well m-b-15">
                            <h5 className="m-t-0">Statement of Cash Flows Current Year &nbsp; <small><a href="https://quickbooks.intuit.com/features/reporting/cash-flow-statement/" target="_blank">See How Here in QB</a></small></h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <input type="file" className="m-t-10" name="cash_flows_current_year" onChange={this.handleFileSelect}/>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('cash_flows_current_year',this.state.tab_id)} className={this.renderClassName('cash_flows_current_year',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('cash_flows_current_year',this.state.tab_id)} 
                                </div>
                            </div>
                            {
                                this.state.files.cash_flows_current_year
                                &&
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="file-wrapper filetype-image">
                                            <div className="file-title"><a href="javascript:void(0)" data-src={ this.state.files.cash_flows_current_year} data-title="Preview"  data-toggle="image-modal-file" className="image-modal-file"><i className="icon-image5 position-left text-primary"></i> {this.getFileName(this.state.files.cash_flows_current_year)}</a></div>
                                        </div>
                                    </div>
                                </div>
                            }                              
                        </div>

                        <div className="well m-b-15">
                            <h5 className="m-t-0">Statement of Cash Flows Previous Fiscal Year</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <input type="file" className="m-t-10" name="cash_flows_previous_year" onChange={this.handleFileSelect}/>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('cash_flows_previous_year',this.state.tab_id)} className={this.renderClassName('cash_flows_previous_year',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('cash_flows_previous_year',this.state.tab_id)} 
                                </div>
                            </div>
                            {
                                this.state.files.cash_flows_previous_year
                                &&
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="file-wrapper filetype-image">
                                            <div className="file-title"><a href="javascript:void(0)" data-src={this.state.files.cash_flows_previous_year} data-title="Preview"  data-toggle="image-modal-file" className="image-modal-file"><i className="icon-image5 position-left text-primary"></i> {this.getFileName(this.state.files.cash_flows_previous_year)}</a></div>
                                        </div>
                                    </div>
                                </div>
                            }                              
                        </div>
                    </div>
                </div>
                <MilitaryConversations/>
                <div className="text-center">
                {
                    this.state.proccesing?<button className="btn btn-primary" disabled="true">Processing...</button>:<button className="btn btn-primary" onClick={this.updateFinancialHealth}>Save &amp; Next</button>
                }
                
                </div>  
                            
            </div>

        );
    }
}

export default FinancialHealth;