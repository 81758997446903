import React, { Component } from 'react'
import { Link, Route, Redirect } from 'react-router-dom';

import FinancialHealth from './FinancialHealth';
import Warehouse from './Warehouse';
import Environmental from './Environmental';
import InsectControl from './InsectControl';
import Housekeeping from './Housekeeping';
import Repair from './Repair';
import Responsible from './Responsible';
import FirePrevention from './FirePrevention';
import Security from './Security';
import Authority from './Authority';
import Evaluation from './Evaluation';
import Considerations from './Considerations';
import PostDataWithParam from '../services/PostDataWithParam';
import Urls from '../externaljs/Urls';
import Storage from '../externaljs/Storage';
import GetData from '../services/GetData';

class MilitaryApprovedTab extends Component {
    state = {
        completion_percentage: 0,
        completion_percentage_basic: 0,
        approval_status: 0,
        active_tab_id: 1,
        progress_flag: 0,
        approvalAskQuestions:{
            tab_1:0,
            tab_2:0,
            tab_3:0,
            tab_4:0,
            tab_5:0,
            tab_6:0,
            tab_7:0,
            tab_8:0,
            tab_9:0,
            tab_10:0,
            tab_11:0,
            tab_12:0
        },
        approvalAskQuestionsNodes:{
            tab_1:[],
            tab_2:[],
            tab_3:[],
            tab_4:[],
            tab_5:[],
            tab_6:[],
            tab_7:[],
            tab_8:[],
            tab_9:[],
            tab_10:[],
            tab_11:[],
            tab_12:[]
        },
        approvalAskQuestionsNodesSent:{
            tab_1:[],
            tab_2:[],
            tab_3:[],
            tab_4:[],
            tab_5:[],
            tab_6:[],
            tab_7:[],
            tab_8:[],
            tab_9:[],
            tab_10:[],
            tab_11:[],
            tab_12:[]
        }
    }

    database = null;

    componentDidMount() {
       
        this.getCompletionStatus();
        this.getApprovalProgress();
        this.database = Urls.firebase.database();
        this.registerNotifications();
    }

    OpenModal = (field_name, tab_id) => {
        this.clearNotification(field_name, tab_id)
        this.props.onOpenModal(field_name, tab_id)        
    }    

    registerNotifications(){
        this.database.ref('/notifications/approval_ask_questions/user_' + (JSON.parse(Storage.get('imnLoginData'))).id)
        .on('child_added', (snapshot) => {
            let approvalAskQuestions = this.state.approvalAskQuestions;
            let approvalAskQuestionsNodes = this.state.approvalAskQuestionsNodes;
            let tempData = snapshot.val();

            for (var key in approvalAskQuestions) {
                if (approvalAskQuestions.hasOwnProperty(key)) {
                    if(key == tempData.tab_id){
                        approvalAskQuestions[key]++;
                        approvalAskQuestionsNodes[key].push({[tempData.field_name]:snapshot.key})
                    } 
                }
            }

            this.setState({approvalAskQuestions: approvalAskQuestions,approvalAskQuestionsNodes:approvalAskQuestionsNodes},()=>{
              
            })
        })

        this.database.ref('/notifications/approval_ask_questions/user_1')
        .on('child_added', (snapshot) => {
            let approvalAskQuestions = this.state.approvalAskQuestions;
            let approvalAskQuestionsNodes = this.state.approvalAskQuestionsNodesSent;
            let tempData = snapshot.val();

            for (var key in approvalAskQuestions) {
                if (approvalAskQuestions.hasOwnProperty(key)) {
                    if(tempData.sender_user_id == (JSON.parse(Storage.get('imnLoginData'))).id){
                        if(key == tempData.tab_id){
                            approvalAskQuestions[key]++;
                            approvalAskQuestionsNodes[key].push({[tempData.field_name]:snapshot.key})
                        } 
                    }                    
                }
            }

            this.setState({approvalAskQuestions: approvalAskQuestions,approvalAskQuestionsNodesSent:approvalAskQuestionsNodes},()=>{
              
            })
        })

        this.database.ref('/notifications/approval_ask_questions/user_1')
        .on('child_removed', (snapshot) => {
            let approvalAskQuestions = this.state.approvalAskQuestions;
            let approvalAskQuestionsNodes = this.state.approvalAskQuestionsNodesSent;
            let tempData = snapshot.val();

            for (var key in approvalAskQuestions) {
                if (approvalAskQuestions.hasOwnProperty(key)) {
                    if(tempData.sender_user_id == (JSON.parse(Storage.get('imnLoginData'))).id){
                        if(key == tempData.tab_id){
                            approvalAskQuestions[key]--;   

                            if(approvalAskQuestionsNodes[key].length > 0){  
                                for( let i = 0; i < approvalAskQuestionsNodes[key].length; i++){           
                                    if(approvalAskQuestionsNodes[key][i] && approvalAskQuestionsNodes[key][i][tempData.field_name]){ 
                                        delete approvalAskQuestionsNodes[key][i][tempData.field_name]
                                    }
                                }
                            }                      
                            
                        } 
                    }                    
                }
            }

            this.setState({approvalAskQuestions: approvalAskQuestions,approvalAskQuestionsNodesSent:approvalAskQuestionsNodes},()=>{
               
            })
        })
    }

    clearNotification = (field_name, tab_id) => {
        let itemUrl = '';
        let approvalAskQuestions = this.state.approvalAskQuestions;
        let approvalAskQuestionsNodes = this.state.approvalAskQuestionsNodes;
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempCount = approvalAskQuestions['tab_'+tab_id];

        
        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){ 
                              
                if(tempNode[i] && tempNode[i][field_name]){                  
                    itemUrl = '/notifications/approval_ask_questions/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '/' + tempNode[i][field_name];
                                       
                    this.database.ref(itemUrl).remove();
                     
                    delete tempNode[i];   
                                    
                    tempCount--;
                }
            }         
        }

        approvalAskQuestionsNodes['tab_'+tab_id] = tempNode;
        approvalAskQuestions['tab_'+tab_id] = tempCount;

        this.setState({approvalAskQuestions:approvalAskQuestions,approvalAskQuestionsNodes:approvalAskQuestionsNodes });
    }

    getApprovalActiveTab = () => {
        PostDataWithParam('GetApprovalActiveTab', {})
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ active_tab_id: response.data.tab_id }, () => {
                        if (response.data.tab_id == 1) {
                            this.props.history.push('/independent-mover/dashboard/military-approved/information-tab/financial')
                        } else if (response.data.tab_id == 2) {
                            this.props.history.push('/independent-mover/dashboard/military-approved/information-tab/warehouse')
                        } else if (response.data.tab_id == 3) {
                            this.props.history.push('/independent-mover/dashboard/military-approved/information-tab/environmental')
                        } else if (response.data.tab_id == 4) {
                            this.props.history.push('/independent-mover/dashboard/military-approved/information-tab/insect')
                        } else if (response.data.tab_id == 5) {
                            this.props.history.push('/independent-mover/dashboard/military-approved/information-tab/housekeeping')
                        } else if (response.data.tab_id == 6) {
                            this.props.history.push('/independent-mover/dashboard/military-approved/information-tab/repair')
                        } else if (response.data.tab_id == 7) {
                            this.props.history.push('/independent-mover/dashboard/military-approved/information-tab/responsible')
                        } else if (response.data.tab_id == 8) {
                            this.props.history.push('/independent-mover/dashboard/military-approved/information-tab/fire')
                        } else if (response.data.tab_id == 9) {
                            this.props.history.push('/independent-mover/dashboard/military-approved/information-tab/security')
                        } else if (response.data.tab_id == 10) {
                            this.props.history.push('/independent-mover/dashboard/military-approved/information-tab/authority')
                        } else if (response.data.tab_id == 11) {
                            this.props.history.push('/independent-mover/dashboard/military-approved/information-tab/evaluation')
                        } else if (response.data.tab_id == 12) {
                            this.props.history.push('/independent-mover/dashboard/military-approved/information-tab/considerations')
                        }
                    });
                }
            })
            .catch(err => {
                this.setState({ active_tab_id: 1 });
            })
    }

    getApprovalProgress = () => {
        PostDataWithParam('GetApprovalProgress', {})
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ completion_percentage: response.data.percentage, approval_status: response.data.approval_status, progress_flag: response.data.progress_flag });
                }
            })
            .catch(err => {
                this.setState({ completion_percentage: 0, approval_status: 0, progress_flag: 0 });
            })

        this.getApprovalActiveTab();
    }

    getCompletionStatus = () => {
        GetData('GetProfileCompleteStatus')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ completion_percentage_basic: response.completion_percentage });
                }
            })
            .catch(err => {
                this.setState({ completion_percentage_basic: 0 });
            })
    }

    getNavLinkClass = (path, exact = true) => {
        if (exact)
            return this.props.location.pathname === path ? 'active' : '';
        else {
            return ((this.props.location.pathname).indexOf(path) !== -1) ? 'active' : '';
        }
    }

    submitForReview = () => {
        PostDataWithParam('SendForApproval', {})
            .then((response) => {
                if (response.success === 1) {
                    window.swal('Success!', response.msg, 'success');
                    this.getApprovalProgress();
                }
            })
            .catch(err => {

            })
    }

    renderReviewBtn = () => {

        if(this.state.approval_status == 0 && this.state.completion_percentage >= 80 && this.state.completion_percentage_basic >= 80)
        {
            return <button type="button"  className="btn btn-success pull-right myreloreview" onClick={this.submitForReview} >Submit for MyRelo Review</button>
        } 
        else if(this.state.approval_status == 2){
            return <label className="btn btn-success pull-right "><i className='icon-checkmark-circle m-r-5'></i>MyRelo Review status accepted</label>
        }
        else if(this.state.approval_status == 3){
            return <label className="btn btn-danger pull-right " ><i className='icon-cancel-circle2 m-r-5'></i>MyRelo Review status rejected</label>
        }
        else 
        {
            return <button type="button" className="btn btn-primary pull-right " disabled={true} >Request Sent for Review</button>
        }

    }

    render() {
        return (
            <div id="tab-military-approved" className="tab-pane active">
                <div className="text-left">
                    <h5 className="inline-block m-r-30 text-blue-800">Information Completion </h5>
                    <div className="progress inline-block" style={{ width: '300px', verticalAlign: 'middle' }}>
                        <div className="progress-bar bg-danger" style={{ width: this.state.completion_percentage + '%' }}>
                            <span>{this.state.completion_percentage}% Complete</span>
                        </div>
                    </div>
                    {this.renderReviewBtn()}                    
                    {/* {
                        this.state.approval_status == 1 
                        &&
                        <input type="button" value="Request Sent for Review" className="btn btn-success pull-right" disabled={true} />
                    } */}
                </div>
                <hr />
                <div className="alert alert-primary alert-styled-left">
                    <button type="button" className="close text-semibold" data-dismiss="alert"><span>&times;</span><span className="sr-only">Close</span></button>
                    In this section, please complete the questionnaire and upload the required documents. We have provided examples and form letters you can send to the necessary authorities to request the needed documents. Remember, once some of these are done you will most likely never be asked by the military to supply them again. Department of Defense is looking for professional, financially sound companies to ensure the highest level of customer satisfaction and least amount of risk to taxpayers.
                </div>
                <div className="row">
                    <div className="col-md-4 col-lg-4">
                        <div className="list-group bg-blue-800">
                            <div className="list-group-header">Required information for military approval</div>
                            <Link to="/independent-mover/dashboard/military-approved/information-tab/financial" className={"list-group-item clearfix " + this.getNavLinkClass('/independent-mover/dashboard/military-approved/information-tab/financial')}><span className="pull-left">Financial Health </span><span className={(this.state.approvalAskQuestionsNodes.tab_1!='')?"badge badge-danger":"badge badge-success"}>{this.state.approvalAskQuestions.tab_1}</span></Link>
                            <Link to="/independent-mover/dashboard/military-approved/information-tab/warehouse" className={"list-group-item clearfix " + this.getNavLinkClass('/independent-mover/dashboard/military-approved/information-tab/warehouse')}><span className="pull-left">Warehouse </span><span className={(this.state.approvalAskQuestionsNodes.tab_2!='')?"badge badge-danger":"badge badge-success"}>{this.state.approvalAskQuestions.tab_2}</span></Link>
                            <Link to="/independent-mover/dashboard/military-approved/information-tab/environmental" className={"list-group-item clearfix " + this.getNavLinkClass('/independent-mover/dashboard/military-approved/information-tab/environmental')}><span className="pull-left">Environmental </span><span className={(this.state.approvalAskQuestionsNodes.tab_3!='')?"badge badge-danger":"badge badge-success"}>{this.state.approvalAskQuestions.tab_3}</span></Link>
                            <Link to="/independent-mover/dashboard/military-approved/information-tab/insect" className={"list-group-item clearfix " + this.getNavLinkClass('/independent-mover/dashboard/military-approved/information-tab/insect')}><span className="pull-left">Insect and Rodent Control </span><span className={(this.state.approvalAskQuestionsNodes.tab_4!='')?"badge badge-danger":"badge badge-success"}>{this.state.approvalAskQuestions.tab_4}</span></Link>
                            <Link to="/independent-mover/dashboard/military-approved/information-tab/housekeeping" className={"list-group-item clearfix " + this.getNavLinkClass('/independent-mover/dashboard/military-approved/information-tab/housekeeping')}><span className="pull-left">Housekeeping </span><span className={(this.state.approvalAskQuestionsNodes.tab_5!='')?"badge badge-danger":"badge badge-success"}>{this.state.approvalAskQuestions.tab_5}</span></Link>
                            <Link to="/independent-mover/dashboard/military-approved/information-tab/repair" className={"list-group-item clearfix " + this.getNavLinkClass('/independent-mover/dashboard/military-approved/information-tab/repair')}><span className="pull-left">Repair </span><span className={(this.state.approvalAskQuestionsNodes.tab_6!='')?"badge badge-danger":"badge badge-success"}>{this.state.approvalAskQuestions.tab_6}</span></Link>
                            <Link to="/independent-mover/dashboard/military-approved/information-tab/responsible" className={"list-group-item clearfix " + this.getNavLinkClass('/independent-mover/dashboard/military-approved/information-tab/responsible')}><span className="pull-left">Responsible Management & Operations </span><span className={(this.state.approvalAskQuestionsNodes.tab_7!='')?"badge badge-danger":"badge badge-success"}>{this.state.approvalAskQuestions.tab_7}</span></Link>
                            <Link to="/independent-mover/dashboard/military-approved/information-tab/fire" className={"list-group-item clearfix " + this.getNavLinkClass('/independent-mover/dashboard/military-approved/information-tab/fire')}><span className="pull-left">Fire Prevention & Protection </span><span className={(this.state.approvalAskQuestionsNodes.tab_8!='')?"badge badge-danger":"badge badge-success"}>{this.state.approvalAskQuestions.tab_8}</span></Link>
                            <Link to="/independent-mover/dashboard/military-approved/information-tab/security" className={"list-group-item clearfix " + this.getNavLinkClass('/independent-mover/dashboard/military-approved/information-tab/security')}><span className="pull-left">Security </span><span className={(this.state.approvalAskQuestionsNodes.tab_9!='')?"badge badge-danger":"badge badge-success"}>{this.state.approvalAskQuestions.tab_9}</span></Link>
                            <Link to="/independent-mover/dashboard/military-approved/information-tab/authority" className={"list-group-item clearfix " + this.getNavLinkClass('/independent-mover/dashboard/military-approved/information-tab/authority')}><span className="pull-left">Operating Authority and Licensing </span><span className={(this.state.approvalAskQuestionsNodes.tab_10!='')?"badge badge-danger":"badge badge-success"}>{this.state.approvalAskQuestions.tab_10}</span></Link>
                            <Link to="/independent-mover/dashboard/military-approved/information-tab/evaluation" className={"list-group-item clearfix " + this.getNavLinkClass('/independent-mover/dashboard/military-approved/information-tab/evaluation')}><span className="pull-left">Evaluation of Flood Hazard </span><span className={(this.state.approvalAskQuestionsNodes.tab_11!='')?"badge badge-danger":"badge badge-success"}>{this.state.approvalAskQuestions.tab_11}</span></Link>
                            <Link to="/independent-mover/dashboard/military-approved/information-tab/considerations" className={"list-group-item clearfix " + this.getNavLinkClass('/independent-mover/dashboard/military-approved/information-tab/considerations')}><span className="pull-left">Other Considerations </span><span className={( this.state.approvalAskQuestionsNodes.tab_12!='')?"badge badge-danger":"badge badge-success"}>{this.state.approvalAskQuestions.tab_12}</span></Link>

                        </div>
                    </div>
                    <div className="col-md-8 col-lg-8">
                        <div className="tabbable">
                            <div className="tab-content">
                                <Route path="/independent-mover/dashboard/military-approved/information-tab" exact render={
                                    () => { return <Redirect to="/independent-mover/dashboard/military-approved/information-tab/financial" /> }
                                } />
                                <Route path="/independent-mover/dashboard/military-approved/information-tab/financial" render={(props) => {
                                    return <FinancialHealth {...props} onOpenModal={this.OpenModal} onUpdateApprovalProgress={this.getApprovalProgress} approvalAskQuestionsNodesProps={this.state.approvalAskQuestionsNodes} approvalAskQuestionsNodesSentProps={this.state.approvalAskQuestionsNodesSent}/>
                                }} />
                                <Route path="/independent-mover/dashboard/military-approved/information-tab/warehouse" render={(props) => {
                                    return <Warehouse {...props} onOpenModal={this.OpenModal} onUpdateApprovalProgress={this.getApprovalProgress} approvalAskQuestionsNodesProps={this.state.approvalAskQuestionsNodes} approvalAskQuestionsNodesSentProps={this.state.approvalAskQuestionsNodesSent}/>
                                }} />
                                <Route path="/independent-mover/dashboard/military-approved/information-tab/environmental" render={(props) => {
                                    return <Environmental {...props} onOpenModal={this.OpenModal} onUpdateApprovalProgress={this.getApprovalProgress} approvalAskQuestionsNodesProps={this.state.approvalAskQuestionsNodes} approvalAskQuestionsNodesSentProps={this.state.approvalAskQuestionsNodesSent}/>
                                }} />
                                <Route path="/independent-mover/dashboard/military-approved/information-tab/insect" render={(props) => {
                                    return <InsectControl {...props} onOpenModal={this.OpenModal} onUpdateApprovalProgress={this.getApprovalProgress} approvalAskQuestionsNodesProps={this.state.approvalAskQuestionsNodes} approvalAskQuestionsNodesSentProps={this.state.approvalAskQuestionsNodesSent}/>
                                }} />
                                <Route path="/independent-mover/dashboard/military-approved/information-tab/housekeeping" render={(props) => {
                                    return <Housekeeping {...props} onOpenModal={this.OpenModal} onUpdateApprovalProgress={this.getApprovalProgress} approvalAskQuestionsNodesProps={this.state.approvalAskQuestionsNodes} approvalAskQuestionsNodesSentProps={this.state.approvalAskQuestionsNodesSent}/>
                                }} />
                                <Route path="/independent-mover/dashboard/military-approved/information-tab/repair" render={(props) => {
                                    return <Repair {...props} onOpenModal={this.OpenModal} onUpdateApprovalProgress={this.getApprovalProgress} approvalAskQuestionsNodesProps={this.state.approvalAskQuestionsNodes} approvalAskQuestionsNodesSentProps={this.state.approvalAskQuestionsNodesSent}/>
                                }} />
                                <Route path="/independent-mover/dashboard/military-approved/information-tab/responsible" render={(props) => {
                                    return <Responsible {...props} onOpenModal={this.OpenModal} onUpdateApprovalProgress={this.getApprovalProgress} approvalAskQuestionsNodesProps={this.state.approvalAskQuestionsNodes} approvalAskQuestionsNodesSentProps={this.state.approvalAskQuestionsNodesSent}/>
                                }} />
                                <Route path="/independent-mover/dashboard/military-approved/information-tab/fire" render={(props) => {
                                    return <FirePrevention {...props} onOpenModal={this.OpenModal} onUpdateApprovalProgress={this.getApprovalProgress} approvalAskQuestionsNodesProps={this.state.approvalAskQuestionsNodes} approvalAskQuestionsNodesSentProps={this.state.approvalAskQuestionsNodesSent}/>
                                }} />
                            </div>
                            <Route path="/independent-mover/dashboard/military-approved/information-tab/security" render={(props) => {
                                return <Security {...props} onOpenModal={this.OpenModal} onUpdateApprovalProgress={this.getApprovalProgress} approvalAskQuestionsNodesProps={this.state.approvalAskQuestionsNodes} approvalAskQuestionsNodesSentProps={this.state.approvalAskQuestionsNodesSent}/>
                            }} />
                            <Route path="/independent-mover/dashboard/military-approved/information-tab/authority" render={(props) => {
                                return <Authority {...props} onOpenModal={this.OpenModal} onUpdateApprovalProgress={this.getApprovalProgress} approvalAskQuestionsNodesProps={this.state.approvalAskQuestionsNodes} approvalAskQuestionsNodesSentProps={this.state.approvalAskQuestionsNodesSent}/>
                            }} />
                            <Route path="/independent-mover/dashboard/military-approved/information-tab/evaluation" render={(props) => {
                                return <Evaluation {...props} onOpenModal={this.OpenModal} onUpdateApprovalProgress={this.getApprovalProgress} approvalAskQuestionsNodesProps={this.state.approvalAskQuestionsNodes} approvalAskQuestionsNodesSentProps={this.state.approvalAskQuestionsNodesSent}/>
                            }} />
                            <Route path="/independent-mover/dashboard/military-approved/information-tab/considerations" render={(props) => {
                                return <Considerations {...props} onOpenModal={this.OpenModal} onUpdateApprovalProgress={this.getApprovalProgress} approvalAskQuestionsNodesProps={this.state.approvalAskQuestionsNodes} approvalAskQuestionsNodesSentProps={this.state.approvalAskQuestionsNodesSent}/>
                            }} />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default MilitaryApprovedTab;