import React, { Component } from 'react'
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataWithParam from '../services/PostDataWithParam';
import DateFormat from '../externaljs/DateFormat';
import Storage from '../externaljs/Storage';
import GetData from '../services/GetData';

class ViewDriver extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Staff', link: '/independent-mover/dashboard/team-member-details/all', class: '' },
            { title: 'Staff Details', link: '', class: 'active' }
        ],
        fields: {
            id: '',
            salutation: '',
            first_name: '',
            last_name: '',
            gender: '',
            email: '',
            alternate_email: '',
            contact_no: '',
            alternate_contact_no: '',
            work_contact_no: '',
            address: '',
            dob: '',
            ssn: '',
            ssn_val: '',
            custom_profile_id: [],
            latitude: '',
            longitude: ''
        },
        events: [],
        error: {
            first_name_err: '',
            last_name_err: '',
            email_err: '',
            contact_no_err: '',
            alternate_contact_no_err: '',
            salutation_err: '',
            dob_err: '',
            ssn_err: '',
            dept_id_err: '',
            address_err: '',
            alternate_email_err: '',
            work_contact_no_err: '',
            home_contact_no_err: '',
        },
        passWordType: true,
        iconEye: true,
        staff_user_id: null,
        custom_menu: [],
        profileList: []
    }

    componentDidMount() {
        // window.$('[data-toggle="image-modal"]').click(function (e) {
        //     e.preventDefault();
        //     var modalTitle = window.$(this).attr('data-title');
        //     var imgSrc = window.$(this).attr('data-src');
        //     window.$('#imageModal .modal-title').html(modalTitle);
        //     window.$('#imageModal .modal-body').html('<img src="' + imgSrc + '" class="img-responsive"/>');
        //     window.$('#imageModal').modal('show');
        // });
        this.getCustomProfileList();
        this.initDatePicker('dob');

        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        let custom_menu = [];
        if (staff_user_id != "") {
            custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
        }
        this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
            // console.log(this.state.custom_menu);
            // console.log(this.state.staff_user_id);
        });
        this.initMultiSelectProfile();

    }

    getCustomProfileList = () => {
        GetData('GetCustomProfileList')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ profileList: response.data });
                }
            })
            .finally(() => {
                this.getUserInfo();
            })
    }
    initMultiSelectProfile = () => {
        window.$('.select-profile-xs-mul').select2({
            containerCssClass: 'select-xs'
        })
            .on('select2:select', e => {
                let fields = { ...this.state.fields };
                console.log("Select", e.params.data.id)
                fields.custom_profile_id.push(e.params.data.id)
                this.setState({ fields });
            })
            .off('select2:unselect')
            .on('select2:unselect', e => {
                let fields = { ...this.state.fields };
                console.log("unselect", e.params.data.id)
                let tempProfileId = fields.custom_profile_id.filter((id) => id != e.params.data.id);
                fields.custom_profile_id = tempProfileId
                this.setState({ fields })
            })
    }
    initDatePicker = (name) => {

        window.$('.input-group.date').daterangepicker({
            showDropdowns: true,
            autoclose: true,
            singleDatePicker: true
        }).on('apply.daterangepicker', (ev, picker) => {
            let fields = this.state.fields;
            fields.dob = picker.startDate.format('MM/DD/YYYY');
            this.setState({ fields: fields });
        });
        window.$('.select').select2({
            minimumResultsForSearch: Infinity
        });
        window.$('.daterangepicker').css('z-index', '1600');
    }

    initAutocomplete = (e) => {
        e.persist();
        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: ['geocode'] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {

            let place = autocomplete.getPlace();
            let fields = { ...this.state.fields };
            fields.latitude = place.geometry.location.lat();
            fields.longitude = place.geometry.location.lng();
            fields.address = window.$('#' + e.target.id)[0].value;
            this.setState({ fields: fields }, () => {
                // console.log(this.state.fields)
            });
        })
    }

    getUserInfo = (isModalOpen = false) => {
        PostDataWithParam('GetAllStaffs', { user_id: this.props.match.params.user_id, staff_user_id: this.state.staff_user_id })
            .then((response) => {
                if (response.success === 1) {
                    let response_data = response.data[0];
                    for (var key in response_data) {
                        if (response_data.hasOwnProperty(key)) {
                            if (response_data[key] == null) {
                                response_data[key] = ""
                            }
                        }
                    }
                    let custom_profile_id = []
                    if (response_data.custom_profile_id && response_data.custom_profile_id !== "") {
                        custom_profile_id = response_data.custom_profile_id.split(",");
                        window.$("#select-profile")
                            .val(custom_profile_id)
                            .trigger('change');
                    }
                    this.setState({ fields: { ...response_data, custom_profile_id } }, () => {
                        isModalOpen && window.$("#updateManageProfile").modal("show");
                    });
                }
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong.', 'error')
            })
    }

    // getEvents = (user_id) => {
    //     PostDataWithParam('GetEvents', {type:'1',user_id: user_id})
    //     .then(response => {
    //         if(response.success === 1){
    //             this.setState({events: response.data})
    //         } else {
    //             this.setState({events: []})
    //         }
    //     })
    //     .catch(err => {
    //         this.setState({events: []})
    //     })
    // }
    togglePassword() {
        let fields = Object.assign({}, this.state.fields);
        if (this.state.passWordType == true) {
            this.setState({ passWordType: false });
            this.setState({ iconEye: false });
            let swap = fields['ssn'] // masked value
            fields['ssn'] = fields['ssn_val']
            fields['ssn_val'] = swap  // masked value    
        } else {
            this.setState({ passWordType: true });
            this.setState({ iconEye: true });
            let swapssn = fields['ssn']
            fields['ssn'] = fields['ssn_val']  // masked value          
            fields['ssn_val'] = swapssn
        }
        this.setState({ fields: fields });
    }

    handleOnChange = (e) => {
        // debugger;
        let fields = Object.assign({}, this.state.fields);
        fields[e.target.name] = e.target.value;
        if (e.target.name == 'contact_no'
            || e.target.name == 'alternate_contact_no'
        ) {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                fields[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
        }
        if (e.target.name == 'ssn_val') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/)
            if (match) {
                fields['ssn'] = match[1] + ' ' + match[2] + ' ' + match[3]
                fields[e.target.name] = 'XXX' + '-' + 'XX' + '-' + match[3]
            }
        }
        if (e.target.name == "salutation") {
            if (e.target.value == "Mr.") {
                fields['gender'] = "Mr."
            }
            else if (e.target.value == "Ms.") {
                fields['gender'] = "Ms."
            }
            else {
                fields['gender'] = "Mrs."
            }
        }

        this.setState({ fields: fields }, () => {
            // console.log(this.state.fields)
        });

    }

    updateProfile = (e) => {
        let fields = { ...this.state.fields };
        // fields.menu=JSON.stringify(this.state.menu.map(item=>({id:item.menu_id, is_add:item.is_add, is_edit:item.is_edit, is_delete:item.is_delete})));
        // console.log(fields);
        // return;
        PostDataWithParam('UpdateStaffNew', fields)
            .then((response) => {
                let result = response;

                if (result.success === 1) {
                    this.getUserInfo();
                    window.swal('Success!', 'Custom staff Updated Successfully', 'success');

                } else {

                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error');
            })
    }

    editModalOpen = (e) => {
        e.preventDefault();
        this.getUserInfo(true)
    }
    render() {
        return (
            <React.Fragment>
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            {Breadcumb(this.state.breadcrumb)}
                            <div className="row">
                                {/* <div className="col-md-2">
                                <div className="profile-image">
                                    <img src={this.state.fields.profile_image || "assets/images/profile-pic-sample.jpg"} alt="" className="img-responsive" />
                                </div>
                                <div className="text-center text-orange">
                                    <p className="m-t-15 m-b-0">
                                        <span className="icon-star-full2"></span>
                                        <span className="icon-star-full2"></span>
                                        <span className="icon-star-full2"></span>
                                        <span className="icon-star-full2"></span>
                                    </p>
                                    <p className="m-0 f-s-24">4.0 <small className="text-muted f-s-13">(765 ratings)</small></p>
                                </div>
                            </div> */}
                                <div className="col-md-12">
                                    <div className="panel panel-flat profile-panel">
                                        <div className="panel-heading clearfix">
                                            <div className="heading-elements">

                                                {
                                                    this.state.staff_user_id == "" &&
                                                    <ul className="icons-list">
                                                        <li className="dropdown">
                                                            <a href="#" className="dropdown-toggle text-mid-grey" data-toggle="dropdown" aria-expanded="false"><i className="icon-menu7"></i></a>
                                                            <ul className="dropdown-menu dropdown-menu-right">
                                                                {/* <li><Link to={"/independent-mover/dashboard/editdriver/" + this.state.fields.id}><i className="icon-pencil"></i> Edit Staff</Link></li> */}
                                                                <li>
                                                                    {/* <Link to={"#" + this.state.fields.id}><i className="icon-pencil"></i> Edit Staff</Link> */}
                                                                    <a href="#" onClick={this.editModalOpen}><i className="icon-pencil"></i>Edit Profile</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                }
                                                {
                                                    this.state.staff_user_id != "" && this.state.custom_menu.map((menu, i) => {
                                                        return (
                                                            <ul className="icons-list">
                                                                {(menu.title == "Manage Team" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                                    <li className="dropdown">
                                                                        <a href="#" className="dropdown-toggle text-mid-grey" data-toggle="dropdown" aria-expanded="false"><i className="icon-menu7"></i></a>
                                                                        <ul className="dropdown-menu dropdown-menu-right">
                                                                            {/* <li><Link to={"/independent-mover/dashboard/editdriver/" + this.state.fields.id}><i className="icon-pencil"></i> Edit Staff</Link></li> */}
                                                                            <li>
                                                                                {/* <Link to={"#" + this.state.fields.id}><i className="icon-pencil"></i> Edit Staff</Link> */}
                                                                                <a href="#" onClick={this.editModalOpen}><i className="icon-pencil"></i>Edit Profile</a>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                }
                                                            </ul>
                                                        );
                                                    })
                                                }

                                            </div>
                                            <h2 className="pull-left text-success m-b-0 m-t-0 text-medium text-capitalize">{this.state.fields.gender} {this.state.fields.first_name} {this.state.fields.last_name}</h2>
                                        </div>
                                        <div className="panel-body p-t-0">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Email Address</label>
                                                    <p className="m-b-20">{this.state.fields.email || "----"}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Mobile No</label>
                                                    <p className="m-b-20">{this.state.fields.contact_no || "----"}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Alternate Mobile No</label>
                                                    <p className="m-b-20">{this.state.fields.alternate_contact_no || "----"}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Address</label>
                                                    <p className="m-b-20">{this.state.fields.address || "----"}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Date Of Birth</label>
                                                    <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.fields.dob, 'm-d-Y') || "----"}</p>
                                                </div>
                                                {/* <div className="col-md-4">
                                                    <label className="text-bold m-b-0">SSN</label>
                                                    <p className="m-b-20">{this.state.fields.ssn || "----"}</p>
                                                </div> */}
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Custom Profile Name</label>
                                                    <p className="m-b-20">{this.state.fields.custom_profile_name || "----"}</p>
                                                </div>
                                            </div>

                                            {/* <hr className="m-t-0" />
                                        <h5 className="text-medium m-t-0 text-green m-b-15">Passport Info</h5> */}

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="modal fade" tabindex="-1" role="dialog" id="updateManageProfile">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title">Edit Staff Details</h4>
                            </div>
                            <hr className="m-0 m-t-5"></hr>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Salutation <span className="text-danger">*</span> </label>
                                            <select className="form-control" name="salutation" onChange={this.handleOnChange} value={this.state.fields.gender} required={true}>
                                                <option value="">Select</option>
                                                <option value="Mr.">Mr.</option>
                                                <option value="Ms.">Ms.</option>
                                                <option value="Mrs.">Mrs.</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label>First Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter First Name" name="first_name" value={this.state.fields.first_name} onChange={this.handleOnChange} required={true} />
                                            <p className="text-danger help-block">{this.state.error.first_name_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label>Last Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter Last Name" name="last_name" value={this.state.fields.last_name} onChange={this.handleOnChange} required={true} />
                                            <p className="text-danger help-block">{this.state.error.last_name_err}</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Email ID <span className="text-danger">*</span></label>
                                            <input className="form-control" type="email" placeholder="Enter Email Address" name="email" value={this.state.fields.email} onChange={this.handleOnChange} required={true} />
                                            <p className="text-danger help-block">{this.state.error.email_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Phone Number <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="XXX-XXX-XXXX" name="contact_no" value={this.state.fields.contact_no} onChange={this.handleOnChange} maxLength="12" required={true} />
                                            <p className="text-danger help-block">{this.state.error.contact_no_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Alternate Phone Number <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="XXX-XXX-XXXX" name="alternate_contact_no" value={this.state.fields.alternate_contact_no} onChange={this.handleOnChange} maxLength="12" required={true} />
                                            <p className="text-danger help-block">{this.state.error.alternate_contact_no_err}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Address</label>
                                            {/*  */}
                                            <input className="form-control" placeholder="Enter Address" type="text" id="edit1_address" onFocus={this.initAutocomplete} name="address" value={this.state.fields.address || ''} onChange={this.handleOnChange} />
                                            <p className="text-danger help-block">{this.state.error.address_err}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date of Birth</label>
                                            <div className="input-group date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control" placeholder="Select date"
                                                    name="dob" value={DateFormat.getFormattedDateTime(this.state.fields.dob, 'm-d-Y') || "----"}
                                                    id="dob"
                                                    onChange={this.handleOnChange} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label>SSN </label>
                                            <input placeholder="XXX-XX-XXXX" type="text" className="form-control"
                                                value={this.state.fields.ssn} name="ssn_val"
                                                onChange={this.handleOnChange} maxLength="9"
                                            />
                                            <span style={{ color: 'black', float: 'right', marginLeft: '-25px', marginTop: '-25px', marginRight: '10px', position: 'relative', zIndex: '2', cursor: 'pointer' }} className={`fa field-icon ${this.state.iconEye ? 'fa-eye' : 'fa-eye-slash'}`} onClick={this.togglePassword.bind(this)}></span>
                                        </div>
                                    </div> */}

                                </div>
                                <h4 className="text-success">Profile</h4>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Select Profile <span className="text-danger">*</span></label>
                                            <select data-placeholder="Select Profile" className="select-profile-xs-mul" multiple="multiple" id="select-profile">
                                                <option value="" ></option>
                                                {this.state.profileList.map(profile =>

                                                    <option key={profile.id} value={profile.id} >{profile.title}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer p-t-15">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.updateProfile}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ViewDriver;