import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import PostDataWithParam from '../services/PostDataWithParam';
import Breadcumb from '../externaljs/Breadcrumb';
import GetData from '../services/GetData';
import ConfirmBox from '../externaljs/ConfirmBox';
import {FileType,FileSvg,FileIcon} from '../externaljs/FileType';

class CompanyProfile extends Component {

    state = {
        company_detail: [],
        company_files: [],
        UpdateSuc: '',
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Company Profile', link: '', class: 'active' }
        ]
    }

    componentDidMount() {
        this.GetCompanyInfo();
        this.GetAllCompanyFile();
        if (typeof this.props.location.state !== 'undefined') {
            this.setState({ UpdateSuc: this.props.location.state.UpdateErr });
        }
    }

    componentWillUnmount() {
        this.setState({ UpdateSuc: '' });
    }

    GetCompanyInfo = () => {
        PostDataWithParam('GetCompanyInfo')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ company_detail: response.data });
                }
            })
    }

    showSuccessAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-success"><i className="icon-check2 position-left"></i>
                    {this.state.UpdateSuc}
                </div>
            )
        }
    }

    handlecheckDetails = (status) => {
        if (status !== null) {
            return (
                status
            )
        } else {
            return '-----'
        }
    }

    handlecheck = (status) => {
        if (status !== null) {
            return (
                status
            )
        } else {
            return 'No data found'
        }
    }

    handleFileCheck = (status) => {
        if (status !== '') {
            return (
                <div className="thumb custom-logo" style={{ background: "url(" + this.state.company_detail.logo + ") no-repeat" }}>
                </div>
            )
        } else {
            return (
                <div className="thumb custom-logo" style={{ background: "url('assets/images/demo-logo.png') no-repeat" }}>
                </div>
            )
        }
    }

    GetAllCompanyFile = () => {
        GetData('GetCompanyDocument')
            .then((response) => {
                if (response.success == 1) {
                    this.setState({ company_files: response.data });
                }
            })
    }

    deleteFile = (id) => {
        ConfirmBox("You want to delete this file!")
            .then((result) => {
                if (result) {
                    PostDataWithParam('DeleteCompanyDocument', { document_id: id })
                        .then((response) => {
                            if (response.success == 1) {
                                window.swal('Deleted!', response.msg, 'success')
                                this.GetAllCompanyFile()
                            }
                        })
                }
            })
    }

    render() {
        return (
            <div className="content-container">
                <div className="row">
                    <div className="col-md-12">
                        {Breadcumb(this.state.breadcrumb)}
                        <div className="row">
                            <div className="col-md-3">
                                <div className="thumbnail">
                                    {this.handleFileCheck(this.state.company_detail.logo)}
                                    <div className="text-center text-warning">
                                        <h4 className="m-b-0 m-t-15">4.8</h4>
                                        <p className="m-b-15">
                                            <span className="icon-star-full2"></span>
                                            <span className="icon-star-full2"></span>
                                            <span className="icon-star-full2"></span>
                                            <span className="icon-star-full2"></span>
                                        </p>
                                    </div>
                                    <hr className="m-b-0 m-t-0" />
                                    <div className="caption text-center">
                                        <h6 className="text-semibold no-margin">Company Name<small className="display-block">{this.state.company_detail.organization_name}</small></h6>
                                        <ul className="icons-list mt-15">
                                            <li><a href="" data-popup="tooltip" title="" data-original-title="Google Drive" className="m-l-5 m-r-5"><i className="icon-facebook"></i></a></li>
                                            <li><a href="" data-popup="tooltip" title="" data-original-title="Twitter" className="m-l-5 m-r-5"><i className="icon-twitter"></i></a></li>
                                            <li><a href="" data-popup="tooltip" title="" data-original-title="Website" className="m-l-5 m-r-5"><i className="fa fa-globe"></i></a></li>
                                            <li><a href="" data-popup="tooltip" title="" data-original-title="LinkedIn" className="m-l-5 m-r-5"><i className="fa fa-linkedin"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="panel panel-flat profile-page">
                                    {this.showSuccessAlert(this.state.UpdateSuc)}
                                    <div className="panel-heading">
                                        <div className="heading-elements">
                                            <ul className="icons-list pull-right">
                                                <li className="dropdown">
                                                    <a href="" className="dropdown-toggle" data-toggle="dropdown">
                                                        <i className="icon-menu9"></i>
                                                    </a>
                                                    <ul className="dropdown-menu dropdown-menu-right">
                                                        <li><Link to='/independent-mover/dashboard/edit-company-profile'><i className="icon-pencil"></i> Edit Company Profile</Link></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label className="text-bold m-b-0">Office Phone:</label>
                                                        <p className="m-b-20">{this.handlecheckDetails(this.state.company_detail.company_contact_no)}</p>
                                                        <label className="text-bold m-b-0">Year Founded:</label>
                                                        <p className="m-b-20">{this.handlecheckDetails(this.state.company_detail.year_founded)}</p>
                                                        <label className="text-bold m-b-0">State License Number:</label>
                                                        <p className="m-b-20">{this.handlecheckDetails(this.state.company_detail.state_license_number)}</p>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <label className="text-bold m-b-0">Fax Phone Number:</label>
                                                        <p className="m-b-20">{this.handlecheckDetails(this.state.company_detail.company_fax_no)}</p>
                                                        <label className="text-bold m-b-0">DOT Number:</label>
                                                        <p className="m-b-20">{this.handlecheckDetails(this.state.company_detail.DOT_number)}</p>

                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="text-bold m-b-0">Year Founded:</label>
                                                        <p className="m-b-20">{this.handlecheckDetails(this.state.company_detail.year_founded)}</p>

                                                        <label className="text-bold m-b-0">MC#</label>
                                                        <p className="m-b-20">{this.handlecheckDetails(this.state.company_detail.company_MC)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-flat profile-page">
                                    <div className="panel-heading p-b-5">
                                        <h6 className="m-b-0 m-t-0 text-semibold">About us</h6>
                                    </div>
                                    <div className="panel-body">
                                        {this.handlecheck(this.state.company_detail.about_us)}
                                    </div>
                                </div>
                                <div className="panel panel-flat profile-page">
                                    <div className="panel-heading p-b-5">
                                        <h6 className="m-b-0 m-t-0 text-semibold">Our Mission</h6>
                                    </div>
                                    <div className="panel-body">
                                        {this.handlecheck(this.state.company_detail.our_mission)}
                                    </div>
                                </div>
                                <div className="panel panel-flat profile-page">
                                    <div className="panel-heading p-b-5">
                                        <h6 className="m-b-0 m-t-0 text-semibold">Core Values</h6>
                                    </div>
                                    <div className="panel-body">
                                        {this.handlecheck(this.state.company_detail.core_values)}
                                    </div>
                                </div>
                                <div className="panel panel-flat">
                                    <div className="panel-heading p-b-5">
                                        <h6 className="m-b-0 m-t-0 text-semibold">Uploaded documents</h6>
                                    </div>
                                    <div className="panel-body">
                                        <div className="file-manager hori-scroll">
                                            <ul>
                                                {
                                                    this.state.company_files.length != 0
                                                    &&
                                                    this.state.company_files.map((file) => {
                                                        return (
                                                            <li key={file.id}>
                                                                <div className={"file-wrapper " + FileType(file.document_extension)} >
                                                                    <div className="options">
                                                                        <a href="javascript:void(0)" className="text-danger-600" onClick={() => this.deleteFile(file.id)}><i className="icon-cancel-circle2"></i></a>
                                                                    </div>                                                                    
                                                                    {FileSvg(file.document_extension,file.document_path)}
                                                                    <div className="file-title"><a href={file.document_path}><i className={FileIcon(file.document_extension) + " position-left text-primary"}></i> {file.document_name}</a></div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                                {
                                                    this.state.company_files.length == 0
                                                    &&
                                                    <p>No Files Found.</p>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyProfile;