import React, { Component } from 'react';
import PostDataWithParam from '../services/PostDataWithParam';
import DateFormat from '../externaljs/DateFormat';
import ConfirmBox from '../externaljs/ConfirmBox';
import { Link } from 'react-router-dom';
import PostDataWithParams from '../services/PostDataWithParam';
import Storage from '../externaljs/Storage';
import react from 'react';
import GetData from '../services/GetData';

const initFormData = {
    id: 0,
    date_from: "",
    date_to: "",
    time_from: "",
    time_to: "",
    is_full: 0,
    is_morning: 0,
    is_evening: 0
}
class MySchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            allEventsForFullCalendar: [],
            selectedViewType: "bookedOrders",
            scheduleShow: [],
            offDays: [],
            hollydays: [],
            offers: [],
            isOfferShow: false,
            order_details: {
                request_start_date: '',
                request_end_date: '',
                booked: [],
                invited: [],
                order_from: []
            },
            form_data: { ...initFormData },
            schedule: {
                id: 0,
                order_request_detail_id: '',
                user_id: '',
                date_from: '',
                date_to: '',
                is_full: 0,
                is_morning: 0,
                is_evening: 0
            },
            loading: false,
            btnLoader: false,
            modalLoader: false,
            newOfferDetails: props.newOfferDetails,
            isNewOffer: !!props.isNewOffer,
            editPermission: true,
            manage_orders: true,
            offers_received: true,
            staff_user_id: '',
        }
    }

    staffMenuCheckInterval = null;
    subscriptionCheckInterval = null;

    async componentDidMount() {
        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        this.setState({ staff_user_id })
        this.subscriptionCheckInterval = setInterval(() => {
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1)
                this.setState({ editPermission: false })
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ editPermission: true })
        }, 1000);
        if (this.state.isNewOffer) {
            this.getAllEventsByDateRange();


        } else {
            this.getOffDays();
        }

        this.staffMenuCheckInterval = setInterval(() => {
            if (Storage.get('mmcStaffMenu')) {
                let custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
                this.setState({ custom_menu }, () => {
                    // console.log(this.state.custom_menu)

                    let find_offer_received = this.state.custom_menu.find(menu => menu.title == "Offers Received")
                    if (find_offer_received) {
                        if (find_offer_received.is_add == 1 || find_offer_received.is_add == '1')
                            this.setState({ offers_received: true })
                        else
                            this.setState({ offers_received: false })
                    }
                    else this.setState({ offers_received: false })

                    let find_reloOrders = this.state.custom_menu.find(menu => menu.title == "ReloOrders")
                    if (find_reloOrders) {
                        if (find_reloOrders.is_add == 1 || find_reloOrders.is_add == '1')
                            this.setState({ manage_orders: true })
                        else
                            this.setState({ manage_orders: false })
                    }
                    else this.setState({ manage_orders: false })

                });
            }
        }, 1000)
    }

    componentWillUnmount() {
        window.$('.tooltip').removeClass('in')
        if (this.staffMenuCheckInterval) {
            clearInterval(this.staffMenuCheckInterval)
        }
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
    }

    doSubscribe = (e) => {
        window.$('#order_details').modal('hide');
        window.swal({
            title: "Warning!",
            text: "Please upgrade your membership to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }

    getAllEventsByDateRange = () => {
        let startDate = window.moment(this.state.newOfferDetails.start).subtract(2, 'days').format('YYYY-MM-DD');
        let endDate = window.moment(this.state.newOfferDetails.end).add(2, 'days').format('YYYY-MM-DD');
        this.setState({ loading: true })
        return PostDataWithParams('getAllEventsByDateRange', {
            date_from: startDate,
            date_to: endDate,
        })
            .then(response => {
                if (response.success === 1) {
                    const { schedulejob_list, holiday_list, offday_list, offer_list } = response.data;
                    let scheduleList = schedulejob_list.map(item => this.updateWeekViewEvent(item, "ORDER"));
                    let holidayList = holiday_list.map(item => this.updateWeekViewEvent(item, "HOLIDAY"))
                    let offdayList = offday_list.map(item => this.updateWeekViewEvent(item, "OFFDAY"));
                    let offerList = offer_list.map(item => this.updateWeekViewEvent(item, "OFFER"));
                    let openOffer = offerList.find(event => event.id == this.state.newOfferDetails.order_id);
                    offerList = offerList.filter(event => event.id != this.state.newOfferDetails.order_id);
                    this.setState({ events: [openOffer, ...scheduleList, ...holidayList, ...offdayList, ...offerList], loading: false }, () => {
                        // console.log("#######", JSON.stringify(this.state.events));
                        this.initWeekViewCalender();

                    })
                } else {
                    this.setState({ events: [], loading: false })
                }
            })
            .catch(err => {
                console.log(err)
                this.setState({ events: [], loading: false });
            }).finally(() => {

            })
    }
    updateWeekViewEvent = (item, eventType) => {
        let event = { ...item };
        let eventDateFormat = date => window.moment(date).format("ddd MMM DD YYYY 00:00:00");
        let dateRangeFormat = date => window.moment(date).format('MM-DD-YYYY');
        if (eventType == "ORDER") {
            let customerName = !!event.customer_first_name && event.customer_first_name + ' ' + !!event.customer_last_name && event.customer_last_name
            event.color = "#93d3cd";
            if (event.is_morning == 1) {
                event.event_class = 'morning';
            }
            if (event.is_full == 1) {
                event.event_class = 'full-day';
            }
            if (event.is_evening == 1) {
                event.event_class = 'evening';
            }
            event.eventType = "ORDER";
            event.start = eventDateFormat(event.date_from);
            event.end = eventDateFormat(event.date_to);
            event.title = `${event.title} &nbsp
                        <b>Customer -</b>  ${customerName} (${event.destination_address}) &nbsp 
                        <b>Services -</b> ${event.services_needed}`;
            event.tooltip_text = `<div>
                            <b>Customer -</b>  ${customerName} <br/>
                            <b>Address -</b>        ${event.destination_address} <br/>
                            <b>Services -</b> ${event.services_needed} <br/>
                            <b>Schedule Range - </b> ${dateRangeFormat(event.date_from)} - ${dateRangeFormat(event.date_to)}
                       <div>`;
        }
        if (eventType == "OFFER") {
            let offer_from = event.posted_by;
            if (event.offer_from_profile_name && event.offer_from_profile_name.toUpperCase() == "CUSTOMER" && offer_from != '') {
                let arr = offer_from.split(' ');
                if (arr.length > 1)
                    offer_from = `${arr[0]} ${arr[1].substr(0, 1).toUpperCase()}`
            }
            event.eventType = "OFFER";
            event.color = event.id == this.state.newOfferDetails.order_id ? "#F39926" : "#f7b867";
            event.event_class = event.id == this.state.newOfferDetails.order_id ? "new-offer active" : "new-offer";
            event.start = eventDateFormat(event.request_start_date);
            event.end = eventDateFormat(event.request_end_date);
            event.title = `New Offer from <b>${offer_from} (${event.offer_from_profile_name})<b>`;
            event.tooltip_text = `<div style=text-align:left>
                            New Offer from <b>${offer_from} (${event.offer_from_profile_name})<b>
                            <br/>
                            <b>Offer Range - </b> ${dateRangeFormat(event.request_start_date)} - ${dateRangeFormat(event.request_end_date)}
                        <div>`;
        }
        if (eventType == "OFF_DAY") {
            event.start = eventDateFormat(event.date_from);
            event.end = eventDateFormat(event.date_to);
            event.eventType = "OFF_DAY";
            event.color = "#bcbfc0";
            if (event.is_morning == 1) {
                event.event_class = 'morning';
            }
            if (event.is_full == 1) {
                event.event_class = 'fullday';
            }
            if (event.is_evening == 1) {
                event.event_class = 'evening';
            }
        }
        return event;
    }

    getAvailability = () => {
        console.log("getAvailability  Start  events", this.state.events)
        this.setState({ loading: true }, () => {
            PostDataWithParam('GetAvailableDates', {})
                .then(response => {
                    if (response.success === 1) {
                        let res_data = response.data;

                        response.data.map((item, i) => {
                            if (res_data[i].is_morning == 1) {
                                res_data[i].color = '#8FBC8F'
                                res_data[i].title = 'Available AM'
                            }
                            if (res_data[i].is_evening == 1) {
                                res_data[i].color = '#008000'
                                res_data[i].title = 'Available PM'
                            }
                            res_data[i].modal_type = 'availability';
                            var end_date = new Date(res_data[i].date_to);
                            var end_date1 = end_date.setDate(end_date.getDate() + 1);
                            res_data[i].date_to = DateFormat.getFormattedDateTime(end_date1, 'm/d/Y');
                            res_data[i].end = DateFormat.getFormattedDateTime(end_date1, 'm/d/Y');
                        })
                        let state_events = this.state.events

                        this.setState({ events: res_data }, () => {
                            console.log("getAvailability  Success  events", res_data, this.state.events)
                            this.getEvents();
                        })
                    } else {
                        this.getEvents();
                        console.log("getAvailability  Success else  events", response, this.state.events)
                    }
                })
                .catch(err => {
                    this.getEvents();
                    console.log("getAvailability  Catch Error  events", err, this.state.events)
                })
        });
    }

    getEvents = () => {
        console.log("getEvents  Start  events", this.state.events)
        this.setState({ loading: true }, () => {
            PostDataWithParam('GetEvents', { type: '1' })
                .then(response => {
                    if (response.success === 1) {
                        let state_events = this.state.events
                        console.log("getEvents  Then  events", response.data, this.state.events)
                        this.setState({ events: [...state_events, ...response.data], loading: false }, () => {
                            this.initFullcalendar();
                        })
                    } else {
                        this.setState({ loading: false }, () => {
                            this.initFullcalendar();
                        })
                    }
                })
                .catch(err => {
                    this.setState({ loading: false }, () => {
                        this.initFullcalendar();
                    })
                })
        });
    }

    gotoDate = (date) => {
        try {
            let m = window.moment(date); //YYYY-mm-dd
            window.$('.fullcalendar-basic').fullCalendar('gotoDate', m);
        } catch (err) {
            console.log(err)
        }
    }
    moreBtnCoordinate = [];
    initFullcalendar = () => {
        let dt = new Date();
        let obj = this;
        let events = [...this.state.allEventsForFullCalendar, ...this.state.offDays, ...this.state.hollydays];
        // const formatEvents = events.map(event => ({
        //     ...event,
        //     rendering: "background",
        //     allDay: true,
        //     color: undefined
        // }))

        window.$('.fullcalendar-basic').fullCalendar({
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,basicWeek,basicDay'
            },
            defaultDate: dt,
            editable: true,
            eventOverlap: false,
            events: events,
            defaultView: this.state.isNewOffer ? 'basicWeek' : 'month',
            height: this.state.isNewOffer ? 600 : undefined,
            eventRender: (event, el, view) => {
                // if (!(event.statrt && event.start._d) || !(event.end && event.end._d)) return false;
                try {
                    var tooltipTitle = event.title;
                    let startDate = event.start._d;
                    let endDate = event.end._d;
                    let startDateFormat = window.moment(startDate).format('MM-DD-YYYY');
                    let endDateFormat = window.moment(endDate).subtract(1, 'day').format('MM-DD-YYYY');
                    let packDateFormat = window.moment(event.requested_pack_date).format('MM-DD-YYYY');
                    let displayEvent = false;
                    const addColor = (color) => {
                        el.css({ 'background-color': color, "border-color": color });
                    }
                    if (event.eventType == "OFFER") {
                        displayEvent = true;
                        let offer_from = event.posted_by;
                        if (event.offer_from_profile_name && event.offer_from_profile_name.toUpperCase() == "CUSTOMER" && offer_from != '') {
                            let arr = offer_from.split(' ');
                            if (arr.length > 1)
                                offer_from = `${arr[0]} ${arr[1].substr(0, 1).toUpperCase()}`
                        }

                        tooltipTitle = `<div style="text-align:left;">
                            New Offer from <b>${offer_from} (${event.offer_from_profile_name})<b>
                            <br/>
                            <b>Offer Range - </b> ${startDateFormat} - ${endDateFormat}
                        <div>`;

                        let fcTitle = `New Offer from <b>${offer_from} (${event.offer_from_profile_name})<b>`;

                        el.find(".fc-title").html(fcTitle)
                        // offer color handle
                        addColor("#F39926");


                    }
                    // ----------------------------------- SCHEDULE EVENT HANDLE -------------------------------
                    if (event.eventType == "ORDER") {
                        displayEvent = true;
                        let customerName = `${!!event.customer_first_name && event.customer_first_name} ${!!event.customer_last_name && event.customer_last_name}`

                        tooltipTitle = `<div style="text-align:left;">
                        <b>Customer -</b>  ${customerName} <br/>
                        ${event.requested_pack_date != null ? ` <b>PD -</b>  ${packDateFormat} <br/>` : ''}
                        ${startDateFormat != null ? `<b>LD -</b>  ${startDateFormat} <br/>` : ''}
                        ${endDateFormat != null ? `<b>DD -</b>   ${endDateFormat} <br/>` : ''} 
                        ${event.source_address != null ? `<b>Origin -</b>  ${event.source_address} <br/>` : ''}
                        ${event.destination_address != null ? `<b>Destination  -</b>  ${event.destination_address} <br/>` : ''}
                        <b>Services -</b> ${event.services_needed} <br/>
                        ${event.created_by_self == 1 ? "<b>Created by - </b> Self" : ''}
                        <div>`;
                        // let fcTitle = `${event.title} <br/>&nbsp &nbsp &nbsp
                        //     <b>Customer Name -</b>  ${customerName}
                        //     <b>Address -</b>        ${event.destination_address}
                        //     <b>Service Nedded -</b> ${event.services_needed}`;
                        let fcTitle = `
                        <b>Customer -</b>  ${customerName} &nbsp 
                        ${event.requested_pack_date != null ? ` <b>PD -</b>  ${packDateFormat} ` : ''} &nbsp
                        ${startDateFormat != null ? `<b>LD -</b>  ${startDateFormat} ` : ''} &nbsp
                        ${endDateFormat != null ? `<b>DD -</b>   ${endDateFormat} ` : ''} &nbsp 
                        ${event.source_address != null ? `<b>Origin -</b>  ${event.source_address} ` : ''} &nbsp
                        ${event.destination_address != null ? `<b>Destination  -</b>  ${event.destination_address} ` : ''} &nbsp 
                        <b>Services -</b> ${event.services_needed}`;
 
                        el.find(".fc-title").html(fcTitle)
                        // schedule color handle
                        if (this.state.isNewOffer) {
                            addColor("#93d3cd");
                        } else {
                            addColor("#26A69A");
                        }
                        if (event.is_morning == 1) {

                            el.addClass('morning-event');
                            // tooltipTitle = 'Morning Schedule';  
                        }
                        if (event.is_full == 1) {
                            el.addClass('fullday-event');
                            // tooltipTitle = 'Fullday Schedule';
                        }
                        if (event.is_evening == 1) {
                            el.addClass('evening-event');
                            // tooltipTitle = 'Evening Schedule';
                        }
                    }
                    // ---------------------------------------------END------------------------------------------
                    if (event.eventType == "NEED_RESOURCES") {
                        displayEvent = true;
                        let customerName = `${!!event.customer_first_name && event.customer_first_name} ${!!event.customer_last_name && event.customer_last_name}`

                        tooltipTitle = `<div style="text-align:left;">
                        <b>Customer -</b>  ${customerName} <br/>
                        ${event.requested_pack_date && ` <b>PD -</b>  ${packDateFormat} <br/>`}
                        <b>LD -</b>  ${startDateFormat} <br/> 
                        <b>DD -</b>   ${endDateFormat} <br/> 
                        <b>Origin -</b>  ${event.source_address} <br/> 
                        <b>Destination  -</b>  ${event.destination_address} <br/> 
                        <b>Services -</b> ${event.services_needed} <br/>
                        ${event.created_by_self == 1 ? "<b>Created by - </b> Self" : ''}
                    <div>`;
                        // let fcTitle = `${event.title} <br/>&nbsp &nbsp &nbsp
                        //     <b>Customer Name -</b>  ${customerName}
                        //     <b>Address -</b>        ${event.destination_address}
                        //     <b>Service Nedded -</b> ${event.services_needed}`;
                        let fcTitle = `
                        <b>Customer -</b>  ${customerName} &nbsp 
                       ${event.requested_pack_date && ` <b>PD -</b>  ${packDateFormat} &nbsp`}
                        <b>LD -</b>  ${startDateFormat} &nbsp 
                        <b>DD -</b>   ${endDateFormat} &nbsp 
                        <b>Origin -</b>  ${event.source_address} &nbsp 
                        <b>Destination  -</b>  ${event.destination_address} &nbsp 
                        <b>Services -</b> ${event.services_needed}`;

                        el.find(".fc-title").html(fcTitle)
                        // schedule color handle
                        if (this.state.isNewOffer) {
                            addColor("#93d3cd");
                        } else {
                            addColor("#039be5");
                        }
                        if (event.is_morning == 1) {

                            el.addClass('morning-event');
                            // tooltipTitle = 'Morning Schedule';  
                        }
                        if (event.is_full == 1) {
                            el.addClass('fullday-event');
                            // tooltipTitle = 'Fullday Schedule';
                        }
                        if (event.is_evening == 1) {
                            el.addClass('evening-event');
                            // tooltipTitle = 'Evening Schedule';
                        }
                    }

                    if (event.eventType == "HOLYDAY") {
                        displayEvent = true;
                        // addColor("#EF5350");
                        el.addClass('fc-bg-holiday').append(`
                        <div>${event.title}</div>`);
                    }

                    if (event.eventType == "OFF_DAY") {
                        displayEvent = true;
                        if (this.state.isNewOffer) {
                            addColor("#bcbfc0");
                        } else {
                            addColor("#797f81");
                        }
                        if (event.is_morning == 1) {
                            el.addClass('morning-event');
                        }
                        if (event.is_full == 1) {
                            el.addClass('fullday-event');
                        }
                        if (event.is_evening == 1) {
                            el.addClass('evening-event');
                        }
                    }

                    window.$(el).tooltip({
                        title: tooltipTitle,
                        placement: 'top',
                        container: 'body',
                        html: true,

                    });
                    return displayEvent;
                } catch (err) {
                    console.log("MySchedule.js", err)
                    return false;
                }

            },
            eventAfterRender: function (event, el) {
                let customerName = `${!!event.customer_first_name && event.customer_first_name} ${!!event.customer_last_name && event.customer_last_name}`
                let contentWidth = window.$(el)
                    .width();
                // console.log("contentWidth", contentWidth, event.start)
                if (event.eventType === "ORDER" && contentWidth < 160) {
                    el.find(".fc-title").html(`<b>Customer -</b>  ${customerName}`);
                }
            },
            dayClick: function (date, jsEvent, view) {
                if (obj.props.isOffCalenderEvent) return;
                let form_data = {
                    id: 0,
                    date_from: DateFormat.getFormattedDateTime(date._d, 'm/d/Y'),
                    date_to: DateFormat.getFormattedDateTime(date._d.setDate(date._d.getDate() + 1), 'm/d/Y'),
                    time_from: "",
                    time_to: "",
                    is_full: 0,
                    is_morning: 0,
                    is_evening: 0
                }

                // -------------------- handel Order Details Modal ------------------------
                if (jsEvent.target.classList.contains("more-btn")) {

                    return false;
                } else {
                    if (obj.state.editPermission) {
                        obj.setState({ form_data: form_data }, () => {
                            window.$('#add_offday').modal('show');
                        });
                    }
                    else {

                        window.swal({
                            title: "Warning!",
                            text: "Please upgrade your membership to complete this action",
                            className: "subscrip-info-swal",
                            icon: '',
                        })
                        obj.props.history.push("/independent-mover/dashboard/my-subscription");
                    }
                }
                // -------------------- handel Order Details Modal ------------------------

                // window.$('#add_availability').modal({
                //     backdrop: 'static',
                //     keyboard: false
                // });
            },
            eventClick: function (event, el) {
                if (obj.props.isOffCalenderEvent) return;
                if (event.modal_type == 'availability') {
                    let form_data = {
                        date_from: event.date_from,
                        date_to: event.date_to,
                        time_from: event.time_from,
                        time_to: event.time_to,
                        is_full: event.is_full,
                        is_morning: event.is_morning,
                        is_evening: event.is_evening,
                        id: event.id
                    }
                    obj.setState({ form_data: form_data }, () => {
                        window.$('#add_availability').modal('show');
                    });
                    return false;
                }
                // order details modal handle
                if (event.order_id && event.modal_type == 'order-details') {
                    window.$('#order_details').modal('show');
                    obj.getOrderDetails(event.order_id)
                }
                // offer details modal handle
                if (event.id && event.modal_type == 'offer-details') {
                    window.$('#order_details').modal('show');
                    obj.getOrderDetails(event.id, 'received')
                }

            }
        });


        window.$('#add_offday').on('shown.bs.modal', function () {
            window.$('#date_from').daterangepicker({
                singleDatePicker: true,
                autoClose: true,
                showDropdowns: true
            }).on('apply.daterangepicker', (ev, picker) => {
                let form_data = obj.state.form_data;
                form_data['date_from'] = picker.startDate.format('MM/DD/YYYY');
                obj.setState({ form_data: form_data });
            });

            window.$('#date_to').daterangepicker({
                singleDatePicker: true,
                autoClose: true,
                showDropdowns: true
            }).on('apply.daterangepicker', (ev, picker) => {
                let form_data = obj.state.form_data;
                form_data['date_to'] = picker.startDate.format('MM/DD/YYYY');
                obj.setState({ form_data: form_data });
            });

            window.$('#time_from').pickatime({
                formatSubmit: 'HH:i',
                hiddenName: true,
                onSet: function (context) {
                    let form_data = obj.state.form_data;
                    form_data['time_from'] = window.$('#time_from').val();
                    obj.setState({ form_data: form_data });
                }
            });

            window.$('#time_to').pickatime({
                formatSubmit: 'HH:i',
                hiddenName: true,
                onSet: function (context) {
                    let form_data = obj.state.form_data;
                    form_data['time_to'] = window.$('#time_to').val();
                    obj.setState({ form_data: form_data });
                }
            });

        });
    }

    initWeekViewCalender = () => {
        let startDate = window.moment(this.state.newOfferDetails.start).subtract(2, 'days').format('ll');
        let endDate = window.moment(this.state.newOfferDetails.end).add(2, 'days').format('ll');
        const weekViewWrap = window.$('.fullcalendar-basic');
        if (weekViewWrap && weekViewWrap.html() == '') {
            try {
                window.$('.fullcalendar-basic').weekview({
                    startDate: new Date(startDate),
                    endDate: new Date(endDate),
                    events: this.state.events,
                    eventHeight: 40,
                    weekviewBodyHeight: 450,
                    scrollDistance: 250,
                    navButtons: true
                });


                window.$('[data-toggle="tooltip"]').tooltip({
                    viewport: '.weekview-body',
                    placement: 'auto',
                    html: true
                });


            } catch (e) {
                console.log(e)
            }
        }
        let that = this;
        window.$(".events-col").on("click", function () {
            let id = window.$(this).attr("data-id");
            if (!!id) {
                window.$('#order_details').modal('show');
                that.getOrderDetails(id)
            }
        });

    }

    getOrderDetails = (id, type = 'accepted') => {
        this.setState({ modalLoader: true }, () => {
            PostDataWithParam('GetOrderDetailsForDriversAndHelpers', { order_id: id, type: 'accepted' })
                .then(response => {
                    if (response.success == 1) {
                        this.setState({ order_details: { ...response.data[0], type: type }, modalLoader: false });
                    } else {
                        this.setState({
                            order_details: {
                                booked: [],
                                invited: [],
                                order_from: []
                            }, modalLoader: false
                        })
                    }
                })
                .catch(err => {
                    this.setState({
                        order_details: {
                            booked: [],
                            invited: [],
                            order_from: []
                        }, modalLoader: false
                    })
                })
        });
    }

    formatOrdersData = (data, eventType) => {
        return data.map((item, i) => {
            let title = item.title;
            if (item.is_morning == 1) {
                title = 'Schedule AM'
            }
            if (item.is_evening == 1) {
                title = 'Schedule PM'
            }

            let end_date = new Date(item.date_to);
            let end_date1 = end_date.setDate(end_date.getDate() + 1);

            return {
                ...item,
                title,
                modal_type: 'order-details',
                eventType,
                date_to: DateFormat.getFormattedDateTime(end_date1, 'm/d/Y'),
                end: DateFormat.getFormattedDateTime(end_date1, 'm/d/Y')
            }

        })
    }

    formatNewOffersData = (data) => {
        return data.map((item, i) => {
            let end_date = new Date(item.request_end_date);
            let end_date1 = end_date.setDate(end_date.getDate() + 1);
            return {
                ...item,
                title: item.title,
                eventType: "OFFER",
                start: DateFormat.getFormattedDateTime(new Date(item.request_start_date), 'm/d/Y'),
                date_to: DateFormat.getFormattedDateTime(end_date1, 'm/d/Y'),
                end: DateFormat.getFormattedDateTime(end_date1, 'm/d/Y'),
                modal_type: 'offer-details'
            }
        })
    }

    getSchedule = () => {
        this.setState({ loading: true }, () => {
            PostDataWithParam('ViewScheduleJob', !!this.props.userId ? { user_id: this.props.userId } : {})
                .then(response => {
                    if (response.success === 1) {
                        this.setState({
                            allEventsForFullCalendar: this.formatOrdersData(response.data,"ORDER")
                        }, () => {
                            this.initFullcalendar();
                        })
                    } else {
                        this.initFullcalendar();
                    }
                })
                .catch(err => {
                    this.initFullcalendar();
                }).finally(() => {
                    this.setState({ loading: false }, () => {
                        this.initFullcalendar();
                    })
                })
        });
    }

    saveAvailability = (e) => {
        let form_data = this.state.form_data;
        form_data[e.target.name] = 1;
        this.setState({ form_data: form_data })
        this.setState({ btnLoader: true }, () => {

            PostDataWithParam('getAvailability', form_data)
                .then((response) => {
                    if (response.success === 1) {
                        if (response.data[0].is_full == 1) {
                            ConfirmBox("Available date exist for this date range.Are You want to proceed?")
                                .then((result) => {
                                    if (result) {
                                        PostDataWithParam('deleteAvailability', form_data)
                                            .then((response) => {
                                                if (response.success === 1) {
                                                    PostDataWithParam('PostAvailableDates', form_data)
                                                        .then((response) => {
                                                            if (response.success === 1) {
                                                                this.setState({ form_data: form_data, btnLoader: false }, () => {
                                                                    window.swal('Success!', 'Available date successfully saved.', 'success');
                                                                    window.$('#add_availability').modal('hide');
                                                                    let form_data = {
                                                                        id: 0,
                                                                        date_from: "",
                                                                        date_to: "",
                                                                        time_from: "",
                                                                        time_to: "",
                                                                        is_full: 0,
                                                                        is_morning: 0,
                                                                        is_evening: 0
                                                                    }
                                                                    this.setState({ form_data: form_data, btnLoader: false }, () => {
                                                                        this.getAvailability()
                                                                    })
                                                                })
                                                            }
                                                        })
                                                }
                                            })
                                    }
                                })
                        }

                        if (response.data[0].is_evening == 1) {
                            if ((form_data.is_evening == 1) || (form_data.is_full == 1)) {
                                ConfirmBox("Available date exist for this date range.Are You want to proceed?")
                                    .then((result) => {
                                        if (result) {
                                            PostDataWithParam('deleteAvailability', form_data)
                                                .then((response) => {
                                                    if (response.success === 1) {
                                                        PostDataWithParam('PostAvailableDates', form_data)
                                                            .then((response) => {
                                                                if (response.success === 1) {
                                                                    this.setState({ form_data: form_data, btnLoader: false }, () => {
                                                                        window.swal('Success!', 'Available date successfully saved.', 'success');
                                                                        window.$('#add_availability').modal('hide');
                                                                        let form_data = {
                                                                            id: 0,
                                                                            date_from: "",
                                                                            date_to: "",
                                                                            time_from: "",
                                                                            time_to: "",
                                                                            is_full: 0,
                                                                            is_morning: 0,
                                                                            is_evening: 0
                                                                        }
                                                                        this.setState({ form_data: form_data, btnLoader: false }, () => {
                                                                            this.getAvailability()
                                                                        })
                                                                    })
                                                                }
                                                            })
                                                    }
                                                })
                                        }
                                    })
                            }
                            else {
                                PostDataWithParam('PostAvailableDates', form_data)
                                    .then((response) => {
                                        if (response.success === 1) {
                                            this.setState({ form_data: form_data, btnLoader: false }, () => {
                                                window.swal('Success!', 'Available date successfully saved.', 'success');
                                                window.$('#add_availability').modal('hide');
                                                let form_data = {
                                                    id: 0,
                                                    date_from: "",
                                                    date_to: "",
                                                    time_from: "",
                                                    time_to: "",
                                                    is_full: 0,
                                                    is_morning: 0,
                                                    is_evening: 0
                                                }
                                                this.setState({ form_data: form_data, btnLoader: false }, () => {
                                                    this.getAvailability()
                                                })
                                            })
                                        }
                                    })
                            }
                        }

                        if (response.data[0].is_morning == 1) {
                            if ((form_data.is_full == 1) || (form_data.is_morning == 1)) {
                                ConfirmBox("Available date exist for this date range.Are You want to proceed?")
                                    .then((result) => {
                                        if (result) {
                                            PostDataWithParam('deleteAvailability', form_data)
                                                .then((response) => {
                                                    if (response.success === 1) {
                                                        PostDataWithParam('PostAvailableDates', form_data)
                                                            .then((response) => {
                                                                if (response.success === 1) {
                                                                    this.setState({ form_data: form_data, btnLoader: false }, () => {
                                                                        window.swal('Success!', 'Available date successfully saved.', 'success');
                                                                        window.$('#add_availability').modal('hide');
                                                                        let form_data = {
                                                                            id: 0,
                                                                            date_from: "",
                                                                            date_to: "",
                                                                            time_from: "",
                                                                            time_to: "",
                                                                            is_full: 0,
                                                                            is_morning: 0,
                                                                            is_evening: 0
                                                                        }
                                                                        this.setState({ form_data: form_data, btnLoader: false }, () => {
                                                                            this.getAvailability()
                                                                        })
                                                                    })
                                                                }
                                                            })
                                                    }
                                                })
                                        }
                                    })
                            }
                            else {
                                PostDataWithParam('PostAvailableDates', form_data)
                                    .then((response) => {
                                        if (response.success === 1) {
                                            this.setState({ form_data: form_data, btnLoader: false }, () => {
                                                window.swal('Success!', 'Available date successfully saved.', 'success');
                                                window.$('#add_availability').modal('hide');
                                                let form_data = {
                                                    id: 0,
                                                    date_from: "",
                                                    date_to: "",
                                                    time_from: "",
                                                    time_to: "",
                                                    is_full: 0,
                                                    is_morning: 0,
                                                    is_evening: 0
                                                }
                                                this.setState({ form_data: form_data, btnLoader: false }, () => {
                                                    this.getAvailability()
                                                })
                                            })
                                        }
                                    })
                            }
                        }

                    }
                    else {
                        PostDataWithParam('PostAvailableDates', form_data)
                            .then((response) => {
                                if (response.success === 1) {
                                    this.setState({ form_data: form_data, btnLoader: false }, () => {
                                        window.swal('Success!', 'Available date successfully saved.', 'success');
                                        window.$('#add_availability').modal('hide');
                                        let form_data = {
                                            id: 0,
                                            date_from: "",
                                            date_to: "",
                                            time_from: "",
                                            time_to: "",
                                            is_full: 0,
                                            is_morning: 0,
                                            is_evening: 0
                                        }
                                        this.setState({ form_data: form_data, btnLoader: false }, () => {
                                            this.getAvailability()
                                        })
                                    })
                                }
                            })
                    }

                })
                .catch(() => {
                    window.swal('Error!', 'Oops!Something went wrong.', 'error')
                    let form_data = {
                        id: 0,
                        date_from: "",
                        date_to: "",
                        time_from: "",
                        time_to: "",
                        is_full: 0,
                        is_morning: 0,
                        is_evening: 0
                    }
                    this.setState({ form_data: form_data, btnLoader: false }, () => {
                        this.getAvailability()
                    })
                })
        });
    }

    getOrderDetailsPage = () => {
        window.$('#order_details').modal('hide');
    }
    getFullAddress({ data, addressType, isHideHouseAddress = true }) {
        let houseAddress = addressType === 'primary_destination' ? data.destination_address : data.source_address;
        let fullAddressArr = !isHideHouseAddress && houseAddress ? [houseAddress] : [];
        const addValue = name => {
            if (!!data[`${addressType}_${name}`])
                fullAddressArr.push(data[`${addressType}_${name}`])
        }
        addValue('city')
        addValue('zip')
        addValue('state')
        addValue('country')
        return fullAddressArr.length > 0 ? fullAddressArr.join(',') : '---'
    }
    getOffDaysFormat = (data) => {
        return data.map((item, i) => {
            let end_date = new Date(item.date_to);
            let end_date1 = end_date.setDate(end_date.getDate() + 1);
            let title = "Off Day (Full)"
            if (item.is_morning == 1) {
                title = "Off Day (AM)"
            } else if (item.is_evening == 1) {
                title = "Off Day (PM)"
            }
            return {
                ...item,
                eventType: "OFF_DAY",
                title: title,
                start: DateFormat.getFormattedDateTime(new Date(item.date_from), 'm/d/Y'),
                date_to: DateFormat.getFormattedDateTime(end_date1, 'm/d/Y'),
                end: DateFormat.getFormattedDateTime(end_date1, 'm/d/Y'),
            }
        })

    }
    getOffDays = () => {
        this.setState({ loading: true }, () => {
            PostDataWithParam('GetOffDay', !!this.props.userId ? { user_id: this.props.userId } : {})
                .then(response => {
                    if (response.success === 1) {
                        this.setState({
                            offDays: [...this.getOffDaysFormat(response.data)]
                        }, () => {
                            this.initFullcalendar();
                        })
                    } else {
                        this.initFullcalendar();
                    }
                })
                .catch(err => {
                    this.initFullcalendar();
                }).finally(() => {
                    this.getHollydays();
                })
        });
    }
    saveOffday = (e) => {
        let form_data = { ...this.state.form_data, [e.target.name]: 1 };
        this.setState({ btnLoader: true, loading: true }, () => {

            PostDataWithParam('CreateOffDay', form_data)
                .then((response) => {
                    if (response.success === 1) {
                        window.$('#add_offday').modal('hide');
                        window.swal('Success!', 'Off day successfully saved.', 'success');
                        console.log("getOffDaysFormat", this.getOffDaysFormat([response.data]))
                        this.setState({
                            form_data: { ...initFormData },
                            offDays: [...this.state.offDays, ...this.getOffDaysFormat([response.data])],
                            btnLoader: false,
                            loading: false
                        }, () => {
                            this.initFullcalendar();
                            setTimeout(() => {
                                this.gotoDate(response.data.date_from)
                            }, 200)

                        })

                    }
                }).catch(() => {
                    this.setState({ loading: false }, () => { this.initFullcalendar(); });

                })
        })
    }
    getHollydays = () => {
        this.setState({ loading: true }, () => {
            PostDataWithParam('getHolidayList', {})
                .then(response => {
                    if (response.success === 1) {
                        let formatHollydays = response.data.map((item, i) => {
                            let end_date = new Date(item.date_to);
                            let end_date1 = end_date.setDate(end_date.getDate() + 1);
                            return {
                                ...item,
                                title: item.purpose,
                                eventType: "HOLYDAY",
                                start: DateFormat.getFormattedDateTime(new Date(item.date_from), 'm/d/Y'),
                                date_to: DateFormat.getFormattedDateTime(end_date1, 'm/d/Y'),
                                end: DateFormat.getFormattedDateTime(end_date1, 'm/d/Y'),
                                rendering: "background",
                                allDay: true,
                                color: undefined
                            }
                        })
                        this.setState({
                            hollydays: formatHollydays
                        }, () => {
                            this.initFullcalendar();
                        })
                    }
                    else {
                        this.initFullcalendar();
                    }
                })
                .catch(err => {
                    this.initFullcalendar();
                }).finally(() => {
                    this.getFullCalendarEventListByType();
                })
        });
    }
    getOffers = () => {
        this.setState({ loading: true }, () => {
            PostDataWithParam('GetAllOfferList', {})
                .then(response => {
                    if (response.success === 1) {
                        this.setState({
                            allEventsForFullCalendar: this.formatNewOffersData(response.data)
                        }, () => {
                            this.initFullcalendar();
                        })
                    }
                    else {
                        this.initFullcalendar();
                    }
                })
                .catch(err => {
                    console.log(err)
                }).finally(() => {
                    this.setState({ loading: false }, () => {
                        this.initFullcalendar();
                    });

                })
        });
    }
    getNeedResources = () => {
        this.setState({ loading: true }, () => {
            PostDataWithParam('NeedResources', {})
                .then(response => {
                    if (response.success === 1) {
                        this.setState({ allEventsForFullCalendar: this.formatOrdersData(response.data,"NEED_RESOURCES") });
                    }
                    else {
                        this.initFullcalendar();
                    }
                })
                .catch(err => {
                    console.log(err)
                }).finally(() => {
                    this.setState({ loading: false }, () => {
                        this.initFullcalendar();
                    });

                })
        });
    }
    getAllOrders = () => {
        this.setState({ loading: true }, () => {
            PostDataWithParam('AllOrders', {})
                .then(response => {
                    if (response.success === 1) {
                        const newOffers = this.formatNewOffersData(response.data.new_offers);
                        const bookedOrders = this.formatOrdersData(response.data.booked_orders, "ORDER");
                        const needResources = this.formatOrdersData(response.data.need_resources, "NEED_RESOURCES");

                        this.setState({
                            allEventsForFullCalendar: [...newOffers, ...bookedOrders, ...needResources]
                        }, () => {
                            this.initFullcalendar();
                        })
                    }
                    else {
                        this.initFullcalendar();
                    }
                })
                .catch(err => {
                    console.log(err)
                }).finally(() => {
                    this.setState({ loading: false }, () => {
                        this.initFullcalendar();
                    });

                })
        });
    }


    handleSwitch = (e) => {
        this.setState({ isOfferShow: e.target.checked }, () => {
            window.$(".fullcalendar-basic").fullCalendar('rerenderEvents');
        });
    }
    changeViewType = (e) => {
        this.setState({ selectedViewType: e.target.value }, () => {
            this.getFullCalendarEventListByType();
        })
    }
    getFullCalendarEventListByType = () => {
        const { selectedViewType } = this.state;
        switch (selectedViewType) {
            case "bookedOrders": { this.getSchedule(); break; }
            case "newOffers": { this.getOffers(); break; }
            case "needResources": { this.getNeedResources(); break; }
            default: this.getAllOrders()
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className={!!this.props.userId}>
                  
                    <div className="row">
                        <div className="col-lg-6 col-md-4">
                            <h1 className="m-t-30">{this.props.calenderTitle || 'My Schedule'}</h1>

                        </div>
                        <div className="col-lg-6 col-md-8 text-right">
                            <div className="availabilitylist">
                                <div className="display-inline-block m-l-10">
                                    <h6 className="f-s-13">
                                        <span className=""><i className="icon-square position-left" style={{ color: '#26A69A' }}></i> Booked Order</span>
                                    </h6>
                                </div>
                                <div className="display-inline-block m-l-10">
                                    <h6 className="f-s-13">
                                        <span className=""><i className="icon-square position-left" style={{ color: '#EF5350' }}></i>Holiday</span>
                                    </h6>
                                </div>
                                <div className="display-inline-block m-l-10">
                                    <h6 className="f-s-13">
                                        <span className=""><i className="icon-square position-left" style={{ color: '#797F81' }}></i>Off Day</span>
                                    </h6>
                                </div>
                                <div className="display-inline-block m-l-10">
                                    <h6 className="f-s-13">
                                        <span className=""><i className="icon-square position-left" style={{ color: '#F39926' }}></i>New Offer</span>
                                    </h6>
                                </div>
                                <div className="display-inline-block m-l-10">
                                    <h6 className="f-s-13">
                                        <span className=""><i className="icon-square position-left" style={{ color: '#039be5' }}></i>Need Resources</span>
                                    </h6>
                                </div>
                            </div>
                            <div className="schedule-switch m-b-15 m-t-15">
                                <div class="form-group m-b-0">
                                    <select className="form-control" onChange={this.changeViewType} value={this.state.selectedViewType}>
                                        <option value="all">All</option>
                                        <option value="bookedOrders">Booked Order</option>
                                        <option value="newOffers">New Offers</option>
                                        <option value="needResources">Need Resources</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="clearfix" id="relo-calender-content">
                        {
                            this.state.loading
                            &&
                            <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                        }
                        {
                            !this.state.loading
                            &&
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="panel panel-flat">

                                        <div className="panel-body">
                                            <div className="fullcalendar-basic no-border"></div>
                                            {/* <div className="week-view"></div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {/* <React.Fragment>
                    <div className="modal fade" ref={"add_ava"} id="add_availability" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                    <h4 className="modal-title" id="myModalLabel">Add Availability</h4>
                                </div>
                                <div className="modal-body">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="control-label">Date From</label>
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="icon-calendar22"></i></span>
                                                    <input type="text" id="date_from" className="form-control availability-daterange-single" value={this.state.form_data.date_from} placeholder="Enter requested start date" name="date_from" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="control-label">Date To</label>
                                                <div className="input-group">
                                                    <span className="input-group-addon"><i className="icon-calendar22"></i></span>
                                                    <input type="text" id="date_to" className="form-control availability-daterange-single" value={this.state.form_data.date_to} placeholder="Enter requested start date" name="date_to" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <button title="" type="button" className="btn btn-success btn-xs m-r-5" name="is_full" data-dismiss="modal" onClick={this.saveAvailability} value="1" >Full Day Available</button>
                                        <button title="" type="button" className="btn btn-success btn-xs m-r-5" name="is_morning" data-dismiss="modal" onClick={this.saveAvailability} value="1" ><img src="assets/images/icons/white/sunrise.svg" width="20" /> &nbsp; Available AM </button>
                                        <button title="" type="button" className="btn btn-success btn-xs" name="is_evening" data-dismiss="modal" onClick={this.saveAvailability} value="1" ><img src="assets/images/icons/white/sunset.svg" width="20" /> &nbsp; Available PM</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment> */}

                {/* -----------------------------------  Order Details Modal ----------------------------------------------- */}
                <div className="modal fade" id="order_details" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog modal-lg" role="document">
                        {
                            this.state.modalLoader
                            &&
                            <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                        }
                        {
                            !this.state.modalLoader
                            &&
                            <div className="modal-content">
                                <div className="modal-header clearfix p-t-10 p-b-10">
                                    <h6 className="modal-title pull-left m-t-10" id="myModalLabel">Relo # : <span className="text-warning">{this.state.order_details.order_id}</span></h6>
                                    <div className="pull-right">
                                        {this.state.editPermission ?
                                            <React.Fragment>
                                                {this.state.staff_user_id == '' ?
                                                    <Link to={{
                                                        pathname: this.state.order_details.type === 'received' ? '/moving-company/dashboard/my-offer-details' : '/moving-company/dashboard/order-details',
                                                        state: {
                                                            id: this.state.order_details.id,
                                                            type: this.state.order_details.type,
                                                            request_from: this.state.order_details.request_from
                                                        }
                                                    }} className="btn btn-primary btn-xs" onClick={this.getOrderDetailsPage}>View Full Info &nbsp;<i className="icon-share2"></i>
                                                    </Link>
                                                    :
                                                    <React.Fragment>
                                                        {(this.state.manage_orders || this.state.offers_received) &&
                                                            <Link to={{
                                                                pathname: this.state.order_details.type === 'received' ? '/moving-company/dashboard/my-offer-details' : '/moving-company/dashboard/order-details',
                                                                state: {
                                                                    id: this.state.order_details.id,
                                                                    type: this.state.order_details.type,
                                                                    request_from: this.state.order_details.request_from
                                                                }
                                                            }} className="btn btn-primary btn-xs" onClick={this.getOrderDetailsPage}>View Full Info &nbsp;<i className="icon-share2"></i>
                                                            </Link>}
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                            :
                                            <Link to="/moving-company/dashboard/my-sub" className="btn btn-primary btn-xs" onClick={this.doSubscribe}>View Full Info &nbsp;<i className="icon-share2"></i>
                                            </Link>
                                        }
                                        <a href="#" className="btn btn-default btn-xs m-l-15" data-dismiss="modal" aria-label="Close">Close</a>
                                    </div>
                                </div>
                                <hr className="m-0" />
                                <div className="modal-body">
                                    <React.Fragment>
                                        <h6 className="text-success">Customer Info</h6>
                                        <div className="row">


                                            {false ?
                                                (<div className="col-md-4">
                                                    <label className="text-bold m-b-0">Customer Name</label>
                                                    {/* customer first name and last initial first */}
                                                    <p className="m-b-20">{this.state.order_details.customer_first_name || "---"}  {this.state.details.customer_last_name && this.state.details.customer_last_name.substr(0, 1)}</p>
                                                </div>
                                                ) :
                                                (
                                                    <React.Fragment>
                                                        <div className="col-md-4">
                                                            <label className="text-bold m-b-0">Customer First Name</label>
                                                            <p className="m-b-20">{this.state.order_details.customer_first_name || "---"}</p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="text-bold m-b-0">Customer Last Name</label>
                                                            <p className="m-b-20">{this.state.order_details.customer_last_name || "---"}</p>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            }
                                        </div>
                                        <hr />
                                    </React.Fragment>

                                    <h6 className="text-success m-t-0">Service Info</h6>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Customer Estimated Weight</label>
                                            <p className="m-b-20">{this.state.order_details.customer_estimated_weight || "---"}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Requested Pack Date:</label>
                                            <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.order_details.requested_pack_date, 'm-d-Y')}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Requested Pickup Date</label>
                                            <div>
                                                <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.order_details.request_start_date, 'm-d-Y')}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Requested Delivery Date</label>
                                            <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.order_details.request_end_date, 'm-d-Y')}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Primary Pickup Origin Address</label>
                                            <p className="m-b-20">
                                                {
                                                    this.getFullAddress(
                                                        {
                                                            data: this.state.order_details,
                                                            addressType: "primary_pickup",
                                                            isHideHouseAddress: false
                                                        })
                                                }
                                            </p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Primary Destination Delivery Address</label>
                                            <p className="m-b-20">
                                                {
                                                    this.getFullAddress(
                                                        {
                                                            data: this.state.order_details,
                                                            addressType: "primary_destination",
                                                            isHideHouseAddress: false
                                                        })
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0"># MyReloPro's Requested On Site</label>
                                            <p className="m-b-20">{this.state.order_details.total_manpower_needed || "---"}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Special Items</label>
                                            <p className="m-b-20">{this.state.order_details.special_items || "---"}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Firearms</label>
                                            <p className="m-b-20">{this.state.order_details.firearms || "---"}</p>
                                        </div>
                                    </div>
                                    <div className="row">


                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Vehicles</label>
                                            <p className="m-b-20">{this.state.order_details.vehicles || "---"}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Remarks</label>
                                            <p className="m-b-20">{this.state.order_details.remarks || "---"}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Service Needed</label>
                                            <p className="m-b-20">{this.state.order_details.services_needed || "---"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                    </div>
                </div>
                {/* ------------------------------------------------------------------------------------------------------------ */}

                {/* ------------------------------------   Offday Add Modal    ---------------------------------------- */}
                <div className="modal fade" id="add_offday" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title" id="myModalLabel">Add Offday</h4>
                            </div>
                            <div className="modal-body">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">Date From</label>
                                            <div className="input-group">
                                                <span className="input-group-addon"><i className="icon-calendar22"></i></span>
                                                <input type="text" id="date_from" className="form-control availability-daterange-single" value={this.state.form_data.date_from} placeholder="Enter requested start date" name="date_from" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="control-label">Date To</label>
                                            <div className="input-group">
                                                <span className="input-group-addon"><i className="icon-calendar22"></i></span>
                                                <input type="text" id="date_to" className="form-control availability-daterange-single" value={this.state.form_data.date_to} placeholder="Enter requested start date" name="date_to" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <button title="" type="button" className="btn btn-primary btn-xs m-r-5" name="is_full" data-dismiss="modal" onClick={this.saveOffday} value="1" ><img src="assets/images/icons/full-day.svg" width="20" /> &nbsp;Full Day Off</button>
                                    <button title="" type="button" className="btn btn-primary btn-xs m-r-5" name="is_morning" data-dismiss="modal" onClick={this.saveOffday} value="1" ><img src="assets/images/icons/morning.svg" width="20" /> &nbsp;AM Off</button>
                                    <button title="" type="button" className="btn btn-primary btn-xs" name="is_evening" data-dismiss="modal" onClick={this.saveOffday} value="1" ><img src="assets/images/icons/evening.svg" width="20" /> &nbsp;PM Off</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/*--------------------------------------------------------------------------------------------------------------  */}
            </React.Fragment>

        )
    }
}

export default MySchedule;