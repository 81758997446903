import React, { Component } from 'react';
import PostDataWithParam from '../services/PostDataWithParam';
import Breadcumb from '../externaljs/Breadcrumb';
import Storage from '../externaljs/Storage';

class ChangePassword extends Component {
    state = { 
        fields: {
            profile_id:(JSON.parse(Storage.get('imnLoginData'))).profile_id,
            new_password:'',
            old_password:'',
            confirm_password:''
        },
        error: {
            old_password_err: '',
            new_password_err: '',
            confirm_password_err:''
        },
        ChnagePasswordStatus: false,
        PasswordErr:'',
        PasswordSuccess:'',
        processing: false,
        breadcrumb:[
            {title:'Dashboard',link:'/independent-mover/dashboard/home',class:''},
            {title:'Change Password',link:'',class:'active'}
        ]
     }

    
     changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields });
    }

    resetError = (cb) => {
        let error = {
            old_password_err: '',
            new_password_err: '',
            confirm_password_err:''
        }
        this.setState({ error: error }, () =>{
            cb();
        });
    }

    handlePasswordChange = () => {
        this.resetError(() => {
            let error = this.state.error;
            if (!this.state.fields.old_password) {
                error.old_password_err = 'Please enter current passowrd';
            } 
            else if (!this.state.fields.new_password) {  
                error.new_password_err = 'Please enter new passowrd';
            } else if (!this.state.fields.confirm_password) {  
                error.confirm_password_err = 'Please enter confirm passowrd.';
            } 
            else {   
                this.setState({processing: true}, () => {
                    let fields = this.state.fields;
                    this.setState({ fields: fields });
                    PostDataWithParam('ResetPassword',this.state.fields)
                    .then( (response) => {
                        let result = response;
                        if(result.success === 1){
                            this.setState({ PasswordSuccess : result.msg },()=>{
                                this.setState({ ChnagePasswordStatus: true,PasswordErr : '' });   
                            });
                        } else {
                            this.setState({ PasswordErr : result.msg,PasswordSuccess : '' });
                        }
                        this.setState({processing: false});
                    })
                })         

            }
            this.setState({ error: error });
        });  
    }

    showAlert = (errMsg) => {
        if (errMsg != '') {
            return (
                <div className="alert alert-danger"><i className="icon-cross position-left"></i>
                    {errMsg}
                </div>
            )
        }
    }

    showSuccessAlert = (sucMsg) => {
        if (sucMsg != '') {
            return (
                <div className="alert alert-success"><i className="icon-check2 position-left"></i>
                    {sucMsg}
                </div>
            )
        }
    }

    renderSubmitBtn = () => {
        if(this.state.processing){
            return <input type="button" className="btn btn-primary" value="Processing..." disabled={true}/>
        }else{
            return <input type="button" className="btn btn-primary" onClick={this.handlePasswordChange} value="Update" />
        }
    }


    render() { 
        return (
            <div className="content-container">
                <div className="row">
                    <div className="col-md-12">
                        {Breadcumb(this.state.breadcrumb)}
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3">
                                <div className="panel panel-flat">
                                    <div className="panel-heading">
                                        <h5 className="panel-title">Change Password</h5>                                        
                                    </div>
                                    <div className="panel-body">
                                        {this.showAlert(this.state.PasswordErr)}
                                        {this.showSuccessAlert(this.state.PasswordSuccess)}
                                        <div className="form-group">
                                            <label className="control-label">Current Password</label>
                                            <input type="password" placeholder="Enter Current Password" className="form-control" name="old_password" onChange={this.changeValue}/>
                                            <p className="text-danger help-block">{this.state.error.old_password_err}</p>
                                        </div>                                       
                                        <div className="form-group">
                                            <label className="control-label">New Password</label>
                                            <input type="password" placeholder="Enter New Password" className="form-control" name="new_password" onChange={this.changeValue}/>
                                            <p className="text-danger help-block">{this.state.error.new_password_err}</p>
                                        </div>                                       
                                        <div className="form-group">
                                            <label className="control-label">Repeat New Password</label>
                                            <input type="password" placeholder="Re-enter New Password" className="form-control" name="confirm_password" onChange={this.changeValue}/>
                                            <p className="text-danger help-block">{this.state.error.confirm_password_err}</p>
                                        </div>
                                    </div>
                                    <div className="panel-footer text-center">
                                        {this.renderSubmitBtn()}                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default ChangePassword;