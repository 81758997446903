import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import GetData from '../services/GetData';
import PostDataWithParam from '../services/PostDataWithParam';
import MilitaryConversations from './MilitaryConversations';
import { Prompt } from 'react-router';

class Housekeeping extends Component {
    state = {
        fields: {
            standard_operating_procedure: -1,
            swept_regularly: -1,
            trash_and_other_debris_removed_daily: -1,
            material_kept_orderly: -1,
            not_have_excessive_dust_dirt: -1,
            have_normal_maintenance_schedule: -1,
        },
        housekeeping_id: '',
        tab_id: 5,
        saveStatus: false,
        proccesing:false,
        formDirty:false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        GetData('GetHousekeeping')
        .then((response) => {
            if (response.success == 1) {
                this.setState({ fields: response.data[0], housekeeping_id: response.data[0].id });
            }
        })
        window.$('[data-popup="popover"]').popover();
        window.$('[data-popup="tooltip"]').tooltip();
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields,formDirty:true });
    }

    updateHousekeeping = () => {
        this.setState({ proccesing:true})
        let fields = this.state.fields;
        fields['tab_id'] = this.state.tab_id;
        fields['housekeeping_id'] = this.state.housekeeping_id;

        PostDataWithParam('SaveHousekeeping', fields)
            .then((response) => {
                if (response.success === 1) {
                    window.swal('Success!', response.msg, 'success')
                    this.setState({ saveStatus: true ,proccesing:false,formDirty:false}, () => {
                        this.props.onUpdateApprovalProgress();
                    });
                } else {
                    this.setState({ proccesing:false,formDirty:false})
                    window.swal('Error!', response.msg, 'error')
                }
            })
    }

    openModal = (field_name,tab_id) => {
        this.props.onOpenModal(field_name,tab_id)
    }

    renderAlertMsg = (field_name,tab_id) => {
        
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
        

        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Answer Received</p>
                        </React.Fragment>
                    )
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Waiting for reply</p>
                        </React.Fragment>
                    )
                }
            }
        }
    }
    renderClassName = (field_name,tab_id) => {
        
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
          

        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return "btn-success "
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return "btn-warning "
                }
            }
        }


    }
    render() {

        if (this.state.saveStatus) {
            return <Redirect to='/independent-mover/dashboard/military-approved/information-tab/repair' />
        }

        return (
            <div className="tab-pane active" id="top-icon-tab5">
                <Prompt
                when={this.state.formDirty}
                message="Please save your data before you leave the page. Otherwise, you will lose the information you entered !"
                />
                <div className="panel panel-flat">
                    <div className="panel-heading">
                        <h5 className="panel-title">Housekeeping &nbsp;<a href="#" data-popup="popover" title="" data-trigger="hover" data-content="One of the most important items in warehousing is good housekeeping. Survey personnel will assure there are provisions for the collection and disposal of packing materials, crates, and other similar trash; that excessive dust and dirt are not prevalent; that working supplies are properly located; and that equipment is maintained in a sound and orderly condition."><i className="icon-info22"></i></a></h5>
                    </div>
                    <div className="panel-body">
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Do you have a standard operating procedure to maintain a clean and orderly workspace in the warehouse?</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="standard_operating_procedure" onChange={this.changeValue} checked={(this.state.fields.standard_operating_procedure == 1) ? true : false} />
                                        Y
                                        </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="standard_operating_procedure" onChange={this.changeValue} checked={(this.state.fields.standard_operating_procedure == 0) ? true : false} />
                                        N
                                        </label>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('standard_operating_procedure',this.state.tab_id)} className={this.renderClassName('standard_operating_procedure',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('standard_operating_procedure',this.state.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Is the warehouse swept regularly? </h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="swept_regularly" onChange={this.changeValue} checked={(this.state.fields.swept_regularly == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="swept_regularly" onChange={this.changeValue} checked={(this.state.fields.swept_regularly == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('swept_regularly',this.state.tab_id)} className={this.renderClassName('swept_regularly',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('swept_regularly',this.state.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Is all trash and other debris removed daily to ensure orderly workspace?</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="trash_and_other_debris_removed_daily" onChange={this.changeValue} checked={(this.state.fields.trash_and_other_debris_removed_daily == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="trash_and_other_debris_removed_daily" onChange={this.changeValue} checked={(this.state.fields.trash_and_other_debris_removed_daily == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('trash_and_other_debris_removed_daily',this.state.tab_id)} className={this.renderClassName('trash_and_other_debris_removed_daily',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('trash_and_other_debris_removed_daily',this.state.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Is all packing material and other working supplies kept orderly?</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="material_kept_orderly" onChange={this.changeValue} checked={(this.state.fields.material_kept_orderly == 1) ? true : false} />
                                        Y
                                        </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="material_kept_orderly" onChange={this.changeValue} checked={(this.state.fields.material_kept_orderly == 0) ? true : false} />
                                        N
                                        </label>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('material_kept_orderly',this.state.tab_id)} className={this.renderClassName('material_kept_orderly',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('material_kept_orderly',this.state.tab_id)}
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Will you ensure the warehouse does not have excessive dust and dirt?</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="not_have_excessive_dust_dirt" onChange={this.changeValue} checked={(this.state.fields.not_have_excessive_dust_dirt == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="not_have_excessive_dust_dirt" onChange={this.changeValue} checked={(this.state.fields.not_have_excessive_dust_dirt == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('not_have_excessive_dust_dirt',this.state.tab_id)} className={this.renderClassName('not_have_excessive_dust_dirt',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('not_have_excessive_dust_dirt',this.state.tab_id)}
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Do you have a normal maintenance schedule for all equipment (forklifts, hand trucks, etc)?</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="have_normal_maintenance_schedule" onChange={this.changeValue} checked={(this.state.fields.have_normal_maintenance_schedule == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="have_normal_maintenance_schedule" onChange={this.changeValue} checked={(this.state.fields.have_normal_maintenance_schedule == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('have_normal_maintenance_schedule',this.state.tab_id)} className={this.renderClassName('have_normal_maintenance_schedule',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('have_normal_maintenance_schedule',this.state.tab_id)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <MilitaryConversations />
                <div className="text-center">{this.state.proccesing? <button className="btn btn-primary" disabled={true}>Processing...</button>:<button className="btn btn-primary" onClick={this.updateHousekeeping}>Save &amp; Next</button>}</div>
            </div>

        );
    }
}

export default Housekeeping;