import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataWithParam from '../services/PostDataWithParam';
import GetData from '../services/GetData';
import ConfirmBox from '../externaljs/ConfirmBox';
import Storage from '../externaljs/Storage';

const initial_form_data = {
    id: '',
   main_category:'',
   sub_category:''
}



class AddTrainingCategory extends Component {

    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Add Training category', link: '', class: 'active' }
        ],
        category_list: [],
        loading: false,
        order_type: '',
        //new added
        form_data: {
            ...initial_form_data
        },
       
        main_category_err: '',
        sub_category_err: '',
        changeUpdateStatus: false,
        UpdateErr: '',
        isUpdate: false

    }

    componentDidMount() {
        this.GetCategoryData();
    }

    GetCategoryData = () => {
        // debugger;
        this.setState({ loading: true }, () => {
            // var that = this;
            PostDataWithParam('GMAGetTrainingCategory', {id:''})
                .then((response) => {
                    if (response.success == 1) {
                        this.setState({ category_list: response.data, loading: false }, () => {
                             DataTableBasic();
                            // console.log(order_list)
                            // DataTableBasic(function () {
                            //     var searchFilter = window.$('.dataTables_filter input[type=search]');

                            //     if (searchFilter.length) {
                            //         var newOptions = [
                            //             // { 'key': '', value: 'All' },
                            //             // { 'key': '10', value: 'MyPCSGov' },
                            //             { 'key': '2', value: 'GetMilitaryApproved' },
                            //         ];
                            //         var sel = '<select class="customFilterBy">';
                            //         window.$(newOptions).each(function (key, txt) {
                            //             if (that.state.selCustomFilter == txt.key) {
                            //                 sel += '<option value="' + txt.key + '" selected>' + txt.value + '</option>';
                            //             } else {
                            //                 sel += '<option value="' + txt.key + '">' + txt.value + '</option>';
                            //             }

                            //         });
                            //         sel += '</select>';
                            //         searchFilter.parent().parent().append(sel);
                            //         window.$(".customFilterBy").select2({
                            //             minimumResultsForSearch: Infinity,
                            //             width: 'auto'
                            //         }).on('select2:select', function (e) {
                            //             that.removeDataTable();
                            //             this.GetCategoryData(e.params.data.id);
                            //         });
                            //     }
                            // });
                        });

                    } else {
                        this.setState({ category_list: [], loading: false});
                        // window.swal('Sorry!', 'No Profiles Found', 'info');
                    }
                })
                .catch((err) => {
                    this.setState({ category_list: [], loading: false});
                    window.swal('Error!', 'Something went wrong', 'error');
                })
        })
    }

    removeDataTable = () => {
        if (window.$.fn.DataTable.isDataTable('#list-table')) {
            window.$('#list-table').DataTable().destroy();
        }

        window.$('#list-table tbody').empty();
    }


    onItemClick = (e) => {
        this.setState({ form_data: { ...initial_form_data },isUpdate:false })
        window.$('#addTrainingCategoryModal').modal({
            backdrop: 'static',
            keyboard: false
        });
        window.$("#addTrainingCategoryModal").modal('show');

    }

    handleOnChange = (e) => {
        let form_data = Object.assign({}, this.state.form_data)
        form_data[e.target.name] = e.target.value
        this.setState({ form_data: form_data });
    }


    createCategory = (e) => {
       
        if (this.state.form_data.main_category == '') this.setState({ main_category_err: 'Please enter main category' })
        // if (this.state.form_data.sub_category == '') this.setState({ sub_category_err: 'Please enter sub category' })
        else {
        let form_data = { ...this.state.form_data };
        PostDataWithParam(this.state.isUpdate?'GMAEditTrainingCategory':'GMAAddTrainingCategory', form_data)
            .then((response) => {
                let result = response;

                if (result.success === 1) {
                     this.removeDataTable();
                     window.$('#addTrainingCategoryModal').modal('hide');
                    this.GetCategoryData();
                    window.swal('Success!', response.msg, 'success');

                } else {
                    this.setState({loading:false});
                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => {
                this.setState({loading:false});
                window.swal('Error!', 'Something went wrong3', 'error');
            })
        }
    }


    DeleteCategory = (id) => {
        // console.log(id);
        // return;
        ConfirmBox("You want to delete this Category!")
            .then((result) => {
                if (result) {
                    PostDataWithParam('GMADeleteTrainingCategory', { id: id })
                        .then((response) => {
                            if (response.success == 1) {
                                this.removeDataTable();
                                this.GetCategoryData();
                                window.swal('Deleted!', response.msg, 'success')
                            }
                            else {
                                window.swal('Error!', response.msg, 'error')
                            }

                        })
                        .catch(err => {
                            window.swal('Error!', 'Something went wrong', 'error')
                        })
                }
            })

    }


    ActiveDeactiveCategory = (id, status) => {
        if (status == 1) {
            ConfirmBox("You want to deactivate this Category!")
                .then((result) => {
                    if (result) {
                        PostDataWithParam('GMAActiveInactiveTrainingCategory', { id: id, is_active: 0 })
                            .then((response) => {
                                let result = response;
                                if (result.success === 1) {
                                    this.removeDataTable();
                                    this.GetCategoryData();
                                    window.swal('Deactivated!', 'Category Deactivated Successfully', 'success')
                                } else {
                                    window.swal('Deactivated!', result.msg, 'success')
                                }
                            })
                            .catch(err => {
                                window.swal('Error!', 'Something went wrong', 'error')
                            })
                    }
                });
        }
        else {
            ConfirmBox("You want to activate this Category!")
                .then((result) => {
                    if (result) {
                        PostDataWithParam('GMAActiveInactiveTrainingCategory', { id: id, is_active: 1 })
                            .then((response) => {
                                let result = response;
                                if (result.success === 1) {
                                    this.removeDataTable();
                                    this.GetCategoryData();
                                    window.swal('Activated!', 'Category Activated Successfully', 'success')
                                } else {
                                    window.swal('Activated!', result.msg, 'success')
                                }
                            })
                            .catch(err => {
                                window.swal('Error!', 'Something went wrong', 'error')
                            })
                    }
                });
        }
    }

    handleBtn = (status) => {
        if (status == 0) {
            return (
                'Activate'
            )
        } else {
            return (
                'Deactivate'
            )

        }
    }

    getCategoryInfo = (id) => {

        PostDataWithParam('GMAGetTrainingCategory', { id:id })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ form_data: response.data[0],isUpdate:true}, () => {
                    });
                }
                else {
                    console.log('Error')
                }

            })
            .catch(err => {
                this.setState({ changeUpdateStatus: false });
            })
    }

    renderStatus = (status) => {
        if (status == 1) {
            return (
                <span className="text-success">Active</span>
            )
        } else{
            return (
                <td className="text-danger">Inactive</td>
            )
        } 
    }

    render() {

        return (
            <React.Fragment>
                <div className="content-container">
                    <div className="row">
                        <div className="col-md-12">
                            {Breadcumb(this.state.breadcrumb)}
                            <div className="clearfix">
                                <h4 className="pull-left">Add Training Category</h4>

                                    <div className="pull-right">
                                        <Link className="btn btn-primary" to="#" onClick={this.onItemClick}><i className="icon-plus2"></i> Add New Training Category</Link>
                                    </div>

                            </div>
                            <div className="panel panel-flat">
                                {
                                    this.state.loading
                                    &&
                                    <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                                }
                                {
                                    !this.state.loading
                                    &&
                                    this.state.category_list.length !== 0
                                    &&
                                    <table className="table datatable-basic table-striped" id="list-table">
                                        <thead>
                                            <tr>
                                            <th>Category</th>
                                            <th>Sub Category</th>
                                            <th>Status</th>
                                            <th className="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.state.category_list.map((category, index) =>

                                             
                                                <tr key={index}>
                                                    <td style={{ 'width': '300px' }}>{category.main_category}</td>
                                                    <td>{category.sub_category}</td>
                                                    <td>{this.renderStatus(category.is_active)}</td>
                                                        <td className="text-center">
                                                            <ul className="icons-list">
                                                                <li className="dropdown">
                                                                    <a href="" className="dropdown-toggle" data-toggle="dropdown">
                                                                        <i className="icon-menu9"></i>
                                                                    </a>
                                                                    <ul className="dropdown-menu dropdown-menu-right">

                                                                        <li><a href="#" data-toggle="modal" data-target="#addTrainingCategoryModal" onClick={() => this.getCategoryInfo(category.id)}><i className="icon-pencil"></i> Edit</a></li>
                                                                        <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.ActiveDeactiveCategory(category.id, category.is_active)} ><i className="icon-user-block"></i> {this.handleBtn(category.is_active)}</a></li>
                                                                        <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.DeleteCategory(category.id)} ><i className="glyphicon glyphicon-remove"></i> Delete</a></li>

                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>

                                }
                                {
                                    !this.state.loading
                                    &&
                                    this.state.category_list.length === 0
                                    &&
                                    <div className="alert alert-primary border-none text-center m-15">No Category Found.</div>
                                }

                            </div>


                        </div>
                    </div>
                </div>
                <div className="modal fade" tabindex="-1" role="dialog" id="addTrainingCategoryModal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title">{this.state.isUpdate?'Update':'Add'} Training Category</h4>
                            </div>
                            <hr className="m-0 m-t-5"></hr>
                            <div className="modal-body">
                            <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label className="text-semibold">Category <span className="text-danger">*</span></label>
                                                <input type="text" name="main_category" value={this.state.form_data.main_category} onChange={this.handleOnChange} className="form-control" placeholder="Enter Category"/>
                                                <p className="text-danger help-block">{this.state.main_category_err}</p>
                                          </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label className="text-semibold">Sub Category </label>
                                                <input type="text" name="sub_category" value={this.state.form_data.sub_category} onChange={this.handleOnChange} className="form-control" placeholder="Enter Sub Category"/>
                                                {/* <p className="text-danger help-block">{this.state.sub_category_err}</p> */}
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className="modal-footer p-t-15">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={this.createCategory}>{this.state.isUpdate?'Update':'Save'}</button>
                            </div>
                        </div>
                    </div>
                </div>

    
            </React.Fragment>
        );
    }
}

export default AddTrainingCategory;

