import React, { Component } from 'react';
import PostDataWithParam from '../services/PostDataWithParam';
import PostDataFile from '../services/PostDataFile';
import { Redirect, Link } from 'react-router-dom';
import { FormatSSN } from '../externaljs/FormatSSN';
import Breadcumb from '../externaljs/Breadcrumb';
import Storage from '../externaljs/Storage';
import ConfirmBox from '../externaljs/ConfirmBox';
import GetData from '../services/GetData';

class Inventory extends Component {
   state = {
      breadcrumb: [
         { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
         { title: 'Manage Items', link: '', class: 'active' }
      ],
      loading: false,
      firstload: false,
      loadMore: 0,
      allmaininventory: [],
      allsubinventorybymain: [],
      allinventoryitembycat: [],
      getSubRoomListDetails: [],
      maininventorydetailsbyid: [],
      subinventorydetailsbyid: [],
      inventoryitemdetailsbyid: [],
      itemImage: [],
      inventoryName: '',
      inventoryID: '',
      itemTitle: '',
      itemImage: '',
      fields: {
         title: '',
         attachment: '',
         subtitle: '',
         inventorytitle: '',
         inventorycubicfeet: '',
         inventoryservicefee: '',
         inventoryattachment: '',
         main_inventory_id: '',
         sub_inventory_id: '',
         main_inventory_name: '',
         sub_inventory_name: ''
      },
      error: {
         title_error: '',
         attachment_error: '',
         subtitle_error: '',
         inventorytitle_error: '',
         inventorycubicfeet_error: '',
         inventoryservicefee_error: '',
         inventoryattachment_error: ''
      },
      staff_user_id: null,
      custom_menu: [],
      permission: false,
      isOpenEditModal: false
   }

   componentDidMount() {
      let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
      let custom_menu = [];
      if (staff_user_id != "") {
         custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
      }
      this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
         // console.log(this.state.custom_menu);
         // console.log(this.state.staff_user_id);
      });
      for (var i = 0; i < custom_menu.length; i++) {
         if (custom_menu[i].title == "Inventory") {
            if (custom_menu[i].is_add == 1 || custom_menu[i].is_add == "1")
               this.setState({ permission: true }, () => {
                  // console.log(this.state.permission);
               })
         }
      }

      this.getMainInventoryList();

   }

   changeValue = (e) => {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({ fields: fields });
   }

   handleAttachment = (e) => {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.files[0];
      this.setState({ fields: fields });
   }

   resetError = (cb) => {
      let error = {
         title_error: '',
         attachment_error: '',
         subtitle_error: '',
         inventorytitle_error: '',
         inventorycubicfeet_error: '',
         inventoryservicefee_error: '',
         inventoryattachment_error: ''
      }
      this.setState({ error: error }, () => {
         cb();
      });
   }


   getSubRoomList = (id) => {
      this.setState({ loading: false }, () => {
         GetData('SubInventoryListByMain/' + id)
            .then((response) => {
               if (response.success === 1) {
                  this.setState({ getSubRoomListDetails: response.data, loading: false });
               } else {
                  this.setState({ getSubRoomListDetails: [], loading: false });
               }
            })
            .catch(err => {
               this.setState({ getSubRoomListDetails: [], loading: false });
               window.swal('Error!', 'Oops! Something went wrong.', 'error');
            })
      });
   }
   getMainInventoryList = () => {
      this.setState({ loading: true }, () => {
         PostDataWithParam('GMAShowRoomWiseInventoryDetails', { main_inventory_id: this.props.match.params.inventory_id })
            .then((response) => {
               if (response.success === 1) {
                  this.setState({ allmaininventory: response.data, loading: false }, () => {
                     // console.log(this.state.allmaininventory[0].inventory_details)
                     // console.log(JSON.stringify(this.state.allmaininventory[0].main_inventory))
                     // console.log(this.state.allmaininventory[0].main_inventory_id);
                     let inventoryName = JSON.stringify(this.state.allmaininventory[0].main_inventory);
                     inventoryName = inventoryName.substring(1, (inventoryName.length - 1))
                     let inventoryID = this.state.allmaininventory[0].main_inventory_id;
                     this.setState({ inventoryName, inventoryID })
                  });
               } else {
                  this.setState({ allmaininventory: [], loading: false });
                  window.swal('Error!', 'Oops! Something went wrong.', 'error');
               }
            })
            .catch(err => {
               this.setState({ allmaininventory: [], loading: false });
               window.swal('Error!', 'Oops! Something went wrong.', 'error');
            })
      });
   }

   getSubInventoryListByMain = (id, key, deletecase = '') => {
      this.setState({ loading: false }, () => {
         GetData('SubInventoryListByMain/' + id)
            .then((response) => {
               if (response.success === 1) {
                  let newsubinvenarray = this.state.allsubinventorybymain.slice(); //creates the clone of the state
                  newsubinvenarray[key] = response.data;
                  this.setState({ allsubinventorybymain: newsubinvenarray, loading: false });
               } else {
                  let newsubinvenarray = this.state.allsubinventorybymain.slice(); //creates the clone of the state
                  newsubinvenarray[key] = [];
                  this.setState({ allsubinventorybymain: newsubinvenarray, loading: false });
               }

               if (deletecase == '') {
                  if (window.$("#accordion" + key).hasClass('in')) {
                     window.$("#accordion" + key).removeClass('in');
                     window.$("#accordion" + key).attr('aria-expanded', 'false');
                     window.$("#accordionanchor" + key).addClass('collapsed');
                     window.$("#accordionanchor" + key).attr('aria-expanded', 'false');
                  } else {
                     window.$("#accordion" + key).addClass('in');
                     window.$("#accordion" + key).attr('aria-expanded', 'true');
                     window.$("#accordionanchor" + key).removeClass('collapsed');
                     window.$("#accordionanchor" + key).attr('aria-expanded', 'true');
                  }
               }

            })
            .catch(err => {
               this.setState({ allsubinventorybymain: [], loading: false });
               window.swal('Error!', 'Oops! Something went wrong.', 'error');
            })
      });
   }

   getInventoryItemListByCat = (mid, sid) => {
      this.setState({ loading: false }, () => {
         GetData('GMAInventoryItemListByCat/' + mid + '/' + sid)
            .then((response) => {
               if (response.success === 1) {
                  this.setState({ allinventoryitembycat: response.data, main_inventory_id: mid, sub_inventory_id: sid, main_inventory_name: response.inventory_cat_values.main_inventory_name, sub_inventory_name: response.inventory_cat_values.sub_inventory_name, loading: false, firstload: true });
               } else {
                  this.setState({ allinventoryitembycat: [], main_inventory_id: mid, sub_inventory_id: sid, main_inventory_name: response.inventory_cat_values.main_inventory_name, sub_inventory_name: response.inventory_cat_values.sub_inventory_name, loading: false, firstload: true });
               }
            })
            .catch(err => {
               this.setState({ allinventoryitembycat: [], loading: false, firstload: true });
               window.swal('Error!', 'Oops! Something went wrong.', 'error');
            })
      });
   }

   AddMainInventory = () => {
      this.resetError(() => {
         let error = this.state.error;
         if (document.getElementById('title').value === '') {
            error.title_error = 'Title is required.';
         }
         else if (this.state.fields.attachment === '') {
            error.attachment_error = 'Image is required.';
         } else if (this.state.fields.attachment.type != 'image/jpeg' && this.state.fields.attachment.type != 'image/jpg' && this.state.fields.attachment.type != 'image/png' && this.state.fields.attachment.type != 'image/gif') {
            error.attachment_error = 'Please Select Image';
         }
         else {
            let fields = this.state.fields;
            fields.title = document.getElementById('title').value;
            this.setState({ fields: fields });
            let fd = new FormData();
            fd.append('title', this.state.fields.title);
            fd.append('attachment', this.state.fields.attachment, this.state.fields.attachment.name);

            PostDataFile('AddMainInventory', fd)
               .then((response) => {
                  if (response.success === 1) {
                     window.$("#addroom-form").trigger("reset");
                     window.$('#addroom').modal('hide');
                     window.swal('Successful!', response.msg, 'success')
                     this.getMainInventoryList();
                  } else {
                     window.swal('Error!', response.msg, 'error');
                  }
               })
               .catch(err => {
                  window.swal('Error!', 'Oops! Something went wrong.', 'error');
               })
         }
         this.setState({ error: error });
      });
   }

   UpdateMainInventory = () => {
      this.resetError(() => {
         let error = this.state.error;
         if (document.getElementById('edit-title').value === '') {
            error.title_error = 'Title is required.';
         }
         else if (this.state.fields.attachment != '' && this.state.fields.attachment.type != 'image/jpeg' && this.state.fields.attachment.type != 'image/jpg' && this.state.fields.attachment.type != 'image/png' && this.state.fields.attachment.type != 'image/gif') {
            error.attachment_error = 'Please Select Image';
         }
         else {
            let fields = this.state.fields;
            fields.title = document.getElementById('edit-title').value;
            this.setState({ fields: fields });
            let fd = new FormData();
            fd.append('id', document.getElementById('edit_main_inventory_id').value);
            fd.append('title', this.state.fields.title);
            if (this.state.fields.attachment != '') {
               fd.append('attachment', this.state.fields.attachment, this.state.fields.attachment.name);
            }
            PostDataFile('UpdateMainInventory', fd)
               .then((response) => {
                  if (response.success === 1) {
                     window.$("#editroom-form").trigger("reset");
                     window.$('#editroom').modal('hide');
                     window.swal('Successful!', response.msg, 'success')
                     this.getMainInventoryList();
                  } else {
                     window.swal('Error!', response.msg, 'error');
                  }
               })
               .catch(err => {
                  window.swal('Error!', 'Oops! Something went wrong.', 'error');
               })
         }
         this.setState({ error: error });
      });
   }

   UpdateSubInventory = () => {
      this.resetError(() => {
         let error = this.state.error;
         if (document.getElementById('edit-subtitle').value === '') {
            error.subtitle_error = 'Title is required.';
         }
         else {
            let fields = this.state.fields;
            fields.subtitle = document.getElementById('edit-subtitle').value;
            this.setState({ fields: fields });
            let fd = new FormData();
            fd.append('id', document.getElementById('edit_sub_inventory_id').value);
            fd.append('title', this.state.fields.subtitle);

            PostDataFile('UpdateSubInventory', fd)
               .then((response) => {
                  if (response.success === 1) {
                     window.$("#editsubroom-form").trigger("reset");
                     window.$('#editfilter').modal('hide');
                     window.swal('Successful!', response.msg, 'success')
                     this.getMainInventoryList();
                  } else {
                     window.swal('Error!', response.msg, 'error');
                  }
               })
               .catch(err => {
                  window.swal('Error!', 'Oops! Something went wrong.', 'error');
               })
         }
         this.setState({ error: error });
      });
   }

   UpdateInventoryItem = () => {
      this.resetError(() => {
         let error = this.state.error;
         if (document.getElementById('edit-inventorytitle').value === '') {
            error.inventorytitle_error = 'Title is required.';
         }
         // else if (document.getElementById('edit-inventorycubicfeet').value === '') {
         //    error.inventorycubicfeet_error = 'Cubic Feet is required.';
         // } 
         // else if (document.getElementById('edit-inventoryservicefee').value === '') {
         //    error.inventoryservicefee_error = 'Extra Service Fee is required.';
         // } 
         else if (this.state.fields.inventoryattachment != '' && this.state.fields.inventoryattachment.type != 'image/jpeg' && this.state.fields.inventoryattachment.type != 'image/jpg' && this.state.fields.inventoryattachment.type != 'image/png' && this.state.fields.inventoryattachment.type != 'image/gif') {
            error.inventoryattachment_error = 'Please Select Image';
         }
         else {
            let fields = this.state.fields;
            fields.inventorytitle = document.getElementById('edit-inventorytitle').value;
            fields.inventorycubicfeet = document.getElementById('edit-inventorycubicfeet').value;
            // fields.inventoryservicefee = document.getElementById('edit-inventoryservicefee').value;
            this.setState({ fields: fields });
            // var main_inventory_id = window.$("#additem").find("#main_inventory_id").val();
            // var sub_inventory_id = window.$("#additem").find("#sub_inventory_id").val();
            var main_inventory_id = document.getElementById('edit_inventory_item_main_id').value;
            var sub_inventory_id = document.getElementById('edit_inventory_item_sub_id').value;
            let fd = new FormData();
            fd.append('id', document.getElementById('edit_inventory_item_id').value);
            fd.append('title', this.state.fields.inventorytitle);
            fd.append('cubic_feet', this.state.fields.inventorycubicfeet);
            // fd.append('extra_service_fee', this.state.fields.inventoryservicefee);
            if (this.state.fields.inventoryattachment != '') {
               fd.append('attachment', this.state.fields.inventoryattachment, this.state.fields.inventoryattachment.name);
            }

            PostDataFile('GMAUpdateInventoryItem', fd)
               .then((response) => {
                  if (response.success === 1) {
                     window.$("#edit-inventory").trigger("reset");
                     window.$('#edititem').modal('hide');
                     window.swal('Successful!', 'Item Successfully Updated!', 'success');
                     this.getMainInventoryList(main_inventory_id);
                  } else {
                     window.swal('Error!', response.msg, 'error');
                  }
               })
               .catch(err => {
                  window.swal('Error!', 'Oops! Something went wrong.', 'error');
               })
         }
         this.setState({ error: error });
      });
   }

   AddSubInventory = () => {
      this.resetError(() => {
         let error = this.state.error;
         if (document.getElementById('subtitle').value === '') {
            error.subtitle_error = 'Title is required.';
         }
         else {
            let fields = this.state.fields;
            fields.subtitle = document.getElementById('subtitle').value;
            this.setState({ fields: fields });
            var main_inventory_id = window.$("#addfilter").find("#main_inventory_id").val();
            let fd = new FormData();
            fd.append('title', this.state.fields.subtitle);
            fd.append('main_inventory_id', main_inventory_id);

            PostDataFile('AddSubInventory', fd)
               .then((response) => {
                  if (response.success === 1) {
                     window.$("#addsubroom-form").trigger("reset");
                     window.$('#addfilter').modal('hide');
                     window.swal('Successful!', response.msg, 'success')
                     this.getMainInventoryList();
                  } else {
                     window.swal('Error!', response.msg, 'error');
                  }
               })
               .catch(err => {
                  window.swal('Error!', 'Oops! Something went wrong.', 'error');
               })
         }
         this.setState({ error: error });
      });
   }

   OpenSubInventoryModal = (main_inventory_id) => {
      this.setState({ main_inventory_id: main_inventory_id });
      window.$('#addfilter').modal('show');
   }

   OpenInventoryItemAddModal = (mid, sid) => {
      this.setState({ main_inventory_id: mid, sub_inventory_id: sid });
      window.$('#additem').modal('show');
   }

   EnLargeInventoryItemImage = (title, image) => {
      this.setState({ inventorytitle: title, inventoryattachment: image });
      window.$('#showimage').modal('show');
   }

   DeleteInventoryItem = (id) => {
      var main_inventory_id = window.$("#addfilter").find("#main_inventory_id").val();
      ConfirmBox("You want to delete this item!")
         .then((result) => {
            if (result) {
               this.setState({ loading: false }, () => {
                  GetData('GMADeleteInventoryItem/' + id)
                     .then((response) => {
                        if (response.success === 1) {
                           window.swal('Successful!', response.msg, 'success');
                           // this.getInventoryItemListByCat(response.data.main_inventory_id, response.data.sub_inventory_id);
                           this.getMainInventoryList(main_inventory_id);
                        } else {
                           window.swal('Error!', response.msg, 'error');
                        }
                     })
                     .catch(err => {
                        window.swal('Error!', 'Oops! Something went wrong.', 'error');
                     })
               });
            }
         });
   }

   DeleteSubInventory = (subinventory_id, maininventory_id, key) => {
      ConfirmBox("You want to delete this sub inventory!")
         .then((result) => {
            if (result) {
               this.setState({ loading: false }, () => {
                  GetData('DeleteSubInventory/' + subinventory_id)
                     .then((response) => {
                        if (response.success === 1) {
                           window.swal('Successful!', response.msg, 'success');
                           this.getSubInventoryListByMain(maininventory_id, key, 'deletecase');
                        } else {
                           window.swal('Error!', response.msg, 'error');
                        }
                     })
                     .catch(err => {
                        window.swal('Error!', 'Oops! Something went wrong.', 'error');
                     })
               });
            }
         });
   }

   DeleteMainInventory = (id) => {
      ConfirmBox("You want to delete this main inventory!")
         .then((result) => {
            if (result) {
               this.setState({ loading: false }, () => {
                  GetData('DeleteMainInventory/' + id)
                     .then((response) => {
                        if (response.success === 1) {
                           window.swal('Successful!', response.msg, 'success');
                           this.getMainInventoryList();
                        } else {
                           window.swal('Error!', response.msg, 'error');
                        }
                     })
                     .catch(err => {
                        window.swal('Error!', 'Oops! Something went wrong.', 'error');
                     })
               });
            }
         });
   }

   EditMainInventory = (id) => {
      this.setState({ loading: false }, () => {
         GetData('MainInventoryDetails/' + id)
            .then((response) => {
               if (response.success === 1) {
                  this.setState({ maininventorydetailsbyid: response.data, loading: false });
                  window.$('#editroom').modal('show');
               } else {
                  this.setState({ maininventorydetailsbyid: [], loading: false });
                  window.swal('Error!', response.msg, 'error');
               }
            })
            .catch(err => {
               window.swal('Error!', 'Oops! Something went wrong.', 'error');
            })
      });
   }

   EditSubInventory = (id) => {
      this.setState({ loading: false }, () => {
         GetData('SubInventoryDetails/' + id)
            .then((response) => {
               if (response.success === 1) {
                  this.setState({ subinventorydetailsbyid: response.data, loading: false });
                  window.$('#editfilter').modal('show');
               } else {
                  this.setState({ subinventorydetailsbyid: [], loading: false });
                  window.swal('Error!', response.msg, 'error');
               }
            })
            .catch(err => {
               window.swal('Error!', 'Oops! Something went wrong.', 'error');
            })
      });
   }

   EditInventoryItem = (id) => {
      this.setState({ loading: false, isOpenEditModal: false }, () => {
         GetData('GMAInventoryItemDetails/' + id)
            .then((response) => {
               if (response.success === 1) {
                  this.setState({ inventoryitemdetailsbyid: response.data, loading: false, isOpenEditModal: true }, () => {
                     window.$('#edititem').modal('show');
                  });

               } else {
                  this.setState({ inventoryitemdetailsbyid: [], loading: false });
                  window.swal('Error!', response.msg, 'error');
               }
            })
            .catch(err => {
               window.swal('Error!', 'Oops! Something went wrong.', 'error');
            })
      });
   }

   AddInventoryItem = () => {
      this.resetError(() => {
         let error = this.state.error;
         if (document.getElementById('inventorytitle').value === '') {
            error.inventorytitle_error = 'Title is required.';
         }
         // else if (document.getElementById('inventorycubicfeet').value === '') {
         //    error.inventorycubicfeet_error = 'Cubic Feet is required.';
         // } 
         // else if (document.getElementById('inventoryservicefee').value === '') {
         //    error.inventoryservicefee_error = 'Extra Service Fee is required.';
         // }
         else if (this.state.fields.inventoryattachment === '') {
            error.inventoryattachment_error = 'Image is required.';
         } else if (this.state.fields.inventoryattachment.type != 'image/jpeg' && this.state.fields.inventoryattachment.type != 'image/jpg' && this.state.fields.inventoryattachment.type != 'image/png' && this.state.fields.inventoryattachment.type != 'image/gif') {
            error.inventoryattachment_error = 'Please Select Image';
         }
         else {
            let fields = this.state.fields;
            fields.inventorytitle = document.getElementById('inventorytitle').value;
            fields.inventorycubicfeet = document.getElementById('inventorycubicfeet').value;
            // fields.inventoryservicefee = document.getElementById('inventoryservicefee').value;
            this.setState({ fields: fields });
            var main_inventory_id = window.$("#additem").find("#main_inventory_id").val();
            // var sub_inventory_id = window.$("#additem").find("#sub_inventory_id").val();
            let fd = new FormData();
            fd.append('title', this.state.fields.inventorytitle);
            fd.append('cubic_feet', this.state.fields.inventorycubicfeet);
            // fd.append('extra_service_fee', this.state.fields.inventoryservicefee);
            fd.append('main_inventory_id', main_inventory_id);
            // fd.append('sub_inventory_id', sub_inventory_id);
            fd.append('attachment', this.state.fields.inventoryattachment, this.state.fields.inventoryattachment.name);

            PostDataFile('GMAAddInventoryItem', fd)
               .then((response) => {
                  if (response.success === 1) {
                     window.$("#add-inventory").trigger("reset");
                     window.$('#additem').modal('hide');
                     window.swal('Successful!', response.msg, 'success')
                     // this.getInventoryItemListByCat(main_inventory_id, sub_inventory_id);
                     this.getMainInventoryList(main_inventory_id);
                  } else {
                     window.swal('Error!', response.msg, 'error');
                  }
               })
               .catch(err => {
                  window.swal('Error!', 'Oops! Something went wrong.', 'error');
               })
         }
         this.setState({ error: error });
      });
   }

   ActiveDeactiveItem = (id, is_active) => {
      var main_inventory_id = window.$("#additem").find("#main_inventory_id").val();
      if (is_active == 1) {
         ConfirmBox("You want to deactivate this item!")
            .then((result) => {
               if (result) {
                  PostDataWithParam('GMAActiveInactiveItem', { id: id, is_active: 0 })
                     .then((response) => {
                        let result = response;
                        if (result.success === 1) {
                           this.getMainInventoryList(main_inventory_id);
                           window.swal('Deactivated!', 'Item Deactivated Successfully', 'success')
                        } else {
                           window.swal('Deactivated!', result.msg, 'success')
                        }
                     })
                     .catch(err => {
                        window.swal('Error!', 'Something went wrong', 'error')
                     })
               }
            });
      }
      else {
         ConfirmBox("You want to activate this item!")
            .then((result) => {
               if (result) {
                  PostDataWithParam('GMAActiveInactiveItem', { id: id, is_active: 1 })
                     .then((response) => {
                        let result = response;
                        if (result.success === 1) {
                           this.getMainInventoryList(main_inventory_id);
                           window.swal('Activated!', 'Item Activated Successfully', 'success')
                        } else {
                           window.swal('Activated!', result.msg, 'success')
                        }
                     })
                     .catch(err => {
                        window.swal('Error!', 'Something went wrong', 'error')
                     })
               }
            });
      }
   }

   handleBtn = (is_active) => {
      if (is_active == 0) {
         return (
            'Activate'
         )
      } else {
         return (
            'Deactivate'
         )

      }
   }

   renderStatus = (status) => {
      if (status == 1) {
         return (
            <span className="text-success">Active</span>
         )
      } else if (status == 0) {
         return (
            <span className="text-danger">Inactive</span>
         )
      } else {
         return (
            <span className="text-primary">In Progress</span>
         )

      }
   }

   ShowImage = (item_id) => {
      // this.setState({ loading: true }, () => {
      PostDataWithParam('GMAShowRoomWiseInventoryDetails', { main_inventory_id: this.props.match.params.inventory_id })
         .then((response) => {
            if (response.success === 1) {
               this.setState({ itemImage: response.data, loading: false }, () => {
                  // console.log(this.state.itemImage[0].inventory_details[0])
                  // console.log(this.state.itemImage)
                  for (var i = 0; i < this.state.itemImage[0].inventory_details.length; i++) {
                     if (item_id == this.state.itemImage[0].inventory_details[i].id) {
                        // console.log(this.state.itemImage[0].inventory_details[i].image);
                        // console.log(this.state.itemImage[0].inventory_details[i].title);
                        let itemTitle = this.state.itemImage[0].inventory_details[i].title;
                        let itemImage = this.state.itemImage[0].inventory_details[i].image;
                        this.setState({ itemImage, itemTitle })
                     }

                     else {
                        // console.log('sorry')
                     }
                  }
                  window.$('#showimage').modal('show');

               });
            } else {
               this.setState({ itemImage: [], loading: false });
               window.swal('Error!', 'Oops! Something went wrong.', 'error');
            }
         })
         .catch(err => {
            this.setState({ itemImage: [], loading: false });
            window.swal('Error!', 'Oops! Something went wrong.', 'error');
         })
      // });

   }

   renderInventoryItems = () => {
      if (this.state.allmaininventory[0].inventory_details.length > 0) {
         let inventory_items = this.state.allmaininventory[0].inventory_details.map((item, i) => {
            return (
               <div className="col-lg-6 col-md-12">
                  <div className="panel panel-default">
                     <div className="panel-body">
                        <div className="media stack-media-on-mobile">
                           <div className="media-left">
                              <div className="thumb">
                                 {/* <a href="#" data-toggle="modal" data-target="#showimage"> */}
                                 <a href="javascript:void(0);" onClick={() => this.ShowImage(item.id)}>
                                    {/* <img src="assets/images/architecture-cabinets-carpet-245208.jpg" className="img-responsive img-rounded media-preview" alt="" /> */}
                                    <img src={"https://myrelo.us/relotechApi/public/uploads/inventory/item/" + item.image} className="img-responsive img-rounded media-preview" alt="" />
                                    <span className="zoom-image"><i className=" icon-zoomin3"></i></span>
                                 </a>
                              </div>
                           </div>
                           <div className="media-body">
                              <h6 className="media-heading"><a>{item.title}</a></h6>
                              <ul className="list-inline list-inline-separate text-muted mb-5">
                                 <li>Cubic feet: {item.cubic_feet}</li>
                                 {/* <li>Extra Service fee: {item.extra_service_fee}</li> */}
                              </ul>
                              <ul className="list-inline list-inline-separate text-muted mb-5">
                                 <li>Active/Inactive: {this.renderStatus(item.is_active)}</li>
                              </ul>
                              <a href="javascript:void(0);" className="btn btn-default btn-xs" onClick={() => this.EditInventoryItem(item.id)}>Edit</a>&nbsp;&nbsp;
                              <a href="javascript:void(0);" className="btn btn-default btn-xs" onClick={() => this.DeleteInventoryItem(item.id)} >Delete</a>&nbsp;&nbsp;
                              <a href="javascript:void(0)" className="btn btn-default btn-xs" onClick={() => this.ActiveDeactiveItem(item.id, item.is_active)}>{this.handleBtn(item.is_active)}</a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            );
         })
         return inventory_items;
      }
   }



   render() {


      return (

         <div className="row">
            <div className="col-md-12">
               {Breadcumb(this.state.breadcrumb)}
            </div>
            {/* <div className="col-md-4"> */}
            {/* {this.state.staff_user_id == "" && <a href="" className="btn btn-primary m-b-15" data-toggle="modal" data-target="#addroom"><i className="icon-plus22"></i> Add New Room</a>}
                 {(this.state.staff_user_id != "") && this.state.custom_menu.map((menu, i) => {
                    return (
                       <div>
                          {
                             (menu.title == "Inventory" && (menu.is_add == 1 || menu.is_add == "1")) && <a href="" className="btn btn-primary m-b-15" data-toggle="modal" data-target="#addroom"><i className="icon-plus22"></i> Add New Room</a>}</div>);
                 })} */}
            {/* <div className="media-body"><a data-toggle="collapse" data-parent="#menu-accordion" href="#accordion1" aria-expanded="false" className="collapsed f-s-16 m-t-10 l-h-33 text-slate">Inventory Details </a></div><br></br> */}
            {/* <div className="panel-group content-group m-b-10" id="menu-accordion">
                    <div className="panel panel-default">
                       <div className="panel-heading active p-t-8 p-b-8">
                          <div className="media InCat">
                             <div className="media-left"><img src="assets/images/carvings-design-furniture-158613.jpg" className="img-circle img-xs" alt="" /></div>
                             <div className="media-body"><a data-toggle="collapse" data-parent="#menu-accordion" href="#accordion1" aria-expanded="false" className="collapsed f-s-16 m-t-10 l-h-33 text-slate">Bedroom <i className="icon-arrow-down32 text-muted f-s-15"></i></a></div>
                             <div className="media-right">
                                <a href="#" data-toggle="modal" data-target="#editCat" className="l-h-33"><i className="icon-pencil"></i></a>
                                <a href="#" className="l-h-33 m-l-5"><i className="icon-trash text-danger-800"></i></a>
                             </div>
                          </div>
                       </div>
                       <div id="accordion1" className="panel-collapse collapse custom-sidebar-page" aria-expanded="false">
                          <div className="panel-body p-0 b-0">
                             <ul className="list-group  p-0 b-0">
                                <li className="list-group-item active">
                                   <div className="media">
                                      <div className="media-body">
                                         <a href="#">
                                            Bed, Mattress
                              </a>
                                      </div>
                                      <div className="media-right">
                                         <a href="#" data-toggle="modal" data-target="#editCat2"><i className="icon-pencil text-slate f-s-15"></i></a>
                                         <a href="#" className="m-l-5"><i className="icon-trash text-slate f-s-15"></i></a>
                                      </div>
                                   </div>
                                </li>
                                <li className="list-group-item b-b">
                                   <div className="media">
                                      <div className="media-body">
                                         <a href="#">
                                            Bed, Mattress
                              </a>
                                      </div>
                                      <div className="media-right">
                                         <a href="#" data-toggle="modal" data-target="#editCat2"><i className="icon-pencil text-slate f-s-15"></i></a>
                                         <a href="#" className="m-l-5"><i className="icon-trash text-slate f-s-15"></i></a>
                                      </div>
                                   </div>
                                </li>
                                <li className="list-group-item">
                                   <div className="media">
                                      <div className="media-body">
                                         <a href="#">
                                            Bed, Mattress
                              </a>
                                      </div>
                                      <div className="media-right">
                                         <a href="#" data-toggle="modal" data-target="#editCat2"><i className="icon-pencil text-slate f-s-15"></i></a>
                                         <a href="#" className="m-l-5"><i className="icon-trash text-slate f-s-15"></i></a>
                                      </div>
                                   </div>
                                </li>
                             </ul>
                             <div className="text-center">
                                <a href="" className="btn btn-link" data-toggle="modal" data-target="#addCat2"><i className="icon-plus22"></i> Add New Filter</a>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div> */}
            {/* <div className="panel-group content-group m-b-10" id="menu-accordion">
                    <div className="panel panel-default">
                       <div className="panel-heading p-t-8 p-b-8">
                          <div className="media InCat">
                             <div className="media-left"><img src="assets/images/apartment-bedroom-ceiling-1082355.jpg" className="img-circle img-xs" alt="" /></div>
                             <div className="media-body"><a data-toggle="collapse" data-parent="#menu-accordion" href="#accordion2" aria-expanded="false" className="collapsed f-s-16 m-t-10 l-h-33 text-slate">Office <i className="icon-arrow-down32 text-muted f-s-1"></i></a></div>
                             <div className="media-right">
                                <a href="#" data-toggle="modal" data-target="#editCat" className="l-h-33"><i className="icon-pencil"></i></a>
                                <a href="#" className="l-h-33 m-l-5"><i className="icon-trash text-danger-800"></i></a>
                             </div>
                          </div>
                       </div>
                       <div id="accordion2" className="panel-collapse collapse custom-sidebar-page" aria-expanded="false">
                          <div className="panel-body">
                             <ul className="list-group  p-0">
                                <li className="list-group-item">
                                   <div className="media">
                                      <div className="media-body">
                                         <a href="#">
                                            Bed, Mattress
                              </a>
                                      </div>
                                      <div className="media-right">
                                         <a href="#" data-toggle="modal" data-target="#editCat2"><i className="icon-pencil text-slate f-s-15"></i></a>
                                         <a href="#" className="m-l-5"><i className="icon-trash text-slate f-s-15"></i></a>
                                      </div>
                                   </div>
                                </li>
                                <li className="list-group-item b-b">
                                   <div className="media">
                                      <div className="media-body">
                                         <a href="#">
                                            Bed, Mattress
                              </a>
                                      </div>
                                      <div className="media-right">
                                         <a href="#" data-toggle="modal" data-target="#editCat2"><i className="icon-pencil text-slate f-s-15"></i></a>
                                         <a href="#" className="m-l-5"><i className="icon-trash text-slate f-s-15"></i></a>
                                      </div>
                                   </div>
                                </li>
                                <li className="list-group-item">
                                   <div className="media">
                                      <div className="media-body">
                                         <a href="#">
                                            Bed, Mattress
                              </a>
                                      </div>
                                      <div className="media-right">
                                         <a href="#" data-toggle="modal" data-target="#editCat2"><i className="icon-pencil text-slate f-s-15"></i></a>
                                         <a href="#" className="m-l-5"><i className="icon-trash text-slate f-s-15"></i></a>
                                      </div>
                                   </div>
                                </li>
                             </ul>
                             <div className="text-center">
                                <a href="" className="btn btn-link" data-toggle="modal" data-target="#addCat2"><i className="icon-plus22"></i> Add New Filter</a>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div> */}
            {/* </div> */}
            <div className="col-md-12">
               <div className="clearfix">
                  <h4 className="pull-left">{this.state.inventoryName} </h4>
                  {(this.state.staff_user_id == "") && <a href="javascript:void(0);" onClick={() => this.OpenInventoryItemAddModal(this.state.main_inventory_id, this.state.sub_inventory_id)} className="btn btn-primary pull-right"><i className="icon-plus22"></i> Add Item</a>}
                  {(this.state.staff_user_id != "") && this.state.custom_menu.map((menu, i) => {
                     return (
                        <div>
                           {
                              (menu.title == "Inventory" && (menu.is_add == 1 || menu.is_add == "1")) && <a href="javascript:void(0);" onClick={() => this.OpenInventoryItemAddModal(this.state.main_inventory_id, this.state.sub_inventory_id)} className="btn btn-primary pull-right"><i className="icon-plus22"></i> Add Item</a>}
                        </div>);
                  })}
               </div>

               {
                  !this.state.loading
                  &&
                  this.state.allmaininventory.length == 0
                  &&
                  <div className="alert alert-primary border-none text-center">No Items Found.</div>
               }
               {
                  this.state.loading
                  &&
                  <div className="well text-center m-t-20"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
               }
               {
                  !this.state.loading
                  &&
                  this.state.allmaininventory.length != 0
                  &&
                  <div>
                     <p className="f-s-16">{this.state.allmaininventory[0].inventory_details.length} Item(s) found</p>
                     <div className="row">
                        {this.renderInventoryItems()}
                     </div>
                  </div>
               }

               <div className="row">

                  {/* <div className="col-lg-6 col-md-12">
                       <div className="panel panel-default">
                          <div className="panel-body">
                             <div className="media stack-media-on-mobile">
                                <div className="media-left">
                                   <div className="thumb">
                                      <a href="#" data-toggle="modal" data-target="#showimage">
                                         <img src="assets/images/architecture-cabinets-carpet-245208.jpg" className="img-responsive img-rounded media-preview" alt="" />
                                         <span className="zoom-image"><i className=" icon-zoomin3"></i></span>
                                      </a>
                                   </div>
                                </div>
                                <div className="media-body">
                                   <h6 className="media-heading"><a>Full Mattress1</a></h6>
                                   <ul className="list-inline list-inline-separate text-muted mb-5">
                                      <li>Cubic feet: 25</li>
                                      <li>Extra Service fee: 0</li>
                                   </ul>
                                   <a href="" className="btn btn-default btn-xs" data-toggle="modal" data-target="#edititem">Edit</a>
                                   <a href="" className="btn btn-link">Delete</a>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div> */}
                  {/* <div className="col-lg-6 col-md-12">
                       <div className="panel panel-default">
                          <div className="panel-body">
                             <div className="media stack-media-on-mobile">
                                <div className="media-left">
                                   <div className="thumb">
                                      <a href="#" data-toggle="modal" data-target="#showimage">
                                         <img src="assets/images/architecture-cabinets-carpet-245208.jpg" className="img-responsive img-rounded media-preview" alt="" />
                                         <span className="zoom-image"><i className=" icon-zoomin3"></i></span>
                                      </a>
                                   </div>
                                </div>
                                <div className="media-body">
                                   <h6 className="media-heading"><a>Full Mattress2</a></h6>
                                   <ul className="list-inline list-inline-separate text-muted mb-5">
                                      <li>Cubic feet: 25</li>
                                      <li>Extra Service fee: 0</li>
                                   </ul>
                                   <a href="" className="btn btn-default btn-xs" data-toggle="modal" data-target="#edititem">Edit</a>
                                   <a href="" className="btn btn-link">Delete</a>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div> */}
                  {/* <div className="col-lg-6 col-md-12">
                       <div className="panel panel-default">
                          <div className="panel-body">
                             <div className="media stack-media-on-mobile">
                                <div className="media-left">
                                   <div className="thumb">
                                      <a href="#" data-toggle="modal" data-target="#showimage">
                                         <img src="assets/images/architecture-cabinets-carpet-245208.jpg" className="img-responsive img-rounded media-preview" alt="" />
                                         <span className="zoom-image"><i className=" icon-zoomin3"></i></span>
                                      </a>
                                   </div>
                                </div>
                                <div className="media-body">
                                   <h6 className="media-heading"><a>Full Mattress3</a></h6>
                                   <ul className="list-inline list-inline-separate text-muted mb-5">
                                      <li>Cubic feet: 25</li>
                                      <li>Extra Service fee: 0</li>
                                   </ul>
                                   <a href="" className="btn btn-default btn-xs" data-toggle="modal" data-target="#edititem">Edit</a>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div> */}
               </div>
            </div>



            <div id="editCat" className="modal fade" role="dialog">
               <div className="modal-dialog">
                  <form className="modal-content">
                     <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Edit New Room</h4>
                     </div>
                     <div className="modal-body">
                        <div className="form-group">
                           <label>Title <span className="text-danger">*</span></label>
                           <input type="text" className="form-control" value="Bedroom" />
                        </div>
                        <div className="form-group">
                           <label>Upload Image <span className="text-danger">*</span></label>
                           <input type="file" />
                        </div>
                     </div>
                     <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal">Submit</button>
                     </div>
                  </form>
               </div>
            </div>
            <div id="addCat2" className="modal fade" role="dialog">
               <div className="modal-dialog">
                  <form className="modal-content">
                     <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Add New Filter</h4>
                     </div>
                     <div className="modal-body">
                        <div className="form-group">
                           <label>Title <span className="text-danger">*</span></label>
                           <input type="text" className="form-control" />
                        </div>
                     </div>
                     <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal">Submit</button>
                     </div>
                  </form>
               </div>
            </div>
            <div id="editCat2" className="modal fade" role="dialog">
               <div className="modal-dialog">
                  <form className="modal-content">
                     <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Edit Filter</h4>
                     </div>
                     <div className="modal-body">
                        <div className="form-group">
                           <label>Title <span className="text-danger">*</span></label>
                           <input type="text" className="form-control" value="Bed, Mattress" />
                        </div>
                     </div>
                     <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal">Submit</button>
                     </div>
                  </form>
               </div>
            </div>

            <div id="additem" className="modal fade" role="dialog">
               <div className="modal-dialog">
                  <form className="modal-content" id="add-inventory">
                     <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Add Item</h4>
                     </div>
                     <div className="modal-body">
                        <div className="form-group">
                           <label>Select Room <span className="text-danger">*</span></label>
                           <select className="form-control" name="main_inventory_id" id="main_inventory_id" onChange={(e) => this.getSubRoomList(e.target.value)} disabled>
                              <option value={this.state.inventoryID}>{this.state.inventoryName}</option>
                              {/* {this.state.allmaininventory.map((maininventory, i) => {
                                   return (
                                      <option value={maininventory.id}>{maininventory.title}</option>
                                   )
                                })
                                } */}
                           </select>
                           <p className="text-danger help-block">{this.state.error.inventorytitle_error}</p>
                        </div>
                        {/* <div className="form-group">
                             <label>Select Sub Room <span className="text-danger">*</span></label>
                             <select className="form-control" name="sub_inventory_id" id="sub_inventory_id">
                                <option>Select One</option>
                                {this.state.getSubRoomListDetails.map((mainsubinventory, i) => {
                                   return (
                                      <option value={mainsubinventory.id}>{mainsubinventory.title}</option>
                                   )
                                })
                                }
                             </select>
                             <p className="text-danger help-block">{this.state.error.inventorytitle_error}</p>
                          </div> */}
                        <div className="form-group">
                           <label>Title <span className="text-danger">*</span></label>
                           <input type="text" className="form-control" name="inventorytitle" id="inventorytitle" onChange={this.changeValue} />
                           <p className="text-danger help-block">{this.state.error.inventorytitle_error}</p>
                        </div>
                        <div className="form-group">
                           <label>Cubic Feet </label>
                           <input type="text" className="form-control" name="inventorycubicfeet" id="inventorycubicfeet" onChange={this.changeValue} />
                           <p className="text-danger help-block">{this.state.error.inventorycubicfeet_error}</p>
                        </div>
                        {/* <div className="form-group">
                             <label>Extra Service fee <span className="text-danger">*</span></label>
                             <input type="text" className="form-control" name="inventoryservicefee" id="inventoryservicefee" onChange={this.changeValue} />
                             <p className="text-danger help-block">{this.state.error.inventoryservicefee_error}</p>
                          </div> */}
                        <div className="form-group">
                           <label>Upload Image <span className="text-danger">*</span></label>
                           <input type="file" id="inventoryattachment" name="inventoryattachment" onChange={this.handleAttachment} />
                           <p className="text-danger help-block">{this.state.error.inventoryattachment_error}</p>
                        </div>
                     </div>
                     <div className="modal-footer">
                        <input type="hidden" id="main_inventory_id" name="main_inventory_id" value={this.state.main_inventory_id} />
                        <input type="hidden" id="sub_inventory_id" name="sub_inventory_id" value={this.state.sub_inventory_id} />
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={this.AddInventoryItem}>Submit</button>
                     </div>
                  </form>
               </div>
            </div>
            {this.state.isOpenEditModal &&
               <div id="edititem" className="modal fade" role="dialog">
                  <div className="modal-dialog">
                     <form className="modal-content">
                        <div className="modal-header">
                           <button type="button" className="close" data-dismiss="modal">&times;</button>
                           <h4 className="modal-title">Edit Item</h4>
                        </div>
                        <div className="modal-body">
                           <div className="form-group">
                              <label>Title <span className="text-danger">*</span></label>
                              <input type="text" className="form-control" name="inventorytitle" id="edit-inventorytitle" defaultValue={this.state.inventoryitemdetailsbyid.title || ''} onChange={this.changeValue} />
                              <p className="text-danger help-block">{this.state.error.inventorytitle_error}</p>
                           </div>
                           <div className="form-group">
                              <label>Cubic Feet </label>
                              <input type="text" className="form-control" name="inventorycubicfeet" id="edit-inventorycubicfeet" defaultValue={this.state.inventoryitemdetailsbyid.cubic_feet || ''} onChange={this.changeValue} />
                              <p className="text-danger help-block">{this.state.error.inventorycubicfeet_error}</p>
                           </div>
                           {/* <div className="form-group">
                             <label>Extra Service fee <span className="text-danger">*</span></label>
                             <input type="text" className="form-control" value="0" />
                          </div> */}
                           <div className="form-group">
                              <label>Upload Image <span className="text-danger">*</span></label>
                              <input type="file" id="edit-inventoryattachment" name="inventoryattachment" onChange={this.handleAttachment} />
                              <p className="text-danger help-block">{this.state.error.inventoryattachment_error}</p>
                           </div>
                           {
                              this.state.inventoryitemdetailsbyid.image &&
                              <img src={this.state.inventoryitemdetailsbyid.image} className="img-responsive img-circle img-xs" alt="" />
                           }
                        </div>
                        <div className="modal-footer">
                           <input type="hidden" id="edit_inventory_item_id" name="edit_inventory_item_id" value={this.state.inventoryitemdetailsbyid.id || ''} />
                           <input type="hidden" id="edit_inventory_item_main_id" name="edit_inventory_item_main_id" value={this.state.inventoryitemdetailsbyid.main_inventory_id || ''} />
                           <input type="hidden" id="edit_inventory_item_sub_id" name="edit_inventory_item_sub_id" value={this.state.inventoryitemdetailsbyid.sub_inventory_id || ''} />
                           <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                           <button type="button" className="btn btn-primary" onClick={this.UpdateInventoryItem}>Submit</button>
                        </div>
                     </form>
                  </div>
               </div>}
            <div id="showimage" className="modal fade" role="dialog">
               <div className="modal-dialog">
                  <form className="modal-content">
                     <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">{this.state.itemTitle}</h4>
                     </div>
                     <div className="modal-body">
                        {/* <img src="assets/images/architecture-cabinets-carpet-245208.jpg" className="img-responsive" alt="" /> */}
                        <img src={"https://myrelo.us/relotechApi/public/uploads/inventory/item/" + (this.state.itemImage)} className="img-responsive img-rounded media-preview" alt="" />
                     </div>
                     <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                     </div>
                  </form>
               </div>
            </div>
         </div>

      );
   }
}


export default Inventory;