import React, { Component } from 'react'
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataWithParam from '../services/PostDataWithParam';
import { Redirect, Link } from 'react-router-dom';
import GetData from '../services/GetData';
import {FormatSSN} from '../externaljs/FormatSSN';

class EditStaff extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Staff', link: '/independent-mover/dashboard/other-staff', class: '' },
            { title: 'Edit Staff', link: '', class: 'active' }
        ],
        fields:{
            first_name: '',
            last_name: '',
            email: '',
            contact_no: '',
            alternate_contact_no: '',
            gender: 'Mr.',
            dob: '',
            ssn: '',
            address: '',
            latitude: '',
            longitude: '',
            alternate_email: '',
            custom_profile_id:1,
            staff_id:this.props.match.params.staff_id
        },
        addStaffStatus: false,
        staffErr: '',
        profileList: [],
        processing:false
    }

    componentDidMount() {
        this.getCustomProfileList();
        this.ViewStaff();

        window.$('.input-group.date').daterangepicker({
           showDropdowns: true,
            autoclose: true,
            singleDatePicker: true
        }).on('apply.daterangepicker', (ev, picker) => {
            let fields = this.state.fields;
            fields.dob = picker.startDate.format('YYYY-MM-DD');
            this.setState({ fields: fields });
        });

        FormatSSN();
    }

    Ssnformat = (ssn) => {
        if (typeof ssn !== 'undefined' && ssn!=null) {
            let ssn_new = ssn.replace(/^.{6}/g, '***-**')
            return ssn_new
        } else {
            return '';
        }
    }

    openTextbox = () =>{        
        this.setState({ viewSsn:false,ssnClick:true },()=>{
            FormatSSN()
        });
    }

    ViewStaff = () => {
        PostDataWithParam('ViewStaffDetails',{staff_id:this.props.match.params.staff_id})
        .then((response) => {
            if (response.success === 1) {
                this.setState({ fields: response.data[0] });
            }
        })
    }

    initAutocomplete = (e) => {
        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: ['geocode'] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let fields = this.state.fields;
            fields.latitude = place.geometry.location.lat();
            fields.longitude = place.geometry.location.lng();
            fields.address = window.$('#address')[0].value;
            this.setState({ fields: fields });
        })
    }

    getCustomProfileList = () => {
        GetData('GetCustomProfileList')
        .then((response) => {
            if (response.success === 1) {
                this.setState({ profileList: response.data });
            }
        })
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        if(e.target.name=='contact_no' || e.target.name=='alternate_contact_no')
        {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                fields[e.target.name]= match[1] + ' ' + match[2] + ' ' + match[3]
              }
        }
        this.setState({ fields: fields });
    }

    handleAddStaff = (e) => {
        e.preventDefault();
        this.setState({processing: true},()=>{
        PostDataWithParam('UpdateStaff', {...this.state.fields,staff_id:this.state.fields.id})
            .then((response) => {
                let result = response;
                if (result.success === 1) {
                    window.swal('Success!', response.msg, 'success')
                    this.setState({ addStaffStatus: true });
                } else {
                    window.swal('Error!', response.msg, 'error')
                    this.setState({ addStaffStatus: false });
                }
            })
            .catch(err=>{
                window.swal('Error!','Oops! Something went wrong.','error')
            })
        })  
    }

    renderSubmitBtn = () => {
        if(this.state.processing){
            return <input type="button" className="btn btn-primary" value="Processing..." disabled={true}/>
        }else{
            return <button type="submit" className="btn btn-primary" >Add</button>
        }
    }

    render() {
        if (this.state.addStaffStatus) {
            return <Redirect to={'/independent-mover/dashboard/other-staff'} />
        }

        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="panel panel-flat profile-page">
                        <div className="panel-heading p-b-0">
                            <h5 className="panel-title">Edit Staff Details</h5>
                        </div>
                        <form action="" method="POST" onSubmit={this.handleAddStaff}>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Salutation <span className="text-danger">*</span></label>
                                            <select className="form-control" name="gender" value={this.state.fields.gender} onChange={this.changeValue}>
                                                <option>Mr.</option>
                                                <option>Ms.</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label>First Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter First Name" name="first_name" value={this.state.fields.first_name} onChange={this.changeValue}/>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label>Last Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter Last Name" name="last_name" value={this.state.fields.last_name} onChange={this.changeValue}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Email Id <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" value="" name="email" value={this.state.fields.email} onChange={this.changeValue} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Phone No <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" name="contact_no" value={this.state.fields.contact_no} onChange={this.changeValue} maxLength="10"/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Alternate Phone No</label>
                                            <input className="form-control" type="text" name="alternate_contact_no" value={this.state.fields.alternate_contact_no || ""} onChange={this.changeValue} maxLength="10"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Address</label>
                                            <input className="form-control" type="text" id="address" onFocus={this.initAutocomplete} name="address" value={this.state.fields.address} onChange={this.changeValue}/>
                                        </div>
                                    </div>


                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Date of Birth </label>

                                            <div className="input-group date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control" name="dob" id="dob" value={this.state.fields.dob} onChange={this.changeValue} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>SSN </label>
                                            <input type="text" className="form-control ssn" name="ssn" onChange={this.changeValue} maxLength="11" value={this.state.fields.ssn} placeholder="Enter your social security number" />
                                        </div>
                                    </div>
                                </div>
                                <h4 className="m-b-0">Profile</h4>
                                <hr className="m-t-5" />
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Select Profile <span className="text-danger">*</span></label>
                                            <select className="form-control" name="custom_profile_id" value={this.state.fields.custom_profile_id} onChange={this.changeValue}>
                                                <option></option>
                                                {this.state.profileList.map(profile =>
                                                    <option key={profile.id} value={profile.id} >{profile.title}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-footer text-right p-r-20">
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditStaff;