import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import PostDataWithParam from '../services/PostDataWithParam';
import Breadcumb from '../externaljs/Breadcrumb';
import GetData from '../services/GetData';
import PostDataWithParams from '../services/PostDataWithParam';
import Storage from '../externaljs/Storage';

class FindHelpers extends Component {
    state = {
        helpersCount: 0,
        loadMore: 0,
        helpers: [],
        btnCount: 0,
        search_text: '',
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Find Pros', link: '', class: 'active' }
        ],
        selectedProviders: [],
        form_data: {
            id: '',
            request_start_date: '',
            request_end_date: '',
            source_address: '',
            source_latitude: '',
            source_longitude: '',
            total_manpower_needed: '',
            services_needed: [],
            relocation_notes: '',
            customer_email: '',
            customer_name: '',
            contact_no: '',
            home_contact_no: '',
            driver_helper_mover: [],
            driver_email: '',
            driver_name: '',
            driver_mobile_number: '',
            assign_flag: 0,
            hire_by_profile_id: 2,
            order_request_id: '',
            type: ''
        },
        loading: false,
        subscription_id: 0,
        temp_helper_id: '',
        user_detail: {
             available_services: []
        },
        available_dates: [],
        get_available_dates: [],
        events: [],
        address_type: '',
        address: '',
        addJobLoader: false,
        services_needed: [],
        services_needed_str: [],
        staff_user_id: null,
        custom_menu: [],
        editPermission: true,
        isProfileChk: false,
        available_services: [],
        radius_serviced: ''
    }

    subscriptionCheckInterval = null;

    componentDidMount = () => {
        if (typeof this.props.location.state !== 'undefined' && typeof this.props.location.state.form_data !== 'undefined') {
            this.setState({ form_data: this.props.location.state.form_data });
        }

        if (typeof this.props.location.state !== 'undefined' && typeof this.props.location.state.order_request_id !== 'undefined') {

            this.setState({
                order_request_id: this.props.location.state.order_request_id,
                type: this.props.location.state.type,
                order_no: this.props.location.state.order_no,
                address_type: this.props.location.state.address_type,
                address: this.props.location.state.address,
                services_needed: this.props.location.state.services_needed,
                services_needed_str: this.props.location.state.services_needed_str
            }, () => {

                this.renderTitle()
            });
        }

        this.findHelpers(0, '')
        document.body.addEventListener('subscription', (e) => {
            this.GetUserInfo();
        }, false);
        this.GetUserInfo();
        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        let custom_menu = [];
        if (staff_user_id != "") {
            custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
        }
        this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
            // console.log(this.state.custom_menu);
            // console.log(this.state.staff_user_id);
        });

        this.subscriptionCheckInterval = setInterval(() => {
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1)
                this.setState({ editPermission: false })
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ editPermission: true })
        }, 1000);
    }

    componentWillUnmount() {
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
    }

    doSubscribe = (e) => {
        window.swal({
            title: "Warning!",
            text: "Please upgrade your membership to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }


    changeValue = (e) => {
        let search_text = e.target.value;
        this.setState({ search_text: search_text });
    }
    handleSearchOnEnterKeyPress = (e) => {
        const code = e.keyCode || e.which;
        if (code === 13) {
            this.searchProvider();
        }
    }
    searchProvider = () => {

        this.findHelpers(0, this.state.search_text)

    }

    GetUserInfo = () => {
        GetData('GetUserInfo', {})
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ subscription_id: response.data.subscription_id });
                }
            })
            .catch(err => { })
    }

    findHelpers = (offset, search_text) => {
        this.setState({ loading: true }, () => {
            PostDataWithParam('FindDriversHelpersForMMC', { offset: offset, search_text: search_text, order_id: this.state.order_request_id, type: 'all', mmc: 'mmc' })
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ helpers: response.data.mmc, helpersCount: response.data.count, loadMore: response.data.load_more, loading: false, btnCount: 0 });
                    } else {
                        this.setState({ helpers: [], helpersCount: 0, loadMore: 0, loading: false, btnCount: 0 });
                    }
                })
                .catch(() => {
                    this.setState({ helpers: [], helpersCount: 0, loadMore: 0, loading: false, btnCount: 0 });
                })
        })
    }

    loadMore = () => {
        let count = this.state.btnCount;

        count = count + 1;
        let offset = count * 9;

        this.setState({ btnCount: count, loading: true }, () => {
            PostDataWithParam('FindDriversHelpersForMMC', { offset: offset, search_text: this.state.search_text, type: 'all', mmc: 'mmc' })
                .then((response) => {
                    if (response.success === 1) {
                        let oldHelpers = this.state.helpers;
                        let newHelpers = response.data.mmc;
                        let allHelpers = oldHelpers.concat(newHelpers);
                        this.setState({ helpers: allHelpers, helpersCount: response.data.count, loadMore: response.data.load_more, loading: false, btnCount: count }, () => {
                            for (let item of this.state.selectedProviders) {
                                window.$('#helper_' + item.user_id).attr('checked', true)
                            }
                        });
                    } else {
                        this.setState({ helpers: [], helpersCount: 0, loadMore: 0, loading: false, btnCount: 0 });
                    }
                })
                .catch(() => {
                    this.setState({ helpers: [], helpersCount: 0, loadMore: 0, loading: false, btnCount: 0 });
                })
        });
    }

    checkHelper = (e, profile_id, chk_id) => {
        let selectedProviders = this.state.selectedProviders;

        if (e.target.checked) {
            if (selectedProviders.length >= 10) {
                document.getElementById('helper_' + chk_id).checked = false;

                window.swal('Error!', 'Pick upto 10', 'error');
            }
            else
                selectedProviders.push({ user_id: e.target.value, name: e.target.name, profile_id: profile_id })
        } else {
            for (let i = 0; i < selectedProviders.length; i++) {
                if (selectedProviders[i].user_id === e.target.value) {
                    selectedProviders.splice(i, 1);
                    break;
                }
            }
        }

        this.setState({ selectedProviders: selectedProviders });
    }

    removeProvider = (user_id) => {
        let selectedProviders = this.state.selectedProviders;

        for (let i = 0; i < selectedProviders.length; i++) {
            if (selectedProviders[i].user_id === user_id) {
                selectedProviders.splice(i, 1);
                window.$('#helper_' + user_id).attr('checked', false)
                break;
            }
        }

        this.setState({ selectedProviders: selectedProviders });
    }

    redirectSubscription = () => {
        window.$('#subscribeToPremiumModal').modal('show');
    }

    createJob = (selectedProviders) => {
        let form_data = this.state.form_data;

        if (form_data.services_needed.length != 0) {
            let services_needed = form_data.services_needed.map((item, i) => {
                return { name: item };
            });
            form_data.services_needed = JSON.stringify(services_needed);
            form_data.driver_helper_mover = JSON.stringify(selectedProviders);

            this.setState({ addJobLoader: true }, () => {
                PostDataWithParam('CreateOffer', form_data)
                    .then((response) => {
                        if (response.success == 1) {
                            this.setState({ addJobLoader: false })
                            window.swal('Success!', response.msg, 'success')
                            this.props.history.push({
                                pathname: '/independent-mover/dashboard/assign-offer-job',
                                state: { selectedProviders: selectedProviders, form_data: this.state.form_data }
                            })
                        } else {
                            this.setState({ addJobLoader: false })
                            window.swal('Error!', response.msg, 'error');
                        }
                    })
                    .catch(err => {
                        this.setState({ addJobLoader: false })
                        window.swal('Error!', 'Something went wrong', 'error');
                    })
            });
        } else {
            this.props.history.push({
                pathname: '/independent-mover/dashboard/assign-offer-job',
                state: { selectedProviders: selectedProviders, form_data: this.state.form_data }
            })
        }
    }

    handleLimitInvitation = (selectedProviders, checkedItems) => {
        if (this.state.order_request_id) {
            if (checkedItems !== 0) {

                if (checkedItems > 10) {
                    window.swal('Error!', 'Pick upto 10', 'error')
                    return (
                        <button type="button" className="btn btn-primary btn-xs btn-block" disabled={true}>Send Offer</button>
                    )
                } else {

                    if (this.state.addJobLoader) {
                        return <button type="button" className="btn btn-primary btn-xs btn-block" disabled={true}>Processing...</button>
                    } else {

                        if (this.state.editPermission) {
                            return <button type="button" className="btn btn-primary btn-xs btn-block" onClick={this.assignHelper}>Send Offer</button>
                        }
                        else {
                            return <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-primary btn-xs btn-block" onClick={this.doSubscribe}>Send Offer</Link>
                        }
                    }
                }
            } else {
                return <button type="button" className="btn btn-primary btn-xs btn-block" disabled={true}>Send Offer</button>
            }
        } else {
            if (checkedItems !== 0) {
                if (checkedItems > 10) {
                    window.swal('Error!', 'Pick upto 10', 'error')
                    return (
                        <button type="button" className="btn btn-primary btn-xs btn-block" disabled={true}>Send Offer</button>
                    )
                } else {


                    if (this.state.addJobLoader) {
                        return <button type="button" className="btn btn-primary btn-xs btn-block" disabled={true}>Processing...</button>
                    } else {

                        if (this.state.editPermission) {
                            return <button type="button" className="btn btn-primary btn-xs btn-block" onClick={() => this.createJob(selectedProviders)}>Send Offer</button>
                        }
                        else {
                            return <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-primary btn-xs btn-block" onClick={this.doSubscribe}>Send Offer</Link>
                        }
                    }
                }
            } else {
                return (
                    <button type="button" className="btn btn-primary btn-xs btn-block" disabled={true}>Send Offer</button>
                )
            }
        }
    }

    assignHelper = () => {

        this.setState({ addJobLoader: true }, () => {
            PostDataWithParam('AssignReloHelpersToExistingJob', {
                order_request_id: this.state.order_request_id,
                flag: this.state.type,
                driver_helper_mover: JSON.stringify(this.state.selectedProviders),
                address_type: this.state.address_type,
                address: this.state.address,
                services_needed: this.state.services_needed
            })
                .then((response) => {
                    if (response.success == 1) {
                        this.setState({ addJobLoader: false });
                        window.swal('Success!', response.msg, 'success')
                        let flag = this.state.type == "outsource" ? 'accepted' : '';
                        this.props.history.push({
                            pathname: '/independent-mover/dashboard/order-details',
                            state: { type: flag, id: this.state.order_request_id, request_from: this.props.location.state.request_from }
                        });
                    } else {
                        this.setState({ addJobLoader: false });
                        window.swal('Error!', response.msg, 'error');
                    }
                })
                .catch(err => { })
        });
    }

    renderTitle = () => {
        if (this.state.order_request_id) {
            return (
                <div className="">
                    <Link to={{
                        pathname: '/independent-mover/dashboard/order-details',
                        state: {
                            id: this.state.order_request_id,
                            type: ''
                        }
                    }} className="btn btn-link p-l-0"><i className="icon-arrow-left8"></i> Back</Link>
                    <h5 className="panel-title">
                        Find Pros for the
                        {
                            this.state.address_type == "Both"
                            &&
                            ` Origin and Destination Addresses`
                        }
                        {
                            this.state.address_type != "Both"
                            &&

                            ` ${this.state.address_type} Address ${this.state.address}`
                        }
                        (Relo #{this.state.order_no})
                        </h5>
                </div>
            )
        } else {
            return (
                <h5 className="panel-title">Find Pro </h5>
            )
        }
    }

    setTempHelper = (id) => {
        this.setState({ temp_helper_id: id }, () => {
            this.GetHelperProfile()
        });
    }

    GetHelperProfile = () => {
        this.setState({ modalLoader: true }, () => {
            PostDataWithParam('ViewHelperProfile', { user_id: this.state.temp_helper_id })
                .then((response) => {
                    if (response.success === 1) {
                        let user_detail = this.state.user_detail;
                        user_detail = response.data;
                        if (response.data.available_services) {
                            user_detail.available_services = JSON.parse(response.data.available_services);
                        } else {
                            user_detail.available_services = [];
                        }
                        this.setState({ user_detail: user_detail, modalLoader: false }, () => {
                            this.getAvailability();
                        })
                    } else {
                        this.setState({ user_detail: [], modalLoader: false }, () => {
                            this.getAvailability();
                        })
                    }
                })
                .catch(err => {
                    this.setState({ user_detail: [], modalLoader: false }, () => {
                        this.getAvailability();
                    })
                })
        })
    }

    getAvailability = () => {


        PostDataWithParams('GetAvailableDates', { type: '1', user_id: this.state.temp_helper_id })
            .then(response => {
                if (response.success === 1) {
                    this.setState({ available_dates: response.data }, () => {

                        this.initFullcalendar();

                    })
                } else {
                    this.setState({ get_available_dates: [], available_dates: [] }, () => {
                        this.initFullcalendar();
                    })
                }
            })
            .catch(err => {
                this.setState({ get_available_dates: [], available_dates: [] }, () => {
                    this.initFullcalendar();
                })
            })
    }

    initFullcalendar = () => {
        let dt = new Date();
        let obj = this;

        window.$('.fullcalendar-basic').fullCalendar({
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,basicWeek,basicDay'
            },
            defaultDate: dt,
            editable: true,
            events: obj.state.available_dates
        });
    }

    selectHelper = (e, profile_id, chk_id) => {
        let selectedProviders = this.state.selectedProviders;

        let Q = document.getElementById('helper_' + chk_id).checked
        if (Q) {
            this.setState({ isProfileChk: false })
        }

        else if (e.target.checked) {
            this.setState({ isProfileChk: true })

            if (selectedProviders.length >= 10) {
                document.getElementById('helper_' + chk_id).checked = false;

                window.swal('Error!', 'Pick upto 10', 'error');
            }
            else {
                selectedProviders.push({ user_id: e.target.value, name: e.target.name, profile_id: profile_id })
                document.getElementById('helper_' + chk_id).checked = true;
            }
        } else {
            for (let i = 0; i < selectedProviders.length; i++) {
                if (selectedProviders[i].user_id === e.target.value) {
                    selectedProviders.splice(i, 1);
                    break;
                }
            }
        }

        this.setState({ selectedProviders: selectedProviders }, () => {
            // console.log(this.state.selectedProviders)
        });

        // if(e.target.checked)
        // {
        //     // window.$('#{"helper_" + this.state.helpers.user_id}').checked=true;
        //     this.setState({isProfileChk:true})
        // }
    }


    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div>
                        <div className="panel panel-flat profile-page">
                            <div className="panel-heading clearfix p-b-5">
                                {this.renderTitle()}
                            </div>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-md-10 p-r-5">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Search by name, city, state, zip code, address" className="form-control"
                                                name="search_text"
                                                onChange={this.changeValue}
                                                onKeyPress={this.handleSearchOnEnterKeyPress}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2 p-l-5">
                                        <div className="form-group">
                                            <button type="button" className="btn btn-primary btn-block" onClick={this.searchProvider}>FIND</button>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.services_needed_str.length != 0
                                    &&
                                    <div className="row">
                                        <div className="col-md-12">
                                            <label className="text-bold m-b-0">Services Needed:</label>
                                            <p className="m-b-20">
                                                {
                                                    this.state.services_needed_str.length != 0
                                                    &&
                                                    this.state.services_needed_str.map((item, i) => {
                                                        return (
                                                            <span key={i}>
                                                                {item}
                                                                {
                                                                    this.state.services_needed_str.length != (i + 1)
                                                                    &&
                                                                    ' , '
                                                                }
                                                            </span>
                                                        )
                                                    })
                                                }
                                            </p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9">
                                <p className="f-s-16">{this.state.helpersCount} Pro found <small>( Pick upto 10 )</small></p>

                                {
                                    this.state.loading
                                    &&
                                    <div className="col-md-12">
                                        <div className="well text-center">
                                            <i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...
                                        </div>
                                    </div>
                                }

                                {
                                    this.selectHelper
                                    &&
                                    <input type="checkbox" checked="true" id={"helper_" + this.state.helpers.user_id} value={this.state.helpers.user_id} name={this.state.helpers.first_name + ' ' + this.state.helpers.last_name} />
                                    &&
                                    this.checkHelper
                                }

                                <div className="offer-grid grid-col-3">
                                    {


                                        !this.state.loading
                                        &&
                                        this.state.helpers.length != 0

                                        &&
                                        this.state.helpers.map((helper, index) => {
                                            return (

                                                <div className="service-box m-b-20" key={index}>
                                                    {helper.bgv_verified == 1 &&
                                                        <div className="bgc-badge-wrap">
                                                            <div className="bgc-badge-media text-left">
                                                                <img src="assets/images/clearstar-logo.png" alt="" />
                                                                <p className="m-0 text-slate">Passed Background Check</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.staff_user_id == "" &&
                                                        <div className="custom-checkbox-wrap">
                                                            <label className="checkbox-inline custom-checkbox">

                                                                <input type="checkbox" id={"helper_" + helper.user_id} onClick={(e) => this.checkHelper(e, helper.profile_id, helper.user_id)} value={helper.user_id} name={helper.first_name + ' ' + helper.last_name} />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.staff_user_id != "" && this.state.custom_menu.map((menu, i) => {
                                                            return (
                                                                <div className="custom-checkbox-wrap">
                                                                    {(menu.title == "Find & Book a Pro" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                                        <label className="checkbox-inline custom-checkbox">
                                                                            <input type="checkbox" id={"helper_" + helper.user_id} onClick={(e) => this.checkHelper(e, helper.profile_id, helper.user_id)} value={helper.user_id} name={helper.first_name + ' ' + helper.last_name} />
                                                                            <span></span>
                                                                        </label>
                                                                    }
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <div className="service-box-list">
                                                        <ul className="list-unstyled m-0">
                                                            {
                                                                helper.profile_image == ""
                                                                &&
                                                                <li className=""><span style={{ background: 'url(assets/images/default_avatar.jpg) no-repeat center center / 100%' }}></span></li>
                                                            }
                                                            {
                                                                helper.profile_image !== ""
                                                                &&
                                                                <li className=""><span style={{ background: 'url(' + helper.profile_image + ') no-repeat center center / 100%' }}></span></li>
                                                            }
                                                            <li className="f-s-16">

                                                                <a data-toggle="modal" data-target="#profileInfoModal" href="#" onClick={() => this.setTempHelper(helper.user_id)}>
                                                                    {helper.first_name + ' ' + helper.last_name}</a>

                                                                {
                                                                    helper.address
                                                                    &&
                                                                    <h6 className="text-slate text-uppercase m-0"><b>Address:</b> <br /><p className="text-muted text-capitalize">{(helper.address.length < 30) ? helper.address : helper.address.substr(0, 30) + '...'}</p></h6>
                                                                }
                                                                {
                                                                    !helper.address
                                                                    &&
                                                                    <h6 className="text-slate text-uppercase m-0"><b>Address:</b> <br /> <p className="text-muted text-capitalize">---</p></h6>

                                                                }

                                                                {
                                                                    (helper.available_services) ?
                                                                        (<h6 className="text-slate text-uppercase m-0"><b>Available Services:</b> <br /><p className="text-muted text-capitalize">{helper.available_services}</p></h6>)
                                                                        :
                                                                        <h6 className="text-slate text-uppercase m-0"><b>Available Services:</b> <br /> <p className="text-muted text-capitalize">---</p></h6>

                                                                }
                                                                {(helper.radius_serviced) ?
                                                                    (<h6 className="text-slate text-uppercase m-0"><b>Radius Served from Home Address:</b><br /><p className="text-muted text-capitalize">Radius Serviced from Home Address:{helper.radius_serviced} Mile(s)</p></h6>)
                                                                    :
                                                                    <h6 className="text-slate text-uppercase m-0"><b>Radius Served from Home Address:</b> <br /> <p className="text-muted text-capitalize">---</p></h6>

                                                                }
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    {
                                                        Math.ceil(helper.avarage_rating) > 0
                                                        &&
                                                        <div className="service-box-list text-center text-orange">
                                                            <p className="m-0 f-s-24">{helper.avarage_rating}</p>
                                                            <p className="m-0">
                                                                <React.Fragment>
                                                                    <span className={(Math.ceil(helper.avarage_rating) >= 1) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                    <span className={(Math.ceil(helper.avarage_rating) >= 2) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                    <span className={(Math.ceil(helper.avarage_rating) >= 3) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                    <span className={(Math.ceil(helper.avarage_rating) >= 4) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                    <span className={(Math.ceil(helper.avarage_rating) == 5) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                </React.Fragment>
                                                            </p>
                                                        </div>
                                                    }
                                                    {
                                                        Math.ceil(helper.avarage_rating) == 0.0
                                                        &&
                                                        <div className="service-box-list text-center text-orange">
                                                            <p className="m-0 f-s-24">----</p>
                                                            <p className="m-0">
                                                                <React.Fragment>
                                                                    <span className='icon-star-empty3'></span>
                                                                    <span className='icon-star-empty3'></span>
                                                                    <span className='icon-star-empty3'></span>
                                                                    <span className='icon-star-empty3'></span>
                                                                    <span className='icon-star-empty3'></span>
                                                                </React.Fragment>
                                                            </p>
                                                        </div>
                                                    }
                                                </div>


                                            )
                                        })
                                    }
                                </div>
                                {
                                    !this.state.loading
                                    &&
                                    this.state.helpers.length == 0
                                    &&
                                    <div className="panel panel-default">
                                        <div className="panel-body">
                                            <div className="alert alert-primary border-none text-center">No Pro Found</div>
                                        </div>
                                    </div>
                                }

                                {
                                    this.state.loadMore == 1
                                    &&
                                    <div className="text-center">
                                        <a href="javascript:void(0)" className="btn btn-primary" onClick={this.loadMore}>Load More</a>
                                    </div>
                                }
                            </div>

                            {this.state.staff_user_id == "" &&
                                <div className="col-md-3">
                                    <div className="sidebar-fixed-rightside">
                                        <p className="f-s-16">Selected Pros</p>
                                        <div className="panel panel-default sidebar-content">
                                            <div className="panel-body selected-comapany">
                                                <form action="proposal-add.php" method="post">
                                                    <h6 className="m-t-0"><img src="assets/images/icons/support.svg" alt="" />Selected Pros ({this.state.selectedProviders.length}/10)</h6>
                                                    <ul className="selected-company-list m-b-25">
                                                        {
                                                            this.state.selectedProviders.length != 0
                                                            &&
                                                            this.state.selectedProviders.map((item, index) => {
                                                                return (
                                                                    <li key={index}>{item.name}<a href="javascript:void(0)" className="pull-right" onClick={() => this.removeProvider(item.user_id)}><i className="icon-cross2 text-muted"></i></a></li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                    <div className="text-center m-t-15">

                                                        {this.handleLimitInvitation(this.state.selectedProviders, this.state.selectedProviders.length)}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.staff_user_id != "" && this.state.custom_menu.map((menu, i) => {
                                    return (
                                        <div className="col-md-3">
                                            { (menu.title == "Find & Book a Pro" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                <div className="sidebar-fixed-rightside">
                                                    <p className="f-s-16">Selected Pro</p>
                                                    <div className="panel panel-default sidebar-content">
                                                        <div className="panel-body selected-comapany">
                                                            <form action="proposal-add.php" method="post">
                                                                <h6 className="m-t-0"><img src="assets/images/icons/support.svg" alt="" />Selected Pro ({this.state.selectedProviders.length}/10)</h6>
                                                                <ul className="selected-company-list m-b-25">
                                                                    {
                                                                        this.state.selectedProviders.length != 0
                                                                        &&
                                                                        this.state.selectedProviders.map((item, index) => {
                                                                            return (
                                                                                <li key={index}>{item.name}<a href="javascript:void(0)" className="pull-right" onClick={() => this.removeProvider(item.user_id)}><i className="icon-cross2 text-muted"></i></a></li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                                <div className="text-center m-t-15">

                                                                    {this.handleLimitInvitation(this.state.selectedProviders, this.state.selectedProviders.length)}
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
                {/* profile modal started */}
                <div id="profileInfoModal" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            {
                                this.state.modalLoader
                                &&
                                <div className="col-md-12">
                                    <div className="well text-center">
                                        <i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...
                        </div>
                                </div>
                            }
                            <div className="modal-body p-t-0">
                                {!this.state.modalLoader
                                    &&
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="text-center">
                                                    {
                                                        this.state.user_detail.profile_image != ''
                                                        &&
                                                        <span className="thumb-60" style={{ background: 'url(' + this.state.user_detail.profile_image + ') no-repeat center center / 100%' }}></span>
                                                    }
                                                    {
                                                        this.state.user_detail.profile_image == ''
                                                        &&
                                                        <span className="thumb-60" style={{ background: 'url(assets/images/default_avatar.jpg) no-repeat center center / 100%' }}></span>
                                                    }
                                                </div>

                                                {
                                                    Math.ceil(this.state.user_detail.avarage_rating) > 0
                                                    &&
                                                    <div className="text-center text-orange">
                                                        <p className="m-t-15 m-b-0">
                                                            <React.Fragment>
                                                                <span className={(Math.ceil(this.state.user_detail.avarage_rating) >= 1) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                <span className={(Math.ceil(this.state.user_detail.avarage_rating) >= 2) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                <span className={(Math.ceil(this.state.user_detail.avarage_rating) >= 3) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                <span className={(Math.ceil(this.state.user_detail.avarage_rating) >= 4) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                <span className={(Math.ceil(this.state.user_detail.avarage_rating) == 5) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                {this.state.user_detail.avarage_rating}
                                                            </React.Fragment>
                                                        </p>
                                                    </div>
                                                }
                                                {
                                                    Math.ceil(this.state.user_detail.avarage_rating) == 0.0
                                                    &&
                                                    <div className="text-center text-orange">
                                                        <p className="m-t-15 m-b-0">
                                                            <React.Fragment>
                                                                <span className='icon-star-empty3'></span>
                                                                <span className='icon-star-empty3'></span>
                                                                <span className='icon-star-empty3'></span>
                                                                <span className='icon-star-empty3'></span>
                                                                <span className='icon-star-empty3'></span>
                                                            </React.Fragment>
                                                        </p>
                                                    </div>
                                                }
                                                <h3>About</h3>
                                                <hr className="m-t-5 m-b-15 border-color-light-blue" />
                                                <p>
                                                    {this.state.user_detail.about_me || "----"}
                                                </p>
                                            </div>
                                            <div className="col-md-8">
                                                <h1 className="text-blue m-t-0 inline-block">{this.state.user_detail.gender} {this.state.user_detail.first_name} {this.state.user_detail.last_name}</h1>
                                                <div className="custom-checkbox-wrap inline-block">
                                                    <label className="checkbox-inline custom-checkbox m-l-15 m-b-5">

                                                        <input type="checkbox"
                                                            id={"helper_" + this.state.user_detail.user_id}
                                                            value={this.state.user_detail.user_id}
                                                            name={this.state.user_detail.first_name + ' ' + this.state.user_detail.last_name}
                                                            className="btn btn-primary" data-dismiss="modal"
                                                            onClick={(e) => this.selectHelper(e, this.state.user_detail.profile_id, this.state.user_detail.user_id)} />
                                                        <span></span>
                                                    </label>
                                                </div>


                                                <p className="f-s-16 text-muted"><i className="icon-location3 text-primary"></i> {this.state.user_detail.address
                                                    ? `${this.state.user_detail.address}${this.state.user_detail.zip
                                                        ?
                                                        `, ${this.state.user_detail.zip}`
                                                        : ''
                                                    }`
                                                    : "----"}
                                                </p>
                                                <div className="border-left p-l-30 m-t-30 border-color-light-blue ">
                                                    <h4 className="m-t-5">Basic Info</h4>
                                                    <hr className="m-t-0 m-b-10 border-color-light-blue" />
                                                    <div className="row">
                                                        {/* <div className="col-md-6">
                                          <label className="text-bold m-b-0">Email Address</label>
                                          <p className="m-b-20">{this.state.user_detail.email || "----"}</p>
                                       </div>
                                       <div className="col-md-6">
                                          <label className="text-bold m-b-0">Recovery Email Address</label>
                                          <p className="m-b-20">{this.state.user_detail.alternate_email || "----"}</p>
                                       </div>
                                       <div className="col-md-6">
                                          <label className="text-bold m-b-0">Mobile Phone</label>
                                          <p className="m-b-20">{this.state.user_detail.contact_no || "----"}</p>
                                       </div>
                                       <div className="col-md-6">
                                          <label className="text-bold m-b-0">Alternate Phone No</label>
                                          <p className="m-b-20">{this.state.user_detail.home_contact_no || "----"}</p>
                                       </div> */}
                                                        {/* <div className="col-md-6">
                                                            <label className="text-bold m-b-0">Date Of Birth</label>
                                                            <p className="m-b-20">{this.state.user_detail.dob || "----"}</p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="text-bold m-b-0">Social Security number</label>
                                                            <p className="m-b-20">{this.state.user_detail.ssn || "----"}</p>
                                                        </div> */}
                                                        <div className="col-md-6">
                                                            <label className="text-bold m-b-0">Years of Experience</label>
                                                            <p className="m-b-20">{(this.state.user_detail.years_of_experience > 0) ? this.state.user_detail.years_of_experience + ' Years' : '----'} </p>
                                                        </div>
                                                    </div>

                                                    <hr className="m-t-0 m-b-10 border-color-light-grey" />
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label className="text-bold m-b-0">Available Services</label><br/>
                                                            {
                                                                this.state.user_detail.available_services.length != 0
                                                                &&
                                                                this.state.user_detail.available_services.map((item, i) => {
                                                                    return (<React.Fragment key={i}><label className="checkbox-inline custom-checkbox  m-b-10 m-l-0 m-r-10">
                                                                        <input type="checkbox" disabled="disabled" checked={true} />
                                                                        <span className=""></span>
                                                                        {item.name}
                                                                    </label></React.Fragment>)
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <hr className="m-t-0 m-b-10 border-color-light-grey" />
                                                        <div className="row">
                                                        <div className="col-md-6">
                                                            <label className="text-bold m-b-0">Radius Serviced from Home Address</label><br/>
                                                            <p className="m-b-20">{this.state.user_detail.radius_serviced || "----"} MILES</p>
                                                        </div>
                                                    </div>


                                                    {/* <h4 className="m-t-5">Passport Info</h4>
                                    <hr className="m-t-0 m-b-10 border-color-light-blue"/>

                                    <div className="row">
                                       <div className="col-md-6">
                                          <label className="text-bold m-b-0">Passport Number</label>
                                          <p className="m-b-20">{this.state.user_detail.passport_no || "----"}</p>
                                       </div>
                                       <div className="col-md-6">
                                          <label className="text-bold m-b-0">Passport Expiration Date</label>
                                          <p className="m-b-20">{this.state.user_detail.passport_exp_date || "----"}</p>
                                       </div>
                                       <div className="col-md-6">
                                          <label className="text-bold m-b-0">Place of Birth</label>
                                          <p className="m-b-20">{this.state.user_detail.place_of_birth || "----"}</p>
                                       </div>
                                       
                                    </div>

                                    <h4 className="m-t-5">Medical Info</h4>
                                    <hr className="m-t-0 m-b-10 border-color-light-blue"/>
                                    <div className="row">
                                       <div className="col-md-6">
                                          <label className="text-bold m-b-0">Medical Card Issue Date</label>
                                          <p className="m-b-20">{this.state.user_detail.medical_card_issue_date || "----"}</p>
                                       </div>
                                       <div className="col-md-6">
                                          <label className="text-bold m-b-0">Medical Card Expiration Date</label>
                                          <p className="m-b-20">{this.state.user_detail.medical_exam_exp_date || "----"}</p>
                                       </div>
                                       
                                    </div>
                                    <h3>Background Check Info</h3>
                                    <hr className="m-t-5 m-b-15 border-color-light-blue"/>

                                    <div className="row">
                                       <div className="col-md-6">
                                          <label className="text-bold m-b-0">Background check performed Date</label>
                                          <p className="m-b-20">{this.state.user_detail.background_check_perform_date || "----"}</p>
                                       </div>
                                       <div className="col-md-6">
                                          <label className="text-bold m-b-0">Background Check Completed</label>
                                          <p className="m-b-20">{this.state.user_detail.background_check_status == 1 ? 'Yes' : 'No'}</p>
                                       </div>
                                        */}
                                                    {/* </div> */}
                                                </div>

                                            </div>
                                            <div className="col-md-12">
                                                <h3>Availability</h3>
                                                <hr className="m-t-5 m-b-15 border-color-light-blue" />
                                                <div className="p-15">
                                                    <div className="fullcalendar-basic"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                            <div className="modal-footer">
                                {
                                    !this.state.modalLoader
                                    &&
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                {/* 
                profile modal end */}
            </div>
        );
    }
}

export default FindHelpers;