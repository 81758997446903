import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Breadcumb from '../externaljs/Breadcrumb';

class UnassignedOfferDetails extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Bookings', link: '/independent-mover/dashboard/unassign-offer', class: '' },
            { title: 'Request Details', link: '', class: 'active' }
        ]
    }

    componentDidMount() {
        window.$('.radio-circle > input').click(function () {
            if (window.$(this).prop("checked") == true) {
                window.$(this).closest('li').addClass('active');
                window.$(this).attr('disabled', 'disabled');
                window.$(this).closest('li').next('li').find('input').removeAttr('disabled');
            }
        });
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        {Breadcumb(this.state.breadcrumb)}                        
                        <div className="panel panel-flat profile-page">
                            <div className="panel-heading">
                                <div className="heading-elements">
                                    <ul className="icons-list">
                                        <li className="dropdown">
                                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                <i className="icon-menu9"></i>
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-right">
                                                <li><Link to="/independent-mover/dashboard/outsource-project"><i className=" icon-redo2"></i> Outsource Project</Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <h6 className="m-b-0 m-t-0 text-semibold">Order Summary<br /><small>Request ID #006254</small></h6>
                            </div>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <div className="well m-b-30 text-center bg-blue-gradient ">
                                            <h1 className="no-margin text-light">Congratulation!</h1>
                                            <h6 className="m-t-8 m-b-30 text-semibold">
                                                Margo Baker awarded you to complete the relocation.
                                                Would you like to accept the Job?
                                            </h6>
                                            <a href="#" className="btn btn-success m-r-15"><i className="icon-check2"></i> Yes, Accept it</a>
                                            <a href="#" className="btn btn-default"><i className="icon-cross"></i> No, Reject it</a>

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="text-bold m-b-0">Customer Name:</label>
                                        <p className="m-b-20">Margo Baker</p>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="text-bold m-b-0">Requested Relocation Date:</label>
                                        <p className="m-b-20">Sep 19, 2018, 3:40 PM</p>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="text-bold m-b-0">Status</label>
                                        <p className="m-b-20">In Transit &nbsp; ( <a className="" data-toggle="modal" data-target="#tracking-order"><u>Track Order</u></a> )</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <label className="text-bold m-b-0">Origin Address:</label>
                                        <p className="m-b-20">1118 Oak Street, Syracuse, NY, New York, 13224</p>
                                    </div>
                                    <div className="col-md-4">
                                        <label className="text-bold m-b-0">Destination Address:</label>
                                        <p className="m-b-20">34 Lee Road, Syracuse, NY, New York, 13224</p>
                                    </div>
                                </div>
                                <div id="additionalifo" className="collapse">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Customer Email address:</label>
                                            <p className="m-b-20">example@gomail.com</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Customer Mobile:</label>
                                            <p className="m-b-20">+12 457 558</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Customer Alternate Phone Number:</label>
                                            <p className="m-b-20">+12 457 558</p>
                                        </div>
                                    </div>
                                    <label className="text-bold m-b-0">Description</label>
                                    <p className="m-b-20">Pellentesque lacinia id diam a elementum. Pellentesque habitant morbi tristique
                                        senectus et netus et malesuada fames ac turpis egestas. Nunc egestas erat sed nisi cursus
                                        vitae quis nibh. Nulla sed augue sed diam aliquam vulputate sit amet sit amet mauris. Vestibulum imperdiet
                                        est ac mollis porta, purus eros vestibulum erat, sit amet sollicitudin nisl sapien eu turpis. Nulla sit amet
                                        interdum sapien. Donec gravida felis in lorem lobortis tempus. Morbi pellentesque augue sit amet est vulputate
                                        sollicitudin. Integer hendrerit, mi eget interdum rutrum, neque nisi sodales purus, at tempor risus libero vitae
                                        orci. Aliquam eu fermentum enim, non fringilla ligula. Nam non tellus ac purus tincidunt vulputate. Sed porta velit
                                        in quam commodo vestibulum. Donec quis dolor mollis,
                        urna id, volutpat velit. Morbi vel pulvinar urna. Fusce ut hendrerit libero. </p>

                                </div>
                                <div className="text-center m-b-30">
                                    <a href="javascript:void(0);" data-toggle="collapse" data-target="#additionalifo">- Show/Hide Additional Information -</a>
                                </div>
                                <div className="m-b-15 bg-red-gradient p-15 border-radius-3px">
                                    <h5 className="m-0"><i className="icon-exclamation"></i> Pre-Move survey pending by Margo Baker</h5>
                                </div>
                            </div>
                            <div className="panel-footer p-0">
                                <ul className="nav nav-tabs nav-tabs-solid nav-tabs-component nav-justified cuatom-tab-design m-0">
                                    <li className="active"><a href="#tab-premove-survey" data-toggle="tab" aria-expanded="true"><h6 className="m-0">Pre-move survey files<br />&amp; conversation</h6></a></li>
                                    <li className=""><a href="#tab-drivers-helpers" data-toggle="tab" aria-expanded="false"><h6 className="m-0">Drivers &amp; Helpers</h6><small>2 drivers & 10 Helpers</small></a></li>
                                    <li className=""><a href="#tab-tracking" data-toggle="tab" aria-expanded="false"><h6 className="m-0">Tracking</h6><small>In transit</small></a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="tabbable">
                            <div className="tab-content">
                                <div className="tab-pane active" id="tab-premove-survey">
                                    <div className="tabbable">
                                        <div className="clearfix">
                                            <h4 className="m-t-0">Files for pre-move survey</h4>
                                        </div>
                                        <div className="panel panel-flat profile-page">
                                            <div className="panel-body">
                                                <div className="file-manager hori-scroll">
                                                    <ul>
                                                        <li>
                                                            <div className="file-wrapper filetype-image">
                                                                <div className="options">
                                                                    <a href="" className="text-danger-600"><i className="icon-cancel-circle2"></i></a>
                                                                </div>
                                                                <div className="file-icon" style={{ backgroundImage: 'url(assets/images/124883885.jpg)' }}></div>
                                                                <div className="file-title"><a href=""><i className="icon-image5 position-left text-primary"></i> image-file-124883885.jpg</a></div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="file-wrapper filetype-pdf">
                                                                <div className="options">
                                                                    <a href="" className="text-danger-600"><i className="icon-cancel-circle2"></i></a>
                                                                </div>
                                                                <div className="file-icon"><img src="assets/images/icons/pdf.svg" alt="PDF" /></div>
                                                                <div className="file-title"><a href=""><i className="icon-file-pdf position-left text-danger"></i> pdf-file-124883885.pdf</a></div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="file-wrapper filetype-audio">
                                                                <div className="options">
                                                                    <a href="" className="text-danger-600"><i className="icon-cancel-circle2"></i></a>
                                                                </div>
                                                                <div className="file-icon"><img src="assets/images/icons/headphones.svg" alt="Audio" /></div>
                                                                <div className="file-title"><a href=""><i className="icon-music position-left text-indigo"></i> image-file-124883885.jpg</a></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h4>Conversations</h4>
                                    <div className="panel panel-flat profile-page">
                                        <div className="panel-body p-b-0 editor-box" id="editor-box-in">
                                            <form action="#">
                                                <div className="form-group">
                                                    <label>Write Something</label>
                                                    <textarea placeholder="Write Something..." className="form-control textarea-editor"></textarea>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label className="file-label">
                                                            <input className="file-input" name="resume" type="file" />
                                                            <span className="text-muted">
                                                                <i className="icon icon-attachment"></i> Attach File
                                            </span>
                                                        </label>
                                                    </div>
                                                    <div className="col-md-6 text-right">
                                                        <button type="submit" className="btn btn-default close-editor">Cancel</button>
                                                        <button type="submit" className="btn btn-primary close-editor">Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <hr />
                                        <div className="panel-body">
                                            <div className="message-body clearfix">
                                                <div className="content-group-sm">
                                                    <div className="media">
                                                        <span className="media-left">
                                                            <span className="btn bg-primary-400 btn-rounded btn-icon btn-xs">
                                                                <span className="letter-icon">M</span>
                                                            </span>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="media-heading text-semibold">Moving APT </span>
                                                            <div className="text-size-mini text-muted">
                                                                Sep 19, 2018, 3:40 PM
                                            </div>
                                                        </div>
                                                        <a href="#editor-box-in" className="media-right text-muted" title="Reply"><i className="icon icon-reply"></i></a>
                                                    </div>
                                                </div>
                                                <p>It is a long established fact that a reader will be distracted by
                                                    the readable content of a page when looking at its layout. The point
                                                    of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                                    as opposed to using 'Content here, content here', making it look like readable English.
                                                    Many desktop publishing packages and web page editors now use Lorem Ipsum as their default
                                                    model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.
                                                    Various versions have evolved over the years, sometimes by accident, sometimes on purpose
                                                    (injected humour and the like).
                                </p>
                                                <div className="tabbable attachedment-files">

                                                    <div className="file-manager">
                                                        <ul>
                                                            <li>
                                                                <div className="file-wrapper filetype-image">

                                                                    <div className="file-title"><a href=""><i className="icon-image5 position-left text-primary"></i> image-file-124883885.jpg</a></div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="file-wrapper filetype-zip">


                                                                    <div className="file-title"><a href=""><i className="icon-file-zip position-left text-slate"></i> zip-file-124883885.zip</a></div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="file-wrapper filetype-pdf">


                                                                    <div className="file-title"><a href=""><i className="icon-file-pdf position-left text-danger"></i> pdf-file-124883885.pdf</a></div>
                                                                </div>
                                                            </li>

                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>
                                            <hr />
                                            <div className="message-body clearfix">
                                                <div className="content-group-sm">
                                                    <div className="media">
                                                        <span className="media-left">
                                                            <span className="btn bg-danger-400 btn-rounded btn-icon btn-xs">
                                                                <span className="letter-icon">V</span>
                                                            </span>
                                                        </span>
                                                        <div className="media-body">
                                                            <span className="media-heading text-semibold">Victoria </span>
                                                            <div className="text-size-mini text-muted">
                                                                Sep 20, 2018, 12:02 PM
                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p>It is a long established fact that a reader will be distracted by
                                                    the readable content of a page when looking at its layout. The point
                                                    of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                                    as opposed to using 'Content here, content here', making it look like readable English.
                                                    Many desktop publishing packages and web page editors now use Lorem Ipsum as their default
                                                    model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.
                                                    Various versions have evolved over the years, sometimes by accident, sometimes on purpose
                                                    (injected humour and the like).
                                </p>
                                                <div className="tabbable attachedment-files">
                                                    <div className="file-manager">
                                                        <ul>
                                                            <li>
                                                                <div className="file-wrapper filetype-image">

                                                                    <div className="file-title"><a href=""><i className="icon-image5 position-left text-primary"></i> image-file-124883885.jpg</a></div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="file-wrapper filetype-zip">


                                                                    <div className="file-title"><a href=""><i className="icon-file-zip position-left text-slate"></i> zip-file-124883885.zip</a></div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="tab-pane" id="tab-drivers-helpers">
                                    <div className="panel panel-flat m-b-40 m-t-40">
                                        <div className="panel-heading p-0">
                                            <ul className="nav nav-tabs nav-tabs-bottom">
                                                <li className="active">
                                                    <a href="#bordered-tab1" data-toggle="tab" aria-expanded="true">
                                                        <h5 className="text-uppercase m-0 m-l-5 m-r-5"><b>DRIVER's</b></h5>
                                                        <p className="m-b-0 m-l-5 m-r-5 text-muted"><b>2</b> drivers assigned</p>
                                                    </a>
                                                </li>
                                                <li className="">
                                                    <a href="#bordered-tab2" data-toggle="tab" aria-expanded="false">
                                                        <h5 className="text-uppercase m-0 m-l-5 m-r-5">HELPER's</h5>
                                                        <p className="m-b-0 m-l-5 m-r-5 text-muted"><b>10</b> helpers assigned</p>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="panel-body">

                                            <div className="tabbable">

                                                <div className="tab-content">
                                                    <div className="tab-pane active" id="bordered-tab1">

                                                        <div className="well p-0 m-b-20">
                                                            <div className="p-15">
                                                                <div className="row">
                                                                    <div className="col-md-3">
                                                                        <label className="m-b-0">Driver's Name</label>
                                                                        <p className="m-b-0"><b>James Johnson</b></p>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <label className="m-b-0">Phone</label>
                                                                        <p className="m-b-0"><b>1234657890</b></p>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <label className="m-b-0">Driver's License #</label>
                                                                        <p className="m-b-0"><b>DL4578689</b></p>
                                                                    </div>
                                                                    <div className="col-md-3 text-right">
                                                                        <a href="" className="btn btn-success btn-xs">Track</a>
                                                                        <a href="" className="btn btn-default btn-xs"><i className="icon-cross2"></i> Remove</a>
                                                                        <a className="btn btn-default btn-xs" data-toggle="collapse" href="#driver_files_1" aria-expanded="false"><i className="icon-arrow-down12"></i></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="driver_files_1" className="collapse">
                                                                <hr className="m-0" />
                                                                <div className="p-15">
                                                                    <div className="clearfix">
                                                                        <div className="pull-right"><a href="" className="btn btn-xs btn-primary"><i className="icon-plus22"></i> Add New File</a></div>
                                                                        <h6 className="text-primary m-t-0">Origin Base Access</h6>
                                                                    </div>
                                                                    <table className="table table-striped table-condensed table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Requested Date</th>
                                                                                <th>Related File</th>
                                                                                <th>Current Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Nov 28, 2018</td>
                                                                                <td><a href="">access_file_name.jpg</a></td>
                                                                                <td className="text-warning">Pending</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="clearfix m-t-15">
                                                                        <div className="pull-right"><a href="" className="btn btn-xs btn-primary"><i className="icon-plus22"></i> Add New File</a></div>
                                                                        <h6 className="text-primary">Destination Base Access</h6>
                                                                    </div>
                                                                    <table className="table table-striped table-condensed table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Requested Date</th>
                                                                                <th>Related File</th>
                                                                                <th>Current Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Nov 28, 2018</td>
                                                                                <td><a href="">access_file_name.jpg</a></td>
                                                                                <td className="text-warning">Pending</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="well p-0 m-b-20">
                                                            <div className="p-15">
                                                                <div className="row">
                                                                    <div className="col-md-3">
                                                                        <label className="m-b-0">Driver's Name</label>
                                                                        <p className="m-b-0"><b>James Johnson</b></p>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <label className="m-b-0">Phone</label>
                                                                        <p className="m-b-0"><b>1234657890</b></p>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <label className="m-b-0">Driver's License #</label>
                                                                        <p className="m-b-0"><b>DL4578689</b></p>
                                                                    </div>
                                                                    <div className="col-md-3 text-right">
                                                                        <a href="" className="btn btn-success btn-xs">Track</a>
                                                                        <a href="" className="btn btn-default btn-xs"><i className="icon-cross2"></i> Remove</a>
                                                                        <a className="btn btn-default btn-xs" data-toggle="collapse" href="#driver_files_2" aria-expanded="false"><i className="icon-arrow-down12"></i></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="driver_files_2" className="collapse">
                                                                <hr className="m-0" />
                                                                <div className="p-15">
                                                                    <div className="clearfix">
                                                                        <div className="pull-right"><a href="" className="btn btn-xs btn-primary"><i className="icon-plus22"></i> Add New File</a></div>
                                                                        <h6 className="text-primary m-t-0">Origin Base Access</h6>
                                                                    </div>
                                                                    <table className="table table-striped table-condensed table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Requested Date</th>
                                                                                <th>Related File</th>
                                                                                <th>Current Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Nov 28, 2018</td>
                                                                                <td><a href="">access_file_name.jpg</a></td>
                                                                                <td className="text-warning">Pending</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="clearfix m-t-15">
                                                                        <div className="pull-right"><a href="" className="btn btn-xs btn-primary"><i className="icon-plus22"></i> Add New File</a></div>
                                                                        <h6 className="text-primary">Destination Base Access</h6>
                                                                    </div>
                                                                    <table className="table table-striped table-condensed table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Requested Date</th>
                                                                                <th>Related File</th>
                                                                                <th>Current Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Nov 28, 2018</td>
                                                                                <td><a href="">access_file_name.jpg</a></td>
                                                                                <td className="text-warning">Pending</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="text-center p-15">
                                                            <a href="" className="btn border-primary text-primary btn-flat text-uppercase">Add More Driver</a>
                                                        </div>

                                                    </div>
                                                    <div className="tab-pane" id="bordered-tab2">

                                                        <div className="well p-0 m-b-20">
                                                            <div className="p-15">
                                                                <div className="row">
                                                                    <div className="col-md-3">
                                                                        <label className="m-b-0">Helper's Name</label>
                                                                        <p className="m-b-0"><b>James Johnson</b></p>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <label className="m-b-0">Phone</label>
                                                                        <p className="m-b-0"><b>1234657890</b></p>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <label className="m-b-0">Driver's License #</label>
                                                                        <p className="m-b-0"><b>DL4578689</b></p>
                                                                    </div>
                                                                    <div className="col-md-3 text-right">
                                                                        <a href="" className="btn btn-success btn-xs">Track</a>
                                                                        <a href="" className="btn btn-default btn-xs"><i className="icon-cross2"></i> Remove</a>
                                                                        <a className="btn btn-default btn-xs" data-toggle="collapse" href="#driver_files_3" aria-expanded="false"><i className="icon-arrow-down12"></i></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="driver_files_3" className="collapse">
                                                                <hr className="m-0" />
                                                                <div className="p-15">
                                                                    <div className="clearfix">
                                                                        <div className="pull-right"><a href="" className="btn btn-xs btn-primary"><i className="icon-plus22"></i> Add New File</a></div>
                                                                        <h6 className="text-primary m-t-0">Origin Base Access</h6>
                                                                    </div>
                                                                    <table className="table table-striped table-condensed table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Requested Date</th>
                                                                                <th>Related File</th>
                                                                                <th>Current Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Nov 28, 2018</td>
                                                                                <td><a href="">access_file_name.jpg</a></td>
                                                                                <td className="text-warning">Pending</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="clearfix m-t-15">
                                                                        <div className="pull-right"><a href="" className="btn btn-xs btn-primary"><i className="icon-plus22"></i> Add New File</a></div>
                                                                        <h6 className="text-primary">Destination Base Access</h6>
                                                                    </div>
                                                                    <table className="table table-striped table-condensed table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Requested Date</th>
                                                                                <th>Related File</th>
                                                                                <th>Current Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Nov 28, 2018</td>
                                                                                <td><a href="">access_file_name.jpg</a></td>
                                                                                <td className="text-warning">Pending</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="well p-0 m-b-20">
                                                            <div className="p-15">
                                                                <div className="row">
                                                                    <div className="col-md-3">
                                                                        <label className="m-b-0">Helper's Name</label>
                                                                        <p className="m-b-0"><b>James Johnson</b></p>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <label className="m-b-0">Phone</label>
                                                                        <p className="m-b-0"><b>1234657890</b></p>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <label className="m-b-0">Driver's License #</label>
                                                                        <p className="m-b-0"><b>DL4578689</b></p>
                                                                    </div>
                                                                    <div className="col-md-3 text-right">
                                                                        <a href="" className="btn btn-success btn-xs">Track</a>
                                                                        <a href="" className="btn btn-default btn-xs"><i className="icon-cross2"></i> Remove</a>
                                                                        <a className="btn btn-default btn-xs" data-toggle="collapse" href="#driver_files_4" aria-expanded="false"><i className="icon-arrow-down12"></i></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id="driver_files_4" className="collapse">
                                                                <hr className="m-0" />
                                                                <div className="p-15">
                                                                    <div className="clearfix">
                                                                        <div className="pull-right"><a href="" className="btn btn-xs btn-primary"><i className="icon-plus22"></i> Add New File</a></div>
                                                                        <h6 className="text-primary m-t-0">Origin Base Access</h6>
                                                                    </div>
                                                                    <table className="table table-striped table-condensed table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Requested Date</th>
                                                                                <th>Related File</th>
                                                                                <th>Current Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Nov 28, 2018</td>
                                                                                <td><a href="">access_file_name.jpg</a></td>
                                                                                <td className="text-warning">Pending</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table><div className="clearfix m-t-15">
                                                                        <div className="pull-right"><a href="" className="btn btn-xs btn-primary"><i className="icon-plus22"></i> Add New File</a></div>
                                                                        <h6 className="text-primary">Destination Base Access</h6>
                                                                    </div>
                                                                    <table className="table table-striped table-condensed table-bordered">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Requested Date</th>
                                                                                <th>Related File</th>
                                                                                <th>Current Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Nov 28, 2018</td>
                                                                                <td><a href="">access_file_name.jpg</a></td>
                                                                                <td className="text-warning">Pending</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="text-center p-15">
                                                            <a href="" className="btn border-primary text-primary btn-flat text-uppercase">Add More Helper</a>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane" id="tab-tracking">
                                    <div className="panel panel-flat">
                                        <div className="panel-body p-0">
                                            <div className="tracking-timeline">
                                                <ul>
                                                    <li data-date="Jan 26, 2018" data-time="6:28 PM">
                                                        Awaiting Pack/ Pickup of Shipment
                                        </li>
                                                    <li data-date="Jan 26, 2018" data-time="6:28 PM">
                                                        Packing<br />
                                                        <a href="#" className="btn btn-success btn-xs btn-labeled"><b><i className="icon-check2"></i></b>Packing Done</a>
                                                    </li>
                                                    <li data-date="Jan 26, 2018" data-time="6:28 PM">
                                                        Pickup from:<br />
                                                        <small className="text-muted"> 1118 Oak Street, Syracuse, NY, New York, 13224 </small><br />
                                                        <a href="#" className="btn btn-success btn-xs btn-labeled"><b><i className="icon-check2"></i></b>Pickup Done</a>
                                                    </li>
                                                    <li data-date="Jan 26, 2018" data-time="6:28 PM">
                                                        Relocation Start<br />
                                                        <a href="#" className="btn btn-success btn-xs btn-labeled"><b><i className="icon-check2"></i></b>Relocation Started</a>
                                                    </li>
                                                    <li data-date="Jan 26, 2018" data-time="6:28 PM">
                                                        Storage<br />
                                                        <p className="text-muted"><small>Do you want restore at intermediate storage?</small></p>
                                                        <a href="#" className="btn btn-success btn-xs btn-labeled"><b><i className="icon-check2"></i></b>Yes</a>
                                                        <a href="#" className="btn btn-danger btn-xs btn-labeled" data-toggle="modal" data-target="#write-comments"><b><i className="icon-cross3"></i></b>No</a>
                                                    </li>
                                                    <li data-date="Jan 26, 2018" data-time="6:28 PM">
                                                        Goods verification<br />
                                                        <a href="#" className="btn btn-success btn-xs btn-labeled"><b><i className="icon-check2"></i></b>Goods Verified Done</a>
                                                    </li>
                                                    <li data-date="Jan 26, 2018" data-time="6:28 PM">
                                                        Pickup from storage<br />
                                                        <a href="#" className="btn btn-success btn-xs btn-labeled"><b><i className="icon-check2"></i></b>Pickup Started From Storage</a>
                                                    </li>
                                                    <li data-date="Jan 26, 2018" data-time="6:28 PM">
                                                        Reached Destination<br />
                                                        <a href="#" className="btn btn-success btn-xs btn-labeled"><b><i className="icon-check2"></i></b>Relocation Done</a>
                                                    </li>
                                                    <li data-date="Jan 26, 2018" data-time="6:28 PM">
                                                        Complete<br />
                                                        <a href="#" className="btn btn-success btn-xs btn-labeled"><b><i className="icon-check2"></i></b>Relocation Completed</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="tracking-order" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Order Tracking</h4>
                            </div>
                            <div className="modal-body alpha-grey p-b-0 p-t-0 m-t-15">
                                <div className="tracking-timeline">
                                    <ul>
                                        <li data-date="Jan 24, 2018" data-time="6:28 PM">Origin pre-approval approved.</li>
                                        <li data-date="Jan 24, 2018" data-time="6:28 PM">Packing done. Ready to ship.</li>
                                        <li data-date="Jan 24, 2018" data-time="6:28 PM"><span className="text-muted">Relocation started from</span><br />1118 Oak Street, Syracuse, NY, New York, 13224.</li>
                                        <li data-date="Jan 24, 2018" data-time="6:28 PM">Goods verified.</li>
                                        <li data-date="Jan 24, 2018" data-time="6:28 PM">Destination pre-approval approved.</li>
                                        <li data-date="Jan 24, 2018" data-time="6:28 PM"><span className="text-muted">Relocation Ended. Your items reached the destination city.</span><br />34 Lee Road, Syracuse, NY, New York, 13224</li>
                                        <li data-date="Jan 24, 2018" data-time="6:28 PM">Relocation completed.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="modal-footer p-t-15">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="view-comments" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Note</h4>
                            </div>
                            <div className="modal-body">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Ut rhoncus rhoncus risus, non accumsan ante tempor nec.
                                    Sed eget justo sed ex pulvinar feugiat. Quisque eget nulla
                                    posuere, semper dui et, consequat nunc. Fusce tincidunt lorem
                                    orci, vitae fringilla ipsum luctus vitae. Proin nec metus felis.
                                    In eu risus rutrum risus dapibus sollicitudin. Vestibulum ante ipsum
                                    primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nunc
                                    pulvinar nulla vitae est dapibus egestas. Curabitur et laoreet ligula.
                                    Vivamus mollis lacus vitae fringilla posuere. </p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="change-driver" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Change Driver</h4>
                            </div>
                            <div className="modal-body ">
                                <table className="table datatable-basic table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{whiteSpace: 'nowrap'}}>Name</th>
                                            <th>Mobile</th>
                                            <th>Email Address</th>
                                            <th>DL Exp. Date</th>
                                            <th>Medical Exam Exp.</th>
                                            <th>Type</th>
                                            <th>Vehicle No</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr id="#001">
                                            <td className="text-nowrap"><a href="">Mr. Cadice Brown</a></td>
                                            <td>1234657980</td>
                                            <td>email@example.com</td>
                                            <td>Nov 10, 2022</td>
                                            <td>Mar 30, 2019</td>
                                            <td>Local Contractor</td>
                                            <td>WO-657-MI</td>
                                        </tr>
                                        <tr id="#002">
                                            <td className="text-nowrap"><a href="">Mr. Jackelyn Weible</a></td>
                                            <td>454657980</td>
                                            <td>email@example.com</td>
                                            <td>Nov 10, 2022</td>
                                            <td>Mar 30, 2019</td>
                                            <td>Local Contractor</td>
                                            <td>WO-657-MI</td>
                                        </tr>
                                        <tr id="#14">
                                            <td className="text-nowrap"><a href="">Mr. Cadice Brown</a></td>
                                            <td>1234657980</td>
                                            <td>email@example.com</td>
                                            <td>Nov 10, 2022</td>
                                            <td>Mar 30, 2019</td>
                                            <td>Local Contractor</td>
                                            <td>WO-657-MI</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer p-t-15">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="change-helper" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Change Helper</h4>
                            </div>
                            <div className="modal-body ">
                                <table className="table datatable-basic table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{whiteSpace: 'nowrap'}}>Name</th>
                                            <th>Mobile</th>
                                            <th>Email Address</th>
                                            <th>DL Exp. Date</th>
                                            <th>Medical Exam Exp.</th>
                                            <th>Type</th>
                                            <th>Vehicle No</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr id="#001">
                                            <td className="text-nowrap"><a href="">Mr. Cadice Brown</a></td>
                                            <td>1234657980</td>
                                            <td>email@example.com</td>
                                            <td>Nov 10, 2022</td>
                                            <td>Mar 30, 2019</td>
                                            <td>Local Contractor</td>
                                            <td>WO-657-MI</td>
                                        </tr>
                                        <tr id="#002">
                                            <td className="text-nowrap"><a href="">Mr. Jackelyn Weible</a></td>
                                            <td>454657980</td>
                                            <td>email@example.com</td>
                                            <td>Nov 10, 2022</td>
                                            <td>Mar 30, 2019</td>
                                            <td>Local Contractor</td>
                                            <td>WO-657-MI</td>
                                        </tr>
                                        <tr id="#13">
                                            <td className="text-nowrap"><a href="">Mr. Cadice Brown</a></td>
                                            <td>1234657980</td>
                                            <td>email@example.com</td>
                                            <td>Nov 10, 2022</td>
                                            <td>Mar 30, 2019</td>
                                            <td>Local Contractor</td>
                                            <td>WO-657-MI</td>
                                        </tr>
                                        <tr id="#14">
                                            <td className="text-nowrap"><a href="">Mr. Cadice Brown</a></td>
                                            <td>1234657980</td>
                                            <td>email@example.com</td>
                                            <td>Nov 10, 2022</td>
                                            <td>Mar 30, 2019</td>
                                            <td>Local Contractor</td>
                                            <td>WO-657-MI</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer p-t-15">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UnassignedOfferDetails;