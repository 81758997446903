import React, { Component } from 'react';
import { Redirect,Link } from 'react-router-dom';
import GetData from '../services/GetData';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import PostDataWithParam from '../services/PostDataWithParam';
import ConfirmBox from '../externaljs/ConfirmBox';
import Breadcumb from '../externaljs/Breadcrumb';

class OtherStaff extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Staff', link: '', class: 'active' }
        ],
        staffErr: '',
        lists: [],
        addStaffStatus: false,
        loading:false
    }

    componentDidMount() {
        this.AllStaffs();
    }

    AllStaffs = () => {
        this.setState({loading: true}, () => {
            GetData('AllStaffs')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({loading: false}, () => {
                        this.setState({ lists: response.data }, () => {
                            DataTableBasic();
                        });
                    })
                } else {
                    this.setState({ lists: [], loading: false })
                }
            })
            .catch(() => {
                this.setState({ lists: [], loading: false })
            })
        })
    }

    removeDataTable = () => {
        if ( window.$.fn.DataTable.isDataTable('#list-table') ) {
            window.$('#list-table').DataTable().destroy();
        }
          
        window.$('#list-table tbody').empty();
    }

    handleStatus = (status) => {
        if (status == 0) {
            return (
                <td>
                    Inactive
                </td>
            )
        } else {
            return (
                <td>
                    Active
                </td>
            )

        }
    }

    handleBtn = (status) => {
        if (status == 0) {
            return (
                'Activate'
            )
        } else {
            return (
                'Deactivate'
            )

        }
    }


    ActiveDeactiveStaff = (staff_id, status) => {

        if (status == 1) {
            ConfirmBox("You want to deactivate this staff!")
                .then((result) => {
                    if (result) {
                        PostDataWithParam('ActiveDeactiveStaff', { staff_id: staff_id, status: 0 })
                            .then((response) => {
                                let result = response;
                                if (result.success === 1) {
                                    this.removeDataTable();
                                    this.AllStaffs();                                    
                                    window.swal('Deactivated!', result.msg, 'success')
                                } else {
                                    window.swal('Deactivated!', result.msg, 'success')
                                }
                            });
                    }
                });
        }
        else {
            ConfirmBox("You want to activate this staff!")
                .then((result) => {
                    if (result) {
                        PostDataWithParam('ActiveDeactiveStaff', { staff_id: staff_id, status: 1 })
                            .then((response) => {
                                let result = response;
                                if (result.success === 1) {
                                    this.removeDataTable();
                                    this.AllStaffs();
                                    window.swal('Activated!', result.msg, 'success')
                                } else {
                                    window.swal('Activated!', result.msg, 'success')
                                }
                            });
                    }
                });
        }
    }

    DeleteStaff = (staff_id) => {
        ConfirmBox("You want to delete this staff!")
            .then((result) => {
                if (result) {
                    PostDataWithParam('DeleteStaff', { staff_id: staff_id })
                        .then((response) => {
                            let result = response;
                            if (result.success === 1) {
                                this.removeDataTable();
                                this.AllStaffs();
                                window.swal('Deleted!', result.msg, 'success')
                            } else {
                                window.swal('Deleted!', result.msg, 'success')
                            }
                        });
                }
            });
    }



    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="panel panel-flat">
                        <div className="panel-heading">
                            <h5 className="panel-title">Staff List</h5>
                            <div className="heading-elements">
                                <Link to="/independent-mover/dashboard/add-other-staff" className="btn btn-primary btn-xs m-r-15"><i className="icon-plus22"></i> Add Staff</Link>
                                
                            </div>
                        </div>
                        {
                            this.state.loading
                            &&
                            <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                        }
                        {
                            !this.state.loading 
                            && 
                            this.state.lists.length !== 0
                            &&
                            <table className="table datatable-basic table-striped" id="list-table">
                                <thead>
                                    <tr>
                                        <th style={{ whiteSpace: 'nowrap' }}>ID</th>
                                        <th style={{ whiteSpace: 'nowrap' }}>Full Name</th>
                                        <th>Mobile</th>
                                        <th>Email</th>
                                        <th>Profile</th>
                                        <th>Status</th>
                                        <th className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.lists.map(list =>
                                        <tr key={list.id}>
                                            <td>#00{list.id}</td>
                                            <td className="text-nowrap">
                                                <Link to={"/independent-mover/dashboard/view-other-staff/" + list.id}>{list.first_name} {list.last_name}</Link>
                                            </td>
                                            <td>{list.contact_no}</td>
                                            <td>{list.email}</td>
                                            <td>{list.title}</td>
                                            {this.handleStatus(list.status)}
                                            <td className="text-center">
                                                <ul className="icons-list">
                                                    <li className="dropdown">
                                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                            <i className="icon-menu9"></i>
                                                        </a>
                                                        <ul className="dropdown-menu dropdown-menu-right">
                                                            <li><Link to={"/independent-mover/dashboard/edit-other-staff/" + list.id}><i className="icon-pencil"></i> Edit Staff</Link></li>
                                                            <li><a href="javascript:void(0)" onClick={() => this.DeleteStaff(list.id)}><i className="icon-cross2"></i> Delete Staff</a></li>
                                                            <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.ActiveDeactiveStaff(list.id, list.status)}><i className="icon-user-block"></i> {this.handleBtn(list.status)} Staff</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }
                        {
                            !this.state.loading 
                            && 
                            this.state.lists.length === 0
                            &&
                            <div className="alert alert-primary border-none text-center">No Staff Found.</div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default OtherStaff;