import React, { Component } from 'react';
import PostDataWithParam from '../services/PostDataWithParam';
import PostDataFile from '../services/PostDataFile';
import GetData from '../services/GetData';
import ConfirmBox from '../externaljs/ConfirmBox';
import { FileType, FileSvg, FileIcon } from '../externaljs/FileType';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Storage from '../externaljs/Storage';

class ScacTab extends Component {

    state = {
        fields: {
            logo: '',
            organization_name: '',
            doing_business_as_dba: '',
            year_founded: '',
            website_link: '',
            main_office_phone_no: '',
            company_fax_no: '',
            operation_email: '',
            sales_email: '',
            customer_service_email: '',
            driver_and_team_recruitment: '',
            claim_email: '',
            after_hours_staff_name: '',
            after_hours_contact_phone: '',
            radius_of_quality_service: '',
            management_contact_name: '',
            management_title: '',
            company_email: '',
            management_phone_no: '',
            military_approved_warehouse: '',
            certified_truck_scale_on_premises: '',
            warehouse: '',
            warehouse_size: '',
            climatized: '',
            climatized_size: '',
            air_conditioned: '',
            air_conditioned_size: '',
            pre_move_survey: '',
            packing: '',
            crating: '',
            short_hauling_hhg: '',
            long_hauling_hhg: '',
            freight_hauling: '',
            storage: '',
            intermodal: '',
            day_road_labor: '',
            Military_Approved: '',
            International_Origin_Service: '',
            International_Destination_Service: '',

            last_mile_delivery: '',
            freight_warehouse: '',
            third_party_services: '',
            secured_parking_lot: '',
            tractors: '',
            trailers: '',
            straight_trucks: '',
            pack_vans: '',
            cdl_drivers: '',
            non_cdl_drivers: '',
            packers: '',
            loaders: '',
            primary_street_address: '',
            primary_address_other_info: '',
            secondary_street_address: '',
            secondary_address_other_info: '',
            primary_zip_code: '',
            secondary_zip_code: '',
            services_provided: [],
            primary_country: '',
            secondary_country: '',
            MailphysicalValue: 0,
            service_range: '',
            radius_serviced: 0
        },
        error: {
            DOT_number_err: '',
            about_us_err: '',
            company_MC_err: '',
            company_contact_no_err: '',
            company_email_err: '',
            company_fax_no_err: '',
            core_values_err: '',
            facebook_link_err: '',
            linkedIn_link_err: '',
            profile_image_err: '',
            organization_name_err: '',
            our_mission_err: '',
            state_license_number_err: '',
            twitter_link_err: '',
            website_link_err: '',
            year_founded_err: '',
            primary_address_other_info_err: '',
            secondary_address_other_info_err: ''

        },
        proccesing: false,
        changeUpdateStatus: false,
        UpdateErr: '',
        selectedFile: null,
        selectedFileAll: [],
        allStates: [],
        primaryCities: [],
        secondaryCities: [],
        company_files: [],
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Company Profile', link: '/independent-mover/dashboard/company-profile', class: '' },
            { title: 'Edit Company Profile', link: '', class: 'active' }
        ],
        completion_percentage: 0,
        services_provided_list: [],
        src: null,
        crop: {
            // aspect: 16 / 9,
            // height: 200,
            // width: 200,
            // x: 0,
            // y: 0
            unit: '%',
            width: 50,
            height: 50,
            x: 0,
            y: 0
        },
        croppedImageUrl: '',
        loading: false,
        uploadProccesing: false
    }

    componentDidMount() {

        this.setState({ loading: true }, () => {
            this.GetCompanyInfo();
            this.getCompletionStatus();
            this.GetAllCompanyFile();
        })

    }

    primaryCountry() {
        if (window.$('.primary-country').length > 0) {
            window.$('.primary-country').select2({
                containerCssClass: 'select-xs'
            }).off('select2:select').on('select2:select', (e) => {
                let fields = Object.assign({}, this.state.fields);
                fields['primary_country'] = e.params.data.id;
                this.setState({ fields: fields });
            }).off('select2:unselect').on('select2:unselect', (e) => {

            });

        }
    }

    secondaryCountry() {
        if (window.$('.secondary-country').length > 0) {
            window.$('.secondary-country').select2({
                containerCssClass: 'select-xs'
            }).off('select2:select').on('select2:select', (e) => {
                let fields = Object.assign({}, this.state.fields);
                fields['secondary_country'] = e.params.data.id;
                this.setState({ fields: fields });
            }).off('select2:unselect').on('select2:unselect', (e) => {

            });

        }
    }

    primaryCity() {
        if (window.$('.primary-city').length > 0) {
            window.$('.primary-city').select2({
                containerCssClass: 'select-xs'
            }).off('select2:select').on('select2:select', (e) => {
                let fields = Object.assign({}, this.state.fields);
                fields['primary_city'] = e.params.data.id;
                this.setState({ fields: fields });
            }).off('select2:unselect').on('select2:unselect', (e) => {

            });

        }
    }

    secondaryCity() {
        if (window.$('.secondary-city').length > 0) {
            window.$('.secondary-city').select2({
                containerCssClass: 'select-xs'
            }).off('select2:select').on('select2:select', (e) => {
                let fields = Object.assign({}, this.state.fields);
                fields['secondary_city'] = e.params.data.id;
                this.setState({ fields: fields });
            }).off('select2:unselect').on('select2:unselect', (e) => {

            });

        }
    }

    GetCompanyInfo = () => {

        PostDataWithParam('GetCompanyInfo', {})
            .then((response) => {
                if (response.success === 1) {
                    Storage.set('imnFirstLogin', JSON.stringify({ organization_name: response.data[0].organization_name, logo: response.data[0].logo }))
                    let services_provided = [];
                    if (response.data[0].services_provided) {
                        let services_provided_arr = JSON.parse(response.data[0].services_provided);
                        services_provided = services_provided_arr.map((item, i) => {
                            return item.name;
                        })
                    }

                    this.setState({ fields: response.data[0], services_provided_list: services_provided }, () => {

                    });
                } else {
                    this.setState({ fields: [], services_provided_list: [] })
                }
            })
            .catch(err => {
                this.setState({ fields: [], services_provided_list: [] })
            })
    }

    UpdateRadius = () => {
        PostDataWithParam('UpdateHelperRadiusService', { 'radius_serviced': this.state.setredius })
            .then((response) => {
                if (response.success === 1) {
                    this.GetCompanyInfo();
                }
            })
    }

    getStates() {
        PostDataWithParam('GetStates', {})
            .then((response) => {
                let result = response;
                if (result.success === 1) {
                    this.setState({ allStates: result.data }, () => {
                        this.GetCompanyInfo();
                    });
                } else {
                    this.setState({ UpdateErr: result.msg });
                }
            })
            .catch(err => {

            })
    }

    getCities(state = 'Alaska', flag = '') {
        var state_code = 'AK';
        for (var st of this.state.allStates) {
            if (st.state.trim() == state.trim()) {
                state_code = st.state_code;
                break;
            }
        }

        PostDataWithParam('GetCities', { state_code: state_code })
            .then((response) => {
                let result = response;
                if (result.success === 1) {
                    if (flag == '') {
                        this.setState({ primaryCities: result.data, secondaryCities: result.data }, () => {
                            this.primaryCity();
                            this.secondaryCity();
                        });
                    } else if (flag == 'primary') {
                        this.setState({ primaryCities: result.data }, () => {
                            this.primaryCity();
                        });
                    } else if (flag == 'secondary') {
                        this.setState({ secondaryCities: result.data }, () => {
                            this.secondaryCity()
                        });
                    }

                } else {
                    this.setState({ UpdateErr: result.msg });
                }
            })
            .catch(err => {

            })
    }

    primaryState() {
        if (window.$('.primary-state').length > 0) {
            window.$('.primary-state').select2({
                containerCssClass: 'select-xs'
            }).off('select2:select').on('select2:select', (e) => {
                let fields = Object.assign({}, this.state.fields);
                fields['primary_state'] = e.params.data.id;
                this.setState({ fields: fields });
                this.getCities(e.params.data.id, 'primary');
            }).off('select2:unselect').on('select2:unselect', (e) => {
                this.getCities(e.params.data.id, 'primary');
            });

        }
    }

    secondaryState() {
        if (window.$('.secondary-state').length > 0) {
            window.$('.secondary-state').select2({
                containerCssClass: 'select-xs'
            }).off('select2:select').on('select2:select', (e) => {
                let fields = Object.assign({}, this.state.fields);
                fields['secondary_state'] = e.params.data.id;
                this.setState({ fields: fields });
                this.getCities(e.params.data.id, 'secondary');
            }).off('select2:unselect').on('select2:unselect', (e) => {
                this.getCities(e.params.data.id, 'secondary');
            });

        }
    }



    getCompletionStatus = () => {
        GetData('GetProfileCompleteStatus')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ completion_percentage: response.completion_percentage }, () => {

                    });
                }
            })
            .catch(err => {
                this.setState({ completion_percentage: 0 });
            })
    }

    GetAllCompanyFile = () => {
        GetData('GetCompanyDocument')
            .then((response) => {
                if (response.success == 1) {
                    this.setState({ company_files: response.data, loading: false }, () => {
                        this.renderRadiusGraph()
                    });
                } else {
                    this.setState({ company_files: [], loading: false }, () => {
                        this.renderRadiusGraph()
                    });
                }
            })
            .catch(err => {
                this.setState({ company_files: [], loading: false }, () => {
                    this.renderRadiusGraph()
                })
            })
    }

    renderRadiusGraph() {

        window.$("#demo").ionRangeSlider({
            grid: true,
            min: 1,
            max: 200,
            from: this.state.fields.radius_serviced,
            step: 20,
            skin: "round",
            max_postfix: "+",
            prefix: "Miles ",
            onChange: (data) => {
                this.setState({ setredius: data.from }, () => {
                    this.UpdateRadius();
                });
            },
        });
    }

    deleteFile = (id) => {
        ConfirmBox("You want to delete this file!")
            .then((result) => {
                if (result) {
                    PostDataWithParam('DeleteCompanyDocument', { document_id: id })
                        .then((response) => {
                            if (response.success == 1) {
                                window.swal('Deleted!', response.msg, 'success')
                                this.GetAllCompanyFile()
                            }
                        })
                }
            })
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        let field_name = e.target.name;
        let value = e.target.value;

        let checkboxes = [
            'pre_move_survey', 'packing', 'crating', 'Loading',
            'Debris_Removal',
            'storage', 'Appliance_Service', 'Disassembly_Setup', 'Clock_Service',
            'International_Origin_Service',
            'International_Destination_Service', 'maid_service', 'Transport', 'third_party_services'
        ];

        if (checkboxes.indexOf(field_name) > -1) {
            if (this.state.services_provided_list.indexOf(value) > -1) {
                let services_provided_list = this.state.services_provided_list.filter((item, i) => {
                    if (item == value)
                        return false;
                    else
                        return true;
                })
                this.setState({ services_provided_list: services_provided_list });
            } else {
                let services_provided_list = this.state.services_provided_list;
                services_provided_list.push(value);
                this.setState({ services_provided_list: services_provided_list });
            }
        }
        fields[e.target.name] = e.target.value;
        if (e.target.name == 'management_phone_no' || e.target.name == 'main_office_phone_no' || e.target.name == 'company_fax_no' || e.target.name == 'after_hours_contact_phone') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                fields[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
        }
        this.setState({ fields: fields });
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            window.$("#imageModal").modal('show')
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onImageLoaded = (image, crop) => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = crop => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            let dataURL = canvas.toDataURL("image/jpeg");
            resolve(dataURL);
        });
    }

    handleUpdateCompanyLogo = () => {
        if (this.state.croppedImageUrl != '') {
            this.setState({ proccesing: true }, () => {
                PostDataWithParam('UpdateCroppedCompanyLogoForMovers', { 'logo': this.state.croppedImageUrl })
                    .then((response) => {
                        if (response.success === 1) {
                            Storage.set('imnFirstLogin', JSON.stringify({ organization_name: this.state.fields.organization_name, logo: this.state.fields.logo }))
                            window.$("#imageModal").modal('hide')
                            window.swal('Success!', "Company logo updated successfully", 'success')
                            this.setState({
                                changeUpdateStatus: true, proccesing: false
                            });
                            this.GetCompanyInfo();
                        } else {
                            this.setState({ UpdateErr: response.msg, changeUpdateStatus: false, proccesing: false });
                        }
                    })
                    .catch((err) => {
                        this.setState({ UpdateErr: 'Something went wrong!', changeUpdateStatus: false, proccesing: false });
                    })
            })
        }
    }

    handleFileSelectAll = (e) => {
        let userfiles = []
        for (let i = 0; i < e.target.files.length; i++) {
            userfiles.push(e.target.files[i])
        }
        this.setState({ selectedFileAll: userfiles }, () => {
            if (this.state.selectedFileAll.length != 0) {
                let fd = new FormData();
                for (let i = 0; i < this.state.selectedFileAll.length; i++) {
                    fd.append('document[' + i + ']', this.state.selectedFileAll[i], this.state.selectedFileAll[i].name);
                }
                this.setState({ uploadProccesing: true }, () => {
                    PostDataFile('UpdateCompanyDocument', fd)
                        .then((response) => {
                            if (response.success === 1) {
                                window.swal('Success!', "Company document updated successfully", 'success')
                                this.setState({ changeUpdateStatus: true, uploadProccesing: false });
                                this.GetAllCompanyFile()
                            } else {
                                window.swal('Error!', "Company document not updated", 'error')
                                this.setState({ uploadProccesing: false });
                            }
                        })
                        .catch(err => {
                            window.swal('Error!', 'Something went wrong.', 'error')
                            this.setState({ uploadProccesing: false });
                        })
                });
            }
        });
    }

    resetError = (cb) => {
        let error = {
            DOT_number_err: '',
            about_us_err: '',
            company_MC_err: '',
            company_contact_no_err: '',
            company_email_err: '',
            company_fax_no_err: '',
            core_values_err: '',
            facebook_link_err: '',
            linkedIn_link_err: '',
            logo_err: '',
            organization_name_err: '',
            our_mission_err: '',
            state_license_number_err: '',
            twitter_link_err: '',
            website_link_err: '',
            year_founded_err: '',
            primary_address_other_info_err: '',
            secondary_address_other_info_err: ''
        }
        this.setState({ error: error }, () => {
            cb();
        });
    }

    handleUpdateCompanyProfile = () => {
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        this.resetError(() => {
            let error = this.state.error;
            let tempServiceProvided = [];

            if (this.state.services_provided_list.length > 0) {
                tempServiceProvided = this.state.services_provided_list.map((item, i) => {
                    return { name: item }
                })
            }

            let tempServiceFields = this.state.fields;
            tempServiceFields.services_provided = JSON.stringify(tempServiceProvided);
            this.setState({ fields: tempServiceFields });

            if (!this.state.fields.organization_name) {
                error.organization_name_err = 'Organization Name is required.';
            }
            else {
                this.setState({ proccesing: true }, () => {
                    PostDataWithParam('UpdateCompanyProfile', this.state.fields)
                        .then((response) => {
                            let result = response;
                            if (result.success === 1) {
                                window.swal('Success!', response.msg, 'success')
                                Storage.set('imnFirstLogin', JSON.stringify({ organization_name: this.state.fields.organization_name, logo: this.state.fields.logo }))
                                this.setState({ changeUpdateStatus: true, proccesing: false });
                                this.getCompletionStatus();
                            } else {
                                window.swal('Error!', response.msg, 'error')
                                this.setState({ proccesing: false });
                                this.getCompletionStatus();
                            }
                        })
                        .catch(err => {
                            window.swal('Error!', 'Something went wrong.', 'error')
                            this.setState({ proccesing: false });
                            this.getCompletionStatus();
                        })
                })
            }

            this.setState({ error: error });
        });
    }

    handleFileCheck = (file) => {
        if (file !== '') {
            return (
                <div className="thumb custom-logo" style={{ backgroundImage: "url(" + this.state.fields.logo + ")" }}>
                </div>
            )
        } else {
            return (
                <div className="thumb custom-logo" style={{ backgroundImage: "url('assets/images/icon-no-image.svg')" }}>
                </div>
            )
        }
    }

    initPhysicalAutocomplete = (e) => {
        let fields = this.state.fields;
        fields.primary_street_address = e.target.value;
        this.setState({ fields: fields });

        var componentForm = {
            country: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            postal_code: 'short_name'
        };

        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: [] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let fields = this.state.fields;

            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = place.address_components[i][componentForm[addressType]];
                    if (addressType == "administrative_area_level_1")
                        fields.primary_state = val
                    else if (addressType == "locality")
                        fields.primary_city = val
                    else if (addressType == "postal_code")
                        fields.primary_zip_code = val
                    else if (addressType == "country")
                        fields.primary_country = val
                }
            }
            let address = ''
            for (let i = 0; i < place.address_components.length; i++) {
                if (place.address_components[i].types[0] == "street_number" || place.address_components[i].types[0] == "route" || place.address_components[i].types[0] == "sublocality_level_1" || place.address_components[i].types[0] == "sublocality_level_2" || place.address_components[i].types[0] == "sublocality_level_3") {
                    address = address + " " + place.address_components[i].long_name
                }

            }
            if (address == '') {
                address = window.$('#primary_street_address')[0].value;
            }
            fields.primary_street_address = address
            this.setState({ fields: fields });
        })
    }

    setMailphysicalValue(e) {
        let fields = this.state.fields;
        if (window.$('input:checkbox[name=MailphysicalValue]').is(':checked')) {
            fields.MailphysicalValue = 1;
        }
        else {
            fields.MailphysicalValue = 0;
        }
        this.setState({ fields: fields });

    }

    handleServiceRange = (e) => {
        // alert(e.target.value);
        // let fields = Object.assign({}, this.state.fields);
        // fields[e.target.name] = e.target.value;
        // this.setState({ fields: fields })

        PostDataWithParam('UpdateHelperRadiusService', { 'service_range': e.target.value })
            .then((response) => {
                if (response.success === 1) {
                    this.GetCompanyInfo();
                }
            })
    }


    render() {
        return (
            <div>
                {
                    this.state.loading
                    &&
                    <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                }
                {
                    !this.state.loading
                    &&
                    <React.Fragment>
                        <div id="tab-profile" className="tab-pane active">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="text-success">SCAC Information</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>SCAC Name</label>
                                                <input className="form-control" name="scac_name" id="scac_name" value={this.state.fields.scac_name} type="text" readOnly={true} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>D-Quality Assurance Phone</label>
                                                <input className="form-control" name="d_quality_assurance_phone" id="d_quality_assurance_phone" onChange={this.changeValue} value={this.state.fields.d_quality_assurance_phone || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>D-Customer service Email</label>
                                                <input className="form-control" name="d_customer_service_email" id="d_customer_service_email" onChange={this.changeValue} value={this.state.fields.d_customer_service_email || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>D-Quality Assurance Email</label>
                                                <input className="form-control" name="d_quality_assurance_email" id="d_quality_assurance_email" onChange={this.changeValue} value={this.state.fields.d_quality_assurance_email || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>D-Claims Phone</label>
                                                <input className="form-control" name="d_claim_phone" id="d_claim_phone" onChange={this.changeValue} value={this.state.fields.d_claim_phone || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>D-Claims Email</label>
                                                <input className="form-control" name="d_claim_email" id="d_claim_email" onChange={this.changeValue} value={this.state.fields.d_claim_email || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>D-Storage In Transit (SIT) Phone </label>
                                                <input className="form-control" name="d_storage_in_transit_phone" id="d_storage_in_transit_phone" onChange={this.changeValue} value={this.state.fields.d_storage_in_transit_phone || ''} type="text" />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>D-Storage In Transit (SIT) Email</label>
                                                <input className="form-control" name="d_storage_in_transit_email" id="d_storage_in_transit_email" onChange={this.changeValue} value={this.state.fields.d_storage_in_transit_email || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>D-Non Temporary Storage (NTS) Phone</label>
                                                <input className="form-control" name="d_non_temporary_storage_phone" id="d_non_temporary_storage_phone" onChange={this.changeValue} value={this.state.fields.d_non_temporary_storage_phone || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>D-Non Temporary Storage (NTS) Email</label>
                                                <input className="form-control" name="d_non_temporary_storage_email" id="d_non_temporary_storage_email" onChange={this.changeValue} value={this.state.fields.d_non_temporary_storage_email || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>D-Clearing Phone </label>
                                                <input className="form-control" name="d_clearing_phone" id="d_clearing_phone" onChange={this.changeValue} value={this.state.fields.d_clearing_phone || ''} type="text" />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>D-Clearing Email</label>
                                                <input className="form-control" name="d_clearing_email" id="d_clearing_email" onChange={this.changeValue} value={this.state.fields.d_clearing_email || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>D-Billing Phone</label>
                                                <input className="form-control" name="d_billing_phone" id="d_billing_phone" onChange={this.changeValue} value={this.state.fields.d_billing_phone || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>D-Billing Email</label>
                                                <input className="form-control" name="d_billing_email" id="d_billing_email" onChange={this.changeValue} value={this.state.fields.d_billing_email || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>I-Customer Service Phone </label>
                                                <input className="form-control" name="i_customer_service_phone" id="i_customer_service_phone" onChange={this.changeValue} value={this.state.fields.i_customer_service_phone || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>I-Customer Service Email</label>
                                                <input className="form-control" name="i_customer_service_email" id="i_customer_service_email" onChange={this.changeValue} value={this.state.fields.i_customer_service_email || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>I-Quality Assurance Phone</label>
                                                <input className="form-control" name="i_quality_assurance_phone" id="i_quality_assurance_phone" onChange={this.changeValue} value={this.state.fields.i_quality_assurance_phone || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>I-Quality Assurance Email</label>
                                                <input className="form-control" name="i_quality_assurance_email" id="i_quality_assurance_email" onChange={this.changeValue} value={this.state.fields.i_quality_assurance_email || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>I-Claims Phone </label>
                                                <input className="form-control" name="i_claim_phone" id="i_claim_phone" onChange={this.changeValue} value={this.state.fields.i_claim_phone || ''} type="text" />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>I-Claims Email</label>
                                                <input className="form-control" name="i_claim_email" id="i_claim_email" onChange={this.changeValue} value={this.state.fields.i_claim_email || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>I-Storage In Transit (SIT) Phone</label>
                                                <input className="form-control" name="i_storage_in_transit_phone" id="i_storage_in_transit_phone" onChange={this.changeValue} value={this.state.fields.i_storage_in_transit_phone || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>I-Storage In Transit (SIT) Email</label>
                                                <input className="form-control" name="i_storage_in_transit_email" id="i_storage_in_transit_email" onChange={this.changeValue} value={this.state.fields.i_storage_in_transit_email || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>I-Non Temporary Storage (NTS) Phone</label>
                                                <input className="form-control" name="i_non_temporary_storage_phone" id="i_non_temporary_storage_phone" onChange={this.changeValue} value={this.state.fields.i_non_temporary_storage_phone || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>I-Non Temporary Storage (NTS) Email</label>
                                                <input className="form-control" name="i_non_temporary_storage_email" id="i_non_temporary_storage_email" onChange={this.changeValue} value={this.state.fields.i_non_temporary_storage_email || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>I-Clearing Phone</label>
                                                <input className="form-control" name="i_clearing_phone" id="i_clearing_phone" onChange={this.changeValue} value={this.state.fields.i_clearing_phone || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>I-Clearing Email</label>
                                                <input className="form-control" name="i_clearing_email" id="i_clearing_email" onChange={this.changeValue} value={this.state.fields.i_clearing_email || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>I-Billing Phone</label>
                                                <input className="form-control" name="i_billing_phone" id="i_billing_phone" onChange={this.changeValue} value={this.state.fields.i_billing_phone || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>I-Billing Email</label>
                                                <input className="form-control" name="i_billing_email" id="i_billing_email" onChange={this.changeValue} value={this.state.fields.i_billing_email || ''} type="text" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>

        );
    }
}

export default ScacTab;