import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataWithParam from '../services/PostDataWithParam';
import GetData from '../services/GetData';
import ConfirmBox from '../externaljs/ConfirmBox';
import Storage from '../externaljs/Storage';

const initial_form_data = {
    id: '',
    title: '',
    profile_id: 2,
    status: 1,
    menu: [],
    show_in_menu: 0
}

// const initial_field_data=  {
//     id:'',
//     associated_with_profile_id:'',
//     title:'',
//     profile_id:'',
//     status:'',
//     menu:[],
//     created_by:''
// }

class ManageProfile extends Component {

    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Department', link: '', class: 'active' }
        ],
        order_list: [],
        loading: false,
        selCustomFilter: '',
        order_type: '',
        //new added
        profile_menu: [],
        profile_menu_err: '',
        menu: [],
        form_data: {
            ...initial_form_data
        },

        // field_data : {
        //     ...initial_field_data
        // },

        tmp_checked_menu: [],
        changeUpdateStatus: false,
        UpdateErr: '',
        custom_profile_id: '',
        custom_menu: []

    }

    componentDidMount() {
        this.GetProfileData();
        let custom_profile_id = (JSON.parse(Storage.get('imnLoginData'))).custom_profile_id;
        // let custom_menu = (JSON.parse(Storage.get('imnLoginData'))).menu;
        this.setState({ custom_profile_id: custom_profile_id }, () => {
            // console.log(this.state.custom_menu);
        });

    }

    getUserInfo = (id) => {

        PostDataWithParam('ViewCustomProfileForGMA', { id })
            .then((response) => {
                if (response.success === 1) {

                    // console.log(response.data[0])
                    // let response_data = response.data;
                    let { id, title, status, associated_with_profile_id: profile_id, show_in_menu } = response.data[0];
                    if (profile_id != '')
                        this.getProfileMenuList(profile_id);

                    let menu = [];

                    if (response.data[0].menu) {
                        let menu_arr = (response.data[0].menu);
                        menu = menu_arr.map((item, i) => {
                            return item.menu_id;
                        })
                    }

                    this.setState({ form_data: { id, title, status, profile_id, menu, show_in_menu }, menu: response.data[0].menu }, () => {
                        // console.log(this.state.form_data)   
                    });
                }
                else {
                    console.log('Error')
                }

            })
            .catch(err => {
                this.setState({ changeUpdateStatus: false });
            })
    }

    GetProfileData = (sortBy) => {
        this.setState({ loading: true }, () => {
            let postData = {};
            if (sortBy) {
                postData = { 'profile_id': sortBy };
            }
            var that = this;
            PostDataWithParam('ViewCustomProfileForGMA', postData)
                .then((response) => {
                    if (response.success == 1) {
                        this.setState({ order_list: response.data, loading: false, selCustomFilter: sortBy }, () => {
                            // DataTableBasic();
                            // console.log(order_list)
                            DataTableBasic(function () {
                                var searchFilter = window.$('.dataTables_filter input[type=search]');

                                if (searchFilter.length) {
                                    var newOptions = [
                                        // { 'key': '', value: 'All' },
                                        // { 'key': '10', value: 'MyPCSGov' },
                                        { 'key': '2', value: 'GetMilitaryApproved' },
                                    ];
                                    var sel = '<select class="customFilterBy">';
                                    window.$(newOptions).each(function (key, txt) {
                                        if (that.state.selCustomFilter == txt.key) {
                                            sel += '<option value="' + txt.key + '" selected>' + txt.value + '</option>';
                                        } else {
                                            sel += '<option value="' + txt.key + '">' + txt.value + '</option>';
                                        }

                                    });
                                    sel += '</select>';
                                    searchFilter.parent().parent().append(sel);
                                    window.$(".customFilterBy").select2({
                                        minimumResultsForSearch: Infinity,
                                        width: 'auto'
                                    }).on('select2:select', function (e) {
                                        that.removeDataTable();
                                        that.GetProfileData(e.params.data.id);
                                    });
                                }
                            });
                        });

                    } else {
                        this.setState({ order_list: [], loading: false, selCustomFilter: sortBy });
                        // window.swal('Sorry!', 'No Profiles Found', 'info');
                    }
                })
                .catch((err) => {
                    this.setState({ order_list: [], loading: false, selCustomFilter: sortBy });
                    window.swal('Error!', 'Something went wrong', 'error');
                })
        })
    }

    removeDataTable = () => {
        if (window.$.fn.DataTable.isDataTable('#list-table')) {
            window.$('#list-table').DataTable().destroy();
        }

        window.$('#list-table tbody').empty();
    }


    onItemClick = (e) => {
        this.setState({ form_data: { ...initial_form_data, menu: [] } })
        window.$('#select_all').prop("checked", false);
        window.$('#manageProfile').modal({
            backdrop: 'static',
            keyboard: false
        });
        this.getProfileMenuList(2);
        window.$("#manageProfile").modal('show');

    }

    handleOnChange = (e) => {
        let form_data = Object.assign({}, this.state.form_data)
        // console.log(form_data);
        if (e.target.name == "show_in_menu") {

            if (form_data.show_in_menu == 0 || form_data.show_in_menu == "0") {
                form_data[e.target.name] = 1;

            }

            else {
                form_data[e.target.name] = 0;

            }
        }

        else {
            form_data[e.target.name] = e.target.value
        }
        this.setState({ form_data: form_data });
    }

    getProfileMenuList = (profile_id) => {

        let form_data = { ...this.state.form_data };
        // let profile_id = e.target.value;
        // form_data.profile_id = profile_id;
        // this.setState({form_data:form_data})
        // this.setState({form_data})
        PostDataWithParam('GetProfileWiseMenuList', { profile_id: form_data.profile_id })
            .then((response) => {
                let result = response;
                if (result.success === 1) {
                    this.setState({ profile_menu: result.data });

                } else {
                    this.setState({ profile_menu_err: result.msg });
                }

            })
            .catch(err => {

            })
    }

    renderMenuList = () => {

        return <div className="form-group">
            <label>Select Features</label>
            <ul className="checkbox-list m-b-0 p-l-0">
                <li className="list-unstyled"><div><label className="checkbox-inline text-primary">
                    <input type="checkbox" name="menu" id="select_all" value="select_all" onChange={this.selectMenu} /><strong>Select All</strong></label></div>
                </li>
                {this.state.profile_menu.map((menu, i) => {
                    return (
                        <li className="list-unstyled">
                            <label className="checkbox-inline">
                                {/* <input type="checkbox" name="id" value={`{id:${menu.id}}`} onChange={this.selectMenu} /> */}
                                <input type="checkbox" checked={this.state.form_data.menu.indexOf(menu.id) !== -1} name="menu" value={menu.id} onChange={this.selectMenu} />
                                <span className="" />
                                {menu.title}
                            </label>
                        </li>

                    );
                })
                }
            </ul>
        </div>
    }

    renderMenuListUpdate = () => {

        return <div className="form-group">
            <label>Select Features</label>
            <ul className="checkbox-list m-b-0 p-l-0 clearfix">
                <li className="list-unstyled inline-block text-primary p-0">
                    <label className="checkbox-inline">
                        <input type="checkbox" name="menu" id="select_all" value="select_all" onChange={this.selectMenu} /> <strong>Select All</strong>
                    </label>
                </li>
                {/* <li className="list-unstyled inline-block pull-right text-primary p-0"><strong><i className="icon-plus3 m-l-15"></i>&nbsp;Add<i className="icon-pencil m-l-15"></i>&nbsp;Edit<i className="icon-bin m-l-15"></i>&nbsp;Delete</strong></li> */}
                <li className="list-unstyled inline-block pull-right text-primary p-0"><strong><i className="fa fa-cog"></i>&nbsp;Manage&nbsp;&nbsp;&nbsp;<i className="fa fa-eye"></i>&nbsp;View Only</strong></li>
            </ul>
            {this.state.profile_menu.map((menu, i) => {

                return (
                    <ul className="checkbox-list m-b-0 p-l-0 clearfix">
                        <li className="list-unstyled -block p-0 pull-left">
                            <label className="checkbox-inline m-b-15">

                                <input type="checkbox"
                                    checked={this.state.form_data.menu.indexOf(menu.id) !== -1}
                                    name="menu" value={menu.id} onChange={this.selectMenu} />
                                <span className="" />
                                {menu.title}

                            </label>

                        </li>

                        <li className="list-unstyled inline-block pull-right p-0">

                            {/* view profile */}
                            <label className="checkbox-inline pull-right m-r-0" style={{ 'verticalAlign': 'top', 'padding-left': '30px' }}>
                                <input type="checkbox" name="menu_permission" value="is_delete" checked={this.checkMenuAction("is_delete", menu.id)}
                                    disabled={this.state.form_data.menu.indexOf(menu.id) !== -1 ? true : true} onChange={(e) => this.selectMenuPermission(e, menu.id)}></input>
                            </label>
                            {/* <label className="checkbox-inline pull-right m-r-0" style={{ 'verticalAlign': 'top', 'padding-left': '60px' }}>
                                <input type="checkbox" name="menu_permission" value="is_edit" checked={this.checkMenuAction("is_edit", menu.id)}
                                    disabled={this.state.form_data.menu.indexOf(menu.id) !== -1 ? false : true} onChange={(e) => this.selectMenuPermission(e, menu.id)}></input>
                            </label> */}

                            {/* manage profile */}
                            <label className="checkbox-inline pull-right m-r-0" style={{ 'verticalAlign': 'top', 'padding-left': '80px' }}>
                                <input type="checkbox" name="menu_permission" value="is_add" checked={this.checkMenuAction("is_add", menu.id)}
                                    disabled={this.state.form_data.menu.indexOf(menu.id) !== -1 ? false : true} onChange={(e) => this.selectMenuPermission(e, menu.id)}></input>
                            </label>

                        </li>
                    </ul>
                );
            })
            }
        </div>
    }

    checkMenuAction = (actionType, menuId) => {
        var findMenu = this.state.menu.find(menu => menu.menu_id == menuId)
        // console.log("findmenu", findMenu);
        // console.log('state menu', this.state.menu);
        return findMenu && findMenu[actionType] == 1 ? true : false
    }

    selectMenuPermission = (e, menuId) => {
        let { name, value, checked } = e.target;
        let actionMenu = [...this.state.menu];
        let updateMenu = actionMenu.map(menu => {
            if (menu.menu_id == menuId) {
                menu[value] = checked ? 1 : 0
            }
            return menu;
        })
        // console.log('update menu', updateMenu, name, );
        this.setState({ menu: updateMenu }, () => {
            // console.log(this.state.menu)
        });
    }

    selectMenu = e => {
        let index;
        let form_data = { ...this.state.form_data };
        let actionMenu = [...this.state.menu];
        if (e.target.value != "select_all") {
            if (form_data.menu.indexOf(e.target.value) == -1) {
                actionMenu.push({ menu_id: e.target.value, is_add: 1, is_edit: 1, is_delete: 1 })
                form_data.menu.push(e.target.value);
            } else {
                actionMenu = actionMenu.filter(menu => menu.menu_id !== e.target.value)
                index = form_data.menu.indexOf(e.target.value);
                form_data.menu.splice(index, 1);
            }

            this.setState({ form_data, menu: actionMenu }, () => {
                // console.log(JSON.stringify(this.state.form_data.menu));
            });
        } else {
            if (e.target.checked) {
                let all_menu_id = this.state.profile_menu.map(item => {
                    return item.id;
                });

                let all_menu_action = this.state.profile_menu.map(item => {
                    return { menu_id: item.id, is_add: 1, is_edit: 1, is_delete: 1 };
                });

                form_data.menu = all_menu_id;
                this.setState({ form_data, menu: all_menu_action });
            } else {
                form_data.menu = [];
                this.setState({ form_data, menu: [] });
            }
        }
    }

    createProfile = (e) => {
        let form_data = { ...this.state.form_data };
        form_data.menu = JSON.stringify(form_data.menu.map(item => ({ id: item, is_add: 1, is_edit: 1, is_delete: 1 })));
        // console.log(form_data);
        // return;
        PostDataWithParam('CreateCustomProfileForGMA', form_data)
            .then((response) => {
                let result = response;

                if (result.success === 1) {
                    this.removeDataTable();
                    this.GetProfileData();
                    window.swal('Success!', 'Custom Profile Created Successfully', 'success');

                } else {

                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error');
            })
    }

    updateProfile = (e) => {
        let form_data = { ...this.state.form_data };
        form_data.menu = JSON.stringify(this.state.menu.map(item => ({ id: item.menu_id, is_add: item.is_add, is_edit: item.is_edit, is_delete: item.is_delete })));
        // console.log(form_data);
        // return;
        PostDataWithParam('CreateCustomProfileForGMA', form_data)
            .then((response) => {
                let result = response;

                if (result.success === 1) {
                    this.removeDataTable();
                    this.GetProfileData();
                    window.swal('Success!', 'Profile Updated Successfully', 'success');

                } else {

                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error');
            })
    }

    renderStatus = (status) => {
        if (status == 1) {
            return (
                <span className="text-success">Active</span>
            )
        } else if (status == 0) {
            return (
                <td className="text-danger">Inactive</td>
            )
        } else {
            return (
                <td className="text-primary">In Progress</td>
            )

        }
    }

    DeleteProfile = (id) => {
        // console.log(id);
        // return;
        ConfirmBox("You want to delete this Profile!")
            .then((result) => {
                if (result) {
                    PostDataWithParam('DeleteCustomProfileForGMA', { id })
                        .then((response) => {
                            if (response.success == 1) {
                                this.removeDataTable();
                                this.GetProfileData();
                                window.swal('Deleted!', response.msg, 'success')
                            }
                            else {
                                window.swal('Error!', response.msg, 'error')
                            }

                        })
                        .catch(err => {
                            window.swal('Error!', 'Something went wrong', 'error')
                        })
                }
            })

    }


    ActiveDeactiveProfile = (id, status) => {
        if (status == 1) {
            ConfirmBox("You want to deactivate this profile!")
                .then((result) => {
                    if (result) {
                        PostDataWithParam('ActiveDeactiveCustomProfileForGMA', { id: id, status: 0 })
                            .then((response) => {
                                let result = response;
                                if (result.success === 1) {
                                    this.removeDataTable();
                                    this.GetProfileData();
                                    window.swal('Deactivated!', 'Profile Deactivated Successfully', 'success')
                                } else {
                                    window.swal('Deactivated!', result.msg, 'success')
                                }
                            })
                            .catch(err => {
                                window.swal('Error!', 'Something went wrong', 'error')
                            })
                    }
                });
        }
        else {
            ConfirmBox("You want to activate this profile!")
                .then((result) => {
                    if (result) {
                        PostDataWithParam('ActiveDeactiveCustomProfileForGMA', { id: id, status: 1 })
                            .then((response) => {
                                let result = response;
                                if (result.success === 1) {
                                    this.removeDataTable();
                                    this.GetProfileData();
                                    window.swal('Activated!', 'Profile Activated Successfully', 'success')
                                } else {
                                    window.swal('Activated!', result.msg, 'success')
                                }
                            })
                            .catch(err => {
                                window.swal('Error!', 'Something went wrong', 'error')
                            })
                    }
                });
        }
    }

    handleBtn = (status) => {
        if (status == 0) {
            return (
                'Activate'
            )
        } else {
            return (
                'Deactivate'
            )

        }
    }

    render() {

        return (
            <React.Fragment>
                <div className="content-container">
                    <div className="row">
                        <div className="col-md-12">
                            {Breadcumb(this.state.breadcrumb)}
                            <div className="clearfix">
                                <h4 className="pull-left">Manage Department</h4>

                                {this.state.custom_profile_id == "" &&
                                    <div className="pull-right">
                                        <Link className="btn btn-primary" to="#" onClick={this.onItemClick}><i className="icon-plus2"></i> Add Department</Link>
                                    </div>
                                }

                            </div>
                            <div className="panel panel-flat">
                                {
                                    this.state.loading
                                    &&
                                    <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                                }
                                {
                                    !this.state.loading
                                    &&
                                    this.state.order_list.length !== 0
                                    &&
                                    <table className="table datatable-basic table-striped" data-button-title="Message" id="list-table">
                                        <thead>
                                            <tr>
                                                {/* <th className="text-nowrap">ID</th> */}
                                                <th>Profile Name</th>
                                                <th>Platform</th>
                                                <th>Status</th>
                                                {this.state.custom_profile_id == "" && <th className="text-center">Actions</th>}
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.state.order_list.map((order, index) =>

                                                // <tr id={"#00" + order.id} key={order.id}>
                                                // {/* <td><Link to={{
                                                //     pathname: '#' + order.id
                                                // }}>{helper.gender} {helper.first_name} {helper.last_name}</Link></td> */}
                                                <tr key={index}>
                                                    {/* <td>{order.id}</td> */}
                                                    <td style={{ 'width': '300px' }}>{order.title}</td>
                                                    <td>{order.associated_with_profile_id == "10" ? "MyPCSGov" : "Independent Movers Network® (Mover)"}</td>
                                                    <td>{this.renderStatus(order.status)}</td>
                                                    {this.state.custom_profile_id == "" &&
                                                        <td className="text-center">
                                                            <ul className="icons-list">
                                                                <li className="dropdown">
                                                                    <a href="" className="dropdown-toggle" data-toggle="dropdown">
                                                                        <i className="icon-menu9"></i>
                                                                    </a>
                                                                    <ul className="dropdown-menu dropdown-menu-right">

                                                                        <li>
                                                                            <a href="#" data-toggle="modal" data-target="#updateManageProfile" onClick={() => this.getUserInfo(order.id)}><i className="icon-pencil"></i>Edit Profile</a>

                                                                        </li>

                                                                        <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.ActiveDeactiveProfile(order.id, order.status)} ><i className="icon-user-block"></i>{this.handleBtn(order.status)} Profile</a></li>
                                                                        <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.DeleteProfile(order.id)} ><span className="glyphicon glyphicon-remove"></span>&nbsp;&nbsp;&nbsp;&nbsp;Delete Profile</a></li>

                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    }
                                                </tr>
                                            )}


                                            {/* {this.state.helper_list.map(helper =>

                                                    <tr id={"#00" + helper.id} key={helper.id}>
                                                        <td><Link to={{
                                                            pathname: '/dashboard/viewhelper/' + helper.user_id
                                                        }}>{helper.gender} {helper.first_name} {helper.last_name}</Link></td>
                                                        <td>{helper.contact_no}</td>
                                                        <td>{helper.email}</td>
                                                        <td>{helper.associated_with}</td>
                                                        <td>{this.handleStatus(helper.status)}</td>
                                                        <td className="text-center">
                                                            <ul className="icons-list">
                                                                <li className="dropdown">
                                                                    <a href="" className="dropdown-toggle" data-toggle="dropdown">
                                                                        <i className="icon-menu9"></i>
                                                                    </a>
                                                                    <ul className="dropdown-menu dropdown-menu-right">
                                                                        <li><Link to={{
                                                                            pathname: '/dashboard/edit-helper/' + helper.user_id
                                                                        }}>
                                                                            <i className="icon-pencil"></i>Edit Helper</Link></li>
                                                                        <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.ActiveDeactiveUsers(helper.id, helper.status)}><i className="icon-user-block"></i> {this.handleBtn(helper.status)} Helper </a></li>
                                                                        <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.DeleteUsers(helper.id)} ><i className="icon-user-block" ></i> Delete Helper</a></li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                )} */}
                                        </tbody>
                                    </table>

                                }
                                {
                                    !this.state.loading
                                    &&
                                    this.state.order_list.length === 0
                                    &&
                                    <div className="alert alert-primary border-none text-center m-15">No Profile Found.</div>
                                }

                            </div>


                        </div>
                    </div>
                </div>
                <div className="modal fade" tabindex="-1" role="dialog" id="manageProfile">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title">Add Department</h4>
                            </div>
                            <hr className="m-0 m-t-5"></hr>
                            <div className="modal-body">
                                {/* {this.getProfileMenuList(2)} */}
                                {/* <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Select Domain</label>
                                            <select className="form-control" name="profile_id" value={this.state.form_data.profile_id} onChange={(e)=>this.getProfileMenuList(e.target.value)}>
                                                <option>Select One</option>
                                                <option value="10">MyPCSGov</option>
                                                <option value="2">GetMilitaryApproved</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Add New Profile</label>
                                            <input type="text" className="form-control" name="title" value={this.state.form_data.title} onChange={this.handleOnChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.renderMenuList()}
                                        {/* <div className="form-group">
                                            <label>Select Menu</label>
                                            <ul className="checkbox-list m-b-0 p-l-0">
                                                <li className="list-unstyled"><label className="checkbox-inline">
                                                    <input type="checkbox" name="select_all" value="select all" />
                                                    <span className=""></span>Select All</label>
                                                </li>
                                                {this.renderCheckboxes()}
                                            </ul>
                                        </div> */}
                                    </div>
                                </div>
                                <hr className="m-t-0"></hr>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="checkbox-inline">

                                            <input type="checkbox" name="show_in_menu" id="show_in_menu" value="1" onChange={this.handleOnChange} checked={this.state.form_data.show_in_menu == 1} />
                                            <span className=""></span> <strong>Show On Navigation <span className="text-danger">*</span></strong> </label>
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer p-t-15">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.createProfile}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Edit */}

                <div className="modal fade" tabindex="-1" role="dialog" id="updateManageProfile">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title">Edit Department</h4>
                            </div>
                            <hr className="m-0 m-t-5"></hr>
                            <div className="modal-body">
                                {/* <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Select Domain</label>
                                            <select className="form-control" name="profile_id" value={this.state.form_data.profile_id} disabled={true}>
                                            
                                                <option>Select One</option>
                                                <option value="10">MyPCSGov</option>
                                                <option value="2">GetMilitaryApproved</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Profile Name</label>

                                            <input type="text" className="form-control" name="title" value={this.state.form_data.title || ''} onChange={this.handleOnChange} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        {this.renderMenuListUpdate()}
                                        {/* <div className="form-group">
                                            <label>Select Menu</label>
                                            <ul className="checkbox-list m-b-0 p-l-0">
                                                <li className="list-unstyled"><label className="checkbox-inline">
                                                    <input type="checkbox" name="select_all" value="select all" />
                                                    <span className=""></span>Select All</label>
                                                </li>
                                                {this.renderCheckboxes()}
                                            </ul>
                                        </div> */}
                                    </div>

                                </div>
                                <hr className="m-t-0"></hr>
                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="checkbox-inline">

                                            <input type="checkbox" name="show_in_menu" id="show_in_menu" onChange={this.handleOnChange} checked={this.state.form_data.show_in_menu == 1} />
                                            <span className=""></span> <strong>Show On Navigation <span className="text-danger">*</span></strong> </label>
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer p-t-15">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.updateProfile}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ManageProfile;

