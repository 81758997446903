import React, { Component } from 'react';
import PostDataFile from '../services/PostDataFile';
import PostDataWithParam from '../services/PostDataWithParam';
import { Redirect, Link } from 'react-router-dom';
import {FormatSSN} from '../externaljs/FormatSSN';
import Breadcumb from '../externaljs/Breadcrumb';

class EditHelper extends Component {
    state = {
        fields: {
            first_name: '',
            last_name: '',
            licence_number: '',
            license_exp_date: '',
            medical_exam_exp_date: '',
            vehicle_no: '',
            trailer: '',
            contact_no: '',
            email: '',
            alternate_email: '',
            home_contact_no: '',
            address: '',
            latitude: '',
            longitude: '',
            dob: '',
            ssn: '',
            emergency_contact_name: '',
            emergency_contact_phone: '',
            emergency_contact_email: '',
            background_check_status: '',
            profile_image: '',
            licence_image: '',
            passport_image: '',
            background_check_image: ''
        },
        error: {
            first_name_err: '',
            last_name_err: '',
            email_err: '',
            contact_no_err: '',
            gender_err: '',
            dob_err: '',
            address_err: '',
            vehicle_no_err: '',
            licence_image_err: '',
            licence_number_err: ''

        },
        addHelperStatus: false,
        HelperErr: '',
        viewSsn:true,
        profile_image: '',
        licence_image: '',
        passport_image: '',
        background_check_image: '',
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Helpers', link: '/independent-mover/dashboard/helpers', class: '' },
            { title: 'Edit Helper', link: '', class: 'active' }
        ]
    }

    componentDidMount() {
        window.$('.input-group.date').datepicker({
            autoclose: true
        }).on('changeDate', (e) => {
            let fields = this.state.fields;
            fields.dob = e.format();
            this.setState({ fields: fields });
        });
        window.$('.select').select2({
            minimumResultsForSearch: Infinity
        });
        this.GetHelperDetails();
    }
    Ssnformat = (ssn) => {
        if (typeof ssn !== 'undefined' && ssn!=null) {
            let ssn_new = ssn.replace(/^.{6}/g, '***-**')
            return ssn_new
        } else {
            return '';
        }
    }

    openTextbox = () =>{        
        this.setState({ viewSsn:false,ssnClick:true },()=>{
            FormatSSN()
        });
    }

    GetHelperDetails = () => {
        PostDataWithParam('GetHelperDetails', { helper_id: this.props.match.params.helper_id })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ fields: response.data[0] });
                }
            })
            .catch(err=>{
                this.setState({ HelperErr: 'Oops! Something went wrong.',addHelperStatus: false });
            })
    }


    initAutocomplete = (e) => {
        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: ['geocode'] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let fields = this.state.fields;
            fields.latitude = place.geometry.location.lat();
            fields.longitude = place.geometry.location.lng();
            fields.address = window.$('#address')[0].value;
            this.setState({ fields: fields });
        })
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields });
    }

    resetError = (cb) => {
        let error = {
            first_name_err: '',
            last_name_err: '',
            email_err: '',
            contact_no_err: '',
            gender_err: '',
            dob_err: '',
            address_err: ''
        }
        this.setState({ error: error }, () => {
            cb();
        });
    }

    handletBackgroundImage = (e) => {
        this.setState({ background_check_image: e.target.files[0] });
    }
    handlePassportImage    = (e) => {
        this.setState({ passport_image: e.target.files[0] });
    }
    handleLicenceImage     = (e) => {
        this.setState({ licence_image: e.target.files[0] });
    }
    handleProfileImage     = (e) => {
        this.setState({ profile_image: e.target.files[0] });
    }

   checkBackground = (status) => {
        if(status === 1){
            return(
                <div>
                <label className="radio-inline"><input type="radio"  value="1" defaultChecked={true} name="background_check_status" onChange={this.changeValue}/> Yes</label>
                <label className="radio-inline"><input type="radio"  value="0" name="background_check_status" onChange={this.changeValue}/> No</label>
                </div>
            )
        }else{
            return(
                <div>
                <label className="radio-inline"><input type="radio"  value="1" name="background_check_status" onChange={this.changeValue}/> Yes</label>
                <label className="radio-inline"><input type="radio"  value="0" defaultChecked={true} name="background_check_status" onChange={this.changeValue}/> No</label>
                </div>
            )
        }
    }

    handleAddHelper = () => {
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        this.resetError(() => {
            let error = this.state.error;
            if (!this.state.fields.gender) {
                error.gender_err = 'Salutation is required.';
            }
            else if (!this.state.fields.first_name) {

                error.first_name_err = 'First name is required.';
            }
            else if (!this.state.fields.last_name) {

                error.last_name_err = 'Last name is required.';
            }
            else if (!this.state.fields.licence_number) {

                error.licence_number_err = "Driver's License is required.";
            }
            else if (!this.state.fields.vehicle_no) {

                error.vehicle_no_err = 'Vehicle No is required.';
            }
            else if (!this.state.fields.contact_no) {

                error.contact_no_err = 'Please enter mobile number.';
            }
            else if (isNaN(this.state.fields.contact_no)) {
                error.contact_no_err = 'Please enter a valid mobile number.';
            }
            else if (!this.state.fields.email) {

                error.email_err = 'Email is required.';
            } else if (!pattern.test(this.state.fields.email)) {

                error.email_err = 'Please enter valid email.';
            }
            else if (!pattern.test(this.state.fields.alternate_email) && this.state.fields.alternate_email) {
                error.alternate_email_err = 'Please enter valid email.';
            }
            else if (isNaN(this.state.fields.home_contact_no)) {
                error.home_contact_no_err = 'Please enter a valid mobile number.';
            }
            else if (!this.state.fields.address) {
                error.address_err = 'Address is required.';
            } 
            else if (document.getElementById('dob').value === '') {
                error.dob_err = 'Date of birth is required.';
            } 
            else if (isNaN(this.state.fields.emergency_contact_phone)) {
                error.emergency_contact_phone_err = 'Please enter a valid mobile number.';
            }
            else if (!pattern.test(this.state.fields.emergency_contact_email) && this.state.fields.emergency_contact_email) {
                error.emergency_contact_email_err = 'Please enter valid email.';
            }
            else if (this.state.fields.licence_image === '') {
                error.licence_image_err = 'License image is required.';
            }
            else if (!this.state.fields.background_check_status) {
                error.background_check_status_err = 'Please select an option';
            } 
            
            else {

                let fields = this.state.fields;
                fields.helper_id = this.props.match.params.helper_id ;
                fields.dob = document.getElementById('dob').value;
                fields.license_exp_date = document.getElementById('license_exp_date').value;
                fields.medical_exam_exp_date = document.getElementById('medical_exam_exp_date').value;
                this.setState({ fields: fields });
                    let fd = new FormData();
                    if(this.state.background_check_image.name !== undefined){
                        fd.append('background_check_image', this.state.background_check_image, this.state.background_check_image.name);
                    }
                    if(this.state.passport_image.name !== undefined){
                        fd.append('passport_image', this.state.passport_image, this.state.passport_image.name);
                    }
                    if(this.state.profile_image.name !== undefined){
                        fd.append('profile_image', this.state.profile_image, this.state.profile_image.name);
                    }
                    if(this.state.licence_image.name !== undefined){
                        fd.append('licence_image', this.state.licence_image, this.state.licence_image.name);
                    }
                    fd.append('first_name', this.state.fields.first_name);
                    fd.append('last_name', this.state.fields.last_name);
                    fd.append('email', this.state.fields.email);
                    fd.append('alternate_email', this.state.fields.alternate_email);
                    fd.append('contact_no', this.state.fields.contact_no);
                    fd.append('home_contact_no', this.state.fields.home_contact_no);
                    fd.append('gender', this.state.fields.gender);
                    fd.append('dob', this.state.fields.dob);
                    fd.append('ssn', this.state.fields.ssn);
                    fd.append('address', this.state.fields.address);
                    fd.append('latitude', this.state.fields.latitude);
                    fd.append('longitude', this.state.fields.longitude);
                    fd.append('vehicle_no', this.state.fields.vehicle_no);
                    fd.append('licence_number', this.state.fields.licence_number);
                    fd.append('emergency_contact_name', this.state.fields.emergency_contact_name);
                    fd.append('emergency_contact_phone', this.state.fields.emergency_contact_phone);
                    fd.append('emergency_contact_email', this.state.fields.emergency_contact_email);
                    fd.append('background_check_status', this.state.fields.background_check_status);
                    fd.append('trailer', this.state.fields.trailer);
                    fd.append('license_exp_date', this.state.fields.license_exp_date);
                    fd.append('medical_exam_exp_date', this.state.fields.medical_exam_exp_date);
                    fd.append('helper_id',this.state.fields.helper_id);

                    PostDataFile('AddHelpers', fd)
                        .then((response) => {
                            if (response.success === 1) {
                                this.setState({ HelperErr: response.msg }, () => {
                                    this.setState({ addHelperStatus: true });
                                });
                            } else {
                                this.setState({ HelperErr: response.msg });
                            }
                        })
                        .catch(err=>{
                            this.setState({ HelperErr: 'Oops! Something went wrong.',addHelperStatus: false });
                        })
                }
            // }
            this.setState({ error: error });
        });
    }

    render() {

        if (this.state.addHelperStatus) {
            return <Redirect to={{
                pathname: '/independent-mover/dashboard/helpers',
                state: { HelperErr: this.state.HelperErr }
            }} />
        }

        return (
            <div className="row">
            <div className="col-md-12">
            {Breadcumb(this.state.breadcrumb)}
                <div className="panel panel-flat profile-page">
                    <div className="panel-heading p-b-0">
                        <h5 className="panel-title">Edit Helper Details</h5>
                        <span className="text-danger">{this.state.HelperErr}</span>
                    </div>
                    <form action="#">
                        <div className="panel-body">
        
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label>Salutation</label>
                                        <select className="form-control" name="gender" onChange={this.changeValue} value={this.state.fields.gender}>
                                            <option></option>
                                            <option>Mr.</option>
                                            <option>Ms.</option>
                                            <option>Mrs.</option>
                                        </select>
                                        <p className="text-danger help-block">{this.state.error.gender_err}</p>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input className="form-control" type="text"  name="first_name" onChange={this.changeValue} value={this.state.fields.first_name}/>
                                        <p className="text-danger help-block">{this.state.error.first_name_err}</p>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input className="form-control" type="text" name="last_name" onChange={this.changeValue} value={this.state.fields.last_name}/>
                                        <p className="text-danger help-block">{this.state.error.last_name_err}</p>
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Driver's License #</label>
                                        <input className="form-control" type="text" name="licence_number" onChange={this.changeValue} value={this.state.fields.licence_number}/>
                                        <p className="text-danger help-block">{this.state.error.licence_number_err}</p>
                                 </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>License Exp. Date</label>
                                        <div className="input-group date">
                                            <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                            <input type="text" className="form-control" id="license_exp_date" value={this.state.fields.license_exp_date}/>
                                        </div>                                
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="from-group">
                                        <label>Medical Exam Exp. Date</label>
                                        <div className="input-group date">
                                            <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                            <input type="text" className="form-control"  id="medical_exam_exp_date" value={this.state.fields.medical_exam_exp_date}/>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Vehicle #</label>
                                        <input className="form-control" type="text"  name="vehicle_no" onChange={this.changeValue} value={this.state.fields.vehicle_no}/>
                                        <p className="text-danger help-block">{this.state.error.vehicle_no_err}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Trailer # (if any)</label>
                                        <input className="form-control" type="text" name="trailer" onChange={this.changeValue} value={this.state.fields.trailer}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Mobile Phone #</label>
                                        <input className="form-control" type="text" name="contact_no" onChange={this.changeValue} value={this.state.fields.contact_no}/>
                                        <p className="text-danger help-block">{this.state.error.contact_no_err}</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Primary Email Address</label>
                                        <input className="form-control" type="text" name="email" onChange={this.changeValue} value={this.state.fields.email}/>
                                        <p className="text-danger help-block">{this.state.error.email_err}</p>
                                </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Recovery Email address</label>
                                        <input className="form-control" type="text" name="alternate_email" onChange={this.changeValue} value={this.state.fields.alternate_email}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Home Phone #</label>
                                        <input className="form-control" type="text" name="home_contact_no" onChange={this.changeValue} value={this.state.fields.home_contact_no}/>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Address</label>
                                        <input className="form-control" type="text"id="address" onFocus={this.initAutocomplete} name="address" onChange={this.changeValue}  value={this.state.fields.address}/>
                                        <p className="text-danger help-block">{this.state.error.address_err}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Date of Birth</label>
                                        <div className="input-group date">
                                            <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                            <input type="text" className="form-control"  id="dob" value={this.state.fields.dob}/>
                                            <p className="text-danger help-block">{this.state.error.dob_err}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                            <label>SSN </label>
                                            {
                                            this.state.viewSsn
                                            &&
                                            <input type="text" className="form-control" name="state-ssn" required={true} defaultValue={this.Ssnformat(this.state.fields.ssn)} onClick={this.openTextbox}/>
                                            }                                            
                                            {
                                            this.state.ssnClick
                                            &&
                                            <input type="text" className="form-control ssn" name="ssn" onChange={this.changeValue} maxLength="11" placeholder="Enter your social security number"/>
                                            }
                                            <p className="text-danger help-block">{this.state.error.ssn_err}</p>
                                        </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Emergency Contact Name</label>
                                        <input className="form-control" type="text" name="emergency_contact_name" onChange={this.changeValue} value={this.state.fields.emergency_contact_name}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Emergency Contact Phone</label>
                                        <input className="form-control" type="text" name="emergency_contact_phone" onChange={this.changeValue} value={this.state.fields.emergency_contact_phone}/>
                                        <p className="text-danger help-block">{this.state.error.emergency_contact_phone_err}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Emergency Contact Email Address</label>
                                        <input className="form-control" type="text" name="emergency_contact_email" onChange={this.changeValue} value={this.state.fields.emergency_contact_email}/>
                                        <p className="text-danger help-block">{this.state.error.emergency_contact_email_err}</p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Profile Image</label>
                                        <div>
                                            <img src={this.state.fields.profile_image} alt="image"  style={style} className="m-b-10"/>
                                        </div>                                
                                        <input type="file"  name="profile_image" id="profile_image" onChange={this.handleProfileImage}/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>License Image</label>
                                        <div>
                                            <img src={this.state.fields.licence_image}  alt="image" style={style}  className="m-b-10"/>
                                        </div>
                                        <input type="file" name="licence_image" id="licence_image" onChange={this.handleLicenceImage}/>
                                        <p className="text-danger help-block">{this.state.error.licence_image_err}</p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Passport Image</label>
                                        <div>
                                            <img src={this.state.fields.passport_image}  alt="image"  style={style}  className="m-b-10"/>
                                        </div>
                                        <input type="file" name="passport_image" id="passport_image" onChange={this.handlePassportImage}/>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Background Check</label>
                                        <div>
                                            {this.checkBackground(this.state.fields.background_check_status)}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Background Check Image</label>
                                        <img src={this.state.fields.background_check_image}  alt="image"  style={style}  className="m-b-10"/>
                                        <input type="file" name="background_check_image" id="background_check_image" onChange={this.handletBackgroundImage}/>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div className="panel-footer text-right p-r-20">
                            <button type="button" className="btn btn-primary" onClick={this.handleAddHelper}>Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        );
    }
}

export default EditHelper;

const style = {
    maxWidth: '100%'
}