import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PostDataWithParam from '../services/PostDataWithParam';
import GetData from '../services/GetData';
import ConfirmBox from '../externaljs/ConfirmBox';
import Storage from '../externaljs/Storage';
import Breadcumb from '../externaljs/Breadcrumb';

class ManageQuestion extends Component {
     state = {
          breadcrumb: [
               { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
               { title: 'Customer satisfaction survey', link: '/independent-mover/dashboard/home', class: '' },
               { title: 'Manage Questions', link: '', class: 'active' }
          ],
          question: '',
          questions: [],
          loading: false,
          menu: [],
          custom_profile: [],
          staff_user_id: '',
          custom_menu: [],
     }

     componentDidMount() {
          this.getAllQuestions();
          let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
          let custom_menu = [];
          if (staff_user_id != "") {
               custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
          }
          this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
               // console.log(this.state.custom_menu);
               // console.log(this.state.staff_user_id);
          });
     }

     changeValue = (e) => {
          this.setState({ question: e.target.value });
     }

     submitQues = (e) => {
          e.preventDefault();
          PostDataWithParam('AddQuestion', { question: this.state.question })
               .then((response) => {
                    if (response.success === 1) {
                         window.$("#addNewQuestion").modal('hide')
                         window.swal('Success!', response.msg, 'success');
                         this.setState({ question: '' });
                         this.getAllQuestions()
                    } else {
                         window.swal('Error!', response.msg, 'error');
                    }
               })
               .catch(err => {
                    window.swal('Error!', 'Oops! Something went wrong.', 'error');
               })
     }

     getAllQuestions = () => {
          this.setState({ loading: true }, () => {
               PostDataWithParam('GetQuestion', {})
                    .then((response) => {
                         if (response.success === 1) {
                              this.setState({ questions: response.data, loading: false });
                         } else {
                              this.setState({ questions: [], loading: false });
                              // window.swal('Error!', 'Oops! Something went wrong.', 'error');
                         }
                    })
                    .catch(err => {
                         this.setState({ questions: [], loading: false });
                         // window.swal('Error!', 'Oops! Something went wrong.', 'error');
                    })
          });
     }

     DeleteQuestion = (id) => {
          ConfirmBox("You want to delete this question?")
               .then((result) => {
                    if (result) {
                         PostDataWithParam('DeleteQuestion', { id: id })
                              .then((response) => {
                                   if (response.success == 1) {
                                        window.swal('Deleted!', response.msg, 'success')
                                        this.getAllQuestions()
                                   } else {
                                        window.swal('Error!', response.msg, 'error')
                                   }
                              })
                              .catch(err => {
                                   window.swal('Error!', 'Oops! Something went wronghh.', 'error');
                              });
                    }
               });
     }


     render() {
          return (

               <div>
                    {/* <div className="breadcrumb-line breadcrumb-line-component m-b-20">
                         <ul className="breadcrumb">
                              <li><a href='/independent-mover/dashboard/home'><i className="icon-meter-slow position-left"></i> Dashboard</a></li>
                              <li><a href='/independent-mover/dashboard/home'>Customer satisfaction survey</a></li>
                              <li className="active">Manage Questions</li>
                         </ul>
                    </div> */}
                    <div className="row">
                         <div className="col-md-12">
                              {Breadcumb(this.state.breadcrumb)}
                         </div>
                    </div>
                    <div className="panel panel-default">
                         <div className="panel-heading">
                              <h5 className="panel-title">Manage Questions</h5>
                              {
                                   (this.state.staff_user_id == "") &&
                                   <div className="heading-elements"><a href="" data-toggle="modal" data-target="#addNewQuestion" className="btn btn-xs btn-primary"><i className="icon-plus22"></i> Add New Question</a></div>
                              }

                              {
                                   (this.state.staff_user_id == "") && (this.state.staff_user_id != "") && this.state.custom_menu.map((menu, i) => {
                                        return (
                                             <div>
                                                  {
                                                       (menu.title == "Customer Satisfaction Survey" && (menu.is_add == 1 || menu.is_add == "1")) &&

                                                       <div className="heading-elements"><a href="" data-toggle="modal" data-target="#addNewQuestion" className="btn btn-xs btn-primary"><i className="icon-plus22"></i> Add New Question</a></div>

                                                  }
                                             </div>
                                        );
                                   })

                              }

                         </div>
                         <div className="panel-body">
                              {
                                   this.state.loading
                                   &&
                                   <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                              }
                              {
                                   !this.state.loading
                                   &&
                                   this.state.questions.length > 0
                                   &&
                                   this.state.questions.map((item, i) => {
                                        return (
                                             <div className="well m-b-15 p-15" key={i}>
                                                  <div className="row">
                                                       <div className="col-md-9">
                                                            <h5 className="m-0">{item.question}</h5>
                                                       </div>
                                                       {
                                                            (this.state.staff_user_id == "") &&
                                                            <div className="col-md-3 text-right">
                                                                 <a href="javascript:void(0)" onClick={() => this.DeleteQuestion(item.id)} className="text-danger"><i className="icon-cross"></i> Delete</a>
                                                            </div>
                                                       }

                                                       {

                                                            (this.state.staff_user_id != "") && this.state.custom_menu.map((menu, i) => {
                                                                 return (
                                                                      <div>
                                                                           {
                                                                                (menu.title == "Customer Satisfaction Survey" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                                                <div className="col-md-3 text-right">
                                                                                     <a href="javascript:void(0)" onClick={() => this.DeleteQuestion(item.id)} className="text-danger"><i className="icon-cross"></i> Delete</a>
                                                                                </div>
                                                                           }
                                                                      </div>
                                                                 );
                                                            })

                                                       }

                                                  </div>
                                             </div>
                                        )
                                   })
                              }
                              {
                                   !this.state.loading
                                   &&
                                   this.state.questions.length == 0
                                   &&
                                   <div className="row">
                                        <div className="alert alert-primary border-none text-center">
                                             No Questions Found.
                                        </div>
                                   </div>
                              }
                         </div>
                    </div>

                    <div id="addNewQuestion" className="modal fade" role="dialog">
                         <div className="modal-dialog">
                              <div className="modal-content">
                                   <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        <h4 className="modal-title">Add New Question</h4>
                                   </div>
                                   <hr className="m-0 m-t-5" />
                                   <form method="POST" action="#" onSubmit={this.submitQues}>
                                        <div className="modal-body">
                                             <div className="form-group">
                                                  <label>New Question</label>
                                                  <input type="text" className="form-control" placeholder="Enter a New Question" name="question" required={true} onChange={this.changeValue} />
                                             </div>
                                        </div>
                                        <hr className="m-0" />
                                        <div className="modal-footer p-t-15">
                                             <button type="submit" className="btn btn-primary btn-xs">Submit</button>
                                             <button type="button" className="btn btn-default btn-xs" data-dismiss="modal">Close</button>
                                        </div>
                                   </form>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}


export default ManageQuestion;