import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import Breadcumb from '../externaljs/Breadcrumb';
import PostDataWithParam from '../services/PostDataWithParam';
import { FormatSSN } from '../externaljs/FormatSSN';
import PostDataFile from '../services/PostDataFile';

class AddDriver extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Drivers', link: '/independent-mover/dashboard/drivers', class: '' },
            { title: 'Add Driver', link: '', class: 'active' }
        ],
        fields: {
            first_name: '',
            last_name: '',
            email: '',
            contact_no: '',
            associated_with_id: '',
            medical_exam_exp_date: '',
            medical_card_issue_date: '',
            gender: 'Mr.',
            alternate_email: '',
            home_contact_no: '',
            address: '',
            latitude: '',
            longitude: '',
            city: '',
            state: '',
            zip: '',
            years_of_experience: '',
            licence_number: '',
            licence_state: 'Alaska',
            license_exp_date: '',
            licence_image: '',
            passport_image: '',
            passport_no: '',
            passport_exp_date: '',
            place_of_birth: '',
            background_check_status: 0,
            background_check_perform_date: '',
            medical_card_image: '',
            background_check_image: '',
            about_me: '',
            dob: '',
            ssn: '',
            ssn_value: '',
            background_check_requested_to: '',
            is_extra_buck: 0,
            current_employer: '',
            profile_image: ''
        },
        error: {
            first_name_err: '',
            last_name_err: '',
            email_err: '',
            contact_no_err: ''
        },
        changeUpdateStatus: false,
        UpdateErr: '',
        departments: [],
        allStates: [],
        primaryCities: [],
        dept: [],
        ssnClick: false,
        viewSsn: true,
        selectedFile: null,
        profile_image: '',
        processing: false
    }

    renderSubmitBtn = () => {
        if (this.state.processing) {
            return <input type="button" className="btn btn-primary" value="Processing..." disabled={true} />
        } else {
            return <button type="submit" className="btn btn-primary">SUBMIT</button>
        }
    }

    datepic(name) {
        if(name=='dob'){
            window.$('#' + name).daterangepicker({
                showDropdowns: true,
                autoclose: true,
                singleDatePicker: true,
                maxDate: new Date()
            }).on('apply.daterangepicker', (ev, picker) => {
                let fields = this.state.fields;
                fields[name] = picker.startDate.format('MM/DD/YYYY');
                this.setState({ fields: fields });
            });
        }
        else{
            window.$('#' + name).daterangepicker({
                showDropdowns: true,
                autoclose: true,
                singleDatePicker: true,
            }).on('apply.daterangepicker', (ev, picker) => {
                let fields = this.state.fields;
                fields[name] = picker.startDate.format('MM/DD/YYYY');
                this.setState({ fields: fields });
            });
        }
        
    }
    componentDidMount() {


        this.datepic('dob');
        this.datepic('license_exp_date');
        this.datepic('passport_exp_date');
        this.datepic('medical_card_issue_date');
        this.datepic('medical_exam_exp_date');
        this.datepic('background_check_perform_date');

        window.$('.monthselect, .yearselect').select2();
        this.getStates();

    }

    Ssnformat = (social_security_number) => {
        if (typeof social_security_number !== 'undefined' && social_security_number != null) {
            let ssn_new = social_security_number.replace(/^.{6}/g, '***-**')
            return ssn_new
        } else {
            return '';
        }
    }

    openTextbox = () => {
        this.setState({ viewSsn: false, ssnClick: true }, () => {
            FormatSSN()
        });
    }
    initAutocomplete = (e) => {
        let fields = this.state.fields;
        fields.address = e.target.value;
        this.setState({ fields: fields });

        var componentForm = {
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            postal_code: 'short_name'
        };

        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: [] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let fields = this.state.fields;

            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = place.address_components[i][componentForm[addressType]];
                    if (addressType == "administrative_area_level_1")
                        fields.state = val
                    else if (addressType == "locality")
                        fields.city = val
                    else if (addressType == "postal_code")
                        fields.zip = val
                }
            }

            fields.latitude = place.geometry.location.lat();
            fields.longitude = place.geometry.location.lng();
            fields.address = window.$('#address')[0].value;
            this.setState({ fields: fields });
        })
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        if(e.target.name=='contact_no' || e.target.name=='home_contact_no')
        {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                fields[e.target.name]= match[1] + ' ' + match[2] + ' ' + match[3]
              }
        }
        this.setState({ fields: fields });


    }

    handleFileSelect = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.files[0]
        this.setState({ fields: fields });
    }

    getStates() {
        PostDataWithParam('GetStates', {})
            .then((response) => {
                let result = response;
                if (result.success === 1) {
                    this.setState({ allStates: result.data }, () => {
                        //this.GetCompanyInfo();
                    });
                } else {
                    this.setState({ UpdateErr: result.msg });
                }
            })
            .catch(err => {

            })
    }


    handleUpdateProfile = (e) => {

        let error=this.state.error;

        e.preventDefault();
        let fields = this.state.fields;
        let fd = new FormData();

        for (var key in fields) {
            if (fields.hasOwnProperty(key)) {
                fd.append(key, fields[key]);
            }
        }
        if (this.state.fields.licence_image && this.state.fields.licence_image.name)
            fd.append('licence_image', this.state.fields.licence_image, this.state.fields.licence_image.name);
        else
            fd.append('licence_image', '');

        if (this.state.fields.passport_image && this.state.fields.passport_image.name)
            fd.append('passport_image', this.state.fields.passport_image, this.state.fields.passport_image.name);
        else
            fd.append('passport_image', '');

        if (this.state.fields.medical_card_image && this.state.fields.medical_card_image.name)
            fd.append('medical_card_image', this.state.fields.medical_card_image, this.state.fields.medical_card_image.name);
        else
            fd.append('medical_card_image', '');

        if (this.state.fields.background_check_image && this.state.fields.background_check_image.name)
            fd.append('background_check_image', this.state.fields.background_check_image, this.state.fields.background_check_image.name);
        else
            fd.append('background_check_image', '');

        if (this.state.fields.profile_image && this.state.fields.profile_image.name)
            fd.append('profile_image', this.state.fields.profile_image, this.state.fields.profile_image.name);
        else
            fd.append('profile_image', '');

        // if (!this.state.fields.contact_no) {
                
        //         error.contact_no_err = 'Please enter mobile number.';
        //     }
        // if (isNaN(this.state.fields.contact_no)) {
                
        //         error.contact_no_err = 'Please enter a valid mobile number.';
        //     }

        this.setState({ processing: true }, () => {
            // if (this.state.fields.licence_state === '' || this.state.fields.licence_state === 'null' || this.state.fields.licence_state === null)
            //     fd.append('licence_state', 'Alaska');

            PostDataFile('AddDrivers', fd)
                .then((response) => {
                    if (response.success === 1) {
                        window.swal('Success!', response.msg, 'success')
                        this.setState({ changeUpdateStatus: true });
                    } else {
                        window.swal('Error!', response.msg, 'error')
                        this.setState({ processing: false });
                    }
                })
                .catch(err => {
                    window.swal('Error!', 'Something went wrong.', 'error')
                    this.setState({ processing: false });
                })
        })

    
    }

    render() {

        if (this.state.changeUpdateStatus) {
            return <Redirect to='/independent-mover/dashboard/drivers' />
        }

        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="panel panel-flat profile-page">
                        <div className="panel-heading p-b-0">
                            <h5 className="panel-title">Add Driver Details</h5>
                        </div>
                        <hr />
                        <form action="" method="POST" onSubmit={this.handleUpdateProfile}>
                            <div className="panel-body p-t-0">
                                <h5 className="text-medium m-t-0 text-green m-b-15">Driver Info</h5>
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Salutation <span className="text-danger">*</span></label>
                                            <select className="form-control" name="gender" value={this.state.fields.gender || 'Mr.'} onChange={this.changeValue} required={true}>
                                                <option>Mr.</option>
                                                <option>Ms.</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label>First Name <span className="text-danger">*</span></label>
                                            <input type="text" name="first_name" value={this.state.fields.first_name || ''} onChange={this.changeValue} className="form-control" placeholder="Enter First Name" required={true} />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label>Last Name <span className="text-danger">*</span></label>
                                            <input type="text" name="last_name" value={this.state.fields.last_name || ''} onChange={this.changeValue} className="form-control" placeholder="Enter Last Name" required={true} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Home Street Address</label>
                                            <input type="text" name="address" id="address" value={this.state.fields.address || ''} onChange={this.initAutocomplete} className="form-control" placeholder="Enter Home Street Address" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>City</label>
                                            <input type="text" name="city" value={this.state.fields.city || ''} onChange={this.changeValue} className="form-control" placeholder="Enter City" readOnly={true} />

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>State</label>
                                            <input type="text" name="state" value={this.state.fields.state || ''} onChange={this.changeValue} className="form-control" placeholder="Enter State" readOnly={true} />

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Zip</label>
                                            <input type="text" className="form-control" name="zip" placeholder="Enter Zip" value={this.state.fields.zip || ''} onChange={this.changeValue} readOnly={true} />

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Email Address <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name="email" value={this.state.fields.email || ''} onChange={this.changeValue} placeholder="Enter Email id" required={true} />

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Recovery Email Address</label>
                                            <input type="text" className="form-control" name="alternate_email" value={this.state.fields.alternate_email || ''} onChange={this.changeValue} placeholder="Enter Email id" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Mobile Phone <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" name="contact_no" value={this.state.fields.contact_no || ''} onChange={this.changeValue} placeholder="xxx xxx xxxx" required={true} maxLength="12"/>
                                            <p className="text-danger help-block">{this.state.error.contact_no_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Alternate Phone No</label>
                                            <input type="text" className="form-control" name="home_contact_no" value={this.state.fields.home_contact_no || ''} onChange={this.changeValue} placeholder="xxx xxx xxxx" maxLength="12"/>

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Date of Birth</label>
                                            <div className="input-group date" id="dob">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control" name="dob" value={this.state.fields.dob || ''} onChange={this.changeValue} placeholder="Enter date of birth" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Social Security Number</label>
                                            <input type="text" className="form-control" name="ssn" value={this.state.fields.ssn || ''} onChange={this.changeValue} disabled="" />
                                        </div>
                                    </div> */}
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Years of Experience</label>
                                            <input type="number" min="0" className="form-control" name="years_of_experience" value={this.state.fields.years_of_experience || ''} onChange={this.changeValue} />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Upload Image</label>
                                            <input name="profile_image" type="file" onChange={this.handleFileSelect} />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <h5 className="text-medium m-t-0 text-green m-b-15">Drivers License Info</h5>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Drivers License No</label>
                                            <input type="text" className="form-control" name="licence_number" value={this.state.fields.licence_number || ''} onChange={this.changeValue} placeholder="Enter Drivers License No" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Drivers License State</label>
                                            <select className="form-control" name="licence_state" value={this.state.fields.licence_state || 'Alaska'} onChange={this.changeValue}>
                                                {this.state.allStates.map((data, i) => {
                                                    return (
                                                        <option value={data.state} key={i}>{data.state}</option>)
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Drivers License Expiration Date</label>
                                            <div className="input-group date" id="license_exp_date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>

                                                <input type="text" className="form-control" name="license_exp_date" value={this.state.fields.license_exp_date || ''} onChange={this.changeValue} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Drivers License image</label>
                                            <input type="file" name="licence_image" onChange={this.handleFileSelect} />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <h5 className="text-medium m-t-0 text-green m-b-15">Passport Info</h5>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Passport Number</label>
                                            <input type="text" className="form-control" name="passport_no" value={this.state.fields.passport_no || ''} onChange={this.changeValue} placeholder="Enter Passport Number" disabled="" />

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Passport Expiration Date</label>
                                            <div className="input-group date" id="passport_exp_date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>

                                                <input type="text" className="form-control" name="passport_exp_date" value={this.state.fields.passport_exp_date || ''} onChange={this.changeValue} placeholder="Enter Passport Expiration Date" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Place of Birth</label>
                                            <input type="text" placeholder="Enter Place" name="place_of_birth" value={this.state.fields.place_of_birth || ''} onChange={this.changeValue} className="form-control" />

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Passport Image</label>
                                            <input type="file" name="passport_image" onChange={this.handleFileSelect} />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <h5 className="text-medium m-t-0 text-green m-b-15">Medical Info</h5>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Medical Card Issue Date</label>
                                            <div className="input-group date" id="medical_card_issue_date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control" name="medical_card_issue_date" value={this.state.fields.medical_card_issue_date || ''} onChange={this.changeValue} placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Medical Card Expiration Date</label>
                                            <div className="input-group date" id="medical_exam_exp_date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>

                                                <input type="text" className="form-control" name="medical_exam_exp_date" value={this.state.fields.medical_exam_exp_date || ''} onChange={this.changeValue} placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Medical Card Image</label>

                                            <input type="file" name="medical_card_image" onChange={this.handleFileSelect} />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <h5 className="text-medium m-t-0 text-green m-b-15">Background Check Info</h5>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Background check performed Date</label>
                                            <div className="input-group date" id="background_check_perform_date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control" name="background_check_perform_date" value={this.state.fields.background_check_perform_date || ''} onChange={this.changeValue} placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Background Check Completed</label>
                                            <div>
                                                <label className="radio-inline"><input type="radio" name="background_check_status" value="1" onChange={this.changeValue} checked={(this.state.fields.background_check_status == 1) ? true : false} /> Yes</label>
                                                <label className="radio-inline"><input type="radio" name="background_check_status" value="0" onChange={this.changeValue} checked={(this.state.fields.background_check_status == 0) ? true : false} /> No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Background Check Image</label>

                                            <input type="file" name="background_check_image" onChange={this.handleFileSelect} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label">Background check will be requested to Mover</label><br />

                                            <input type="text" name="background_check_requested_to" value={this.state.fields.background_check_requested_to || ''} onChange={this.changeValue} className="form-control" placeholder="Background check will be requested to Mover" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-footer text-right p-r-20">
                                {this.renderSubmitBtn()}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddDriver;