import React, { Component } from 'react';
import PostDataWithParam from '../../services/PostDataWithParam';
import { Redirect } from 'react-router-dom';
import { FormGroupMaterial } from '../../externaljs/FormGroupMaterial';
import PaypalExpressBtn from 'react-paypal-express-checkout';

import Urls from '../../externaljs/Urls';

class RegisterStepTwo extends Component {
    state = {
        fields: {
            contact_no: '',
            email: '',
            organization_name: '',
            password: '',
            profile_id: 2,
            card_name: '',
            card_no: '',
            expiry_month: '',
            expiry_year: '',
            cvv_no: '',
            amount: '',
            company_contact_no: '',
            first_name: '',
            last_name: '',
            subscription_id: 1,
            website_link: '',
            position: '',            
        },
        regErr: '',
        regStatus: false,
        processing: false,
        paypalProcessing: false
    }

    componentDidMount(){
        if (typeof this.props.location.state === 'undefined') {
            this.props.history.push('/home');
        }else{
            this.setState({fields: this.props.location.state}, () => {
                this.initCardForm();
            });
        }
        FormGroupMaterial();

        window.$('.btn-pay-thru-card').on('click', function(e){
            e.preventDefault();
            window.$('.choose-payment-method').toggleClass('pay-using-card');
        });

        window.$('.cancel-payment-method').on('click', function(e){
            e.preventDefault();
            window.$('.choose-payment-method').removeClass('pay-using-card');
        });
    }

    /**
     * Paypal express checkout
     */

    onSuccess = (payment) => {
        this.setState({ paypalProcessing: true }, () => {
            PostDataWithParam('UserRegistration', {...this.state.fields, transaction_id: payment.paymentID})
            .then((response) => {
                if (response.success == 1) {
                    this.setState({ regErr: response.msg, regStatus: true });
                } else {
                    this.setState({ regErr: response.msg , paypalProcessing: false, regStatus: false});
                }
            })
            .catch(err => {
                this.setState({ regErr: 'Oops! Something went wrong.', paypalProcessing: false, regStatus: false });
            })
        });        
    }

    onCancel = (data) => {
        this.setState({ paypalProcessing: false });
        window.swal('Cancelled!', 'Payment could not be processed.', 'warning');
    }

    onError = (err) => {
        this.setState({ paypalProcessing: false });
        window.swal('Error!', 'Payment could not be processed.', 'error');
    }

    /**
     * End of paypal
     */

    initCardForm(){
        window.$('.cardnnocheck').validateCreditCard(function (result) {

            if (result.card_type != null) {
                var card_icon = 'default.svg';
                if (result.card_type.name == 'amex') {
                    card_icon = 'amex.svg';
                } else if (result.card_type.name == 'diners_club_carte_blanche') {
                    card_icon = 'diners.svg';
                } else if (result.card_type.name == 'diners_club_international') {
                    card_icon = 'diners.svg';
                } else if (result.card_type.name == 'jcb') {
                    card_icon = 'jcb.svg';
                } else if (result.card_type.name == 'visa_electron') {
                    card_icon = 'visa.svg';
                } else if (result.card_type.name == 'visa') {
                    card_icon = 'visa.svg';
                } else if (result.card_type.name == 'mastercard') {
                    card_icon = 'mastercard.svg';
                } else if (result.card_type.name == 'discover') {
                    card_icon = 'discover.svg';
                } else if (result.card_type.name == 'maestro') {
                    card_icon = 'maestro.svg';
                }
                window.$('.card-icon img').attr('src', 'assets/images/card_icons_flat/' + card_icon);

            }
        });

        window.$('.cardnnocheck').on('input propertychange paste', function () {
            if (window.$('.cardnnocheck').val().length < 1) {
                window.$('.card-icon img').attr('src', 'assets/images/card_icons_flat/default.svg');
            }
        });

        window.$('a[rel=popover]').popover({
            html: true,
            trigger: 'hover',
            placement: 'left',
            content: function () {
                return '<img width="200" src="' + window.$(this).data('img') + '" />';
            }
        });
    }

    changeValue = (e) => {
        let fields = Object.assign({}, this.state.fields);
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields});
    }

    changeFormat = (e) => {

        let fields = this.state.fields;
        let value = e.target.value;
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
        var matches = v.match(/\d{4,16}/g);
        var match = matches && matches[0] || '';
        var parts = [];

        for (let i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4))
        }

        if (parts.length) {
            fields.card_no = parts.join(' ')
        } else {
            fields.card_no = value;
        }

        this.setState({ fields: fields });
    }

    handleAddMmc = (e) => {
        e.preventDefault();
        
        this.setState({ processing: true }, () => {
            PostDataWithParam('UserRegistration', this.state.fields)
                .then((response) => {
                    if (response.success == 1) {
                        this.setState({ regErr: response.msg, regStatus: true });
                    } else {
                        this.setState({ regErr: response.msg , processing: false, regStatus: false});
                    }
                })
                .catch(err => {
                    this.setState({ regErr: 'Oops! Something went wrong.', processing: false, regStatus: false });
                })
        })
    }

    renderSubmitBtn = () => {
        if(this.state.processing){
            return <input type="button" className="btn btn-primary" value="Processing..." disabled={true}/>
        }else{
            return <button type="submit" className="btn btn-primary">REGISTER</button>
        }
    }

    goBack = () => {
        let fields = Object.assign({}, this.state.fields);
        fields.cvv_no = '';

        this.props.history.push({
            pathname: '/registration-imn',
            state: fields
        });
    }

    showAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-danger"><i className="icon-cross position-left"></i>
                    {this.state.regErr}
                </div>
            )
        }
    }

    render() {
        if (this.state.regStatus) {
            return <Redirect to={{
                pathname: '/login',
                state: { regErr: this.state.regErr }
            }} />
        }

        return (
            <div className="login-page-register register-page">

                <div className="login-register-box">
                    <div className="text-center login-box-header">
                        <div className="m-b-30">
                            <img src="assets/images/MyRelo-logo-black.png" alt="site-logo"/>
                        </div>
                        {this.showAlert(this.state.regErr)}
                    </div>
                    <form action="" method="post" onSubmit={this.handleAddMmc}>
                        <div className="login-box-body p-l-20 p-r-20 m-t-30">
                            <div className="well p-b-10">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h6 className="m-t-0"><small className="text-uppercase text-muted">Plan Selected</small></h6>
                                        <h3 className="m-0 text-primary">
                                        {this.state.fields.subscription_id == 2 && "Monthly"}
                                        {this.state.fields.subscription_id == 3 && "Yearly"}
                                        {this.state.fields.subscription_id == 4 && "Lifetime"}
                                        </h3>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <h3 className="m-t-0 m-b-5">${this.state.fields.amount} USD</h3><p className="text-muted">per month</p>
                                    </div>
                                </div>
                            </div>

                            <div className="choose-payment-method">


                                {!this.state.paypalProcessing && 
                                    <div className="payment-option">
                                        <h6 className="text-center text-primary m-t-40 m-b-20">Choose Payment Method</h6>
                                        <div className="row">
                                            <div className="col-md-8 col-md-offset-2">
                                                {this.state.fields.subscription_id != 1 && 
                                                    <PaypalExpressBtn env={Urls.paypal.env} client={Urls.paypal.client} currency={Urls.paypal.currency}  style={{color:'blue', size:'responsive', label: 'paypal', tagline : false, shape: 'rect', layout : 'horizontal'}} total={parseInt(this.state.fields.amount)} onError={this.onError} onSuccess={this.onSuccess} onCancel={this.onCancel} />
                                                }
                                            </div>
                                            <div className="col-md-8 col-md-offset-2">
                                                <a href="" className="btn btn-primary btn-block m-t-20 btn-pay-thru-card">Pay Using Card</a>
                                            </div>
                                        </div>
                                        <div className="m-t-20 text-center">
                                            {!this.state.processing && <a href="javascript:void(0);" className="" onClick={this.goBack}><i className="icon-arrow-left8"></i> &nbsp; Back to previous</a>}
                                        </div>
                                    </div>
                                }

                                {this.state.paypalProcessing && 
                                    <h3 className="text-center text-default m-t-40 m-b-20">Processing...</h3>
                                }
                                
                                <div className="card-payment">
                                    <h6 className="text-center text-primary m-t-40 m-b-20">Pay Using Credit/Debit Card</h6>
                                    <div className="form-group form-group-material">
                                        <label className="control-label">Name Of Card</label>
                                        <input type="text" className="form-control f-s-14" placeholder="Name Of Card" name="card_name" value={this.state.fields.card_name} onChange={this.changeValue} required/>
                                    </div>
                                    <div className="form-group form-group-material ">
                                        <label className="control-label">Card No</label>
                                        <input type="text" className="form-control cardnnocheck f-s-14" placeholder="Card No" name="card_no" value={this.state.fields.card_no} onChange={this.changeFormat} required/>
                                        <div className="card-icon">
                                            <img src="assets/images/card_icons_flat/default.svg"/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group form-group-material">
                                                <label className="control-label">Expiry Month</label>
                                                <select className="form-control f-s-14"  name="expiry_month" value={this.state.fields.expiry_month} onChange={this.changeValue} required>
                                                    <option value="">Expiry Month</option>
                                                    <option value="01">Jan</option>
                                                    <option value="02">Feb</option>
                                                    <option value="03">Mar</option>
                                                    <option value="04">Apr</option>
                                                    <option value="05">May</option>
                                                    <option value="06">Jun</option>
                                                    <option value="07">Jul</option>
                                                    <option value="08">Aug</option>
                                                    <option value="09">Sep</option>
                                                    <option value="10">Oct</option>
                                                    <option value="11">Nov</option>
                                                    <option value="12">Dec</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group form-group-material">
                                                <label className="control-label">Expiry Year</label>
                                                <select className="form-control f-s-14"  name="expiry_year" value={this.state.fields.expiry_year} onChange={this.changeValue} required>
                                                    <option value="">Expiry Year</option>
                                                    <option value="2019">2019</option>
                                                    <option value="2020">2020</option>
                                                    <option value="2021">2021</option>
                                                    <option value="2022">2022</option>
                                                    <option value="2023">2023</option>
                                                    <option value="2024">2024</option>
                                                    <option value="2025">2025</option>
                                                    <option value="2026">2026</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group form-group-material">
                                                <label className="control-label">CVV</label>
                                                <input type="password" className="form-control f-s-14" placeholder="CVV" maxLength="3" name="cvv_no" value={this.state.fields.cvv_no} onChange={this.changeValue} required/>
                                                <div className="info-cvv">
                                                    <a className="text-muted" rel="popover" data-img="assets/images/card_icons_flat/security-code.svg"><i className="fa fa-info-circle"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group m-t-40 clearfix text-center">
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                {this.renderSubmitBtn()}
                                            </div>
                                            <div className="col-md-12 m-t-20 text-center">
                                                <a href="" className="cancel-payment-method">Choose another payment method</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default RegisterStepTwo;