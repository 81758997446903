// import { Editor } from '@tinymce/tinymce-react';
import React, { Component } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Link } from 'react-router-dom';
import Breadcumb from '../externaljs/Breadcrumb';
import ConfirmBox from '../externaljs/ConfirmBox';
import DateFormat from '../externaljs/DateFormat';
import { FileIcon, FileSvg, FileType } from '../externaljs/FileType';
import Storage from '../externaljs/Storage';
import Urls from '../externaljs/Urls';
import FileUploadWithProgress from "../services/FileUploadWithProgress";
import GetData from '../services/GetData';
import PostDataFile from '../services/PostDataFile';
import PostDataWithParam from '../services/PostDataWithParam';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';

const orderType = {
    myorders: "/myorders",
    offersreceived: "/offersreceived",
    offersposted: "/offersposted"
}
let setExistingProModal = true

export class OrderDetails extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Jobs', link: '/independent-mover/dashboard/manage-orders', class: '' },
            { title: 'Proposal Details', link: '', class: 'active' }
        ],
        ssn: "",
        id: '',
        pathname: '',
        type: '',
        file_group: 1,
        files: [],
        filesModal: [],
        filesView: [],
        details: {
            request_start_date: '',
            request_end_date: '',
            booked: {
                helper: [],
                driver: [],
                mover: [],
                packer: [],
                thirdparty: [],
                claimrepair: []
            },
            invited: {
                helper: [],
                driver: [],
                mover: [],
                packer: [],
                thirdparty: [],
                claimrepair: []
            },
            offer_from: {
                contact_no: "",
                email: "",
                organization_name: ""
            },
            cya_video: [],
            admin_details: {
                admin_name: "",
                admin_email: "",
                admin_user_id: ""
            },
            total_invited_and_booked_users_list: [],
            total_assigned_staffs_list: []
        },
        tmpUploadedFiles: [],
        file_group: 1,
        files: [],
        conversationHelperList: [],
        conversationDriverList: [],
        conversationMoverList: [],
        conversationAdminList: [],
        conversationCustomerList: [],
        conversationPeopleList: [],
        message: '',
        attachment: [],
        order_type: '',
        subscription_id: 0,
        proccesing: false,
        loading: false,
        helper_details: {
            address_type: '',
            address: ''
        },
        mover_details: {
            address_type: '',
            address: ''
        },
        driver_details: {
            address_type: '',
            address: ''
        },
        ratings: [],
        order_request_detail_id: 0,
        tracking_list: [],
        tempOrder: {},
        tempTrackOrder: [],
        tracking_nxt_btn: 1,
        chooseAddressModal: '',
        form_data: {
            services_needed: [],
            uploadFileName: '',
            uploadFileDesc: '',
            id: '',
            previewURL: null,
            file_type: '',
            uploadOfferFiles: []
        },

        temp_services_needed: [],
        search_loading: false,
        search_driver_list: [],
        search_packer_list: [],
        custom_dept_list: [],
        search_staff_list: [],
        chk_drivers: [],
        search_driver_fields: {
            name: '',
            email: '',
            contact_no: ''
        },
        search_packer_fields: {
            name: '',
            email: '',
            contact_no: ''
        },
        search_staff_fields: {
            name: '',
            email: '',
            contact_no: ''
        },
        selectedProviders: [],
        serviceNeededForMyTeam: [],
        selectedPackers: [],
        services_needed_array: [],
        request_from: '',
        shareFile: [],
        shareFileDetails: {},
        mover_flag: 0,
        driver_flag: 0,
        helper_flag: 0,
        customer_flag: 0,
        thirdparty_flag: 0,
        packer_flag: 0,
        claimrepair_flag: 0,
        document_id: 0,
        sharedFileWith: [],
        services_provided_list: [],
        modalLoader: false,
        temp_mover_id: '',
        fields: {
            logo: '',
            organization_name: '',
            doing_business_as_dba: '',
            year_founded: '',
            website_link: '',
            company_contact_no: '',
            company_fax_no: '',
            operation_email: '',
            sales_email: '',
            customer_service_email: '',
            driver_and_team_recruitment: '',
            claim_email: '',
            after_hours_staff_name: '',
            after_hours_contact_phone: '',
            radius_of_quality_service: '',
            management_contact_name: '',
            management_title: '',
            company_email: '',
            contact_no: '',
            military_approved_warehouse: '',
            certified_truck_scale_on_premises: '',
            warehouse: '',
            warehouse_size: '',
            climatized: '',
            climatized_size: '',
            air_conditioned: '',
            air_conditioned_size: '',
            pre_move_survey: '',
            packing: '',
            crating: '',
            short_hauling_hhg: '',
            long_hauling_hhg: '',
            freight_hauling: '',
            storage: '',
            intermodal: '',
            day_road_labor: '',
            sit_approved: '',
            international: '',
            last_mile_delivery: '',
            freight_warehouse: '',
            third_party_services: '',
            secured_parking_lot: '',
            tractors: '',
            trailers: '',
            straight_trucks: '',
            pack_vans: '',
            cdl_drivers: '',
            non_cdl_drivers: '',
            packers: '',
            loaders: '',
            primary_street_address: '',
            primary_address_other_info: '',
            secondary_street_address: '',
            secondary_address_other_info: '',
            primary_zip_code: '',
            secondary_zip_code: '',
            services_provided: [],
            primary_country: '',
            secondary_country: '',
            temp_helper_id: '',
            user_detail: {
                available_services: []
            }
        },
        total_Msg: {
            helperTotalMsg: 0,
            moverTotalMsg: 0,
            driveTotalMsg: 0,
            adminTotalMsg: 0,
            customerTotalMsg: 0
        },
        msg_count_user_id: '',
        msg_count_user_flag: '',
        cya_video_path: '',
        staff_user_id: '',
        custom_menu: [],
        permission: true,
        show_driver: true,
        show_packer: false,
        show_staff: false,
        activeStaffId: 0,
        tabid: 'order-summary',
        booked_pros: [],
        invited_pros: [],
        allmessagelist: [],
        conversation_id: '',
        full_name: '',
        //for inventory items
        showInventoryTab: false,
        showMoverTab: false,
        showFilesTab: true,
        showMoverNote: false,
        showArchiveTab: false,
        helper_address_err: '',
        helper_serv_err: '',
        editPermission: true,
        isProccesingFileUpload: false,
        uploadPercentage: 0,
        isFileSaving: false,
        profileShowModal: false,
        allmaininventory: [],
        showInventoryItems: false,
        total_estimated_weight: 0,
        total_cubic_feet: 0,
        get_mover_note: '',
        get_mover_fromDate: '',
        get_mover_toDate: '',
        booked_driver_user_ids: [],
        loadUserLocation: true,
        locationData: [],
        trackLoading: false,
        processingDeclineStaff: false
    }
    database = null;
    subscriptionCheckInterval = null;

    componentDidMount() {
        this.loadOrderDetails()
    }

    loadOrderDetails() {

        if (this.props.location.state) {
            this.setState({ id: this.props.location.state.id, type: this.props.location.state.type, request_from: !!this.props.location.state.request_from ? this.props.location.state.request_from : 'own' }, () => {
                if (this.state.type == 'accepted') {
                    this.setState({ order_type: 1 }, () => {
                        this.getConversationMoverList();
                        this.getConversationHelperList();
                        this.getConversationAdminList();
                        this.getConversationDriverList();
                        this.getConversationCustomerList();
                    });
                } else {
                    this.setState({ order_type: 2 }, () => {
                        this.getConversationMoverList();
                        this.getConversationHelperList();
                        this.getConversationAdminList();
                        this.getConversationDriverList();
                        this.getConversationCustomerList();
                    });
                }

                this.orderDetails();
                // this.renderInventoryItems();
                // this.GetMoverNote();
                this.getFiles();
                this.database = Urls.firebase.database();
                this.registerNotifications();
                this.getShareFileStatus(this.props.location.state.id);

            })
        } else {
            this.props.history.goBack();
        }

        this.GetUserInfo();
        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        let custom_menu = [];
        if (staff_user_id != "") {
            custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
            for (var i = 0; i < custom_menu.length; i++) {
                if (custom_menu[i].title == "ReloOrders") {
                    if (custom_menu[i].is_add == 1 || custom_menu[i].is_add == "1")
                        this.setState({ permission: true }, () => {
                            // console.log(this.state.permission);
                        })
                    else {
                        this.setState({ permission: false })
                    }
                }
            }
        }
        this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
            // console.log(this.state.custom_menu);
            // console.log(this.state.staff_user_id);
        });

        this.subscriptionCheckInterval = setInterval(() => {
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1)
                this.setState({ editPermission: false })
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ editPermission: true })
        }, 1000);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.state) {
            if (this.props.location.state.id !== prevProps.location.state.id) {
                this.loadOrderDetails()
            }
        }

    }

    componentWillUnmount() {
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
    }

    doSubscribe = (e) => {
        window.swal({
            title: "Warning!",
            text: "Please upgrade your membership to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }

    handleClickActiveTab = (status) => {
        this.setState({ tabid: status }, () => {
            window.$('html, body').animate({
                scrollTop: window.$("#" + status).offset().top
            }, 1000);
        });
    }
    openConversationModal = () => {
        window.$("#conversationModal").show();
    }
    openMessageModal = (e, user_id, full_name) => {
        e.preventDefault();
        this.setState({ conversation_id: user_id, full_name: full_name });
        window.$("#messageModal").modal('show');
        console.log('sdsadsadsaf');
        this.getAllMessages(user_id);
    }
    getAllMessages(user_id) {

        this.setState({ allmessagelist: [] }, () => {
            console.log(user_id);
            PostDataWithParam('GetOrderConversationsMsg', { order_request_detail_id: this.state.id, conversations_user_id: user_id })
                .then(response => {
                    if (response.success == 1) {
                        this.setState({ allmessagelist: response.data }, () => {
                            //   this.messagelistindividual(this.state.allmessagelist);

                        });
                    } else {

                    }
                })
                .catch(err => {

                })
        })
    }
    messagelistindividual(messages) {
        if (messages.length > 0) {
            let msg = messages.map((item, i) => {
                return (
                    <React.Fragment key={'message-' + i}>
                        <div className="message-body clearfix">
                            <div className="media m-b-15">
                                <span className="media-left p-r-15">
                                    <span className={((item.conversations_user_id == (JSON.parse(Storage.get('imnLoginData'))).id) ? "bg-primary-400" : "bg-warning") + " btn-rounded btn-icon text-uppercase"}>{item.full_name.substr(0, 1)}</span>
                                </span>
                                <div className="media-body">
                                    <span className="media-heading text-semi-bold">{item.full_name} </span>
                                    <div className="text-mid-grey f-s-13">
                                        {DateFormat.getFormattedDateTime(item.msg_time, 'm-d-Y,h:i')}
                                    </div>
                                </div>
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: item.message }}></p>
                            <div className="attachedment-files">
                                <ul className="file-list">
                                    {item.quotetion_files.length > 0 && item.quotetion_files.map((file, j) => {
                                        return (
                                            <li key={'file-' + j}>
                                                <a href={file.file_path || 'javascript:void(0)'}><i className={FileIcon(this.getExtension(file.file_name)) + " position-left text-primary"}></i>{file.file_name}</a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        {(i < (messages.length - 1)) && <hr />}
                    </React.Fragment>
                )
            });

            return msg;
        }
    }
    handleMessageChange(e) {
        this.setState({ message: e.target.getContent() })
    }
    sendMessageIndividual = (e, conversations_user_id) => {
        e.preventDefault();
        // if (convType == TEAM_CONVERSATION) return;
        let formData = new FormData();
        if (this.state.attachment.length > 0) {
            for (let i = 0; i < this.state.attachment.length; i++) {
                formData.append('file_name[' + i + ']', this.state.attachment[i], this.state.attachment[i].name);
            }
        }
        formData.append('order_request_detail_id', this.state.id);
        formData.append('conversations_user_id', conversations_user_id);
        formData.append('message', this.state.message);

        PostDataFile('SentOrderConversationsMsg', formData)
            .then(response => {
                if (response.success == 1) {
                    window.swal('Success!', response.msg, 'success');
                    this.setState({ message: '', attachment: [] }, () => {
                        this.state['activeEditor'].setContent('');
                        // this.getConversationPeopleList(1, convType, () => {
                        this.getAllMessages(conversations_user_id);
                        // });
                    })
                } else {
                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => {

            })
    }
    renderMessageIndividual() {
        return (
            <div className="conversation">
                <div id='conversations_people'>
                    <h3 className="p-l-20 p-r-20 m-0">{this.state.full_name}</h3>
                </div>
                <div id="messagebody" className="">
                    <div className="p-20 editor-box">
                        <form onSubmit={(e) => this.sendMessageIndividual(e, this.state.conversation_id)}>
                            <div className="form-group">
                                {/* <Editor
                                    placeHolder="Write Something ..."
                                    initialValue=""
                                    init={{
                                        setup: editor => {
                                            this.setState({ ['activeEditor']: editor })
                                        },
                                        menubar: false,
                                        statusbar: false,
                                        toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                    }}
                                    onChange={this.handleMessageChange.bind(this)}
                                    className="form-control textarea-editor"
                                    name="qutationMessage"
                                    id='qutationMessage'
                                /> */}
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="file-label">
                                        <input className="file-input" name="resume" type="file" onChange={this.handleFileChange.bind(this)} multiple={true} />                                        <span className="text-muted">
                                            <i className="icon icon-attachment"></i> Attach File
                                        </span>
                                    </label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <hr className="m-t-0 m-b-0" />
                    <div className="p-20">
                        {this.messagelistindividual(this.state.allmessagelist)}

                        {/* <div className="message-body clearfix">
                            <div className="media m-b-15">
                                <span className="media-left p-r-15">
                                    <span className="bg-primary-400 btn-rounded btn-icon text-uppercase">M</span>
                                </span>
                                <div className="media-body">
                                    <span className="media-heading text-semi-bold">Moving APT</span>
                                    <div className="text-mid-grey f-s-13">
                                        sep 19, 2020, 3:40 PM
                                </div>
                                </div>
                            </div>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            <div className="attachedment-files">
                                <ul className="file-list">
                                    <li>
                                        <a href='javascript:void(0);'><i className="position-left text-primary"></i>image-file-1248</a>
                                    </li>
                                    <li>
                                        <a href='javascript:void(0);'><i className="position-left text-primary"></i>zip-file-1248</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        */}
                    </div>
                </div>
            </div>




        )
    }
    existingDriverModal = () => {
        this.setState({ selectedProviders: [], show_packer: false, show_driver: true, show_staff: false, activeStaffId: 0 })
        this.searchDriver();
        // this.searchPacker();
        PostDataWithParam('DepertmentsListOfStaff', {})
            .then(response => {
                if (response.success == 1) {
                    this.setState({ custom_dept_list: response.data })
                }
                else {
                    this.setState({ custom_dept_list: [] })
                }
            })
            .catch(err => {
                this.setState({ custom_dept_list: [] })
            })
        window.$('#addExistingDriverModal').modal('show');
    }
    handleOnChangeSearchDriver = (e) => {
        let search_driver_fields = this.state.search_driver_fields;
        search_driver_fields[e.target.name] = e.target.value;
        if (e.target.name == 'contact_no') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                search_driver_fields[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
        }
        this.setState({ search_driver_fields: search_driver_fields });
    }
    searchDriver = () => {
        let search_driver_fields = this.state.search_driver_fields;

        this.setState({ search_loading: true }, () => {
            PostDataWithParam('SearchDriverForCreateOrder', {
                ...search_driver_fields,
                order_request_detail_id: this.state.id
            })
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ search_driver_list: response.result, search_loading: false, show_packer: false, show_driver: true, show_staff: false });
                    } else {
                        this.setState({ search_driver_list: [], search_loading: false, show_packer: false, show_driver: true })
                    }
                })
                .catch(() => {
                    this.setState({ search_driver_list: [], search_loading: false, show_packer: false, show_driver: true })
                }).finally(() => {
                    this.setState({ serviceNeededForMyTeam: [], selectedProviders: [] })
                })
        })
    }

    searchPacker = () => {
        let search_packer_fields = this.state.search_packer_fields;

        this.setState({ search_loading: true }, () => {
            PostDataWithParam('SearchPackerForCreateOrder', {
                ...search_packer_fields,
                order_request_detail_id: this.state.id
            })
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ search_packer_list: response.result, search_loading: false, show_driver: false, show_packer: true, show_staff: false });
                    } else {
                        this.setState({ search_packer_list: [], search_loading: false })
                    }
                })
                .catch(() => {
                    this.setState({ search_packer_list: [], search_loading: false })
                })
                .finally(() => {
                    this.setState({ serviceNeededForMyTeam: [], selectedProviders: [] })
                })
        })
    }

    searchStaff = (e, id) => {
        let search_staff_fields = this.state.search_staff_fields;

        this.setState({ search_loading: true, activeStaffId: id }, () => {
            PostDataWithParam('DepartmentWiseStaffsList', {
                custom_profile_id: id
            })
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ search_staff_list: response.data, search_loading: false, show_driver: false, show_packer: false, show_staff: true });
                    } else {
                        this.setState({ search_staff_list: [], search_loading: false })
                    }
                })
                .catch(() => {
                    this.setState({ search_staff_list: [], search_loading: false })
                })
                .finally(() => {
                    this.setState({ serviceNeededForMyTeam: [], selectedProviders: [] })
                })
        })
    }


    getDriverInfo = (id) => {
        this.setState({ search_loading: true }, () => {
            PostDataWithParam('GetDriverDetails', { driver_id: id })
                .then((response) => {
                    if (response.success === 1) {
                        let form_data = this.state.form_data;

                        form_data.driver_email = response.data[0].email;
                        form_data.driver_first_name = response.data[0].first_name;
                        form_data.driver_last_name = response.data[0].last_name;
                        form_data.driver_mobile_number = response.data[0].contact_no;

                        this.setState({ form_data: form_data, search_loading: false, show_packer: false, show_driver: true }, () => {
                            window.$('#addExistingDriverModal').modal('hide');


                        });
                    } else {
                        this.setState({ search_loading: false })
                        window.swal('Error!', 'Something went wrong.', 'error')
                    }
                })
                .catch(err => {
                    this.setState({ search_loading: false })
                    window.swal('Error!', 'Something went wrong.', 'error')
                })
        })
    }

    chkDrivers = (e, profile_id, chk_id) => {
        let selectedProviders = this.state.selectedProviders;

        if (e.target.checked) {
            selectedProviders.push({ user_id: e.target.value, name: e.target.name, profile_id: profile_id })
        } else {
            for (let i = 0; i < selectedProviders.length; i++) {
                if (selectedProviders[i].user_id === e.target.value) {
                    selectedProviders.splice(i, 1);
                    break;
                }
            }
        }

        this.setState({ selectedProviders: selectedProviders });
    }
    chkPacker = (e, profile_id, chk_id) => {
        let selectedProviders = this.state.selectedProviders;

        if (e.target.checked) {
            selectedProviders.push({ user_id: e.target.value, name: e.target.name, profile_id: profile_id })
        } else {
            for (let i = 0; i < selectedProviders.length; i++) {
                if (selectedProviders[i].user_id === e.target.value) {
                    selectedProviders.splice(i, 1);
                    break;
                }
            }
        }

        this.setState({ selectedProviders: selectedProviders });
        // let selectedPackers = this.state.selectedPackers;

        // if (e.target.checked) {
        //     selectedPackers.push({ user_id: e.target.value, name: e.target.name, profile_id: profile_id })
        // } else {
        //     for (let i = 0; i < selectedPackers.length; i++) {
        //         if (selectedPackers[i].user_id === e.target.value) {
        //             selectedPackers.splice(i, 1);
        //             break;
        //         }
        //     }
        // }

        // this.setState({ selectedPackers: selectedPackers });
    }

    assignDriver = () => {
        let flag = this.state.type == "accepted" ? 'outsource' : '';
        this.setState({ search_loading: true }, () => {
            PostDataWithParam('AssignReloHelpersToExistingJob', {
                order_request_id: this.state.id,
                flag: flag,
                driver_helper_mover: JSON.stringify(this.state.selectedProviders),
                driver_flag: 'driver',
                services_needed: JSON.stringify(this.state.serviceNeededForMyTeam.map((item) => ({ name: item })))
            })
                .then((response) => {
                    if (response.success == 1) {
                        window.$('#addExistingDriverModal').modal('hide');

                        this.orderDetails();
                        this.setState({ search_loading: true, show_packer: false, show_driver: true });
                        window.swal('Success!', response.msg, 'success')
                        let flag = (this.state.type == "outsource") ? 'accepted' : '';
                        this.props.history.push({
                            pathname: '/independent-mover/dashboard/order-details',
                            state: { type: flag, id: this.state.id, request_from: this.state.request_from }
                        });
                    } else {
                        window.$('#addExistingDriverModal').modal('hide');

                        this.setState({ search_loading: true, show_packer: false, show_driver: true });
                        window.swal('Error!', response.msg, 'error');
                    }
                })
                .catch(err => {
                    window.$('#addExistingDriverModal').modal('hide');
                    this.setState({ search_loading: true, show_packer: false, show_driver: true });
                })
        });
    }

    assignStaff = () => {
        let flag = this.state.type == "accepted" ? 'outsource' : '';
        this.setState({ search_loading: true }, () => {
            PostDataWithParam('AssignStaffsInOrder', {
                order_request_id: this.state.id,
                flag: flag,
                driver_helper_mover: JSON.stringify(this.state.selectedProviders),
                services_needed: JSON.stringify(this.state.serviceNeededForMyTeam.map((item) => ({ name: item })))
            })
                .then((response) => {
                    if (response.success == 1) {
                        window.$('#addExistingDriverModal').modal('hide');

                        this.orderDetails();
                        this.setState({ search_loading: true, show_packer: false, show_driver: false, show_staff: true });
                        window.swal('Success!', response.msg, 'success')
                        let flag = (this.state.type == "outsource") ? 'accepted' : '';
                        this.props.history.push({
                            pathname: '/independent-mover/dashboard/order-details',
                            state: { type: flag, id: this.state.id, request_from: this.state.request_from }
                        });
                    } else {
                        window.$('#addExistingDriverModal').modal('hide');

                        this.setState({ search_loading: true, show_packer: false, show_driver: false, show_staff: true });
                        window.swal('Error!', response.msg, 'error');
                    }
                })
                .catch(err => {
                    window.$('#addExistingDriverModal').modal('hide');
                    this.setState({ search_loading: true, show_packer: false, show_driver: false, show_staff: true });
                })
        });
    }

    getServices = (services, e) => {
        e.preventDefault();
        this.setState(
            {
                temp_services_needed: services
            }
        );
    }
    handleFileCheck = (file) => {
        if (file !== '') {
            return (
                <span className="thumb-60" style={{ background: "url(" + this.state.fields.logo + ") no-repeat center center / 100%" }}></span>
            )
        } else {
            return (
                <span className="thumb-60" style={{ background: 'url(assets/images/default_avatar.jpg) no-repeat center center / 100%' }}></span>
            )
        }
    }
    GetUserInfo = () => {
        GetData('GetUserInfo', {})
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ subscription_id: response.data.subscription_id });

                    window.$('[data-toggle=tooltip]').tooltip();
                }
            })
            .catch(err => { })
    }

    redirectSubscription = () => {
        window.$('#subscribeToPremiumModal').modal('show');
    }

    /* Start Chat */
    initChatBox(user_id, name, order_id, profile_image, e) {
        e.preventDefault();
        this.props.openChatBox({
            chat_box_id: `${order_id}_${user_id}`,
            chat_info: {
                order_id,
                order_no: this.state.details.order_id,
                receiver_info: {
                    user_id,
                    name,
                    profile_image
                }
            }
        })
    }
    /* End Chat */

    registerNotifications() {
        this.database.ref('/notifications/order_conversations_message/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '_' + this.state.id)
            .on('child_added', (snapshot) => {

                let tempData = snapshot.val();
                let conversationHelperList = this.state.conversationHelperList;
                let conversationDriverList = this.state.conversationDriverList;
                let conversationMoverList = this.state.conversationMoverList;
                let conversationAdminList = this.state.conversationAdminList;
                let conversationCustomerList = this.state.conversationCustomerList;

                let tempListH = conversationHelperList.map((itemH, iH) => {
                    if (itemH.conversations_user_id == tempData.sender_user_id) {
                        let tempItemH = itemH;
                        tempItemH.msgCount++;
                        tempItemH.nodeUrl = '/notifications/order_conversations_message/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '_' + this.state.id;
                        return tempItemH;
                    } else {
                        return itemH;
                    }
                });

                let tempListD = conversationDriverList.map((itemD, iD) => {
                    if (itemD.conversations_user_id == tempData.sender_user_id) {
                        let tempItemD = itemD;
                        tempItemD.msgCount++;
                        tempItemD.nodeUrl = '/notifications/order_conversations_message/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '_' + this.state.id;
                        return tempItemD;
                    } else {
                        return itemD;
                    }
                });

                let tempListM = conversationMoverList.map((itemM, iM) => {
                    if (itemM.conversations_user_id == tempData.sender_user_id) {
                        let tempItemM = itemM;
                        tempItemM.msgCount++;
                        tempItemM.nodeUrl = '/notifications/order_conversations_message/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '_' + this.state.id;
                        return tempItemM;
                    } else {
                        return itemM;
                    }
                });

                let tempListA = conversationAdminList.map((itemA, iA) => {
                    if (itemA.conversations_user_id == tempData.sender_user_id) {
                        let tempItemA = itemA;
                        tempItemA.msgCount++;
                        tempItemA.nodeUrl = '/notifications/order_conversations_message/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '_' + this.state.id;
                        return tempItemA;
                    } else {
                        return itemA;
                    }
                });
                let tempListC = conversationCustomerList.map((itemA, iA) => {
                    if (itemA.conversations_user_id == tempData.sender_user_id) {
                        let tempItemA = itemA;
                        tempItemA.msgCount++;
                        tempItemA.nodeUrl = '/notifications/order_conversations_message/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '_' + this.state.id;
                        return tempItemA;
                    } else {
                        return itemA;
                    }
                });
                if (this.state.msg_count_user_id != '' && this.state.msg_count_user_flag != '') {
                    this.getMessages(this.state.msg_count_user_id, this.state.msg_count_user_flag, '', '', '', null);
                    if (this.state.msg_count_user_flag == 'customer') {

                        tempListC[0].msgCount = 0;
                    }
                    else if (this.state.msg_count_user_flag == 'mover') {
                        tempListM[0].msgCount = 0;
                    }
                    else if (this.state.msg_count_user_flag == 'helper') {
                        tempListH[0].msgCount = 0;
                    }
                    else if (this.state.msg_count_user_flag == 'driver') {
                        tempListD[0].msgCount = 0;
                    }
                    else if (this.state.msg_count_user_flag == 'admin') {
                        tempListA[0].msgCount = 0;
                    }
                }
                this.setState({ conversationAdminList: tempListA, conversationHelperList: tempListH, conversationDriverList: tempListD, conversationMoverList: tempListM, conversationCustomerList: tempListC });
            })
    }

    orderDetails() {
        this.setState({ loading: true }, () => {
            PostDataWithParam('GetOrderDetailsForMovers', { order_id: this.state.id, type: this.state.type })
                .then(response => {
                    if (response.success == 1) {
                        // console.log('DATA: ', response.data[0]);
                        let ratings = this.state.ratings;
                        let details = response.data[0];
                        let total_estimated_weight = details.total_estimated_weight || this.state.total_estimated_weight;
                        let total_cubic_feet = details.total_cubic_feet || this.state.total_cubic_feet;

                        let booked_helper_user_ids = response.data[0].booked.helper.map(item => item.user_id)
                        let booked_driver_user_ids = response.data[0].booked.driver.map(item => item.user_id)
                        let booked_mover_user_ids = response.data[0].booked.mover.map(item => item.user_id)

                        booked_driver_user_ids = booked_driver_user_ids.concat(booked_helper_user_ids, booked_mover_user_ids)
                        this.setState({ booked_driver_user_ids, locationData: [] }, () => {
                            // console.log('booked', this.state.booked_driver_user_ids);
                        })

                        this.setState({ details: response.data[0], total_cubic_feet, total_estimated_weight, loading: false }, () => {
                            this.getShareFileDetails();
                            this.ssnFormat(this.state.details.ssn);
                            if (this.props.location.state
                                && this.props.location.state.from
                                && this.props.location.state.from === "CREATE_OFFER" && setExistingProModal
                            ) {
                                this.existingDriverModal();
                                setExistingProModal = false
                            }
                        })
                        let services_needed_array = this.state.details.services_needed.split(",");

                        if (this.state.details.total_invited_and_booked_users_list && this.state.details.total_invited_and_booked_users_list.length > 0) {
                            // this.state.booked_pros.push(this.state.details.total_invited_and_booked_users_list)
                            this.state.details.total_invited_and_booked_users_list.map((item, i) => {
                                let rating_obj = {
                                    review_to_user_id: item.user_id,
                                    rate: '',
                                    public_review: '',
                                    private_review: ''
                                }
                                ratings.push(rating_obj)
                            })
                        }

                        this.setState({ services_needed_array: services_needed_array, ratings: ratings, order_request_detail_id: response.data[0].id }, () => {

                        })

                    } else {
                        this.setState({
                            details: {
                                booked: [],
                                invited: [],
                                cya_video: []

                            }, loading: false,

                        })
                    }
                })
                .catch(err => {
                    this.setState({
                        details: {
                            booked: [],
                            invited: [],
                            cya_video: []
                        }, loading: false,

                    })
                })
        })
    }

    goBack(e) {
        e.preventDefault();
        this.props.history.push({
            pathname: this.state.pathname
        })
    }

    /**
     * Files
     */

    setFileGroup(file_group, e) {
        e.preventDefault();
        this.setState({ file_group: file_group, showInventoryTab: false, showFilesTab: true, showMoverTab: false, showArchiveTab: false }, () => {
            this.getFiles();
        });
    }

    getFiles() {
        PostDataWithParam('GetOfferFiles', { order_request_detail_id: this.state.id, file_group: this.state.file_group })
            .then(response => {
                if (response.success == 1) {
                    this.setState({ files: response.data, proccesing: false, showFilesTab: true, showMoverTab: false, showArchiveTab: false, showInventoryTab: false });
                } else {
                    this.setState({ files: [], proccesing: false, showFilesTab: true, showMoverTab: false, showArchiveTab: false, showInventoryTab: false });
                }
            })
            .catch(err => {
                this.setState({ files: [], proccesing: false, showFilesTab: true, showMoverTab: false, showArchiveTab: false, showInventoryTab: false });
            });
    }

    // uploadFiles(e) {
    //     if (e.target.files.length > 0) {
    //         let formData = new FormData();
    //         for (let i = 0; i < e.target.files.length; i++) {
    //             formData.append('request_files[' + i + ']', e.target.files[i], e.target.files[i].name);
    //         }
    //         formData.append('hire_by_profile_id', this.state.details.hire_by_profile_id);
    //         formData.append('file_group', this.state.file_group);
    //         formData.append('order_request_detail_id', this.state.id);
    //         this.setState({ proccesing: true }, () => {
    //             PostDataFile('UploadOfferFiles', formData)
    //                 .then(response => {
    //                     if (response.success == 1) {
    //                         window.swal('Success!', response.msg, 'success');
    //                         this.getFiles();
    //                     } else {
    //                         window.swal('Error!', response.msg, 'error')
    //                     }
    //                 })
    //                 .catch(err => {

    //                 });
    //         });
    //     }
    // }

    // getFileName(file) {
    //     let files = file.request_file.split('/');
    //     return files[files.length - 1];
    // }

    // deleteFile(id, e) {
    //     e.preventDefault();
    //     ConfirmBox("You want to delete this file!")
    //         .then((result) => {
    //             if (result) {
    //                 PostDataWithParam('DeleteOfferFiles', { id: id })
    //                     .then((response) => {
    //                         if (response.success == 1) {
    //                             window.swal('Deleted!', response.msg, 'success')
    //                             this.getFiles();
    //                         }
    //                     })
    //                     .catch(err => {

    //                     })
    //             }
    //         })
    // }

    // shareWith = (e) => {
    //     e.preventDefault();
    //     let shareFile = this.state.shareFile;

    //     if (shareFile.indexOf(e.target.value) > -1) {
    //         let temp = shareFile.filter((item, i) => {
    //             if (item == e.target.value && e.target.checked == false)
    //                 return false;
    //             else
    //                 return true;
    //         });
    //         shareFile = temp;
    //     } else {
    //         shareFile.push(e.target.value);
    //     }
    //     this.setState({ shareFile: shareFile });
    // }

    // submitShareWith = (e) => {
    //     e.preventDefault();
    //     this.state.mover_flag = 0;
    //     this.state.helper_flag = 0;
    //     this.state.driver_flag = 0;
    //     this.state.customer_flag = 0;
    //     this.state.thirdparty_flag = 0;
    //     this.state.packer_flag = 0;
    //     this.state.claimrepair_flag = 0;
    //     this.state.shareFile.filter(item => {
    //         if (item == 'mover') {
    //             this.state.mover_flag = 1;
    //         } else if (item == 'helper') {
    //             this.state.helper_flag = 1;
    //         }
    //         else if (item == 'driver') {
    //             this.state.driver_flag = 1;
    //         }
    //         else if (item == 'customer') {
    //             this.state.customer_flag = 1;
    //         }
    //         else if (item == '3rd party') {
    //             this.state.thirdparty_flag = 1;
    //         }
    //         else if (item == 'packer') {
    //             this.state.packer_flag = 1;
    //         }
    //         else if (item == 'claim repair') {
    //             this.state.claimrepair_flag = 1;
    //         }
    //     })
    //     let user_id = (JSON.parse(Storage.get('imnLoginData'))).id;
    //     PostDataWithParam("ShareFile",
    //         {
    //             order_id: this.state.details.id,
    //             user_id: user_id,
    //             profile_id: 2,
    //             mover_flag: this.state.mover_flag,
    //             driver_flag: this.state.driver_flag,
    //             helper_flag: this.state.helper_flag,
    //             customer_flag: this.state.customer_flag,
    //             thirdparty_flag: this.state.thirdparty_flag,
    //             packer_flag: this.state.packer_flag,
    //             claimrepair_flag: this.state.claimrepair_flag,
    //             document_id: this.state.document_id
    //         }).then(response => {
    //             if (response.success === 1) {
    //                 if (this.props.location.state) {
    //                     this.getShareFileStatus(this.props.location.state.id);
    //                 }
    //                 window.swal('Share', response.msg, 'success');
    //             } else {
    //                 window.swal("Error", response.msg, 'Error')
    //             }
    //         }).catch(err => {
    //             window.swal("Error", err.msg, 'Error')
    //         })

    // }
    // getShareFileDetails = () => {
    //     PostDataWithParam("ShareFileDetails", { order_id: this.state.details.id, profile_id: 2 })
    //         .then(response => {
    //             if (response.success === 1) {
    //                 this.setState({ shareFileDetails: response.data }, () => {
    //                 })
    //             } else {
    //                 this.setState({ shareFileDetails: [] });
    //             }
    //         }).catch(err => {
    //             this.setState({ shareFileDetails: [] });
    //         })
    // }
    // getShareFileStatus = (orderId) => {
    //     PostDataWithParam("ShareFileStatus", { order_id: orderId })
    //         .then(response => {
    //             if (response.success === 1) {
    //                 this.setState({ sharedFileWith: response.data }, () => {
    //                 })
    //             } else {
    //                 this.setState({ sharedFileWith: [] });
    //             }
    //         }).catch(err => {
    //             this.setState({ sharedFileWith: [] });
    //         })
    // }
    // getFileId = (e, file_id) => {
    //     e.preventDefault();
    //     let files = []
    //     this.state.sharedFileWith.map((item, i) => {
    //         if (item.document_id == file_id) {
    //             if (item.mover_flag == 1) {
    //                 files.push("mover")
    //             }
    //             if (item.helper_flag == 1) {
    //                 files.push("helper")
    //             }
    //             if (item.driver_flag == 1) {
    //                 files.push("driver")
    //             }
    //             if (item.customer_flag == 1) {
    //                 files.push("customer")
    //             }
    //             if (item.thirdparty_flag == 1) {
    //                 files.push("3rd party")
    //             }
    //             if (item.packer_flag == 1) {
    //                 files.push("packer")
    //             }
    //             if (item.claimrepain_flag == 1) {
    //                 files.push("claim repair")
    //             }
    //         }
    //     })

    //     this.setState({ document_id: file_id, shareFile: files });
    // }

    archivefile(id) {

        console.log(id);
        ConfirmBox("you want to archive this file!")
            .then((result) => {
                if (result) {
                    PostDataWithParam('ArchiveImage', { id: id })
                        .then((response) => {
                            if (response.success == 1) {
                                window.swal('Archived!', response.msg, 'success')
                                this.getFiles();

                            }
                        })
                        .catch(err => {

                        })
                }
            })
    }


    renderArchiveFile() {
        if (this.state.arch_files.length > 0) {
            let files = this.state.arch_files.map((file, i) => {
                return (
                    <React.Fragment key={'item-' + i}>
                        {
                            (JSON.parse(Storage.get('imnLoginData'))).id != file.uploaded_by_user_id
                            &&
                            // this.state.shareFileDetails[file.id]==1
                            // &&
                            <React.Fragment>
                                <li>
                                    <div className={"file-wrapper " + FileType(file.file_type)}>
                                        {FileSvg(file.file_type, file.request_file)}
                                        <div className="file-title"><a href={file.request_file}><i className={FileIcon(file.file_type) + " position-left text-primary"}></i> {this.getFileName(file)}</a>
                                        </div>
                                    </div>
                                </li>
                            </React.Fragment>
                        }
                        {
                            (JSON.parse(Storage.get('imnLoginData'))).id === file.uploaded_by_user_id
                            &&
                            <React.Fragment>
                                <li >
                                    <div className={"file-wrapper " + FileType(file.file_type)}>
                                        {FileSvg(file.file_type, file.request_file)}
                                        <div className="file-title"><a href={file.request_file}><i className={FileIcon(file.file_type) + " position-left text-primary"}></i> {this.getFileName(file)}</a></div>
                                    </div>
                                </li>
                            </React.Fragment>
                        }
                    </React.Fragment>
                )
            });

            return files;
        } else {
            return <h6 className="alert alert-primary no-border m-b-0">No files were found.</h6>
        }
    }

    listArchived = () => {

        this.setState({})
        PostDataWithParam('ArchiveImageList', { order_request_detail_id: this.state.id })
            .then(response => {
                if (response.success == 1) {

                    this.setState({ arch_files: response.data, proccesing: false, showInventoryTab: false, showMoverTab: false, showFilesTab: false, showArchiveTab: true }, () => {
                        // console.log('files', this.state.arch_files)
                    });
                } else {
                    this.setState({ arch_files: [], proccesing: false, showInventoryTab: false, showMoverTab: false, showFilesTab: false, showArchiveTab: true });
                }
            })
            .catch(err => {

                this.setState({ arch_files: [], proccesing: false, showInventoryTab: false, showMoverTab: false, showFilesTab: false, showArchiveTab: true });
            });
    }

    setInvenotryTab = (e) => {
        this.setState({ showInventoryTab: true, showMoverTab: false, showFilesTab: false, showArchiveTab: false })
        this.renderInventoryItems();
    }

    setMoverTab = (e) => {
        this.setState({ showInventoryTab: false, showMoverTab: true, showFilesTab: false, showArchiveTab: false })
        this.GetMoverNote();
    }

    renderInventoryItems = () => {

        PostDataWithParam('GetInventoryListForCustomer', { order_id: this.state.id, offset: '' })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ allmaininventory: response.data.item_list, total: response.data.total_items }, () => {
                        this.setState({ showInventoryItems: true, showFilesTab: false, showMoverTab: false, showArchiveTab: false, showInventoryTab: true })
                        //   if(this.state.allmaininventory.length>0){
                        //       this.setState({isGetInventory:true},()=>{this.setInvenotryTab()})
                        //   }
                        //   let offset = this.state.offset;
                        //     if (offset == 0) {
                        //         offset = this.state.limit;
                        //     } else {
                        //         offset += this.state.limit;
                        //     }
                        //     this.setState({ offset: offset });
                        //   console.log(this.state.allmaininventory)
                    });
                } else {
                    this.setState({
                        allmaininventory: [], loadMore: 0, offset: 0, total: 0, isGetInventory: false,
                        showInventoryItems: true, showFilesTab: false, showMoverTab: false, showArchiveTab: false, showInventoryTab: true
                    });
                    // window.swal("Error!", "Oops! Something went wrong. 1", "error");
                }
            })
            .catch((err) => {
                this.setState({
                    allmaininventory: [], loadMore: 0, offset: 0, total: 0, isGetInventory: false,
                    showInventoryItems: true, showFilesTab: false, showMoverTab: false, showArchiveTab: false, showInventoryTab: true
                });
                //   window.swal("Error!", "Oops! Something went wrong. 2", "error");
            });
    }

    showItemList = () => {

        if (this.state.allmaininventory.length > 0) {
            let inventory_items = this.state.allmaininventory.map((item, i) => {
                return (

                    <React.Fragment>
                        <div className="panel panel-default text-grey-600" key={item.room_id}>
                            <a href="javascript:void();" data-toggle="collapse" data-target={'#inventory-body' + item.room_id} className="">
                                <div className="panel-heading">
                                    <div className="heading-elements">
                                        <div className="icons-list text-grey"> {item.items.length} item(s) selected<i className="icon-arrow-down22"></i></div>
                                    </div>
                                    <h6 className="m-0 text-success">{item.title} ({item.items.length})</h6>

                                </div>
                            </a>
                            <hr className="m-0" />
                            <div className="collapse" id={'inventory-body' + item.room_id}>
                                <div className="panel-body">
                                    <div className="show-inventory-items file-manager horizontal-scroll">

                                        <ul>
                                            {/* <h6 className="text-success">{item.title}</h6> */}
                                            {
                                                item.items.map((sub_item, j) => {
                                                    return (
                                                        <li>
                                                            <div className="well p-15 m-b-0">
                                                                <div className="media">
                                                                    <div className="media-left">
                                                                        <img src={"https://myrelo.us/relotechApi/public/uploads/inventory/item/" + sub_item.image} className="img-rounded media-inventory-preview" alt="" />
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <label className="text-bold m-b-0 text-purple-600">Item Name</label>
                                                                        <p className="m-b-5">{sub_item.title}</p>
                                                                        <label className="text-bold m-b-5 text-purple-600">Qty</label>
                                                                        <p className="m-b-5">{sub_item.quantity}</p>
                                                                    </div>
                                                                    <div className="media-right">

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                })
                                            }

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </React.Fragment>
                );
            })
            return inventory_items;
        }
        else {
            return <h6 className="alert alert-primary no-border">No files were found.</h6>
        }
    }

    // renderFiles() {
    //     console.log(this.state.files.length);
    //     if (this.state.proccesing) {
    //         return <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Uploading. Please wait...</div>
    //     } else {
    //         if (this.state.files.length > 0) {
    //             let files = this.state.files.map((file, i) => {
    //                 return (
    //                     <React.Fragment key={'item-' + i}>
    //                         {
    //                             (JSON.parse(Storage.get('imnLoginData'))).id != file.uploaded_by_user_id
    //                             &&
    //                             // this.state.shareFileDetails[file.id] == 1
    //                             // &&
    //                             <React.Fragment>
    //                                 <li>
    //                                     <div className={"file-wrapper " + FileType(file.file_type)}>
    //                                         {FileSvg(file.file_type, file.request_file)}
    //                                         <div className="file-title"><a href={file.request_file}><i className={FileIcon(file.file_type) + " position-left text-primary"}></i> {this.getFileName(file)}</a></div>
    //                                         <div className="overlay-file archive-filetype">
    //                                             {/* <a href="" className="text-success" data-toggle="modal" data-target={"#showfile" + i}><i className="icon-eye4 f-s-20"></i> <span className="show">View</span></a> */}
    //                                             {FileType(file.file_type) == "filetype-pdf" ?
    //                                                 <a href={file.request_file} target="_blank"><i className="icon-eye4 f-s-18"></i> <span className="show">View</span></a>
    //                                                 :
    //                                                 <a href="" className="text-success" data-toggle="modal" data-target={"#showfile" + i}><i className="icon-eye4 f-s-18"></i> <span className="show">View</span></a>
    //                                             }
    //                                             <a href="" className="text-info" data-toggle="modal" data-target={"#sharefile" + i} onClick={(e) => this.getFileId(e, file.id)}><i className="icon-share3 f-s-20"></i> <span className="show">Share</span></a>
    //                                             <a href="javascript:void(0)" className="text-success" onClick={this.archivefile.bind(this, file.id)}><i className="icon-archive f-s-20"></i> <span className="show">Archive</span></a>
    //                                         </div>
    //                                     </div>
    //                                 </li>
    //                             </React.Fragment>
    //                         }
    //                         {
    //                             (JSON.parse(Storage.get('imnLoginData'))).id === file.uploaded_by_user_id
    //                             &&
    //                             <React.Fragment>
    //                                 <li >
    //                                     <div className={"file-wrapper " + FileType(file.file_type)}>
    //                                         {FileSvg(file.file_type, file.request_file)}
    //                                         <div className="file-title"><a href={file.request_file}><i className={FileIcon(file.file_type) + " position-left text-primary"}></i> {this.getFileName(file)}</a></div>
    //                                         <div className="overlay-file">
    //                                             {/* <a href="" className="text-info" data-toggle="modal" data-target="#sharefile" onClick={(e) => this.getFileId(e, file.id)}><i className="icon-share3 f-s-20"></i> <span className="show">Share</span></a> */}
    //                                             {/* <a href="" className="text-success" data-toggle="modal" data-target={"#showfile" + i}><i className="icon-eye4 f-s-20"></i> <span className="show">View</span></a> */}
    //                                             {FileType(file.file_type) == "filetype-pdf" ?
    //                                                 <a href={file.request_file} target="_blank"><i className="icon-eye4 f-s-18"></i> <span className="show">View</span></a>
    //                                                 :
    //                                                 <a href="" className="text-success" data-toggle="modal" data-target={"#showfile" + i}><i className="icon-eye4 f-s-18"></i> <span className="show">View</span></a>
    //                                             }
    //                                             <a href="" className="text-danger" onClick={this.deleteFile.bind(this, file.id)}><i className="icon-cancel-circle2 f-s-20"></i> <span className="show">Delete</span></a>
    //                                         </div>
    //                                     </div>
    //                                 </li>
    //                             </React.Fragment>
    //                         }
    //                         <div id="sharefile" className="modal fade" role="dialog">
    //                             <div className="modal-dialog modal-xs">
    //                                 <div className="modal-content">
    //                                     <div className="modal-header">
    //                                         <button type="button" className="close" data-dismiss="modal">&times;</button>
    //                                         <h4 className="modal-title">Share With</h4>
    //                                     </div>
    //                                     <div className="modal-body">
    //                                         <div className="form-group m-b-0">
    //                                             <label className="checkbox-inline custom-checkbox check-success">
    //                                                 <input type="checkbox" name="driver" value="driver" checked={this.state.shareFile.indexOf('driver') > -1} onChange={this.shareWith} /><span></span> Driver</label>
    //                                         </div>
    //                                         <div className="form-group m-b-0">
    //                                             <label className="checkbox-inline custom-checkbox check-success">
    //                                                 <input type="checkbox" name="helper" value="helper" checked={this.state.shareFile.indexOf('helper') > -1} onChange={this.shareWith} /><span></span> Helper</label>
    //                                         </div>
    //                                         <div className="form-group m-b-0">
    //                                             <label className="checkbox-inline custom-checkbox check-success">
    //                                                 <input type="checkbox" name="mover" value="mover" checked={this.state.shareFile.indexOf('mover') > -1} onChange={this.shareWith} /><span></span> Moving Company</label>
    //                                         </div>
    //                                         <div className="form-group m-b-0">
    //                                             <label className="checkbox-inline custom-checkbox check-success">
    //                                                 <input type="checkbox" name="customer" value="customer" checked={this.state.shareFile.indexOf('customer') > -1} onChange={this.shareWith} /><span></span> Customer</label>
    //                                         </div>
    //                                         <div className="form-group m-b-10">
    //                                             <label className="checkbox-inline custom-checkbox check-success">
    //                                                 <input type="checkbox" name="packer" value="packer" checked={this.state.shareFile.indexOf('packer') > -1} onChange={this.shareWith} /><span></span> Packer</label>
    //                                         </div>
    //                                         <div className="form-group m-b-10">
    //                                             <label className="checkbox-inline custom-checkbox check-success">
    //                                                 <input type="checkbox" name="3rd party" value="3rd party" checked={this.state.shareFile.indexOf('3rd party') > -1} onChange={this.shareWith} /><span></span>3rd party</label>
    //                                         </div>
    //                                         <div className="form-group m-b-10">
    //                                             <label className="checkbox-inline custom-checkbox check-success">
    //                                                 <input type="checkbox" name="claim repair" value="claim repair" checked={this.state.shareFile.indexOf('claim repair') > -1} onChange={this.shareWith} /><span></span>claim repair</label>
    //                                         </div>
    //                                     </div>
    //                                     <div className="modal-footer">
    //                                         <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.submitShareWith}>Done</button>
    //                                     </div>
    //                                 </div>

    //                             </div>
    //                         </div>

    //                         {/* <div id={"showfile" + i} className="modal fade" role="dialog">
    //                             <div className="modal-dialog">
    //                                 <div className="modal-content">
    //                                     <div className="modal-header">
    //                                         <button type="button" className="close" data-dismiss="modal">&times;</button>
    //                                         <h4 className="modal-title"></h4>
    //                                     </div>
    //                                     <div className="modal-body">
    //                                         <div className="row">
    //                                             <div className={"file-wrapper " + FileType(file.file_type)}>
    //                                                 {FileSvg(file.file_type, file.request_file)}
    //                                                 <div className="file-title m-t-15"><a href={file.request_file}><i className={FileIcon(file.file_type) + " position-left text-primary"}></i> {this.getFileName(file)}</a>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                     <div className="modal-footer">
    //                                         <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div> */}

    //                         {/* showfile Modal start */}
    //                         <div id={"showfile" + i} className="modal fade" role="dialog">
    //                             <div className="modal-dialog modal-sm">
    //                                 <div className="modal-content">
    //                                     <div className="modal-header">
    //                                         {/* <button type="button" className="close" data-dismiss="modal" onClick={(e)=>this.closeModal("audio-video"+i)}>&times;</button> */}
    //                                         {FileType(file.file_type) == "filetype-image" &&
    //                                             <button type="button" className="close" data-dismiss="modal">&times;</button>
    //                                         }
    //                                         {FileType(file.file_type) == "filetype-video" &&
    //                                             <button type="button" className="close" data-dismiss="modal" onClick={(e) => this.closeModal("audio-video" + i)}>&times;</button>
    //                                         }
    //                                         <h4 className="modal-title" style={{ color: "black" }}>File Details</h4>
    //                                     </div>
    //                                     <hr className="m-t-15" />
    //                                     <div className="modal-body">
    //                                         <div className="row">
    //                                             <div className="col-md-12">
    //                                                 <div className="show-file-picture">
    //                                                     <div className={FileType(file.file_type)}>
    //                                                         {/* {FileSvg(file.file_type, file.request_file)} */}
    //                                                         {FileType(file.file_type) == "filetype-image" &&
    //                                                             <img src={file.request_file} />}

    //                                                         {FileType(file.file_type) == "filetype-video" &&
    //                                                             <video width="100%" height="100%" controls id={"audio-video" + i}>
    //                                                                 <source src={file.request_file} type="video/mp4" />
    //                                                             </video>
    //                                                         }
    //                                                     </div>
    //                                                 </div>
    //                                                 <div className="file-title m-t-15">
    //                                                     <h6 style={{ color: "black" }}><strong>File Name</strong></h6>
    //                                                     {/* <p style={{ color: "black" }}>{this.getFileName(file.file_name)}</p> */}
    //                                                     <p style={{ color: "black" }}><pre>{this.getFileName(file)}</pre></p>
    //                                                     <h6 style={{ color: "black" }}><strong>File Description</strong></h6>
    //                                                     <pre style={{ color: "black" }}>{file.file_description}</pre>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                     <div className="modal-footer">
    //                                         {/* <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e)=>this.closeModal("audio-video"+i)}>Close</button> */}
    //                                         {FileType(file.file_type) == "filetype-image" &&
    //                                             <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
    //                                         }
    //                                         {FileType(file.file_type) == "filetype-video" &&
    //                                             <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e) => this.closeModal("audio-video" + i)}>Close</button>
    //                                         }
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>

    //                         {/* showfile Modal end */}

    //                     </React.Fragment>
    //                 )
    //             });

    //             return files;
    //         } else {
    //             return <h6 className="alert alert-primary border-none m-b-0">No files were found.</h6>
    //         }
    //     }
    // }

    closeModal(id) {
        window.$('#' + id)[0].pause();
        // window.$('#audio-video0')[0].pause()
    }

    /**
     * End of files
     */

    /**
     * start of Cya Videos
     */
    getCyaVideo = (e, video_path) => {
        e.preventDefault();
        this.setState({ cya_video_path: video_path });
    }

    renderCyaVideo() {
        if (this.state.proccesing) {
            return <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Please wait...</div>
        } else {
            if (this.state.details.cya_video.length > 0) {
                let cya_video = this.state.details.cya_video.map((cya_video, i) => {
                    return (
                        <React.Fragment key={'item-' + i}>
                            <li>
                                <div className="file-wrapper filetype-pdf">
                                    <div className="options">
                                        <a href="javascript:void(0)" className="text-danger-600"><i className="icon-cancel-circle2"></i></a>
                                    </div>
                                    <a href="#" data-toggle="modal" onClick={(e) => this.getCyaVideo(e, cya_video.video_path)} data-target="#myVideo">
                                        {/* <a  href="javascript:void(0)" data-toggle="modal"  data-target="#myVideo"> */}
                                        <div className="file-icon"><img src="assets/images/icons/video.svg" alt="Audio" /></div>
                                        <div className="file-title"><a href="javascript:void(0)"><i className="icon-file-video position-left text-success"></i>{cya_video.video_name}</a></div>
                                    </a>
                                </div>
                            </li>

                        </React.Fragment>
                    )
                });

                return cya_video;
            } else {
                return <h6 className="alert alert-primary border-none m-b-0">No files were found.</h6>
            }
        }
    }

    /**
     * End of Cya Videos
     */

    renderBookedMover() {
        if (this.state.details.booked.mover.length > 0) {
            let booked = this.state.details.booked.mover.map((item, i) => {
                return (
                    <React.Fragment key={'booked-mover-' + i}>
                        <tbody>
                            <tr>
                                <td style={{ width: '35%' }}>
                                    <div className="media">
                                        <div className="media-left">
                                            <Link to="#">
                                                <img className="media-object" src={item.profile_image} alt="..." width="35" />
                                            </Link>
                                        </div>
                                        <div className="media-body">
                                            <h6 className="m-0"><a href="javascript:void(0)" onClick={() => this.setTempHelper(item.user_id)}>{item.full_name}</a><b /></h6>
                                        </div>
                                    </div>
                                </td>
                                {/* <td>{item.contact_no}</td>
                                <td>{item.email || '---'}</td> */}
                                {/* <td className="count">
                                    <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                        <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                    </a>
                                </td> */}
                                <td className="text-center">
                                    <a href="" data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                        onClick={this.initChatBox.bind(this, item.user_id, item.full_name, this.state.id, '')}>
                                        <i className="icon-bubbles9 f-s-20 text-success"></i></a>
                                </td>
                                <td className="text-center">
                                    <a href="" onClick={(e) => this.shareApp(e, item.user_id)}><i className="icon-share3"></i></a>
                                </td>
                            </tr>

                        </tbody>
                    </React.Fragment>
                    // <div className="well p-0 m-b-20" key={'booked-mover-' + i}>
                    //     <div className="p-15">
                    //         <div className="row">
                    //             <div className="col-md-2">
                    //                 <label className="m-b-0 text-bold">Mover's Company Name</label>
                    //                 {
                    //                     (JSON.parse(Storage.get('imnLoginData'))).id == item.user_id
                    //                     &&
                    //                     <p className="m-b-0"><a href="javascript:void(0)" >{item.full_name} <b className="text-primary">(It’s You)</b></a><b /></p>
                    //                 }
                    //                 {
                    //                     (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                    //                     &&
                    //                     <p className="m-b-0"><a href="javascript:void(0)" data-toggle="modal" data-target="#profileInfoModal" onClick={(e) => this.setTempMover(e, item.user_id)}>{item.full_name}</a><b /></p>
                    //                 }
                    //             </div>

                    //             {
                    //                 (
                    //                     !item.address_type
                    //                     ||
                    //                     item.address_type == ""
                    //                 )
                    //                 &&
                    //                 <React.Fragment>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             this.state.details.source_address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="text-bold m-b-0">Address <b className="text-success">(Origin)</b></label>
                    //                                 <p className="m-b-20">{this.state.details.source_address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             this.state.details.destination_address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="text-bold m-b-0">Address <b className="text-success">(Destination)</b></label>
                    //                                 <p className="m-b-20">{this.state.details.destination_address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                 </React.Fragment>
                    //             }
                    //             {
                    //                 item.address_type
                    //                 &&
                    //                 <React.Fragment>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             item.address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="m-b-0 text-bold">Address <b className="text-success">({item.address_type || "---"})</b></label>
                    //                                 <p className="m-b-0">{item.address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                     <div className="col-md-3">
                    //                     </div>
                    //                 </React.Fragment>
                    //             }
                    //             <div className="col-md-2">
                    //                 <label className="m-b-0 text-bold">Phone</label>
                    //                 <p className="m-b-0">{item.contact_no}</p>
                    //             </div>
                    //             {
                    //                 (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                    //                 &&
                    //                 <div className="col-md-2 text-right">
                    //                     {/* <a href="#" className="m-r-10" onClick={(e) => this.openMsgSection(e, item.user_id, 'mover')}><i className="icon-envelope"></i></a> */}
                    //                     {/* Start Chat */}
                    //                     <a href="" className="btn btn-default btn-xs user-chat"
                    //                         data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                    //                         onClick={this.initChatBox.bind(this, item.user_id, item.full_name, this.state.id, '')}>
                    //                         <i className="icon-comment-discussion"></i> Chat</a>
                    //                     {/* End Chat */}
                    //                 </div>
                    //             }

                    //         </div>
                    //         <div className="row">
                    //             <div className="col-md-12">
                    //                 <label className="m-b-0 text-bold">Services</label>
                    //                 <p className="m-b-0">{item.services_needed || "----"}</p>
                    //             </div>
                    //         </div>
                    //     </div>
                    // </div>
                )
            });
            return booked;
        } else {
            return <h6 className="text-grey-600">No result found.</h6>
        }
    }
    openMsgSection = (e, userId, profile) => {
        e.preventDefault();
        if (profile == "mover") {
            window.$("#bordered-tab1").addClass('active')
            window.$("#bordered-tab2").removeClass('active')
            window.$("#bordered-tab3").removeClass('active')
            window.$("#bordered-tab4").removeClass('active')

            window.$("#mover-tab").addClass('active')
            window.$("#helper-tab").removeClass('active')
            window.$("#driver-tab").removeClass('active')
            window.$("#superadmin-tab").removeClass('active')
        } else if (profile == "helper") {
            window.$("#bordered-tab1").removeClass('active')
            window.$("#bordered-tab2").addClass('active')
            window.$("#bordered-tab3").removeClass('active')
            window.$("#bordered-tab4").removeClass('active')

            window.$("#mover-tab").removeClass('active')
            window.$("#helper-tab").addClass('active')
            window.$("#driver-tab").removeClass('active')
            window.$("#superadmin-tab").removeClass('active')
        }
        else if (profile == "driver") {
            window.$("#bordered-tab1").removeClass('active')
            window.$("#bordered-tab2").removeClass('active')
            window.$("#bordered-tab3").addClass('active')
            window.$("#bordered-tab4").removeClass('active')

            window.$("#mover-tab").removeClass('active')
            window.$("#helper-tab").removeClass('active')
            window.$("#driver-tab").addClass('active')
            window.$("#superadmin-tab").removeClass('active')
        } else if (profile == "superadmin") {
            window.$("#bordered-tab1").removeClass('active')
            window.$("#bordered-tab2").removeClass('active')
            window.$("#bordered-tab3").removeClass('active')
            window.$("#bordered-tab4").addClass('active')

            window.$("#mover-tab").removeClass('active')
            window.$("#helper-tab").removeClass('active')
            window.$("#driver-tab").removeClass('active')
            window.$("#superadmin-tab").addClass('active')
        } else if (profile == "customer") {
            window.$("#bordered-tab1").removeClass('active')
            window.$("#bordered-tab2").removeClass('active')
            window.$("#bordered-tab3").removeClass('active')
            window.$("#bordered-tab4").removeClass('active')
            window.$("#bordered-tab5").addClass('active')

            window.$("#mover-tab").removeClass('active')
            window.$("#helper-tab").removeClass('active')
            window.$("#driver-tab").removeClass('active')
            window.$("#superadmin-tab").removeClass('active')
            window.$("#customer-tab").addClass('active')
        }

        window.$("#conversations_people_" + userId).trigger("click");
        window.scrollTo(0, 1000000);
    }
    renderBookedDriver() {
        if (this.state.details.booked.driver.length > 0) {
            let booked = this.state.details.booked.driver.map((item, i) => {
                return (
                    <React.Fragment key={'booked-driver-' + i}>

                        <tr>
                            <td style={{ width: '35%' }}>
                                <div className="media">
                                    <div className="media-left">
                                        <Link to="#">
                                            <img className="media-object" src={item.profile_image} alt="..." width="35" />
                                        </Link>
                                    </div>
                                    <div className="media-body">
                                        <h6 className="m-0"><a href="javascript:void(0)" onClick={() => this.setTempHelper(item.user_id)}>{item.full_name}</a><b /></h6>
                                    </div>
                                </div>
                            </td>
                            {/* <td>{item.contact_no}</td>
                            <td>{item.email || '---'}</td> */}
                            {/* <td className="count text-center">
                                <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                    <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                </a>
                            </td> */}
                            <td className="text-center"><a href="" data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                onClick={this.initChatBox.bind(this, item.user_id, item.full_name, this.state.id, '')}>
                                <i className="icon-bubbles9 f-s-20 text-success"></i></a>
                            </td>
                            <td className="text-center">
                                <a href="" onClick={(e) => this.shareApp(e, item.user_id)}><i className="icon-share3"></i></a>
                            </td>
                            {/* <td className="text-center"><i className="icon-share2 f-s-20 text-success"></i></td> */}
                        </tr>


                    </React.Fragment>
                    // <div className="well p-0 m-b-20" key={'booked-driver-' + i}>
                    //     <div className="p-15">
                    //         <div className="row">
                    //             <div className="col-md-2">
                    //                 <label className="m-b-0 text-bold">Driver's Name</label>
                    //                 <p className="m-b-0"><a data-toggle="modal" data-target="#profileInfoModalHelperDriver" href="#" onClick={() => this.setTempHelper(item.user_id)}>{item.full_name}</a><b /></p>
                    //             </div>

                    //             {
                    //                 (
                    //                     !item.address_type
                    //                     ||
                    //                     item.address_type == ""
                    //                 )
                    //                 &&
                    //                 <React.Fragment>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             this.state.details.source_address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="text-bold m-b-0">Address <b className="text-success">(Origin)</b></label>
                    //                                 <p className="m-b-20">{this.state.details.source_address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             this.state.details.destination_address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="text-bold m-b-0">Address <b className="text-success">(Destination)</b></label>
                    //                                 <p className="m-b-20">{this.state.details.destination_address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                 </React.Fragment>

                    //             }


                    //             {
                    //                 item.address_type
                    //                 &&
                    //                 <React.Fragment>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             item.address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="m-b-0 text-bold">Address <b className="text-success">({item.address_type || "---"})</b></label>
                    //                                 <p className="m-b-0">{item.address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                     <div className="col-md-3">
                    //                         {/* <label className="text-bold m-b-0">Address <b className="text-success">(Destination)</b></label>
                    //                         <p className="m-b-20">--</p> */}
                    //                     </div>
                    //                 </React.Fragment>
                    //             }
                    //             <div className="col-md-2">
                    //                 <label className="m-b-0 text-bold">Phone</label>
                    //                 <p className="m-b-0">{item.contact_no}</p>
                    //             </div>
                    //             <div className="col-md-2 text-right">
                    //                 {/* <a href="#" className="m-r-10" onClick={(e) => this.openMsgSection(e, item.user_id, 'driver')}><i className="icon-envelope"></i></a> */}
                    //                 <a href="" className="btn btn-default btn-xs user-chat" data-chatusername={item.full_name}
                    //                     data-uid={this.state.id + '_' + item.user_id}
                    //                     onClick={this.initChatBox.bind(this, item.user_id, item.full_name, this.state.id, '')}>
                    //                     <i className="icon-comment-discussion"></i> Chat</a>
                    //             </div>
                    //         </div>
                    //         <div className="row">
                    //         </div>
                    //     </div>
                    // </div>
                )
            });

            return booked;
        } else {
            // return <h6 className="text-grey-600">No result found.</h6>
        }
    }

    renderBookedHelper() {
        if (this.state.details.booked.helper.length > 0) {
            let booked = this.state.details.booked.helper.map((item, i) => {
                return (
                    <React.Fragment key={'booked-helper-' + i}>
                        <tr>
                            <td style={{ width: '35%' }}>
                                <div className="media">
                                    <div className="media-left">
                                        <Link to="#">
                                            <img className="media-object" src={item.profile_image} alt="..." width="35" />
                                        </Link>
                                    </div>
                                    <div className="media-body">
                                        <h6 className="m-0"><a href="javascript:void(0)" onClick={() => this.setTempHelper(item.user_id)}>{item.full_name}</a><b /></h6>
                                    </div>
                                </div>
                            </td>
                            {/* <td>{item.contact_no}</td>
                            <td>{item.email || '---'}</td> */}
                            {/* <td className="count text-center">
                                <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                    <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                </a>
                            </td> */}
                            <td className="text-center"><a href="" data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                onClick={this.initChatBox.bind(this, item.user_id, item.full_name, this.state.id, '')}>
                                <i className="icon-bubbles9 f-s-20 text-success"></i></a>
                            </td>
                            <td className="text-center">
                                <a href="" onClick={(e) => this.shareApp(e, item.user_id)}><i className="icon-share3"></i></a>
                            </td>
                            {/* <td className="text-center"><i className="icon-share2 f-s-20 text-success"></i></td> */}
                        </tr>


                    </React.Fragment>
                    // <div className="well p-0 m-b-20" key={'booked-helper-' + i}>
                    //     <div className="p-15">
                    //         <div className="row">
                    //             <div className="col-md-2">
                    //                 <label className="m-b-0 text-bold">Helper's Name</label>
                    //                 <p className="m-b-0"><a data-toggle="modal" data-target="#profileInfoModalHelperDriver" href="#" onClick={() => this.setTempHelper(item.user_id)}>{item.full_name}</a><b /></p>
                    //             </div>

                    //             {
                    //                 (
                    //                     !item.address_type
                    //                     ||
                    //                     item.address_type == ""
                    //                 )
                    //                 &&
                    //                 <React.Fragment>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             this.state.details.source_address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="text-bold m-b-0">Address <b className="text-success">(Origin)</b></label>
                    //                                 <p className="m-b-20">{this.state.details.source_address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             this.state.details.destination_address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="text-bold m-b-0">Address <b className="text-success">(Destination)</b></label>
                    //                                 <p className="m-b-20">{this.state.details.destination_address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                 </React.Fragment>
                    //             }
                    //             {
                    //                 item.address_type
                    //                 &&
                    //                 <React.Fragment>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             item.address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="m-b-0 text-bold">Address <b className="text-success">({item.address_type || "---"})</b></label>
                    //                                 <p className="m-b-0">{item.address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                     <div className="col-md-3">
                    //                     </div>
                    //                 </React.Fragment>
                    //             }
                    //             <div className="col-md-2">
                    //                 <label className="m-b-0 text-bold">Phone</label>
                    //                 <p className="m-b-0">{item.contact_no}</p>
                    //             </div>
                    //             <div className="col-md-2 text-right">
                    //                 <a href="#" className="m-r-10" onClick={(e) => this.openMsgSection(e, item.user_id, 'helper')}><i className="icon-envelope"></i></a>
                    //                 <a href="" className="btn btn-default btn-xs user-chat"
                    //                     data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                    //                     onClick={this.initChatBox.bind(this, item.user_id, item.full_name, this.state.id, '')}>
                    //                     <i className="icon-comment-discussion"></i> Chat</a>
                    //             </div>

                    //         </div>
                    //         <div className="row">
                    //             <div className="col-md-12">
                    //                 <label className="m-b-0 text-bold">Services</label>
                    //                 <p className="m-b-0">{item.services_needed || "----"}</p>
                    //             </div>
                    //         </div>
                    //     </div>
                    // </div>
                )
            });

            return booked;
        } else {
            // return <h6 className="text-grey-600">No result found.</h6>
        }
    }

    renderInvitedHelper() {
        if (this.state.details.invited.helper.length > 0) {
            let invited = this.state.details.invited.helper.map((item, i) => {
                return (
                    <React.Fragment key={'invited-helper-' + i}>
                        <tr>
                            <td style={{ width: '35%' }}>
                                <div className="media">
                                    <div className="media-left">
                                        <Link to="#">
                                            <img className="media-object" src={item.profile_image} alt="..." width="35" />
                                        </Link>
                                    </div>
                                    <div className="media-body">
                                        <h6 className="m-0">
                                            <a href="javascript:void(0)" onClick={() => this.setTempHelper(item.user_id)}>{item.full_name}</a>
                                            <span className="m-l-5 m-b-0 text-orange">
                                                <span className="icon-star-full2"></span>
                                                <span className="icon-star-full2"></span>
                                                <span className="icon-star-full2"></span>
                                                <span className="icon-star-full2"></span>
                                                <span className="icon-star-empty3"></span>
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {item.status == 0 && <span className="text-primary">Pending</span>}
                                {item.status == 1 && <span className="text-info">Communicating</span>}
                                {item.status == 2 && <span className="text-danger">Declined</span>}
                                {item.status == 4 && <span className="text-danger">Cancel</span>}
                            </td>
                            {
                                item.status == 0 &&
                                item.award_status == 0 &&
                                this.state.permission &&
                                <React.Fragment>
                                    {/* <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 1, item.user_id, this.state.details.id)}><i className="icon-checkmark4 f-s-20 text-success"></i></a></td> */}
                                    <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}><i className="icon-blocked f-s-20 text-danger"></i></a></td>
                                    {/* <td className="count text-center">
                                        <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                            <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                        </a>
                                    </td> */}
                                    {
                                        (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                                        &&
                                        <td className="text-center"><a href=""
                                            data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                            onClick={this.initChatBox.bind(
                                                this, item.user_id, item.full_name, this.state.id, item.profile_image
                                            )}>
                                            <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                                    }
                                </React.Fragment>
                            }
                            {
                                item.award_status == 1
                                &&
                                item.status != 2
                                &&
                                this.state.permission &&
                                <React.Fragment>
                                    {/* <td className="text-muted text-center"><span className="text-success"><i className="icon-checkmark-circle"></i></span> Awarded</td> */}
                                    <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}><i className="icon-blocked f-s-20 text-danger"></i></a></td>
                                    {/* <td className="count text-center">
                                        <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                            <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                        </a>
                                    </td> */}
                                    {
                                        (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                                        &&
                                        <td className="text-center"><a href=""
                                            data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                            onClick={this.initChatBox.bind(
                                                this, item.user_id, item.full_name, this.state.id, item.profile_image
                                            )}>
                                            <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                                    }
                                </React.Fragment>
                            }
                            {
                                item.status == 2 &&
                                (item.award_status == 0 || item.award_status == 1)
                                &&
                                this.state.permission &&
                                <React.Fragment>
                                    {/* <td className="text-center"><a href="" onClick={(e) => this.reAwardDeclineOffers(e, 1, item.user_id, this.state.details.id)}><i className="icon-checkmark4 f-s-20 text-success"></i></a></td> */}
                                    <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}><i className="icon-blocked f-s-20 text-danger"></i></a></td>
                                    {/* <td className="count text-center">
                                        <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                            <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                        </a>
                                    </td> */}
                                    {
                                        (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                                        &&
                                        <td className="text-center"><a href=""
                                            data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                            onClick={this.initChatBox.bind(
                                                this, item.user_id, item.full_name, this.state.id, item.profile_image
                                            )}>
                                            <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                                    }
                                </React.Fragment>
                            }
                            <td className="text-center">
                                <a href="" onClick={(e) => this.shareApp(e, item.user_id)}><i className="icon-share3"></i></a>
                            </td>
                        </tr>
                    </React.Fragment>
                    // <div className="well p-0 m-b-20" key={'invited-helper-' + i}>
                    //     <div className="p-15">
                    //         <div className="row">
                    //             <div className="col-md-2">
                    //                 <label className="m-b-0 text-bold">Helper's Name</label>
                    //                 <p className="m-b-0"><a data-toggle="modal" data-target="#profileInfoModalHelperDriver" href="#" onClick={() => this.setTempHelper(item.user_id)}>{item.full_name}</a><b /></p>
                    //             </div>

                    //             {
                    //                 (
                    //                     !item.address_type
                    //                     ||
                    //                     item.address_type == ""
                    //                 )
                    //                 &&
                    //                 <React.Fragment>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             this.state.details.source_address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="text-bold m-b-0">Address <b className="text-success">(Origin)</b></label>
                    //                                 <p className="m-b-20">{this.state.details.source_address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             this.state.details.destination_address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="text-bold m-b-0">Address <b className="text-success">(Destination)</b></label>
                    //                                 <p className="m-b-20">{this.state.details.destination_address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                 </React.Fragment>
                    //             }
                    //             {
                    //                 item.address_type
                    //                 &&
                    //                 <React.Fragment>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             item.address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="m-b-0 text-bold">Address <b className="text-success">({item.address_type || "---"})</b></label>
                    //                                 <p className="m-b-0">{item.address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                     <div className="col-md-3">
                    //                     </div>
                    //                 </React.Fragment>
                    //             }
                    //             <div className="col-md-2">
                    //                 <label className="m-b-0 text-bold">Phone</label>
                    //                 <p className="m-b-0">{item.contact_no}</p>
                    //             </div>
                    //             <div className="col-md-2 text-right">
                    //                 {/* <a href="" className="m-r-10" onClick={(e) => this.openMsgSection(e, item.user_id, 'helper')}><i className="icon-envelope"></i></a> */}
                    //                 {item.status == 0 && <span className="text-primary">Pending</span>}
                    //                 {item.status == 1 && <span className="text-info">Communicating</span>}
                    //                 {item.status == 2 && <span className="text-danger">Declined</span>}
                    //             </div>
                    //         </div>
                    //         <div className="row">
                    //             <div className="col-md-10">
                    //                 <label className="m-b-0 text-bold">Services</label>
                    //                 <p className="m-b-0">{item.services_needed || "---"}</p>
                    //             </div>
                    //             {
                    //                 item.status == 0 &&
                    //                 item.award_status == 0
                    //                 &&
                    //                 <div className="col-md-2 text-right">
                    //                     <a href="" className="btn btn-success btn-xs m-r-5" onClick={(e) => this.awardDeclineOffers(e, 1, item.user_id, this.state.details.id)}>Award</a>
                    //                     <a href="" className="btn btn-default btn-xs" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}>Decline</a>
                    //                 </div>
                    //             }
                    //             {
                    //                 item.award_status == 1
                    //                 &&
                    //                 item.status != 2
                    //                 &&
                    //                 <div className="col-md-2 text-right">
                    //                     <p className="text-muted"><span className="text-success"><i className="icon-checkmark-circle"></i></span> Awarded</p>
                    //                 </div>
                    //             }
                    //         </div>
                    //     </div>
                    // </div>
                )
            });

            return invited;
        } else {
            // return <h6 className="text-grey-600">No result found.</h6>
        }
    }
    // Invited function
    renderInvitedMover() {
        if (this.state.details.invited.mover.length > 0) {
            let invited = this.state.details.invited.mover.map((item, i) => {
                return (
                    <React.Fragment key={'invited-mover-' + i}>
                        <tbody>

                            <tr>
                                <td style={{ width: '35%' }}>
                                    <div className="media">
                                        <div className="media-left">
                                            <Link to="#">
                                                <img className="media-object" src={item.profile_image} alt="..." width="35" />
                                            </Link>
                                        </div>
                                        <div className="media-body">
                                            <h6 className="m-0">
                                                <a href="javascript:void(0)" onClick={() => this.setTempHelper(item.user_id)}>{item.full_name}</a>
                                                <span className="m-l-5 m-b-0 text-orange">
                                                    <span className="icon-star-full2"></span>
                                                    <span className="icon-star-full2"></span>
                                                    <span className="icon-star-full2"></span>
                                                    <span className="icon-star-full2"></span>
                                                    <span className="icon-star-empty3"></span>
                                                </span>
                                            </h6>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    {item.status == 0 && <span className="text-primary">Pending</span>}
                                    {item.status == 1 && <span className="text-info">Communicating</span>}
                                    {item.status == 2 && <span className="text-danger">Declined</span>}
                                    {item.status == 4 && <span className="text-danger">Cancel</span>}
                                </td>
                                {
                                    item.status == 0 &&
                                    item.award_status == 0
                                    &&
                                    this.state.permission &&
                                    <React.Fragment>
                                        {/* <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 1, item.user_id, this.state.details.id)}><i className="icon-checkmark4 f-s-20 text-success"></i></a></td> */}
                                        <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}><i className="icon-blocked f-s-20 text-danger"></i></a></td>
                                        {/* <td className="count text-center">
                                            <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                                <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                            </a>
                                        </td> */}
                                        {
                                            (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                                            &&
                                            <td className="text-center"><a href=""
                                                data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                                onClick={this.initChatBox.bind(
                                                    this, item.user_id, item.full_name, this.state.id, item.profile_image
                                                )}>
                                                <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                                        }
                                    </React.Fragment>
                                }
                                {
                                    item.award_status == 1
                                    &&
                                    item.status != 2
                                    &&
                                    this.state.permission &&
                                    <React.Fragment>
                                        {/* <td className="text-muted text-center"><span className="text-success"><i className="icon-checkmark-circle"></i></span> Awarded</td> */}
                                        <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}><i className="icon-blocked f-s-20 text-danger"></i></a></td>
                                        {/* <td className="count text-center">
                                            <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                                <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                            </a>
                                        </td> */}
                                        {
                                            (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                                            &&
                                            <td className="text-center"><a href=""
                                                data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                                onClick={this.initChatBox.bind(
                                                    this, item.user_id, item.full_name, this.state.id, item.profile_image
                                                )}>
                                                <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                                        }
                                    </React.Fragment>
                                }
                                {
                                    item.status == 2 &&
                                    (item.award_status == 0 || item.award_status == 1)
                                    &&
                                    this.state.permission &&
                                    <React.Fragment>
                                        {/* <td className="text-center"><a href="" onClick={(e) => this.reAwardDeclineOffers(e, 1, item.user_id, this.state.details.id)}><i className="icon-checkmark4 f-s-20 text-success"></i></a></td> */}
                                        <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}><i className="icon-blocked f-s-20 text-danger"></i></a></td>
                                        {/* <td className="count text-center">
                                        <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                            <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                        </a>
                                    </td> */}
                                        {
                                            (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                                            &&
                                            <td className="text-center"><a href=""
                                                data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                                onClick={this.initChatBox.bind(
                                                    this, item.user_id, item.full_name, this.state.id, item.profile_image
                                                )}>
                                                <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                                        }
                                    </React.Fragment>
                                }
                                <td className="text-center">
                                    <a href="" onClick={(e) => this.shareApp(e, item.user_id)}><i className="icon-share3"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </React.Fragment>
                    // <div className="well p-0 m-b-20" key={'invited-mover-' + i}>
                    //     <div className="p-15">
                    //         <div className="row">
                    //             <div className="col-md-2">
                    //                 <label className="m-b-0 text-bold">Mover's Company Name</label>
                    //                 <p className="m-b-0"><a href="javascript:void(0)" data-toggle="modal" data-target="#profileInfoModal" onClick={(e) => this.setTempMover(e, item.user_id)}>{item.full_name}</a><b /></p>
                    //             </div>


                    //             {
                    //                 (
                    //                     !item.address_type
                    //                     ||
                    //                     item.address_type == ""
                    //                 )
                    //                 &&
                    //                 <React.Fragment>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             this.state.details.source_address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="text-bold m-b-0">Address <b className="text-success">(Origin)</b></label>
                    //                                 <p className="m-b-20">{this.state.details.source_address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             this.state.details.destination_address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="text-bold m-b-0">Address <b className="text-success">(Destination)</b></label>
                    //                                 <p className="m-b-20">{this.state.details.destination_address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                 </React.Fragment>
                    //             }
                    //             {
                    //                 item.address_type
                    //                 &&
                    //                 <React.Fragment>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             item.address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="m-b-0 text-bold">Address <b className="text-success">({item.address_type || "---"})</b></label>
                    //                                 <p className="m-b-0">{item.address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                     <div className="col-md-3">
                    //                     </div>
                    //                 </React.Fragment>
                    //             }

                    //             <div className="col-md-2">
                    //                 <label className="m-b-0 text-bold">Phone</label>
                    //                 <p className="m-b-0">{item.contact_no}</p>
                    //             </div>
                    //             <div className="col-md-2 text-right">
                    //                 {/* <a href="" className="m-r-10" onClick={(e) => this.openMsgSection(e, item.user_id, 'mover')}><i className="icon-envelope"></i></a> */}
                    //                 {item.status == 0 && <span className="text-primary">Pending</span>}
                    //                 {item.status == 1 && <span className="text-info">Communicating</span>}
                    //                 {item.status == 2 && <span className="text-danger">Declined</span>}
                    //             </div>
                    //         </div>
                    //         <div className="row">
                    //             <div className="col-md-10">
                    //                 <label className="m-b-0 text-bold">Services</label>
                    //                 <p className="m-b-0">{item.services_needed || "---"}</p>
                    //             </div>
                    //             {
                    //                 item.status == 0 &&
                    //                 item.award_status == 0
                    //                 &&
                    //                 <div className="col-md-2 text-right">
                    //                     <a href="" className="btn btn-success btn-xs m-r-5" onClick={(e) => this.awardDeclineOffers(e, 1, item.user_id, this.state.details.id)}>Award</a>
                    //                     <a href="" className="btn btn-default btn-xs" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}>Decline</a>
                    //                 </div>
                    //             }
                    //             {
                    //                 item.award_status == 1
                    //                 &&
                    //                 item.status != 2
                    //                 &&
                    //                 <div className="col-md-2 text-right">
                    //                     <p className="text-muted"><span className="text-success"><i className="icon-checkmark-circle"></i></span> Awarded</p>
                    //                 </div>
                    //             }
                    //         </div>
                    //     </div>
                    // </div>
                )
            });

            return invited;
        } else {
            return <h6 className="text-grey-600">No result found.</h6>
        }
    }

    awardDeclineOffers = (e, status, user_id, id) => {
        e.preventDefault();
        if (status == 1) {
            ConfirmBox("You want to Award this Offer!")
                .then((result) => {
                    if (result) {
                        PostDataWithParam('AwardDeclineOfferByCustomer',
                            {
                                award_status: status,
                                offer_id: id,
                                request_to_user_id: user_id
                            })
                            .then(response => {
                                if (response.success === 1) {
                                    window.swal('Success!', response.msg, 'success');
                                    this.orderDetails();
                                } else {
                                    window.swal('Error!', response.msg, 'error');
                                    this.orderDetails();
                                }
                            })
                            .catch(err => {
                                this.orderDetails();
                            })
                    }
                })
        }
        else {
            ConfirmBox("You want to Cancel this Offer!")
                .then((result) => {
                    if (result) {
                        PostDataWithParam('AwardDeclineOfferByCustomer',
                            {
                                award_status: status,
                                offer_id: id,
                                request_to_user_id: user_id
                            })
                            .then(response => {
                                if (response.success === 1) {
                                    window.swal('Success!', response.msg, 'success');
                                    this.orderDetails();
                                } else {
                                    window.swal('Error!', response.msg, 'error');
                                    this.orderDetails();
                                }
                            })
                            .catch(err => {
                                this.orderDetails();
                            })
                    }
                })
        }

    }

    declineStaffOffers = (e, staff_id, id) => {
        e.preventDefault();
        this.setState({ processingDeclineStaff: true }, () => {
            PostDataWithParam('UnAssignedStaffsInOrder',
                {
                    order_id: id,
                    staff_id: staff_id
                })
                .then(response => {
                    if (response.success === 1) {
                        this.setState({ processingDeclineStaff: false })
                        window.swal('Success!', response.msg, 'success');
                        this.orderDetails();
                    } else {
                        this.setState({ processingDeclineStaff: false })
                        window.swal('Error!', response.msg, 'error');
                        this.orderDetails();
                    }
                })
                .catch(err => {
                    this.setState({ processingDeclineStaff: false })
                    this.orderDetails();
                })
        })

    }

    shareApp = (e, user_id) => {
        e.preventDefault();
        ConfirmBox("You want to Share this App!")
            .then((result) => {
                if (result) {
                    PostDataWithParam('ShareAppInvitedPros',
                        {
                            user_id: user_id
                        })
                        .then(response => {
                            if (response.success === 1) {
                                window.swal('Success!', "App Shared Successfully", 'success');
                                this.orderDetails();
                            } else {
                                window.swal('Error!', response.msg, 'error');
                                this.orderDetails();
                            }
                        })
                        .catch(err => {
                            window.swal('Sorry!', 'Something went wrong.', 'error');
                            this.orderDetails();
                        })
                }
            })
    }

    reAwardDeclineOffers = (e, status, user_id, id) => {
        e.preventDefault();
        PostDataWithParam('ReAwardOfferByCustomer',
            {
                award_status: status,
                offer_id: id,
                request_to_user_id: user_id
            })
            .then(response => {
                if (response.success === 1) {
                    window.swal('Success!', response.msg, 'success');
                    this.orderDetails();
                    // this.getConversationPeopleList(0, () => { });
                } else {
                    window.swal('Error!', 'Offer has already declined!', 'error');
                    this.orderDetails();
                    // this.getConversationPeopleList(0, () => { });
                }
            })
            .catch(err => {
                this.orderDetails();
                // this.getConversationPeopleList(0, () => { });
            })
    }

    setStaffProfile = (id) => {
        this.setState({ temp_helper_id: id }, () => {
            this.GetStaffProfile();
        });
    }

    GetStaffProfile = () => {
        this.setState({ modalLoader: true }, () => {
            PostDataWithParam('GetAllStaffs', { user_id: this.state.temp_helper_id })
                .then((response) => {
                    if (response.success === 1) {
                        let user_detail = this.state.user_detail;
                        user_detail = response.data[0];
                        if (response.data.available_services) {
                            user_detail.available_services = JSON.parse(response.data.available_services);
                        } else {
                            user_detail.available_services = [];
                        }
                        this.setState({ user_detail: user_detail, modalLoader: false, staffProfileShowModal: true }, () => {
                            // this.getAvailability();
                            window.$('#staffProfileInfoModal').modal('show');
                            console.log('user_detail', this.state.user_detail);

                        })
                    } else {
                        this.setState({ user_detail: [], modalLoader: false, staffProfileShowModal: false }, () => {
                            // this.getAvailability();
                            window.$('#staffProfileInfoModal').modal('hide');

                        })
                    }
                })
                .catch(err => {
                    this.setState({ user_detail: [], modalLoader: false, staffProfileShowModal: false }, () => {
                        // this.getAvailability();
                        window.$('#staffProfileInfoModal').modal('hide');

                    })
                })
        })
    }

    renderInvitedStaff() {
        if (this.state.details.total_assigned_staffs_list.length > 0) {
            let invited = this.state.details.total_assigned_staffs_list.map((item, i) => {
                return (
                    <React.Fragment key={'invited-driver-' + i}>
                        <tr>
                            <td style={{ width: '35%' }}>
                                <div className="media">
                                    <div className="media-left">
                                        <a href="#">
                                            <img className="media-object" src={item.profile_image} alt="..." width="35" />
                                        </a>
                                    </div>
                                    <div className="media-body">
                                        <h6 className="m-0">
                                            <a href="javascript:void(0)" onClick={() => this.setStaffProfile(item.request_to_user_id)}>{item.full_name}</a>
                                        </h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {item.status == 1 && <span className="text-info">Accepted</span>}
                                {item.status == 2 && <span className="text-danger">Declined</span>}
                                {item.status == 3 && <span className="text-danger">Closed</span>}
                                {item.status == 4 && <span className="text-danger">Cancel</span>}
                            </td>
                            {
                                this.state.permission &&
                                <React.Fragment>
                                    <td className="text-center"><a href="" onClick={(e) => this.declineStaffOffers(e, item.request_to_user_id, this.state.details.id)}><i className="icon-blocked f-s-20 text-danger"></i></a></td>
                                    {/* {
                                        (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                                        &&
                                        <td className="text-center"><a href=""
                                            data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                            onClick={this.initChatBox.bind(
                                                this, item.user_id, item.full_name, this.state.id, item.profile_image
                                            )}>
                                            <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                                    } */}
                                </React.Fragment>
                            }

                        </tr>
                    </React.Fragment>
                )
            });

            return invited;
        } else {
            // return <h6 className="text-grey-600">No result found.</h6>
        }
    }

    renderInvitedDriver() {
        if (this.state.details.invited.driver.length > 0) {
            let invited = this.state.details.invited.driver.map((item, i) => {
                return (
                    <React.Fragment key={'invited-driver-' + i}>
                        <tr>
                            <td style={{ width: '35%' }}>
                                <div className="media">
                                    <div className="media-left">
                                        <a href="#">
                                            <img className="media-object" src={item.profile_image} alt="..." width="35" />
                                        </a>
                                    </div>
                                    <div className="media-body">
                                        <h6 className="m-0">
                                            <a href="javascript:void(0)" onClick={() => this.setTempHelper(item.user_id)}>{item.full_name}</a>
                                            <span className="m-l-5 m-b-0 text-orange">
                                                <span className="icon-star-full2"></span>
                                                <span className="icon-star-full2"></span>
                                                <span className="icon-star-full2"></span>
                                                <span className="icon-star-full2"></span>
                                                <span className="icon-star-empty3"></span>
                                            </span>
                                        </h6>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {item.status == 0 && <span className="text-primary">Pending</span>}
                                {item.status == 1 && <span className="text-info">Communicating</span>}
                                {item.status == 2 && <span className="text-danger">Declined</span>}
                                {item.status == 4 && <span className="text-danger">Cancel</span>}
                            </td>
                            {
                                item.status == 0 &&
                                item.award_status == 0
                                &&
                                this.state.permission &&
                                <React.Fragment>
                                    {/* <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 1, item.user_id, this.state.details.id)}><i className="icon-checkmark4 f-s-20 text-success"></i></a></td> */}
                                    <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}><i className="icon-blocked f-s-20 text-danger"></i></a></td>
                                    {/* <td className="count text-center">
                                        <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                            <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                        </a>
                                    </td> */}
                                    {
                                        (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                                        &&
                                        <td className="text-center"><a href=""
                                            data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                            onClick={this.initChatBox.bind(
                                                this, item.user_id, item.full_name, this.state.id, item.profile_image
                                            )}>
                                            <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                                    }
                                </React.Fragment>
                            }
                            {
                                item.award_status == 1
                                &&
                                item.status != 2
                                &&
                                this.state.permission &&
                                <React.Fragment>
                                    {/* <td className="text-muted text-center"><span className="text-success"><i className="icon-checkmark-circle"></i></span> Awarded</td> */}
                                    <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}><i className="icon-blocked f-s-20 text-danger"></i></a></td>
                                    {/* <td className="count text-center">
                                        <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                            <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                        </a>
                                    </td> */}
                                    {
                                        (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                                        &&
                                        <td className="text-center"><a href=""
                                            data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                            onClick={this.initChatBox.bind(
                                                this, item.user_id, item.full_name, this.state.id, item.profile_image
                                            )}>
                                            <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                                    }
                                </React.Fragment>
                            }
                            {
                                item.status == 2 &&
                                (item.award_status == 0 || item.award_status == 1)
                                &&
                                this.state.permission &&
                                <React.Fragment>
                                    {/* <td className="text-center"><a href="" onClick={(e) => this.reAwardDeclineOffers(e, 1, item.user_id, this.state.details.id)}><i className="icon-checkmark4 f-s-20 text-success"></i></a></td> */}
                                    <td className="text-center"><a href="" onClick={(e) => this.awardDeclineOffers(e, 2, item.user_id, this.state.details.id)}><i className="icon-blocked f-s-20 text-danger"></i></a></td>
                                    {/* <td className="count text-center">
                                        <a href="#" onClick={(e) => this.openMessageModal(e, item.user_id, item.full_name)}>
                                            <i className="icon-envelope f-s-20 text-indigo-800"></i>
                                        </a>
                                    </td> */}
                                    {
                                        (JSON.parse(Storage.get('imnLoginData'))).id != item.user_id
                                        &&
                                        <td className="text-center"><a href=""
                                            data-chatusername={item.full_name} data-uid={this.state.id + '_' + item.user_id}
                                            onClick={this.initChatBox.bind(
                                                this, item.user_id, item.full_name, this.state.id, item.profile_image
                                            )}>
                                            <i className="icon-bubbles9 f-s-20 text-success"></i></a></td>
                                    }
                                </React.Fragment>
                            }
                            <td className="text-center">
                                <a href="" onClick={(e) => this.shareApp(e, item.user_id)}><i className="icon-share3"></i></a>
                            </td>
                        </tr>
                    </React.Fragment>
                    // <div className="well p-0 m-b-20" key={'invited-driver-' + i}>
                    //     <div className="p-15">
                    //         <div className="row">
                    //             <div className="col-md-2">
                    //                 <label className="m-b-0 text-bold">Driver's Name</label>
                    //                 <p className="m-b-0"><a data-toggle="modal" data-target="#profileInfoModalHelperDriver" href="#" onClick={() => this.setTempHelper(item.user_id)}>{item.full_name}</a><b /></p>
                    //             </div>
                    //             {
                    //                 (
                    //                     !item.address_type
                    //                     ||
                    //                     item.address_type == ""
                    //                 )
                    //                 &&
                    //                 <React.Fragment>

                    //                     <div className="col-md-3">
                    //                         {
                    //                             this.state.details.source_address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="text-bold m-b-0">Address <b className="text-success">(Origin)</b></label>
                    //                                 <p className="m-b-20">{this.state.details.source_address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             this.state.details.destination_address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="text-bold m-b-0">Address <b className="text-success">(Destination)</b></label>
                    //                                 <p className="m-b-20">{this.state.details.destination_address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                 </React.Fragment>
                    //             }
                    //             {
                    //                 item.address_type
                    //                 &&
                    //                 <React.Fragment>
                    //                     <div className="col-md-3">
                    //                         {
                    //                             item.address
                    //                             &&
                    //                             <React.Fragment>
                    //                                 <label className="m-b-0 text-bold">Address <b className="text-success">({item.address_type || "---"})</b></label>
                    //                                 <p className="m-b-0">{item.address || "---"}</p>
                    //                             </React.Fragment>
                    //                         }
                    //                     </div>
                    //                     <div className="col-md-3">
                    //                     </div>
                    //                 </React.Fragment>
                    //             }
                    //             <div className="col-md-2">
                    //                 <label className="m-b-0 text-bold">Phone</label>
                    //                 <p className="m-b-0">{item.contact_no}</p>
                    //             </div>
                    //             <div className="col-md-2 text-right">
                    //                 {/* <a href="" className="m-r-10" onClick={(e) => this.openMsgSection(e, item.user_id, 'driver')}><i className="icon-envelope"></i></a> */}

                    //                 {item.status == 0 && <span className="text-primary">Pending</span>}
                    //                 {item.status == 1 && <span className="text-info">Communicating</span>}
                    //                 {item.status == 2 && <span className="text-danger">Declined</span>}
                    //             </div>
                    //         </div>
                    //         <div className="row">

                    //         </div>
                    //     </div>
                    // </div>
                )
            });

            return invited;
        } else {
            // return <h6 className="text-grey-600">No result found.</h6>
        }
    }


    //get services from modal check box 
    setServices(e) {
        let form_data = this.state.form_data;
        if (form_data.services_needed.indexOf(e.target.value) > -1) {
            let services_needed = form_data.services_needed.filter((item, i) => {
                if (item == e.target.value)
                    return false;
                else
                    return true;
            });
            form_data.services_needed = services_needed;
        } else {
            form_data.services_needed.push(e.target.value)
        }

        this.setState({ form_data: form_data }, () => {

        });
    }
    changeRadio = (e) => {
        if (e.target.name == 'mover') {

            let mover_details = this.state.mover_details;

            mover_details['address_type'] = e.target.value;

            if (e.target.value == "Origin") {
                mover_details['address'] = this.state.details.source_address
            } else if (e.target.value == "Destination") {
                mover_details['address'] = this.state.details.destination_address
            } else {
                mover_details['address'] = ''
            }
            this.setState({ mover_details: mover_details });
        }
        else if (e.target.name == 'driver') {
            let driver_details = this.state.driver_details;

            driver_details['address_type'] = e.target.value;

            if (e.target.value == "Origin") {
                driver_details['address'] = this.state.details.source_address
            } else if (e.target.value == "Destination") {
                driver_details['address'] = this.state.details.destination_address
            } else {
                driver_details['address'] = ''
            }
            this.setState({ driver_details: driver_details });
        }
        else {
            let helper_details = this.state.helper_details;

            helper_details['address_type'] = e.target.value;

            if (e.target.value == "Origin") {
                helper_details['address'] = this.state.details.source_address
            } else if (e.target.value == "Destination") {
                helper_details['address'] = this.state.details.destination_address
            } else {
                helper_details['address'] = ''
            }
            this.setState({ helper_details: helper_details });
        }



    }
    inviteReloMovers = () => {

        if (this.state.mover_details.address_type == '' && this.state.mover_details.address == '') {
            this.setState({ helper_address_err: 'Please select at least one address' })
            return;
        }


        else if (this.state.form_data.services_needed.length <= 0) {

            this.setState({ helper_serv_err: 'Please select at least one service.' }, () => {
                //console.log(this.state.service_err)
            });

        }

        else {
            let addressDetails = this.state.mover_details;
            if (!this.state.details.source_address && this.state.details.destination_address) {
                addressDetails.address_type = 'Destination';
                addressDetails.address = this.state.details.destination_address;
                this.setState({ mover_details: addressDetails });
            }
            if (this.state.details.source_address && !this.state.details.destination_address) {
                addressDetails.address_type = 'Origin';
                addressDetails.address = this.state.details.source_address;
                this.setState({ mover_details: addressDetails });
            }


            this.setState({ helper_serv_err: '' });
            this.setState({ helper_address_err: '' });

            let form_data = this.state.form_data;
            let services_needed_str = form_data.services_needed;
            if (form_data.services_needed.length != 0) {
                let services_needed = form_data.services_needed.map((item, i) => {
                    return { name: item };
                });
                form_data.services_needed = JSON.stringify(services_needed);
            }
            let flag = this.state.type == "accepted" ? 'outsource' : '';
            this.props.history.push({
                pathname: '/independent-mover/dashboard/find-movers',
                state: {
                    order_request_id: this.state.id,
                    order_no: this.state.details.order_id,
                    type: flag,
                    address_type: this.state.mover_details.address_type,
                    address: this.state.mover_details.address,
                    services_needed: JSON.stringify(this.state.form_data.services_needed),
                    services_needed_str: services_needed_str,
                    request_from: this.state.request_from
                }
            })
            window.$("#chooseAddress").modal('hide')

        }
    }

    inviteReloHelpers = () => {

        // e.preventDefault();
        // console.log(this.state.helper_details)
        //     if (this.state.helper_details.address_type == '' && this.state.helper_details.address == '') {
        //         this.setState({ helper_address_err: 'Please select at least one address' })
        //         return;
        //     }
        //     else {
        //         let addressDetails = this.state.helper_details;
        //         if (!this.state.details.source_address && this.state.details.destination_address) {
        //             addressDetails.address_type = 'Destination';
        //             addressDetails.address = this.state.details.destination_address;
        //             this.setState({ helper_details: addressDetails });
        //         }
        //         if (this.state.details.source_address && !this.state.details.destination_address) {
        //             addressDetails.address_type = 'Origin';
        //             addressDetails.address = this.state.details.source_address;
        //             this.setState({ helper_details: addressDetails });
        //         }
        //     }
        //     let form_data = this.state.form_data;
        //     let services_needed_str = form_data.services_needed;

        //     if (form_data.services_needed.length != 0) {
        //         let services_needed = form_data.services_needed.map((item, i) => {
        //             return { name: item };
        //         });
        //         form_data.services_needed = JSON.stringify(services_needed);
        //     }

        //     let flag = (this.state.type == "accepted") ? 'outsource' : '';
        //     this.props.history.push({
        //         pathname: '/independent-mover/dashboard/find-helpers',
        //         state: {
        //             order_request_id: this.state.id,
        //             order_no: this.state.details.order_id,
        //             type: flag,
        //             address_type: this.state.helper_details.address_type,
        //             address: this.state.helper_details.address,
        //             services_needed: this.state.form_data.services_needed,
        //             services_needed_str: services_needed_str
        //         }
        //     })
        //     window.$("#chooseAddress").modal('hide')

        // }
        if (this.state.helper_details.address_type == '' && this.state.helper_details.address == '') {
            this.setState({ helper_address_err: 'Please select at least one address' })
            return;
        }


        else if (this.state.form_data.services_needed.length <= 0) {

            this.setState({ helper_serv_err: 'Please select at least one service.' }, () => {
                //console.log(this.state.service_err)
            });

        }

        else {
            let addressDetails = this.state.helper_details;
            if (!this.state.details.source_address && this.state.details.destination_address) {
                addressDetails.address_type = 'Destination';
                addressDetails.address = this.state.details.destination_address;
                this.setState({ helper_details: addressDetails });
            }
            if (this.state.details.source_address && !this.state.details.destination_address) {
                addressDetails.address_type = 'Origin';
                addressDetails.address = this.state.details.source_address;
                this.setState({ helper_details: addressDetails });
            }


            this.setState({ helper_serv_err: '' });
            this.setState({ helper_address_err: '' });

            let form_data = this.state.form_data;
            let services_needed_str = form_data.services_needed;
            if (form_data.services_needed.length != 0) {
                let services_needed = form_data.services_needed.map((item, i) => {
                    return { name: item };
                });
                form_data.services_needed = JSON.stringify(services_needed);
            }
            let flag = this.state.type == "accepted" ? 'outsource' : '';

            this.props.history.push({
                pathname: '/independent-mover/dashboard/find-helpers',
                state: {
                    order_request_id: this.state.id,
                    type: flag,
                    pathname: this.state.pathname,
                    activeFlag: this.state.activeFlag,
                    address_type: this.state.helper_details.address_type,
                    address: this.state.helper_details.address,
                    services_needed: this.state.form_data.services_needed,
                    services_needed_str: services_needed_str,
                    order_no: this.state.details.order_id,
                    request_from: this.state.request_from
                }
            })
            window.$("#chooseAddress").modal('hide')
        }
    }

    inviteReloDrivers = () => {
        let form_data = this.state.form_data;
        if (form_data.services_needed.length != 0) {
            let services_needed = form_data.services_needed.map((item, i) => {
                return { name: item };
            });
            form_data.services_needed = JSON.stringify(services_needed);
        }
        let flag = this.state.type == "accepted" ? 'outsource' : '';
        this.props.history.push({
            pathname: '/independent-mover/dashboard/find-drivers',
            state: {
                order_request_id: this.state.id,
                order_no: this.state.details.order_id,
                type: flag,
                address_type: this.state.driver_details.address_type,
                address: this.state.driver_details.address,
                services_needed: this.state.form_data.services_needed,
                request_from: this.state.request_from
            }
        })
        window.$("#chooseAddress").modal('hide')
    }

    getRatings = (e, userId, i) => {
        let ratings = this.state.ratings;

        if (e.target.name == "feedback") {
            ratings[i].public_review = e.target.value;
        } else {
            ratings[i].rate = e.target.value;
        }

        this.setState({ ratings: ratings })
    }
    ratingSubmit = () => {
        PostDataWithParam("GiveRatingReview", {
            order_request_detail_id: this.state.order_request_detail_id,
            rating_review: JSON.stringify(this.state.ratings),
            is_complete: 1
        }).then(response => {
            if (response.success == 1) {
                window.swal("Success!", response.msg, "success");
                this.props.history.push("/independent-mover/dashboard/closed-orders");
            } else {
                window.swal("Error!", response.msg, "error");
            }
        })
            .catch(err => { window.swal("Error!", 'Something went wrong!', "error"); });
    }

    getConversationMoverList() {
        PostDataWithParam('OrderRelatedConversationsPeopleList', { order_request_detail_id: this.state.id, order_type: this.state.order_type, conversations_profile_id: 2 })
            .then(response => {
                if (response.success == 1) {
                    let conversationMoverList = response.data;
                    let tempList = conversationMoverList.map((item, i) => {
                        return { ...item, conversations: [], msgCount: 0, nodeUrl: '' }
                    });
                    this.setState({ conversationMoverList: tempList }, () => {
                        window.$('.panel-collapsed').each(function () {
                            window.$(this).children('.panel-heading').nextAll().hide();
                        });
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                } else {
                    this.setState({ conversationMoverList: [] }, () => {
                        window.$('.panel-collapsed').each(function () {
                            window.$(this).children('.panel-heading').nextAll().hide();
                        });
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                }

            })
            .catch(err => { })
    }

    getConversationAdminList() {
        PostDataWithParam('OrderRelatedConversationsPeopleList', { order_request_detail_id: this.state.id, order_type: this.state.order_type, conversations_profile_id: 1 })
            .then(response => {
                if (response.success == 1) {
                    let conversationAdminList = response.data;
                    let tempList = conversationAdminList.map((item, i) => {
                        return { ...item, conversations: [], msgCount: 0, nodeUrl: '' }
                    });
                    this.setState({ conversationAdminList: tempList }, () => {
                        window.$('.panel-collapsed').each(function () {
                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                } else {
                    this.setState({ conversationAdminList: [] }, () => {
                        window.$('.panel-collapsed').each(function () {
                            window.$(this).children('.panel-heading').nextAll().hide();
                        });
                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                }

            })
            .catch(err => { })
    }

    getConversationHelperList() {
        PostDataWithParam('OrderRelatedConversationsPeopleList', { order_request_detail_id: this.state.id, order_type: this.state.order_type, conversations_profile_id: 5 })
            .then(response => {
                if (response.success == 1) {
                    let conversationHelperList = response.data;
                    let tempList = conversationHelperList.map((item, i) => {
                        return { ...item, conversations: [], msgCount: 0, nodeUrl: '' }
                    });
                    this.setState({ conversationHelperList: tempList }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {
                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                } else {
                    this.setState({ conversationHelperList: [] }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {
                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                }

            })
            .catch(err => { })
    }

    getConversationDriverList() {
        PostDataWithParam('OrderRelatedConversationsPeopleList', { order_request_detail_id: this.state.id, order_type: this.state.order_type, conversations_profile_id: 6 })
            .then(response => {
                if (response.success == 1) {
                    let conversationDriverList = response.data;
                    let tempList = conversationDriverList.map((item, i) => {
                        return { ...item, conversations: [], msgCount: 0, nodeUrl: '' }
                    });
                    this.setState({ conversationDriverList: tempList }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {
                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                } else {
                    this.setState({ conversationDriverList: [] }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {
                            window.$(this).children('.panel-heading').nextAll().hide();
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                }
            })
            .catch(err => { })
    }

    getConversationCustomerList() {
        PostDataWithParam('OrderRelatedConversationsPeopleList', { order_request_detail_id: this.state.id, order_type: this.state.order_type, conversations_profile_id: 4 })
            .then(response => {
                if (response.success == 1) {
                    let conversationCustomerList = response.data;
                    let tempList = conversationCustomerList.map((item, i) => {
                        return { ...item, conversations: [], msgCount: 0, nodeUrl: '' }
                    });
                    this.setState({ conversationCustomerList: tempList }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {
                            window.$(this).children('.panel-heading').nextAll().hide();
                        });
                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                } else {
                    this.setState({ conversationCustomerList: [] }, () => {
                        // Hide if collapsed by default
                        window.$('.panel-collapsed').each(function () {
                            window.$(this).children('.panel-heading').nextAll().hide()
                        });


                        // Rotate icon if collapsed by default
                        window.$('.panel-collapsed').find('[data-action=collapse]').addClass('rotate-180');
                    });
                }

            })
            .catch(err => { })
    }

    getMessages(user_id, flag, item, index, stateName, e) {
        let conversationPeopleList;

        if (stateName != "") {

            if (item.nodeUrl != "") {
                this.database.ref(item.nodeUrl).remove();
            }

            let tempState = this.state[stateName];

            for (let i = 0; i < tempState.length; i++) {
                if (i == index) {
                    tempState[i].msgCount = 0
                }
            }

            this.setState({ [stateName]: tempState });
        }

        PostDataWithParam('GetOrderConversationsMsg', { order_request_detail_id: this.state.id, conversations_user_id: user_id })
            .then(response => {
                if (response.success == 1) {
                    if (flag == 'helper')
                        conversationPeopleList = this.state.conversationHelperList;
                    else if (flag == 'driver')
                        conversationPeopleList = this.state.conversationDriverList;
                    else if (flag == 'mover')
                        conversationPeopleList = this.state.conversationMoverList;
                    else if (flag == 'admin')
                        conversationPeopleList = this.state.conversationAdminList;
                    else if (flag == 'customer')
                        conversationPeopleList = this.state.conversationCustomerList;

                    let tempList = conversationPeopleList.map((item, i) => {
                        if (item.conversations_user_id == user_id) {
                            let tempItem = item;
                            tempItem.conversations = response.data;
                            return tempItem;
                        } else {
                            return item;
                        }
                    });

                    if (flag == 'helper') {
                        this.setState({ conversationHelperList: tempList })
                    }
                    else if (flag == 'driver') {
                        this.setState({ conversationDriverList: tempList })
                    }
                    else if (flag == 'mover') {
                        this.setState({ conversationMoverList: tempList })
                    }
                    else if (flag == 'admin') {
                        this.setState({ conversationAdminList: tempList })
                    }
                    else if (flag == 'customer') {
                        this.setState({ conversationCustomerList: tempList })
                    }

                } else {
                    if (flag == 'helper')
                        conversationPeopleList = this.state.conversationHelperList;
                    else if (flag == 'driver')
                        conversationPeopleList = this.state.conversationDriverList;
                    else if (flag == 'mover')
                        conversationPeopleList = this.state.conversationMoverList;
                    else if (flag == 'admin')
                        conversationPeopleList = this.state.conversationAdminList;
                    else if (flag == 'customer')
                        conversationPeopleList = this.state.conversationCustomerList;

                    let tempList = conversationPeopleList.map((item, i) => {
                        if (item.conversations_user_id == user_id) {
                            let tempItem = item;
                            tempItem.conversations = [];
                            return tempItem;
                        } else {
                            return item;
                        }
                    });

                    if (flag == 'helper') {
                        this.setState({ conversationHelperList: tempList })
                    }
                    else if (flag == 'driver') {
                        this.setState({ conversationDriverList: tempList })
                    }
                    else if (flag == 'mover') {
                        this.setState({ conversationMoverList: tempList })
                    }
                    else if (flag == 'admin') {
                        this.setState({ conversationAdminList: tempList })
                    }
                    else if (flag == 'customer') {
                        this.setState({ conversationCustomerList: tempList })
                    }
                }
            })
            .catch(err => {

            })
        this.setState({ msg_count_user_id: user_id, msg_count_user_flag: flag })
    }

    handleOnChange(e) {

        let form_data = Object.assign({}, this.state.form_data);
        form_data[e.target.name] = e.target.value;
        if (e.target.name == 'contact_no' || e.target.name == 'home_contact_no' || e.target.name == 'releasing_agent_phone' || e.target.name == 'receiving_agent_phone') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                form_data[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
        }
        this.setState({ form_data: form_data });
        //this.setState({ message: e.target.getContent() })


    }

    handleFileChange(e) {
        this.setState({ attachment: e.target.files });
    }

    sendMessage(conversations_user_id, flag, j, e) {
        e.preventDefault();
        let formData = new FormData();
        if (this.state.attachment.length > 0) {
            for (let i = 0; i < this.state.attachment.length; i++) {
                formData.append('file_name[' + i + ']', this.state.attachment[i], this.state.attachment[i].name);
            }
        }
        formData.append('order_request_detail_id', this.state.id);
        formData.append('conversations_user_id', conversations_user_id);
        formData.append('message', this.state.message);

        PostDataFile('SentOrderConversationsMsg', formData)
            .then(response => {
                if (response.success == 1) {
                    window.swal('Success!', response.msg, 'success');
                    if (flag == 'helper')
                        this.state['activeEditor_' + j].setContent('');
                    else if (flag == 'driver')
                        this.state['activeDriverEditor_' + j].setContent('');
                    else if (flag == 'mover')
                        this.state['activeMoverEditor_' + j].setContent('');
                    else if (flag == 'admin')
                        this.state['activeAdmin_' + j].setContent('');
                    else if (flag == 'customer')
                        this.state['activeCustomer_' + j].setContent('');

                    this.setState({ message: '', attachment: [] })
                    this.getMessages(conversations_user_id, flag, '', '', '', null)
                } else {
                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => {

            })
    }
    closeForProposal = (e) => {
        e.preventDefault();
        PostDataWithParam('CloseProposal', { order_id: this.state.id })
            .then(response => {
                if (response.success == 1) {
                    window.swal('Success!', response.msg, 'success');
                    this.props.history.push('/independent-mover/dashboard/order-details');
                } else {
                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => {
                window.swal('Error!', err, 'error');
            })
    }
    cancelThisJob = (e) => {
        e.preventDefault();
        ConfirmBox("You want to cancel this Offer !")
            .then((result) => {
                if (result) {

                    PostDataWithParam('CloseOwnPostedOrder', { order_id: this.state.id })
                        .then(response => {
                            if (response.success == 1) {
                                window.swal('Success!', response.msg, 'success');
                                this.props.history.push('/independent-mover/dashboard/closed-orders');
                            } else {
                                window.swal('Error!', response.msg, 'error');
                            }
                        })
                        .catch(err => {
                            window.swal('Error!', err, 'error');
                        })
                }
            });
    }

    renderHelperConversations() {
        if (this.state.conversationHelperList.length > 0) {
            let conversationHelperList = this.state.conversationHelperList.map((item, i) => {
                return (
                    <div className="panel panel-flat profile-page panel-collapsed" key={'helper-' + i}>
                        <a href="javascript:void(0);" id={'conversations_people_' + item.conversations_user_id} data-toggle="collapse" data-target={"#helper-messagebody-" + i} onClick={this.getMessages.bind(this, item.conversations_user_id, 'helper', item, i, 'conversationHelperList')}>
                            <div className="panel-heading">
                                <div className="heading-elements">
                                    <ul className="icons-list">
                                        <li><i className="icon-arrow-down22"></i></li>
                                    </ul>
                                </div>

                                <h6 className="m-b-20">{item.full_name} <span className="badge bg-green m-l-15">{this.state.total_Msg.helperTotalMsg = item.msgCount}</span></h6>

                            </div>
                        </a>
                        {(this.state.staff_user_id == "" || this.state.permission) &&
                            <div id={"helper-messagebody-" + i} className="collapse">
                                <div className="panel-body p-b-0 editor-box" id={"editor-box-in-helper" + i} >
                                    <form action="" method="POST" onSubmit={this.sendMessage.bind(this, item.conversations_user_id, 'helper', i)}>
                                        <div className="form-group">
                                            <label className="control-label">Write Something</label>
                                            {/* <Editor
                                                placeHolder="Write Something ..."
                                                initialValue=""
                                                init={{
                                                    setup: editor => {
                                                        this.setState({ ['activeEditor_' + i]: editor })
                                                    },
                                                    menubar: false,
                                                    statusbar: false,
                                                    toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                                }}
                                                onChange={this.handleOnChange.bind(this)}
                                                className="form-control textarea-editor"
                                                name="qutationMessage"
                                                id={'qutationHelperMessage-' + i}
                                            /> */}
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="file-label">
                                                    <input className="file-input" name="resume" type="file" onChange={this.handleFileChange.bind(this)} multiple={true} />
                                                    <span className="text-muted">
                                                        <i className="icon icon-attachment"></i> Attach File
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <hr />
                                <div className="panel-body">
                                    {this.renderMessages(item.conversations)}
                                </div>
                            </div>}
                    </div>
                )
            })
            return conversationHelperList
        } else {
            return <div className="alert alert-primary border-none text-center">No Helpers Found.</div>
        }
    }

    getExtension(file_name) {
        let file = file_name.split('.');
        return file[file.length - 1];
    }

    renderMessages = (messages) => {
        if (messages.length > 0) {
            let msg = messages.map((item, i) => {
                return (
                    <React.Fragment key={'message-' + i}>
                        <div className="message-body clearfix">
                            <div className="content-group-sm">
                                <div className="media">
                                    <span className="media-left">
                                        <span className={((item.conversations_user_id == (JSON.parse(Storage.get('imnLoginData'))).id) ? "bg-primary-400" : "bg-warning") + " btn btn-rounded btn-icon btn-xs"}>
                                            <span className="letter-icon">{item.full_name.substr(0, 1)}</span>
                                        </span>
                                    </span>
                                    <div className="media-body">
                                        <span className="media-heading text-semibold">{item.full_name} </span>
                                        <div className="text-size-mini text-muted">
                                            {DateFormat.getFormattedDateTime(item.msg_time, 'm-d-Y,h:i')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: item.message }}></p>
                            <div className="tabbable attachedment-files">
                                <div className="file-manager">
                                    <ul>
                                        {item.quotetion_files.length > 0 && item.quotetion_files.map((file, j) => {
                                            return (
                                                <li key={'file-' + j}>
                                                    <div className="file-wrapper filetype-image">
                                                        <div className="file-title"><a href={file.file_path}><i className={FileIcon(this.getExtension(file.file_name)) + " position-left text-primary"}></i>{file.file_name}</a></div>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {(i < (messages.length - 1)) && <hr />}
                    </React.Fragment>
                )
            });

            return msg;
        }
        else {
            return <div className="alert alert-primary border-none text-center">No Files Were Found.</div>
        }
    }

    renderDriverConversations() {
        if (this.state.conversationDriverList.length > 0) {
            let conversationDriverList = this.state.conversationDriverList.map((item, i) => {
                return (
                    <div className="panel panel-flat profile-page panel-collapsed" key={'driver-' + i}>
                        <a href="javascript:void(0);" id={'conversations_people_' + item.conversations_user_id} data-toggle="collapse" data-target={"#driver-messagebody-" + i} onClick={this.getMessages.bind(this, item.conversations_user_id, 'driver', item, i, 'conversationDriverList')}>
                            <div className="panel-heading">
                                <div className="heading-elements">
                                    <ul className="icons-list">
                                        <li><i className="icon-arrow-down22"></i></li>
                                    </ul>
                                </div>
                                <h6 className="m-b-20">{item.full_name} <span className="badge bg-green m-l-15">{this.state.total_Msg.driveTotalMsg = item.msgCount}</span></h6>
                            </div>
                        </a>
                        {(this.state.staff_user_id == "" || this.state.permission) &&
                            <div id={"driver-messagebody-" + i} className="collapse">
                                <div className="panel-body p-b-0 editor-box" id={"editor-box-in-driver" + i} >
                                    <form action="" method="POST" onSubmit={this.sendMessage.bind(this, item.conversations_user_id, 'driver', i)}>
                                        <div className="form-group">
                                            <label className="control-label">Write Something</label>
                                            {/* <Editor
                                                placeHolder="Write Something ..."
                                                initialValue=""
                                                init={{
                                                    setup: editor => {
                                                        this.setState({ ['activeDriverEditor_' + i]: editor })
                                                    },
                                                    menubar: false,
                                                    statusbar: false,
                                                    toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                                }}
                                                onChange={this.handleOnChange.bind(this)}
                                                className="form-control textarea-editor"
                                                name="qutationMessage"
                                                id={'qutationDriverMessage-' + i}
                                            /> */}
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="file-label">
                                                    <input className="file-input" name="resume" type="file" onChange={this.handleFileChange.bind(this)} multiple={true} />
                                                    <span className="text-muted">
                                                        <i className="icon icon-attachment"></i> Attach File
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <hr />
                                <div className="panel-body">
                                    {this.renderMessages(item.conversations)}
                                </div>
                            </div>}
                    </div>
                )
            })
            return conversationDriverList
        } else {
            return <div className="alert alert-primary border-none text-center">No Drivers Found.</div>
        }
    }
    closeActivePane = (e, profile) => {
        e.preventDefault();
        this.setState({ msg_count_user_id: '', msg_count_user_flag: '' });
    }

    renderMoverConversations() {
        if (this.state.conversationMoverList.length > 0) {
            let conversationMoverList = this.state.conversationMoverList.map((item, i) => {
                return (
                    <div className={i == 0 && this.state.details.hire_by_profile_id == 2 && (this.state.type == 'receive' || this.state.type == 'accepted') ? 'panel panel-flat profile-page' : "panel panel-flat profile-page panel-collapsed"} key={'mover-' + i}>

                        <a href="javascript:void(0)" id={'conversations_people_' + item.conversations_user_id} data-toggle="collapse" data-target={"#mover-messagebody-" + i} onClick={this.getMessages.bind(this, item.conversations_user_id, 'mover', item, i, 'conversationMoverList')}>
                            <div className="panel-heading">
                                <div className="heading-elements">
                                    <ul className="icons-list">
                                        <li><i className="icon-arrow-down22"></i></li>
                                    </ul>
                                </div>
                                <h6 className="m-b-20">{item.full_name} <span className="badge bg-green m-l-15">{this.state.total_Msg.moverTotalMsg = item.msgCount}</span></h6>
                            </div>
                        </a>
                        {(this.state.staff_user_id == "" || this.state.permission) &&
                            <div id={"mover-messagebody-" + i} className="collapse">
                                <div className="panel-body p-b-0 editor-box" id={"editor-box-in-mover" + i} >
                                    <form action="" method="POST" onSubmit={this.sendMessage.bind(this, item.conversations_user_id, 'mover', i)}>
                                        <div className="form-group">
                                            <label className="control-label">Write Something</label>
                                            {/* <Editor
                                                placeHolder="Write Something ..."
                                                initialValue=""
                                                init={{
                                                    setup: editor => {
                                                        this.setState({ ['activeMoverEditor_' + i]: editor })
                                                    },
                                                    menubar: false,
                                                    statusbar: false,
                                                    toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                                }}
                                                onChange={this.handleOnChange.bind(this)}
                                                className="form-control textarea-editor"
                                                name="qutationMessage"
                                                id={'qutationMoverMessage-' + i}
                                            /> */}
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="file-label">
                                                    <input className="file-input" name="resume" type="file" onChange={this.handleFileChange.bind(this)} multiple={true} />
                                                    <span className="text-muted">
                                                        <i className="icon icon-attachment"></i> Attach File
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <hr />
                                <div className="panel-body">
                                    {this.renderMessages(item.conversations)}
                                </div>
                            </div>}
                    </div>
                )
            })
            return conversationMoverList
        } else {
            return <div className="alert alert-primary border-none text-center">No Movers Found.</div>
        }
    }


    renderAdminConversations() {
        if (this.state.conversationAdminList.length > 0) {
            let conversationAdminList = this.state.conversationAdminList.map((item, i) => {
                return (
                    <div className="panel panel-flat profile-page panel-collapsed" key={'admin-' + i}>

                        <a href="javascript:void(0);" id={'conversations_people_' + item.conversations_user_id} data-toggle="collapse" data-target={"#admin-messagebody-" + i} onClick={this.getMessages.bind(this, item.conversations_user_id, 'admin', item, i, 'conversationAdminList')}>
                            <div className="panel-heading">
                                <div className="heading-elements">
                                    <ul className="icons-list">
                                        <li><i className="icon-arrow-down22"></i></li>
                                    </ul>
                                </div>
                                <h6 className="m-b-20">{item.full_name} <span className="badge bg-green m-l-15">{this.state.total_Msg.adminTotalMsg = item.msgCount}</span></h6>
                            </div>
                        </a>
                        {(this.state.staff_user_id == "" || this.state.permission) &&
                            <div id={"admin-messagebody-" + i} className="collapse">
                                <div className="panel-body p-b-0 editor-box" id={"editor-box-in-admin" + i} >
                                    <form action="" method="POST" onSubmit={this.sendMessage.bind(this, item.conversations_user_id, 'admin', i)}>
                                        <div className="form-group">
                                            <label className="control-label">Write Something</label>
                                            {/* <Editor
                                                placeHolder="Write Something ..."
                                                initialValue=""
                                                init={{
                                                    setup: editor => {
                                                        this.setState({ ['activeAdmin_' + i]: editor })
                                                    },
                                                    menubar: false,
                                                    statusbar: false,
                                                    toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                                }}
                                                onChange={this.handleOnChange.bind(this)}
                                                className="form-control textarea-editor"
                                                name="qutationMessage"
                                                id={'qutationAdminMessage-' + i}
                                            /> */}
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="file-label">
                                                    <input className="file-input" name="resume" type="file" onChange={this.handleFileChange.bind(this)} multiple={true} />
                                                    <span className="text-muted">
                                                        <i className="icon icon-attachment"></i> Attach File
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <hr />
                                <div className="panel-body">
                                    {this.renderMessages(item.conversations)}
                                </div>
                            </div>}
                    </div>
                )
            })
            return conversationAdminList
        } else {
            return <div className="alert alert-primary border-none text-center">No Admin Found.</div>
        }
    }
    renderCustomerConversations() {
        if (this.state.conversationCustomerList.length > 0) {
            let conversationCustomerList = this.state.conversationCustomerList.map((item, i) => {
                return (
                    <div className={this.state.details.hire_by_profile_id == 4 ? 'panel panel-flat profile-page' : "panel panel-flat profile-page panel-collapsed"} key={'customer-' + i}>
                        <a href="javascript:void(0)"
                            id={'conversations_people_' + item.conversations_user_id}
                            data-toggle="collapse" data-target={"#customer-messagebody-" + i}
                            onClick={this.getMessages.bind(this, item.conversations_user_id,
                                'customer', item, i, 'conversationCustomerList')}
                            className="customer_panel"
                        >

                            <div className="panel-heading">
                                <div className="heading-elements">
                                    <ul className="icons-list">
                                        <li><i className="icon-arrow-down22"></i></li>
                                    </ul>
                                </div>
                                <h6 className="m-b-20">{item.full_name}
                                    <span className="badge bg-green m-l-15">{this.state.total_Msg.customerTotalMsg = item.msgCount}</span>
                                </h6>
                            </div>
                        </a>
                        {(this.state.staff_user_id == "" || this.state.permission) &&
                            <div id={"customer-messagebody-" + i} className="collapse">
                                <div className="panel-body p-b-0 editor-box" id={"editor-box-in-customer" + i} >
                                    <form action="" method="POST" onSubmit={this.sendMessage.bind(this, item.conversations_user_id, 'customer', i)}>
                                        <div className="form-group">
                                            <label className="control-label">Write Something</label>
                                            {/* <Editor
                                                placeHolder="Write Something ..."
                                                initialValue=""
                                                init={{
                                                    setup: editor => {
                                                        this.setState({ ['activeCustomer_' + i]: editor })
                                                    },
                                                    menubar: false,
                                                    statusbar: false,
                                                    toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                                }}
                                                onChange={this.handleOnChange.bind(this)}
                                                className="form-control textarea-editor"
                                                name="qutationMessage"
                                                id={'qutationCustomerMessage-' + i}
                                            /> */}
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className="file-label">
                                                    <input className="file-input" name="resume" type="file" onChange={this.handleFileChange.bind(this)} multiple={true} />
                                                    <span className="text-muted">
                                                        <i className="icon icon-attachment"></i> Attach File
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <hr />
                                <div className="panel-body">
                                    {this.renderMessages(item.conversations)}
                                </div>
                            </div>}
                    </div>
                )
            })
            return conversationCustomerList
        } else {
            return <div className="alert alert-primary border-none text-center">No Customer Found.</div>
        }
    }

    setTempOrder = () => {
        window.$("#tracking-Move").modal('show');
        this.setState({ trackLoading: true }, () => {
            PostDataWithParam('TrackOrder', { order_request_detail_id: this.state.id })
                .then((response) => {
                    if (response.success == 1) {
                        this.setState({ tempTrackOrder: response.data, loading: false }, () => {
                            this.GettrackingList(this.state.details.military_flag);
                            this.toggleChange();
                        });
                    } else {
                        this.setState({ tempTrackOrder: [], loading: false });
                    }
                })
                .catch(err => {
                    this.setState({ tempTrackOrder: [], loading: false });
                })
        })
    }

    GettrackingList = (military_flag) => {
        let postData = { 'flag': military_flag };
        PostDataWithParam('GetAllOrderTrackingStatus', postData)
            .then((response) => {
                if (response.success == 1) {
                    this.setState({ tracking_list: response.data, trackLoading: false }, () => {
                        let tracking_list = this.state.tracking_list;
                        // console.log('tracking_list',tracking_list);
                        let count = this.state.tempTrackOrder.length;

                        for (let list_index in tracking_list) {

                            for (let track of this.state.tempTrackOrder) {

                                if (track.order_status_field == tracking_list[list_index].id) {
                                    tracking_list[list_index]['track_class'] = "complete";
                                    tracking_list[list_index]['track_date'] = track.order_status_time;
                                    tracking_list[list_index]['track_time'] = track.order_status_time;
                                    tracking_list[list_index]['track_btn_status'] = 1;
                                    tracking_list[list_index]['is_skip'] = track.is_skip;
                                    tracking_list[list_index]['skip_track_class'] = "skip";
                                    // if (tracking_list[list_index].id >= this.state.tracking_nxt_btn) {
                                    //     this.setState({ tracking_nxt_btn: parseInt(tracking_list[list_index].id) + 1 });
                                    // }
                                    //alert(tracking_list[list_index].id);
                                    if (parseInt(tracking_list[list_index].id) == 19) {
                                        this.setState({ tracking_nxt_btn: 6 });
                                    }
                                    else if (parseInt(tracking_list[list_index].id) == 7) {
                                        if (military_flag == 1)
                                            this.setState({ tracking_nxt_btn: 16 });
                                        else
                                            this.setState({ tracking_nxt_btn: 8 });
                                    }
                                    else if (parseInt(tracking_list[list_index].id) == 1) {
                                        this.setState({ tracking_nxt_btn: 20 });
                                    }
                                    else if (parseInt(tracking_list[list_index].id) == 20) {
                                        this.setState({ tracking_nxt_btn: 2 });
                                    }

                                    else if (parseInt(tracking_list[list_index].id) == 2) {
                                        this.setState({ tracking_nxt_btn: 3 });
                                    }

                                    else if (parseInt(tracking_list[list_index].id) == 16) {
                                        this.setState({ tracking_nxt_btn: 17 });
                                    }
                                    else if (parseInt(tracking_list[list_index].id) == 18 && military_flag == 1) {
                                        this.setState({ tracking_nxt_btn: 8 });
                                    }

                                    else if (parseInt(tracking_list[list_index].id) == 15) {
                                        this.setState({ tracking_nxt_btn: 14 });
                                    }
                                    else if (parseInt(tracking_list[list_index].id) == 5) {
                                        this.setState({ tracking_nxt_btn: 19 });
                                    }
                                    else if (parseInt(tracking_list[list_index].id) == 8) {
                                        this.setState({ tracking_nxt_btn: 12 } || { tracking_nxt_btn: 9 });
                                    }
                                    else if (parseInt(tracking_list[list_index].id) == 12 || parseInt(tracking_list[list_index].id) == 13) {
                                        this.setState({ tracking_nxt_btn: 15 });
                                    }
                                    else if (parseInt(tracking_list[list_index].id) + 1 == 9) {
                                        this.setState({ tracking_nxt_btn: 9 });
                                    }
                                    else {
                                        this.setState({ tracking_nxt_btn: parseInt(tracking_list[list_index].id) + 1 }, () => {
                                            // console.log('@', this.state.tracking_nxt_btn);
                                        });
                                    }
                                }
                            }
                        }

                        if (this.state.tracking_list.length > 0 && this.state.tempTrackOrder.length > 0)
                            tracking_list[count - 1]['track_class'] = "current";

                        this.setState({ tracking_list: tracking_list }, () => {

                            window.$("#tracking-Move").modal('show')
                            this.toggleChange();
                        });
                    });
                } else {
                    this.setState({ tracking_list: [], trackLoading: false });
                }
            })
            .catch(err => {
                this.setState({ tracking_list: [], trackLoading: false });
            })
    }

    changeDoneStatus = (id, title) => {
        window.$("#tracking-Move").modal('hide')
        window.$("#tracking-Move-done-status").modal('show')
        let postData = {
            order_request_detail_id: this.state.id,
            order_status_id: id
        }

        PostDataWithParam('SaveOrderTrackingStatus', postData)
            .then((response) => {
                if (response.success == 1) {
                    window.swal('Success!', response.msg, 'success')
                    window.$("#tracking-Move").modal('hide')
                    this.orderDetails();
                    window.$("#tracking-Move-done-status").modal('hide')
                } else {
                    window.swal('Error!', response.msg, 'error')
                    window.$("#tracking-Move-done-status").modal('hide')
                }
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error')
                window.$("#tracking-Move-done-status").modal('hide')
            })
    }

    changeMultipleDoneStatus = (sort_order) => {
        window.$("#tracking-Move").modal('hide')
        window.$("#tracking-Move-done-status").modal('show')
        let postData = {
            order_request_detail_id: this.state.id,
            tracking_status_sort_order_id: sort_order,
            military_flag: this.state.details.military_flag
        }
        PostDataWithParam('SaveOrderMultipleTrackingStatus', postData)
            .then((response) => {
                if (response.success == 1) {
                    window.swal('Success!', response.msg, 'success')
                    window.$("#tracking-Move").modal('hide')
                    this.orderDetails();
                    window.$("#tracking-Move-done-status").modal('hide')
                } else {
                    window.swal('Error!', response.msg, 'error')
                    window.$("#tracking-Move-done-status").modal('hide')
                }
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error')
                window.$("#tracking-Move-done-status").modal('hide')
            })
    }

    skipTrackStatus = (sort_order, skip_status) => {
        let postData = {
            order_request_detail_id: this.state.tempOrder.order_request_detail_id,
            tracking_status_sort_order_id: sort_order,
            military_flag: this.state.tempOrder.military_flag,
            is_skip: skip_status
        }
        PostDataWithParam('SkipOrderTrackingStatus', postData)
            .then((response) => {
                if (response.success == 1) {
                    window.swal('Success!', response.msg, 'success')
                    this.removeDataTable();
                    if (this.state.tabid === 'manageReloOrders')
                        this.GetReloOrderList();
                    if (this.state.tabid === 'manageEDCOrders')
                        this.getEDCOrderList();
                    // this.GetReloOrderList();
                    window.$("#tracking-Move").modal('hide')

                } else {
                    window.swal('Error!', response.msg, 'error')
                }
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error')
            })
    }

    // checkOrderType = (type) => {
    //     return this.props.history.location.state && this.props.history.location.state.pathname.lastIndexOf(type) !== -1;
    // }

    getFullAddress({ data, addressType }) {
        let houseAddress = addressType === 'primary_destination' ? data.destination_address : data.source_address;
        let fullAddressArr = houseAddress ? [houseAddress] : [];
        const addValue = name => {
            if (!!data[`${addressType}_${name}`])
                fullAddressArr.push(data[`${addressType}_${name}`])
        }
        addValue('city')
        addValue('zip')
        addValue('state')
        addValue('country')
        return fullAddressArr.length > 0 ? fullAddressArr.join(',') : '---'
    }

    getModalForInvitation = (e) => {
        e.preventDefault();
        let chooseAddressModal = '';
        window.$('input[type="radio"]').prop("checked", false);
        this.setState({ form_data: { ...this.state.form_data, services_needed: [] } })
        if (e.target.name == 'mover') {
            chooseAddressModal = e.target.name
        }
        else {
            chooseAddressModal = e.target.name
        }
        this.setState({ chooseAddressModal: chooseAddressModal });
    }

    setTempMover = (e, id) => {
        e.preventDefault();
        this.setState({ temp_mover_id: id }, () => {
            this.GetCompanyInfo()
        });
    }

    GetCompanyInfo = () => {
        this.setState({ modalLoader: true }, () => {
            PostDataWithParam('GetCompanyInfo', { company_user_id: this.state.temp_mover_id })
                .then((response) => {
                    if (response.success === 1) {
                        let services_provided = [];
                        if (response.data[0].services_provided) {
                            let services_provided_arr = JSON.parse(response.data[0].services_provided);
                            services_provided = services_provided_arr.map((item, i) => {
                                return item.name;
                            })
                        }

                        this.setState({ fields: response.data[0], services_provided_list: services_provided, modalLoader: false });

                    } else {
                        this.setState({ modalLoader: false });
                    }
                })
                .catch(err => {
                    this.setState({ modalLoader: false });
                })
        });
    }

    setTempHelper = (id) => {
        this.setState({ temp_helper_id: id }, () => {
            this.GetHelperProfile();
        });
    }

    GetHelperProfile = () => {
        this.setState({ modalLoader: true }, () => {
            PostDataWithParam('ViewHelperProfile', { user_id: this.state.temp_helper_id })
                .then((response) => {
                    if (response.success === 1) {
                        let user_detail = this.state.user_detail;
                        user_detail = response.data;
                        if (response.data.available_services) {
                            user_detail.available_services = JSON.parse(response.data.available_services);
                        } else {
                            user_detail.available_services = [];
                        }
                        this.setState({ user_detail: user_detail, modalLoader: false, profileShowModal: true }, () => {
                            this.getAvailability();
                            window.$('#profileInfoModalHelperDriver').modal('show');

                        })
                    } else {
                        this.setState({ user_detail: [], modalLoader: false, profileShowModal: false }, () => {
                            this.getAvailability();
                            window.$('#profileInfoModalHelperDriver').modal('hide');

                        })
                    }
                })
                .catch(err => {
                    this.setState({ user_detail: [], modalLoader: false, profileShowModal: false }, () => {
                        this.getAvailability();
                        window.$('#profileInfoModalHelperDriver').modal('hide');

                    })
                })
        })
    }

    getAvailability = () => {
        PostDataWithParam('GetAvailableDates', { type: '1', user_id: this.state.temp_helper_id })
            .then(response => {
                if (response.success === 1) {
                    let res_data = response.data

                    res_data.map((item, i) => {

                        if (res_data[i].is_morning == 1) {
                            res_data[i].color = '#8FBC8F'
                            res_data[i].title = 'Available AM'
                        }
                        if (res_data[i].is_evening == 1) {
                            res_data[i].color = '#008000'
                            res_data[i].title = 'Available PM'
                        }
                        res_data[i].modal_type = 'availability';
                        var end_date = new Date(res_data[i].date_to);
                        var end_date1 = end_date.setDate(end_date.getDate() + 1);
                        res_data[i].date_to = DateFormat.getFormattedDateTime(end_date1, 'm-d-Y');
                        res_data[i].end = DateFormat.getFormattedDateTime(end_date1, 'm-d-Y');
                    })
                    this.setState({ available_dates: res_data }, () => {
                        this.initFullcalendar();
                    })
                } else {
                    this.setState({ get_available_dates: [], available_dates: [] }, () => {
                        this.initFullcalendar();
                    })
                }
            })
            .catch(err => {
                this.setState({ get_available_dates: [], available_dates: [] }, () => {
                    this.initFullcalendar();
                })
            })
    }

    initFullcalendar = () => {
        let dt = new Date();
        let obj = this;

        window.$('.fullcalendar-basic').fullCalendar({
            header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,basicWeek,basicDay'
            },
            defaultDate: dt,
            editable: true,
            events: obj.state.available_dates
        });
    }


    toggleChange = () => {
        if (this.state.details.storage_needed == 1) {
            window.$('#drop4').show();
            window.$('#drop3').hide();
            window.$('#drop2').show();
            window.$('#drop1').hide();
            window.$("#div_10").show();
            window.$("#div_11").hide();
            window.$("#div_12").show();
            window.$("#div_13").hide();
            window.$("#div_14").show();
        }
        else {
            window.$('#drop3').show();
            window.$('#drop4').hide();
            window.$("#div_9").hide();
            window.$("#div_10").hide();
            window.$("#div_11").hide();
            window.$("#div_12").show();
            window.$("#div_13").hide();
            window.$("#div_14").show();
        }
    }

    ssnFormat = (str) => {
        if (str != null && str.length === 11) {
            str = 'XXX-XX-' + str.substr(7, 4);
            this.setState({
                ssn: str
            })
        } else {
            this.setState({
                ssn: "---"
            })
        }

    }

    renderConversations() {
        if (this.state.conversationPeopleList.length > 0) {
            let conversationPeopleList = this.state.conversationPeopleList.map((item, i) => {
                let tempMsgCount = 0;
                this.state.profileMsgCount.map((msgitem, i) => {
                    if (msgitem == item.conversations_user_id)
                        tempMsgCount = tempMsgCount + 1;
                });
                return (
                    (item.conversations_user_id != JSON.parse(Storage.get('imnLoginData')).id)
                        ?
                        <div id={"messagebody-" + item.conversations_user_id}>
                            {/* <hr className="m-t-0 m-b-0" /> */}
                            <div className="">
                                {this.renderMessages(item.conversations)}
                            </div>
                        </div>
                        :
                        <div></div>
                )
            });



            return (
                <React.Fragment>
                    <div className="conversation p-20 p-t-0">
                        <h3 className="m-t-0 m-b-20">Moving APT</h3>
                        <div className="editor-box">
                            <form>
                                <div className="form-group">
                                    <label className="control-label">Write Something</label>
                                    {/* <Editor
                                        placeHolder="Write Something ..."
                                        initialValue=""
                                        init={{
                                            setup: editor => {
                                                // this.setState({ ['activeEditor_' + item.conversations_user_id]: editor })
                                            },
                                            menubar: false,
                                            statusbar: false,
                                            toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                        }}
                                        onChange={this.handleOnChange.bind(this)}
                                        className="form-control textarea-editor"
                                        name="qutationMessage"
                                        id='qutationMessage'
                                    /> */}
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="file-label">
                                            <input className="file-input" name="resume" type="file" onChange={this.handleFileChange.bind(this)} multiple={true} />
                                            <span className="text-muted">
                                                <i className="icon icon-attachment"></i> Attach File
                                            </span>
                                        </label>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <hr className="m-t-0" />
                    <div className="">
                        {conversationPeopleList}
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <div className="panel panel-default conversation m-20 p-10">
                    <div className="panel-heading">
                        <div className="heading-elements">
                        </div>
                        <h6 className="text-grey-600 m-0">No conversations found.</h6>
                    </div>
                </div>
            );
        }
    }

    // toggleAdditionalInfo = (e) => {
    //     e.preventDefault();
    //     // this.setState(prevState => ({ isShowAdditionalInfo: !prevState.isShowAdditionalInfo }))
    //     window.$('.order-details-panel').slideToggle();
    // }

    // renderDriverInfo() {
    //     if (this.state.details.invited.driver.length > 0) {
    //         let invited = this.state.details.invited.driver.map((item, i) => {
    //             return (
    //                 <React.Fragment key={'invited-driver-' + i}>
    //                     <tr>
    //                         <td>{item.full_name}</td>
    //                         <td>{item.contact_no}</td>
    //                         <td>{item.email}</td>
    //                     </tr>
    //                 </React.Fragment>
    //             );
    //         })
    //         return invited;
    //     }
    // }


    getFilesModal() {
        PostDataWithParam("GetOfferFiles", {
            order_request_detail_id: this.state.id,
            file_group: this.state.file_group,
            is_file_save: 1     //to identify whether the file should be saved or not
        })
            .then((response) => {
                if (response.success == 1) {
                    // console.log(response.data.length)
                    if (response.data.length != 0)
                        this.setState({ filesView: [response.data[response.data.length - 1]] });
                    else
                        this.setState({ filesView: response.data });
                } else {
                    this.setState({ filesView: [] });
                }
            })
            .catch((err) => {
                this.setState({ filesView: [] });
            });
    }

    fileUpload = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ uploaded_files: e.target.files })
        // console.log('uploadFileName', this.state.form_data.uploadFileName)
        if (this.state.tmpUploadedFiles.length > 0) {
            let formData = new FormData();
            for (let i = 0; i < this.state.tmpUploadedFiles.length; i++) {
                formData.append('request_files[' + i + ']', this.state.tmpUploadedFiles[i], this.state.tmpUploadedFiles[i].name);
            }
            formData.append('hire_by_profile_id', 2);
            formData.append('file_group', this.state.file_group);
            formData.append('order_request_detail_id', this.state.id);
            formData.append('file_description', this.state.form_data.uploadFileDesc);
            formData.append('file_name', this.state.form_data.uploadFileName);

            this.setState({ isFileSaving: true }, () => {
                PostDataFile('UploadOfferFiles', formData)
                    .then(response => {
                        if (response.success == 1) {
                            this.setState({ viewSaveFile: true, showFilesTab: true, isFileSaving: false });
                            window.$('#FileUpload-Modal').modal('hide');
                            window.$('#FileUpload-Modal').trigger('reset');
                            window.swal('Success!', 'Saved Successfully', 'success');
                            let form_data = this.state.form_data;
                            form_data.id = response.data.order_request_detail_id;
                            this.getFiles();
                        } else {
                            window.swal('Error!', 'Something went wrong.', 'error');
                        }
                    })
                    .catch(err => {
                        console.log('err', err)
                        this.setState({ isFileSaving: false });
                        window.swal('Error!', 'Something went wrong.', 'error')
                    });
            })

        }
    }

    uploadFiles(e) {
        e.persist()
        this.setState({ filesModal: e.target.files })
        if (e.target.files.length > 0) {
            let previewURL = '';
            let file_type = e.target.files[0].name.split('.').pop();
            let file_id = '';
            let file_name = e.target.files[0].name;
            let fileReader = new FileReader();
            fileReader.onload = (ev) => {
                // this.setState({ file: ev.target.result })
                previewURL = ev.target.result
            }
            fileReader.readAsDataURL(e.target.files[0])
            let formData = new FormData();
            for (let i = 0; i < e.target.files.length; i++) {
                formData.append('request_files[' + i + ']', e.target.files[i], e.target.files[i].name);
            }
            formData.append('hire_by_profile_id', 2);
            formData.append('file_group', this.state.file_group);
            formData.append('order_request_detail_id', this.state.id);

            this.setState({ isProccesingFileUpload: true }, () => {
                FileUploadWithProgress('UploadOfferFiles', formData, (e) => {
                    var percent = Math.round((e.loaded / e.total) * 100);
                    this.setState({ uploadPercentage: percent })
                })
                    .then(response => {
                        if (response.success == 1) {
                            let form_data = this.state.form_data;
                            // form_data.id = response.data.order_request_detail_id;
                            form_data.previewURL = previewURL
                            form_data.file_type = file_type
                            form_data.file_name = file_name
                            form_data.file_id = file_id
                            let files = { previewURL: form_data.previewURL, file_id: '', file_name: form_data.file_name, file_type: form_data.file_type }
                            this.state.form_data.uploadOfferFiles.push(files)
                            console.log(this.state.form_data.uploadOfferFiles);
                            this.setState({ showFilesTab: true, isProccesingFileUpload: false, uploadPercentage: 0, form_data, tmpUploadedFiles: [...this.state.tmpUploadedFiles, e.target.files[0]] });

                            // this.getFiles();
                            // this.getFilesModal();
                        } else {
                            this.setState({ isProccesingFileUpload: false });
                            window.swal('Error!', 'Something went wrong.', 'error');
                        }
                    })
                    .catch(err => {
                        console.log('err', err)
                        this.setState({ isProccesingFileUpload: false });
                        window.swal('Error!', 'Something went wrong.', 'error')
                    });
            })

        }

    }

    getFileName(file) {
        let files = file.request_file.split("/");
        return files[files.length - 1];
    }

    deleteFile(id, e) {
        e.preventDefault();
        ConfirmBox("You want to delete this file!").then((result) => {
            if (result) {
                PostDataWithParam("DeleteOfferFiles", { id: id })
                    .then((response) => {
                        if (response.success == 1) {
                            window.swal("Deleted!", response.msg, "success");
                            this.getFiles();
                        }
                    })
                    .catch((err) => { });
            }
        });
    }

    getFileId = (e, file_id) => {
        e.preventDefault();
        let files = []
        this.state.sharedFileWith.map((item, i) => {
            if (item.document_id == file_id) {
                if (item.mover_flag == 1) {
                    files.push("mover")
                }
                if (item.helper_flag == 1) {
                    files.push("helper")
                }
                if (item.driver_flag == 1) {
                    files.push("driver")
                }
                if (item.customer_flag == 1) {
                    files.push("customer")
                }
            }
        })

        this.setState({ document_id: file_id, shareFile: files }, () => {
        });
    }

    shareWith = (e) => {
        e.preventDefault();
        let shareFile = this.state.shareFile;

        if (shareFile.indexOf(e.target.value) > -1) {
            let temp = shareFile.filter((item, i) => {
                if (item == e.target.value && e.target.checked == false)
                    return false;
                else
                    return true;
            });
            shareFile = temp;
        } else {
            shareFile.push(e.target.value);
        }
        this.setState({ shareFile: shareFile }, () => {
        });
    }
    submitShareWith = (e) => {
        e.preventDefault();
        this.state.mover_flag = 0;
        this.state.helper_flag = 0;
        this.state.driver_flag = 0;
        this.state.shareFile.filter(item => {
            if (item == 'mover') {
                this.state.mover_flag = 1;
            } else if (item == 'helper') {
                this.state.helper_flag = 1;
            }
            else if (item == 'driver') {
                this.state.driver_flag = 1;
            }
        })
        let user_id = (JSON.parse(Storage.get('customerLoginData'))).id;
        PostDataWithParam("ShareFile",
            {
                order_id: this.state.form_data.id,
                user_id: user_id,
                profile_id: 14,
                mover_flag: this.state.mover_flag,
                driver_flag: this.state.driver_flag,
                helper_flag: this.state.helper_flag,
                customer_flag: this.state.customer_flag,
                document_id: this.state.document_id
            }).then(response => {
                if (response.success === 1) {
                    this.getShareFileStatus(this.state.form_data.id);
                    window.swal('Share', response.msg, 'success');
                } else {
                    window.swal("Error", response.msg, 'Error')
                }
            }).catch(err => {
                window.swal("Error", err.msg, 'Error')
            })

    }

    getShareFileDetails = () => {
        PostDataWithParam("ShareFileDetails", { order_id: this.state.form_data.id, profile_id: 14 })
            .then(response => {
                if (response.success === 1) {
                    this.setState({ shareFileDetails: response.data }, () => {
                    })
                } else {
                    this.setState({ shareFileDetails: [] });
                }
            }).catch(err => {
                this.setState({ shareFileDetails: [] });
            })
    }
    getShareFileStatus = (orderId) => {
        PostDataWithParam("ShareFileStatus", { order_id: orderId })
            .then(response => {
                if (response.success === 1) {
                    this.setState({ sharedFileWith: response.data }, () => {
                    })
                } else {
                    this.setState({ sharedFileWith: [] });
                }
            }).catch(err => {
                this.setState({ sharedFileWith: [] });
            })
    }
    renderOrderFrom() {
        //debugger;
        if (this.state.details.admin_details.length > 0) {
            let order_from = this.state.details.admin_details.map((item, i) => {
                return (
                    <div className="well p-0 m-b-20" key={'admin-' + i}>
                        <div className="p-15">
                            <div className="row">
                                <div className="col-md-3">

                                    <React.Fragment>
                                        <label className="m-b-0">Name</label>
                                        <p className="m-b-0"><a href="javascript:void(0)" onClick={() => this.setTempHelper(item.user_id)}>{item.full_name}</a><b /></p>
                                    </React.Fragment>
                                </div>
                                <div className="col-md-2">
                                    <label className="m-b-0">Phone</label>
                                    <p className="m-b-0"><b>{item.contact_no}</b></p>
                                </div>
                                <div className="col-md-3">
                                    <label className="m-b-0">Email Id</label>
                                    <p className="m-b-0"><b>{item.email}</b></p>
                                </div>
                                <div className="col-md-4 text-right">
                                    {/* <a href="#" className="btn btn-default btn-xs m-r-5" onClick={(e) => this.openMsgSection(e, item.user_id)}>Message</a> */}
                                    <a href="" className="btn btn-default btn-xs user-chat"
                                        data-chatusername={item.full_name}
                                        data-uid={this.state.id + '_' + item.user_id}
                                        onClick={this.initChatBox.bind(
                                            this, item.user_id, item.full_name,
                                            this.state.id, item.profile_image)}>
                                        <i className="icon-comment-discussion">
                                        </i> Chat</a>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            });

            return order_from;
        } else {
            return <h6 className="text-grey-600">No result found.</h6>
        }
    }
    renderFiles() {
        if (this.state.files.length > 0) {
            let files = this.state.files.map((file, i) => {
                return (
                    <li key={"item-" + i}>
                        <div className={"file-wrapper " + FileType(file.file_type)}>
                            {/* <div className="overlay-file">
                    <a href="" className="text-info" data-toggle="modal" data-target="#sharefile" onClick={(e) => this.getFileId(e, file.id)}><i className="icon-share3 f-s-15"></i> <span className="show">Share</span></a>
                    <a
                      href=""
                      className="text-danger"
                      onClick={this.deleteFile.bind(this, file.id)}
                    >
                      <i className="icon-cancel-circle2"></i>
                      <span className="show">Delete</span>
                    </a>
    
                  </div> */}
                            {FileSvg(file.file_type, file.request_file)}
                            <div className="file-title">
                                <a href={file.request_file}>
                                    <i
                                        className={
                                            FileIcon(file.file_type) + " position-left text-primary"
                                        }
                                    ></i>{" "}
                                    {this.getFileName(file)}
                                </a>
                            </div>
                            <hr className="m-0" />
                            <div className="uploadfile-icons text-center">
                                {/* <a href="" className="text-info" data-toggle="modal" data-target="#sharefile" onClick={(e) => this.getFileId(e, file.id)}><i className="icon-share3 f-s-18"></i> <span className="show">Share</span></a> */}
                                {FileType(file.file_type) == "filetype-pdf" ?
                                    <a href={file.request_file} target="_blank"><i className="icon-eye4 f-s-18"></i> <span className="show">View</span></a>
                                    :
                                    <a href="" className="text-success" data-toggle="modal" data-target={"#showfile" + i}><i className="icon-eye4 f-s-18"></i> <span className="show">View</span></a>
                                }
                                <a href="" className="text-danger" onClick={this.deleteFile.bind(this, file.id)}><i className="icon-cancel-circle2 f-s-18"></i> <span className="show">Delete</span></a>
                                {
                                    (JSON.parse(Storage.get('imnLoginData'))).id !== file.uploaded_by_user_id &&
                                    <a href="javascript:void(0)" className="text-success" onClick={this.archivefile.bind(this, file.id)}><i className="icon-archive f-s-20"></i> <span className="show">Archive</span></a>
                                }
                                <a href={file.download_file_url} target="_blank" className="text-success"><i className="icon-download f-s-20"></i> <span className="show">Download</span></a>
                            </div>
                        </div>
                        <div id="sharefile" className="modal fade" role="dialog">
                            <div className="modal-dialog modal-xs">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        <h4 className="modal-title text-grey">Share With</h4>
                                    </div>
                                    <div className="modal-body text-grey">
                                        <div className="form-group m-b-10">
                                            <label className="checkbox-inline custom-checkbox check-success">
                                                <input type="checkbox" name="driver" value="driver" checked={this.state.shareFile.indexOf('driver') > -1} onChange={this.shareWith} /><span></span> Driver</label>
                                        </div>
                                        <div className="form-group m-b-10">
                                            <label className="checkbox-inline custom-checkbox check-success">
                                                <input type="checkbox" name="helper" value="helper" checked={this.state.shareFile.indexOf('helper') > -1} onChange={this.shareWith} /><span></span> Helper</label>
                                        </div>
                                        <div className="form-group m-b-10">
                                            <label className="checkbox-inline custom-checkbox check-success">
                                                <input type="checkbox" name="mover" value="mover" checked={this.state.shareFile.indexOf('mover') > -1} onChange={this.shareWith} /><span></span> Moving Company</label>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.submitShareWith}>Done</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* showfile Modal start */}

                        <div id={"showfile" + i} className="modal fade" role="dialog">
                            <div className="modal-dialog modal-sm">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        {/* <button type="button" className="close" data-dismiss="modal" onClick={(e) => this.closeModal("audio-video" + i)}>&times;</button> */}
                                        {FileType(file.file_type) == "filetype-image" &&
                                            <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">&times;</span></button>
                                        }
                                        {FileType(file.file_type) == "filetype-video" &&
                                            <button type="button" className="close" data-dismiss="modal" onClick={(e) => this.closeModal("audio-video" + i)}><span aria-hidden="true">&times;</span></button>
                                        }
                                        <h4 className="modal-title" style={{ color: "black" }}>File Details</h4>
                                    </div>
                                    <hr className="m-0" />
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="show-file-picture">
                                                    <div className={FileType(file.file_type)}>
                                                        {/* {FileSvg(file.file_type, file.request_file)} */}
                                                        {FileType(file.file_type) == "filetype-image" &&
                                                            <img src={file.request_file} />}

                                                        {FileType(file.file_type) == "filetype-video" &&
                                                            <video width="100%" height="100%" controls id={"audio-video" + i}>
                                                                <source src={file.request_file} type="video/mp4" />
                                                            </video>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="file-title m-t-15">
                                                    <h6 style={{ color: "black" }}><strong>File Name</strong></h6>
                                                    {/* <p style={{ color: "black" }}>{this.getFileName(file.file_name)}</p> */}
                                                    <p style={{ color: "black" }}><pre>{this.getFileName(file)}</pre></p>
                                                    <h6 style={{ color: "black" }}><strong>File Description</strong></h6>
                                                    <p style={{ color: "black" }}><pre>{file.file_description}</pre></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        {/* <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e)=>this.closeModal("audio-video"+i)}>Close</button> */}
                                        {FileType(file.file_type) == "filetype-image" &&
                                            <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                                        }
                                        {FileType(file.file_type) == "filetype-video" &&
                                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e) => this.closeModal("audio-video" + i)}>Close</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* showfile Modal end */}
                    </li>

                );
            });

            return files;
        } else {
            return <h6 className="alert alert-primary no-border m-b-0">No files were found.</h6>;
        }
    }

    closeModal(id) {
        window.$('#' + id)[0].pause();
        // window.$('#audio-video0')[0].pause()
    }


    openFileModal = (e) => {
        let form_data = { ...this.state.form_data, uploadFileName: '', uploadFileDesc: '' }
        form_data.uploadOfferFiles = []
        this.setState({ form_data, filesView: [], tmpUploadedFiles: [] }, () => {

            window.$('#FileUpload-Modal').modal('show')
        })
    }

    deleteFileModal(id, e) {
        e.preventDefault();
        ConfirmBox("You want to delete this file!").then((result) => {
            if (result) {
                PostDataWithParam("DeleteOfferFiles", { id: id })
                    .then((response) => {
                        if (response.success == 1) {
                            window.swal("Deleted!", response.msg, "success");
                            this.getFilesModal();
                        }
                    })
                    .catch((err) => { });
            }
        });
    }

    renderFilesModal() {
        // if (this.state.filesView.length > 0) {
        //     let files = this.state.filesView.map((file, i) => {
        if (this.state.form_data.uploadOfferFiles.length > 0) {
            let files = this.state.form_data.uploadOfferFiles.map((file, i) => {
                return (
                    <li key={"item-" + i}>
                        <div className={"file-wrapper " + FileType(file.file_type)}>
                            <div className="overlay-file">

                                <a href="javascript:void(0)" className="text-danger"
                                // onClick={this.deleteFileModal.bind(this, file.id)}
                                ><i className="icon-cancel-circle2"></i><span className="show">Delete</span>
                                </a>

                            </div>
                            {FileSvg(file.file_type, file.previewURL)}
                            <div className="file-title">
                                <a href={file.previewURL}>
                                    <i
                                        className={
                                            FileIcon(file.file_type) + " position-left text-primary"
                                        }
                                    ></i>{" "}
                                    {/* {this.getFileName(file)} */}
                                    {file.file_name}
                                </a>
                            </div>
                        </div>

                    </li>

                );
            });

            return files;
        } else {
            //   return <h6 className="text-grey-600">No files were found.</h6>;

        }

    }

    setServicesForMyTeam = (e) => {
        const value = e.target.value;
        const serviceNeededForMyTeam = [...this.state.serviceNeededForMyTeam];
        const findServiceIndex = serviceNeededForMyTeam.indexOf(value);
        if (findServiceIndex !== -1)
            serviceNeededForMyTeam.splice(findServiceIndex, 1);
        else
            serviceNeededForMyTeam.push(value);
        this.setState({ serviceNeededForMyTeam });
    }

    GetMoverNote = () => {

        PostDataWithParam('GetOrderNote', { order_id: this.state.id })
            .then(response => {
                if (response.success == 1) {
                    let from_date = response.data[0].from_date.split(" ")
                    let to_date = response.data[0].to_date.split(" ")
                    this.setState({
                        get_mover_fromDate: from_date[0], get_mover_toDate: to_date[0], get_mover_note: response.data[0].note, isGetMovernote: true,
                        showInventoryItems: true, showFilesTab: false, showMoverTab: true, showArchiveTab: false, showInventoryTab: false
                    })

                }
                else {
                    window.swal('Sorry!', response.msg, 'info')
                    this.setState({ isGetMovernote: false, showInventoryItems: true, showFilesTab: false, showMoverTab: false, showArchiveTab: false, showInventoryTab: false })
                }

            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error')
                this.setState({ isGetMovernote: false, showInventoryItems: true, showFilesTab: false, showMoverTab: false, showArchiveTab: false, showInventoryTab: false })
            })
    }
    renderRatingUsers = () => {
        if (this.state.details.total_invited_and_booked_users_list && this.state.details.total_invited_and_booked_users_list.length > 0) {

            let users = this.state.details.total_invited_and_booked_users_list.map((item, i) => {
                return (
                    <div className="panel-body" key={"rating-users-" + i}>
                        <div className="p-0">
                            <div className="row">
                                <div className="col-md-1">
                                    <img
                                        src={item.profile_image ? item.profile_image : "assets/images/default_avatar.jpg"}
                                        width="40"
                                        height="40"
                                        className="img-circle"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <h4 className="m-0 m-t-5">{item.full_name}</h4>
                                </div>
                                <div className="col-md-5 text-right">
                                    <div className="star-ratting m-0">
                                        <input type="checkbox" id={"st1_" + i} onChange={(e) => this.getRatings(e, item.user_id, i)} value="5" checked={(this.state.ratings[i] && this.state.ratings[i].rate > 4) ? true : false} />
                                        <label htmlFor={"st1_" + i} className="m-0" />
                                        <input type="checkbox" id={"st2_" + i} onChange={(e) => this.getRatings(e, item.user_id, i)} value="4" checked={(this.state.ratings[i] && this.state.ratings[i].rate > 3) ? true : false} />
                                        <label htmlFor={"st2_" + i} className="m-0" />
                                        <input type="checkbox" id={"st3_" + i} onChange={(e) => this.getRatings(e, item.user_id, i)} value="3" checked={(this.state.ratings[i] && this.state.ratings[i].rate > 2) ? true : false} />
                                        <label htmlFor={"st3_" + i} className="m-0" />
                                        <input type="checkbox" id={"st4_" + i} onChange={(e) => this.getRatings(e, item.user_id, i)} value="2" checked={(this.state.ratings[i] && this.state.ratings[i].rate > 1) ? true : false} />
                                        <label htmlFor={"st4_" + i} className="m-0" />
                                        <input type="checkbox" id={"st5_" + i} onChange={(e) => this.getRatings(e, item.user_id, i)} value="1" checked={(this.state.ratings[i] && this.state.ratings[i].rate == 1) ? true : false} />
                                        <label htmlFor={"st5_" + i} className="m-0" />
                                    </div>
                                </div>
                            </div>
                            <textarea
                                id={"feedbackId" + i}
                                className="form-control"
                                rows="1"
                                onChange={(e) => this.getRatings(e, item.user_id, i)}
                                placeholder="Write a feedback"
                                name="feedback"
                            />
                        </div>
                    </div>
                );
            });
            return users;
        } else {
            return <div className="alert alert-primary border-none text-center">No Users Found.</div>;
        }
    };

    getLocation = (id) => {
        this.setState({ loadUserLocation: true })
        window.$("#tracking-Move").modal('hide')
        // let user_id = [908, 909]  // For static checking
        if (this.state.booked_driver_user_ids.length == 0) {
            window.swal({
                title: "Sorry!",
                text: "No Booked & Confirmed Pro(s) found",
                className: "subscrip-info-swal",
                icon: ''
            })
        } else {
            let c = 0
            this.state.booked_driver_user_ids.forEach(id => {
                this.setLocation(id)
                c++
            });
            if (c == this.state.booked_driver_user_ids.length) {
                this.setState({ loadUserLocation: false }, () => {
                    window.$("#live-location").modal('show')
                })
            }
        }
    }

    setLocation(id) {
        this.database.ref(`/user_location/user_${id}`).on("value", (snapshot) => {
            let locationData = snapshot.val()
            if (Object.keys(locationData).length > 0) {
                this.state.locationData.push(locationData)
            }
        })
    }

    render() {
        var trackingStatus = this.state.details.tracking_status;
        if (this.state.details.tracking_status == 'Unloading Started / Shipment Being Cleared for Storage') {
            if (this.state.details.storage_needed == 1)
                trackingStatus = 'Shipment Being Cleared for Storage';
            else
                trackingStatus = 'Unloading Started';
        }
        if (this.state.details.tracking_status == 'Shipment Delivered to Residence-Self Storage Unit / Shipment Placed in Storage') {
            if (this.state.details.storage_needed == 1)
                trackingStatus = 'Shipment Placed in Storage';
            else
                trackingStatus = 'Shipment Delivered to Residence-Self Storage Unit';
        }
        if (trackingStatus == 'Shipment in Storage' && this.state.details.storage_needed == 1)
            this.state.tracking_nxt_btn = 12;
        if (trackingStatus == 'Unloading Started' && this.state.details.storage_needed == 0)
            this.state.tracking_nxt_btn = 12;
        return (
            <React.Fragment>
                {
                    this.state.loading
                    &&
                    <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                }
                {!this.state.loading
                    &&
                    <div className="row">
                        <div className="col-md-12">
                            {Breadcumb(this.state.breadcrumb)}
                            <div className="panel-heading well clearfix m-b-20 p-t-10 p-b-10">
                                <div className="clearfix">
                                    <h6 className="panel-title pull-left m-t-5 m-b-10">{this.state.details.order_type == 0 ? 'Relo' : 'Shipment'} <b>#{this.state.details.order_id}</b> Posted by <b>{this.state.details.offer_from && this.state.details.offer_from.organization_name}</b></h6>

                                    {(this.state.staff_user_id == "" || this.state.staff_user_id == null) &&

                                        <div className="pull-right">
                                            {

                                                <React.Fragment>
                                                    <ul className="icons-list mobile-icon-list">
                                                        <li>
                                                            {
                                                                (this.state.staff_user_id == "" || this.state.staff_user_id == null) &&
                                                                <div>
                                                                    {/* <button type="button" className="btn alpha-primary btn-xs m-r-10">'Done Assigning Resources'</button> */}
                                                                    {/* After Done mark as assigning resourses */}
                                                                    {/* <button type="button" className="btn alpha-primary btn-xs m-r-10"><i className="icon-checkmark4 text-success m-r-5"></i>Done Assigning Resourses</button> */}
                                                                    <button type="button" className="btn btn-primary btn-xs m-r-10" name="mover" data-toggle="modal" data-target="#chooseAddress" onClick={this.getModalForInvitation}>Invite Movers</button>
                                                                    <button type="button" className="btn btn-primary btn-xs m-r-10" name="driver" onClick={this.existingDriverModal}>Assign to My Team</button>
                                                                    <button type="button" className="btn btn-primary btn-xs" name="helper" data-toggle="modal" data-target="#chooseAddress" onClick={this.getModalForInvitation}>Invite a Pro</button>
                                                                </div>
                                                            }
                                                        </li>

                                                        {this.state.request_from == "own" && this.state.details.hire_by_user_id == (JSON.parse(Storage.get('imnLoginData'))).id &&
                                                            <li className="dropdown m-l-10 ">
                                                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                                    <i className="icon-menu9"></i>
                                                                </a>
                                                                <ul className="dropdown-menu dropdown-menu-right">
                                                                    <li><a href="#" data-toggle="modal" data-target="#RatingGive"><i className="icon-check2"></i> Mark as Completed</a></li>


                                                                    {/* <li><a href="#" onClick={this.closeForProposal}><i className="icon-cross2"></i>Close for Proposal</a></li> */}

                                                                    {this.state.booked_pros.length == 0 &&
                                                                        <li>
                                                                            <a href="#" onClick={this.cancelThisJob}><i className="icon-cross2"></i> Cancel this Job</a>
                                                                        </li>
                                                                    }

                                                                    <li><Link to={{
                                                                        pathname: '/independent-mover/dashboard/edit-order-details',
                                                                        state: {
                                                                            id: this.state.id,
                                                                            isNonMilitary: this.state.details.military_flag == "0" || this.state.details.military_flag == "1"

                                                                        }
                                                                    }}><i className="icon-pencil"></i> &nbsp; Edit Order</Link></li>

                                                                </ul>
                                                            </li>
                                                        }
                                                    </ul>
                                                </React.Fragment>
                                            }
                                        </div>
                                    }

                                    {this.state.staff_user_id != "" && this.state.custom_menu.map((menu, i) => {
                                        return (
                                            <div className="heading-elements">
                                                {(menu.title == "ReloOrders" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                    (this.state.request_from == "own"
                                                        ||
                                                        this.state.details.hire_by_profile_id == 4)
                                                    &&
                                                    <React.Fragment>
                                                        <ul className="icons-list">
                                                            <li className="dropdown">
                                                                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                                    <i className="icon-menu9"></i>
                                                                </a>
                                                                <ul className="dropdown-menu dropdown-menu-right">
                                                                    <li><a href="#" data-toggle="modal" data-target="#RatingGive"><i className="icon-check2"></i> Mark as Completed</a></li>
                                                                    {
                                                                        this.state.details.hire_by_profile_id != 4
                                                                        &&
                                                                        <li><a href="#" onClick={this.closeForProposal}><i className="icon-cross2"></i>Close for Proposal</a></li>
                                                                    }
                                                                    {/* <li><a href="#" onClick={this.cancelThisJob}><i className="icon-cross2"></i> Cancel this Job</a></li> */}

                                                                    <li><Link to={{
                                                                        pathname: '/independent-mover/dashboard/edit-order-details',
                                                                        state: {
                                                                            id: this.state.id,
                                                                            isNonMilitary: this.state.details.military_flag == "0"
                                                                        }
                                                                    }}><i className="icon-pencil"></i> &nbsp; Edit Order</Link></li>

                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        );
                                    })

                                    }
                                </div>
                                <ul className="inner-page navigation tab-navigation m-b-10 m-t-10 p-0">
                                    <li className={(this.state.tabid === 'order-summary' ? 'active' : '')}>
                                        <Link to="#" onClick={() => this.handleClickActiveTab('order-summary')} className="f-s-15 text-uppercase">Order Summary</Link>
                                    </li>
                                    <li className={(this.state.tabid === 'movers-pros' ? 'active' : '')}>
                                        <Link to="#" onClick={() => this.handleClickActiveTab('movers-pros')} className="f-s-15 text-uppercase">Movers &amp; Pros</Link>
                                    </li>
                                    <li className={(this.state.tabid === 'survey-file' ? 'active' : '')}>
                                        <Link to="#" onClick={() => this.handleClickActiveTab('survey-file')} className="f-s-15 text-uppercase">Files &amp; Attachments</Link>
                                    </li>
                                </ul>

                            </div>
                            <div id="order-summary">
                                <div className="panel panel-default profile-page">

                                    {/* {
                                    (this.state.staff_user_id=="" || this.state.staff_user_id==null) ?
                                    <div className="panel-heading text-center">
                                        <button type="button" className="btn btn-primary btn-xs m-r-10" name="mover" data-toggle="modal" data-target="#chooseAddress" onClick={this.getModalForInvitation}>Invite Movers</button>
                                        <button type="button" className="btn btn-primary btn-xs m-r-10" name="driver" onClick={this.existingDriverModal}>Assign to ReloDrivers</button>
                                        <button type="button" className="btn btn-primary btn-xs" name="helper" data-toggle="modal" data-target="#chooseAddress" onClick={this.getModalForInvitation}>Invite ReloHelpers</button>
                                    </div>
                                    :
                                    this.state.staff_user_id!="" && this.state.custom_menu.map((menu,i)=>{
                                        return(
                                            <div className="panel-heading text-center">
                                                {(menu.title=="ReloOrders" &&  (menu.is_add == 1 || menu.is_add == "1")) &&
                                                    <React.Fragment>
                                                    <button type="button" className="btn btn-primary btn-xs m-r-10" name="mover" data-toggle="modal" data-target="#chooseAddress" onClick={this.getModalForInvitation}>Invite Movers</button>
                                                    <button type="button" className="btn btn-primary btn-xs m-r-10" name="driver" onClick={this.existingDriverModal}>Assign to ReloDrivers</button>
                                                    <button type="button" className="btn btn-primary btn-xs" name="helper" data-toggle="modal" data-target="#chooseAddress" onClick={this.getModalForInvitation}>Invite ReloHelpers</button>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        );
                                    })
                                } */}


                                    {
                                        this.state.staff_user_id != "" && this.state.custom_menu.map((menu, i) => {
                                            return (
                                                <div>
                                                    {(menu.title == "ReloOrders" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                        <div className="panel-heading text-center">
                                                            <button type="button" className="btn btn-primary btn-xs m-r-10" name="mover" data-toggle="modal" data-target="#chooseAddress" onClick={this.getModalForInvitation}>Invite Movers</button>
                                                            <button type="button" className="btn btn-primary btn-xs m-r-10" name="driver" onClick={this.existingDriverModal}>Assign to My Team</button>
                                                            <button type="button" className="btn btn-primary btn-xs" name="helper" data-toggle="modal" data-target="#chooseAddress" onClick={this.getModalForInvitation}>Invite a Pro</button>
                                                        </div>
                                                    }
                                                </div>
                                            );
                                        })

                                    }

                                    <div className="panel-body">
                                        <h6 className="text-success">Customer Basic Info</h6>
                                        <div className="row">
                                            {this.state.details.military_flag == 1 && (
                                                <div>
                                                    {this.state.details.scac && (<div className="col-md-4">
                                                        <label className="text-bold m-b-0">SCAC</label>
                                                        <p className="m-b-20">{this.state.details.scac || "---"}</p>
                                                    </div>)}
                                                    {/* <div className="col-md-4">
                                                        <label className="text-bold m-b-0">Customer SSN</label>
                                                        
                                                        <p className="m-b-20">{this.state.ssn || "---"}</p>
                                                    </div> */}
                                                </div>
                                            )}

                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Customer Name</label>
                                                <p className="m-b-20">{this.state.details.customer_first_name || "---"}&nbsp;&nbsp;
                                                    {this.state.details.customer_last_name}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Customer Email</label>
                                                <p className="m-b-20">{this.state.details.customer_email || "---"}&nbsp;&nbsp;
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Customer Phone</label>
                                                <p className="m-b-20">{this.state.details.contact_no || "---"}&nbsp;&nbsp;
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* <div className="col-md-4">
                                            <label className="text-bold m-b-0">Customer Last Name</label>
                                            <p className="m-b-20">{this.state.details.customer_last_name || "---"}</p>
                                        </div> */}
                                            {/* <div className="col-md-4">
                                                <label className="text-bold m-b-0">Primary Phone</label>
                                                <p className="m-b-20">{this.state.details.contact_no || "---"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Secondary Phone</label>
                                                <p className="m-b-20">{this.state.details.home_contact_no || "---"}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Primary Email</label>
                                                <p className="m-b-20">{this.state.details.customer_email || "---"}</p>

                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Secondary Email</label>
                                                <p className="m-b-20">{this.state.details.alternate_email || "---"}</p>

                                            </div> */}
                                        </div>
                                        {/* <div className="row">
                                            <div className="col-md-12 text-center">
                                                <a href="#" onClick={this.toggleAdditionalInfo}>Show/hide Additional Information</a>
                                            </div>
                                        </div> */}
                                    </div>

                                    <div className="panel-body">
                                        <h6 className="text-success m-t-0">Service Info</h6>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Customer Estimated Weight</label>
                                                <p className="m-b-20">{this.state.details.customer_estimated_weight || "---"}</p>
                                            </div>
                                            {this.state.details.order_type == 1 &&
                                                <React.Fragment>
                                                    <div className="col-md-4">
                                                        <label className="text-bold m-b-0">Actual Net Weight</label>
                                                        <p className="m-b-20">{this.state.details.actual_net_weight || "---"}</p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="text-bold m-b-0">Actual Gross Weight</label>
                                                        <p className="m-b-20">{this.state.details.actual_gross_weight || "---"}</p>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Requested Pack Date</label>
                                                <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.details.requested_pack_date, 'm-d-Y')}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Requested Pickup Date</label>
                                                <div>
                                                    <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.details.request_start_date, 'm-d-Y')}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* <div className="col-md-4">
                                            <label className="text-bold m-b-0">Requested Latest Pickup Date</label>
                                            <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.details.requested_latest_pickup_date, 'm-d-Y')}</p>

                                        </div> */}
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Requested Delivery Date</label>
                                                <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.details.request_end_date, 'm-d-Y')}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Required Delivery Date (System Generated)</label>
                                                <div>
                                                    {/* <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.details.required_delivery_date, 'm-d-Y')}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Primary Pickup Origin Address</label>
                                                <p className="m-b-20">{this.state.details.source_address || "---"}<br />
                                                    {this.state.details.primary_pickup_city ? this.state.details.primary_pickup_city + ', ' : ''}
                                                    {this.state.details.primary_pickup_state ? this.state.details.primary_pickup_state + ', ' : ''}<br />
                                                    {this.state.details.primary_pickup_zip ? this.state.details.primary_pickup_zip + ', ' : ''}
                                                    {this.state.details.primary_pickup_country ? this.state.details.primary_pickup_country : ''}
                                                </p>

                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Primary Destination Delivery Address</label>
                                                <p className="m-b-20">{this.state.details.destination_address || "---"}<br />
                                                    {this.state.details.primary_destination_city ? this.state.details.primary_destination_city + ', ' : ''}
                                                    {this.state.details.primary_destination_state ? this.state.details.primary_destination_state + ', ' : ''}<br />
                                                    {this.state.details.primary_destination_zip ? this.state.details.primary_destination_zip + ', ' : ''}
                                                    {this.state.details.primary_destination_country ? this.state.details.primary_destination_country : ''}
                                                </p>

                                            </div>
                                            {this.state.details.military_flag == 0 && (
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0"># MyReloPro's Requested On Site</label>
                                                    <p className="m-b-20">{this.state.details.total_manpower_needed || "---"}</p>
                                                </div>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Special Items</label>
                                                <p className="m-b-20">{this.state.details.special_items || "---"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Firearms</label>
                                                <p className="m-b-20">{this.state.details.firearms || "---"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Vehicles</label>
                                                <p className="m-b-20">{this.state.details.vehicles || "---"}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Status</label>
                                                {(this.state.permission && this.state.details.total_invited_and_booked_users_list && this.state.details.total_invited_and_booked_users_list.length !== 0) ?
                                                    <div className="m-b-20">
                                                        <span className="text-success m-r-5" href="" data-toggle="tooltip" data-placement="top" title="In Progress"><i className="icon-circle2"></i></span> {trackingStatus || "--"} (<a href="javascript:void(0)" onClick={() => this.setTempOrder()}><b>Update</b></a>)
                                                    </div>
                                                    :
                                                    <div className="m-b-20">
                                                        <span className="text-success m-r-5" href="" data-toggle="tooltip" data-placement="top" title="In Progress"><i className="icon-circle2"></i></span> {trackingStatus || "--"}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label className="text-bold m-b-0">Remarks</label>
                                                <p className="m-b-20" dangerouslySetInnerHTML={{ __html: this.state.details.remarks || '---' }}></p>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <label className="text-bold m-b-0">Services Needed</label>
                                                <p className="m-b-20">{this.state.details.services_needed}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Relocation Notes</label>
                                                <p className="m-b-20">{this.state.details.relocation_notes || '---'}</p>
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Requested From Date(For Mover Note)</label>
                                                <p className="m-b-20">{this.state.get_mover_fromDate || "---"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Requested To Date(For Mover Note)</label>
                                                <p className="m-b-20">{this.state.get_mover_toDate || "---"}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="text-bold m-b-0">Relocation Notes(For Mover Note)</label>
                                                <p className="m-b-20">{this.state.get_mover_note || "---"}</p>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label className="text-bold m-b-0">Agreed Compensation/Price</label>
                                                <p className="m-b-20">{this.state.details.agreed_compensation_price || "---"} </p>
                                            </div>
                                        </div>

                                        {/* <hr /> */}
                                        <div className="row">
                                            {/* <div className="col-md-12 text-center">
                                                <a href="#" onClick={this.toggleAdditionalInfo}>Show/hide Additional Information</a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="panel panel-default profile-page order-details-panel" >
                                <div className="panel-body">
                                    <h6 className="text-success m-t-0">Additional Information Section</h6>
                                    {this.state.details.military_flag == 1 &&
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Service Branch</label>
                                                    <p className="m-b-20">{this.state.details.service_branch || "---"}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Shipment Type</label>
                                                    <p className="m-b-20">{this.state.details.shipment_type || "---"}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Shipment Market</label>
                                                    <p className="m-b-20">{this.state.details.shipment_market || "---"}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Origin GBLOC</label>
                                                    <p className="m-b-20">{this.state.details.origin_gbloc || "---"}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Destination GBLOC</label>
                                                    <p className="m-b-20">{this.state.details.destination_gbloc || "---"}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <label className="text-bold m-b-0">Channel</label>
                                                    <p className="m-b-20">{this.state.details.channel || "---"}</p>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Releasing Agent</label>
                                            <p className="m-b-20">{this.state.details.releasing_agent || "---"}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Releasing Agent Phone</label>
                                            <p className="m-b-20">{this.state.details.releasing_agent_phone || "---"}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Releasing Agent Email Address</label>
                                            <p className="m-b-20">{this.state.details.releasing_agent_email || "---"}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Receiving Agent</label>
                                            <p className="m-b-20">{this.state.details.receiving_agent || "---"}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Receiving Agent Phone</label>
                                            <p className="m-b-20">{this.state.details.receiving_agent_phone || "---"}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Receiving Agent Email Address</label>
                                            <p className="m-b-20">{this.state.details.receiving_agent_email || "---"}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* <div className="col-md-4">
                                            <label className="text-bold m-b-0">Code Of Service</label>
                                            <p className="m-b-20">{this.state.details.code_of_service || "---"}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Orders Number</label>
                                            <p className="m-b-20">{this.state.details.order_number || "---"}</p>
                                        </div>*/}
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Orders Date</label>
                                            <p className="m-b-20">{this.state.details.order_date ? DateFormat.getFormattedDateTime(this.state.details.order_date, 'm-d-Y') : '---'}</p>
                                        </div>
                                    </div>
                                    {/* <hr /> */}
                                    {/* <div className="row">
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Relocation Notes</label>
                                            <p className="m-b-20">{this.state.details.relocation_notes||'---'}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Will Storage Be Needed</label>
                                            {this.state.details.storage_needed == 1
                                                ?
                                                <p className="m-b-20">Yes</p>
                                                :
                                                <p className="m-b-20">No</p>
                                            }
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Are Dates Flexible</label>
                                            {this.state.details.flexibility_date == 1
                                                ?
                                                <p className="m-b-20">Yes</p>
                                                :
                                                <p className="m-b-20">No</p>
                                            }
                                        </div>
                                    </div> */}


                                    <hr />
                                    {/* <h6 className="text-success m-t-0">Additional Locations</h6>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Authorized Delivery:</label>
                                            <p className="m-b-20">{this.state.details.authorized_delivery}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Authorized Pickup:</label>
                                            <p className="m-b-20">{this.state.details.authorized_pickup}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">In Transit:</label>
                                            <p className="m-b-20">{this.state.details.in_transit}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Port Of Embark:</label>
                                            <p className="m-b-20">{this.state.details.port_of_embark}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Port Of Debark:</label>
                                            <p className="m-b-20">{this.state.details.port_of_debark}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">TCN Number:</label>
                                            <p className="m-b-20">{this.state.details.tcn_number}</p>
                                        </div>
                                    </div>
                                    <hr />*/}
                                    <h6 className="text-success m-t-0">Driver Info</h6>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Driver Name</label>
                                            <p className="m-b-20">{this.state.details.driver_name}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Driver Mobile Number</label>
                                            <p className="m-b-20">{this.state.details.driver_mobile_number}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <label className="text-bold m-b-0">Driver Email address</label>
                                            <p className="m-b-20">{this.state.details.driver_email}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="movers-pros">
                                {(this.state.details.booked.helper.length > 0 || this.state.details.booked.mover.length > 0 || this.state.details.booked.driver.length > 0)
                                    &&
                                    <React.Fragment>
                                        <div className="clearfix  m-b-10 p-t-20">
                                            <h4 className="m-t-0 pull-left">Booked &amp; Confirmed</h4>
                                        </div>
                                        <div className="panel panel-default">

                                            {
                                                this.state.details.hire_by_profile_id != ''
                                                &&
                                                this.state.details.booked.mover.length > 0
                                                &&
                                                <React.Fragment>
                                                    <div className="panel-body p-b-0">
                                                        <h6 className="m-0 text-success">Your Movers</h6>
                                                    </div>
                                                    <div className="table-responsive no-border">
                                                        <table className="table mover-table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-bold">Name</th>
                                                                    {/* <th className="text-bold">Phone</th>
                                                                    <th className="text-bold">Email</th> */}
                                                                    {/* <th className="text-bold">Message</th> */}
                                                                    <th className="text-bold text-center">Chat</th>
                                                                    <th className="text-bold text-center">Share App</th>
                                                                </tr>
                                                            </thead>
                                                            {this.renderBookedMover()}
                                                        </table>
                                                    </div>
                                                </React.Fragment>
                                            }

                                            {(this.state.details.booked.helper.length > 0 || this.state.details.booked.driver.length > 0)
                                                &&
                                                <React.Fragment>
                                                    <div className="panel-body p-b-0">
                                                        <h6 className="m-0 text-success">Your ReloPros</h6>
                                                    </div>
                                                    <div className="table-responsive no-border">
                                                        <table className="table mover-table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-bold">Name</th>
                                                                    {/* <th className="text-bold">Phone</th>
                                                                    <th className="text-bold">Email</th> */}
                                                                    {/* <th className="text-bold text-center">Message</th> */}
                                                                    <th className="text-bold text-center">Chat</th>
                                                                    <th className="text-bold text-center">Share App</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.renderBookedHelper()}
                                                                {this.renderBookedDriver()}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </React.Fragment>
                                            }

                                        </div>

                                    </React.Fragment>
                                }

                                {(this.state.details.invited.mover.length > 0 || this.state.details.invited.helper.length > 0 || this.state.details.invited.driver.length > 0)
                                    &&
                                    <React.Fragment>
                                        <div className="clearfix  m-b-10 p-t-20">
                                            <h4 className="m-t-0 pull-left">Invited (Awaiting response)</h4>
                                        </div>
                                        <div className="panel panel-default">

                                            {
                                                this.state.details.hire_by_profile_id != ''
                                                &&
                                                this.state.details.invited.mover.length > 0
                                                &&
                                                <React.Fragment>
                                                    <div className="panel-body p-b-0">
                                                        <h6 className="m-0 text-success">Requested Moving Companies For This Move</h6>
                                                    </div>
                                                    <div className="table-responsive no-border">
                                                        <table className="table mover-table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-bold">Name</th>
                                                                    <th className="text-bold">Status</th>
                                                                    {/* <th className="text-bold text-center">Award</th> */}
                                                                    <th className="text-bold text-center">Cancel Invitation</th>
                                                                    <th className="text-bold text-center">Chat</th>
                                                                    <th className="text-bold text-center">Share App</th>
                                                                </tr>
                                                            </thead>
                                                            {this.renderInvitedMover()}
                                                        </table>
                                                    </div>
                                                    {/* <div className="" id="driversAssigned">
                                                    {this.renderInvitedMover()}
                                                </div> */}
                                                </React.Fragment>
                                            }

                                            {(this.state.details.invited.helper.length > 0 || this.state.details.invited.driver.length > 0)
                                                &&
                                                <React.Fragment>
                                                    <div className="panel-body p-b-0">
                                                        <h6 className="m-0 text-success">Requested ReloPros For This Move</h6>
                                                    </div>
                                                    <div className="table-responsive no-border">
                                                        <table className="table mover-table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-bold">Name</th>
                                                                    <th className="text-bold">Status</th>
                                                                    {/* <th className="text-bold text-center">Award</th> */}
                                                                    <th className="text-bold text-center">Cancel Invitation</th>
                                                                    <th className="text-bold text-center">Chat</th>
                                                                    <th className="text-bold text-center">Share App</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.renderInvitedHelper()}
                                                                {this.renderInvitedDriver()}
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>

                                    </React.Fragment>
                                }

                                {(this.state.details.total_assigned_staffs_list.length > 0)
                                    &&
                                    <React.Fragment>
                                        <div className="clearfix  m-b-10 p-t-20">
                                            <h4 className="m-t-0 pull-left">Assigned Staff</h4>
                                        </div>
                                        <div className="panel panel-default">
                                            {(this.state.details.total_assigned_staffs_list.length > 0)
                                                &&
                                                <React.Fragment>
                                                    <div className="panel-body p-b-0">
                                                        <h6 className="m-0 text-success">Requested Staffs For This Move</h6>
                                                    </div>
                                                    <div className="table-responsive no-border">
                                                        <table className="table mover-table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-bold">Name</th>
                                                                    <th className="text-bold">Status</th>
                                                                    {/* <th className="text-bold text-center">Award</th> */}
                                                                    <th className="text-bold text-center">Cancel Invitation</th>
                                                                    {/* <th className="text-bold text-center">Chat</th> */}
                                                                    {/* <th className="text-bold text-center">Share App</th> */}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.renderInvitedStaff()}
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>

                                    </React.Fragment>
                                }
                            </div>

                            <div id="survey-file">
                                {this.state.details.status != 2 ?
                                    <div><h4 className="pull-left">Files</h4>
                                        {(this.state.staff_user_id == "" || this.state.staff_user_id == null) &&
                                            //     <label className="btn btn-success btn-xs btn-file pull-right">
                                            //         <input type="file" name="files[]" onChange={this.uploadFiles.bind(this)} multiple={true} />
                                            //         <i className="icon-file-plus position-left"></i> Add File
                                            // </label>
                                            <button className="btn btn-success btn-xs pull-right" type="button" onClick={this.openFileModal}><i className="icon-file-plus"></i> &nbsp; Add File</button>
                                        }

                                        {this.state.staff_user_id != "" && this.state.custom_menu.map((menu, i) => {
                                            return (
                                                <div>
                                                    {(menu.title == "ReloOrders" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                        //         <label className="btn btn-success btn-xs btn-file pull-right">
                                                        //             <input type="file" name="files[]" onChange={this.uploadFiles.bind(this)} multiple={true} />
                                                        //             <i className="icon-file-plus position-left"></i> Add File
                                                        // </label>
                                                        <button className="btn btn-success btn-xs pull-right" type="button" onClick={this.openFileModal}><i className="icon-file-plus"></i> &nbsp; Add File</button>
                                                    }
                                                </div>
                                            );
                                        })

                                        }
                                    </div> : null

                                }

                                <div className="clearfix"></div>
                                <div className="panel panel-flat profile-page m-b-40">
                                    <div className="panel-heading p-0">
                                        <ul className="nav nav-tabs nav-tabs-bottom nav-horizantal-scroll">
                                            <li className="active">
                                                <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setFileGroup.bind(this, 1)}>
                                                    <p className="text-uppercase m-0 m-l-5 m-r-5">Video &amp; Image files</p>
                                                </a>
                                            </li>
                                            <li className="">
                                                <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setInvenotryTab}>
                                                    <p className="text-uppercase m-0 m-l-5 m-r-5">Estimate Tool</p>
                                                </a>
                                            </li>
                                            <li className="">
                                                <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setFileGroup.bind(this, 3)}>
                                                    <p className="text-uppercase m-0 m-l-5 m-r-5">Other Files</p>
                                                </a>
                                            </li>
                                            <li className="">
                                                <a href="" data-toggle="tab" aria-expanded="true" onClick={this.setMoverTab}>
                                                    <p className="text-uppercase m-0 m-l-5 m-r-5">Request for Pre-move Survey</p>
                                                </a>
                                            </li>

                                            {/* {this.state.isGetInventory && */}
                                            {
                                                this.state.details.offer_from.profile_id != 2
                                                &&
                                                <li className="">
                                                    <a href="" data-toggle="tab" aria-expanded="true" onClick={this.listArchived}>

                                                        <p className="text-uppercase m-0 m-l-5 m-r-5">Archived</p>
                                                    </a>
                                                </li>
                                            }
                                            {/* } */}
                                        </ul>
                                    </div>
                                    {/* FileUpload-Modal start */}
                                    <div className="modal fade" id="FileUpload-Modal" role="dialog">

                                        <div className="modal-dialog">
                                            <form onSubmit={this.fileUpload.bind(this)}>
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                                        <h4 className="modal-title" style={{ color: "black" }}>Upload File & Details</h4>
                                                    </div>
                                                    <hr className="m-0" />
                                                    <div className="modal-body">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    {this.state.isProccesingFileUpload ?
                                                                        <label className="btn btn-upload-file btn-xs">Processing...</label>
                                                                        :
                                                                        <label className="btn btn-upload-file btn-xs">
                                                                            <input type="file" name="files[]" onChange={this.uploadFiles.bind(this)} multiple /><i className="icon-file-plus2 f-s-22"></i> &nbsp; Add File
                                                                            {/* <input type="file" name="files[]" onChange={this.previewFiles.bind(this)} /><i className="icon-file-plus2 f-s-22"></i> &nbsp; Add File */}
                                                                        </label>
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="file-manager horizontal-scroll">
                                                                    <ul>
                                                                        {this.renderFilesModal()}
                                                                        {/* {this.state.previewURL &&
                                                                            <li>
                                                                                <div className={"file-wrapper " + FileType(this.state.form_data.file_type)}>
                                                                                    <div className="overlay-file">

                                                                                        <a
                                                                                            href=""
                                                                                            className="text-danger"
                                                                                        onClick={this.deleteFile.bind(this, file.id)}
                                                                                        onClick={this.deleteFileModal.bind(this, file.id)}
                                                                                        >
                                                                                            <i className="icon-cancel-circle2"></i>
                                                                                            <span className="show">Delete</span>
                                                                                        </a>

                                                                                    </div>
                                                                                    {FileSvg(this.state.form_data.file_type, this.state.form_data.previewURL)}
                                                                                    <div className="file-title">
                                                                                        <a href="javascript:void(0)">
                                                                                            <i
                                                                                                className={
                                                                                                    FileIcon(this.state.form_data.file_type) + " position-left text-primary"
                                                                                                }
                                                                                            ></i>{" "}
                                                                                            {this.getFileName(file)}
                                                                                        </a>
                                                                                    </div>
                                                                                </div>

                                                                            </li>
                                                                        } */}

                                                                        {/* Static circular progress bar */}
                                                                        {this.state.isProccesingFileUpload &&
                                                                            <li>
                                                                                <div className="file-wrapper">
                                                                                    <div className="progress-file-icon">
                                                                                        <img src="assets/images/blur-inventory.jpg" alt="" />
                                                                                        <div className="inventory-progess-bar">
                                                                                            <CircularProgressbar value={this.state.uploadPercentage} text={`${this.state.uploadPercentage}%`} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        }
                                                                        {/* Static circular progress bar */}

                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className="control-label">Name your file <span className="text-danger">*</span></label>
                                                                    <input type="text" value={this.state.form_data.uploadFileName} name="uploadFileName" className="form-control" onChange={this.handleOnChange.bind(this)} required={true} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className="control-label">Tell us a little more about it </label>
                                                                    <textarea className="form-control" value={this.state.form_data.uploadFileDesc} id="" rows="2" name="uploadFileDesc" onChange={this.handleOnChange.bind(this)} ></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className="m-0" />
                                                    <div className="modal-footer">
                                                        {/* {this.state.isFileSaving ?
                    <button className="btn btn-primary btn-xs" disabled={true} >Processing...</button>
                    :
                    <button className="btn btn-primary btn-xs" >Save</button>
                } */}
                                                        {this.state.isProccesingFileUpload ?
                                                            <React.Fragment>
                                                                <button className="btn btn-primary btn-xs" disabled={true}>Save</button>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                {this.state.isFileSaving ?
                                                                    <button className="btn btn-primary btn-xs" disabled={true} >Processing...</button>
                                                                    :
                                                                    <button className="btn btn-primary btn-xs" >Save</button>
                                                                }
                                                            </React.Fragment>
                                                        }
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {/* FileUpload-Modal end */}

                                    {this.state.showArchiveTab &&
                                        <div className="panel-body">
                                            <div className="tabbable hori-scroll">
                                                <div className="file-manager">
                                                    <ul>{this.renderArchiveFile()}</ul>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {this.state.showFilesTab &&
                                        <div className="panel-body">
                                            <div className="tabbable hori-scroll">
                                                <div className="file-manager">
                                                    <ul>
                                                        {this.renderFiles()}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {this.state.showInventoryTab &&
                                        <React.Fragment>
                                            {/* {this.state.showInventoryItems ? */}

                                            {this.state.allmaininventory.length > 0 &&
                                                <div className="panel-body text-right p-b-0 p-t-0">
                                                    <h6 className="text-primary m-b-0"><span className="text-semibold">Total Cubic Feet : </span><span className="text-grey-800">{this.state.total_cubic_feet}</span></h6>
                                                    <h6 className="text-primary m-0 m-b-10"><span className="text-semibold">Total Estimated Weight : </span><span className="text-grey-800">{this.state.total_estimated_weight}</span></h6>
                                                </div>
                                            }

                                            {/* :
                                                <h6 className="alert alert-primary no-border">No files were found.</h6>
                                            } */}

                                            {this.state.showInventoryItems &&
                                                <div className="panel-body">
                                                    {this.showItemList()}
                                                </div>
                                            }
                                        </React.Fragment>
                                    }

                                    {
                                        this.state.showMoverTab &&
                                        <div className="panel-body">
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6">
                                                    <label className="text-bold m-b-0">From Date</label>
                                                    <p className="m-b-20">{this.state.get_mover_fromDate || '---'}</p>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <label className="text-bold m-b-0">To Date</label>
                                                    <p className="m-b-20">{this.state.get_mover_toDate || '---'}</p>

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label className="text-bold m-b-0">Note</label>
                                                    <p className="m-b-20">{this.state.get_mover_note || '---'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>



                            {/* Cya A File Start */}
                            {/* <div><h4 className="pull-left">CYA Video</h4> </div>
                            <div className="clearfix"></div>
                            <div className="panel panel-flat profile-page m-b-40">
                                <div className="panel-body">
                                    <div className="tabbable hori-scroll">
                                        <div className="file-manager">
                                            <ul>
                                                {this.renderCyaVideo()}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* Cya A File End */}
                            {/* {
                                this.state.details.hire_by_user_id != (JSON.parse(Storage.get('imnLoginData'))).id
                                &&
                                <React.Fragment>
                                    <h4>Hired By</h4>
                                    <div className="panel panel-default m-b-40">
                                        <div className="panel-body">
                                            <div className="well p-0 m-b-20">
                                                <div className="p-15">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            {
                                                                this.state.details.offer_from.profile_id == 2
                                                                &&
                                                                <label className="m-b-0">Mover's Name</label>
                                                            }
                                                            {
                                                                this.state.details.offer_from.profile_id == 4
                                                                &&
                                                                <label className="m-b-0">Customer's Name</label>
                                                            }
                                                            <p className="m-b-0"><a href="javascript:void(0)">{this.state.details.offer_from.organization_name}</a><b /></p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label className="m-b-0">Phone</label>
                                                            <p className="m-b-0"><b>{this.state.details.offer_from.contact_no}</b></p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <label className="m-b-0">Email</label>
                                                            <p className="m-b-0"><b>{this.state.details.offer_from.email}</b></p>
                                                        </div>
                                                        {
                                                            (JSON.parse(Storage.get('imnLoginData'))).id != this.state.details.offer_from.user_id
                                                            &&
                                                            <div className="col-md-3">
                                                                {
                                                                    this.state.details.offer_from.profile_id == "2" ?
                                                                        <a href="#" className="m-r-10" onClick={(e) => this.openMsgSection(e, this.state.details.offer_from.user_id, 'mover')}><i className="icon-envelope"></i></a>
                                                                        :
                                                                        <a href="#" className="m-r-10" onClick={(e) => this.openMsgSection(e, this.state.details.offer_from.user_id, 'customer')}><i className="icon-envelope"></i></a>
                                                                }
                                                                <a href="" className="btn btn-default btn-xs user-chat"
                                                                    data-chatusername={this.state.details.offer_from.organization_name}
                                                                    data-uid={this.state.id + '_' + this.state.details.offer_from.user_id}
                                                                    onClick={this.initChatBox.bind(
                                                                        this,
                                                                        this.state.details.offer_from.user_id,
                                                                        this.state.details.offer_from.organization_name,
                                                                        this.state.id, '')}>
                                                                    <i className="icon-comment-discussion"></i> Chat</a>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>

                            } */}
                            {
                                this.state.type === 'accepted'
                                &&
                                <React.Fragment>
                                    <h4>Connect With Super Admin</h4>
                                    <div className="panel panel-default m-b-40">
                                        <div className="panel-body">
                                            <div className="well p-0 m-b-20">
                                                <div className="p-15">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label className="m-b-0 text-bold">Name</label>
                                                            <p className="m-b-0 f-s-15"><a href="javascript:void(0)">{this.state.details.admin_details.admin_name}</a><b />
                                                                <a href="" className="btn btn-success btn-xs user-chat m-l-15"
                                                                    data-chatusername={this.state.details.admin_details.admin_name}
                                                                    data-uid={this.state.id + '_' + this.state.details.admin_details.admin_user_id}
                                                                    onClick={this.initChatBox.bind(
                                                                        this,
                                                                        this.state.details.admin_details.admin_user_id,
                                                                        this.state.details.admin_details.admin_name,
                                                                        this.state.id, '')}>
                                                                    <i className="icon-bubbles9 f-s-20"></i> Chat
                                                                </a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>

                            }
                            {/* <h4>Movers, ReloDrivers &amp; ReloHelpers Booked for this Relocation</h4> */}
                            {/* <div className="panel panel-default m-b-40">
                                <div className="panel-heading p-0 hori-scroll">
                                    <ul className="nav nav-tabs nav-tabs-bottom m-b-0">
                                        <li className="active">
                                            <a href="#moversAssigned" data-toggle="tab" aria-expanded="false">
                                                <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>Movers
                                                <span className="label label-success m-l-5">{this.state.details.booked.mover.length}</span></h5>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="#driversAssigned" data-toggle="tab" aria-expanded="false">
                                                <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>ReloDriver
                                                <span className="label label-success m-l-5">{this.state.details.booked.driver.length}</span></h5>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="#helpersAssigned" data-toggle="tab" aria-expanded="false">
                                                <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>ReloHelper
                                                <span className="label label-success m-l-5">{this.state.details.booked.helper.length}</span></h5>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="panel-body">
                                    <div className="tabbable">
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="moversAssigned">
                                                {this.renderBookedMover()}
                                            </div>
                                            <div className="tab-pane" id="driversAssigned">
                                                {this.renderBookedDriver()}
                                            </div>
                                            <div className="tab-pane" id="helpersAssigned">
                                                {this.renderBookedHelper()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <h4>Movers, ReloDrivers &amp; ReloHelpers Invited for this Relocation</h4> */}
                            {/* <div className="panel panel-default m-b-40">
                                <div className="panel-heading p-0 hori-scroll">
                                    <ul className="nav nav-tabs nav-tabs-bottom m-b-0">
                                        <li className="active">
                                            <a href="#moversInvited" data-toggle="tab" aria-expanded="false">
                                                <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>Movers
                                                <span className="label label-success m-l-5">{this.state.details.invited.mover.length}</span></h5>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="#driversInvited" data-toggle="tab" aria-expanded="false">
                                                <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>ReloDriver
                                                <span className="label label-success m-l-5">{this.state.details.invited.driver.length}</span></h5>
                                            </a>
                                        </li>
                                        <li className="">
                                            <a href="#helpersInvited" data-toggle="tab" aria-expanded="false">
                                                <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>ReloHelper
                                                <span className="label label-success m-l-5">{this.state.details.invited.helper.length}</span></h5>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="panel-body">
                                    <div className="tabbable">
                                        <div className="tab-content">
                                            <div className="tab-pane active" id="moversInvited">
                                                {this.renderInvitedMover()}
                                                {(this.state.staff_user_id == "" || this.state.staff_user_id == null) &&
                                                    <div className="text-center">
                                                        <button type="button" className="btn btn-primary btn-xs" name="mover" data-toggle="modal" data-target="#chooseAddress" onClick={this.getModalForInvitation}>Invite More Movers</button>
                                                    </div>}
                                                {
                                                    this.state.staff_user_id != "" && this.state.custom_menu.map((menu, i) => {
                                                        return (
                                                            <div>
                                                                {
                                                                    (menu.title == "ReloOrders" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                                    <div className="text-center">
                                                                        <button type="button" className="btn btn-primary btn-xs" name="mover" data-toggle="modal" data-target="#chooseAddress" onClick={this.getModalForInvitation}>Invite More Movers</button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            <div className="tab-pane" id="driversInvited">
                                                {this.renderInvitedDriver()}
                                                {(this.state.staff_user_id == "" || this.state.staff_user_id == null) &&
                                                    <div className="text-center">
                                                        <button type="button" className="btn btn-primary btn-xs" name="driver" onClick={this.existingDriverModal}>Assign to More ReloDrivers</button>
                                                    </div>}
                                                {
                                                    this.state.staff_user_id != "" && this.state.custom_menu.map((menu, i) => {
                                                        return (
                                                            <div>
                                                                {
                                                                    (menu.title == "ReloOrders" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                                    <div className="text-center">
                                                                        <button type="button" className="btn btn-primary btn-xs" name="driver" onClick={this.existingDriverModal}>Assign to More ReloDrivers</button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            <div className="tab-pane" id="helpersInvited">
                                                {this.renderInvitedHelper()}
                                                {(this.state.staff_user_id == "" || this.state.staff_user_id == null) &&
                                                    <div className="text-center">
                                                        <button type="button" className="btn btn-primary btn-xs" name="helper" data-toggle="modal" data-target="#chooseAddress" onClick={this.getModalForInvitation}>Invite More ReloHelpers</button>
                                                    </div>
                                                }
                                                {
                                                    this.state.staff_user_id != "" && this.state.custom_menu.map((menu, i) => {
                                                        return (
                                                            <div>
                                                                {
                                                                    (menu.title == "ReloOrders" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                                    <div className="text-center">
                                                                        <button type="button" className="btn btn-primary btn-xs" name="helper" data-toggle="modal" data-target="#chooseAddress" onClick={this.getModalForInvitation}>Invite More ReloHelpers</button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}


                            {/* <h4>Messaging</h4> */}
                            {/* <div className="panel panel-default">
                                <div className="panel-heading p-0 hori-scroll">
                                    <ul className="nav nav-tabs nav-tabs-bottom m-b-0">
                                        <li className={this.state.details.hire_by_profile_id == 2 ? "active" : ""} id='mover-tab'>
                                            <a href="#bordered-tab1" data-toggle="tab" aria-expanded="true" onClick={(e) => this.closeActivePane(e, "mover")}>

                                                <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>Movers
                                                <span className={this.state.total_Msg.moverTotalMsg == 0 ? "label label-success m-l-5" : "label label-danger m-l-5"}>{this.state.total_Msg.moverTotalMsg}</span></h5>
                                            </a>
                                        </li>
                                        <li className="" id='helper-tab'>
                                            <a href="#bordered-tab2" data-toggle="tab" aria-expanded="true" onClick={(e) => this.closeActivePane(e, "helper")} >

                                                <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>ReloHelper
                                                <span className={this.state.total_Msg.helperTotalMsg == 0 ? "label label-success m-l-5" : "label label-danger m-l-5"}>{this.state.total_Msg.helperTotalMsg}</span></h5>
                                            </a>
                                        </li>
                                        <li className="" id='driver-tab'>
                                            <a href="#bordered-tab3" data-toggle="tab" aria-expanded="false" onClick={(e) => this.closeActivePane(e, "driver")}>


                                                <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>ReloDriver
                                                <span className={this.state.total_Msg.driveTotalMsg == 0 ? "label label-success m-l-5" : "label label-danger m-l-5"}>{this.state.total_Msg.driveTotalMsg}</span></h5>
                                            </a>
                                        </li>
                                        <li className="" id='superadmin-tab'>
                                            <a href="#bordered-tab4" data-toggle="tab" aria-expanded="false" onClick={(e) => this.closeActivePane(e, "admin")} >

                                                <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>SuperAdmin
                                                <span className={this.state.total_Msg.adminTotalMsg == 0 ? "label label-success m-l-5" : "label label-danger m-l-5"}>{this.state.total_Msg.adminTotalMsg}</span></h5>
                                            </a>
                                        </li>
                                        <li className={this.state.details.hire_by_profile_id == 4 ? "active" : ""} id='customer-tab'>
                                            <a href="#bordered-tab5" data-toggle="tab" aria-expanded="false" onClick={(e) => this.closeActivePane(e, "customer")}>

                                                <h5 className="text-uppercase m-0 m-l-5 m-r-5" style={{ textTransform: 'none' }}>ReloCustomer

                                                <span className={this.state.total_Msg.customerTotalMsg == 0 ? "label label-success m-l-5" : 'label label-danger m-l-5'}>{this.state.total_Msg.customerTotalMsg}</span>

                                                </h5>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="panel-body">
                                    <div className="tabbable">
                                        <div className="tab-content">
                                            <div id="bordered-tab1" className={this.state.details.hire_by_profile_id == 2 ? "tab-pane active" : "tab-pane"}>
                                                {this.renderMoverConversations()}
                                            </div>
                                            <div id="bordered-tab2" className="tab-pane">
                                                {this.renderHelperConversations()}
                                            </div>
                                            <div id="bordered-tab3" className="tab-pane">
                                                {this.renderDriverConversations()}
                                            </div>
                                            <div id="bordered-tab4" className="tab-pane">
                                                {this.renderAdminConversations()}
                                            </div>
                                            <div id="bordered-tab5" className={this.state.details.hire_by_profile_id == 4 ? "tab-pane active" : "tab-pane"}>
                                                {this.renderCustomerConversations()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        {/* -- Add ReloHelper - Choose Address Modal -- */}
                        <div id="RatingGive" className="modal fade" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                        >
                                            &times;
                                        </button>
                                        <h4 className="modal-title">Post Feedback</h4>
                                    </div>
                                    <div className="modal-body">
                                        <div
                                            className="panel-group panel-group-control content-group-lg"
                                            id="accordion-control"
                                        >
                                            {this.renderRatingUsers()}
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            onClick={this.ratingSubmit}
                                            type="button"
                                            className="btn btn-primary"
                                            data-dismiss="modal"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="chooseAddress" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        {
                                            this.state.chooseAddressModal == 'mover'
                                            &&
                                            <h4 className="modal-title" id="myModalLabel">Select address to find ReloMovers</h4>
                                        }
                                        {
                                            this.state.chooseAddressModal == 'helper'
                                            &&
                                            <h4 className="modal-title" id="myModalLabel">Select address to find ReloPros</h4>
                                        }
                                    </div>
                                    <div className="modal-body">

                                        {
                                            this.state.details.source_address
                                            &&
                                            this.state.details.destination_address
                                            &&
                                            <React.Fragment>
                                                <div className="form-group">
                                                    <div className="radio">
                                                        {/* <label><input type="radio" name={this.state.chooseAddressModal} value="Origin" onChange={this.changeRadio} /> Origin Address<br /><small className="text-muted">{this.state.details.source_address}</small></label> */}
                                                        <label><input type="radio" name={this.state.chooseAddressModal} value="Origin" onChange={this.changeRadio} /> Origin Address<br /><small className="text-muted"> {
                                                            this.getFullAddress(
                                                                {
                                                                    data: this.state.details,
                                                                    addressType: "primary_pickup",

                                                                })
                                                        }</small></label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="radio">
                                                        {/* <label><input type="radio" name={this.state.chooseAddressModal} value="Destination" onChange={this.changeRadio} /> Destination Address<br /><small className="text-muted">{this.state.details.destination_address}</small></label> */}
                                                        <label><input type="radio" name={this.state.chooseAddressModal} value="Destination" onChange={this.changeRadio} /> Destination Address<br /><small className="text-muted">
                                                            {this.getFullAddress(
                                                                {
                                                                    data: this.state.details,
                                                                    addressType: "primary_destination",

                                                                })}</small></label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="radio">
                                                        <label><input type="radio" name={this.state.chooseAddressModal} value="Both" onChange={this.changeRadio} /> Both Addresses</label>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                        {
                                            this.state.details.source_address
                                            &&
                                            !this.state.details.destination_address
                                            &&
                                            <div className="form-group">
                                                <div className="radio">
                                                    <label><input type="radio" name={this.state.chooseAddressModal} value="Origin" onChange={this.changeRadio} /> Origin Address<br /><small className="text-muted">{this.state.details.source_address}</small></label>
                                                </div>
                                            </div>
                                        }
                                        {
                                            !this.state.details.source_address
                                            &&
                                            this.state.details.destination_address
                                            &&
                                            <div className="form-group">
                                                <div className="radio">
                                                    <label><input type="radio" name={this.state.chooseAddressModal} value="Destination" onChange={this.changeRadio} /> Destination Address<br /><small className="text-muted">{this.state.details.destination_address}</small></label>
                                                </div>
                                            </div>
                                        }
                                        <span className="text-danger">{this.state.helper_address_err}</span><br />
                                        <div className="well">
                                            <label className="text-semibold">Services Needed</label>
                                            <ul className="checkbox-list">
                                                {
                                                    this.state.services_needed_array.length != 0
                                                    &&
                                                    this.state.services_needed_array.map((item, i) => {
                                                        return (
                                                            <li key={i}><label className="checkbox-inline"><input type="checkbox" name="services_needed" value={item} checked={this.state.form_data.services_needed.indexOf(item) > -1} onChange={this.setServices.bind(this)} /><span className=""></span>{item}</label></li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <span className="text-danger">{this.state.helper_serv_err}</span><br />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                        {
                                            this.state.chooseAddressModal == 'mover'
                                            &&
                                            <button type="button" className="btn btn-primary" name="mover" onClick={this.inviteReloMovers.bind(this)}>Invite ReloMover</button>
                                        }
                                        {
                                            this.state.chooseAddressModal == 'driver'
                                            &&
                                            <button type="button" className="btn btn-primary" name="driver" onClick={this.inviteReloDrivers.bind(this)}>Invite ReloDriver</button>
                                        }
                                        {
                                            this.state.chooseAddressModal == 'helper'
                                            &&
                                            <button type="button" className="btn btn-primary" name="helper" onClick={this.inviteReloHelpers.bind(this)}>Invite ReloPro</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.profileShowModal
                            &&
                            <div id="profileInfoModalHelperDriver" className="modal fade" role="dialog">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        </div>
                                        {
                                            this.state.modalLoader
                                            &&
                                            <div className="col-md-12">
                                                <div className="well text-center">
                                                    <i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...
                                                </div>
                                            </div>
                                        }
                                        <div className="modal-body p-t-0">
                                            {!this.state.modalLoader
                                                &&
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="text-center">
                                                                {
                                                                    !!this.state.user_detail.profile_image
                                                                        ?
                                                                        <span className="thumb-60" style={{ background: 'url(' + this.state.user_detail.profile_image + ') no-repeat center center / 100%' }}></span>
                                                                        :
                                                                        <span className="thumb-60" style={{ background: 'url(assets/images/default_avatar.jpg) no-repeat center center / 100%' }}></span>
                                                                }
                                                            </div>
                                                            {
                                                                Math.ceil(this.state.user_detail.avarage_rating) > 0
                                                                &&
                                                                <div className="text-center text-orange">
                                                                    <p className="m-t-15 m-b-0">
                                                                        <React.Fragment>
                                                                            <span className={(Math.ceil(this.state.user_detail.avarage_rating) >= 1) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                            <span className={(Math.ceil(this.state.user_detail.avarage_rating) >= 2) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                            <span className={(Math.ceil(this.state.user_detail.avarage_rating) >= 3) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                            <span className={(Math.ceil(this.state.user_detail.avarage_rating) >= 4) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                            <span className={(Math.ceil(this.state.user_detail.avarage_rating) == 5) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                        </React.Fragment>
                                                                        {this.state.user_detail.avarage_rating}
                                                                    </p>
                                                                </div>
                                                            }
                                                            {
                                                                Math.ceil(this.state.user_detail.avarage_rating) == 0.0
                                                                &&
                                                                <div className="text-center text-orange">
                                                                    <p className="m-t-15 m-b-0">
                                                                        <React.Fragment>
                                                                            <span className='icon-star-empty3'></span>
                                                                            <span className='icon-star-empty3'></span>
                                                                            <span className='icon-star-empty3'></span>
                                                                            <span className='icon-star-empty3'></span>
                                                                            <span className='icon-star-empty3'></span>
                                                                        </React.Fragment>
                                                                    </p>
                                                                </div>
                                                            }
                                                            <h3>About</h3>
                                                            <hr className="m-t-5 m-b-15 border-color-light-blue" />
                                                            <p>
                                                                {this.state.user_detail.about_me || "----"}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <h1 className="text-blue m-t-0">{this.state.user_detail.gender} {this.state.user_detail.first_name} {this.state.user_detail.last_name}</h1>
                                                            <p className="f-s-16 text-muted"><i className="icon-location3 text-primary"></i> {this.state.user_detail.address || "----"}</p>
                                                            <div className="border-left p-l-30 m-t-30 border-color-light-blue ">
                                                                <h4 className="m-t-5">Basic Info</h4>
                                                                <hr className="m-t-0 m-b-10 border-color-light-blue" />
                                                                <div className="row">
                                                                    {/* <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Email Address</label>
                                                                        <p className="m-b-20">{this.state.user_detail.email || "----"}</p>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Recovery Email Address</label>
                                                                        <p className="m-b-20">{this.state.user_detail.alternate_email || "----"}</p>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Mobile Phone</label>
                                                                        <p className="m-b-20">{this.state.user_detail.contact_no || "----"}</p>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Alternate Phone No</label>
                                                                        <p className="m-b-20">{this.state.user_detail.home_contact_no || "----"}</p>
                                                                    </div> */}
                                                                    {/* <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Date Of Birth</label>
                                                                        <p className="m-b-20">{this.state.user_detail.dob || "----"}</p>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Social Security number</label>
                                                                        <p className="m-b-20">{this.state.user_detail.ssn || "----"}</p>
                                                                    </div> */}
                                                                    <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Years of Experience</label>
                                                                        <p className="m-b-20">{(this.state.user_detail.years_of_experience > 0) ? this.state.user_detail.years_of_experience + ' Years' : '----'} </p>
                                                                    </div>
                                                                </div>
                                                                {/* <h4 className="m-t-5">Passport Info</h4> */}
                                                                <hr className="m-t-0 m-b-10 border-color-light-blue" />

                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Available Services</label><br />
                                                                        {
                                                                            this.state.user_detail.available_services.length != 0
                                                                            &&
                                                                            this.state.user_detail.available_services.map((item, i) => {
                                                                                return (<React.Fragment key={i}><label className="checkbox-inline custom-checkbox  m-b-10 m-l-0 m-r-10">
                                                                                    <input type="checkbox" disabled="disabled" checked={true} />
                                                                                    <span className=""></span>
                                                                                    {item.name}
                                                                                </label></React.Fragment>)
                                                                            })
                                                                        }
                                                                    </div>
                                                                    {/* <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Passport Number</label>
                                                                        <p className="m-b-20">{this.state.user_detail.passport_no || "----"}</p>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Passport Expiration Date</label>
                                                                        <p className="m-b-20">{this.state.user_detail.passport_exp_date || "----"}</p>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Place of Birth</label>
                                                                        <p className="m-b-20">{this.state.user_detail.place_of_birth || "----"}</p>
                                                                    </div> */}
                                                                </div>

                                                                {/* <h4 className="m-t-5">Medical Info</h4> */}
                                                                {/* <hr className="m-t-0 m-b-10 border-color-light-blue" />
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Medical Card Issue Date</label>
                                                                        <p className="m-b-20">{this.state.user_detail.medical_card_issue_date || "----"}</p>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Medical Card Expiration Date</label>
                                                                        <p className="m-b-20">{this.state.user_detail.medical_exam_exp_date || "----"}</p>
                                                                    </div>
                                                                </div> */}
                                                                <h3>Background Check Info</h3>
                                                                <hr className="m-t-5 m-b-15 border-color-light-blue" />

                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Background check performed Date</label>
                                                                        <p className="m-b-20">{this.state.user_detail.background_check_perform_date || "----"}</p>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Background Check Completed</label>
                                                                        <p className="m-b-20">{this.state.user_detail.background_check_status == 1 ? 'Yes' : 'No'}</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-md-12">
                                                            <h3>Availability</h3>
                                                            <hr className="m-t-5 m-b-15 border-color-light-blue" />
                                                            <div className="p-15">
                                                                <div className="fullcalendar-basic"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>
                                        <div className="modal-footer">
                                            {
                                                !this.state.modalLoader
                                                &&
                                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            this.state.staffProfileShowModal
                            &&
                            <div id="staffProfileInfoModal" className="modal fade" role="dialog">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        </div>
                                        {
                                            this.state.modalLoader
                                            &&
                                            <div className="col-md-12">
                                                <div className="well text-center">
                                                    <i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...
                                                </div>
                                            </div>
                                        }
                                        <div className="modal-body p-t-0">
                                            {!this.state.modalLoader
                                                &&
                                                <React.Fragment>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="text-center">
                                                                {
                                                                    !!this.state.user_detail.profile_image
                                                                        ?
                                                                        <span className="thumb-60" style={{ background: 'url(' + this.state.user_detail.profile_image + ') no-repeat center center / 100%' }}></span>
                                                                        :
                                                                        <span className="thumb-60" style={{ background: 'url(assets/images/default_avatar.jpg) no-repeat center center / 100%' }}></span>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <h1 className="text-blue m-t-0">{this.state.user_detail.gender} {this.state.user_detail.first_name} {this.state.user_detail.last_name}</h1>
                                                            <p className="f-s-16 text-muted"><i className="icon-location3 text-primary"></i> {this.state.user_detail.address || "----"}</p>
                                                            <div className="border-left p-l-30 m-t-30 border-color-light-blue ">
                                                                <h4 className="m-t-5">Basic Info</h4>
                                                                <hr className="m-t-0 m-b-10 border-color-light-blue" />
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Email Address</label>
                                                                        <p className="m-b-20">{!!this.state.user_detail.email ? this.state.user_detail.email : '---'} </p>
                                                                    </div>
                                                                </div>
                                                                <hr className="m-t-0 m-b-10 border-color-light-blue" />

                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Mobile No</label>
                                                                        <p className="m-b-20">{!!this.state.user_detail.contact_no ? this.state.user_detail.contact_no : '---'} </p>
                                                                    </div>
                                                                </div>
                                                                <hr className="m-t-0 m-b-10 border-color-light-blue" />

                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Alternate Mobile No</label>
                                                                        <p className="m-b-20">{!!this.state.user_detail.alternate_contact_no ? this.state.user_detail.alternate_contact_no : '---'} </p>
                                                                    </div>
                                                                </div>
                                                                <hr className="m-t-0 m-b-10 border-color-light-blue" />

                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Date Of Birth</label>
                                                                        <p className="m-b-20">{!!this.state.user_detail.dob ? this.state.user_detail.dob : '---'} </p>
                                                                    </div>
                                                                </div>
                                                                <hr className="m-t-0 m-b-10 border-color-light-blue" />

                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <label className="text-bold m-b-0">Custom Profile Name</label>
                                                                        <p className="m-b-20">{!!this.state.user_detail.custom_profile_name ? this.state.user_detail.custom_profile_name : '---'} </p>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>
                                        <div className="modal-footer">
                                            {
                                                !this.state.modalLoader
                                                &&
                                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }


                        <div id="profileInfoModal" className="modal fade" role="dialog">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                    </div>
                                    {
                                        this.state.modalLoader
                                        &&
                                        <div className="col-md-12">
                                            <div className="well text-center">
                                                <i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...
                                            </div>
                                        </div>
                                    }

                                    <div className="modal-body p-t-0">
                                        {!this.state.modalLoader
                                            &&
                                            <React.Fragment>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="text-center">
                                                            {this.handleFileCheck(this.state.fields.logo)}
                                                        </div>
                                                        {
                                                            Math.ceil(this.state.fields.avarage_rating) > 0
                                                            &&
                                                            <div className="text-center text-orange">
                                                                <p className="m-t-15 m-b-0">
                                                                    <React.Fragment>
                                                                        <span className={(Math.ceil(this.state.fields.avarage_rating) >= 1) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                        <span className={(Math.ceil(this.state.fields.avarage_rating) >= 2) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                        <span className={(Math.ceil(this.state.fields.avarage_rating) >= 3) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                        <span className={(Math.ceil(this.state.fields.avarage_rating) >= 4) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                        <span className={(Math.ceil(this.state.fields.avarage_rating) == 5) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                                        {this.state.fields.avarage_rating}
                                                                    </React.Fragment>
                                                                </p>
                                                            </div>
                                                        }
                                                        {
                                                            Math.ceil(this.state.fields.avarage_rating) == 0.0
                                                            &&
                                                            <div className="text-center text-orange">
                                                                <p className="m-t-15 m-b-0">
                                                                    <React.Fragment>
                                                                        <span className='icon-star-empty3'></span>
                                                                        <span className='icon-star-empty3'></span>
                                                                        <span className='icon-star-empty3'></span>
                                                                        <span className='icon-star-empty3'></span>
                                                                        <span className='icon-star-empty3'></span>
                                                                    </React.Fragment>
                                                                </p>
                                                            </div>
                                                        }
                                                        <h5 className="m-t-10">Mailing Address {this.state.id}</h5>
                                                        <hr className="m-t-5 m-b-10 border-color-light-blue" />
                                                        <p>
                                                            <i className="icon-location3 text-primary"></i> {this.state.fields.secondary_street_address}
                                                        </p>
                                                        <ul className="list-group p-t-0">
                                                            <li className="list-group-item active">Services Provided</li>
                                                            {
                                                                this.state.services_provided_list.length > 1
                                                                &&
                                                                this.state.services_provided_list.map((item, index) => {
                                                                    return (<li className="list-group-item" key={index}><i className="icon-check text-success"></i>{item}</li>)
                                                                })
                                                            }
                                                            {
                                                                this.state.services_provided_list.length == 1
                                                                &&
                                                                <p className="text-danger text-center">No Services Found.</p>
                                                            }
                                                        </ul>
                                                        <ul className="list-group m-t-15">
                                                            <li className="list-group-item">Tractors <span className="badge bg-blue">{this.state.fields.tractors || '0'}</span></li>
                                                            <li className="list-group-item">Trailers <span className="badge bg-blue">{this.state.fields.trailers || '0'}</span></li>
                                                            <li className="list-group-item">Straight Trucks <span className="badge bg-blue">{this.state.fields.straight_trucks || '0'}</span></li>
                                                            <li className="list-group-item">Pack Vans <span className="badge bg-blue">{this.state.fields.pack_vans || '0'}</span></li>
                                                            <li className="list-group-item">CDL Drivers <span className="badge bg-blue">{this.state.fields.cdl_drivers || '0'}</span></li>
                                                            <li className="list-group-item">Non-CDL Drivers <span className="badge bg-blue">{this.state.fields.non_cdl_drivers || '0'}</span></li>
                                                            <li className="list-group-item">Packers <span className="badge bg-blue">{this.state.fields.packers || '0'}</span></li>
                                                            <li className="list-group-item">Loaders <span className="badge bg-blue">{this.state.fields.loaders || '0'}</span></li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <h1 className="text-blue m-t-0 m-b-0">{this.state.fields.organization_name} <small className="f-s-15 text-muted">({this.state.fields.doing_business_as_dba || '----'})</small></h1>
                                                        <p className="f-s-14 text-muted"> Founded on: {this.state.fields.year_founded || '----'} | Website: <a href="javascript:void(0)">{this.state.fields.website_link || '----'}</a></p>
                                                        <p className="f-s-16 text-muted"><i className="icon-location3 text-primary"></i> {this.state.fields.primary_street_address || '----'}</p>
                                                        <div className="border-left p-l-30 m-t-30 border-color-light-blue ">
                                                            <h4 className="m-t-5">Contact Info</h4>
                                                            <hr className="m-t-0 m-b-10 border-color-light-blue" />


                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Main Office Phone Number</label>
                                                                    <p className="m-b-20">{this.state.fields.company_contact_no || '----'}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Office Fax Number</label>
                                                                    <p className="m-b-20">{this.state.fields.company_fax_no || '----'}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Operations Email Address</label>
                                                                    <p className="m-b-20">{this.state.fields.operation_email || '----'}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Sales Email Address (For Leads & New Customers)</label>
                                                                    <p className="m-b-20">{this.state.fields.sales_email || '----'}</p>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <label className="text-bold m-b-0">Customer Service Email Address (For Existing Customers)</label>
                                                                    <p className="m-b-20">{this.state.fields.customer_service_email || '----'}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Claims Email Address</label>
                                                                    <p className="m-b-20">{this.state.fields.claim_email || '----'}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">After Hours Contact Phone</label>
                                                                    <p className="m-b-20">{this.state.fields.after_hours_contact_phone || '----'}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">After Hours Staff Name</label>
                                                                    <p className="m-b-20">{this.state.fields.after_hours_staff_name || '----'}</p>
                                                                </div>
                                                            </div>
                                                            <h4 className="m-t-5">Additional Info</h4>
                                                            <hr className="m-t-0 m-b-10 border-color-light-blue" />
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Management Contact Name</label>
                                                                    <p className="m-b-20">{this.state.fields.management_contact_name || '----'}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Management Title</label>
                                                                    <p className="m-b-20">{this.state.fields.management_title || '----'}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Management Email Address</label>
                                                                    <p className="m-b-20">{this.state.fields.company_email || '----'}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Management Phone</label>
                                                                    <p className="m-b-20">{this.state.fields.contact_no || '----'}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Certified Truck Scale on Premises?</label>
                                                                    <p className="m-b-20">{this.state.fields.certified_truck_scale_on_premises == 1
                                                                        ? 'Yes' :
                                                                        'No'}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Secured Parking Lot?</label>
                                                                    <p className="m-b-20">{
                                                                        this.state.fields.secured_parking_lot == 1
                                                                            ? 'Yes'
                                                                            : 'No'
                                                                    }</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Do You Have a Warehouse?</label>
                                                                    <p className="m-b-20">{
                                                                        this.state.fields.warehouse == 1 ? 'Yes' : 'No'
                                                                    }</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Warehouse Size in Square Feet</label>
                                                                    <p className="m-b-20">{this.state.fields.warehouse_size || '----'}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Climatized?</label>
                                                                    <p className="m-b-20">{
                                                                        this.state.fields.climatized == 1 ? 'Yes' : 'No'
                                                                    }</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Climatized Size in Square Feet</label>
                                                                    <p className="m-b-20">{this.state.fields.climatized_size || '----'}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Air Conditioned?</label>
                                                                    <p className="m-b-20">{
                                                                        this.state.fields.air_conditioned == 1
                                                                            ? 'Yes'
                                                                            : 'No'
                                                                    }</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Air Conditioned Size in Square Feet</label>
                                                                    <p className="m-b-20">{this.state.fields.air_conditioned_size || '----'}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Military Approved Warehouse?</label>
                                                                    <p className="m-b-20">{
                                                                        this.state.fields.military_approved_warehouse == 1
                                                                            ? 'Yes'
                                                                            : 'No'
                                                                    }</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="text-bold m-b-0">Radius of Quality Service</label>
                                                                    <p className="m-b-20">{this.state.fields.radius_of_quality_service || '----'}</p>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>

                                                </div>
                                            </React.Fragment>
                                        }
                                    </div>

                                    <div className="modal-footer">
                                        {
                                            !this.state.modalLoader
                                            &&
                                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                        }
                                    </div>

                                </div>

                            </div>
                        </div>
                        {/* } */}

                        <div id="tracking-Move" className="modal fade" role="dialog">
                            <div className="modal-dialog">


                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        <h4 className="modal-title">Move Tracking</h4>
                                    </div>
                                    <hr className="m-t-25 m-b-0" />
                                    <div className="modal-body alpha-grey p-0">
                                        {this.state.trackLoading ?
                                            <div className="well text-center m-10"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                                            :
                                            <div className="tracking-timeline">
                                                <ul>
                                                    {
                                                        this.state.tracking_list.map((item, i) => {


                                                            return (

                                                                <li className={item.is_skip == 1 ? item.skip_track_class : item.track_class || ""} key={i} id={"div_" + item.id}>
                                                                    <div data-date={(item.track_date) ? DateFormat.getFormattedDateTime(item.track_date, 'm-d-Y') : ""} data-time={(item.track_date) ? DateFormat.getFormattedDateTime(item.track_date, 'H:i a') : ""}>

                                                                        {(item.id == 8)
                                                                            ?
                                                                            <div>
                                                                                <span id="drop3">{item.title.split("/")[0]}</span>
                                                                                <span id="drop4">{item.title.split("/")[1]}</span>
                                                                            </div>
                                                                            :
                                                                            <div></div>
                                                                        }

                                                                        {(item.id == 9)
                                                                            ?
                                                                            <div>
                                                                                <span id="drop1">{item.title.split("/")[0]}</span>
                                                                                <span id="drop2">{item.title.split("/")[1]}</span>
                                                                            </div>
                                                                            :
                                                                            <div></div>
                                                                        }
                                                                        {(item.id != 8 && item.id != 9) ? (item.title) : <div></div>}</div>
                                                                    {
                                                                        item.id == 6
                                                                        &&
                                                                        <React.Fragment>
                                                                            <a href="javascript:void(0)" className="f-s-14" onClick={(e) => this.getLocation(item.id)}><i className="icon-location4"></i> Live View Driver Location</a>
                                                                            <br />
                                                                        </React.Fragment>
                                                                    }
                                                                    {
                                                                        item.id == 14
                                                                        &&
                                                                        <React.Fragment>
                                                                            <a href="javascript:void(0)" className="f-s-14"><i className="icon-pencil"></i> View Survey</a>
                                                                            <br />
                                                                        </React.Fragment>
                                                                    }
                                                                    {
                                                                        this.state.tracking_nxt_btn == item.id
                                                                        &&
                                                                        item.id != 13 && item.id != 14
                                                                        // && item.id != 15
                                                                        &&
                                                                        <React.Fragment>
                                                                            <a href="javascript:void(0)" className="btn btn-success btn-xs btn-labeled" onClick={() => this.changeDoneStatus(item.id, item.title)}><b><i className="icon-check2"></i></b>Done</a>
                                                                            <span>{' '}</span>
                                                                            <a href="javascript:void(0)" className="btn btn-warning btn-xs btn-labeled" onClick={() => this.skipTrackStatus(item.sort_order, 1)}><b><i className="fa fa-step-forward"></i></b>Skip</a>
                                                                        </React.Fragment>
                                                                    }

                                                                    {
                                                                        ((item.id != 13 && item.id != 14) && (item.track_btn_status != 1) && (this.state.tracking_nxt_btn != item.id))
                                                                        &&
                                                                        <React.Fragment>
                                                                            <a href="javascript:void(0)" className="btn btn-success btn-xs btn-labeled" onClick={() => this.changeMultipleDoneStatus(item.sort_order)}><b><i className="icon-check2"></i></b>Done</a>
                                                                            <span>{' '}</span>
                                                                            <a href="javascript:void(0)" className="btn btn-warning btn-xs btn-labeled" onClick={() => this.skipTrackStatus(item.sort_order, 1)}><b><i className="fa fa-step-forward"></i></b>Skip</a>
                                                                        </React.Fragment>
                                                                    }

                                                                    {(item.is_skip == 1) ?
                                                                        <React.Fragment>
                                                                            <br /><small className="text-warning"><i className="fa fa-step-forward"></i> Skipped</small>
                                                                        </React.Fragment>
                                                                        :
                                                                        (item.track_btn_status == 1)
                                                                            ?
                                                                            <React.Fragment>
                                                                                <br /><small className="text-success"><i className="icon-check2"></i> Done</small>
                                                                            </React.Fragment>
                                                                            :
                                                                            ''
                                                                    }
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </div>

                                    <hr className="m-0" />
                                    {!this.state.trackLoading &&
                                        <div className="modal-footer p-t-15">
                                            <button type="button" className="btn btn-default btn-xs" data-dismiss="modal">Close</button>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>

                        <div id="live-location" className="modal fade" role="dialog">
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        <h4 className="modal-title">Pro(s) Live Location</h4>
                                    </div>
                                    <br />
                                    <hr className="m-0" />
                                    <div className="modal-body" style={{ height: "400px", padding: 0 }}>
                                        {!this.state.loadUserLocation &&
                                            <div id="map">

                                                {typeof (this.state.locationData[0]) !== 'undefined' &&
                                                    <Map
                                                        google={this.props.google}
                                                        zoom={8}
                                                        style={{ width: "100%", height: "100%" }}
                                                        initialCenter={{ lat: this.state.locationData[0].latitude, lng: this.state.locationData[0].longitude }}
                                                    >
                                                        {this.state.locationData.map(item => (
                                                            <Marker position={{ lat: item.latitude, lng: item.longitude }} label={item.name} key={item.id} />
                                                        ))

                                                        }
                                                    </Map>
                                                }

                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="ViewServices" className="modal fade" role="dialog">
                            <div className="modal-dialog modal-xs">


                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        <h4 className="modal-title">Services Needed</h4>
                                    </div>
                                    <hr className="m-0 m-t-10" />

                                    <div className="modal-body">
                                        {
                                            this.state.temp_services_needed.length > 0
                                            &&
                                            this.state.temp_services_needed.map((temp_services, i) =>
                                                <p key={i}><i className="icon-briefcase3 position-left text-muted"></i> {temp_services}</p>
                                            )
                                        }
                                    </div>
                                    <hr className="m-0" />
                                    <div className="modal-footer p-t-15">
                                        <button type="button" className="btn btn-default btn-xs" data-dismiss="modal">Close</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="modal fade" id="addExistingDriverModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title" id="myModalLabel">Select from Existing Driver/Packer</h4>
                                    </div>
                                    <div className="modal-body">
                                        {/* <div className="well">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group m-b-0">
                                                        <label className="control-label">Driver Name</label>
                                                        <input type="text" name="name" className="form-control" onChange={this.handleOnChangeSearchDriver} />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group m-b-0">
                                                        <label className="control-label">Driver Mobile Number</label>
                                                        <input type="text" name="contact_no" className="form-control" onChange={this.handleOnChangeSearchDriver} />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group m-b-0">
                                                        <label className="control-label">Driver Email Address</label>
                                                        <input type="text" name="email" className="form-control" onChange={this.handleOnChangeSearchDriver} />
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <label className="control-label">&nbsp;</label>
                                                    <button className="btn btn-primary btn-block" onClick={this.searchDriver}>Search</button>
                                                </div>
                                            </div>
                                        </div> */}

                                        {/* <p className="p-t-10 text-primary"><b>Search Result</b></p> */}

                                        <div className="panel">
                                            <div className="panel-heading p-0">
                                                <ul className="nav nav-tabs nav-tabs-bottom nav-horizantal-scroll m-b-0">
                                                    <li className={this.state.show_driver && "active"}>
                                                        <a data-toggle="tab" aria-expanded="true" href="#ExistingDriver" onClick={this.searchDriver}>
                                                            <h6>Existing Driver</h6>
                                                        </a>
                                                    </li>
                                                    <li className={this.state.show_packer && "active"}>
                                                        <a data-toggle="tab" aria-expanded="true" href="#ExistingPacker" onClick={this.searchPacker}>
                                                            <h6>Existing Packer</h6>
                                                        </a>
                                                    </li>
                                                    {this.state.custom_dept_list.map(item => {
                                                        return <li className={(this.state.activeStaffId == item.id) && "active"}>
                                                            <a data-toggle="tab" aria-expanded="true" href="#ExistingStaff" onClick={(e) => this.searchStaff(e, item.id)}>
                                                                <h6>{item.title}</h6>
                                                            </a>
                                                        </li>
                                                    })}
                                                </ul>
                                            </div>
                                            <div className="panel-body">
                                                {this.state.show_driver &&
                                                    <div className="tab-content">
                                                        <div className="tab-pane active" id="ExistingDriver">
                                                            <div className="table-responsive well p-0">
                                                                {
                                                                    this.state.search_loading
                                                                    &&
                                                                    <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                                                                }
                                                                {
                                                                    !this.state.search_loading
                                                                    &&
                                                                    this.state.search_driver_list.length !== 0
                                                                    && (
                                                                        <>
                                                                            <div className="well">
                                                                                <label className="text-semibold">
                                                                                    Services Needed&nbsp;
                                                                                    <span className="text-danger">*</span>
                                                                                </label>
                                                                                <ul className="checkbox-list">
                                                                                    {
                                                                                        this.state.services_needed_array.length != 0
                                                                                        &&
                                                                                        this.state.services_needed_array.map((item, i) => {
                                                                                            return (
                                                                                                <li key={i}><label className="checkbox-inline"><input type="checkbox" name="services_needed" value={item} checked={this.state.serviceNeededForMyTeam.indexOf(item) > -1} onChange={this.setServicesForMyTeam} /><span className=""></span>{item}</label></li>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                            <table className="table table-striped">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Driver Name</th>
                                                                                        {/* <th>Driver Mobile</th>
                                                                                        <th>Driver Email</th> */}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        this.state.search_driver_list.map((d_list, index) => {
                                                                                            return (
                                                                                                <tr key={index}>
                                                                                                    <td>
                                                                                                        <label className="checkbox-inline">
                                                                                                            <input type="checkbox" onClick={(e) => this.chkDrivers(e, d_list.profile_id, d_list.user_id)} value={d_list.user_id} name={d_list.first_name + ' ' + d_list.last_name} />
                                                                                                            <span className=""></span>{d_list.gender} {d_list.first_name} {d_list.last_name}
                                                                                                        </label>
                                                                                                    </td>
                                                                                                    {/* <td>{d_list.contact_no}</td>
                                                                                                    <td>{d_list.email}</td> */}
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                        )
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </>)}
                                                                {
                                                                    !this.state.search_loading
                                                                    &&
                                                                    this.state.search_driver_list.length === 0
                                                                    &&
                                                                    <div className="alert alert-primary border-none text-center">No Driver Found.</div>
                                                                }
                                                            </div>

                                                            {
                                                                !this.state.search_loading
                                                                &&
                                                                this.state.search_driver_list.length !== 0
                                                                &&
                                                                <div className="text-right">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-success m-t-10"
                                                                        onClick={this.assignDriver}
                                                                        disabled={this.state.selectedProviders.length === 0 || this.state.serviceNeededForMyTeam.length === 0}
                                                                    >
                                                                        Assign
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>}

                                                {this.state.show_packer &&

                                                    <div className="tab-content">
                                                        <div className="tab-pane active" id="ExistingPacker">
                                                            <div className="table-responsive well p-0">
                                                                {
                                                                    this.state.search_loading
                                                                    &&
                                                                    <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                                                                }
                                                                {
                                                                    !this.state.search_loading
                                                                    &&
                                                                    this.state.search_packer_list.length !== 0
                                                                    && (
                                                                        <>
                                                                            <div className="well">
                                                                                <label className="text-semibold">
                                                                                    Services Needed&nbsp;
                                                                                    <span className="text-danger">*</span>
                                                                                </label>
                                                                                <ul className="checkbox-list">
                                                                                    {
                                                                                        this.state.services_needed_array.length != 0
                                                                                        &&
                                                                                        this.state.services_needed_array.map((item, i) => {
                                                                                            return (
                                                                                                <li key={i}><label className="checkbox-inline"><input type="checkbox" name="services_needed" value={item} checked={this.state.serviceNeededForMyTeam.indexOf(item) > -1} onChange={this.setServicesForMyTeam} /><span className=""></span>{item}</label></li>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                            <table className="table table-striped">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Packer Name</th>
                                                                                        {/* <th>Packer Mobile</th>
                                                                                        <th>Packer Email</th> */}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        this.state.search_packer_list.map((p_list, index) => {
                                                                                            return (
                                                                                                <tr key={index}>
                                                                                                    <td>
                                                                                                        <label className="checkbox-inline">
                                                                                                            <input type="checkbox" onClick={(e) => this.chkPacker(e, p_list.profile_id, p_list.user_id)} value={p_list.user_id} name={p_list.first_name + ' ' + p_list.last_name} />
                                                                                                            <span className=""></span>{p_list.gender} {p_list.first_name} {p_list.last_name}
                                                                                                        </label>
                                                                                                    </td>
                                                                                                    {/* <td>{p_list.contact_no}</td>
                                                                                                    <td>{p_list.email}</td> */}
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                        )
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </>)}
                                                                {
                                                                    !this.state.search_loading
                                                                    &&
                                                                    this.state.search_packer_list.length === 0
                                                                    &&
                                                                    <div className="alert alert-primary border-none text-center">No Packer Found.</div>
                                                                }
                                                            </div>

                                                            {
                                                                !this.state.search_loading
                                                                &&
                                                                this.state.search_packer_list.length !== 0
                                                                &&
                                                                <div className="text-right">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-success m-t-10"
                                                                        onClick={this.assignDriver}
                                                                        disabled={this.state.selectedProviders.length === 0 || this.state.serviceNeededForMyTeam.length === 0}
                                                                    >
                                                                        Assign
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>}

                                                {this.state.show_staff &&

                                                    <div className="tab-content">
                                                        <div className="tab-pane active" id="ExistingStaff">
                                                            <div className="table-responsive well p-0">
                                                                {
                                                                    this.state.search_loading
                                                                    &&
                                                                    <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                                                                }
                                                                {
                                                                    !this.state.search_loading
                                                                    &&
                                                                    this.state.search_staff_list.length !== 0
                                                                    && (
                                                                        <>
                                                                            <div className="well">
                                                                                <label className="text-semibold">
                                                                                    Services Needed&nbsp;
                                                                                    <span className="text-danger">*</span>
                                                                                </label>
                                                                                <ul className="checkbox-list">
                                                                                    {
                                                                                        this.state.services_needed_array.length != 0
                                                                                        &&
                                                                                        this.state.services_needed_array.map((item, i) => {
                                                                                            return (
                                                                                                <li key={i}><label className="checkbox-inline"><input type="checkbox" name="services_needed" value={item} checked={this.state.serviceNeededForMyTeam.indexOf(item) > -1} onChange={this.setServicesForMyTeam} /><span className=""></span>{item}</label></li>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                            <table className="table table-striped">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Staff Name</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        this.state.search_staff_list.map((p_list, index) => {
                                                                                            return (
                                                                                                <tr key={index}>
                                                                                                    <td>
                                                                                                        <label className="checkbox-inline">
                                                                                                            <input type="checkbox" onClick={(e) => this.chkPacker(e, p_list.profile_id, p_list.user_id)} value={p_list.user_id} name={p_list.first_name + ' ' + p_list.last_name} />
                                                                                                            <span className=""></span>{p_list.gender} {p_list.first_name} {p_list.last_name}
                                                                                                        </label>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                        )
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </>)}
                                                                {
                                                                    !this.state.search_loading
                                                                    &&
                                                                    this.state.search_staff_list.length === 0
                                                                    &&
                                                                    <div className="alert alert-primary border-none text-center">No Staff Found.</div>
                                                                }
                                                            </div>

                                                            {
                                                                !this.state.search_loading
                                                                &&
                                                                this.state.search_staff_list.length !== 0
                                                                &&
                                                                <div className="text-right">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-success m-t-10"
                                                                        onClick={this.assignStaff}
                                                                        disabled={this.state.selectedProviders.length === 0 || this.state.serviceNeededForMyTeam.length === 0}
                                                                    >
                                                                        Assign
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                }

                {
                    this.state.processingDeclineStaff
                    &&
                    <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                }

                {/* Start of cya files Modal
                */ }
                <div id="myVideo" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header p-b-0">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="m-b-0">Cya Video</h4>
                            </div>
                            <div className="modal-body">
                                {/* <!-- Timeline --> */}

                                <video src={this.state.cya_video_path} type="video/mp4" controls="controls" autoPlay={false} loop >

                                </video>
                            </div>
                        </div>

                    </div>
                </div>
                <div id="conversationModal" className="modal fade event" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header p-b-0">
                                <button type="button" className="close" data-dismiss="modal" style={{ 'opacity': '1' }}><i className="icon-cancel-square2 text-indigo-800"></i></button>
                                <h6 className="modal-title text-uppercase text-bold text-indigo-800">Conversation With</h6>
                            </div>
                            <div className="modal-body p-0">
                                {this.renderConversations()}
                            </div>
                        </div>
                    </div>
                </div>
                <React.Fragment>
                    <div id="messageModal" className="modal fade event" role="dialog">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header p-b-0">
                                    <button type="button" className="close" data-dismiss="modal" style={{ 'opacity': '1' }}><i className="icon-cancel-square2 text-indigo-800"></i></button>
                                    <h6 className="modal-title text-uppercase text-bold text-indigo-800">Conversation With</h6>
                                </div>
                                <div className="modal-body p-0">
                                    {/* {this.renderConversations()} */}
                                    {this.renderMessageIndividual()}
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                {/* 
                * End of cya files Modal
                */ }
                {
                    <div id="tracking-Move-done-status" className="modal fade" role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="well text-center m-10"><i className="icon-spinner4 spinner position-left"></i> Processing...</div>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyDJvNKFF3zHyOKcuSWzJ2RFMQV1Eu3gihI",
})(OrderDetails);
// export default OrderDetails;