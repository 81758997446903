import React, { Component } from 'react'
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataWithParam from '../services/PostDataWithParam';
import { Redirect, Link } from 'react-router-dom';
import GetData from '../services/GetData';
import { FormatSSN } from '../externaljs/FormatSSN';

class AddStaff extends Component {

    constructor(props) {
        super(props);
        this.initAutocomplete = this.initAutocomplete.bind(this);
        this.state = {
            breadcrumb:[
                {title:'Dashboard',link:'/independent-mover/dashboard/home',class:''},
                {title:'Manage Staff',link:'/independent-mover/dashboard/other-staff',class:''},
                {title:'Add Staff',link:'',class:'active'}
            ],
            fields: {
                first_name: '',
                last_name: '',
                email: '',
                contact_no: '',

                alternate_contact_no: '',
                gender: 'Mr.',
                dob: '',
                ssn: '',
                address: '',
                latitude: '',
                longitude: '',
                alternate_email: '',
                custom_profile_id: []
            },
            addStaffStatus: false,
            staffErr: '',
            profileList: [],
            processing: false,
            error: {
                first_name_err: '',
                last_name_err: '',
                email_err: '',
                contact_no_err: '',
                alternate_contact_no_err: ''
            },
            selectProfileError: ""
        }
    }
    componentDidMount() {
        this.getCustomProfileList();

        window.$('.input-group.date').daterangepicker({
            showDropdowns: true,
            autoclose: true,
            singleDatePicker: true,
            maxDate: new Date()
        }).on('apply.daterangepicker', (ev, picker) => {
            let fields = this.state.fields;
            fields.dob = picker.startDate.format('MM/DD/YYYY');
            this.setState({ fields: fields });
        });

        FormatSSN();
        this.initMultiSelectProfile();

    }

    initMultiSelectProfile = () => {
        window.$('.select-profile-xs-mul').select2({
            containerCssClass: 'select-xs'
        })
            .on('select2:select', e => {
                let fields = { ...this.state.fields };
                console.log("Select", e.params.data.id)
                fields.custom_profile_id.push(e.params.data.id)
                this.setState({ fields }, this.checkSelectProfileError);
            })
            .off('select2:unselect')
            .on('select2:unselect', e => {
                let fields = { ...this.state.fields };
                let tempProfileId = fields.custom_profile_id.filter((id) => id != e.params.data.id);
                fields.custom_profile_id = tempProfileId
                this.setState({ fields }, this.checkSelectProfileError)
            })
    }
    initAutocomplete = (e) => {
        let fields = Object.assign({}, this.state.fields);

        if (e.target.name == "address")
            fields.address = e.target.value;
        this.setState({ fields: fields });
        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: [] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let fields = this.state.fields;
            fields.latitude = place.geometry.location.lat();
            fields.longitude = place.geometry.location.lng();
            fields.address = place.formatted_address;
            this.setState({ fields: fields });
        })
    }

    getCustomProfileList = () => {
        GetData('GetCustomProfileList')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ profileList: response.data });
                }
            })
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        if (e.target.name == 'contact_no' || e.target.name == 'alternate_contact_no') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                fields[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
            else {
                fields[e.target.name] = cleaned;
            }
        }
        this.setState({ fields: fields });
    }

    handleAddStaff = (e) => {
        e.preventDefault();
        if(this.checkSelectProfileError()) return;

        this.setState({ processing: true }, () => {
            PostDataWithParam('AddStaffs', this.state.fields)
                .then((response) => {
                    let result = response;
                    if (result.success === 1) {
                        window.swal('Success!', response.msg, 'success')
                        this.setState({ addStaffStatus: true, processing: false });
                    } else {
                        window.swal('Error!', response.msg, 'error')
                        this.setState({ addStaffStatus: false, processing: false });
                    }
                })
                .catch(err => {
                    window.swal('Error!', 'Oops! Something went wrong.', 'error')
                    this.setState({ addStaffStatus: false, processing: false });
                })
        })
        // }
    }

    renderSubmitBtn = () => {
        if (this.state.processing) {
            return <input type="button" className="btn btn-primary" value="Processing..." disabled={true} />
        } else {
            return <button type="submit" className="btn btn-primary" >Add</button>
        }
    }
    checkSelectProfileError = () => {
        let selectProfileError = ""
        if (!this.state.fields.custom_profile_id.length) {
            selectProfileError = "Please select any profile."
        }
        this.setState({ selectProfileError });
        return selectProfileError;
    }
    render() {
        if (this.state.addStaffStatus) {
            return <Redirect to={'/independent-mover/dashboard/team-member-details/all/'} />
        }

        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="panel panel-flat profile-page">
                        <div className="panel-heading p-b-0">
                            <h5 className="panel-title">Add Staff Details</h5>
                        </div>
                        <form action="" method="POST" onSubmit={this.handleAddStaff}>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Salutation <span className="text-danger">*</span></label>
                                            <select className="form-control" name="gender" onChange={this.changeValue} required={true}>
                                                <option>Mr.</option>
                                                <option>Ms.</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label>First Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter First Name" name="first_name" onChange={this.changeValue} required={true} />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label>Last Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter Last Name" name="last_name" onChange={this.changeValue} required={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Email Id <span className="text-danger">*</span></label>
                                            <input className="form-control" type="email" name="email" onChange={this.changeValue} required={true} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Phone No <span className="text-danger">*</span></label>
                                            <input className="form-control" placeholder="xxx xxx xxxx" type="text" name="contact_no" onChange={this.changeValue} required={true} value={this.state.fields.contact_no} maxLength="12" />
                                            <p className="text-danger help-block">{this.state.error.contact_no_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Alternate Phone No</label>
                                            <input className="form-control" type="text" placeholder="xxx xxx xxxx" name="alternate_contact_no" onChange={this.changeValue} value={this.state.fields.alternate_contact_no} maxLength="12" />
                                            <p className="text-danger help-block">{this.state.error.alternate_contact_no_err}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Address <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" id="address" value={this.state.fields.address || ''} onChange={this.initAutocomplete} name="address" required={true} />
                                        </div>
                                    </div>


                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Date of Birth <span className="text-danger">*</span></label>
                                            <div className="input-group date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control" id="dob" value={this.state.fields.dob || ''} name="dob" required={true} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4">
                                        <div className="form-group">
                                            <label>SSN <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control ssn" name="ssn" onChange={this.changeValue} maxLength="11" placeholder="Enter your social security number" required={true}/>
                                        </div>
                                    </div> */}
                                </div>
                                <h4 className="m-b-0">Profile</h4>
                                <hr className="m-t-5" />
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Select Profile <span className="text-danger">*</span></label>
                                            <select data-placeholder="Select Profile" className="select-profile-xs-mul" multiple="multiple">
                                                <option value="" ></option>
                                                {this.state.profileList.map(profile =>

                                                    <option key={profile.id} value={profile.id} >{profile.title}</option>
                                                )}
                                            </select>
                                            <p className="text-danger help-block">{this.state.selectProfileError}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-footer text-right p-r-20">
                                {
                                    this.state.processing
                                    &&
                                    <button type="button" className="btn btn-primary" disabled={true}>Processing...</button>
                                }
                                {
                                    !this.state.processing
                                    &&
                                    <button type="submit" className="btn btn-primary">Save</button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddStaff;