import React, { Component } from 'react'
import PostDataWithParam from '../services/PostDataWithParam';
import GetData from '../services/GetData';

class RatingReview extends Component {
  state = {
    rating_details: {
      five: 0,
      four: 0,
      three: 0,
      two: 0,
      one: 0,
      avg: 0,
      individual_rating: []
    },
    loading: false
  }

  componentDidMount() {
    this.getAllRatingReview();
  }

  getAllRatingReview = () => {
    this.setState({ loading: true }, () => {
      GetData('GetAllRatingReview')
        .then((response) => {
          if (response.success === 1) {
            this.setState({ rating_details: response.data, loading: false });
            
          } else {
            this.setState({ rating_details: this.state.rating_details, loading: false });
          }
        })
        .catch(err => {
          this.setState({ rating_details: this.state.rating_details, loading: false });
        })
    });
  }

  render() {
    return (
      <div>
        <div className="row">
            <div className="col-sm-3">
            {
                Math.ceil(this.state.rating_details.avg) > 0
                &&
                <div className="rating-block">
                    <h4>Average user rating</h4>
                    <h2 className="text-semi-bold f-s-35">{this.state.rating_details.avg} <small>/ 5</small></h2>
                    <div className="star-ratting-dislay m-t-5 m-b-5">
                        <span className={(Math.ceil(this.state.rating_details.avg) >= 1)?"text-warning-300 m-r-5":"text-grey-300 m-r-5"}>
                            <i className="icon-star-full2 f-s-35"></i>
                        </span>
                        <span className={(Math.ceil(this.state.rating_details.avg) >= 2)?"text-warning-300 m-r-5":"text-grey-300 m-r-5"}>
                            <i className="icon-star-full2 f-s-35"></i>
                        </span>
                        <span className={(Math.ceil(this.state.rating_details.avg) >= 3)?"text-warning-300 m-r-5":"text-grey-300 m-r-5"}>
                            <i className="icon-star-full2 f-s-35"></i>
                        </span>
                        <span className={(Math.ceil(this.state.rating_details.avg) >= 4)?"text-warning-300 m-r-5":"text-grey-300 m-r-5"}>
                            <i className="icon-star-full2 f-s-35"></i>
                        </span>
                        <span className={(Math.ceil(this.state.rating_details.avg) >= 5)?"text-warning-300 m-r-5":"text-grey-300 m-r-5"}>
                            <i className="icon-star-full2 f-s-35"></i>
                        </span>
                      </div>
                </div>
            }
            {
                Math.ceil(this.state.rating_details.avg) == 0.0
                &&
                <div className="rating-block">
                    <h4>Average user rating</h4>
                    <h2 className="text-semi-bold f-s-35">--- <small>/ 5</small></h2>
                    <div className="star-ratting-dislay m-t-5 m-b-5">
                      <span className="text-grey-300 m-r-5">
                          <i className="icon-star-full2 f-s-35"></i>
                      </span>
                      <span className="text-grey-300 m-r-5">
                          <i className="icon-star-full2 f-s-35"></i>
                      </span>
                      <span className="text-grey-300 m-r-5">
                          <i className="icon-star-full2 f-s-35"></i>
                      </span>
                      <span className="text-grey-300 m-r-5">
                          <i className="icon-star-full2 f-s-35"></i>
                      </span>
                      <span className="text-grey-300 m-r-5">
                          <i className="icon-star-full2 f-s-35"></i>
                      </span>
                    </div>
                </div>
            }
            </div>
            <div className="col-sm-3">
                <h4>Rating breakdown</h4>
                <div className="pull-left">
                    <div className="pull-left"  style={{ width: '35px' , lineHeight: '1'}}>
                        <div  style={{ height: '9px' , margin: '5px 0'}}>5 <span className="icon-star-full2"></span></div>
                    </div>
                    <div className="pull-left" style={{ width: '180px'}}>
                        <div className="progress"  style={{ height: '9px' , margin: '8px 0' }}>
                          <div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="5" style={ Math.ceil(this.state.rating_details.avg) == 0.0 ? {width:'0%'} :{ width: '100%'}}>
                            <span className="sr-only">80% Complete (danger)</span>
                          </div>
                        </div>
                    </div>
                    <div className="pull-right" style={{ marginLeft: '10px'}}>{this.state.rating_details.five}</div>
                </div>
                  <div className="pull-left">
                    <div className="pull-left"  style={{ width: '35px' , lineHeight: '1'}}>
                        <div  style={{ height: '9px' , margin: '5px 0'}}>4 <span className="icon-star-full2"></span></div>
                    </div>
                    <div className="pull-left" style={{ width: '180px'}}>
                        <div className="progress"  style={{ height: '9px' , margin: '8px 0' }}>
                          <div className="progress-bar progress-bar-primary" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="5" style={ Math.ceil(this.state.rating_details.avg) == 0.0 ? {width:'0%'} :{ width: '80%'}}>
                            <span className="sr-only">60% Complete (danger)</span>
                          </div>
                        </div>
                    </div>
                    <div className="pull-right" style={{ marginLeft: '10px'}}>{this.state.rating_details.four}</div>
                </div>
                <div className="pull-left">
                    <div className="pull-left"  style={{ width: '35px' , lineHeight: '1'}}>
                        <div  style={{ height: '9px' , margin: '5px 0'}}>3 <span className="icon-star-full2"></span></div>
                    </div>
                    <div className="pull-left" style={{ width: '180px'}}>
                        <div className="progress"  style={{ height: '9px' , margin: '8px 0' }}>
                          <div className="progress-bar progress-bar-info" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="5" style={ Math.ceil(this.state.rating_details.avg) == 0.0 ? {width:'0%'} :{ width: '60%'}}>
                            <span className="sr-only">60% Complete (danger)</span>
                          </div>
                        </div>
                    </div>
                    <div className="pull-right" style={{ marginLeft: '10px'}}>{this.state.rating_details.three}</div>
                </div>
                <div className="pull-left">
                    <div className="pull-left"  style={{ width: '35px' , lineHeight: '1'}}>
                        <div  style={{ height: '9px' , margin: '5px 0'}}>2 <span className="icon-star-full2"></span></div>
                    </div>
                    <div className="pull-left" style={{ width: '180px'}}>
                        <div className="progress"  style={{ height: '9px' , margin: '8px 0' }}>
                          <div className="progress-bar  bg-warning-300" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="5"  style={ Math.ceil(this.state.rating_details.avg) == 0.0 ? {width:'0%'} :{ width: '40%'}}>
                            <span className="sr-only">60% Complete (danger)</span>
                          </div>
                        </div>
                    </div>
                    <div className="pull-right" style={{ marginLeft: '10px'}}>{this.state.rating_details.two}</div>
                </div>
                <div className="pull-left">
                    <div className="pull-left"  style={{ width: '35px' , lineHeight: '1'}}>
                        <div  style={{ height: '9px' , margin: '5px 0'}}>1 <span className="icon-star-full2"></span></div>
                    </div>
                    <div className="pull-left" style={{ width: '180px'}}>
                        <div className="progress"  style={{ height: '9px' , margin: '8px 0' }}>
                          <div className="progress-bar  bg-danger-300" role="progressbar" aria-valuenow="5" aria-valuemin="0" aria-valuemax="5" style={ Math.ceil(this.state.rating_details.avg) == 0.0 ? {width:'0%'} :{ width: '20%'}}>
                            <span className="sr-only">60% Complete (danger)</span>
                          </div>
                        </div>
                    </div>
                    <div className="pull-right" style={{ marginLeft: '10px'}}>{this.state.rating_details.one}</div>
                </div>
                
            </div>          
        </div>
        <hr />

        {
          this.state.loading
          &&
          <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
      }
      {
          !this.state.loading
          &&
          this.state.rating_details.individual_rating != 0
          &&
          this.state.rating_details.individual_rating.map((rating, index) =>
            <React.Fragment>
                <div className="row" key={index}>
                    <div className="col-md-12">
                    <div className="media">
                       
                        <div className="media-left"><img src={rating.image != ""?rating.image:'assets/images/default_avatar.jpg'} className="img-circle" width="60" height="60" style={{ border: '1px solid #ccc'}}/></div>
                        <div className="media-body">
                            <div className="clearfix">
                                <h5 className="m-0 pull-left">{rating.name}</h5>
                                <div className="star-ratting-dislay m-t-5 m-b-5 pull-right"><a href="" className="m-r-15">Relo #{rating.order_id}</a>
                                <React.Fragment>
                                    <span className={(Math.ceil(rating.rate) >= 1)?"text-warning-300 m-r-5":"text-grey-300 m-r-5"}>
                                        <i className="icon-star-full2 f-s-15"></i>
                                    </span>
                                    <span className={(Math.ceil(rating.rate) >= 2)?"text-warning-300 m-r-5":"text-grey-300 m-r-5"}>
                                        <i className="icon-star-full2 f-s-15"></i>
                                    </span>
                                    <span className={(Math.ceil(rating.rate) >= 3)?"text-warning-300 m-r-5":"text-grey-300 m-r-5"}>
                                        <i className="icon-star-full2 f-s-15"></i>
                                    </span>
                                    <span className={(Math.ceil(rating.rate) >= 4)?"text-warning-300 m-r-5":"text-grey-300 m-r-5"}>
                                        <i className="icon-star-full2 f-s-15"></i>
                                    </span>
                                    <span className={(Math.ceil(rating.rate) >= 5)?"text-warning-300 m-r-5":"text-grey-300 m-r-5"}>
                                        <i className="icon-star-full2 f-s-15"></i>
                                    </span>
                                    <span className="label bg-warning-300">{rating.rate}.0</span>
                                </React.Fragment> 
                                </div>
                            </div>
                            <p>{rating.public_review}</p>
                        </div>
                    </div>
                    </div>
                </div>
                <hr/>
            </React.Fragment>
          )
        }
        {
          !this.state.loading
          &&
          this.state.rating_details.individual_rating == 0
          &&
          <div className="alert alert-danger text-center">Not rated yet.</div>
        }
      </div>
    );
  }
}

export default RatingReview;