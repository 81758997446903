import React, { Component } from 'react'
import HeaderMain from './HeaderMain';
import FooterMain from './FooterMain';
import PostDataWithParam from '../../services/PostDataWithParam';
import { Redirect } from 'react-router-dom';
import { FormGroupMaterial } from '../../externaljs/FormGroupMaterial';

class ContactUs extends Component {
    state = {
        fields:{
            firstName:'',
            lastName:'',
            companyName:'',
            email:'',
            phoneNumber:'',
            commentQuestion:'',
            profileId:'2',
            url:'GetMilitaryApproved.com',
            source:'web'
        },
        processing: false,
        saveStatus:false
    }

    componentDidMount() { 
        FormGroupMaterial();
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        if( e.target.name=='phoneNumber')
        {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                fields[e.target.name]= match[1] + ' ' + match[2] + ' ' + match[3]
              }
        }
        this.setState({ fields: fields });
    }

    handleContactus = (e) => {
        e.preventDefault();
        this.setState({ processing: true }, () => {
            PostDataWithParam('ContactUs', this.state.fields)
            .then((response) => {
                if (response.success == 1) {
                    window.swal('Success!', response.msg, 'success')
                    this.setState({ saveStatus: true,processing: false });
                } else {
                    window.swal('Error!', response.msg, 'error')
                    this.setState({ processing: false })
                }
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong.', 'error')
                this.setState({ processing: false });
            })
        })
    }

    renderSubmitBtn = () => {
        if (this.state.processing) {
            return <input type="button" className="btn bg-red" value="Processing..." disabled={true} />
        } else {
            return <button type="submit" className="btn bg-red">Submit</button>
        }
    }

    render() {
        if (this.state.saveStatus) {
            return <Redirect to='/home' />
        }

        return (
            <div>
                <HeaderMain />
                <div className="contact-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="banner-content text-left text-white apps">
                                    <h1>How Can We Help You Get Military Approved?</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="contact-form-wrap p-t-40 p-b-40">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3">                                
                                <form action="#" method="POST" onSubmit={this.handleContactus}>
                                    <div className="col-md-6">
                                        <div className="form-group form-group-material">
                                            <label className="control-label">First Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" placeholder="Enter First Name" name="firstName" onChange={this.changeValue} required/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-group-material">
                                            <label className="control-label">Last Name <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" placeholder="Enter Last Name" name="lastName" onChange={this.changeValue} required/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group form-group-material">
                                            <label className="control-label">Company Name</label>
                                            <input type="text" className="form-control" placeholder="Enter Company Name (if applicable)" name="companyName" onChange={this.changeValue}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-group-material">
                                            <label className="control-label">Email Address <span className="text-danger">*</span></label>
                                            <input type="email" className="form-control" placeholder="Enter Email Address" name="email" onChange={this.changeValue} required/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group form-group-material">
                                            <label className="control-label">Phone Number <span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" placeholder="Contact Phone Number" name="phoneNumber" value={this.state.fields.phoneNumber} onChange={this.changeValue} required maxLength="10"/>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group form-group-material">
                                            <label className="control-label">Comment or Question <span className="text-danger">*</span></label>
                                            <textarea className="form-control" placeholder="Enter Comment or Question" name="commentQuestion" onChange={this.changeValue} required></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-center">
                                        {this.renderSubmitBtn()}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                <FooterMain />
            </div>
        );
    }
}

export default ContactUs;