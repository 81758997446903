import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { activeModulesReducer,getInboxCountReducer } from './reducer';

const reducers = combineReducers({
    activeModules: activeModulesReducer,
    InboxCount: getInboxCountReducer
});

export const store = createStore(reducers, {
        activeModules : {
            Claims: false,
            Customer_Satisfaction_Survey: false,
            Customer_Service: false,
            Document_Management: true,
            Internal_Communication_System: true,
            Quality_Control: false,
            Reimbursement: false,
            Helper:false,
            Driver:false,
            claimsMain:true
        },
        InboxCount : 0
    },
    compose(applyMiddleware(thunk))
);