import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import GetData from '../services/GetData';
import PostDataFile from '../services/PostDataFile';
import MilitaryConversations from './MilitaryConversations';
import { Prompt } from 'react-router';
class InsectControl extends Component {
    state = {
        fields: {
            insect_rodent_control_company:-1,
            last_pest_control_report:null,     
        },
        insect_rodent_control_id:'',
        tab_id:4,
        saveStatus:false,
        proccesing:false,
        formDirty:false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        GetData('GetInsectAndRodentControl')
        .then((response) => {
            if (response.success == 1) {
                this.setState({ fields:response.data[0], insect_rodent_control_id: response.data[0].id });
            }
        })
        window.$('[data-popup="popover"]').popover();
        window.$('[data-popup="tooltip"]').tooltip();
    }

    handleFileSelect = (e) => {
        let fields = this.state.fields;
        
        fields.last_pest_control_report_file = e.target.files[0];
        this.setState({ fields: fields,formDirty:true });
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields,formDirty:true });
    }

    updateInsectControl = () => {
        this.setState({proccesing:true})
        let fd = new FormData();
        fd.append('tab_id', this.state.tab_id);
        fd.append('insect_rodent_control_company', this.state.fields.insect_rodent_control_company);
        fd.append('insect_rodent_control_id', this.state.insect_rodent_control_id);
        
        
        if(this.state.fields.last_pest_control_report_file && this.state.fields.last_pest_control_report_file.name)
            fd.append('last_pest_control_report', this.state.fields.last_pest_control_report_file, this.state.fields.last_pest_control_report_file.name);

        PostDataFile('SaveInsectAndRodentControl',fd)
        .then((response) => {
            if (response.success === 1) {
                window.swal('Success!', response.msg,'success')
                this.setState({ saveStatus: true,proccesing:false,formDirty:false }, () => {
                    this.props.onUpdateApprovalProgress();
                });  
            } else {
                this.setState({proccesing:false,formDirty:false})
                window.swal('Error!', response.msg,'error')
            }
        })
    }

    openModal = (field_name,tab_id) => {
        this.props.onOpenModal(field_name,tab_id)
    }

    getFileName(file){
        if(file!=null){
            let files = file.split('/');
            return files[files.length -1];
        }        
    }

    renderAlertMsg = (field_name,tab_id) => {
        
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
        

        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Answer Received</p>
                        </React.Fragment>
                    )
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Waiting for reply</p>
                        </React.Fragment>
                    )
                }
            }
        }
    }
    renderClassName = (field_name,tab_id) => {
        
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
         

        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return "btn-success "
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return "btn-warning "
                }
            }
        }


    }
    render() {
        if (this.state.saveStatus) {
            return <Redirect to='/independent-mover/dashboard/military-approved/information-tab/housekeeping'/>
        }

        return (
            <div className="tab-pane active" id="top-icon-tab4">
                <Prompt
                when={this.state.formDirty}
                message="Please save your data before you leave the page. Otherwise, you will lose the information you entered !"
                />
                <div className="panel panel-flat">
                    <div className="panel-heading">
                        <h5 className="panel-title">Insect and Rodent Control &nbsp;<a href="#" data-popup="popover" title="" data-trigger="hover" data-content="Warehouses will not show evidence of insect and/or rodent infestation. Warehousemen will have an established program , conducted every 30 days, either self-administered or provided by a reputable outside firm, for the control and extermination of insects and rodents, and will include moth control in all areas utilized for the storage of overstuffed furniture (e.g., couches, chairs), rugs, and pianos.Warehousemen will keep records to confirm the existence of the program."><i className="icon-info22"></i></a></h5>
                    </div>
                    <div className="panel-body">
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Do you use a professional insect-rodent control company?</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="insect_rodent_control_company" onChange={this.changeValue} checked={(this.state.fields.insect_rodent_control_company==1)?true:false} />
                                        Y
                                        </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="insect_rodent_control_company" onChange={this.changeValue} checked={(this.state.fields.insect_rodent_control_company==0)?true:false}/>
                                        N
                                        </label>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('insect_rodent_control_company',this.state.tab_id)} className={this.renderClassName('insect_rodent_control_company',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('insect_rodent_control_company',this.state.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Upload Last Pest Control Report &nbsp; <small><a href="assets/images/Pest-Control-Report-Sheet.pdf" target="_blank" download>See Sample</a></small></h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <input type="file" name="last_pest_control_report" onChange={this.handleFileSelect}/>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('last_pest_control_report',this.state.tab_id)} className={this.renderClassName('last_pest_control_report',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('last_pest_control_report',this.state.tab_id)} 
                                </div>
                            </div>
                            {
                                this.state.fields.last_pest_control_report
                                &&
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="file-wrapper filetype-image">
                                            <div className="file-title"><a href="javascript:void(0)" data-src={this.state.fields.last_pest_control_report} data-title="Preview" data-toggle="image-modal-file" className="image-modal-file"><i className="icon-image5 position-left text-primary"></i> {this.getFileName(this.state.fields.last_pest_control_report)}</a></div>
                                        </div>
                                    </div>
                                </div>
                            } 
                        </div>
                    </div>
                </div>
                <MilitaryConversations/>
                <div className="text-center">{this.state.proccesing? <button className="btn btn-primary" disabled={true}>Processing...</button>:<button className="btn btn-primary" onClick={this.updateInsectControl}>Save &amp; Next</button>}</div>
            </div>

        );
    }
}

export default InsectControl;