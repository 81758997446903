import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import PostDataWithParam from '../../services/PostDataWithParam';
import Storage from '../../externaljs/Storage';
import Urls from '../../externaljs/Urls';
import { FormGroupMaterial } from '../../externaljs/FormGroupMaterial';

class Login extends Component {
    state = {
        email: '',
        password: '',
        profile_id: 2,
        loggedIn: false,
        emailErr: '',
        passwordErr: '',
        loginErr: '',
        regSuc: '',
        processing: false,
        custom_profile_id: '',
        isLoadCustomMenu: false,
        urlToken: '',
        is_mover_appz: "",
        searchQuery: "",
    }

    componentDidMount() {
        if (!!this.props.location.search) {
            this.setState({ searchQuery: this.props.location.search });
            let queryParams = new URLSearchParams(this.props.location.search);
            if (queryParams !== '') {
                let urlToken = queryParams.get("token");
                let is_mover_appz = queryParams.get("is_mover_appz");
                if (!!urlToken) {
                    this.setState({ urlToken: urlToken }, () => {
                        this.handleActivationLogin();
                    });
                }
                if (!!is_mover_appz)
                    this.setState({ is_mover_appz: 1 })

            }
        }
        if (typeof this.props.location.state !== 'undefined') {
            this.setState({ regSuc: this.props.location.state.regErr, loginErr: '' });
        }
        FormGroupMaterial();
    }

    componentWillUnmount() {
        this.setState({ regSuc: '' });
        this.setState({ loginErr: '' });
    }


    changeValue = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleActivationLogin = (e) => {
        this.setState({ processing: true }, () => {
            PostDataWithParam('AuthenticateDetails', { 'token': this.state.urlToken, is_independent_mover: 1 })
                .then((response) => {
                    let result = response;
                    if (result.success === 1) {
                        Storage.set('imnLoginToken', result.token);
                        Storage.set('imnLoginData', JSON.stringify(result.data));
                        Storage.set('imnFirstLogin', JSON.stringify({ organization_name: result.data.organization_name, logo: result.data.logo }));
                        this.setState({ loggedIn: true });
                        this.getCustomProfileMenu();
                    } else {
                        this.setState({ loginErr: result.msg, regSuc: '', loggedIn: false, processing: false });
                    }
                })
                .catch(err => {
                    this.setState({ loginErr: 'Oops! Something went wrong.', regSuc: '', loggedIn: false, processing: false });
                })
        })
    }
    handleLogin = (e) => {
        e.preventDefault();
        if (!this.state.email) {
            this.setState({ emailErr: 'Email is required.' });
        }
        else if (!this.state.password) {
            this.setState({ emailErr: '' });
            this.setState({ passwordErr: 'Password is required.' });
        } else {
            this.setState({ emailErr: '' });
            this.setState({ passwordErr: '' });

            this.setState({ processing: true }, () => {
                PostDataWithParam('Authenticate', { email: this.state.email, password: this.state.password, profile_id: 2, is_independent_mover: 1 })
                    .then((response) => {
                        let result = response;
                        if (result.success === 1) {
                            Storage.set('imnLoginToken', result.token);
                            Storage.set('imnLoginData', JSON.stringify(result.data));
                            Storage.set('imnFirstLogin', JSON.stringify({ organization_name: result.data.organization_name, logo: result.data.logo }));
                            this.setState({ loggedIn: true });
                            this.getCustomProfileMenu();
                            this.props.history.push({
                                pathname: "/independent-mover/dashboard",
                                search: this.props.location.search
                            });
                        } else {
                            if (result.is_active && result.is_active === "0") {
                                this.props.history.push({
                                    pathname: '/activation-message/signin',
                                    state: { regMsg: this.state.regErr },
                                    search: this.props.location.search
                                });
                            }
                            this.setState({ loginErr: result.msg, regSuc: '', loggedIn: false, processing: false });
                        }
                    })
                    .catch(err => {
                        this.setState({ loginErr: 'Oops! Something went wrong.', regSuc: '', loggedIn: false, processing: false });
                    })
            })
        }
    }

    getCustomProfileMenu() {

        let custom_profile_id = (JSON.parse(Storage.get('imnLoginData'))).custom_profile_id;
        this.setState({ custom_profile_id: custom_profile_id }, () => {
            // console.log(this.state.custom_profile_id);
        });
        if (custom_profile_id != "") {
            try {
                this.state.isLoadCustomMenu = false;
                let response = PostDataWithParam('GetCustomProfileWiseMenuList', { custom_profile_id: custom_profile_id })

                if (response.success == 1) {
                    // let custom_menu = [];
                    let custom_menu = {
                        Messages: null,
                        "Offers Received": null,
                        ReloOrders: null,
                        "Manage Claims": null,
                        "Find & Book a Pro": null,
                        "Manage Team": null,
                        "Manage Customer": null,
                        "Customer Satisfaction Survey": null,
                        Inventory: null
                    };
                    for (let j = 0; j < response.data.length; j++) {
                        custom_menu[response.data[j].title] = response.data[j];
                    }
                    // Storage.set('mmcStaffMenu', JSON.stringify(Object.values(custom_menu)));
                    Storage.set('mmcStaffMenu', JSON.stringify(Object.values(custom_menu).filter(menu => !!menu)));
                    this.setState({ isLoadCustomMenu: true });

                } else {
                    // await this.setState({
                    //     custom_menu: [],
                    // })
                }
            } catch (err) {
                // await this.setState({
                //     custom_menu: [],
                // })
            }
        }
        else {
            this.setState({ isLoadCustomMenu: true });
        }
    }

    renderSubmitBtn = () => {
        if (this.state.processing) {
            return <input type="button" className="btn btn-primary btn-block" value="Processing..." disabled={true} />
        } else {
            return <button type="submit" className="btn btn-primary btn-block">LOGIN</button>
        }
    }

    showAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-danger"><i className="icon-cross position-left"></i>
                    {this.state.loginErr}
                </div>
            )
        }
    }

    showSuccessAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-success"><i className="icon-check2 position-left"></i>
                    {this.state.regSuc}
                </div>
            )
        }
    }

    render() {
        const { searchQuery: search } = this.state;
        if (this.state.loggedIn) {
            if ((JSON.parse(Storage.get('imnLoginData'))).profile_id == 2) {
                return <Redirect to={{
                    pathname: "/independent-mover/dashboard",
                    search
                }} />
            }
            else if ((JSON.parse(Storage.get('imnLoginData'))).profile_id == 7) {
                return <Redirect to={{
                    pathname: "/independent-mover/dashboard",
                    search
                }} />
            }
        }

        return (
            <div className={this.state.is_mover_appz === 1 ? "login-page-register moverappz-login register-page" : "login-page-register register-page"}>
                <div className="login-register-box">
                    <div className="text-center login-box-header">
                        <div className="m-b-30">
                            <Link to="/home">
                                <img src="front-assets/images/logo.png" alt="site-logo" width="350" />

                            </Link>
                        </div>
                        <div className="btn-group">
                            <Link to={"/login" + this.props.location.search} className="btn border-slate text-slate-800 btn-flat text-uppercase active">Sign in</Link>
                            <Link to={"/registration-imn" + this.props.location.search} className="btn border-slate text-slate-800 btn-flat text-uppercase">CREATE ACCOUNT
                                <p className="m-0 f-s-13">(FREE TRIAL)</p>
                            </Link>
                        </div>
                        <br />
                        {this.showAlert(this.state.loginErr)}
                        {this.showSuccessAlert(this.state.regSuc)}
                    </div>
                    <form action="#" method="POST" onSubmit={this.handleLogin}>
                        <div className="login-box-body m-t-30">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group form-group-material">
                                        <label className="control-label">Email Address</label>
                                        <input type="text" className="form-control" placeholder="Enter your email address or mobile no." name="email" onChange={this.changeValue} required />
                                        <p className="text-danger help-block">{this.state.emailErr}</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group form-group-material">
                                        <label className="control-label">Password</label>
                                        <input type="password" className="form-control" placeholder="Password" name="password" onChange={this.changeValue} required />
                                        <p className="text-danger help-block">{this.state.passwordErr}</p>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center">
                                    <div className="form-group m-t-40 clearfix text-sm-center text-center">
                                        {this.renderSubmitBtn()}&nbsp;
                                        <h6 className="text-muted">OR</h6>
                                        <Link to="/forgot-password" className="btn btn-link m-t-10 p-0 f-s-13 text-uppercase">Forgot Password?</Link>
                                    </div>
                                </div>
                            </div>
                            <hr className="m-t-10 m-b-10" />
                            <div className="text-center">
                                <Link to="/resend-activation" className="btn btn-link btn-block f-s-13 text-uppercase"><u>Didn’t receive activation email?</u></Link>
                            </div>
                            <hr className="m-t-10 m-b-40" />
                            <div className="login-box-footer text-center f-s-16">
                                <span className="text-grey-400"> Don't have an account?</span>
                                <Link to="/registration-imn" className="">&nbsp;Sign Up Now</Link>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        );
    }
}

export default Login;