import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Breadcumb from '../externaljs/Breadcrumb';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import PostDataWithParam from '../services/PostDataWithParam';
import { DataTableOptional } from '../externaljs/DataTableOptional';
import Storage from '../externaljs/Storage';
import DateFormat from '../externaljs/DateFormat';
class ClosedOrder extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'ClosedReloOrders', link: '', class: 'active' }
        ],
        order_list: [],
        loading: false,
        rating_review: [],
        staff_user_id: null,
        staffOfferOrder: 0
    }
    componentDidMount() {
        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        this.setState({ staff_user_id });
        this.GetOrderList();
    }

    GetOrderList = () => {
        this.removeDataTable();
        this.setState({ loading: true }, () => {
            PostDataWithParam('GetClosedOrderList', { 
                staffOfferOrder: this.state.staffOfferOrder, 
                staff_user_id: this.state.staffOfferOrder == 1 ? this.state.staff_user_id : '' 
            })
                .then((response) => {
                    if (response.success == 1) {
                        this.setState({ order_list: response.data, loading: false }, () => {
                            DataTableOptional(false);
                            window.$('[data-toggle=tooltip]').tooltip();
                        });
                    } else {
                        this.setState({ order_list: [], loading: false });
                    }
                })
                .catch(err => {
                    this.setState({ order_list: [], loading: false });
                })
        })
    }

    removeDataTable = () => {
        if (window.$.fn.DataTable.isDataTable('#list-table')) {
            window.$('#list-table').DataTable().destroy();
        }

        window.$('#list-table tbody').empty();
    }

    renderStatus = (status) => {
        if (status == 0) {
            return (
                <div>
                    <span className="text-orange m-r-5" href="" data-toggle="tooltip" data-placement="top" title="Pending">Pending</span>
                </div>

            )
        }
        else if (status == 1) {
            return (
                <div>
                    <span className="text-success m-r-5" href="" data-toggle="tooltip" data-placement="top" title="In Progress">In Progress</span>
                </div>
            )
        } else if (status == 2) {
            return (
                <div>
                    <span className="text-success m-r-5" href="" data-toggle="tooltip" data-placement="top" title="Completed">Completed</span>
                </div>
            )
        } else if (status == 3) {
            return (
                <div>
                    <span className="text-danger m-r-5" href="" data-toggle="tooltip" data-placement="top" title="Cancelled">Cancelled</span>
                </div>
            )
        }
    }
    renderOrderType = (hire_by_user_id) => {
        if (hire_by_user_id === (JSON.parse(Storage.get('imnLoginData'))).id)
            return ''
        else
            return 'accepted'
    }

    // showClosedOrderType=()=>{

    // }

    getRatingReview = (id) => {
        PostDataWithParam('GetRatingReview', { order_request_detail_id: id, is_mover: 1 })
            .then(response => {
                if (response.success == 1) {
                    this.setState({ rating_review: response.data[0] })
                    window.$("#ratting-give").modal('show')
                }
                else {
                    this.setState({ rating_review: [] })
                    window.$("#ratting-give").modal('show')
                }
            })
            .catch(err => {
                this.setState({ rating_review: [] })
                window.$("#ratting-give").modal('hide')
                console.log(err);
            })
    }

    changeOfferOrderStatus = (e) => {
        if (e.target.checked)
        {
            this.setState({ staffOfferOrder: 1 }, () => { this.GetOrderList() })
        }
            
        else{
            this.setState({ staffOfferOrder: 0 }, () => { this.GetOrderList() })
        }
            
    }

    render() {
        return (
            <div>
                {Breadcumb(this.state.breadcrumb)}
                <div className="clearfix">
                    {/* <div className="pull-right">
							<form className="dropdown pull-right">
							   <a className="dropdown-toggle btn btn-primary" data-toggle="dropdown" aria-expanded="false"><span className="">Show All </span><span className="caret"></span></a>
							   <ul className="dropdown-menu">
							      <li className="active">
							         <a onClick={this.showClosedOrderType()}>Show All</a>
							      </li>
							      <li className="divider m-0"></li>
							      <li>
							         <a onClick={this.showClosedOrderType()}>Show Completed</a>
							      </li>
							      <li className="divider m-0"></li>
							      <li>
							        <a onClick={this.showClosedOrderType()}>Show Cancelled</a>
							      </li>
							   </ul>
							</form>


                        </div> */}
                    <h4 className="pull-left">Closed ReloOrders</h4>
                    {!!this.state.staff_user_id &&
                        <span style={{ float: 'right' }}>
                            <label><b>All Orders</b></label>{' '}
                            <label className="checkbox-inline checkbox-switch switch-danger">
                                <small className="m-r-5 text-muted"></small>
                                <input type="checkbox" onChange={(e) => { this.changeOfferOrderStatus(e) }} /><span></span>
                                <small className="text-muted"></small>
                            </label>
                            {' '}<label><b>My Orders</b></label>
                        </span>
                    }
                </div>
                <div className="panel panel-flat">
                    {
                        this.state.loading
                        &&
                        <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                    }
                    {
                        !this.state.loading
                        &&
                        this.state.order_list.length !== 0
                        &&
                        <table className="table datatable-basic table-striped" id="list-table">
                            <thead>
                                <tr>
                                    <th>Relo #</th>
                                    <th>Customer Name</th>
                                    <th>Posted By</th>
                                    <th>Relocation Request Date</th>
                                    <th>End Date</th>
                                    <th>Current Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.order_list.map((order, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><Link
                                                    to={{
                                                        pathname: '/independent-mover/dashboard/closed-order-details',
                                                        state: {
                                                            id: order.id,
                                                            type: this.renderOrderType(order.hire_by_user_id)
                                                        }
                                                    }}
                                                >#{order.order_id}</Link></td>
                                                <td className="text-nowrap">{order.customer_name}</td>
                                                <td className="text-nowrap">{order.request_from == "own" ? "self" : order.request_from}</td>
                                                <td>{DateFormat.getFormattedDateTime(order.request_start_date, 'm-d-Y')}</td>
                                                <td>{DateFormat.getFormattedDateTime(order.request_end_date, 'm-d-Y')}</td>
                                                <td>{this.renderStatus(order.status)}
                                                    {order.status != 3 &&
                                                        <>
                                                            {
                                                                order.rating_status == 0 ?
                                                                    <a href="javascript:void(0)" className="f-s-12"> <i>Awaiting customer to provide Rating/Review</i></a>
                                                                    :
                                                                    <a href="javascript:void(0)" className="f-s-12" onClick={(e) => this.getRatingReview(order.id)}><i className="icon-file-eye2" ></i> View Rating/Review provided by the Customer</a>
                                                                // data-toggle="modal" data-target="#ratting-give"
                                                            }
                                                        </>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    }
                    {
                        !this.state.loading
                        &&
                        this.state.order_list.length === 0
                        &&
                        <div className="alert alert-primary border-none text-center m-15">No Orders Found.</div>
                    }
                </div>

                <div id="ratting-give" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">View Rate & Review</h4>
                            </div>
                            <br />
                            <hr className="m-0" />
                            <div className="modal-body">
                                {/* <div className="text-center">
                                    <img src={this.state.tempItem.profile_image || "assets/images/provider1.jpg"} className="img-circle" style={{ width: '60px' }} />
                                    <h4 className="m-b-0"><span>{this.state.tempItem.full_name}</span></h4>
                                </div> */}
                                {this.state.rating_review.rate ?
                                    <div className="star-ratting">
                                        {
                                            Math.ceil(this.state.rating_review.rate) > 0
                                            &&
                                            <div className="text-center text-orange">
                                                <p className="m-0">
                                                    <React.Fragment>
                                                        <span className={(Math.ceil(this.state.rating_review.rate) >= 1) ? 'icon-star-full2 f-s-35' : 'icon-star-empty3 f-s-35'}></span>
                                                        <span className={(Math.ceil(this.state.rating_review.rate) >= 2) ? 'icon-star-full2 f-s-35' : 'icon-star-empty3 f-s-35'}></span>
                                                        <span className={(Math.ceil(this.state.rating_review.rate) >= 3) ? 'icon-star-full2 f-s-35' : 'icon-star-empty3 f-s-35'}></span>
                                                        <span className={(Math.ceil(this.state.rating_review.rate) >= 4) ? 'icon-star-full2 f-s-35' : 'icon-star-empty3 f-s-35'}></span>
                                                        <span className={(Math.ceil(this.state.rating_review.rate) == 5) ? 'icon-star-full2 f-s-35' : 'icon-star-empty3 f-s-35'}></span>
                                                    </React.Fragment>
                                                </p>
                                            </div>
                                        }
                                        {
                                            Math.ceil(this.state.rating_review.rate) == 0.0
                                            &&
                                            <div className="text-center text-orange">
                                                <p className="m-0">
                                                    <React.Fragment>
                                                        <span className='icon-star-empty3 f-s-35'></span>
                                                        <span className='icon-star-empty3 f-s-35'></span>
                                                        <span className='icon-star-empty3 f-s-35'></span>
                                                        <span className='icon-star-empty3 f-s-35'></span>
                                                        <span className='icon-star-empty3 f-s-35'></span>
                                                    </React.Fragment>
                                                </p>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div className="star-ratting">
                                        <div className="text-center text-orange">
                                            <p className="m-0">
                                                <React.Fragment>
                                                    <span className='icon-star-empty3 f-s-35'></span>
                                                    <span className='icon-star-empty3 f-s-35'></span>
                                                    <span className='icon-star-empty3 f-s-35'></span>
                                                    <span className='icon-star-empty3 f-s-35'></span>
                                                    <span className='icon-star-empty3 f-s-35'></span>
                                                </React.Fragment>
                                            </p>
                                        </div>
                                    </div>
                                }

                                <div className="form-group">
                                    <label><b>Public Comment</b></label>
                                    <p>{this.state.rating_review.public_review ||
                                        <div className="panel panel-default">
                                            <div className="panel-body">
                                                <div className="alert alert-primary border-none text-center m-0">No Public Comments Found.</div>
                                            </div>
                                        </div>
                                    }
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label><b>Private Comment</b></label>
                                    <p>{this.state.rating_review.private_review ||
                                        <div className="panel panel-default">
                                            <div className="panel-body">
                                                <div className="alert alert-primary border-none text-center m-0">No Private Comments Found.</div>
                                            </div>
                                        </div>
                                    }
                                    </p>
                                </div>
                            </div>
                            <hr className="m-0" />
                            <div className="modal-footer p-t-15">
                                <button type="button" data-dismiss="modal" className="btn btn-default btn-xs">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClosedOrder;