import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import GetData from '../services/GetData';
import { PanelCollapse } from '../externaljs/PanelCollapse';
import { getInboxCount } from '../redux/action';
import Urls from '../externaljs/Urls';
import Storage from '../externaljs/Storage';
import PostDataWithParam from '../services/PostDataWithParam';

class Sidebar extends Component {
    state = {
        activeLink: '',
        modules: [],
        menu: [],
        Claims: false,
        Document_Management: true,
        Reimbursement: false,
        claimsMain: true,
        Drivers: false,
        Helpers: false,
        total_new_msg: '',
        subscription_id: 0,
        msgComposeReply: [],
        custom_profile: [],
        staff_user_id: null,
        custom_menu: [],
        organization_name: '',
        logo: '',
        editPermission: true,
        lists: [],
        logoWidth: '',
        logoHeight: ''
    }

    database = null;
    custom_profile_cnt = 0;
    subscriptionCheckInterval = null;
    initNavJquery = () => {
        window.$('.navigation-main').find('li').has('ul').children('a').off('click')

        //Navigation
        window.$('.navigation-main').find('li').has('ul').children('a').on('click', function (e) {
            e.preventDefault();
            var sideMenu = window.$(this).parent('li').not('.disabled').not(window.$('.sidebar-xs').not('.sidebar-xs-indicator').find('.navigation-main').children('li'));
            window.$('.navigation-main').find('li').not(sideMenu).removeClass('active slide-li');
            sideMenu.addClass('active').toggleClass('slide-li').children('ul').slideToggle(250);

            // Accordion
            if (window.$('.navigation-main').hasClass('navigation-accordion')) {
                sideMenu.siblings(':has(.has-ul)').children('ul').slideUp(250);
            }
        });
    }
    componentDidMount() {
        this.getMsgCount();


        this.database = Urls.firebase.database();
        this.registerNotifications();

        PanelCollapse();
        document.body.addEventListener('subscription', (e) => {
            this.GetUserInfo();
        }, false);
        this.GetUserInfo();
        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        this.setState({ staff_user_id: staff_user_id }, () => {
            setTimeout(this.initNavJquery, 0)

        });
        this.getCustomProfile();
        let logoHeight = '', logoWidth = '';
        this.subscriptionCheckInterval = setInterval(() => {
            let organization_name = (JSON.parse(Storage.get('imnFirstLogin'))).organization_name;
            let logo = (JSON.parse(Storage.get('imnFirstLogin'))).logo;

            this.setState({ organization_name, logo }, () => {
                this.getMeta(
                    logo,
                    function (width, height) {
                        // console.log(width + 'px ' + height + 'px') 
                        logoHeight = height;
                        logoWidth = width;
                    }
                );
                this.setState({ logoHeight, logoWidth }, () => {
                    // console.log(this.state.logoHeight, this.state.logoWidth)
                })
            });

            this.setState({ organization_name, logo });
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1)
                this.setState({ editPermission: false })
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ editPermission: true })
        }, 1000);

        this.GetGMASubscriptionListForSuperAdmin();
    }

    getMeta(url, callback) {
        var img = new Image();
        img.src = url;
        img.onload = function () { callback(this.width, this.height); }
    }

    componentWillUnmount() {
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if ((prevProps.isLoadCustomMenu != this.props.isLoadCustomMenu) && (this.state.staff_user_id != "")) {
            // console.log('sss');
            let custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
            await this.setState({ custom_menu });
            this.initNavJquery();
        }
    }

    doSubscribe = (e) => {
        window.swal({
            title: "Warning!",
            text: "Please upgrade your membership to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }

    doGMASubscribe = (e) => {
        window.swal({
            title: "Warning!",
            text: "Please upgrade your Get Military Approved subscription to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }

    GetUserInfo = () => {
        GetData('GetUserInfo', {})
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ subscription_id: response.data.subscription_id }, () => {

                    });
                }
            })
            .catch(err => { })
    }

    getNavLinkClass = (path, exact = true) => {
        if (exact)
            return this.props.location.pathname === path ? 'active' : '';
        else {
            return ((this.props.location.pathname).indexOf(path) !== -1) ? 'active' : '';
        }
    }

    redirectPath = (path) => {
        return this.props.history.push(path);
    }

    getMsgCount = () => {
        this.props.getInboxCount();
    }

    redirectSubscription = () => {

        window.$('#subscribeToPremiumModal').modal('show');
    }

    registerNotifications() {
        this.database.ref('/notifications/msg_compose_reply/user_' + (JSON.parse(Storage.get('imnLoginData'))).id)
            .on('child_added', (snapshot) => {
                let msgComposeReply = this.state.msgComposeReply;
                let tempData = snapshot.val();
                tempData['nodeUrl'] = '/notifications/msg_compose_reply/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '/' + snapshot.key;

                msgComposeReply.push(tempData);

                let showMsg = "";
                if (tempData.type == "compose")
                    showMsg = tempData.sender_full_name + ' has sent you a message';
                else if (tempData.type == "reply")
                    showMsg = tempData.sender_full_name + ' has sent you a reply';

                this.setState({ msgComposeReply: msgComposeReply });
            })

        this.database.ref('/notifications/msg_compose_reply/user_' + (JSON.parse(Storage.get('imnLoginData'))).id)
            .on('child_removed', (snapshot) => {


                let msg = this.state.msgComposeReply.filter((item, i) => {
                    return item.msg_id != snapshot.val().msg_id
                })

                this.setState({ msgComposeReply: msg });
            })
    }

    getCustomProfile = (e) => {
        PostDataWithParam('ViewCustomProfileForGMA', { profile_id: 2, status: 1 })
            .then((response) => {
                if (response.success == 1) {
                    // console.log('success')
                    this.setState({ custom_profile: response.data }, () => {
                        // console.log(this.state.custom_profile)
                        window.sideMenuSelect();
                    });
                }
                else {

                }

            })
            .catch(err => {
                // window.swal('Error!', 'Something went wrong', 'error');
            })
    }

    GetGMASubscriptionListForSuperAdmin = () => {
        GetData('GetGMASubscriptionListForSuperAdmin')
            .then(response => {
                if (response.success == 1) {
                    this.setState({ lists: response.data, loading: false }, () => {
                        // console.log(this.state.lists.length)
                        let loginData = JSON.parse(Storage.get('imnLoginData'));
                        Storage.set('imnLoginData', JSON.stringify({
                            ...loginData,
                            gma_package_name: this.state.lists[0].package_name,
                            gma_package_price: this.state.lists[0].package_price
                        }))
                    })
                }
                else {
                    this.setState({ lists: [], loading: false })
                }
            })
            .catch(err => {
                this.setState({ lists: [], loading: false })
                window.swal('Error!', 'Oops Something went wrong', 'error')
            })

    }

    render() {

        return (
            <div className="sidebar sidebar-main sidebar-fixed">
                <div className="sidebar-content">
                    <div className="sidebar-user">
                        {/* <div className="logo-movers">
                            
                            {!this.state.logo ?
                                <div className="media">
                                    <div className="media-left p-r-10">
                                        <a href="#"><img src={this.state.logo || "https://myrelo.us/relotechApi/public/uploads/profile_image/default_avatar.jpg"} width="" height="auto" /></a>
                                    </div>
                                    <div className="media-body text-left">
                                        <h4 className="m-0 p-0"><strong>{this.state.organization_name ? this.state.organization_name : "---"}</strong><br />
                                            <p className="m-0 f-s-12 text-muted"><i><b>Powered by Independent Movers Network <sup>&#174;</sup></b></i></p>
                                        </h4>
                                    </div>
                                </div>
                                :
                                <React.Fragment>
                                    <a href="#"><img className="company-logo" src={this.state.logo || "https://myrelo.us/relotechApi/public/uploads/profile_image/default_avatar.jpg"} width="" height="auto" /></a>
                                    <p className="m-0 f-s-12 p-t-10 text-muted text-right"><i><b>Powered by Independent Movers Network <sup>&#174;</sup></b></i></p>
                                </React.Fragment>
                            }
                        </div> */}
                        <div className="logo-movers">
                            {(this.state.logo && (this.state.logoHeight == this.state.logoWidth)) &&
                                <div className="media">
                                    <div className="media-left p-r-10">
                                        <a href="#"><img src={this.state.logo || "https://myrelo.us/relotechApi/public/uploads/profile_image/default_avatar.jpg"} width="" height="auto" /></a>
                                    </div>
                                    <div className="media-body text-left">
                                        <h4 className="m-0 p-0"><strong>{this.state.organization_name ? this.state.organization_name : "---"}</strong><br />
                                            <p className="m-0 f-s-12 text-muted"><i><b>Powered by Independent Movers Network <sup>&#174;</sup></b></i></p>
                                        </h4>
                                    </div>
                                </div>
                            }
                            {(!this.state.logo) &&
                                <div className="media">
                                    <div className="media-left p-r-10">
                                        <a href="#"><img src={this.state.logo || "https://myrelo.us/relotechApi/public/uploads/profile_image/default_avatar.jpg"} width="" height="auto" /></a>
                                    </div>
                                    <div className="media-body text-left">
                                        <h4 className="m-0 p-0"><strong>{this.state.organization_name ? this.state.organization_name : "---"}</strong><br />
                                            <p className="m-0 f-s-12 text-muted"><i><b>Powered by Independent Movers Network <sup>&#174;</sup></b></i></p>
                                        </h4>
                                    </div>
                                </div>
                            }
                            {(this.state.logo && (this.state.logoHeight != this.state.logoWidth)) &&
                                <React.Fragment>
                                    <a href="#"><img className="company-logo" src={this.state.logo || "https://myrelo.us/relotechApi/public/uploads/profile_image/default_avatar.jpg"} width="" height="auto" /></a>
                                    <p className="m-0 f-s-12 p-t-10 text-muted text-right"><i><b>Powered by Independent Movers Network <sup>&#174;</sup></b></i></p>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    <div className="sidebar-category sidebar-category-visible">
                        <div className="category-content no-padding">

                            <ul className="navigation navigation-main navigation-accordion p-b-0">
                                <li className={this.getNavLinkClass("/independent-mover/dashboard/home") || this.getNavLinkClass("/independent-mover/dashboard/my-subscription") || this.getNavLinkClass("/independent-mover/dashboard/subscription-payment") || this.getNavLinkClass("/independent-mover/dashboard/myprofile") || this.getNavLinkClass("/independent-mover/dashboard/edit-my-profile") || this.getNavLinkClass("/independent-mover/dashboard/company-profile") || this.getNavLinkClass("/independent-mover/dashboard/edit-company-profile") || this.getNavLinkClass("/independent-mover/dashboard/change-password")}>
                                    <Link to="/independent-mover/dashboard/home" ><i className="icon-home4"></i> <span>Dashboard</span></Link>
                                </li>
                                {this.state.editPermission ?
                                    <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/myschedule")}>
                                        <Link to="/independent-mover/dashboard/myschedule" ><i className="icon-calendar2"></i> <span>My Schedule</span></Link>
                                    </li>
                                    :
                                    <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/myschedule")}>
                                        <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe}><i className="icon-calendar2"></i> <span>My Schedule</span></Link>
                                    </li>
                                }
                            </ul>

                            {/* Main Menu List */}

                            {this.state.staff_user_id == "" &&
                                <ul className="navigation navigation-main navigation-accordion">
                                    {/* <li className={'sidebar-inner-li ' + (this.getNavLinkClass("/independent-mover/dashboard/home") || this.getNavLinkClass("/independent-mover/dashboard/my-subscription") || this.getNavLinkClass("/independent-mover/dashboard/subscription-payment") || this.getNavLinkClass("/independent-mover/dashboard/myprofile") || this.getNavLinkClass("/independent-mover/dashboard/edit-my-profile") || this.getNavLinkClass("/independent-mover/dashboard/company-profile") || this.getNavLinkClass("/independent-mover/dashboard/edit-company-profile") || this.getNavLinkClass("/independent-mover/dashboard/change-password"))}>
                                    <Link to="/independent-mover/dashboard/home" ><i className="icon-home4"></i> <span>Dashboard</span></Link>
                                </li> */}
                                    {/* <li className={'sidebar-inner-li ' + (this.getNavLinkClass("/independent-mover/dashboard/all-messages") || this.getNavLinkClass("/independent-mover/dashboard/messages") || this.getNavLinkClass("/independent-mover/dashboard/compose-message") || this.getNavLinkClass("/independent-mover/dashboard/inbox") || this.getNavLinkClass("/independent-mover/dashboard/messages/inbox-details", false) || this.getNavLinkClass("/independent-mover/dashboard/sent-message") || this.getNavLinkClass("/independent-mover/dashboard/messages/sent-message-details", false))}>
                                        <Link to="/independent-mover/dashboard/all-messages" ><i className="icon-inbox"></i> <span>Messages</span> <span className="label bg-warning-400">

                                            {this.state.msgComposeReply.length}
                                        </span></Link>
                                    </li> */}
                                    <li className={'sidebar-inner-li ' + (this.getNavLinkClass("/independent-mover/dashboard/find-an-offer") || this.getNavLinkClass("/independent-mover/dashboard/my-offer-details"))}>

                                        <Link to="/independent-mover/dashboard/find-an-offer"><img src="assets/images/icons/white/discount.svg" width="18" className="m-r-10" /> <span>Offers Received</span></Link>

                                    </li>


                                    <li className={this.getNavLinkClass("/independent-mover/dashboard/find-drivers")}>
                                        <a href="javascript:void(0)" className="has-ul"><i className="icon-search4"></i> <span>Find &amp; Book a Pro</span></a>
                                        <ul className="hidden-ul">
                                            <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/find-movers")}>
                                                <Link to="/independent-mover/dashboard/find-movers">Movers</Link>
                                            </li>
                                            <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/find-helpers")}><Link to="/independent-mover/dashboard/find-helpers">Helpers</Link></li>

                                            <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/find-thirdparty")}>
                                                <Link to="/independent-mover/dashboard/find-thirdparty">3rd Party</Link>
                                            </li>
                                            <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/find-claimrepair")}>
                                                <Link to="/independent-mover/dashboard/find-claimrepair">Claim Repair</Link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className={this.getNavLinkClass("/independent-mover/dashboard/unassign-offer")}>
                                        <a href="javascript:void(0)" className="has-ul"><i className="icon-briefcase3"></i> <span>ReloOrders</span></a>

                                        <ul className="hidden-ul">
                                            <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/assign-offer-job")}>

                                                {this.state.editPermission ?
                                                    <Link to="/independent-mover/dashboard/assign-offer-job">Create &amp; Assign ReloOrders</Link>
                                                    :
                                                    <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe}>Create &amp; Assign ReloOrders</Link>
                                                }

                                            </li>
                                            <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/manage-orders")}><Link to="/independent-mover/dashboard/manage-orders">Manage ReloOrders</Link></li>
                                            <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/closed-orders")}><Link to="/independent-mover/dashboard/closed-orders">Closed ReloOrders</Link></li>

                                        </ul>
                                    </li>
                                    <li className={'sidebar-inner-li ' + (this.getNavLinkClass("/independent-mover/dashboard/manage-claims") || this.getNavLinkClass("/independent-mover/dashboard/manage-claim-details", false))}>
                                        <Link to="/independent-mover/dashboard/manage-claims" ><i className="icon-cash3"></i> <span>Manage Claims</span></Link>
                                    </li>

                                    <li className={this.getNavLinkClass("/independent-mover/dashboard/add-other-staff") || this.getNavLinkClass("/independent-mover/dashboard/view-other-staff") || this.getNavLinkClass("/independent-mover/dashboard/edit-other-staff", false) || this.getNavLinkClass("/independent-mover/dashboard/adddriver") || this.getNavLinkClass("/independent-mover/dashboard/editdriver", false) || this.getNavLinkClass("/independent-mover/dashboard/viewdriver", false)}>
                                        <a href="javascript:void(0)" className="has-ul"><i className="icon-user-plus"></i> <span>Manage Team</span></a>
                                        <ul className="hidden-ul">
                                            <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/drivers")}>
                                                <Link to="/independent-mover/dashboard/drivers">Drivers</Link>
                                            </li>
                                            <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/packers")}>
                                                <Link to="/independent-mover/dashboard/packers">Packers</Link>
                                            </li>
                                            {this.state.custom_profile.map((profile, i) => {
                                                return (
                                                    profile.show_in_menu == 1 &&

                                                    <li key={profile.id} className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/team-member-details/" + profile.id)}>
                                                        <Link to={{ pathname: "/independent-mover/dashboard/team-member-details/" + profile.id, search: "?profile_title=" + profile.title }} >
                                                            {profile.title}
                                                        </Link>
                                                    </li>

                                                );
                                            }
                                            )}
                                            <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/team-member-details/" + "all")}>
                                                <Link to={{ pathname: "/independent-mover/dashboard/team-member-details/" + "all", search: "?profile_title=Staff" }}>Manage Staff</Link>
                                            </li>


                                        </ul>
                                    </li>
                                    <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/customer")}>
                                        <Link to="/independent-mover/dashboard/customer"><i className="icon-users"></i> <span>Manage Customer</span></Link>

                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" className="has-ul"><i className="icon-thumbs-up2"></i> <span>Customer Satisfaction Survey</span></a>
                                        <ul className="hidden-ul">
                                            <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/manage-question")}><Link to="/independent-mover/dashboard/manage-question">Manage Questions</Link></li>
                                            <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/customer-serveys")}><Link to="/independent-mover/dashboard/customer-serveys">Survey Reports</Link></li>
                                            <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/css-dashboard")}><Link to="/independent-mover/dashboard/css-dashboard">Dashboard</Link></li>
                                        </ul>
                                    </li>
                                    {/* <li className={'sidebar-inner-li ' + (this.getNavLinkClass("/independent-mover/dashboard/military-approved/profile-tab") || this.getNavLinkClass("/independent-mover/dashboard/military-approved/information-tab", false))}>
                                    {
                                        this.state.subscription_id == 1 &&
                                        <Link to="/independent-mover/dashboard/my-subscription" className="blink-zoom army-green-btn"><i className="icon-clipboard5"></i><span>Get Military Approved</span></Link>
                                    }
                                    {
                                        this.state.subscription_id > 1 &&
                                        <Link to="/independent-mover/dashboard/military-approved/profile-tab" className="blink-zoom army-green-btn"><i className="icon-clipboard5"></i><span>Get Military Approved</span></Link>
                                    }
                                </li> */}
                                    <li className={'sidebar-inner-li ' + (this.getNavLinkClass("/independent-mover/dashboard/inventory") || this.getNavLinkClass("/independent-mover/dashboard/inventory", false))}>
                                        <Link to="/independent-mover/dashboard/inventory" ><i className="icon-list3"></i> <span>Manage Items</span></Link>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" className="has-ul">
                                            <i className="icon-cog6" ></i>
                                            <span>Settings</span>
                                        </a>
                                        <ul className="hidden-ul">
                                            {
                                                !this.state.staff_user_id && this.state.staff_user_id == '' &&
                                                <React.Fragment>
                                                    <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/manage-profile")}>
                                                        <Link to="/independent-mover/dashboard/manage-profile">Manage Department</Link>
                                                    </li>
                                                    <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/add-training-category")}>
                                                        <Link to="/independent-mover/dashboard/add-training-category">Add Training Category</Link>
                                                    </li>
                                                    {/* <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/brand")}>
                                                        <Link to="/independent-mover/dashboard/brand">My Branded App</Link>
                                                    </li> */}
                                                </React.Fragment>
                                            }
                                        </ul>
                                    </li>


                                </ul>


                            }
                            {/* <-- end of main menu */}

                            {/* Menu based on custom profile --> */}

                            {
                                this.state.staff_user_id != "" && this.state.custom_menu.map((menu, i) => {

                                    return (

                                        <ul className="navigation navigation-main navigation-accordion">

                                            {menu.title == "Messages" &&
                                                <li key={menu.id} className={'sidebar-inner-li ' + (this.getNavLinkClass("/independent-mover/dashboard/all-messages") || this.getNavLinkClass("/independent-mover/dashboard/messages") || this.getNavLinkClass("/independent-mover/dashboard/compose-message") || this.getNavLinkClass("/independent-mover/dashboard/inbox") || this.getNavLinkClass("/independent-mover/dashboard/messages/inbox-details", false) || this.getNavLinkClass("/independent-mover/dashboard/sent-message") || this.getNavLinkClass("/independent-mover/dashboard/messages/sent-message-details", false))}>
                                                    <Link to="/independent-mover/dashboard/all-messages" ><i className="icon-inbox"></i> <span>Messages</span> <span className="label bg-warning-400">

                                                        {this.state.msgComposeReply.length}
                                                    </span></Link>
                                                </li>}

                                            {menu.title == "Offers Received" &&
                                                <li key={menu.id} className={'sidebar-inner-li ' + (this.getNavLinkClass("/independent-mover/dashboard/find-an-offer") || this.getNavLinkClass("/independent-mover/dashboard/my-offer-details"))}>
                                                    <Link to="/independent-mover/dashboard/find-an-offer"><img src="assets/images/icons/white/discount.svg" width="18" className="m-r-10" /> <span>Offers Received</span></Link>
                                                </li>}

                                            {
                                                menu.title == "ReloOrders" &&
                                                <li className={this.getNavLinkClass("/independent-mover/dashboard/unassign-offer")}>
                                                    <a href="javascript:void(0)" className="has-ul"><i className="icon-briefcase3"></i> <span>ReloOrders</span></a>

                                                    <ul className="hidden-ul">
                                                        {(menu.is_add == 1 || menu.is_add == "1") &&
                                                            <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/assign-offer-job")}>
                                                                {this.state.editPermission ?
                                                                    <Link to="/independent-mover/dashboard/assign-offer-job">Create &amp; Assign ReloOrders</Link>
                                                                    :
                                                                    <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe}>Create &amp; Assign ReloOrders</Link>
                                                                }
                                                            </li>
                                                        }
                                                        <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/manage-orders")}><Link to="/independent-mover/dashboard/manage-orders">Manage ReloOrders</Link></li>
                                                        <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/closed-orders")}><Link to="/independent-mover/dashboard/closed-orders">Closed ReloOrders</Link></li>

                                                    </ul>
                                                </li>
                                            }

                                            {
                                                menu.title == "Manage Claims" &&
                                                <li key={menu.id} className={'sidebar-inner-li ' + (this.getNavLinkClass("/independent-mover/dashboard/manage-claims") || this.getNavLinkClass("/independent-mover/dashboard/manage-claim-details", false))}>
                                                    <Link to="/independent-mover/dashboard/manage-claims" ><i className="icon-cash3"></i> <span>Manage Claims</span></Link>
                                                </li>
                                            }

                                            {
                                                menu.title == "Find & Book a Pro" &&
                                                <li key={menu.id} className={this.getNavLinkClass("/independent-mover/dashboard/find-drivers")}>
                                                    <a href="javascript:void(0)" className="has-ul"><i className="icon-search4"></i> <span> Find &amp; Book a Pro</span></a>
                                                    <ul className="hidden-ul">
                                                        <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/find-movers")}>
                                                            <Link to="/independent-mover/dashboard/find-movers">Movers</Link>
                                                        </li>
                                                        <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/find-helpers")}><Link to="/independent-mover/dashboard/find-helpers">Helpers</Link></li>

                                                        <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/find-thirdparty")}>
                                                            <Link to="/independent-mover/dashboard/find-thirdparty">3rd Party</Link>
                                                        </li>
                                                        <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/find-claimrepair")}>
                                                            <Link to="/independent-mover/dashboard/find-claimrepair">Claim Repair</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            }

                                            {
                                                menu.title == "Manage Team" &&
                                                <li key={menu.id} className={this.getNavLinkClass("/independent-mover/dashboard/add-other-staff") || this.getNavLinkClass("/independent-mover/dashboard/view-other-staff") || this.getNavLinkClass("/independent-mover/dashboard/edit-other-staff", false) || this.getNavLinkClass("/independent-mover/dashboard/adddriver") || this.getNavLinkClass("/independent-mover/dashboard/editdriver", false) || this.getNavLinkClass("/independent-mover/dashboard/viewdriver", false)}>
                                                    <a href="javascript:void(0)" className="has-ul"><i className="icon-user-plus"></i> <span>Manage Team</span></a>
                                                    <ul className="hidden-ul">
                                                        <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/drivers")}>
                                                            <Link to="/independent-mover/dashboard/drivers">Drivers</Link>
                                                        </li>
                                                        <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/packers")}>
                                                            <Link to="/independent-mover/dashboard/packers">Packers</Link>
                                                        </li>
                                                        {this.state.custom_profile.map((profile, i) => {
                                                            return (
                                                                profile.show_in_menu == 1 &&

                                                                <li key={profile.id} className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/team-member-details/" + profile.id)}>
                                                                    <Link to={{ pathname: "/independent-mover/dashboard/team-member-details/" + profile.id }}>
                                                                        {profile.title}
                                                                    </Link>
                                                                </li>

                                                            );
                                                        }
                                                        )}
                                                        <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/team-member-details/" + "all")}>
                                                            <Link to={{ pathname: "/independent-mover/dashboard/team-member-details/" + "all" }}>Manage Staff</Link>
                                                        </li>
                                                        {/* <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/manage-profile")}>
                                                            <Link to="/independent-mover/dashboard/manage-profile">Manage Department</Link>
                                                        </li> */}
                                                    </ul>
                                                </li>
                                            }

                                            {
                                                menu.title == "Manage Customer" &&
                                                <li key={menu.id} className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/customer")}>
                                                    <Link to="/independent-mover/dashboard/customer"><i className="icon-users"></i> <span>Manage Customer</span></Link>
                                                </li>
                                            }

                                            {
                                                menu.title == "Customer Satisfaction Survey" &&
                                                <li key={menu.id}>
                                                    <a href="javascript:void(0)" className="has-ul"><i className="icon-thumbs-up2"></i> <span>Customer Satisfaction Survey</span></a>
                                                    <ul className="hidden-ul">
                                                        <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/manage-question")}><Link to="/independent-mover/dashboard/manage-question">Manage Questions</Link></li>
                                                        <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/customer-serveys")}><Link to="/independent-mover/dashboard/customer-serveys">Survey Reports</Link></li>
                                                        <li className={'sidebar-inner-li ' + this.getNavLinkClass("/independent-mover/dashboard/css-dashboard")}><Link to="/independent-mover/dashboard/css-dashboard">Dashboard</Link></li>
                                                    </ul>
                                                </li>
                                            }

                                            {
                                                menu.title == "Inventory" &&
                                                <li key={menu.id} className={'sidebar-inner-li ' + (this.getNavLinkClass("/independent-mover/dashboard/inventory") || this.getNavLinkClass("/independent-mover/dashboard/inventory", false))}>
                                                    <Link to="/independent-mover/dashboard/inventory" ><i className="icon-list3"></i> <span>Manage Items</span></Link>
                                                </li>

                                            }



                                        </ul>
                                    );

                                })
                            }

                            {/* <-- End of Menu based on custom profile */}
                            {/* <ul className="navigation navigation-main navigation-accordion">
                                <li className={'sidebar-inner-li ' + (this.getNavLinkClass("/independent-mover/dashboard/military-approved/profile-tab") || this.getNavLinkClass("/independent-mover/dashboard/military-approved/information-tab", false))}>
                                    {
                                        (JSON.parse(Storage.get('imnLoginData')).gma_subscription_id == 0 && this.state.lists.length > 0) &&
                                        <Link to={{
                                            pathname: "/independent-mover/dashboard/checkout",
                                            state: {
                                                gma_subscription_id: JSON.parse(Storage.get('imnLoginData')).gma_subscription_id,
                                                title: JSON.parse(Storage.get('imnLoginData')).gma_package_name,
                                                value: JSON.parse(Storage.get('imnLoginData')).gma_package_price,
                                                type: 'gmaSubscription'
                                            }
                                        }} onClick={this.doGMASubscribe} className="blink-zoom army-green-btn"><i className="icon-clipboard5"></i><span>Get Military Approved</span></Link>

                                    }
                                    {
                                        JSON.parse(Storage.get('imnLoginData')).gma_subscription_id > 0 &&
                                        <Link to="/independent-mover/dashboard/military-approved/information-tab" className="blink-zoom army-green-btn"><i className="icon-clipboard5"></i><span>Get Military Approved</span></Link>
                                    }
                                </li>
                            </ul> */}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = function (state, props) {
    return {

        InboxCount: state.InboxCount
    }
}

const mapActionsToProp = {

    getInboxCount: getInboxCount
}

export default connect(mapStateToProps, mapActionsToProp)(Sidebar);
