import Storage from '../externaljs/Storage';
import PostDataWithParamOptional from './PostDataWithParamOptional';
import Urls from '../externaljs/Urls';
import { authMiddleware } from '../externaljs/authHelpers';

export default function PostDataWithParam(url, params) {
    //Check if user is active or not...
    // PostDataWithParamOptional('UserStatus', { user_id: JSON.parse(Storage.get('imnLoginData')).id, profile_id: 2 })
    // .then((response) => {
    //     if (response.success === 1 && response.data == 0) {
    //         Storage.remove('imnLoginToken');
    //         Storage.remove('imnLoginData');
    //         Storage.remove('imnFirstLogin');
    //         window.location.href = Urls.login
    //     } 
    // })
    // .catch(err => {

    // });
    if (Storage.get('imnLoginToken') !== "") {
        PostDataWithParamOptional('GetUserDetailsForSubscription')
            .then((response) => {
                if (response.success === 1 && response.data[0].status == 0) {
                    Storage.remove('imnLoginToken');
                    Storage.remove('imnLoginData');
                    Storage.remove('imnFirstLogin');
                    window.location.href = Urls.login
                }
                else {
                    let loginData = JSON.parse(Storage.get('imnLoginData'));
                    // console.log(loginData)
                    // console.log('subscription_id dash',response.data[0].subscription_id);

                    Storage.set('imnLoginData', JSON.stringify({
                        ...loginData,
                        subscription_id: response.data[0].subscription_id,
                        // subscription_id: 13,
                        trial_subscription_id: response.data[0].trial_subscription_id,
                        subscription_expiry_date: response.data[0].subscription_expiry_date,
                        gma_subscription_id: response.data[0].gma_subscription_id,
                        gma_subscription_issue_date: response.data[0].gma_subscription_issue_date,
                        gma_subscription_expiry_date: response.data[0].gma_subscription_expiry_date,
                    }))
                }
            })
            .catch(err => {

            });
    }
    //end... 
    // const API = "https://myrelo.us/relotechApi/public/Api/";
    // const API = "http://localhost/Relotech/relotechApi/public/Api/";

    let headers = new Headers();
    headers.append('Content-type', 'application/json');
    headers.append('token', Storage.get('imnLoginToken'));


    return fetch(Urls.api + url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(params)
    })
        .then(authMiddleware)
}