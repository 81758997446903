import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import Breadcumb from '../externaljs/Breadcrumb';
import { Editor } from '@tinymce/tinymce-react';
import PostDataWithParam from '../services/PostDataWithParam';
import DateFormat from '../externaljs/DateFormat';
import PostDataFile from '../services/PostDataFile';
import ConfirmBox from '../externaljs/ConfirmBox';
import Storage from '../externaljs/Storage';
import { connect } from 'react-redux';
import { getInboxCount } from '../redux/action';

class InboxDetails extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Messages', link: '/independent-mover/dashboard/inbox', class: '' },
            { title: 'Inbox', link: '/independent-mover/dashboard/inbox', class: '' },
            { title: 'Message Details', link: '', class: 'active' }
        ],
        activeEditor: '',
        messages: [],
        subject: '',
        message_id: '',
        fields: {
            message: '',
            attachment: ''
        },
        error: {
            message_err: ''
        },
        MessaageErr: '',
        MessageStatus: false,
        DeleteStatus: false,
        custom_profile: [],
        staff_user_id: '',
        custom_menu: [],
        edit_msg: true,
        editPermission: true,
        sending_message: false,
    }

    subscriptionCheckInterval = null;

    componentDidMount() {
        this.getMsgDetails();

        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        let custom_menu = [];
        if (staff_user_id != "") {
            custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
            this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
                // console.log(this.state.custom_menu);
                // console.log(this.state.staff_user_id);
                let edit_msg = this.state.custom_menu.find(menu => menu.title == 'Messages')
                if (typeof (edit_msg) != 'undefined') {
                    if (edit_msg.is_add == 1 || edit_msg.is_add == '1') this.setState({ edit_msg: true })
                    else this.setState({ edit_msg: false })
                }

            });
        }

        this.subscriptionCheckInterval = setInterval(() => {
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1)
                this.setState({ editPermission: false })
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ editPermission: true })
        }, 1000);

    }

    componentWillUnmount() {
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
    }

    doSubscribe = (e) => {
        window.swal({
            title: "Warning!",
            text: "Please upgrade your membership to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }

    getMsgDetails = () => {
        PostDataWithParam('MessageDetails', { message_id: this.props.match.params.message_id, msg_flag: 1 })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ messages: response.data, subject: response.data[0].subject }, () => {
                        this.props.getInboxCount();
                    })
                }
            })
    }

    spanColor = (first_letter, sender_user_id) => {
        let color = '';
        if (sender_user_id === (JSON.parse(Storage.get('imnLoginData'))).id)
            color = 'primary'
        else
            color = 'success'
        return (
            <span className={"btn bg-" + color + "-400 btn-rounded btn-icon btn-xs"}>
                <span className="letter-icon">{first_letter.toUpperCase()}</span>
            </span>
        )
    }

    stripTags = (html) => {
        const regex = /(<([^>]+)>)/ig;
        const result = html.replace(regex, '');
        return result
    }

    handleAttachment = (e) => {
        let fields = this.state.fields;
        fields.attachment = e.target.files[0];
        this.setState({ fields: fields });
    }

    handleEditorChange = (e) => {
        let fields = this.state.fields;
        fields.message = e.target.getContent();
        this.setState({ fields: fields });
    }

    handleMessage = () => {
        this.resetError(() => {
            let error = this.state.error;
            if (!this.state.fields.message) {
                error.message_err = 'Message is required.';
            } else {
                let fd = new FormData();
                if (this.state.fields.attachment.name !== undefined) {
                    fd.append('attachment', this.state.fields.attachment, this.state.fields.attachment.name);
                }
                fd.append('message', this.state.fields.message);
                fd.append('msg_id', this.props.match.params.message_id);
                fd.append('msg_flag', 1);
                this.setState({ sending_message: true }, () => {
                    PostDataFile('ReplyMessage', fd)
                        .then((response) => {
                            if (response.success === 1) {
                                this.setState({ MessaageErr: response.msg, sending_message: false }, () => {
                                    window.swal('Success!', response.msg, 'success');
                                    this.state.activeEditor.setContent('');
                                    this.getMsgDetails()
                                    this.setState({ MessageStatus: true });
                                });
                            } else {
                                this.setState({ MessaageErr: response.msg, sending_message: false });
                            }
                        })
                        .catch(err => {
                            this.setState({ MessaageErr: 'Oops! Something went wrong.', sending_message: false });
                        })
                })
            }
            this.setState({ error: error });
        });
    }

    resetError = (cb) => {
        let error = {
            message_err: ''
        }
        this.setState({ error: error }, () => {
            cb();
        });
    }

    DeleteMessage = (msg_id) => {
        ConfirmBox("You want to delete this Conversation!")
            .then((result) => {
                if (result) {
                    PostDataWithParam('DeleteMessage', { message_id: msg_id, delete_status: 1 })
                        .then((response) => {
                            let result = response;
                            if (result.success === 1) {
                                window.swal('Success!', result.msg, 'success');
                                this.setState({ DeleteStatus: true });
                            } else {
                                window.swal('Failed!', result.msg, 'error');
                            }
                        })
                }
            });
    }

    render() {

        if (this.state.DeleteStatus) {
            return <Redirect to={{
                pathname: '/independent-mover/dashboard/inbox'
            }} />
        }

        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="panel panel-flat profile-page">
                        <div className="panel-heading">
                            <div className="media">
                                <span className="media-left p-r-10">
                                    <Link to="/independent-mover/dashboard/inbox" className="text-muted left-arrow-back show m-t-5"><i className="icon-arrow-left8"></i></Link>
                                </span>
                                <div className="media-body">
                                    <ul className="icons-list pull-right">
                                        <li className="dropdown">
                                            <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                                <i className="icon-menu9"></i>
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-right">
                                                <li><a href="javascript:void(0)" onClick={() => this.DeleteMessage(this.props.match.params.message_id)}><i className="icon icon-trash"></i> Delete</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <h5 className="panel-title m-0 pull-left">{this.state.subject}</h5>
                                </div>
                            </div>
                        </div>
                        <div>

                            <hr className="m-t-0" />
                            <div className="panel-body p-t-0 editor-box" id="editor-box-in">
                                {/* {this.state.edit_msg &&  */}
                                <form action="#">
                                    <div className="form-group">
                                        <Editor
                                            placeHolder="Write Something ..."
                                            initialValue=""
                                            init={{
                                                setup: editor => {
                                                    this.setState({ activeEditor: editor })
                                                },
                                                menubar: false,
                                                statusbar: false,
                                                toolbar: "undo redo | styleselect | bold italic | bullist numlist outdent indent"
                                            }}
                                            onChange={this.handleEditorChange}
                                            className="form-control"
                                            name="qutationMessage"
                                            id='qutationMessage'
                                        />
                                        <p className="text-danger help-block">{this.state.error.message_err}</p>
                                        <label className="file-label">
                                            <input className="file-input" name="attachment" id="attachment" type="file" onChange={this.handleAttachment} />
                                            <span className="text-muted">
                                                <i className="icon icon-attachment"></i> Attach File
                                            </span>
                                        </label>
                                        <p>
                                        {this.state.fields.attachment.name != ""  && this.state.fields.attachment.name}
                                        </p>
                                    </div>
                                    <div className="text-right">
                                        {/* <button type="button" className="btn btn-default close-editor m-r-10">Cancel</button> */}
                                        {/* {this.state.editPermission ?
                                            <button type="button" className="btn btn-primary close-editor" onClick={this.handleMessage}>Submit</button>
                                            :
                                            <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} className="btn btn-primary close-editor">Submit</Link>
                                        } */}
                                        {this.state.editPermission ?
                                            <React.Fragment>
                                                {this.state.sending_message ?
                                                    <div className="text-right">
                                                        <button type="button" className="btn btn-primary close-editor" disabled={true}>Processing</button>
                                                    </div>
                                                    :
                                                    <div className="text-right">
                                                        <button type="button" className="btn btn-default close-editor m-r-10">Cancel</button>
                                                        <button type="button" className="btn btn-primary close-editor" onClick={this.handleMessage}>Submit</button>
                                                    </div>
                                                }
                                            </React.Fragment>
                                            :
                                            <div className="text-right">
                                                <button type="button" className="btn btn-default close-editor m-r-10">Cancel</button>
                                                <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} className="btn btn-primary close-editor">Submit</Link>
                                            </div>
                                        }
                                    </div>
                                </form>
                                {/* } */}
                            </div>

                            <hr className="m-t-0" />
                            <div className="panel-body">
                                {
                                    this.state.messages.map((msg, index) => {
                                        return (
                                            <div key={index}>
                                                <div className="message-body clearfix">
                                                    <div className="content-group-sm">
                                                        <div className="media">
                                                            {
                                                                msg.profile_image == ""
                                                                &&
                                                                <div className="media-left">
                                                                    {this.spanColor(msg.full_name[0], msg.sender_user_id)}
                                                                </div>
                                                            }
                                                            {
                                                                msg.profile_image != ""
                                                                &&
                                                                <div className="media-left">
                                                                    <img src={msg.profile_image} alt="" className="img-circle" />
                                                                </div>
                                                            }
                                                            <div className="media-body">
                                                                <span className="media-heading text-semibold">{msg.full_name}</span>
                                                                <div className="text-size-mini text-muted">
                                                                    {DateFormat.getFormattedDateTime(msg.msg_time, 'M d, Y,h:i')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p dangerouslySetInnerHTML={{ __html: msg.message }}></p>
                                                    {msg.signature != ''
                                                        &&
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="pull-left p-t-10" style={{ width: 300 }}>
                                                                    <p dangerouslySetInnerHTML={{ __html: msg.signature }}></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        msg.attachment !== null
                                                        &&
                                                        <div className="tabbable attachedment-files">
                                                            <div className="file-manager">
                                                                <ul>
                                                                    <li>
                                                                        <div className="file-wrapper filetype-image">
                                                                            <div className="file-title"><a href={msg.attachment_path}><i className="icon-image5 position-left text-primary"></i> {msg.attachment}</a></div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    this.state.messages.length !== index + 1
                                                    &&
                                                    <hr />
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// export default InboxDetails;

const mapStateToProps = function (state, props) {
    return {

        InboxCount: state.InboxCount
    }
}

const mapActionsToProp = {

    getInboxCount: getInboxCount
}

export default connect(mapStateToProps, mapActionsToProp)(InboxDetails);