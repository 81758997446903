import React, { Component } from 'react'
import { Link } from "react-router-dom";
import PostDataWithParam from '../services/PostDataWithParam';
import DateFormat from '../externaljs/DateFormat';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import Storage from '../externaljs/Storage';
import PostDataFile from '../services/PostDataFile';

class ManageClaims extends Component {
    state = {
        claims: [],
        viewOrderClaims: [],
        viewClaimType: 1,
        loading: true,
        military_order: true,
        non_military_order: false,
        selCustomFilter: '',
        order_type: '',
        military_flag: '1',
        staff_user_id: '',
        custom_menu: [],
        editPermission: true,
        manage_claims: true
    }

    subscriptionCheckInterval = null;
    staffMenuCheckInterval = null;

    componentDidMount() {
        this.claimList();

        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        let custom_menu = [];
        if (staff_user_id != "") {
            custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
        }
        this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
            // console.log(this.state.custom_menu);
            // console.log(this.state.staff_user_id);
        });

        this.staffMenuCheckInterval = setInterval(() => {
            if (Storage.get('mmcStaffMenu')) {
                let custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
                this.setState({ custom_menu }, () => {
                    let claims = this.state.custom_menu.find(menu => menu.title == "Manage Claims")
                    if (claims) {
                        if (claims.is_add == 1 || claims.is_add == '1') this.setState({ manage_claims: true })
                        else this.setState({ manage_claims: false })
                    }
                    else this.setState({ manage_claims: false })
                });
            }
        }, 1000)

        this.subscriptionCheckInterval = setInterval(() => {
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1)
                this.setState({ editPermission: false })
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ editPermission: true })
        }, 1000);
    }

    componentWillUnmount() {
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
        if (this.staffMenuCheckInterval) {
            clearInterval(this.staffMenuCheckInterval)
        }
    }

    doSubscribe = (e) => {
        window.swal({
            title: "Warning!",
            text: "Please upgrade your membership to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }

    claimList = (sortBy = '') => {
        this.removeDataTable();
        this.setState({ loading: true }, () => {
            let postData = { type: sortBy, military_flag: this.state.military_flag };
            let formData = new FormData();
            formData.append('type', '');
            formData.append('military_flag', this.state.military_flag);
            if (sortBy) {
                postData = { 'type': sortBy, military_flag: this.state.military_flag };
                formData.append('type', sortBy);
                formData.append('military_flag', this.state.military_flag);
            }
            const last_military_flag = this.state.military_flag;
            PostDataFile('GetClaimListForMovers', formData)
                // PostDataWithParam('GetClaimListForMovers', postData)
                .then((response) => {
                    if (response.success === 1) {
                        if (this.state.military_flag === last_military_flag) {
                            this.setState({ claims: response.data, loading: false, selCustomFilter: postData.type }, () => {
                                try {

                                    DataTableBasic();
                                    var searchFilter = window.$('.dataTables_filter input[type=search]');
                                    var that = this;
                                    if (searchFilter.length) {
                                        var newOptions = [
                                            { 'key': '', value: 'All' },
                                            { 'key': '1', value: 'Damage Claim' },
                                            { 'key': '2', value: 'Reimbursements Claim' },
                                        ];
                                        var sel = '<select class= "customFilterBy">';
                                        window.$(newOptions).each(function (key, txt) {
                                            if (that.state.selCustomFilter == txt.key) {
                                                sel += '<option value="' + txt.key + '" selected>' + txt.value + '</option>';
                                            } else {
                                                sel += '<option value="' + txt.key + '">' + txt.value + '</option>';
                                            }

                                        });
                                        sel += '</select>';
                                        searchFilter.parent().parent().append(sel);
                                        window.$(".customFilterBy").select2({
                                            minimumResultsForSearch: Infinity,
                                            width: 'auto'
                                        }).on('select2:select', function (e) {
                                            that.removeDataTable();
                                            that.claimList(e.params.data.id);
                                            console.log("@", that.state.selCustomFilter)
                                        });
                                    }

                                }

                                catch (exception) {
                                    console.log(exception)
                                }

                            });
                        }
                    }
                    else {
                        this.setState({ claims: [], loading: false, selCustomFilter: sortBy });
                    }
                })
                .catch(err => {
                    // window.swal('Error!', 'Something went wrong', 'error')
                    this.setState({ claims: [], loading: false, selCustomFilter: sortBy });
                })
        })
    }

    changeStatus = (claim_id, status) => {
        this.closeClaimListModal();
        PostDataWithParam('ChangeClaimStatus', { claim_id: claim_id, status: status })
            .then((response) => {
                if (response.success === 1) {
                    window.swal('Success!', response.msg, 'success')
                    this.removeDataTable();
                    this.claimList();
                } else {
                    window.swal('Error!', response.msg, 'error')
                    this.removeDataTable();
                    this.claimList();
                }
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error')
                this.removeDataTable();
                this.claimList();
            })
    }

    // Creat Tab View
    handleClickActiveTab = (stat) => {
        if (stat == 'militaryOrder') {
            this.setState({ military_order: true, non_military_order: false, military_flag: 1 });
        } else {
            this.setState({ military_order: false, non_military_order: true, military_flag: 0 })
        }

        this.claimList();
    }

    removeDataTable = () => {
        if (window.$.fn.DataTable.isDataTable("#list-table")) {
            window.$("#list-table").DataTable().destroy();
        }

        window.$("#list-table tbody").empty();
    }
    initChatBox(relo_id, user_id, name, order_id, profile_image, e) {

        e.preventDefault();
        console.log('sdasdsa', user_id, name, order_id, profile_image);
        this.props.openChatBox({
            chat_box_id: `${order_id}_${user_id}`,
            chat_info: {
                order_id,
                order_no: relo_id,
                receiver_info: {
                    user_id,
                    name,
                    profile_image
                }
            }
        })
    }
    getChatCountByOrder = (order_id, user_id) => {
        const findOrder = this.props.unreadMsgUsers.find(unreadUser => unreadUser.user_id === user_id && unreadUser.order_id === order_id);
        if (findOrder) {
            return findOrder.msg_count
        }
        return null;
    }

    viewClaimsByOrder = (e, data, type) => {
        e.preventDefault();
        this.setState({ viewOrderClaims: data, viewClaimType: type }, () => {
            window.$("#claimListModal").modal("show")
        })
    }
    closeClaimListModal = () => {
        this.setState({ viewOrderClaims: [], viewClaimType: 1 });
        window.$("#claimListModal").modal("hide")
    }

    render() {
        return (
            <>
                <div>
                    <div className="breadcrumb-line breadcrumb-line-component m-b-20">
                        <ul className="breadcrumb">
                            <li><Link to="/independent-mover/dashboard/home"><i className="icon-meter-slow position-left"></i> Dashboard</Link></li>
                            <li className="active">Manage Claims</li>
                        </ul>
                    </div>
                    <div className="clearfix">
                        <h4 className="pull-left">Manage Claims</h4>
                    </div>
                    <div className="panel panel-flat">
                        <div className="panel-header border-radius-3px">
                            <ul className="nav nav-tabs nav-tabs-bottom nav-horizantal-scroll m-b-0">

                                <li className={this.state.military_order ? 'active' : ''}>
                                    <Link to="#" style={{ 'pointer-events': this.state.military_order ? 'none' : '' }} data-toggle="tab" aria-expanded="true" name="military_order" onClick={() => this.handleClickActiveTab('militaryOrder')}><h6>Military Claim</h6></Link>
                                </li>
                                <li className={this.state.non_military_order ? 'active' : ''}>
                                    <Link to="#" style={{ 'pointer-events': this.state.non_military_order ? 'none' : '' }} data-toggle="tab" aria-expanded="true" name="non_military_order" onClick={() => this.handleClickActiveTab('nonMilitaryOrder')}><h6>Non-Military Claim</h6></Link>
                                </li>
                            </ul>
                        </div>
                        {
                            this.state.loading
                            &&
                            <div className="well text-center m-15"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                        }
                        <div>


                            {/* For military_list */}
                            <div>
                                {
                                    !this.state.loading
                                    &&
                                    // this.state.claims.length != 0
                                    (<table className="table datatable-basic table-striped" id="list-table">
                                        <thead>
                                            <tr>
                                                <th>Relo #</th>
                                                <th>Customer Name</th>
                                                <th>Claim Type</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.claims.map((claim, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                #{claim.order_id}
                                                            </td>
                                                            <td className="text-nowrap">{claim.customer_first_name + ' ' + claim.customer_last_name}</td>
                                                            <td>{claim.type == 1 ? 'Damage Claim' : 'Reimbursements Claim'}</td>
                                                            {

                                                                <td>
                                                                    {this.state.staff_user_id == "" && <div className="pull-right">
                                                                        {/* <a href="javascript:void(0)" className="btn btn-xs btn-primary m-r-15"><i className="icon-envelop2"></i> Message</a> */}
                                                                        {this.state.editPermission ?
                                                                            (<>
                                                                                <a href="#" className="btn btn-xs btn-primary mr-2"
                                                                                    onClick={(e) => this.viewClaimsByOrder(e, claim.claim_list, claim.type)}>
                                                                                    <i className="icon-eye2"></i>
                                                                                    view associated claims
                                                                                </a>
                                                                                <span className="p-5"></span>
                                                                                <a href="javascript:void(0)" className="btn btn-xs btn-success" data-chatusername={claim.customer_first_name + ' ' + claim.customer_last_name} data-uid={claim.id + '_' + claim.claim_by_user_id} onClick={this.initChatBox.bind(this, claim.order_id, claim.claim_by_user_id, claim.customer_first_name + ' ' + claim.customer_last_name, claim.id, '')}>
                                                                                    <i className="icon-bubbles3"></i> Chat
                                                                                    <span class="badge">{this.getChatCountByOrder(claim.id, claim.claim_by_user_id)}</span>
                                                                                </a>
                                                                            </>
                                                                            )
                                                                            :
                                                                            <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-xs btn-success" onClick={this.doSubscribe}>
                                                                                <i className="icon-bubbles3"></i>
                                                                                <span class="badge">{this.getChatCountByOrder(claim.id, claim.claim_by_user_id)}</span>
                                                                            </Link>
                                                                        }
                                                                    </div>}

                                                                    {(this.state.staff_user_id != "") && this.state.custom_menu.map((menu, i) => {
                                                                        return (
                                                                            <div>
                                                                                {
                                                                                    (menu.title == "Manage Claims" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                                                    <div className="pull-right">
                                                                                        {/* <a href="javascript:void(0)" className="btn btn-xs btn-primary m-r-15"><i className="icon-envelop2"></i> Message</a> */}
                                                                                        {this.state.editPermission ?
                                                                                            <>
                                                                                                <a href="#" className="btn btn-xs btn-primary mr-2"
                                                                                                    onClick={(e) => this.viewClaimsByOrder(e, claim.claim_list, claim.type)}>
                                                                                                    <i className="icon-eye2"></i>
                                                                                                    view associated claims
                                                                                                </a>
                                                                                                <span className="p-5"></span>
                                                                                                <a href="javascript:void(0)" className="btn btn-xs btn-success" data-chatusername={claim.customer_first_name + ' ' + claim.customer_last_name} data-uid={claim.id + '_' + claim.claim_by_user_id} onClick={this.initChatBox.bind(this, claim.order_id, claim.claim_by_user_id, claim.customer_first_name + ' ' + claim.customer_last_name, claim.id, '')}>
                                                                                                    <i className="icon-bubbles3"></i> Chat
                                                                                                    <span class="badge">{this.getChatCountByOrder(claim.id, claim.claim_by_user_id)}</span>
                                                                                                </a>
                                                                                            </>
                                                                                            :
                                                                                            <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-xs btn-success" onClick={this.doSubscribe}>
                                                                                                <i className="icon-bubbles3"></i>
                                                                                                <span class="badge">{this.getChatCountByOrder(claim.id, claim.claim_by_user_id)}</span>
                                                                                            </Link>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        );
                                                                    })
                                                                    }

                                                                </td>
                                                            }
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>

                                    </table>
                                    )
                                }
                            </div>

                            {/* For non_military_list */}
                            {/* <div style={{ display: (this.state.non_military_order ? 'block' : 'none') }}></div> */}

                            {
                                !this.state.loading
                                &&
                                this.state.claims.length == 0
                                &&
                                <div className="alert alert-primary border-none text-center m-l-20 m-r-20 m-t-20">No Claims Found.</div>
                            }
                        </div>


                    </div>


                </div>
                {/* claimListModal-Modal start */}
                <div className="modal fade" id="claimListModal" role="dialog">

                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title" style={{ color: "black" }}>Claims</h4>
                            </div>
                            <hr className="m-0" />
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="text-nowrap">{this.state.viewClaimType == 1 ? 'Claim' : 'Reimbursement '} #</th>
                                                    <th>Relo #</th>
                                                    <th>Customer Name</th>
                                                    <th>Claim Type</th>
                                                    <th>Date Claim Filed</th>
                                                    <th>Current Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.viewOrderClaims.map((claim, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {this.state.editPermission ?
                                                                        <Link to={"/independent-mover/dashboard/manage-claim-details/" + claim.id} onClick={this.closeClaimListModal}>#{claim.claim_id}</Link>
                                                                        :
                                                                        <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} onClick={this.closeClaimListModal}>#{claim.claim_id}</Link>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    #{claim.order_id}
                                                                </td>
                                                                <td className="text-nowrap">{claim.customer_first_name + ' ' + claim.customer_last_name}</td>
                                                                <td>{claim.type == 1 ? 'Damage Claim' : 'Reimbursements Claim'}</td>
                                                                {/* <td>{DateFormat.getFormattedDateTime(claim.request_start_date, 'm-d-Y')}</td> */}
                                                                <td>{DateFormat.getFormattedDateTime(claim.claim_date, 'm-d-Y')}</td>
                                                                {
                                                                    claim.final_claim_status == 0
                                                                    &&
                                                                    <td>
                                                                        {<div className="m-b-10 dropdown pull-left">
                                                                            <span className="text-primary m-r-5"><div className="reimbursement-status"><span className="active"></span><span></span><span></span></div> Open</span>
                                                                            {/* <a href="" data-toggle="dropdown" className="dropdown-toggle"><u>Change Status1</u></a> */}
                                                                            {this.state.editPermission ?
                                                                                <React.Fragment>
                                                                                    {this.state.manage_claims &&
                                                                                        <a href="" data-toggle="dropdown" className="dropdown-toggle"><u>Change Status</u></a>
                                                                                    }
                                                                                </React.Fragment>
                                                                                :
                                                                                <React.Fragment>
                                                                                    <Link to="/independent-mover/dashboard/my-subscription" className="dropdown-toggle" onClick={this.doSubscribe} >Change Status</Link>
                                                                                </React.Fragment>
                                                                            }
                                                                            <ul className="dropdown-menu">
                                                                                <li><a href="javascript:void(0)" onClick={() => this.changeStatus(claim.claim_id, 1)}><i className="icon-sync"></i>In-Progress</a></li>
                                                                                <li><a href="javascript:void(0)" onClick={() => this.changeStatus(claim.claim_id, 2)}><i className="icon-checkmark2"></i> Resolved</a></li>
                                                                            </ul>
                                                                        </div>}
                                                                    </td>
                                                                }
                                                                {
                                                                    claim.final_claim_status == 1
                                                                    &&
                                                                    <td>
                                                                        <div className="m-b-10 dropdown pull-left">
                                                                            <span className="text-danger m-r-5"><div className="reimbursement-status"><span></span><span className="active"></span><span></span></div> IN Progress</span>
                                                                            {this.state.editPermission ?
                                                                                <React.Fragment>
                                                                                    {this.state.manage_claims &&
                                                                                        <a href="" data-toggle="dropdown" className="dropdown-toggle"><u>Change Status</u></a>
                                                                                    }
                                                                                </React.Fragment>
                                                                                :
                                                                                <React.Fragment>
                                                                                    <Link to="/independent-mover/dashboard/my-subscription" className="dropdown-toggle" onClick={this.doSubscribe} >Change Status</Link>
                                                                                </React.Fragment>
                                                                            }
                                                                            <ul className="dropdown-menu">
                                                                                <li><a href="javascript:void(0)" onClick={() => this.changeStatus(claim.claim_id, 2)}><i className="icon-checkmark2"></i> Resolved</a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </td>
                                                                }
                                                                {
                                                                    claim.final_claim_status == 2
                                                                    &&
                                                                    <td>
                                                                        <div className="m-b-10 pull-left">
                                                                            <span className="text-success"><div className="reimbursement-status"><span></span><span></span><span className="active"></span></div> Resolved</span>
                                                                        </div>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>

                                        </table>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                {/* claimListModal-Modal end */}
            </>
        );
    }
}

export default ManageClaims;