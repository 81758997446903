import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import PostDataWithParam from '../services/PostDataWithParam';
import PostDataFile from '../services/PostDataFile';
import MilitaryConversations from './MilitaryConversations';
import { Prompt } from 'react-router';

class Evaluation extends Component {
    state = {
        fields: {
            above_flood_plain:-1,
            map_file:'',
            map_upload_file:''                     
        },
        id:'',
        tab_id:11,
        saveStatus:false,
        responseData:0,
        proccesing:false,
        formDirty:false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        PostDataWithParam('GetMilitaryEvaluationFloodHazard',{})
        .then((response) => {
            if (response.success == 1) {
                this.setState({ fields:response.data, id: response.data.id,responseData:1 });
            } else {
                let fields = {
                    above_flood_plain:-1,
                    map_file:'',
                    map_upload_file:''                     
                }
                this.setState({ fields:fields, id:'',responseData:0 });
            }
        })
        .catch(err=>{
            let fields = {
                above_flood_plain:-1,
                map_file:'',
                map_upload_file:''                      
            }
            this.setState({ fields:fields, id:'',responseData:0 });
        })
    }

    ChangeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields,formDirty:true});
    }

    handleFileSelect = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.files[0];
        this.setState({ fields: fields,formDirty:true });
    }

    updateEvaluation = () => {
        this.setState({ proccesing:true })
        let fd = new FormData();
        fd.append('tab_id', this.state.tab_id);
        fd.append('id', this.state.id);
        fd.append('above_flood_plain', this.state.fields.above_flood_plain);
        

        if(this.state.fields.map_upload_file && this.state.fields.map_upload_file.name)
            fd.append('map_file', this.state.fields.map_upload_file,this.state.fields.map_upload_file.name);

        PostDataFile('SaveMilitaryEvaluationFloodHazard',fd)
        .then((response) => {
            if (response.success === 1) {
                window.swal('Success!', response.msg,'success')
                this.setState({ saveStatus: true,proccesing:false,formDirty:false }, () => {
                    this.props.onUpdateApprovalProgress();
                });   
            } else {
                this.setState({ proccesing:false,formDirty:false })
                window.swal('Error!', response.msg,'error')
            }
        })
    }

    openModal = (field_name,tab_id) => {
        this.props.onOpenModal(field_name,tab_id)
    }

    getFileName(file){
        if(file!=null){
            let files = file.split('/');
            return files[files.length -1];
        }        
    }

    renderAlertMsg = (field_name,tab_id) => {
       
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
       
        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Answer Received</p>
                        </React.Fragment>
                    )
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Waiting for reply</p>
                        </React.Fragment>
                    )
                }
            }
        }
    }
    renderClassName = (field_name,tab_id) => {
        
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
       
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
           

        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return "btn-success "
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return "btn-warning "
                }
            }
        }


    }
    render() {
        if (this.state.saveStatus) {
            return <Redirect  to='/independent-mover/dashboard/military-approved/information-tab/considerations'/>
        }
        return (
            <div className="tab-pane active" id="top-icon-tab11">
                <Prompt
                when={this.state.formDirty}
                message="Please save your data before you leave the page. Otherwise, you will lose the information you entered !"
                />
                <div className="panel panel-flat">
                    <div className="panel-heading">
                        <h5 className="panel-title">Evaluation of Flood Hazard</h5>
                    </div>
                    <div className="panel-body">
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Is your floor of your warehouse above the 100 year flood plain? &nbsp;<small><a href="https://msc.fema.gov/portal/search" target="_blank">Check it here.</a></small></h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" name="above_flood_plain" value="1" onChange={this.ChangeValue} checked={(this.state.fields.above_flood_plain==1)?true:false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" name="above_flood_plain" value="0" onChange={this.ChangeValue} checked={(this.state.fields.above_flood_plain==0)?true:false}/>
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('above_flood_plain',this.state.tab_id)} className={this.renderClassName('above_flood_plain',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('above_flood_plain',this.state.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Print the map and upload here</h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <input type="file" name="map_upload_file" onChange={this.handleFileSelect}/>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('map_file',this.state.tab_id)} className={this.renderClassName('map_file',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('map_file',this.state.tab_id)} 
                                </div>
                            </div>
                            {
                                this.state.fields.map_file && this.state.responseData==1
                                &&
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="file-wrapper filetype-image">
                                            <div className="file-title"><a href="javascript:void(0)" data-src={this.state.fields.map_file} data-title="Preview" data-toggle="image-modal-file" className="image-modal-file"><i className="icon-image5 position-left text-primary"></i> {this.getFileName(this.state.fields.map_file)}</a></div>
                                        </div>
                                    </div>
                                </div>
                            }
                            
                        </div>
                    </div>
                </div>

                <MilitaryConversations/>  
                <div className="text-center">{this.state.proccesing? <button className="btn btn-primary" disabled={true}>Processing...</button>:<button className="btn btn-primary" onClick={this.updateEvaluation}>Save &amp; Next</button>}</div>
            </div>

        );
    }
}

export default Evaluation;