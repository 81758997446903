import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PostDataWithParam from '../services/PostDataWithParam';
import Breadcumb from '../externaljs/Breadcrumb';
import GetData from '../services/GetData';
import Storage from '../externaljs/Storage';

class FindMovers extends Component {
    state = {
        providersCount: 0,
        loadMore: 0,
        providers: [],
        btnCount: 0,
        search_text: '',
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Find Movers', link: '', class: 'active' }
        ],
        selectedProviders: [],
        form_data: {
            id: '',
            request_start_date: '',
            request_end_date: '',
            source_address: '',
            source_latitude: '',
            source_longitude: '',
            total_manpower_needed: '',
            services_needed: [],
            relocation_notes: '',
            customer_email: '',
            customer_name: '',
            contact_no: '',
            home_contact_no: '',
            driver_helper_mover: [],
            driver_email: '',
            driver_name: '',
            driver_mobile_number: '',
            assign_flag: 0,
            hire_by_profile_id: 2,
            order_request_id: '',
            type: ''
        },
        loading: false,
        subscription_id: 0,
        temp_mover_id: '',
        fields: {
            logo: '',
            organization_name: '',
            doing_business_as_dba: '',
            year_founded: '',
            website_link: '',
            company_contact_no: '',
            company_fax_no: '',
            operation_email: '',
            sales_email: '',
            customer_service_email: '',
            driver_and_team_recruitment: '',
            claim_email: '',
            after_hours_staff_name: '',
            after_hours_contact_phone: '',
            radius_of_quality_service: '',
            management_contact_name: '',
            management_title: '',
            company_email: '',
            contact_no: '',
            military_approved_warehouse: '',
            certified_truck_scale_on_premises: '',
            warehouse: '',
            warehouse_size: '',
            climatized: '',
            climatized_size: '',
            air_conditioned: '',
            air_conditioned_size: '',
            pre_move_survey: '',
            packing: '',
            crating: '',
            short_hauling_hhg: '',
            long_hauling_hhg: '',
            freight_hauling: '',
            storage: '',
            intermodal: '',
            day_road_labor: '',
            sit_approved: '',
            international: '',
            last_mile_delivery: '',
            freight_warehouse: '',
            third_party_services: '',
            secured_parking_lot: '',
            tractors: '',
            trailers: '',
            straight_trucks: '',
            pack_vans: '',
            cdl_drivers: '',
            non_cdl_drivers: '',
            packers: '',
            loaders: '',
            primary_street_address: '',
            primary_address_other_info: '',
            secondary_street_address: '',
            secondary_address_other_info: '',
            primary_zip_code: '',
            secondary_zip_code: '',
            services_provided: [],
            primary_country: '',
            secondary_country: ''

        },
        company_files: [],
        services_provided_list: [],
        modalLoader: false,
        addJobLoader: false,
        address_type: '',
        address: '',
        services_needed: [],
        services_needed_str: [],
        staff_user_id: null,
        custom_menu: [],
        editPermission: true
    }

    subscriptionCheckInterval = null;

    componentDidMount = () => {
        if (typeof this.props.location.state !== 'undefined' && typeof this.props.location.state.form_data !== 'undefined') {
            this.setState({ form_data: this.props.location.state.form_data });
        }

        if (typeof this.props.location.state !== 'undefined' && typeof this.props.location.state.order_request_id !== 'undefined') {


            this.setState({
                order_request_id: this.props.location.state.order_request_id,
                type: this.props.location.state.type,
                order_no: this.props.location.state.order_no,
                address_type: this.props.location.state.address_type,
                address: this.props.location.state.address,
                services_needed: JSON.parse(this.props.location.state.services_needed),
                services_needed_str: this.props.location.state.services_needed_str
            }, () => {
                this.renderTitle()
            });
        }
        this.findProviders(0, '')
        document.body.addEventListener('subscription', (e) => {
            this.GetUserInfo();
        }, false);
        this.GetUserInfo();

        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        let custom_menu = [];
        if (staff_user_id != "") {
            custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
        }
        this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
            // console.log(this.state.custom_menu);
            // console.log(this.state.staff_user_id);
        });

        this.subscriptionCheckInterval = setInterval(() => {
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1)
                this.setState({ editPermission: false })
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ editPermission: true })
        }, 1000);

    }

    componentWillUnmount() {
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
    }

    doSubscribe = (e) => {
        window.swal({
            title: "Warning!",
            text: "Please upgrade your membership to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }

    GetCompanyInfo = () => {
        this.setState({ modalLoader: true }, () => {
            PostDataWithParam('GetCompanyInfo', { company_user_id: this.state.temp_mover_id })
                .then((response) => {
                    if (response.success === 1) {

                        let services_provided = [];
                        if (response.data[0].services_provided) {
                            let services_provided_arr = JSON.parse(response.data[0].services_provided);
                            services_provided = services_provided_arr.map((item, i) => {
                                return item.name;
                            })
                        }


                        this.setState({ fields: response.data[0], services_provided_list: services_provided, modalLoader: false });

                    } else {
                        this.setState({ modalLoader: false });
                    }
                })
                .catch(err => {
                    this.setState({ modalLoader: false });
                })
        });
    }


    changeValue = (e) => {
        let search_text = e.target.value;
        this.setState({ search_text: search_text });
    }
    handleSearchOnEnterKeyPress = (e) => {
        const code = e.keyCode || e.which;
        if (code === 13) {
            this.searchProvider();
        }
    }
    searchProvider = () => {
        if (this.state.search_text !== "") {
            this.setState({ btnCount: 0 }, () => {
                this.findProviders(0, this.state.search_text)
            });
        } else {
            this.findProviders(0, '')
        }
    }

    GetUserInfo = () => {
        GetData('GetUserInfo', {})
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ subscription_id: response.data.subscription_id });
                }
            })
            .catch(err => { })
    }

    findProviders = (offset, search_text) => {
        this.setState({ loading: true }, () => {
            PostDataWithParam('FindMoversForMMC', { offset: offset, search_text: search_text, order_id: this.state.order_request_id })
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ providers: response.data.mmc, providersCount: response.data.count, loadMore: response.data.load_more, loading: false });
                    } else {
                        this.setState({ providers: [], providersCount: 0, loadMore: 0, loading: false, btnCount: 0 });
                    }
                })
                .catch(() => {
                    this.setState({ providers: [], providersCount: 0, loadMore: 0, loading: false, btnCount: 0 });
                })
        })
    }

    loadMore = () => {
        let count = this.state.btnCount;

        count = count + 1;
        let offset = count * 9;

        this.setState({ btnCount: count, loading: true }, () => {
            PostDataWithParam('FindMoversForMMC', { offset: offset, search_text: this.state.search_text, order_id: this.state.order_request_id })
                .then((response) => {
                    if (response.success === 1) {
                        let oldProviders = this.state.providers;
                        let newProviders = response.data.mmc;
                        let allProviders = oldProviders.concat(newProviders);
                        this.setState({ providers: allProviders, providersCount: response.data.count, loadMore: response.data.load_more, loading: false }, () => {
                            for (let item of this.state.selectedProviders) {
                                window.$('#provider_' + item.user_id).attr('checked', true)
                            }
                        });
                    } else {
                        this.setState({ providers: [], providersCount: 0, loadMore: 0, loading: false, btnCount: 0 });
                    }
                })
                .catch(() => {
                    this.setState({ providers: [], providersCount: 0, loadMore: 0, loading: false, btnCount: 0 });
                })
        });
    }

    renderFeature = (feature) => {
        if (feature == 1)
            return "feature"
        else
            return ""
    }

    checkHelper = (e, profile_id, chk_id) => {
        let selectedProviders = this.state.selectedProviders;


        if (e.target.checked) {
            if (selectedProviders.length >= 10) {
                document.getElementById('provider_' + chk_id).checked = false;

                window.swal('Error!', 'Pick upto 10', 'error');
            }
            else
                selectedProviders.push({ user_id: e.target.value, name: e.target.name, profile_id: profile_id })
        } else {
            for (let i = 0; i < selectedProviders.length; i++) {
                if (selectedProviders[i].user_id === e.target.value) {
                    selectedProviders.splice(i, 1);
                    break;
                }
            }
        }

        this.setState({ selectedProviders: selectedProviders });

    }

    removeProvider = (user_id) => {
        let selectedProviders = this.state.selectedProviders;

        for (let i = 0; i < selectedProviders.length; i++) {
            if (selectedProviders[i].user_id === user_id) {
                selectedProviders.splice(i, 1);
                window.$('#provider_' + user_id).attr('checked', false)
                break;
            }
        }

        this.setState({ selectedProviders: selectedProviders });
    }



    redirectSubscription = () => {

        window.$('#subscribeToPremiumModal').modal('show');
    }

    handleLimitInvitation = (selectedProviders, checkedItems) => {
        if (this.state.order_request_id) {
            if (checkedItems !== 0) {
                if (checkedItems > 10) {
                    window.swal('Error!', 'Pick upto 10', 'error')
                    return (
                        <button type="button" className="btn btn-primary btn-xs btn-block" disabled={true}>Send Offer</button>
                    )
                } else {

                    if (this.state.addJobLoader) {
                        return <button type="button" className="btn btn-primary btn-xs btn-block" disabled={true}>Processing...</button>
                    } else {

                        if (this.state.editPermission) {
                            return <button type="button" className="btn btn-primary btn-xs btn-block" onClick={this.assignMover}>Send Offer</button>
                        }
                        else {
                            return <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-primary btn-xs btn-block" onClick={this.doSubscribe}>Send Offer</Link>
                        }
                    }

                }
            } else {
                return (
                    <button type="button" className="btn btn-primary btn-xs btn-block" disabled={true}>Send Offer</button>
                )
            }
        } else {
            if (checkedItems !== 0) {
                if (checkedItems > 10) {
                    window.swal('Error!', 'Pick upto 10', 'error')
                    return (
                        <button type="button" className="btn btn-primary btn-xs btn-block" disabled={true}>Send Offer</button>
                    )
                } else {


                    if (this.state.addJobLoader) {
                        return <button type="button" className="btn btn-primary btn-xs btn-block" disabled={true}>Processing...</button>
                    } else {

                        if (this.state.editPermission) {
                            return <button type="button" className="btn btn-primary btn-xs btn-block" onClick={() => this.createJob(selectedProviders)}>Send Offer</button>
                        }
                        else {
                            return <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-primary btn-xs btn-block" onClick={this.doSubscribe}>Send Offer</Link>
                        }
                    }
                }
            } else {
                return (
                    <button type="button" className="btn btn-primary btn-xs btn-block" disabled={true}>Send Offer</button>
                )
            }
        }

    }

    createJob = (selectedProviders) => {
        let form_data = this.state.form_data;

        if (form_data.services_needed.length != 0) {
            let services_needed = form_data.services_needed.map((item, i) => {
                return { name: item };
            });
            form_data.services_needed = JSON.stringify(services_needed);
            form_data.driver_helper_mover = JSON.stringify(selectedProviders);

            this.setState({ addJobLoader: true }, () => {
                PostDataWithParam('CreateOffer', form_data)
                    .then((response) => {
                        if (response.success == 1) {
                            this.setState({ addJobLoader: false })
                            window.swal('Success!', response.msg, 'success')
                            this.props.history.push({
                                pathname: '/independent-mover/dashboard/assign-offer-job',
                                state: { selectedProviders: selectedProviders, form_data: this.state.form_data }
                            })
                        } else {
                            this.setState({ addJobLoader: false })
                            window.swal('Error!', response.msg, 'error');
                        }
                    })
                    .catch(err => {
                        this.setState({ addJobLoader: false })
                        window.swal('Error!', 'Something went wrong', 'error');
                    })
            })
        } else {
            this.props.history.push({
                pathname: '/independent-mover/dashboard/assign-offer-job',
                state: { selectedProviders: selectedProviders, form_data: this.state.form_data }
            })
        }
    }

    assignMover = () => {
        this.setState({ addJobLoader: true }, () => {
            PostDataWithParam('AssignReloHelpersToExistingJob', {
                order_request_id: this.state.order_request_id,
                flag: this.state.type,
                driver_helper_mover: JSON.stringify(this.state.selectedProviders),
                address_type: this.state.address_type,
                address: this.state.address,
                services_needed: this.state.services_needed
            })
                .then((response) => {
                    if (response.success == 1) {
                        this.setState({ addJobLoader: false })
                        window.swal('Success!', response.msg, 'success')
                        let flag = (this.state.type == "outsource") ? 'accepted' : '';
                        this.props.history.push({
                            pathname: '/independent-mover/dashboard/order-details',
                            state: { type: flag, id: this.state.order_request_id, request_from: this.props.location.state.request_from }
                        });
                    } else {
                        this.setState({ addJobLoader: false })
                        window.swal('Error!', response.msg, 'error');
                    }
                })
                .catch(err => {
                    this.setState({ addJobLoader: false })
                })
        })
    }

    renderTitle = () => {
        if (this.state.order_request_id) {
            return (
                <div className="">
                    <Link to={{
                        pathname: '/independent-mover/dashboard/order-details',
                        state: {
                            id: this.state.order_request_id,
                            type: ''
                        }
                    }} className="btn btn-link p-l-0"><i className="icon-arrow-left8"></i> Back</Link>

                    <h5 className="panel-title">
                        Find Movers for
                    {
                            this.state.address_type == "Both"
                            &&
                            " Origin and Destination Addresses "
                        }
                        {
                            this.state.address_type != "Both"
                            &&
                            ' the ' + this.state.address_type + ' Address "' + this.state.address + '" '
                        }
                    (Relo #{this.state.order_no})
                    </h5>
                </div>
            )
        } else {
            return (
                <h5 className="panel-title">Find Movers </h5>
            )
        }
    }

    setTempMover = (id) => {
        this.setState({ temp_mover_id: id }, () => {
            this.GetCompanyInfo()
        });
    }

    handleFileCheck = (file) => {
        if (file !== '') {
            return (
                <span className="thumb-60" style={{ background: "url(" + this.state.fields.logo + ") no-repeat center center / 100%" }}></span>
            )
        } else {
            return (
                <span className="thumb-60" style={{ background: 'url(assets/images/default_avatar.jpg) no-repeat center center / 100%' }}></span>
            )
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="panel panel-flat profile-page">
                        <div className="panel-heading p-b-5 p-t-10">
                            {this.renderTitle()}
                        </div>
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-md-10 p-r-5">
                                    <div className="form-group m-0">
                                        <input
                                            type="text"
                                            placeholder="Search by name, city, state, zip code, address, SCAC" className="form-control"
                                            name="search_text"
                                            onChange={this.changeValue}
                                            onKeyPress={this.handleSearchOnEnterKeyPress}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2 p-l-5">
                                    <div className="form-group m-0">
                                        <button type="button" className="btn btn-primary btn-block" onClick={this.searchProvider}>FIND</button>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.services_needed_str.length != 0
                                &&
                                <div className="row m-t-15">
                                    <div className="col-md-12">
                                        <label className="text-bold m-b-0">Services Needed:</label>
                                        <p className="m-b-20">
                                            {
                                                this.state.services_needed_str.length != 0
                                                &&
                                                this.state.services_needed_str.map((item, i) => {
                                                    return (
                                                        <span key={i}>
                                                            {item}
                                                            {
                                                                this.state.services_needed_str.length != (i + 1)
                                                                &&
                                                                ' , '
                                                            }
                                                        </span>
                                                    )
                                                })
                                            }
                                        </p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-9">
                            <p className="f-s-16">{this.state.providersCount} Mover(s) Found. <small className="text-muted">(Pick upto 10)</small></p>
                            <div className="row">
                                {
                                    this.state.loading
                                    &&
                                    <div className="col-md-12">
                                        <div className="well text-center">
                                            <i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...
                                    </div>
                                    </div>
                                }
                            </div>
                            <div className="offer-grid grid-col-3">
                                {!this.state.loading
                                    &&
                                    this.state.providers.length != 0
                                    &&
                                    this.state.providers.map((provider, index) => {
                                        return (


                                            <div className={"service-box m-b-20"} key={index}>
                                                <div className="corner-triangle">
                                                    <div className="corner-triangle-image">
                                                        <a href=""><img src="assets/images/value.svg" /></a>
                                                    </div>
                                                </div>
                                                {this.state.staff_user_id == "" &&
                                                    <div className="custom-checkbox-wrap">
                                                        <label className="checkbox-inline custom-checkbox">
                                                            <input type="checkbox" id={"provider_" + provider.user_id} onClick={(e) => this.checkHelper(e, provider.profile_id, provider.user_id)} value={provider.user_id} name={provider.organization_name} />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                }
                                                {
                                                    this.state.staff_user_id != "" && this.state.custom_menu.map((menu, i) => {
                                                        return (
                                                            <div className="custom-checkbox-wrap">
                                                                {(menu.title == "Find & Book a Pro" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                                    <label className="checkbox-inline custom-checkbox">
                                                                        <input type="checkbox" id={"provider_" + provider.user_id} onClick={(e) => this.checkHelper(e, provider.profile_id, provider.user_id)} value={provider.user_id} name={provider.organization_name} />
                                                                        <span></span>
                                                                    </label>
                                                                }
                                                            </div>
                                                        );
                                                    })
                                                }
                                                <div className="service-box-list">
                                                    <ul className="list-unstyled m-0">
                                                        {
                                                            provider.logo == ""
                                                            &&
                                                            <li className=""><span style={{ background: 'url(assets/images/default_avatar.jpg) no-repeat center center / cover' }}></span></li>
                                                        }
                                                        {
                                                            provider.logo !== ""
                                                            &&
                                                            <li className=""><span style={{ background: 'url(' + provider.logo + ') no-repeat center center / 100%' }}></span></li>
                                                        }
                                                        <li className="f-s-16">

                                                            <a data-toggle="modal" data-target="#profileInfoModal" href="#" onClick={() => this.setTempMover(provider.user_id)}>{provider.organization_name}</a>

                                                            {
                                                                provider.primary_street_address
                                                                &&
                                                                <p>{(provider.primary_street_address.length < 30) ? provider.primary_street_address : provider.primary_street_address.substr(0, 30) + '...'}</p>
                                                            }
                                                            {
                                                                !provider.primary_street_address
                                                                &&
                                                                <p>---</p>
                                                            }
                                                        </li>
                                                    </ul>
                                                </div>
                                                {
                                                    Math.ceil(provider.avarage_rating) > 0
                                                    &&
                                                    <div className="service-box-list text-center text-orange">
                                                        <p className="m-0 f-s-24">{provider.avarage_rating}</p>
                                                        <p className="m-0">
                                                            <React.Fragment>
                                                                <span className={(Math.ceil(provider.avarage_rating) >= 1) ? 'icon-star-full2 m-r-3' : 'icon-star-empty3 m-r-3'}></span>
                                                                <span className={(Math.ceil(provider.avarage_rating) >= 2) ? 'icon-star-full2 m-r-3' : 'icon-star-empty3 m-r-3'}></span>
                                                                <span className={(Math.ceil(provider.avarage_rating) >= 3) ? 'icon-star-full2 m-r-3' : 'icon-star-empty3 m-r-3'}></span>
                                                                <span className={(Math.ceil(provider.avarage_rating) >= 4) ? 'icon-star-full2 m-r-3' : 'icon-star-empty3 m-r-3'}></span>
                                                                <span className={(Math.ceil(provider.avarage_rating) == 5) ? 'icon-star-full2' : 'icon-star-empty3'}></span>
                                                            </React.Fragment>
                                                        </p>
                                                    </div>
                                                }
                                                {
                                                    Math.ceil(provider.avarage_rating) == 0.0
                                                    &&
                                                    <div className="service-box-list text-center text-orange">
                                                        <p className="m-0 f-s-24">----</p>
                                                        <p className="m-0">
                                                            <React.Fragment>
                                                                <span className='icon-star-empty3 m-r-3'></span>
                                                                <span className='icon-star-empty3 m-r-3'></span>
                                                                <span className='icon-star-empty3 m-r-3'></span>
                                                                <span className='icon-star-empty3 m-r-3'></span>
                                                                <span className='icon-star-empty3'></span>
                                                            </React.Fragment>
                                                        </p>
                                                    </div>
                                                }
                                            </div>

                                        )
                                    })
                                }
                                {
                                    !this.state.loading
                                    &&
                                    this.state.providers.length == 0
                                    &&
                                    <React.Fragment>
                                        <div className="col-md-12">
                                            <div className="alert alert-primary border-none text-center">No Provider Found.</div>
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                            {
                                this.state.loadMore == 1
                                &&
                                <div className="text-center">
                                    <a href="javascript:void(0)" className="btn btn-primary" onClick={this.loadMore}>Load More</a>
                                </div>
                            }
                        </div>

                        {this.state.staff_user_id == "" &&
                            <div className="col-md-3 p-t-35">
                                <div className="sidebar-fixed-rightside">
                                    <div className="panel panel-default sidebar-content">
                                        <div className="panel-body selected-comapany">
                                            <h6 className="m-t-0"><img src="assets/images/icons/delivery-truck.svg" alt="" />Selected Movers ({this.state.selectedProviders.length}/10)</h6>
                                            <ul className="selected-company-list m-b-25">
                                                {
                                                    this.state.selectedProviders.length != 0
                                                    &&
                                                    this.state.selectedProviders.map((item, index) => {
                                                        return (
                                                            <li key={index}>{item.name}<a href="javascript:void(0)" className="pull-right" onClick={() => this.removeProvider(item.user_id)}><i className="icon-cross2 text-muted"></i></a></li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            <div className="text-center m-t-15">
                                                {this.handleLimitInvitation(this.state.selectedProviders, this.state.selectedProviders.length)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.staff_user_id != "" && this.state.custom_menu.map((menu, i) => {
                                return (
                                    <div className="col-md-3">
                                        { (menu.title == "Find & Book a Pro" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                            <div className="sidebar-fixed-rightside">
                                                <div className="panel panel-default sidebar-content">
                                                    <div className="panel-body selected-comapany">
                                                        <h6 className="m-t-0"><img src="assets/images/icons/delivery-truck.svg" alt="" />Selected Movers ({this.state.selectedProviders.length}/10)</h6>
                                                        <ul className="selected-company-list m-b-25">
                                                            {
                                                                this.state.selectedProviders.length != 0
                                                                &&
                                                                this.state.selectedProviders.map((item, index) => {
                                                                    return (
                                                                        <li key={index}>{item.name}<a href="javascript:void(0)" className="pull-right" onClick={() => this.removeProvider(item.user_id)}><i className="icon-cross2 text-muted"></i></a></li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                        <div className="text-center m-t-15">
                                                            {this.handleLimitInvitation(this.state.selectedProviders, this.state.selectedProviders.length)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                );
                            })
                        }
                    </div>
                </div>
                <div id="profileInfoModal" className="modal fade" role="dialog">

                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            {
                                this.state.modalLoader
                                &&
                                <div className="col-md-12">
                                    <div className="well text-center">
                                        <i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...
                            </div>
                                </div>
                            }

                            <div className="modal-body p-t-0">
                                {!this.state.modalLoader
                                    &&
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="text-center">
                                                    {this.handleFileCheck(this.state.fields.logo)}
                                                </div>

                                                {
                                                    Math.ceil(this.state.fields.avarage_rating) > 0
                                                    &&
                                                    <div className="text-center text-orange">
                                                        <p className="m-t-15 m-b-0">
                                                            <React.Fragment>
                                                                <span className={(Math.ceil(this.state.fields.avarage_rating) >= 1) ? 'icon-star-full2 m-r-3' : 'icon-star-empty3 m-r-3'}></span>
                                                                <span className={(Math.ceil(this.state.fields.avarage_rating) >= 2) ? 'icon-star-full2 m-r-3' : 'icon-star-empty3 m-r-3'}></span>
                                                                <span className={(Math.ceil(this.state.fields.avarage_rating) >= 3) ? 'icon-star-full2 m-r-3' : 'icon-star-empty3 m-r-3'}></span>
                                                                <span className={(Math.ceil(this.state.fields.avarage_rating) >= 4) ? 'icon-star-full2 m-r-3' : 'icon-star-empty3 m-r-3'}></span>
                                                                <span className={(Math.ceil(this.state.fields.avarage_rating) == 5) ? 'icon-star-full2 m-r-3' : 'icon-star-empty3 m-r-3'}></span>
                                                                {this.state.fields.avarage_rating}
                                                            </React.Fragment>
                                                        </p>
                                                    </div>
                                                }
                                                {
                                                    Math.ceil(this.state.fields.avarage_rating) == 0.0
                                                    &&
                                                    <div className="text-center text-orange">
                                                        <p className="m-t-15 m-b-0">
                                                            <React.Fragment>
                                                                <span className='icon-star-empty3 m-r-3'></span>
                                                                <span className='icon-star-empty3 m-r-3'></span>
                                                                <span className='icon-star-empty3 m-r-3'></span>
                                                                <span className='icon-star-empty3 m-r-3'></span>
                                                                <span className='icon-star-empty3'></span>
                                                            </React.Fragment>
                                                        </p>
                                                    </div>
                                                }
                                                {(this.state.fields.secondary_street_address) && (
                                                    <React.Fragment>
                                                        <h5 className="m-t-10">Mailing Address {this.state.id}</h5>
                                                        <hr className="m-t-5 m-b-10 border-color-light-grey" />
                                                        <p className="f-s-16 text-muted"><i className="icon-location3 text-primary"></i> 
                                                        {this.state.fields.primary_street_address
                                                            ? `${this.state.fields.primary_street_address} 
                                                            ${this.state.fields.primary_zip_code
                                                                ? `, ${this.state.fields.primary_zip_code}`
                                                                : ''
                                                            }`
                                                            : "----"}
                                                        </p>
                                                    </React.Fragment>
                                                )}
                                                <ul className="list-group m-t-15">
                                                    <li className="list-group-item text-primary"><b>Services Provided</b></li>
                                                    {
                                                        this.state.services_provided_list.length > 1
                                                        &&
                                                        this.state.services_provided_list.map((item, index) => {
                                                            return (<li className="list-group-item" key={index}><i className="icon-check text-success"></i>{item}</li>)
                                                        })
                                                    }
                                                    {
                                                        this.state.services_provided_list.length == 1
                                                        &&
                                                        <p className="text-danger text-center">No Services Found.</p>
                                                    }

                                                </ul>
                                                <ul className="list-group m-t-15">
                                                    <li className="list-group-item">Tractors <span className="badge bg-blue">{this.state.fields.tractors || '0'}</span></li>
                                                    <li className="list-group-item">Trailers <span className="badge bg-blue">{this.state.fields.trailers || '0'}</span></li>
                                                    <li className="list-group-item">Straight Trucks <span className="badge bg-blue">{this.state.fields.straight_trucks || '0'}</span></li>
                                                    <li className="list-group-item">Pack Vans <span className="badge bg-blue">{this.state.fields.pack_vans || '0'}</span></li>
                                                    <li className="list-group-item">CDL Drivers <span className="badge bg-blue">{this.state.fields.cdl_drivers || '0'}</span></li>
                                                    <li className="list-group-item">Non-CDL Drivers <span className="badge bg-blue">{this.state.fields.non_cdl_drivers || '0'}</span></li>
                                                    <li className="list-group-item">Packers <span className="badge bg-blue">{this.state.fields.packers || '0'}</span></li>
                                                    <li className="list-group-item">Loaders <span className="badge bg-blue">{this.state.fields.loaders || '0'}</span></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="border-left p-l-20 border-color-light-grey ">
                                                    <h1 className="m-t-0 m-b-0">{this.state.fields.organization_name} <small className="f-s-15 text-muted">({this.state.fields.doing_business_as_dba || '----'})</small></h1>
                                                    <p className="f-s-14 text-grey-400">Founded on: {this.state.fields.year_founded || '----'} | Website: <a href={this.state.fields.website_link || 'javascript:void(0)'} target="_blank">{this.state.fields.website_link || '----'}</a></p>
                                                    <p className="f-s-16 text-muted"><i className="icon-location3 text-primary"></i>   {this.state.fields.primary_street_address
                                                            ? `${this.state.fields.primary_street_address} 
                                                            ${this.state.fields.primary_zip_code
                                                                ? `, ${this.state.fields.primary_zip_code}`
                                                                : ''
                                                            }`
                                                            : "----"}
                                                        </p>
                                                        <h4 className="text-primary m-t-20">Contact Info</h4>
                                                        <hr className="m-t-0 m-b-10 border-color-light-grey" />


                                                        <div className="row">
                                                            {/* <div className="col-md-6">
                                                <label className="text-bold m-b-0">Main Office Phone Number</label>
                                                <p className="m-b-20">{this.state.fields.company_contact_no || '----'}</p>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="text-bold m-b-0">Office Fax Number</label>
                                                <p className="m-b-20">{this.state.fields.company_fax_no || '----'}</p>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="text-bold m-b-0">Operations Email Address</label>
                                                <p className="m-b-20">{this.state.fields.operation_email || '----'}</p>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="text-bold m-b-0">Sales Email Address (For Leads & New Customers)</label>
                                                <p className="m-b-20">{this.state.fields.sales_email || '----'}</p>
                                            </div>
                                            <div className="col-md-12">
                                                <label className="text-bold m-b-0">Customer Service Email Address (For Existing Customers)</label>
                                                <p className="m-b-20">{this.state.fields.customer_service_email || '----'}</p>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="text-bold m-b-0">Claims Email Address</label>
                                                <p className="m-b-20">{this.state.fields.claim_email || '----'}</p>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="text-bold m-b-0">After Hours Contact Phone</label>
                                                <p className="m-b-20">{this.state.fields.after_hours_contact_phone || '----'}</p>
                                            </div> */}
                                                            <div className="col-md-6">
                                                                <label className="text-bold m-b-0">After Hours Staff Name</label>
                                                                <p className="m-b-20">{this.state.fields.after_hours_staff_name || '----'}</p>
                                                            </div>
                                                        </div>
                                                        <h4 className="text-primary m-t-5">Additional Info</h4>
                                                        <hr className="m-t-0 m-b-10 border-color-light-grey" />
                                                        <div className="row">

                                                            <div className="col-md-6">
                                                                <label className="text-bold m-b-0">Management Contact Name</label>
                                                                <p className="m-b-20">{this.state.fields.management_contact_name || '----'}</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="text-bold m-b-0">Management Title</label>
                                                                <p className="m-b-20">{this.state.fields.management_title || '----'}</p>
                                                            </div>
                                                            {/* <div className="col-md-6">
                                                <label className="text-bold m-b-0">Management Email Address</label>
                                                <p className="m-b-20">{this.state.fields.company_email || '----'}</p>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="text-bold m-b-0">Management Phone</label>
                                                <p className="m-b-20">{this.state.fields.contact_no || '----'}</p>
                                            </div> */}
                                                            <div className="col-md-6">
                                                                <label className="text-bold m-b-0">Certified Truck Scale on Premises?</label>
                                                                <p className="m-b-20">{this.state.fields.certified_truck_scale_on_premises == 1
                                                                    ? 'Yes' :
                                                                    'No'}</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="text-bold m-b-0">Secured Parking Lot?</label>
                                                                <p className="m-b-20">{
                                                                    this.state.fields.secured_parking_lot == 1
                                                                        ? 'Yes'
                                                                        : 'No'
                                                                }</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="text-bold m-b-0">Do You Have a Warehouse?</label>
                                                                <p className="m-b-20">{
                                                                    this.state.fields.warehouse == 1 ? 'Yes' : 'No'
                                                                }</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="text-bold m-b-0">Warehouse Size in Square Feet</label>
                                                                <p className="m-b-20">{this.state.fields.warehouse_size || '----'}</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="text-bold m-b-0">Climatized?</label>
                                                                <p className="m-b-20">{
                                                                    this.state.fields.climatized == 1 ? 'Yes' : 'No'
                                                                }</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="text-bold m-b-0">Climatized Size in Square Feet</label>
                                                                <p className="m-b-20">{this.state.fields.climatized_size || '----'}</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="text-bold m-b-0">Air Conditioned?</label>
                                                                <p className="m-b-20">{
                                                                    this.state.fields.air_conditioned == 1
                                                                        ? 'Yes'
                                                                        : 'No'
                                                                }</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="text-bold m-b-0">Air Conditioned Size in Square Feet</label>
                                                                <p className="m-b-20">{this.state.fields.air_conditioned_size || '----'}</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="text-bold m-b-0">Military Approved Warehouse?</label>
                                                                <p className="m-b-20">{
                                                                    this.state.fields.military_approved_warehouse == 1
                                                                        ? 'Yes'
                                                                        : 'No'
                                                                }</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="text-bold m-b-0">Radius of Quality Service</label>
                                                                <p className="m-b-20">{this.state.fields.radius_of_quality_service || '----'}</p>
                                                            </div>
                                                        </div>
                                                </div>


                                            </div>

                                        </div>
                                    </React.Fragment>
                                }
                            </div>

                            <div className="modal-footer">
                                {
                                    !this.state.modalLoader
                                    &&
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                }
                            </div>

                        </div>

                    </div>
                </div>


            </div>
        );
    }
}

export default FindMovers;