import React, { Component } from 'react';
import { Route, Redirect, HashRouter } from 'react-router-dom';

import Storage from './externaljs/Storage';
import Urls from './externaljs/Urls';
import DashboardLayout from './components/DashboardLayout';


import Login from './components/BeforeLoggedin/Login';
import Homepage from './components/BeforeLoggedin/Homepage';
// import RegistrationSelectProfile from './components/BeforeLoggedin/RegistrationSelectProfile';
// import RegistrationClient from './components/BeforeLoggedin/RegistrationClient';
import RegistrationMmc from './components/BeforeLoggedin/SignUp';
import Wwa from './components/BeforeLoggedin/Wwa';
import GetMilApv from './components/BeforeLoggedin/GetMilApv';
// import RegistrationIndividual from './components/BeforeLoggedin/RegistrationIndividual';
import ForgotPassword from './components/BeforeLoggedin/ForgotPassword';
import OtpCheck from './components/BeforeLoggedin/OtpCheck';
import RegisterStepTwo from './components/BeforeLoggedin/RegisterStepTwo';
import ContactUs from './components/BeforeLoggedin/ContactUs';

import PrivacyPolicy from './components/BeforeLoggedin/PrivacyPolicy';
import TermsofService from './components/BeforeLoggedin/TermsofService';
import SubscriptionAgreement from './components/BeforeLoggedin/SubscriptionAgreement';
import MoneySavingTools from './components/BeforeLoggedin/MoneySavingTools';
import ResendActivation from './components/BeforeLoggedin/ResendActivation';
import ActivationMessage from './components/BeforeLoggedin/ActivationMessage';
import ClaimYourBusiness from './components/BeforeLoggedin/ClaimYourBusiness';

class App extends Component {
  render() {
    return (
      <HashRouter>
        <div className="App">
          <Route path="/" exact render={
            () => { return <Redirect to="/independent-mover/dashboard" /> }
          } />

          <Route path="/independent-mover/dashboard" render={
            (props) => {
              if (Storage.get('imnLoginToken') !== '') {
                return <DashboardLayout {...props} />
              } else {
                if (props.location.pathname === "/independent-mover/dashboard" && !props.location.search)
                  return <Redirect to="/home" />
                else return <Redirect to={`/login${props.location.search}` }/>
              }
            }
          } />


          {/********************************** Before Login Route *******************/}

          <Route path="/home" render={
            (props) => {
              if (Storage.get('imnLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <Homepage {...props} />
              }
            }
          } />
          <Route path="/wwa" render={
            (props) => {
              if (Storage.get('imnLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <Wwa {...props} />
              }
            }
          } />
          <Route path="/getmilapv" render={
            (props) => {
              if (Storage.get('imnLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <GetMilApv {...props} />
              }
            }
          } />

          <Route path="/privacy-policy" render={
            (props) => {
              if (Storage.get('imnLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <PrivacyPolicy {...props} />
              }
            }
          } />

          <Route path="/subscription-agreement" render={
            (props) => {
              if (Storage.get('imnLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <SubscriptionAgreement {...props} />
              }
            }
          } />

          <Route path="/terms-service" render={
            (props) => {
              if (Storage.get('imnLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <TermsofService {...props} />
              }
            }
          } />

          <Route path="/money-saving-tools" render={
            (props) => {
              if (Storage.get('imnLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <MoneySavingTools {...props} />
              }
            }
          } />

          <Route path="/contact-us" render={
            (props) => {
              if (Storage.get('imnLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <ContactUs {...props} />
              }
            }
          } />

          <Route path="/registration-imn" render={
            (props) => {
              if (Storage.get('imnLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <RegistrationMmc {...props} />
              }
            }
          } />

          <Route path="/registration-imn-step-two" render={
            (props) => {
              if (Storage.get('imnLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <RegisterStepTwo {...props} />
              }
            }
          } />

          <Route path="/otp-check" render={
            (props) => {
              if (Storage.get('imnLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <OtpCheck {...props} />
              }
            }
          } />

          <Route path="/forgot-password" render={
            (props) => {
              if (Storage.get('imnLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <ForgotPassword {...props} />
              }
            }
          } />
          <Route path="/resend-activation" render={
            (props) => {
              if (Storage.get('imnLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <ResendActivation {...props} />
              }
            }
          } />

          <Route path="/login" render={
            (props) => {
              if (Storage.get('imnLoginToken') != '') {
                if ((JSON.parse(Storage.get('imnLoginData'))).profile_id == 2) {
                  window.location.href = Urls.mmcDashboard + props.location.search
                  return <p></p>;
                } else if ((JSON.parse(Storage.get('imnLoginData'))).profile_id == 7) {
                  window.location.href = Urls.mmcDashboard + props.location.search
                  return <p></p>;
                } else {
                  return <p></p>;
                }
              } else {
                return <Login {...props} />
              }
            }
          } />

          <Route path="/activation-message/:from" render={
            (props) => {
              if (Storage.get('imnLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <ActivationMessage {...props} />
              }
            }
          } />

          <Route path="/claim-your-business" render={
            (props) => {
              if (Storage.get('imnLoginToken') !== '') {
                return <Redirect to="/login" />
              } else {
                return <ClaimYourBusiness {...props} />
              }
            }
          } />
        </div>
      </HashRouter>
    );
  }
}

export default App;
