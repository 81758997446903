import React, { Component } from 'react'
import Breadcumb from '../externaljs/Breadcrumb';
import { Link } from 'react-router-dom';
import Storage from '../externaljs/Storage';
import PostDataWithParam from '../services/PostDataWithParam';
import DateFormat from '../externaljs/DateFormat';

class AssignedOrder extends Component {
    state = {
        breadcrumb: [
           
        ],
        offers: [],
        orderType: 0,
        loading: false,
        offset: 0,
        limit: 9,
        search_text: '',
        loadMore: 0,
        total: 0,
        total_count: 0,
        editPermission: true,
        isDeclined: false,
        isAccepted: false,
        edit_rcvOffers: true,
        final_search_text: '',
        driver_helper_mover:[],
        address:'',
        address_type:''
    }
   
    componentDidMount() {
        if (typeof this.props.location.state !== 'undefined') {
            this.setState({ driver_helper_mover: this.props.location.state.driver_helper_mover });
        }
        this.offersAssigned();
        
    }


    offersAssigned = () => {
        this.setState({ loading: true, loadMore: 0, total_count: 0 }, () => {
            PostDataWithParam('UnassignedOfferListForMovers', {})
                .then(response => {
                    if (response.success === 1) {
                        let offers = this.state.offers;
                        if (offers) {
                            offers = [...offers, ...response.data]
                        }
                        else {
                            offers = response.data
                        }
                        this.setState({ offers: offers, loading: false, search_text: this.state.search_text, loadMore: response.load_more, total: response.data.count, total_count: response.total_count }, () => {
                            let offset = this.state.offset;
                            if (offset == 0) {
                                offset = this.state.limit;
                            } else {
                                offset += this.state.limit;
                            }
                            this.setState({ offset: offset });
                        })
                    } else {
                        this.setState({ offers: [], offset: 0, total: 0, loading: false })
                    }
                })
                .catch(err => {
                    this.setState({ offers: [], loading: false })
                })
        })
    }


    handleOnChange(e) {
        this.setState({ search_text: e.target.value });
    }

    search() {
        this.setState({ offset: 0, loadMore: 0, total: 0, offers: [],final_search_text:this.state.search_text }, () => {
            this.offersAssigned();
        })
    }
  
    selectOffer(services_needed, id,source_address,destination_address,e) {
        e.preventDefault();
        if((source_address !== '' && destination_address !=='') || (source_address == '' && destination_address ==''))
        {
            this.setState({ address_type: 'Both',address:'' });
        }
        else if(source_address !== '')
        {
            this.setState({ address_type: 'Origin',address:source_address });
        }
        else if(destination_address !== '')
        {
            this.setState({ address_type: 'Destination',address: destination_address});
        }
        PostDataWithParam('AssignReloHelpersToExistingJob', {  
                order_request_id: id,
                flag: null,
                driver_helper_mover: JSON.stringify(this.state.driver_helper_mover),
                address_type: this.state.address_type,
                address: this.state.address,
                services_needed: services_needed
        })
            .then(response => {
                if (response.success === 1) {
                    window.swal('Success!', response.msg, 'success');
                    this.setState({ loading: true, offers: [] }, () => {
                        this.offersAssigned();
                        this.setState({ loading: false });
                    })
                } else {
                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => { })
    }
    renderOffers = () => {
        if (this.state.offers.length > 0) {
            let offers = this.state.offers.map((item, i) => {
                return (
                    <div className="order-item" key={'offers-' + i}>
                        <div className="order-item-details">
                            <div className="order-item-header">
                                <h4 className="m-0">Relo #{item.order_id}</h4>
                                <p className="text-semibold text-muted overflow-ellipsis"><i><span>{item.posted_by}</span> posted on {DateFormat.getFormattedDateTime(item.posted_date, 'm-d-Y')}</i></p>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-6">
                                    <div className="form-group">
                                        <label className="text-bold m-0">Requested Start Date</label>
                                        <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22 m-r-10"></i>{DateFormat.getFormattedDateTime(item.request_start_date, 'm-d-Y')}</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-6">
                                    <div className="form-group">
                                        <label className="text-bold m-0">Requested End Date</label>
                                        <p className="f-s-16 m-0 text-muted"><i className="icon-calendar22 m-r-10"></i>{DateFormat.getFormattedDateTime(item.request_end_date, 'm-d-Y')}</p>
                                    </div>
                                </div>
                            </div>
                           
                                <div className="form-group">
                                    <label className="text-bold m-0">Service Origin Address</label>
                                    <p className="f-s-16 m-0 text-muted overflow-ellipsis"><i className="icon-location3 m-r-10"></i>{item.source_address ? ((item.source_address && item.source_address.length > 30) ? item.source_address.substr(0, 30) + '...' : item.source_address) : '...'}</p>
                                </div>
                                <div className="form-group">
                                    <label className="text-bold m-0">Service Destination Address</label>
                                    <p className="f-s-16 m-0 text-muted overflow-ellipsis"><i className="icon-location3 m-r-10"></i>{item.destination_address ? ((item.source_address && item.destination_address.length > 30) ? item.destination_address.substr(0, 30) + '...' : item.destination_address) : '...'}</p>
                                </div>
                            
                            <div className="overlay">
                                        {
                                        /*
                                            <React.Fragment>
                                                    <Link to="#" onClick={this.selectOffer.bind(this, item.services_needed, item.id,item.source_address,item.destination_address)} className="btn btn-success btn-xs btn-block">Select</Link>}
                                            </React.Fragment>
                                        */
                                    }
                                    <Link
                                            to={{
                                                    pathname: '/independent-mover/dashboard/order-details',
                                                        state: {
                                                            id: item.id,
                                                            type: '',
                                                            request_from: ''
                                                        }
                                                    }}
                                                    className="btn btn-success btn-xs btn-block"
                                         >Select</Link>
     
                            </div>
                        </div>
                       
                    </div>
                )
            });
            return offers;
        }
    }


    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {/* {Breadcumb(this.state.breadcrumb)} */}
                    <div className="row">
                        <div className="col-sm-10">
                            <div className="form-group">
                                <input type="text" name="search_text" className="form-control" placeholder="Search by Relo#, Name, City, State, Zip Code, Etc." style={{ 'height': '50px' }} value={this.state.search_text} onChange={this.handleOnChange.bind(this)} />
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <button className="btn btn-primary btn-block" style={{ 'height': '50px' }} onClick={this.search.bind(this)}>Search</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                         
                            {
                                !this.state.loading
                                &&
                                this.state.offers.length == 0
                                &&
                                <div className="panel panel-default">
                                    <div className="panel-body">
                                        <h6 className="alert alert-primary border-none text-center m-0">No Offers Found.</h6>
                                    </div>
                                </div>
                            }
                            {
                                this.state.loading
                                &&
                                <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                            }
                            {
                                !this.state.loading
                                &&
                                this.state.offers.length != 0
                                &&
                                <div>
                                    <p className="f-s-16">{this.state.offers.length} Offers found of {this.state.total_count}</p>
                                    <div className="order-item-box">
                                        {this.renderOffers()}
                                    </div>
                                    {this.state.loadMore == 1 &&
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <a href="javascript:void(0)" className="btn btn-load m-b-20 m-t-20" onClick={this.offersAssigned.bind(this)}>Load More</a>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AssignedOrder;
