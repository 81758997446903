import React, { Component } from 'react'
import PostDataWithParam from '../services/PostDataWithParam';
import GetData from '../services/GetData';

class AccessControl extends Component {
    state = {
        profileList: [],
        title: '',
        addErr: '',
        parentMenu: [],
        profileName: '',
        subMenu: []

    }

    componentDidMount() {
        window.$('#add-custom-profile').hide();
        window.$("#add-custom-profile-btn").click(() => {
            this.setState({ title: '', addErr: '' });
            window.$('#add-custom-profile-btn').hide();
            window.$('#add-custom-profile').show();
        });
        window.$("#add-custom-profile .btn-default").click(function () {
            window.$('#add-custom-profile-btn').show();
            window.$('#add-custom-profile').hide();
        });
        this.getCustomProfileList();
    }

    getCustomProfileList = () => {
        GetData('GetCustomProfileList')
            .then((response) => {
                if (response.success === 1) {
                    response.data.map((item) => {
                        item['active_class'] = ''
                    })
                    this.setState({ profileList: response.data });
                }
            })
    }

    changeValue = (e) => {
        this.setState({ title: e.target.value });
    }

    AddCustomProfile = () => {
        if (this.state.title !== "") {
            PostDataWithParam('AddCustomProfile', { title: this.state.title })
                .then((response) => {
                    if (response.success === 1) {
                        this.getCustomProfileList()
                        window.$('#add-custom-profile-btn').show();
                        window.$('#add-custom-profile').hide();
                        window.swal(
                            'Success!',
                            response.msg,
                            'success'
                        )
                    } else {
                        window.swal(
                            'Error!',
                            response.msg,
                            'error'
                        )
                    }
                })
                .catch(err => {
                    window.swal(
                        'Error!',
                        'Oops! Something went wrong.',
                        'error'
                    )
                })
        } else {
            this.setState({ addErr: 'Please Enter Profile Title' });
        }
    }

    getParentMenu = (custom_profile_id, profile_name) => {
        PostDataWithParam('GetParentMenuListForCustomProfile', { custom_profile_id: custom_profile_id })
            .then((response) => {
                if (response.success === 1) {
                    let plist = this.state.profileList
                    plist.map((profile) => {
                        if (profile.id == custom_profile_id) {
                            profile.active_class = 'active'
                        } else {
                            profile.active_class = ''
                        }
                    })
                    response.data.map((item) => {
                        item['class_name'] = 'icon-plus22'
                    })
                    this.setState({ parentMenu: response.data, profileName: profile_name, profileList: plist });
                } else {
                    window.swal(
                        'Error!',
                        'Oops! Something went wrong.',
                        'error'
                    )
                }
            })
            .catch(err => {
                window.swal(
                    'Error!',
                    'Oops! Something went wrong.',
                    'error'
                )
            })
    }

    getParentMenuAfterStatusUpdate = (custom_profile_id, profile_name, menu_id, class_name) => {
        PostDataWithParam('GetParentMenuListForCustomProfile', { custom_profile_id: custom_profile_id })
            .then((response) => {
                if (response.success === 1) {

                    let plist = this.state.profileList
                    plist.map((profile) => {
                        if (profile.id == custom_profile_id) {
                            profile.active_class = 'active'
                        } else {
                            profile.active_class = ''
                        }
                    })
                    response.data.map((item) => {
                        if (item.menu_id == menu_id) {
                            item['class_name'] = class_name
                        } else {
                            item['class_name'] = 'icon-plus22'
                        }
                    })
                    this.setState({ parentMenu: response.data, profileName: profile_name, profileList: plist },()=>{
                        this.getSubMenuAfterStatusUpdate(custom_profile_id, menu_id, class_name)
                    });
                } else {
                    window.swal(
                        'Error!',
                        'Oops! Something went wrong.',
                        'error'
                    )
                }
            })
            .catch(err => {
                window.swal(
                    'Error!',
                    'Oops! Something went wrong.',
                    'error'
                )
            })
    }

    getSubMenu = (custom_profile_id, id, class_name) => {
        if (class_name == "icon-plus22") {
            PostDataWithParam('GetChildMenuListForCustomProfile', { custom_profile_id: custom_profile_id, parent_id: id })
                .then((response) => {
                    if (response.success === 1) {
                        let pmenus = this.state.parentMenu
                        pmenus.map((pmenu) => {
                            if (pmenu.menu_id == id) {
                                pmenu.sub_menu = response.data
                                pmenu.class_name = 'icon-minus3'
                            }
                        })
                        this.setState({ parentMenu: pmenus });
                    } else {
                        window.swal(
                            'Error!',
                            response.msg,
                            'error'
                        )
                    }
                })
                .catch(err => {
                    window.swal(
                        'Error!',
                        'Oops! Something went wrong.',
                        'error'
                    )
                })
        } else {
            let pmenus = this.state.parentMenu
            pmenus.map((pmenu) => {
                if (pmenu.menu_id == id) {
                    pmenu.sub_menu = []
                    pmenu.class_name = 'icon-plus22'
                }
            })
            this.setState({ parentMenu: pmenus });
        }
    }    

    getSubMenuAfterStatusUpdate = (custom_profile_id, id, class_name) => {
        if (class_name == "icon-minus3") {
            PostDataWithParam('GetChildMenuListForCustomProfile', { custom_profile_id: custom_profile_id, parent_id: id })
            .then((response) => {
                if (response.success === 1) {
                    let pmenus = this.state.parentMenu
                    pmenus.map((pmenu) => {
                        if (pmenu.menu_id == id) {
                            pmenu.sub_menu = response.data
                            pmenu.class_name = "icon-minus3"
                        }
                    })
                    this.setState({ parentMenu: pmenus });
                } else {
                    window.swal(
                        'Error!',
                        response.msg,
                        'error'
                    )
                }
            })
            .catch(err => {
                window.swal(
                    'Error!',
                    'Oops! Something went wrong.',
                    'error'
                )
            })
        } else {
            let pmenus = this.state.parentMenu
            pmenus.map((pmenu) => {
                if (pmenu.menu_id == id) {
                    pmenu.sub_menu = []
                    pmenu.class_name = 'icon-plus22'
                }
            })
            this.setState({ parentMenu: pmenus });
        }
    }
    

    handleStatusUpdate = (status, custom_profile_id, menu_id, class_name) => {
        PostDataWithParam('ActiveDeactiveMenuForCustomProfile', { status: status, custom_profile_id: custom_profile_id, menu_id: menu_id })
            .then((response) => {
                let result = response;
                if (result.success === 1) {
                    if(class_name!="sub_menu_type"){
                        this.getParentMenuAfterStatusUpdate(custom_profile_id, this.state.profileName, menu_id, class_name)
                    }                    
                } else {
                    this.setState({ loginErr: result.msg });
                }
            })
    }

    getCheckedModule = (status, custom_profile_id, menu_id, class_name) => {
        if (status == 1) {
            status = 0;
            return (
                <label className="checkbox-inline checkbox-switch switch-danger">
                    <input type="checkbox" value="" defaultChecked={true} onChange={() => this.handleStatusUpdate(status, custom_profile_id, menu_id, class_name)} /><span></span>
                </label>
            )
        }
        else {
            status = 1;
            return (
                <label className="checkbox-inline checkbox-switch switch-danger">
                    <input type="checkbox" value="" defaultChecked={false} onChange={() => this.handleStatusUpdate(status, custom_profile_id, menu_id, class_name)} /><span></span>
                </label>
            )
        }


    }

    render() {
        return (
            <div className="content-container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="breadcrumb-line breadcrumb-line-component m-b-20">
                            <ul className="breadcrumb">
                                <li><a href="index.html"><i className="icon-meter-slow position-left"></i> Dashboard</a></li>
                                <li className="active">Access Control</li>
                            </ul>
                        </div>
                        <h4>Set Staff Permission</h4>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="panel panel-flat ">
                                    <div className="panel-heading p-b-10">
                                        <h6 className="panel-title text-brown-400">Select Profile</h6>
                                    </div>
                                    <div className="panel-body p-0">
                                        <div className="list-group p-t-0 no-border">
                                            {
                                                this.state.profileList.length != 0
                                                &&
                                                this.state.profileList.map((profile, index) => {
                                                    return (
                                                        <a key={index} href="javascript:void(0)" className={"list-group-item " + profile.active_class} onClick={() => this.getParentMenu(profile.id, profile.title)}>{profile.title}</a>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <a href="javascript:void(0)" className="btn btn-success btn-labeled btn-block" id="add-custom-profile-btn"><b><i className="icon-plus22"></i></b> Add Custom Profile</a>

                                <div className="panel panel-flat m-t-30" id="add-custom-profile">
                                    <div className="panel-heading">
                                        <h6 className="panel-title">Add New Custom Profile</h6>
                                    </div>
                                    <div className="panel-body">
                                        <div className="form-group">
                                            <label>Profile Title</label>
                                            <input type="text" className="form-control" name="titile" value={this.state.title} onChange={this.changeValue} />
                                            <p className="text-danger help-block">{this.state.addErr}</p>
                                        </div>
                                        <button className="btn btn-default pull-left" type="button">Cancel</button>
                                        <button className="btn btn-success pull-right" type="button" onClick={this.AddCustomProfile}>Submit</button>
                                    </div>
                                </div>

                            </div>

                            <div className="col-md-9">
                                <div className="panel panel-flat">
                                    <div className="panel-heading">
                                        <h5 className="m-t-0 m-b-0">{this.state.profileName}</h5>
                                    </div>
                                    <hr/>
                                    <div className="panel-body p-0">
                                        <div className="table-responsive">
                                            {
                                                this.state.parentMenu.map((menu, i) => {
                                                    return (
                                                        <div key={i}>
                                                            <p>
                                                                {menu.menu_name}
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                {this.getCheckedModule(menu.status, menu.custom_profile_id, menu.menu_id, menu.class_name)}
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <a href="javascript:void(0)" className="btn btn-success" onClick={() => this.getSubMenu(menu.custom_profile_id, menu.menu_id, menu.class_name)}><b><i className={menu.class_name}></i></b></a>
                                                            </p>
                                                            <div>
                                                                {
                                                                    menu.sub_menu.length != 0
                                                                    &&
                                                                    menu.sub_menu.map((smenu, j) => {
                                                                        return (
                                                                            <p key={j}>
                                                                                {smenu.menu_name}
                                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                {this.getCheckedModule(smenu.status, smenu.custom_profile_id, smenu.menu_id, 'sub_menu_type')}
                                                                            </p>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            <hr />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default AccessControl;