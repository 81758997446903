import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataWithParam from '../services/PostDataWithParam';
import PostDataFile from '../services/PostDataFile';
import GetData from '../services/GetData';
import ConfirmBox from '../externaljs/ConfirmBox';
import Storage from '../externaljs/Storage';

const initial_field_data = {
   title: '',
   attachment: '',
   subtitle: '',
   inventorytitle: '',
   inventorycubicfeet: '',
   inventoryservicefee: '',
   inventoryattachment: '',
   main_inventory_id: '',
   sub_inventory_id: '',
   main_inventory_name: '',
   sub_inventory_name: ''
}

class Inventory extends Component {

   state = {
      breadcrumb: [
         { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
         { title: 'Manage Items', link: '', class: 'active' }
      ],

      loading: false,
      firstload: false,
      allmaininventory: [],
      allsubinventorybymain: [],
      allinventoryitembycat: [],
      getSubRoomListDetails: [],
      maininventorydetailsbyid: [],
      subinventorydetailsbyid: [],
      inventoryitemdetailsbyid: [],

      inventory_list: [],
      selCustomFilter: '',
      inventory_type: '',
      //new added
      profile_menu: [],
      profile_menu_err: '',
      menu: [],

      fields: {
         ...initial_field_data
      },
      error: {
         title_error: '',
         attachment_error: '',
         subtitle_error: '',
         inventorytitle_error: '',
         inventorycubicfeet_error: '',
         inventoryservicefee_error: '',
         inventoryattachment_error: ''
      },

      tmp_checked_menu: [],
      changeUpdateStatus: false,
      UpdateErr: '',
      staff_user_id: null,
      custom_menu: [],
      permission: false

   }

   componentDidMount() {

      let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
      let custom_menu = [];
      if (staff_user_id != "") {
         custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
      }
      this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
         // console.log(this.state.custom_menu);
         // console.log(this.state.staff_user_id);
      });
      for (var i = 0; i < custom_menu.length; i++) {
         if (custom_menu[i].title == "Inventory") {
            if (custom_menu[i].is_add == 1 || custom_menu[i].is_add == "1")
               this.setState({ permission: true }, () => {
                  // console.log(this.state.permission);
               })
         }
      }

      this.GetMainInventoryList();
   }

   GetMainInventoryList = (sortBy) => {
      this.setState({ loading: true }, () => {
         var that = this;
         PostDataWithParam('GMAMainInventoryList')
            .then((response) => {
               if (response.success == 1) {
                  this.setState({ allmaininventory: response.data, loading: false, selCustomFilter: sortBy }, () => {
                     // DataTableBasic();
                     // console.log(this.state.allmaininventory)
                     DataTableBasic(function () {
                        var searchFilter = window.$('.dataTables_filter input[type=search]');

                        if (searchFilter.length) {
                           var newOptions = [
                              // { 'key': '', value: 'All' },
                              // { 'key': '10', value: 'MyPCSGov' },
                              { 'key': '2', value: 'GetMilitaryApproved' },
                           ];
                           var sel = '<select class="customFilterBy">';
                           window.$(newOptions).each(function (key, txt) {
                              if (that.state.selCustomFilter == txt.key) {
                                 sel += '<option value="' + txt.key + '" selected>' + txt.value + '</option>';
                              } else {
                                 sel += '<option value="' + txt.key + '">' + txt.value + '</option>';
                              }

                           });
                           sel += '</select>';
                           searchFilter.parent().parent().append(sel);
                           window.$(".customFilterBy").select2({
                              minimumResultsForSearch: Infinity,
                              width: 'auto'
                           }).on('select2:select', function (e) {
                              that.removeDataTable();
                              that.GetMainInventoryList(e.params.data.id);
                           });
                        }
                     });
                  });

               } else {
                  this.setState({ allmaininventory: [], loading: false, selCustomFilter: sortBy });
                  // window.swal('Oops!', 'No Items Found', 'info');
               }
            })
            .catch((err) => {
               this.setState({ allmaininventory: [], loading: false, selCustomFilter: sortBy });
               window.swal('Error!', 'Something went wrong', 'error');
            })
      })
   }

   removeDataTable = () => {
      if (window.$.fn.DataTable.isDataTable('#list-table')) {
         window.$('#list-table').DataTable().destroy();
      }

      window.$('#list-table tbody').empty();
   }

   changeValue = (e) => {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.value;
      this.setState({ fields: fields });
   }

   handleAttachment = (e) => {
      let fields = this.state.fields;
      fields[e.target.name] = e.target.files[0];
      this.setState({ fields: fields });
   }

   resetError = (cb) => {
      let error = {
         title_error: '',
         attachment_error: '',
         subtitle_error: '',
         inventorytitle_error: '',
         inventorycubicfeet_error: '',
         inventoryservicefee_error: '',
         inventoryattachment_error: ''
      }
      this.setState({ error: error }, () => {
         cb();
      });
   }

   getSubRoomList = (id) => {
      this.setState({ loading: false }, () => {
         GetData('SubInventoryListByMain/' + id)
            .then((response) => {
               if (response.success === 1) {
                  this.setState({ getSubRoomListDetails: response.data, loading: false });
               } else {
                  this.setState({ getSubRoomListDetails: [], loading: false });
               }
            })
            .catch(err => {
               this.setState({ getSubRoomListDetails: [], loading: false });
               window.swal('Error!', 'Oops! Something went wrong.', 'error');
            })
      });
   }


   getSubInventoryListByMain = (id, key, deletecase = '') => {
      this.setState({ loading: false }, () => {
         GetData('SubInventoryListByMain/' + id)
            .then((response) => {
               if (response.success === 1) {
                  let newsubinvenarray = this.state.allsubinventorybymain.slice(); //creates the clone of the state
                  newsubinvenarray[key] = response.data;
                  this.setState({ allsubinventorybymain: newsubinvenarray, loading: false });
               } else {
                  let newsubinvenarray = this.state.allsubinventorybymain.slice(); //creates the clone of the state
                  newsubinvenarray[key] = [];
                  this.setState({ allsubinventorybymain: newsubinvenarray, loading: false });
               }

               if (deletecase == '') {
                  if (window.$("#accordion" + key).hasClass('in')) {
                     window.$("#accordion" + key).removeClass('in');
                     window.$("#accordion" + key).attr('aria-expanded', 'false');
                     window.$("#accordionanchor" + key).addClass('collapsed');
                     window.$("#accordionanchor" + key).attr('aria-expanded', 'false');
                  } else {
                     window.$("#accordion" + key).addClass('in');
                     window.$("#accordion" + key).attr('aria-expanded', 'true');
                     window.$("#accordionanchor" + key).removeClass('collapsed');
                     window.$("#accordionanchor" + key).attr('aria-expanded', 'true');
                  }
               }

            })
            .catch(err => {
               this.setState({ allsubinventorybymain: [], loading: false });
               window.swal('Error!', 'Oops! Something went wrong.', 'error');
            })
      });
   }

   getInventoryItemListByCat = (mid, sid) => {
      this.setState({ loading: false }, () => {
         GetData('InventoryItemListByCat/' + mid + '/' + sid)
            .then((response) => {
               if (response.success === 1) {
                  this.setState({ allinventoryitembycat: response.data, main_inventory_id: mid, sub_inventory_id: sid, main_inventory_name: response.inventory_cat_values.main_inventory_name, sub_inventory_name: response.inventory_cat_values.sub_inventory_name, loading: false, firstload: true });
               } else {
                  this.setState({ allinventoryitembycat: [], main_inventory_id: mid, sub_inventory_id: sid, main_inventory_name: response.inventory_cat_values.main_inventory_name, sub_inventory_name: response.inventory_cat_values.sub_inventory_name, loading: false, firstload: true });
               }
            })
            .catch(err => {
               this.setState({ allinventoryitembycat: [], loading: false, firstload: true });
               window.swal('Error!', 'Oops! Something went wrong.', 'error');
            })
      });
   }

   AddMainInventory = () => {
      this.resetError(() => {
         let error = this.state.error;
         if (document.getElementById('title').value === '') {
            error.title_error = 'Title is required.';
         }
         //   else if (this.state.fields.attachment === '') {
         //      error.attachment_error = 'Image is required.';
         //   } else if (this.state.fields.attachment.type != 'image/jpeg' && this.state.fields.attachment.type != 'image/jpg' && this.state.fields.attachment.type != 'image/png' && this.state.fields.attachment.type != 'image/gif') {
         //      error.attachment_error = 'Please Select Image';
         //   }
         else {
            let fields = this.state.fields;
            fields.title = document.getElementById('title').value;
            this.setState({ fields: fields });
            let fd = new FormData();
            fd.append('title', this.state.fields.title);
            if (this.state.fields.attachment) { fd.append('attachment', this.state.fields.attachment, this.state.fields.attachment.name); }
            else { fd.append('attachment', ''); }
            console.log(fd);
            PostDataFile('GMAAddMainInventory', fd)
               .then((response) => {
                  if (response.success === 1) {
                     window.$("#addroom-form").trigger("reset");
                     window.$('#addroom').modal('hide');
                     window.swal('Successful!', response.msg, 'success')
                     this.removeDataTable();
                     this.GetMainInventoryList();
                  } else {
                     window.swal('Error!', response.msg, 'error');
                  }
               })
               .catch(err => {
                  window.swal('Error!', 'Oops! Something went wrong.', 'error');
               })
         }
         this.setState({ error: error });
      });
   }

   UpdateMainInventory = () => {
      this.resetError(() => {
         let error = this.state.error;
         if (document.getElementById('edit-title').value === '') {
            error.title_error = 'Title is required.';
         }
         //   else if (this.state.fields.attachment != '' && this.state.fields.attachment.type != 'image/jpeg' && this.state.fields.attachment.type != 'image/jpg' && this.state.fields.attachment.type != 'image/png' && this.state.fields.attachment.type != 'image/gif') {
         //      error.attachment_error = 'Please Select Image';
         //   }
         else {
            let fields = this.state.fields;
            fields.title = document.getElementById('edit-title').value;
            this.setState({ fields: fields });
            let fd = new FormData();
            fd.append('id', document.getElementById('edit_main_inventory_id').value);
            fd.append('title', this.state.fields.title);
            if (this.state.fields.attachment != '') {
               fd.append('attachment', this.state.fields.attachment, this.state.fields.attachment.name);
            }
            PostDataFile('GMAUpdateMainInventory', fd)
               .then((response) => {
                  if (response.success === 1) {
                     window.$("#editroom-form").trigger("reset");
                     window.$('#editroom').modal('hide');
                     window.swal('Successful!', response.msg, 'success')
                     this.removeDataTable();
                     this.GetMainInventoryList();
                  } else {
                     window.swal('Error!', response.msg, 'error');
                  }
               })
               .catch(err => {
                  window.swal('Error!', 'Oops! Something went wrong.', 'error');
               })
         }
         this.setState({ error: error });
      });
   }

   UpdateSubInventory = () => {
      this.resetError(() => {
         let error = this.state.error;
         if (document.getElementById('edit-subtitle').value === '') {
            error.subtitle_error = 'Title is required.';
         }
         else {
            let fields = this.state.fields;
            fields.subtitle = document.getElementById('edit-subtitle').value;
            this.setState({ fields: fields });
            let fd = new FormData();
            fd.append('id', document.getElementById('edit_sub_inventory_id').value);
            fd.append('title', this.state.fields.subtitle);

            PostDataFile('UpdateSubInventory', fd)
               .then((response) => {
                  if (response.success === 1) {
                     window.$("#editsubroom-form").trigger("reset");
                     window.$('#editfilter').modal('hide');
                     window.swal('Successful!', response.msg, 'success')
                     this.removeDataTable();
                     this.GetMainInventoryList();
                  } else {
                     window.swal('Error!', response.msg, 'error');
                  }
               })
               .catch(err => {
                  window.swal('Error!', 'Oops! Something went wrong.', 'error');
               })
         }
         this.setState({ error: error });
      });
   }

   UpdateInventoryItem = () => {
      this.resetError(() => {
         let error = this.state.error;
         if (document.getElementById('edit-inventorytitle').value === '') {
            error.inventorytitle_error = 'Title is required.';
         } else if (document.getElementById('edit-inventorycubicfeet').value === '') {
            error.inventorycubicfeet_error = 'Cubic Feet is required.';
         } else if (document.getElementById('edit-inventoryservicefee').value === '') {
            error.inventoryservicefee_error = 'Extra Service Fee is required.';
         } else if (this.state.fields.inventoryattachment != '' && this.state.fields.inventoryattachment.type != 'image/jpeg' && this.state.fields.inventoryattachment.type != 'image/jpg' && this.state.fields.inventoryattachment.type != 'image/png' && this.state.fields.inventoryattachment.type != 'image/gif') {
            error.inventoryattachment_error = 'Please Select Image';
         }
         else {
            let fields = this.state.fields;
            fields.inventorytitle = document.getElementById('edit-inventorytitle').value;
            fields.inventorycubicfeet = document.getElementById('edit-inventorycubicfeet').value;
            fields.inventoryservicefee = document.getElementById('edit-inventoryservicefee').value;
            this.setState({ fields: fields });
            var main_inventory_id = document.getElementById('edit_inventory_item_main_id').value;
            var sub_inventory_id = document.getElementById('edit_inventory_item_sub_id').value;
            let fd = new FormData();
            fd.append('id', document.getElementById('edit_inventory_item_id').value);
            fd.append('title', this.state.fields.inventorytitle);
            fd.append('cubic_feet', this.state.fields.inventorycubicfeet);
            fd.append('extra_service_fee', this.state.fields.inventoryservicefee);
            if (this.state.fields.inventoryattachment != '') {
               fd.append('attachment', this.state.fields.inventoryattachment, this.state.fields.inventoryattachment.name);
            }

            PostDataFile('UpdateInventoryItem', fd)
               .then((response) => {
                  if (response.success === 1) {
                     window.$("#edit-inventory").trigger("reset");
                     window.$('#edititem').modal('hide');
                     window.swal('Successful!', response.msg, 'success');
                     this.getInventoryItemListByCat(main_inventory_id, sub_inventory_id);
                  } else {
                     window.swal('Error!', response.msg, 'error');
                  }
               })
               .catch(err => {
                  window.swal('Error!', 'Oops! Something went wrong.', 'error');
               })
         }
         this.setState({ error: error });
      });
   }

   AddSubInventory = () => {
      this.resetError(() => {
         let error = this.state.error;
         if (document.getElementById('subtitle').value === '') {
            error.subtitle_error = 'Title is required.';
         }
         else {
            let fields = this.state.fields;
            fields.subtitle = document.getElementById('subtitle').value;
            this.setState({ fields: fields });
            var main_inventory_id = window.$("#addfilter").find("#main_inventory_id").val();
            let fd = new FormData();
            fd.append('title', this.state.fields.subtitle);
            fd.append('main_inventory_id', main_inventory_id);

            PostDataFile('AddSubInventory', fd)
               .then((response) => {
                  if (response.success === 1) {
                     window.$("#addsubroom-form").trigger("reset");
                     window.$('#addfilter').modal('hide');
                     window.swal('Successful!', response.msg, 'success')
                     this.removeDataTable();
                     this.GetMainInventoryList();
                  } else {
                     window.swal('Error!', response.msg, 'error');
                  }
               })
               .catch(err => {
                  window.swal('Error!', 'Oops! Something went wrong.', 'error');
               })
         }
         this.setState({ error: error });
      });
   }

   OpenSubInventoryModal = (main_inventory_id) => {
      this.setState({ main_inventory_id: main_inventory_id });
      window.$('#addfilter').modal('show');
   }

   OpenInventoryItemAddModal = (mid, sid) => {
      this.setState({ main_inventory_id: mid, sub_inventory_id: sid });
      window.$('#additem').modal('show');
   }

   EnLargeInventoryItemImage = (title, image) => {
      this.setState({ inventorytitle: title, inventoryattachment: image });
      window.$('#showimage').modal('show');
   }

   DeleteInventoryItem = (id) => {
      ConfirmBox("You want to delete this inventory item!")
         .then((result) => {
            if (result) {
               this.setState({ loading: false }, () => {
                  GetData('DeleteInventoryItem/' + id)
                     .then((response) => {
                        if (response.success === 1) {
                           window.swal('Successful!', response.msg, 'success');
                           this.getInventoryItemListByCat(response.data.main_inventory_id, response.data.sub_inventory_id);
                        } else {
                           window.swal('Error!', response.msg, 'error');
                        }
                     })
                     .catch(err => {
                        window.swal('Error!', 'Oops! Something went wrong.', 'error');
                     })
               });
            }
         });
   }

   DeleteSubInventory = (subinventory_id, maininventory_id, key) => {
      ConfirmBox("You want to delete this sub inventory!")
         .then((result) => {
            if (result) {
               this.setState({ loading: false }, () => {
                  GetData('DeleteSubInventory/' + subinventory_id)
                     .then((response) => {
                        if (response.success === 1) {
                           window.swal('Successful!', response.msg, 'success');
                           this.getSubInventoryListByMain(maininventory_id, key, 'deletecase');
                        } else {
                           window.swal('Error!', response.msg, 'error');
                        }
                     })
                     .catch(err => {
                        window.swal('Error!', 'Oops! Something went wrong.', 'error');
                     })
               });
            }
         });
   }

   DeleteMainInventory = (id) => {
      ConfirmBox("You want to delete this room!")
         .then((result) => {
            if (result) {
               this.setState({ loading: false }, () => {
                  GetData('GMADeleteMainInventory/' + id)
                     .then((response) => {
                        if (response.success === 1) {
                           window.swal('Successful!', response.msg, 'success');
                           this.removeDataTable();
                           this.GetMainInventoryList();
                        } else {
                           window.swal('Error!', response.msg, 'error');
                        }
                     })
                     .catch(err => {
                        window.swal('Error!', 'Oops! Something went wrong.', 'error');
                     })
               });
            }
         });
   }

   EditMainInventory = (id) => {
      this.setState({ loading: false }, () => {
         GetData('GMAMainInventoryDetails/' + id)
            .then((response) => {
               if (response.success === 1) {
                  this.setState({ maininventorydetailsbyid: response.data, loading: false });
                  window.$('#editroom').modal('show');
               } else {
                  this.setState({ maininventorydetailsbyid: [], loading: false });
                  window.swal('Error!', response.msg, 'error');
               }
            })
            .catch(err => {
               window.swal('Error!', 'Oops! Something went wrong.', 'error');
            })
      });
   }

   EditSubInventory = (id) => {
      this.setState({ loading: false }, () => {
         GetData('SubInventoryDetails/' + id)
            .then((response) => {
               if (response.success === 1) {
                  this.setState({ subinventorydetailsbyid: response.data, loading: false });
                  window.$('#editfilter').modal('show');
               } else {
                  this.setState({ subinventorydetailsbyid: [], loading: false });
                  window.swal('Error!', response.msg, 'error');
               }
            })
            .catch(err => {
               window.swal('Error!', 'Oops! Something went wrong.', 'error');
            })
      });
   }

   EditInventoryItem = (id) => {
      this.setState({ loading: false }, () => {
         GetData('InventoryItemDetails/' + id)
            .then((response) => {
               if (response.success === 1) {
                  this.setState({ inventoryitemdetailsbyid: response.data, loading: false });
                  window.$('#edititem').modal('show');
               } else {
                  this.setState({ inventoryitemdetailsbyid: [], loading: false });
                  window.swal('Error!', response.msg, 'error');
               }
            })
            .catch(err => {
               window.swal('Error!', 'Oops! Something went wrong.', 'error');
            })
      });
   }

   AddInventoryItem = () => {
      this.resetError(() => {
         let error = this.state.error;
         if (document.getElementById('inventorytitle').value === '') {
            error.inventorytitle_error = 'Title is required.';
         } else if (document.getElementById('inventorycubicfeet').value === '') {
            error.inventorycubicfeet_error = 'Cubic Feet is required.';
         } else if (document.getElementById('inventoryservicefee').value === '') {
            error.inventoryservicefee_error = 'Extra Service Fee is required.';
         }
         else if (this.state.fields.inventoryattachment === '') {
            error.inventoryattachment_error = 'Image is required.';
         } else if (this.state.fields.inventoryattachment.type != 'image/jpeg' && this.state.fields.inventoryattachment.type != 'image/jpg' && this.state.fields.inventoryattachment.type != 'image/png' && this.state.fields.inventoryattachment.type != 'image/gif') {
            error.inventoryattachment_error = 'Please Select Image';
         }
         else {
            let fields = this.state.fields;
            fields.inventorytitle = document.getElementById('inventorytitle').value;
            fields.inventorycubicfeet = document.getElementById('inventorycubicfeet').value;
            fields.inventoryservicefee = document.getElementById('inventoryservicefee').value;
            this.setState({ fields: fields });
            var main_inventory_id = window.$("#additem").find("#main_inventory_id").val();
            var sub_inventory_id = window.$("#additem").find("#sub_inventory_id").val();
            let fd = new FormData();
            fd.append('title', this.state.fields.inventorytitle);
            fd.append('cubic_feet', this.state.fields.inventorycubicfeet);
            fd.append('extra_service_fee', this.state.fields.inventoryservicefee);
            fd.append('main_inventory_id', main_inventory_id);
            fd.append('sub_inventory_id', sub_inventory_id);
            fd.append('attachment', this.state.fields.inventoryattachment, this.state.fields.inventoryattachment.name);

            PostDataFile('AddInventoryItem', fd)
               .then((response) => {
                  if (response.success === 1) {
                     window.$("#add-inventory").trigger("reset");
                     window.$('#additem').modal('hide');
                     window.swal('Successful!', response.msg, 'success')
                     this.getInventoryItemListByCat(main_inventory_id, sub_inventory_id);
                  } else {
                     window.swal('Error!', response.msg, 'error');
                  }
               })
               .catch(err => {
                  window.swal('Error!', 'Oops! Something went wrong.', 'error');
               })
         }
         this.setState({ error: error });
      });
   }


   renderStatus = (status) => {
      if (status == 1) {
         return (
            <span className="text-success">Active</span>
         )
      } else if (status == 0) {
         return (
            <td className="text-danger">Inactive</td>
         )
      } else {
         return (
            <td className="text-primary">In Progress</td>
         )

      }
   }

   DeleteProfile = (id) => {
      // console.log(id);
      // return;
      ConfirmBox("You want to delete this Profile!")
         .then((result) => {
            if (result) {
               PostDataWithParam('DeleteCustomProfileForGMA', { id })
                  .then((response) => {
                     if (response.success == 1) {
                        this.removeDataTable();
                        this.GetMainInventoryList();
                        window.swal('Deleted!', response.msg, 'success')
                     }
                     else {
                        window.swal('Error!', response.msg, 'error')
                     }

                  })
                  .catch(err => {
                     window.swal('Error!', 'Something went wrong', 'error')
                  })
            }
         })

   }


   ActiveDeactiveRoom = (id, is_active) => {
      if (is_active == 1) {
         ConfirmBox("You want to deactivate this room!")
            .then((result) => {
               if (result) {
                  PostDataWithParam('GMAActiveInactiveMainRoom', { id: id, is_active: 0 })
                     .then((response) => {
                        let result = response;
                        if (result.success === 1) {
                           this.removeDataTable();
                           this.GetMainInventoryList();
                           window.swal('Deactivated!', 'Room Deactivated Successfully', 'success')
                        } else {
                           window.swal('Deactivated!', result.msg, 'success')
                        }
                     })
                     .catch(err => {
                        window.swal('Error!', 'Something went wrong', 'error')
                     })
               }
            });
      }
      else {
         ConfirmBox("You want to activate this room!")
            .then((result) => {
               if (result) {
                  PostDataWithParam('GMAActiveInactiveMainRoom', { id: id, is_active: 1 })
                     .then((response) => {
                        let result = response;
                        if (result.success === 1) {
                           this.removeDataTable();
                           this.GetMainInventoryList();
                           window.swal('Activated!', 'Room Activated Successfully', 'success')
                        } else {
                           window.swal('Activated!', result.msg, 'success')
                        }
                     })
                     .catch(err => {
                        window.swal('Error!', 'Something went wrong', 'error')
                     })
               }
            });
      }
   }

   handleBtn = (is_active) => {
      if (is_active == 0) {
         return (
            'Activate'
         )
      } else {
         return (
            'Deactivate'
         )

      }
   }

   render() {

      return (
         <React.Fragment>
            <div className="content-container">
               <div className="row">
                  <div className="col-md-12">
                     {Breadcumb(this.state.breadcrumb)}
                     <div className="clearfix">
                        <h5 className="pull-left">Manage Items</h5>

                        {(this.state.staff_user_id == "" || this.state.permission) &&
                           <div className="pull-right">
                              {/* <div className="pull-right"><Link className="btn btn-primary" to="#" onClick={this.onItemClick}><i className="icon-plus2"></i> Add New Room</Link></div> */}
                              <div className="pull-right"><a href="" className="btn btn-primary m-b-15" data-toggle="modal" data-target="#addroom"><i className="icon-plus22"></i> Add New Room</a></div>
                           </div>
                        }

                     </div>
                     <div className="panel panel-flat">

                        {
                           this.state.loading
                           &&
                           <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                        }
                        {
                           !this.state.loading
                           &&
                           this.state.allmaininventory.length !== 0
                           &&
                           <table className="table datatable-basic table-striped" data-button-title="Message" id="list-table">
                              <thead>
                                 <tr>
                                    {/* <th className="text-nowrap">ID</th> */}
                                    <th>Room Name</th>
                                    <th>Status</th>
                                    {(this.state.staff_user_id == "" || this.state.permission) && <th className="text-center">Actions</th>}
                                 </tr>
                              </thead>
                              <tbody>

                                 {this.state.allmaininventory.map((inventory, index) =>

                                    <tr key={index}>
                                       <td style={{ 'width': '500px' }}>
                                          <Link to={"/independent-mover/dashboard/view-inventory-details/" + inventory.id}>{inventory.title}</Link>
                                          {/* <Link to={"/independent-mover/dashboard/view-inventory-details/"+ inventory.id}>Test Room</Link> */}
                                       </td>
                                       <td>{this.renderStatus(inventory.is_active)}</td>
                                       {(this.state.staff_user_id == "" || this.state.permission) &&
                                          <td className="text-center">
                                             <ul className="icons-list">
                                                <li className="dropdown">
                                                   <a href="" className="dropdown-toggle" data-toggle="dropdown">
                                                      <i className="icon-menu9"></i>
                                                   </a>
                                                   <ul className="dropdown-menu dropdown-menu-right">

                                                      <li>
                                                         <a href="#" data-toggle="modal" data-target="#editroom" onClick={() => this.EditMainInventory(inventory.id)}><i className="icon-pencil"></i>Edit Room</a>

                                                      </li>

                                                      <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.ActiveDeactiveRoom(inventory.id, inventory.is_active)} ><i className="icon-user-block"></i>{this.handleBtn(inventory.is_active)} Room</a></li>
                                                      <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.DeleteMainInventory(inventory.id)} ><i className="icon-trash text-danger-100"></i>Delete Room</a></li>

                                                   </ul>
                                                </li>
                                             </ul>
                                          </td>
                                       }
                                    </tr>
                                 )}

                              </tbody>
                           </table>

                        }
                        {
                           !this.state.loading
                           &&
                           this.state.allmaininventory.length === 0
                           &&
                           <div className="alert alert-primary border-none text-center m-15">No Items Found.</div>
                        }

                     </div>


                  </div>
               </div>
            </div>

            {/* Add Item */}

            <div id="addroom" className="modal fade" role="dialog">
               <div className="modal-dialog">
                  <form className="modal-content" id="addroom-form">
                     <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Add New Room</h4>
                     </div>
                     <div className="modal-body">
                        <div className="form-group">
                           <label>Title <span className="text-danger">*</span></label>
                           <input type="text" id="title" className="form-control" name="title" placeholder="Enter Title" onChange={this.changeValue} />
                           <p className="text-danger help-block">{this.state.error.title_error}</p>
                        </div>
                        <div className="form-group">
                           <label>Upload Image </label>
                           <input type="file" id="attachment" name="attachment" onChange={this.handleAttachment} />
                           <p className="text-danger help-block">{this.state.error.attachment_error}</p>
                        </div>
                     </div>
                     <div className="modal-footer">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" onClick={this.AddMainInventory} className="btn btn-primary">Submit</button>
                     </div>
                  </form>
               </div>
            </div>

            {/* Edit */}

            <div id="editroom" className="modal fade" role="dialog">
               <div className="modal-dialog">
                  <form className="modal-content" id="editroom-form">
                     <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Edit New Room</h4>
                     </div>
                     <div className="modal-body">
                        <div className="form-group">
                           <label>Title <span className="text-danger">*</span></label>
                           <input type="text" id="edit-title" name="title" placeholder="Enter Title" className="form-control" defaultValue={this.state.maininventorydetailsbyid.title || ''} onChange={this.changeValue} />
                           <p className="text-danger help-block">{this.state.error.title_error}</p>
                        </div>
                        <div className="form-group">
                           <label>Upload Image </label>
                           <input type="file" id="edit-attachment" name="attachment" onChange={this.handleAttachment} />
                           <p className="text-danger help-block">{this.state.error.attachment_error}</p>
                        </div>
                        {
                           this.state.maininventorydetailsbyid.image &&
                           <img src={this.state.maininventorydetailsbyid.image} className="img-responsive img-circle img-xs" alt="" />
                        }
                     </div>
                     <div className="modal-footer">
                        <input type="hidden" id="edit_main_inventory_id" name="edit_main_inventory_id" value={this.state.maininventorydetailsbyid.id || ''} />
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" onClick={this.UpdateMainInventory}>Submit</button>
                     </div>
                  </form>
               </div>
            </div>


         </React.Fragment>
      );
   }
}

export default Inventory;

