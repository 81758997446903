import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import HeaderMain from './HeaderMain';
import FooterMain from './FooterMain';
class GetMilApv extends Component {
    state = {}

    componentDidMount() { 
        window.$(window).on("load", function () {
            /* Page Scroll to id fn call */
            window.$("#provider-grid a,a[href='#top'],a[rel='m_PageScroll2id']").mPageScroll2id({
                highlightSelector: "#provider-grid a"
            });

            /* demo functions */
            window.$("a[rel='next']").click(function (e) {
                e.preventDefault();
                var to = window.$(this).parent().parent("section").next().attr("id");
                window.$.mPageScroll2id("scrollTo", to);
            });
        });
    }

    render() {
        return (
            <div>
                 <HeaderMain />
                 <div className="banner military-banner">
    <div className="banner-ovelay">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="banner-content text-center text-white">
                        <h1 className="m-0 common-banner-title">Get Military Approved</h1>
                        <p className="m-0 common-banner-title-pera">Join the ELITE Relocation Professionals who SUPPORT Our Military families.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section className="page-body-wrap p-t-40 p-b-40">
    <div className="container">
        <h1 className="m-0 text-uppercase text-sm-center f-s-35 text-xs-center m-b-15">Why Get Military Approved?</h1>
        <div className="row">
            <div className="section-1">
                <div className="col-md-7">
                    <div className="page-content text-sm-center text-xs-center">
                        
                        <p className="m-0 p-b-30">
                            That answer is quite simple, the military is, by far, the largest consumer
                            of relocation services on the planet. Currently, the Department of Defense
                            purchases about 15-20% of all domestic relocation in the United States, at a
                            cost of over $4 BILLION annually. Professional relocation companies, like yours,
                            cannot ignore this reality and continue to diversify their services, without 
                            considering investing in expansion to service the largest client on Earth. 
                        </p>
                        <p className="m-0 p-b-30">
                            Over the years, military moves have gotten a bad rap from the industry.
                            Excuses range from difficult customers to military base access to tough military regulations.
                            We DISAGREE completely, mainly because of lack of education on behalf of the
                            relocation industry with their teams is the biggest reason for these complaints. The 
                            military and their personnel have educated the transferee on exactly what to expect on
                            a relocation. Unfortunately, the relocation industry has not kept up with the changing times,
                            here at GetMilitaryApproved, our unique technology makes gaining knowledge STRESS FREE & EASY!
                        </p>
                        <p className="m-0 p-b-30">Our members gain insight into EXACTLY what the military requires and how to
                            succeed-profit dramatically from that knowledge. This begins with the process to become a SIT-NTS-TSP Approved Service Provider.
                            As we all know, the government rarely makes things easy to understand (see 50,000+ page U.S. Tax Code <img src="front-assets/images/icons/smiling.svg" width="20"/>). GetMilitaryApproved has dissected the Defense Transportation Regulations (DTR) for service provider
                            approval, into an easy to understand platform which allows us to ensure the HIGHEST  success rate of approval.
                        </p>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="page-picture p-b-20">
                        <img src="front-assets/images/military-family.jpg" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="container">
        <div className="row">
            <h3 className="m-0 p-b-20 f-s-25 text-medium text-center">Here's the basics of our patented approval process</h3>
            <div className="col-md-10 col-md-offset-1">

                <div className="page-box m-b-40 m-t-40 p-20">
                    <div className="row">
                        <div className="col-md-1 col-sm-2">
                            <span className="number text-center inline-block">1</span>
                        </div>
                        <div className="col-md-10 col-sm-8">
                            <p>Complete your Company Profile, in its entirety. (This includes uploading 
                                documents, pictures of your facility & equipment, and other needed items).</p>
                        </div>
                        <div className="col-md-1 col-sm-2">
                            <i className="pull-right"><img src="front-assets/images/icons/dark-blue/user.svg" width="45"/></i>
                        </div>
                    </div>
                </div>
                <div className="page-box m-b-40 m-t-40 p-20">
                    <div className="row">
                        <div className="col-md-1 col-sm-2">
                            <span className="number text-center inline-block">2</span>
                        </div>
                        <div className="col-md-10 col-sm-8">
                            <p>We validate all your profile information for errors and work directly with your
                                team to compile exactly what the government needs to EXPEDITE approval of your company.</p>
                        </div>
                        <div className="col-md-1 col-sm-2">
                            <i className="pull-right"><img src="front-assets/images/icons/dark-blue/valid-document.svg" width="45"/></i>
                        </div>
                    </div>
                </div>
                <div className="page-box m-b-40 m-t-40 p-20">
                    <div className="row">
                        <div className="col-md-1 col-sm-2">
                            <span className="number text-center inline-block">3</span>
                        </div>
                        <div className="col-md-10 col-sm-8">
                            <p>We provide a customized, detailed Plan of Action based on your profile information submitted.
                                We advise which approval type you can get immediately and in the future. We want ALL of our
                                members to become TSP’s eventually, due to extreme
                                amount of revenue, profitability, and sustainability for your company. </p>
                        </div>
                        <div className="col-md-1 col-sm-2">
                            <i className="pull-right"><img src="front-assets/images/icons/dark-blue/organize.svg" width="45"/></i>
                        </div>
                    </div>
                </div>
                <div className="page-box m-b-40 m-t-40 p-20">
                    <div className="row">
                        <div className="col-md-1 col-sm-2">
                            <span className="number text-center inline-block">4</span>
                        </div>
                        <div className="col-md-10 col-sm-8">
                            <p>Once all items meet our review standards, we compile the complete
                                approval package to be sent to USTRANSCOM @ Scott AFB. Your team 
                                emails the package and awaits reply. </p>
                            <h3 className="text-center f-s-25">(More on that later<img src="front-assets/images/icons/smiling.svg" width="20"/>)</h3>

                        </div>
                        <div className="col-md-1 col-sm-2">
                            <i className="pull-right"><img src="front-assets/images/icons/dark-blue/review2.svg" width="45"/></i>
                        </div>
                    </div>
                </div>
                <div className="page-box m-b-40 m-t-40 p-20">
                    <div className="row">
                        <div className="col-md-1 col-sm-2">
                            <span className="number text-center inline-block">5</span>
                        </div>
                        <div className="col-md-10 col-sm-8">
                            <p>The military approval team contacts your company
                                with additional questions, our team helps you formulate 
                                responses and meet the requirements needed for approval.</p>
                        </div>
                        <div className="col-md-1 col-sm-2">
                            <i className="pull-right"><img src="front-assets/images/icons/dark-blue/soldier.svg" width="45"/></i>
                        </div>
                    </div>
                </div>
                <div className="page-box m-b-40 m-t-40 p-20">
                    <div className="row">
                        <div className="col-md-1 col-sm-2">
                            <span className="number text-center inline-block">6</span>
                        </div>
                        <div className="col-md-7 col-md-offset-3 col-sm-8">
                            <div className="media">
                                <div className="media-left">
                                    <i><img src="front-assets/images/icons/green/success.svg" width="50" style={{verticalAlign: 'middle'}}/></i>
                                </div>
                                <div className="media-body">
                                    <h3 className="f-s-25 m-0 p-b-4">Great job!!</h3>
                                    <span className="text-green">YOU ARE APPROVED </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1 col-sm-2">
                            <i className="pull-right"><img src="front-assets/images/icons/smiling.svg" width="45"/></i>
                        </div>
                    </div>
                </div>

            </div>
        </div>
</div>
</section>

<div className="page-content-area">
    <div className="page-content-overlay">
        <div className="container">
            <div className="row">

                <div className="col-md-12 p-b-40 page-content-inner">
                    <h3 className="m-0 p-b-15 f-s-25 text-medium">But Wait, There’s More</h3>
                    <p className="">Our commitment to you does NOT end there, our team gives you all the tools to succeed in your new relationship with the military.
                        <br/><b>These include:</b></p>
                    <ul className="listing">
                        <li>Training materials, courses, and videos for your warehouse, drivers, and rest of staff in all areas of what is require and expected of your new LARGE
                            client, the US Military!</li>
                        <li>FREE Email Marketing to other Approved Service Providers, who need your assistance in your local market in the areas of packing, pickup, storage,
                            and hauling opportunities.</li>
                        <li>Networking, Networking, Networking- we bring you into the GetMilitaryApproved family and help you form the long lasting relationships with other providers
                            and government personnel. </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</div>

<div className="banner page-bottom-banner">
    <div className="banner-ovelay">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="banner-content text-center text-white">
                        <p className="f-s-20 p-b-20 m-0">
                            Let the team at GetMilitaryApproved, help you begin the journey to become one of the
                            EXCLUSIVE service providers, to the world’s best military on the face of the Earth. The GetMilitaryApproved 
                            team has the  knowledge and ability to help your relocation company GROW and THRIVE! 
                        </p>
                        <a href="#" className="page-btn bg-red">Become a Member Today<br/>(It&rsquo;s FREE)</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<FooterMain />
</div>
        );
    }
}

export default GetMilApv;