import React, { Component } from 'react';
import PostDataWithParam from '../services/PostDataWithParam';
import { Redirect,Link } from 'react-router-dom';
import GetData from '../services/GetData';
import {FormatSSN} from '../externaljs/FormatSSN';

class SubscriptionPayment extends Component {
    state = {
        modData:{},
        card_name:'',
        card_no:'',
        expiry_month:'',
        expiry_year:'',
        cvv_no:'',
        card_name_err:'',
        card_no_err:'',
        expiry_month_err:'',
        expiry_year_err:'',
        cvv_no_err:'',
        regData:{},
        regStatus:false,
        regErr:'',
        amount:0,
        card_brand:'',
        processing: false,
        moduleId:0,
        title:'',
        price:'',
        description:'',
        image:'',
        subscription_payment_id:''
    }

    componentDidMount(){

        if (typeof this.props.location.state !== 'undefined') {
            this.setState({ moduleId:this.props.location.state.moduleId });
            this.setState({ title:this.props.location.state.title });
            this.setState({ price:this.props.location.state.price });
            this.setState({ description:this.props.location.state.description });
            this.setState({ image:this.props.location.state.image });
        }

        window.$('.cardnnocheck').validateCreditCard((result) => {
            if (result.card_type != null) {
                var card_icon = 'default.svg';
                if (result.card_type.name === 'amex') {
                    card_icon = 'amex.svg';
                } else if (result.card_type.name === 'diners_club_carte_blanche') {
                    card_icon = 'diners.svg';
                } else if (result.card_type.name === 'diners_club_international') {
                    card_icon = 'diners.svg';
                } else if (result.card_type.name === 'jcb') {
                    card_icon = 'jcb.svg';
                } else if (result.card_type.name === 'visa_electron') {
                    card_icon = 'visa.svg';
                } else if (result.card_type.name === 'visa') {
                    card_icon = 'visa.svg';
                } else if (result.card_type.name === 'mastercard') {
                    card_icon = 'mastercard.svg';
                } else if (result.card_type.name === 'discover') {
                    card_icon = 'discover.svg';
                } else if (result.card_type.name === 'maestro') {
                    card_icon = 'maestro.svg';
                }
                this.setState({ card_brand:result.card_type.name });
                window.$('.card-icon img').attr('src', 'assets/images/card_icons_flat/' + card_icon);
            }
        });

        window.$('.cardnnocheck').on('input propertychange paste', function () {
            if (window.$('.cardnnocheck').val().length < 1) {
                window.$('.card-icon img').attr('src', 'assets/images/card_icons_flat/default.svg');
            }
        });

        window.$('a[rel=popover]').popover({
            html: true,
            trigger: 'hover',
            placement: 'right',
            content: function () {
                return '<img width="200" src="' + window.$(this).data('img') + '" />';
            }
        });
    }
    

    changeValue = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleCheckout = () => {       
        if (!this.state.card_name) {
            this.setState({ card_name_err: 'Card name is required.' });
        }  else if (!this.state.card_no) {
            this.setState({ card_name_err: '' });
            this.setState({ card_no_err: 'Card no is required.' });          
        }
        
          else if (document.getElementById('expiry_month').value==="") {
            this.setState({ card_no_err: '' });            
            this.setState({ expiry_month_err: 'Expiry month is required.' });
        } else if (document.getElementById('expiry_year').value==="") {

            this.setState({ expiry_month_err: '' });    
            this.setState({ expiry_year_err: 'Expiry year is required.' });
        } else if (!this.state.cvv_no) {
            this.setState({ expiry_year_err: '' });
            this.setState({ cvv_no_err: 'CVV is required.' });
        } else {     
            this.setState({ card_name_err: '' });
            this.setState({ card_no_err: '' });
            this.setState({ expiry_month_err: '' });   
            this.setState({ expiry_year_err: '' });
            this.setState({ cvv_no_err: '' });

            
            const card = {
                card_name:this.state.card_name,
                card_no:this.state.card_no,
                expiry_month:document.getElementById('expiry_month').value,
                expiry_year:document.getElementById('expiry_year').value,
                cvv_no:this.state.cvv_no,
                amount:this.state.price,
                profile_id:1
            }

            let postData = {...card};
            this.setState({processing: true},()=>{
                PostDataWithParam('Checkout',postData)
                .then( (response) => {
                    let result = response;
                    if(result.success === 1){
                        this.setState({ regErr : result.msg },()=>{
                        this.setState({ regStatus: true });   
                        this.setState({ subscription_payment_id: result.subscription_payment_id });  
                    });                             
                    } else {
                        this.setState({ regErr : result.msg });
                    }
                    this.setState({processing: false});
                })
            })            
        }
    }

    renderSubmitBtn = () => {
        if(this.state.processing){
            return <input type="button" className="btn btn-primary" value="Processing..." disabled={true}/>
        }else{
            return <button type="button" className="btn btn-primary" onClick={this.handleCheckout}>SUBMIT</button>
        }
    }
    
    render() {

        if (this.state.regStatus) {
            return <Redirect  to={{
                pathname: '/independent-mover/dashboard/my-subscription',
                state: {
                     regErr: this.state.regErr,
                     subscription_payment_id:this.state.subscription_payment_id,
                     moduleId:this.state.moduleId
                    }
                }}/>
        }

        return (
            <div className="content-container">
            <div className="row">
                <div className="col-md-12">
                    <div className="breadcrumb-line breadcrumb-line-component m-b-20">
                        <ul className="breadcrumb">
                            <li><a href="index.html"><i className="icon-meter-slow position-left"></i> Dashboard</a></li>
                            <li className="active">Payment Information</li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <form action="login.html" method="post">
                                <div className="panel panel-flat">
                                    <div className="panel-heading">
                                        <h5 className="panel-title">Payment Information</h5>
                                    </div>
                                    <div className="panel-body">

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="control-label">Name Of Card</label>
                                                    <input type="text" className="form-control f-s-14" placeholder="Name Of Card" name="card_name" onChange={this.changeValue}/>
                                                    <p className="text-danger help-block">{this.state.card_name_err}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group card-no-inner">
                                                    <label className="control-label">Card No</label>
                                                    <input type="text" className="form-control cardnnocheck f-s-14" placeholder="Card No" id="card_no" name="card_no" onChange={this.changeValue}/>
                                                    <div className="card-icon">
                                                        <img src="assets/images/card_icons_flat/default.svg"/>
                                                    </div>
                                                    <p className="text-danger help-block">{this.state.card_no_err}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="control-label">Expiry Month</label>
                                                    <select className="form-control f-s-14" name="expiry_month" id="expiry_month" onChange={this.changeValue}>
                                                        <option value="">Expiry Month</option>
                                                        <option value="01">Jan</option>
                                                        <option value="02">Feb</option>
                                                        <option value="03">Mar</option>
                                                        <option value="04">Apr</option>
                                                        <option value="05">May</option>
                                                        <option value="06">Jun</option>
                                                        <option value="07">Jul</option>
                                                        <option value="08">Aug</option>
                                                        <option value="09">Sep</option>
                                                        <option value="10">Oct</option>
                                                        <option value="11">Nov</option>
                                                        <option value="12">Dec</option>
                                                    </select>
                                                    <p className="text-danger help-block">{this.state.expiry_month_err}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="control-label">Expiry Year</label>
                                                    <select className="form-control f-s-14" name="expiry_year" id="expiry_year" onChange={this.changeValue}>
                                                        <option value="" >Expiry Year</option>
                                                        <option value="2019">2019</option>
                                                        <option value="2020">2020</option>
                                                        <option value="2021">2021</option>
                                                        <option value="2022">2022</option>
                                                        <option value="2023">2023</option>
                                                        <option value="2024">2024</option>
                                                        <option value="2025">2025</option>
                                                        <option value="2026">2026</option>
                                                    </select>
                                                    <p className="text-danger help-block">{this.state.expiry_year_err}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group card-no-inner">
                                                    <label className="control-label">CVV</label>
                                                    <input type="text" className="form-control f-s-14" placeholder="CVV" maxLength="3" name="cvv_no" onChange={this.changeValue}/>
                                                    <div className="info-cvv">
                                                        <a className="text-muted" rel="popover" data-img="assets/images/card_icons_flat/security-code.svg"><i className="fa fa-info-circle"></i></a>
                                                    </div>
                                                    <p className="text-danger help-block">{this.state.cvv_no_err}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="panel-footer p-15 text-center">
                                    {this.renderSubmitBtn()}  
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-5">
                            <div className="panel panel-flat">
                                <div className="panel-heading">
                                    <h5 className="panel-title">Department</h5>
                                </div>
                                <div className="panel-body text-center">
                                    <div className="text-blue m-t-10 m-b-30">
                                        <img src={"assets/images/" + this.state.image} height="80" />
                                    </div>
                                    <h6 className="no-margin text-semibold">{this.state.title}</h6>
                                    <p className="text-muted content-group-sm">{this.state.description}</p>
                                    <h3 className="text-success">${this.state.price}</h3>
                                   
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>  );
    }
}


export default SubscriptionPayment;