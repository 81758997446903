import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import GetData from '../services/GetData';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import PostDataWithParam from '../services/PostDataWithParam';
import ConfirmBox from '../externaljs/ConfirmBox';
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataFile from '../services/PostDataFile';

class Client extends Component {
    state = {
        ClientErr: '',
        client_list: [],
        addClientStatus: false,
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Clients', link: '', class: 'active' }
        ],
        import_file:'',

    }

    componentDidMount() {
        if (typeof this.props.location.state !== 'undefined') {
            this.setState({ ClientErr: this.props.location.state.ClientErr });
        }
        this.GetAllClients();
    }

    componentWillUnmount() {
        this.setState({ ClientErr: '' });
    }

    GetAllClients = () => {
        PostDataWithParam('GetAllClients')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ client_list: response.data })
                    DataTableBasic();

                }
            })
    }

    showAlert = (status) => {
        if (status != '') {
            return (
                <div className="alert alert-success">
                    {status}
                </div>
            )
        }
    }

    handleBtn = (status) => {
        if (status == 0) {
            return (
                'Activate'
            )
        } else {
            return (
                'Deactivate'
            )

        }
    }

    handleChange = (e) => {
        let isChecked = e.target.checked;
        if(isChecked){
            PostDataWithParam('GetAllClients',{status:'Inactive'})
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ client_list: response.data })
                    DataTableBasic();

                }
            })
        }else{
            PostDataWithParam('GetAllClients')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ client_list: response.data })
                    DataTableBasic();

                }
            })
        }
      }


    handleaStaffLimit = () => {
        GetData('GetStaffClientHelperAddLimit')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ addClientStatus: true });
                } else {
                    window.swal({
                        title: 'Your staff add limit exceeded.',
                        text: "Please renew your subscription.",
                        type: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    ActiveDeactiveClients = (client_id, status) => {

        if (status == 1) {
            ConfirmBox("You want to deactivate this client!")
            .then((result) => {
                if (result.value) {
                    PostDataWithParam('ActiveDeactiveClients', { client_id: client_id, status: 0 })
                        .then((response) => {
                            let result = response;
                            if (result.success === 1) {
                                this.GetAllClients();
                                window.swal('Deactivated!', 'Client Deactivated Successfully', 'success')
                            } else {
                                window.swal('Deactivated!', result.msg, 'success')
                            }
                        });
                }
            });
        }
        else {
             ConfirmBox("You want to activate this client!")
            .then((result) => {
                if (result.value) {
                    PostDataWithParam('ActiveDeactiveClients', { client_id: client_id, status: 1 })
                        .then((response) => {
                            let result = response;
                            if (result.success === 1) {
                                this.GetAllClients();
                                window.swal('Activated!', 'Client Activated Successfully', 'success')
                            } else {
                                window.swal('Activated!', result.msg, 'success')
                            }
                        });
                }
            });
        }
    }

    handleStatus = (status) => {
        if (status == 0) {
            return (
                <td>
                    Deactive
                </td>
            )
        } else {
            return (
                <td>
                    Active
                </td>
            )

        }
    }

    handleCSVUpload = (e) => {
        this.setState({ import_file: e.target.files[0] },() =>{
            let fd = new FormData();
            fd.append('import_file', this.state.import_file, this.state.import_file.name);
            PostDataFile('ImportExcelForClients', fd)
            .then((response) => {
                if (response.success === 1) {
                    this.GetAllClients();
                    window.swal('Successfull!', 'Client(s) Added Successfully', 'success')
                } else {
                    window.swal('Failed!', 'The E-mail IDs already exists', 'error')
                }
            })
            .catch(err=>{
                this.setState({ ClientErr: 'Oops! Something went wrong.' });
            })
        });
    }


    render() {
        if (this.state.addClientStatus) {
            return <Redirect to={{
                pathname: '/independent-mover/dashboard/addclient'
            }} />
        }
        return (
            <div className="row">
            <div className="col-md-12">
            {Breadcumb(this.state.breadcrumb)}
                <div className="panel panel-flat">
                    <div className="panel-heading">
                        <h5 className="panel-title">Client List</h5>
                        <div className="heading-elements">
                            <label className="checkbox-inline p-l-20 m-r-20">
                                <input type="checkbox" onChange={this.handleChange} />Include inactive
                            </label>
                            <Link to="/independent-mover/dashboard/addclient"   className="btn btn-primary btn-xs m-r-15"><i className="icon-plus22"></i> Add Client</Link>
                            <label className="btn btn-success btn-file btn-xs"><input type="file" onChange={this.handleCSVUpload} name="import_file" /><i className="icon-file-excel"></i> Upload CSV</label>
                        </div>
                    </div>
                    {this.showAlert(this.state.ClientErr)}
                    {
                    this.state.client_list.length !== 0
                    &&
                    <table className="table datatable-basic table-striped">
                        <thead>
                            <tr>
                                <th style={style}>Company Name</th>
                                <th>Office Phone</th>
                                <th>Office Email</th>
                                <th>State License No</th>
                                <th>Contract Status</th>
                                <th>Contract End Date</th>
                                <th className="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.state.client_list.map(list =>
                                <tr id={"#00" + list.id} key={list.id}>
                                <td className="text-nowrap"><Link to={"/independent-mover/dashboard/viewclient/"+list.id } >{list.organization_name}</Link></td>
                                <td>{list.company_contact_no}</td>
                                <td>{list.company_email}</td>
                                <td>{list.state_license_number}</td>
                                <td>{list.contract_status || 'N/A'} </td>
                                <td>{list.contract_end_date || 'N/A'}</td>
                                <td className="text-center">
                                    <ul className="icons-list">
                                        <li className="dropdown">
                                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                <i className="icon-menu9"></i>
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-right">
                                            <li><Link to={"/independent-mover/dashboard/editclient/" +list.id} ><i className="icon-pencil"></i> Edit Client</Link></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                     }
                     {
                         this.state.client_list.length === 0
                         &&
                         <div className="alert alert-primary border-none text-center">No Client Found.</div>
                     }
                </div>
            </div>
        </div>
         );
    }
}

export default Client;

const style = {
    whiteSpace: 'nowrap'
}