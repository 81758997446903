import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataWithParam from '../services/PostDataWithParam';
import GetData from '../services/GetData';
import ConfirmBox from '../externaljs/ConfirmBox';
import DateFormat from '../externaljs/DateFormat';
import Storage from '../externaljs/Storage';
import { getQueryStringToObject } from '../externaljs/UrlHelpers';

const initial_form_data = {
    id: '',
    salutation: '',
    first_name: '',
    last_name: '',
    gender: '',
    email: '',
    alternate_email: '',
    contact_no: '',
    alternate_contact_no: '',
    work_contact_no: '',
    address: '',
    dob: '',
    ssn: '',
    ssn_val: '',
    custom_profile_id: '',
    latitude: '',
    longitude: ''

}


class ManageProfile extends Component {

    state = {
        breadcrumb: [

        ],
        order_list: [],
        loading: false,
        selCustomFilter: '',
        order_type: '',
        //new added
        profile_menu: [],
        profile_menu_err: '',
        menu: [],
        form_data: {
            ...initial_form_data
        },

        error: {
            first_name_err: '',
            last_name_err: '',
            email_err: '',
            contact_no_err: '',
            alternate_contact_no_err: '',
            salutation_err: '',
            dob_err: '',
            ssn_err: '',
            dept_id_err: '',
            address_err: '',
            alternate_email_err: '',
            work_contact_no_err: '',
            home_contact_no_err: '',
        },

        passWordType: true,
        iconEye: true,
        custom_profile: [],

        tmp_checked_menu: [],
        changeUpdateStatus: false,
        UpdateErr: '',
        staff_user_id: null,
        custom_menu: [],
        permission: false,
        profileTitle: ""

    }
    handleBreadcrumb = () => {
        let breadcrumb = [{ title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' }];
        if (this.props.location && this.props.location.search) {
            let profileTitle = getQueryStringToObject(this.props.location.search).profile_title;
            if (profileTitle) {
                this.setState({ breadcrumb, profileTitle });
                breadcrumb.push({ title: `Manage ${profileTitle}`, link: '', class: 'active' })
            }
        }

    }
    componentDidMount() {
        this.handleBreadcrumb();
        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        let custom_menu = [];
        if (staff_user_id != "") {
            custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
        }


        this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
            // console.log(this.state.custom_menu);
            // console.log(this.state.staff_user_id);
        });
        for (var i = 0; i < custom_menu.length; i++) {
            if (custom_menu[i].title == "Manage Team") {
                if (custom_menu[i].is_add == 1 || custom_menu[i].is_add == "1")
                    this.setState({ permission: true }, () => {
                        // console.log(this.state.permission);
                    })
            }
        }
        this.GetProfileData();
        this.initDatePicker('dob');
        this.getCustomProfile();
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.match.params.profile_id != prevProps.match.params.profile_id) {
            this.handleBreadcrumb();
            this.removeDataTable();
            this.GetProfileData();


        }

    }

    getCustomProfile() {
        PostDataWithParam('ViewCustomProfileForGMA', { profile_id: 2 })
            .then((response) => {
                if (response.success == 1) {
                    // console.log('success')
                    this.setState({ custom_profile: response.data }, () => {
                        // console.log(this.state.custom_profile)
                    });
                }
                else {

                }

            })
            .catch(err => {
                // window.swal('Error!', 'Something went wrong', 'error');
            })
    }

    initDatePicker = (name) => {
        // console.log("initDatePicker");
        window.$('.input-group.date').daterangepicker({
            "showDropdowns": true,
            "autoclose": true,
            "singleDatePicker": true,
            "parentEl": ".date-wrapper",
            "maxDate": new Date()
        })
            .on('apply.daterangepicker', (ev, picker) => {
                let form_data = this.state.form_data;
                form_data.dob = picker.startDate.format('MM/DD/YYYY');
                this.setState({ form_data: form_data });
            });
        window.$('.select').select2({
            minimumResultsForSearch: Infinity
        });
        // window.$('.daterangepicker').css('z-index','1600');
    }

    getUserInfo = (user_id) => {

        PostDataWithParam('GetAllStaffs', { user_id: user_id, staff_user_id: this.state.staff_user_id })
            .then((response) => {
                if (response.success === 1) {

                    // console.log(response.data[0])
                    this.setState({ form_data: response.data[0] }, () => {
                        // console.log(this.state.form_data)
                    })
                    window.$('#select_all').prop("checked", false);
                    window.$('#updateManageProfile').modal({
                        backdrop: 'static',
                        keyboard: false,
                    });

                    window.$("#updateManageProfile").modal('show');

                }
                else {
                    console.log('Error')
                }

            })
            .catch(err => {
                this.setState({ changeUpdateStatus: false });
            })
    }

    GetProfileData = (sortBy) => {
        this.setState({ loading: true }, () => {
            let postData = {};
            if (sortBy) {
                if (sortBy == "all")
                    postData = { 'staff_user_id': this.state.staff_user_id };
                else
                    postData = { 'custom_profile_id': this.props.match.params.profile_id, 'staff_user_id': this.state.staff_user_id };
                // postData = {'profile_id':7};
            }

            else {
                if (this.props.match.params.profile_id == "all") {
                    postData = { 'staff_user_id': this.state.staff_user_id };
                }
                else {
                    postData = { 'custom_profile_id': this.props.match.params.profile_id, 'staff_user_id': this.state.staff_user_id };
                }
            }
            var that = this;
            PostDataWithParam('GetAllStaffs ', postData)
                .then((response) => {
                    if (response.success == 1) {
                        this.setState({ order_list: response.data, loading: false, selCustomFilter: sortBy }, () => {
                            // DataTableBasic();
                            // console.log(this.state.order_list)

                            DataTableBasic(function () {
                                var searchFilter = window.$('.dataTables_filter input[type=search]');

                                if (searchFilter.length) {
                                    var newOptions = [
                                        // { 'key': '', value: 'All' },
                                        // { 'key': '10', value: 'MyPCSGov' },
                                        { 'key': '2', value: 'GetMilitaryApproved' },
                                    ];
                                    var sel = '<select class="customFilterBy">';
                                    window.$(newOptions).each(function (key, txt) {
                                        if (that.state.selCustomFilter == txt.key) {
                                            sel += '<option value="' + txt.key + '" selected>' + txt.value + '</option>';
                                        } else {
                                            sel += '<option value="' + txt.key + '">' + txt.value + '</option>';
                                        }

                                    });
                                    sel += '</select>';
                                    searchFilter.parent().parent().append(sel);
                                    window.$(".customFilterBy").select2({
                                        minimumResultsForSearch: Infinity,
                                        width: 'auto'
                                    }).on('select2:select', function (e) {
                                        that.removeDataTable();
                                        that.GetProfileData(e.params.data.id);
                                    });
                                }
                            });


                        });

                    } else {
                        this.setState({ order_list: [], loading: false, selCustomFilter: sortBy });
                        // window.swal('Sorry!', 'No Profiles Found', 'info');
                    }
                })
                .catch((err) => {
                    this.setState({ order_list: [], loading: false, selCustomFilter: sortBy });
                    window.swal('Error!', 'Something went wrong', 'error');
                })
        })
    }

    removeDataTable = () => {
        if (window.$.fn.DataTable.isDataTable('#list-table')) {
            window.$('#list-table').DataTable().destroy();
        }

        window.$('#list-table tbody').empty();
    }


    onItemClick = (e) => {
        this.setState({ form_data: { ...initial_form_data } })
        window.$('#select_all').prop("checked", false);
        window.$('#manageProfile').modal({
            backdrop: 'static',
            keyboard: false,
        });
        // this.getProfileMenuList(2);
        window.$("#manageProfile").modal('show');

    }

    // handleOnChange=(e)=>{
    //     let form_data = Object.assign({},this.state.form_data)
    //     form_data[e.target.name]=e.target.value
    //     this.setState({ form_data: form_data });
    // }

    handleOnChange = (e) => {
        // debugger;
        let form_data = Object.assign({}, this.state.form_data);
        form_data[e.target.name] = e.target.value;
        if (e.target.name == 'contact_no'
            || e.target.name == 'alternate_contact_no'
        ) {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                form_data[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
        }
        if (e.target.name == 'ssn_val') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/)
            if (match) {
                form_data['ssn'] = match[1] + ' ' + match[2] + ' ' + match[3]
                form_data[e.target.name] = 'XXX' + '-' + 'XX' + '-' + match[3]
            }
        }
        if (e.target.name == "salutation") {
            if (e.target.value == "Mr.") {
                form_data['gender'] = "Mr."
            }
            else if (e.target.value == "Ms.") {
                form_data['gender'] = "Ms."
            }
            else {
                form_data['gender'] = "Mrs."
            }
        }

        this.setState({ form_data: form_data }, () => {
            // console.log(this.state.form_data)
        });

    }



    createProfile = (e) => {

        let form_data = { ...this.state.form_data };

        // console.log(form_data);
        // return;
        PostDataWithParam('AddStaffsNew', form_data)
            .then((response) => {
                let result = response;

                if (result.success === 1) {
                    this.removeDataTable();
                    this.GetProfileData("all");
                    window.swal('Success!', 'Custom Staff Created Successfully', 'success').then((willDelete) => {
                        if (willDelete) {
                            this.props.history.push('/independent-mover/dashboard/team-member-details/all');
                        }
                    });

                    // return(
                    //     <Redirect to="/independent-mover/dashboard/team-member-details/all"></Redirect>
                    // )

                } else {

                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error');
            })
    }


    updateProfile = (e) => {
        let form_data = { ...this.state.form_data };
        // form_data.menu=JSON.stringify(this.state.menu.map(item=>({id:item.menu_id, is_add:item.is_add, is_edit:item.is_edit, is_delete:item.is_delete})));
        // console.log(form_data);
        // return;
        PostDataWithParam('UpdateStaffNew', form_data)
            .then((response) => {
                let result = response;

                if (result.success === 1) {
                    this.removeDataTable();
                    this.GetProfileData();
                    window.swal('Success!', 'Custom staff Updated Successfully', 'success').then((willDelete) => {
                        if (willDelete) {
                            this.props.history.push('/independent-mover/dashboard/team-member-details/' + this.props.match.params.profile_id);
                        }
                    });

                } else {

                    window.swal('Error!', response.msg, 'error');
                }
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error');
            })
    }

    initAutocomplete = (e) => {
        e.persist();
        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: ['geocode'] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {

            let place = autocomplete.getPlace();
            let form_data = { ...this.state.form_data };
            form_data.latitude = place.geometry.location.lat();
            form_data.longitude = place.geometry.location.lng();
            form_data.address = window.$('#' + e.target.id)[0].value;
            this.setState({ form_data: form_data }, () => {
                // console.log(this.state.form_data)
            });
        })
    }

    togglePassword() {
        let form_data = Object.assign({}, this.state.form_data);
        if (this.state.passWordType == true) {
            this.setState({ passWordType: false });
            this.setState({ iconEye: false });
            let swap = form_data['ssn'] // masked value
            form_data['ssn'] = form_data['ssn_val']
            form_data['ssn_val'] = swap  // masked value    
        } else {
            this.setState({ passWordType: true });
            this.setState({ iconEye: true });
            let swapssn = form_data['ssn']
            form_data['ssn'] = form_data['ssn_val']  // masked value          
            form_data['ssn_val'] = swapssn
        }
        this.setState({ form_data: form_data });
    }

    renderStatus = (status) => {
        if (status == 1) {
            return (
                <span className="text-success">Active</span>
            )
        } else if (status == 0) {
            return (
                <td className="text-danger">Inactive</td>
            )
        } else {
            return (
                <td className="text-primary"></td>
            )

        }
    }

    DeleteProfile = (user_id) => {
        // console.log(id);
        // return;
        ConfirmBox("You want to delete this Staff!")
            .then((result) => {
                if (result) {
                    PostDataWithParam('DeleteStaff', { user_id })
                        .then((response) => {
                            if (response.success == 1) {
                                this.removeDataTable();
                                this.GetProfileData();
                                window.swal('Deleted!', response.msg, 'success')
                            }
                            else {
                                window.swal('Error!', response.msg, 'error')
                            }

                        })
                        .catch(err => {
                            window.swal('Error!', 'Something went wrong', 'error')
                        })
                }
            })

    }


    ActiveDeactiveProfile = (id, staff_status) => {
        if (staff_status == 1) {
            ConfirmBox("You want to deactivate this staff!")
                .then((result) => {
                    if (result) {
                        PostDataWithParam('ActiveDeactiveStaff', { user_id: id, status: 0 })
                            .then((response) => {
                                let result = response;
                                if (result.success === 1) {
                                    this.removeDataTable();
                                    this.GetProfileData();
                                    window.swal('Deactivated!', 'Staff Deactivated Successfully', 'success')
                                } else {
                                    window.swal('Deactivated!', result.msg, 'success')
                                }
                            })
                            .catch(err => {
                                window.swal('Error!', 'Something went wrong', 'error')
                            })
                    }
                });
        }
        else {
            ConfirmBox("You want to activate this staff!")
                .then((result) => {
                    if (result) {
                        PostDataWithParam('ActiveDeactiveStaff', { user_id: id, status: 1 })
                            .then((response) => {
                                let result = response;
                                if (result.success === 1) {
                                    this.removeDataTable();
                                    this.GetProfileData();
                                    window.swal('Activated!', 'Staff Activated Successfully', 'success')
                                } else {
                                    window.swal('Activated!', result.msg, 'success')
                                }
                            })
                            .catch(err => {
                                window.swal('Error!', 'Something went wrong', 'error')
                            })
                    }
                });
        }
    }

    handleBtn = (staff_status) => {
        if (staff_status == 0) {
            return (
                'Activate'
            )
        } else {
            return (
                'Deactivate'
            )

        }
    }

    render() {

        const { custom_profile } = this.state;

        let renderCustomProfile = custom_profile.length > 0
            && custom_profile.map((profile, i) => {
                return (
                    <option key={i} value={profile.id}>{profile.title}</option>
                )
            }, this);

        return (
            <React.Fragment>
                <div className="content-container">
                    <div className="row">
                        <div className="col-md-12">
                            {Breadcumb(this.state.breadcrumb)}
                            <div className="clearfix">
                                <h4 className="pull-left">{this.state.profileTitle} List</h4>

                                {this.state.staff_user_id == "" &&
                                    <div className="pull-right">
                                        <Link className="btn btn-primary" to="/independent-mover/dashboard/add-other-staff"><i className="icon-plus2"></i> Add Staff</Link>
                                        {/* <div className="pull-right"><Link className="btn btn-primary" to="/independent-mover/dashboard/add-team-member" ><i className="icon-plus2"></i> Add Staff</Link></div> */}
                                    </div>
                                }
                                {
                                    this.state.staff_user_id != "" && this.state.custom_menu.map((menu, i) => {
                                        return (
                                            <div className="pull-right">
                                                {(menu.title == "Manage Team" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                    <div className="pull-right"><Link className="btn btn-primary" to="#" onClick={this.onItemClick}><i className="icon-plus2"></i> Add Staff</Link></div>
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div className="panel panel-flat">

                                {
                                    this.state.loading
                                    &&
                                    <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                                }
                                {
                                    !this.state.loading
                                    &&
                                    this.state.order_list.length !== 0
                                    &&
                                    <table className="table datatable-basic table-striped" data-button-title="Message" id="list-table">
                                        <thead>
                                            <tr>
                                                {/* <th className="text-nowrap">ID</th> */}
                                                <th>Staff Name</th>
                                                {/* <th>Email</th>
                                                <th>Phone No.</th> */}
                                                <th>Status</th>
                                                {this.state.staff_user_id == "" &&
                                                    <th className="text-center">Actions</th>
                                                }
                                                {(this.state.staff_user_id != "" && this.state.permission) &&
                                                    <th className="text-center">Actions</th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* <td>{order.id}</td> */}

                                            {this.state.order_list.map((order, index) =>

                                                <tr key={index}>

                                                    <td style={{ 'width': '300px' }}>
                                                        <Link to={"/independent-mover/dashboard/view-team-member/" + order.user_id}>{order.gender} {order.first_name} {order.last_name}</Link>
                                                    </td>
                                                    {/* <td style={{ 'width': '300px' }}>{order.email}</td>
                                                    <td>{order.contact_no}</td> */}
                                                    {/* <td>{order.associated_with_profile_id=="10"?"MyPCSGov":"GetMilitaryApproved"}</td> */}
                                                    <td>{this.renderStatus(order.staff_status)}</td>
                                                    {(this.state.staff_user_id == "" || this.state.permission) &&
                                                        <td className="text-center">
                                                            {this.state.staff_user_id == "" &&
                                                                <ul className="icons-list">
                                                                    <li className="dropdown">
                                                                        <a href="" className="dropdown-toggle" data-toggle="dropdown">
                                                                            <i className="icon-menu9"></i>
                                                                        </a>
                                                                        <ul className="dropdown-menu dropdown-menu-right">
                                                                            <li>
                                                                                <a onClick={() => this.getUserInfo(order.user_id)}><i className="icon-pencil"></i>Edit Profile</a>
                                                                                {/* data-toggle="modal" data-target="#updateManageProfile" */}
                                                                            </li>
                                                                            <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.ActiveDeactiveProfile(order.user_id, order.staff_status)} ><i className="icon-user-block"></i>{this.handleBtn(order.staff_status)} Profile</a></li>
                                                                            <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.DeleteProfile(order.user_id)} ><span className="glyphicon glyphicon-remove"></span>&nbsp;&nbsp;&nbsp;&nbsp;Delete Profile</a></li>

                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            }
                                                            {
                                                                this.state.staff_user_id != "" && this.state.custom_menu.map((menu, i) => {
                                                                    return (
                                                                        <ul className="icons-list">
                                                                            {(menu.title == "Manage Team" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                                                <li className="dropdown">
                                                                                    <a href="" className="dropdown-toggle" data-toggle="dropdown">
                                                                                        <i className="icon-menu9"></i>
                                                                                    </a>
                                                                                    <ul className="dropdown-menu dropdown-menu-right">
                                                                                        <li>
                                                                                            <a onClick={() => this.getUserInfo(order.user_id)}><i className="icon-pencil"></i>Edit Profile</a>
                                                                                            {/* data-toggle="modal" data-target="#updateManageProfile" */}
                                                                                        </li>
                                                                                        <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.ActiveDeactiveProfile(order.user_id, order.staff_status)} ><i className="icon-user-block"></i>{this.handleBtn(order.staff_status)} Profile</a></li>
                                                                                        <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.DeleteProfile(order.user_id)} ><span className="glyphicon glyphicon-remove"></span>&nbsp;&nbsp;&nbsp;&nbsp;Delete Profile</a></li>

                                                                                    </ul>
                                                                                </li>
                                                                            }
                                                                        </ul>
                                                                    );
                                                                })
                                                            }
                                                        </td>}
                                                </tr>
                                            )}

                                        </tbody>
                                    </table>

                                }
                                {
                                    !this.state.loading
                                    &&
                                    this.state.order_list.length === 0
                                    &&
                                    <div className="alert alert-primary border-none text-center m-15">No Staff Found.</div>
                                }

                            </div>


                        </div>
                    </div>
                </div>

                <div className="modal fade" tabindex="-1" role="dialog" id="manageProfile">
                    <form onSubmit={this.createProfile}>
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                    <h4 className="modal-title">Add Staff Details</h4>
                                </div>
                                <hr className="m-0 m-t-5"></hr>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Salutation <span className="text-danger">*</span> </label>
                                                <select className="form-control" name="salutation" onChange={this.handleOnChange} value={this.state.form_data.salutation} required={true}>
                                                    <option value="">Select</option>
                                                    <option value="Mr.">Mr.</option>
                                                    <option value="Ms.">Ms.</option>
                                                    <option value="Mrs.">Mrs.</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label>First Name <span className="text-danger">*</span></label>
                                                <input className="form-control" type="text" placeholder="Enter First Name" name="first_name" value={this.state.form_data.first_name} onChange={this.handleOnChange} required={true} />
                                                <p className="text-danger help-block">{this.state.error.first_name_err}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label>Last Name <span className="text-danger">*</span></label>
                                                <input className="form-control" type="text" placeholder="Enter Last Name" name="last_name" value={this.state.form_data.last_name} onChange={this.handleOnChange} required={true} />
                                                <p className="text-danger help-block">{this.state.error.last_name_err}</p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Email ID <span className="text-danger">*</span></label>
                                                <input className="form-control" type="email" placeholder="Enter Email Address" name="email" value={this.state.form_data.email} onChange={this.handleOnChange} required={true} />
                                                <p className="text-danger help-block">{this.state.error.email_err}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Phone Number <span className="text-danger">*</span></label>
                                                <input className="form-control" type="text" placeholder="XXX-XXX-XXXX" name="contact_no" value={this.state.form_data.contact_no} onChange={this.handleOnChange} maxLength="12" required={true} />
                                                <p className="text-danger help-block">{this.state.error.contact_no_err}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Alternate Phone Number</label>
                                                <input className="form-control" type="text" placeholder="XXX-XXX-XXXX" name="alternate_contact_no" value={this.state.form_data.alternate_contact_no} onChange={this.handleOnChange} maxLength="12" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Address</label>
                                                {/*  */}
                                                <input className="form-control" placeholder="Enter Address" type="text" id="address" onFocus={this.initAutocomplete} name="address" value={this.state.form_data.address || ''} onChange={this.handleOnChange} />
                                                <p className="text-danger help-block">{this.state.error.address_err}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group date-wrapper">
                                                <label>Date of Birth</label>
                                                <div className="input-group date">
                                                    <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                    <input type="text" className="form-control" placeholder="Select date"
                                                        name="dob" value={this.state.form_data.dob}
                                                        id="dob"
                                                        onChange={this.handleOnChange} autoComplete="off" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>SSN </label>
                                                {/* <span className="fa fa-eye" aria-hidden="true"></span>  */}

                                                <input placeholder="XXX-XX-XXXX" type="text" className="form-control"
                                                    value={this.state.form_data.ssn_val} name="ssn_val"
                                                    onChange={this.handleOnChange} maxLength="9"

                                                />
                                                <span style={{ color: 'black', float: 'right', marginLeft: '-25px', marginTop: '-25px', marginRight: '10px', position: 'relative', zIndex: '2', cursor: 'pointer' }} className={`fa field-icon ${this.state.iconEye ? 'fa-eye' : 'fa-eye-slash'}`} onClick={this.togglePassword.bind(this)}></span>
                                            </div>
                                        </div>
                                    </div>
                                    <h4 className="text-success">Profile</h4>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Select Profile <span className="text-danger">*</span></label>
                                                <select className="form-control" name="custom_profile_id" onChange={this.handleOnChange} value={this.state.form_data.custom_profile_id} required={true}>
                                                    <option value="">Select Profile</option>
                                                    {renderCustomProfile}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer p-t-15">
                                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <button className="btn btn-primary"  >Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                {/* Edit */}

                <div className="modal fade" tabindex="-1" role="dialog" id="updateManageProfile">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title">Edit Staff Details</h4>
                            </div>
                            <hr className="m-0 m-t-5"></hr>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Salutation <span className="text-danger">*</span> </label>
                                            <select className="form-control" name="salutation" onChange={this.handleOnChange} value={this.state.form_data.gender} required={true}>
                                                <option value="">Select</option>
                                                <option value="Mr.">Mr.</option>
                                                <option value="Ms.">Ms.</option>
                                                <option value="Mrs.">Mrs.</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label>First Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter First Name" name="first_name" value={this.state.form_data.first_name} onChange={this.handleOnChange} required={true} />
                                            <p className="text-danger help-block">{this.state.error.first_name_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label>Last Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="Enter Last Name" name="last_name" value={this.state.form_data.last_name} onChange={this.handleOnChange} required={true} />
                                            <p className="text-danger help-block">{this.state.error.last_name_err}</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Email ID <span className="text-danger">*</span></label>
                                            <input className="form-control" type="email" placeholder="Enter Email Address" name="email" value={this.state.form_data.email} onChange={this.handleOnChange} required={true} />
                                            <p className="text-danger help-block">{this.state.error.email_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Phone Number <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" placeholder="XXX-XXX-XXXX" name="contact_no" value={this.state.form_data.contact_no} onChange={this.handleOnChange} maxLength="12" required={true} />
                                            <p className="text-danger help-block">{this.state.error.contact_no_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Alternate Phone Number</label>
                                            <input className="form-control" type="text" placeholder="XXX-XXX-XXXX" name="alternate_contact_no" value={this.state.form_data.alternate_contact_no} onChange={this.handleOnChange} maxLength="12" />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Address</label>
                                            {/*  */}
                                            <input className="form-control" placeholder="Enter Address" type="text" id="edit_address" onFocus={this.initAutocomplete} name="address" value={this.state.form_data.address || ''} onChange={this.handleOnChange} />
                                            <p className="text-danger help-block">{this.state.error.address_err}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date of Birth</label>
                                            <div className="input-group date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control" placeholder="Select date"
                                                    name="dob" value={DateFormat.getFormattedDateTime(this.state.form_data.dob, 'm-d-Y') || "----"}
                                                    id="dob"
                                                    onChange={this.handleOnChange} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label>SSN </label>
                                            

                                            <input placeholder="XXX-XX-XXXX" type="text" className="form-control"
                                                value={this.state.form_data.ssn} name="ssn_val"
                                                onChange={this.handleOnChange} maxLength="9"

                                            />
                                            <span style={{ color: 'black', float: 'right', marginLeft: '-25px', marginTop: '-25px', marginRight: '10px', position: 'relative', zIndex: '2', cursor: 'pointer' }} className={`fa field-icon ${this.state.iconEye ? 'fa-eye' : 'fa-eye-slash'}`} onClick={this.togglePassword.bind(this)}></span>
                                        </div>
                                    </div> */}
                                </div>
                                <h4 className="text-success">Profile</h4>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Select Profile <span className="text-danger">*</span></label>
                                            <select className="form-control" name="custom_profile_id" onChange={this.handleOnChange} value={this.state.form_data.custom_profile_id} required={true} disabled>
                                                <option value="">Select Profile</option>
                                                {renderCustomProfile}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer p-t-15">
                                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.updateProfile}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ManageProfile;

