import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import GetData from '../services/GetData';
import PostDataWithParam from '../services/PostDataWithParam';
import MilitaryConversations from './MilitaryConversations';
import { Prompt } from 'react-router';

class Warehouse extends Component {
    state = {
        fields: {
            size_of_warehouse:'',
            height_of_walls:'',
            construction_type:[]           
        },
        warehouse_id:'',
        tab_id:2,
        saveStatus:false,
        proccesing:false,
        formDirty:false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.$('[data-toggle="tooltip"]').tooltip();
        window.$('[data-popup="popover"]').popover();
        window.$('[data-popup="tooltip"]').tooltip();
        this.getWarehouseDetails();
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields ,formDirty:true});
    }
    getWarehouseDetails = () =>
    {
        GetData('GetWarehouse')
        .then((response) => {
            if (response.success == 1) {
                if(response.data[0].construction_type != null && response.data[0].construction_type != '')
                {
                let construction_type=response.data[0].construction_type.split(',');
                response.data[0].construction_type=construction_type;
                this.setState({ 
                    fields:response.data[0], 
                    warehouse_id: response.data[0].id 
                });
                }
            }
        })
    }

    updateWarehouse = () => {
        
        let fields = Object.assign({}, this.state.fields);
        let construction_type = fields.construction_type.map((item, i) => {
            return { name: item };
        });
        fields['tab_id'] = this.state.tab_id;
        fields['warehouse_id'] = this.state.warehouse_id;
        fields.construction_type = JSON.stringify(construction_type);
        this.setState({proccesing:true});
        PostDataWithParam('SaveWareHouse',fields)
        .then((response) => {
            if (response.success === 1) {
                window.swal('Success!', response.msg,'success')
                this.setState({ saveStatus: true, proccesing:false ,formDirty:false}, () => {
                    this.props.onUpdateApprovalProgress();
                });   
            } else {
                this.setState({ proccesing:false,formDirty:false });
                window.swal('Error!', response.msg,'error')
            }
        })
    }

    openModal = (field_name,tab_id) => {
        this.props.onOpenModal(field_name,tab_id)
    }
    setConstruct(e) {
        let fields = this.state.fields;
        if (fields.construction_type.indexOf(e.target.value) > -1) {
            let construction_type = fields.construction_type.filter((item, i) => {
                if (item == e.target.value)
                    return false;
                else
                    return true;
            });
            fields.construction_type = construction_type;
        } else {
            fields.construction_type.push(e.target.value)
        }

        this.setState({ fields: fields }, () => {
           
        });
    }

    renderAlertMsg = (field_name,tab_id) => {
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];

        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Answer Received</p>
                        </React.Fragment>
                    )
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Waiting for reply</p>
                        </React.Fragment>
                    )
                }
            }
        }
    }
    renderClassName = (field_name,tab_id) => {
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];    

        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return "btn-success "
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return "btn-warning "
                }
            }
        }


    }
    render() {
        if (this.state.saveStatus) {
            return <Redirect  to='/independent-mover/dashboard/military-approved/information-tab/environmental'/>
        }

        return (
            <div className="tab-pane active" id="top-icon-tab2">
                <Prompt
                when={this.state.formDirty}
                message="Please save your data before you leave the page. Otherwise, you will lose the information you entered !"
                />
                <div className="panel panel-flat">
                    <div className="panel-heading">
                        <h5 className="panel-title">Warehouse &nbsp;
                        <a href="javascript:void(0)" data-popup="popover" title="" data-trigger="hover" data-toggle="tooltip" data-content="Facilities may be constructed of any modern building material normally used and generally accepted for the construction of warehouses, transportation terminals, and structures used for similar purposes.Materials may include, but are not limited to, brick, block, concrete, and rolled sheet metal, which are the most commonly used and accepted materials. As a minimum, the inspecting official will review the following areas during the on-site survey:construction, compliance with local building codes and ordinances, door and window closures, weather tightness, and adequacy of security procedures.Warehouses rated as &quot;wood-frame&quot; or &amp;&quot;pole&quot; buildings, as determined by the Insurance Services Office or other cognizant fire insurance rating organization, are not acceptable for the storage of personal property due to the extreme fire hazard associated with this type of construction." data-original-title=""><i className="icon-info22"></i></a>
                        </h5>
                    </div>
                    <div className="panel-body">
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Size of Warehouse in Square Feet</h5>
                            <div className="row">
                                <div className="col-md-12">
                                    <input type="text" className="form-control" name="size_of_warehouse" onChange={this.changeValue} value={this.state.fields.size_of_warehouse || ""}/>
                                </div>
                                <div className="col-md-12">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('size_of_warehouse',this.state.tab_id)} className={this.renderClassName('size_of_warehouse',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('size_of_warehouse',this.state.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Height of Walls in feet</h5>
                            <div className="row">
                                <div className="col-md-12">
                                    <input type="number" className="form-control" name="height_of_walls" onChange={this.changeValue} value={this.state.fields.height_of_walls || ""}/>
                                </div>
                                <div className="col-md-12">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('height_of_walls',this.state.tab_id)} className={this.renderClassName('height_of_walls',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('height_of_walls',this.state.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Construction Type</h5>
                            <div className="row">
                                <div className="col-md-8">
                                <label className="checkbox-inline m-r-20 m-l-0">
                                        <input type="checkbox" name="construction_type" value="Metal" checked={this.state.fields.construction_type.indexOf('Metal') > -1} onChange={this.setConstruct.bind(this)} /><span className=""></span>
                                        Metal
                                    </label>
                                    <label className="checkbox-inline m-r-20 m-l-0">
                                    <input type="checkbox" name="construction_type" value="Brick" checked={this.state.fields.construction_type.indexOf('Brick') > -1} onChange={this.setConstruct.bind(this)} /><span className=""></span>
                                        Brick
                                    </label>
                                    <label className="checkbox-inline m-r-20 m-l-0">
                                    <input type="checkbox" name="construction_type" value="Concrete" checked={this.state.fields.construction_type.indexOf('Concrete') > -1} onChange={this.setConstruct.bind(this)} /><span className=""></span>
                                        Concrete
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('construction_type',this.state.tab_id)} className={this.renderClassName('construction_type',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('construction_type',this.state.tab_id)} 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MilitaryConversations/>
                <div className="text-center">
                {this.state.proccesing? <button className="btn btn-primary" disabled={true}>Processing...</button>: <button className="btn btn-primary" onClick={this.updateWarehouse}>Save &amp; Next</button>} 
                </div>
            </div>
        );
    }
}

export default Warehouse;