import React, { Component } from 'react';
import PostDataWithParam from '../services/PostDataWithParam';
import DateFormat from '../externaljs/DateFormat';
import Breadcumb from '../externaljs/Breadcrumb';
import {Link} from 'react-router-dom';
import ConfirmBox from '../externaljs/ConfirmBox';

class ViewHelper extends Component {
    state = {
        helper_details:[],
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Helpers', link: '/independent-mover/dashboard/helpers', class: '' },
            { title: 'Helper Details', link: '', class: 'active' }
        ]
    }

    componentDidMount() {
        this.GetHelperDetails();
    }

    formatSSN = (ssn) => {
        if (typeof ssn !== 'undefined' && ssn != null) {
            let ssn_new = ssn.replace(/^.{6}/g, '***-**')
            return ssn_new
        } else {
            return '';
        }
    }

    handleBtn = (status) => {
        if (status == 0) {
            return (
                'Activate'
            )
        } else {
            return (
                'Deactivate'
            )

        }
    }

    GetHelperDetails = () => {
        PostDataWithParam('GetHelperDetails', { helper_id: this.props.match.params.helper_id })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ helper_details: response.data[0] });
                }
            })
            .catch(err=>{
                this.setState({ HelperErr: 'Oops! Something went wrong.',addHelperStatus: false });
            })
    }
   
    ActiveDeactiveHelpers = (helper_id, status) => {

        if (status == 1) {
            ConfirmBox("You want to deactivate this helper!")
            .then((result) => {
                if (result.value) {
                    PostDataWithParam('ActiveDeactiveHelpers', { helper_id: helper_id, status: 0 })
                        .then((response) => {
                            let result = response;
                            if (result.success === 1) {
                                this.GetHelperDetails();
                                window.swal('Deactivated!', 'Helper Deactivated Successfully', 'success')
                            } else {
                                window.swal('Deactivated!', result.msg, 'success')
                            }
                        });
                }
            });
        }
        else {
            ConfirmBox("You want to activate this helper!")
            .then((result) => {
                if (result.value) {
                    PostDataWithParam('ActiveDeactiveHelpers', { helper_id: helper_id, status: 1 })
                        .then((response) => {
                            let result = response;
                            if (result.success === 1) {
                                this.GetHelperDetails();
                                window.swal('Activated!', 'Helper Activated Successfully', 'success')
                            } else {
                                window.swal('Activated!', result.msg, 'success')
                            }
                        });
                }
            });
        }
    }
    render() {
        return (
            <div className="row">
    <div className="col-md-12">
    {Breadcumb(this.state.breadcrumb)}
        <div className="panel panel-flat profile-page">
            <div className="panel-heading clearfix">
                <h5 className="panel-title text-success pull-left">{this.state.helper_details.gender} {this.state.helper_details.first_name} {this.state.helper_details.last_name}</h5>
                <div className="heading-elements">
                    <ul className="icons-list pull-right">
                        <li className="dropdown">
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                <i className="icon-menu9"></i>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-right">
                            <li><Link to={"/independent-mover/dashboard/edithelper/"+this.props.match.params.helper_id}><i className="icon-pencil"></i> Edit Helper</Link></li>
                                
                                <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.ActiveDeactiveHelpers(this.state.helper_details.id, this.state.helper_details.status)}><i className="icon-user-block"></i> {this.handleBtn(this.state.helper_details.status)} Helper</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="panel-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <label className="text-bold m-b-0">Driving Licence No:</label>
                                <p  className="m-b-20">{this.state.helper_details.licence_number}</p>
                                {
                              this.state.helper_details.home_contact_no
                                &&
                                <div>
                                <label className="text-bold m-b-0">Home Phone No:</label>
                                <p className="m-b-20">{this.state.helper_details.home_contact_no}</p>
                                    </div>
                                }
                                <label className="text-bold m-b-0">Address:</label>
                                <p className="m-b-20">{this.state.helper_details.address}</p>


                            </div>

                            <div className="col-md-4">
                                <label className="text-bold m-b-0">Vehicle No:</label>
                                <p  className="m-b-20">{this.state.helper_details.vehicle_no}</p>
                                <label className="text-bold m-b-0">Primary Email :</label>
                                <p className="m-b-20">{this.state.helper_details.email}</p>
                                <label className="text-bold m-b-0">Date of Birth :</label>
                                <p className="m-b-20">{DateFormat.getFormattedDateTime(this.state.helper_details.dob,'M d, Y')}</p>




                            </div>
                            <div className="col-md-4">
                            {
                              this.state.helper_details.alternate_email
                                &&
                                <div>
                                <label className="text-bold m-b-0">Recovery Email:</label>
                                <p className="m-b-20">{this.state.helper_details.alternate_email}</p>
                                </div>
                           }
                                <label className="text-bold m-b-0">Phone No:</label>
                                <p className="m-b-20">{this.state.helper_details.contact_no}</p>
                           {
                              this.state.helper_details.ssn
                                &&
                                <div>
                                <label className="text-bold m-b-0">SSN:</label>
                                <p className="m-b-20">{this.formatSSN(this.state.helper_details.ssn)}</p>
                                </div>
                           }
                            </div>
                            <div className="col-md-4">
                                <label className="text-bold m-b-4">Licence Image :</label>
                                <div>
                                    <img src={this.state.helper_details.licence_image} alt="image" width="200" height="100" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
            );
    }
}

export default ViewHelper;

