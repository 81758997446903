import React, { Component } from 'react'
import { Link } from "react-router-dom";
import Breadcumb from '../externaljs/Breadcrumb';
import { DataTableOptional } from '../externaljs/DataTableOptional';
import PostDataWithParam from '../services/PostDataWithParam';
import Storage from '../externaljs/Storage';
import GetData from '../services/GetData';
import DateFormat from '../externaljs/DateFormat';
import Urls from '../externaljs/Urls';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
const database = Urls.firebase.database();

export class ManageOrders extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'ReloOrders', link: '', class: 'active' }
        ],
        order_list: [],
        edcOrderList: [],
        tracking_list: [],
        loading: true,
        tempOrder: {},
        tempTrackOrder: [],
        tracking_nxt_btn: 1,
        fieldDrop4: 'Shipment Being Cleared for Storage',
        fieldDrop3: 'Unloading Started',
        fieldDrop2: 'Shipment Placed in Storage',
        fieldDrop1: 'Shipment Delivered to Residence-Self Storage Unit',
        fielddiv9: '',
        fielddiv10: '',
        fielddiv11: 'Shipment Scheduled for Delivery from Storage',
        fielddiv12: '',
        fielddiv13: '',
        fielddiv14: '',
        selCustomFilter: '',
        order_type: '',
        staff_user_id: '',
        custom_menu: [],
        permission: false,
        editPermission: true,
        rating_review: [],
        tabid: 'manageReloOrders',
        scacName: "",
        isScac: 0,
        booked_driver_user_ids: [],
        loadUserLocation: true,
        locationData: [],
        trackLoading: false,
        staffOfferOrder: 0
    }

    subscriptionCheckInterval = null;

    componentDidMount() {
        this.getCompanyInfo();
        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        let custom_menu = [];
        if (staff_user_id != "") {
            custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
        }
        this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
            // console.log(this.state.custom_menu);
            // console.log(this.state.staff_user_id);
        });
        for (var i = 0; i < custom_menu.length; i++) {
            if (custom_menu[i].title == "ReloOrders") {
                if (custom_menu[i].is_add == 1 || custom_menu[i].is_add == "1")
                    this.setState({ permission: true }, () => {
                        // console.log(this.state.permission);
                    })
            }
        }

        this.subscriptionCheckInterval = setInterval(() => {
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1)
                this.setState({ editPermission: false })
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ editPermission: true })
        }, 1000);
    }
    getCompanyInfo = () => {

        PostDataWithParam('GetCompanyInfo', {})
            .then((response) => {
                if (response.success === 1) {
                    const data = response.data[0];
                    this.setState({ isScac: data.is_scac, scacName: data.scac_name })
                } else {
                    this.setState({ isScac: 0 })
                }
            })
            .catch(err => {
                this.setState({ isScac: 0 })
            })
            .finally(() => {
                this.GetReloOrderList()
            })
    }
    componentWillUnmount() {
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
    }

    doSubscribe = (e) => {
        window.swal({
            title: "Warning!",
            text: "Please upgrade your membership to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }

    GetReloOrderList = (sortBy) => {
        this.setState({ loading: true }, () => {
            let postData = {};
            if (sortBy) {
                postData = {
                    'order_type': sortBy,
                    'staffOfferOrder': this.state.staffOfferOrder,
                    'staff_user_id': this.state.staffOfferOrder == 1 ? this.state.staff_user_id : ''
                };
            }
            else {
                postData = {
                    'staffOfferOrder': this.state.staffOfferOrder,
                    'staff_user_id': this.state.staffOfferOrder == 1 ? this.state.staff_user_id : ''
                };
            }
            PostDataWithParam('GetOrderListForMovers', postData)
                .then((response) => {
                    if (response.success == 1) {
                        this.setState({ order_list: response.data, loading: false, selCustomFilter: sortBy }, () => {
                            DataTableOptional();
                            // window.$('[data-toggle=tooltip]').tooltip();
                            window.$('[data-toggle="tooltip"]').tooltip(
                                {
                                    html: true,
                                }
                            );

                            window.$('.dataTables_paginate').on('click', () => {
                                window.$('[data-toggle="tooltip"]').tooltip(
                                    {
                                        html: true,
                                    }
                                );
                            })
                            window.$('.dataTables_filter').find('input').on('keyup', () => {
                                window.$('[data-toggle="tooltip"]').tooltip(
                                    {
                                        html: true,
                                    }
                                );
                            })
                            var searchFilter = window.$('.dataTables_filter input[type=search]');
                            var that = this;
                            if (searchFilter.length) {
                                var newOptions = [
                                    { 'key': '', value: 'All' },
                                    { 'key': '1', value: 'Military' },
                                    { 'key': '0', value: 'Non-Military' },
                                ];
                                var sel = '<select class="customFilterBy">';
                                window.$(newOptions).each(function (key, txt) {
                                    if (that.state.selCustomFilter == txt.key) {
                                        sel += '<option value="' + txt.key + '" selected>' + txt.value + '</option>';
                                    } else {
                                        sel += '<option value="' + txt.key + '">' + txt.value + '</option>';
                                    }

                                });
                                sel += '</select>';
                                searchFilter.parent().parent().append(sel);
                                window.$(".customFilterBy").select2({
                                    minimumResultsForSearch: Infinity,
                                    width: 'auto'
                                }).on('select2:select', function (e) {
                                    that.removeDataTable();
                                    that.GetReloOrderList(e.params.data.id);
                                });

                                var captionParent = window.$('.datatable-header');
                                if (sortBy !== '0') {
                                    captionParent.append(`
                                <div class="legend-wrap">
                                    <span></span>
                                    Military ReloOrders
                                </div>`);
                                }
                            }
                        });
                    } else {
                        this.setState({ order_list: [], loading: false, selCustomFilter: sortBy });
                        // window.swal('Error!', response.msg, 'error');
                    }
                })
                .catch(err => {
                    this.setState({ order_list: [], loading: false, selCustomFilter: sortBy });
                    // window.swal('Error!', 'Something went wrong', 'error');
                })
        })
    }
    getEDCOrderList = () => {
        this.setState({ loading: true })
        let headers = new Headers();
        headers.append('Content-Type', 'application/json');

        // PostDataWithParam("EDCOrder", {})
        //     .then(res => {
        //         if (res && res.Results) {
        //             this.setState({
        //                 edcOrderList: res.Results.filter((order) => order.SCAC === this.state.scacName),
        //                 loading: false
        //             });
        //             DataTableBasic();
        //         }

        //     })
        //     .catch((err) => {
        //         this.setState({ loading: false })
        //     })

        PostDataWithParam("GetEDCOrderListForMovers", {staffOfferOrder: this.state.staffOfferOrder,
        staff_user_id: this.state.staffOfferOrder == 1 ? this.state.staff_user_id : ''})
            .then(res => {
                if (res.success == 1) {
                    this.setState({ edcOrderList: res.data, loading: false });
                    DataTableBasic();
                    window.$('[data-toggle="tooltip"]').tooltip(
                        {
                            html: true,
                        }
                    );

                    window.$('.dataTables_paginate').on('click', () => {
                        window.$('[data-toggle="tooltip"]').tooltip(
                            {
                                html: true,
                            }
                        );
                    })

                    window.$('.dataTables_filter').find('input').on('keyup', () => {
                        window.$('[data-toggle="tooltip"]').tooltip(
                            {
                                html: true,
                            }
                        );
                    })
                }
                else {
                    this.setState({ edcOrderList: [], loading: false });
                }

            })
            .catch((err) => {
                this.setState({ edcOrderList: [], loading: false })
            })
    }
    GettrackingList = (military_flag) => {
        let postData = { 'flag': military_flag };
        PostDataWithParam('GetAllOrderTrackingStatus', postData)
            .then((response) => {
                if (response.success == 1) {
                    this.setState({ tracking_list: response.data, trackLoading: false }, () => {
                        let tracking_list = this.state.tracking_list;
                        let count = this.state.tempTrackOrder.length;
                        for (let list_index in tracking_list) {
                            for (let track of this.state.tempTrackOrder) {
                                if (track.order_status_field == tracking_list[list_index].id) {
                                    tracking_list[list_index]['track_class'] = "complete";
                                    tracking_list[list_index]['track_date'] = track.order_status_time;
                                    tracking_list[list_index]['track_time'] = track.order_status_time;
                                    tracking_list[list_index]['track_btn_status'] = 1;
                                    tracking_list[list_index]['is_skip'] = track.is_skip;
                                    tracking_list[list_index]['skip_track_class'] = "skip";
                                    // if (tracking_list[list_index].id >= this.state.tracking_nxt_btn) {
                                    //     this.setState({ tracking_nxt_btn: parseInt(tracking_list[list_index].id) + 1 });
                                    // }
                                    if (parseInt(tracking_list[list_index].id) == 19) {
                                        this.setState({ tracking_nxt_btn: 6 });
                                    }
                                    else if (parseInt(tracking_list[list_index].id) == 7) {
                                        if (military_flag == 1)
                                            this.setState({ tracking_nxt_btn: 16 });
                                        else
                                            this.setState({ tracking_nxt_btn: 8 });
                                    }

                                    else if (parseInt(tracking_list[list_index].id) == 1) {
                                        this.setState({ tracking_nxt_btn: 20 });
                                    }
                                    else if (parseInt(tracking_list[list_index].id) == 20) {
                                        this.setState({ tracking_nxt_btn: 2 });
                                    }

                                    else if (parseInt(tracking_list[list_index].id) == 2) {
                                        this.setState({ tracking_nxt_btn: 3 });
                                    }

                                    else if (parseInt(tracking_list[list_index].id) == 16) {
                                        this.setState({ tracking_nxt_btn: 17 });
                                    }
                                    else if (parseInt(tracking_list[list_index].id) == 18 && military_flag == 1) {
                                        this.setState({ tracking_nxt_btn: 8 });
                                    }

                                    else if (parseInt(tracking_list[list_index].id) == 15) {
                                        this.setState({ tracking_nxt_btn: 14 });
                                    }
                                    else if (parseInt(tracking_list[list_index].id) == 5) {
                                        this.setState({ tracking_nxt_btn: 19 });
                                    }
                                    else if (parseInt(tracking_list[list_index].id) == 8) {
                                        this.setState({ tracking_nxt_btn: 12 } || { tracking_nxt_btn: 9 });
                                    }
                                    else if (parseInt(tracking_list[list_index].id) == 12 || parseInt(tracking_list[list_index].id) == 13) {
                                        this.setState({ tracking_nxt_btn: 15 });
                                    }
                                    else if (parseInt(tracking_list[list_index].id) + 1 == 9) {
                                        this.setState({ tracking_nxt_btn: 9 });
                                    }
                                    else {
                                        this.setState({ tracking_nxt_btn: parseInt(tracking_list[list_index].id) + 1 }, () => {
                                            // console.log('@', this.state.tracking_nxt_btn);
                                        });
                                    }

                                }
                            }
                        }

                        if (this.state.tracking_list.length > 0 && this.state.tempTrackOrder.length > 0)
                            tracking_list[count - 1]['track_class'] = "current";

                        this.setState({ tracking_list: tracking_list }, () => {
                            window.$("#tracking-Move").modal('show');

                        });
                    });
                } else {
                    this.setState({ tracking_list: [], trackLoading: false });
                }
            })
            .catch(err => {
                this.setState({ tracking_list: [], trackLoading: false });
            })
    }

    renderStatus = (status, order, military_flag) => {
        if (order.tracking_status == 'Unloading Started / Shipment Being Cleared for Storage') {
            if (order.storage_needed == 1)
                order.tracking_status = 'Shipment Being Cleared for Storage';
            else
                order.tracking_status = 'Unloading Started';
        }
        if (order.tracking_status == 'Shipment Delivered to Residence-Self Storage Unit / Shipment Placed in Storage') {
            if (order.storage_needed == 1)
                order.tracking_status = 'Shipment Placed in Storage';
            else
                order.tracking_status = 'Shipment Delivered to Residence-Self Storage Unit';
        }

        if (status == 0 && this.state.staff_user_id == "") {
            if (this.state.editPermission) {
                return (
                    <div>
                        <span className="text-orange m-r-5" href="" data-toggle="tooltip" data-placement="top" title="Pending"><i className="icon-circle2"></i></span> {order.tracking_status || "--"} {order.order_request_detail_id !== null && order.total_invited_and_booked_users_count > 0 ? <a href="javascript:void(0)" onClick={() => this.setTempOrder(order, military_flag)}>(Update)</a> : ''}
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <span className="text-orange m-r-5" href="" data-toggle="tooltip" data-placement="top" title="Pending"><i className="icon-circle2"></i></span> {order.tracking_status || "--"}
                        {order.order_request_detail_id !== null ? <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe}>(Update)</Link> : ''}
                    </div>
                )
            }
        }

        else if (status == 0 && this.state.staff_user_id != "") {

            if (this.state.editPermission) {
                return (
                    <div>
                        <span className="text-success m-r-5" href="" data-toggle="tooltip" data-placement="top" title="In Progress"><i className="icon-circle2"></i></span> {order.tracking_status || "--"} {order.order_request_detail_id !== null && order.total_invited_and_booked_users_count > 0 ? <a href="javascript:void(0)" onClick={() => this.setTempOrder(order, military_flag)}>{this.state.permission ? "(Update)" : ""}</a> : ''}
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <span className="text-success m-r-5" href="" data-toggle="tooltip" data-placement="top" title="Pending"><i className="icon-circle2"></i></span> {order.tracking_status || "--"}
                        {order.order_request_detail_id !== null ? <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe}>(Update)</Link> : ''}
                    </div>
                )
            }
        }

        else if (status == 1 && this.state.staff_user_id == "") {

            if (this.state.editPermission) {
                return (
                    <div>
                        <span className="text-success m-r-5" href="" data-toggle="tooltip" data-placement="top" title="In Progress"><i className="icon-circle2"></i></span> {order.tracking_status || "--"} {order.order_request_detail_id !== null && order.total_invited_and_booked_users_count > 0 ? <a href="javascript:void(0)" onClick={() => this.setTempOrder(order, military_flag)}>(Update)</a> : ''}
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <span className="text-success m-r-5" href="" data-toggle="tooltip" data-placement="top" title="Pending"><i className="icon-circle2"></i></span> {order.tracking_status || "--"}
                        {order.order_request_detail_id !== null ? <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe}>(Update)</Link> : ''}
                    </div>
                )
            }
        }

        else if (status == 1 && this.state.staff_user_id != "") {

            if (this.state.editPermission) {
                return (
                    <div>
                        <span className="text-success m-r-5" href="" data-toggle="tooltip" data-placement="top" title="In Progress"><i className="icon-circle2"></i></span> {order.tracking_status || "--"} {order.order_request_detail_id !== null && order.total_invited_and_booked_users_count > 0 ? <a href="javascript:void(0)" onClick={() => this.setTempOrder(order, military_flag)}>{this.state.permission ? "(Update)" : ""}</a> : ''}
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <span className="text-success m-r-5" href="" data-toggle="tooltip" data-placement="top" title="Pending"><i className="icon-circle2"></i></span> {order.tracking_status || "--"}
                        {order.order_request_detail_id !== null ? <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe}>(Update)</Link> : ''}
                    </div>
                )
            }
        }

        else if (status == 2 && this.state.staff_user_id == "") {

            if (this.state.editPermission) {
                return (
                    <div>
                        <span className="text-muted m-r-5" href="" data-toggle="tooltip" data-placement="top" title="Closed"><i className="icon-circle2"></i></span> {order.tracking_status || "--"} {order.order_request_detail_id !== null && order.total_invited_and_booked_users_count > 0 ? <a href="javascript:void(0)" onClick={() => this.setTempOrder(order, military_flag)}>(Update)</a> : ''}
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <span className="text-muted m-r-5" href="" data-toggle="tooltip" data-placement="top" title="Pending"><i className="icon-circle2"></i></span> {order.tracking_status || "--"}
                        {order.order_request_detail_id !== null ? <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe}>(Update)</Link> : ''}
                    </div>
                )
            }
        }

        else if (status == 2 && this.state.staff_user_id != "") {

            if (this.state.editPermission) {
                return (
                    <div>
                        <span className="text-success m-r-5" href="" data-toggle="tooltip" data-placement="top" title="In Progress"><i className="icon-circle2"></i></span> {order.tracking_status || "--"} {order.order_request_detail_id !== null && order.total_invited_and_booked_users_count > 0 ? <a href="javascript:void(0)" onClick={() => this.setTempOrder(order, military_flag)}>{this.state.permission ? "(Update)" : ""}</a> : ''}
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <span className="text-success m-r-5" href="" data-toggle="tooltip" data-placement="top" title="Pending"><i className="icon-circle2"></i></span> {order.tracking_status || "--"}
                        {order.order_request_detail_id !== null ? <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe}>(Update)</Link> : ''}
                    </div>
                )
            }
        }
    }

    renderOrderType = (hire_by_user_id) => {
        if (hire_by_user_id === (JSON.parse(Storage.get('imnLoginData'))).id)
            return ''
        else
            return 'accepted'
    }

    removeDataTable = () => {
        if (window.$.fn.DataTable.isDataTable('#list-table')) {
            window.$('#list-table').DataTable().destroy();
        }

        window.$('#list-table tbody').empty();
    }

    setTempOrder = (order, military_flag) => {
        window.$("#tracking-Move").modal('show');
        this.setState({ trackLoading: true, locationData: [] }, () => {
            let booked_driver_user_ids
            if (order.total_booked_users_list.length > 0) {
                booked_driver_user_ids = order.total_booked_users_list.map(item => item.user_id)
                this.setState({ booked_driver_user_ids, locationData: [] })
            }

            let postData = { 'flag': military_flag };
            PostDataWithParam('GetAllOrderTrackingStatus', postData)
                .then((response) => {
                    if (response.success == 1) {


                        this.setState({ tracking_list: response.data });
                    }
                });

            if (order.tracking_status == 'Order Accepted')
                this.setState({ tracking_nxt_btn: 1 });
            if (order.tracking_status == 'Mover Assigning Resources')
                this.setState({ tracking_nxt_btn: 2 });
            if (order.tracking_status == 'Packing Started')
                this.setState({ tracking_nxt_btn: 3 });
            if (order.tracking_status == 'Packing Completed')
                this.setState({ tracking_nxt_btn: 4 });
            if (order.tracking_status == 'Loading / Pickup')
                this.setState({ tracking_nxt_btn: 19 });
            if (order.tracking_status == 'Loading Completed')
                this.setState({ tracking_nxt_btn: 6 })
            if (order.tracking_status == 'Shipment In Transit')
                this.setState({ tracking_nxt_btn: 7 });
            if (order.tracking_status == 'Driver Arrives in Area')
                this.setState({ tracking_nxt_btn: 16 });
            if (order.tracking_status == 'Shipment Being Cleared for Storage')
                this.setState({ tracking_nxt_btn: 9 });
            if (order.tracking_status == 'Shipment Placed in Storage')
                this.setState({ tracking_nxt_btn: 12 });
            if (order.tracking_status == 'Shipment in Storage')
                this.setState({ tracking_nxt_btn: 13 }, () => { console.log('1', this.state.tracking_nxt_btn); });
            if (order.tracking_status == 'Unloading Started')
                this.setState({ tracking_nxt_btn: 12 });
            if (order.tracking_status == "Unloading into SIT Started")
                this.setState({ tracking_nxt_btn: 12 });
            if (order.tracking_status == "Unloading Started at Residence/ Self Storage Unit")
                this.setState({ tracking_nxt_btn: 12 });
            this.setState({ tempOrder: order }, () => {
                PostDataWithParam('TrackOrder', { order_request_detail_id: this.state.tempOrder.order_request_detail_id })
                    .then((response) => {
                        if (response.success == 1) {
                            this.setState({ tempTrackOrder: response.data, loading: false }, () => {
                                this.GettrackingList(military_flag);
                                this.toggleChange(order.storage_needed);

                            });
                        } else {
                            this.setState({ tempTrackOrder: [], loading: false });
                        }
                    })
                    .catch(err => {
                        this.setState({ tempTrackOrder: [], loading: false });
                    })
            });
        })
    }



    changeDoneStatus = (id, title) => {
        let postData = {
            order_request_detail_id: this.state.tempOrder.order_request_detail_id,
            order_status_id: id
        }

        PostDataWithParam('SaveOrderTrackingStatus', postData)
            .then((response) => {
                if (response.success == 1) {
                    window.swal('Success!', response.msg, 'success')
                    this.removeDataTable();
                    if (this.state.tabid === 'manageReloOrders')
                        this.GetReloOrderList();
                    if (this.state.tabid === 'manageEDCOrders')
                        this.getEDCOrderList();
                    // this.GetReloOrderList();
                    window.$("#tracking-Move").modal('hide')

                } else {
                    window.swal('Error!', response.msg, 'error')
                }
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error')
            })
    }

    changeMultipleDoneStatus = (sort_order) => {
        let postData = {
            order_request_detail_id: this.state.tempOrder.order_request_detail_id,
            tracking_status_sort_order_id: sort_order,
            military_flag: this.state.tempOrder.military_flag
        }
        PostDataWithParam('SaveOrderMultipleTrackingStatus', postData)
            .then((response) => {
                if (response.success == 1) {
                    window.swal('Success!', response.msg, 'success')
                    this.removeDataTable();
                    if (this.state.tabid === 'manageReloOrders')
                        this.GetReloOrderList();
                    if (this.state.tabid === 'manageEDCOrders')
                        this.getEDCOrderList();
                    // this.GetReloOrderList();
                    window.$("#tracking-Move").modal('hide')

                } else {
                    window.swal('Error!', response.msg, 'error')
                }
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error')
            })
    }

    skipTrackStatus = (sort_order, skip_status) => {
        let postData = {
            order_request_detail_id: this.state.tempOrder.order_request_detail_id,
            tracking_status_sort_order_id: sort_order,
            military_flag: this.state.tempOrder.military_flag,
            is_skip: skip_status
        }
        PostDataWithParam('SkipOrderTrackingStatus', postData)
            .then((response) => {
                if (response.success == 1) {
                    window.swal('Success!', response.msg, 'success')
                    this.removeDataTable();
                    if (this.state.tabid === 'manageReloOrders')
                        this.GetReloOrderList();
                    if (this.state.tabid === 'manageEDCOrders')
                        this.getEDCOrderList();
                    // this.GetReloOrderList();
                    window.$("#tracking-Move").modal('hide')

                } else {
                    window.swal('Error!', response.msg, 'error')
                }
            })
            .catch(err => {
                window.swal('Error!', 'Something went wrong', 'error')
            })
    }

    toggleChange = (storage_needed) => {


        if (storage_needed == 1) {
            window.$('#drop4').show();

            window.$('#drop3').hide();

            window.$('#drop2').show();

            window.$('#drop1').hide();

            window.$("#div_10").show();
            window.$("#div_11").hide();

            window.$("#div_9").show();
            window.$("#div_12").show();
            window.$("#div_13").hide();
            window.$("#div_14").show();
        }
        else {
            window.$('#drop3').show();

            window.$('#drop4').hide();

            window.$("#div_9").hide();
            window.$("#div_10").hide();
            window.$("#div_11").hide();
            window.$("#div_12").show();
            window.$("#div_13").hide();
            window.$("#div_14").show();
        }


    }

    setOrderType = (e) => {
        e.preventDefault();
        // console.log(e.target.value);
        this.setState({ order_type: e.target.value }, () => {
            this.GetReloOrderList();
        });
    }

    getRatingReview = (id) => {
        window.$("#tracking-Move").modal('hide')
        PostDataWithParam('GetRatingReview', { order_request_detail_id: id })
            .then(response => {
                if (response.success == 1) {
                    this.setState({ rating_review: response.data[0] })
                    window.$("#ratting-give").modal('show')
                }
                else {
                    this.setState({ rating_review: [] })
                    window.$("#ratting-give").modal('show')
                }
            })
            .catch(err => {
                this.setState({ rating_review: [] })
                window.$("#ratting-give").modal('hide')
                console.log(err);
            })
    }

    getLocation = (id) => {
        this.setState({ loadUserLocation: true })
        window.$("#tracking-Move").modal('hide')
        // let user_id = [908, 909]  // For static checking
        if (this.state.booked_driver_user_ids.length == 0) {
            window.swal({
                title: "Sorry!",
                text: "No Booked & Confirmed Pro(s) found",
                className: "subscrip-info-swal",
                icon: ''
            })
        } else {
            let c = 0
            this.state.booked_driver_user_ids.forEach(id => {
                this.setLocation(id)
                c++
            });

            if (c == this.state.booked_driver_user_ids.length) {
                this.setState({ loadUserLocation: false }, () => {
                    window.$("#live-location").modal('show')
                })
            }
        }
    }

    setLocation(id) {
        database.ref(`/user_location/user_${id}`).on("value", (snapshot) => {
            let locationData = snapshot.val()
            if (Object.keys(locationData).length > 0) {
                this.state.locationData.push(locationData)
            }
        })
    }

    handleTabSwitch = (tabid) => {
        this.setState({ tabid });
        if (tabid === 'manageReloOrders')
            this.GetReloOrderList();
        if (tabid === 'manageEDCOrders')
            this.getEDCOrderList();
    }

    showBookedPros(total_booked_users_list) {
        if (total_booked_users_list.length > 0) {
            let booked_driver = total_booked_users_list.map((item, i) => {
                return (
                    `<div align="left" style="margin-bottom:5px">
                        <img className="media-object" src=${item.profile_image} alt="..." width="20" />
                            ${item.full_name}
                        </div>`
                )
            })
            return booked_driver.join("")
        } else {
            return `<span>No pro(s) found</span>`
        }
    }

    changeOfferOrderStatus = (e) => {
        if (e.target.checked) {
            this.setState({ staffOfferOrder: 1 }, () => {
                if (this.state.tabid === 'manageReloOrders')
                    this.GetReloOrderList();
                if (this.state.tabid === 'manageEDCOrders')
                    this.getEDCOrderList();
            })
        }
        else {
            this.setState({ staffOfferOrder: 0 }, () => {
                if (this.state.tabid === 'manageReloOrders')
                    this.GetReloOrderList();
                if (this.state.tabid === 'manageEDCOrders')
                    this.getEDCOrderList();
            })
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="panel-header bg-white border-radius-3px">
                        <ul className="nav nav-tabs nav-tabs-bottom nav-horizantal-scroll m-b-0">

                            <li className={(this.state.tabid === 'manageReloOrders' ? 'active' : '')}>
                                <Link to="#" data-toggle="tab" aria-expanded="true" name="military_order" onClick={() => this.handleTabSwitch('manageReloOrders')}>
                                    <h6>Relo Orders</h6>
                                </Link>

                            </li>
                            {this.state.isScac == 1 && <li className={(this.state.tabid === 'manageEDCOrders' ? 'active' : '')}>

                                <Link to="#" data-toggle="tab" aria-expanded="true" name="non_military_order" onClick={() => this.handleTabSwitch('manageEDCOrders')}>
                                    <h6>EDC Orders</h6>
                                </Link>

                            </li>}
                            {!!this.state.staff_user_id &&
                                <li style={{ float: 'right' }}>
                                    <label><b>All Orders</b></label>{' '}
                                    <label className="checkbox-inline checkbox-switch switch-danger">
                                        <small className="m-r-5 text-muted"></small>
                                        <input type="checkbox" onChange={(e) => { this.changeOfferOrderStatus(e) }} /><span></span>
                                        <small className="text-muted"></small>
                                    </label>
                                    {' '}<label><b>My Orders</b></label>
                                </li>
                            }
                        </ul>

                    </div>
                    {this.state.tabid === "manageReloOrders" && <>
                        <div className="clearfix bg-white pl-10 pr-10">
                            {
                                this.state.staff_user_id == "" &&
                                <div className="pull-right">
                                    {this.state.editPermission ?
                                        <Link to="/independent-mover/dashboard/assign-offer-job" className="btn btn-primary  mt-5"><i className="icon-plus2"></i> Create New ReloOrder</Link>
                                        :
                                        <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-primary mt-5" onClick={this.doSubscribe}><i className="icon-plus2"></i> Create New ReloOrder</Link>
                                    }
                                </div>
                            }

                            {
                                this.state.staff_user_id != "" && this.state.custom_menu.map((menu, i) => {
                                    return (
                                        <div>
                                            {
                                                (menu.title == "ReloOrders" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                                <div className="pull-right">
                                                    {this.state.editPermission ?
                                                        <Link to="/independent-mover/dashboard/assign-offer-job" className="btn btn-primary  mt-5"><i className="icon-plus2"></i> Create New ReloOrder</Link>
                                                        :
                                                        <Link to="/independent-mover/dashboard/my-subscription" className="btn btn-primary  mt-5" onClick={this.doSubscribe}><i className="icon-plus2"></i> Create New ReloOrder</Link>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    );
                                })
                            }
                            <h4 className="pull-left">Manage ReloOrders</h4>
                        </div>

                        <div className="panel panel-flat">

                            {
                                this.state.loading
                                &&
                                <div className="well text-center m-10"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                            }
                            {
                                !this.state.loading
                                &&
                                this.state.order_list.length !== 0
                                &&
                                <div>
                                    <table className="table datatable-basic" id="list-table">
                                        <thead>
                                            <tr>
                                                {/* table started  */}
                                                <th style={{ whiteSpace: 'nowrap', display: 'none' }}>ID</th>
                                                <th style={{ whiteSpace: 'nowrap' }}>Relo#/GBL#</th>
                                                <th style={{ whiteSpace: 'nowrap' }}>Posted Date</th>
                                                <th style={{ whiteSpace: 'nowrap' }}>Customer Name</th>
                                                <th style={{ whiteSpace: 'nowrap' }}>From City,<br /> State</th>
                                                <th style={{ whiteSpace: 'nowrap' }}>To City, <br />State</th>
                                                <th style={{ whiteSpace: 'nowrap' }}>Weight </th>
                                                <th style={{ whiteSpace: 'nowrap' }}>Current Status</th>
                                                <th>Service Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                this.state.order_list.map((order, index) => {
                                                    return (
                                                        <tr key={index} className={order.military_flag == '1' ? 'military-row' : ''}>
                                                            <td className="" style={{ display: 'none' }}>{order.id}</td>
                                                            <td>{this.state.editPermission ?
                                                                <Link
                                                                    to={{
                                                                        pathname: '/independent-mover/dashboard/order-details',
                                                                        state: {
                                                                            id: order.id,
                                                                            type: this.renderOrderType(order.hire_by_user_id),
                                                                            request_from: order.request_from
                                                                        }
                                                                    }}>#{order.order_id}</Link>
                                                                :
                                                                <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe}>#{order.order_id}</Link>
                                                            }
                                                            </td>
                                                            <td className="text-nowrap">{order.posted_date ? DateFormat.getFormattedDateTime(order.posted_date, 'm-d-Y') : '---'}</td>
                                                            <td className="text-nowrap">{order.customer_name}</td>
                                                            <td className="text-nowrap">{order.primary_pickup_city} <div>{order.primary_pickup_state != null ? '' : ''} {order.primary_pickup_state} </div></td>
                                                            <td className="text-nowrap">{order.primary_destination_city} <div> {order.primary_destination_state != null ? '' : ''} {order.primary_destination_state} </div></td>
                                                            {/* {<td className="text-nowrap">{order.request_from == "own" ? "self" : order.request_from}</td>
                                                            <td>{DateFormat.getFormattedDateTime(order.request_start_date, 'm-d-Y h:i a')}</td>} */}
                                                            <td className="text-nowrap">{order.weight_of_shipment}</td>
                                                            <td>
                                                                <span className="m-r-5"
                                                                    data-toggle="tooltip"
                                                                    title={`<b>Booked & Confirmed: </b><br />${this.showBookedPros(order.total_booked_users_list)}`}
                                                                    data-placement="bottom"
                                                                >{this.renderStatus(order.status, order, order.military_flag)}</span>
                                                            </td>
                                                            <td>{order.service_date ? DateFormat.getFormattedDateTime(order.service_date, 'm-d-Y') : '---'}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                            {
                                !this.state.loading
                                &&
                                this.state.order_list.length === 0
                                &&
                                <div className="panel-body">
                                    <div className="alert alert-primary border-none text-center m-b-0">No Orders Found.</div>
                                </div>
                            }
                        </div>
                    </>}

                    {this.state.tabid === "manageEDCOrders" && <>
                        <div className="clearfix bg-white pl-10 pr-10">
                            <h4 className="pull-left">Manage EDC Orders</h4>
                        </div>

                        <div className="panel panel-flat">

                            {
                                this.state.loading
                                &&
                                <div className="well text-center m-10"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                            }
                            {
                                !this.state.loading
                                &&
                                this.state.edcOrderList.length !== 0
                                &&
                                <div>
                                    <table className="table datatable-basic" id="list-table">
                                        <thead>
                                            <tr>
                                                <th style={{ whiteSpace: 'nowrap' }}>Shipment ID</th>
                                                <th style={{ whiteSpace: 'nowrap' }}>Booked Date</th>
                                                <th style={{ whiteSpace: 'nowrap' }}>Customer Name</th>
                                                <th style={{ whiteSpace: 'nowrap' }}>From City, State</th>
                                                <th style={{ whiteSpace: 'nowrap' }}>To City, State</th>
                                                <th style={{ whiteSpace: 'nowrap' }}>Current Status</th>
                                                <th>Service Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.edcOrderList.map((order, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="text-nowrap">{this.state.editPermission ?
                                                                <Link
                                                                    to={{
                                                                        pathname: '/independent-mover/dashboard/order-details',
                                                                        state: {
                                                                            id: order.id,
                                                                            type: this.renderOrderType(order.hire_by_user_id),
                                                                            request_from: order.request_from
                                                                        }
                                                                    }}
                                                                >#{order.order_id}</Link>
                                                                :
                                                                <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe}>#{order.ShipmentId}</Link>
                                                            }
                                                            </td>
                                                            <td>{window.moment(order.posted_date).format("MM-DD-YYYY h:mm:ss")}</td>
                                                            <td className="text-nowrap">{order.customer_name}</td>
                                                            <td className="text-nowrap">{order.primary_pickup_city} <div>{order.primary_pickup_state != null ? '' : ''} {order.primary_pickup_state} </div></td>
                                                            <td className="text-nowrap">{order.primary_destination_city} <div> {order.primary_destination_state != null ? '' : ''} {order.primary_destination_state} </div></td>
                                                            <td className="text-nowrap">{order.weight_of_shipment}</td>
                                                            {/* <td>{order.tracking_status || 'In Progress'}</td> */}
                                                            <td>
                                                                <span className="m-r-5"
                                                                    data-toggle="tooltip"
                                                                    title={`<b>Booked & Confirmed: </b><br />${this.showBookedPros(order.total_booked_users_list)}`}
                                                                    data-placement="bottom"
                                                                >
                                                                    {this.renderStatus(order.status, order, order.military_flag)}
                                                                </span>
                                                            </td>
                                                            <td>{order.service_date ? DateFormat.getFormattedDateTime(order.service_date, 'm-d-Y') : '---'}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                            {
                                !this.state.loading
                                &&
                                this.state.edcOrderList.length === 0
                                &&
                                <div className="panel-body">
                                    <div className="alert alert-primary border-none text-center m-b-0">No EDC Orders Found.</div>
                                </div>
                            }
                        </div>
                    </>}
                </div>

                <div id="tracking-Move" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Move Tracking</h4>
                            </div>
                            <hr className="m-t-25 m-b-0" />
                            <div className="modal-body alpha-grey p-0">
                                {this.state.trackLoading ?
                                    <div className="well text-center m-10"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                                    :
                                    <div className="tracking-timeline">
                                        <ul>
                                            {
                                                this.state.tracking_list.map((item, i) => {
                                                    return (
                                                        <li className={item.is_skip == 1 ? item.skip_track_class : item.track_class || ""} key={i} id={"div_" + item.id}>

                                                            {
                                                                item.id == 13
                                                                &&
                                                                (item.track_class == "complete" || item.track_class == "current")
                                                                &&
                                                                <div data-date={(item.track_date) ? DateFormat.getFormattedDateTime(item.track_date, 'm-d-Y') : ""} data-time={(item.track_date) ? DateFormat.getFormattedDateTime(item.track_date, 'H:i a') : ""}>

                                                                    {(item.id == 8)
                                                                        ?
                                                                        <div>
                                                                            <span id="drop3">{item.title.split("/")[0]}</span>

                                                                            <span id="drop4">{item.title.split("/")[1]}</span>

                                                                        </div>
                                                                        :
                                                                        <div></div>
                                                                    }
                                                                    {(item.id == 9)
                                                                        ?
                                                                        <div>
                                                                            <span id="drop1">{item.title.split("/")[0]}</span>
                                                                            <span id="drop2">{item.title.split("/")[1]}</span>

                                                                        </div>
                                                                        :
                                                                        <div></div>
                                                                    }
                                                                    {
                                                                        (item.id != 8 && item.id != 9)
                                                                            ?
                                                                            (item.title == 'Shipment Scheduled for Delivery from Storage')
                                                                                ?
                                                                                (this.state.fielddiv11)
                                                                                :
                                                                                (item.title)
                                                                            :
                                                                            <div></div>
                                                                    }</div>
                                                            }
                                                            {
                                                                item.id != 13
                                                                &&
                                                                <div data-date={(item.track_date) ? DateFormat.getFormattedDateTime(item.track_date, 'm-d-Y') : ""} data-time={(item.track_date) ? DateFormat.getFormattedDateTime(item.track_date, 'H:i a') : ""}>

                                                                    {(item.id == 8)
                                                                        ?
                                                                        <div>
                                                                            <span id="drop3">{item.title.split("/")[0]}</span>

                                                                            <span id="drop4">{item.title.split("/")[1]}</span>

                                                                        </div>
                                                                        :
                                                                        <div></div>
                                                                    }

                                                                    {(item.id == 9)
                                                                        ?
                                                                        <div>
                                                                            <span id="drop1">{item.title.split("/")[0]}</span>
                                                                            <span id="drop2">{item.title.split("/")[1]}</span>

                                                                        </div>
                                                                        :
                                                                        <div></div>
                                                                    }
                                                                    {
                                                                        (item.id != 8 && item.id != 9)
                                                                            ?
                                                                            (item.title == 'Shipment Scheduled for Delivery from Storage')
                                                                                ?
                                                                                (this.state.fielddiv11)
                                                                                :
                                                                                (item.title)
                                                                            :
                                                                            <div></div>
                                                                    }</div>
                                                            }

                                                            {
                                                                item.id == 6
                                                                &&
                                                                <React.Fragment>
                                                                    <a href="javascript:void(0)" className="f-s-14" onClick={(e) => this.getLocation(item.id)}><i className="icon-location4"></i> Live View Driver Location</a>
                                                                    <br />
                                                                </React.Fragment>
                                                            }
                                                            {
                                                                item.id == 14
                                                                &&
                                                                <React.Fragment>
                                                                    <a href="javascript:void(0)" className="f-s-14" onClick={(e) => this.getRatingReview(item.id)}><i className="icon-file-eye2"></i> View Rating/Review</a>
                                                                    <br />
                                                                </React.Fragment>
                                                            }

                                                            {
                                                                this.state.tracking_nxt_btn == item.id
                                                                &&
                                                                item.id != 13 && item.id != 14
                                                                // && item.id != 15
                                                                &&
                                                                <React.Fragment>
                                                                    <a href="javascript:void(0)" className="btn btn-success btn-xs btn-labeled" onClick={() => this.changeDoneStatus(item.id, item.title)}><b><i className="icon-check2"></i></b>Done</a>
                                                                    <span>{' '}</span>
                                                                    <a href="javascript:void(0)" className="btn btn-warning btn-xs btn-labeled" onClick={() => this.skipTrackStatus(item.sort_order, 1)}><b><i className="fa fa-step-forward"></i></b>Skip</a>
                                                                </React.Fragment>
                                                            }
                                                            {/* {JSON.stringify(item.id)}
                                                        {JSON.stringify(this.state.tracking_nxt_btn)} */}

                                                            {
                                                                ((item.id != 13 && item.id != 14) && (item.track_btn_status != 1) && (this.state.tracking_nxt_btn != item.id))
                                                                &&
                                                                <React.Fragment>
                                                                    <a href="javascript:void(0)" className="btn btn-success btn-xs btn-labeled" onClick={() => this.changeMultipleDoneStatus(item.sort_order)}><b><i className="icon-check2"></i></b>Done</a>
                                                                    <span>{' '}</span>
                                                                    <a href="javascript:void(0)" className="btn btn-warning btn-xs btn-labeled" onClick={() => this.skipTrackStatus(item.sort_order, 1)}><b><i className="fa fa-step-forward"></i></b>Skip</a>
                                                                </React.Fragment>
                                                            }

                                                            {(item.is_skip == 1) ?
                                                                <React.Fragment>
                                                                    <br /><small className="text-warning"><i className="fa fa-step-forward"></i> Skipped</small>
                                                                </React.Fragment>
                                                                :
                                                                (item.track_btn_status == 1)
                                                                    ?
                                                                    <React.Fragment>
                                                                        <br /><small className="text-success"><i className="icon-check2"></i> Done</small>
                                                                    </React.Fragment>
                                                                    :
                                                                    ''
                                                            }
                                                        </li>
                                                    )
                                                })
                                            }

                                        </ul>
                                    </div>
                                }
                            </div>
                            <hr className="m-0" />
                            {!this.state.trackLoading &&
                                <div className="modal-footer p-t-15">
                                    <button type="button" className="btn btn-default btn-xs" data-dismiss="modal">Close</button>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div id="live-location" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">Driver Location</h4>
                            </div>
                            <br />
                            <hr className="m-0" />
                            <div className="modal-body" style={{ height: "400px", padding: 0 }}>
                                {!this.state.loadUserLocation &&
                                    <div id="map">
                                        {typeof (this.state.locationData[0]) !== 'undefined' &&
                                            <Map
                                                google={this.props.google}
                                                zoom={8}
                                                style={{ width: "100%", height: "100%" }}
                                                initialCenter={{ lat: this.state.locationData[0].latitude, lng: this.state.locationData[0].longitude }}
                                            >

                                                {this.state.locationData.map(item => (
                                                    <Marker position={{ lat: item.latitude, lng: item.longitude }} label={item.name} key={item.id} />
                                                ))

                                                }
                                            </Map>
                                        }

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div id="ratting-give" className="modal fade" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                <h4 className="modal-title">View Rate & Review</h4>
                            </div>
                            <br />
                            <hr className="m-0" />
                            <div className="modal-body">
                                {/* <div className="text-center">
                                    <img src={this.state.tempItem.profile_image || "assets/images/provider1.jpg"} className="img-circle" style={{ width: '60px' }} />
                                    <h4 className="m-b-0"><span>{this.state.tempItem.full_name}</span></h4>
                                </div> */}
                                {this.state.rating_review.rate ?
                                    <div className="star-ratting">
                                        {
                                            Math.ceil(this.state.rating_review.rate) > 0
                                            &&
                                            <div className="text-center text-orange">
                                                <p className="m-0">
                                                    <React.Fragment>
                                                        <span className={(Math.ceil(this.state.rating_review.rate) >= 1) ? 'icon-star-full2 f-s-35' : 'icon-star-empty3 f-s-35'}></span>
                                                        <span className={(Math.ceil(this.state.rating_review.rate) >= 2) ? 'icon-star-full2 f-s-35' : 'icon-star-empty3 f-s-35'}></span>
                                                        <span className={(Math.ceil(this.state.rating_review.rate) >= 3) ? 'icon-star-full2 f-s-35' : 'icon-star-empty3 f-s-35'}></span>
                                                        <span className={(Math.ceil(this.state.rating_review.rate) >= 4) ? 'icon-star-full2 f-s-35' : 'icon-star-empty3 f-s-35'}></span>
                                                        <span className={(Math.ceil(this.state.rating_review.rate) == 5) ? 'icon-star-full2 f-s-35' : 'icon-star-empty3 f-s-35'}></span>
                                                    </React.Fragment>
                                                </p>
                                            </div>
                                        }
                                        {
                                            Math.ceil(this.state.rating_review.rate) == 0.0
                                            &&
                                            <div className="text-center text-orange">
                                                <p className="m-0">
                                                    <React.Fragment>
                                                        <span className='icon-star-empty3 f-s-35'></span>
                                                        <span className='icon-star-empty3 f-s-35'></span>
                                                        <span className='icon-star-empty3 f-s-35'></span>
                                                        <span className='icon-star-empty3 f-s-35'></span>
                                                        <span className='icon-star-empty3 f-s-35'></span>
                                                    </React.Fragment>
                                                </p>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div className="star-ratting">
                                        <div className="text-center text-orange">
                                            <p className="m-0">
                                                <React.Fragment>
                                                    <span className='icon-star-empty3 f-s-35'></span>
                                                    <span className='icon-star-empty3 f-s-35'></span>
                                                    <span className='icon-star-empty3 f-s-35'></span>
                                                    <span className='icon-star-empty3 f-s-35'></span>
                                                    <span className='icon-star-empty3 f-s-35'></span>
                                                </React.Fragment>
                                            </p>
                                        </div>
                                    </div>
                                }

                                <div className="form-group">
                                    <label><b>Public Comment</b></label>
                                    <p>{this.state.rating_review.public_review ||
                                        <div className="panel panel-default">
                                            <div className="panel-body">
                                                <div className="alert alert-primary border-none text-center m-0">No Public Comments Found.</div>
                                            </div>
                                        </div>
                                    }
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label><b>Private Comment</b></label>
                                    <p>{this.state.rating_review.private_review ||
                                        <div className="panel panel-default">
                                            <div className="panel-body">
                                                <div className="alert alert-primary border-none text-center m-0">No Private Comments Found.</div>
                                            </div>
                                        </div>
                                    }
                                    </p>
                                </div>
                            </div>
                            <hr className="m-0" />
                            <div className="modal-footer p-t-15">
                                <button type="button" data-dismiss="modal" className="btn btn-default btn-xs">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyDJvNKFF3zHyOKcuSWzJ2RFMQV1Eu3gihI",
})(ManageOrders);
// export default ManageOrders;