import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import PostDataWithParam from '../services/PostDataWithParam';
import PostDataFile from '../services/PostDataFile';
import MilitaryConversations from './MilitaryConversations';
import { Prompt } from 'react-router';

class FirePrevention extends Component {

    state = {
        fields: {
            fire_alarm_protection: -1,
            kind: -1,
            install_minimum_system: -1,
            iso_ppo: '',
            closest_fire_hydrant: '',
            warehouse_fire_extinguisher: -1,
            fire_extinguisher_serviced: -1,
            warehouse_operational_layout_file: null,
            hazardous_materials: -1,
            non_smoking_signs: -1,
            non_smoking_enforced: -1,
            waste_refuse_removed: -1,
            metal_garbage_cans: -1,
            free_of_storage_equipment: -1,
            stored_in_pallets_vault: -1,
            contact_with_outside_walls: -1,
            stacked_above_ten_feet: -1,
            system_serviced_annually: -1,
            have_fire_extinguishers: -1,
            system_inspected_after: 0,
            secure_area: -1,
            inspect_all_shipments: -1,
            tab_id: 8,
            id: ''
        },
        saveStatus: false,
        proccesing:false,
        formDirty:false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.$('[data-popup="popover"]').popover();
        window.$('[data-popup="tooltip"]').tooltip();
        
        window.$('.select-size-xs').select2({
            containerCssClass: 'select-xs'
        })
        .off('select2:select')
        .on('select2:select', e => {
            let fields = this.state.fields;
            fields.system_inspected_after = e.params.data.id;
            this.setState({ fields: fields });
        })

        PostDataWithParam('GetFirePreventionProtection', {})
            .then((response) => {
                if (response.success == 1) {
                    let response_data = { ...this.state.fields, ...response.data };
                    this.setState({ fields: response_data }, () => {
                        window.$('.select-size-xs').select2({
                            containerCssClass: 'select-xs'
                        })
                            .off('select2:select')
                            .on('select2:select', e => {
                                let fields = this.state.fields;
                                fields.system_inspected_after = e.params.data.id;
                                this.setState({ fields: fields });
                            })
                    });
                }
            })
    }

    getFileName(file){
        if(file!=null){
            let files = file.split('/');
            return files[files.length -1];
        }        
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields,formDirty:true});
    }

    handleFileSelect = (e) => {
        let fields = this.state.fields;
        fields.warehouse_operational_layout_file_report = e.target.files[0];
        this.setState({ fields: fields,formDirty:true });
    }

    updateFirePrevention = () => {
        this.setState({ proccesing: true })
        let fields = this.state.fields;
        let fd = new FormData();

        for (var key in fields) {
            if (fields.hasOwnProperty(key)) {
                                
                fd.append(key, fields[key]);
            }
        }

        if(this.state.fields.warehouse_operational_layout_file_report && this.state.fields.warehouse_operational_layout_file_report.name)
            fd.append('warehouse_operational_layout_file', this.state.fields.warehouse_operational_layout_file_report, this.state.fields.warehouse_operational_layout_file_report.name);
        

        PostDataFile('SaveFirePreventionProtection', fd)
            .then((response) => {
                if (response.success === 1) {
                    window.swal('Success!', response.msg, 'success')
                    this.setState({ saveStatus: true, proccesing: false,formDirty:false }, () => {
                        this.props.onUpdateApprovalProgress();
                    });
                } else {
                    this.setState({ proccesing: true,formDirty:false })
                    window.swal('Error!', response.msg, 'error')
                }
            })

    }

    openModal = (field_name,tab_id) => {
        this.props.onOpenModal(field_name,tab_id)
    }

    renderAlertMsg = (field_name,tab_id) => {
        
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
        

        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Answer Received</p>
                        </React.Fragment>
                    )
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Waiting for reply</p>
                        </React.Fragment>
                    )
                }
            }
        }
    }
    renderClassName = (field_name,tab_id) => {
        
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
        

        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return "btn-success "
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return "btn-warning "
                }
            }
        }


    }
    render() {
        if (this.state.saveStatus) {
            return <Redirect to='/independent-mover/dashboard/military-approved/information-tab/security' />
        }
        return (
            <div className="tab-pane active" id="top-icon-tab8">
                <Prompt
                when={this.state.formDirty}
                message="Please save your data before you leave the page. Otherwise, you will lose the information you entered !"
                />
                <div className="panel panel-flat">
                    <div className="panel-heading">
                        <h5 className="panel-title">Fire Prevention &amp; Protection  &nbsp;<a href="#" data-popup="popover" title="" data-trigger="hover" data-content="Minimum fire protection standards are based upon fire protection systems, fire insurance rates, and other factors.Warehouses that do not meet these minimum standards will not be approved for participation in the program."><i className="icon-info22"></i></a></h5>
                    </div>
                    <div className="panel-body">
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Do you have fire alarm and protection system?</h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="fire_alarm_protection" onChange={this.changeValue} checked={(this.state.fields.fire_alarm_protection == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="fire_alarm_protection" onChange={this.changeValue} checked={(this.state.fields.fire_alarm_protection == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('fire_alarm_protection',this.state.fields.tab_id)} className={this.renderClassName('fire_alarm_protection',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('fire_alarm_protection',this.state.fields.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Which kind? </h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="radio">
                                        <label>
                                            <input type="radio" value="1" name="kind" onChange={this.changeValue} checked={(this.state.fields.kind == 1) ? true : false} />
                                            Supervised and Remotely monitored detection & reporting system
                                                                        </label>
                                    </div>
                                    <div className="radio">
                                        <label>
                                            <input type="radio" value="2" name="kind" onChange={this.changeValue} checked={(this.state.fields.kind == 2) ? true : false} />
                                            Fully Automatic Unsupervised Sprinkler System
                                                                        </label>
                                    </div>
                                    <div className="radio">
                                        <label>
                                            <input type="radio" value="3" name="kind" onChange={this.changeValue} checked={(this.state.fields.kind == 3) ? true : false} />
                                            Fully Automatic Supervised Spinkler System.
                                                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('kind',this.state.fields.tab_id)} className={this.renderClassName('kind',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('kind',this.state.fields.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Are you willing to install the minimum required system needed for approval?</h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="install_minimum_system" onChange={this.changeValue} checked={(this.state.fields.install_minimum_system == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="install_minimum_system" onChange={this.changeValue} checked={(this.state.fields.install_minimum_system == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('install_minimum_system',this.state.fields.tab_id)} className={this.renderClassName('install_minimum_system',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('install_minimum_system',this.state.fields.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-0">Do you know your ISO (Insurance Services Office) or PPC (Public Protection Classification) for your community? If so, what is it?</h5>
                            <small>(Hint: Your local government/fire department can provide this with a quick phone call.) &nbsp;<img src="assets/images/icons/smiley-colorfull.svg" width="25" /></small>
                            <div className="m-t-10" />
                            <textarea className="form-control" name="iso_ppo" onChange={this.changeValue} value={this.state.fields.iso_ppo || ""}></textarea>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('iso_ppo',this.state.fields.tab_id)} className="btn btn-default btn-xs pull-right m-t-10" data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('iso_ppo',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                    <div className="well m-b-15">
                        <h5 className="m-0">How far is the closest fire hydrant to your warehouse?</h5>
                        <input type="text" className="form-control" name="closest_fire_hydrant" onChange={this.changeValue} value={this.state.fields.closest_fire_hydrant || ""} />
                        <div className="row">
                            <div className="col-md-12">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('closest_fire_hydrant',this.state.fields.tab_id)} className={this.renderClassName('closest_fire_hydrant',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('closest_fire_hydrant',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                    <div className="well m-b-15">
                        <h5 className="m-t-0">Does your warehouse have fire estinguishers?</h5>
                        <div className="row">
                            <div className="col-md-8">
                                <label className="radio-inline">
                                    <input type="radio" value="1" name="warehouse_fire_extinguisher" onChange={this.changeValue} checked={(this.state.fields.warehouse_fire_extinguisher == 1) ? true : false} />
                                    Y
                                                                    </label>
                                <label className="radio-inline">
                                    <input type="radio" value="0" name="warehouse_fire_extinguisher" onChange={this.changeValue} checked={(this.state.fields.warehouse_fire_extinguisher == 0) ? true : false} />
                                    N
                                                                    </label>
                            </div>
                            <div className="col-md-4">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('warehouse_fire_extinguisher',this.state.fields.tab_id)} className={this.renderClassName('warehouse_fire_extinguisher',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('warehouse_fire_extinguisher',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                    <div className="well m-b-15">
                        <h5 className="m-t-0">Are the fire estinguishers serviced annually?</h5>
                        <div className="row">
                            <div className="col-md-8">
                                <label className="radio-inline">
                                    <input type="radio" value="1" name="fire_extinguisher_serviced" onChange={this.changeValue} checked={(this.state.fields.fire_extinguisher_serviced == 1) ? true : false} />
                                    Y
                                                                    </label>
                                <label className="radio-inline">
                                    <input type="radio" value="0" name="fire_extinguisher_serviced" onChange={this.changeValue} checked={(this.state.fields.fire_extinguisher_serviced == 0) ? true : false} />
                                    N
                                                                    </label>
                            </div>
                            <div className="col-md-4">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('fire_extinguisher_serviced',this.state.fields.tab_id)} className={this.renderClassName('fire_extinguisher_serviced',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('fire_extinguisher_serviced',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                    <div className="well m-b-15">
                        <h5 className="panel-title m-t-0">Please upload/provide a warehouse operational layout fire plan &nbsp;<small><a href="#" data-popup="popover" title="" data-trigger="hover" data-content="A warehouse operational layout fire plan is required as a part of the pre-award survey process.Additionally, all approved storage facilities will submit an initial and revised fire plan as outlined below.The layout fire plan will show the configuration of pallets or open-stack storage, racks, aisles, deck space, working areas, office space.Respective dimensions inclusive of wall clearances will be shown.This layout fire plan will be reviewed on site and be signed and dated by a fire department official for approval.The approved layout fire plan will be sent to the Storage Management Office for permanent retention in the NTS TSP SIT providers file.Thereafter any change to the operational characteristic of the warehouse must be submitted to the SMO in advance of the changes,for the SMOs review and approval.After the SMO review, the changes will be reflected on a modified or revised layout fire plan, approved by on-site review, signed, and dated by a fire department official.The modified layout plan will be sent to the SMO for retention.A copy of the current approved layout fire plan is to be posted in a prominent position within the warehouse to which it applies."><i className="icon-info22"></i></a></small></h5>
                        <div className="row">
                            <div className="col-md-8">
                                <input type="file" name="warehouse_operational_layout_file" onChange={this.handleFileSelect} />
                            </div>
                            <div className="col-md-4">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('warehouse_operational_layout_file',this.state.fields.tab_id)} className={this.renderClassName('warehouse_operational_layout_file',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('warehouse_operational_layout_file',this.state.fields.tab_id)} 
                            </div>
                        </div>
                        {
                            this.state.fields.warehouse_operational_layout_file
                            &&
                            this.state.fields.warehouse_operational_layout_file!=null
                            &&
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="file-wrapper filetype-image">
                                        <div className="file-title"><a href="javascript:void(0)" data-src={this.state.fields.warehouse_operational_layout_file} data-title="Preview" data-toggle="image-modal-file" className="image-modal-file"><i className="icon-image5 position-left text-primary"></i> {this.getFileName(this.state.fields.warehouse_operational_layout_file)}</a></div>
                                    </div>
                                </div>
                            </div>
                        }                         
                    </div>
                    <div className="well m-b-15">
                        <h5 className="m-t-0">Do you have any hazardous materials or operations inside or outside of your warehouse?</h5>
                        <div className="row">
                            <div className="col-md-8">
                                <label className="radio-inline">
                                    <input type="radio" value="1" name="hazardous_materials" onChange={this.changeValue} checked={(this.state.fields.hazardous_materials == 1) ? true : false} />
                                    Y
                                                                    </label>
                                <label className="radio-inline">
                                    <input type="radio" value="0" name="hazardous_materials" onChange={this.changeValue} checked={(this.state.fields.hazardous_materials == 0) ? true : false} />
                                    N
                                                                    </label>
                            </div>
                            <div className="col-md-4">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('hazardous_materials',this.state.fields.tab_id)} className={this.renderClassName('hazardous_materials',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('hazardous_materials',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                    <div className="well m-b-15">
                        <h5 className="m-t-0">Do you have Non-Smoking signs posted in conspicous locations in all storage areas?</h5>
                        <div className="row">
                            <div className="col-md-8">
                                <label className="radio-inline">
                                    <input type="radio" value="1" name="non_smoking_signs" onChange={this.changeValue} checked={(this.state.fields.non_smoking_signs == 1) ? true : false} />
                                    Y
                                                                    </label>
                                <label className="radio-inline">
                                    <input type="radio" value="0" name="non_smoking_signs" onChange={this.changeValue} checked={(this.state.fields.non_smoking_signs == 0) ? true : false} />
                                    N
                                                                    </label>
                            </div>
                            <div className="col-md-4">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('non_smoking_signs',this.state.fields.tab_id)} className={this.renderClassName('non_smoking_signs',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('non_smoking_signs',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                    <div className="well m-b-15">
                        <h5 className="m-t-0">Is Non-Smoking restrictions rigidly enforced?</h5>
                        <div className="row">
                            <div className="col-md-8">
                                <label className="radio-inline">
                                    <input type="radio" value="1" name="non_smoking_enforced" onChange={this.changeValue} checked={(this.state.fields.non_smoking_enforced == 1) ? true : false} />
                                    Y
                                                                    </label>
                                <label className="radio-inline">
                                    <input type="radio" value="0" name="non_smoking_enforced" onChange={this.changeValue} checked={(this.state.fields.non_smoking_enforced == 0) ? true : false} />
                                    N
                                                                    </label>
                            </div>
                            <div className="col-md-4">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('non_smoking_enforced',this.state.fields.tab_id)} className={this.renderClassName('non_smoking_enforced',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('non_smoking_enforced',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                    <div className="well m-b-15">
                        <h5 className="m-t-0">Is waste and refuse removed from storage areas daily?</h5>
                        <div className="row">
                            <div className="col-md-8">
                                <label className="radio-inline">
                                    <input type="radio" value="1" name="waste_refuse_removed" onChange={this.changeValue} checked={(this.state.fields.waste_refuse_removed == 1) ? true : false} />
                                    Y
                                                                    </label>
                                <label className="radio-inline">
                                    <input type="radio" value="0" name="waste_refuse_removed" onChange={this.changeValue} checked={(this.state.fields.waste_refuse_removed == 0) ? true : false} />
                                    N
                                                                    </label>
                            </div>
                            <div className="col-md-4">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('waste_refuse_removed',this.state.fields.tab_id)} className={this.renderClassName('waste_refuse_removed',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('waste_refuse_removed',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                    <div className="well m-b-15">
                        <h5 className="m-t-0">In storage area do you have metal garbage cans with tight fitting lids?</h5>
                        <div className="row">
                            <div className="col-md-8">
                                <label className="radio-inline">
                                    <input type="radio" value="1" name="metal_garbage_cans" onChange={this.changeValue} checked={(this.state.fields.metal_garbage_cans == 1) ? true : false} />
                                    Y
                                                                    </label>
                                <label className="radio-inline">
                                    <input type="radio" value="0" name="metal_garbage_cans" onChange={this.changeValue} checked={(this.state.fields.metal_garbage_cans == 0) ? true : false} />
                                    N
                                                                    </label>
                            </div>
                            <div className="col-md-4">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('metal_garbage_cans',this.state.fields.tab_id)} className={this.renderClassName('metal_garbage_cans',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('metal_garbage_cans',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                    <div className="well m-b-15">
                        <h5 className="m-t-0">Are aisles kept free of storage and equipment to ensure a safe environment?</h5>
                        <div className="row">
                            <div className="col-md-8">
                                <label className="radio-inline">
                                    <input type="radio" value="1" name="free_of_storage_equipment" onChange={this.changeValue} checked={(this.state.fields.free_of_storage_equipment == 1) ? true : false} />
                                    Y
                                                                    </label>
                                <label className="radio-inline">
                                    <input type="radio" value="0" name="free_of_storage_equipment" onChange={this.changeValue} checked={(this.state.fields.free_of_storage_equipment == 0) ? true : false} />
                                    N
                                                                    </label>
                            </div>
                            <div className="col-md-4">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('free_of_storage_equipment',this.state.fields.tab_id)} className={this.renderClassName('free_of_storage_equipment',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('free_of_storage_equipment',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                    <div className="well m-b-15">
                        <h5 className="m-t-0">Are all household goods stored on pallets or in storage vaults at a minimum of 2 inches from the floor?</h5>
                        <div className="row">
                            <div className="col-md-8">
                                <label className="radio-inline">
                                    <input type="radio" value="1" name="stored_in_pallets_vault" onChange={this.changeValue} checked={(this.state.fields.stored_in_pallets_vault == 1) ? true : false} />
                                    Y
                                                                    </label>
                                <label className="radio-inline">
                                    <input type="radio" value="0" name="stored_in_pallets_vault" onChange={this.changeValue} checked={(this.state.fields.stored_in_pallets_vault == 0) ? true : false} />
                                    N
                                                                    </label>
                            </div>
                            <div className="col-md-4">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('stored_in_pallets_vault',this.state.fields.tab_id)} className={this.renderClassName('stored_in_pallets_vault',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('stored_in_pallets_vault',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                    <div className="well m-b-15">
                        <h5 className="m-t-0">Are household goods not in contact with the outside walls of the warehouse?</h5>
                        <div className="row">
                            <div className="col-md-8">
                                <label className="radio-inline">
                                    <input type="radio" value="1" name="contact_with_outside_walls" onChange={this.changeValue} checked={(this.state.fields.contact_with_outside_walls == 1) ? true : false} />
                                    Y
                                                                    </label>
                                <label className="radio-inline">
                                    <input type="radio" value="0" name="contact_with_outside_walls" onChange={this.changeValue} checked={(this.state.fields.contact_with_outside_walls == 0) ? true : false} />
                                    N
                                                                    </label>
                            </div>
                            <div className="col-md-4">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('contact_with_outside_walls',this.state.fields.tab_id)} className={this.renderClassName('contact_with_outside_walls',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('contact_with_outside_walls',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                    <div className="well m-b-15">
                        <h5 className="m-t-0">Are LOOSE stacked household goods stacked above 10 feet from the floor? </h5>
                        <div className="row">
                            <div className="col-md-8">
                                <label className="radio-inline">
                                    <input type="radio" value="1" name="stacked_above_ten_feet" onChange={this.changeValue} checked={(this.state.fields.stacked_above_ten_feet == 1) ? true : false} />
                                    Y
                                                                    </label>
                                <label className="radio-inline">
                                    <input type="radio" value="0" name="stacked_above_ten_feet" onChange={this.changeValue} checked={(this.state.fields.stacked_above_ten_feet == 0) ? true : false} />
                                    N
                                                                    </label>
                            </div>
                            <div className="col-md-4">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('stacked_above_ten_feet',this.state.fields.tab_id)} className={this.renderClassName('stacked_above_ten_feet',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('stacked_above_ten_feet',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                    <div className="well m-b-15">
                        <h5 className="m-t-0">Is heating, electrical, plumbing and other systems serviced annually to ensure sound condition and meet all required nationally recognized codes?</h5>
                        <div className="row">
                            <div className="col-md-8">
                                <label className="radio-inline">
                                    <input type="radio" value="1" name="system_serviced_annually" onChange={this.changeValue} checked={(this.state.fields.system_serviced_annually == 1) ? true : false} />
                                    Y
                                                                    </label>
                                <label className="radio-inline">
                                    <input type="radio" value="0" name="system_serviced_annually" onChange={this.changeValue} checked={(this.state.fields.system_serviced_annually == 0) ? true : false} />
                                    N
                                                                    </label>
                            </div>
                            <div className="col-md-4">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('system_serviced_annually',this.state.fields.tab_id)} className={this.renderClassName('system_serviced_annually',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('system_serviced_annually',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                    <div className="well m-b-15">
                        <h5 className="m-t-0">Do all motorized equipment have fire estinguishers attached (forklifts, pallet jacks, etc)?</h5>
                        <div className="row">
                            <div className="col-md-8">
                                <label className="radio-inline">
                                    <input type="radio" value="1" name="have_fire_extinguishers" onChange={this.changeValue} checked={(this.state.fields.have_fire_extinguishers == 1) ? true : false} />
                                    Y
                                                                    </label>
                                <label className="radio-inline">
                                    <input type="radio" value="0" name="have_fire_extinguishers" onChange={this.changeValue} checked={(this.state.fields.have_fire_extinguishers == 0) ? true : false} />
                                    N
                                                                    </label>
                            </div>
                            <div className="col-md-4">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('have_fire_extinguishers',this.state.fields.tab_id)} className={this.renderClassName('have_fire_extinguishers',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('have_fire_extinguishers',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                    <div className="well m-b-15">
                        <h5 className="m-t-0">How often is your fire and protection systems inspected by a qualified technician? (Pick One)</h5>
                        <div className="row">
                            <div className="col-md-12">
                                <select className="select-size-xs" value={this.state.fields.system_inspected_after} onChange={this.changeValue}>
                                    <option value="0">Select One</option>
                                    <option value="1">Every 30 days</option>
                                    <option value="2">Every 90 days</option>
                                    <option value="3">Every year</option>
                                </select>
                            </div>
                            <div className="col-md-12">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('system_inspected_after',this.state.fields.tab_id)} className={this.renderClassName('system_inspected_after',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('system_inspected_after',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                    <div className="well m-b-15">
                        <h5 className="m-t-0">Firearms are normally stored within a member's storage lot. If not able to be stored in the lot, do you have a secure area in which to store firearms?</h5>
                        <div className="row">
                            <div className="col-md-8">
                                <label className="radio-inline">
                                    <input type="radio" value="1" name="secure_area" onChange={this.changeValue} checked={(this.state.fields.secure_area == 1) ? true : false} />
                                    Y
                                                                    </label>
                                <label className="radio-inline">
                                    <input type="radio" value="0" name="secure_area" onChange={this.changeValue} checked={(this.state.fields.secure_area == 0) ? true : false} />
                                    N
                                                                    </label>
                            </div>
                            <div className="col-md-4">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('secure_area',this.state.fields.tab_id)} className={this.renderClassName('secure_area',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('secure_area',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                    <div className="well m-b-15">
                        <h5 className="m-t-0">Do you inspect all shipments coming into your warehouse?</h5>
                        <div className="row">
                            <div className="col-md-8">
                                <label className="radio-inline">
                                    <input type="radio" value="1" name="inspect_all_shipments" onChange={this.changeValue} checked={(this.state.fields.inspect_all_shipments == 1) ? true : false} />
                                    Y
                                                                    </label>
                                <label className="radio-inline">
                                    <input type="radio" value="0" name="inspect_all_shipments" onChange={this.changeValue} checked={(this.state.fields.inspect_all_shipments == 0) ? true : false} />
                                    N
                                                                    </label>
                            </div>
                            <div className="col-md-4">
                                <a href="javascript:void(0)" onClick={()=>this.openModal('inspect_all_shipments',this.state.fields.tab_id)} className={this.renderClassName('inspect_all_shipments',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                {this.renderAlertMsg('inspect_all_shipments',this.state.fields.tab_id)} 
                            </div>
                        </div>
                    </div>
                </div>
                <MilitaryConversations />
                <div className="text-center">{this.state.proccesing? <button className="btn btn-primary" disabled={true}>Processing...</button>:<button className="btn btn-primary" onClick={this.updateFirePrevention}>Save &amp; Next</button>}</div>

            </div>

        );
    }
}

export default FirePrevention;