import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom';
import Breadcumb from '../externaljs/Breadcrumb';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import GetData from '../services/GetData';
import PostDataWithParam from '../services/PostDataWithParam';
import ConfirmBox from '../externaljs/ConfirmBox';
import DateFormat from '../externaljs/DateFormat';
import Storage from '../externaljs/Storage';

class Driver extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Drivers', link: '', class: 'active' }
        ],
        DriverErr: '',
        driver_list: [],
        addDriverStatus: false,
        import_file: '',
        DriverErr: '',
        loading: false,
        staff_user_id: null,
        custom_menu: [],
        edit_team: true,
    }

    componentDidMount() {
        this.GetAllDrivers();

        let staff_user_id = (JSON.parse(Storage.get('imnLoginData'))).staff_user_id;
        let custom_menu = [];
        if (staff_user_id != "") {
            custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
        }
        this.setState({ staff_user_id: staff_user_id, custom_menu: custom_menu }, () => {
            // console.log(this.state.custom_menu);
            // console.log(this.state.staff_user_id);
            let edit_team = this.state.custom_menu.find(menu => menu.title == 'Manage Team')
            if (edit_team) {
                if (edit_team.is_add == 1 || edit_team.is_add == '1') this.setState({ edit_team: true })
                else this.setState({ edit_team: false })
            }
            
        });
    }

    GetAllDrivers = () => {
        this.setState({ loading: true }, () => {
            PostDataWithParam('GetAllDrivers', { profile_id: 6 })
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ loading: false }, () => {
                           
                            this.setState({ driver_list: response.data.reverse() }, () => {
                                 DataTableBasic();
                            });
                        })
                    } else {
                        this.setState({ driver_list: [], loading: false })
                    }
                })
                .catch(() => {
                    this.setState({ driver_list: [], loading: false })
                })
        })
    }

    removeDataTable = () => {
        if (window.$.fn.DataTable.isDataTable('#list-table')) {
            window.$('#list-table').DataTable().destroy();
        }

        window.$('#list-table tbody').empty();
    }

    handleBtn = (status) => {
        if (status == 0) {
            return (
                'Activate'
            )
        } else {
            return (
                'Deactivate'
            )

        }
    }

    DeleteDriver = (driver_id) => {
        ConfirmBox("You want to delete this driver!")
            .then((result) => {
                if (result) {
                    PostDataWithParam('DeleteDriver', { driver_id: driver_id })
                        .then((response) => {
                            let result = response;
                            if (result.success === 1) {
                                this.removeDataTable();
                                this.GetAllDrivers();
                                window.swal('Deleted!', 'Driver Deleted Successfully', 'success')
                            } else {
                                window.swal('Error!', result.msg, 'error')
                            }
                        });
                }
            });
    }

    ActiveDeactiveDrivers = (driver_id, status) => {

        if (status == 1) {
            ConfirmBox("You want to deactivate this driver!")
                .then((result) => {
                    if (result) {
                        PostDataWithParam('ActiveDeactiveDrivers', { driver_id: driver_id, status: 0 })
                            .then((response) => {
                                let result = response;
                                if (result.success === 1) {
                                    this.removeDataTable();
                                    this.GetAllDrivers();
                                    window.swal('Deactivated!', 'Driver Deactivated Successfully', 'success')
                                } else {
                                    window.swal('Deactivated!', result.msg, 'success')
                                }
                            });
                    }
                });
        }
        else {
            ConfirmBox("You want to activate this driver!")
                .then((result) => {
                    if (result) {
                        PostDataWithParam('ActiveDeactiveDrivers', { driver_id: driver_id, status: 1 })
                            .then((response) => {
                                let result = response;
                                if (result.success === 1) {
                                    this.removeDataTable();
                                    this.GetAllDrivers();
                                    window.swal('Activated!', 'Driver Activated Successfully', 'success')
                                } else {
                                    window.swal('Activated!', result.msg, 'success')
                                }
                            });
                    }
                });
        }
    }

    handleStatus = (status) => {
        if (status == 0) {
            return (
                <td>
                    Inactive
                </td>
            )
        } else {
            return (
                <td>
                    Active
                </td>
            )

        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <div className="clearfix">

                        {
                            this.state.staff_user_id == "" &&
                            <div className="pull-right">
                                <Link to="/independent-mover/dashboard/adddriver" className="btn btn-primary btn-xs m-r-15"><i className="icon-plus22"></i> Add Driver</Link>

                            </div>
                        }
                        {
                            this.state.staff_user_id != "" && this.state.custom_menu.map((menu, i) => {
                                return (
                                    <div className="pull-right">
                                        {(menu.title == "Manage Team" && (menu.is_add == 1 || menu.is_add == "1")) &&
                                            <Link to="/independent-mover/dashboard/adddriver" className="btn btn-primary btn-xs m-r-15"><i className="icon-plus22"></i> Add Driver</Link>
                                        }
                                    </div>
                                );
                            })
                        }
                        <h4 className="pull-left">Driver List</h4>
                    </div>
                    <div className="panel panel-flat">
                        {
                            this.state.loading
                            &&
                            <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                        }
                        {
                            !this.state.loading
                            &&
                            this.state.driver_list.length !== 0
                            &&

                            <table className="table datatable-basic table-striped" id="list-table">
                                <thead>
                                    <tr>
                                        <th style={{ whiteSpace: 'nowrap' }}>Name</th>
                                        {/* <th>Mobile</th>
                                        <th>Email Address</th> */}
                                        <th>DL Exp. Date</th>
                                        <th>Status</th>
                                        {this.state.edit_team && <th className="text-center">Actions</th>}
                                    </tr>
                                </thead>
                                <tbody>
                              
                                    {this.state.driver_list.map(d_list =>
                                        <tr id={"#00" + d_list.id} key={d_list.id}>
                                            <td className="text-nowrap"><Link to={"/independent-mover/dashboard/viewdriver/" + d_list.id}>{d_list.gender} {d_list.first_name} {d_list.last_name}</Link></td>
                                            {/* <td>{d_list.contact_no}</td>
                                            <td>{d_list.email}</td> */}
                                            <td>
                                            {d_list.license_exp_date !== null ? DateFormat.getFormattedDateTime(d_list.license_exp_date, 'm-d-Y') : "---"}
                                            </td>
                                            {this.handleStatus(d_list.driver_status)}
                                            {this.state.edit_team &&
                                                <td className="text-center">
                                                <ul className="icons-list">
                                                    <li className="dropdown">
                                                        <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                                                            <i className="icon-menu9"></i>
                                                        </a>
                                                        <ul className="dropdown-menu dropdown-menu-right">
                                                            <li><Link to={"/independent-mover/dashboard/editdriver/" + d_list.id}><i className="icon-pencil"></i> Edit Driver</Link></li>
                                                            <li><a href="javascript:void(0)" onClick={() => this.DeleteDriver(d_list.id)}><i className="icon-cross2"></i> Delete Driver</a></li>
                                                            <li><a href="javascript:void(0)" className="deactivate-user" onClick={() => this.ActiveDeactiveDrivers(d_list.id, d_list.driver_status)}><i className="icon-user-block"></i> {this.handleBtn(d_list.driver_status)} Driver</a></li>

                                                        </ul>
                                                    </li>
                                                </ul>
                                            </td>}
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                        }
                        {
                            !this.state.loading
                            &&
                            this.state.driver_list.length === 0
                            &&
                            <div className="alert alert-primary border-none text-center">No Driver Found.</div>
                        }
                    </div>

                </div>
            </div>
        );
    }
}

export default Driver;