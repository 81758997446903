export default function ConfirmBox(msg) {
    // return window.swal({
    //     title: 'Are you sure?',
    //     text: msg,
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Yes'
    // }).then(res => res)

    return window.swal({
        title: "Are you sure?",
        text: msg,
        icon: "warning",
        buttons: true,
        dangerMode: true,
    }).then(res => res)
}