import React, { Component } from 'react'
import Breadcumb from '../externaljs/Breadcrumb';

class OutsourceProject extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Bookings', link: '/independent-mover/dashboard/unassign-offer', class: '' },
            { title: 'Outsource Project', link: '', class: 'active' }
        ]
    }
    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                    <form>
                        <div className="panel panel-flat profile-page">
                            <div className="panel-heading">
                                <h6 className="m-b-0 m-t-0 text-semibold">Request ID #006254</h6>
                            </div>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Hire For</label>
                                                    <input className="form-control" type="text" value="Margo Baker" disabled="" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Relocation Request Date:</label>
                                                    <div className="input-group date">
                                                        <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                        <input type="text" className="form-control" placeholder="" value="22 JAN 1987" disabled="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <label>Pickup Location</label>
                                                    <input className="form-control" type="text" value="1118 Oak Street, Syracuse, NY, New York, 13224" disabled="" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Drop off Location</label>
                                                    <input className="form-control" type="text" value="34 Lee Road, Syracuse, NY, New York, 13224" disabled="" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label>Relocation Notes</label>
                                            <textarea className="form-control" rows="4"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <h6>Visibility</h6>
                                    <div>
                                        <div className="radio">
                                            <label>
                                                <input type="radio" name="optradio" />Move Management Companies
                                    <small className="text-warning-400"> (this will send a notification to all move management companies in the network)</small>
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" name="optradio" checked /> Drivers/Helpers Network</label>
                                        </div>
                                        <div className="p-l-30">
                                            <div className="checkbox">
                                                <label><input type="checkbox" value="" checked="" />Drivers <small className="text-warning-400"> (this will send a notification to all available drivers in the network)</small></label>
                                            </div>
                                            <div className="checkbox">
                                                <label><input type="checkbox" value="" checked="" />Helpers <small className="text-warning-400"> (this will send a notification to all available helpers in the network)</small></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 className="pull-left">Files</h4> <a href="" className="btn btn-success pull-right"><i className="icon-file-plus position-left"></i> Add File</a>
                        <div className="clearfix"></div>
                        <div className="panel panel-flat profile-page">
                            <div className="panel-body">
                                <div className="tabbable hori-scroll">

                                    <div className="file-manager">
                                        <ul>
                                            <li>
                                                <div className="file-wrapper filetype-image">
                                                    <div className="options">
                                                        <a href="" className="text-danger-600"><i className="icon-cancel-circle2"></i></a>
                                                    </div>
                                                    <div className="file-icon" style={{ backgroundImage: 'url(assets/images/124883885.jpg)' }}></div>
                                                    <div className="file-title"><a href=""><i className="icon-image5 position-left text-primary"></i> image-file-124883885.jpg</a></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="file-wrapper filetype-pdf">
                                                    <div className="options">
                                                        <a href="" className="text-danger-600"><i className="icon-cancel-circle2"></i></a>
                                                    </div>
                                                    <div className="file-icon"><img src="assets/images/icons/pdf.svg" alt="PDF" /></div>
                                                    <div className="file-title"><a href=""><i className="icon-file-pdf position-left text-danger"></i> pdf-file-124883885.pdf</a></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="file-wrapper filetype-zip">
                                                    <div className="options">
                                                        <a href="" className="text-danger-600"><i className="icon-cancel-circle2"></i></a>
                                                    </div>
                                                    <div className="file-icon"><img src="assets/images/icons/zip.svg" alt="Archive" /></div>
                                                    <div className="file-title"><a href=""><i className="icon-file-zip position-left text-slate"></i> zip-file-124883885.zip</a></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="file-wrapper filetype-audio">
                                                    <div className="options">
                                                        <a href="" className="text-danger-600"><i className="icon-cancel-circle2"></i></a>
                                                    </div>
                                                    <div className="file-icon"><img src="assets/images/icons/headphones.svg" alt="Audio" /></div>
                                                    <div className="file-title"><a href=""><i className="icon-music position-left text-indigo"></i> image-file-124883885.jpg</a></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <button className="btn btn-primary btn-lg ">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default OutsourceProject;