import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { CommonScript } from '../externaljs/CommonScript';
import Storage from '../externaljs/Storage';
import Urls from '../externaljs/Urls';
import DateFormat from '../externaljs/DateFormat';
import PostDataWithParam from '../services/PostDataWithParam';
import GetData from '../services/GetData';
import { connect } from 'react-redux';
import { getInboxCount } from '../redux/action';
import { CircularProgressbar } from 'react-circular-progressbar';
import FileUploadWithProgress from "../services/FileUploadWithProgress";
import { FileType, FileSvg, FileIcon } from "../externaljs/FileType";
import ConfirmBox from '../externaljs/ConfirmBox';

class Header extends Component {
    constructor(props) {
        super(props);
        this.handleOnChange = this.handleOnChange.bind(this);
    }
    state = {
        logoutStatus: false,
        acceptedRejectedNotifications: [],
        receivedOfferNotifications: [],
        receivedAnswer: [],
        msgComposeReply: [],
        gma_subscription_id: 0,
        questionsNotification: {
            msgCount: 0,
        },
        notificationStatus: 'offer',
        notificationList: [],
        // -------------subscription-------------------
        trial_subscription_id: '',
        subscription_id: '',
        subscription_issue_date: '',
        subscription_expiry_date: '',
        subscription_date: '',
        daysToExpire: 8888,         //8888 is arbitrary count
        editPermission: true,
        gma: true,

        //----------staff permission----------------
        edit_msg: true,
        offers_received: true,
        manage_orders: true,
        edit_rcvOffers: true,

        //----------help----------------
        user_name_err: '',
        support_requirement_err: '',
        email_id_err: '',
        file_upload_err: '',
        form_data: {
            user_name: JSON.parse(Storage.get('imnLoginData')).first_name + " " + JSON.parse(Storage.get('imnLoginData')).last_name,
            mobile_no: JSON.parse(Storage.get('imnLoginData')).contact_no,
            // mobile_no:'',
            email_id: JSON.parse(Storage.get('imnLoginData')).email,
            support_requirement: 0,
            comments: '',
            request_files: [],
            id: '',
            profile_id: 2
        },
        isProccesingFileUpload: false,
        isFileSaving: false,
        filesModal: [],
        filesView: [],
        uploadPercentage: 0,
        staff_user_id: '',
        unreadNotificationCount: 0

    }

    /* Start Notification */
    database = null;
    /* End Notification */

    subscriptionCheckInterval = null;
    staffMenuCheckInterval = null;

    componentDidMount() {
        // CommonScript();
        // GetData('FreeSubscription')
        // GetData('FreeGMASubscription')
        /* Start Notification */

        this.database = Urls.firebase.database();
        this.registerNotifications();
        this.registerMsgNotifications();
        /* End Notification */

        window.$(document).on('click', '.notification .media-list li > a', function () {
            window.$(this).closest('.dropdown').removeClass('open');
        })
        document.body.addEventListener('subscription', (e) => {
            this.GetUserInfo();
        }, false);
        this.GetUserInfo();

        this.subscriptionCheckInterval = setInterval(async () => {
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 1)
                this.setState({ editPermission: false })
            if (JSON.parse(Storage.get('imnLoginData')).subscription_id == 2)
                this.setState({ editPermission: true })
            await this.handleSubscriptionDate();
            await this.handleGMASubscriptionDate();
        }, 7000);

        this.staffMenuCheckInterval = setInterval(() => {
            if (Storage.get('mmcStaffMenu')) {
                let custom_menu = (JSON.parse(Storage.get('mmcStaffMenu')));
                this.setState({ custom_menu }, () => {

                    let find_offer_received = this.state.custom_menu.find(menu => menu.title == "Offers Received")
                    if (find_offer_received) {
                        this.setState({ offers_received: true })
                        if (find_offer_received.is_add == 1 || find_offer_received.is_add == '1') this.setState({ edit_rcvOffers: true })
                        else this.setState({ edit_rcvOffers: false })
                    }
                    else this.setState({ offers_received: false, edit_rcvOffers: false })

                    let find_reloOrders = this.state.custom_menu.find(menu => menu.title == "ReloOrders")
                    if (find_reloOrders) this.setState({ manage_orders: true })
                    else this.setState({ manage_orders: false })

                    let edit_msg = this.state.custom_menu.find(menu => menu.title == 'Messages')
                    let gma = this.state.custom_menu.find(menu => menu.title.trim() == 'Get Military Approved')
                    if (edit_msg) {
                        if (edit_msg.is_add == 1 || edit_msg.is_add == '1') this.setState({ edit_msg: true })
                        else this.setState({ edit_msg: false })
                    }
                    else this.setState({ edit_msg: false, gma: false })

                    if (gma) {
                        if (gma.is_add == 1 || gma.is_add == '1') this.setState({ gma: true })
                        else this.setState({ gma: false })
                    }
                    else this.setState({ gma: false })
                });
            }
        }, 1000)

        let staff_user_id = JSON.parse(Storage.get('imnLoginData')).staff_user_id;
        this.setState({ staff_user_id })

    }

    // ------------------- subscription start ------------------------------

    async handleSubscriptionDate() {

        let trial_subscription_id, subscription_id, subscription_issue_date, subscription_expiry_date;

        if (JSON.parse(Storage.get('imnLoginData')).subscription_expiry_date) {

            trial_subscription_id = JSON.parse(Storage.get('imnLoginData')).trial_subscription_id;
            subscription_id = JSON.parse(Storage.get('imnLoginData')).subscription_id;
            // console.log('subscription_id head',subscription_id);
            subscription_issue_date = JSON.parse(Storage.get('imnLoginData')).subscription_issue_date;
            subscription_expiry_date = JSON.parse(Storage.get('imnLoginData')).subscription_expiry_date;

            this.setState({ trial_subscription_id, subscription_id, subscription_issue_date, subscription_expiry_date }, () => {
                // console.log(trial_subscription_id, subscription_id, subscription_issue_date, subscription_expiry_date)
                var today = new Date();
                var expiry = new Date(this.state.subscription_expiry_date);
                var diffTime = expiry.getTime() - today.getTime();
                var diffDay = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                // console.log('daysToExpire', diffDay)

                if (diffDay == 0) {
                    this.setState({ daysToExpire: 0 })
                }
                else if (diffDay > 0) {

                    this.setState({ daysToExpire: diffDay })
                }
                else {
                    GetData('FreeSubscription')
                        .then(response => {

                        })
                        .catch(err => {
                            // console.log('FreeSubscription', err)
                        })
                }
            })
        }
        else {
            trial_subscription_id = JSON.parse(Storage.get('imnLoginData')).trial_subscription_id;
            subscription_id = JSON.parse(Storage.get('imnLoginData')).subscription_id;
            subscription_issue_date = JSON.parse(Storage.get('imnLoginData')).subscription_issue_date;
            subscription_id = JSON.parse(Storage.get('imnLoginData')).subscription_id;
            this.setState({ trial_subscription_id, subscription_id, subscription_issue_date, subscription_expiry_date }, () => {
                // console.log('subscription_id head',subscription_id);
            })
        }

    }

    async handleGMASubscriptionDate() {
        let gma_subscription_id, gma_subscription_issue_date, gma_subscription_expiry_date;
        if (JSON.parse(Storage.get('imnLoginData')).gma_subscription_expiry_date) {
            gma_subscription_issue_date = JSON.parse(Storage.get('imnLoginData')).gma_subscription_issue_date;
            gma_subscription_expiry_date = JSON.parse(Storage.get('imnLoginData')).gma_subscription_expiry_date;
            var today = new Date();
            var expiry = new Date(gma_subscription_expiry_date);
            var diffTime = expiry.getTime() - today.getTime();
            var diffDay = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            // var diffDay = -9;
            // console.log('daysToExpire', diffDay)

            if (diffDay < 0) {
                GetData('FreeGMASubscription')
            }
        }
    }

    doSubscribe = (e) => {
        // window.swal('warning', 'Please upgrade your membership to complete this action', 'warning')
        window.swal({
            title: "Warning!",
            text: "Please upgrade your membership to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }

    doGMASubscribe = (e) => {
        window.swal({
            title: "Warning!",
            text: "Please upgrade your Get Military Approved subscription to complete this action",
            className: "subscrip-info-swal",
            icon: ''
        })
    }

    // ------------------- subscription end ------------------------------

    handleLogout = () => {
        Storage.remove('imnLoginToken');
        Storage.remove('imnLoginData');
        Storage.remove('imnFirstLogin');
        Storage.remove('mmcStaffMenu');
        this.setState({ logoutStatus: true });
    }
    registerMsgNotifications() {
        this.database.ref('/notifications/msg_compose_reply/user_' + (JSON.parse(Storage.get('imnLoginData'))).id)
            .on('child_added', (snapshot) => {
                let msgComposeReply = this.state.msgComposeReply;
                let tempData = snapshot.val();

                tempData['nodeUrl'] = '/notifications/msg_compose_reply/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '/' + snapshot.key;

                msgComposeReply.push(tempData);

                let showMsg = "";
                if (tempData.type == "compose")
                    showMsg = tempData.sender_full_name + ' has sent you a message';
                else if (tempData.type == "reply")
                    showMsg = tempData.sender_full_name + ' has sent you a reply';

                this.setState({ msgComposeReply: msgComposeReply });
                /*
        * msg_compose_reply remove listener start 
        * when read status update in inbox msg list then remove from 
        * `/notifications/msg_compose_reply/` and `/notifications/notification_list/` refs
        */
                this.database.ref('/notifications/msg_compose_reply/user_' + (JSON.parse(Storage.get('imnLoginData'))).id)
                    .on('child_removed', (snapshot) => {
                        let msgComposeReply = this.state.msgComposeReply;
                        let notificationList = this.state.notificationList;
                        let tempData = snapshot.val();
                        const findComposeIndex = msgComposeReply.findIndex(msg => parseInt(msg.msg_id) === parseInt(tempData.msg_id));
                        const findNotificationsIndex = notificationList.findIndex(notification => parseInt(notification.msg_id) === parseInt(tempData.msg_id));
                        if (findComposeIndex !== -1) msgComposeReply.splice(findComposeIndex, 1);
                        if (findNotificationsIndex !== -1) {
                            this.database.ref(notificationList[findNotificationsIndex].nodeUrl).remove();
                            // notificationList.splice(findNotificationsIndex, 1);
                        }


                        this.setState({ msgComposeReply });
                    })
                // msg_compose_reply remove listener end 
            })
    }

    incrementUnreadNotificationCount = () => {
        this.setState((prevState) => ({
            unreadNotificationCount: prevState.unreadNotificationCount + 1
        }))
    }
    decrementUnreadNotificationCount = () => {
        this.setState((prevState) => ({
            unreadNotificationCount: prevState.unreadNotificationCount - 1
        }))
    }
    registerNotifications() {
        let notificationListRef = this.database.ref('/notifications/notification_list/user_' + (JSON.parse(Storage.get('imnLoginData'))).id);

        notificationListRef.on('child_added', (snapshot) => {
            let notificationList = this.state.notificationList;
            let tempData = snapshot.val();
            if (tempData.read_status === 0) {
                this.incrementUnreadNotificationCount();
            }
            tempData['nodeUrl'] = '/notifications/notification_list/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '/' + snapshot.key;
            notificationList.push(tempData);
            this.setState({ notificationList: notificationList, });

        });
        notificationListRef.on("child_changed", (snapshot) => {
            let changedNotification = snapshot.val();
            console.log("child_changed=>", snapshot.val());
            let notificationList = this.state.notificationList.map(item => {
                if (item.nodeUrl.includes(`/${snapshot.key}`)) {
                    // if read_status === 1 then decrement unread notification count because user read this notification
                    if (changedNotification.read_status === 1) {
                        this.decrementUnreadNotificationCount();
                    }
                    return { ...item, ...changedNotification }
                }
                return item
            });
            this.setState({ notificationList })
        });
        notificationListRef.on("child_removed", (snapshot) => {
            let deleteNotification = snapshot.val();
            let notificationList = this.state.notificationList.filter(item => !item.nodeUrl.includes(`/${snapshot.key}`));
            this.setState({ notificationList });
            if (deleteNotification.read_status === 0) this.decrementUnreadNotificationCount();
        });
    }
    viewChat = (e, notificationData, ...rest) => {
        e.preventDefault();
        this.readStatusUpdate(e, ...rest);
        const { id, order_id, sender_user_id, sender_full_name } = notificationData;
        this.props.openChatBox({
            chat_box_id: `${id}_${sender_user_id}`, //order_id _
            chat_info: {
                order_id: id,
                order_no: order_id,
                receiver_info: {
                    user_id: sender_user_id,
                    name: sender_full_name,
                    profile_image: ""
                }
            }
        })
    }

    renderOrderType = (hire_by_user_id) => {
        if (hire_by_user_id === (JSON.parse(Storage.get('imnLoginData'))).id)
            return ''
        else
            return 'accepted'
    }

    getChangeDateType(flag){
        if (flag == "1") return "start date";
        if (flag == "2") return "end date";
        if (flag == "3") return "start date & end date";
    }
    
    showNotifications() {
        // console.log(this.state.notificationList)
        if (this.state.notificationList.length > 0) {
            let notifications = this.state.notificationList.map((item, i) => {
                return (
                    <li key={'notificationList-' + i} className={item.read_status === 0 ? "unread" : ""}>
                        {this.state.editPermission ?
                            <React.Fragment>
                                <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link>
                                {item.notification_type == "msgComposeReply" &&
                                    <Link to={{
                                        pathname: '/independent-mover/dashboard/messages/inbox-details/' + item.msg_id,
                                        state: {}
                                    }}
                                        onClick={(e) => this.readStatusUpdate(e, item.nodeUrl, i, 'notificationList')}
                                        className="p-0" replace
                                    >
                                        {
                                            item.type == "compose"
                                            &&
                                            <div>
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">{item.subject}</span>
                                                    {/* <span className="media-annotation pull-right">{DateFormat.getFormattedDateTime(item.msg_time, 'm-d-Y')}</span> */}
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                <p className="text-muted">{item.sender_full_name} has sent you a message</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.msg_time, 'm-d-Y')}</p>
                                            </div>
                                        }
                                        {
                                            item.type == "reply"
                                            &&
                                            <div>
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">{item.subject}</span>
                                                    {/* <span className="media-annotation pull-right">{DateFormat.getFormattedDateTime(item.msg_time, 'm-d-Y')}</span> */}
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                <p className="text-muted">{item.sender_full_name} has sent you a reply</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.msg_time, 'm-d-Y')}</p>
                                            </div>
                                        }
                                    </Link>
                                }
                                {
                                    item.notification_type == "connectToMover"
                                    &&
                                    <Link to={{
                                        pathname: "/independent-mover/dashboard/messages/inbox-details/" + item.msg_id,
                                        state: {
                                            user_id: item.sender_user_id,
                                            // profile_id: item.sender_profile_name
                                        }
                                    }}
                                        onClick={(e) => this.readStatusUpdate(e, item.nodeUrl, i, 'notificationList')}
                                        className="p-0" replace>
                                        <p className="text-muted">{item.sender_full_name} wants to connect with you </p>
                                        <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                    </Link>
                                }
                                {
                                    (item.notification_type == "orderReceive" || item.notification_type == "offersReceive" || item.notification_type == "reOpenProposal")
                                    &&
                                    <React.Fragment>
                                        {this.state.edit_rcvOffers ?
                                            <Link to={{
                                                pathname: '/independent-mover/dashboard/my-offer-details',
                                                state: {
                                                    id: item.id,
                                                    type: "received"
                                                }
                                            }}
                                                onClick={(e) => this.readStatusUpdate(e, item.nodeUrl, i, 'notificationList')}
                                                className="p-0" replace
                                            >
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">#{item.order_id}</span>
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                <p className="text-muted">{item.sender_full_name}
                                                    {item.notification_type == "reOpenProposal" ? ' has re-opended your offer request' : ' has sent you an offer request'}
                                                </p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                            </Link>
                                            :
                                            <Link to='#' className="p-0">
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">#{item.order_id}</span>
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                <p className="text-muted">{item.sender_full_name}  has sent you an offer request</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                            </Link>
                                        }
                                    </React.Fragment>
                                }
                                {
                                    (item.notification_type == "reOpenMove")
                                    &&
                                    <React.Fragment>
                                        {this.state.edit_rcvOffers ?
                                            <Link to={{
                                                pathname: '/independent-mover/dashboard/my-offer-details',
                                                state: {
                                                    id: item.id,
                                                    type: "received"
                                                }
                                            }}
                                                onClick={(e) => this.readStatusUpdate(e, item.nodeUrl, i, 'notificationList')}
                                                className="p-0" replace
                                            >
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">#{item.order_id}</span>
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                <p className="text-muted">{item.sender_full_name} has opened your offer</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                            </Link>
                                            :
                                            <Link to='#' className="p-0">
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">#{item.order_id}</span>
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                <p className="text-muted">{item.sender_full_name} has opened your offer</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                            </Link>
                                        }
                                    </React.Fragment>
                                }

                                {
                                    (item.notification_type == "changeOrderDate")
                                    &&
                                    <Link to={{
                                                pathname: '/independent-mover/dashboard/my-offer-details',
                                                state: {
                                                    id: item.id,
                                                    type: "received"
                                                }
                                            }}
                                        onClick={(e) => this.readStatusUpdate(e, item.nodeUrl, i, 'notificationList')} 
                                        className="p-0" replace
                                    >
                                        <div className="clearfix p-b-5">
                                            <span className="text-semibold pull-left">#{item.order_id}</span>
                                            {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                        </div>
                                        <p className="text-muted">{item.sender_full_name} has changed {this.getChangeDateType(item.flag)} Please review & accept
                                        </p>
                                        <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                    </Link>

                                }

                                {
                                    (item.notification_type == "orderAwarded")
                                    &&
                                    <React.Fragment>
                                        {this.state.edit_rcvOffers ?
                                            <Link to={{
                                                pathname: '/independent-mover/dashboard/my-offer-details',
                                                state: {
                                                    id: item.id,
                                                    // type: item.type
                                                    type: "received",
                                                    // orderType: 0
                                                }
                                            }}
                                                onClick={(e) => this.readStatusUpdate(e, item.nodeUrl, i, 'notificationList')}
                                                className="p-0" replace
                                            >
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">#{item.order_id}</span>
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                <p className="text-muted">{item.sender_full_name} has awarded you a order</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                            </Link>
                                            :
                                            <Link to='#' className="p-0">
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">#{item.order_id}</span>
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                <p className="text-muted">{item.sender_full_name} has awarded you a order</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                            </Link>
                                        }
                                    </React.Fragment>
                                }
                                {
                                    (item.notification_type == "closeProposal")
                                    &&
                                    <React.Fragment>
                                        {this.state.edit_rcvOffers ?
                                            <Link to={{
                                                pathname: '/independent-mover/dashboard/my-offer-details',
                                                state: {
                                                    id: item.id,
                                                    type: 'received',
                                                    post_date: item.request_date_time,
                                                    post_by: item.posted_by,
                                                    orderType: this.state.orderType,
                                                    actionType: 'SEND_PROPOSAL',
                                                    customer_id: item.customer_id
                                                }
                                            }}
                                                onClick={(e) => this.readStatusUpdate(e, item.nodeUrl, i, 'notificationList')}
                                                className="p-0" replace
                                            >
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">#{item.order_id}</span>
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                <p className="text-muted">This job is closed for sending proposals</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                            </Link>
                                            :
                                            <Link to='#' className="p-0">
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">#{item.order_id}</span>
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                <p className="text-muted">{item.sender_full_name} has closed your offer</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                            </Link>
                                        }
                                    </React.Fragment>
                                }
                                {
                                    (item.notification_type == "cancelJob")
                                    &&
                                    <React.Fragment>
                                        {this.state.edit_rcvOffers ?
                                            <Link to={{
                                                pathname: '/independent-mover/dashboard/closed-order-details',
                                                state: {
                                                    id: item.id,
                                                    type: this.renderOrderType(item.hire_by_user_id)
                                                }
                                            }}
                                                onClick={(e) => this.readStatusUpdate(e, item.nodeUrl, i, 'notificationList')}
                                                className="p-0" replace
                                            >
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">#{item.order_id}</span>
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                <p className="text-muted">{item.sender_full_name} has cancelled your order</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                            </Link>
                                            :
                                            <Link to='#' className="p-0">
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">#{item.order_id}</span>
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                <p className="text-muted">{item.sender_full_name} has cancelled your order</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                            </Link>
                                        }
                                    </React.Fragment>
                                }
                                {
                                    (item.notification_type == "acceptRejectOffer" || item.notification_type == "acceptRejectOrder")
                                    &&
                                    <React.Fragment>
                                        {this.state.manage_orders ?
                                            <Link to={{
                                                pathname: '/independent-mover/dashboard/order-details',
                                                state: {
                                                    id: item.id,
                                                    type: item.type
                                                }
                                            }} onClick={(e) => this.readStatusUpdate(e, item.nodeUrl, i, 'notificationList')}
                                                className="p-0" replace
                                            >
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">#{item.order_id}</span>
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                <p className="text-muted">{item.sender_full_name} has {item.activeFlag == 1 ? 'accepted' : 'rejected'} your offer</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                            </Link>
                                            :
                                            <Link to='#' className="p-0">
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">#{item.order_id}</span>
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                {/* {JSON.stringify(item.notification_type)} */}
                                                <p className="text-muted">{item.sender_full_name} has {item.activeFlag == 1 ? 'accepted' : 'rejected'} your offer</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                            </Link>

                                        }
                                    </React.Fragment>
                                }
                                {
                                    item.notification_type == "markAsComplete"
                                    &&
                                    <React.Fragment>
                                        {this.state.manage_orders ?
                                            <Link to={{
                                                pathname: '/independent-mover/dashboard/closed-order-details',
                                                state: {
                                                    id: item.id,
                                                    type: this.renderOrderType(item.hire_by_user_id)
                                                }
                                            }} onClick={(e) => this.readStatusUpdate(e, item.nodeUrl, i, 'notificationList')}
                                                className="p-0" replace
                                            >
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">#{item.order_id}</span>
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                <p className="text-muted">{item.sender_full_name} has marked your order as completed</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                            </Link>
                                            :
                                            <Link to='#' className="p-0">
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">#{item.order_id}</span>
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                {/* {JSON.stringify(item.notification_type)} */}
                                                <p className="text-muted">{item.sender_full_name} has marked your order as completed</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                            </Link>

                                        }
                                    </React.Fragment>
                                }
                                {
                                    item.notification_type == "acceptRejectGMAApplication"
                                    &&
                                    <Link to="/independent-mover/dashboard/military-approved" className="p-0">
                                        <div className="clearfix p-b-5">
                                            {/* <span className="text-semibold pull-left">#{item.order_id}</span> */}
                                            {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                        </div>
                                        <p className="text-muted">{item.sender_full_name} has {item.status == 2 ? 'accepted' : 'rejected'} your Myrelo Review Application</p>
                                        <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                    </Link>
                                }
                                {
                                    item.notification_type == "askQuestion"
                                    &&
                                    <p className="text-muted">{item.sender_full_name} has sent you a reply</p>
                                }
                                {
                                    item.notification_type == "orderChat"
                                    &&
                                    <React.Fragment>
                                        {this.state.edit_msg ?
                                            <Link
                                                to="#"
                                                onClick={(e) => this.viewChat(e, item, item.nodeUrl, i, 'notificationList')}>
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">#{item.order_id}</span>

                                                </div>
                                                <p className="text-muted">{item.sender_full_name} has sent you a message.</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                            </Link>
                                            :
                                            <Link to="#">
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">#{item.order_id}</span>

                                                </div>
                                                <p className="text-muted">{item.sender_full_name} has sent you a message.</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                            </Link>
                                        }
                                    </React.Fragment>
                                }
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link>
                                {item.notification_type == "msgComposeReply" &&
                                    <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} className="p-0">
                                        {
                                            item.type == "compose"
                                            &&
                                            <div>
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">{item.subject}</span>
                                                    {/* <span className="media-annotation pull-right">{DateFormat.getFormattedDateTime(item.msg_time, 'm-d-Y')}</span> */}
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                <p className="text-muted">{item.sender_full_name} has sent you a message</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.msg_time, 'm-d-Y')}</p>
                                            </div>
                                        }
                                        {
                                            item.type == "reply"
                                            &&
                                            <div>
                                                <div className="clearfix p-b-5">
                                                    <span className="text-semibold pull-left">{item.subject}</span>
                                                    {/* <span className="media-annotation pull-right">{DateFormat.getFormattedDateTime(item.msg_time, 'm-d-Y')}</span> */}
                                                    {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                                </div>
                                                <p className="text-muted">{item.sender_full_name} has sent you a reply</p>
                                                <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.msg_time, 'm-d-Y')}</p>
                                            </div>
                                        }
                                    </Link>
                                }
                                {
                                    item.notification_type == "connectToMover"
                                    &&
                                    <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} className="p-0">
                                        <p className="text-muted">{item.sender_full_name} wants to connect with you </p>
                                        <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                    </Link>
                                }
                                {
                                    (item.notification_type == "orderReceive" || item.notification_type == "offersReceive" || item.notification_type == "reOpenProposal")
                                    &&
                                    <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} className="p-0">
                                        <div className="clearfix p-b-5">
                                            <span className="text-semibold pull-left">#{item.order_id}</span>
                                            {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                        </div>
                                        <p className="text-muted">{item.sender_full_name}
                                            {item.notification_type == "reOpenProposal" ? ' has re-opended your offer request' : ' has sent you an offer request'}
                                        </p>
                                        <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                    </Link>
                                }
                                {
                                    (item.notification_type == "reOpenMove")
                                    &&
                                    <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} className="p-0">
                                        <div className="clearfix p-b-5">
                                            <span className="text-semibold pull-left">#{item.order_id}</span>
                                            {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                        </div>
                                        <p className="text-muted">{item.sender_full_name} has opened your offer</p>
                                        <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                    </Link>
                                }
                                {
                                    (item.notification_type == "orderAwarded")
                                    &&
                                    <React.Fragment>
                                        <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} className="p-0">
                                            <div className="clearfix p-b-5">
                                                <span className="text-semibold pull-left">#{item.order_id}</span>
                                                {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                            </div>
                                            <p className="text-muted">{item.sender_full_name} has awarded you a order</p>
                                            <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                        </Link>
                                    </React.Fragment>
                                }
                                {
                                    item.notification_type == "markAsComplete"
                                    &&
                                    <React.Fragment>
                                        <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} className="p-0">
                                            <div className="clearfix p-b-5">
                                                <span className="text-semibold pull-left">#{item.order_id}</span>
                                                {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                            </div>
                                            <p className="text-muted">{item.sender_full_name} has marked your order as completed</p>
                                            <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                        </Link>
                                    </React.Fragment>
                                }
                                {
                                    (item.notification_type == "closeProposal")
                                    &&
                                    <React.Fragment>
                                        <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} className="p-0">
                                            <div className="clearfix p-b-5">
                                                <span className="text-semibold pull-left">#{item.order_id}</span>
                                                {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                            </div>
                                            <p className="text-muted">This job is closed for sending proposals</p>
                                            <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                        </Link>
                                    </React.Fragment>
                                }
                                {
                                    (item.notification_type == "cancelJob")
                                    &&
                                    <React.Fragment>
                                        <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} className="p-0">
                                            <div className="clearfix p-b-5">
                                                <span className="text-semibold pull-left">#{item.order_id}</span>
                                                {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                            </div>
                                            <p className="text-muted">{item.sender_full_name} has cancelled your order</p>
                                            <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                        </Link>
                                    </React.Fragment>
                                }
                                {
                                    (item.notification_type == "acceptRejectOffer" || item.notification_type == "acceptRejectOrder")
                                    &&
                                    <Link to="/independent-mover/dashboard/my-subscription" onClick={this.doSubscribe} className="p-0">
                                        <div className="clearfix p-b-5">
                                            <span className="text-semibold pull-left">#{item.order_id}</span>
                                            {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                        </div>
                                        <p className="text-muted">{item.sender_full_name} has {item.activeFlag == 1 ? 'accepted' : 'rejected'} your offer</p>
                                        <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                    </Link>
                                }
                                {
                                    item.notification_type == "askQuestion"
                                    &&
                                    <p className="text-muted">{item.sender_full_name} has sent you a reply</p>
                                }
                                {
                                    item.notification_type == "acceptRejectGMAApplication"
                                    &&
                                    <Link to="/independent-mover/dashboard/my-subscription"
                                        onClick={this.doSubscribe} className="p-0">
                                        <div className="clearfix p-b-5">
                                            {/* <span className="text-semibold pull-left">#{item.order_id}</span> */}
                                            {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                        </div>
                                        <p className="text-muted">{item.sender_full_name} has {item.status == 2 ? 'accepted' : 'rejected'} your Myrelo Review Application</p>
                                        <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                    </Link>
                                }
                                {
                                    item.notification_type == "orderChat"
                                    &&
                                    <Link
                                        to="/independent-mover/dashboard/my-subscription"
                                        onClick={this.doSubscribe}
                                    >
                                        <div className="clearfix p-b-5">
                                            <span className="text-semibold pull-left">#{item.order_id}</span>
                                            {/* <Link to="#" className="text-semibold pull-right text-warning p-0" onClick={(e) => this.clearIndividualNotification(e, item.nodeUrl, i, 'notificationList')}><i className="icon-cross"></i></Link> */}
                                        </div>
                                        <p className="text-muted">{item.sender_full_name} has sent you a message.</p>
                                        <p className="media-annotation text-right m-0">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</p>
                                    </Link>
                                }
                            </React.Fragment>
                        }
                    </li>

                )

            });
            return notifications.reverse();
        }
    }


    // /* Start Notification */
    // registerNotifications(){
    //     this.database.ref('/notifications/offers_received/user_' + (JSON.parse(Storage.get('imnLoginData'))).id)
    //     .on('child_added', (snapshot) => {
    //         this.setState({notificationStatus:'receiveOffer'});
    //         let receivedOfferNotifications = this.state.receivedOfferNotifications;
    //         let tempData = snapshot.val();
    //         tempData['nodeUrl'] = '/notifications/offers_received/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '/' + snapshot.key;            
    //         receivedOfferNotifications.push(tempData);
    //         this.setState({receivedOfferNotifications: receivedOfferNotifications}, () => {

    //         });
    //     })

    //     this.database.ref('/notifications/approval_ask_questions/user_' + (JSON.parse(Storage.get('imnLoginData'))).id)
    //     .on('child_added', (snapshot) => {
    //         this.setState({notificationStatus:'receiveMsg'});
    //         var receivedAnswer=this.state.receivedAnswer;
    //         let tempData = snapshot.val();
    //         PostDataWithParam('getUsername', {sender_id:tempData.sender_user_id})
    //         .then((response) => {
    //               if (response.success == 1) {
    //                 receivedAnswer.push({name:response.data[0].organization_name,time:tempData.time});
    //                 this.setState({receivedAnswer: receivedAnswer}, () => {       
    //                     this.renderReceivedMsg();
    //                     });
    //               }

    //         })

    //          let questionsNotification = this.state.questionsNotification;
    //          questionsNotification.msgCount++;
    //         this.setState({questionsNotification: questionsNotification});

    //     });


    //     this.database.ref('/notifications/offers_accepted_rejected/user_' + (JSON.parse(Storage.get('imnLoginData'))).id)
    //     .on('child_added', (snapshot) => {
    //         this.setState({notificationStatus:'offer'});
    //         let acceptedRejectedNotifications = this.state.acceptedRejectedNotifications;
    //         let tempData = snapshot.val();
    //         tempData['nodeUrl'] = '/notifications/offers_accepted_rejected/user_' + (JSON.parse(Storage.get('imnLoginData'))).id + '/' + snapshot.key;            
    //         acceptedRejectedNotifications.push(tempData);
    //         this.setState({acceptedRejectedNotifications: acceptedRejectedNotifications}, () => {

    //         });
    //     })

    //     this.database.ref('/notifications/msg_compose_reply/user_' + (JSON.parse(Storage.get('imnLoginData'))).id)
    //     .on('child_added', (snapshot) => {
    //         this.setState({notificationStatus:'message'});

    //         let msgComposeReply = this.state.msgComposeReply;
    //         let tempData = snapshot.val();
    //         tempData['nodeUrl'] = '/notifications/msg_compose_reply/user_' + (JSON.parse(Storage.get('imnLoginData'))).id+'/'+snapshot.key;

    //         msgComposeReply.push(tempData);

    //         let showMsg = "";
    //         if(tempData.type == "compose")
    //         showMsg = tempData.sender_full_name + ' has sent you a message';
    //         else if(tempData.type == "reply")
    //         showMsg = tempData.sender_full_name + ' has sent you a reply';

    //         this.setState({msgComposeReply: msgComposeReply}, () => {

    //             this.props.getInboxCount();
    //         });
    //     })

    //     this.database.ref('/notifications/msg_compose_reply/user_' + (JSON.parse(Storage.get('imnLoginData'))).id)
    //     .on('child_removed', (snapshot) => {
    //         let msg = this.state.msgComposeReply.filter((item, i) => {
    //             return item.msg_id != snapshot.val().msg_id
    //         })

    //         this.setState({ msgComposeReply: msg });
    //     })
    // }




    renderReceivedOfferNotifications() {
        if (this.state.receivedOfferNotifications.length > 0) {
            let notifications = this.state.receivedOfferNotifications.map((item, i) => {
                return (
                    <li key={'receivedOfferNotifications-' + i}>
                        <Link to={{
                            pathname: '/independent-mover/dashboard/my-offer-details',
                            state: {
                                id: item.id,
                                type: item.type
                            }
                        }} onClick={(e) => this.clearNotification(e, item.nodeUrl, i, 'receivedOfferNotifications')}>
                            <div className="clearfix">
                                <span className="text-semibold pull-left">#{item.order_id.replace(/['"]+/g, '')}</span>
                                <span className="media-annotation pull-right">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</span>
                            </div>
                            <p className="text-muted">{item.sender_name} has sent you a request for service</p>
                        </Link>
                    </li>
                )
            });
            return notifications.reverse();
        }
    }

    renderMsgComposeReplyNotifications() {
        if (this.state.msgComposeReply.length > 0) {
            let notifications = this.state.msgComposeReply.map((item, i) => {
                return (
                    <li key={'msgComposeReply-' + i}>
                        <Link to={{
                            pathname: '/independent-mover/dashboard/messages/inbox-details/' + item.msg_id,
                            state: {}
                        }} onClick={(e) => this.clearNotification(e, item.nodeUrl, i, 'msgComposeReply')}>
                            <div className="clearfix">
                                <span className="text-semibold pull-left">{item.subject}</span>
                                <span className="media-annotation pull-right">{DateFormat.getFormattedDateTime(item.msg_time, 'm-d-Y')}</span>
                            </div>
                            {
                                item.type == "compose"
                                &&
                                <p className="text-muted">{item.sender_full_name} has sent you a message</p>
                            }
                            {
                                item.type == "reply"
                                &&
                                <p className="text-muted">{item.sender_full_name} has sent you a reply</p>
                            }
                        </Link>
                    </li>
                )
            });
            return notifications.reverse();
        }
    }

    renderReceivedMsg() {
        if (this.state.receivedAnswer.length > 0) {
            let notifications = this.state.receivedAnswer.map((item, i) => {
                return (
                    <li key={'receivedAnswer-' + i}>
                        <div className="clearfix">
                            <span className="media-annotation pull-right">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</span>
                        </div>
                        <p className="text-muted">{item.name} has sent you a reply</p>

                    </li>
                )
            });
            return notifications.reverse();
        }
    }
    renderAcceptedRejectedOfferNotifications() {
        if (this.state.acceptedRejectedNotifications.length > 0) {
            let notifications = this.state.acceptedRejectedNotifications.map((item, i) => {
                return (
                    <li key={'acceptedRejectedNotifications-' + i}>
                        <Link to={{
                            pathname: '/independent-mover/dashboard/order-details',
                            state: {
                                id: item.id,
                                type: item.type
                            }
                        }} onClick={(e) => this.clearNotification(e, item.nodeUrl, i, 'acceptedRejectedNotifications')}>
                            <div className="clearfix">
                                <span className="text-semibold pull-left">#{item.order_id}</span>
                                <span className="media-annotation pull-right">{DateFormat.getFormattedDateTime(item.time, 'm-d-Y')}</span>
                            </div>
                            <p className="text-muted">{item.sender_name} has accepted your offer</p>
                        </Link>
                    </li>
                )
            });
            return notifications.reverse();
        }
    }

    clearNotification(e, itemUrl, index, stateName) {

        this.database.ref(itemUrl).remove();

        // let arr = this.state[stateName];

        // for (let i = 0; i < arr.length; i++) {
        //     if (i == index) {
        //         arr.splice(i, 1);
        //     }
        // }

        // this.setState({ [stateName]: arr });
    }

    clearIndividualNotification(e, itemUrl, index, stateName) {
        this.database.ref(itemUrl).remove();

        // let arr = this.state[stateName];

        // for (let i = 0; i < arr.length; i++) {
        //     if (i == index) {
        //         arr.splice(i, 1);
        //     }
        // }

        // this.setState({ [stateName]: arr }, () => {
        //     // window.$("#msgShow").toggle();
        // });
    }

    /* End Notification */

    getNavLinkClass = (path, exact = true) => {
        if (exact)
            return this.props.location.pathname === path ? 'selected' : '';
        else {
            return ((this.props.location.pathname).indexOf(path) !== -1) ? 'selected' : '';
        }
    }

    GetUserInfo = () => {
        GetData('GetUserInfo', {})
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ gma_subscription_id: response.data.gma_subscription_id });
                }
            })
            .catch(err => { })
    }

    closeSubscripInfo = () => {
        window.$(".subscrip-info").hide();
    }

    componentWillUnmount() {
        if (this.subscriptionCheckInterval) {
            clearInterval(this.subscriptionCheckInterval)
        }
        if (this.staffMenuCheckInterval) {
            clearInterval(this.staffMenuCheckInterval)
        }
    }

    clearAllNotification = () => {

        this.database.ref('/notifications/notification_list/user_' + (JSON.parse(Storage.get('imnLoginData'))).id)
            .remove();
        this.setState({ notificationList: [] })
    }

    // ------------- help work start -----------------

    handleOnChange = (e) => {
        let form_data = Object.assign({}, this.state.form_data);
        form_data[e.target.name] = e.target.value;
        if (e.target.name == 'mobile_no') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                form_data[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
        }
        this.setState({ form_data: form_data })
    }
    uploadFiles = (e) => {
        this.setState({ filesModal: e.target.files })
        let form_data = { ...this.state.form_data }
        this.setState({ form_data })
        if (e.target.files.length > 0) {
            let formData = new FormData();
            for (let i = 0; i < e.target.files.length; i++) {
                formData.append('request_files[' + i + ']', e.target.files[i], e.target.files[i].name);
            }
            formData.append('id', this.state.form_data.id);
            formData.append('user_name', this.state.form_data.user_name);
            // formData.append('mobile_no', parseInt(this.state.form_data.mobile_no!=='' && this.state.form_data.mobile_no.replace(/[^\d]/g, '')));
            formData.append('mobile_no', this.state.form_data.mobile_no);
            formData.append('comments', this.state.form_data.comments);
            formData.append('support_requirement', this.state.form_data.support_requirement);
            formData.append('profile_id', this.state.form_data.profile_id);
            formData.append('email_id', this.state.form_data.email_id);
            formData.append('send_mail', 0);


            this.setState({ isProccesingFileUpload: true }, () => {
                FileUploadWithProgress('SaveSupportRequirement', formData, (e) => {
                    var percent = Math.round((e.loaded / e.total) * 100);
                    this.setState({ uploadPercentage: percent })
                })
                    .then(response => {
                        if (response.success == 1) {
                            let form_data = { ...this.state.form_data };
                            form_data.id = response.data.id;

                            this.setState({ form_data, filesView: response.data.request_file, isProccesingFileUpload: false, uploadPercentage: 0 }, () => {
                            }, () => {
                            });
                        } else {
                            this.setState({ isProccesingFileUpload: false, filesView: [] });
                            window.swal('Error!', 'Something went wrong.', 'error');
                        }
                    })
                    .catch(err => {
                        // console.log('err', err)
                        this.setState({ isProccesingFileUpload: false, filesView: [] });
                        window.swal('Error!', 'Something went wrong.', 'error')
                    });
            })

        }

    }
    saveHelpModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.form_data.user_name.length == '') this.setState({ user_name_err: 'Please enter your name' })
        else if (this.state.form_data.email_id == '') this.setState({ email_id_err: 'Please enter email' })
        else {
            let form_data = { ...this.state.form_data }
            this.setState({ form_data })
            let formData = new FormData();
            formData.append('id', this.state.form_data.id);
            formData.append('user_name', this.state.form_data.user_name);
            // formData.append('mobile_no', parseInt(this.state.form_data.mobile_no!=='' && this.state.form_data.mobile_no.replace(/[^\d]/g, '')));
            formData.append('mobile_no', this.state.form_data.mobile_no);
            formData.append('comments', this.state.form_data.comments);
            formData.append('support_requirement', this.state.form_data.support_requirement);
            formData.append('profile_id', this.state.form_data.profile_id);
            formData.append('email_id', this.state.form_data.email_id);
            formData.append('request_files', []);
            formData.append('send_mail', 1);

            this.setState({ isFileSaving: true }, () => {
                FileUploadWithProgress('SaveSupportRequirement', formData, (e) => { })
                    .then(response => {
                        if (response.success == 1) {
                            let form_data = { ...this.state.form_data };
                            form_data.comments = '';
                            form_data.user_name = JSON.parse(Storage.get('imnLoginData')).first_name + " " + JSON.parse(Storage.get('imnLoginData')).last_name;
                            form_data.mobile_no = JSON.parse(Storage.get('imnLoginData')).contact_no.trim();
                            form_data.email_id = JSON.parse(Storage.get('imnLoginData')).email;
                            form_data.support_requirement = 0;
                            form_data.request_files = [];
                            form_data.id = '';
                            this.setState({ filesModal: [], form_data, isFileSaving: false, filesView: [], user_name_err: '', email_id_err: '' });
                            window.swal('Success!', response.msg, 'success');
                            window.$('#helpModal').modal('hide');
                            window.$('#helpModal').trigger('reset');
                        } else {
                            this.setState({ form_data, isFileSaving: false, filesView: [], user_name_err: '', email_id_err: '' });
                            window.swal('Error!', response.msg, 'error');
                        }
                    })
                    .catch(err => {
                        // console.log('err', err)
                        this.setState({ form_data, isFileSaving: false, filesView: [], user_name_err: '', email_id_err: '' });
                        window.swal('Error!', 'Something Went Wrong', 'error')
                    });
            })

        }

    }
    getFileName(file) {
        if (typeof (file.file_path) !== 'undefined') {
            let files = file.file_path.split("/");
            return files[files.length - 1];
        }
        else {
            let files = file.split("/")
            return files[files.length - 1]
        }
    }

    deleteFileModal(id, e) {
        e.preventDefault();
        ConfirmBox("You want to delete this file!").then((result) => {
            if (result) {
                PostDataWithParam("DeleteSupportRequirementFile", { id: id })
                    .then((response) => {
                        if (response.success == 1) {
                            window.swal("Deleted!", response.msg, "success");
                            this.getFilesModal();
                        }
                    })
                    .catch((err) => { });
            }
        });
    }

    getFilesModal() {
        PostDataWithParam("GetSupportRequirementFile", {
            id: this.state.form_data.id
        })
            .then((response) => {
                if (response.success == 1) {
                    // console.log(response.data.length)
                    if (response.data.length != 0)
                        this.setState({ filesView: response.data });
                    else
                        this.setState({ filesView: response.data });
                } else {
                    this.setState({ filesView: [] });
                }
            })
            .catch((err) => {
                this.setState({ filesView: [] });
            });
    }

    renderFilesModal() {
        // console.log('this.state.filesView',this.state.filesView)
        if (this.state.filesView.length > 0) {

            let files = this.state.filesView.map((file, i) => {
                return (
                    <li key={"item-" + i}>
                        <div className={"file-wrapper " + FileType(file.file_type)}>
                            <div className="overlay-file">
                                <a href="" className="text-danger" onClick={this.deleteFileModal.bind(this, file.id)}>
                                    <i className="icon-cancel-circle2"></i>
                                    <span className="show">Delete</span>
                                </a>
                            </div>
                            {FileSvg(file.file_type, file.file_path)}
                            <div className="file-title">
                                <a href={file.file_path}>
                                    <i
                                        className={
                                            FileIcon(file.file_type) + " position-left text-primary"
                                        }
                                    ></i>{" "}
                                    {this.getFileName(file)}
                                </a>
                            </div>
                        </div>

                    </li>

                );
            });

            return files;
        } else {
            if (this.state.isProccesingFileUpload == false)
                return <h6 className="alert alert-primary no-border m-r-15">No files were found.</h6>;
        }

    }
    openHelpModal = (data) => {
        this.setState({
            form_data: {
                ...this.state.form_data,
                ...data
            }
        }, () => {
            window.$('#helpModal').modal('show');
        });

    }
    // ------------- help work end -------------------
    readStatusUpdate = (e, itemUrl, index, stateName) => {
        let arr = [...this.state[stateName]];
        let item = arr[index];
        if ("read_status" in item && item.read_status == 0) {
            this.database.ref(itemUrl).update({
                read_status: 1
            });

        }
    }
    render() {

        if (this.state.logoutStatus) {
            return <Redirect to="/login" />
        }

        let subscriptionInfo = null;
        if (this.props.location.pathname === "/independent-mover/dashboard/home" || this.props.location.pathname === "/independent-mover/dashboard") {

            if (this.state.subscription_id == 2 && this.state.trial_subscription_id == 1)
                subscriptionInfo = (
                    <div className="text-center subscrip-info">

                        {this.state.daysToExpire == 0 &&
                            <p className="m-0 f-s-14">Your trial period will expire today. <Link to="/independent-mover/dashboard/my-subscription" className="text-primary" onClick={() => this.closeSubscripInfo()}><u>Upgrade NOW!</u></Link></p>
                        }
                        {this.state.daysToExpire != 0 &&
                            <p className="m-0 f-s-14">{this.state.daysToExpire} days left for your trial period to expire. <Link to="/independent-mover/dashboard/my-subscription" className="text-primary" onClick={() => this.closeSubscripInfo()}><u>Upgrade Now!</u></Link></p>
                        }

                    </div>
                )

            if (this.state.subscription_id == 2 && this.state.trial_subscription_id == 0)
                subscriptionInfo = (
                    <React.Fragment>
                        {(this.state.daysToExpire == 0) &&
                            <div className="text-center subscrip-info">
                                <p className="m-0 f-s-14">Your subscription will expire today. <Link to="/independent-mover/dashboard/my-subscription" className="text-primary" onClick={() => this.closeSubscripInfo()}><u>Upgrade NOW!</u></Link></p>
                            </div>
                        }
                        {(this.state.daysToExpire != 0 && this.state.daysToExpire <= 7) &&
                            <div className="text-center subscrip-info">
                                <p className="m-0 f-s-14">{this.state.daysToExpire} days left for your subscription to expire. <Link to="/independent-mover/dashboard/my-subscription" className="text-primary" onClick={() => this.closeSubscripInfo()}><u>Upgrade Now!</u></Link></p>
                            </div>
                        }
                    </React.Fragment>
                )

            if (this.state.subscription_id == 1)
                subscriptionInfo = (
                    <div className="text-center subscrip-info">
                        <p className="m-0 f-s-14">Your trial period has expired. <Link to="/independent-mover/dashboard/my-subscription" className="text-primary" onClick={() => this.closeSubscripInfo()}><u>Upgrade NOW!</u></Link></p>
                    </div>
                )

        }

        return (
            <React.Fragment>
                <div className="navbar navbar-default navbar-fixed-top header-highlight">
                    {subscriptionInfo}
                    <div className="navbar-collapse collapse" id="navbar-mobile">
                        <ul className="nav navbar-nav">
                            <li className="hidden-xs"><a className="sidebar-control sidebar-main-toggle"><i className="icon-paragraph-justify3"></i></a></li>
                            <li className="visible-xs"><a className="sidebar-mobile-main-toggle"><i className="icon-paragraph-justify3"></i></a></li>
                        </ul>
                        {/* <div className="header-search">
                        <input type="text" id="hot_search_class_input" className="form-control" placeholder="Search.." />
                        <button type="button" className="btn"><i className="icon-arrow-right8"></i></button>
                        <a href="" id="close-searchbar"><i className="icon-cross2"></i></a>
                    </div> */}
                        {/* <div className="header-search visible-lg" style={{ width: "290px" }}></div> */}
                        <ul className="tabrow nav navbar-nav visible-lg">
                            <li className={this.getNavLinkClass("/independent-mover/dashboard/home")}><Link to="/independent-mover/dashboard" className="text-uppercase">Dashboard</Link></li>
                            {
                                (this.state.gma && this.state.gma_subscription_id < 1) &&
                                <li className={this.getNavLinkClass("/independent-mover/dashboard/military-approved", false)}>
                                    <Link to={{
                                        pathname: "/independent-mover/dashboard/checkout",
                                        state: {
                                            gma_subscription_id: JSON.parse(Storage.get('imnLoginData')).gma_subscription_id,
                                            title: JSON.parse(Storage.get('imnLoginData')).gma_package_name,
                                            value: JSON.parse(Storage.get('imnLoginData')).gma_package_price,
                                            type: 'gmaSubscription'
                                        }
                                    }} onClick={this.doGMASubscribe}
                                        className="text-uppercase blink-zoom army-green-btn">get military approved</Link></li>
                            }
                            {
                                (this.state.gma && this.state.gma_subscription_id >= 1) &&
                                <li className={this.getNavLinkClass("/independent-mover/dashboard/military-approved", false)}><Link to="/independent-mover/dashboard/military-approved" className="text-uppercase blink-zoom army-green-btn">get military approved</Link></li>
                            }
                            {
                                this.state.staff_user_id == '' && <li className={this.getNavLinkClass("/independent-mover/dashboard/moverAppz")}><Link to="/independent-mover/dashboard/moverAppz" className="text-uppercase">My MoverAppz</Link></li>
                            }
                            <li className={this.getNavLinkClass("/independent-mover/dashboard/training-material")}><Link to="/independent-mover/dashboard/training-material" className="text-uppercase">My Training</Link></li>
                            <li className="header-btn-help"><Link to="" data-toggle="modal" data-target="#helpModal" className="text-uppercase"><i className="icon-help"></i> Help</Link></li>
                        </ul >

                        <ul className="nav navbar-nav navbar-right">

                            {/* Start Notification */}
                            {/* <li>
                            <h6 className="text-primary m-b-0 m-t-15">{(JSON.parse(Storage.get('imnLoginData'))).first_name} {(JSON.parse(Storage.get('imnLoginData'))).last_name}</h6>
                        </li> */}

                            {/* <li className="notification">
                            <Link to="/independent-mover/dashboard/inbox" ><img src="assets/images/icons/envelope.svg" alt="" width="15" />
                                <span className="badge bg-warning-400">{this.state.msgComposeReply.length}</span>
                            </Link></li> */}

                            {/* {this.state.edit_msg && */}
                            <li>
                                <Link to="/independent-mover/dashboard/all-messages" >
                                    {/* <i className="icon-inbox"></i> */}
                                    <img src="assets/images/icons/envelope.svg" alt="" width="15" />
                                    {/* <span>Messages</span> */}
                                    <span className="badge bg-warning-400">
                                        {/* {this.state.msgComposeReply.length} */}
                                        {this.state.msgComposeReply.length}
                                    </span>
                                </Link>
                            </li>
                            {/* } */}

                            <li className="notification dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false" onClick={() => {
                                    this.props.closeChatNotifications();
                                    this.props.closeChatBox();
                                }}>
                                    {/* <i className="icon-bell2"></i> */}
                                    <img src="assets/images/icons/notification.svg" width="15" />
                                    {/* <span className="visible-xs-inline-block position-right">Notifications</span> */}
                                    <span className="badge bg-warning-400">{this.state.unreadNotificationCount}</span>
                                </a>

                                <div className="dropdown-menu dropdown-content width-350">
                                    <div className="dropdown-content-heading">
                                        Notifications
                                        {this.state.notificationList.length > 0 &&
                                            <div className="header-right pull-right">
                                                <Link to="#" onClick={this.clearAllNotification}>Clear All</Link>
                                            </div>}
                                    </div>
                                    <hr className="m-0" />
                                    <ul className="media-list dropdown-content-body">
                                        {this.showNotifications()}
                                        {this.state.notificationList.length == 0 &&
                                            <li>
                                                <p className="text-muted">No new notifications</p>
                                            </li>
                                        }

                                    </ul>
                                    {/* End Notification */}
                                </div>
                            </li>

                            <li className="dropdown dropdown-user">
                                <a className="dropdown-toggle" data-toggle="dropdown">
                                    {
                                        (JSON.parse(Storage.get('imnLoginData'))).profile_image != ''
                                        &&
                                        <img src={(JSON.parse(Storage.get('imnLoginData'))).profile_image} alt="" />
                                    }
                                    {
                                        ((JSON.parse(Storage.get('imnLoginData'))).profile_image == '' || (JSON.parse(Storage.get('imnLoginData'))).profile_image == null)
                                        &&
                                        <img src="assets/images/default_avatar.jpg" alt="" />
                                    }
                                </a>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><Link to="/independent-mover/dashboard/myprofile"><i className="icon-user-plus"></i> My User Profile</Link></li>
                                    <li><Link to="/independent-mover/dashboard/military-approved/profile-tab"><i className="fa fa-building"></i> Company Profile</Link></li>
                                    <li><Link to="/independent-mover/dashboard/my-subscription"><i className="icon-box"></i> Manage Subscriptions</Link></li>
                                    <li><Link to="/independent-mover/dashboard/my-cardlist"><i className="icon-credit-card"></i> My Saved cards</Link></li>
                                    {this.state.staff_user_id == '' && <li className="hidden-lg"><Link to="/independent-mover/dashboard/moverAppz"><i className="icon-file-text2"></i> My MoverAppz</Link></li>}
                                    <li className="hidden-lg"><Link to="/independent-mover/dashboard/training-material"><i className="icon-keyboard"></i> My Training</Link></li>
                                    <li className="divider"></li>
                                    <li><Link to="/independent-mover/dashboard/change-password"><i className="icon-lock"></i> Change Password</Link></li>
                                    <li><a href="javascript:void(0)" onClick={this.handleLogout}><i className="icon-switch2"></i> Logout</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                </div>

                {/* Help Modal Start */}

                <div className="modal fade" id="helpModal" tabindex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">How Can We Help?</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <hr className="m-b-0" />
                            <div className="modal-body">
                                <div className="row">
                                    <form>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for=""><strong>Name</strong> <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" name="user_name" id="" onChange={this.handleOnChange.bind(this)} value={this.state.form_data.user_name} placeholder="" required />
                                                <p className="text-danger">{this.state.user_name_err}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for=""><strong>Phone</strong> </label>
                                                <input type="text" className="form-control" name="mobile_no" id="" onChange={this.handleOnChange.bind(this)} value={this.state.form_data.mobile_no} placeholder="xxx xxx xxxx" maxLength="10" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for=""><strong>Email address</strong> <span className="text-danger">*</span></label>
                                                <input type="text" className="form-control" name="email_id" id="" onChange={this.handleOnChange.bind(this)} value={this.state.form_data.email_id} placeholder="" required />
                                                <p className="text-danger">{this.state.email_id_err}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group m-b-10">
                                                <label for=""><strong>Support Requirement</strong> <span className="text-danger">*</span></label>
                                                <ul className="checkbox-list list-unstyled">
                                                    <li className="radio-inline m-r-30">
                                                        <label class="radio-inline p-l-20" for="customerSupportRadio"><input type="radio" id="customerSupportRadio" name="support_requirement" value="0" onChange={this.handleOnChange.bind(this)} checked={(this.state.form_data.support_requirement == 0) ? true : false} /> Customer Support</label>
                                                    </li>
                                                    <li className="radio-inline m-r-30">
                                                        <label class="radio-inline p-l-20" for="technicalSupportRadio"><input type="radio" id="technicalSupportRadio" name="support_requirement" value="1" onChange={this.handleOnChange.bind(this)} checked={(this.state.form_data.support_requirement == 1) ? true : false} /> Technical Support</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for=""><strong>Comments / Questions</strong> <span style={{ fontWeight: 300 }}>(Please attach any screenshots, videos or other files which will help our team expedite our response & solution)</span></label>
                                                <textarea className="form-control" name="comments" value={this.state.form_data.comments} onChange={this.handleOnChange.bind(this)} rows="2"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="file-manager horizontal-scroll admin-file-manager">
                                                <ul>
                                                    {this.renderFilesModal()}
                                                    {this.state.isProccesingFileUpload &&
                                                        <li>
                                                            <div className="file-wrapper">
                                                                <div className="progress-file-icon">
                                                                    <img src="assets/images/blur-inventory.jpg" alt="" />
                                                                    <div className="inventory-progess-bar">
                                                                        <CircularProgressbar value={this.state.uploadPercentage} text={`${this.state.uploadPercentage}%`} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>}
                                                </ul>
                                            </div>
                                            <br />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="btn btn-upload-file btn-xs">
                                                    <input type="file" name="files[]" onChange={this.uploadFiles} multiple disabled={this.state.isProccesingFileUpload ? true : false} /><i className="icon-attachment f-s-22"></i> &nbsp; Attachment
                                                </label>
                                            </div>
                                            <span>{this.state.form_data.request_files.length > 0 && this.getFileName(this.state.form_data.request_files)}</span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <hr className="m-0" />
                            <div className="modal-footer p-t-10 p-b-10">
                                {this.state.isProccesingFileUpload ?
                                    <React.Fragment>
                                        <button type="submit" className="btn btn-xs btn-primary" disabled={true}>Processing...</button>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        {this.state.isFileSaving ?
                                            <button className="btn btn-xs btn-primary" disabled={true}>Processing...</button>
                                            :
                                            <React.Fragment>
                                                <button type="submit" onClick={this.saveHelpModal} className="btn btn-xs btn-primary">Submit</button>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* Help Modal End */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = function (state, props) {
    return {

        InboxCount: state.InboxCount
    }
}

const mapActionsToProp = {

    getInboxCount: getInboxCount
}

export default connect(mapStateToProps, mapActionsToProp, null, { withRef: true })(Header);