import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import PostDataWithParam from '../../services/PostDataWithParam';
import { FormGroupMaterial } from '../../externaljs/FormGroupMaterial';

class ForgotPassword extends Component {
    state = {
        profile_id: 2,
        email: '',
        EmailStatus: false,
        emailErr: '',
        loginErr: '',
        regSuc: '',
        processing: false,
        fromLoc: '',
        cybEmail: null,
        is_independent_mover:1
    }

    componentDidMount() {
        FormGroupMaterial();
        if (!!this.props.location.search) {
            let queryParams = new URLSearchParams(this.props.location.search);
            if (queryParams !== '') {
                let from = queryParams.get("from");
                let cybEmail = queryParams.get("email");
                if (!!from) {
                    this.setState({ fromLoc: from, cybEmail });
                }
            }
        }
    }

    componentWillUnmount() {
        this.setState({ regSuc: '', loginErr: '', emailErr: '' });
    }


    changeValue = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleForgotPassword = (e) => {
        e.preventDefault();
        if (!this.state.email) {
            this.setState({ emailErr: 'Email is required.' });
        }
        else {
            this.setState({ emailErr: '' });
            this.setState({ processing: true }, () => {
                PostDataWithParam('VerifyEmail', this.state)
                    .then((response) => {
                        if (response.success === 1) {
                            window.swal('Success!', response.msg, 'success')
                            this.setState({ EmailStatus: true });
                        } else {
                            window.swal('Error!', response.msg, 'error')
                        }
                        this.setState({ processing: false });
                    })
                    .catch(err => {
                        window.swal('Error!', 'Something went wrong.', 'error')
                        this.setState({ processing: false });
                    })
            })
        }
    }

    renderSubmitBtn = () => {
        if (this.state.processing) {
            return <input type="button" className="btn btn-primary" value="Sending..." disabled={true} />;
        } else {

            return <button type="submit" className="btn btn-primary">Submit</button>;
        }
    }

    render() {

        if (this.state.EmailStatus && this.state.fromLoc != 'cyb') {
            return <Redirect to="/otp-check" />
        }
        if (this.state.EmailStatus && this.state.fromLoc == 'cyb') {
            return <Redirect to="/otp-check?from=cyb" />
        }

        return (
            <section className={this.state.fromLoc != 'cyb' ? "login-page-register" : "login-page-register cyb-mover-bg"}>{/*cyb-mover-bg*/}
                <div className="login-register-box">
                    <div className="text-center login-box-header common-unit">
                        <div className="m-b-30">
                            <Link to="/home"><img src="front-assets/images/logo.png" alt="site-logo" width="400"/></Link>
                        </div>
                        {this.state.fromLoc != 'cyb' ?
                            <h3>Forgot your password?</h3>
                            :
                            <h3 className="text-warning-600">Please reset your password to claim your business & login to your account</h3>
                        }
                    </div>
                    {this.state.cybEmail && <h6 className="text-600 text-center">A forgot password link will be sent to <a href="javascript:void(0)">{this.state.cybEmail}</a></h6>}
                    <br />
                    {this.state.fromLoc != 'cyb' ?
                        <p className="text-center m-b-30 text-muted">Enter your email id or mobile no.<br />We'll Send instructions on how to reset your password.</p>
                        :
                        <p className="text-center m-b-30 text-muted">Enter your email address or mobile no below.<br />We will send instructions on how to proceed.</p>
                    }
                    <form action="#" method="POST" onSubmit={this.handleForgotPassword}>
                        <div className="login-box-body m-t-30">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group form-group-material">
                                        <label className="control-label">Email Address</label>
                                        <input type="text" className="form-control" placeholder="Email Address" name="email" onChange={this.changeValue} required />
                                        <p className="text-danger help-block">{this.state.emailErr}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group m-t-40 clearfix text-sm-center">
                                {this.renderSubmitBtn()}&nbsp;
                                <Link to="/login" className="btn btn-link pull-right m-t-8">Cancel</Link>
                            </div>
                        </div>
                        <hr className="m-t-40 m-b-40" />
                        <div className="login-box-footer text-center">
                            {this.state.fromLoc != 'cyb' ?
                                <span className="text-grey-400"> Don't have an account?</span>
                                :
                                <span className="text-grey-400">Email not working? </span>
                            }
                            <Link to="/registration-imn" className="link-btn">Sign Up Now</Link>
                        </div>
                    </form>
                </div>

            </section>
        );
    }
}

export default ForgotPassword;