import React, { Component } from 'react'
import { Link, Route, Redirect } from 'react-router-dom';

import GetData from '../services/GetData';
import PostDataFile from '../services/PostDataFile';
import MilitaryConversations from './MilitaryConversations';
import { Prompt } from 'react-router';

class Environmental extends Component {
    state = {
        fields: {
            warehouse_insulated: -1,
            warehouse_ventilated: -1,
            warehouse_climate_controlled: -1,
            warehouse_exterior_interior_picture:[]
        },
        selectedFileAll:[],
        environmental_id: '',
        tab_id: 3,
        saveStatus: false,
        proccesing:false,
        formDirty:false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        GetData('GetEnvironmental')
        .then((response) => {
            if (response.success == 1) {
                this.setState({ fields: response.data, environmental_id: response.data.id });
            }
        })
        window.$('[data-popup="popover"]').popover();
        window.$('[data-popup="tooltip"]').tooltip();
    }

    ChangeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields,formDirty:true });
    }

    handleFileSelect = (e) => {

        let userfiles = []
        for (let i = 0; i < e.target.files.length; i++) {
            userfiles.push(e.target.files[i])
        }
        this.setState({ selectedFileAll: userfiles,formDirty:true });
    }

    updateEnvironmental = () => {
        this.setState({proccesing:true})
        let fd = new FormData();
        fd.append('tab_id', this.state.tab_id);
        fd.append('environmental_id', this.state.environmental_id);
        fd.append('warehouse_insulated', this.state.fields.warehouse_insulated);
        fd.append('warehouse_ventilated', this.state.fields.warehouse_ventilated);
        fd.append('warehouse_climate_controlled', this.state.fields.warehouse_climate_controlled);

        if(this.state.selectedFileAll.length>0){
            for (let i = 0; i < this.state.selectedFileAll.length; i++) {
                fd.append('warehouse_exterior_interior_picture[' + i + ']', this.state.selectedFileAll[i], this.state.selectedFileAll[i].name);
            }
        }        

       
        PostDataFile('SaveEnvironmental', fd)
        .then((response) => {
            if (response.success === 1) {
                window.swal('Success!', response.msg, 'success')
                this.setState({ saveStatus: true,proccesing:false,formDirty:false }, () => {
                    this.props.onUpdateApprovalProgress();
                });
            } else {
                this.setState({proccesing:false,formDirty:false })
                window.swal('Error!', response.msg, 'error')
            }
        })
    }

    openModal = (field_name, tab_id) => {
        this.props.onOpenModal(field_name, tab_id)
    }

    getFileName(file){
        if(file!=null){
            let files = file.split('/');
            return files[files.length -1];
        }        
    }

    renderAlertMsg = (field_name,tab_id) => {
        
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
       
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
       

        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Answer Received</p>
                        </React.Fragment>
                    )
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Waiting for reply</p>
                        </React.Fragment>
                    )
                }
            }
        }
    }
    renderClassName = (field_name,tab_id) => {
       
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
      
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
        

        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return "btn-success "
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return "btn-warning "
                }
            }
        }


    }
    render() {
        if (this.state.saveStatus) {
            return <Redirect to='/independent-mover/dashboard/military-approved/information-tab/insect' />
        }
        return (

            <div className="tab-pane active" id="top-icon-tab3">
                <Prompt
                when={this.state.formDirty}
                message="Please save your data before you leave the page. Otherwise, you will lose the information you entered !"
                />
                <div className="panel panel-flat">
                    <div className="panel-heading">
                        <h5 className="panel-title">Environmental &nbsp;<a href="#" data-popup="popover" title="" data-trigger="hover" data-content="Facilities will be constructed or equipped with adequate devices that will reasonably ensure property will not be exposed to extreme heat; cold; moisture; high humidity; or other environmental conditions which would cause damage, such as mold, mildew, and cracking or separation of finished and glued surfaces. Protective measures may include, but are not limited to, insulation, ventilators, ceiling and floor fans, dehumidifiers, and like devices that are intended to control or regulate the environment within a structure. The requirement for such built-in or external devices will be based on a review of the following factors:geographic location of the facility; weather conditions; previous experience; and incidences of damage claims for facilities within the same area that relate to mold, mildew, or articles which have deteriorated as a result of a climatic condition.Each facility will be evaluated on its own merits considering the above factors."><i className="icon-info22"></i></a></h5>
                    </div>
                    <div className="panel-body">
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Is Warehouse Insulated? </h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="radio-inline">
                                        <input type="radio" name="warehouse_insulated" value="1" onChange={this.ChangeValue} checked={(this.state.fields.warehouse_insulated == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" name="warehouse_insulated" value="0" onChange={this.ChangeValue} checked={(this.state.fields.warehouse_insulated == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={() => this.openModal('warehouse_insulated', this.state.tab_id)} className={this.renderClassName('warehouse_insulated',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('warehouse_insulated',this.state.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Is Warehouse Ventilated with fans or other types?</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="radio-inline">
                                        <input type="radio" name="warehouse_ventilated" value="1" onChange={this.ChangeValue} checked={(this.state.fields.warehouse_ventilated == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" name="warehouse_ventilated" value="0" onChange={this.ChangeValue} checked={(this.state.fields.warehouse_ventilated == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={() => this.openModal('warehouse_ventilated', this.state.tab_id)} className={this.renderClassName('warehouse_ventilated',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('warehouse_ventilated',this.state.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Is Warehouse Climate Controlled ?</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="radio-inline">
                                        <input type="radio" name="warehouse_climate_controlled" value="1" onChange={this.ChangeValue} checked={(this.state.fields.warehouse_climate_controlled == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" name="warehouse_climate_controlled" value="0" onChange={this.ChangeValue} checked={(this.state.fields.warehouse_climate_controlled == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={() => this.openModal('warehouse_climate_controlled', this.state.tab_id)} className={this.renderClassName('warehouse_climate_controlled',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('warehouse_climate_controlled',this.state.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Upload Pictures of the Warehouse exterior/ interior from multiple sides and angles.</h5>
                            <div className="row">
                                <div className="col-md-6">
                                    <input type="file" name="warehouse_exterior_interior_picture" onChange={this.handleFileSelect} multiple/>
                                </div>
                                <div className="col-md-6">
                                    <a href="javascript:void(0)" onClick={() => this.openModal('warehouse_exterior_interior_picture', this.state.tab_id)} className={this.renderClassName('warehouse_exterior_interior_picture',this.state.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('warehouse_exterior_interior_picture',this.state.tab_id)} 
                                </div>
                            </div>
                            {
                                this.state.fields.warehouse_exterior_interior_picture.length > 0
                                &&
                                <div className="row">
                                <div className="col-md-12">
                                {
                                    this.state.fields.warehouse_exterior_interior_picture.map((picture,index)=>{
                                        return (                                            
                                            <div className="file-wrapper filetype-image" key={index}>
                                                <div className="file-title">
                                                <a href="javascript:void(0)" data-src={picture} data-title="Preview" data-toggle="image-modal-file" className="image-modal-file">
                                                <i className="icon-image5 position-left text-primary"></i> {this.getFileName(picture)}
                                                </a></div>
                                            </div>                                               
                                        )
                                    })
                                }
                                </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <MilitaryConversations />
                <div className="text-center">{ this.state.proccesing?<button className="btn btn-primary" disabled="true">Processing...</button>:<button className="btn btn-primary" onClick={this.updateEnvironmental}>Save &amp; Next</button>}</div>
            </div>

        );
    }
}

export default Environmental;