import GetData from '../services/GetData';

export const SET_ACTIVE_MODULES = "set_active_modules";
export const GET_INBOX_COUNT = "get_inbox_count";

export function getInboxCount() {
    return (dispatch) => {
        GetData('TotalNewMsg')
        .then((response) => {
            if (response.success === 1) {
                dispatch({
                    type: GET_INBOX_COUNT,
                    payload: {
                        total_new_msg: response.data.total_new_msg
                    }
                });
            } else {
                dispatch({
                    type: GET_INBOX_COUNT,
                    payload: {
                        total_new_msg: 0
                    }
                });
            }
        })
        .catch(err => {
            dispatch({
                type: GET_INBOX_COUNT,
                payload: {
                    total_new_msg: 0
                }
            });
        })
    }
}

// export function setActiveModules(){
//     return (dispatch) => {
//         GetData('GetActiveModules')
//         .then((response) => {
//             if (response.success === 1) {
//                 dispatch({
//                     type: SET_ACTIVE_MODULES,
//                     payload: {
//                         activeModules: response.data
//                     }
//                 });
//             }else{
//                 dispatch({
//                     type: SET_ACTIVE_MODULES,
//                     payload: {
//                         activeModules: {
//                             Claims: false,
//                             Customer_Satisfaction_Survey: false,
//                             Customer_Service: false,
//                             Document_Management: true,
//                             Internal_Communication_System: true,
//                             Quality_Control: false,
//                             Reimbursement: false,
//                             Helper:false,
//                             Driver:false,
//                             claimsMain:true
//                         }
//                     }
//                 });
//             }
//         })
//         .catch(err => {
//             dispatch({
//                 type: SET_ACTIVE_MODULES,
//                 payload: {
//                     activeModules: {
//                         Claims: false,
//                         Customer_Satisfaction_Survey: false,
//                         Customer_Service: false,
//                         Document_Management: true,
//                         Internal_Communication_System: true,
//                         Quality_Control: false,
//                         Reimbursement: false,
//                         Helper:false,
//                         Driver:false,
//                         claimsMain:true
//                     }
//                 }
//             });
//         })

//     }
// }