import React, { Component } from 'react'
import PostDataWithParam from '../../services/PostDataWithParam';
import { Link } from 'react-router-dom';

export default class ClaimYourBusiness extends Component {

    state = {
        offset: 0,
        loadMore: 0,
        total: 0,
        limit:3,
        searchOffers: [],
        searchloading: false,
        search_total_count: 0,
        global_search_id: '',
        isSearchResult: false,
    }

    handleSearchOnChange(e) {
        this.setState({ global_search_id: e.target.value });
    }

    search(e) {
        e.preventDefault();
        this.setState({ offset: 0, loadMore: 0, total: 0, searchOffers: [] }, () => {
            this.searchAllOrder();
        })
    }

    searchAllOrder = () => {
        this.setState({ searchloading: true, loadMore: 0, search_total_count: 0, isSearchResult:false }, () => {
            PostDataWithParam('GlobalSearchOfMovers', { search_text: this.state.global_search_id, offset: this.state.offset })
                .then(response => {
                    if (response.success === 1) {
                        let searchOffers = this.state.searchOffers;
                        if (searchOffers) {
                            searchOffers = [...searchOffers, ...response.data]
                        }
                        else {
                            searchOffers = response.data
                        }
                        this.setState({ searchOffers: searchOffers, searchloading: false, loadMore: response.load_more, total: response.data.count, search_total_count: response.total_count, isSearchResult:true }, () => {
                            let offset = this.state.offset;
                            if (offset == 0) {
                                offset = this.state.limit;
                            } else {
                                offset += this.state.limit;
                            }
                            this.setState({ offset: offset });
                        })
                    } else {
                        this.setState({ searchOffers: [], offset: 0, total: 0, searchloading: false, isSearchResult:true })
                    }
                })
                .catch(err => {
                    this.setState({ searchOffers: [], searchloading: false, isSearchResult:true })
                })
        })
    }

    renderAllSearch = () => {
        if (this.state.searchOffers.length > 0) {
            let offersSearch = this.state.searchOffers.map((item, i) => {
                return (
                    <div className="service-box m-b-20" key={'offers-' + i}>
                        <div className="corner-triangle">
                            <div className="corner-triangle-image">
                                <a href=""><img src="assets/images/value.svg" /></a>
                            </div>
                        </div>
                        <div className="service-box-list">
                            <ul className="list-unstyled m-0">
                                <li className="">
                                    <span style={{ background: "url('" + (item.profile_image || 'assets/images/default_avatar.jpg') + "') no-repeat center center / cover" }} />
                                </li>
                                {/* <li className="company-logo" style={{ backgroundImage: "url('" + (item.profile_image || 'assets/images/default_avatar.jpg') + "')" }}></li> */}
                                <li className="f-s-16"><a data-toggle="modal" data-target="#profileInfoModal" href="#">{item.organization_name}</a></li>
                            </ul>
                        </div>
                        <div className="service-box-list text-center text-orange">
                            <h6 className="text-slate text-uppercase m-0"><b>Address:</b> <br /><p className="text-muted text-capitalize">{item.address || '---'}</p></h6>
                            <p className="m-0 f-s-24">5.0</p>
                            <p className="m-0">
                                <span className="icon-star-full2 m-r-3" />
                                <span className="icon-star-full2 m-r-3" />
                                <span className="icon-star-full2 m-r-3" />
                                <span className="icon-star-full2 m-r-3" />
                                <span className="icon-star-full2" />
                            </p>
                        </div>
                        <div className="p-t-10">
                            <Link to="/forgot-password" className="btn fl-button"><span className="">Own this business? Claim Now!</span></Link>
                        </div>
                    </div>
                )
            });
            return offersSearch;
        }
        else {
            // return <div className="alert alert-primary no-border text-center m-15">No Order Found.</div>
            return <div className="alert alert-primary">No Order Found.</div>
        }
    }

    render() {
        return (
            <div>
                <div className="claimYourBusiness-banner bg-light-blue">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h2 className="m-t-0">Claim Your Business</h2>
                            </div>
                            <div className="col-md-12 m-b-20">
                                <form action="#" method="POST" onSubmit={this.search.bind(this)}>
                                    <div className="search-wrap">
                                        <input type="text" className="form-control" name="global_search_id" value={this.state.global_search_id} placeholder="Search by Company Name, Email Address, City, State, Phone etc." style={{ height: 50, fontSize: 16 }} onChange={this.handleSearchOnChange.bind(this)} />
                                        <button type="submit" className="searchButton">
                                            <i className="fa fa-search" />
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <img src="front-assets/images/claimYourBusinesst.png" alt="" className="img-responsive" />
                                    </div>
                                    <div className="col-md-6">
                                        <div className="claimYourBusiness-content">
                                            <ul>
                                                <li>Get more FREE leads from MyRelo® customer users</li>
                                                <li>Unlimited Virtual Premove Survey feature</li>
                                                <li>Connect with over 1000 independent movers worldwide</li>
                                                <li>Increased hauling opportunities</li>
                                                <li>Additional capacity</li>
                                                <li>Complete Domestic US coverage with MyReloPro® casual labor helpers</li>
                                                <li>Lower claim costs with ‘CYA® Cover Your @ss’ feature</li>
                                                <li>Competitive 3rd Party Services</li>
                                                <li>Increase productivity with ‘Where’s My Crew?’</li>
                                                <li>Plus so much more!</li>
                                            </ul>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-t-40 p-b-40">
                    <div className="container">
                        <div className="row">
                            {this.state.searchloading
                                &&
                                // <div className="well p-b-0">
                                <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                                // </div>
                            }

                            {!this.state.searchloading && (this.state.searchOffers.length > 0) &&
                                <div className="well p-b-0">
                                    {/* <h5 className="m-t-0 text-primary">{this.state.searchOffers.length} Offer(s) found of {this.state.search_total_count}</h5> */}
                                    <React.Fragment>
                                        <div className="offer-grid grid-col-3">
                                            {this.renderAllSearch()}
                                        </div>
                                        {this.state.loadMore == 1 &&
                                            <div className="row">
                                                <div className="col-md-12 text-center">
                                                    <a href="javascript:void(0)" className="btn btn-primary m-b-20" onClick={this.searchAllOrder.bind(this)}>Load More</a>
                                                </div>
                                            </div>
                                        }
                                    </React.Fragment>
                                </div>
                            }
                            {/* <div className="offer-grid grid-col-3">
                                <div class="service-box m-b-20">
                                    <div class="corner-triangle">
                                        <div class="corner-triangle-image">
                                            <a href=""><img src="assets/images/value.svg" /></a>
                                        </div>
                                    </div>
                                    <div className="service-box-list">
                                        <ul className="list-unstyled m-0">
                                            <li className><span style={{ background: 'rgba(0, 0, 0, 0) url("assets/images/default_avatar.jpg") no-repeat scroll center center / cover' }} /></li>
                                            <li className="f-s-16"><a data-toggle="modal" data-target="#profileInfoModal" href="#">sysalgo</a></li>
                                        </ul>
                                    </div>
                                    <div className="service-box-list text-center text-orange">
                                        <h6 className="text-slate text-uppercase m-0"><b>Address:</b> <br /><p className="text-muted text-capitalize">328 Sycamore Street, Fayettevd...</p></h6>
                                        <p className="m-0 f-s-24">5.0</p>
                                        <p className="m-0">
                                            <span className="icon-star-full2 m-r-3" />
                                            <span className="icon-star-full2 m-r-3" />
                                            <span className="icon-star-full2 m-r-3" />
                                            <span className="icon-star-full2 m-r-3" />
                                            <span className="icon-star-full2" />
                                        </p>
                                    </div>
                                    <div className="p-t-10">
                                        <Link to="/forgot-password" className="btn fl-button"><span className="">Own this business? Claim Now!</span></Link>
                                    </div>
                                </div>
                                <div class="service-box m-b-20">
                                    <div class="corner-triangle">
                                        <div class="corner-triangle-image">
                                            <a href=""><img src="assets/images/value.svg" /></a>
                                        </div>
                                    </div>
                                    <div className="service-box-list">
                                        <ul className="list-unstyled m-0">
                                            <li className><span style={{ background: 'rgba(0, 0, 0, 0) url("assets/images/default_avatar.jpg") no-repeat scroll center center / cover' }} /></li>
                                            <li className="f-s-16"><a data-toggle="modal" data-target="#profileInfoModal" href="#">sysalgo</a></li>
                                        </ul>
                                    </div>
                                    <div className="service-box-list text-center text-orange">
                                        <h6 className="text-slate text-uppercase m-0"><b>Address:</b> <br /><p className="text-muted text-capitalize">328 Sycamore Street, Fayettevd...</p></h6>
                                        <p className="m-0 f-s-24">5.0</p>
                                        <p className="m-0">
                                            <span className="icon-star-full2 m-r-3" />
                                            <span className="icon-star-full2 m-r-3" />
                                            <span className="icon-star-full2 m-r-3" />
                                            <span className="icon-star-full2 m-r-3" />
                                            <span className="icon-star-full2" />
                                        </p>
                                    </div>
                                    <div className="p-t-10">
                                        <a className="btn fl-button" href="#"><span className="">Own this business? Claim Now!</span></a>
                                    </div>
                                </div> */}
                            {/* <div class="service-box m-b-20">
                                    <div class="corner-triangle">
                                        <div class="corner-triangle-image">
                                            <a href=""><img src="assets/images/value.svg" /></a>
                                        </div>
                                    </div>
                                    <div className="service-box-list">
                                        <ul className="list-unstyled m-0">
                                            <li className><span style={{ background: 'rgba(0, 0, 0, 0) url("assets/images/default_avatar.jpg") no-repeat scroll center center / cover' }} /></li>
                                            <li className="f-s-16"><a data-toggle="modal" data-target="#profileInfoModal" href="#">sysalgo</a></li>
                                        </ul>
                                    </div>
                                    <div className="service-box-list text-center text-orange">
                                    <h6 className="text-slate text-uppercase m-0"><b>Address:</b> <br /><p className="text-muted text-capitalize">328 Sycamore Street, Fayettevd...</p></h6>
                                        <p className="m-0 f-s-24">5.0</p>
                                        <p className="m-0">
                                            <span className="icon-star-full2 m-r-3" />
                                            <span className="icon-star-full2 m-r-3" />
                                            <span className="icon-star-full2 m-r-3" />
                                            <span className="icon-star-full2 m-r-3" />
                                            <span className="icon-star-full2" />
                                        </p>
                                    </div>
                                    <div className="p-t-10">
                                        <a className="btn fl-button" href="#"><span className="">Own this business? Claim Now!</span></a>
                                    </div>
                                </div>  
                            </div>*/}

                            {this.state.isSearchResult &&
                                <div className="col-md-12 m-t-20 text-center">
                                    <Link to="/registration-imn" className="btn ClaimYourBusiness-btn bg-red text-uppercase">
                                        <p>Don’t see your business here?</p>
                                        <span>Add your business, create free account</span>
                                    </Link>
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
