import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';

import PostDataWithParam from '../services/PostDataWithParam';
import GetData from '../services/GetData';
import { FormatSSN } from '../externaljs/FormatSSN';
import DateFormat from '../externaljs/DateFormat';
import Breadcumb from '../externaljs/Breadcrumb';

class EditCustomer extends Component {
    state = {
        fields: {
            first_name: '',
            last_name: '',
            email: '',
            contact_no: '',
            alternate_contact_no: '',
            gender: '',
            dob: '',
            ssn: '',
            dept_id: [],
            address: '',
            latitude: '',
            longitude: '',
            customer_id: '',
            phone_no: '',
            alternate_phone_no: '',
            alternate_email: '',
            spouse_first_name: '',
            spouse_last_name: '',
            spouse_home_contact_no: '',
            spouse_contact_no: '',
            spouse_work_contact_no: '',
            spouse_home_contact_no: '',
            spouse_phone_no: '',
            spouse_work_phone_no: '',
            number_of_children: '',
            number_of_pets: '',
            home_contact_no: ''
        },
        error: {
            first_name_err: '',
            last_name_err: '',
            email_err: '',
            contact_no_err: '',
            alternate_contact_no_err: '',
            gender_err: '',
            dob_err: '',
            ssn_err: '',
            address_err: '',
            alternate_email_err: '',
            spouse_first_name_err: '',
            spouse_last_name_err: '',
            spouse_home_contact_no_err: '',
            spouse_contact_no_err: '',
            spouse_work_contact_no_err: '',
            number_of_children_err: '',
            number_of_pets_err: '',
            home_contact_no_err: ''
        },
        processing:false,
        addCustomerStatus: false,
        CustErr: '',
        ssnClick: false,
        viewSsn: true,
        lists: [],
        clientSelected: '',
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Customer', link: '/independent-mover/dashboard/customer', class: '' },
            { title: 'Edit Customer', link: '', class: 'active' }
        ]

    }

    componentDidMount() {
        this.getCustomerDetails();
        window.$('.input-group.date').datepicker({
            autoclose: true
        }).on('changeDate', (e) => {
            let fields = this.state.fields;
            fields.dob = e.format();
            this.setState({ fields: fields });
        });
        window.$('.select').select2({
            minimumResultsForSearch: Infinity
        });
    }

    Ssnformat = (ssn) => {
        if (ssn !== null) {
            let ssn_new = ssn.replace(/^.{6}/g, '***-**')
            return ssn_new
        } else {
            return '';
        }
    }

    openTextbox = () => {
        this.setState({ viewSsn: false, ssnClick: true }, () => {
            FormatSSN()
        });
    }


    getCustomerDetails = () => {
        PostDataWithParam('getCustomerDetails', { customer_id: this.props.match.params.customer_id })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ fields: response.data[0] });
                    
                }
            })
    }
    handleSubmit=(e)=>{
        e.preventDefault()
        let fields = this.state.fields;
            fields.customer_id=this.props.match.params.customer_id;
        this.setState({ processing: true }, () => {
                 this.setState({ fields: fields });
                PostDataWithParam('AddCustomer',fields)
                    .then((response) => {
                        let result = response;
                        if (result.success === 1) {
                            this.setState({ CustErr: result.msg }, () => {
                                this.setState({ addCustomerStatus: true });
                            });
                        } else {
                            this.setState({ CustErr: result.msg });
                        }
                    })
                    .catch(err => {
                        this.setState({ CustErr: 'Oops! Something went wrong.' });
                    })
            })
    }
    initAutocomplete = (e) => {
        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: [] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let fields = this.state.fields;
            fields.latitude = place.geometry.location.lat();
            fields.longitude = place.geometry.location.lng();
            fields.address = window.$('#address')[0].value;
            this.setState({ fields: fields });
        })
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields });
    }
    resetError = (cb) => {
        let error = {
            first_name_err: '',
            last_name_err: '',
            email_err: '',
            contact_no_err: '',
            alternate_contact_no_err: '',
            gender_err: '',
            dob_err: '',
            ssn_err: '',
            address_err: '',
            alternate_email_err: ''
        }
        this.setState({ error: error }, () => {
            cb();
        });
    }
    render() {

        if (this.state.addCustomerStatus) {
            return <Redirect to={{
                pathname: '/independent-mover/dashboard/customer',
                state: { CustErr: this.state.CustErr }
            }} />
        }

        return (
            <div className="content-container">
                <div className="row">
                    <div className="col-md-12">
                        {Breadcumb(this.state.breadcrumb)}
                        <div className="panel panel-flat profile-page">
                            <div className="panel-heading p-b-0">
                                <h5 className="panel-title">Edit Customer Details</h5>
                                <span className="text-danger">{this.state.CustErr}</span>
                            </div>
                            <form action="#" onSubmit={this.handleSubmit}>
                                <div className="panel-body">

                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Salutation <span className="text-danger">*</span></label>
                                                <p className="text-danger help-block">{this.state.error.gender_err}</p>
                                                 <select className="form-control" name="gender" value={this.state.fields.gender || 'Mr.'} onChange={this.changeValue} required={true}>
                                                    <option>Mr.</option>
                                                    <option>Ms.</option>
                                                    <option value="Mrs.">Mrs.</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label>First Name <span className="text-danger">*</span></label>
                                                <input type="text" name="first_name" value={this.state.fields.first_name || ''} onChange={this.changeValue} className="form-control" placeholder="Enter First Name" required={true} />
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label>Last Name <span className="text-danger">*</span></label>
                                                <input type="text" name="last_name" value={this.state.fields.last_name || ''} onChange={this.changeValue} className="form-control" placeholder="Enter Last Name" required={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Primary Email Address <span className="text-danger">*</span></label>
                                                <input className="form-control" type="text" name="email" value={this.state.fields.email || ''} onChange={this.changeValue} placeholder="Enter Primary Email Address" required={true}/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Secondary Email Address</label>
                                                <input className="form-control" type="text" name="recovery_email" onChange={this.changeValue} value={this.state.fields.recovery_email || ''} placeholder="Enter Secondary Email Address" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Mobile Phone <span className="text-danger">*</span></label>
                                                <input className="form-control" type="text" name="phone_no" onChange={this.changeValue}  value={this.state.fields.phone_no || ''} placeholder="Enter Mobile Phone" required={true}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Work Phone</label>
                                                <input className="form-control" type="text" name="alternate_phone_no" onChange={this.changeValue} value={this.state.fields.alternate_phone_no || ''} placeholder="Enter Work Phone"/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Address </label>
                                                <input className="form-control" type="text" id="address" onFocus={this.initAutocomplete} name="address" onChange={this.changeValue} placeholder="Enter Address" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Date of Birth </label>
                                                <div className="input-group date" id="dob">
                                                    <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                    <input type="text" className="form-control" name="dob" value={this.state.fields.dob || ''} onChange={this.changeValue} placeholder="Enter date of birth" />
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Social Security Number </label>
                                                <input type="text" className="form-control ssn" name="ssn" value={this.state.fields.ssn} onChange={this.changeValue} maxLength="11" placeholder="Enter your social security number" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-footer text-right p-r-20">
                                    <button type="Submit" className="btn btn-primary" >Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}


export default EditCustomer;