import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class FooterMain extends Component {
    state = {}
    handleScrollToStats = () => {
        window.scrollTo({
            top: 0
        })
    }

    render() {
        return (

            <footer className="footer p-t-40 p-b-40">
                <div className="container">
                    <ul>
                        <li>
                            <Link to="#">Home</Link>
                        </li>
                        <li>
                            <Link to="#">Account</Link>
                        </li>
                        <li>
                            <Link to="#">Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to="#">Terms &amp; Conditions</Link>
                        </li>
                        <li>
                            <Link to="#">Blog</Link>
                        </li>
                        <li>
                            <Link to="#">Contact</Link>
                        </li>
                    </ul>
                    <p>&copy; 2021 Independent Movers Network. All Rights Reserved.</p>
                </div>
            </footer>
        );
    }
}

export default FooterMain;
