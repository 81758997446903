import React, { Component } from 'react';
import GetData from '../services/GetData';
import PostDataWithParam from '../services/PostDataWithParam';
import { FileType, FileSvg, FileIcon } from "../externaljs/FileType";
class ViewMoversProfile extends Component {
    state = {
        id: 0,
        fields: {
            logo: '',
            organization_name: '',
            doing_business_as_dba: '',
            year_founded: '',
            website_link: '',
            company_contact_no: '',
            company_fax_no: '',
            operation_email: '',
            sales_email: '',
            customer_service_email: '',
            driver_and_team_recruitment: '',
            claim_email: '',
            after_hours_staff_name: '',
            after_hours_contact_phone: '',
            radius_of_quality_service: '',
            management_contact_name: '',
            management_title: '',
            company_email: '',
            contact_no: '',
            military_approved_warehouse: '',
            certified_truck_scale_on_premises: '',
            warehouse: '',
            warehouse_size: '',
            climatized: '',
            climatized_size: '',
            air_conditioned: '',
            air_conditioned_size: '',
            pre_move_survey: '',
            packing: '',
            crating: '',
            short_hauling_hhg: '',
            long_hauling_hhg: '',
            freight_hauling: '',
            storage: '',
            intermodal: '',
            day_road_labor: '',
            sit_approved: '',
            international: '',
            last_mile_delivery: '',
            freight_warehouse: '',
            third_party_services: '',
            secured_parking_lot: '',
            tractors: '',
            trailers: '',
            straight_trucks: '',
            pack_vans: '',
            cdl_drivers: '',
            non_cdl_drivers: '',
            packers: '',
            loaders: '',
            primary_street_address: '',
            primary_address_other_info: '',
            secondary_street_address: '',
            secondary_address_other_info: '',
            primary_zip_code: '',
            secondary_zip_code: '',
            services_provided: [],
            primary_country: '',
            secondary_country: ''

        },
        company_files: [],
        services_provided_list: [],
    }

    componentDidMount() {

        this.setState({ id: this.props.match.params.provider_user_id }, () => {
            this.GetCompanyInfo();
            this.GetAllCompanyFile();
            this.getCompletionStatus();
        })
    }
    getCompletionStatus = () => {
        PostDataWithParam('GetProfileCompleteStatus', { user_id: this.state.id })
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ completion_percentage: response.completion_percentage });
                }
            })
            .catch(err => {
                this.setState({ completion_percentage: 0 });
            })
    }
    GetCompanyInfo = () => {
        PostDataWithParam('GetCompanyInfo', { company_user_id: this.state.id })
            .then((response) => {
                if (response.success === 1) {
                    let services_provided = [];
                    if (response.data[0].services_provided) {
                        let services_provided_arr = JSON.parse(response.data[0].services_provided);
                        services_provided = services_provided_arr.map((item, i) => {
                            return item.name;
                        })
                    }

                    this.setState({ fields: response.data[0], services_provided_list: services_provided });
                    window.$(".styled, .multiselect-container input").uniform({
                        radioClass: 'choice'
                    });
                }
            })
            .catch(err => { })
    }

    GetAllCompanyFile = () => {
        GetData("GetCompanyDocument")
        PostDataWithParam('GetCompanyDocument', { company_user_id: this.state.id })
            .then(response => {
                if (response.success == 1) {
                    this.setState({ company_files: response.data });
                }
            });
    }

    handleFileCheck = (file) => {
        if (file !== '') {
            return (
                <div className="thumb custom-logo" style={{ backgroundImage: "url(" + this.state.fields.logo + ")" }}>
                </div>
            )
        } else {
            return (
                <div className="thumb custom-logo" style={{ backgroundImage: "url('assets/images/icon-no-image.svg')" }}>
                </div>
            )
        }
    }


    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="breadcrumb-line breadcrumb-line-component m-b-20">
                        <ul className="breadcrumb">
                            <li><a href="#/independent-mover/dashboard/home"><i className="icon-meter-slow position-left"></i> Dashboard</a></li>
                            <li className="active">Get Military Approved </li>
                        </ul>
                    </div>
                    <div className="panel panel-default">
                        <div className="panel-heading p-0">
                            <ul className="nav nav-tabs nav-tabs-solid m-b-0">
                                <li className="active"><a href="#tab-profile" className="f-s-16" data-toggle="tab">Basic Profile</a></li>
                            </ul>
                        </div>
                        <div className="panel-body">
                            <div className="text-center">
                                <h5 className="inline-block m-r-30 text-blue-800">Profile Completion</h5>
                                <div className="progress inline-block" style={{ width: this.state.completion_percentage + '%' }}>
                                    <div className="progress-bar bg-blue-800" style={{ width: this.state.completion_percentage + '%' }}>
                                        <span>{this.state.completion_percentage}% Complete</span>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="thumbnail">
                                        <div className="thumb custom-logo" >
                                            {this.handleFileCheck(this.state.fields.logo)}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Legal Name of Company</label>
                                                <p className="form-control">{this.state.fields.organization_name}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Year Founded</label>
                                                <p className="form-control">{this.state.fields.year_founded || ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Doing Business As/ DBA</label>
                                                <p className="form-control">{this.state.fields.doing_business_as_dba || ''}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Website</label>
                                                <p className="form-control">{this.state.fields.website_link || ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className="text-success">Physical Street Address</h5>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Street Address</label>
                                        <p className="form-control">{this.state.fields.primary_street_address}</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Other Info</label>
                                        <p className="form-control">{this.state.fields.primary_address_other_info || ''}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>City</label>
                                        <p className="form-control" readonly="">{this.state.fields.primary_city}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>State</label>
                                        <p className="form-control" readonly="">{this.state.fields.primary_state}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Zip Code</label>
                                        <p className="form-control" readonly="">{this.state.fields.primary_zip_code || ""}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Country (if Applicable)</label>
                                        <p className="form-control" readonly="">{this.state.fields.primary_country}</p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-12">
                                    <h5 className="text-success">Mailing Address</h5>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Street Address</label>
                                        <p className="form-control">{this.state.fields.secondary_street_address}</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Other Info</label>
                                        <p className="form-control">{this.state.fields.secondary_address_other_info || ""}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>City</label>
                                        <p className="form-control" readonly="">{this.state.fields.secondary_city}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>State</label>
                                        <p className="form-control" readonly="">{this.state.fields.secondary_state}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Zip Code</label>
                                        <p className="form-control" readonly="">{this.state.fields.secondary_zip_code}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Country (if Applicable)</label>
                                        <p className="form-control" readonly="">{this.state.fields.secondary_country}</p>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Main Office Phone Number</label>
                                        <p className="form-control">{this.state.fields.company_contact_no}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Office Fax Number</label>
                                        <p className="form-control">{this.state.fields.company_fax_no || ""}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Operations Email Address</label>
                                        <p className="form-control">{this.state.fields.operation_email || ""}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Sales Email Address (For Leads & New Customers)</label>
                                        <p className="form-control">{this.state.fields.sales_email || ""}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Customer Service Email Address (For Existing Customers)</label>
                                        <p className="form-control">{this.state.fields.customer_service_email || ""}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Claims Email Address</label>
                                        <p className="form-control">{this.state.fields.claim_email || ""}</p>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>After Hours Contact Phone</label>
                                        <p className="form-control">{this.state.fields.after_hours_contact_phone}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>After Hours Staff Name</label>
                                        <p className="form-control">{this.state.fields.after_hours_staff_name}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Driver and Team Recruitment (GROW your Team)</label>
                                        <p className="form-control">{this.state.fields.driver_and_team_recruitment || ""}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Management Contact Name</label>
                                        <p className="form-control">{this.state.fields.management_contact_name}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Management Title</label>
                                        <p className="form-control">{this.state.fields.management_title}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Management Email Address</label>
                                        <p className="form-control">{this.state.fields.company_email}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Management Phone</label>
                                        <p className="form-control">{this.state.fields.contact_no}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Certified Truck Scale on Premises?</label>
                                        <div>
                                            <label className="radio-inline">
                                                <input type="radio" className="styled" name="certified_truck_scale_on_premises"
                                                    value="1"
                                                    checked={this.state.fields.certified_truck_scale_on_premises == 1
                                                        ? true :
                                                        false}
                                                    disabled={true} />Y
                                            </label>
                                            <label className="radio-inline">
                                                <input type="radio" className="styled" name="certified_truck_scale_on_premises"
                                                    value="0"
                                                    checked={
                                                        this.state.fields
                                                            .certified_truck_scale_on_premises == 0
                                                            ? true
                                                            : false
                                                    } disabled={true} />N
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Secured Parking Lot?</label>
                                        <div>
                                            <label className="radio-inline radio-left">
                                                <input type="radio" className="styled" name="secured_parking_lot"
                                                    value="1"
                                                    checked={
                                                        this.state.fields.secured_parking_lot == 1
                                                            ? true
                                                            : false
                                                    }
                                                    disabled={true}
                                                />Y
                                            </label>
                                            <label className="radio-inline radio-left">
                                                <input type="radio" className="styled" name="secured_parking_lot"
                                                    value="0"
                                                    checked={
                                                        this.state.fields.secured_parking_lot == 0
                                                            ? true
                                                            : false
                                                    }
                                                    disabled={true}
                                                />N
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Warehouse?</label>
                                        <div>
                                            <label className="radio-inline radio-left">
                                                <input type="radio" className="styled" name="secured_parking_lot"
                                                    value="1"
                                                    checked={
                                                        this.state.fields.warehouse == 1 ? true : false
                                                    }
                                                    disabled={true}
                                                />Y
                                            </label>
                                            <label className="radio-inline radio-left">
                                                <input type="radio" className="styled" name="secured_parking_lot"
                                                    value="0"
                                                    checked={
                                                        this.state.fields.warehouse == 0 ? true : false
                                                    }
                                                    disabled={true}
                                                />N
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Warehouse Size in Square Feet</label>
                                        <p className="form-control">{this.state.fields.warehouse_size}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Climatized?</label>
                                        <div>
                                            <label className="radio-inline radio-left">
                                                <input type="radio" className="styled" name="secured_parking_lot"
                                                    value="1"
                                                    checked={
                                                        this.state.fields.climatized == 1 ? true : false
                                                    }
                                                    disabled={true}
                                                />Y
                                            </label>
                                            <label className="radio-inline radio-left">
                                                <input type="radio" className="styled" name="secured_parking_lot"
                                                    value="0"
                                                    checked={
                                                        this.state.fields.climatized == 0 ? true : false
                                                    }
                                                    disabled={true}
                                                />N
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Climatized Size in Square Feet</label>
                                        <p className="form-control">{this.state.fields.climatized_size}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Air Conditioned?</label>
                                        <div>
                                            <label className="radio-inline radio-left">
                                                <input type="radio" className="styled" name="secured_parking_lot"
                                                    value="1"
                                                    checked={
                                                        this.state.fields.air_conditioned == 1
                                                            ? true
                                                            : false
                                                    }
                                                    disabled={true}
                                                />Y
                                            </label>
                                            <label className="radio-inline radio-left">
                                                <input type="radio" className="styled" name="secured_parking_lot"
                                                    value="0"
                                                    checked={
                                                        this.state.fields.air_conditioned == 0
                                                            ? true
                                                            : false
                                                    }
                                                    disabled={true}
                                                />N
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Air Conditioned Size in Square Feet</label>
                                        <p className="form-control">{this.state.fields.air_conditioned_size}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Military Approved Warehouse?</label>
                                        <div>
                                            <label className="radio-inline radio-left">
                                                <input type="radio" className="styled" name="secured_parking_lot"
                                                    value="1"
                                                    checked={
                                                        this.state.fields.military_approved_warehouse == 1
                                                            ? true
                                                            : false
                                                    }
                                                    disabled={true}
                                                />Y
                                            </label>
                                            <label className="radio-inline radio-left">
                                                <input type="radio" className="styled" name="secured_parking_lot"
                                                    value="0"
                                                    checked={
                                                        this.state.fields.military_approved_warehouse == 0
                                                            ? true
                                                            : false
                                                    }
                                                    disabled={true}
                                                />N
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Radius of Quality Service</label>
                                        <p className="form-control">{this.state.fields.radius_of_quality_service}</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Services Provided</label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" className="styled" id="pre_move_survey" name="pre_move_survey"
                                                            checked={
                                                                this.state.services_provided_list.indexOf(
                                                                    "Pre-Move Survey"
                                                                ) > -1
                                                            }
                                                            value="Pre-Move Survey"
                                                            disabled={true}
                                                        />
                                                        Pre-Move Survey
                                                        </label>
                                                </div>
                                                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" className="styled" id="pre_move_survey" name="pre_move_survey"
                                                            checked={
                                                                this.state.services_provided_list.indexOf(
                                                                    "Packing"
                                                                ) > -1
                                                            }
                                                            value="Packing"
                                                            disabled={true}
                                                        />
                                                        Packing
                                                        </label>
                                                </div>
                                                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" className="styled" id="pre_move_survey" name="pre_move_survey"
                                                            checked={
                                                                this.state.services_provided_list.indexOf(
                                                                    "Crating"
                                                                ) > -1
                                                            }
                                                            value="Crating"
                                                            disabled={true}
                                                        />
                                                        Crating
                                                        </label>
                                                </div>
                                                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" className="styled" id="pre_move_survey" name="pre_move_survey"
                                                            checked={
                                                                this.state.services_provided_list.indexOf(
                                                                    "Short Hauling HHG (500 Miles)"
                                                                ) > -1
                                                            }
                                                            value="Short Hauling HHG (500 Miles)"
                                                            disabled={true}
                                                        />
                                                        Short Hauling HHG (500 Miles)
                                                        </label>
                                                </div>
                                                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" className="styled" id="pre_move_survey" name="pre_move_survey"
                                                            checked={
                                                                this.state.services_provided_list.indexOf(
                                                                    "Long Hauling HHG (500+ Miles)"
                                                                ) > -1
                                                            }
                                                            value="Long Hauling HHG (500+ Miles)"
                                                            disabled={true}
                                                        />
                                                        Long Hauling HHG (500+ Miles)
                                                        </label>
                                                </div>
                                                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" className="styled" id="pre_move_survey" name="pre_move_survey"
                                                            checked={
                                                                this.state.services_provided_list.indexOf(
                                                                    "Freight Hauling"
                                                                ) > -1
                                                            }
                                                            disabled={true}
                                                            value="Freight Hauling"
                                                        />
                                                        Freight Hauling
                                                        </label>
                                                </div>
                                                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" className="styled" id="pre_move_survey" name="pre_move_survey"
                                                            checked={
                                                                this.state.services_provided_list.indexOf(
                                                                    "Storage"
                                                                ) > -1
                                                            }
                                                            value="Storage"
                                                            disabled={true}
                                                        />
                                                        Storage
                                                        </label>
                                                </div>
                                                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" className="styled" id="pre_move_survey" name="pre_move_survey"
                                                            checked={
                                                                this.state.services_provided_list.indexOf(
                                                                    "Intermodal"
                                                                ) > -1
                                                            }
                                                            value="Intermodal"
                                                            disabled={true}

                                                        />
                                                        Intermodal
                                                        </label>
                                                </div>
                                                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" className="styled" id="pre_move_survey" name="pre_move_survey"
                                                            checked={
                                                                this.state.services_provided_list.indexOf(
                                                                    "Day Road Labor"
                                                                ) > -1
                                                            }
                                                            value="Day Road Labor"
                                                            disabled={true}
                                                        />
                                                        Day Road Labor
                                                        </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" className="styled" id="pre_move_survey" name="pre_move_survey"
                                                            checked={
                                                                this.state.services_provided_list.indexOf(
                                                                    "SIT Approved"
                                                                ) > -1
                                                            }
                                                            value="SIT Approved"
                                                            disabled={true}
                                                        />
                                                        SIT Approved
                                                        </label>
                                                </div>
                                                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" className="styled" id="pre_move_survey" name="pre_move_survey"
                                                            checked={
                                                                this.state.services_provided_list.indexOf(
                                                                    "International"
                                                                ) > -1
                                                            }
                                                            value="International"
                                                            disabled={true}
                                                        />
                                                        International
                                                        </label>
                                                </div>
                                                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" className="styled" id="pre_move_survey" name="pre_move_survey"
                                                            checked={
                                                                this.state.services_provided_list.indexOf(
                                                                    "Last Mile Delivery"
                                                                ) > -1
                                                            }
                                                            value="Last Mile Delivery"
                                                            disabled={true}
                                                        />
                                                        Last Mile Delivery
                                                        </label>
                                                </div>
                                                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" className="styled" id="pre_move_survey" name="pre_move_survey"
                                                            checked={
                                                                this.state.services_provided_list.indexOf(
                                                                    "Freight Warehouse & Handling"
                                                                ) > -1
                                                            }
                                                            value="Freight Warehouse & Handling"
                                                            disabled={true}
                                                        />
                                                        Freight Warehouse &nbsp; Handling
                                                        </label>
                                                </div>
                                                <div className="checkbox">
                                                    <label>
                                                        <input type="checkbox" className="styled" id="pre_move_survey" name="pre_move_survey"
                                                            checked={
                                                                this.state.services_provided_list.indexOf(
                                                                    "Third Party Services"
                                                                ) > -1
                                                            }
                                                            value="Third Party Services"
                                                            disabled={true}
                                                        />
                                                        Third Party Services
                                                        </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Tractors</label>
                                        <p className="form-control">{this.state.fields.tractors}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Trailers</label>
                                        <p className="form-control">{this.state.fields.trailers}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Straight Trucks</label>
                                        <p className="form-control">{this.state.fields.straight_trucks}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Pack Vans</label>
                                        <p className="form-control">{this.state.fields.pack_vans}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>CDL Drivers</label>
                                        <p className="form-control">{this.state.fields.cdl_drivers}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Non-CDL Drivers</label>
                                        <p className="form-control">{this.state.fields.non_cdl_drivers}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Packers</label>
                                        <p className="form-control">{this.state.fields.packers}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Loaders</label>
                                        <p className="form-control">{this.state.fields.loaders}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="m-b-20">
                                        <h5 className="display-block m-b-0">Show off your Team</h5>
                                        <small className="p-b-10">(Feel free to upload any pictures of your professional team, warehouse, equipment, etc.)</small>
                                    </div>
                                </div>
                                {this.state.company_files.length != 0 && (
                                    <div className="col-md-12">
                                        <div className="panel panel-flat">
                                            <div className="panel-heading p-b-5">
                                                <h6 className="m-b-0 m-t-0 text-semibold"></h6>
                                            </div>
                                            <div className="panel-body">
                                                <div className="file-manager hori-scroll">
                                                    <ul>
                                                        {this.state.company_files.length != 0 &&
                                                            this.state.company_files.map(file => {
                                                                return (
                                                                    <li key={file.id}>
                                                                        <div
                                                                            className={
                                                                                "file-wrapper " +
                                                                                FileType(file.document_extension)
                                                                            }
                                                                        >
                                                                            {FileSvg(
                                                                                file.document_extension,
                                                                                file.document_path
                                                                            )}
                                                                            <div className="file-title">
                                                                                <a href={file.document_path}>
                                                                                    <i
                                                                                        className={
                                                                                            FileIcon(
                                                                                                file.document_extension
                                                                                            ) +
                                                                                            " position-left text-primary"
                                                                                        }
                                                                                    />{" "}
                                                                                    {file.document_name}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                );
                                                            })}
                                                        {this.state.company_files.length == 0 && (
                                                            <p>No Files Found.</p>
                                                        )}

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}


export default ViewMoversProfile;