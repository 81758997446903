
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import PostDataFile from '../services/PostDataFile';
import PostDataWithParam from '../services/PostDataWithParam';
import { FormatSSN } from '../externaljs/FormatSSN';
import GetData from '../services/GetData';
import Breadcumb from '../externaljs/Breadcrumb';

class AddCustomer extends Component {
    state = {
        fields: {
            first_name: '',
            last_name: '',
            email: '',
            phone_no: '',
            alternate_phone_no: '',
            gender: '',
            dob: '',
            ssn: '',
            address: '',
            latitude: '',
            longitude: '',
            alternate_email: '',
            home_phone_no: '',
            spouse_first_name: '',
            spouse_last_name: '',
            spouse_home_phone_no: '',
            spouse_phone_no: '',
            spouse_work_phone_no: '',
            number_of_children: '',
            number_of_pets: '',
            contract_start_date: '',
            contract_end_date: '',
            contract_image: ''
        },
        error: {
            first_name_err: '',
            last_name_err: '',
            email_err: '',
            phone_no_err: '',
            gender_err: '',
            dob_err: '',
            ssn_err: '',
            address_err: '',
            alternate_email_err: '',
            home_phone_no_err: '',
            spouse_home_phone_no_err: '',
            spouse_phone_no_err: '',
            spouse_work_phone_no_err: '',
            number_of_children_err: '',
            number_of_pets_err: '',
            contract_start_date_err: '',
            contract_end_date_err: '',
            contract_image_err: ''
        },
        addCustomerStatus: false,
        CustErr: '',
        lists: [],
        change: false,
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Customer', link: '/independent-mover/dashboard/customer', class: '' },
            { title: 'Add Customer', link: '', class: 'active' }
        ],
        isProccesingFileUpload :false
    }

    componentDidMount() {
        window.$('#contract_info').hide();
        // window.$('.input-group.date').daterangepicker({
        //     autoclose: true
        // }).on('changeDate', (e) => {
        //     let fields = this.state.fields;
        //     fields.dob = e.format();
        //     this.setState({ fields: fields });
        // });
        window.$('.select').select2({
            minimumResultsForSearch: Infinity
        });
        this.datepic('dob');
        FormatSSN();
        this.GetAllCustomers();
    }

    datepic(name) {
        window.$('#' + name).daterangepicker({
            showDropdowns: true,
            autoclose: true,
            singleDatePicker: true
        }).on('apply.daterangepicker', (ev, picker) => {
            let fields = this.state.fields;
            fields[name] = picker.startDate.format('MM/DD/YYYY');
            this.setState({ fields: fields });
        });
    }

    handleChange = (e) => {
        let isChecked = e.target.checked;
        if (isChecked) {
            this.setState({ change: true });
            window.$('#contract_info').show();
        } else {
            this.setState({ change: false });
            window.$('#contract_info').hide();
        }
    }

    initAutocomplete = (e) => {
        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: [] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let fields = this.state.fields;
            fields.latitude = place.geometry.location.lat();
            fields.longitude = place.geometry.location.lng();
            fields.address = window.$('#address')[0].value;
            this.setState({ fields: fields });
        })
    }

    GetAllCustomers = () => {
        PostDataWithParam('GetAllCustomer')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ lists: response.data });
                }
            })
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        if (e.target.name == 'phone_no' || e.target.name == 'alternate_phone_no') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                fields[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
        }
        this.setState({ fields: fields });
    }

    resetError = (cb) => {
        let error = {
            first_name_err: '',
            last_name_err: '',
            email_err: '',
            phone_no_err: '',
            gender_err: '',

        }

        this.setState({ error: error }, () => {
            cb();
        });
    }

    handletContractInfoImage = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.files[0];
        this.setState({ fields: fields });
    }
    handleSubmit = () => {
        // e.preventDefault()
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        this.resetError(() => {
            let error = this.state.error;
            if (!this.state.fields.gender) {
                // console.log('1')
                error.gender_err = 'Salutation is required.';
            }
            else if (!this.state.fields.first_name) {
                // console.log('2')
                error.first_name_err = 'First name is required.';
            }
            else if (!this.state.fields.last_name) {
                // console.log('3')
                error.last_name_err = 'Last name is required.';
            }

            else if (!this.state.fields.phone_no) {
                // console.log('4')
                error.phone_no_err = 'Please enter mobile number.';
            }
            // else if (isNaN(this.state.fields.phone_no)) {
            //     console.log('5')
            //     error.phone_no_err = 'Please enter a valid mobile number.';
            // }
            else if (!this.state.fields.email) {
                // console.log('6')
                error.email_err = 'Email is required.';
            } else if (!pattern.test(this.state.fields.email)) {
                // console.log('7')
                error.email_err = 'Please enter valid email.';
            }

            else {
                // console.log('8')
                let fields = this.state.fields;
                this.setState({ isProccesingFileUpload: true }, () => {

                    // fields.dob = document.getElementById('dob').value;
                    // fields.customer_id = document.getElementById('customer_id').value;
                    // this.setState({ fields: fields });

                    PostDataWithParam('AddCustomer', fields)
                        .then((response) => {
                            let result = response;
                            if (result.success === 1) {
                                window.swal('Successfull!', result.msg, 'success');
                                this.setState({ CustErr: result.msg }, () => {
                                    this.setState({ addCustomerStatus: true,isProccesingFileUpload: false });
                                });
                            } else {
                                window.swal('Error!', response.msg, 'error');
                                this.setState({ CustErr: result.msg,isProccesingFileUpload: false});
                            }
                        })
                        .catch(err => {
                            window.swal('Oops! Something went wrong.');
                            this.setState({ CustErr: 'Oops! Something went wrong.' ,isProccesingFileUpload: false});
                        })
                })
                this.setState({ error: error });
            }
        })
    }
    // handleAddContractInfo = () => {
    //     if(this.state.change){
    //         let fields = this.state.fields;
    //         fields.contract_start_date = document.getElementById('contract_start_date').value;
    //         fields.contract_end_date = document.getElementById('contract_end_date').value;
    //         this.setState({ fields: fields });
    //         let fd = new FormData();
    //         fd.append('contract_start_date', this.state.fields.contract_start_date);
    //         fd.append('contract_end_date', this.state.fields.contract_end_date);
    //         fd.append('document', this.state.fields.contract_image, this.state.fields.contract_image.name);
    //         fd.append('customer_id', this.state.customer_id);                

    //         PostDataFile('AddCustomerContract', fd)
    //         .then((response) => {
    //             if (response.success === 1) {
    //                 this.setState({ CustErr: "Customer Added Successfully" }, () => {
    //                     this.setState({ addCustomerStatus: true });
    //                 });
    //             } else {
    //                 this.setState({ CustErr: response.msg });
    //             }
    //         })
    //         .catch(err => {
    //             this.setState({ CustErr: 'Oops! Something went wrong.', addCustomerStatus: false });
    //         })
    //     }
    // }

    render() {

        if (this.state.addCustomerStatus) {
            return <Redirect to={{
                pathname: '/independent-mover/dashboard/customer',
                state: { CustErr: this.state.CustErr }
            }} />
        }

        return (
            <div className="content-container">
                <div className="row">
                    <div className="col-md-12">
                        {Breadcumb(this.state.breadcrumb)}
                        <div className="panel panel-flat profile-page">
                            <div className="panel-heading p-b-0">
                                <h5 className="panel-title">Add Customer Details</h5>
                                <span className="text-danger">{this.state.CustErr}</span>
                            </div>
                            <form action="#">
                                <div className="panel-body">

                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Salutation <span className="text-danger">*</span></label>
                                                <select className="form-control" name="gender" onChange={this.changeValue} >
                                                    <option value="">Select</option>
                                                    <option value="Mr.">Mr.</option>
                                                    <option value="Ms.">Ms.</option>
                                                    <option value="Mrs.">Mrs.</option>
                                                </select>
                                                <p className="text-danger help-block">{this.state.error.gender_err}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label>First Name <span className="text-danger">*</span></label>
                                                <input type="text" name="first_name" onChange={this.changeValue} className="form-control" placeholder="Enter First Name" />
                                                <p className="text-danger help-block">{this.state.error.first_name_err}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label>Last Name <span className="text-danger">*</span></label>
                                                <input type="text" name="last_name" onChange={this.changeValue} className="form-control" placeholder="Enter Last Name" />
                                                <p className="text-danger help-block">{this.state.error.last_name_err}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Primary Email Address <span className="text-danger">*</span></label>
                                                <input className="form-control" type="text" name="email" onChange={this.changeValue} placeholder="Enter Primary Email Address" />
                                                <p className="text-danger help-block">{this.state.error.email_err}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Secondary Email Address</label>
                                                <input className="form-control" type="text" name="recovery_email" onChange={this.changeValue} placeholder="Enter Secondary Email Address" />

                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Mobile Phone <span className="text-danger">*</span></label>
                                                <input className="form-control" type="text" name="phone_no" onChange={this.changeValue} value={this.state.fields.phone_no || ''} placeholder="xxx xxx xxxx" maxLength="10" />
                                                <p className="text-danger help-block">{this.state.error.phone_no_err}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Work Phone</label>
                                                <input className="form-control" type="text" name="alternate_phone_no" onChange={this.changeValue} value={this.state.fields.alternate_phone_no || ''} placeholder="xxx xxx xxxx" maxLength="10" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Address</label>
                                                <input className="form-control" type="text" id="address" onFocus={this.initAutocomplete} name="address" onChange={this.changeValue} placeholder="Enter Address" />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Date of Birth</label>
                                                <div className="input-group date" id="dob">
                                                    <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                    <input type="text" className="form-control" name="dob" value={this.state.fields.dob || ''} onChange={this.changeValue} placeholder="Enter date of birth" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-footer text-right p-r-20">
                                    {this.state.isProccesingFileUpload ?
                                        <label className="btn btn-upload-file btn-xs">Processing...</label>
                                        :
                                        <button type="button" className="btn btn-primary" onClick={this.handleSubmit} >Add</button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}


export default AddCustomer;