import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import HeaderMain from './HeaderMain';
import FooterMain from './FooterMain';
import Content from './Content';

class Homepage extends Component {
    state = {}

    render() {

        return (
            <div>
                <HeaderMain />
                <Content />
                <FooterMain />
            </div>
        );
    }
}

export default Homepage;