import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DataTableBasic } from '../externaljs/DataTableBasic';
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataWithParam from '../services/PostDataWithParam';
import DateFormat from '../externaljs/DateFormat';

class MoversApplications extends Component {
    state = {
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Application List', link: '', class: 'active' }
        ],
        applicationList:[],
        name:'',
        date_range:'',
        from_date:'',
        to_date:''

    }

    componentDidMount() {
        window.$('#date_range').daterangepicker({
            opens: 'left',
            applyClass: 'bg-slate-600',
            cancelClass: 'btn-default',
            autoUpdateInput: false,
            locale: {
                cancelLabel: 'Clear'
            }
        });

        window.$('#date_range').on('apply.daterangepicker', (ev, picker) => {
            this.setState({ from_date:picker.startDate.format('YYYY-MM-DD'),to_date:picker.endDate.format('YYYY-MM-DD') },()=>{
                this.setState({ date_range: this.state.from_date+' to '+this.state.to_date });
            });
        });

        window.$('#date_range').on('cancel.daterangepicker', (ev, picker) => {
            window.$(this).val('');
        });

        this.GetApplicationList()
    }

    GetApplicationList = () => {
        PostDataWithParam('GetApplicationListForMMC',{})
        .then((response) => {
            if (response.success === 1) {
                this.setState({ applicationList: response.data },()=>{
                    DataTableBasic()
                });                
            }
        })
        .catch(err => {
            this.setState({ applicationList: [] });
        })
    }

    renderActionTD = (status,accept_reject_date) => {
        if(status==1 && accept_reject_date!=null)
            return <span className="text-success">Accepted on {DateFormat.getFormattedDateTime(accept_reject_date, 'M d, Y')}</span>
        else if(status==2 && accept_reject_date!=null)
            return <span className="text-danger">Rejected on {DateFormat.getFormattedDateTime(accept_reject_date, 'M d, Y')}</span>
        else
            return <span className="text-primary">Open</span>
    }

    changeValue = (e) => {
        let name = e.target.value;
        this.setState({ name: name });
    }

    filterResult = () => {
        
            this.filterApplicationList()
       
    }

    filterApplicationList = () => {
        PostDataWithParam('GetApplicationListForMMC',{name:this.state.name,from_date:this.state.from_date,to_date:this.state.to_date})
        .then((response) => {
            if (response.success === 1) {
                this.setState({ applicationList: response.data },()=>{
                    DataTableBasic()
                });                
            }
        })
        .catch(err => {
            this.setState({ applicationList: [] });
        })
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        {Breadcumb(this.state.breadcrumb)}
                        <div className="panel panel-default">
                            <div className="panel-heading clearfix">
                                <h5 className="panel-title">Application List</h5>
                                <div className="heading-elements">
                                <Link to="/independent-mover/dashboard/send-application" className="btn btn-xs btn-success"><i className="icon-plus2"></i> Send a New Application</Link>
                                </div>
                            </div>
                            <div className="panel-heading p-b-0">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label className="control-label">Search by Name</label>
                                            <input type="text" className="form-control" name="name" onChange={this.changeValue}/>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label className="control-label">Search Between Dates</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control daterange-left" id="date_range" value={this.state.date_range} readOnly={true}/>
                                                <span className="input-group-addon"><i className="icon-calendar22"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label className="control-label show hidden-xs hidden-sm">&nbsp;</label>
                                            <button className="btn btn-primary btn-block" type="button" onClick={this.filterResult}>Filter Result</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table className="table datatable-basic table-striped">
                                <thead>
                                    <tr>
                                        <th style={{ whiteSpace: 'nowrap' }}>Customer Name</th>
                                        <th>Location</th>
                                        <th>Request Date</th>
                                        <th>File(s)</th>
                                        <th width="200">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.applicationList.map((application)=>{
                                            return (
                                            <tr key={application.id}>
                                                <td>
                                                    <div className="customer-details">
                                                        <Link to={{ pathname: '/independent-mover/dashboard/application-details/' + application.id }}>{application.customer_name}</Link>                                                        
                                                    </div>
                                                </td>
                                                <td>{application.address}</td>
                                                <td>{DateFormat.getFormattedDateTime(application.request_date, 'M d, Y')}</td>
                                                <td>{application.file_count}</td>
                                                <td>
                                                    {this.renderActionTD(application.status,application.accept_reject_date)}                                                    
                                                </td>
                                            </tr>
                                            )
                                        })
                                    }                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>                
            </div>
        );
    }
}

export default MoversApplications;