import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import PostDataWithParam from '../services/PostDataWithParam';
import MilitaryConversations from './MilitaryConversations';
import { Prompt } from 'react-router';
class Security extends Component {
    state = {
        fields: {
            walls_roof: -1,
            burgular_system: -1,
            system_inspected_maintained: -1,
            doors_heavy_duty: -1,
            monitored_alarm_system: -1,
            skylights: '',
            doors_double_lock: -1,
            padloacks: -1,
            adequate_lighting: -1,
            tab_id: 9,
            id: ''
        },
        saveStatus: false,
        proccesing:false,
        formDirty:false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        PostDataWithParam('GetMilitaryApprovalSecurity', {})
        .then((response) => {
            if (response.success == 1) {
                let response_data = {...this.state.fields, ...response.data};
                this.setState({ fields: response_data });
            }
        })
        window.$('[data-popup="popover"]').popover();
        window.$('[data-popup="tooltip"]').tooltip();
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields: fields ,formDirty:true});
    }

    SaveMilitaryApprovalSecurity = () => {
        this.setState({ proccesing: true })
        PostDataWithParam('SaveMilitaryApprovalSecurity', this.state.fields)
            .then((response) => {
                if (response.success === 1) {
                    window.swal('Success!', response.msg, 'success')
                    this.setState({ saveStatus: true,proccesing:false,formDirty:false }, () => {
                        this.props.onUpdateApprovalProgress();
                    });
                } else {
                    this.setState({ proccesing: false,formDirty:false })
                    window.swal('Error!', response.msg, 'error')
                }
            })
    }

    openModal = (field_name,tab_id) => {
        this.props.onOpenModal(field_name,tab_id)
    }

    renderAlertMsg = (field_name,tab_id) => {
       
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
        
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
        
        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Answer Received</p>
                        </React.Fragment>
                    )
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return (
                        <React.Fragment>
                            <div className="clearfix"></div>
                            <p className="text-danger text-right">Waiting for reply</p>
                        </React.Fragment>
                    )
                }
            }
        }
    }
    renderClassName = (field_name,tab_id) => {
       
        let approvalAskQuestionsNodes = this.props.approvalAskQuestionsNodesProps;
        let approvalAskQuestionsNodesSentProps = this.props.approvalAskQuestionsNodesSentProps;
       
        let tempNode = approvalAskQuestionsNodes['tab_'+tab_id];
        let tempNode2 = approvalAskQuestionsNodesSentProps['tab_'+tab_id];
           

        if(tempNode.length > 0){
            for( let i = 0; i < tempNode.length; i++){           
                if(tempNode[i] && tempNode[i][field_name]){                        
                    return "btn-success "
                }
            }
        }

        if(tempNode2.length > 0){
            for( let i = 0; i < tempNode2.length; i++){           
                if(tempNode2[i] && tempNode2[i][field_name]){                        
                    return "btn-warning "
                }
            }
        }


    }
    render() {
        if (this.state.saveStatus) {
            return <Redirect to='/independent-mover/dashboard/military-approved/information-tab/authority' />
        }

        return (
            <div className="tab-pane active" id="top-icon-tab9">
                <Prompt
                when={this.state.formDirty}
                message="Please save your data before you leave the page. Otherwise, you will lose the information you entered !"
                />
                <div className="panel panel-flat">
                    <div className="panel-heading">
                        <h5 className="panel-title">Security</h5>
                    </div>
                    <div className="panel-body">
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Are the walls and roof structurally sound, including windows, doors and skylights?</h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="walls_roof" onChange={this.changeValue} checked={(this.state.fields.walls_roof == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="walls_roof" onChange={this.changeValue} checked={(this.state.fields.walls_roof == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('walls_roof',this.state.fields.tab_id)} className={this.renderClassName('walls_roof',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('walls_roof',this.state.fields.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="panel-title m-t-0">Do you have a burgular alarm system? &nbsp;<small><a href="#" data-popup="popover" title="" data-trigger="hover" data-content="When approved by the storage program manager, the use of electronic security protection systems with AC/DC alternating power sources or protective systems, such as sound detectors, motion sensors, infrared beam sensors, and closed-circuit television, are acceptable only when supervised and monitored at a central station on a 24-hour basis.Additionally, the warehousemen will have a program for the bi-annual inspection and maintenance of these systems."><i className="icon-info22"></i></a></small></h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="burgular_system" onChange={this.changeValue} checked={(this.state.fields.burgular_system == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="burgular_system" onChange={this.changeValue} checked={(this.state.fields.burgular_system == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('burgular_system',this.state.fields.tab_id)} className={this.renderClassName('burgular_system',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('burgular_system',this.state.fields.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Is this system inspected and maintenanced at a minimum twice per year by a qualified technician?</h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="system_inspected_maintained" onChange={this.changeValue} checked={(this.state.fields.system_inspected_maintained == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="system_inspected_maintained" onChange={this.changeValue} checked={(this.state.fields.system_inspected_maintained == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('system_inspected_maintained',this.state.fields.tab_id)} className={this.renderClassName('system_inspected_maintained',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('system_inspected_maintained',this.state.fields.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Are all entry doors heavy duty (metal or solid code wood)? </h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="doors_heavy_duty" onChange={this.changeValue} checked={(this.state.fields.doors_heavy_duty == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="doors_heavy_duty" onChange={this.changeValue} checked={(this.state.fields.doors_heavy_duty == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('doors_heavy_duty',this.state.fields.tab_id)} className={this.renderClassName('doors_heavy_duty',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('doors_heavy_duty',this.state.fields.tab_id)} 
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Are all windows monitored by alarm system?</h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="monitored_alarm_system" onChange={this.changeValue} checked={(this.state.fields.monitored_alarm_system == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="monitored_alarm_system" onChange={this.changeValue} checked={(this.state.fields.monitored_alarm_system == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('monitored_alarm_system',this.state.fields.tab_id)} className={this.renderClassName('monitored_alarm_system',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('monitored_alarm_system',this.state.fields.tab_id)}
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Do you have any skylights or other points of entry other than windows and doors? Please explain.</h5>
                            <div className="row">
                                <div className="col-md-12">
                                    <textarea className="form-control" name="skylights" onChange={this.changeValue} value={this.state.fields.skylights || ""}></textarea>
                                </div>
                                <div className="col-md-12">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('skylights',this.state.fields.tab_id)} className={this.renderClassName('fire_alarm_protection',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('skylights',this.state.fields.tab_id)}
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Do all doors have double locks (ex. Handle lock with deadbolt, double sliding locks on roll up doors)</h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="doors_double_lock" onChange={this.changeValue} checked={(this.state.fields.doors_double_lock == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="doors_double_lock" onChange={this.changeValue} checked={(this.state.fields.doors_double_lock == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('doors_double_lock',this.state.fields.tab_id)} className={this.renderClassName('doors_double_lock',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('doors_double_lock',this.state.fields.tab_id)}
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Do all van bodies (trailers, box trucks, etc.) have padlocks?</h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="padloacks" onChange={this.changeValue} checked={(this.state.fields.padloacks == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="padloacks" onChange={this.changeValue} checked={(this.state.fields.padloacks == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('padloacks',this.state.fields.tab_id)} className={this.renderClassName('padloacks',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('padloacks',this.state.fields.tab_id)}
                                </div>
                            </div>
                        </div>
                        <div className="well m-b-15">
                            <h5 className="m-t-0">Does exterior of building have adequate lighting to deter criminals? </h5>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className="radio-inline">
                                        <input type="radio" value="1" name="adequate_lighting" onChange={this.changeValue} checked={(this.state.fields.adequate_lighting == 1) ? true : false} />
                                        Y
                                                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" value="0" name="adequate_lighting" onChange={this.changeValue} checked={(this.state.fields.adequate_lighting == 0) ? true : false} />
                                        N
                                                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <a href="javascript:void(0)" onClick={()=>this.openModal('adequate_lighting',this.state.fields.tab_id)} className={this.renderClassName('adequate_lighting',this.state.fields.tab_id) + " btn btn-default btn-xs pull-right m-t-10"} data-toggle="modal" data-target="#comment"><i className="icon-question4"></i> Conversations</a>
                                    {this.renderAlertMsg('adequate_lighting',this.state.fields.tab_id)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <MilitaryConversations/>   
                <div className="text-center">{this.state.proccesing? <button className="btn btn-primary" disabled={true}>Processing...</button>:<button className="btn btn-primary" onClick={this.SaveMilitaryApprovalSecurity}>Save &amp; Next</button>}</div>
            </div>

        );
    }
}

export default Security;