import React, { Component } from 'react';
import PostDataWithParam from '../services/PostDataWithParam';
import PostDataFile from '../services/PostDataFile';
import GetData from '../services/GetData';
import ConfirmBox from '../externaljs/ConfirmBox';
import { FileType, FileSvg, FileIcon } from '../externaljs/FileType';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Storage from '../externaljs/Storage';

class ProfileTab extends Component {

    state = {
        prevFields: {},
        fields: {
            logo: '',
            organization_name: '',
            doing_business_as_dba: '',
            year_founded: '',
            website_link: '',
            main_office_phone_no: '',
            company_fax_no: '',
            operation_email: '',
            sales_email: '',
            customer_service_email: '',
            driver_and_team_recruitment: '',
            claim_email: '',
            after_hours_staff_name: '',
            after_hours_contact_phone: '',
            radius_of_quality_service: '',
            management_contact_name: '',
            management_title: '',
            company_email: '',
            management_phone_no: '',
            military_approved_warehouse: '',
            certified_truck_scale_on_premises: '',
            warehouse: '',
            warehouse_size: '',
            climatized: '',
            climatized_size: '',
            air_conditioned: '',
            air_conditioned_size: '',
            pre_move_survey: '',
            packing: '',
            crating: '',
            short_hauling_hhg: '',
            long_hauling_hhg: '',
            freight_hauling: '',
            storage: '',
            intermodal: '',
            day_road_labor: '',
            Military_Approved: '',
            International_Origin_Service: '',
            International_Destination_Service: '',

            last_mile_delivery: '',
            freight_warehouse: '',
            third_party_services: '',
            secured_parking_lot: '',
            tractors: '',
            trailers: '',
            straight_trucks: '',
            pack_vans: '',
            cdl_drivers: '',
            non_cdl_drivers: '',
            packers: '',
            loaders: '',
            primary_street_address: '',
            primary_address_other_info: '',
            secondary_street_address: '',
            secondary_address_other_info: '',
            primary_zip_code: '',
            secondary_zip_code: '',
            services_provided: [],
            primary_country: '',
            secondary_country: '',
            MailphysicalValue: 0,
            service_range: '',
            radius_serviced: 0
        },
        error: {
            DOT_number_err: '',
            about_us_err: '',
            company_MC_err: '',
            company_contact_no_err: '',
            company_email_err: '',
            company_fax_no_err: '',
            core_values_err: '',
            facebook_link_err: '',
            linkedIn_link_err: '',
            profile_image_err: '',
            organization_name_err: '',
            our_mission_err: '',
            state_license_number_err: '',
            twitter_link_err: '',
            website_link_err: '',
            year_founded_err: '',
            primary_address_other_info_err: '',
            secondary_address_other_info_err: ''

        },
        proccesing: false,
        changeUpdateStatus: false,
        UpdateErr: '',
        selectedFile: null,
        selectedFileAll: [],
        allStates: [],
        primaryCities: [],
        secondaryCities: [],
        company_files: [],
        breadcrumb: [
            { title: 'Dashboard', link: '/moving-company/dashboard/home', class: '' },
            { title: 'Company Profile', link: '/moving-company/dashboard/company-profile', class: '' },
            { title: 'Edit Company Profile', link: '', class: 'active' }
        ],
        completion_percentage: 0,
        services_provided_list: [],
        src: null,
        crop: {
            // aspect: 16 / 9,
            // height: 200,
            // width: 200,
            // x: 0,
            // y: 0
            unit: '%',
            width: 50,
            height: 50,
            x: 0,
            y: 0
        },
        croppedImageUrl: '',
        loading: false,
        uploadProccesing: false
    }

    componentDidMount() {

        this.setState({ loading: true }, () => {
            this.GetCompanyInfo();
            this.getCompletionStatus();
            this.GetAllCompanyFile();
        })

    }

    primaryCountry() {
        if (window.$('.primary-country').length > 0) {
            window.$('.primary-country').select2({
                containerCssClass: 'select-xs'
            }).off('select2:select').on('select2:select', (e) => {
                let fields = Object.assign({}, this.state.fields);
                fields['primary_country'] = e.params.data.id;
                this.setState({ fields: fields });
            }).off('select2:unselect').on('select2:unselect', (e) => {

            });

        }
    }

    secondaryCountry() {
        if (window.$('.secondary-country').length > 0) {
            window.$('.secondary-country').select2({
                containerCssClass: 'select-xs'
            }).off('select2:select').on('select2:select', (e) => {
                let fields = Object.assign({}, this.state.fields);
                fields['secondary_country'] = e.params.data.id;
                this.setState({ fields: fields });
            }).off('select2:unselect').on('select2:unselect', (e) => {

            });

        }
    }

    primaryCity() {
        if (window.$('.primary-city').length > 0) {
            window.$('.primary-city').select2({
                containerCssClass: 'select-xs'
            }).off('select2:select').on('select2:select', (e) => {
                let fields = Object.assign({}, this.state.fields);
                fields['primary_city'] = e.params.data.id;
                this.setState({ fields: fields });
            }).off('select2:unselect').on('select2:unselect', (e) => {

            });

        }
    }

    secondaryCity() {
        if (window.$('.secondary-city').length > 0) {
            window.$('.secondary-city').select2({
                containerCssClass: 'select-xs'
            }).off('select2:select').on('select2:select', (e) => {
                let fields = Object.assign({}, this.state.fields);
                fields['secondary_city'] = e.params.data.id;
                this.setState({ fields: fields });
            }).off('select2:unselect').on('select2:unselect', (e) => {

            });

        }
    }

    GetCompanyInfo = () => {

        PostDataWithParam('GetCompanyInfo', {})
            .then((response) => {
                if (response.success === 1) {
                    Storage.set('imnFirstLogin', JSON.stringify({ organization_name: response.data[0].organization_name, logo: response.data[0].logo }))
                    let services_provided = [];
                    if (response.data[0].services_provided) {
                        let services_provided_arr = JSON.parse(response.data[0].services_provided);
                        services_provided = services_provided_arr.map((item, i) => {
                            return item.name;
                        })
                    }

                    this.setState({ fields: response.data[0], services_provided_list: services_provided }, () => {

                    });
                } else {
                    this.setState({ fields: [], services_provided_list: [] })
                }
            })
            .catch(err => {
                this.setState({ fields: [], services_provided_list: [] })
            })
    }

    UpdateRadius = () => {
        PostDataWithParam('UpdateHelperRadiusService', { 'radius_serviced': this.state.setredius })
            .then((response) => {
                if (response.success === 1) {
                    this.GetCompanyInfo();
                }
            })
    }

    getStates() {
        PostDataWithParam('GetStates', {})
            .then((response) => {
                let result = response;
                if (result.success === 1) {
                    this.setState({ allStates: result.data }, () => {
                        this.GetCompanyInfo();
                    });
                } else {
                    this.setState({ UpdateErr: result.msg });
                }
            })
            .catch(err => {

            })
    }

    getCities(state = 'Alaska', flag = '') {
        var state_code = 'AK';
        for (var st of this.state.allStates) {
            if (st.state.trim() == state.trim()) {
                state_code = st.state_code;
                break;
            }
        }

        PostDataWithParam('GetCities', { state_code: state_code })
            .then((response) => {
                let result = response;
                if (result.success === 1) {
                    if (flag == '') {
                        this.setState({ primaryCities: result.data, secondaryCities: result.data }, () => {
                            this.primaryCity();
                            this.secondaryCity();
                        });
                    } else if (flag == 'primary') {
                        this.setState({ primaryCities: result.data }, () => {
                            this.primaryCity();
                        });
                    } else if (flag == 'secondary') {
                        this.setState({ secondaryCities: result.data }, () => {
                            this.secondaryCity()
                        });
                    }

                } else {
                    this.setState({ UpdateErr: result.msg });
                }
            })
            .catch(err => {

            })
    }

    primaryState() {
        if (window.$('.primary-state').length > 0) {
            window.$('.primary-state').select2({
                containerCssClass: 'select-xs'
            }).off('select2:select').on('select2:select', (e) => {
                let fields = Object.assign({}, this.state.fields);
                fields['primary_state'] = e.params.data.id;
                this.setState({ fields: fields });
                this.getCities(e.params.data.id, 'primary');
            }).off('select2:unselect').on('select2:unselect', (e) => {
                this.getCities(e.params.data.id, 'primary');
            });

        }
    }

    secondaryState() {
        if (window.$('.secondary-state').length > 0) {
            window.$('.secondary-state').select2({
                containerCssClass: 'select-xs'
            }).off('select2:select').on('select2:select', (e) => {
                let fields = Object.assign({}, this.state.fields);
                fields['secondary_state'] = e.params.data.id;
                this.setState({ fields: fields });
                this.getCities(e.params.data.id, 'secondary');
            }).off('select2:unselect').on('select2:unselect', (e) => {
                this.getCities(e.params.data.id, 'secondary');
            });

        }
    }



    getCompletionStatus = () => {
        GetData('GetProfileCompleteStatus')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ completion_percentage: response.completion_percentage }, () => {

                    });
                }
            })
            .catch(err => {
                this.setState({ completion_percentage: 0 });
            })
    }

    GetAllCompanyFile = () => {
        GetData('GetCompanyDocument')
            .then((response) => {
                if (response.success == 1) {
                    this.setState({ company_files: response.data, loading: false }, () => {
                        this.renderRadiusGraph()
                    });
                } else {
                    this.setState({ company_files: [], loading: false }, () => {
                        this.renderRadiusGraph()
                    });
                }
            })
            .catch(err => {
                this.setState({ company_files: [], loading: false }, () => {
                    this.renderRadiusGraph()
                })
            })
    }

    renderRadiusGraph() {

        window.$("#demo").ionRangeSlider({
            grid: true,
            min: 1,
            max: 200,
            from: this.state.fields.radius_serviced,
            step: 20,
            skin: "round",
            max_postfix: "+",
            prefix: "Miles ",
            onChange: (data) => {
                this.setState({ setredius: data.from }, () => {
                    this.UpdateRadius();
                });
            },
        });
    }

    deleteFile = (id) => {
        ConfirmBox("You want to delete this file!")
            .then((result) => {
                if (result) {
                    PostDataWithParam('DeleteCompanyDocument', { document_id: id })
                        .then((response) => {
                            if (response.success == 1) {
                                window.swal('Deleted!', response.msg, 'success')
                                this.GetAllCompanyFile()
                            }
                        })
                }
            })
    }

    changeValue = (e) => {
        let fields = this.state.fields;
        let field_name = e.target.name;
        let value = e.target.value;

        let checkboxes = [
            'pre_move_survey', 'packing', 'crating', 'Loading',
            'Debris_Removal',
            'storage', 'Appliance_Service', 'Disassembly_Setup', 'Clock_Service',
            'International_Origin_Service',
            'International_Destination_Service', 'maid_service', 'Transport', 'third_party_services'
        ];

        if (checkboxes.indexOf(field_name) > -1) {
            if (this.state.services_provided_list.indexOf(value) > -1) {
                let services_provided_list = this.state.services_provided_list.filter((item, i) => {
                    if (item == value)
                        return false;
                    else
                        return true;
                })
                this.setState({ services_provided_list: services_provided_list });
            } else {
                let services_provided_list = this.state.services_provided_list;
                services_provided_list.push(value);
                this.setState({ services_provided_list: services_provided_list });
            }
        }
        fields[e.target.name] = e.target.value;
        if (e.target.name == 'management_phone_no' || e.target.name == 'main_office_phone_no' || e.target.name == 'company_fax_no' || e.target.name == 'after_hours_contact_phone') {
            var cleaned = ('' + e.target.value).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                fields[e.target.name] = match[1] + ' ' + match[2] + ' ' + match[3]
            }
        }
        this.setState({ fields: fields });
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            window.$("#imageModal").modal('show')
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onImageLoaded = (image, crop) => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = crop => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            let dataURL = canvas.toDataURL("image/jpeg");
            resolve(dataURL);
        });
    }

    handleUpdateCompanyLogo = () => {
        if (this.state.croppedImageUrl != '') {
            this.setState({ proccesing: true }, () => {
                PostDataWithParam('UpdateCroppedCompanyLogoForMovers', { 'logo': this.state.croppedImageUrl })
                    .then((response) => {
                        if (response.success === 1) {
                            Storage.set('imnFirstLogin', JSON.stringify({ organization_name: this.state.fields.organization_name, logo: this.state.fields.logo }))
                            window.$("#imageModal").modal('hide')
                            window.swal('Success!', "Company logo updated successfully", 'success')
                            this.setState({
                                changeUpdateStatus: true, proccesing: false
                            });
                            this.GetCompanyInfo();
                        } else {
                            this.setState({ UpdateErr: response.msg, changeUpdateStatus: false, proccesing: false });
                        }
                    })
                    .catch((err) => {
                        this.setState({ UpdateErr: 'Something went wrong!', changeUpdateStatus: false, proccesing: false });
                    })
            })
        }
    }

    handleFileSelectAll = (e) => {
        let userfiles = []
        for (let i = 0; i < e.target.files.length; i++) {
            userfiles.push(e.target.files[i])
        }
        this.setState({ selectedFileAll: userfiles }, () => {
            if (this.state.selectedFileAll.length != 0) {
                let fd = new FormData();
                for (let i = 0; i < this.state.selectedFileAll.length; i++) {
                    fd.append('document[' + i + ']', this.state.selectedFileAll[i], this.state.selectedFileAll[i].name);
                }
                this.setState({ uploadProccesing: true }, () => {
                    PostDataFile('UpdateCompanyDocument', fd)
                        .then((response) => {
                            if (response.success === 1) {
                                window.swal('Success!', "Company document updated successfully", 'success')
                                this.setState({ changeUpdateStatus: true, uploadProccesing: false });
                                this.GetAllCompanyFile()
                            } else {
                                window.swal('Error!', "Company document not updated", 'error')
                                this.setState({ uploadProccesing: false });
                            }
                        })
                        .catch(err => {
                            window.swal('Error!', 'Something went wrong.', 'error')
                            this.setState({ uploadProccesing: false });
                        })
                });
            }
        });
    }

    resetError = (cb) => {
        let error = {
            DOT_number_err: '',
            about_us_err: '',
            company_MC_err: '',
            company_contact_no_err: '',
            company_email_err: '',
            company_fax_no_err: '',
            core_values_err: '',
            facebook_link_err: '',
            linkedIn_link_err: '',
            logo_err: '',
            organization_name_err: '',
            our_mission_err: '',
            state_license_number_err: '',
            twitter_link_err: '',
            website_link_err: '',
            year_founded_err: '',
            primary_address_other_info_err: '',
            secondary_address_other_info_err: ''
        }
        this.setState({ error: error }, () => {
            cb();
        });
    }

    handleUpdateCompanyProfile = () => {
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        this.resetError(() => {
            let error = this.state.error;
            let tempServiceProvided = [];

            if (this.state.services_provided_list.length > 0) {
                tempServiceProvided = this.state.services_provided_list.map((item, i) => {
                    return { name: item }
                })
            }

            let tempServiceFields = this.state.fields;
            tempServiceFields.services_provided = JSON.stringify(tempServiceProvided);
            this.setState({ fields: tempServiceFields });

            if (!this.state.fields.organization_name) {
                error.organization_name_err = 'Organization Name is required.';
            }
            else {
                this.setState({ proccesing: true }, () => {
                    PostDataWithParam('UpdateCompanyProfile', this.state.fields)
                        .then((response) => {
                            let result = response;
                            if (result.success === 1) {
                                window.swal('Success!', response.msg, 'success')
                                Storage.set('imnFirstLogin', JSON.stringify({ organization_name: this.state.fields.organization_name, logo: this.state.fields.logo }))
                                this.setState({ changeUpdateStatus: true, proccesing: false });
                                this.getCompletionStatus();
                            } else {
                                window.swal('Error!', response.msg, 'error')
                                this.setState({ proccesing: false });
                                this.getCompletionStatus();
                            }
                        })
                        .catch(err => {
                            window.swal('Error!', 'Something went wrong.', 'error')
                            this.setState({ proccesing: false });
                            this.getCompletionStatus();
                        })
                })
            }

            this.setState({ error: error });
        });
    }

    handleFileCheck = (file) => {
        if (file !== '') {
            return (
                <div className="thumb custom-logo" style={{ backgroundImage: "url(" + this.state.fields.logo + ")" }}>
                </div>
            )
        } else {
            return (
                <div className="thumb custom-logo" style={{ backgroundImage: "url('assets/images/icon-no-image.svg')" }}>
                </div>
            )
        }
    }

    initPhysicalAutocomplete = (e) => {
        let fields = this.state.fields;
        fields.primary_street_address = e.target.value;
        this.setState({ fields: fields });

        var componentForm = {
            country: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            postal_code: 'short_name'
        };

        let autocomplete = new window.google.maps.places.Autocomplete(
            /** @type {!HTMLInputElement} */(document.getElementById(e.target.id)),
            { types: [] });

        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            let fields = this.state.fields;

            for (var i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (componentForm[addressType]) {
                    var val = place.address_components[i][componentForm[addressType]];
                    if (addressType == "administrative_area_level_1")
                        fields.primary_state = val
                    else if (addressType == "locality")
                        fields.primary_city = val
                    else if (addressType == "postal_code")
                        fields.primary_zip_code = val
                    else if (addressType == "country")
                        fields.primary_country = val
                }
            }
            let address = ''
            for (let i = 0; i < place.address_components.length; i++) {
                if (place.address_components[i].types[0] == "street_number" || place.address_components[i].types[0] == "route" || place.address_components[i].types[0] == "sublocality_level_1" || place.address_components[i].types[0] == "sublocality_level_2" || place.address_components[i].types[0] == "sublocality_level_3") {
                    address = address + " " + place.address_components[i].long_name
                }

            }
            if (address == '') {
                address = window.$('#primary_street_address')[0].value;
            }
            fields.primary_street_address = address
            this.setState({ fields: fields });
        })
    }

    setMailphysicalValue(e) {
        let fields = { ...this.state.fields };
        let prevFields = { ...this.state.prevFields };
        if (window.$('input:checkbox[name=MailphysicalValue]').is(':checked')) {
            prevFields = { ...this.state.fields };
            fields.MailphysicalValue = 1;
            fields.secondary_street_address = fields.primary_street_address || "";
            fields.secondary_address_other_info = fields.primary_address_other_info || "";
            fields.secondary_city = fields.primary_city || "";
            fields.secondary_state = fields.primary_state || "";
            fields.secondary_zip_code = fields.primary_zip_code || "";
            fields.secondary_country = fields.primary_country || "";
        }
        else {
            fields.MailphysicalValue = 0;
            fields.secondary_street_address = prevFields.secondary_street_address || "";
            fields.secondary_address_other_info = prevFields.secondary_address_other_info || "";
            fields.secondary_city = prevFields.secondary_city;
            fields.secondary_state = prevFields.secondary_state;
            fields.secondary_zip_code = prevFields.secondary_zip_code;
            fields.secondary_country = prevFields.secondary_country;
        }
        this.setState({ fields, prevFields });


    }

    handleServiceRange = (e) => {
        // alert(e.target.value);
        // let fields = Object.assign({}, this.state.fields);
        // fields[e.target.name] = e.target.value;
        // this.setState({ fields: fields })

        PostDataWithParam('UpdateHelperRadiusService', { 'service_range': e.target.value })
            .then((response) => {
                if (response.success === 1) {
                    this.GetCompanyInfo();
                }
            })
    }


    render() {
        return (
            <div>
                {
                    this.state.loading
                    &&
                    <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Loading. Please wait...</div>
                }
                {
                    !this.state.loading
                    &&
                    <React.Fragment>
                        <div id="tab-profile" className="tab-pane active">
                            <div className="text-center">
                                <h5 className="inline-block m-r-30 text-blue-800">Profile Completion</h5>
                                <div className="progress inline-block" style={{ width: '300px' }}>
                                    <div className="progress-bar bg-blue-800" style={{ width: this.state.completion_percentage + '%' }}>
                                        <span>{this.state.completion_percentage}% Complete</span>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-4 col-lg-3">
                                            <label className="upload-company-logo thumbnail">
                                                {this.handleFileCheck(this.state.fields.logo)}

                                                <input type="file" name="logo" onChange={this.onSelectFile} />
                                            </label>
                                        </div>
                                        <div className="col-md-8 col-lg-9">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Legal Name of Company.</label>
                                                        <input className="form-control" type="text" name="organization_name" placeholder="Enter Company Name" onChange={this.changeValue} value={this.state.fields.organization_name} />
                                                        <p className="text-danger help-block">{this.state.error.organization_name_err}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Year Founded</label>
                                                        <input className="form-control" type="text" name="year_founded" placeholder="Enter Year Founded" onChange={this.changeValue} value={this.state.fields.year_founded || ''} />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Doing Business As/ DBA</label>
                                                        <input className="form-control" type="text" name="doing_business_as_dba" placeholder="Doing Business As/ DBA" onChange={this.changeValue} value={this.state.fields.doing_business_as_dba || ''} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Website</label>
                                                        <input className="form-control" name="website_link" placeholder="Enter Website" onChange={this.changeValue} value={this.state.fields.website_link || 'http://'} type="text" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="text-success">Physical Street Address</h5>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Street Address</label>
                                                <input className="form-control" name="primary_street_address" id="primary_street_address" placeholder="Enter Street Address" onChange={this.initPhysicalAutocomplete} value={this.state.fields.primary_street_address} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Street Address 2</label>
                                                <input className="form-control" name="primary_address_other_info" id="primary_address_other_info" placeholder="Enter Other Address Info" onChange={this.changeValue} value={this.state.fields.primary_address_other_info || ''} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>City</label>
                                                <input className="form-control" id="primary_city" name="primary_city" value={this.state.fields.primary_city} type="text" readOnly={true} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>State</label>
                                                <input className="form-control" id="primary_state" name="primary_state" value={this.state.fields.primary_state} type="text" readOnly={true} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Zip Code</label>
                                                <input className="form-control" id="primary_zip_code" name="primary_zip_code" value={this.state.fields.primary_zip_code || ''} type="text" readOnly={true} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Country (if Applicable) </label>
                                                <input className="form-control" id="primary_country" name="primary_country" value={this.state.fields.primary_country} type="text" readOnly={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    {/* <hr />
                                    {
                                        parseInt(this.state.fields.is_scac) === 1
                                        &&
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h5 className="text-success">SCAC information</h5>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>SCAC Name</label>
                                                    <input className="form-control" name="scac_name" id="scac_name" value={this.state.fields.scac_name} type="text" readOnly={true} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>D-Quality Assurance Phone</label>
                                                    <input className="form-control" name="d_quality_assurance_phone" id="d_quality_assurance_phone" onChange={this.changeValue} value={this.state.fields.d_quality_assurance_phone || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>D-Customer service Email</label>
                                                    <input className="form-control" name="d_customer_service_email" id="d_customer_service_email" onChange={this.changeValue} value={this.state.fields.d_customer_service_email || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>D-Quality Assurance Email</label>
                                                    <input className="form-control" name="d_quality_assurance_email" id="d_quality_assurance_email" onChange={this.changeValue} value={this.state.fields.d_quality_assurance_email || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>D-Claims Phone</label>
                                                    <input className="form-control" name="d_claim_phone" id="d_claim_phone" onChange={this.changeValue} value={this.state.fields.d_claim_phone || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>D-Claims Email</label>
                                                    <input className="form-control" name="d_claim_email" id="d_claim_email" onChange={this.changeValue} value={this.state.fields.d_claim_email || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>D-Storage In Transit (SIT) Phone </label>
                                                    <input className="form-control" name="d_storage_in_transit_phone" id="d_storage_in_transit_phone" onChange={this.changeValue} value={this.state.fields.d_storage_in_transit_phone || ''} type="text" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>D-Storage In Transit (SIT) Email</label>
                                                    <input className="form-control" name="d_storage_in_transit_email" id="d_storage_in_transit_email" onChange={this.changeValue} value={this.state.fields.d_storage_in_transit_email || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>D-Non Temporary Storage (NTS) Phone</label>
                                                    <input className="form-control" name="d_non_temporary_storage_phone" id="d_non_temporary_storage_phone" onChange={this.changeValue} value={this.state.fields.d_non_temporary_storage_phone || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>D-Non Temporary Storage (NTS) Email</label>
                                                    <input className="form-control" name="d_non_temporary_storage_email" id="d_non_temporary_storage_email" onChange={this.changeValue} value={this.state.fields.d_non_temporary_storage_email || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>D-Clearing Phone </label>
                                                    <input className="form-control" name="d_clearing_phone" id="d_clearing_phone" onChange={this.changeValue} value={this.state.fields.d_clearing_phone || ''} type="text" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>D-Clearing Email</label>
                                                    <input className="form-control" name="d_clearing_email" id="d_clearing_email" onChange={this.changeValue} value={this.state.fields.d_clearing_email || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>D-Billing Phone</label>
                                                    <input className="form-control" name="d_billing_phone" id="d_billing_phone" onChange={this.changeValue} value={this.state.fields.d_billing_phone || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>D-Billing Email</label>
                                                    <input className="form-control" name="d_billing_email" id="d_billing_email" onChange={this.changeValue} value={this.state.fields.d_billing_email || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>I-Customer Service Phone </label>
                                                    <input className="form-control" name="i_customer_service_phone" id="i_customer_service_phone" onChange={this.changeValue} value={this.state.fields.i_customer_service_phone || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>I-Customer Service Email</label>
                                                    <input className="form-control" name="i_customer_service_email" id="i_customer_service_email" onChange={this.changeValue} value={this.state.fields.i_customer_service_email || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>I-Quality Assurance Phone</label>
                                                    <input className="form-control" name="i_quality_assurance_phone" id="i_quality_assurance_phone" onChange={this.changeValue} value={this.state.fields.i_quality_assurance_phone || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>I-Quality Assurance Email</label>
                                                    <input className="form-control" name="i_quality_assurance_email" id="i_quality_assurance_email" onChange={this.changeValue} value={this.state.fields.i_quality_assurance_email || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>I-Claims Phone </label>
                                                    <input className="form-control" name="i_claim_phone" id="i_claim_phone" onChange={this.changeValue} value={this.state.fields.i_claim_phone || ''} type="text" />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>I-Claims Email</label>
                                                    <input className="form-control" name="i_claim_email" id="i_claim_email" onChange={this.changeValue} value={this.state.fields.i_claim_email || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>I-Storage In Transit (SIT) Phone</label>
                                                    <input className="form-control" name="i_storage_in_transit_phone" id="i_storage_in_transit_phone" onChange={this.changeValue} value={this.state.fields.i_storage_in_transit_phone || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>I-Storage In Transit (SIT) Email</label>
                                                    <input className="form-control" name="i_storage_in_transit_email" id="i_storage_in_transit_email" onChange={this.changeValue} value={this.state.fields.i_storage_in_transit_email || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>I-Non Temporary Storage (NTS) Phone</label>
                                                    <input className="form-control" name="i_non_temporary_storage_phone" id="i_non_temporary_storage_phone" onChange={this.changeValue} value={this.state.fields.i_non_temporary_storage_phone || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>I-Non Temporary Storage (NTS) Email</label>
                                                    <input className="form-control" name="i_non_temporary_storage_email" id="i_non_temporary_storage_email" onChange={this.changeValue} value={this.state.fields.i_non_temporary_storage_email || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>I-Clearing Phone</label>
                                                    <input className="form-control" name="i_clearing_phone" id="i_clearing_phone" onChange={this.changeValue} value={this.state.fields.i_clearing_phone || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>I-Clearing Email</label>
                                                    <input className="form-control" name="i_clearing_email" id="i_clearing_email" onChange={this.changeValue} value={this.state.fields.i_clearing_email || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>I-Billing Phone</label>
                                                    <input className="form-control" name="i_billing_phone" id="i_billing_phone" onChange={this.changeValue} value={this.state.fields.i_billing_phone || ''} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>I-Billing Email</label>
                                                    <input className="form-control" name="i_billing_email" id="i_billing_email" onChange={this.changeValue} value={this.state.fields.i_billing_email || ''} type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        parseInt(this.state.fields.is_scac) === 1
                                        &&
                                        <hr />
                                    } */}

                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="text-success">Mailing Address (Same as physical address
                                                <label className="checkbox-inline custom-checkbox m-b-0 m-l-3"><input type="checkbox" value="1" name="MailphysicalValue" onChange={this.setMailphysicalValue.bind(this)} /><span className=""></span>)</label>
                                            </h5>

                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Street Address</label>
                                                <input className="form-control" name="secondary_street_address" id="secondary_street_address" placeholder="Enter Street Address" onChange={this.changeValue} value={this.state.fields.secondary_street_address} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Street Address 2</label>
                                                <input className="form-control" name="secondary_address_other_info" id="secondary_address_other_info" placeholder="Enter Other Address Info" onChange={this.changeValue} value={this.state.fields.secondary_address_other_info} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>City</label>
                                                <input className="form-control" id="secondary_city" name="secondary_city" onChange={this.changeValue} value={this.state.fields.secondary_city} type="text" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>State</label>
                                                <input className="form-control" id="secondary_state" name="secondary_state" onChange={this.changeValue} value={this.state.fields.secondary_state} type="text" />

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Zip Code</label>
                                                <input className="form-control" id="secondary_zip_code" name="secondary_zip_code" onChange={this.changeValue} value={this.state.fields.secondary_zip_code} type="text" />

                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Country (if Applicable) </label>
                                                <input className="form-control" id="secondary_country" name="secondary_country" onChange={this.changeValue} value={this.state.fields.secondary_country} type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Main Office Phone Number</label>
                                                <input className="form-control" type="text" name="main_office_phone_no" placeholder="xxx xxx xxxx" onChange={this.changeValue} value={this.state.fields.main_office_phone_no || ""} maxLength="12" />
                                                <p className="text-danger help-block">{this.state.error.company_contact_no_err}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Office Fax Number</label>
                                                <input className="form-control" type="text" name="company_fax_no" placeholder="xxx xxx xxxx" onChange={this.changeValue} value={this.state.fields.company_fax_no || ''} maxLength="12" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Operations Email Address</label>
                                                <input className="form-control" type="text" name="operation_email" placeholder="Enter Operations Email Address" onChange={this.changeValue} value={this.state.fields.operation_email || ''} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Sales Email Address (For Leads & New Customers)</label>
                                                <input className="form-control" type="text" name="sales_email" placeholder="Enter Sales Email Address" onChange={this.changeValue} value={this.state.fields.sales_email || ''} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Customer Service Email Address (For Existing Customers)</label>
                                                <input className="form-control" type="text" name="customer_service_email" placeholder="Enter Customer Service Email Address" onChange={this.changeValue} value={this.state.fields.customer_service_email || ''} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Claims Email Address</label>
                                                <input className="form-control" type="text" name="claim_email" placeholder="Enter Claims Email Address" onChange={this.changeValue} value={this.state.fields.claim_email || ''} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>After Hours Staff Name</label>
                                                <input className="form-control" type="text" name="after_hours_staff_name" placeholder="Enter After Hours Staff Name" onChange={this.changeValue} value={this.state.fields.after_hours_staff_name || ""} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                            <div className="form-group">
                                                <label>After Hours Contact Phone</label>
                                                <input className="form-control" type="text" name="after_hours_contact_phone" placeholder="xxx xxx xxxx" onChange={this.changeValue} value={this.state.fields.after_hours_contact_phone || ""} maxLength="12" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Management Contact Name</label>
                                                <input className="form-control" type="text" name="management_contact_name" placeholder="Enter Management Contact Name" onChange={this.changeValue} value={this.state.fields.management_contact_name || ""} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Management Title</label>
                                                <input className="form-control" type="text" name="management_title" placeholder="Enter Management Title" onChange={this.changeValue} value={this.state.fields.management_title} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Management Email Address</label>
                                                <input className="form-control" type="text" name="company_email" placeholder="Enter Management Email Address" onChange={this.changeValue} value={this.state.fields.company_email} />
                                                <p className="text-danger help-block">{this.state.error.company_email_err}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Management Phone</label>
                                                <input className="form-control" type="text" name="management_phone_no" placeholder="xxx xxx xxxx" onChange={this.changeValue} value={this.state.fields.management_phone_no} maxLength="12" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Certified Truck Scale on Premises?</label>
                                                <div>
                                                    <label className="radio-inline">
                                                        <input type="radio" className="styled" name="certified_truck_scale_on_premises" onChange={this.changeValue} value="1" checked={(this.state.fields.certified_truck_scale_on_premises == 1) ? true : false} />Y
                                                    </label>
                                                    <label className="radio-inline">
                                                        <input type="radio" className="styled" name="certified_truck_scale_on_premises" onChange={this.changeValue} value="0" checked={(this.state.fields.certified_truck_scale_on_premises == 0) ? true : false} />N
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Secured Parking Lot?</label>
                                                <div>
                                                    <label className="radio-inline radio-left">
                                                        <input type="radio" className="styled" name="secured_parking_lot" onChange={this.changeValue} value="1" checked={(this.state.fields.secured_parking_lot == 1) ? true : false} />Y
                                                    </label>
                                                    <label className="radio-inline radio-left">
                                                        <input type="radio" className="styled" name="secured_parking_lot" onChange={this.changeValue} value="0" checked={(this.state.fields.secured_parking_lot == 0) ? true : false} />N
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Do You Have a Warehouse?</label>
                                                <div>
                                                    <label className="radio-inline">
                                                        <input type="radio" className="styled" name="warehouse" onChange={this.changeValue} value="1" checked={(this.state.fields.warehouse == 1) ? true : false} />Y
                                                    </label>
                                                    <label className="radio-inline">
                                                        <input type="radio" className="styled" name="warehouse" onChange={this.changeValue} value="0" checked={(this.state.fields.warehouse == 0) ? true : false} />N
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Warehouse Size in Square Feet</label>
                                                <input className="form-control" type="text" name="warehouse_size" placeholder="Enter Warehouse Size in Square Feet" onChange={this.changeValue} value={this.state.fields.warehouse_size} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Climatized?</label>
                                                <div>
                                                    <label className="radio-inline">
                                                        <input type="radio" className="styled" name="climatized" onChange={this.changeValue} value="1" checked={(this.state.fields.climatized == 1) ? true : false} />Y
                                                    </label>
                                                    <label className="radio-inline">
                                                        <input type="radio" className="styled" name="climatized" onChange={this.changeValue} value="0" checked={(this.state.fields.climatized == 0) ? true : false} />N
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Climatized Size in Square Feet</label>
                                                <input className="form-control" type="text" name="climatized_size" placeholder="Enter Climatized Size in Square Feet" onChange={this.changeValue} value={this.state.fields.climatized_size} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Air Conditioned?</label>
                                                <div>
                                                    <label className="radio-inline radio-left">
                                                        <input type="radio" className="styled" name="air_conditioned" onChange={this.changeValue} value="1" checked={(this.state.fields.air_conditioned == 1) ? true : false} />Y
                                                    </label>
                                                    <label className="radio-inline radio-left">
                                                        <input type="radio" className="styled" name="air_conditioned" onChange={this.changeValue} value="0" checked={(this.state.fields.air_conditioned == 0) ? true : false} />N
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Air Conditioned Size in Square Feet</label>
                                                <input className="form-control" type="text" name="air_conditioned_size" placeholder="Enter Air Conditioned Size in Square Feet" onChange={this.changeValue} value={this.state.fields.air_conditioned_size} />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <h5 className="text-success">Service Radius (in miles)</h5>
                                                <div className="col-md-6">
                                                    <input id="demo" type="text" name="radius_serviced" value={this.state.fields.radius_serviced} className="js-range-slider" tabIndex="-1" readOnly={true} onChange={this.changeValue} />
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    <label className="radio-inline custom-radio p-l-30 text-semi-bold text-uppercase f-s-13 m-b-0"><input type="radio" name="service_range" value="0" checked={this.state.fields.service_range == "0" ? true : false} onChange={this.handleServiceRange} /><span></span> Nationwide USA</label>
                                                    <label className="radio-inline custom-radio p-l-30 text-semi-bold text-uppercase f-s-13 m-b-0"><input type="radio" name="service_range" value="1" checked={this.state.fields.service_range == "1" ? true : false} onChange={this.handleServiceRange} /><span></span> Worldwide</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <h5 className="text-success">Services Provided</h5>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox" className="styled" id="pre_move_survey" name="pre_move_survey" onChange={this.changeValue} checked={(this.state.services_provided_list.indexOf('Pre-Move Survey') > -1)} value="Pre-Move Survey" />
                                                                Pre-Move Survey
                                                            </label>
                                                        </div>
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox" className="styled" id="packing" name="packing" onChange={this.changeValue} checked={(this.state.services_provided_list.indexOf('Packing') > -1)} value="Packing" />
                                                                Packing/Unpacking
                                                            </label>
                                                        </div>
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox" className="styled" id="crating" name="crating" onChange={this.changeValue} checked={(this.state.services_provided_list.indexOf('Crating') > -1)} value="Crating" />
                                                                Crating/Uncrating
                                                            </label>
                                                        </div>
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox" className="styled" id="Loading" name="Loading" onChange={this.changeValue} checked={(this.state.services_provided_list.indexOf('Loading') > -1)} value="Loading" />
                                                                Loading/UnLoading
                                                            </label>
                                                        </div>

                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox" className="styled" id="Debris_Removal" name="Debris_Removal" onChange={this.changeValue} checked={(this.state.services_provided_list.indexOf('Debris Removal') > -1)} value="Debris Removal" />
                                                                Debris Removal
                                                            </label>
                                                        </div>
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox" className="styled" id="storage" name="storage" onChange={this.changeValue} checked={(this.state.services_provided_list.indexOf('Storage') > -1)} value="Storage" />
                                                                Storage
                                                            </label>
                                                        </div>
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox" className="styled" id="Appliance_Service" name="Appliance_Service" onChange={this.changeValue} checked={(this.state.services_provided_list.indexOf('Appliance Service') > -1)} value="Appliance Service" />
                                                                Appliance Service
                                                            </label>
                                                        </div>
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox" className="styled" id="Disassembly_Setup" name="Disassembly_Setup" onChange={this.changeValue} checked={(this.state.services_provided_list.indexOf('Disassembly-Setup') > -1)} value="Disassembly-Setup" />
                                                                Disassembly-Setup
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">

                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox" className="styled" id="Clock_Service" name="Clock_Service" onChange={this.changeValue} checked={(this.state.services_provided_list.indexOf('Clock Service') > -1)} value="Clock Service" />
                                                                Clock Service
                                                            </label>
                                                        </div>
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox" className="styled" id="International_Origin_Service" name="International_Origin_Service" onChange={this.changeValue} checked={(this.state.services_provided_list.indexOf('International Origin Service') > -1)} value="International Origin Service" />
                                                                International Origin Service
                                                            </label>
                                                        </div>
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox" className="styled" id="International_Destination_Service" name="International_Destination_Service" onChange={this.changeValue} checked={(this.state.services_provided_list.indexOf('International Destination Service') > -1)} value="International Destination Service" />
                                                                International Destination Service
                                                            </label>
                                                        </div>
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox" className="styled" id="maid_service" name="maid_service" onChange={this.changeValue} checked={(this.state.services_provided_list.indexOf('Maid Service') > -1)} value="Maid Service" />
                                                                Maid Service
                                                            </label>
                                                        </div>
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox" className="styled" id="Transport" name="Transport" onChange={this.changeValue} checked={(this.state.services_provided_list.indexOf('Transport') > -1)} value="Transport" />
                                                                Transport/Driving
                                                            </label>
                                                        </div>
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox" className="styled" id="third_party_services" name="third_party_services" onChange={this.changeValue} checked={(this.state.services_provided_list.indexOf('Third Party Services') > -1)} value="Third Party Services" />
                                                                Third Party Services(Other)
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Tractors</label>
                                                <input type="number" min="0" className="form-control" name="tractors" onChange={this.changeValue} value={this.state.fields.tractors} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Trailers</label>
                                                <input type="number" min="0" className="form-control" name="trailers" onChange={this.changeValue} value={this.state.fields.trailers} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Straight Trucks</label>
                                                <input type="number" min="0" className="form-control" name="straight_trucks" onChange={this.changeValue} value={this.state.fields.straight_trucks} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Pack Vans</label>
                                                <input type="number" min="0" className="form-control" name="pack_vans" onChange={this.changeValue} value={this.state.fields.pack_vans} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>CDL Drivers</label>
                                                <input type="number" min="0" className="form-control" name="cdl_drivers" onChange={this.changeValue} value={this.state.fields.cdl_drivers} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Non-CDL Drivers</label>
                                                <input type="number" min="0" className="form-control" name="non_cdl_drivers" onChange={this.changeValue} value={this.state.fields.non_cdl_drivers} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Packers</label>
                                                <input type="number" min="0" className="form-control" name="packers" onChange={this.changeValue} value={this.state.fields.packers} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Loaders</label>
                                                <input type="number" min="0" className="form-control" name="loaders" onChange={this.changeValue} value={this.state.fields.loaders} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <h5 className="display-block m-b-0">Show off your Team</h5>
                                                <small className="p-b-10">(Feel free to upload any pictures of your professional team, warehouse, equipment, etc.)</small>
                                                {
                                                    this.state.uploadProccesing
                                                    &&
                                                    <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Uploading. Please wait...</div>
                                                }
                                                {
                                                    !this.state.uploadProccesing
                                                    &&
                                                    <React.Fragment>
                                                        <input type="file" name="logo" onChange={this.handleFileSelectAll} multiple />
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>

                                        {
                                            this.state.company_files.length != 0
                                            &&
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="panel panel-flat">
                                                        <div className="panel-heading p-b-5">
                                                            <h6 className="m-b-0 m-t-0 text-semibold"></h6>
                                                        </div>
                                                        <div className="panel-body">
                                                            {
                                                                this.state.uploadProccesing
                                                                &&
                                                                <div className="well text-center"><i className="icon-spinner4 spinner position-left"></i> Uploading. Please wait...</div>
                                                            }
                                                            {
                                                                !this.state.uploadProccesing
                                                                &&
                                                                <React.Fragment>
                                                                    <div className="file-manager hori-scroll">
                                                                        <ul>
                                                                            {
                                                                                this.state.company_files.length != 0
                                                                                &&
                                                                                this.state.company_files.map((file) => {
                                                                                    return (
                                                                                        <li key={file.id}>
                                                                                            <div className={"file-wrapper " + FileType(file.document_extension)} >
                                                                                                <div className="options">
                                                                                                    <a href="javascript:void(0)" className="text-danger-600" onClick={() => this.deleteFile(file.id)}><i className="icon-cancel-circle2"></i></a>
                                                                                                </div>
                                                                                                {FileSvg(file.document_extension, file.document_path)}
                                                                                                <div className="file-title"><a href={file.document_path}><i className={FileIcon(file.document_extension) + " position-left text-primary"}></i> {file.document_name}</a></div>
                                                                                            </div>
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                            {
                                                                                this.state.company_files.length == 0
                                                                                &&
                                                                                <p>No Files Found.</p>
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </React.Fragment>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                {this.state.proccesing ? <button type="button" className="btn btn-primary" disabled={true}>Processing...</button> : <button type="button" className="btn btn-primary" onClick={this.handleUpdateCompanyProfile}>Update</button>}
                            </div>
                        </div>
                        <div className="modal fade" id="imageModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        <h4 className="modal-title" id="myModalLabel">Upload Company Logo</h4>
                                    </div>
                                    <div className="modal-body">
                                        {this.state.src && (
                                            <ReactCrop
                                                src={this.state.src}
                                                crop={this.state.crop}
                                                onImageLoaded={this.onImageLoaded}
                                                onComplete={this.onCropComplete}
                                                onChange={this.onCropChange}
                                            />
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        {
                                            this.state.proccesing ?
                                                <button type="button" className="btn btn-primary" disabled={true}>Proccesing...</button> :
                                                <button type="button" className="btn btn-primary" onClick={this.handleUpdateCompanyLogo}>Upload</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>

        );
    }
}

export default ProfileTab;