import Storage from "./Storage";
import Urls from "./Urls";

export const logout = () => {
    Storage.remove('imnLoginToken');
    Storage.remove('imnLoginData');
    Storage.remove('imnFirstLogin');
    Storage.remove('mmcStaffMenu');
    window.location.href = Urls.login;
}
export const authMiddleware = async (res) => {
    const resPromise = res.json();
    const response = await resPromise;
    if (response && response.msg === "unauthorized") {
        logout();
        throw new Error("unauthorized");
    }
    return resPromise;
}