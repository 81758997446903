import React, { Component } from 'react';
import {Redirect, Link } from 'react-router-dom';
import GetData from '../services/GetData';
import PostDataFile from '../services/PostDataFile';
import Breadcumb from '../externaljs/Breadcrumb';
import PostDataWithParam from '../services/PostDataWithParam';

class SendApplication extends Component {
    state = {
        CustErr: '',
        customer_list: [],
        driver_list: [],
        breadcrumb: [
            { title: 'Dashboard', link: '/independent-mover/dashboard/home', class: '' },
            { title: 'Manage Application ',  link: '/independent-mover/dashboard/movers-applications', class: '' },
            { title: 'Send a New Application', link: '', class: 'active' }
        ],
        import_file:'',
        cus_fields:{
            user_id:'',
            email:'',
            contact_no:'',
            home_contact_no:'',
            address:'',
            reg_date:'',
            ssn:'',
        },
        driver_fields:{
            user_id:'',
            email:'',
            licence_number:'',
            contact_no:'',
            home_contact_no:'',
            address:'',
            vehicle_no:'',
        },
        selectedFileAll:[],
        error: {
            reg_date_err:'',
            reg_cust_err:'',
            reg_driver_err:''
        },
        successmsg:'',
        
    }

    componentDidMount() {
        
         window.$('.datepic').datepicker({
            autoclose: true
        }).on('changeDate', (e) => {
            let cus_fill = this.state.cus_fields;
            cus_fill.reg_date = e.format();
            this.setState({ cus_fields: cus_fill });
        });
        this.GetAllCustomer();
        this.GetAllDrivers();
        this.handleChange();
    }

    GetAllCustomer = () => {
        PostDataWithParam('GetCustomerListForApplication')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ customer_list: response.data });
                    
                }
            })
    }

    GetAllDrivers = () => {
        PostDataWithParam('GetDriverListForApplication')
            .then((response) => {
                if (response.success === 1) {
                    this.setState({ driver_list: response.data });
                    
                }
            })
    }

    resetError = (cb) => {
        let error = {
            reg_date_err:'',
            reg_cust_err:'',
            reg_driver_err:''
        }
        this.setState({ error: error }, () =>{
            cb();
        });
    }

    handleSaveAppl = () => {
        this.resetError(() => {
            let error = this.state.error;
           
            if(this.state.cus_fields.reg_date === '') {
                error.reg_date_err = 'Please Enter Date.';
            }  
            if(this.state.driver_fields.user_id === '') {
                error.reg_driver_err = 'Please Select Driver.';
            }
            if(this.state.cus_fields.user_id === '') {
                error.reg_cust_err = 'Please Select Customer.';
            }                      
            else {    
                PostDataWithParam('SendNewApplication',{customer_user_id:this.state.cus_fields.user_id,driver_user_id:this.state.driver_fields.user_id,request_date:this.state.cus_fields.reg_date})
                .then( (response) => {
                    let result = response;
                    if(result.success === 1){
                       
                        if(this.state.selectedFileAll.length!=0){    
                            let fd = new FormData();
                            for (let i = 0; i < this.state.selectedFileAll.length; i++) {
                                fd.append('document['+i+']', this.state.selectedFileAll[i], this.state.selectedFileAll[i].name);
                            }
                            fd.append('application_id',response.application_id);
                            PostDataFile('AddApplicationDocument', fd)
                            .then((response) => {
                                if (response.success === 1) {
                                    this.setState({ successmsg : "Application Send Successfully" },()=>{
                                        window.swal('Success!', 'Application Send Successfully', 'success');
                                        this.setState({ changeUpdateStatus: true });   
                                    });
                                } 
                            })
                        }
                        
                    }
                })
            }
            this.setState({ error: error });
        });
    }

    handleChange = () => {
        window.$('.select-search').select2()
        .off('select2:select')
        .on('select2:select',e=>{
        let selname = e.target.name;
        let id= e.target.value;
            if(selname === 'customer'){
                PostDataWithParam('GetCustomerDetailsForApplication',{customer_id:id})
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ cus_fields: response.data[0] });
                    
                    }
                })
            }else{
                PostDataWithParam('GetDriverDetailsForApplication',{driver_id:id})
                .then((response) => {
                    if (response.success === 1) {
                        this.setState({ driver_fields: response.data[0] });
                    
                    }
                })
            }
        })
      }

      handleFileSelectAll = (e) => {
        let userfiles = []
        for (let i = 0; i < e.target.files.length; i++) {
            userfiles.push(e.target.files[i])
        }
        this.setState({ selectedFileAll: userfiles });
    }
    
    render() {
        if (this.state.changeUpdateStatus) {
            return <Redirect  to='/independent-mover/dashboard/movers-applications'/>
        }

        return (
            <div className="row">
                <div className="col-md-12">
                    {Breadcumb(this.state.breadcrumb)}
                   
                        <div className="panel panel-default profile-page">
                            <div className="panel-heading">
                                <h5 className="panel-title">Send a New Application</h5>
                            </div>
                            <div className="panel-body">
                    <div className="row">
                        <div className="col-md-6">
                            <p><b>Driver</b></p>
                            <div className="well p-15 border-radius-3px">
                                <div className="form-group">
                                    <label>Select Driver <span className="text-warning">*</span></label>
                                    <select name="driver" className="select-search">
                                    <option value="">--Select Driver--</option>
                                    {this.state.driver_list.map(driver =>
                                        <option key={driver.id} value={driver.id}>{driver.first_name} {driver.last_name}</option>
                                    )}  
                                    </select>
                                    <p className="text-danger help-block">{this.state.error.reg_driver_err}</p>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Driver's Email Address</label>
                                            <input type="text" className="form-control" placeholder="Enter Email Address" value={this.state.driver_fields.email} readOnly={true}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Driver's Mobile Phone</label>
                                            <input type="text" className="form-control" placeholder="Enter Mobile Phone" value={this.state.driver_fields.contact_no} readOnly={true}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Driver's Alternate Phone No</label>
                                            <input type="text" className="form-control" placeholder="Enter Alternate Phone No" value={this.state.driver_fields.home_contact_no} readOnly={true}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Driver's License</label>
                                            <input type="text" className="form-control" placeholder="Enter License Number" value={this.state.driver_fields.licence_number} readOnly={true}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Driver's Vehicle No</label>
                                            <input type="text" className="form-control" placeholder="Enter Vehicle No" value={this.state.driver_fields.vehicle_no} readOnly={true}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>
                                                Address
                                            </label>
                                            <input type="text" className="form-control" placeholder="Address" value={this.state.driver_fields.address} readOnly={true}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                       
                        <div className="col-md-6">
                            <p><b>Customer</b></p>
                            <div className="well p-15 border-radius-3px">
                                <div className="form-group">
                                    <label>Select Customer <span className="text-warning">*</span></label>
                                    <select name="customer" className="select-search">
                                    <option value="">--Select Customer--</option>
                                    {this.state.customer_list.map(customer =>
                                        <option key={customer.id} value={customer.id}>{customer.first_name} {customer.last_name}</option>
                                    )} 
                                    </select>
                                    <p className="text-danger help-block">{this.state.error.reg_cust_err}</p>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Date Requested to be on site <span className="text-warning">*</span></label>
                                            <div className="input-group date">
                                                <span className="input-group-addon"><i className="icon-calendar"></i></span>
                                                <input type="text" className="form-control datepic" placeholder="Enter Date" readOnly={true}/>                                                
                                            </div>
                                            <p className="text-danger help-block">{this.state.error.reg_date_err}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Customer Primary Email Address</label>
                                            <input type="text" className="form-control" placeholder="Enter Customer Email" value={this.state.cus_fields.email} readOnly={true}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Customer Primary Phone Number</label>
                                            <input type="text" className="form-control" placeholder="Enter Customer Mobile" value={this.state.cus_fields.contact_no} readOnly={true}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Customer Secondary Phone Number</label>
                                            <input type="text" className="form-control" placeholder="Enter Customer Alternate Phone Number" value={this.state.cus_fields.home_contact_no || ""} readOnly={true}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>SSN</label>
                                            <input type="text" className="form-control" placeholder="Enter Social Security Number" value={this.state.cus_fields.ssn} readOnly={true}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Address</label>
                                            <input type="text" className="form-control" placeholder="Enter Customer Address" value={this.state.cus_fields.address} readOnly={true}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Upload Documents</label>
                                    <input type="file" name="docup" onChange={this.handleFileSelectAll} multiple/>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                        </div>
                        <div className="text-center">
                <button className="btn btn-primary btn-lg " onClick={this.handleSaveAppl}>Submit</button>
                <button className="btn btn-request btn-lg m-l-10">Cancel</button>
            </div>
                    
                </div>
            </div>
        );
    }
}

export default SendApplication;

const style = {
    whiteSpace: 'nowrap'
}